import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UtilService} from './util.service';
import {MediaCenterService} from './rest/media-center.service';
import {TranslateService} from '@ngx-translate/core';

import {SentencecasePipe} from '@saep-ict/angular-core';

interface FolderNode {
	name: string;
	children: FolderNode[];
}

@Injectable({
	providedIn: 'root',
})

export class FolderUtilService {
	constructor(
		public snackbar: MatSnackBar,
		public translate: TranslateService,
		public sentencecasePipe: SentencecasePipe,
		public utilService: UtilService,
		private mediaCenterService: MediaCenterService,
	) {
	}

	getFoldersTreeArray(paths) {
		/**
		 * In ingersso prende un'array di stringhe che rappresentano i singoli path dei file
		 *
		 * es.
		 * [
		 *    "test_mimmo_multi_level_new_3/level_1_1/level_1_1_file_1.txt",
		 *    "test_mimmo_multi_level_new_3/level_1_1/level_1_1_1/level_1_1_1_file_1.txt",
		 *    "test_mimmo_multi_level_new_3/level_1_2/level_1_2_2/level_1_2_2_1/level_1_2_2_1_file_1.txt",
		 *    ...
		 * ]
		 *
		 * Restitusico un array nidificato formato da oggetti di di tipo FolderNode
		 */


		/** inizializzo	un oggetto root settato sulla directory cdi cui sto facendo l'upload.
		 * Lo faccio perché la proprietà webkit mi ritorna l'alberatura a partire dai figli
		 */
		const root: FolderNode = { name: this.getRootFolder(paths[0]), children: [] };

		// Loop through each path
		for (const path of paths) {
			const parts = path.split('/');

			/** elimino il riferimento al file */
			parts.pop();
			let currentNode: FolderNode = root;

			// Ciclo le "parti" per creare i relativi nodi se necessario
			for (let i = 1; i < parts.length; i++) {
				const part = parts[i];
				let childNode = currentNode.children.find(child => child.name === part);

				//Creo il nodo se non esisre
				if (!childNode) {
					childNode = <FolderNode>{ name: part, children: [] };
					currentNode.children.push(childNode);
				}

				// Imposto childNode come currentNode per la successiva iterazione
				currentNode = childNode;
			}
		}

		return root;
	}

	getRootFolder(path: string) {
		const parts = path.split('/');
		return parts[0];
	}


	getPathsArrayFromFilesArray(filesArray: File[]) {
		/**
		 * in inresso arriva una array di oggetti File
		 * es.
		 *
		 * [
		 *    {name: 'root.txt', lastModified: 1707813141661, lastModifiedDate: Tue Feb 13 2024 09:32:21 GMT+0100 (Ora standard dell’Europa centrale), webkitRelativePath: 'test_mimmo_multi_level_new_3/root.txt', size: 5, …}
		 *    {name: 'level_1_1_file_1.txt', lastModified: 1707813141661, lastModifiedDate: Tue Feb 13 2024 09:32:21 GMT+0100 (Ora standard dell’Europa centrale), webkitRelativePath: 'test_mimmo_multi_level_new_3/level_1_1/level_1_1_file_1.txt', size: 5, …}
		 *    {name: 'level_1_1_1_file_1.txt', lastModified: 1707813141661, lastModifiedDate: Tue Feb 13 2024 09:32:21 GMT+0100 (Ora standard dell’Europa centrale), webkitRelativePath: 'test_mimmo_multi_level_new_3/level_1_1/level_1_1_1/level_1_1_1_file_1.txt', size: 5, …}
		 *    ...
		 *    {}
		 * ]
		 *
		 * restituice un array con solo i path dei singoli file presi dalla proprietà webkitRelativePath
		 *
		 * es.
		 *
		 * [
		 *    "test_mimmo_multi_level_new_3/level_1_1/level_1_1_file_1.txt",
		 *    "test_mimmo_multi_level_new_3/level_1_1/level_1_1_1/level_1_1_1_file_1.txt",
		 *    "test_mimmo_multi_level_new_3/level_1_2/level_1_2_2/level_1_2_2_1/level_1_2_2_1_file_1.txt",
		 *    ...
		 * ]
		 */


		const pathsArray: string[] = [];
		for (const item of filesArray) {
			pathsArray.push(item.webkitRelativePath);
		}
		return pathsArray;
	}

	checkIfFolderExist(folder: string) {
		const foldersList: string[] = [];
		for (const item of this.utilService.folderContent) {
			if (item.fileType === 'folder') {
				foldersList.push(item.name.toLowerCase());
			}
		}
		return foldersList.includes(folder.toLowerCase());
	}


	createFoldersTreeOnBucket(foldersTree: any, path: string = '', isChildren = false): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				if(!isChildren && this.checkIfFolderExist(foldersTree.name)) {
					throw new Error("saep_ict_angular_core.attachment_resolver.message.error_with.foder_already_exist");
				}
				const res = await this.mediaCenterService
					.createFolder({
						path: this.utilService.context.value.filter.path + path,
						newName: foldersTree.name
					}, null, { fileType: 'folder' });

				if (foldersTree.children.length > 0) {
					//const promises: Promise<void>[] = [];
					for (const child of foldersTree.children) {
						//promises.push(this.createFoldersTreeOnBucket(child, path + foldersTree.name + '/'));
						await this.createFoldersTreeOnBucket(child, path + foldersTree.name + '/', true)
					}
					//await Promise.all(promises);
				}
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	// Al momento è inutile. Voglio valutare la possibilità di usarla per alcuni controlli.
	createFoldersTree(filesArray) {
		return this.getFoldersTreeArray(this.getPathsArrayFromFilesArray(filesArray));
	}

	checkIfFolder(files: FileList) {
		return !!(files[0] && files[0].webkitRelativePath);
	}

}
