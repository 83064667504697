<password-verify
	(submitFormEvent)="userPasswordUpdate($event)"
	[type]="typePasswordVerifyEnum.CHANGE"
	[redirectLink]="redirectLink"
	[showCancelButton]="true"
>
</password-verify>

<!-- <button mat-button color="primary" [routerLink]="redirectLink" class="button__cancel">
	{{ 'general.cancel' | translate | sentencecase }}
</button> -->
