<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<ng-container *ngFor="let card of dataConfigurationList; let i = index; let last = last">
			<div>
				<mat-card class="michelangelo-theme" [ngClass]="{ 'mb-20': !last, 'mb-30': !last }">
					<mat-toolbar>
						<mat-toolbar-row>
							{{ card.title | translate | sentencecase }}
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<ng-template [ngIf]="card.upload">
							<attachment-resolver
								[multiple]="card.upload.multiple"
								[acceptedType]="card.upload.acceptedType"
								(fileEmitter)="onAttachmentResolverFileChange($event, i)"
							></attachment-resolver>
						</ng-template>
						<form-button-bar
							*ngIf="card.button.display"
							[align]="'right'"
							[primaryButtonLabel]="card.button.label | translate | sentencecase"
							(primaryButtonClickEmitter)="card.button.action(card.id, card.upload.data[0].data)"
						></form-button-bar>
					</mat-card-content>
				</mat-card>
			</div>
		</ng-container>
	</ng-container>
	<ng-container pageSide></ng-container>
</page-with-sidebar>
