// model
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

// store
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '@saep-ict/angular-spin8-core';

export enum LoginContextCodeActionEnum {
	UPDATE = '[login-context-code] Update List',
	LOAD = '[login-context-code] Load',
	RESET = '[login-context-code] Reset',
	ERROR = '[login-context-code] Error'
}

export namespace LoginContextCodeStateAction {
	export const update = createAction(
		LoginContextCodeActionEnum.UPDATE,
		props<BaseStateModel<BodyTablePouchCustomModel>>()
	);
	export const load = createAction(LoginContextCodeActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const reset = createAction(LoginContextCodeActionEnum.RESET);
	export const error = createAction(LoginContextCodeActionEnum.ERROR, props<BaseStateModel<null>>());
}
