import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { RestBasePk } from '@saep-ict/angular-core';
import { UserDetailModel, UserPasswordUpdateModel } from '@saep-ict/angular-spin8-core';

export enum UserActionEnum {
	UPDATE = '[user] Update',
	LOAD = '[user] Load',
	SAVE = '[user] Save',
	PASSWORD_CHANGE = '[user] Passwor_Change',
	PASSWORD_RECOVERY = '[user] Passwor_Recovery',
	COMPLETED = '[user] Completed',
	RESET = '[user] Reset',
	ERROR = '[user] Error'
}

export namespace UserStateAction {
	export const load = createAction(UserActionEnum.LOAD, props<RestBasePk>());
	export const update = createAction(UserActionEnum.UPDATE, props<BaseStateModel<UserDetailModel>>());

	export const save = createAction(UserActionEnum.SAVE, props<BaseStateModel<UserDetailModel>>());
	export const password_change = createAction(
		UserActionEnum.PASSWORD_CHANGE,
		props<BaseStateModel<UserPasswordUpdateModel>>()
	);
	export const password_recovery = createAction(
		UserActionEnum.PASSWORD_RECOVERY,
		props<BaseStateModel<{ email: string; context_type: string }>>()
	);
	export const completed = createAction(UserActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const reset = createAction(UserActionEnum.RESET);
	export const error = createAction(UserActionEnum.ERROR, props<BaseStateModel<any>>());
}
