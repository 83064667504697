import { BaseStateModel } from '@saep-ict/angular-core';
import { createAction, props } from '@ngrx/store';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';

export enum OrderListActionEnum {
	UPDATE = '[order list] Update',
	LOAD_ALL = '[order list] Load All',
	LOAD_ALL_DONE = '[order list] Load All Done',
	LOAD_ALL_AND_GET_DRAFT = '[order list] Load All And Get Draft',
	COMPLETED = '[order list] Completed',
	SKIP = '[order list] Skip',
	RESET = '[order list] Reset',
	ERROR = '[order list] Error'
}

export namespace OrderListStateAction {
	export const loadAll = createAction(OrderListActionEnum.LOAD_ALL);
	export const loadAllAndGetDraft = createAction(OrderListActionEnum.LOAD_ALL_AND_GET_DRAFT);
	export const update = createAction(OrderListActionEnum.UPDATE, props<BaseStateModel<OrderStateModel[]>>());
	export const completed = createAction(OrderListActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const skip = createAction(OrderListActionEnum.SKIP);
	export const reset = createAction(OrderListActionEnum.RESET);
	export const error = createAction(OrderListActionEnum.ERROR, props<BaseStateModel<any>>());
}
