import { NgModule } from '@angular/core';
import { DropAreaDirective } from './drop-area.directive';

@NgModule({
  declarations: [
    DropAreaDirective
  ],
  exports: [
    DropAreaDirective
  ]
})
export class DirectivesModule { }
