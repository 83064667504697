import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeDomSanitizerPipe } from './safe-dom-sanitizer.pipe';
import { DateAsAgoPipe } from './date-as-ago.pipe';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
  declarations: [SafeDomSanitizerPipe, DateAsAgoPipe, FileSizePipe],
  exports: [
    SafeDomSanitizerPipe,
    DateAsAgoPipe,
    FileSizePipe
  ],
  providers: [
    DatePipe,
    TitleCasePipe,
    FileSizePipe
  ]
})
export class PipeModule { }
