<page-with-sidebar>
	<ng-container pageMain>
		<mat-card>
			<action-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(openDialogConfirmCloseAction)="openDialogConfirmCloseAction($event)"
				(onItemSelect)="goToActionDetail($event)"
				(selectCompany)="selectCompany($event.level, $event.code)"
				[isLogs]="isLogs"
			>
			</action-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container pageSide>
		<sidebar-content-wrapper>
			<ng-container main-content>
				<form class="michelangelo-theme" [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()">
					<mat-form-field>
						<mat-label>{{ 'action.field.type' | translate | sentencecase }}</mat-label>
						<mat-select formControlName="action_type">
							<mat-option *ngFor="let actionType of ActionTypes" [value]="actionType">
								{{ 'action.type.' + actionType.code | translate | sentencecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div class="mb-3" *ngIf="!isLogs">
						<mat-checkbox color="primary" formControlName="status_open">Solo aperte</mat-checkbox>
					</div>
					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
						(primaryButtonClickEmitter)="onFormFiltersSubmit()"
						[secondaryButtonLabel]="'general.clean' | translate | sentencecase"
						(secondaryButtonClickEmitter)="resetFilters()"
					></form-button-bar>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>

<button
	class="mat-fab-button"
	(click)="createNew()"
	mat-fab
	[matTooltip]="'action.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	color="primary"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'action.add_new' | translate | sentencecase }}</span>
</button>
