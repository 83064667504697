import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	SubscribeManagerService,
	BaseStateModel,
	BaseState,
	AttachmentListManager
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StateFeature } from '../../../../../state';
import { OrderStateAction } from '../../../../../state/order/order.actions';
import { LocalListHandlerBaseModel, AttachedFile } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import * as ConfigurationCustomerOrder from '../../../../../constants/configuration-customer/order/order.constant';
import { attachmentListManagerColumnListBase } from '@saep-ict/angular-core';
import { OrderStateModel, UserDetailModel, AngularSpin8CoreUtilOfferService, OfferStateModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'offer-detail-attachment-list',
	templateUrl: './offer-detail-attachment-list.component.html',
	styleUrls: ['./offer-detail-attachment-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferDetailAttachmentListComponent {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	offer$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	offer: OfferStateModel;

	attachmentListManagerConfiguration: AttachmentListManager.Configuration<AttachedFile>;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private utilOfferService: AngularSpin8CoreUtilOfferService
	) {
		this.getStaticData();
		this.attachmentListManagerConfiguration = {
			localListHandler: <LocalListHandlerBaseModel<AttachedFile>>{
				pagination: {
					pageSize: 10
				},
				filters: {
					localSearchText: {
						value: null,
						key_list: ['name']
					}
				},
				sort: {
					name: 'name',
					order: 'ASC'
				},
				data: []
			},
			page: {
				title: 'attachment.list',
				columnList: attachmentListManagerColumnListBase,
				display: {
					paginator: true,
					textFilter: true
				}
			},
			upload: {
				multiple: true
			},
			privilege: {
				add: ConfigurationCustomerOrder.attachment.canCreate[this.user.current_permission.context_application],
				delete:
					ConfigurationCustomerOrder.attachment.canEdit[this.user.current_permission.context_application],
				download:
					ConfigurationCustomerOrder.attachment.canDownload[this.user.current_permission.context_application]
			},
			pathUrl: 'offer',
			pathQueryParam: this.offer.code_item,
		};
		this.subscribeManagerInit();
	}

	getStaticData() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(this.subscribeOffer().subscribe(), 'subscribe-offer');
	}

	subscribeOffer(): Observable<OfferStateModel> {
		return this.offer$.pipe(
			filter(state => !!(state && state.data)),
			map(state => {
				this.offer = <OfferStateModel>state.data;
				this.attachmentListManagerConfiguration.localListHandler.data = this.offer.attachment_list || [];
				this.attachmentListManagerConfiguration = _.cloneDeep(this.attachmentListManagerConfiguration);
				return this.offer;
			})
		);
	}

	attachmentListManagerChange(e: AttachedFile[]) {
		this.offer.attachment_list = e;
		const newOfferToSave = this.utilOfferService.getPayloadToSaveOffer(this.offer, this.offer._id, null);
		this.store.dispatch(OrderStateAction.save(new BaseState(newOfferToSave.orderData.order)));
	}
}
