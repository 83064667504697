import { Injectable } from '@angular/core';
import { VatPriceModel } from '../../model/price.model';
import {
	ArticlePouchModel,
	ShippingChargePouchModel,
	CommercialAreaPouchModel,
	PriceEnum,
	TableListEnum,
	DiscountTypeEnum,
	DiscountModel,
	ThresholdPouchModel
} from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';
import {
	OrganizationPouchModel
} from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { StateFeature } from '../../state';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as ConfigurationCustomerOrder from '../../constants/configuration-customer/order/order.constant';
import * as ConfigurationCustomerAdditionalServiceOrder from '../../constants/configuration-customer/additional-service/additional-service-order.constant';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';
import { OrderPouchModel, HeaderTablePouchModel } from '@saep-ict/pouch_agent_models';
import { PouchDbCommonsAdapter } from '../pouch-db/spin8/pouchdb-commons.adapter';
import { SentencecasePipe } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { UtilAddressService } from './util-address.service';
import {
	AuxiliaryTableStateModel,
	OrderStateModel,
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	OfferStateModel,
	OrderEnum,
	OrderPriceMap,
	OrderPriceMapItem
} from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerShipping from '../../constants/configuration-customer/shipping/shipping.constant';
import * as ConfigurationCustomerPrice from '../../constants/configuration-customer/price/price.constant';

@Injectable({
	providedIn: 'root'
})
export class UtilPriceService {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	vatPrice: VatPriceModel = { vatArticle: 0, vatShipping: 0 };

	shippingChargeList: ShippingChargePouchModel[];

	shippingChargeFilterList = ConfigurationCustomerOrder.ShippingChargeFilterList;

	constructor(
		private store: Store,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter,
		private translate: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		private utilAddressService: UtilAddressService
	) {
		this.loadStaticData();
	}

	loadStaticData() {
		this.user$.pipe(filter(res => !!(res && res.data)), take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.auxiliaryTable$.pipe(filter(res => !!(res && res.data)), take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
	}

	/**
	 * Get article price from selected division or 'is_main_of_list: true' one
	 * @param article Current article
	 * @param division Selected division
	 */
	mapArticlePrice(article: ArticlePouchModel, division?: string) {
		let articlePrice = { price: 0, discount: [], vat: 0 };
		if (article.division_list) {
			const articleDivision = division
				? article.division_list.find(x => x.division === division)
				: article.division_list.find(x => x.is_main_of_list);
			if (articleDivision) {
				articlePrice = {
					price: articleDivision.price,
					discount: this.getArticleDiscounts(articleDivision),
					vat: articleDivision.vat
				};
			}
		}
		article.articlePrice = articlePrice;
	}

	mapOrderArticlePrice(a: ArticlePouchModel) {
		a.input_quantity = a.ordered_quantity;
		a.articlePrice = {
			price: a.price,
			discount: a.discount,
			vat: a.vat
		};
		a.calculate_price = this.retrieveTotalArticlePrice(a);
	}

	/**
	 * Get structured discounts model
	 * @param articleDivision Selected division
	 * @returns Structured discounts
	 */
	getArticleDiscounts(articleDivision): DiscountModel[] {
		return articleDivision.discounts
			.filter(discountValue => discountValue !== 0)
			.map(discountValue => {
				return {
					value: discountValue,
					type: DiscountTypeEnum.percentage
				};
			});
	}

	retrieveTotalArticlePrice(article: ArticlePouchModel) {
		const priceWithDiscount = this.retrievePriceWithDiscount(
			article.articlePrice.price,
			article.articlePrice.discount,
			article.discount_agent
		);
		return this.round(
			priceWithDiscount *
			(article.input_quantity ? article.input_quantity : article.ordered_quantity)
		);
	}

	retrievePriceWithDiscount(price: number, discountList: DiscountModel[], discountAgent?: DiscountModel) {
		if (discountList) {
			discountList.forEach(discount => {
				price = this.calculateDecimalFromDiscount(price, discount.value);
			});
		}
		if (discountAgent) {
			price = this.calculateDecimalFromDiscount(price, discountAgent.value);
		}
		return this.round(price);
	}

	calculateDecimalFromDiscount(price: number, discount: number) {
		return price - (price * discount) / 100;
	}

	/**
	 * Restituisce l'ordine a valle del calcolo dell'oggetto order.header.price
	 *
	 * @param {OrderStateModel} order
	 * @returns
	 * @memberof UtilOrderService
	 */
	async updatePriceOrder(
		order: OrderStateModel,
		organization: OrganizationPouchModel = null,
		commercialArea: CommercialAreaPouchModel = null
	): Promise<OrderStateModel> {
		try {
			order.header.price = {
				total: 0,
				article: 0,
				vat: 0,
				shipping: order.header.price?.shipping || 0
			};
			this.updateArticlePriceOrder(order);
			if (
				order.header.goods_destination_object &&
				order.header.goods_destination_object.code_item &&
				order.header.starting_warehouse &&
				organization
			) {
				await this.updateShippingPriceOrder(order, organization, commercialArea);
			}
			if (
				order.header.additional_services &&
				!_.isEmpty(order.header.additional_services)
			) {
				ConfigurationCustomerAdditionalServiceOrder.updatePriceHandler(order);
			}
			this.updateVatPriceOrder(order);
			this.updateTotalPriceOrder(order);
			return order;
		} catch(err) {
			throw new Error(err);
		}
	}

	sortOrderPriceMapProperties(orderPriceMap: OrderPriceMap) {
		// TODO: da ottimizzare
		if (orderPriceMap && orderPriceMap.vat) {
			const vat = orderPriceMap.vat;
			delete orderPriceMap.vat;
			orderPriceMap.vat = vat;
		}
		if (orderPriceMap && orderPriceMap.discount) {
			const discount = orderPriceMap.discount;
			delete orderPriceMap.discount;
			orderPriceMap.discount = discount;
		}
	}

	updateOrderPriceMap(order: OrderStateModel): OrderPriceMap {
		const orderPriceMap: OrderPriceMap = new OrderPriceMap();
		for (const k in order.header.price) {
			orderPriceMap[k] = {
				label: k,
				value: order.header.price[k],
				main: k === OrderEnum.PriceMapType.TOTAL ? true : false
			};
		}
		if (order.type === 'offer') {
			let sumFreeLine = 0;
			(order as OfferStateModel).free_line_list.forEach(freeLine => {
				sumFreeLine += this.returnPriceWithDiscount(freeLine.price, freeLine.discount_agent.value);
			});
			if (!orderPriceMap.freeLines) {
				orderPriceMap.freeLines = <OrderPriceMapItem>{
					label: OrderEnum.PriceMapType.FREE_LINES,
					value: 0
				};
			}
			orderPriceMap.freeLines.value = sumFreeLine;
			if (!orderPriceMap.total) {
				orderPriceMap.total = <OrderPriceMapItem>{
					value: 0
				};
			}
			orderPriceMap.total.value = orderPriceMap.total.value + sumFreeLine;
		} else {
			delete orderPriceMap.freeLines;
		}
		this.sortOrderPriceMapProperties(orderPriceMap);
		// Discount
		if (orderPriceMap.article.value) {
			let totalDiscountedPrice = orderPriceMap.article.value;
			order.header.discount?.forEach(discount => {
				totalDiscountedPrice = totalDiscountedPrice * ((100 - discount.value) / 100);
			});
			orderPriceMap.discount.value = orderPriceMap.article.value - totalDiscountedPrice;
			orderPriceMap.total.value -= orderPriceMap.discount.value;
		}
		return orderPriceMap;
	}

	returnTotalDiscountPercentage(article: ArticlePouchModel): number {
		let discount = 0;
		if (article.articlePrice.discount && article.articlePrice.discount.length > 0) {
			for (let i = 0; i < article.articlePrice.discount.length; i++) {
				discount += article.articlePrice.discount[i].value;
			}
		}
		if (
			article.discount_agent &&
			article.discount_agent.value
			// TODO: verificare se il salvataggio tiene già conto del type
			// && article.discount_agent.type === DiscountTypeEnum.percentage
		) {
			discount += article.discount_agent.value;
		}
		return this.round(discount);
	}

	/**
	 * Il metodo ritorna un valore dopo avervi aggiunto un'IVA percentuale. Questa viene tipicamente passata in maniera
	 * esplicita e differente per ogni articolo. In assenza del parametro `vat` è stato comunque mantenuto il fallback
	 * facente riferimento alle const dell'oggetto `ConfigurationCustomerOrder.vat`
	 *
	 * @param {number} price
	 * @param {number} [vat]
	 * @returns {number}
	 * @memberof UtilPriceService
	 */
	returnPriceWithVat(price: number, vat?: number): number {
		if (!vat && vat != 0) {
			vat = this.returnVat();
		}
		price = price + (price / 100) * vat;
		return this.round(price);
	}

	returnVat(param: OrderEnum.VatType = OrderEnum.VatType.ARTICLE) {
		const vat = this.user?.current_permission?.context_application
			? ConfigurationCustomerOrder.vat[param][this.user.current_permission.context_application]
			: ConfigurationCustomerOrder.vat[param].PUBLIC;
		return vat || 0;
	}

	returnPriceWithDiscount(price: number, discount: number): number {
		return this.round(price - (price / 100) * discount);
	}

	/**
	 * Restituisce la lista articoli con la proprieta calculate_price valorizzata tenendo conto di
	 * prezzo unitario, sconti, IVA
	 *
	 * @param {ArticlePouchModel[]} articleList
	 * @param {string} [division]
	 * @returns {ArticlePouchModel[]}
	 * @memberof UtilPriceService
	 */
	returnArticleListWithCalculatePriceForSingleItem(
		articleList: ArticlePouchModel[],
		division?: string
	): ArticlePouchModel[] {
		for (const article of articleList) {
			this.mapArticlePrice(article, division);
			article.calculate_price_for_single_item = this.retrievePriceWithDiscount(
				article.articlePrice.price,
				article.articlePrice.discount
			);
		}
		return articleList;
	}

	round(
		value: number,
		digitsNumber: number = ConfigurationCustomerPrice.decimalDigit
	) {
		return +value.toFixed(digitsNumber);
	}

	updateArticlePriceOrder(order: OrderPouchModel<any>) {
		let orderProductsTotalWithVat = 0;
		order.product_list.forEach(product => {
			const articleWithArticlePrice: ArticlePouchModel = _.cloneDeep(product);
			articleWithArticlePrice.articlePrice = {
				discount: articleWithArticlePrice.discount,
				price: articleWithArticlePrice.price,
				vat: articleWithArticlePrice.vat
			};
			// articleWithArticlePrice.input_quantity = articleWithArticlePrice.ordered_quantity;
			const articlePrice = this.retrieveTotalArticlePrice(articleWithArticlePrice);
			order.header.price.article += articlePrice;
			// Order products total with VAT
			const unitPriceWithDiscount = this.retrievePriceWithDiscount(
				articleWithArticlePrice.price,
				articleWithArticlePrice.discount
			);
			const unitPriceWithVat = this.returnPriceWithVat(unitPriceWithDiscount, articleWithArticlePrice.vat);
			orderProductsTotalWithVat += unitPriceWithVat * product.ordered_quantity;
		});
		this.vatPrice.vatArticle = this.round(orderProductsTotalWithVat - order.header.price.article);
	}

	updateTotalPriceOrder(order: OrderPouchModel<any>) {
		try {
			if (!order.header.price) {
				order.header.price = { total: 0 };
			}
			order.header.price.total =
				this.round(
					Object.keys(order.header.price).reduce((cumulative, current) =>
						current === OrderEnum.PriceMapType.TOTAL ?
						cumulative :
						(cumulative + order.header.price[current]),
						0
					)
				);
		} catch {
			throw Error(this.sentencecasePipe.transform(this.translate.instant('order.error.price_computation_error')));
		}
	}

	updateVatPriceOrder(order) {
		try {
			if (!order.header.price) {
				order.header.price = { vat: 0 };
			}
			order.header.price.vat =
				this.round(
					Object.keys(this.vatPrice).reduce((cumulative, current) =>
						cumulative + this.vatPrice[current],
						0
					)
				);
		} catch {
			throw Error(this.sentencecasePipe.transform(this.translate.instant('order.error.price_vat_error')));
		}
	}

	async updateShippingPriceOrder(
		order: OrderStateModel,
		organization: OrganizationPouchModel,
		commercialArea: CommercialAreaPouchModel = null
	) {
		// retrieve commercial area
		if (!commercialArea) {
			commercialArea = await this.utilAddressService.selectCommercialArea(order, organization);
		}
		if (!commercialArea) {
			throw Error(
				this.sentencecasePipe.transform(this.translate.instant('order.error.commercial_area_not_found'))
			);
		}

		// retrieve shipping charges list
		order.header.price.shipping = 0;
		if (
			ConfigurationCustomerShipping.isCostToApply(order) &&
			commercialArea['valid']
		) {
			try {
				this.shippingChargeList = await this.getShippingChargesTable(
					organization.organization_type,
					order.header.starting_warehouse,
					commercialArea.commercial_area
				);
			} catch {
				throw Error(
					this.sentencecasePipe.transform(
						this.translate.instant('order.error.shipping_charge_table_not_found')
					)
				);
			}
			let shippingCharge: ShippingChargePouchModel;
			let filteredShippingChargeList: ShippingChargePouchModel[] = this.shippingChargeList;
			const mainDivision = organization ? organization.division_list.find(div => div.is_main_of_list) : null;
			if (mainDivision) {
				for (const key of this.shippingChargeFilterList) {
					filteredShippingChargeList =
						filteredShippingChargeList.filter(charge => charge.carrier && charge[key] === mainDivision[key])
							.length > 0
							? filteredShippingChargeList.filter(
									charge => charge.carrier && charge[key] === mainDivision[key]
							  )
							: filteredShippingChargeList;
				}
				shippingCharge = this.selectPresentShippingCharge(filteredShippingChargeList);
			}
			if (_.isEmpty(shippingCharge)) {
				filteredShippingChargeList = this.shippingChargeList.filter(charge =>
					this.shippingChargeFilterList.every(key => !Object.prototype.hasOwnProperty.call(charge, key))
				);
				shippingCharge = this.selectPresentShippingCharge(filteredShippingChargeList);
			}
			if (_.isEmpty(shippingCharge)) {
				throw Error(
					this.sentencecasePipe.transform(this.translate.instant('order.error.price_shipping_error'))
				);
			}

			// compute price
			try {
				// build thresholds object
				const thresholdData = <{ [key: string]: ThresholdPouchModel[] }>{};
				Object.values(TableListEnum.Threshold.Type).forEach(chargeType => {
					thresholdData[chargeType] = shippingCharge.thresholds
						.filter(item => chargeType === item.type)
						.sort((a, b) => a.value - b.value);
				});
				// use thresholds object to update shipping price
				this.applyShippingCharge(order, thresholdData, shippingCharge.surcharge || 0);

				// update price related to additional services
				const priceShippingWithoutServices = order.header.price.shipping;
				if (order.header.additional_services) {
					for (const key of Object.keys(order.header.additional_services)) {
						order.header.price.shipping += this.getUpdateValue(
							order.header.additional_services[key].charge,
							order.header.additional_services[key].charge_type,
							priceShippingWithoutServices,
							order.header.additional_services[key].ordered_quantity || 1
						);
					}
				}

				// update VAT
				this.vatPrice.vatShipping =
					(order.header.price.shipping *
						ConfigurationCustomerOrder.vat.SHIPPING[
							this.user?.current_permission?.context_application || ContextApplicationItemCodeEnum.PUBLIC
						]) /
					100;
			} catch {
				throw Error(
					this.sentencecasePipe.transform(this.translate.instant('order.error.price_shipping_error'))
				);
			}
		}
	}

	applyShippingCharge(
		order: OrderStateModel,
		thresholdData: { [key: string]: ThresholdPouchModel[] },
		surcharge: number = 0
	) {
		let shippingPrice = 0;
		let applicableWeight = order.header.weight || 0;
		let formerWeightThreshold = 0;

		// K-type thresholds have to be evaluated first
		const chargeRuleList = thresholdData[TableListEnum.Threshold.Type.K].sort((a, b) => a.value - b.value);
		// find right rule corresponding to order weight...
		let chargeRule = chargeRuleList.find(rule => order.header.weight <= rule.value);
		if (!chargeRule) {
			chargeRule = chargeRuleList[chargeRuleList.length - 1];
		}
		// ...set new reference and update the residual non-charged weight...
		const weightSpan = chargeRule.value - formerWeightThreshold;
		formerWeightThreshold = chargeRule.value;
		applicableWeight -= weightSpan;
		// ...find price correspondent to the currently considered weight interval
		shippingPrice += chargeRule.charge || 0;
		// remove K-type thresholds from thresholds object
		delete thresholdData[TableListEnum.Threshold.Type.K];

		// for each entry in the thresholds object...
		for (const [key, value] of Object.entries(thresholdData)) {
			// ...sort the items by weight in ascending order and...
			const chargeRuleList = value.sort((a, b) => a.value - b.value);

			if (key === TableListEnum.Threshold.Type.F) {
				// ...for each of the items (rule for applying charge according to weight)...
				currentLoop: for (const chargeRule of chargeRuleList) {
					// ...check if non-charged weight is still present...
					if (applicableWeight <= 0) {
						break currentLoop;
					}
					// ...set new reference...
					const weightSpan = chargeRule.value - formerWeightThreshold;
					formerWeightThreshold = chargeRule.value;
					// ...find price correspondent to the currently considered weight interval...
					shippingPrice += Math.min(applicableWeight, weightSpan) * chargeRule.charge || 0;
					// ... and update the residual non-charged weight
					applicableWeight -= weightSpan;
					break;
				}
			}

			// if more than one option is present substitute the previous if block with switch one:
			/* switch (key) {
				case TableListEnum.Threshold.Type.F:
					// TODO: implement logic
					break;
			} */
		}
		// update shipping price in the order
		order.header.price.shipping += shippingPrice;
		order.header.price.shipping += surcharge;
	}

	selectPresentShippingCharge(shippingChargeList: ShippingChargePouchModel[]) {
		let shippingCharge: ShippingChargePouchModel;
		for (const charge of shippingChargeList) {
			if (charge && charge.begin_date) {
				shippingCharge =
					charge.begin_date > (shippingCharge?.begin_date || 0) && charge.begin_date < Date.now()
						? charge
						: shippingCharge;
			} else if (!shippingCharge?.begin_date) {
				shippingCharge = charge;
			}
		}
		return shippingCharge || null;
	}

	getShippingChargesTable(organization_type: string, starting_warehouse: string, destination: string) {
		const shippingChargesTableName =
			`table${ConfigurationCustomerAppStructure.noSqlDocSeparator}shipping_charges_${organization_type}_${starting_warehouse}_${destination}`;
		return this.pouchDbCommonsAdapter.basePouch
			.getDetail(shippingChargesTableName)
			.then((doc: HeaderTablePouchModel<ShippingChargePouchModel>) => {
				return doc.values;
			})
			.catch(err => {
				throw new Error(err.message);
			});
	}

	getUpdateValue(
		updateValue: number,
		valueType: PriceEnum.ValueType = PriceEnum.ValueType.ABSOLUTE,
		startingValue: number = null,
		numberOfUpdates: number = 1
	) {
		switch (valueType) {
			case PriceEnum.ValueType.PERCENTAGE:
				if (startingValue) {
					return ((startingValue * updateValue) / 100) * numberOfUpdates;
				}
				break;
			case PriceEnum.ValueType.ABSOLUTE:
			default:
				return updateValue * numberOfUpdates;
		}
		return null;
	}
}
