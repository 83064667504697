<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="false"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
		><ng-template tdDataTableTemplate="price" let-value="value" let-row="row">
			{{ value | currency: 'EUR':'symbol':'':'it' }}
		</ng-template>

		<ng-template tdDataTableTemplate="price_total" let-value="value" let-row="row">
			{{
				utilPriceService.returnPriceWithDiscount(row.price, row.discount_agent.value)
					| currency: 'EUR':'symbol':'':'it'
			}}
		</ng-template>

		<ng-template tdDataTableTemplate="actions" let-value="value" let-row="row" let-index="index">
			<button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="showActions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item (click)="onItemSelect.emit({ freeLine: row, index: index })">
					<mat-icon>edit</mat-icon>
					<span>{{ 'general.edit' | translate | sentencecase }}</span>
				</button>
				<button mat-menu-item (click)="selectDelete.emit(index)">
					<mat-icon>clear</mat-icon>
					<span>{{ 'general.delete' | translate | sentencecase }}</span>
				</button>
			</mat-menu>
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
