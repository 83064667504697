import { Injectable } from '@angular/core';
import { UntypedFormControl, AbstractControl, ValidationErrors } from '@angular/forms';

// model
import * as FormControlMultipurposeModel from '../../model/widget/form-control-multipurpose.model';
import * as FormControlMultipurposeEnum from '../../enum/widget/form-control-multipurpose.enum';

// misc
import { BehaviorSubject, Observable } from 'rxjs';
import _ from 'lodash';
import { AngularCoreUtilService } from './util.service';
import { FormControlMultipurposeSetDisabled } from '../../misc/form-control-multipurpose/form-control-multipurpose-set-disabled';

@Injectable({
	providedIn: 'root'
})
export class FormControlMultipurposeService {

	rootFormValueList: {
		id: string;
		value: BehaviorSubject<any>;
	}[] = [];

	constructor(
        private utilService: AngularCoreUtilService
    ) {}

	/**
	 * Crea o aggiorna un particolare BehaviorSubject appartenente a rootFormValueList
	 * @param id: identifica l'istanza speecifica nell'oggetto serializzato
	 * @param value: valore da usare per l'aggiornamento
	 */
	updateRootFormValueList(id: string, value: any) {
		const i = this.utilService.getElementIndex(this.rootFormValueList, 'id', id);
		if (i || i === 0) {
			this.rootFormValueList[i].value.next(value);
		} else {
			this.rootFormValueList.push({
				id: id,
				value: new BehaviorSubject<any>(value)
			});
		}
	}

	/**
	 * Restituisce l'Observable relativo al BehaviorSubject contenuto nell'oggetto
	 * rootFormValueList
	 *
	 * @param {string} id: identifica l'istanza speecifica nell'oggetto serializzato
	 * @returns {Observable<any>}
	 * @memberof FormControlMultipurposeService
	 */
	returnRootFormValue(id: string): Observable<any> {
		const i = this.utilService.getElementIndex(this.rootFormValueList, 'id', id);
		return i || i === 0 ? this.rootFormValueList[i].value.asObservable() : null;
	}

	/**
	 * Aggiunge ricorsivamente la proprietà form_id a tutti gli elementi di formFieldList.
	 * L'id permette di effettuare la subscribe allo specifico BehaviorSubject appartenente a
	 * rootFormValueList in qualsiasi istanza di form-control-multipurpose
	 *
	 * @param {FormControlMultipurposeModel.Item[]} formFieldList
	 * @param {string} form_id
	 * @returns {FormControlMultipurposeModel.Item[]}
	 * @memberof FormControlMultipurposeService
	 */
	returnFormFieldListWithRootFormId(formFieldList: FormControlMultipurposeModel.Item[], form_id: string): FormControlMultipurposeModel.Item[] {
		for (let i = 0; i < formFieldList.length; i++) {
			formFieldList[i].form_id = form_id;
			if (formFieldList[i].form_control_list) {
				formFieldList[i].form_control_list = this.returnFormFieldListWithRootFormId(
					formFieldList[i].form_control_list,
					form_id
				);
			}
		}
		return formFieldList;
	}

	/**
	 * Effettua il merge tra una mappa di form field e un oggetto strutturato coerentemente
	 * (che può rappresentare dati di default o provenienti da BE).
	 * Oltre ad attribuire ad ogni elemento l'eventuale value associato, salva formValue in tutti
	 * i form_value degli elementi annidati nella mappa.
	 *
	 * @param {FormControlMultipurposeModel.Item[]} formFieldList: mappa dei campi renderizzati in form-control-multipurpose
	 * @param {*} formValue: l'intero valore del root form
	 * @param {*} [formValuePartial]: valore parziale del root form corrispondente all'annidamento
	 * raggiunto dalla funzione
	 * @returns {FormControlMultipurposeModel.Item[]}
	 * @memberof FormControlMultipurposeService
	 */
	updateFormFieldListValue(
		formFieldList: FormControlMultipurposeModel.Item[],
		formValue: any,
		formValuePartial?: any
	): FormControlMultipurposeModel.Item[] {
		formFieldList = _.cloneDeep(formFieldList);
		// riassegnazione di formValuePartial (tipicamente assente nel primo trigger di questo metodo)
    // l'oggetto serve a valorizzare gli elementi di formFieldList escludendo annidamenti dei livelli superiori
    // di formValue, precedentemente processati
		formValuePartial = formValuePartial ? formValuePartial : formValue;
		for (let i = 0; i < formFieldList.length; i++) {
			// viene popolato form_value con il valore di formValue per agevolare i meccanismi
			// di validazione più complessi tipo FORM_ARRAY
			formFieldList[i].form_value = formValue;
			if (formValuePartial[formFieldList[i].name]) {
				// se presente una prop. di formValuePartial avente stesso nome del field in fase
				// di processo, viene assegnato il suo valore all'elemento di formFieldList processato
				formFieldList[i].value = formValuePartial[formFieldList[i].name];
				switch (formFieldList[i].type) {
          // l'operazione viene eseguita ricorsivamente in presenza di FORM_GROUP gruppi annidati
					case FormControlMultipurposeEnum.ItemType.FORM_GROUP:
						formFieldList[i].form_control_list = this.updateFormFieldListValue(
							formFieldList[i].form_control_list,
							formValue,
							formValuePartial[formFieldList[i].name]
						);
						break;
				}
			}
		}
		return formFieldList;
	}

	/**
	 * Setta i validatori dinamici dipendenti dal valore del form passato in ingresso secondo la
	 * logica espressa da set_validator_list.
	 * Per ognuno lancia updateValueAndValidity()
	 *
	 *
	 * @param {(FormGroup | FormArray)} form // modificato ad any
   * per problemi comparsi nell'uso del metodo in app parent, una volta trasferito in libreria
	 * @param {FormControlMultipurposeModel.Item} field
	 * @param {*} formValue
	 * @memberof FormControlMultipurposeService
	 */
   updateFormControlAccordingToType(form, field: FormControlMultipurposeModel.Item, formValue: any): Promise<void> {
    try {
      switch (field.type) {
        case FormControlMultipurposeEnum.ItemType.FORM_GROUP:
          for (const subField of field.form_control_list) {
            this.updateFormControlValidation(form.get(subField.name), subField, formValue);
          }
          break;
        case FormControlMultipurposeEnum.ItemType.FORM_ARRAY:
          for (let i = 0; i < form.controls.length; i++) {
            for (const subField of field.form_control_list) {
              this.updateFormControlValidation(form.controls[i].controls[subField.name], subField, formValue);
            }
          }
          break;
        case FormControlMultipurposeEnum.ItemType.DATE_RANGE:
          for (const date of Object.keys(FormControlMultipurposeEnum.DateRange)) {
            this.updateFormControlValidation(
              form.get(field.name).controls[FormControlMultipurposeEnum.DateRange[date]],
              field,
              formValue
            );
          }
          break;
        default:
          if (Object.prototype.hasOwnProperty.call(field, FormControlMultipurposeEnum.ItemCallback.SET_DISABLED)) {
            const set_disbled: boolean = field.set_disabled(formValue);
            form.get(field.name).setValue(
              set_disbled && field.clear_value_if_disabled ? null : form.get(field.name).value
            );
            if (set_disbled) {
              form.get(field.name).disable();
            } else {
              form.get(field.name).enable();
            }
          }
          this.updateFormControlValidation(form.get(field.name), field, formValue);
          break;
      }
      return;
    } catch(err) {
      console.log(err);
    }
	}

	/**
	 * Setta eventuali validatori dinamici presenti nella mappa, scatena updateValueAndValidity()
	 *
	 * @param {AbstractControl} formControl
	 * @param {FormControlMultipurposeModel.Item} field
	 * @param {*} formValue
	 * @memberof FormControlMultipurposeService
	 */
	updateFormControlValidation(formControl: AbstractControl, field: FormControlMultipurposeModel.Item, formValue: any) {
		if (Object.prototype.hasOwnProperty.call(field, FormControlMultipurposeEnum.ItemCallback.SET_VALIDATOR_LIST)) {
			formControl.setValidators(field.set_validator_list(formValue));
		}
		formControl.updateValueAndValidity();
	}

	/**
	 * Crea un FormControl a partire da un elemento FormControlMultipurposeModel.Item. E' possibile sovrascrivere
	 * field.value attraverso extraFieldValue (ma solo in caso questi sia diverso da undefined)
	 *
	 * @param {FormControlMultipurposeModel.Item} field
	 * @param {*} [extraFieldValue]
	 * @returns {FormControl}
	 * @memberof FormControlMultipurposeComponent
	 */
	returnNewFormControl(field: FormControlMultipurposeModel.Item, extraFieldValue?: any): UntypedFormControl {
		return new UntypedFormControl(
			extraFieldValue === undefined ? field.value : extraFieldValue,
			field.validator_list && field.validator_list.length > 0 ? field.validator_list : []
		);
	}

	/**
	 * Restituisce la mappa passata in input con le proprietà on_form_template settate a value.
	 * Utile per nascondere all'utente campi settati in default o a sistema.
	 *
	 * @param {FormControlMultipurposeModel.Item[]} formFieldList
	 * @param {string[]} propertyNameList
	 * @param {boolean} value
	 * @returns {FormControlMultipurposeModel.Item[]}
	 * @memberof FormControlMultipurposeService
	 */
	returnFormFieldListWithSomeOnFormTemplate(
		formFieldList: FormControlMultipurposeModel.Item[],
		propertyNameList: string[],
		value: boolean
	): FormControlMultipurposeModel.Item[] {
		formFieldList = _.cloneDeep(formFieldList);
		for (let i = 0; i < formFieldList.length; i++) {
			for (let n = 0; n < propertyNameList.length; n++) {
				if (propertyNameList[n] === formFieldList[i].name) {
					formFieldList[i].on_form_template = value;
					break;
				}
				if (formFieldList[i].form_control_list) {
					formFieldList[i].form_control_list = this.returnFormFieldListWithSomeOnFormTemplate(
						formFieldList[i].form_control_list,
						propertyNameList,
						value
					);
				}
			}
		}
		return formFieldList;
	}

	/**
	 * Restituisce la mappa passata in input dopo aver aggiunto a validator_list il contenuto di validatorList.
	 * Utile nei casi in cui la validazione è condizionata a qualche contesto particolare ma statica rispetto
	 * ai valori assunti dal form.
	 *
	 * @param {FormControlMultipurposeModel.Item[]} formFieldList
	 * @param {string[]} propertyNameList
	 * @param {((control: AbstractControl) => ValidationErrors)[]} validatorList
	 * @returns {FormControlMultipurposeModel.Item[]}
	 * @memberof FormControlMultipurposeService
	 */
	returnFormFieldListWithSomeValidatorList(
		formFieldList: FormControlMultipurposeModel.Item[],
		propertyNameList: string[],
		validatorList: ((control: AbstractControl) => ValidationErrors)[]
	): FormControlMultipurposeModel.Item[] {
		formFieldList = _.cloneDeep(formFieldList);
		for (let i = 0; i < formFieldList.length; i++) {
			for (let n = 0; n < propertyNameList.length; n++) {
				if (propertyNameList[n] === formFieldList[i].name) {
					if (!formFieldList[i].validator_list) {
						formFieldList[i].validator_list = [];
					}
					validatorList.forEach(v => {
						formFieldList[i].validator_list.push(v);
					});
					break;
				}
				if (formFieldList[i].form_control_list) {
					formFieldList[i].form_control_list = this.returnFormFieldListWithSomeValidatorList(
						formFieldList[i].form_control_list,
						propertyNameList,
						validatorList
					);
				}
			}
		}
		return formFieldList;
	}

	/**
	 * Restituisce la mappa passata in input dopo aver settato column_width degli elementi selezionati
	 *
	 * @param {FormControlMultipurposeModel.Item[]} formFieldList
	 * @param {string[]} propertyNameList
	 * @param {FormControlMultipurposeModel.ColumnWidth} columnWidth
	 * @returns {FormControlMultipurposeModel.Item[]}
	 * @memberof FormControlMultipurposeService
	 */
	returnFormFieldListWithSomeColumnWidth(
		formFieldList: FormControlMultipurposeModel.Item[],
		propertyNameList: string[],
		columnWidth: FormControlMultipurposeModel.ColumnWidth
	): FormControlMultipurposeModel.Item[] {
		formFieldList = _.cloneDeep(formFieldList);
		for (let i = 0; i < formFieldList.length; i++) {
			for (let n = 0; n < propertyNameList.length; n++) {
				if (propertyNameList[n] === formFieldList[i].name) {
					formFieldList[i].column_width = columnWidth;
					break;
				}
				if (formFieldList[i].form_control_list) {
					formFieldList[i].form_control_list = this.returnFormFieldListWithSomeColumnWidth(
						formFieldList[i].form_control_list,
						propertyNameList,
						columnWidth
					);
				}
			}
		}
		return formFieldList;
	}

  returnFormFieldListWithAllDisabled(
		formFieldList: FormControlMultipurposeModel.Item[]
	): FormControlMultipurposeModel.Item[] {
		formFieldList = _.cloneDeep(formFieldList);
		for (let i = 0; i < formFieldList.length; i++) {
      formFieldList[i].set_disabled = formValue => FormControlMultipurposeSetDisabled.disabledAlways();
      if (formFieldList[i].form_control_list) {
        formFieldList[i].form_control_list = this.returnFormFieldListWithAllDisabled(formFieldList[i].form_control_list);
      }
		}
		return formFieldList;
	}

}
