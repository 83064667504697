import { Component, EventEmitter, OnInit, Output, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectionTypeEnum } from '../../enum/attachment-list-manager.enum';
import { AttachmentResolverService } from '../../service/structure/attachment-resolver.service';
import { UploadService } from '../../service/upload/upload.service';
import { UtilMatSnackBar } from '../../service/util/mat-snack-bar.service';

export interface PreviewFile extends File {
	preview?: string | ArrayBuffer;
}

@Component({
	selector: 'attachment-resolver',
	templateUrl: './attachment-resolver.component.html',
	styleUrls: ['./attachment-resolver.component.scss']
})
export class AttachmentResolverComponent implements OnInit, OnDestroy {
	@Input() dropAreaEnabled: boolean = true;
	@Input() multiple: boolean;
	@Input() acceptedType: string;
	@Input() fileEmitterType: 'files' | 'filesPreview' = 'filesPreview';
	@Input() selectionType: SelectionTypeEnum;
	@Output() fileEmitter: EventEmitter<any> = new EventEmitter();

	@ViewChild('folderInput') folderUpload: ElementRef<HTMLInputElement>;

	files: PreviewFile[] = [];
	filesPreview = [];
	emitCheck = false;
	private empty: Subscription;
	public selectionTypeEnum = SelectionTypeEnum;

	public selectedFiles: File[];

	constructor(
		private attachmentResolverService: AttachmentResolverService,
		public uploadService: UploadService,
		private utilMatSnackBar: UtilMatSnackBar
	) {
		this.empty = this.attachmentResolverService.emptyAR$.subscribe(res => {
			this.files = [];
			this.filesPreview = [];
			this.selectedFiles = [];
		});
	}


	ngOnInit() {}

	ngOnDestroy() {
		this.empty.unsubscribe();
	}

	changeFiles(files: FileList) {
		/** Questo array contiene i nomi di file nascosti di sistema da escludere dal processo di upload **/
		const systemFilesToExclude = [
			'.ds_store',
			'.appdata',
			'.caches',
			'.crashreporter',
			'.trashed',
			'.volumes',
			'com.apple.finder.plist',
			'desktop.db',
			'thumbs.db',
			'hiberfil.sys',
		]
		const filesArray = Array.from(files).filter(file => !systemFilesToExclude.includes(file.name.toLowerCase()));
		this.selectedFiles = filesArray;

		if (!this.multiple) {
			this.files = [];
			this.filesPreview = [];
		}
		filesArray.forEach((file: File, i) => {
			if (file.type) {
				this.files.push(file);
				if (filesArray.length === i + 1) {
					// se true scatena l'emit in readThisPreview()
					this.emitCheck = true;
				}
				this.readThisPreview(file);
			} else {
				this.utilMatSnackBar.errorWith('saep_ict_angular_core.attachment_resolver.message.error_with.only_file_allowed');
			}
		});
	}

	onFolderSelected(event) {
		// if(event.target.files.length === 0) {
		// 	this.utilMatSnackBar.message('saep_ict_angular_core.attachment_resolver.message.empty_folder_ignored', 5000,'ok');
		// }
		this.changeFiles(event.target.files);
		this.folderUpload.nativeElement.value = null;
	}


	readThisPreview(inputValue: PreviewFile): void {
		const myReader: FileReader = new FileReader();
		myReader.readAsDataURL(inputValue);
		myReader.onload = _event => {
			// genera la preview di un file type image/*
			if (inputValue.type.includes('image/')) {
				inputValue.preview = myReader.result;
			}
			this.filesPreview.push({
				data: myReader.result,
				name: inputValue.name
			});
			if (this.emitCheck) {
				this.emitFile();
				this.emitCheck = false;
			}
		};
	}

	deleteFile(index: number) {
		this.uploadService.cancelUpload(index);
		this.files.splice(index, 1);
		this.filesPreview.splice(index, 1);
		this.emitFile();
	}

	emitFile() {
		this.fileEmitter.emit(this[this.fileEmitterType]);
		if(this.folderUpload) {
			this.folderUpload.nativeElement.value = null;
		}
	}

	eventWrapperFolderInput() {
		//this.utilMatSnackBar.message('saep_ict_angular_core.attachment_resolver.message.empty_folder_ignored', 5000,'ok');
		this.folderUpload.nativeElement.click();
	}
}