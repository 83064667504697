import { Validators } from '@angular/forms';
import { OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import {
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum,
	FormControlMultipurposeSetValidator,
	FormControlMultipurposeSetDisabled,
	FormValidatorCustom
} from '@saep-ict/angular-core';

export const creationFieldMapContextCodeOrganizationPartialTaxData: FormControlMultipurposeModel.Item[] = [
  {
    name: 'tax_data',
    label: 'organization.field.tax_data',
    on_form_template: true,
    type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
    form_control_list: [
      {
        name: 'tax_code',
        label: 'organization.field.tax_code',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6,
        set_validator_list: formValue =>
          FormControlMultipurposeSetValidator.requiredIfSomeFieldIsSomeValue(
            formValue,
            {
              pathToProperty: 'organization_type',
              propertyValue: OrganizationTypeEnum.PRIVATE
            },
            [Validators.minLength(16), Validators.maxLength(16)]
          ),
        set_disabled: formValue =>
          FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
            pathToProperty: 'organization_type',
            propertyValue: OrganizationTypeEnum.COMPANY
          }),
        clear_value_if_disabled: true
      },
      {
        name: 'vat_number',
        label: 'organization.field.vat_number',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6,
        set_validator_list: formValue =>
          FormControlMultipurposeSetValidator.requiredIfSomeFieldIsSomeValue(
            formValue,
            {
              pathToProperty: 'organization_type',
              propertyValue: OrganizationTypeEnum.COMPANY
            },
            [Validators.minLength(11), Validators.maxLength(11), FormValidatorCustom.checkLuhn]
          ),
        set_disabled: formValue =>
          FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
            pathToProperty: 'organization_type',
            propertyValue: OrganizationTypeEnum.PRIVATE
          }),
        clear_value_if_disabled: true
      },
      {
        name: 'pec',
        label: 'organization.field.pec',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6,
        set_validator_list: formValue =>
          FormControlMultipurposeSetValidator.requiredIfSomeFieldIsSomeValue(
            formValue,
            {
              pathToProperty: 'organization_type',
              propertyValue: OrganizationTypeEnum.COMPANY
            },
            [FormValidatorCustom.email]
          )
      },
      {
        name: 'sdi_code',
        label: 'organization.field.sdi',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6,
        set_validator_list: formValue =>
          FormControlMultipurposeSetValidator.requiredIfSomeFieldIsSomeValue(
            formValue,
            {
              pathToProperty: 'organization_type',
              propertyValue: OrganizationTypeEnum.COMPANY
            },
            [Validators.minLength(6), Validators.maxLength(7)]
          ),
        set_disabled: formValue =>
          FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
            pathToProperty: 'organization_type',
            propertyValue: OrganizationTypeEnum.PRIVATE
          })
      }
    ]
  }
];
