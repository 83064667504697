import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { ContextApplicationItemCodeEnum } from '../../enum/permission/context-application-item-code.enum';
import { OpportunityEnum } from '../../enum/opportunity.enum';
import { CommentStateModel } from '../state/comment-state.model';
import { OrganizationStateModel } from '../state/organization-state.model';

export interface OpportunityPouchModel extends BasePouchModel {
	code_item: string;

	// organization
	organization?: OrganizationStateModel;
	organization_id?: string;

	// organization contact that is the referent
	contact_id: string;
	contact_name: string;

	// assegnee - owner of the opportunity
	assegnee_user_id: string;
	assegnee_user_name: string;
	assegnee_avatar?: string;
	assegnee_full_name?: string;

	// comments
	comment_list?: CommentStateModel[];

	// logs
	log_list?: OpportunityLogModel[];

	/**
	 * Opportunity information
	 */
	// Actual revenue amount for the opportunity
	actual_value?: number;
	// Type a value between 0 and 1,000,000,000,000 to indicate the lead's potential available budget.
	budget_amount?: number;
	// Type a number from 0 to 100 that represents the likelihood of closing the opportunity. This can aid the sales team in their efforts to convert the opportunity in a sale.
	close_probability?: number;
	// Date and time when the record was created
	date_creation: number;
	// expected closing date of the opportunity to help make accurate revenue forecasts
	estimated_close_date?: number;
	// id client da google analytics
	client_id?: string;
	// real closing date of the opportunity
	close_date?: number;
	// determinate if there is an offer attached to the opportunity
	has_offer?: boolean;
	// Message of the opportunity inserted by the operator
	message?: string;
	// Date and time when the record was modified
	modified_on?: number;
	// Type of the opportunity inserted by the operator
	opportunity_type?: string;
	// Origin (id) of the opportunity
	origin_id?: string;
	// Origin (name) of the opportunity
	origin_name?: string;
	// Rating calcolato da altri parametri che indica il grado di interesse dell'opportunità
	project_interest?: number;
	// date and time of the prospecting follow-up meeting with the lead
	schedule_followup_prospect?: number;
	// Shows the opportunity statususing specific references in each context. Won and lost opportunities are read-only and can't be edited until they are reactivated.
	status_code?: string;
	// Shows the ID of the workflow step.
	step_id: string;
	// Shows the current phase in the sales pipeline for the opportunity. This is updated by a workflow.
	step_name?: string;
	// title identifier of the opportunity
	title: string;
	// Topic of the opportunity inserted by the operator
	topic?: string;
	//
	reference?: string;
	//
	order_into_step?: number;
	// lead, prospect, client
	level?: OpportunityEnum.Level;
	// id utente SAEP nel caso sia già presente a sistema
	user_id?: string;
	// indica se proviene da una form automatica o meno. Se sì, non è gestibile (manageable: false)
	manageable?: boolean;
	// indica da dove ha avuto origine la richiesta
	context_application?: ContextApplicationItemCodeEnum;
	// da chi è stata inserita
	added_by?: string;
}

export interface OpportunityLogModel {
	date_creation: number;
	description: string;
}
