<div class="wrapper">
	<ng-container *ngIf="_configuration">
		<div class="heading">
			<div class="row-title-wrapper">
				<span>Brand</span>
			</div>
			<div class="values-wrapper">
				<ng-container *ngFor="let h of _configuration.data.summary; let isFirst = first">
				  <span class="value" *ngIf="!isFirst && h.trend">{{ h.trend.description ?? "%" }} </span>
				  <span class="value" *ngIf="h.total">
					{{ h.total.description }}
					{{ _configuration.data.unit[_configuration.unit] }}
				  </span>
				</ng-container>
			  </div>
		</div>
	</ng-container>

	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
		<mat-nested-tree-node *matTreeNodeDef="let node">
			<div class="mat-tree-node">
				<div class="row-title-wrapper">
					<button
						*ngIf="
							(node.article_list && node.article_list.length > 0) ||
							(node.category_list && node.category_list.length > 0)
						"
						mat-icon-button
						matTreeNodeToggle
						[attr.aria-label]="'Toggle ' + node._id"
					>
						<mat-icon class="mat-icon-rtl-mirror">
							{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
						</mat-icon>
					</button>
					{{ utilTranslateService.getTranslationFromLanguage(node.language_list).description }}
				</div>
				<ng-container
					*ngTemplateOutlet="statisticTemplate; context: { statisticList: node.statisticList }"
				></ng-container>
			</div>
			<div [class.mat-tree-invisible]="!treeControl.isExpanded(node)" role="group">
				<ng-container matTreeNodeOutlet>
					<div *ngFor="let article of node.article_list" class="mat-tree-node">
						<div class="row-title-wrapper">
							{{ article[articleCodeTypeToShow] }}
							{{
								utilTranslateService.getTranslationFromLanguage(
									article.articleDescription.language_list
								).description
							}}
						</div>
						<ng-container
							*ngTemplateOutlet="statisticTemplate; context: { statisticList: article.statisticList }"
						></ng-container>
					</div>
				</ng-container>
			</div>
		</mat-nested-tree-node>
	</mat-tree>

	<ng-template #statisticTemplate let-statisticList="statisticList">
		<div class="values-wrapper">
			<ng-container *ngFor="let s of statisticList">
				<span *ngIf="s.trend" [ngClass]="s.trend[_configuration.unit] < 0 ? 'red' : 'green'">
					{{ s.trend[_configuration.unit] | utilPriceReturnItemValueFormattedPipe: 1 }}
				</span>
				<span>
					{{ s.total[_configuration.unit] | utilPriceReturnItemValueFormattedPipe: 0 }}
				</span>
			</ng-container>
		</div>
	</ng-template>
</div>
