<mat-card>
	<!-- Legal information -->
	<div class="section mt-20" *ngIf="displayLegalInformation">
		<p class="title">{{ 'general.information.legal' | translate | sentencecase }}</p>
		<organization-legal-information></organization-legal-information>
	</div>

	<mat-divider></mat-divider>

	<!-- Personal Data  -->
	<ng-container *ngIf="displayPersonalData">
		<div class="section mt-50 mt-30">
			<p class="title">{{ 'general.information.personal' | translate | sentencecase }}</p>
			<div class="wrapper">
				<div class="row-wrapper">
					<div class="row-item">
						<div class="header">
							{{ 'organization.field.input_registration_code' | translate | sentencecase }}
						</div>
						<div class="content" *ngIf="organization.input_registration_code; else noData">
							{{ organization.input_registration_code }}
						</div>
					</div>
					<div class="row-item">
						<div class="header">{{ 'organization.field.date_of_birth' | translate | sentencecase }}</div>
						<div class="content" *ngIf="organization.date_of_birth; else noData">
							{{ organization.date_of_birth | date: 'dd/MM/yyyy' }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #noData>
			<span class="content">-</span>
		</ng-template>
	</ng-container>

	<mat-divider></mat-divider>

	<!-- Useful links -->
	<!-- <div class="section mt-50 mt-30">
		<p class="title">{{ 'general.useful_links' | translate | sentencecase }}</p>
		<div class="wrapper">
			<div class="row-wrapper">
				<div class="row-item">
					<div class="header">
						{{ 'organization.field.url_documents' | translate | sentencecase }}
					</div>
					<div class="content">
						<a
							[href]="this.cmrLinks.documents"
							target="_blank"
							*ngIf="this.cmrLinks.documents; else noData"
							>{{ this.cmrLinks.documents }}</a
						>
					</div>
				</div>

				<div class="row-item">
					<div class="header">
						{{ 'organization.field.url_tickets' | translate | sentencecase }}
					</div>
					<div class="content">
						<a [href]="this.cmrLinks.tickets" target="_blank" *ngIf="this.cmrLinks.tickets; else noData">{{
							this.cmrLinks.tickets
						}}</a>
					</div>
				</div>
			</div>

			<div class="row-wrapper">
				<div class="row-item">
					<div class="header">
						{{ 'organization.field.url_recipes' | translate | sentencecase }}
					</div>
					<div class="content">
						<a [href]="this.cmrLinks.recipes" target="_blank" *ngIf="this.cmrLinks.recipes; else noData">{{
							this.cmrLinks.recipes
						}}</a>
					</div>
				</div>

				<div class="row-item">
					<div class="header">
						{{ 'organization.field.url_sales' | translate | sentencecase }}
					</div>
					<div class="content">
						<a [href]="this.cmrLinks.sales" target="_blank" *ngIf="this.cmrLinks.sales; else noData">{{
							this.cmrLinks.sales
						}}</a>
					</div>
				</div>
			</div>

			<ng-template #noData>
				<span class="content">-</span>
			</ng-template>
		</div>
	</div> -->

	<!-- <div class="section">
		<p class="title">{{ 'general.useful_links' | translate | sentencecase }}</p>
		<div class="row">
			<div class="info-row col-12 col-lg-6">
				<div class="header">
					{{ 'organization.field.url_documents' | translate | sentencecase }}
				</div>
				<div class="content rompi">
					<a
						class="break-all"
						[href]="this.cmrLinks.documents"
						target="_blank"
						*ngIf="this.cmrLinks.documents; else noData"
						>{{ this.cmrLinks.documents }}</a
					>
				</div>
			</div>
			<div class="info-row col-12 col-lg-6">
				<div class="header">
					{{ 'organization.field.url_tickets' | translate | sentencecase }}
				</div>
				<div class="content rompi">
					<a
						class="break-all"
						[href]="this.cmrLinks.tickets"
						target="_blank"
						*ngIf="this.cmrLinks.tickets; else noData"
						>{{ this.cmrLinks.tickets }}</a
					>
				</div>
			</div>
			<div class="info-row col-12 col-lg-6">
				<div class="header">
					{{ 'organization.field.url_recipes' | translate | sentencecase }}
				</div>
				<div class="content rompi">
					<a
						class="break-all"
						[href]="this.cmrLinks.recipes"
						target="_blank"
						*ngIf="this.cmrLinks.recipes; else noData"
						>{{ this.cmrLinks.recipes }}</a
					>
				</div>
			</div>
			<div class="info-row col-12 col-lg-6">
				<div class="header">
					{{ 'organization.field.url_sales' | translate | sentencecase }}
				</div>
				<div class="content rompi">
					<a
						class="break-all"
						[href]="this.cmrLinks.sales"
						target="_blank"
						*ngIf="this.cmrLinks.sales; else noData"
						>{{ this.cmrLinks.sales }}</a
					>
				</div>
			</div>

			<ng-template #noData>
				<span class="content">-</span>
			</ng-template>
		</div>
	</div> -->

	<ng-container *ngIf="displayUtilLinks">
		<!-- Business information -->
		<div class="section mt-50 mt-30">
			<p class="title">{{ 'general.information.business' | translate | sentencecase }}</p>

			<div class="wrapper">
				<div class="row-wrapper">
					<div class="row-item">
						<div class="header">
							{{ 'organization.field.revenue_year' | translate | sentencecase }}
						</div>
						<div class="content">
							{{
								organization.crm && organization.crm.revenue_year ? organization.crm.revenue_year : '-'
							}}
						</div>
					</div>

					<div class="row-item">
						<div class="header">
							{{ 'organization.field.url_linkedin' | translate | sentencecase }}
						</div>
						<div class="content">
							<a
								[href]="organization.crm.url_linkedin"
								target="_blank"
								*ngIf="organization.crm && organization.crm.url_linkedin; else noData"
								>{{ organization.crm.url_linkedin }}</a
							>
						</div>
					</div>
				</div>

				<div class="row-wrapper">
					<div class="row-item">
						<div class="header">
							{{ 'organization.field.organization_rating' | translate | sentencecase }}
						</div>
						<div class="content">
							{{
								organization.crm && organization.crm.organization_rating
									? organization.crm.organization_rating
									: '-'
							}}
						</div>
					</div>

					<div class="row-item">
						<div class="header">
							{{ 'organization.field.url_website' | translate | sentencecase }}
						</div>
						<div class="content">
							<a
								[href]="organization.crm.url_website"
								target="_blank"
								*ngIf="organization.crm && organization.crm.url_website; else noData"
								>{{ organization.crm.url_website }}</a
							>
						</div>
					</div>
				</div>

				<ng-template #noData>
					<span class="content">-</span>
				</ng-template>
			</div>

			<!-- <div class="row">
				<div class="info-row col-12 col-lg-6">
					<div class="header">
						{{ 'organization.field.revenue_year' | translate | sentencecase }}
					</div>
					<div class="content">
						{{ organization.crm && organization.crm.revenue_year ? organization.crm.revenue_year : '-' }}
					</div>
				</div>
				<div class="info-row col-12 col-lg-6">
					<div class="header">
						{{ 'organization.field.url_linkedin' | translate | sentencecase }}
					</div>
					<div class="content rompi">
						<a
							class="break-all"
							[href]="organization.crm.url_linkedin"
							target="_blank"
							*ngIf="organization.crm && organization.crm.url_linkedin; else noData"
							>{{ organization.crm.url_linkedin }}</a
						>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="info-row col-12 col-lg-6">
					<div class="header">
						{{ 'organization.field.organization_rating' | translate | sentencecase }}
					</div>
					<div class="content">
						{{
							organization.crm && organization.crm.organization_rating
								? organization.crm.organization_rating
								: '-'
						}}
					</div>
				</div>
				<div class="info-row col-12 col-lg-6">
					<div class="header">
						{{ 'organization.field.url_website' | translate | sentencecase }}
					</div>
					<div class="content rompi">
						<a
							class="break-all"
							[href]="organization.crm.url_website"
							target="_blank"
							*ngIf="organization.crm && organization.crm.url_website; else noData"
							>{{ organization.crm.url_website }}</a
						>
					</div>
				</div>
			</div> -->
		</div>
	</ng-container>
	<mat-divider *ngIf="displayPaymentMethod"></mat-divider>
	<ng-container>
		<!-- Payment methods -->
		<div class="section mt-50 mt-30" *ngIf="displayPaymentMethod">
			<p class="title">{{ 'payment_method.list' | translate | sentencecase }}</p>
			<td-data-table
				*ngIf="paymentListPartial?.length; else noPaymentList"
				[data]="paymentListPartial"
				[columns]="companyDetailPayment"
			>
			</td-data-table>
			<ng-template #noPaymentList>
				<div>
					<span class="no-data">
						{{ 'payment_method.not_found' | translate | sentencecase }}
					</span>
				</div>
			</ng-template>
		</div>
	</ng-container>
</mat-card>
