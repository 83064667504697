import { PageEvent } from "@angular/material/paginator";
import { ITdDataTableSortChangeEvent } from "@covalent/core/data-table";
import { ITdDataTableColumnCustom } from "../structure/list-structure.model";

export interface LocalListHandlerBaseLocalPagination extends PageEvent {
	totalFilteredItemCount?: number;
}

export interface LocalListHandlerBaseModel<D, F = any> extends ListSetting<F>{
	data: D[];
	dataSubset?: D[];
}

export interface LocalListHandlerBaseModelFilters<D> {
	localSearchText: {
		value: string | null;
		key_list: string[];
	};
	customFilters?: D
}

export interface LocalListHandlerBaseApplyFilterParam {
	pagination?: PageEvent;
	sort?: ITdDataTableSortChangeEvent;
	text?: string;
}

export interface ListSetting<T = any> {
	pageName?: string;
	filters?: LocalListHandlerBaseModelFilters<T>;
	pagination?: LocalListHandlerBaseLocalPagination;
	sort?: ITdDataTableSortChangeEvent;
  sortRemapObject?: any;
  languageKey?: string;
  columnList?: ITdDataTableColumnCustom[];
}
