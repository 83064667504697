import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {BaseStateModel, SubscribeManagerService} from '@saep-ict/angular-core';
import {Observable} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';
import {ViewTypeEnum} from '../../../../enum/view-type.enum';
import {ConfigurationViewModel} from '../../../../model/configuration.model';
import {StateFeature} from '../../../../state';
import {ICardSliderConfiguration, ItemType} from '../../../../widget/b2c/article/b2c-article-item/card-slider.models';
import * as ConfigurationCustomerReference
	from '../../../../constants/configuration-customer/reference/reference.constant';
import {JumbotronConfig} from '../../../../widget/jumbotron/jumbotron.component';
import {
	ArticlePouchModel,
	Category,
	CategoryMap,
	DivisionPouchModel,
	LocalListHandlerBaseModel
} from '@saep-ict/pouch_agent_models';
import {ArticleActionEnum} from '../../../../state/article/article.actions';
import {ArticleListFilterModel} from '../../../../service/pouch-db/filter/article-list-filter.model';
import {UtilPriceService} from '../../../../service/util/util-price.service';
import {AppUtilService} from '../../../../service/util/app-util.service';
import {TranslateService} from '@ngx-translate/core';
import {OrganizationStateModel, ROUTE_URL} from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [SubscribeManagerService]
})
export class HomeComponent implements AfterViewInit, OnDestroy {
	@ViewChild('mapContainer') gmap: ElementRef;

	public viewTypeEnum = ViewTypeEnum;
	public ItemType = ItemType;

	jumbotronValue: JumbotronConfig = {
		title: `theme.jumbotron.title`,
		subtitle: 'theme.jumbotron.subtitle',
		labelButton: 'theme.jumbotron.labelButton',
		url: `${ROUTE_URL.catalog}/category_ORD_80`, // da RECUPERARE da couch tramite configurazione in evidenza
		backgroundImage: 'theme.jumbotron.backgroundImage', // da configurare manualmente
		hasBackdrop: true
	};

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configuration: BaseStateModel<ConfigurationViewModel>;

	configurationReferenceDev = ConfigurationCustomerReference.development;
	configurationReferenceCustomer = ConfigurationCustomerReference.customer;

	lat = `${this.translateService.instant(this.configurationReferenceCustomer.position.LAT)}`;
	long = `${this.translateService.instant(this.configurationReferenceCustomer.position.LONG)}`;

	// map: google.maps.Map;

	// coordinates = new google.maps.LatLng(+this.lat, +this.long);
	// mapOptions: google.maps.MapOptions = {
	// 	center: this.coordinates,
	// 	zoom: 10,
	// 	disableDefaultUI: true,
	// 	// scrollwheel: false,
	// 	// disableDoubleClickZoom: true,
	// 	// draggable: false,
	// 	gestureHandling: 'cooperative'
	// };

	// marker = new google.maps.Marker({
	// 	position: this.coordinates
	// });

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);

	categoryList$: Observable<BaseStateModel<CategoryMap, ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	categoryHighlightList: Category[] = [];

	listPageBaseData = <LocalListHandlerBaseModel<ArticlePouchModel>>{
		data: []
	};

	config: ICardSliderConfiguration;

	socialMediaList = Object.values(ConfigurationCustomerReference.customer.socialMedia);

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private utilPriceService: UtilPriceService,
		private utilService: AppUtilService,
		private translateService: TranslateService
	) {
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res && res.data ? res.data : null;
		});
		this.configuration$.pipe(take(1)).subscribe(res => {
			this.configuration = res ? res : null;
		});

		this.subscribeManagerService.populate(
			this.subscribeMainPipeData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'main-pipe-data'
		);
		this.subscribeManagerService.populate(this.subscribeCategoryList().subscribe(), 'category-list-subscription');
	}

	ngAfterViewInit() {
		this.mapInitializer();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeMainPipeData(): Observable<ArticlePouchModel[]> {
		return this.articleList$.pipe(
			filter((store: BaseStateModel<ArticlePouchModel[]>) => !!(store && store.data)),
			map((store: BaseStateModel<ArticlePouchModel[]>) => {
				switch (store.type) {
					case ArticleActionEnum.ERROR: {
						throw new Error(ArticleActionEnum.ERROR);
					}
					case ArticleActionEnum.UPDATE: {
						const mainDivision =
							this.organization && this.organization.division_list
								? this.utilService.returnIsMainOfList<DivisionPouchModel>(
										this.organization.division_list
								  )
								: null;
						this.listPageBaseData.data = this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem(
							store.data.filter(i => i.articleDescription.visible && i.articleDescription.is_highlighted),
							mainDivision ? mainDivision.division : null
						);
						break;
					}
				}
				return this.listPageBaseData.data;
			})
		);
	}

	subscribeCategoryList() {
		return this.categoryList$.pipe(
			filter(res => !!(res && res.data)),
			tap(res => {
				this.categoryHighlightList = res && res.data && res.data.is_highlighted ? res.data.is_highlighted : [];
				if (this.categoryHighlightList.length > 0) {
					this.config = {
						data: this.categoryHighlightList,
						medias: {
							sm: 1,
							md: 2,
							lg: 3
						},
						animation: {
							animationDuration: '.8s',
							easing: 'ease-in-out',
							loop: true
						}
					};
				}
			})
		);
	}

	mapInitializer() {
		// this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
		// this.marker.setMap(this.map);
	}

	getArray(n: number, startFrom: number = 0): number[] {
		return [...Array(n).keys()].map(i => i + startFrom);
	}

	clickCallToAction() {
		window.location.href = `mailto:${this.translateService.instant(this.configurationReferenceCustomer.EMAIL)}`;
	}
}
