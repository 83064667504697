// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ContextCodeAssociation } from '@saep-ict/angular-spin8-core';

export enum ContextCodeAssociationActionEnum {
	RESET = '[context-code-association] Reset',
	ERROR = '[context-code-association] Error',
	UPDATE = '[context-code-association] Update',
	LOAD = '[context-code-association] Load',
	SAVE = '[context-code-association] Save',
	SAVE_SUCCESS = '[context-code-association] Save Success',
	DELETE_ITEM = '[context-code-association] Delete Item',
	DELETE_ITEM_SUCCESS = '[context-code-association] Delete Item Success'
}

export namespace ContextCodeAssociationStateAction {
	export const reset = createAction(ContextCodeAssociationActionEnum.RESET);
	export const error = createAction(ContextCodeAssociationActionEnum.ERROR, props<BaseStateModel<any>>());
	export const update = createAction(
		ContextCodeAssociationActionEnum.UPDATE,
		props<BaseStateModel<ContextCodeAssociation>>()
	);
	export const load = createAction(ContextCodeAssociationActionEnum.LOAD, props<BaseStateModel<{ _id: string }>>());
	export const save = createAction(
		ContextCodeAssociationActionEnum.SAVE,
		props<BaseStateModel<ContextCodeAssociation>>()
	);
	export const saveSuccess = createAction(
		ContextCodeAssociationActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<ContextCodeAssociation>>()
	);
	export const deleteItem = createAction(
		ContextCodeAssociationActionEnum.DELETE_ITEM,
		props<BaseStateModel<ContextCodeAssociation>>()
	);
	export const deleteItemSuccess = createAction(
		ContextCodeAssociationActionEnum.DELETE_ITEM_SUCCESS,
		props<BaseStateModel<null>>()
	);
}
