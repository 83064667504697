import { ITdDataTableColumnCustom } from "@saep-ict/pouch_agent_models";

export const baseColumns: ITdDataTableColumnCustom[] = [
    {
        name: 'code_item',
        label: 'Codice',
        width: 80
    },
    {
        name: 'description',
        label: 'Descrizione'
    }
];