export const development = {
    ADDRESS: 'A. Sforza, 87 20141 Milano (MI)',
    ADDRESS_SHORT: 'A. Sforza, 87',
    POSTAL_CODE: '20141',
    CITY: 'Milano',
    PROVINCE: 'MI',
    COUNTRY: 'Italy',
    WEBSITE: 'www.saep.it',
    VAT: 'IT 03228490136',
    EMAIL: 'info@saep.it',
    NAME_LONG: 'SAEP ICT Engineering s.r.l.',
    NAME: 'SAEP ICT',
    PHONE: '02 36684945',
    SOFTWARE: 'SPIN-8',
    LOGO: '/assets/common/img/logo/saep-ict/logo-saep-ict-color.svg'
};
export const customer = {
    ADDRESS: 'theme.contact.field.organization_address',
    EMAIL: 'theme.contact.field.email',
    NAME_LONG: 'theme.contact.field.organization_name',
    NAME: 'theme.contact.field.organization_name',
    PHONE: 'theme.contact.field.phone',
    SOFTWARE: 'SPIN-8',
    position: {
        LAT: 'theme.contact.organization_position.lat',
        LONG: 'theme.contact.organization_position.long'
    },
    socialMedia: {
        facebook: {
            hidden: false,
            iconPath: '/assets/common/img/social-media/facebook.svg',
            label: 'Facebook',
            link: 'https://www.facebook.com'
        },
        instagram: {
            hidden: false,
            iconPath: '/assets/common/img/social-media/instagram.svg',
            label: 'Instagram',
            link: 'https://www.instagram.com'
        },
        linkedin: {
            hidden: false,
            iconPath: '/assets/common/img/social-media/linkedin.svg',
            label: 'LinkedIn',
            link: 'https://www.linkedin.com'
        },
        youtube: {
            hidden: false,
            iconPath: '/assets/common/img/social-media/youtube.svg',
            label: 'YouTube',
            link: 'https://www.youtube.com'
        }
    }
}