import { FormControlMultipurposeModel, FormControlMultipurposeEnum } from '@saep-ict/angular-core';
import { Validators } from '@angular/forms';
import { creationFieldMapDocumentBase } from '../creation-field-map-base.const';
import _ from 'lodash';

export const creationFieldMapContextCodeBase: FormControlMultipurposeModel.Item[] =
_.cloneDeep(creationFieldMapDocumentBase).concat([
	{
		name: 'type',
		on_form_template: false
	},
	{
		name: 'description',
		label: 'saep_ict_angular_spin8_core.general.description',
		on_form_template: true,
		type: FormControlMultipurposeEnum.ItemType.STRING,
		column_width: 12,
		validator_list: [Validators.required]
	}
]);
