import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SubscribeManagerService, BaseState } from '@saep-ict/angular-core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticlePouchModel, OrderPouchModel, OrderStatusEnum, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import {
	AuxiliaryTableStateModel,
	ExtraFieldOrderHeaderPouchModel,
	OrderEnum,
	OrderStateModel,
	OrganizationStateModel,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { Store } from '@ngrx/store';
import { SubscribeManagerItem } from '../../../../model/subscribe-manager.model';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
	OrganizationActionEnum,
	OrganizationStateAction
} from '../../../../state/organization/organization.actions';
import { OrderActionEnum, OrderStateAction } from '../../../../state/order/order.actions';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import _ from 'lodash';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import * as ConfigurationSubscribeManager from '../../../../constants/subscribe-manager.constant';
import * as ConfigurationCustomerOrder from '../../../../constants/configuration-customer/order/order.constant';	 
import {ContextApplicationItemCodeEnum} from '@saep-ict/angular-spin8-core'
import { ArticleActionEnum } from '../../../../state/article/article.actions';
import * as ConfigurationCustomerOrderNavigation from '../../../../constants/configuration-customer/order/order-navigation.constant';

@Component({
	selector: 'order-revision-detail',
	templateUrl: './order-revision-detail.component.html',
	styleUrls: ['./order-revision-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderRevisionDetailComponent implements OnDestroy {
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organizationList$: Observable<BaseStateModel<OrganizationPouchModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'main-data', observable: this.subscribeMainData() },
		{ key: 'router-data', observable: this.subscribeRouterData() }
	];

	orderId: string = this.route.snapshot.paramMap.get('orderId');
	orderStatus: OrderStatusEnum = this.route.snapshot.paramMap.get('orderStatus') as OrderStatusEnum;
	routedMainContentKey: string;
	configuration = "OrderRevisionDetailComponent"
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	orderEnum = OrderEnum.FilterType.CONSOLIDATED


	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleListTotal: ArticlePouchModel[];
	articleList: ArticlePouchModel[];

	orderToAuthorize = OrderStatusEnum.TO_AUTHORIZE;


	constructor(
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private utilOrderService: UtilOrderService,
		private utilStoreService: StoreUtilService,
		private permissionUtilService: PermissionUtilService,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrderStateAction.reset());
	}

	// load static data
	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
		this.utilStoreService.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
			this.auxiliaryTable = res.data;
		});
		if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
			const organizationId = this.route.snapshot.paramMap.get('organizationId');
			this.utilStoreService.retrieveSyncState<OrganizationPouchModel[]>(this.organizationList$).subscribe(res => {
				const organization: OrganizationStateModel = res.data.find(x => x.code_item === organizationId);
				if (organization) {
					this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(organization)));
				} else {
					this.router.navigate(['/not-found']);
				}
			});
		}
	}

	subscribeMainData(): Observable<void> {
		return this.organization$.pipe(
			filter(
				(store: BaseStateModel<OrganizationPouchModel>) =>
					store && store.data && store.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationPouchModel>) => {
				switch (organization.type) {
					case OrganizationActionEnum.ERROR:
						throw new Error(OrganizationActionEnum.ERROR);
					case OrganizationActionEnum.UPDATE:
						this.organization = organization.data;
						return this.articleList$;
				}
				if (this.orderId !== 'new') {
					this.store.dispatch(OrderStateAction.load(new BaseState(this.orderId)));
				}
				return this.articleList$;
			}),
			filter(
				(articleList: BaseStateModel<ArticlePouchModel[]>) =>
					articleList && articleList.type !== ArticleActionEnum.LOAD_FROM_RECAP
			),
			mergeMap((articleList: BaseStateModel<ArticlePouchModel[]>) => {
				if (articleList.type === ArticleActionEnum.ERROR) {
					throw new Error(ArticleActionEnum.ERROR);
				}
				this.articleListTotal = articleList.data;
				return this.order$;
			}),
			filter(
				(order: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					!!(
						this.organization &&
						order &&
						order.type &&
						order.type !== OrderActionEnum.LOAD &&
						(order.data || order.type === OrderActionEnum.ERROR)
					)
			),
			mergeMap(async (order: BaseStateModel<OrderStateModel>) => {
				if (order.type === OrderActionEnum.ERROR) {
					throw new Error(OrderActionEnum.ERROR);
				}
				if (order.type === OrderActionEnum.UPDATE) {
					this.articleList = _.cloneDeep(this.articleListTotal);
					this.order = order.data;
					if (this.order.product_list.length) {
						if (this.order.header.status !== OrderStatusEnum.DRAFT) {
							this.articleList = this.utilOrderService.returnArticleListByOrderSent(this.order);
						} else {
							this.articleList = this.utilOrderService.returnFilteredAndMergedArticleListByOrderDraft(
								this.order,
								this.articleList,
								this.organization
							);
							this.order = this.utilOrderService.returnOrderDraftWithoutMissingArticle(
								this.order,
								this.articleList
							);
						}
					}}
				order = _.cloneDeep(order);
				await this.goBack(
					order.type,
					order.data && order.data.header ? order.data.header.status : null,
					this.routedMainContentKey
				);
			})
		);
	}

	subscribeRouterData(): Observable<void> {
		return this.router.events.pipe(
			filter((e: RouterEvent) => !!(e && e.url && e instanceof NavigationEnd)),
			map((e: RouterEvent) => {
				this.routedMainContentKey = ConfigurationCustomerOrder.returnLastUrlSegment(e.url);
			})
		);
	}

	goBack(storeActionType: string, orderStatus: OrderStatusEnum, routedMainContentKey: string): Promise<void> {
		return new Promise(resolve => {
			if (
				storeActionType === OrderActionEnum.ERROR ||
				storeActionType === OrderActionEnum.REMOVED ||
				(orderStatus !== OrderStatusEnum.DRAFT &&
					(routedMainContentKey === ROUTE_URL.catalog || routedMainContentKey === 'header-edit')) // TODO: trasferire insieme agli altri segmenti
			) {
				const goBakPath =
					ConfigurationCustomerOrderNavigation.getGoBackPath(
						this.orderStatus,
						this.user.current_permission.context_application
					);
				this.router.navigate([goBakPath]);
			} else {
				resolve();
			}
		});
	}
}