export enum ContextApplicationItemCodeEnum {
	AGENT = 'AGENT',
	B2B = 'B2B',
	B2C = 'B2C',
	BACKOFFICE = 'BACKOFFICE',
	BACKOFFICE_ADMIN = 'BACKOFFICE_ADMIN',
	CRM = 'CRM',
	PORTAL = 'PORTAL',
	PUBLIC = 'PUBLIC'
}

export enum ContextOtherItemCodeEnum {
	ARTICLE = 'ARTICLE',
	CONTACT = 'CONTACT',
	ORDER = 'ORDER',
	REQUEST = 'REQUEST',
	TICKET = 'TICKET'
}
