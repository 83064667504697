export enum NewsStatusEnum {
	NEW = 1,
	ARCHIVE = 2
}

export enum NewsActionEnum {
	ARCHIVE = 'ARCHIVE',
	DELETE = 'DELETE',
    TOGGLE_STATUS = 'TOGGLE_STATUS'
}
