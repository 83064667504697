<div class="form-button-bar" [ngClass]="'align-' + align">
	<button
			class="{{ primaryButtonClass ? primaryButtonClass : 'mat-raised-button' }}"
			mat-button
			color="primary"
			[type]="primaryButtonIsSubmit ? 'submit' : 'button'"
			*ngIf="primaryButtonLabel"
			[disabled]="primaryButtonIsDisabled"
			(click)="primaryButtonClickEmitter.emit()"
	>
		<mat-icon *ngIf="primaryButtonIconLabel">{{ primaryButtonIconLabel }}</mat-icon>
		{{ primaryButtonLabel }}
	</button>

	<button
		class="{{ secondaryButtonClass ? secondaryButtonClass : 'mat-stroked-button' }}"
		mat-button
		type="button"
		*ngIf="secondaryButtonLabel"
		[disabled]="secondaryButtonIsDisabled"
		(click)="secondaryButtonClickEmitter.emit()"
	>
		<mat-icon *ngIf="secondaryButtonIconLabel">{{ secondaryButtonIconLabel }}</mat-icon>
		{{ secondaryButtonLabel }}
	</button>

	<button
		class="{{ otherButtonClass }}"
		mat-button
		type="button" 
		*ngIf="otherButtonLabel"
		[disabled]="otherButtonIsDisabled"
		(click)="otherButtonClickEmitter.emit()"
	>
		<mat-icon *ngIf="otherButtonIconLabel">{{ otherButtonIconLabel }}</mat-icon>
		{{ otherButtonLabel }}
	</button>

	<button
		class="{{ thirdButtonClass }}"
		mat-button 
		type="button" 
		*ngIf="thirdButtonLabel"
		[disabled]="thirdButtonIsDisabled"
		(click)="thirdButtonClickEmitter.emit()"
	>
		<mat-icon *ngIf="thirdButtonIconLabel">{{ thirdButtonIconLabel }}</mat-icon>
		{{ thirdButtonLabel }}
	</button>
</div>
