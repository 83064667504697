import { Injector } from '@angular/core';

// model
import { TicketModel } from '../../model/lib.model';

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { TicketCenterTicketActionEnum, TicketCenterTicketStoreAction } from './ticket.actions';

// misc
import { AngularCoreUtilService, BaseStateModel } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { TicketCenterUtilService } from '../../service/util/ticket-center-util.service';

const injector = Injector.create({
	providers: [
    { provide: AngularCoreUtilService, deps: [] },
    { provide: TicketCenterUtilService, deps: [] }
  ]
});

const utilService = injector.get(AngularCoreUtilService);
const ticketCenterUtilService = injector.get(TicketCenterUtilService);

export namespace TicketCenterTicketStoreReducer {

	export const initialState: BaseStateModel<TicketModel<any>[]> = null;

	const _reducer = createReducer(
		initialState,
		on(TicketCenterTicketStoreAction.load, state => state),
		on(TicketCenterTicketStoreAction.update, (state, param) => param),
    on(TicketCenterTicketStoreAction.addItemOrUpdateItem, (state, param) => {
      return TicketCenterTicketStoreReducer.addItemOrUpdateItem(state, param);
    }),
		on(TicketCenterTicketStoreAction.reset, () => null),
		on(TicketCenterTicketStoreAction.error, (state, param) =>
      ticketCenterUtilService.returnStoreReducerError<TicketModel<any>[]>(state, param)
    )
	);

	export function reducer(state: BaseStateModel<TicketModel<any>[]>, action: TypedAction<TicketCenterTicketActionEnum>) {
    return _reducer(state, action);
	}

  export const addItemOrUpdateItem = (
    state: BaseStateModel<TicketModel<any>[]>,
    param: BaseStateModel<TicketModel<any>>): BaseStateModel<TicketModel<any>[]> => {
      const itemIndex = utilService.getElementIndex(state.data, 'id', param.data.id.toString());
      if (itemIndex || itemIndex === 0) {
        state.data[itemIndex] = param.data;
      } else {
        state.data.push(param.data);
      }
    return _.cloneDeep(state);
  }

}
