<ng-container *ngFor="let branch of configuration.articleCheckoutTree">
	<!-- TODO: definire se la classe level mat-h{{level}} applicata al div è utile per lo style del componente
	in modalità annidata -->
	<div>
		<!-- pipe `translate` necessaria per gestione label multingua nei soli articoli senza categoria -->
		{{
			utilTranslateService.getTranslationFromLanguage(branch.language_list).description |
			translate |
			lowercase |
			sentencecase
		}}
		({{ branch.article_list.length }})
		<span
			articleListSummaryValue
			[configuration]="{
				articleList: branch.article_list,
				currency: configuration.currency
			}"
		>
		</span>
	</div>
	<article-list-category-tree-recursion-summary
		[configuration]="{
			articleCheckoutTree: branch.category_list,
			currency: configuration.currency
		}"
		[level]="level + 1"
	>
	</article-list-category-tree-recursion-summary>
</ng-container>
