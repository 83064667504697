import { Inject, Injectable, Optional } from '@angular/core';
import {
	IResourceMethodPromise,
	IResourceMethodPromiseStrict,
	ResourceAction,
	ResourceRequestMethod
} from '@ngx-resource/core';

import { RestBaseResponse, RestBasePk} from '@saep-ict/angular-core';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';
import { PersonalInfoUpdate } from '../../model/auth/personal-info-update';
import {
	LoginAuthRequestModel,
	LoginAuthResponseModel,
	RestGetUserAvatarResponse,
	RestPostUserListPayload,
	UserActivateAccountRequestModel,
	UserDetailResponseModel,
	VerifyGuidEmailRequestModel,
	VerifyGuidEmailResponseModel
} from '../../model/permission/user.model';
import { UserManagementQueryParameter } from '../../model/permission/user-management.model';
import { UserPasswordChangeModelRequest } from '../../model/auth/password.model';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';
// import { Store } from '@ngrx/store';
// import { Router } from '@angular/router';
// import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class AngularSpin8CoreUserService extends AngularSpin8CoreBaseApiService {
    // apiUrl?: string;
    // constructor(
    //     @Optional() @Inject(LOADER_SERVICE_TOKEN) loaderService: ILoaderService,
    //     @Inject(APP_CONFIG_TOKEN)  appConfig: ISaepIctAngularSpin8CoreAppConfig,
    //     store: Store<any>,
    //     router: Router,
    //     localStorageService: LocalStorageService,
    // ){
    //     super(loaderService,appConfig,store,router,localStorageService)
    //     this.apiUrl = this.returnApiUrlSegment1('privateApi')
    //     console.log(this.apiUrl)
    // }

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/authenticate/'
	})
	login: IResourceMethodPromise<LoginAuthRequestModel, LoginAuthResponseModel>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/Authentication/GetUserInfos?username={!mail}/'
	})
	getUserInfo: IResourceMethodPromise<{ mail: string }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Patch,
		path:  `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/activate-account/{!guid}/`
	})
	activateAccount: IResourceMethodPromiseStrict<
		{ password: string },
		void,
		UserActivateAccountRequestModel,
		RestBaseResponse<void>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/detail/`
	})
	getUserList: IResourceMethodPromise<UserManagementQueryParameter, RestBaseResponse<UserDetailResponseModel[]>>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/detail/`,
    //path: `/${returnApiUrlSegment('privateApi')}/users/{!id}/detail/`,
	})
	getUserDetail: IResourceMethodPromise<RestBasePk, RestBaseResponse<UserDetailResponseModel>>;

	@ResourceAction({
		method: ResourceRequestMethod.Patch,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/detail/`
    })
	updateUserDetail: IResourceMethodPromise<PersonalInfoUpdate, RestBaseResponse<UserDetailResponseModel>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/users/{!id}/password/change/'
	})
	passwordChange: IResourceMethodPromiseStrict<
		UserPasswordChangeModelRequest,
		void,
		RestBasePk,
		RestBaseResponse<void>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/password/update/`
	})
	passwordUpdate: IResourceMethodPromiseStrict<
		{ password: string; old_password: string },
		void,
		RestBasePk,
		RestBaseResponse<void>
	>;

	// NON UTILIZZATO
	// @ResourceAction({
	// 	method: ResourceRequestMethod.Post,
	// 	path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/password/reset/`
	// })
	// passwordReset: IResourceMethodPromise<RestBasePk, RestBaseResponse<void>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/password/recovery/`
	})
	passwordRecovery: IResourceMethodPromise<{ email: string; context_type: string }, RestBaseResponse<void>>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/verify-guid/{!email}/{!guid}/'
	})
	verifyGuidEmail: IResourceMethodPromise<
		VerifyGuidEmailRequestModel,
		RestBaseResponse<VerifyGuidEmailResponseModel>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Patch,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/deactivate-account/`
	})
	deactivateAccount: IResourceMethodPromise<RestBasePk, RestBaseResponse<void>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/create-with-permission/`
	})
	postUserList: IResourceMethodPromise<RestPostUserListPayload, RestBaseResponse<[]>>;

	@ResourceAction({
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/user-icon/`,
		method: ResourceRequestMethod.Get
	})
	getUserAvatar: IResourceMethodPromise<{ id: number | string }, RestGetUserAvatarResponse>;

	@ResourceAction({
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/{!id}/user-icon/`,
		method: ResourceRequestMethod.Post
	})
	postUserAvatar: IResourceMethodPromise<{ id: number | string; iconBase64: string }, RestGetUserAvatarResponse>;

	@ResourceAction({
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/users/delete/`,
		method: ResourceRequestMethod.Delete
		})
	deleteUser: IResourceMethodPromise<{ users: number[] | string[] }, RestBaseResponse<void>>;
	
}
