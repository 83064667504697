import { StatusBarConfig, StatusBarElement } from "@saep-ict/angular-core";
import { OrderStatusEnum } from "@saep-ict/pouch_agent_models";
import { StateRelatedLink } from "../constants/configuration-customer/order/status-aggregation-map.constant";

export class StatusBarElementNew extends StatusBarElement {
    id: OrderStatusEnum;
    iconCustom?: boolean;
    goesInOveride?: boolean;
    showLabel?: boolean;
}

export class StatusBarConfigNew extends StatusBarConfig {
    steps: StatusBarElementNew[]
}

export class StatusBarConfigNewBis {
    condensed?: boolean;
    statusMap?: StateRelatedLink[];
}