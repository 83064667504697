import { Component, OnInit, Input } from '@angular/core';

export class ItemBoxCreditDebitInfoComponentConfig{
	value: string | number;
	suffix?: string;
	title?: string;
}


@Component({
  selector: 'box-credit-debit-info',
  templateUrl: './box-credit-debit-info.component.html',
  styleUrls: ['./box-credit-debit-info.component.scss']
})

export class BoxCreditDebitInfoComponent implements OnInit {

    @Input() boxCreditDebitInfoComponentConfig: Array<ItemBoxCreditDebitInfoComponentConfig>;
    @Input() showSeparator: boolean = true;

    constructor() { }

    ngOnInit(): void {
  }

}
