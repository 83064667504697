import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

// model
import { BreadcrumbModel } from '../../model/breadcrumb.model';
import { MediaCenterFilter } from '../../model/media-center.model';

@Component({
	selector: 'mc-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
	breadcrumb: BreadcrumbModel[] = [];

	@Input() set configuration(b: MediaCenterFilter) {
		if (b) {
      let path: string = b.path;
      if (b.text) {
        path += 'Search/';
      }
			this.breadcrumb = this.formatBreadcrumb(path);
		}
	}

	@Output() pathChange = new EventEmitter();
	constructor() { }

	ngOnInit() { }

	formatBreadcrumb(s: string, c = "/"): BreadcrumbModel[] {
    const breadcrumb: BreadcrumbModel[] = [
      {
        label: "Home",
        path: "/"
      }
    ];
		if (s !== c) {
			s = s.substring(1, s.length - 1);
			const a = s.split(c);
			for (let i = 0; i < a.length; i++) {
				const item: BreadcrumbModel = {
					label: a[i],
					path: ""
				};
				for (let l = 0; l <= a.length - (a.length - i); l++) {
					if (l === 0) {
						item.path = c + a[l] + c;
					} else {
						item.path = item.path + a[l] + c;
					}
				}
        breadcrumb.push(item);
			}
		}
    return breadcrumb;
	}

	emitSelectedPath(path: string) {
		this.pathChange.emit(path);
	}

}
