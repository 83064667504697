import { Component, OnInit, Input } from '@angular/core';


export enum IconType {
    MATERIAL = 'material',
    CUSTOM = 'custom'
}



export class ItemSidebarContentStandardComponentConfig {
	title: string;
	subtitle?: string;
	rightContent?: string;
	icon?: {type: IconType, name: string};
}

@Component({
  selector: 'sidebar-content-standard',
  templateUrl: './sidebar-content-standard.component.html',
  styleUrls: ['./sidebar-content-standard.component.scss']
})
export class SidebarContentStandardComponent implements OnInit {


    @Input() sidebarContentStandardComponentConfig: Array<ItemSidebarContentStandardComponentConfig>
    iconType = IconType

    constructor() { }

    ngOnInit(): void {
    }

}
