<div class="container-fluid">
	<mat-card class="michelangelo-theme mb-30">
		<top-bar
			[goBackButton]="true"
			[goBackPath]="backPath"
			class="mb-20"
		>
			<ng-container left-side-content><h1>{{ 'general.information.general' | translate | sentencecase }}</h1></ng-container>
		</top-bar>
		<mat-card-content>
			<form-dynamic
				[configuration]="formDynamicConfiguration"
				(valueChange)="formDynamicChange($event)"
			></form-dynamic>
			<mat-divider class="bottom-spacing mb-20 mb-30"></mat-divider>
		</mat-card-content>
		<mat-card-content>
			<h2>{{ 'article.related.list' | translate | sentencecase }}</h2>
			<article-list-structure-wrapper-plain-list
				*ngIf="
					local_handler_related_article_list &&
					local_handler_related_article_list.data &&
					local_handler_related_article_list.data.length > 0"
				[localListHandlerWidgetWrapperData]="local_handler_related_article_list"
				[localListHandlerWidgetWrapperColumnList]="local_handler_related_article_list.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="false"
				[onItemSelectShow]="true"
				[configurationAction]="configurationCustomerFamilyBackofficeDetail.relatedArticleListAction"
				(onDeleteArticle)="
					dialogRelatedListDelete(
						$any($event),
						'related_article_list'
					)
				"
				(onItemSelect)="goToArticleDetail($event)"
			></article-list-structure-wrapper-plain-list>
			<button
				*ngIf="configurationCustomerFamilyBackofficeDetail.relatedArticleListCanAdd"
				mat-fab
				(click)="
					dialogRelatedListAdd(
						mainList,
						'related_article_list'
					)
				"
				class="mat-fab-button-section-bottom-right"
				[matTooltip]="'article.add_related' | translate | sentencecase"
				matTooltipPosition="left"
				color="primary"
			>
				<mat-icon>add</mat-icon>
				<span class="label">{{ 'article.add_related' | translate | sentencecase }}</span>
			</button>
			<mat-divider class="both-spacing mt-20 mb-20"></mat-divider>
		</mat-card-content>
		<mat-card-content>
			<h2>{{ 'family.related_list.table_title' | translate | sentencecase }}</h2>
			<article-list-structure-wrapper-plain-list
				*ngIf="
					local_handler_related_family_list &&
					local_handler_related_family_list.data &&
					local_handler_related_family_list.data.length > 0"
				[localListHandlerWidgetWrapperData]="local_handler_related_family_list"
				[localListHandlerWidgetWrapperColumnList]="local_handler_related_family_list.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="false"
				[onItemSelectShow]="true"
				[configurationAction]="configurationCustomerFamilyBackofficeDetail.relatedFamilyListAction"
				(onDeleteArticle)="
					dialogRelatedListDelete(
						$any($event),
						familyList,
						'related_family_list'
					)
				"
				(onItemSelect)="goToArticleDetail($event)"
			></article-list-structure-wrapper-plain-list>
			<button
				*ngIf="configurationCustomerFamilyBackofficeDetail.relatedFamilyListCanAdd"
				mat-fab
				(click)="
					dialogRelatedListAdd(
						familyList,
						'related_family_list'
					)
				"
				class="mat-fab-button-section-bottom-right"
				[matTooltip]="'family.related_list.add' | translate | sentencecase"
				matTooltipPosition="left"
				color="primary"
			>
				<mat-icon>add</mat-icon>
				<span class="label">{{ 'family.related_list.add' | translate | sentencecase }}</span>
			</button>
			<mat-divider class="both-spacing mt-20 mb-20"></mat-divider>
		</mat-card-content>
		<mat-card-content>
			<h2>{{ 'family.alternative_list.table_title' | translate | sentencecase }}</h2>
			<article-list-structure-wrapper-plain-list
				*ngIf="
					local_handler_alternative_family_list &&
					local_handler_alternative_family_list.data &&
					local_handler_alternative_family_list.data.length > 0"
				[localListHandlerWidgetWrapperData]="local_handler_alternative_family_list"
				[localListHandlerWidgetWrapperColumnList]="local_handler_alternative_family_list.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="false"
				[onItemSelectShow]="true"
				[configurationAction]="configurationCustomerFamilyBackofficeDetail.alternativeFamilyListAction"
				(onDeleteArticle)="
					dialogRelatedListDelete(
						$any($event),
						familyList,
						'alternative_family_list'
					)
				"
				(onItemSelect)="goToArticleDetail($event)"
			></article-list-structure-wrapper-plain-list>
			<button
				*ngIf="configurationCustomerFamilyBackofficeDetail.alternativeFamilyListCanAdd"
				mat-fab
				(click)="
					dialogRelatedListAdd(
						familyList,
						'alternative_family_list'
					)
				"
				class="mat-fab-button-section-bottom-right"
				[matTooltip]="'family.alternative_list.add' | translate | sentencecase"
				matTooltipPosition="left"
				color="primary"
			>
				<mat-icon>add</mat-icon>
				<span class="label">{{ 'family.alternative_list.add' | translate | sentencecase }}</span>
			</button>
			<mat-divider class="both-spacing mt-20 mb-20"></mat-divider>
		</mat-card-content>
	</mat-card>
	<mat-card class="michelangelo-theme">
		<mat-tab-group
			[selectedIndex]="
				utilTranslateService.languages.indexOf(language.DEFAULT ? language.DEFAULT : language.FALLBACK)
			"
		>
			<mat-tab
				*ngFor="let language of utilTranslateService.languages"
				dynamicHeight="true"
			>
				<ng-template mat-tab-label>
					<span>{{ language | uppercase }}</span>
				</ng-template>
				<form-dynamic
					*ngIf="formDynamicLanguageListConfiguration"
					[configuration]="formDynamicLanguageListConfiguration[language]"
					(valueChange)="formDynamicLanguageListChange($event, language)"
				></form-dynamic>
				<h2>
					{{ 'general.image.add_remove' | translate | sentencecase }}
					<button mat-icon-button (click)="imageDisclaimer.isVisible = true">
						<mat-icon> help_outline </mat-icon>
					</button>
				</h2>
				<closable-panel
					#imageDisclaimer
					[isVisible]="true"
					class="mb-20 mb-30"
				>
					<ng-container content>
						{{
							'general.image.dimensions'
							| translate : { imageWidth: configurationCustomerArticle.imageWidth }
							| sentencecase
						}}
						<br>
						<br>
						{{ 'general.image.upload_disclaimer' | translate | sentencecase }}
					</ng-container>
				</closable-panel>
				<attachment-list-manager
					*ngIf="attachmentListManagerConfiguration"
					[configuration]="attachmentListManagerConfiguration[language]"
					(attachmentListchange)="attachmentListManagerChange($event, language)"
				>
				</attachment-list-manager>
			</mat-tab>
		</mat-tab-group>
	</mat-card>
</div>
