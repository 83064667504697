import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// widet & utility
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import {
	MediaReplayService,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import { ContextPermissionGroupItem, UserManagementDialogChangePermissionContextCode } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'dialog-change-permission-context-code',
	templateUrl: './dialog-change-permission-context-code.component.html',
	styleUrls: ['./dialog-change-permission-context-code.component.scss']
})
export class DialogChangePermissionContextCodeComponent implements OnInit {
	contextPermissionGroup = <ContextPermissionGroupItem[]>[];
	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: UserManagementDialogChangePermissionContextCode,
		private fb: FormBuilder,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogChangePermissionContextCodeComponent>,
		public utilService: AngularCoreUtilService,
		public permissionUtilService: PermissionUtilService
	) {
		this.contextPermissionGroup = this.permissionUtilService.createContextPermissionGroupList(
			this.data.permission_group_list,
			this.data.permission_list,
			this.data.contextTypeAndApplicationLink.context_application_item_code,
			this.data.permission_default
		);
		this.createForm();
	}

	ngOnInit() {}

	// form
	createForm() {
		this.form = this.fb.group({
			code: [this.data.contextCodeItem.code],
			description: [this.data.contextCodeItem.description],
			permission: [this.data.contextCodeItem.permission ? this.data.contextCodeItem.permission : []]
		});
	}

	prepareSaveForm() {
		const formModel = this.utilService.deleteEmptyProperties(this.form.value);
		return formModel;
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.dialogRef.close(this.prepareSaveForm());
		}
	}
}
