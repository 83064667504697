export namespace OrderEnum {
	export enum PriceMapType {
		TOTAL = 'total',
		VAT = 'vat',
		ARTICLE = 'article',
		FREE_LINES = 'free_lines',
		SHIPPING = 'shipping',
		DISCOUNT = 'discount'
	}
	export enum FilterType {
		RECEIVED = 'RECEIVED',
		CONSOLIDATED = 'CONSOLIDATED',
		FULFILLED = 'FULFILLED'
	}
	export enum VatType {
		ARTICLE = 'ARTICLE',
		SHIPPING = 'SHIPPING'
	}
	export enum CommonArticleStructure {
		PLAIN_LIST = 'PLAIN_LIST',
		CATEGORY_TREE = 'CATEGORY_TREE',
    	ACCORDION = 'ACCORDION'
	}
}
