import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { InformativePageActionEnum, InformativePageStateAction } from './informative-page.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { InformativePageModel } from '@saep-ict/angular-spin8-core';

export namespace InformativePageStateReducer {
	export const initialState: BaseStateModel<InformativePageModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(InformativePageStateAction.load, state => state),
		on(InformativePageStateAction.loadAll, state => state),
		on(InformativePageStateAction.save, (state, informativePageList) => state),
		on(InformativePageStateAction.update, (state, informativePageList) => informativePageList),
		on(InformativePageStateAction.reset, () => initialState),
		on(InformativePageStateAction.error, (state, informativePage) => ({
			data: state ? state.data : null,
			type: informativePage.type
		}))
	);

	export function reducer(
		state: BaseStateModel<InformativePageModel[]>,
		action: TypedAction<InformativePageActionEnum>
	) {
		return _reducer(state, action);
	}
}
