import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilCompanyService } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerPrice from "../../../constants/configuration-customer/price/price.constant";

@Component({
	selector: 'receipt-list-wrapper',
	templateUrl: './receipt-list-wrapper.component.html',
	styleUrls: ['./receipt-list-wrapper.component.scss']
})
export class ReceiptListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectOrganization: EventEmitter<{ level: string; code: string }> = new EventEmitter();
	@Output() selectActionDialog = new EventEmitter();
	configurationCustomerPrice = ConfigurationCustomerPrice;
	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService
	) {
		super(utilService, translateService, store);
	}

}
