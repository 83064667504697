import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IPageChangeEvent } from '@covalent/core/paging';
import { ITdDataTableSortChangeEvent, TdDataTableComponent, TdDataTableSortingOrder } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	MediaReplayService,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { BackofficeUserFilterModel } from '../../../service/pouch-db/filter/backoffice-user-filter.model';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { ContactListWrapperComponent } from '../../contact/contact-list-wrapper/contact-list-wrapper.component';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../state/opportunity/opportunity.actions';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';
import {
	OpportunityEnum,
	OrganizationStateModel,
	AngularSpin8CoreUtilCompanyService,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerOpportunityColumns from '../../../constants/configuration-customer/opportunity/opportunity-column-map/opportunity-column-map-base.constant';

@Component({
	selector: 'dialog-select-opportunity',
	templateUrl: './dialog-select-opportunity.component.html',
	styleUrls: ['./dialog-select-opportunity.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogSelectOpportunityComponent implements OnDestroy {
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	@ViewChild(ContactListWrapperComponent)
	contactListWrapperComponent: ContactListWrapperComponent;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunity: OpportunityPouchModel[];

	public listPageBaseData: BaseStateModel<ContactPouchModel[], BackofficeUserFilterModel> = {
		data: [],
		dataSetting: {
			pagination: {
				page_current: 1,
				page_size: 5
			},
			appliedFilter: {
				text: null,
				context_code: null
			},
			sort: [{ ['full_name']: 'asc' }]
		}
	};

	form: FormGroup;
	isLoading = false;
	columns = ConfigurationCustomerOpportunityColumns.baseColumns;
	OpportunityEnum = OpportunityEnum;

	constructor(
		private store: Store,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogSelectOpportunityComponent>,
		public utilService: AngularCoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
		public permissionUtilService: PermissionUtilService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService
	) {
		this.isLoading = true;

		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.store.dispatch(OpportunityStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeOpportunityList().subscribe(), 'subscribeOpportunityList');
		// Nascondo le colonne della tabella
		const columnsToExclude = [
			'date_creation',
			'assegnee_full_name',
			'context_application',
			'order_confirm_email',
			'actions'
		];
		this.columns.map(col => {
			col.hidden = columnsToExclude.includes(col.name);
		});
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
	}

	// Subscribe
	subscribeOpportunityList() {
		return this.organizationList$.pipe(
			filter(res => !!(res && res.data)),
			mergeMap(res => {
				this.organizationList = res.data;
				return this.opportunity$;
			}),
			filter((state: BaseStateModel<OpportunityPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<OpportunityPouchModel[]>) => {
				this.isLoading = false;
				switch (state.type) {
					case OpportunityActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case OpportunityActionEnum.ERROR:
						throw new Error(OpportunityActionEnum.ERROR);

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(opportunityList: OpportunityPouchModel[] = this.opportunity) {
		opportunityList = this.getOrganization(opportunityList);

		this.opportunity = opportunityList;
		this.listPageBaseData.data = this.opportunity;
	}

	// CRM filter by type
	getOrganization(opportunityList: OpportunityPouchModel[]) {
		return opportunityList.map(opportunity => {
			const founded = this.organizationList.find(org => org.code_item == opportunity.organization.code_item);
			opportunity.organization.code_item = founded?.code_item;
			opportunity.organization.business_name = founded?.business_name;
			return opportunity;
		});
	}

	// widget & utility
	selectOpportunity(opportunity: OpportunityPouchModel) {
		this.dialogRef.close(opportunity);
	}

	changePageSize(size: number) {
		if (size) {
			this.listPageBaseData.dataSetting.pagination.page_size = size;
			this.applyPermanentFilters();
		}
	}

	paginationChange(pagination: IPageChangeEvent) {
		if (pagination) {
			this.listPageBaseData.dataSetting.pagination.page_current = pagination.page;
			this.applyPermanentFilters();
		}
	}

	sortChange(e: ITdDataTableSortChangeEvent) {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				[e.name]: e.order === TdDataTableSortingOrder.Ascending ? 'asc' : 'desc'
			}
		];
		this.listPageBaseData.dataSetting.sort = sort;
		this.applyPermanentFilters();
	}
}
