import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { CustomerAppConfig } from '../../../customer-app.config';
import * as ConfigurationCustomerReference from '../../../constants/configuration-customer/reference/reference.constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dialog-company-info',
  templateUrl: './dialog-company-info.component.html',
  styleUrls: ['./dialog-company-info.component.scss']
})
export class DialogCompanyInfoComponent implements OnDestroy {

    softwareInfo: any;
    configSubscription: Subscription;
    customerReferenceDev = ConfigurationCustomerReference.development;

    constructor(public dialogRef: MatDialogRef<DialogCompanyInfoComponent>, protected appConfig: CustomerAppConfig) {
        this.configSubscription = this.appConfig.config$.subscribe(config => {
            this.softwareInfo = {
                release: config.release,
                companyName: config.company.name,
                version: config.tag
            };
		});
     }

    ngOnDestroy() {
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}

    // close() {
    //     this.dialogRef.close();
    // }

}
