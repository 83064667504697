import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as ConfigurationCustomerContact from '../../../constants/configuration-customer/contact/contact.constant';
import * as ConfigurationCustomerOrganization from '../../../constants/configuration-customer/organization/organization.constant';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseStateModel,
	DialogConfirmComponent,
	FilterByAllTheBooleansPipe,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel, ListDataPouchModel, OrganizationLevelEnum } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { delay, filter, map, take } from 'rxjs/operators';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { StateFeature } from '../../../state';
import { ContactActionEnum, ContactStateAction } from '../../../state/contact/contact.actions';
import { DialogContactDetailComponent } from '../../../widget/dialog/dialog-contact-detail/dialog-contact-detail.component';
import { ContactListWrapperComponent } from '../../../widget/contact/contact-list-wrapper/contact-list-wrapper.component';
import { OrganizationStateAction, OrganizationActionEnum } from '../../../state/organization/organization.actions';
import { BaseState, SentencecasePipe } from '@saep-ict/angular-core';
import { AppUtilService } from '../../../service/util/app-util.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContactModel } from '../../../model/contact.model';
import { DialogActionListComponent } from '../../../widget/dialog/dialog-action-list/dialog-action-list.component';
import {
	ContextApplicationItemCodeEnum,
	OrganizationStateModel,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel,
	AngularSpin8CoreUtilCompanyService
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';

@Component({
	selector: 'contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
	providers: [SubscribeManagerService]
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(ContactListWrapperComponent)
	contactListWrapperComponent: ContactListWrapperComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	contact$: Observable<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> = this.store.select(
		StateFeature.getContact
	);
	contacts: ContactModel[];

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	columns;

	currentContext: ContextApplicationItemCodeEnum;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	canEdit = false;
	canCreate = false;

	public listPageBaseData = <LocalListHandlerBaseModel<ContactPouchModel>>{
		pagination: {
			pageSize: this.contextApplicationItemCodeEnum.CRM ? 25 : 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'full_name',
					'email',
					'role',
					'phone',
					'area',
					'organization.business_name',
					'organization.level'
				]
			}
		},
		sort: {
			name: 'full_name',
			order: 'ASC'
		},
		data: []
	};

	pageContext: string;
	formFilters: FormGroup;
	organizationId: string;

	// Check if some filter is active
	hasChange = false;

	sideBarPositionValues = SideBarPositionValues;

	constructor(
		private dialog: MatDialog,
		private sentencecasePipe: SentencecasePipe,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private translateService: TranslateService,
		public activatedRoute: ActivatedRoute,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private utilService: AppUtilService,
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private filterByAllTheBooleans: FilterByAllTheBooleansPipe,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {}

	ngOnInit() {
		this.organizationId = this.activatedRoute.parent.snapshot.paramMap.get('idOrganization');
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res.data;
			this.currentContext = this.user.current_permission.context_application;
			this.columns = ConfigurationCustomerContact.columnList(this.currentContext);
			this.columns.find(col => col.name == 'area'? col.width = {max: 100 }: undefined);
		});
		this.createFormFilters();

		this.activatedRoute.parent.url.pipe(take(1)).subscribe(url => {
			this.pageContext = url[0].path;
		});
		const contextApplication = this.user.current_permission.context_application;
		if (!this.route.parent.component || this.route.parent.component === FramePageComponent) {
			// contatti esterni non legati all'organization
			this.setRouteMetaInformation();
			this.canCreate =
				ConfigurationCustomerContact.canCreate[contextApplication];
			this.canEdit =
				ConfigurationCustomerContact.canEdit[contextApplication];
		} else {
			// organization-detail
			this.canCreate =
				ConfigurationCustomerOrganization.canCreate.contact[contextApplication];
			this.canEdit =
				ConfigurationCustomerOrganization.canEdit.contact[contextApplication];
		}
		
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.organizationId) {
				this.filterByOrganization();
				const columnsMap = _.cloneDeep(ConfigurationCustomerContact.columnList(this.currentContext));
				const businessOrganizationIndex = this.utilService.getElementIndex(columnsMap, 'name', 'organization.business_name');
				columnsMap.splice(businessOrganizationIndex, 1);
				this.columns = columnsMap;
			} else {
				// Lista trasversale
				this.store.dispatch(ContactStateAction.loadAll());
				this.subscribeManagerService.populate(this.subscribeContactList().subscribe(), 'subscribeContactList');
				// Nascondo le colonne della tabella
				this.currentContext = this.user.current_permission.context_application;
				this.columns = ConfigurationCustomerContact.columnList[
					ContextApplicationItemCodeEnum[this.currentContext]
				]
 
			}
		})
	}

	filterByOrganization() {
		this.organization$
			.pipe(
				delay(0),
				filter(res => !!(res && res.data))
			)
			.subscribe(state => {
				switch (state.type) {
					case OrganizationActionEnum.UPDATE:
					case OrganizationActionEnum.SAVE_SUCCESS:
						this.organization = state.data;
						if (this.organization?.contact_list) {
							this.contactListWrapperComponent.updateListPageBaseData(
								// escludi contatti "eliminati" in maniera logica
								this.filterByAllTheBooleans.transform(this.organization.contact_list, {
									propertyListName: ['valid'],
									value: true
								})
							);
						}
						break;
				}
			});
		// Nascondo le colonne della tabella
		this.columns = ConfigurationCustomerContact.columnList[
			ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
		]
		}


	ngOnDestroy() {
		this.columns = ConfigurationCustomerContact.columnList[
			ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
		];
		this.store.dispatch(ContactStateAction.reset());
		this.subscribeManagerService.destroy();
		this.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeContactList() {
		return this.contact$.pipe(
			filter((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => !!state),
			map((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => {
				switch (state.type) {
					case ContactActionEnum.UPDATE:
						this.applyPermanentFilters(state.data.docs);
						break;

					case ContactActionEnum.ERROR:
						this.contactListWrapperComponent.updateListPageBaseData([]);
						throw new Error(ContactActionEnum.ERROR);
						break;

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(contactList: ContactPouchModel[] = []) {
		// TODO - Introdurre filtri se servono
		// switch (this.user.current_context) {
		// 	case ContextApplicationItemCodeEnum.AGENT: {
		// 		break;
		// 	}
		// 	case ContextApplicationItemCodeEnum.B2B: {
		// 		break;
		// 	}
		// 	case ContextApplicationItemCodeEnum.BACKOFFICE: {
		// 		break;
		// 	}
		// }

		this.contacts = this.mapContactList(contactList) as ContactModel[];
		// filtri sidebar
		this.onFormFiltersSubmit();
	}

	/**
	 * Filters sidebar
	 */

	createFormFilters() {
		this.formFilters = this.fb.group({
			lead: false
		});

		this.utilService.onCreateFormValueChange(this.formFilters).subscribe(val => (this.hasChange = val));
	}

	resetFilters() {
		this.formFilters.patchValue({
			lead: false
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.contacts);

		if (this.formFilters && this.formFilters.get('lead').value) {
			filteredList = filteredList.filter(
				contact => (contact as ContactModel).organization?.level == OrganizationLevelEnum.LEAD
			);
		}

		this.contactListWrapperComponent.updateListPageBaseData(filteredList);
	}

	mapContactList(list: ContactPouchModel[]): ContactPouchModel[] {
		// Mappare per avere le info corrette da deprecare con un modello dati corretto
		list.map(contact => {
			if (!contact.full_name) {
				contact.full_name = contact.first_name + ' ' + contact.last_name;
			}
			// a scopo di soluzione sorting
			//contact['contact_details'] = contact.email;
			contact.has_notification = ConfigurationCustomerAppStructure.contact.can_activate_email_order_receipt
				? this.setContactNotification(contact)
				: false;
			status = ConfigurationCustomerAppStructure.contact.send_email_confirmation
				? this.setStatus(contact)
				: null;
		});

		return list.filter(contact => contact.valid);
	}

	setContactNotification(contact: ContactPouchModel): boolean {
		if (!contact.has_notification) {
			return false;
		} else {
			return true;
		}
	}

	setStatus(contact: ContactPouchModel): 'confermato' | 'validazione in corso' | 'email non confermata' {
		let res: 'confermato' | 'validazione in corso' | 'email non confermata';
		if (contact.valid) {
			res = 'confermato';
		} else {
			const date = new Date(contact.date_creation);
			const date_plus_month = new Date(date.setMonth(date.getMonth() + 1));
			const date_commparison = new Date().getTime() < date_plus_month.getTime();
			if (date_commparison) {
				res = 'validazione in corso';
			} else {
				res = 'email non confermata';
			}
		}
		return res;
	}

	/**
	 * Actions
	 */

	openDialogContactDetail(contact?: ContactPouchModel) {
		const dialog = this.dialog.open(DialogContactDetailComponent, {
			data: {
				title: this.sentencecasePipe.transform(this.translateService.instant('contact.details')),
				organizationCodeItem: this.organization?.code_item || null,
				canEdit: contact ? this.canEdit : this.canCreate,
				contact: contact
			},
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog'],
			minHeight: '32vh',
			disableClose: false
		});
		dialog.afterClosed().subscribe((contactToSave: ContactPouchModel) => {
			if (contactToSave) {
				this.updateContact(contactToSave);
			}
		});
	}

	openDialogActionList(contact: ContactPouchModel) {
		const dialog = this.dialog.open(DialogActionListComponent, {
			data: {
				modalTitle: this.sentencecasePipe.transform(this.translateService.instant('action.show')),
				contact: contact
			},
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog'],
			minHeight: '32vh',
			disableClose: true
		});
		dialog.afterClosed().subscribe((contactToSave: ContactPouchModel) => {
			if (contactToSave) {
				this.updateContact(contactToSave);
			}
		});
	}

	toggleHasNotification(row: ContactPouchModel) {
		row.has_notification = !row.has_notification;
		this.updateContact(row);
	}

	updateContact(row: ContactPouchModel) {
		if (this.pageContext == 'contacts') {
			this.store.dispatch(ContactStateAction.save(new BaseState(row)));
		} else {
			const updatedOrganization = _.cloneDeep(this.organization);
			if (!updatedOrganization.contact_list) {
				updatedOrganization.contact_list = [];
			}
			if (row.code_item) {
				updatedOrganization.contact_list = updatedOrganization.contact_list.map(contact =>
					contact.code_item === row.code_item ? row : contact
				);
			} else {
				row.code_item = this.utilService.guid();
				row.type = 'contact';
				updatedOrganization.contact_list.push(row);
			}
			this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
		}
	}

	openDialogConfirmRemoveContact(row: ContactPouchModel) {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.translateService.instant('contact.remove'),
				text: this.translateService.instant('question.remove') + ` ${row.email}?`
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.removeContact(row);
			}
		});
	}

	openAddAction(row: ContactPouchModel) {
		this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.actions}/${ROUTE_URL.newAction}`], {
			queryParams: { contact_id: row.code_item }
		});
	}

	openAnalytics(contact: ContactPouchModel) {
		window.open(
			'https://app.powerbi.com/reportEmbed?reportId=e59ea37f-8320-40f0-a161-8614d41b1dc5&autoAuth=true&ctid=03a70941-71e7-467d-934c-c6e128ab0baa&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLWUtcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D' +
				"&autoAuth=true&$filter=utente eq '" +
				contact['client_id'] +
				"'",
			'_blank'
		);
	}

	selectCompany(level: string, code: string) {
		this.utilCompanyService.navigateToCompanyDetail(level, code);
	}

	removeContact(row: ContactPouchModel) {
		const updatedOrganization = _.cloneDeep(this.organization);
		updatedOrganization.contact_list.find(cl => cl._id == row._id).valid = false;
		this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
	}

	setRouteMetaInformation() {
		switch (this.currentContext) {
			case ContextApplicationItemCodeEnum.AGENT:
				this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle(
					'organizations'
				);
				this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(
					'organizations_contacts'
				);
				this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['organizations', 'organizations_contacts']);
				break;
			default:
				this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('contacts');
				this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['contacts']);
		}
	}

	unsetRouteMetaInformation() {
		if (!this.route.parent.component || this.route.parent.component === FramePageComponent) {
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		}
	}
}
