import { NgModule } from '@angular/core';
import { ArticleListSummaryValue } from './article-list-summary-value.directive';
import { ArticleTableWrapperCellDefaultHandler } from './article-table-wrapper-cell-default-handler.directive';

@NgModule({
	declarations: [
		ArticleListSummaryValue,
		ArticleTableWrapperCellDefaultHandler
	],
	exports: [
		ArticleListSummaryValue,
		ArticleTableWrapperCellDefaultHandler
	]
})
export class DirectiveModule {}
