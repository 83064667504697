import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { CourtesySpinnerComponent } from './courtesy-spinner/courtesy-spinner.component';
import { DownloadCouchComponent } from './download-couch/download-couch.component';
import { ContactEmailConfirmation } from './authentication-wrapper/contact-email-confirmation/contact-email-confirmation.component';
import { ClientCodeSelectComponent } from './authentication-wrapper/context-selection/context-selection.component';
import { LoginComponent } from './authentication-wrapper/login/login.component';
import { PasswordRecoveryComponent } from './authentication-wrapper/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from './authentication-wrapper/password-update/password-update.component';
import { AuthenticationWrapperComponent } from './authentication-wrapper/authentication-wrapper.component';
import { ContactComponent } from './contact/contact.component';
import { MediaCenterComponent } from './media-center/media-center.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrderDetailCatalogComponent } from './order/order-detail/main/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from './order/order-detail/main/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailVariationComponent } from './order/order-detail/order-detail-variation/order-detail-variation.component';
import { OrderComponent } from './order/order.component';
import { OrganizationDetailCreditComponent } from './organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailDestinationComponent } from './organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { OrganizationDetailFullDetailComponent } from './organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { OrganizationDetailOverviewComponent } from './organization/organization-detail/organization-detail-overview/organization-detail-overview.component';
import { OrganizationDetailComponent } from './organization/organization-detail/organization-detail.component';
import { OrganizationComponent } from './organization/organization.component';
import { ProbeTestComponent } from './probe-test/probe-test.component';

import { B2cAuthenticationWrapperComponent } from '../b2c/b2c-authentication-wrapper/b2c-authentication-wrapper.component';
import { PasswordCreateComponent } from './authentication-wrapper/password-create/password-create.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { TicketCenterWrapperComponent } from './ticket-center-wrapper/ticket-center.component';
import { OrganizationDetailCommentComponent } from './organization/organization-detail/organization-detail-comment/organization-detail-comment.component';
import { AccessRequestComponent } from './authentication-wrapper/access-request/access-request.component';
import { ArticleComponent } from './article/article.component';
import { ArticleDetailComponent } from './article/article-detail/article-detail.component';
import { RequestListComponent } from './request-list/request-list.component';
import { IllustrationsModule, AngularSpin8CoreOrganizationService } from '@saep-ict/angular-spin8-core';
import { NewsComponent } from './news/news.component';
import { SurveyCenterComponent } from './survey-center/survey-center.component';
import { AssetRequestCenterComponent } from './asset-request-center/asset-request-center.component';
import { ForecastComponent } from './forecast/forecast.component';
import { ReportsComponent } from './reports/reports.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { OrderDetailMainHeaderEditComponent } from './order/order-detail/main/order-detail-main-header-edit/order-detail-main-header-edit.component';
import { OrderDetailAsideHelpComponent } from './order/order-detail/aside/order-detail-aside-help/order-detail-aside-help.component';
import { OrderDetailAsideSummaryComponent } from './order/order-detail/aside/order-detail-aside-summary/order-detail-aside-summary.component';
import { RequestDetailComponent } from './request-list/request-detail/request-detail.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { OrderRevisionDetailComponent } from './order/order-revision-detail/order-revision-detail.component';
import { OrderDetailConfirmComponent } from './order/order-revision-detail/order-detail-confirm/order-detail-confirm.component';
import { FamilyComponent } from './family/family.component';
import { FamilyDetailComponent } from './family/family-detail/family-detail.component';

@NgModule({
	imports: [WidgetModule, SharedModule, IllustrationsModule],
	declarations: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		ClientCodeSelectComponent,
		NotFoundComponent,
		PasswordCreateComponent,
		AuthenticationWrapperComponent,
		ContactEmailConfirmation,
		OrganizationComponent,
		OrganizationDetailComponent,
		OrganizationDetailOverviewComponent,
		OrganizationDetailCreditComponent,
		OrganizationDetailDestinationComponent,
		OrganizationDetailFullDetailComponent,
		OrganizationDetailCommentComponent,
		ContactComponent,
		OrderComponent,
		OrderDetailCatalogComponent,
		OrderDetailCheckoutComponent,
		OrderDetailVariationComponent,
		MediaCenterComponent,
		ProbeTestComponent,
		CourtesySpinnerComponent,
		DownloadCouchComponent,
		B2cAuthenticationWrapperComponent,
		ReceiptComponent,
		TicketCenterWrapperComponent,
		AccessRequestComponent,
		ArticleComponent,
		FamilyComponent,
		ArticleDetailComponent,
		FamilyDetailComponent,
		RequestListComponent,
		RequestDetailComponent,
		NewsComponent,
		NewsDetailComponent,
		SurveyCenterComponent,
		AssetRequestCenterComponent,
		ForecastComponent,
		ReportsComponent,
		OrderDetailComponent,
		OrderDetailMainHeaderEditComponent,
		OrderDetailAsideHelpComponent,
		OrderDetailAsideSummaryComponent,
  		OrderRevisionDetailComponent,
  		OrderDetailConfirmComponent
	],
	exports: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		ClientCodeSelectComponent,
		NotFoundComponent,
		PasswordCreateComponent,
		AuthenticationWrapperComponent,
		OrganizationComponent,
		OrganizationDetailComponent,
		OrganizationDetailOverviewComponent,
		OrganizationDetailCreditComponent,
		OrganizationDetailDestinationComponent,
		OrganizationDetailFullDetailComponent,
		OrganizationDetailCommentComponent,
		ContactComponent,
		OrderComponent,
		OrderDetailCatalogComponent,
		OrderDetailCheckoutComponent,
		OrderDetailVariationComponent,
		CourtesySpinnerComponent,
		ReceiptComponent,
		AccessRequestComponent,
		ArticleComponent,
		ArticleDetailComponent,
		RequestListComponent,
		OrderRevisionDetailComponent,
		OrderDetailConfirmComponent
	],
	providers: [AngularSpin8CoreOrganizationService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageCommonsModule {}
