import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// model
import {
  MediaCenterFolderContent,
  MediaCenterModel,
  MediaCenterResponseModel,
  MoveItemListPayload
} from '../../../model/media-center.model';
import {MediaCenterService} from '../../../service/rest/media-center.service';
// widget & utility
import {UtilService} from '../../../service/util.service';
import * as _ from 'lodash';

@Component({
  selector: 'mc-dialog-name-edit',
  templateUrl: './dialog-move-item-list.component.html',
  styleUrls: ['./dialog-move-item-list.component.scss'],
})
export class DialogMoveItemListComponent implements OnInit {
  originPath = _.cloneDeep(this.utilService.context.value.filter.path);
  newPath = _.cloneDeep(this.utilService.context.value.filter);
  selectedFolderPathList: string[];
  treePosition: MediaCenterFolderContent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MediaCenterModel[],
    public dialogRef: MatDialogRef<DialogMoveItemListComponent>,
    private utilService: UtilService,
    public mediaCenterService: MediaCenterService
  ) {
    this.selectedFolderPathList = this.returnSelectedFolderPathList(data);
    this.getFolderList(this.originPath);
  }
  ngOnInit() {}

  returnSelectedFolderPathList(folderList: MediaCenterModel[]): string[] {
    const pathList: string[] = folderList.filter(i => i.fileType === 'folder').map(i => i.path);
    return pathList;
  }

  excludeSelectedFolderFromTreePosition(folder: MediaCenterFolderContent): MediaCenterFolderContent {
    const treePosition: MediaCenterFolderContent = {
      folders: folder.folders.filter(i => !this.selectedFolderPathList.includes(i.path))
    };
    return treePosition;
  }

  // navigation
  navigateTo(path: string) {
    this.newPath.path = path;
    this.newPath = _.cloneDeep(this.newPath);
    this.getFolderList(this.newPath.path);
  }

  // rest
  getFolderList(path: string) {
    const folderData: any = {
      path: path
    };
    this.mediaCenterService
      .getFolderContent(folderData)
      .then((res: MediaCenterResponseModel) => {
        this.treePosition = this.excludeSelectedFolderFromTreePosition(res.data);
      })
      .catch((err) => {
        console.log(err);
        this.utilService.loadSnackbarWithAsyncTranslatedAction(
          'mc.error.get_folder_content',
          'mc.general.close',
          2000
        );
      });
  }

  moveItemList() {
    const moveItemListPayload: MoveItemListPayload = {
      pathNew: this.newPath.path,
      pathList: this.data.map(i => i.path)
    };
    this.mediaCenterService
      .moveItemList(moveItemListPayload)
      .then((res: any) => {
        this.dialogRef.close(true);
        this.utilService.loadSnackbarWithAsyncTranslatedAction(
          'mc.success.generic',
          'mc.general.close',
          2000
        );
      })
      .catch((err) => {
        console.error(err);
        this.utilService.loadSnackbarWithAsyncTranslatedAction(
          'mc.error.move_item',
          'mc.general.close',
          2000
        );
      });
  }

}
