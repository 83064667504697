import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';

// misc
import {
	BaseStateModel,
	SubscribeManagerService,
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum,
	AngularCoreUtilService,
	FormControlMultipurposeService
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { map, take, debounceTime } from 'rxjs/operators';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

export interface ContextCodeEditOnChange {
	isValid: boolean;
	value: any;
}

@Component({
	selector: 'context-code-edit',
	templateUrl: './context-code-edit.component.html',
	styleUrls: ['./context-code-edit.component.scss'],
	providers: [SubscribeManagerService]
})
export class ContextCodeEditComponent implements OnInit {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	@Input() set configuration(c: FormControlMultipurposeModel.ConfigurationEdit) {
		if (c) {
			// form-control-multipurpose: recupero della mappa di form field, specifica per la
			// combinazione di context applicatione e context code in creazione
			this.formFieldList = this.formFieldMultipurposeService.returnFormFieldListWithRootFormId(
				c.formControlList,
				`${this.utilService.guid()}`
			);
			// creazione di un nuovo BehaviorSubject in rootFormValueList, l'associazione avviene
			// attraverso form_id, uguale in ogni elemento della mappa formFieldList
			this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, null);
			this.formFieldList = this.formFieldMultipurposeService.updateFormFieldListValue(
				this.formFieldList,
				c.value
			);
			this.createForm();
			this.formFieldMultipurposeService.updateFormControlAccordingToType(
				this.form,
				<FormControlMultipurposeModel.Item>{
					type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
					form_control_list: this.formFieldList
				},
				c.value
			);
		}
	}
	formFieldList: FormControlMultipurposeModel.Item[];

	@Output() onFormValueChange = new EventEmitter();

	form: FormGroup;

	subscribeFormDataPrefix: string = 'context-code-edit-form-value-change_';

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private formFieldMultipurposeService: FormControlMultipurposeService,
		private utilService: AngularCoreUtilService
	) {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	/**
	 * restituisce il valore totale del form a fronte di qualsiasi modifica ad opera di
	 * form-control-multipurpose (qualsiasi livello di annidamento)
	 *
	 * @returns {Observable<any>}
	 * @memberof DialogContextCodeEditComponent
	 */
	subscribeFormData(): Observable<any> {
		return this.form.valueChanges.pipe(
			// form-control-multipurpose ha bisigno di propagare eventi di change da qualsiasi livello
			// di annidamento verso il root form, inclusi gli eventi updateValueAndValidity(),
			// il take(1) impedisce un loop infinito dovuto al riassestamento dei validatori in vase a value
			take(1),
			debounceTime(100),
			map(async value => {
				value = this.utilService.deleteEmptyProperties(value);
				// viene propagato il valore del root form a tutte le istanze di form-control-multipurpose
				this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, value);
				// aggiorna i validatori del root form in base a value e scatena updateValueAndValidity()
				// per ognuno
				await this.formFieldMultipurposeService.updateFormControlAccordingToType(
					this.form,
					<FormControlMultipurposeModel.Item>{
						type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
						form_control_list: this.formFieldList
					},
					value
				);

				// emit
				const onChangeValue: ContextCodeEditOnChange = {
					isValid: this.form.valid,
					value: this.prepareSaveForm()
				};
				this.onFormValueChange.emit(onChangeValue);

				// dopo il trigger viene sottoscritta nuovamente la stessa subscribe in modo da riattivare
				// il listener
				this.subscribeManagerService.populate(
					this.subscribeFormData().subscribe(),
					this.subscribeFormDataPrefix + this.formFieldList[0].form_id
				);
			})
		);
	}

	// form
	createForm() {
		const controls = {};
		for (const field of this.formFieldList) {
			controls[field.name] = this.formFieldMultipurposeService.returnNewFormControl(
				field,
				field.name == 'level' ? field.value : field.value
			);
		}
		this.form = new FormGroup(controls);
		this.subscribeManagerService.populate(
			this.subscribeFormData().subscribe(),
			this.subscribeFormDataPrefix + this.formFieldList[0].form_id
		);
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		return this.utilService.deleteEmptyProperties(formModel);
	}

	// onFormSubmit() {
	// 	if (this.form.valid) {
	// 		this.onFormValueChange.emit(this.prepareSaveForm());
	// 	}
	// }
}
