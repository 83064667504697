import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IsMainOfList } from '@saep-ict/pouch_agent_models';
import { UserDetailModel } from '../../model/permission/user.model';

import _ from 'lodash';
import { BaseStateModel, AngularCoreUtilService, SentencecasePipe} from '@saep-ict/angular-core';


export class FormatPlaceholderSpecificCase {
	from: string;
	to: string;
}

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreUtilService extends AngularCoreUtilService {
	constructor(
		private router: Router,
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		public sentencecasePipe: SentencecasePipe
	) {
    super(snackBar, translate, sentencecasePipe);
	}

	hasAtLeastOnePermission(user: BaseStateModel<UserDetailModel>, permission: string[]) {
		if (user.data && user.data.permission) {
			for (let i = 0; i < permission.length; i++) {
				if (user.data.permission.find(p => p === permission[i])) {
					return true;
				}
			}
		}
		return false;
	}

  /** Per ritriggerare il constructor in product-detail.component, navigo a una route diversa e ritorno subito lì (se sto facendo una ricerca dalla pagina prodotto stesso) */
	redirectTo(uri: string, term?: string) {
		this.router
			.navigateByUrl('random', { skipLocationChange: true })
			.then(() => this.router.navigate([uri], { queryParams: { q: term } }));
	}

  /**
	 * Util method to return name and surname
	 * @param user
	 * @returns una stringa che concatena nome e cognome
	 */
	getUserNameFormatted(user: UserDetailModel): string {
		let formattedName = '';
		if (user.first_name) {
			formattedName = user.first_name;
		}
		if (user.last_name) {
			formattedName ? (formattedName = formattedName + ' ' + user.last_name) : (formattedName = user.last_name);
		}
		return formattedName;
	}

	returnIsMainOfList<T extends IsMainOfList>(list: T[] = []): T {
		return list.find(i => i.is_main_of_list);
	}


}
