
export const heading = [['Client', 'Code', 'Vat Number', 'Address', 'City']];

export const exportExcel = (list: any) => {
	let json = [];
	list.forEach(org => {
		let business_address = '';
		let business_city = '';
		org.destination_list.forEach(dest => {
				let a = dest.address;
				business_address =
					a.address
				if (a.zip_code) {
					business_city = a.locality +
				(a.province ? ' (' + a.province.label + ')' : '') + ' - ' +
				a.zip_code
				}	else {
					business_city = a.locality +
				(a.province ? ' (' + a.province.label + ')' : '')
				}
		});
		json.push({
			name: org.business_name ? org.business_name: '',
			code_erp: org.code_erp ? org.code_erp: '',
			vat_number: org.tax_data && org.tax_data.vat_number ? org.tax_data.vat_number.toUpperCase() : '',
			address: business_address,
			city: business_city
		});
	});
	return json;
};
