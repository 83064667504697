export enum CodeItem {
	ARTICLE_LIST_WITHOUT_CATEGORY = 'article-list-without-category'
}
export enum Level {
	BRAND = 'brand',
	LEAF = 'leaf',
	LINE = 'line',
	ROOT = 'root'
}
export enum TabOnTopStyleType {
	TAB = 'TAB',
	CHIP = 'CHIP',
	SELECT = 'SELECT'
}
export enum ChangeImageType {
	CREATE = 'CREATE',
	DELETE_ALL = 'DELETE_ALL'
	}
