import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ICardSliderConfiguration, ItemType } from '../../../widget/b2c/article/b2c-article-item/card-slider.models';
import { ViewTypeEnum } from '../../../enum/view-type.enum';
import { ArticlePouchModel, DivisionPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel, SentencecasePipe, SubscribeManagerService } from '@saep-ict/angular-core';
import { OrderActionEnum } from '../../../state/order/order.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { CallToActionConfig } from '../../../widget/call-to-action/call-to-action.component';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { AppUtilService } from '../../../service/util/app-util.service';
import { ConfigurationViewModel } from '../../../model/configuration.model';
import { ArticleRecap } from '../../../model/state/article-list-state.model';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import {
	AngularSpin8CoreUtilTranslateService,
	ArticleEnum,
	AuxiliaryTableStateModel,
	OrderStateModel,
	OrganizationStateModel,
	Placeholder,
	ROUTE_URL
} from '@saep-ict/angular-spin8-core';
import { AlertType } from '../../../widget/alert/alert.component';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import * as ConfigurationCustomerArticleAvailability from '../../../constants/configuration-customer/article/article-availability.constant';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';

@Component({
	selector: 'b2c-article-detail',
	templateUrl: './b2c-article-detail.component.html',
	styleUrls: ['./b2c-article-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class ProductDetailComponent implements OnDestroy {
	public viewTypeEnum = ViewTypeEnum;
	public ItemType = ItemType;

	config: ICardSliderConfiguration;

	article: ArticlePouchModel;
	relatedProducts: ArticlePouchModel[] = [];

	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configuration: ConfigurationViewModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel = new OrderStateModel();

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	articleDetail$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleDetail);

	articleDescription$: Observable<BaseStateModel<ArticleRecap>> = this.store.select(
		StateFeature.getArticleDescription
	);

	language$ = this.utilTranslateService.translate.onLangChange;
	
	subscribeList: SubscribeManagerItem[] = [
		{ key: 'data', observable: this.subscribeMainPipeData() },
		{ key: 'language-data', observable: this.subscribeLanguageData() }
	];

	callToActionConfig: CallToActionConfig = {
		text: 'Lorem ipsum',
		btnLabel: 'contact.contact_us',
		theme: 'accent'
	};

	placeholder = Placeholder;

	inputQuantity: number;
	quantityCanBeOrdered: number;

	galleryOptions: NgxGalleryOptions[];
	galleryImages: NgxGalleryImage[] = [];

	showContent: {
		description_add: boolean;
		technical_info: boolean;
	};

	inputQuantityUpdate = true;
	isAvailable = true;

	// Enum
	alertTypeEnum = AlertType;
	articleEnum = ArticleEnum;

	ROUTE_URL = ROUTE_URL;

	constructor(
		private snackBar: MatSnackBar,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store,
		private utilOrderService: UtilOrderService,
		private subscribeManagerService: SubscribeManagerService,
		private utilPriceService: UtilPriceService,
		private utilService: AppUtilService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe,
		private bucketManagerService: BucketManagerService
	) {
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	// angular lifecycle
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// static data
	loadStaticData() {
		this.configuration$.pipe(take(1)).subscribe(res => {
			this.configuration = res ? res.data : null;
		});
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res ? res.data : null;
		});
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
		this.articleList$.pipe(take(1)).subscribe(res => {
			this.articleList = res ? res.data : null;
		});
	}

	// observable data
	subscribeMainPipeData(): Observable<ArticlePouchModel> {
		return this.route.paramMap.pipe(
			mergeMap((routeParam: ParamMap) => {
				this.article = <ArticlePouchModel>{
					code_item: routeParam.get('product_id')
				};
				return this.order$;
			}),
			filter(
				(store: BaseStateModel<OrderStateModel>) =>
					!!(store && (store.data || store.type === OrderActionEnum.SKIP))
			),
			map((store: BaseStateModel<OrderStateModel>) => {
				if (store.data) {
					switch (store.type) {
						case OrderActionEnum.ERROR:
							throw new Error(OrderActionEnum.ERROR);
						case OrderActionEnum.UPDATE:
							this.order = store.data;
							break;
					}
				} else {
					this.utilOrderService.setOrderNewDraft<ConfigurationViewModel>(
						this.order,
						this.organization,
						this.configuration
					);
				}
				this.returnArticle(this.articleList);
				this.article = this.utilOrderService.mergeProductDetailInArticle([this.article], this.order)[0];

				if (this.inputQuantityUpdate) {
					const quantityAvailable =
						ConfigurationCustomerArticleAvailability.returnArticleAvailableQuantityConsideringCurrentOrder(
							1,
							this.article,
							this.organization
						);
					this.inputQuantity = quantityAvailable > 0 ? 1 : 0;
					this.quantityCanBeOrdered = _.cloneDeep(this.inputQuantity);	
				}

				this.isAvailable = ConfigurationCustomerArticleAvailability.returnArticleAvailability(this.article, this.organization);
				this.setLanguageContent();
				return this.article;
			})
		);
	}

	subscribeLanguageData(): Observable<void> {
		return this.language$.pipe(
			map(() => {
				this.setLanguageContent();
			})
		);
	}

	setLanguageContent() {
		this.showContent = {
			description_add:
				this.utilTranslateService.getTranslationFromLanguage(this.article.articleDescription.language_list).description_add ?
				true :
				false,
			technical_info:
				this.utilTranslateService.getTranslationFromLanguage(this.article.articleDescription.language_list).technical_info ?
				true :
				false
		};
		this.initializeGallery();
	}

	async initializeGallery() {
		this.galleryImages = [];
		const languageItem = this.utilTranslateService.getLanguageItemWithImageList(this.article);
		if (languageItem) {
			for (const attachment of languageItem.image_list) {
				const bucketLink =
					this.bucketManagerService.returnBucketManagerDownloadUrl(
						attachment.nameOnBucket,
						'article',
						`${this.article.code_item}/${languageItem.language}/gallery`
					);
				this.galleryImages.push({
					small: bucketLink,
					medium: bucketLink
				});	
			}
		} else {
			this.galleryImages.push({
				small: Placeholder.Image.article,
				medium: Placeholder.Image.article
			});	
		}
		this.galleryOptions = [
			{
				thumbnailsColumns: 4,
				imageArrows: true,
				imageAnimation: NgxGalleryAnimation.Slide,
				imageAutoPlayPauseOnHover: true,
				imageBullets: false,
				preview: false,
				imagePercent: 100,
				imageSize: NgxGalleryImageSize.Contain,
				thumbnails: this.galleryImages.length > 1 ? true : false
			}
		];
	}

	returnArticle(articleList: ArticlePouchModel[]) {
		this.article = articleList.find(article => article.code_item == this.article.code_item);
		const mainDivision = this.organization
			? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
			: {};
		this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem([this.article], mainDivision?.division);
		if (
			this.article.articleDescription.related_article_list &&
			this.article.articleDescription.related_article_list.length > 0
		) {
			this.getRelated();
		}
	}

	getRelated() {
		this.relatedProducts = [];
		for (const productCode of this.article.articleDescription.related_article_list) {
			const articleDetail: ArticlePouchModel = this.articleList.find(article => article.code_item == productCode);
			if (articleDetail) {
				this.relatedProducts.push(articleDetail);
			}
		}
		this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem(
			this.relatedProducts,
			this.organization
				? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list).division
				: null
		);
		this.config = {
			data: this.relatedProducts,
			medias: {
				md: 2,
				lg: 3
			},
			animation: {
				animationDuration: '.8s',
				easing: 'ease-in-out',
				loop: true
			}
		};
	}

	// form and actions
	changeValue(quantity: number) {
		this.quantityCanBeOrdered =
			ConfigurationCustomerArticleAvailability.returnArticleAvailableQuantityConsideringCurrentOrder(
				quantity,
				this.article,
				this.organization
			);
		if (quantity > this.quantityCanBeOrdered) {
			let message: string;
			if (this.quantityCanBeOrdered > 0) {
				message = this.sentencecasePipe.transform(
					`
						${this.utilTranslateService.translate.instant('article.quantity.not_available')}
						(${this.quantityCanBeOrdered} ${this.utilTranslateService.translate.instant('general.max')})
					`
				);
			} else {
				message = this.sentencecasePipe.transform(
					this.utilTranslateService.translate.instant('article.quantity.not_available')
				);
			}
			this.snackBar.open(message, 'OK', { duration: 5000 });
		}
		this.inputQuantity = quantity;
	}

	async addToCart() {
		// previene l'anomala assegnazione di un ordine privo di id durante il secondo salvataggio di un ordine
		// creato subito dopo l'invio di un altro ordine
		delete this.utilOrderService.orderChangeTemp;
		await this.utilOrderService.changeOrderArticle(
			{
				event: {
					target: {
						value: (this.article.ordered_quantity
							? this.article.ordered_quantity + this.inputQuantity
							: this.inputQuantity
						).toString()
					}
				},
				row: this.article,
				key: 'input_quantity'
			},
			this.order,
			this.organization,
			true,
			0
		);

		// notification
		// TODO: il trigger dello snakbar va spostato all'interno di un type tipo ORDER_SAVE_SUCCESS
		const snackBarRef = this.snackBar.open('Prodotto aggiunto al carrello', 'Apri il carrello', {
			duration: 3000
		});
		snackBarRef.afterDismissed().subscribe((info: MatSnackBarDismiss) => {
			if (info.dismissedByAction) {
				this.router.navigate([`/${ROUTE_URL.public}/${ROUTE_URL.cart}`]);
			}
		});
	}

	clickCallToAction() {
		//
	}

}
