import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export namespace ColumnListOrder {
	export const base: ITdDataTableColumnCustom[] = [
		{
			name: '_id',
			labelPath: 'receipt.field.document_number',
			label: null,
			width: { min: 140, max: 140 }
		},
		{
			name: 'header.status',
			labelPath: 'general.status.name',
			label: '',
			width: { min: 180, max: 200 },
			sortable: false
		},
		{
			name: 'header.price.total',
			labelPath: 'order.field.amount',
			label: '',
			width: 120,
			sortable: false,
			numeric: true
		}
	];
}
