import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService, AngularCoreUtilService } from '@saep-ict/angular-core';
import _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { StatisticsBaseSerieOrder } from '../../../model/statistics.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { AppUtilService } from '../../util/app-util.service';
import { MetricsDetail } from '../../../model/statistics.model';
import { ChartEnum, chartTimeRange30Days } from '@saep-ict/angular-spin8-core';

@Injectable()
export class AccountBalanceExpiredDetailChartConfigService extends BaseChartClass {
	metrics: MetricsDetail;

	cardDashboardConfigExpiredDetail: CardDashboardConfig = {
		title: 'chart.expired.title',
		subtitle: 'chart.expired.subtitle',
		helpText: 'chart.expired.helptext',
		icon: 'account_balance'
	};

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
		this.chart.type = 'line';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	// Functions

	createDataSerie() {
		const expired = this.utilService.findLeaf(this.statistics.data, 'expired');

		const serieTemp: number[] = [];
		expired.forEach((element: StatisticsBaseSerieOrder) => {
			serieTemp.push(element.value);
			this.fullLabels.push(element.date);
		});
		this.fullDataSets.push({
			label: 'expired',
			data: serieTemp
		});
	}

	populateChart(rangeDays: number = chartTimeRange30Days.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];

		this.fullDataSets.forEach(item => {
			let serie = _.cloneDeep(item);
			serie.data = item.data.slice(-rangeDays);
			tempSerie.push(serie);
		});

		this.addData(this.chart.datasets, tempSerie);
		this.addData(this.chart.labels, this.fullLabels.slice(-rangeDays));

		this.getMetrics(rangeDays);
		this.chart = _.cloneDeep(this.chart);
	}

	getMetrics(rangeDays: number) {
		this.statistics$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.metrics = this.formatMetrics(this.utilService.findLeaf(res.data.data, `metrics.expired.days_${rangeDays}`));
			});
	}

	formatMetrics(data) {
		const metrics = {
			average: data.average < 1 ? +data.average.toFixed(2) : +data.average.toFixed(0),
			trend: data.trend.toFixed(0)
		};
		return metrics;
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {
			label: function (context) {
				let label =
					translateService.instant('client.status.' + context.dataset.label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += context.parsed.y.toFixed(2);
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return {
				stacked: false,
				grid: {
					drawBorder: false,
					display: true,
					borderDash: [8, 6],
					color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					// Scala da usare
					// stepSize: 1000,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
					// callback: this.ticksCallback()
				}
			}
	}

	xAxesStyle() {
		return 	{
				stacked: false,
				grid: {
					drawBorder: false,
					display: false
				},
				ticks: {
					display: false
				}
			}
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				x: this.xAxesStyle(),
				y: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
