import { Directive, EventEmitter, HostListener, Output, HostBinding } from '@angular/core';

@Directive({
	selector: '[drop-area]'
})
export class DropAreaDirective {
	@Output() private filesChangeEmiter: EventEmitter<FileList> = new EventEmitter();

	@HostBinding('style.background') public background = '#F9F9F9';

	@HostListener('dragover', ['$event']) public onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#078BBD';
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#F9F9F9';
	}

	@HostListener('drop', ['$event']) public onDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		const files = evt.dataTransfer.files;
		if (files.length > 0) {
			this.background = '#F9F9F9';
			this.filesChangeEmiter.emit(files);
		}
	}
	constructor() {}
}
