import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	AngularCoreUtilService,
	SideBarPositionValues
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { delay, filter, map, take } from 'rxjs/operators';
import _ from 'lodash';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleRecap } from '../../../model/state/article-list-state.model';
import { TranslateService } from '@ngx-translate/core';
import {
	ArticleEnum,
	ContextApplicationItemCodeEnum,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import { ArticleDescriptionActionEnum, ArticleDescriptionStateAction } from '../../../state/article-description/article-description.actions';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import { FamilyListActionEnum, FamilyListStateAction } from '../../../state/family-list/family-list.actions';
import { ArticleTableConfigurationAction } from '../../../model/article.model';

@Directive({
	selector: '[spin8BackofficeArticleFamilyBaseDirective]'
})
export class BackofficeArticleFamilyBaseDirective implements OnInit, OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	articleList$: Observable<BaseStateModel<ArticleRecap, never>>;
	articleList: ArticlePouchModel[];

	articleAvailabilityState = Object.keys(ArticleEnum.Status.Stock).map(key => ({
		value: ArticleEnum.Status.Stock[key],
		key: key
	}));

	subscribeListBase: SubscribeManagerItem[];
	subscribeListForType: SubscribeManagerItem[];
	
	instanceType: string;
	instanceRouteParam: string;

	formFilters: FormGroup;

	listPageBaseData = <LocalListHandlerBaseModel<ArticlePouchModel>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'articleDescription.language_list.description',
					ConfigurationCustomerAppStructure.erp_has_erp ? 'code_erp' : 'code_item'
				]
			}
		},
		columnList: ConfigurationCustomerArticle.backofficeArticleColumnList,
		sort: {
			name: ConfigurationCustomerAppStructure.erp_has_erp ? 'code_erp' : 'code_item',
			order: 'ASC'
		},
		sortRemapObject: {
			description: 'articleDescription.language_list.description'
		}
	};

    sideBarPositionValues = SideBarPositionValues;

	articleListActionEnum: typeof ArticleDescriptionActionEnum | typeof FamilyListActionEnum;
	articleListStateAction: typeof ArticleDescriptionStateAction | typeof FamilyListStateAction;

	articleTableConfigurationAction: ArticleTableConfigurationAction = {
		gallery: true
	}


	constructor(
		public store: Store,
		public fb: FormBuilder,
		public subscribeManagerService: SubscribeManagerService,
		public router: Router,
		public route: ActivatedRoute,
		public translateService: TranslateService,
		public utilService: AngularCoreUtilService,
		public utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.loadStaticData();
		this.createFormFilters();
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue');
		this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(`catalogue_${this.instanceType}`);
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['catalogue',`catalogue_${this.instanceType}`]);
	}

	ngOnInit() {
		this.subscribeListBase = [
			{ key: 'articleList-data', observable: this.subscribeArticleList() }
		];
		ConfigurationSubscribeManager.init(
			this.subscribeListBase.concat(this.subscribeListForType),
			this.subscribeManagerService
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.instanceType = this.route.snapshot.data['instanceType'];
		this.listPageBaseData.languageKey = this.translateService.currentLang;
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	subscribeArticleList() {
		return this.articleList$.pipe(
			delay(0),
			filter((state: BaseStateModel<ArticleRecap>) => !!(state && state.data && state.data.article_list)),
			map((state: BaseStateModel<ArticleRecap>) => {
				switch (state.type) {
					case this.articleListActionEnum.SAVE_COMPLETE: {
						// tornando alla lista dopo aver modificato un dettaglio articolo va effettuato
						// l'update, in modo da prevenire errori entrando nuovamente in un dettaglio
						this.store.dispatch(this.articleListStateAction.update(state));
						break;
					}
					case this.articleListActionEnum.UPDATE: {
						this.articleList = [];
						for (const articleDescription of state.data.article_list) {
							if (articleDescription.valid) {
								const article: ArticlePouchModel =  {
									code_item: articleDescription.code_item,
									code_erp: articleDescription.code_erp,
									valid: articleDescription.valid,
									articleDescription: _.cloneDeep(articleDescription)
								}
								this.articleList.push(article);
							}
						}
						this.listPageBaseData.data = this.articleList;
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
					}
					case this.articleListActionEnum.ERROR: {
						throw new Error(this.articleListActionEnum.ERROR);
					}
				}

				return state;
			})
		);
	}

	// Form - sidebar

	createFormFilters() {
		this.formFilters = this.fb.group({
			availability_status: ''
		});
	}

	resetFilters() {
		this.formFilters.patchValue({
			availability_status: ''
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.articleList);
		if (this.formFilters && this.formFilters.value.availability_status) {
			filteredList = filteredList.filter(item => {
				return item.articleDescription.stock?.code_segnalation === this.formFilters.value.availability_status;
			});
		}
		this.listPageBaseData.data = filteredList;
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	goToArticleDetail(selectedArticle: ArticlePouchModel) {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.BACKOFFICE: {
				const code = selectedArticle.code_item;
				this.router.navigate([
					`${PATH_URL.PRIVATE}`,
					`${ROUTE_URL.catalog}`,
					`${this.instanceRouteParam}`,
					`${code}`
				]);
				break;
			}
		}
	}
}
