import { createAction, props } from '@ngrx/store';
import { BaseStateModel, BaseStateSerializerModel } from '@saep-ict/angular-core';
import { AuxiliaryTableStateModel } from '@saep-ict/angular-spin8-core';

export enum AuxiliaryTableActionEnum {
	UPDATE = '[auxiliary table] Update',
	UPDATE_ITEM = '[auxiliary table] Update Item',
	LOAD = '[auxiliary table] Load',
	LOAD_ITEM = '[auxiliary table] Load Item',
	COMPLETED = '[auxiliary table] Completed',
	SKIP = '[auxiliary table] Skip',
	RESET = '[auxiliary table] Reset',
	RESET_ITEM = '[auxiliary table] Reset Item',
	ERROR = '[auxiliary table] Error'
}

export namespace AuxiliaryTableStateAction {
	export const load = createAction(AuxiliaryTableActionEnum.LOAD);
	export const loadItem = createAction(
		AuxiliaryTableActionEnum.LOAD_ITEM,
		props<BaseStateSerializerModel<AuxiliaryTableStateModel>>()
	);
	export const update = createAction(
		AuxiliaryTableActionEnum.UPDATE,
		props<BaseStateModel<AuxiliaryTableStateModel>>()
	);
	export const updateItem = createAction(
		AuxiliaryTableActionEnum.UPDATE_ITEM,
		props<BaseStateSerializerModel<AuxiliaryTableStateModel>>()
	);
	export const completed = createAction(AuxiliaryTableActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const skip = createAction(AuxiliaryTableActionEnum.SKIP);
	export const reset = createAction(AuxiliaryTableActionEnum.RESET);
	export const resetItem = createAction(
		AuxiliaryTableActionEnum.RESET_ITEM,
		props<BaseStateSerializerModel<AuxiliaryTableStateModel>>()
	);
	export const error = createAction(AuxiliaryTableActionEnum.ERROR, props<BaseStateModel<any>>());
}
