<h1 mat-dialog-title>
  {{ 'mc.general.move_item_list' | translate | titlecase }}
</h1>
<div mat-dialog-content>
  <p class="subtitle">
    {{ 'mc.dialog_move_item.move_file_to' | translate }}
  </p>
  <mc-breadcrumb [configuration]="newPath" (pathChange)="navigateTo($event)">
  </mc-breadcrumb>
  <mat-list
    class="treePosition"
    role="list"
    *ngIf="
      treePosition && treePosition.folders && treePosition.folders.length > 0
    "
  >
    <div mat-subheader>
      <span>{{ 'mc.dialog_move_item.select_subfolder' | translate }}</span>
    </div>
    <mat-divider></mat-divider>
    <mat-list-item
      role="listitem"
      *ngFor="let item of treePosition.folders"
      (click)="navigateTo(item.path)"
    >
      <mat-icon class="folder" mat-list-icon>folder</mat-icon>
      <div mat-line>{{ item.name }}</div>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>

  <p class="subtitle">
    {{ 'mc.dialog_move_item.item_list_to_move' | translate }}
  </p>
  <mat-list dense role="list">
    <mat-list-item *ngFor="let item of data" role="listitem">
      {{ item.path }}
    </mat-list-item>
  </mat-list>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">
    {{ 'mc.general.cancel' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="moveItemList()"
    cdkFocusInitial
  >
    {{ 'mc.general.confirm' | translate }}
  </button>
</div>
