<div class="container-fluid">
	<!-- Ordini: Ordini attuali -->
	<div class="row">
		<div class="col-12">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigOrdersCurrent">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<highlight-number
							title="saep_ict_angular_spin8_core.status.order.TO_AUTHORIZE"
							subtitle="chart.current_orders.not_authorized"
							[value]="statisticsOrders?.to_authorize?.count || 0"
						></highlight-number>
					</div>
					<div class="col-sm-12 col-md-4">
						<highlight-number
							title="saep_ict_angular_spin8_core.status.order.PROCESSING"
							subtitle="chart.current_orders.transmitted"
							[value]="statisticsOrders?.processing?.count || 0"
						></highlight-number>
					</div>
					<div class="col-sm-12 col-md-4">
						<highlight-number
							title="saep_ict_angular_spin8_core.status.order.CONSOLIDATED"
							subtitle="chart.current_orders.processing"
							[value]="
								statisticsOrders?.consolidated?.count + statisticsOrders?.partially_fulfilled?.count ||
								0
							"
						></highlight-number>
					</div>
				</div>
			</card-dashboard>
		</div>
	</div>

	<!-- Ordini: Andamento ordini -->
	<div class="row">
		<div class="col-12">
			<card-dashboard
				[cardDashboardConfig]="orderTrendChartConfigService.cardDashboardConfigOrdersTrend"
				(changeTimeRange)="orderTrendChartConfigService.changeTimeRange($event, orderTrendChartConfigService)"
			>
				<chart-wrapper [config]="orderTrendChartConfigService.chart"></chart-wrapper>
			</card-dashboard>
		</div>
	</div>

	<div class="row">
		<!-- Ordini: Valore medio -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="orderAverageChartConfigService.cardDashboardConfigOrdersAverage"
				(changeTimeRange)="
					orderAverageChartConfigService.changeTimeRange($event, orderAverageChartConfigService)
				"
			>
				<div class="row">
					<div class="col-6 m-0">
						<div class="value-trend">
							<div class="value-trend__value">
								{{ orderAverageChartConfigService.averageValue | appFormatMultiplesCurrency }}
							</div>
							<div class="value-trend__trend">
								<icon-trend [value]="orderAverageChartConfigService.averageTrend"></icon-trend>
							</div>
						</div>
					</div>
					<div class="col-6 m-0">
						<chart-wrapper [config]="orderAverageChartConfigService.chart"></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>

		<!-- Ordini: Quantità -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				class="card-dashboard-accent"
				[cardDashboardConfig]="orderQuantityChartConfigService.cardDashboardConfigOrdersQuantity"
				(changeTimeRange)="
					orderQuantityChartConfigService.changeTimeRange($event, orderQuantityChartConfigService)
				"
			>
				<div class="row">
					<div class="col-6 m-0">
						<div class="value-trend">
							<div class="value-trend__value">
								{{ orderQuantityChartConfigService.averageValue | number: '1.2-2':'it' }}
							</div>
							<div class="value-trend__trend">
								<icon-trend [value]="orderQuantityChartConfigService.averageTrend"></icon-trend>
							</div>
						</div>
					</div>
					<div class="col-6 m-0">
						<chart-wrapper [config]="orderQuantityChartConfigService.chart"></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>
	</div>

	<div class="row">
		<!-- Ordini: Ordini recenti -->
		<div class="col-sm-12 col-md-12 col-lg-8">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigOrdersRecent">
				<order-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseLastOrder"
					[localListHandlerWidgetWrapperColumnList]="columns"
					(onItemSelect)="goToOrderDetail($event)"
					(selectCompany)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code)"
				>
				</order-list-wrapper>
			</card-dashboard>
		</div>

		<!-- Ordini: Prodotti più ordinati -->
		<div class="col-sm-12 col-md-12 col-lg-4">
			<card-dashboard
				[cardDashboardConfig]="orderBestOrderedChartConfigService.cardDashboardConfigOrdersBestOrdered"
				(changeTimeRange)="
					orderBestOrderedChartConfigService.changeTimeRange($event, orderBestOrderedChartConfigService)
				"
			>
				<chart-wrapper [config]="orderBestOrderedChartConfigService.chart"></chart-wrapper>
			</card-dashboard>
		</div>
	</div>

	<div class="row">
		<!-- Venduto: andamento -->
		<div class="col-sm-12 col-md-12 col-lg-8">
			<card-dashboard
				[cardDashboardConfig]="fulfilledTrendChartConfigService.cardDashboardConfigFulfilledTrend"
				(changeTimeRange)="
					fulfilledTrendChartConfigService.changeTimeRange($event, fulfilledTrendChartConfigService)
				"
			>
				<div class="row mb-20">
					<div class="col-sm-12 col-md-4">
						<highlight-number
							title="fulfilled.total"
							[value]="fulfilledTrendChartConfigService.fulfilledTotal | appFormatMultiplesCurrency"
						></highlight-number>
					</div>
					<div class="col-sm-12 col-md-4">
						<highlight-number
							title="fulfilled.average"
							[value]="fulfilledTrendChartConfigService.fulfilledAverage | appFormatMultiplesCurrency"
						></highlight-number>
					</div>
					<div class="col-sm-12 col-md-4">
						<highlight-number
							title="order.count"
							[value]="fulfilledTrendChartConfigService.fulfilledCount"
						></highlight-number>
					</div>
				</div>
				<ng-container *ngIf="fulfilledTrendChartConfigService.chart.datasets.length">
					<chart-wrapper [config]="fulfilledTrendChartConfigService.chart"></chart-wrapper>
				</ng-container>
			</card-dashboard>
		</div>

		<!-- Venduto: prodotti più venduti -->
		<div class="col-sm-12 col-md-12 col-lg-4">
			<card-dashboard
				[cardDashboardConfig]="productsBestSellerChartConfigService.cardDashboardConfigProductsBestSeller"
				(changeTimeRange)="
					productsBestSellerChartConfigService.changeTimeRange($event, productsBestSellerChartConfigService)
				"
			>
				<ng-container *ngIf="productsBestSellerChartConfigService.chart.datasets.length">
					<chart-wrapper [config]="productsBestSellerChartConfigService.chart"></chart-wrapper>
				</ng-container>
			</card-dashboard>
		</div>
	</div>

	<div class="row">
		<!-- Clients : top5 -->
		<div class="col-sm-12 col-md-12 col-lg-8">
			<card-dashboard
				[cardDashboardConfig]="cardDashboardConfigClientsTop5"
				(changeTimeRange)="changeTimeRangeClientsTop5($event)"
			>
				<ng-container *ngIf="clientsTop5TableConfigService.currentDataSet.length; else noData">
					<td-data-table
						#dataTableClientsTop5
						[data]="clientsTop5TableConfigService.currentDataSet"
						[columns]="clientColumns"
					>
						<ng-template tdDataTableTemplate="business_name" let-row="row" let-value="value">
							<p>
								{{ value }}
								<br />
								<small class="opacity-50">
									{{ row.vat_number }}
								</small>
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="total" let-row="row" let-value="value">
							<p>
								{{ value | appFormatMultiplesCurrency }}
								<br />
								<small class="opacity-50">
									{{ row.count }}
									{{ (row.count > 1 ? 'saep_ict_angular_spin8_core.page.order.name_plural' : 'order.name') | translate }}
								</small>
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="address" let-row="row" let-value="value">
							<p>
								{{ value.address }}
								<br />
								<small class="opacity-50">
									{{ value.zip_code }} {{ value.locality }} ({{ value.province }})
								</small>
							</p>
						</ng-template>
					</td-data-table>
				</ng-container>
				<ng-template #noData>
					<chart-no-data icon="grid_on"></chart-no-data>
				</ng-template>
			</card-dashboard>
		</div>

		<div class="col-sm-12 col-md-12 col-lg-4">
			<div class="row height-100">
				<!-- Clienti : attivi -->
				<div class="col-12">
					<card-dashboard
						[cardDashboardConfig]="clientsActiveChartConfigService.cardDashboardConfigClientsActive"
						(changeTimeRange)="
							clientsActiveChartConfigService.changeTimeRange($event, clientsActiveChartConfigService)
						"
					>
						<div class="row">
							<div class="col-6 m-0">
								<div class="value-trend">
									<div class="value-trend__value">
										{{ clientsActiveChartConfigService.averageValue | number: '1.2-2':'it' }}
									</div>
									<div class="value-trend__trend">
										<icon-trend [value]="clientsActiveChartConfigService.averageTrend"></icon-trend>
									</div>
								</div>
							</div>
							<div class="col-6 m-0">
								<chart-wrapper [config]="clientsActiveChartConfigService.chart"></chart-wrapper>
							</div>
						</div>
					</card-dashboard>
				</div>

				<!-- Clienti : inattivi -->
				<div class="col-12 mt-3">
					<card-dashboard
						[cardDashboardConfig]="clientsInactiveChartConfigService.cardDashboardConfigClientsInactive"
						(changeTimeRange)="
							clientsInactiveChartConfigService.changeTimeRange($event, clientsInactiveChartConfigService)
						"
					>
						<div class="row">
							<div class="col-6 m-0">
								<div class="value-trend">
									<div class="value-trend__value">
										{{ clientsInactiveChartConfigService.averageValue | number: '1.2-2':'it' }}
									</div>
									<div class="value-trend__trend">
										<icon-trend
											[value]="clientsInactiveChartConfigService.averageTrend"
										></icon-trend>
									</div>
								</div>
							</div>
							<div class="col-6 m-0">
								<chart-wrapper [config]="clientsInactiveChartConfigService.chart"></chart-wrapper>
							</div>
						</div>
					</card-dashboard>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12 statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div>
</div>
