import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { AuxiliaryTabeleGeographicTree } from '@saep-ict/angular-spin8-core';

export enum AuxiliaryTableGeographicTreeActionEnum {
	UPDATE = '[auxiliary table geographic-tree] Update',
	LOAD = '[auxiliary table geographic-tree] Load',
	SKIP = '[auxiliary table geographic-tree] Skip',
	RESET = '[auxiliary table geographic-tree] Reset',
	ERROR = '[auxiliary table geographic-tree] Error'
}

export namespace AuxiliaryTableGeographicTreeStateAction {
	export const load = createAction(AuxiliaryTableGeographicTreeActionEnum.LOAD);
	export const update = createAction(
		AuxiliaryTableGeographicTreeActionEnum.UPDATE,
		props<BaseStateModel<AuxiliaryTabeleGeographicTree.StateModel>>()
	);
	export const skip = createAction(AuxiliaryTableGeographicTreeActionEnum.SKIP);
	export const reset = createAction(AuxiliaryTableGeographicTreeActionEnum.RESET);
	export const error = createAction(AuxiliaryTableGeographicTreeActionEnum.ERROR, props<BaseStateModel<any>>());
}
