import { CompanyModel } from './company.model';
import { ProductListModel } from './product.model';
import { ListStructureModel } from '@saep-ict/angular-core';
import { OrderEnum } from '../enum/order/order.enum';
import { DiscountModel } from '@saep-ict/pouch_agent_models';

// list
export interface OrderListModel extends ListStructureModel<OrderModel[]> {}

// details
export interface OrderModel {
	company?: CompanyModel;
	id?: string;
	client_code?: string;
	number?: string;
	id_protocol?: string;
	id_erp?: string;
	client?: string;
	date?: string;
	due_date?: string;
	status?: string;
	tot?: number;
	currency?: string;
	shipping_address?: string;
	reference?: string;
	note?: string;
	discount_agent?: DiscountModel;
	reason?: string;
	payment?: string;
	products?: ProductListModel;
	destination?: string;
	indirizzo?: string;
}

export interface OrderStateCount {
	name: string;
	number: number;
}

export class OrderPriceMap {
	total: OrderPriceMapItem;
	article: OrderPriceMapItem;
	vat: OrderPriceMapItem;
	freeLines: OrderPriceMapItem;
	shipping: OrderPriceMapItem;
	stock_type: OrderPriceMapItem;
	discount: OrderPriceMapItem;
	constructor(
		total?: OrderPriceMapItem,
		article?: OrderPriceMapItem,
		vat?: OrderPriceMapItem,
		shipping?: OrderPriceMapItem,
		freeLines?: OrderPriceMapItem,
		discount?: OrderPriceMapItem
	) {
		this.total = total || {
			label: OrderEnum.PriceMapType.TOTAL,
			value: 0,
			main: true
		};
		this.article = article || {
			label: OrderEnum.PriceMapType.ARTICLE,
			value: 0
		};
		this.shipping = shipping || {
			label: OrderEnum.PriceMapType.SHIPPING,
			value: 0
		};
		if (freeLines) {
			this.freeLines = freeLines;
		}
		this.vat = vat || {
			label: OrderEnum.PriceMapType.VAT,
			value: 0
		};
		this.discount = discount || {
			label: OrderEnum.PriceMapType.DISCOUNT,
			value: 0
		};
	}
}

export interface OrderPriceMapItem {
	label: OrderEnum.PriceMapType;
	value: number;
	main?: boolean;
}
