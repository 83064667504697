import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// model
import { MediaCenterModel } from '../../../model/media-center.model';

@Component({
  selector: 'mc-dialog-name-edit',
  templateUrl: './dialog-name-edit.component.html',
  styleUrls: ['./dialog-name-edit.component.scss'],
})
export class DialogNameEditComponent implements OnInit {
  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogNameEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaCenterModel
  ) {
    this.createForm();
    if (this.data) {
      this.setValueFromModelForm();
    }
  }
  ngOnInit() {}

  // form
  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
  }
  setValueFromModelForm() {
    this.form.setValue({
      name: this.data,
    });
  }
  prepareSaveForm(): string {
    const formModel = this.form.value;
    const saveForm: string = formModel.name;
    return saveForm;
  }
  onFormSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.prepareSaveForm());
    }
  }
}
