import { BaseStateModel } from '@saep-ict/angular-core';
import { createReducer, on } from '@ngrx/store';
import {
	PermissionAuxiliaryTableActionEnum,
	PermissionAuxiliaryTableStateAction
} from './permission-auxiliary-table.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { PermissionAuxiliaryTableStateModel } from '@saep-ict/angular-spin8-core';

export namespace PermissionAuxiliaryTableStateReducer {
	// export const initialState: BaseStateModel<PermissionAuxiliaryTableStateModel> = null;
	// TODO: eliminare il seguente mock
	export const initialState: BaseStateModel<PermissionAuxiliaryTableStateModel> = null;

	const _reducer = createReducer(
		initialState,
		on(PermissionAuxiliaryTableStateAction.load, state => state),
		on(PermissionAuxiliaryTableStateAction.update, (state, auxiliaryTable) => auxiliaryTable),
		on(PermissionAuxiliaryTableStateAction.completed, (state, auxiliaryTable) => ({
			data: state ? state.data : null,
			type: auxiliaryTable.type
		})),
		on(PermissionAuxiliaryTableStateAction.reset, () => initialState),
		on(PermissionAuxiliaryTableStateAction.error, (state, auxiliaryTable) => ({
			data: state ? state.data : null,
			type: auxiliaryTable.type
		}))
	);

	export function reducer(
		state: BaseStateModel<PermissionAuxiliaryTableStateModel>,
		action: TypedAction<PermissionAuxiliaryTableActionEnum>
	) {
		return _reducer(state, action);
	}
}
