import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AngularCoreUtilService, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { OrderStateModel } from '../model/state/order-state.model';
import { OrderRowModel } from '../model/table/order-util.model';

@Directive({
	selector: '[articleListStructureWrapperBase]'
})
export class ArticleListStructureWrapperBase extends LocalListHandlerWidgetWrapper {
	@Input() order: OrderStateModel;
	@Input() organization: OrganizationPouchModel;
	@Output() onArticleChange = new EventEmitter<OrderRowModel>();
	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store
	) {
		super(utilService, translateService, store);
	}

	onArticleChangeHandler(e: OrderRowModel) {
		const i = this.utilService.getElementIndex(this.localListHandlerData.data, 'code_item', e.row.code_item);
		this.localListHandlerData.data[i] = e.row;
		this.onArticleChange.emit(e);
	}
}
