<ng-container *ngIf="_commentList && _commentList.length; else noComments">
	<div class="comment" *ngFor="let comment of _commentList; let i = index">
		<div class="comment__wrapper">
			<div class="comment__title">
				<span class="comment__title__user">
					{{ comment.user_id }}
				</span>
				<span class="comment__title__date">
					{{ comment.date_creation | date: 'dd/MM/yyyy' }}
					{{ comment.date_creation | date: 'HH:mm' }}
				</span>
				<div class="flex-span"></div>
				<span class="comment__actions" *ngIf="canEdit">
					<button
						mat-icon-button
						aria-label="Edit comment"
						[matTooltip]="'comment.edit' | translate | sentencecase"
						(click)="onEdit.emit(i)"
					>
						<mat-icon>edit</mat-icon>
					</button>
					<button
						mat-icon-button
						aria-label="Delete comment"
						[matTooltip]="'comment.remove' | translate | sentencecase"
						(click)="onDelete.emit(i)"
					>
						<mat-icon>clear</mat-icon>
					</button>
				</span>
			</div>
			<div class="comment__content">
				{{ comment.text }}
			</div>
		</div>
	</div>
</ng-container>
<ng-template #noComments>
	<p class="no-data">{{ 'comment.not_found' | translate | sentencecase }}</p>
</ng-template>
