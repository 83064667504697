<app-page-header [title]="searchTerm"></app-page-header>

<section class="filter-simple">
	<div class="container">
		<div class="row">
			<div class="col-12 filter-simple__viewtype">
				<app-viewtype-toggle
					[viewTypeDefault]="viewType"
					(changingViewList)="viewType = $event"
				></app-viewtype-toggle>
			</div>
		</div>
	</div>
</section>

<b2c-article-list-wrapper
	[localListHandlerWidgetWrapperData]="listPageBaseData"
	[localListHandlerPaginatorShow]="true"
	[viewType]="viewType"
></b2c-article-list-wrapper>
