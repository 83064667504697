import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TicketCenterConfigurationModel } from '../../model/configuration.model';
import { ITicketCenterConfigModel } from '../../model/lib-app-config.interface';
import { StateFeature } from '../../store';

@Component({
  selector: 'tc-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss']
})
export class StatusSelectorComponent implements OnInit {

  @Input() currentStatus: string;
  @Output() onStatusChange: EventEmitter<string> = new EventEmitter();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserStore);
	user: UserDetailModel;

  contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
  configuration: TicketCenterConfigurationModel;
  libConfiguration$: Observable<BaseStateModel<ITicketCenterConfigModel>> = this.store.select(StateFeature.getTicketCenterConfiguration);

  constructor(
    private store: Store<any>
  ) {
    this.user$.pipe(take(1)).subscribe(store => {
			this.user = store ? store.data : null;
		});
    this.libConfiguration$.pipe(take(1)).subscribe(store => {
			this.configuration = store.data.customConfiguration;
		});
  }

  ngOnInit() {

  }

  changeStatus(e: string){
    this.onStatusChange.emit(e);
  }

}
