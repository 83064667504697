import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalListHandlerBaseModel, ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../../state';
import { take } from 'rxjs/operators';
import { ContactListWrapperComponent } from '../../../../../widget/contact/contact-list-wrapper/contact-list-wrapper.component';
import { Store } from '@ngrx/store';
import { ContextApplicationItemCodeEnum, OrganizationStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerContact from '../../../../../constants/configuration-customer/contact/contact.constant';
import { ITdDataTableColumn } from '@covalent/core/data-table';

@Component({
	selector: 'app-b2c-organization-detail-full-detail',
	templateUrl: './b2c-organization-detail-full-detail.component.html',
	styleUrls: ['./b2c-organization-detail-full-detail.component.scss']
})
export class B2cOrganizationDetailFullDetailComponent implements OnInit {
	@ViewChild(ContactListWrapperComponent) contactListWrapperComponent: ContactListWrapperComponent;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	currentContext: ContextApplicationItemCodeEnum;
	columns: ITdDataTableColumn[];

	public listPageBaseData = <LocalListHandlerBaseModel<ContactPouchModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'full_name',
			order: 'ASC'
		},
		data: []
	};

	constructor(
		private store: Store) {
	}

	ngOnInit() {
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res && res.data ? res.data : null;
			if (this.organization) {
				const contacts: ContactPouchModel[] = this.organization.contact_list || [];
				this.listPageBaseData.data = contacts;
			}
		});	
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res && res.data ? res.data : null;
			this.currentContext = this.user.current_permission.context_application;
			this.columns = ConfigurationCustomerContact.columnList(this.currentContext);	
		})	
	}	
}
