import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	HostBinding,
	AfterViewInit,
	OnDestroy,
	Inject,
	PLATFORM_ID
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { MediaReplayService } from '../../service/util/media-replay.service';

@Component({
	selector: '[list-wrapper]',
	templateUrl: './list-wrapper.component.html',
	styleUrls: ['./list-wrapper.component.scss']
})
export class ListWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
	// se true comporta la visualizzazione del sidenav aperta in init e in mode 'side'
	@Input() filterOpened: boolean;
	// se il valore viene passato
	// - l'host prende una dimensione fissa e l'overflow viene gestito dalla perfect-scroll
	// - height viene sovrascritto
	@Input() minHeight: string;
	@HostBinding('style.height') hostHeight;
	@ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
	// permette di calcolare la distanza tra l'host e il top del device
	@ViewChild('offsetAnchor', { static: false, read: ElementRef }) offsetAnchor: ElementRef;

	@Input() mode: 'side' | 'over';
	@Input() sidenavWidth = 'auto';

	@Input() titleSidebar = 'Filtri';

	height: string = null;
	private _mediaSubscription: Subscription;

	constructor(public mediaReplayService: MediaReplayService, @Inject(PLATFORM_ID) private platform_id: string) {}

	ngOnInit() {
		if (!this.mode) {
			if (this.filterOpened) {
				this.mode = 'side';
			} else {
				this.mode = 'over';
			}
		}
	}

	ngAfterViewInit() {
		this._mediaSubscription = this.mediaReplayService.media
			.pipe(debounceTime(100))
			.subscribe((change: BreakpointState) => {
				if (isPlatformBrowser(this.platform_id)) {
					if (this.minHeight) {
						this.hostHeight = this.minHeight;
						this.height = this.minHeight;
					} else {
						this.height =
							this.mediaReplayService.getRemainingDeviceHeight(this.offsetAnchor.nativeElement) + 'px';
					}
				}
			});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}
}
