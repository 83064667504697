<page-with-sidebar
	[isRoot]="
		user.current_permission.context_application === contextApplicationItemCodeEnum.B2B || !organization
			? true
			: false
	"
	[sideBarPosition]="sideBarPositionValues.RIGHT"
>
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<order-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToOrderDetail($event)"
				(selectCompany)="
					utilCompanyService.navigateToCompanyDetail(
						$event.level,
						$event.code,
						user.current_permission.context_application
					)
				"
			>
			</order-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper>
			<ng-container main-content>
				<form-dynamic
					[configuration]="formDynamicConfiguration"
					(valueChange)="onFormValueChange($event)"
				></form-dynamic>
				<button
					*ngIf="configurationCustomerCalcSheet.enableExportOrder[user.current_permission.context_application]"
					class="utility-button"
					(click)="exportExcel()"
				>
					<mat-icon class="download">file_download</mat-icon>
					<span>{{ 'general.export_xls' | translate | sentencecase }}</span>
				</button>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>

<button
	*ngIf="configurationCustomerOrder.canCreate(user.current_permission.context_application, organization)"
	class="mat-fab-button"
	[ngClass]="{
		'mat-fab-button__b2c': user.current_permission.context_application === contextApplicationItemCodeEnum.B2C
	}"
	(click)="orderDraftCreate()"
	mat-fab
	[matTooltip]="'order.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	color="primary"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'order.add_new' | translate | sentencecase }}</span>
</button>
