<div
	*ngIf="webSocketChannelList && webSocketChannelList.length > 0"
	class="process-queue"
>
	<mat-card>
		<mat-toolbar (click)="toggleExpandedState()">
			<mat-toolbar-row>
				{{ 'saep_ict_angular_core.at.general.download' | translate | titlecase }}
				<span class="flex-span"></span>
        <mat-icon [ngClass]="{ isExpanded: isExpanded }">expand_less</mat-icon>
			</mat-toolbar-row>
		</mat-toolbar>
		<mat-card-content  [style.height]="isExpanded ? getTotalHeight() : '0px' " >
			<ng-scrollbar [style.max-height]="getTotalHeight()" #scrollbar>
				<mat-list role="list">
					<ng-container *ngFor="let channel of webSocketChannelList">
						<ng-container *ngFor="let item of channel.processList; let i = index">
							<mat-list-item
								role="listitem"
								class="divider-bottom"
							>
								<div class="list-item-wrapper">
								  <div class="left">
									<mat-spinner
									  *ngIf="false ||
										!item.message.header.progress ||
										item.message.header.progress < 100;
										else isCompleted
									  "
									  [mode]="item.message.header.progress ? 'determinate' : 'indeterminate'"
									  diameter="30"
									  [value]="item.message.header.progress"
									  strokeWidth="4"
									  class="progress-icon"
									></mat-spinner>
									<ng-template #isCompleted>
									  <mat-icon class="progress-icon progress-icon__is-complete">check_circle</mat-icon>
									</ng-template>
									<span>
									  {{
										(
										  item.message.header.description ?
										  item.message.header.description :
										  ('saep_ict_angular_core.websocket_state.' + item.type + '.' + item.message.header.state)
										) | translate
									  }}
									  {{ item.message.header.progress ? item.message.header.progress + "%" : "" }}
									</span>

								  </div>
								  <div class="right">
									<button
									mat-mini-fab
									class="progress-icon progress-icon__stop"
									(click)="processStop.emit({idChannel: channel.id, idProcess: item.message.header.id})"
									[matTooltip]="'mc.general.process_delete' | translate"
									type="button"
								  >
									<mat-icon>clear</mat-icon>
								  </button>
								  </div>
								</div>
							</mat-list-item>
						</ng-container>
					</ng-container>
				</mat-list>
			</ng-scrollbar>
		</mat-card-content>
	</mat-card>
</div>
