import { BodyTablePouchModel, DivisionPouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { Injector } from '@angular/core';
import { AngularSpin8CoreUtilService } from '@saep-ict/angular-spin8-core';

const injector = Injector.create({
	providers: [
		{ provide: AngularSpin8CoreUtilService, deps: [] }
	]
});

const utilService = injector.get(AngularSpin8CoreUtilService);

export const getOrganizationPreference = (
    propertyOrganizationDivision: string,
    collection: BodyTablePouchModel[],
    organization: OrganizationPouchModel
): string => {
    let result = null;
    if (organization.division_list) {
        const organizationDivision: DivisionPouchModel = organization.division_list.find(
            division =>
                division.division ===
                utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list).division
        );
        if (organizationDivision && organizationDivision[propertyOrganizationDivision] && collection) {
            const defaultObj = collection.find(
                item => item.code_item === organizationDivision[propertyOrganizationDivision]
            );

            if (defaultObj && defaultObj.code_item) {
                result = defaultObj.code_item;
            }
        }
    }
    return result;
}