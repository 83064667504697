import { Injector } from '@angular/core';
import {
  FormControlMultipurposeEnum,
	FormControlMultipurposeModel,
	FormControlMultipurposeService
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { creationFieldMapContextCodeOrganizationBase } from './creation-field-map-context-code-organization-base.const';
import { creationFieldMapContextCodeOrganizationPartialTaxData } from './context-code-organization-partial/creation-field-map-context-code-organization-partial-taxData.const';

const injector = Injector.create({
	providers: [{ provide: FormControlMultipurposeService, deps: [] }]
});

const formControlMultipurposeService = injector.get(FormControlMultipurposeService);

export const creationFieldMapContextCodeOrganizationBackoffice: FormControlMultipurposeModel.Item[] =
  formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
    formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
      formControlMultipurposeService.returnFormFieldListWithSomeColumnWidth(
        _.cloneDeep(creationFieldMapContextCodeOrganizationBase),
        ['business_name'],
        12
      ),
      ['level', 'organization_type'],
      false
    ),
    ['destination_list'],
    true
  )
  .concat(_.cloneDeep(creationFieldMapContextCodeOrganizationPartialTaxData));
