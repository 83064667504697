import { Route } from '@angular/router';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { CourtesySpinnerComponent } from '../page/commons/courtesy-spinner/courtesy-spinner.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';

export const courtesyRoute: Route = {
	path: ROUTE_URL.courtesy,
	component: CourtesySpinnerComponent,
	resolve: {
		meta: MetaDataResolver
	},
	data: {
		meta: {
			title: 'Waiting...',
			description: 'In attesa di redirect'
		}
	},
	children: []
};
