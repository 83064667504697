import { Injectable } from '@angular/core';

// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ReceiptListActionEnum, ReceiptListStateAction } from './receipt-list.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { ReceiptStateModel } from '../../model/state/receipt-state.model';
import { PouchDbConstant, PouchDbModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class ReceiptListEffects {
	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ReceiptListActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<ReceiptStateModel[]>) => from(this.getAllReceipts(action))),
			map((action: BaseStateModel<ReceiptStateModel[]>) => ReceiptListStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(ReceiptListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private utilPouchService: UtilPouchService) {}

	async getAllReceipts(action: BaseStateModel<ReceiptStateModel[]>): Promise<BaseStateModel<ReceiptStateModel[]>> {
		const documentName = 'invoice';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
		};

		await this.utilPouchService
			.allDocs<ReceiptStateModel>(allDocsParam, PouchDbModel.PouchDbDocumentType.ORDER)
			.then(res => {
				action.data = res.data;
			})
			.catch(err => console.log(err));

		return action;
	}
}
