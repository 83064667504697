import { Type } from '@angular/core';
import { ILoaderService } from './loader-service.interface';
import { ITicketCenterAppConfig } from './lib-app-config.interface';

export interface IEnvironment {
	[key: string]: boolean;
}

export interface ILibModelOptions {
	env: IEnvironment;
	loaderSerivce?: Type<ILoaderService>;
	appConfig: Type<ITicketCenterAppConfig>;
}
