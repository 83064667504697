import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
	concatMap,
	EMPTY,
	finalize,
	from,
	lastValueFrom,
	map,
	Observable,
	reduce,
	Subject,
	Subscription,
	takeUntil,
	tap
} from 'rxjs';
import { FileToUpload, Upload } from './upload';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import { AttachmentListManager } from '../../model/widget/attachmment-list-manager.model';
import { RestAttachmentListManagerService } from '../rest/rest-attachment-list-manager.service';
import { APP_CONFIG_TOKEN, ISaepIctAngularCoreAppConfig } from '../../model/structure/lib-app-config.interface';
import { AngularCoreUtilService } from '../util/util.service';

interface UrlResumable {
	urlResumable: string;
	info: string;
}


@Injectable({ providedIn: 'root' })
export class UploadService implements OnDestroy {
	private progressSubscription: Subscription;
	private cachedObservable$: Subject<Upload>;
	private uploadCompleted$: Subject<AttachedFile>;
	private files: FileToUpload[] = [];
	private pathPrefix: string;


	private readonly chunkSize = 256 * 2048 * 12 // per google deve essere un multiplo di 256
	private progressSubject: Subject<any>;
	private stopSignal: Subject<any>;

	constructor(
		private http: HttpClient,
		private restAttachmentListManagerService: RestAttachmentListManagerService,
		@Inject(APP_CONFIG_TOKEN) protected appConfig: ISaepIctAngularCoreAppConfig,
		private utilService: AngularCoreUtilService
	) {
		this.appConfig['config$'].subscribe(config => {
			if (config && config.bucketManager && config.bucketManager.be_url) {
				this.pathPrefix = config.bucketManager.be_url;
			}
		});
	}

	get hasFilesToUpload(): boolean {
		return this.files.length > 0;
	}

	get isUploadInProgress(): boolean {
		return this.files.length > 0 && this.cachedObservable$ != null;
	}

	get fileList(): FileToUpload[] {
		return this.files;
	}

	get uploadInProgressObservable(): Observable<Upload> {
		return this.cachedObservable$;
		//      ? this.cachedObservable$.asObservable()
		//      : EMPTY;
	}


	ngOnDestroy() {
		this.progressSubscription.unsubscribe();
		this.progressSubject.unsubscribe();
	}

	addFileToUpload(file: File, configuration: AttachmentListManager.Configuration<AttachedFile>) {
		this.files.push({ file, configuration });
	}

	startUpload(): Observable<AttachedFile> {
		if (this.uploadCompleted$) {
			return this.uploadCompleted$;
		} else if (this.files.length > 0) {

			for (const element of this.files) {
				element.progress = { state: 'IN_PROGRESS', progress: 0 };
			}
			this.uploadCompleted$ = new Subject();
			return this.upload();
		} else {
			return EMPTY;
		}
	}

	async deleteAttachmentList(
		attachmentList: AttachedFile[],
		configuration: AttachmentListManager.Configuration<AttachedFile>
	): Promise<void> {
		try {
			const restDeletePayload: AttachmentListManager.RestDeletePayload = {
				pathList:
					attachmentList.map(i => {
						const fileName = i.nameOnBucket ? i.nameOnBucket : i.name;
						const pathQueryParamSegment = configuration.pathQueryParam ? `/${configuration.pathQueryParam}/` : '/';
						return pathQueryParamSegment + fileName;
					})
			};
			await this.restAttachmentListManagerService.delete(
				restDeletePayload,
				null,
				{ path: configuration.pathUrl }
			);
			return;
		} catch (err) {
			throw new Error(err);
		}
	}

	private upload(): Observable<AttachedFile> {
		if (!this.files?.length) {
			this.uploadCompleted$.complete();
			this.uploadCompleted$ = null;
			this.progressSubject.complete();
			return EMPTY;
		}

		const fileToUpload = this.files[0];
		const formData = new FormData();
		let nameOnBucket: string;
		const timeStamp = Date.now();

		if (
			fileToUpload.configuration &&
			fileToUpload.configuration.upload &&
			Object.prototype.hasOwnProperty.call(fileToUpload.configuration.upload, 'fileNameOnBucketCreate')
		) {
			nameOnBucket = fileToUpload.configuration.upload.fileNameOnBucketCreate(fileToUpload.file.name, timeStamp);
		}
		if (nameOnBucket) {
			formData.set('file', fileToUpload.file, nameOnBucket);
		} else {
			formData.append('file', fileToUpload.file);
		}

		if (fileToUpload.configuration) {
			const pathQueryParamSegment =
				fileToUpload.configuration.pathQueryParam ? `/${fileToUpload.configuration.pathQueryParam}/` : '/';
			formData.append('path', pathQueryParamSegment);
		}

		this.cachedObservable$ = new Subject<Upload>();

		const url = this.getPathPrefix() + `/${fileToUpload.configuration.pathUrl}/upload-multipart-chunck`;
		const obs = this.uploadMultichunkFile(url, fileToUpload,nameOnBucket).pipe(
			tap(event => {
				//if (event.type === HttpEventType.Response) {
				if (event === 'complete') {
					const attachedFile: AttachedFile = {
						id: null,
						alt: null,
						name: fileToUpload.file.name,
						nameOnBucket: nameOnBucket,
						data: null,
						bucket_link: null,
						date_creation: timeStamp
					};
					this.uploadCompleted$.next(this.utilService.deleteEmptyProperties(attachedFile));
					this.removeFileFromList(fileToUpload);
				}
			}),
			//upload(fileToUpload),
			finalize(() => {
				this.cachedObservable$.complete();
				this.cachedObservable$ = null;
				this.upload();
			})
		);
		this.progressSubject.subscribe((progress) => {
			fileToUpload.progress = progress;
		})

		this.files[0] = fileToUpload;

		this.progressSubscription = obs.subscribe(this.cachedObservable$);
		return this.uploadCompleted$;
	}

	cancelUpload(index: number) {

		this.stopSignal.next(null);
		this.stopSignal.complete();
		this.files.splice(index, 1);
		if (index == 0 && this.progressSubscription && !this.progressSubscription.closed) {
			this.progressSubscription.unsubscribe();
		}
		if (index == 0 && this.progressSubject && !this.progressSubject.closed) {
			this.progressSubject.unsubscribe();
		}

	}

	private getPathPrefix() {
		return this.pathPrefix ? this.pathPrefix : '';
	}

	private removeFileFromList(fileToUpload: FileToUpload) {
		const fileIdx = this.files.indexOf(fileToUpload);
		if (fileIdx !== -1) {
			this.files.splice(fileIdx, 1);
		}
	}

	private uploadMultichunkFile(url: string, fileToUpload: FileToUpload, nameOnBucket?: string): Observable<any> {
		const chunks: { chunk: Blob, range: string }[] = [];
		const file = fileToUpload.file;
		const fileSlice = file.slice.bind(file); // Bind slice method for efficiency

		for (let start = 0, end = Math.min(this.chunkSize, file.size); start < file.size; start = end) {
			end = Math.min(start + this.chunkSize, file.size);
			const chunk = fileSlice(start, end);
			const range = `bytes ${start}-${end - 1}/${file.size}`;
			chunks.push({ chunk, range });
		}

		this.progressSubject = new Subject<any>();
		this.stopSignal = new Subject<void>();
		let uploadedChunks = 0;

		const path = fileToUpload.configuration && fileToUpload.configuration.pathQueryParam ? `/${fileToUpload.configuration.pathQueryParam}/` : '/';

		return from(this.getUrlResumable(file, url, path))
			.pipe(
				concatMap((urlResumable: UrlResumable) => {
					return from(chunks).pipe(
						concatMap(chunk => this.uploadChunk(url, file, chunk.range, chunk.chunk, path, urlResumable, nameOnBucket)),
						takeUntil(this.stopSignal),
						reduce((acc, uploadResult) => {
							acc.push(uploadResult); // Accetta risultati individuali
							uploadedChunks = uploadedChunks + 1;
							this.progressSubject.next({ state: (uploadedChunks < chunks.length ? 'IN_PROGRESS' : 'DONE'), progress: Math.round((100 * uploadedChunks) / chunks.length)/*, file: file */ })
							return acc; // Ritorna l'accumulatore aggiornato
						}, []),
						map(() => 'complete')
					);
				})
			);
	}

	async getUrlResumable(file: File, url: string, path: string): Promise<any> {
		try {
			const formData = new FormData();
			formData.append("name_file", file.name)
			formData.append("size", "" + file.size);
			formData.append('path', path);
			const responseUrlreusable: any = await lastValueFrom(this.http.post<any>(url, formData));
			return { urlResumable: responseUrlreusable.url_resumable, info: responseUrlreusable.info };
		} catch (err) {
			throw new Error('saep_ict_angular_core.upload.impossible_to_retrieve_url_reusable');
		}
	}

	async uploadChunk(url: string, file: File, range: string, chunk: Blob, path: string, urlResumable: UrlResumable, nameOnBucket?: string): Promise<any> {

		const formData = new FormData();
		if (nameOnBucket) {
			formData.set('file', chunk, nameOnBucket);
		} else {
			formData.append('file', chunk);
		}

		formData.append("name_file", file.name);
		formData.append('mime_type', file.type);
		formData.append('content_range', range);
		formData.append("url_resumable", urlResumable.urlResumable);
		formData.append("chunk_size", "" + this.chunkSize);
		formData.append("info", urlResumable.info);
		return await lastValueFrom(this.http.post(url, formData));
	}
}

