import { Pagination, Filters } from '@saep-ict/pouch-db';
import { Sort } from '../structure/list-structure.model';

export interface RestBaseResponse<T> {
	message: string;
	data?: T;
	pagination?: Pagination;
	sort?: Sort;
	filters?: Filters;
}

export interface RestBaseMessageError {
	status: number;
	body: {
		detail: string;
    message?: string;
	};
	headers: any;
}

export interface RestBasePk {
	id?: string; // TODO - should be mandatory
}

export interface RestBaseGuid {
	guid?: string;
}

export interface QueryParameter extends Pagination, Sort {
	text?: string;
	id?: string;
}
