import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export class InputTypeSelectablesTypeModel {
	type: string;
	icon?: string;
	description?: string;
}

export class ValueModel {
	value: number;
	type: string;
}

@Component({
	selector: 'input-type-selectable',
	templateUrl: './input-type-selectable.component.html',
	styleUrls: ['./input-type-selectable.component.scss']
})
export class InputTypeSelectableComponent implements OnInit {
	_value: ValueModel = new ValueModel();
	@Input('value') set value(v: ValueModel) {
		if (v) {
			this._value = v;
		}
	}
	_types: InputTypeSelectablesTypeModel[] = [];
	@Input('types') set types(t: InputTypeSelectablesTypeModel[]) {
		if (t) {
			this._types = t;
		}
	}
	@Output() onChange = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	changeType() {
		let types = this._types;
		for (let i = 0; i < types.length; i++) {
			if (types[i].type == this._value.type) {
				if (types[i + 1]) {
					this._value.type = types[i + 1].type;
				} else {
					this._value.type = types[0].type;
				}
				this.onChange.emit(this._value);
				break;
			}
		}
	}
}
