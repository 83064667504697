import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OpportunityPouchModel } from '@saep-ict/angular-spin8-core';
import { StateFeature } from '../../../../../state';
import { OpportunityActionEnum } from '../../../../../state/opportunity/opportunity.actions';

@Component({
	selector: 'opportunity-detail-activity',
	templateUrl: './opportunity-detail-activity.component.html',
	styleUrls: ['./opportunity-detail-activity.component.scss'],
	providers: [SubscribeManagerService]
})
export class OpportunityDetailActivityComponent implements OnInit, OnDestroy {
	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	constructor(private store: Store<any>, private subscribeManagerService: SubscribeManagerService) {}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// Reset dello state opportunity nel componente padre
	}

	subscribeOpportunity() {
		return this.opportunity$.pipe(
			filter(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				if (opportunityState.type && opportunityState.type === OpportunityActionEnum.UPDATE) {
					if (opportunityState) {
						this.opportunity = opportunityState.data[0];
					}
				}
			})
		);
	}
}
