import { createAction, props } from '@ngrx/store';
import { BaseStateSerializerModel } from '../../model/state/base-state.model';
import { ListSetting } from '@saep-ict/pouch_agent_models';

export enum ListSettingActionEnum {
	UPDATE = '[List Setting] Update',
  RESET_ITEM = '[List Setting] Reset Item',
  RESET = '[List Setting] Reset',
}

export namespace ListSettingStoreAction {
  export const update = createAction(
    ListSettingActionEnum.UPDATE,
    props<BaseStateSerializerModel<ListSetting<any>>>()
  );
  export const resetItem = createAction(
    ListSettingActionEnum.RESET_ITEM,
    props<BaseStateSerializerModel<ListSetting<any>>>()
  );
  export const reset = createAction(ListSettingActionEnum.RESET);
}
