<mat-dialog-wrapper [title]="data.title | sentencecase">
	<ng-container content *ngIf="data.text">
		<p [innerHTML]="data.text"></p>
	</ng-container>
	<ng-container actions>
		<div class="action-buttons">
			<div *ngFor="let action of data.actions">
				<button mat-raised-button color="primary" (click)="dialogRef.close(action.key)">
					{{ action.text | translate | sentencecase }}
				</button>
			</div>
		</div>
		<button mat-button [mat-dialog-close]="false">
			{{ 'general.cancel' | translate | sentencecase }}
		</button>
	</ng-container>
</mat-dialog-wrapper>
