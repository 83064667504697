import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'dialog-actions',
	templateUrl: './dialog-actions.component.html',
	styleUrls: ['./dialog-actions.component.scss']
})
export class DialogActionsComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<DialogActionsComponent>) {}
	ngOnInit() {}
}
