import { ITdDataTableColumn } from '@covalent/core/data-table';
import _ from 'lodash';
import { ColumnListOrder } from '@saep-ict/angular-spin8-core';

// Copio l'array originale perché il cloneDeep non salva le manipolazioni effettuate sul nuovo array
const newColumnList = [...ColumnListOrder.base];
newColumnList.splice(2, 1,	
	{
	
	name: 'header.date',
	labelPath: 'theme.order.field.date',
	label: '',
	width: 100
},
{
	name: 'header.customer_order_ref_code',
	labelPath: 'theme.order.field.customer_ref',
	label: '',
	width: 100

},
{
	name: 'header.price.article',
	labelPath: 'order.field.amount',
	label: '',
	width: 120,
	sortable: false,
	numeric: true
});

export const projectOverride: ITdDataTableColumn[] = _.cloneDeep(newColumnList);
