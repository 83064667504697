<a
    #wrapper
    href="javascript:void(0)"
    [routerLink]="link"
    (window:resize)="setWrapperHeight()"
    [ngStyle]="{maxWidth: (configurationCustomerArticle.imageWidth + 'px')}"
>
    <img
        [src]="image ? image : 'assets/theme-current/img/img-placeholder-product.png'"
        [alt]="alt"
        [name]="name"
    />
</a>