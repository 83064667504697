import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

// model
import { ITicketCenterConfigModel } from '../../model/lib-app-config.interface';
import { TicketCenterConfigurationModel } from '../../model/configuration.model';
import { TicketModel } from '../../model/lib.model';
import { TicketStatusEnum } from '../../model/enum/status.enum';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../store';

// misc
import { AngularCoreUtilService, BaseStateModel, FormControlMultipurposeEnum, FormControlMultipurposeModel, FormControlMultipurposeService, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
// Bundling to FESM2015 WARNING: Cannot call a namespace ('moment')
import moment from 'moment';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';

@Component({
  selector: 'tc-ticket-detail-wrapper',
  templateUrl: './ticket-detail-wrapper.component.html',
  styleUrls: ['./ticket-detail-wrapper.component.scss'],
  providers: [
    SubscribeManagerService,
  ]
})
export class TicketDetailWrapperComponent implements OnInit, OnDestroy {

  _ticket: TicketModel<any>;
  @Input() set ticket(e: TicketModel<any>) {
    if (e) {
      this._ticket = e;
      this.createForm();
    }
  }
  @Output() onSave: EventEmitter<TicketModel<any>> = new EventEmitter();
  @Output() onCancel: EventEmitter<null> = new EventEmitter();


  libConfig$: Observable<BaseStateModel<ITicketCenterConfigModel>> =
    this.store.select(StateFeature.getTicketCenterConfiguration);
  configuration: TicketCenterConfigurationModel;

  user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserStore);
  user: UserDetailModel;

	form: UntypedFormGroup;
  formCustomFieldList: FormControlMultipurposeModel.Item[];

  userInfoToShow = ['username', 'first_name', 'last_name'];

  contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
  ticketStatusEnum = TicketStatusEnum;

	subscribeFormDataPrefix: string = 'ticket-center-ticket-detail-wrapper-form-value-change_';

  constructor(
    private store: Store<any>,
    private subscribeManagerService: SubscribeManagerService,
    public utilService: AngularCoreUtilService,
    private fb: UntypedFormBuilder,
    private formControlMultipurposeService: FormControlMultipurposeService
  ) {
    this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
    this.libConfig$.pipe(take(1)).subscribe(res => {
      this.configuration = res ? res.data.customConfiguration : null;
		});
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscribeManagerService.destroy();
  }

	subscribeFormData(): Observable<any> {
		return this.form.valueChanges.pipe(
			take(1),
			map(value => {
				value = this.utilService.deleteEmptyProperties(value);
				this.formControlMultipurposeService.updateRootFormValueList(this.formCustomFieldList[0].form_id, value);
        const customField = this.form.get('body.custom_field') as UntypedFormGroup;
				this.formControlMultipurposeService.updateFormControlAccordingToType(
					customField,
					<FormControlMultipurposeModel.Item>{
						type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
						form_control_list: this.formCustomFieldList
					},
					value
				);
				this.subscribeManagerService.populate(
					this.subscribeFormData().subscribe(),
					this.subscribeFormDataPrefix + this.formCustomFieldList[0].form_id
				);
			})
		);
	}

  // form
	createForm() {
    const baseForm = this.fb.group({
      id: [this._ticket.id],
      status: [this._ticket.status ? this._ticket.status : TicketStatusEnum.SENT],
      request: this.fb.group({
        date_request: [{
          value: this._ticket.request && this._ticket.request.date_request ? moment(this._ticket.request.date_request) : moment(Date.now()),
          disabled: true
        }],
        date_due: [this._ticket.request && this._ticket.request.date_due ? moment(this._ticket.request.date_due) : null],
        user: this.fb.group({
          id: [this._ticket.request && this._ticket.request.user && this._ticket.request.user.id ? this._ticket.request.user.id : this.user.id]
        }),
        urgent: [this._ticket.request && this._ticket.request.urgent ? this._ticket.request.urgent : false]
      }),
      body: this.fb.group({
        reference: [this._ticket.body && this._ticket.body.reference ? this._ticket.body.reference : null],
        subject: [this._ticket.body && this._ticket.body.subject ? this._ticket.body.subject : null],
        message: [this._ticket.body && this._ticket.body.message ? this._ticket.body.message : null],
        attachment_list: [this._ticket.body && this._ticket.body.attachment_list ? this._ticket.body.attachment_list && this._ticket.body.attachment_list.length > 0 : []],
        custom_field: this.fb.group({})
      })
    });
    if (this.configuration.ticket.bodyCustomField) {

      let formCustomField: FormControlMultipurposeModel.Item[] =
        this.user.current_permission.context_application !== ContextApplicationItemCodeEnum.BACKOFFICE &&
        this.user.current_permission.context_application !== ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN &&
        this._ticket.id ?
        this.formControlMultipurposeService.returnFormFieldListWithAllDisabled(this.configuration.ticket.bodyCustomField) :
        this.configuration.ticket.bodyCustomField;
      this.formCustomFieldList = this.formControlMultipurposeService.returnFormFieldListWithRootFormId(
        formCustomField,
        `${this.utilService.guid()}`
      );
      this.formControlMultipurposeService.updateRootFormValueList(this.formCustomFieldList[0].form_id, null);
      if (this._ticket.body && this._ticket.body.custom_field) {
        this.formCustomFieldList = this.formControlMultipurposeService.updateFormFieldListValue(
          this.formCustomFieldList,
          this._ticket.body.custom_field
        );
      }
      const customField = baseForm.get('body.custom_field') as UntypedFormGroup;
      for (const field of this.formCustomFieldList) {
        customField.addControl(field.name, this.formControlMultipurposeService.returnNewFormControl(field));
      }
      if (this._ticket.body && this._ticket.body.custom_field) {
        this.formControlMultipurposeService.updateFormControlAccordingToType(
          customField,
          <FormControlMultipurposeModel.Item>{
            type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
            form_control_list: this.formCustomFieldList
          },
          this._ticket.body.custom_field
        );
      }
    }
    this.form = baseForm;
    if (
      this.user.current_permission.context_application !== ContextApplicationItemCodeEnum.BACKOFFICE &&
      this.user.current_permission.context_application !== ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN &&
      this._ticket.id
    ) {
      this.form.disable();
    }
    this.subscribeManagerService.populate(
      this.subscribeFormData().subscribe(),
      this.subscribeFormDataPrefix + this.formCustomFieldList[0].form_id
    );

  }

  prepareSaveForm() {
    const formModel = this.form.value;

    formModel.request.date_request =
      this._ticket.id ?
      this._ticket.request.date_request :
      Date.now();
    formModel.request.date_due =
      formModel.request.date_due ?
      formModel.request.date_due.valueOf() :
      null;
    formModel.request = this.utilService.deleteEmptyProperties(formModel.request);

    const attachmentListPrevious =
      this._ticket.body && this._ticket.body.attachment_list ?
      this._ticket.body.attachment_list:
      [];
    formModel.body.attachment_list = attachmentListPrevious.concat(formModel.body.attachment_list);

    formModel.body.custom_field = this.utilService.deleteEmptyProperties(formModel.body.custom_field);
    formModel.body = this.utilService.deleteEmptyProperties(formModel.body);
    return this.utilService.deleteEmptyProperties(formModel);
  }

  setFormValue(value, field: string) {
		this.form.get(field).setValue(value);
	}

  deleteTicketBodyAttachmentItem(e: number) {
    this._ticket.body.attachment_list.splice(e, 1);
  }

  onFormCancel(){
    this.onCancel.emit();
  }

  onFormSubmit() {
    if (this.form.valid){
      this.onSave.emit(this.prepareSaveForm());
    }
  }
}
