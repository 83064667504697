import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AngularCoreUtilService, SubscribeManagerService, ChartjsModel } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { ArticleListStructureWrapperAccordionBaseDirective } from '../../../../shared/directives/article-list-structure-wrapper-accordion-base/article-list-structure-wrapper-accordion.directive';

@Component({
	selector: 'article-list-structure-backoffice-forecast-wrapper-accordion',
	templateUrl: './article-list-structure-backoffice-forecast-wrapper-accordion.component.html',
	styleUrls: ['./article-list-structure-backoffice-forecast-wrapper-accordion.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleListStructureBackofficeForecastWrapperAccordionComponent extends ArticleListStructureWrapperAccordionBaseDirective implements OnInit {

	constructor(
		public store: Store,
		public utilService: AngularCoreUtilService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public subscribeManagerService: SubscribeManagerService
	) {
		super(store, utilService, utilTranslateService, subscribeManagerService);
	}

	ngOnInit(): void {
	}

	chartDataHorizontal: ChartjsModel = {
		data: null,
		datasets: [
			{
				data: [5000, 5000, 7800, 7400, 5200, 7600],
				label: 'Account A'
			},
			{
				data: [2500, 2300, 6500, 6300, 7200, 7500],
				label: 'Account B'
			}
		],
		labels: ['5D07 21', '5D07 22', '5D0921', '5D09 22', '5D10 21', '5D10 21'],
		type: 'bar',
		options: {
			responsive: true,
		},
		colors: [],
		legend: false,


	};

	chartDataVertical: ChartjsModel = {
		data: null,
		datasets: [
			{
				data: [5000, 5000, 7800, 7400, 5200, 7600],
				label: 'Account A'
			},
			{
				data: [2500, 2300, 6500, 6300, 7200, 7500],
				label: 'Account B'
			}
		],
		labels: ['5D07 21', '5D07 22', '5D0921', '5D09 22', '5D10 21', '5D10 21'],
		type: 'bar',
		options: {
			responsive: true,
		},
		colors: [],
		legend: false,


	};

}
