import { UserDetailModel } from "@saep-ict/angular-spin8-core";
import { TicketThreadModel } from "./thread.model";
import { FileUpload } from "./util.model";

export interface TicketModel<BODY_MODEL> {
  id: number,
	request: TicketRequestModel;
	status: string;
	body: TicketBodyModel<BODY_MODEL>;
	thread: TicketThreadModel;
}

export interface TicketBodyModel<BODY_MODEL> {
	reference: string;
	subject: string;
	message: string;
	attachment_list: FileUpload[];
	custom_field: BODY_MODEL;
}

export interface TicketRequestModel {
	date_request: number;
	date_due: number;
	user: UserDetailModel;
	urgent: boolean;
}
