import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

// This pipe is used to bypass security and avoid error ANGULAR ERROR: UNSAFE VALUE USED IN A RESOURCE URL CONTEXT
@Pipe({
	name: 'safe'
})
export class SafePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
		switch (type) {
			case 'html':
				return this.sanitizer.bypassSecurityTrustHtml(value);
			case 'style':
				return this.sanitizer.bypassSecurityTrustStyle(value);
			case 'script':
				return this.sanitizer.bypassSecurityTrustScript(value);
			case 'url':
				return this.sanitizer.bypassSecurityTrustUrl(value);
			case 'resourceUrl':
				return this.sanitizer.bypassSecurityTrustResourceUrl(value);
			default:
				return this.sanitizer.bypassSecurityTrustHtml(value);
		}
	}
}
