import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { StatisticsDataKeyEnum } from '../../enum/statistics-data-key.enum';
import { CommentStateModel } from './comment-state.model';

export interface OrganizationStateModel extends OrganizationPouchModel {
	comment_list?: CommentStateModel[];
	date_first_contact?: number;
	statistics_data?: StatisticsData[];
	crm?: {
		revenue_year?: number;
		organization_sector?: string;
		employees_number?: number;
		organization_international_rating?: string;
		organization_rating?: number;
		url_website?: string;
		url_linkedin?: string;
		url_documents?: string;
		url_recipes?: string;
		url_sales?: string;
		url_tickets?: string;
	};
}

export interface OrganizationRequestModel extends OrganizationStateModel {
	captcha?: string;
}

export interface StatisticsData {
	key: StatisticsDataKeyEnum;
	value: any;
}
