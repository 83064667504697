import { Injectable } from '@angular/core';
import { IResourceMethodPromiseStrict, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';

@Injectable()
export class AngularSpin8CoreActionService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/email/actions/`
	})
	sendAction: IResourceMethodPromiseStrict<void, { code_item: string }, void, RestBaseResponse<any>>;
}
