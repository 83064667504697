import { Injectable, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AppUtilService } from '../../util/app-util.service';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { ActivatedRoute } from '@angular/router';
import { BaseTableClass } from '../../td-data-table/base-table.service';
import { StatisticsBaseSerieClient } from '../../../model/statistics.model';
import { filter, take } from 'rxjs/operators';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class ClientsTop5AverageTableConfigService extends BaseTableClass implements OnInit {
	public listData = <LocalListHandlerBaseModel<StatisticsBaseSerieClient>>{
		pagination: {
			pageSize: 5
		}
		// data: []
	};

	columns: ITdDataTableColumn[] = [
		{
			name: 'business_name',
			label: this.translate.instant('client.name')
		},
		{
			name: 'average',
			label: this.translate.instant('order.avg'),
			numeric: true
		}
	];

	constructor(
		public store: Store<any>,
		@Inject(TranslateService) private translate: TranslateService,
		public utilService: AppUtilService,
		public route: ActivatedRoute
	) {
		super(utilService, store, route);
	}

	ngOnInit() {
		this.statistics$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.populateTable();
			});
	}

	// Functions

	createDataSerie() {
		let clientsTop5;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardClients) {
			clientsTop5 = this.utilService.findLeaf(this.statistics.data, 'top5.order_value');
		} else {
			clientsTop5 = this.utilService.findLeaf(this.statistics.data, 'clients.top5.order_value');
		}

		this.utilService.findLeaf(clientsTop5, 'days_30').forEach((item: StatisticsBaseSerieClient) => {
			this.fullDataSetDays30.push(item);
		});

		this.utilService.findLeaf(clientsTop5, 'days_60').forEach((item: StatisticsBaseSerieClient) => {
			this.fullDataSetDays60.push(item);
		});

		this.utilService.findLeaf(clientsTop5, 'days_90').forEach((item: StatisticsBaseSerieClient) => {
			this.fullDataSetDays90.push(item);
		});
	}

	populateTable(rangeDays: number = this.timeRange.values[0]) {
		switch (rangeDays) {
			case 30:
				this.currentDataSet = this.fullDataSetDays30;
				break;
			case 60:
				this.currentDataSet = this.fullDataSetDays60;
				break;
			case 90:
				this.currentDataSet = this.fullDataSetDays90;
				break;

			default:
				break;
		}
		this.updateListClientsData(this.currentDataSet);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListClientsData(list: StatisticsBaseSerieClient[]) {
		this.listData.data = _.cloneDeep(list);
		this.listData = Object.assign({}, this.listData);
	}
}
