import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CrmOrderProductPouchModel } from '@saep-ict/angular-spin8-core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiscountTypeEnum } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'dialog-offer-free-line',
	templateUrl: './dialog-offer-free-line.component.html',
	styleUrls: ['./dialog-offer-free-line.component.scss']
})
export class DialogOfferFreeLineComponent implements OnInit {
	form: FormGroup;
	descriptionMaxLength = 60;

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: { freeLine: CrmOrderProductPouchModel },
		private dialogRef: MatDialogRef<DialogOfferFreeLineComponent>
	) {
		this.createForm();
		if (this.data.freeLine) {
			this.populateForm(this.data.freeLine);
		}
	}

	ngOnInit() {}

	getFormControl(key: string): FormControl {
		return <FormControl>this.form.get(key);
	}

	createForm() {
		this.form = this.fb.group({
			description: [null, [Validators.required, Validators.maxLength(this.descriptionMaxLength)]],
			price: [0, [Validators.required]],
			discount: [0, [Validators.required, Validators.min(0), Validators.max(100)]]
		});
	}

	populateForm(freeLine: CrmOrderProductPouchModel) {
		this.form.patchValue({
			description: freeLine.description,
			price: freeLine.price,
			discount: freeLine.discount_agent.value
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.dialogRef.close(this.prepareSaveForm());
		}
	}

	prepareSaveForm() {
		const formModel = this.form.value;

		// Modello base di line_list con valori hardcodati
		const saveForm: CrmOrderProductPouchModel = {
			description: formModel.description,
			discount_agent: {
				value: formModel.discount,
				type: DiscountTypeEnum.percentage
			},
			order_causal_code: 'L',
			price: formModel.price
		};

		return saveForm;
	}
}
