import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ConfigurationProjectModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateFeature } from '../../state';

export interface BreadcrumbTranslateItemModel {
	value: string;
	param?: any;
}

@Injectable({
	providedIn: 'root'
})
export class UtilBreadcrumbService {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	title: BreadcrumbTranslateItemModel = { value: null, param: null };
	subtitle: BreadcrumbTranslateItemModel = { value: null, param: null };

	mainSidenavItemList: ConfigurationProjectModel.Navigation.MainSidebarItemModel[];

	updateActiveNavigationItemSource = new BehaviorSubject<string[]>([]);
	updateActiveNavigationItem$: Observable<string[]> = this.updateActiveNavigationItemSource.asObservable();

	UNSET_TITLE = 'title';
	UNSET_SUBTITLE = 'subtitle';
	UNSET_BOTH = 'both';

	constructor(
		private store: Store
	) {}

	unsetRouteMetaInformation(propertyToUnset: string = this.UNSET_BOTH) {
		switch (propertyToUnset) {
			case this.UNSET_TITLE:
				this.title = { value: null, param: null };
				break;
			case this.UNSET_SUBTITLE:
				this.subtitle = { value: null, param: null };
				break;
			default:
				this.title = { value: null, param: null };
				this.subtitle = { value: null, param: null };
				break;
		}
		this.updateActiveNavigationItemSource.next([]);
	}

	getBreadcrumbTitle(key: string): string {
		const navigationItem: ConfigurationProjectModel.Navigation.MainSidebarItemModel = this.mainSidenavItemList.find(
			i => i.key === key
		);
		return navigationItem?.name || '';
	}
}
