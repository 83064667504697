import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { UserManagementStateAction } from '../../../state/backoffice/user-management/user-management.actions';
import * as ConfigurationCustomerContextApplication from '../../../constants/configuration-customer/context-application/context-application.constant';
import * as ConfigurationCustomerUserManagement from '../../../constants/configuration-customer/user-management/user-management.constant';
import {
	ITdDataTableRowClickEvent,
	TdDataTableComponent,
	ITdDataTableSortChangeEvent,
	TdDataTableSortingOrder
} from '@covalent/core/data-table';
import { IPageChangeEvent } from '@covalent/core/paging';
import { Observable } from 'rxjs';
import { skipWhile, map } from 'rxjs/operators';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { BackofficeUserFilterModel } from '../../../service/pouch-db/filter/backoffice-user-filter.model';
import { SubscribeManagerService, BaseStateModel, SideBarPositionValues } from '@saep-ict/angular-core';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';
import * as UserConfiguration from '../../../constants/user.constant';
import _ from 'lodash';
import * as UserManagementListColumn from '../../../constants/configuration-customer/user-management/user-management-column-map/user-management-column-map-base.constant';

@Component({
	selector: 'user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.scss'],
	providers: [SubscribeManagerService]
})
export class UserManagementComponent implements OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	userManagementState$: Observable<BaseStateModel<UserDetailModel[]>> = this.store.select(
		StateFeature.getUserManagementState
	);
	public userManagementState: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel> = {
		data: [],
		dataSetting: {
			pagination: {
				page_current: 1,
				page_size: 10
			},
			appliedFilter: {
				text: null,
				context_code: null,
				is_active: true
			},
			sort: [{ ['username']: 'asc' }]
		}
	};

	contextTypeAndApplicationLink = ConfigurationCustomerContextApplication.map;

	form: FormGroup;

    sideBarPositionValues = SideBarPositionValues;

	userManagementListColumn = UserManagementListColumn.baseColumns;

	constructor(
		private fb: FormBuilder,
		public router: Router,
		public route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private store: Store,
		public permissionUtilService: PermissionUtilService,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private bucketManagerService: BucketManagerService
	) {
		this.subscribeManagerService.populate(this.subscribeUserManagementState().subscribe(), 'user-management');

		this.createForm();
		this.handleColumnVisibility();
		this.loadUserList();

		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('user_management');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['user_management']);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(UserManagementStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	subscribeUserManagementState() {
		return this.userManagementState$.pipe(
			skipWhile((state: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) => !(state && state.data)),
			map(async (state: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) => {
				await this.setUserAvatar(state.data);
				this.userManagementState = _.cloneDeep(state);
				if (this.dataTable) {
					this.dataTable.refresh();
				}
			})
		);
	}

	// load
	loadUserList() {
		this.store.dispatch(ConfigurationCustomerUserManagement.storeAction.load_list(this.userManagementState));
	}

	// form
	createForm() {
		this.form = this.fb.group({
			context_code: [null]
		});
	}

	getFormFieldValue(key: string) {
		return this.form.get(key).value;
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm = {
			context_code: formModel.context_code as number
		};
		return saveForm;
	}

	resetFilters() {
		this.form.reset();
		this.onFormSubmit();
	}

	onFormSubmit() {
		if (this.form.valid) {
			const formModel = this.prepareSaveForm();
			this.userManagementState.dataSetting.appliedFilter.context_code = formModel.context_code;
			this.loadUserList();
		}
	}

	// navigation
	goToDetail(e: ITdDataTableRowClickEvent) {
		this.router.navigate([e.row.id], { relativeTo: this.route });
	}

	sortChange(e: ITdDataTableSortChangeEvent) {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				[e.name]: e.order === TdDataTableSortingOrder.Ascending ? 'asc' : 'desc'
			}
		];
		this.userManagementState.dataSetting.sort = sort;
		this.loadUserList();
	}

	changePageSize(size: number) {
		if (size) {
			this.userManagementState.dataSetting.pagination.page_size = size;
			this.loadUserList();
		}
	}

	paginationChange(pagination: IPageChangeEvent) {
		if (pagination) {
			this.userManagementState.dataSetting.pagination.page_current = pagination.page;
			this.loadUserList();
		}
	}

	handleColumnVisibility() {
		this.userManagementListColumn.map(col => {
			col.hidden = ['full_name'].includes(col.name);
		});
	}

	// WIP tab-wrapper
	filterUsersOnTabClick(event: MatTabChangeEvent) {
		switch (event.tab.textLabel) {
			case 'all_users':
				this.userManagementState.dataSetting.appliedFilter.is_active = null;
				break;
			case 'active_users':
				this.userManagementState.dataSetting.appliedFilter.is_active = true;
				break;
			case 'deactivated_users':
				this.userManagementState.dataSetting.appliedFilter.is_active = false;
				break;
		}
		this.userManagementState.dataSetting.pagination.page_current = 1;
		this.loadUserList();
	}

	setUserAvatar(userList: UserDetailModel[]): Promise<void> {
		return new Promise(resolve => {
			try {
				for (const user of userList) {
					user.avatar =
						this.bucketManagerService.returnBucketManagerDownloadUrl(
							UserConfiguration.userAvatarFileName,
							'user',
							user.id
						);
				}
				resolve();
			} catch(e) {
				throw new Error(e);
			}
		});
	}
}
