import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	OrderProgressPouchModel,
	ProgressOrderStatusEnum,
	OrderStatusEnum,
	BodyTablePouchModel,
	ArticlePouchModel,
	OrganizationPouchModel
} from '@saep-ict/pouch_agent_models';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, skipWhile } from 'rxjs/operators';
import { BaseState, BaseStateModel, DialogConfirmComponent, SentencecasePipe } from '@saep-ict/angular-core';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OrderActionEnum, OrderStateAction } from './order.actions';
import { OrderListFilterModel } from '../../service/pouch-db/filter/order-filter.model';
import { DestinationFilterModel } from '../../service/pouch-db/filter/destination-filter.model';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { StateFeature } from '..';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { CustomerAppConfig } from '../../customer-app.config';
import * as _ from 'lodash';
import {
	ExtraFieldOrderHeaderPouchModel,
	LinkCodeModel,
	OrderStateModel,
	PouchDbModel,
	ProductVariationStateModel,
	ProductVariationTypeEnum,
	AngularSpin8CoreOrderService,
	AngularSpin8CoreUtilTranslateService,
	PATH_URL
} from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';
import * as Sentry from '@sentry/browser';
import { UtilSentryService } from '../../service/util/util-sentry.service';
import * as ConfigurationCustomerOrderErp from '../../constants/configuration-customer/order/order-erp.constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class OrderEffects {
	@LocalStorage('link_code') link_code: LinkCodeModel;
	@LocalStorage('current_order_id') current_order_id: string;

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.LOAD),
			mergeMap((action: BaseStateModel<string>) =>
				from(this.getOrderDetail(action))
			),
			// mergeMap((action: BaseStateModel<OrderStateModel>) =>
			// 	from(this.getOrderDetailWithArticle(action))
			// ),
			mergeMap((action: BaseStateModel<OrderStateModel>) => from(this.mergeOrderVariation(action))),
			// TODO: il merge delle info kit va riferito ad article_recap** per gli ordini in bozza
			// mergeMap((action: BaseStateModel<OrderStateModel, OrderFilterModel>) =>
			// 	from(this.mergeArticleKitDetails(action))
			// ),
			map((order: BaseStateModel<OrderStateModel>) => OrderStateAction.update(order)),
			catchError((error, caught) => {
				this.store.dispatch(OrderStateAction.error(null));
				return caught;
			})
		)
	);

	loadFromLocalStorageByRest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.LOAD_FROM_LOCALSTORAGE_BY_REST),
			mergeMap((action: BaseStateModel<string>) =>
				from(this.loadFromLocalStorageByRest(action))
			),
			// mergeMap((action: BaseStateModel<OrderStateModel>) =>
			// 	from(this.getOrderDetailWithArticle(action))
			// ),
			mergeMap((action: BaseStateModel<OrderStateModel>) => from(this.mergeOrderVariation(action))),
			map((order: BaseStateModel<OrderStateModel>) => OrderStateAction.update(order)),
			catchError((error, caught) => {
				this.store.dispatch(OrderStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.SAVE),
			mergeMap((action: BaseStateModel<OrderStateModel>) =>
				from(this.saveOrderDetail(action))
			),
			// mergeMap((action: BaseStateModel<OrderStateModel, OrderFilterModel>) =>
			// 	from(this.mergeArticleKitDetails(action))
			// ),
			map((order: BaseStateModel<OrderStateModel>) => {
				// TODO: verificare effetti sul b2c, in agent comporta il lancio doppio dell'update
				// tra i quali il primo inconsistente
				// this.store.dispatch(OrderStateAction.update(order));
				this.manageCurrentOrderId(order);
				return order;
			}),
			map((order: BaseStateModel<OrderStateModel>) => {
				// TODO: to check
				if (order.data.header.status !== OrderStatusEnum.DRAFT) {
					return OrderStateAction.completed(order);
				}
				return OrderStateAction.update(order);
			}),
			catchError((error, caught) => {
				if(error.status === 409) {
					this.store.dispatch(OrderStateAction.error409(error));
				} else this.store.dispatch(OrderStateAction.error(error));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<OrderStateModel>) => from(this.deleteOrder(action.data))),
			map((order: BaseStateModel<PouchDeleteResponse>) => OrderStateAction.removed(order)),
			catchError((error, caught) => {
				this.store.dispatch(OrderStateAction.error(null));
				return caught;
			})
		)
	);

	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	loginContextCode: OrganizationPouchModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: OrderStateModel;

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private storage: LocalStorageService,
		private appConfig: CustomerAppConfig,
		private orderService: AngularSpin8CoreOrderService,
		private sentryUtilService: UtilSentryService,
		private dialog: MatDialog,
		private sentenceCasePipe: SentencecasePipe,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {
		this.loginContextCode$.subscribe(res => {
			this.loginContextCode = res ? res.data : null;
		});
	}

	manageCurrentOrderId(order) {
		// se l'utente non è loggato o se è loggato nel contesto B2C (quindi nello storefront)
		if (!this.appConfig.authenticationToken && order.data.header.status == OrderStatusEnum.DRAFT) {
			this.current_order_id = order.data._id;
		} else {
			this.storage.clear('current_order_id');
		}
	}

	/**
	 * Il metodo recupera l'ordine alternativamente alternativamente
	 * - in modo diretto da couch
	 * - mediato da BE
	 *
	 * @param {BaseStateModel<string>} data
	 * @returns {Promise<BaseStateModel<OrderStateModel>>}
	 * @memberof OrderEffects
	 */
	async getOrderDetail(
		data: BaseStateModel<string>
	): Promise<BaseStateModel<OrderStateModel>> {
		return new Promise(async (resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				resolve(
					(
						await this.pouchAdapterSelectorService.retrieveCurrentAdapter(
							PouchDbModel.PouchDbDocumentType.ORDER
						)
					).basePouch.getDetail(data.data)
				);
			} else {
				this.orderService.getNewOrder({ id: data.data }).then(order => resolve(order.data));
			}
		})
			.then(async (order: OrderStateModel) => {
				return new BaseState(ConfigurationCustomerOrderErp.addOrderId(order));
			})
			.catch(async (err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	/**
	 * Il metodo recupera l'ordine escludivamente mediato da BE. La action coinvolta
	 * ha implicazioni nelle dinamiche di merge ordine b2c presso context-manager.service
	 *
	 * @param {BaseStateModel<string>} data
	 * @returns {Promise<BaseStateModel<OrderStateModel>>}
	 * @memberof OrderEffects
	 */
	async loadFromLocalStorageByRest(
		data: BaseStateModel<string>
	): Promise<BaseStateModel<OrderStateModel>> {
		return new Promise(async (resolve, reject) => {
			this.orderService.getNewOrder({ id: data.data }).then(order => resolve(order.data));
		})
			.then(async (order: OrderStateModel) => {
				return new BaseState(ConfigurationCustomerOrderErp.addOrderId(order));
			})
			.catch(async (err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getOrderDetailWithArticle(
		action: BaseStateModel<OrderStateModel>
	): Promise<BaseStateModel<OrderStateModel>> {
		try {
			const order: BaseStateModel<OrderStateModel> = new BaseState(action.data);
			// Progress order
			if (Object.values(ProgressOrderStatusEnum).includes(order.data.header.status as any)) {
				const orderFilter: OrderListFilterModel = { _id: order.data._id };
				let orderProgress = await this.getOrderProgress(orderFilter)
					.then(res => (orderProgress = res))
					.catch(err => console.error(err));
				if (orderProgress) {
					order.data.order_progress = orderProgress;
				}

				if (orderProgress && orderProgress.values) {
					// Ordina gli oggetti per data DESC e prende il primo
					orderProgress.values.sort((a, b) => (a.date < b.date ? 1 : -1));
					const lastOrderProgress = orderProgress.values[0];

					//  values changed
					const valuesChanged = lastOrderProgress.values_changed;
					if (valuesChanged) {
						if (!order.data.header.order_progress_detail) {
							order.data.header.order_progress_detail = {};
						}

						// first evasion date
						if (valuesChanged[`root['header']['first_evasion_date']`]) {
							const firstEvasionDate: any =
								valuesChanged[`root['header']['first_evasion_date']`].old_value;
							order.data.header.order_progress_detail.first_evasion_date = firstEvasionDate;
						}

						// // goods destination
						// if (valuesChanged[`root['header']['goods_destination_code']`]) {
						// 	const goodsDestinationCodeOldValue: any =
						// 		valuesChanged[`root['header']['goods_destination_code']`].old_value;
						// 	const destinationFilter: DestinationFilterModel = {
						// 		id: goodsDestinationCodeOldValue,
						// 		code_organization: order.data.header.organization.code_item
						// 	};
						// 	(
						// 		await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.ORDER)
						// 	).destinationPouch
						// 		.getDestination(destinationFilter)
						// 		.then((destination: DestinationPouchModel) => {
						// 			order.data.header.order_progress_detail.goods_destination = destination;
						// 			return order;
						// 		});
						// }

						// order causal code
						if (valuesChanged[`root['header']['order_causal']`]) {
							const orderCausalOld: any = valuesChanged[`root['header']['order_causal']`].old_value;
							order.data.header.order_progress_detail.order_causal = orderCausalOld;
						}
					}
				}
			}
			return order;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async mergeOrderVariation(data: BaseStateModel<OrderStateModel>): Promise<BaseStateModel<OrderStateModel>> {
		try {
			const order = data;

			// Progress order
			if (Object.values(ProgressOrderStatusEnum).includes(order.data.header.status as any)) {
				const orderProgress = order.data.order_progress;
				if (orderProgress && orderProgress.values) {
					if (!order.data.order_variation_list) {
						order.data.order_variation_list = [];
					}

					// Ordina gli oggetti per data DESC e prende il primo
					orderProgress.values.sort((a, b) => (a.date < b.date ? 1 : -1));
					const lastOrderProgress = orderProgress.values[0];

					// values changed
					const valuesChanged = lastOrderProgress.values_changed;
					if (valuesChanged) {
						// Product list della first version di un ordine
						const orderProgressProductList = orderProgress['first_version']['product_list'];
						// Contiene gli indici dei prodotti REPLACED
						const indexListReplacedProducts = [];

						// Product REPLACED / PROPERTY_CHANGED
						for (const valueObj in valuesChanged) {
							// Skip se la proprietà proviene da prototype
							if (!valuesChanged.hasOwnProperty(valueObj)) {
								continue;
							}
							const productListLength = orderProgressProductList.length;

							if (valueObj.startsWith(`root['product_list']`)) {
								for (let i = 0; i < productListLength; i++) {
									if (
										valueObj.startsWith(`root['product_list'][${i}]`) &&
										!indexListReplacedProducts.includes(i)
									) {
										// Estrae il nome della proprietà cambiata
										let propertyChanged = valueObj.replace(`root['product_list'][${i}]`, '');
										if (propertyChanged !== '') {
											propertyChanged = propertyChanged.slice(2, -2);
										}

										// Codice prodotto che ha subito la variazione
										const code_item = orderProgressProductList[i].code_item;

										const obj: Object = valuesChanged[valueObj];
										let productCode: string;
										let productVariationType: any;

										if (propertyChanged === 'code') {
											// Product REPLACED
											productCode = obj['old_value'];
											productVariationType = ProductVariationTypeEnum.REPLACED;
											indexListReplacedProducts.push(i);
										} else {
											// Product PROPERTY_CHANGED
											productCode = code_item;
											productVariationType = ProductVariationTypeEnum.PROPERTY_CHANGED;
										}
										const itemChanged: ProductVariationStateModel = {
											productCode: productCode,
											type: productVariationType,
											propertyName: propertyChanged,
											oldValue: obj['old_value'],
											newValue: obj['new_value']
										};
										order.data.order_variation_list.push(itemChanged);
									}
								}
							}
						}
					}

					// Product ADDED
					const iterableItemAdded = lastOrderProgress.iterable_item_added;
					if (iterableItemAdded) {
						for (const key in iterableItemAdded) {
							// Skip se la proprietà proviene da prototype
							if (!iterableItemAdded.hasOwnProperty(key)) {
								continue;
							}
							const obj: Object = iterableItemAdded[key];
							const itemAdded: ProductVariationStateModel = {
								productCode: obj['code'],
								type: ProductVariationTypeEnum.ADDED
							};
							order.data.order_variation_list.push(itemAdded);
						}
					}

					// Product REMOVED
					const iterableItemRemoved = lastOrderProgress.iterable_item_removed;
					if (iterableItemRemoved) {
						for (const key in iterableItemRemoved) {
							// Skip se la proprietà proviene da prototype
							if (!iterableItemRemoved.hasOwnProperty(key)) {
								continue;
							}
							const obj: Object = iterableItemRemoved[key];
							const itemRemoved: ProductVariationStateModel = {
								productCode: obj['code'],
								type: ProductVariationTypeEnum.REMOVED
							};
							order.data.order_variation_list.push(itemRemoved);
						}
					}
				}
			}
			return order;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async getOrderProgress(
		data: OrderListFilterModel
	): Promise<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>> {
		try {
			const orderProgress: OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel> = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.ORDER)
			).orderPouch.getOrderProgress({
				id: data._id
			});
			return orderProgress;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async saveOrderDetail(
		action: BaseStateModel<OrderStateModel>
	): Promise<BaseStateModel<OrderStateModel>> {
		return new Promise(async (resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				action.data.type = action.data.type
					? action.data.type
					: 'order';
				resolve(
					(
						await this.pouchAdapterSelectorService.retrieveCurrentAdapter(
							PouchDbModel.PouchDbDocumentType.ORDER
						)
					).basePouch.saveBodyTable(
						action.data,
						action.dataSetting  && action.dataSetting.useLoader === false ?
						false :
						true
					)
				);
			} else {
				this.orderService.putNewOrder(action.data).then(order => resolve(order.data));
			}
		})
			.then(async (order: OrderStateModel) => {
				action.data = order;
				action.data.code_item = order._id.replace('order' + ConfigurationCustomerAppStructure.noSqlDocSeparator, '');
				const result = new BaseState(order);
				return result;
			})
			.catch((err: PouchErrorResponse) => {	
				Sentry.configureScope((scope) => {
					scope.setTag('orderCodeItem', action.data.code_item);
				});
				this.sentryUtilService.captureMessage(`La chiamata PUT per il salvataggio dell'ordine ${action.data.code_item} non è andata a buon fine. Error`, 'error', err);
				throw { error: err.error, reason: err.reason, status: err.status };
			});
	}

	/**
	 * Effettua alternativamente l'eliminazione dell'ordine su db couch oppure la sola rimozione della sua referenza dal localstorage
	 *
	 * @param {OrderStateModel} data
	 * @returns {Promise<BaseStateModel<PouchDeleteResponse>>}
	 * @memberof OrderEffects
	 */
	async deleteOrder(data: OrderStateModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		try {
			if (this.appConfig.authenticationToken) {
				return (
					await this.pouchAdapterSelectorService.retrieveCurrentAdapter(
						PouchDbModel.PouchDbDocumentType.ORDER
					)
				).orderPouch
					.deleteOrder(data)
					.then(async order => {
						return new BaseState(order);
					})
					.catch((err: PouchErrorResponse) => {
						throw new Error(err.error + err.reason);
					});
			} else {
				this.storage.clear('current_order_id');
				return new BaseState(null);
			}
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async mergeArticleKitDetails(
		action: BaseStateModel<OrderStateModel>
	): Promise<BaseStateModel<OrderStateModel>> {
		// ciclo tutti i prodotti

		// TODO: il seguente non è più allineato col modello univoco dell'article
		// da rivedere in toto una volta ridefinita la gestione delle categorie
		// for (let i = 0; i < action.data.product_list.length; i++) {
		// 	if (action.data.product_list[i].articleDetail) {
		// 		action.data.product_list[i].articleDetail.articleKitList = [];

		// 		// se hanno i kit
		// 		if (action.data.product_list[i].articleDetail.art_kit === 'S') {
		// 			// recupero il dettaglio
		// 			await this.utilArticleKitService
		// 				.getArticleKitDetail(
		// 					action.data.product_list[i].articleDetail,
		// 					action.dataSetting.appliedFilter.organization
		// 				)
		// 				.then((res: ArticleState[]) => {
		// 					action.data.product_list[i].articleDetail.articleKitList = res;
		// 				})
		// 				.catch((err: PouchErrorResponse) => {
		// 					console.log(err, 'article kit not found');
		// 				});
		// 		}
		// 	}
		// }

		return action;
	}

	async addOrderProgress(
		order: OrderStateModel,
		allOrderProgress?: OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>[]
	) {
		const orderFilter: OrderListFilterModel = {
			_id: order._id
		};
		const orderProgress = allOrderProgress
			? allOrderProgress.find(x => x.doc_id === order._id)
			: await this.getOrderProgress(orderFilter);
		if (orderProgress) {
			order.order_progress = orderProgress;
		}

		// order progress - values changed
		if (orderProgress && orderProgress.values) {
			const lastIndex = orderProgress.values.length - 1;
			const valuesChanged = orderProgress.values[lastIndex].values_changed;
			if (valuesChanged) {
				if (!order.header.order_progress_detail) {
					order.header.order_progress_detail = {};
				}
				// first_evasion_date
				if (valuesChanged[`root['header']['first_evasion_date']`]) {
					const firstEvasionDate: any = valuesChanged[`root['header']['first_evasion_date']`].old_value;
					order.header.order_progress_detail.first_evasion_date = firstEvasionDate;
				}

				// goods destination
				if (valuesChanged[`root['header']['goods_destination_object']['code_item']`]) {
					const goodsDestinationCodeOldValue: any =
						valuesChanged[`root['header']['goods_destination_object']['code_item']`].old_value;

					const destinationFilter: DestinationFilterModel = {
						id: goodsDestinationCodeOldValue,
						code_organization: order.header.client_code
					};

					// TODO check it
					const organizationList$ = this.store.select(StateFeature.getOrganizationListState);
					const organizationListSub = organizationList$
						.pipe(
							skipWhile(res => !res),
							map(res => {
								const organization = res.data.find(x => x.code_item === order.header.client_code);
								const oldDestination = organization.destination_list.find(
									x => x.code_item === goodsDestinationCodeOldValue
								);
								order.header.order_progress_detail.goods_destination = oldDestination;
							})
						)
						.subscribe();

					// this.pouchDbLecaAgentiAdapter.destinationPouch
					// 	.getDestination(destinationFilter)
					// 	.then((destination: DestinationPouchModel) => {
					// 		order.header.order_progress_detail.goods_destination = destination;
					// 		return order;
					// 	});
				}

				// starting_warehouse
				if (valuesChanged[`root['header']['starting_warehouse']`]) {
					const startingWarehouseOld: any = valuesChanged[`root['header']['starting_warehouse']`].old_value;
					order.header.order_progress_detail.starting_warehouse = startingWarehouseOld;
				}
			}
		}
	}
}
