import { OrderEnum } from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';

export const StateRelatedLinkAgent: StateRelatedLink[] = [
	{
		state: OrderStatusEnum.DRAFT,
		label: 'theme.order.status.draft',
		related_list: [OrderStatusEnum.DRAFT, OrderStatusEnum.ERROR_PREPARING]
	},
	{
		state: OrderStatusEnum.TO_AUTHORIZE,
		label: 'theme.order.status.to_authorize',
		related_list: [OrderStatusEnum.TO_AUTHORIZE]
	},
	{
		state: OrderStatusEnum.NOT_AUTHORIZED,
		label: 'theme.order.status.not_authorized',
		related_list: [OrderStatusEnum.NOT_AUTHORIZED]
	},
	{
		state: OrderStatusEnum.SENDING,
		label: 'theme.order.status.sending',
		related_list: [			
			OrderStatusEnum.READY_TO_SEND,
			OrderStatusEnum.SENDING,
			OrderStatusEnum.ERROR_SENDING,
			OrderStatusEnum.QUEUE
		]
	},
	{
		state: OrderStatusEnum.CONSOLIDATED,
		label: 'theme.order.status.consolidated',
		related_list: [OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
	},
	{
		state: OrderStatusEnum.FULFILLED,
		label: 'theme.order.status.fulfilled',
		related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED]
	}
];

export const StateRelatedLinkBackoffice: StateRelatedLink[] = [
	{
		state: OrderEnum.FilterType.RECEIVED,
		label: 'theme.order.status.received',
		related_list: [
					   OrderStatusEnum.TO_AUTHORIZE, 
					   OrderStatusEnum.NOT_AUTHORIZED,
					   OrderStatusEnum.READY_TO_SEND, 
					   OrderStatusEnum.ERROR_SENDING,					    
					   OrderStatusEnum.SENDING,
					   OrderStatusEnum.QUEUE, 					    
					]
	},
	{
		state: OrderEnum.FilterType.CONSOLIDATED,
		label: 'theme.order.status.consolidated',
		related_list: [OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
	},
	{
		state: OrderEnum.FilterType.FULFILLED,
		label: 'theme.order.status.fulfilled',
		related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED]
	}
	
];

export const StateRelatedLinkB2b: StateRelatedLink[] = [
	{
		state: OrderStatusEnum.DRAFT,
		label: 'theme.order.status.draft',
		related_list: [OrderStatusEnum.DRAFT, OrderStatusEnum.ERROR_PREPARING, OrderStatusEnum.TO_AUTHORIZE, OrderStatusEnum.NOT_AUTHORIZED]
	},	
	{
		state: OrderStatusEnum.SENDING,
		label: 'theme.order.status.sending',
		related_list: [
			OrderStatusEnum.SENDING,
			OrderStatusEnum.READY_TO_SEND,
			OrderStatusEnum.ERROR_SENDING,
			OrderStatusEnum.QUEUE,
		]
	},
	{
		state: OrderStatusEnum.CONSOLIDATED,
		label: 'theme.order.status.consolidated',
		related_list: [OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
	},
	{
		state: OrderStatusEnum.FULFILLED,
		label: 'theme.order.status.fulfilled',
		related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED]
	},	
	
	
];

export const StateRelatedLinkB2c: StateRelatedLink[] = [
	{
		state: OrderStatusEnum.SENDING,
		label: 'theme.order.status.sending',
		related_list: [
			OrderStatusEnum.SENDING,
			OrderStatusEnum.READY_TO_SEND,
			OrderStatusEnum.ERROR_SENDING,
			OrderStatusEnum.QUEUE,
		]
	},
	{
		state: OrderStatusEnum.CONSOLIDATED,
		label: 'theme.order.status.consolidated',
		related_list: [ OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
	},
	{
		state: OrderStatusEnum.FULFILLED,
		label: 'theme.order.status.fulfilled',
		related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED]
	},
	
];

export interface StateRelatedLink {
	state: OrderStatusEnum | OrderEnum.FilterType;
	label: string;
	subtitle_label?: string;
	related_list: OrderStatusEnum[];
}


