import { PipeTransform, Pipe } from '@angular/core';
import * as UtilPrice from '../../constants/util-price.constants';

@Pipe({
	name: 'utilPriceReturnItemValueFormattedPipe'
})
export class UtilPriceReturnItemValueFormattedPipe implements PipeTransform {
	transform(value: number, decimalDigits?: number) {
		return UtilPrice.returnItemValueFormatted(value, decimalDigits);
	}
}
