import { Component, Input } from '@angular/core';

// model
import { AttachedFile, Category } from '@saep-ict/pouch_agent_models';

// misc
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { Router } from '@angular/router';
import { ListSettingStoreAction, UtilStoreService } from '@saep-ict/angular-core';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'b2c-category-item',
	templateUrl: './b2c-category-item.component.html',
	styleUrls: ['./b2c-category-item.component.scss']
})
export class B2cCategoryItemComponent {
	@Input() set category(e: Category<any>) {
		if (e) {
			this._category = e;
			const categoryImageList = this.utilTranslateService.getTranslationFromLanguage(e.language_list).image_list;
			if (categoryImageList && categoryImageList.length > 0) {
				this.backgroundImage = categoryImageList.find(img => img.is_main_of_list) || categoryImageList[0];
			}
		}
	}
	_category: Category<any>;
	backgroundImage: AttachedFile;

	ROUTE_URL = ROUTE_URL;

	constructor(
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private router: Router,
		private utilStoreService: UtilStoreService
	) {}

	goTo(codeItem: string) {
		this.utilStoreService.serielizedStoreResetItem(ListSettingStoreAction.resetItem, ROUTE_URL.catalog);
		this.router.navigate([ROUTE_URL.public, ROUTE_URL.catalog], { queryParams: { categories: codeItem } });
	}
}
