import { Injectable } from '@angular/core';
import { OfflineDeviceService, PouchDbAdapter, TransmissionService } from '@saep-ict/pouch-db';
import { SurveyCenterPouch } from './pouch-function/survey-center.pouch';
import { CustomerAppConfig } from '../../../../../../../customer-app.config';
import { LoaderService } from '@saep-ict/angular-core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class PouchDbSurveyCenterAdapter extends PouchDbAdapter {
	baseDatabaseTemplate: 'surveycenter';
	database = 'surveycenter';
	surveyCenterPouch: SurveyCenterPouch;

	constructor(
		protected config: CustomerAppConfig,
		offlineDeviceService: OfflineDeviceService,
		private loaderService: LoaderService,
		private httpClient: HttpClient,
		private transmissionService: TransmissionService
	) {
		super(config, offlineDeviceService);
	}

	async initFunctions() {
		this.surveyCenterPouch = new SurveyCenterPouch(
			this.getDB(), this.httpClient, this.config, this.transmissionService, this.loaderService
		);
	}
}
