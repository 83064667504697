import { NgModule } from '@angular/core';

// model
import { TicketCenterTicketStoreReducer } from './ticket.reducer';

// store
import { StoreModule } from '@ngrx/store';

export const storeModule = StoreModule.forFeature('ticket-center-ticket', TicketCenterTicketStoreReducer.reducer);

@NgModule({
    imports: [
      storeModule
    ]
})
export class TicketCenterTicketStoreModule{}
