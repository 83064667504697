
<mat-card class="michelangelo-theme autoheight">
	<top-bar
		[goBackButton]="true"
		[goBackPath]="backPath"
		class="mb-20"
	>
	</top-bar>
	<form class="michelangelo-theme" [formGroup]="form">
		<p class="title">{{ 'request.data.label' | translate | sentencecase }}</p>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="creationDate"
						[placeholder]="'opportunity.field.date_creation' | translate | sentencecase"
						[matDatepicker]="picker"
						(click)="picker.open()"
						stopPropagation
					/>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<mat-select
						formControlName="statusCode"
						[placeholder]="'opportunity.field.status_code' | translate | sentencecase"
					>
						<mat-option
							*ngFor="let status of configurationCustomerOpportunity.OpportunityEnumStatusOutcome[user.current_permission.context_application] | enumIterator"
							[value]="status.description"
						>
							{{ 'general.status.' + status.description  | translate | uppercase }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<p class="title">{{ 'request.data.personal' | translate | sentencecase }}</p>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="organizationBusinessName"
						[placeholder]="'theme.organization.field.business_name' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<mat-select
						formControlName="organizationClass"
						[placeholder]="'theme.organization.field.classification' | translate | sentencecase"
					>
						<mat-option
							*ngFor="let class of request"
							[value]="class"
						>
							{{ class | sentencecase }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<mat-select
						formControlName="organizationType"
						[placeholder]="'organization.typology' | translate | sentencecase"
					>
						<mat-option *ngFor="let type of organizationTypeEnum | enumIterator" [value]="type.id">
							{{
								'saep_ict_angular_spin8_core.page.organization.organization_type_label.' +
									type.description
									| translate
									| sentencecase
							}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field *ngIf="formRequestConfigRequestDetail.organizationLevelField.visible">
					<mat-select
						formControlName="organizationLevel"
						[placeholder]="'organization.field.level' | translate | sentencecase"
					>
						<mat-option
							*ngFor="let level of organizationLevelEnum | enumIterator"
							[value]="level.description"
						>
							{{ level.description | sentencecase }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="organizationVatNumber"
						[placeholder]="'organization.field.vat_number' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="organizationTaxCode"
						[placeholder]="'organization.field.tax_code' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
		</div>

		<p class="title">{{ 'request.data.destination' | translate | sentencecase }}</p>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="destinationBusinessName"
						[placeholder]="'destination.name' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="destinationZipCode"
						[placeholder]="'destination.zip_code' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="destinationAddress"
						[placeholder]="'destination.address' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="destinationLocality"
						[placeholder]="'destination.locality' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<mat-select
						formControlName="destinationProvince"
						[placeholder]="'destination.province' | translate | sentencecase"
						[compareWith]="compareItemValue"
					>
						<mat-option *ngFor="let province of provinceList" [value]="province">
							{{ province.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="destinationCountry"
						[placeholder]="'destination.country' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
		</div>

		<p class="title">{{ 'request.data.contact' | translate | sentencecase }}</p>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="contactFistName"
						[placeholder]="'contact.field.first_name' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="contactLastName"
						[placeholder]="'contact.field.last_name' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="contactEmail"
						[placeholder]="'contact.field.email' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<input
						matInput
						formControlName="contactPhone"
						[placeholder]="'contact.field.phone' | translate | sentencecase"
					/>
				</mat-form-field>
			</div>
		</div>
	</form>
	<form-button-bar
		*ngIf="!formIsEditable && opportunity"
		[align]="'right'"
		[primaryButtonLabel]="'general.approves' | translate | sentencecase"
		(primaryButtonClickEmitter)="approveOrganization()"
		[primaryButtonIsDisabled]="opportunity.status_code !== opportunityEnum.Status.Outcome.Open"
		[secondaryButtonLabel]="'general.edit' | translate | sentencecase"
		(secondaryButtonClickEmitter)="editForm()"
		[otherButtonLabel]="
			(opportunity.status_code === opportunityEnum.Status.Outcome.Open ? 'request.close' : '')
				| translate
				| sentencecase
		"
		(otherButtonClickEmitter)="manageRequest()"
	></form-button-bar>
	<form-button-bar
		*ngIf="formIsEditable"
		[align]="'right'"
		[primaryButtonLabel]="'general.save' | translate | sentencecase"
		(primaryButtonClickEmitter)="onFormSubmit()"
		[primaryButtonIsDisabled]="!form.valid"
		[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
		(secondaryButtonClickEmitter)="exitEditForm()"
	></form-button-bar>
</mat-card>
