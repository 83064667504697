import { Inject, Injectable, Optional } from '@angular/core';
import { IResourceActionInner, IResourceResponse, Resource, ResourceParams } from '@ngx-resource/core';
import { APP_CONFIG_TOKEN, ISaepIctAngularCoreAppConfig } from '../../model/structure/lib-app-config.interface';
import { Store } from '@ngrx/store';
import { ILoaderService, LOADER_SERVICE_TOKEN } from '../../interface/loader-service.interface';
import { ILoaderExceptionCall } from '../../model/structure/ILoaderExceptionCall';
import { CustomHeadersHandlerService } from '../structure/custom-headers-handler.service';

@Injectable()
@ResourceParams({
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT, DELETE',
		'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
	},
})
export class BaseApiService extends Resource {

	loaderExceptions: ILoaderExceptionCall[];

	constructor(
		@Optional() @Inject(LOADER_SERVICE_TOKEN) protected loaderService: ILoaderService,
		@Inject(APP_CONFIG_TOKEN) protected appConfig: ISaepIctAngularCoreAppConfig,
    public store: Store<any>,
    public customHeadersHandlerService: CustomHeadersHandlerService,
	) {
		super();
    this.appConfig['config$'].subscribe(config => {
			if (config && config.saepIctAngularCore && config.saepIctAngularCore.be_url) {
        let urlPrefix = config.saepIctAngularCore.be_url;
        if (config.saepIctAngularCore.suffix) {
          urlPrefix += config.saepIctAngularCore.suffix;
        }
        super.$setPathPrefix(urlPrefix);
			}
		});
	}

	$restAction(options: IResourceActionInner): any {
		const exception =
			this.loaderExceptions &&
			this.loaderExceptions.find((exc) => {
				return (
					options.actionOptions.path === exc.path &&
					options.actionOptions.method === exc.method
				);
			});

		if (!exception && this.loaderService) {
			const guid = this.loaderService.populateLoader(
				options.actionOptions.path
			);
			options.returnData = { id: guid };
		}

		const token = this.appConfig.token
			? 'bearer ' + this.appConfig.token
			: 'token';

		this.$setHeaders({
			Authorization: token
		});

		if (this.customHeadersHandlerService.customHeaders.value) {
			const headers = this.$getHeaders();
      for (const headerName in this.customHeadersHandlerService.customHeaders.value) {
        headers[headerName] = this.customHeadersHandlerService.customHeaders.value[headerName];
      }
			this.$setHeaders(headers);
		}

		return super.$restAction(options);
	}

	$handleSuccessResponse(
		options: IResourceActionInner,
		resp: IResourceResponse
	): any {
		if (options.returnData && options.returnData.id && this.loaderService) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}
		return super.$handleSuccessResponse(options, resp);
	}

	$handleErrorResponse(
		options: IResourceActionInner,
		resp: IResourceResponse
	): any {
		if (options.returnData && options.returnData.id && this.loaderService) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}

		return super.$handleErrorResponse(options, resp);
	}

	protected setPathPrefix(pathPrefix: string) {
		super.$setPathPrefix(pathPrefix);
	}

}
