<mat-sidenav-container
	#offsetAnchor
	(backdropClick)="sidenav.close()"
	[style.min-height]="height"
>
	<mat-sidenav
		#sidenav
		position="end"
		[opened]="filterOpened"
		[mode]="mode"
		(keydown.escape)="sidenav.close()"
		[style.width]="sidenavWidth"
	>
		<ng-scrollbar [autoWidthDisabled]="false" [autoHeightDisabled]="false">
			<mat-toolbar>
				<mat-toolbar-row>
					<span class="title-sidebar">{{ titleSidebar }}</span>
				</mat-toolbar-row>
			</mat-toolbar>
			<ng-content select="[side]"></ng-content>
		</ng-scrollbar>
	</mat-sidenav>
	<mat-sidenav-content [style.min-height]="height">

    <ng-scrollbar [autoWidthDisabled]="true" [autoHeightDisabled]="false">
      <div scrollViewport class="custom-viewport">
        <div class="scroll-content-wrapper" style="width:100%">
          <ng-content select="[main]"></ng-content>
        </div>
      </div>

		</ng-scrollbar>
	</mat-sidenav-content>
</mat-sidenav-container>
