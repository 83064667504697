import { AddressPouchModel, ArticleDescription, ArticlePouchModel, BasePouchModel, BodyTablePouchModel, HeaderTablePouchModel, Category } from '@saep-ict/pouch_agent_models';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import * as StatisticGroupModel from './statistics-group.model';
import * as StatisticEnum from '../enum/statistic.enum';

// Statistics
export class Statistics implements BasePouchModel {
_id?: string;
_rev?: string;
type?: string;
date_update: number;
date_create?: number;
valid?: boolean;
data: RecursiveStatisticsStructure<any>[];
}

export interface RecursiveStatisticsStructure<T> {
key: string;
value: T;
}

export class StatisticsQuarterData {
name: string;
year: number;
}

// series

export class StatisticsBaseSerieClient {
code: string;
total: number;
count: number;
last_order_date: number | string;
business_name: string;
vat_number: string;
address: AddressPouchModel;
}

export class StatisticsBaseSerieOrder {
date: number | string;
value: number;
quantity?: number;
}

export class StatisticsBaseSerieProduct {
code: string;
value: number;
description: string;
}

// metrics

export class MetricsDetail {
average: number;
trend: number;
}

// fulfilled

export class StatisticsFulfilledData {
name: string;
year: number;
months: StatisticsFulfilledMonth[];
total: number;
count: number;
average: number;
products: StatisticsBaseSerieProduct[];
from: number;
to: number;
}

export class StatisticsFulfilledMonth {
month: number;
total: number;
average: number;
count: number;
from: number;
to: number;
}

export interface Document extends HeaderTablePouchModel<BodyTablePouchWithSubArray> {
	code_item: string,
	context: ContextApplicationItemCodeEnum,
	date_update: number,
	group: StatisticGroupModel.GruopInformation, // TODO: valutare se eliminare
	unit: ValueLabel,
	summary: Base[]
}
export interface BodyTablePouchWithSubArray extends BodyTablePouchModel {
	data: Base[]
}
	export interface Base {
		key: StatisticEnum.Key,
		total: ValueType,
		trend?: ValueType,
		sequence?: number,
		date?: Date,
	}
	export interface ValueType {
		value: number,
		quantity: number,
		description?: string;
	}
	export interface ValueLabel {
		value: string,
		quantity: string
	}
	export interface Date {
		begin: number,
		end: number
	}

	export interface StatisticList {
		statisticList?: Base[];			
	}
	// TODO: Tree e Article sono ridondanti e andrebbero revisionati rendendo omogenee una serie di prop.
	// che divergono tra ArticlePouchModel e Category
	export interface Tree extends Category, StatisticList {
		article_list: Article[];
		articleDescription?: ArticleDescription;
	}
	export interface Article extends ArticlePouchModel, StatisticList {
		article_list?: Article[];
		category_list?: Category[];
	}
	export interface NestedCategoryConfiguration {
		data?: Document,
		dataParse?: {
			summary: Base[],
			categoryTree: Tree[]
		},
		unit: StatisticEnum.Unit,
		filter?: {
			categoryToExlcude: string[]
		}
	}

export interface TimeRangeSelector {
	key: 'days' | 'months' | 'years',
	value: number,
	label: string
}

