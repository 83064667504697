import { Component, Input } from '@angular/core';
import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { ArticlePouchModel, AttachedFile } from '@saep-ict/pouch_agent_models';
import { ItemType } from './card-slider.models';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { UtilPriceService } from '../../../../service/util/util-price.service';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { BucketManagerService } from '../../../../service/util/util-bucket-manager.service';

@Component({
	selector: 'b2c-article-item',
	templateUrl: './b2c-article-item.component.html',
	styleUrls: ['./b2c-article-item.component.scss']
})
export class B2cArticleItemComponent {
	@Input() viewType: ViewTypeEnum;
	@Input() itemType: ItemType;
	@Input() showDetails = true;
	@Input() set article(e: ArticlePouchModel) {
		if (e) {
			this._article = e;
			const languageItem = this.utilTranslateService.getLanguageItemWithImageList(e);
			if (languageItem) {
				this.articleImage = languageItem.image_list[0];
				this.articleImage.bucket_link = 
					this.bucketManagerService.returnBucketManagerDownloadUrl(
						languageItem.image_list[0].nameOnBucket,
						'article',
						`${e.code_item}/${languageItem.language}/gallery`
					)
			}
		}
	};
	_article: ArticlePouchModel;
	articleImage: AttachedFile = {
		name: null
	};

	public ViewTypeEnum = ViewTypeEnum;

	ROUTE_URL = ROUTE_URL;
	itemTypeEnum = ItemType;

	constructor(
		public utilOrderService: UtilOrderService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public utilPriceService: UtilPriceService,
		private bucketManagerService: BucketManagerService
	) {}

	linkToItem() {
		if (this._article && this._article.type == 'category') {
			return this._article._id;
		}
		return this._article.code_item;
	}
}
