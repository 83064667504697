import { Component, Input } from '@angular/core';
import { ArticlePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import * as ConfigurationCustomerOrder from '../../../constants/configuration-customer/order/order.constant';

@Component({
	selector: 'article-input-multiple-validation',
	templateUrl: './article-input-multiple-validation.component.html',
	styleUrls: ['./article-input-multiple-validation.component.scss']
})
export class ArticleInputMultipleValidationComponent {

	@Input() article: ArticlePouchModel;
	@Input() organization: OrganizationPouchModel;

	configurationCustomerOrder = ConfigurationCustomerOrder;

	constructor() {
		// do nothing.
	}

}
