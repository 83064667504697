import { ITdDataTableColumn } from '@covalent/core/data-table';
import _ from 'lodash';
import { ColumnListOrder } from '@saep-ict/angular-spin8-core';

// Copio l'array originale perché il cloneDeep non salva le manipolazioni effettuate sul nuovo array
const newColumnList = [...ColumnListOrder.base];
newColumnList.splice( 2, 0,
	{
		name: 'header.organization.business_name',
		labelPath: 'theme.organization.field.name',
		label: null,
		width: { min: 100 }
	},
	{
		name: 'header.date',
		labelPath: 'theme.order.field.date',
		label: '',
		width: 100
	},
	{
		name: 'header.goods_destination_object',
		labelPath: 'order.field.destination',
		label: null,
		width: { min: 100 },
		sortable: false
	},
	{
		name: 'header.order_causal_object.description_short',
		labelPath: 'theme.order.field.causal',
		label: null,
		width: { min: 100 }
	}
);
export const projectOverride: ITdDataTableColumn[] = _.cloneDeep(newColumnList);
