import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	OnDestroy,
	ChangeDetectorRef
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

// model

// widget & utility
import { NgScrollbar } from 'ngx-scrollbar';
import { debounceTime } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MediaReplayService } from '../../service/util/media-replay.service';
import { ChatModel, MessageModel } from '../../model/chat.model';

@Component({
	selector: 'chat-thread',
	templateUrl: './chat-thread.component.html',
	styleUrls: ['./chat-thread.component.scss']
})
export class ChatThreadComponent<USER> implements OnInit, OnDestroy {
	_chat: ChatModel<USER> = new ChatModel();
	/**
	 * L'input intercetta il cambio dell'oggetto chat e in base al numero di pagina della prop. thread effettua
	 * - pagina 1: scroll to bottom
	 * - pagina !1: scroll per porre a vista il più vecchio messaggio ottenuto dall'ultimo input dati
	 *
	 * Gli eventi associati a ngScrollbar devono passare tramite setTimeout altrimenti l'oggetto risulta ancora undefined
	 */
	@Input() set chat(c: ChatModel<USER>) {
		if (c) {
			this.cdr.detectChanges();
			this._chat = Object.assign({}, c);
			if (this._chat.thread.pagination.page_current !== 1) {
				setTimeout(() => {
					this.scrollbarRef.scrollToElement('#' + this.topMessageId, { duration: 500 });
				}, 0);
			} else {
				setTimeout(() => {
					this.scrollbarRef.scrollTo({bottom: 0});
				}, 0);
			}
		}
	}
	@Input() sender: string;
	@Input() disabled = false;
	@Input() threadHeight: number = null;

	@Output() onSendMessage = new EventEmitter();
	@Output() onReachTop = new EventEmitter();

	form: UntypedFormGroup;

	/**
   *  workaround per eliminare  gli errori di validazione generati dall'eventuale submit di un altro brand
      in sostituzione degli'inefficaci .reset() .markeAs*() ecc
   */
	@ViewChild('resetTag', { static: false }) formResetTag;
	@ViewChild('scrollbar', { static: false }) scrollbarRef: NgScrollbar;
	/**
   *  Firefox: scatenando this.key.next(true); direttamente qui dentro, per qualche motivo
        non viene notificato l'evento @Input() set chat(c: ChatState), nonostante tutto il resto del flusso continui a funzionare
        workaround:
        all'interno di questa condizione, viene simulato un click su un button in hidden
        a sua volta collegato al salvataggio del topMessage e allo scatenamento del metodo debounce che emette
        la richiesta di nuovi messaggi vera e propria
   */
	@ViewChild('clickEventTriggerTag', { static: false }) clickEventTrigger: ElementRef;

	/**
	 * ID dell'ultimo messaggio visualizzato dall'utente, usato per risettare lo scroll una volta caricate le pagine successive
	 */
	topMessageId: string;

	private subscription: Subscription;
	private key = new Subject();

	constructor(
		private fb: UntypedFormBuilder,
		public mediaReplayService: MediaReplayService,
		private cdr: ChangeDetectorRef
	) {
		this.createForm();
		// emitter della paginazione dei messaggi
		this.subscription = this.key.pipe(debounceTime(1000)).subscribe(e => this.onReachTop.emit(event));
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	// form
	createForm() {
		this.form = this.fb.group({
			text: ['', Validators.required]
		});
	}

	prepareSaveForm(): MessageModel<USER> {
		const formModel = this.form.value;
		const saveForm: MessageModel<USER> = {
			text: formModel.text as string,
			date_sending: Date.now()
		};
		return saveForm;
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.onSendMessage.emit(this.prepareSaveForm());
			this.formResetTag.resetForm();
		}
	}

	// events
	scrollingUp(e) {
		// if (this.scrollbarRef.directiveRef.position().y === 'start') {
		// 	this.clickEventTrigger.nativeElement.click();
		// }
		this.clickEventTrigger.nativeElement.click();
	}

	/**
	 * metodo bindato on click al button hidden
	 *  1 salva l'ID dell'ultimo messaggio visualizzato per il riposizionamento del thread dopo l'arrivo delle successive pagine
	 *  2 scatena il metodo in debounce per emettere la richiesta della pagina di messaggi successiva
	 */
	emitForNextPage() {
		this.topMessageId = this._chat.thread.data[0]._id;
		this.key.next(true);
	}
}
