<section class="call-to-action" [style.backgroundImage]="'url(' + (callToActionConfig.backgroundImage | translate) + ')'">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="call-to-action__wrapper">
					<p class="call-to-action__title" [ngClass]="callToActionConfig.titleClass">
						{{ callToActionConfig.title| translate | sentencecase }}
					</p>
					<p class="call-to-action__text" [ngClass]="callToActionConfig.textClass">
						{{ callToActionConfig.text | translate | sentencecase }}
					</p>
					<button
						mat-flat-button
						[color]="callToActionConfig.theme || 'primary'"
						class="custom-button call-to-action__button"
						(click)="onClickButton()"
					>
						{{ callToActionConfig.btnLabel | translate | sentencecase }}
					</button>
				</div>
			</div>
		</div>
	</div>
	<div
		class="call-to-action__backdrop"
		*ngIf="callToActionConfig.hasBackdrop"
		[style.backgroundColor]="callToActionConfig.backdropColor || 'rgba(0,0,0,0.5)'"
	></div>
</section>
