import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILoaderService } from '../../interface/loader-service.interface';
import { Loader } from '../../model/structure/loader.model';
import { AngularCoreUtilService } from '../util/util.service';

@Injectable()
export class LoaderService implements ILoaderService {
	private loaderSource = new BehaviorSubject<boolean>(false);
	private groupLoader: Loader[] = [];
	private cpyStatus: boolean = false;
	loader$ = <any>this.loaderSource.asObservable();

  constructor(private utilService: AngularCoreUtilService) {}

	changeLoader(status: boolean) {
		this.cpyStatus = status;
		this.loaderSource.next(status);
	}

	populateLoader(content: string) {
		const guid = this.utilService.guid();
		const loader: Loader = {
			status: true,
			content: content,
			id: guid
		};
		this.groupLoader.push(loader);
		if (this.groupLoader.length === 1) {
			this.changeLoader(true);
		}
		return guid;
	}

	changeSingleLoader(id) {
		const index = this.groupLoader.findIndex(result => {
			return result.id === id;
		});
		this.groupLoader.splice(index, 1);
		if (this.groupLoader.length > 0) {
			if (this.cpyStatus !== true) {
				this.changeLoader(true);
			}
		} else {
			this.changeLoader(false);
		}
	}

  // TODO: rimuovere e aggiornare tutte le librerie che ne fanno uso
  guid() {
    return this.utilService.guid();
	}

}
