import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilColorService {
  /**
   * Utility function to convert each value of a RGB color in HEX format
   */
  private componentToHex(c: number): string {
    var hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  /**
   * Function to convert a color from RGB format to HEX format
   */
  public rgbToHex(r: number, g: number, b: number): string {
    return (
      '#' +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  /**
   * Function to convert a color from HEX formato to RGB format
   */
  public hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
}
