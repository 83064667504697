import { Injectable } from '@angular/core';
import { IResourceMethodPromiseStrict, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { OrderStateModel, AngularSpin8CoreBaseApiService, returnApiUrlSegment } from '@saep-ict/angular-spin8-core';

@Injectable()
export class ResumeService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/private/print-pdf'
	})
	getPdf: IResourceMethodPromiseStrict<OrderStateModel, { document_type: string }, void, RestBaseResponse<any>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `${returnApiUrlSegment('privateApi')}/validation/`
	})
	confirmOrder: IResourceMethodPromiseStrict<OrderStateModel, { document_type: string }, void, RestBaseResponse<any>>;	

}
