<a href="https://www.tiny.cloud/docs/integrations/angular/">@tinymce/tinymce-angular</a>

Supporto per:

<ul>
    <li>angular form builder</li>
    <li>disabilitazione</li>
</ul>

<editor
  apiKey="no-api-key"
  cloudChannel="5"
  [disabled]="false"
  id=""
  [init]="{  }"
  initialValue=""
  [inline]="false"
  plugins=""
  tagName="div"
  toolbar=""
  (onSelectionChange)="handleEvent($event)"
></editor>

<a href="https://quilljs.com/">ngx-quill</a>

Supporto per:

<ul>
    <li>angular form builder</li>
    <li>disabilitazione</li>
</ul>

<quill-editor
  #quill
  [styles]="{height: '200px'}"
  (onEditorChanged)="handleEvent($event)"
></quill-editor>