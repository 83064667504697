import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';
import { OfflineDeviceService, PouchDbAdapter, PouchDbSyncProgress, PouchUtilService } from '@saep-ict/pouch-db';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PouchDbAgentAdapter } from '../../../service/pouch-db/spin8/pouchdb-agent.adapter';
import { PouchDbCommonsAdapter } from '../../../service/pouch-db/spin8/pouchdb-commons.adapter';

@Component({
	selector: 'download-couch',
	templateUrl: './download-couch.component.html',
	styleUrls: ['./download-couch.component.scss'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [style({ opacity: 0 }), animate('1s 1s ease-out', style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))])
		])
	]
})
export class DownloadCouchComponent implements OnInit, OnDestroy, AfterViewInit {
	// @LocalStorage('user') user: UserDetailModel;

	private subscriptions = new Array<Subscription>();
	private agentDownloadStatus$: Observable<PouchDbSyncProgress>;
	private commonDownloadStatus$: Observable<PouchDbSyncProgress>;

	agentDownloadStatus: PouchDbSyncProgress;
	commonDownloadStatus: PouchDbSyncProgress;

	// TODO - inserire le frasi
	public tipsList: string[] = [];
	public counter = 0;
	public counterInterval: NodeJS.Timeout;

	constructor(
		private router: Router,
		public offlineDeviceService: OfflineDeviceService,
		public pouchUtilService: PouchUtilService,
		private store: Store<any>
	) {}

	ngOnInit() {
		this.initCounter();
	}

	ngAfterViewInit() {
		this.offlineDeviceService.init().then(() => {
			this.pouchUtilService.pouchInstance.forEach(dbAdapter => {
				const pouchInstance = <PouchDbAdapter>dbAdapter;
				if (pouchInstance instanceof PouchDbAgentAdapter) {
					this.agentDownloadStatus$ = pouchInstance.pouchCouchSyncProgressStatus;
					this.subscriptions.push(
						this.agentDownloadStatus$.subscribe(x => {
							this.agentDownloadStatus = x;
							// console.log("******PouchDbAgentAdapter DownloadStatus: ", x);
						})
					);
				}
				if (pouchInstance instanceof PouchDbCommonsAdapter) {
					this.commonDownloadStatus$ = pouchInstance.pouchCouchSyncProgressStatus;
					this.subscriptions.push(
						this.commonDownloadStatus$.subscribe(x => {
							this.commonDownloadStatus = x;
							// console.log("******PouchDbCommonsAdapter DownloadStatus: ", x);
						})
					);
				}
			});
			this.subscriptions.push(
				forkJoin([this.agentDownloadStatus$, this.commonDownloadStatus$]).subscribe(results => {
					// console.log(`DOWNLOAD COMPLETED: `, results);
					window.location.assign('/');
				})
			);
		});
	}

	// l'animazione dura 8s come il logo spin-8
	initCounter() {
		if (this.tipsList.length > 1) {
			this.counterInterval = setInterval(() => {
				this.counter = this.counter === this.tipsList.length - 1 ? 0 : ++this.counter;
			}, 8000);
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(item => item.unsubscribe());
		if (this.counterInterval) {
			clearInterval(this.counterInterval);
		}
	}
}
