<mat-selection-list
	#matSelectionList
	[(ngModel)]="formFieldValue"
	(selectionChange)="updatePermissionValue()"
	[disabled]="disabled"
>
	<ng-container *ngFor="let groupItem of groupList">
		<mat-checkbox
			*ngIf="
				groupItem.permission_group_full_object_list && groupItem.permission_group_full_object_list.length > 0
			"
			color="primary"
			class="mat-checkbox-style"
			[disabled]="disabled"
			[checked]="allGroupPermissionChecked(groupItem.permission_group_value_list)"
			[indeterminate]="someGroupPermissionChecked(groupItem.permission_group_value_list)"
			(change)="setGroupPermission($event, groupItem.permission_group_value_list)"
		>
			<strong>{{ groupItem.description | titlecase }}</strong>
		</mat-checkbox>
		<mat-list-option
			color="primary"
			checkboxPosition="before"
			*ngFor="let item of groupItem.permission_group_full_object_list"
			[value]="item.key"
		>
			{{ item.description | titlecase }}
		</mat-list-option>
	</ng-container>
</mat-selection-list>
