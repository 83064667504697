import { CommercialAreaPouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import moment from "moment";
import { MatDatepickerFilterCustom } from '@saep-ict/angular-core';
import * as ConfigurationCustomerShipmentEnum from '../../../enum/shipping.enum';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';

export const today: Date = new Date();
export const firstEvasionDateMinDate = 14;
export const firstEvasionDateMaxDate = 90;
export const customerOrderRefDateMaxDate = 30;
export const matDatepickerFilter = {
	customer_date: MatDatepickerFilterCustom.excludeWeekend,
	due_date: MatDatepickerFilterCustom.excludeWeekend
};
export const minDate: Date = new Date(
    new Date().setDate(
        today.getHours() - 12 > 0 ?
        today.getDate() + 1 :
        today.getDate()
    )
);
export const addDays = (maxDays: number) => moment(today).add(maxDays, 'days');

export const returnB2cFirstEvasionDate = (
    order: OrderStateModel,
    organization: OrganizationPouchModel,
    commercialArea: CommercialAreaPouchModel
) => {
    let firstEvasionDate: number;
    if (
        order.header.carrier &&
        order.header.carrier.code_item &&
        order.header.carrier.code_item === ConfigurationCustomerShipmentEnum.Carrier.RAC
    ) {
        const destination =
            organization.destination_list.find(i => i.code_item === order.header.goods_destination_object.code_item);
        if (destination && destination.division_list) {
            const destinationDivision = destination.division_list.find(i => i.is_main_of_list);
            if (destinationDivision && destinationDivision.delivery_days) {
                // `destinationDivision.delivery_days` può contenere fino a 5 elementi avente valore compreso tra 1 e 5
                // con codifica 1: lun, 2: mar, 3: mer, 4: gio, 5: ven;
                // es: "125", che si traduce nei giorni di consegna settimanali lun, mar, ven;
                // da essa viene ricavato un array (`number[]`) ordinato in modo crescente
                // es: [1, 2, 5]
                const deliveryDays =
                    destinationDivision.delivery_days.split('')
                    .map(i => parseInt(i))
                    .filter(i => !isNaN(i) && i <= 5);
                deliveryDays.sort((a,b) => a-b);

                // giorno corrente
                const dayCurrent = moment();

                // numero del giorno corrente della settimana con codifica (secondo `moment`)
                // 0: dom, 1: lun, 2: mar...
                const dayCurrentNumber = dayCurrent.day();

                // primo numero del giorno della settimana disponibile, ricavato da `deliveryDays`
                let dayAvailableNumber: number;

                // numero di giorni da aggiungere partendo da `dayCurrent` per ottenere `firstEvasionDate`
                let dayDelta: number;

                // i `case` fanno riferimento a tutti i possibili valori di `dayCurrentNumber`; il commento iniziale
                // descrive le alternative di consegna più vicine a `dayCurrent`
                switch(dayCurrentNumber) {
                    case 0:
                        // prossima settimana, il primo giorno >= 2
                        // tra due settimane, giorno 1
                        dayAvailableNumber = deliveryDays.find(i => i >= 2);
                        dayDelta = dayAvailableNumber ? dayAvailableNumber : 8;
                        break;
                    case 1:
                        // questa settimana, il primo giorno >= 3
                        // prossima settimana, il primo giorno disponibile
                        dayAvailableNumber = deliveryDays.find(i => i >= 3);
                        dayDelta =
                            dayAvailableNumber ?
                            dayAvailableNumber - 1 :
                            6 + deliveryDays[0];
                        break;
                    case 2:
                        // questa settimana, il primo giorno >= 4
                        // prossima settimana, il primo giorno disponibile
                        dayAvailableNumber = deliveryDays.find(i => i >= 4);
                        dayDelta =
                            dayAvailableNumber ?
                            dayAvailableNumber - 2 :
                            5 + deliveryDays[0];
                        break;
                    case 3:
                        // questa settimana il giorno 5
                        // prossima settimana, il primo giorno disponibile
                        dayAvailableNumber = deliveryDays.find(i => i === 5);
                        dayDelta =
                            dayAvailableNumber ?
                            2 :
                            4 + deliveryDays[0];
                        break;
                    case 4:
                        // prossima settimana, il primo giorno disponibile
                        dayDelta = deliveryDays[0] + 3;
                        break;
                    case 5:
                        // prossima settimana, il primo giorno >= 2
                        // tra due settimane, giorno 1
                        dayAvailableNumber = deliveryDays.find(i => i >= 2);
                        dayDelta =
                            dayAvailableNumber ?
                            dayAvailableNumber + 2 :
                            10;
                        break;
                    case 6:
                        // prossima settimana, il primo giorno >= 2
                        // tra due settimane, giorno 1
                        dayAvailableNumber = deliveryDays.find(i => i >= 2);
                        dayDelta =
                            dayAvailableNumber ?
                            dayAvailableNumber + 1 :
                            9;
                        break;
                }
                firstEvasionDate = dayCurrent.add(dayDelta, 'days').valueOf();
            }
        }
    } else {
        if (commercialArea) {
            const today: Date = new Date();
            const noOfDaysToAdd = commercialArea.delivery_days;
            let endDate: Date = today;
            let	count = 0;
            while (count < noOfDaysToAdd) {
                endDate = new Date(today.setDate(today.getDate() + 1));
                // skip weekends (0 = sunday, 6 = saturday)
                if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
                    count++;
                }
            }
            firstEvasionDate = endDate.getTime();
        }
    }
    return firstEvasionDate;
}