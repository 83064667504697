import PouchDB from "pouchdb";
import pouchdbFind from "pouchdb-find";
import pouchdbUpsert from "pouchdb-upsert";
import pouchdbAdapterCordovaSqlite from "pouchdb-adapter-cordova-sqlite";
import pouchdbAdapterIndexeddb from "pouchdb-adapter-indexeddb";

import { BehaviorSubject, Observable } from "rxjs";

import { IPouchAppConfig } from "../app.config";
import { ConfigCouchModel } from "../environment-config.model";
import { DBType, OfflineDeviceService } from "../offline-device.service";
import { PouchDbSyncProgress } from "./pouchdb-sync-progress.model";

//declare function require(name: string);

//PouchDB.plugin(require("pouchdb-upsert"));
PouchDB.plugin(pouchdbUpsert);
PouchDB.plugin(pouchdbFind);

/** Classe per gestire la comunicazione e gli stati della stessa con il remoto e l'eventuale replica locale */

export abstract class PouchDbAdapter {
  protected appConfig: ConfigCouchModel;

  protected _pouchDB: PouchDB.Database;
  protected _couchDB: PouchDB.Database;
  private _activeDB: PouchDB.Database;

  protected _pouchDbName: string;
  protected _couchConfig: ConfigCouchModel;
  abstract database: string;
  abstract baseDatabaseTemplate: string;

  // rxjs behaviour subjects to expose stats flags
  syncStatus = new BehaviorSubject<boolean>(false);
  couchDbUp = new BehaviorSubject<boolean>(false);
	private pouchCouchSyncProgressStatus$ = new BehaviorSubject<PouchDbSyncProgress>({
		pouchDbDocCount: 0,
		couchDBDocCount: 0,
		progressPerc: 0,
		remoteDbSyncComplete: false,
  });

  get pouchCouchSyncProgressStatus(): Observable<PouchDbSyncProgress> {
    return this.pouchCouchSyncProgressStatus$.asObservable();
  }

  constructor(private masterAppConfig: IPouchAppConfig, private offlineDeviceService: OfflineDeviceService) {}

  protected async initConnection(couchConfig: ConfigCouchModel) {
    // Inizializzo sempre il remote per il Sync
    this._couchConfig = couchConfig;
    this._pouchDbName = this.database ?? this._couchConfig.database;
    await this.initRemoteCouch();

    // Offline: Verifica la rpesenza del DB sqlite locale e se none siste effettua il download
		const selectedDevice = await this.offlineDeviceService.init();
		if (selectedDevice.offlineMode) {
				const offline = await this.initLocalPouch();
				this.syncLocalWithRemote();
		}
  }

  async initDb(config: ConfigCouchModel) {
    try {
      this.appConfig = config;
      await this.initConnection(config);
      await this.setDB(config.offline ? true : false);
      this.initFunctions();
    } catch (err) {
      throw new Error(`Can't start couchdb`);
    }
  }

  async closeConnection() {
    try {
      if (this._couchDB) {
        await this._couchDB?.close();
        this._couchDB = undefined;
      }
      this._activeDB = undefined;
    } catch (err) {
      throw new Error("can't stop couchdb ");
    }
  }

  abstract initFunctions(): void;

  /**
   * Get complete url of db
   *
   * @return string
   */
  protected get remoteCouchDBUrl(): string {
    if (!this._couchConfig.endpoint || !this.database) {
      return null;
    }
    return this._couchConfig.endpoint + "/" + this.database;
  }

  async initLocalPouch() {
    const deviceConfig = await this.offlineDeviceService.init();
    const dbType = deviceConfig.dbType;
    let initOffliseMode = false;

    if (dbType === DBType.LocalSqlLite) {
      const options = {
        adapter: "cordova-sqlite",
        location: "default", // Da utilizzare solo se non si usa la locazione specifica
        /*
          iosDatabaseLocation: [optional] iOS Database Location. Available options are:
              - default: Library/LocalDatabase subdirectory - NOT visible to iTunes and NOT backed up by iCloud
              - Library: Library subdirectory - backed up by iCloud, NOT visible to iTunes
              - Documents: Documents subdirectory - visible to iTunes and backed up by iCloud
        */
        // iosDatabaseLocation: 'Library',
        // throw an exception in case of androidDatabaseImplementation: 2 setting which is now superseded by androidDatabaseProvider: 'system' setting
        // androidDatabaseProvider: 'system',
        auto_compaction: true,
        debug: false,
        revs_limit: 1,
        batch_size: 1000,
      };
      initOffliseMode = true;
      //PouchDB.plugin(require("pouchdb-adapter-cordova-sqlite"));
      PouchDB.plugin(pouchdbAdapterCordovaSqlite);
      this._pouchDB = new PouchDB(this._pouchDbName, options);
    } else if (dbType === DBType.LocalIndexDB) {
      // https://pouchdb.com/2020/02/12/pouchdb-7.2.0.html
      const options = {
        adapter: "indexeddb",
        auto_compaction: true,
        revs_limit: 1,
        batch_size: 10000,
      };
      initOffliseMode = true;
      PouchDB.plugin(pouchdbAdapterIndexeddb.default);
      this._pouchDB = new PouchDB(this._pouchDbName, options);

      // Verifica presenza di flag per persistenza indexeddb
      if (navigator.storage && navigator.storage.persist) {
        navigator.storage.persist().then(function (persistent) {
          if (persistent) {
            console.log(
              "Storage will not be cleared except by explicit user action"
            );
          } else {
            console.log(
              "Storage may be cleared by the UA under storage pressure."
            );
          }
        });
      }
    } else {
      // ????
      initOffliseMode = false;
    }

    if (initOffliseMode) {
      this.setExplicitDB(true);
    }

    return initOffliseMode;
  }

  async initRemoteCouch() {
    this._couchDB = new PouchDB(this.remoteCouchDBUrl, {
      skip_setup: true, // Disable the accidental DB creation
      fetch: (url, opts: any) => {
        opts.headers.set(
          "x-auth-couchdb-username",
          this.masterAppConfig.username
        );
        opts.headers.set("x-auth-couchdb-roles", "");
        opts.headers.set(
          "x-auth-couchdb-token",
          this.masterAppConfig.signature
        );
        if (this.appConfig.apiKey && url) {
          url = (typeof url === "string") ? this.addApiKeyToUrl(url) : new Request(this.addApiKeyToUrl(url.url), url);
        }
        return PouchDB.fetch(url, opts);
      },
    });
  }

  syncLocalWithRemote() {
    this._pouchDB
      .sync(this._couchDB, {
        live: true,
        retry: true,
      })
      .on("change", (info) => {
        console.log("SYNC CHANGE: ", info);
        this.syncStatusUpdate();
      })
      .on("paused", (err) => {
        this.syncStatusUpdate();
      })
      .on("error", (err) => {
        // TODO: Write error handling and display message to user
        console.error("SYNC Error: ", err);
      })
      .on("active", () => {
        // TODO: Write code when sync is resume after pause/error
        console.log("C2P Resume");
      })
      .on("complete", function () {
        // TODO: Write code when sync is cancelled
        console.log("SYNC Complete");
      });
  }

  watchAllRemoteChanges() {
    const changes = this._couchDB
      .changes({
        since: "now",
        live: true,
        include_docs: true,
      })
      .on("change", (change) => {
        console.log("syncro");
        console.log(change);
        // this.LocalDb.put(change.doc).then( response => console.log(response));
        // handle change
      })
      .on("complete", (info) => {
        console.log("complete", info);
        // changes() was canceled
      })
      .on("error", (err) => {
        console.log("error", err);
      });
  }

  deleteRemote() {
    return this._couchDB.destroy();
  }

  // function to call the below functions
  // then update the rxjs BehaviourSubjects with the
  // results
  private syncStatusUpdate(): void {
    this.checkPouchCouchSync().then((result) => {
      this.syncStatus.next(result);
    });
    this.progressPouchCouchSyncOffline().then((result) => {
      this.pouchCouchSyncProgressStatus$.next(result);
      if (result.progressPerc === 100) {
        this.pouchCouchSyncProgressStatus$.complete();
        console.log(`${this.database} => ${result.progressPerc}% - COMPLETE`);
      }
    });
    this.checkCouchUp().then((result) => {
      this.couchDbUp.next(result);
    });
  }

  private addApiKeyToUrl(url: string) {
    return `${url.includes("?") ? url + '&' : url + '?'}apikey=${this.appConfig.apiKey}`;
  }

  // part of the JSON returned by PouchDB from the info() method
  // is 'update_seq'. When these numbers are equal then the databases
  // are in sync. The way its buried in the JSON means some string
  // functions are required to extract it
  private checkPouchCouchSync(): Promise<boolean> {
    // if both objects exist then make a Promise from both their
    // info() methods
    if (this._pouchDB && this._couchDB) {
      return (
        Promise.all([this._pouchDB.info(), this._couchDB.info()])
          // using the 0 and 1 items in the array of two
          // that is produced by the Promise
          // Do some string trickery to get a number for update_seq
          // and return 'true' if the numbers are equal.
          .then((results: any[]) => {
            return (
              Number(String(results[0].update_seq).split("-")[0]) ===
              Number(String(results[1].update_seq).split("-")[0])
            );
          })
          // on error just resolve as false
          .catch((error) => false)
      );
    } else {
      // if one of the PouchDB or CouchDB objects doesn't exist yet
      // return resolve false
      return Promise.resolve(false);
    }
  }

  private progressPouchCouchSyncOffline(): Promise<PouchDbSyncProgress> {
    if (this._pouchDB && this._couchDB) {
      return (
        Promise.all([this._pouchDB.info(), this._couchDB.info()])
          .then((results: any[]) => {
            return <PouchDbSyncProgress>{
              pouchDbDocCount: results[0].doc_count,
              couchDBDocCount: results[1].doc_count,
              progressPerc: Math.floor(
                (results[0].doc_count / results[1].doc_count) * 100
              ),
              remoteDbSyncComplete: results[0].doc_count < results[1].doc_count,
              database: this.database,
            };
          })
          // on error just resolve as null
          .catch((error) => null)
      );
    } else {
      // if one of the PouchDB or CouchDB objects doesn't exist yet
      // return resolve null
      return Promise.resolve(null);
    }
  }

  async isDbSyncronized(): Promise<boolean> {
    return (
      this.progressPouchCouchSyncOffline()
        .then((x) => {
          return x.remoteDbSyncComplete;
        })
        // on error just resolve as null
        .catch((error) => true)
    );
  }

  // fairly self explanatory function to make a
  // GET http request to the URL and return false
  // if an error status or a timeout occurs, true if
  // successful.
  private checkCouchUp(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      const urlToCall = this.appConfig.apiKey && this.remoteCouchDBUrl ?
        this.addApiKeyToUrl(this.remoteCouchDBUrl) :
        this.remoteCouchDBUrl;

      xhr.open("GET", urlToCall, true);
      xhr.setRequestHeader(
        "x-auth-couchdb-token",
        this.masterAppConfig.signature
      );
      xhr.setRequestHeader(
        "x-auth-couchdb-username",
        this.masterAppConfig.username
      );
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      xhr.onerror = () => {
        reject();
      };
      xhr.send();
    });
  }

  /**
   * use it to explicit set the database target
   */
  protected setExplicitDB(local: boolean) {
    this._activeDB = local ? this._pouchDB : this._couchDB;
  }

  /**
   * If the remote db isn't reachable it returns local db
   * else it returns the activeDB.
   * If also the activeDB doesn't exist it returns the remote DB
   */
  protected setDB(offline: boolean): Promise<PouchDB.Database> {
    return new Promise((resolve, reject) => {
      this.checkCouchUp()
        .then((result) => {
          this.couchDbUp.next(result);
          if (!result) {
            resolve((this._activeDB = this._pouchDB));
          } else {
            resolve(
              (this._activeDB = this._activeDB ? this._activeDB : this._couchDB)
            );
          }
        })
        .catch(() => {
          if (offline) {
            resolve((this._activeDB = this._pouchDB));
          } else {
            reject();
          }
        });
    });
  }

  protected getDB() {
    return this._activeDB;
  }
}
