import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@saep-ict/angular-core';
import { OfflineDeviceService, PouchDbAdapter, TransmissionService } from '@saep-ict/pouch-db';

import { CustomerAppConfig } from '../../../customer-app.config';
import { BasePouch } from './pouch-function/base.pouch';
import { OrganizationPouch } from './pouch-function/organization.pouch';
import { OrderPouch } from './pouch-function/order.pouch';
import { PricePouch } from './pouch-function/price.pouch';
// import { ContactPouch } from './pouch-function/contact.pouch';
import { OpportunityPouch } from './pouch-function/opportunity.pouch';
import { StatisticsPouch } from './pouch-function/statistics.pouch';
import { ArticleKitPouch } from './pouch-function/article-kit.pouch';
import { OfferPouch } from './pouch-function/offer.pouch';
import { AppUtilService } from '../../util/app-util.service';
import { ActionPouch } from './pouch-function/action.pouch';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

@Injectable()
export class PouchDbBackofficeAdapter extends PouchDbAdapter {
	baseDatabaseTemplate = 'backoffice_[backoffice_code_placeholder]';
	database = 'backoffice_[backoffice_code_placeholder]';
	variation: string = ContextApplicationItemCodeEnum.BACKOFFICE;
	basePouch: BasePouch;
	orderPouch: OrderPouch;
	organizationPouch: OrganizationPouch;
	pricePouch: PricePouch;
	// contactPouch: ContactPouch;
	opportunityPouch: OpportunityPouch;
	statisticsPouch: StatisticsPouch;
	articleKitPouch: ArticleKitPouch;
	offerPouch: OfferPouch;
	actionPouch: ActionPouch;

	constructor(
		private config: CustomerAppConfig,
		private httpClient: HttpClient,
		private loaderService: LoaderService,
		offlineDeviceService: OfflineDeviceService,
		private utilService: AppUtilService,
		private transmissionService: TransmissionService
	) {
		super(config, offlineDeviceService);
	}

	async initFunctions() {
		this.basePouch = new BasePouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);
		this.orderPouch = new OrderPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);
		this.organizationPouch = new OrganizationPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);
		this.pricePouch = new PricePouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);
		// this.contactPouch = new ContactPouch(
		// 	this.getDB(),
		// 	this.httpClient,
		// 	this.config,
		// 	this.transmissionService,
		// 	this.loaderService
		// );
		this.opportunityPouch = new OpportunityPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);

		this.statisticsPouch = new StatisticsPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService,
			this.utilService
		);

		this.articleKitPouch = new ArticleKitPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);
		this.offerPouch = new OfferPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);
		this.actionPouch = new ActionPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.transmissionService,
			this.loaderService
		);
	}
}
