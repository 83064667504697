import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'dialog-attachment-list-manager',
	templateUrl: './dialog-attachment-list-manager.component.html',
	styleUrls: ['./dialog-attachment-list-manager.component.scss']
})
export class DialogAttachmentListManagerComponent {

	constructor(
		public dialogRef: MatDialogRef<DialogAttachmentListManagerComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) { }

	attachmentListManagerChange() {
		if (this.data && this.data.closeDialoOnAttachmentListManagerChange) {
			this.dialogRef.close();
		}
	}
}
