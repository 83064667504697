<mat-dialog-wrapper
	[title]="data.title | sentencecase"
	[showCloseIcon]="true"
>
	<ng-container content>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 dialog-order-detail__status-bar mb-3">
					<status-bar-new id="theme-white" [config]="statusBarConfig"></status-bar-new>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-8 dialog-order-detail__essay">
					<b2c-checkout-article-list [productList]="productList" [order]="order"></b2c-checkout-article-list>
					<order-detail-price [order]="order"></order-detail-price>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-4 dialog-order-detail__main-data">
					<div class="row">
						<div class="col-md-6 col-lg-12">
							<h3> {{'checkout.field.destination' | translate | sentencecase}}</h3>
							<p *ngIf="order.header.goods_destination_object">
								{{ order.header.goods_destination_object.address }}<br />
								{{ order.header.goods_destination_object.zip_code }} - {{ order.header.goods_destination_object.locality }} ({{
									order.header.goods_destination_object.province['label']
								}})
								<br />
								{{ order.header.goods_destination_object.country }}
							</p>
						</div>
						<div class="col-md-6 col-lg-12">
							<h3>{{'checkout.field.payment_method'| translate | sentencecase}}</h3>
							<p *ngIf="order.header.payment_object">
								{{
									order.header.payment_object.description ?
									order.header.payment_object.description :
									order.header.payment_object.description_short
								}}
							</p>
						</div>
					</div>
					<ng-container *ngIf="order.header.note_order">
						<h3>{{'checkout.field.note'| translate | sentencecase}}</h3>
						<p>{{ order.header?.note_order }}</p>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</mat-dialog-wrapper>
