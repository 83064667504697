import { transition, animate, style, trigger, state } from '@angular/animations';

export const animations = [
	trigger('slide', [
		state('*', style({ left: 'calc({{left_position}} * 100%)' }), {
			params: {
				left_position: 0,
				timing: '.5s'
			}
		}),
		transition('* <=> *', [animate('{{timing}} {{easing}}')])
	])
];
