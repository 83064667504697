import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { TicketCenterLibConfigurationStoreReducer } from "./lib-configuration.reducer";


export const storeModule = StoreModule.forFeature('ticket-center-lib-configuration', TicketCenterLibConfigurationStoreReducer.reducer);

@NgModule({
    imports: [
      storeModule
    ]
})
export class TicketCenterLibConfigurationStoreModule{}
