import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { FulfilledTrendDetailChartConfigService } from '../../../../service/chart-structure/implementation/fulfilled-trend-detail-chart.service';
import { StatisticsAgent } from '../../../../model/statistics-agent.model';
import { StateFeature } from '../../../../state';
import { filter, take } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { StatisticsDetailSoldStateAction } from '../../../../state/statistics-detail-sold/statistics-detail-sold.action';
import { FulfilledQuartersChartConfigService } from '../../../../service/chart-structure/implementation/fulfilled-quarters-chart.service';
import { StatisticsDetailSold } from '../../../../model/statistics-detail-sold.model';
import { FulfilledQuantityChartConfigService } from '../../../../service/chart-structure/implementation/fulfilled-quantity-chart.service';
import { FulfilledProductsChartConfigService } from '../../../../service/chart-structure/implementation/fulfilled-products-chart.service';
import { FulfilledProductsTableConfigService } from '../../../../service/chart-structure/implementation/fulfilled-products-table.service';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { FulfilledCategoriesTableConfigService } from '../../../../service/chart-structure/implementation/fulfilled-categories-table.service';
import { FulfilledCategoriesChartConfigService } from '../../../../service/chart-structure/implementation/fulfilled-categories-chart.service';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'dashboard-agent-sold',
	templateUrl: './dashboard-agent-sold.component.html',
	styleUrls: ['./dashboard-agent-sold.component.scss'],
	providers: [
		FulfilledTrendDetailChartConfigService,
		FulfilledQuartersChartConfigService,
		FulfilledQuantityChartConfigService,
		FulfilledProductsChartConfigService,
		FulfilledProductsTableConfigService,
		FulfilledCategoriesChartConfigService,
		FulfilledCategoriesTableConfigService
	]
})
export class DashboardAgentSoldComponent implements OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	// Per la visualizzazione della data di ultimo aggiornamento delle statistiche
	dateUpdatedStatistics: number;
	statistics$: Observable<BaseStateModel<StatisticsDetailSold>> = this.store.select(
		StateFeature.getStatisticsDetailSold
	);
	statistics: StatisticsDetailSold;

	// Timerange
	timeRange;

	constructor(
		private store: Store<StatisticsAgent>,
		private utilService: AppUtilService,
		public fulfilledQuartersChartConfigService: FulfilledQuartersChartConfigService,
		public fulfilledTrendDetailChartConfigService: FulfilledTrendDetailChartConfigService,
		public fulfilledQuantityChartConfigService: FulfilledQuantityChartConfigService,
		public fulfilledProductsChartConfigService: FulfilledProductsChartConfigService,
		public fulfilledProductsTableConfigService: FulfilledProductsTableConfigService,
		public categoriesBestSellerChartConfigService: FulfilledCategoriesChartConfigService,
		public categoriesTop5TableConfigService: FulfilledCategoriesTableConfigService
	) {
		// Load Statistics Sold
		this.store.dispatch(StatisticsDetailSoldStateAction.load());

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statistics$
			.pipe(
				filter((state: BaseStateModel<StatisticsDetailSold>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.date_update;

				const quarterList = [];
				this.utilService.findLeaf(state.data.data, 'quarters').forEach(item => quarterList.push({ q: item.name, y: item.year }));
				this.timeRange = quarterList;
			});
	}

	setTimeRange(selectedTimeRange: MatSelectChange) {
		const charts = [
			this.fulfilledQuartersChartConfigService,
			this.fulfilledTrendDetailChartConfigService,
			this.fulfilledQuantityChartConfigService,
			this.fulfilledProductsChartConfigService,
			this.categoriesBestSellerChartConfigService
		];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [
			this.fulfilledProductsTableConfigService,
			this.categoriesTop5TableConfigService
		];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	ngOnDestroy() {
		this.store.dispatch(StatisticsDetailSoldStateAction.reset());
	}
}
