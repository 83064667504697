import { Injector } from '@angular/core';
import { DateMomentService, ITdDataTableColumnCustom, AngularCoreUtilService } from '@saep-ict/angular-core';
import * as TicketCenterStatus from './status-configuration.constant';
import { TitleCasePipe } from '@angular/common';
import { TicketStatusItem } from '@saep-ict/ticket-center';
import { ConfigurationUtil, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

const injector = Injector.create({
	providers: [
		{ provide: DateMomentService, deps: [] },
		{ provide: AngularCoreUtilService, deps: [] },
		{ provide: TitleCasePipe, deps: [] }
	]
});

const dateMomentService = injector.get(DateMomentService);
const utilService = injector.get(AngularCoreUtilService);
const titleCasePipe = injector.get(TitleCasePipe);

export const basePre: ITdDataTableColumnCustom[] = [
	{
		name: 'id',
		label: 'ID'
	},
	{
		name: 'body.custom_field.request_type',
		labelPath: 'lib.ticket_center.custom_field.request_type',
		label: null,
		format: (v: string) => utilService.formatPlaceholder(v)
	}
];

export const basePost: ITdDataTableColumnCustom[] = [
	{
		name: 'request.date_request',
		labelPath: 'tc.general.date_request',
		label: null,
		numeric: true,
		format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
	}
];

export const b2b: ITdDataTableColumnCustom[] = [
	{
		name: 'status',
		labelPath: 'tc.general.status',
		label: null,
		format: (v: string) => returnContextApplicationMap(v, ContextApplicationItemCodeEnum.B2B)
	}
];

export const backoffice: ITdDataTableColumnCustom[] = [
	{
		name: 'body.custom_field.organization_code_item',
		labelPath: 'lib.ticket_center.column.organization_code',
		label: null
	},
	{
		name: 'status',
		labelPath: 'tc.general.status',
		label: null,
		format: (v: string) => returnContextApplicationMap(v, ContextApplicationItemCodeEnum.BACKOFFICE)
	}
];

export const contextApplicationMap = <ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumnCustom[]>>{
	BACKOFFICE_ADMIN: [
		...basePre,
		...backoffice,
		...basePost
	],
	BACKOFFICE: [...basePre, ...backoffice, ...basePost],
	B2B: [...basePre, ...b2b, ...basePost],
	PORTAL: [...basePre, ...basePost]
};

export const returnContextApplicationMap = (
	value: string,
	contextApplication: ContextApplicationItemCodeEnum
): string => {
	const statusMapItem: TicketStatusItem = TicketCenterStatus.contextApplicationMap[
		contextApplication
	].filter_list.find(i => i.value === TicketCenterStatus.TypeEnum[value]);
	return statusMapItem
		? titleCasePipe.transform(
				utilService
					.formatPlaceholder(statusMapItem.name ? statusMapItem.name : statusMapItem.value)
					.toLocaleLowerCase()
			)
		: titleCasePipe.transform(utilService.formatPlaceholder(value).toLocaleLowerCase());
};
