import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
	BaseStateModel,
	DateMomentService,
	FormDynamicConfigurationModel,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel, OrderPouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { TableOrderModel } from '../../../model/table/table-order.model';
import { StateRelatedLink } from '../../../constants/configuration-customer/order/status-aggregation-map.constant';
import * as ConfigurationCustomerOrder from '../../../constants/configuration-customer/order/order.constant';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { OrderListActionEnum, OrderListStateAction } from '../../../state/order-list/order-list.actions';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { delay, filter, map, mergeMap, take } from 'rxjs/operators';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { DialogOrderDetailComponent } from '../../../widget/dialog/dialog-order-detail/dialog-order-detail.component';
import { OrderListWrapperComponent } from '../../../widget/order/order-list-wrapper/order-list-wrapper.component';
import {
	ContextApplicationItemCodeEnum,
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	PATH_URL,
	UserDetailModel,
	OrganizationStateModel,
	AngularSpin8CoreUtilCompanyService,
	AuxiliaryTableStateModel
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import { ExportExcelService } from '../../../service/export/export-excel.service';
import { OrderListFilterCreationFieldMapFormValue } from '../../../model/order-util.model';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import * as ConfigurationCustomerOrderErp from '../../../constants/configuration-customer/order/order-erp.constant';
import * as ConfigurationCustomerCalcSheet from '../../../constants/configuration-customer/calc-sheet/calc-sheet.constant';

@Component({
	selector: 'order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderComponent implements AfterViewInit, OnDestroy {
	@ViewChild(OrderListWrapperComponent)
	orderListWrapperComponent: OrderListWrapperComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: TableOrderModel[] = [];
	orderListFiltered: TableOrderModel[] = [];

	listPageBaseData = <LocalListHandlerBaseModel<TableOrderModel>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'_id',
					'header.organization.business_name',
					'header.organization.code_item',
					// TODO: per permettere la ricerca in code_erp, questi va salvato nell'ordine
					ConfigurationCustomerAppStructure.erp_has_erp ? 'product_list.code_erp' : 'product_list.code_item'
				]
			}
		},
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'header.date',
			order: 'DESC'
		},
		data: []
	};

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	currentContext: ContextApplicationItemCodeEnum;

	// Contesto di pagina
	// 'orders'
	orderState: string;

	configurationCustomerOrder = ConfigurationCustomerOrder;
	configurationCustomerCalcSheet = ConfigurationCustomerCalcSheet;

	sideBarPositionValues = SideBarPositionValues;

	formDynamicConfiguration: FormDynamicConfigurationModel;

	constructor(
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		public dateMomentService: DateMomentService,
		private utilOrderService: UtilOrderService,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private dialog: MatDialog,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private exportService: ExportExcelService
	) {
		this.store.dispatch(OrderListStateAction.loadAll());
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.currentContext = res ? res.data.current_permission.context_application : null;
			this.listPageBaseData.columnList = ConfigurationCustomerOrder.columnList(this.currentContext);
		});
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
		this.organization$.pipe(take(1)).subscribe(res => {
			// determina se la lista ordini è contestuale ad un'unica organization
			this.organization = res ? res.data : null;
		});

		this.setRouteMetaInformation();
	}

	ngAfterViewInit() {
		this.subscribeManagerService.populate(this.subscribeOrderList().subscribe(), 'subscribeOrderList');
	}

	ngOnDestroy() {
		this.store.dispatch(OrderListStateAction.reset());
		this.subscribeManagerService.destroy();
		this.unsetRouteMetaInformation();
	}

	subscribeOrderList() {
		return this.route.paramMap.pipe(
			delay(0), // ERROR Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after...
			mergeMap(params => {
				this.orderState = params.get('orderStatus') ? params.get('orderStatus').toUpperCase() : null;
				this.setRouteMetaInformation();
				return this.orderList$;
			}),
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					orderList && orderList.type !== OrderListActionEnum.LOAD_ALL
			),
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					!!(this.user.current_permission.context_code.code && orderList)
			),
			map(async (orderList: BaseStateModel<OrderStateModel[]>) => {
				switch (orderList.type) {
					case OrderListActionEnum.UPDATE:
						this.permanentFiltersOrder(orderList.data);
						break;
					case OrderListActionEnum.ERROR:
						this.orderListWrapperComponent.updateListPageBaseData([]);
						throw new Error(OrderListActionEnum.ERROR);
					default:
						break;
				}
				this.formDynamicConfiguration = {
					creationFieldMap:
						await ConfigurationCustomerOrder.listFilterCreationFieldMap(
							this.user.current_permission.context_application,
							{
								order_causal: this.auxiliaryTable.causalHeaderSoList,
								status:
									ConfigurationCustomerOrder.statusAggregationMap[
										ContextApplicationItemCodeEnum[
											this.user.current_permission.context_application
										]
									]
							},
							this.orderState ? true : false
						),
					emitAlsoNonValidForm: false,
					buttonReset: {
						show: true
					}
				};
				this.formDynamicConfiguration = _.cloneDeep(this.formDynamicConfiguration);
			})
		);
	}

	// misc
	// Filtrare in base al contesto
	async permanentFiltersOrder(orderList: OrderStateModel[]) {
		// TODO: - ci sono degli ordini senza header, come mai?
		orderList = orderList.filter(order => order.header);
		if (this.orderState) {
			const statusRelatedMap: StateRelatedLink[] =
				ConfigurationCustomerOrder.statusAggregationMap[
					ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
				];
			if (statusRelatedMap) {
				const statusRelatedList = statusRelatedMap.find(i => i.state === this.orderState);
				if (statusRelatedList) {
					orderList = await ConfigurationCustomerOrder.filterByOrderStatus(orderList, statusRelatedList.related_list);
				}
			}
		} else {
			orderList = orderList.filter(order => order.header.status !== OrderStatusEnum.DELETED);
		}

		orderList = ConfigurationCustomerOrder.listFilter(
			orderList,
			this.user.current_permission.context_application,
			this.organization
		);
		this.orderList = ConfigurationCustomerOrderErp.getTableOrderList(_.cloneDeep(orderList));
		this.orderListFiltered = _.cloneDeep(this.orderList);
		this.orderListWrapperComponent.updateListPageBaseData(this.orderList);
	}

	orderDraftCreate() {
		this.utilOrderService.orderDraftCreate(this.organization);
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.B2B:
				this.router.navigate([
					`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.user.current_permission.context_code.code}/new/header-edit`
				]);
				break;
			default:
				this.router.navigate([
					`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.organization.code_item}/new/header-edit`
				]);
				break;
		}
	}

	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.B2C:
				this.openDialogOrderDetailB2c(selectedOrder);
				break;
			default:
				this.router.navigate([
					PATH_URL.PRIVATE,
					'orders',
					selectedOrder.header.status,
					selectedOrder.header.organization.code_item,
					selectedOrder._id,
					'checkout'
				]);
				break;
		}
	}

	openDialogOrderDetailB2c(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		// ??? product_list manca nell'order passato come parametro
		const order = this.orderList.find(order => {
			return order._id === selectedOrder._id;
		});
		this.dialog.open(DialogOrderDetailComponent, {
			data: {
				title: 'Dettaglio ordine',
				order: order
			},
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog'],
			disableClose: true
		});
	}

	setRouteMetaInformation() {
		if (!this.route.parent.component || this.route.parent.component === FramePageComponent) {
			// Componente radice
			this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('orders');
			this.utilBreadcrumbService.subtitle.value = 'saep_ict_angular_spin8_core.status.order.' + this.orderState;
			if (this.orderState) {
				this.utilBreadcrumbService.updateActiveNavigationItemSource.next([
					'orders',
					`orders_${this.orderState.toLocaleLowerCase()}`
				]);
			}
		}
	}

	unsetRouteMetaInformation() {
		if (!this.route.parent.component || this.route.parent.component === FramePageComponent) {
			// Componente radice
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		}
	}

	async onFormValueChange(e: OrderListFilterCreationFieldMapFormValue) {
		if (e) {
			this.orderListFiltered =
				await ConfigurationCustomerOrder.returnListFilterByFormValue(
					this.user.current_permission.context_application,
					_.cloneDeep(this.orderList),
					e
				);
			this.orderListWrapperComponent.updateListPageBaseData(this.orderListFiltered);
		}
	}

	exportExcel() {
		if(this.orderListFiltered && this.orderListFiltered.length > 0) {
			this.exportService.order_download(this.orderListFiltered, this.user);
		} else {
			this.exportService.order_download(this.orderList, this.user);
		}
	}
}
