// model
import { LoginContextCodeActionEnum, LoginContextCodeStateAction } from './login-context-code.actions';

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { BodyTablePouchCustomModel } from '@saep-ict/angular-spin8-core';

export namespace LoginContextCodeStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchCustomModel> = null;

	const _reducer = createReducer(
		initialState,
		on(LoginContextCodeStateAction.load, state => state),
		on(LoginContextCodeStateAction.update, (state, param) => param),
		on(LoginContextCodeStateAction.reset, () => initialState),
		on(LoginContextCodeStateAction.error, (state, param) => param)
	);

	export function reducer(
		state: BaseStateModel<BodyTablePouchCustomModel>,
		action: TypedAction<LoginContextCodeActionEnum>
	) {
		return _reducer(state, action);
	}
}
