import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export const baseColumns: ITdDataTableColumnCustom[] = [
	// TODO: capire come gestire lo status
	// {
	// 	name: 'status',
	// 	label: ''
	// 	width: 50
	// },
	{
		name: 'full_name',
		labelPath: 'contact.field.name',
		label: null
	},
	{
		name: 'phone',
		labelPath: 'contact.field.phone',
		label: null
	},
	{
		name: 'email',
		labelPath: 'contact.field.email',
		label: null
	},
	{
		name: 'organization.business_name',
		labelPath: 'saep_ict_angular_spin8_core.page.organization.name',
		label: null
	}
];