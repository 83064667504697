// import { SatDatepickerRangeValue } from "saturn-datepicker";
// import { ContextApplicationItemCodeEnum } from '../../enum/context-application-item-code.enum';

// export abstract class AbstractListModel<D> {
// 	pagination?: Pagination;
// 	filters?: Filters;
// 	sort?: any[];
// 	data?: D;
// }

// export class Pagination {
// 	page_current: number;
// 	page_size: number;
// 	total_element?: number;
// }

// export class Filters {
// 	_id?: string;
// 	text?: string;
// 	types?: string[];
// 	order_states?: string[];
// 	company_states?: string[];
// 	date?: string[];
// 	due_date?: string[];
// 	tot?: string[];
// 	business_name?: string;
// 	commercialAreas?: string[];
// 	years?: number[];
// 	brandList?: string[];
// 	brandApprovationStatusList?: string[];
// 	status?: string;
// 	company?: string;
// 	status_list?: any[];
// 	asset_type?: number;
// 	urgent?: boolean;
// 	context_code?: ContextApplicationItemCodeEnum;
// 	lineList?: string[];
// 	date_request?: SatDatepickerRangeValue<number>;
// 	date_due?: SatDatepickerRangeValue<number>;
// 	exclude_code?: (string | number)[];

// 	// account balance
// 	date_document?: SatDatepickerRangeValue<number>;
// 	date_expiration?: SatDatepickerRangeValue<number>;
// }

// export interface Sort {
// 	sort_type?: string;
// 	sort_direction?: string;
// }

// export interface PageSettingsModel {
// 	pageName?: string;
// 	pagination?: Pagination;
// 	filters?: Filters;
// 	sort?: any[];
// }

// export abstract class AbstractQueryListModel<D> {
// 	query?: PageSettingsModel;
// 	data?: D;
// }

import { PageEvent } from '@angular/material/paginator';
import { ITdDataTableSortChangeEvent } from '@covalent/core/data-table';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

export abstract class AbstractListModel<D> {
	pagination?: Pagination;
	filters?: Filters;
	sort?: any[];
	data?: D;
}

export class Pagination {
	page_current: number;
	page_size: number;
	total_element?: number;
}

export class Filters {
	_id?: string;
	text?: string;
	types?: string[];
	order_states?: string[];
	company_states?: string[];
	date?: string[];
	due_date?: string[];
	tot?: string[];
	business_name?: string;
	commercialAreas?: string[];
	years?: number[];
	brandList?: string[];
	brandApprovationStatusList?: string[];
	status?: string;
	company?: string;
	status_list?: any[];
	asset_type?: number;
	urgent?: boolean;
	context_code?: ContextApplicationItemCodeEnum;
	lineList?: string[];
	date_request?: DateRangeValue<number>;
	date_due?: DateRangeValue<number>;
	exclude_code?: (string | number)[];

	// account balance
	date_document?: DateRangeValue<number>;
	date_expiration?: DateRangeValue<number>;

	// local search
	localSearchText?: {
		value: string;
		key_list: string[];
	};
}

export interface DateRangeValue<D> {
	begin: D | null;
	end: D | null;
}

export interface Sort {
	sort_type?: string;
	sort_direction?: string;
}

export interface PageSettingsModel {
	pageName?: string;
	pagination?: Pagination;
	filters?: Filters;
	sort?: any[];
}

export abstract class AbstractQueryListModel<D> {
	query?: PageSettingsModel;
	data?: D;
}

