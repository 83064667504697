import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import {
	InformativePageActionEnum,
	InformativePageStateAction
} from '../../../state/informative-page/informative-page.actions';
import { map, skipWhile } from 'rxjs/operators';
import { AngularSpin8CoreUtilTranslateService, InformativePageModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'informative-page',
	templateUrl: './informative-page.component.html',
	styleUrls: ['./informative-page.component.scss'],
	providers: [SubscribeManagerService]
})
export class InformativePageComponent implements OnDestroy {
	informativePage$: Observable<BaseStateModel<InformativePageModel[]>> = this.store.select(
		StateFeature.getInformativePageState
	);
	informativePage: InformativePageModel;

	constructor(
		private store: Store<InformativePageModel>,
		private activatedRoute: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {
		// returns the requested document based on the slug
		this.subscribeManagerService.populate(
			this.activatedRoute.url.subscribe(url => {
				this.store.dispatch(InformativePageStateAction.load({ slug: url[0].path }));
			}),
			'informative-page'
		);

		this.subscribeManagerService.populate(this.subscribeInformativePage().subscribe(), 'subscribeInformativePage');
	}

	subscribeInformativePage() {
		return this.informativePage$.pipe(
			skipWhile(
				(informativePageState: BaseState<InformativePageModel[]>) =>
					!(
						informativePageState &&
						informativePageState.data &&
						informativePageState.data.length > 0 &&
						informativePageState.data[0] !== undefined
					)
			),
			map((informativePageState: BaseState<InformativePageModel[]>) => {
				switch (informativePageState.type) {
					case InformativePageActionEnum.UPDATE:
						this.informativePage = informativePageState.data[0];
						break;

					case InformativePageActionEnum.ERROR:
						throw new Error(InformativePageActionEnum.ERROR);

					default:
						break;
				}

				return informativePageState;
			})
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(InformativePageStateAction.reset());
	}
}
