import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { Observable, filter, from, map, mergeMap, take } from 'rxjs';
import { MediaCenterListModel } from '../../../model/media-center.model';
import { MediaCenterService } from '../../../service/rest/media-center.service';
import { UtilDownloadService } from '../../../service/util-download.service';
import { UtilService } from '../../../service/util.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { catchError } from 'rxjs/operators';
import { ILoaderService, LOADER_SERVICE_TOKEN } from '../../../interface/loader-service.interface';
@Component({
  selector: 'media-center-latest',
  templateUrl: './media-center-latest.component.html',
  styleUrls: ['./media-center-latest.component.scss'],
  providers: [SubscribeManagerService]
})
export class MediaCenterLatestComponent implements OnInit, OnDestroy {

  data: MediaCenterListModel = new MediaCenterListModel();

  constructor(
    private mediaCenterService: MediaCenterService,
    public utilService: UtilService,
    public utilDownloadService: UtilDownloadService,
    private subscribeManagerService: SubscribeManagerService,
    @Optional() @Inject(LOADER_SERVICE_TOKEN) protected loaderService: ILoaderService,
  ) {
    this.subscribeManagerService.populate(
      this.getFolderContent().subscribe(),
      'MediaCenterLatestComponent.getFolderContent'
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscribeManagerService.destroy();
    this.loaderService.changeSingleLoader('fix-loader-ref');
  }

  getFolderContent(): Observable<void> {
    try {
      return this.utilService.contextChange.pipe(
        filter(e => !!(e && e.filter && e.filter.path)),
        mergeMap(e => from(this.mediaCenterService.getFolderContent(this.utilService.returnGetFolderQueryParam(e)))),
        map(e => {
          this.data.list = e.data.files;
        }),
        take(1),
        catchError((error, caught) => {
          this.utilService.loadSnackbarWithAsyncTranslatedAction(
            'mc.error.get_folder_content',
            'mc.general.close',
            2000
          );
          throw new Error(error);
        })
      );
    } catch(err) {
      throw new Error(err);
    }
  }

}
