import { CardArrayItemModel } from '../../model/chart.model';

export abstract class BaseChartStructureService {
    protected abstract _config: CardArrayItemModel;

    constructor() {}

    public set config(col: CardArrayItemModel){
      this._config = col;
    }

    public get config() {
      return this._config;
    }
}
