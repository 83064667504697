import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsBackoffice } from '../../model/statistics-backoffice.model';

export enum StatisticsBackofficeActionEnum {
	LOAD = '[statistics-backoffice] Load',
	UPDATE = '[statistics-backoffice] Update',
	RESET = '[statistics-backoffice] Reset',
	ERROR = '[statistics-backoffice] Error'
}

export namespace StatisticsBackofficeStateAction {
	export const load = createAction(StatisticsBackofficeActionEnum.LOAD, props<BaseStateModel<any>>());
	export const update = createAction(
		StatisticsBackofficeActionEnum.UPDATE,
		props<BaseStateModel<StatisticsBackoffice>>()
	);
	export const reset = createAction(StatisticsBackofficeActionEnum.RESET);
	export const error = createAction(StatisticsBackofficeActionEnum.ERROR, props<BaseStateModel<any>>());
}
