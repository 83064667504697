import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	SubscribeManagerService,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { BackofficeArticleFamilyBaseDirective } from '../../../shared/directives/backoffice-article-family/backoffice-article-family-base.directive';
import { StateFeature } from '../../../state';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import { FamilyListActionEnum, FamilyListStateAction } from '../../../state/family-list/family-list.actions';

@Component({
	selector: 'family',
	templateUrl: '../../../shared/directives/backoffice-article-family/backoffice-article-family-base.html',
	styleUrls: ['./family.component.scss'],
	providers: [SubscribeManagerService]
})
export class FamilyComponent extends BackofficeArticleFamilyBaseDirective {

	articleList$ = this.store.select(StateFeature.getFamilyList);
	subscribeListForType: SubscribeManagerItem[] = [];
	articleListActionEnum = FamilyListActionEnum;
	articleListStateAction = FamilyListStateAction;
	instanceRouteParam = 'families';

	constructor(
		public store: Store,
		public fb: FormBuilder,
		public subscribeManagerService: SubscribeManagerService,
		public router: Router,
		public route: ActivatedRoute,
		public translateService: TranslateService,
		public utilService: AngularCoreUtilService,
		public utilBreadcrumbService: UtilBreadcrumbService
	) {
		super(
			store,
			fb,
			subscribeManagerService,
			router,
			route,
			translateService,
			utilService,
			utilBreadcrumbService
		);
	}

}
