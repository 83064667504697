import { PipeTransform, Pipe } from '@angular/core';
import { AppUtilService } from '../../service/util/app-util.service';

@Pipe({
	name: 'utilReturnIsMainOfListPropertyPipe'
})
// TODO: questa è stata predisposta ad uso futuro, ora usata solo in una configurazione di td-data-table probabilmente
// deprecata. Da testare adeguatamente prima dell'utilizzo in altri punti
export class UtilReturnIsMainOfListPropertyPipe implements PipeTransform {
	constructor(
		public utilService: AppUtilService
	) { }
	transform(value: string, c: {list: any[], propertyName: string}) {
		const mainOfList = this.utilService.returnIsMainOfList(c.list);
		if (mainOfList && mainOfList[c.propertyName]) {
			return `${value} ${mainOfList[c.propertyName]}`;
		} else {
			return value;
		}
	}
}
