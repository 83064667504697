// model
import { TicketCenterConfigurationModel } from "../model/configuration.model";

// constant
import { TicketCenterColumn } from "./column-map.constant";
import { TicketCenterStatus } from "./status-map.constant";
import { TicketCenterLocalSearchTextKeyList } from "./local-search-text-key-list.constant";

export namespace LibConfiguration {
  export const basePageListTitle = "Ticket Center";
  export const base = <TicketCenterConfigurationModel>{
    page: {
      list: {
        title: {
          BACKOFFICE_ADMIN: LibConfiguration.basePageListTitle,
          BACKOFFICE: LibConfiguration.basePageListTitle,
          B2B: LibConfiguration.basePageListTitle,
          B2C: LibConfiguration.basePageListTitle,
          AGENT: LibConfiguration.basePageListTitle,
          CRM: LibConfiguration.basePageListTitle
        },
        tableColumn: TicketCenterColumn.contextApplicationMap,
        localSearchTextKeyList: TicketCenterLocalSearchTextKeyList.contextApplicationMap
      }
    },
    ticket: {
      statusContextApplicationStatusMap: TicketCenterStatus.ContextApplicationStatusMap
    }
  };

}
