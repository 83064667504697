import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import moment from 'moment';

// @dynamic
export class FormValidatorCustom {

  constructor(){
    // do nothing.
  }

  public static match2fields(first, second) {
    return (c:UntypedFormGroup) => {
      return (c.controls && c.controls[first].value == c.controls[second].value) ? null : {
        passwordsEqual: {
          valid: false
        }
      };
    }
  }

  public static email(c: AbstractControl) {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (c.value == '' || c.value == null) {
      return null;
    }
    return EMAIL_REGEXP.test(c.value) ? null : { valid: false };
  }

  public static minNumber(number){
    return (c: UntypedFormGroup) => {
      return (parseFloat(c.value) >= number) ? null : {
        diffSettings: {
          valid: false
        }
      };
    }
  }

  public static maxNumber(number){
    return (c: UntypedFormGroup) => {
      return (parseFloat(c.value) <= number) ? null : {
        diffSettings: {
          valid: false
        }
      };
    }
  }

  public static checkInt() {
    return (c: UntypedFormGroup) => {
      const val = parseFloat(c.value)
      return Number.isInteger(val) ? null : {
        valid: false
      }
    }
  }

  public static allEmptyOrAllFilledByObject() {
		return (c: UntypedFormGroup) => {
			const values = [];
			for (const key of Object.keys(c.controls)) {
				values.push(c.controls[key].value);
			}
			return values.every(i => i)
                || values.every(i => i.length === 0)
                || values.every(i => null)
                ? null
                : { valid: false };
		};
	}

  /**
   * Controllo dell'algoritmo di Luhn (per partita Iva, carte di credito ecc..)
   * Somma i valori delle cifre; le cifre in posizione pari (da destra) sono moltiplicate per 2
   * e se il risultato ha due cifre, queste sono sommate (che corrisponde a sottrarre 9, es: 8*2=16, 1+6=7, 16-9=7).
   * Il risultato della somma dev'essere multiplo di 10 (modulo 10 deve fare 0)
   * @param c 
   * @returns 
   */
  public static checkLuhn(c: AbstractControl) {
    if (c.value == '' || c.value == null) {
        return null;
    }
    const digs = Array.from(c.value);
    let tot = 0;
    digs.forEach((d, i) => {
        const cfr = Number(d);
        if(isNaN(cfr)) return { valid: false };
        if((digs.length-i)%2==0)                  //cifre in posizione pari da destra
            tot += (2*cfr>9 ? 2*cfr-9: 2*cfr);
        else
            tot += cfr;
    });
    return tot%10 == 0 ? null : { valid: false };
  }

  public static isDateFarEnough = (dayMin: number, excludeDayList?: number[]) => {
    return (c: UntypedFormGroup) => {
      if (c && c.value) {
        const today: Date = new Date();
        const dateMin = moment(today).add(dayMin, 'day');
        const enoughFar: boolean = moment(c.value).diff(dateMin, 'd', false) >= 0;
        if (
          !enoughFar ||
          (excludeDayList && excludeDayList.includes(moment(c.value).day()))
        ) {
          return { valid: false }
        }
      }
      return null;
    }
  }

}
