import { UtilPriceService } from '../../../service/util/util-price.service';
import { Component, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
	BaseStateModel,
	SubscribeManagerService,
	MediaReplayService,
	SentencecasePipe,
	DialogConfirmComponent
} from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { OrderActionEnum, OrderStateAction } from '../../../state/order/order.actions';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { ArticlePouchModel, DivisionPouchModel, LanguageItem, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import { CallToActionConfig } from '../../../widget/call-to-action/call-to-action.component';
import { AppUtilService } from '../../../service/util/app-util.service';
import {
	OrderStateModel,
	AngularSpin8CoreUtilArticleService,
	PATH_URL,
	ROUTE_URL,
	OrganizationStateModel,
	AngularSpin8CoreUtilTranslateService,
	OrderPriceMap
} from '@saep-ict/angular-spin8-core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseState } from '@saep-ict/angular-core';
import { OrderListStateAction } from '../../../state/order-list/order-list.actions';
import { AlertType } from '../../../widget/alert/alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';
import * as ConfigurationCustomerArticleAvailability from '../../../constants/configuration-customer/article/article-availability.constant';
import * as ConfigurationCustomerPrice from '../../../constants/configuration-customer/price/price.constant';

@Component({
	selector: 'b2c-cart',
	templateUrl: './b2c-cart.component.html',
	styleUrls: ['./b2c-cart.component.scss'],
	providers: [SubscribeManagerService, SentencecasePipe]
})
export class B2cCartComponent implements OnDestroy {
	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	callToActionConfig: CallToActionConfig = {
		title: 'Scopri Molino Rachello',
		titleClass: 'txt-accent',
		text: 'Il meglio della natura, il meglio per te.',
		textClass: 'txt-white',
		btnLabel: 'contact.contact_us',
		theme: 'accent',
		backgroundImage: "'/assets/common/img/mockup/call-to-action.jpg'",
		hasBackdrop: true
	};

	orderPriceMap: OrderPriceMap = new OrderPriceMap();

	// Enum
	alertTypeEnum = AlertType;
	organizationTypeEnum = OrganizationTypeEnum;

	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(
		public router: Router,
		private store: Store,
		public utilOrderService: UtilOrderService,
		public utilPriceService: UtilPriceService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private subscribeManagerService: SubscribeManagerService,
		public mediaReplayService: MediaReplayService,
		private utilService: AppUtilService,
		public articleService: AngularSpin8CoreUtilArticleService,
		private dialog: MatDialog,
		private sentencecasePipe: SentencecasePipe,
		private translate: TranslateService,
		private snackBar: MatSnackBar,
		private bucketManagerService: BucketManagerService
	) {
		// previene l'anomala assegnazione di un ordine privo di id durante il secondo salvataggio di un ordine
		// creato subito dopo l'invio di un altro ordine
		delete this.utilOrderService.orderChangeTemp;
		this.loadStaticData();
		this.subscribeManagerService.populate(this.subscribeMainPipeData().subscribe(), 'main-pipe-data');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeMainPipeData(): Observable<Promise<OrderStateModel>> {
		return this.order$.pipe(
			filter(
				(store: BaseStateModel<OrderStateModel>) =>
					!!(
						store &&
						(store.data ||
							store.type === OrderActionEnum.SKIP ||
							store.type === OrderActionEnum.REMOVED ||
							store.type === OrderActionEnum.COMPLETED)
					)
			),
			map(async (store: BaseStateModel<OrderStateModel>) => {
				switch (store.type) {
					case OrderActionEnum.ERROR:
						throw new Error(OrderActionEnum.ERROR);
					case OrderActionEnum.UPDATE: {
						this.order = this.utilOrderService.returnOrderDraftWithoutMissingArticle(
							store.data,
							this.articleList
						);
						this.articleList = this.utilOrderService.returnFilteredAndMergedArticleListByOrderDraft(
							this.order,
							this.articleList,
							this.organization
						);
						const mainDivision = this.organization
							? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
							: {};
						this.articleList = this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem(
							this.articleList,
							mainDivision?.division
						);
						this.orderPriceMap = this.utilPriceService.updateOrderPriceMap(this.order);
						this.order = await this.utilPriceService.updatePriceOrder(this.order, this.organization);
						break;
					}
					// TODO: verificare se utile, dato il problema del reset order-detail che non si resetta sull'istanza di rachello
					case OrderActionEnum.COMPLETED:
						this.store.dispatch(OrderStateAction.reset());
						this.store.dispatch(OrderListStateAction.reset());
						this.orderPriceMap = new OrderPriceMap();
						delete this.order;
						break;
					case OrderActionEnum.REMOVED:
						this.store.dispatch(OrderStateAction.skip());
						delete this.utilOrderService.orderChangeTemp;
						delete this.order;
						this.orderPriceMap = new OrderPriceMap();
						break;
				}
				return this.order;
			})
		);
	}

	loadStaticData() {
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res ? res.data : null;
		});
		this.articleList$.pipe(take(1)).subscribe(res => {
			this.articleList = res ? res.data : null;
		});
	}

	clickCallToAction() {
		//
	}

	changeQuantity(totalQuantityEntered: number, article: ArticlePouchModel) {
		if (totalQuantityEntered === 0) {
			const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
				data: {
					title: this.sentencecasePipe.transform(this.translate.instant('general.confirm')),
					text: this.sentencecasePipe.transform(this.translate.instant('order.question.delete_article'))
				},
				autoFocus: true,
				width: '75%'
			});
			dialogRef.afterClosed().subscribe(removeArticle => {
				if (removeArticle) {
					if (this.order.product_list.length === 1) {
						// risposta affermativa in presenza di un unico articolo nel carrelo determina l'eliminazione
						// dell'ordine
						this.store.dispatch(OrderStateAction.remove(new BaseState(this.order)));
					} else {
						this.utilOrderService.changeOrderArticle(
							{
								event: {
									target: {
										value: totalQuantityEntered.toString()
									}
								},
								row: article,
								key: 'delete'
							},
							this.order,
							this.organization,
							true
						);
					}
				} else {
					// riassegna articleList in modo di risettarne in visualizzazione la quantità (portata a 0 dall'utente che poi
					// si rifiuta di eliminare il prodotto dal carrello)
					this.articleList = _.cloneDeep(this.articleList);
				}
			});
		} else {
			const quantityCanBeOrdered =
				ConfigurationCustomerArticleAvailability.returnArticleAvailableQuantity(
					article,
					this.organization
				);
			if (
				quantityCanBeOrdered &&
				totalQuantityEntered > quantityCanBeOrdered
			) {
				const message = this.sentencecasePipe.transform(
					`${this.utilTranslateService.translate.instant('article.quantity.not_available')}
					(${quantityCanBeOrdered} ${this.utilTranslateService.translate.instant('general.max')})`
				);
				this.snackBar.open(message, 'OK', { duration: 5000 });
				const index = this.utilService.getElementIndex(this.articleList, 'code_item', article.code_item);
				this.articleList[index] = _.cloneDeep(this.articleList[index]);
			} else {
				this.utilOrderService.changeOrderArticle(
					{
						event: {
							target: {
								value: totalQuantityEntered.toString()
							}
						},
						row: article,
						key: 'input_quantity'
					},
					this.order,
					this.organization,
					true,
					500
				);
			}
		}
	}

	returnArticleTotalPrice(article: ArticlePouchModel): number {
		let price: number = article.calculate_price_for_single_item;
		if (this.organization?.organization_type === OrganizationTypeEnum.PRIVATE) {
			price = this.utilPriceService.returnPriceWithVat(price, article.articlePrice?.vat);
		}
		return price * article.ordered_quantity;
	}

	returnImageBucketLink(languageItem: LanguageItem, article: ArticlePouchModel): string {
		let bucketLink: string;
		if (languageItem && languageItem.image_list && languageItem.image_list.length > 0) {
			const attachment = languageItem.image_list[0];
			bucketLink =
				this.bucketManagerService.returnBucketManagerDownloadUrl(
					attachment.nameOnBucket,
					'article',
					`${article.code_item}/${languageItem.language}/gallery`
				);
		}
		return bucketLink;
	}
}
