export interface ConfigCouchModel {
  baseDatabaseTemplate: string;
  endpoint: string;
  apiKey?: string;
  database: string;
  context?: string[];
  offline?: boolean;
}

export interface IPouchConfigModel {
  couch: ConfigCouchModel[];
}
