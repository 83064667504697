import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enumIterator' })
export class EnumIteratorPipe implements PipeTransform {
	transform(value): any {
		return Object.keys(value).map(key => {
			return {
				id: key,
				description: value[key]
			};
		});
	}
}
