import { BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	ViewEncapsulation
} from '@angular/core';
import { MediaReplayService, LoaderService } from '@saep-ict/angular-core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'ms-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
	// encapsulation: ViewEncapsulation.None
})
export class FramePageComponent implements OnInit, OnDestroy {
	private _mediaSubscription: Subscription;

    isSidebarOpen: boolean;

	constructor(
		public mediaReplayService: MediaReplayService,
		private cdr: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private platform_id: string,
		public loaderService: LoaderService
	) {
		if (isPlatformBrowser(this.platform_id)) {
			this.mediaReplayService.deviceH = window.innerHeight;
			this.mediaReplayService.deviceW = window.innerWidth;
		}
	}

	ngOnInit() {
		this.cdr.detectChanges();
		this._mediaSubscription = this.mediaReplayService.media
			.pipe(debounceTime(100))
			.subscribe((change: BreakpointState) => {
				if (isPlatformBrowser(this.platform_id)) {
					this.mediaReplayService.deviceH = window.innerHeight;
					this.mediaReplayService.deviceW = window.innerWidth;
				}
			});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}

    receiveIsSidebarOpen($event) {
        this.isSidebarOpen = $event;
    }

}
