import { RestBaseGuid, RestBasePk } from "@saep-ict/angular-core";


export interface UserPasswordChangeModelRequest extends RestBaseGuid {
    password: string;
}

export interface UserPasswordChangeModel extends RestBasePk, RestBaseGuid {
    password: string;
}

export interface UserPasswordUpdateModel extends UserPasswordChangeModel {
    oldPassword?: string;
}
