import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
	BaseState,
	BaseStateModel,
	DialogConfirmComponent,
	GuidFormatterPipe,
	SentencecasePipe,
	SideBarPositionValues
} from '@saep-ict/angular-core';
import {
	OrganizationLevelEnum,
	OrganizationTypeEnum,
	OrganizationPouchModel,
	ContactPouchModel,
	DestinationPouchModel
} from '@saep-ict/pouch_agent_models';
import { OrganizationHeaderConfig } from '../../../../model/util.model';
import * as ConfigurationCustomerOrganization from '../../../../constants/configuration-customer/organization/organization.constant';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import { OrganizationStateAction, OrganizationActionEnum } from '../../../../state/organization/organization.actions';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import _ from 'lodash';
import { UtilAddressService } from '../../../../service/util/util-address.service';
import { NavigationFloatingMenuService } from '../../../../service/navigation/navigation-floating-menu.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { AppUtilService } from '../../../../service/util/app-util.service';
import {
	DialogContextCodeEditComponent,
	ContextCodeItemLinkCustom
} from '../../../../widget/dialog/dialog-context-code-edit/dialog-context-code-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	contextCodeItemCompany,
	ROUTE_URL,
	AngularSpin8CoreUtilCompanyService,
	OrganizationStateModel
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../../frame/admin/admin.component';

@Component({
	selector: 'organization-detail',
	templateUrl: './organization-detail.component.html',
	styleUrls: ['./organization-detail.component.scss'],
	providers: [NavigationFloatingMenuService, SubscribeManagerService]
})
export class OrganizationDetailComponent implements OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	organizationHeaderConfig: OrganizationHeaderConfig;

	// enum
	organizationLevelEnum = OrganizationLevelEnum;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	toExclude: string[] = [];

	configurationCustomerOrganization = ConfigurationCustomerOrganization;
    sideBarPositionValues = SideBarPositionValues;

	constructor(
		private route: ActivatedRoute,
		private store: Store,
		private dialog: MatDialog,
		public utilAddressService: UtilAddressService,
		public navigationFloatingMenuService: NavigationFloatingMenuService,
		private subscribeManagerService: SubscribeManagerService,
		public utilService: AppUtilService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private sentencecasePipe: SentencecasePipe,
		private translateService: TranslateService,
		private guidFormatterPipe: GuidFormatterPipe,
		private permissionUtilService: PermissionUtilService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		const organizationCode: string = this.route.snapshot.paramMap.get('idOrganization');
		this.user$.pipe(take(1)).subscribe((res: BaseState<UserDetailModel>) => {
			if (res) {
				this.user = res.data;
				this.getRouteToExclude();
			} else {
				this.user = null;
			}
		});

		this.subscribeManagerService.populate(
			this.organizationList$
				.pipe(
					filter(res => !!(res && res.data)),
					map(res => {
						this.organization = res.data.find(
							x => x.code_erp === organizationCode || x.code_item === organizationCode
						);
						this.setRouteMetaInformation();
						this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(this.organization)));
					})
				)
				.subscribe(),
			'subscribeOrganizations'
		);

		this.subscribeManagerService.populate(this.subscribeOrganization().subscribe(), 'subscribeOrganization');
	}

	getRouteToExclude() {
		if (this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.CRM) {
			if (this.route.snapshot.paramMap.get('organizationLevel')) {
				if (this.route.snapshot.paramMap.get('organizationLevel') === OrganizationLevelEnum.LEAD) {
					this.toExclude.push('overview');
					this.toExclude.push('credits');
					this.toExclude.push(ROUTE_URL.opportunityKanban);
				}
				if (this.route.snapshot.paramMap.get('organizationLevel') === OrganizationLevelEnum.PROSPECT) {
					this.toExclude.push('overview');
					this.toExclude.push('credits');
					// this.toExclude.push(ROUTE_URL.opportunity);
					this.toExclude.push(ROUTE_URL.opportunityKanban);
				}
				if (this.route.snapshot.paramMap.get('organizationLevel') === OrganizationLevelEnum.CUSTOMER) {
					this.toExclude.push('overview');
					// this.toExclude.push(ROUTE_URL.opportunity);
					this.toExclude.push(ROUTE_URL.opportunityKanban);
					// this.router.navigate(['details'], { relativeTo: this.route });
				}
			}
			return;
		}
		if (
			this.route.snapshot.paramMap.get('organizationLevel') &&
			this.route.snapshot.paramMap.get('organizationLevel') !== OrganizationLevelEnum.CUSTOMER
		) {
			// Mostro le sezioni con crediti e statistiche solo ai clienti
			this.toExclude.push('overview');
			this.toExclude.push('credits');
		}
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
			this.store.dispatch(OrganizationStateAction.reset());
		}
		this.unsetRouteMetaInformation();
	}

	subscribeOrganization() {
		return this.organization$.pipe(
			filter(
				(organizationState: BaseStateModel<OrganizationStateModel>) =>
					!!(organizationState && organizationState.data)
			),
			map((organizationState: BaseStateModel<OrganizationStateModel>) => {
				switch (organizationState.type) {
					case OrganizationActionEnum.UPDATE:
						this.organization = organizationState.data;
						this.organizationHeaderConfig = this.createEntityHeaderConfig(this.organization);
						this.setRouteMetaInformation();
						break;
					default:
						break;
				}
			})
		);
	}

	// Valida organizzazione
	organizationValidation() {
		const title = 'Valida organizzazione';
		const text = "Sei sicuro di voler validare l'organizzazione?";
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true,
			},
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				// Modifica livello di organizzazione e salva
				const updatedOrganization = _.cloneDeep(this.organization);
				updatedOrganization.level = OrganizationLevelEnum.PROSPECT;
				this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(updatedOrganization)));
				// ??? refresh per caricare modifiche children
			}
		});
	}

	createEntityHeaderConfig(organization: OrganizationStateModel): OrganizationHeaderConfig {
		const organizationHeader: OrganizationHeaderConfig = new OrganizationHeaderConfig();

		// avatar
		organizationHeader.avatar = {
			userName: organization.business_name ? organization.business_name : null,
			size: 96
			// isRounded:
			// 	this.user.current_permission.context_application == ContextApplicationItemCodeEnum.CRM ? true : false
		};

		// informationList
		organizationHeader.informationList = [];

		// informationList - phone
		const phone = {
			label: 'theme.contact.field.phone_label',
			value: null,
			icon: 'call',
			order: 1
		};

		// informationList - email
		const email = {
			label: 'theme.contact.field.email_label',
			value: null,
			icon: 'email',
			order: 2
		};

		if (organization.contact_list) {
			const mainContact = this.utilService.returnIsMainOfList<ContactPouchModel>(organization.contact_list);
			if (mainContact) {
				// informationList - phone
				phone.value = mainContact.phone ? mainContact.phone : null;
				// informationList - email
				email.value = mainContact.email ? mainContact.email : null;
			}
		}
		organizationHeader.informationList.push(phone);
		organizationHeader.informationList.push(email);

		// informationList - address
		const address = {
			label: 'saep_ict_angular_spin8_core.widget.destination.address.name',
			value: null,
			icon: 'location_on',
			order: 6
		};

		if (organization.destination_list) {
			const legalHeadquarter = organization.destination_list.find(
				destination => destination.is_registered_office === true
			);
			address.value = legalHeadquarter
				? this.utilAddressService.getFormattedAddress(legalHeadquarter.address)
				: null;
		}
		organizationHeader.informationList.push(address);

		if (this.user.current_permission.context_application !== ContextApplicationItemCodeEnum.CRM) {
			// informationList - codice fiscale
			const taxCode = {
				label: 'organization.field.tax_code',
				value: null,
				icon: 'fingerprint',
				order: 5
			};
			if (organization.tax_data && organization.tax_data.tax_code) {
				taxCode.value = organization.tax_data.tax_code;
			}
			organizationHeader.informationList.push(taxCode);

			// campi dedicati solo alla company
			if (organization.organization_type && organization.organization_type === OrganizationTypeEnum.COMPANY) {
				// informationList - partita iva
				const vatNumber = {
					label: 'organization.field.vat_number',
					value: null,
					icon: 'business',
					order: 4
				};

				vatNumber.value = this.utilService.returnCompleteVatNumber(organization);
				organizationHeader.informationList.push(vatNumber);

				// informationList - website
				const urlWebsite = {
					label: 'organization.field.url_website',
					value: null,
					icon: 'language',
					order: 3
				};

				if (organization.crm && organization.crm.url_website) {
					urlWebsite.value = organization.crm.url_website;
				}

				organizationHeader.informationList.push(urlWebsite);
			}
		}

		return organizationHeader;
	}

	editOrganization() {
		const contextCodeItemLink: ContextCodeItemLinkCustom = _.cloneDeep(contextCodeItemCompany);

		// si assume in questa fase che vengano proposti solo
		// - contatto is_main_of_list
		// - destinazione is_registered_office
		const organizationWithRequiredData: OrganizationPouchModel = _.cloneDeep(this.organization);
		const isMainContact: ContactPouchModel = this.utilService.returnIsMainOfList<ContactPouchModel>(
			organizationWithRequiredData?.contact_list
		);
		organizationWithRequiredData.contact_list = [isMainContact];
		const isRegisteredOffice: DestinationPouchModel = organizationWithRequiredData.destination_list
			? organizationWithRequiredData.destination_list.find(i => i.is_registered_office)
			: null;
		organizationWithRequiredData.destination_list = [isRegisteredOffice];

		contextCodeItemLink.value = organizationWithRequiredData;

		const dialog = this.dialog.open(DialogContextCodeEditComponent, {
			data: {
				...contextCodeItemLink,
				contestoDialog: 'organization',
				type: 'edit',
				description: 'organizzazione'
			},
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((res: OrganizationStateModel) => {
			// Gestione dei dati nella dialog
			// Se mi torna l'ogetto organization faccio l'update
			if (res) {
				this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(res)));
			}
		});
	}

	onFloatingMenuClicked(event) {
		switch (event) {
			case this.navigationFloatingMenuService.addNote.emitName:
				this.openDialogNote();
				break;

			case this.navigationFloatingMenuService.addContact.emitName:
				this.openDialogContact();
				break;

			case this.navigationFloatingMenuService.addAddress.emitName:
				this.openDialogAddress();
				break;

			case this.navigationFloatingMenuService.addOffer.emitName:
				// TODO - rimandare alla pagina nuova offerta
				// this.router.navigate(['/']);
				break;

			case this.navigationFloatingMenuService.addOpportunity.emitName:
				this.openDialogOpportunity();
				break;

			default:
				break;
		}
	}

	getCodeFromLevel(level: string) {
		switch (level) {
			case 'lead':
				return 'L';
			case 'prospect':
				return 'P';
			case 'customer':
				return 'C';
		}
	}

	openDialogNote() {
		console.log('openDialogNote');
	}
	openDialogContact() {
		console.log('openDialogContact');
	}
	openDialogAddress() {
		console.log('openDialogAddress');
	}
	openDialogOpportunity() {
		console.log('openDialogOpportunity');
	}

	getSubtitle() {
		if (this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.CRM) {
			return (
				this.translateService.instant('organization.level-detail.' + this.organization.level).toUpperCase() +
				' ' +
				(this.organization.code_erp
					? this.organization.code_erp
					: this.guidFormatterPipe.transform(this.organization.code_item))
			);
		}
		return (
			this.sentencecasePipe.transform(
				this.translateService.instant(
					'saep_ict_angular_spin8_core.page.organization.organization_type_label.' +
						this.organization.organization_type.toUpperCase()
				)
			) +
			' ' +
			(this.organization.code_erp
				? this.organization.code_erp
				: this.guidFormatterPipe.transform(this.organization.code_item))
		);
	}

	setRouteMetaInformation() {
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['organizations',`organizations_${this.organization.level.toLocaleLowerCase()}`, 'company']);
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.B2B:
			case ContextApplicationItemCodeEnum.PORTAL:
				this.utilBreadcrumbService.title.value = this.utilCompanyService.getBusinessNameLabel(
					this.organization
				);
				break;
			default:
				this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('organizations');
				this.utilBreadcrumbService.subtitle.value = this.utilCompanyService.getBusinessNameLabel(
					this.organization
				);
		}
	}

	unsetRouteMetaInformation() {
		if (!this.route.parent.component || this.route.parent.component === FramePageComponent) {
			// Componente radice
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		} else {
			this.utilBreadcrumbService.unsetRouteMetaInformation(this.utilBreadcrumbService.UNSET_SUBTITLE);
		}
	}
}
