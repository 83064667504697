import { Category } from '@saep-ict/pouch_agent_models';
import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import * as CategoryModel from '../../../model/category-list.model';
import * as ConfigurationCustomerForecastCategoryConfiguration from './forecast-category.constant';
import * as _ from 'lodash';
import { articleColumnMapB2bForecast } from '../article/article-column-map-b2b-forecast.constant';
import * as ForecastModel from '../../../model/forecast.model';
import * as ConfigurationCustomerForecastUtil from './forecast-util.constant';

export const articleStructureAccordionMultipleOpen = false;
export const categoryTabOnTopConfiguration: CategoryModel.Configuration =
    ConfigurationCustomerForecastCategoryConfiguration.categoryTabOnTopConfiguration;
export const returnNestedCategoryParse = (user: UserDetailModel, e: Category[]): Promise<Category[]> => {
    return new Promise(resolve => {
        try {
            const categoryReturn = _.cloneDeep(e[0].category_list);
            switch (user.current_permission.context_application) {
                default:
                    for (const category of categoryReturn) {
                        delete category.category_list;
                    }
            }
            resolve(categoryReturn);
        } catch (err) {
            throw new Error(err);
        }
    });
};
export const returnArticleColumnList = (
    contextApplication: ContextApplicationItemCodeEnum
): ITdDataTableColumnCustom[] => {
    let articleColumnList: ITdDataTableColumnCustom[];
    switch (contextApplication) {
        default:
            articleColumnList = articleColumnMapB2bForecast()
    }
    return articleColumnList;
};

/**
 * Nel corrente progetto il forecast è compilato su base mensile, `inputList` è dunque generato da 12 iterazioni
 * e la label fa riferimento al nome contratto del mese nei bundle multilingua
 * @returns
 */
export const returnInputList = (year: number): ForecastModel.ConfigurationInput[] => {
    const inputList: ForecastModel.ConfigurationInput[] = [];
    for (let i = 1; i <= 12; i++) {
        inputList.push(
            {
                key: i,
                label: `general.time.month_short.${i}`,
                value: null,
                disabled: ConfigurationCustomerForecastUtil.returnInputDisable(year, i)
            }
        );
    }
    return inputList;
};
export const returnDateSelectorList = (): number[] => {
    const yearList: number[] = [];
    yearList.push(ConfigurationCustomerForecastUtil.yearCurrent);
    // dal 1 Ago (mese 8) è disponibile la compilazione del forecast per l'anno successivo
    const monthStart = 8;
    if (ConfigurationCustomerForecastUtil.monthCurrent >= monthStart) {
        yearList.push(ConfigurationCustomerForecastUtil.yearCurrent + 1);
    }
    return yearList;
};