import { Injectable } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';

export interface ITdDataTableColumnKey extends ITdDataTableColumn {
	key: string;
}
abstract class BaseTableList<T> {
	protected _columns: T[];

	constructor() {}

	public set columns(col: T[]) {
		this._columns = col;
	}

	public get columns() {
		return this._columns;
	}
}

@Injectable()
export abstract class BaseTableListKeyService extends BaseTableList<ITdDataTableColumnKey> {
}
@Injectable()
export abstract class BaseTableListService extends BaseTableList<ITdDataTableColumn> {
}
