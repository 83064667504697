import { Component, OnInit, Input } from '@angular/core';
import { SideBarPositionValues } from '../../enum/util/sidebar-position-values.enum';
import { MediaReplayService } from '../../service/util/media-replay.service';


@Component({
  selector: 'page-with-sidebar',
  templateUrl: './page-with-sidebar.component.html',
  styleUrls: ['./page-with-sidebar.component.scss']
})
export class PageWithSidebarComponent implements OnInit {

    @Input() isRoot: boolean = true;
    @Input() sideBarPosition: SideBarPositionValues = SideBarPositionValues.RIGHT;
    @Input() isSticky: boolean = false;

    isSidebarOpen: boolean = true;

    sideBarPositionValues = SideBarPositionValues;

    constructor(
        private mediaReplayService: MediaReplayService
    ) {
        this.isSidebarOpen = this.mediaReplayService.deviceW > 1439;
    }

    ngOnInit(): void {
        // if(!Object.values(this.sideBarPositionValues).includes(this.sideBarPosition)) {
        //     this.sideBarPosition = this.sideBarPositionValues.RIGHT;
        // }
        this.sideBarPosition = (!this.isRoot && this.sideBarPosition === SideBarPositionValues.RIGHT) ? SideBarPositionValues.TOP : this.sideBarPosition;
    }

    toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
    }

    getClass() {
        let classes: string[] = [];
        classes.push(this.isRoot ? 'root' : 'child');
        classes.push(this.sideBarPosition);
        return classes.join(" ");
    }
}
