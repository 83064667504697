import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService } from '@saep-ict/angular-core';
import _ from 'lodash';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { AppUtilService } from '../../util/app-util.service';
import { ChartEnum, chartTimeRange30Days, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class ClientsTop5QuantityChartConfigService extends BaseChartClass {
	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public store: Store<any>,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
		this.chart.type = 'bar';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	// Functions

	populateChart(rangeDays: number = chartTimeRange30Days.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let serieTemp = [];
		let labelTemp = [];

		let clients;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardClients) {
			clients = this.utilService.findLeaf(this.statistics.data, `top5.order_quantity.days_${rangeDays}`);
		} else {
			clients = this.utilService.findLeaf(this.statistics.data, `clients.top5.order_quantity.days_${rangeDays}`);
		}
		clients.forEach(client => {
			serieTemp.push(client.count);
			labelTemp.push(client.business_name);
		});

		let fullDataSet = [];
		fullDataSet.push({
			label: 'order.count',
			backgroundColor: [
				this.colorPrimary70,
				this.colorPrimary,
				this.colorPrimary70,
				this.colorPrimary,
				this.colorPrimary70
			],
			data: serieTemp
		});

		this.addData(this.chart.datasets, fullDataSet);
		this.addData(this.chart.labels, labelTemp);
		this.chart = _.cloneDeep(this.chart);
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {
			label: function(context) {

				let label = translateService.instant(context.dataset.label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += context.raw;
				return label;
			}




			// label: function (tooltipItem, data) {
			// 	let label = translateService.instant(data.datasets[tooltipItem.datasetIndex].label) || '';

			// 	if (label) {
			// 		label = sentencecasePipe.transform(label);
			// 		label += ': ';
			// 	}
			// 	label += tooltipItem.value;
			// 	return label;
			// }
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return {
			stacked: false,
			grid: {
				drawBorder: false,
				display: true,
				borderDash: [4, 4],
				color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
			},
			ticks: {
				display: true,
				padding: 8,
				// Font color for tick labels.
				fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
				// callback: this.ticksCallback()
			}
		}
	}

	xAxesStyle() {
		return {
			stacked: false,
			grid: {
				drawBorder: false,
				display: true
			},
			ticks: {
				display: true,
				// Valore minimo dell'asse
				min: 0,
				// Scala da usare
				stepSize: 100
			}
		}
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				x: this.xAxesStyle(),
				y: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
