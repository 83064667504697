import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { CategoryListStateModel, FilterModel } from '../../../model/category-list.model';
import { AssetRequestCategory } from '../../../model/asset-request/asset-request-category-list.model';

export class CategoryListPouch extends AbstractPouchdbMethod {
	loadCategoryFilteredList(data: CategoryListStateModel<AssetRequestCategory>): Promise<any> {
		return new Promise(resolve => {
			const selector = {
				$and: [
					{
						type: `category_${data.filter.type}`
					}
				]
			} as PouchDB.Find.Selector;

			// filters
			const filter: FilterModel = data.filter;
			if (filter) {
				if (filter.has_parents && filter.has_parents.length > 0) {
					const f = {
						parents: {
							$in: filter.has_parents
						}
					};
					selector.$and.push(f);
				} else {
					selector.$and.push(this.hasNoParents());
				}
				if (filter.has_duration) {
					const f = {
						duration: {
							$exists: true
						}
					};
					selector.$and.push(f);
				}
			} else {
				selector.$and.push(this.hasNoParents());
			}

			const sort = data.sort;
			let sortSelector = [];
			const sortItem = {};
			if (sort) {
				sortItem[sort[0].name] = sort[0].order;
			} else {
				sortItem['sequence'] = 'asc';
			}
			sortSelector = [sortItem];
			this.getWithIndexFilter('category-list', selector, null, sortSelector)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	// widget & utility
	hasNoParents() {
		const f = {
			parents: {
				$exists: false
			}
		};
		return f;
	}
}
