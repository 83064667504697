import { Injectable } from '@angular/core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleRecap } from '../../model/state/article-list-state.model';
import { Store } from '@ngrx/store';
import { UtilPriceService } from './util-price.service';
import {
	OrderStateModel,
	OrganizationStateModel
} from '@saep-ict/angular-spin8-core';
import { ArticleRecapLoadFilter } from '../../model/article.model';
import { ArticleStateAction } from '../../state/article/article.actions';
import { UtilOrderService } from './util-order.service';

@Injectable({
	providedIn: 'root'
})
export class UtilArticleService {

	constructor(
		private utilOrderService: UtilOrderService,
		private store: Store,
		private utilPriceService: UtilPriceService,
	) {}

	async updateOrderOnArticleRecapChange(
		order: OrderStateModel,
		articleList: ArticlePouchModel[],
		organization: OrganizationStateModel
	): Promise<OrderStateModel> {
			try {
				order = this.utilOrderService.returnOrderDraftWithoutMissingArticle(
					order,
					articleList
				);
				for (let i = 0; i < order.product_list.length; i++) {
					order.product_list[i] =
						await this.utilOrderService.updateOrderArticleByOrganizationArticleList(
							order.product_list[i],
							articleList,
							order.header.division
						);
				}
				order = await this.utilPriceService.updatePriceOrder(order, organization);
				return order;
			} catch(err) {
				throw new Error(err);
			}
	}

	checkOrderForUpdateArticleRecap(
		orderThis: OrderStateModel,
		orderStore: OrderStateModel,
		organization: OrganizationStateModel
	) {
		if (
			!orderThis ||
			(
				orderThis.header &&
				orderThis.header.goods_destination_object &&
				orderThis.header.goods_destination_object.code_item &&
				orderStore.header &&
				orderStore.header.goods_destination_object &&
				orderStore.header.goods_destination_object.code_item &&
				orderThis.header.goods_destination_object.code_item !== orderStore.header.goods_destination_object.code_item
			)
		) {
			const dispatchArticleRecapAction: BaseStateModel<ArticleRecap, ArticleRecapLoadFilter> =
			{
				dataSetting: {
					appliedFilter: {
						destinationCodeItem: orderStore.header.goods_destination_object.code_item,
						organization: organization
					}
				},
				data: null
			};
			this.store.dispatch(ArticleStateAction.loadFromRecap(dispatchArticleRecapAction));
		}
	}

}
