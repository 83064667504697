export interface AccessRequestModel {
	piva: string;
	code: string;
	business_name: string;
	address: string;
	reference: string;
	phone: string;
	department: string;
	email: string;
	privacyCheck: boolean;
	captcha?: string;
}
