<div class="variant__col">
	<p>
		<span
			class="variant__final-price"
			[innerHTML]="
				(
					article.calculate_price_for_single_item |
					utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit
				) +
				' ' +
				configurationCustomerPrice.defaultCurrencySymbol
			"	
		>
		</span>
		<span
			*ngIf="articlePriceTooltip"
			class="variant__final-price__icon material-icons-outlined color-gray"
			[matTooltip]="articlePriceTooltip"
			matTooltipPosition="right"
			>info</span
		>
		<span
			class="variant__vat"
			*ngIf="
				article.articlePrice &&
				(article.articlePrice.vat || [undefined, null].includes(article.articlePrice.vat))
			"
		>
			(+ {{ 'general.vat' | translate | uppercase }}
			{{ article.articlePrice.vat || utilPriceService.returnVat() }}%)
		</span>
	</p>
	<p
		*ngIf="
			article.articlePrice &&
			article.articlePrice.price &&
			article.articlePrice.discount &&
			article.articlePrice.discount.length > 0 &&
			utilPriceService.retrievePriceWithDiscount(article.articlePrice.price, article.articlePrice.discount) <
				article.articlePrice.price
		"
	>
		<span class="variant__original-price">
			{{ article.articlePrice.price | currency: 'EUR':'symbol':'':'it' }}
		</span>
		<span class="variant__discount"> -{{ utilPriceService.returnTotalDiscountPercentage(article) }}% </span>
	</p>
</div>
