export enum WebSocketChannelTypeCodeEnum {
	ARCHIVE_DOWNLOAD = "s3.download",
  ARCHIVE_ZIP_DOWNLOAD = "ARCHIVE_ZIP_DOWNLOAD"
}

export enum WebSocketConnectionStateEnum {
	OPEN = "[Web Socket Connection] Open",
	CLOSED = "[Web Socket Connection] Closed",
	ERROR = "[Web Socket Connection] Error"
}

export enum WebSocketActionEnum {
	START_PROCESS = 1,
	STOP_PROCESS = 2
}

export enum WebSocketProcessStateEnum {
	REQUESTED = 1,
	RECEIVED = 2,
	STARTED = 3,
	RUNNING = 4,
	COMPLETED = 5,
	ERROR = 6
}

export enum WebSocketTopicStateEnum {
	REQUESTED = 'REQUESTED',
	RECEIVED = 'RECEIVED',
	STARTED = 'STARTED',
	RUNNING = 'RUNNING',
	COMPLETED = 'COMPLETED',
	ERROR = 'ERROR'
}
