<mat-dialog-wrapper [title]="getTitle()" [showCloseIcon]="false">
	<ng-container content>
		<action
			[dialog]="true"
			[contact]="data.contact"
			[opportunity]="data.opportunity"
			(actionDone)="dialogRef.close()"
		></action>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
