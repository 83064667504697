import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
	AttachmentListManager,
	BaseState,
	BaseStateModel,
	SideBarPositionValues,
	SubscribeManagerService,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { AttachedFile, ITdDataTableColumnCustom, LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { UserStateAction } from '../../../state/user/user.actions';
import {
	UserNotificationActionEnum,
	UserNotificationStateAction
} from '../../../state/user-notification/user-notification.actions';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import {debounceTime, filter, map, skipWhile, take} from 'rxjs/operators';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { ExtendedUserDetailModel } from '../../../model/user.model';
import { Router } from '@angular/router';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import {
	ContextApplicationItemCodeEnum,
	ContextCodeItem,
	ContextPermission,
	ROUTE_URL,
	LanguageStateModel,
	UserNotificationModel,
	AngularSpin8CoreUtilTranslateService
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { AppUtilService } from '../../../service/util/app-util.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogAttachmentListManagerComponent } from '../../../widget/dialog/dialog-attachment-list-manager/dialog-attachment-list-manager.component';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';
import * as UserConfiguration from '../../../constants/user.constant';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import { UtilUserService } from '../../../service/util/util-user.service';
import * as PermissionContextListColumn from '../../../constants/configuration-customer/permission/permission-column-map/permission-column-map-base.constant';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ContextApplicationModel {
	code?: ContextApplicationItemCodeEnum;
	context_code_data?: LocalListHandlerBaseModel<ContextCodeItem>;
}

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
	providers: [SubscribeManagerService]
})
export class UserComponent implements OnDestroy {
	@ViewChild('firstElementFocus') firstElementFocus: ElementRef;

	user$: Observable<BaseStateModel<ExtendedUserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: ExtendedUserDetailModel;

	userNotification$: Observable<BaseStateModel<UserNotificationModel>> = this.store.select(StateFeature.getUserNotificationState);
	userNotification: UserNotificationModel;

	languageList$: Observable<BaseStateModel<LanguageStateModel[]>> = this.store.select(StateFeature.getLanguageList);
	languageList: LanguageStateModel[];

	// Form
	form: FormGroup;
	userNotificationForm: FormGroup;
	isEditing = false;

	columns: ITdDataTableColumnCustom[] = PermissionContextListColumn.baseColumns;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	sideBarPositionValues = SideBarPositionValues;

	ROUTE_URL = ROUTE_URL;

	userBeforeEdit: ExtendedUserDetailModel;

	contextApplicationList: ContextApplicationModel[] = [];

	attachmentListManagerConfiguration: AttachmentListManager.Configuration<AttachedFile> = {
		localListHandler: null,
		page: null,
		privilege: {
			add: true,
			delete: false,
			download: true
		},
		upload: {
			multiple: true,
			fileNameRegex: /^[a-z0-9_. -]+$/i,
			fileNameCharactersAccepted: "a-z 0-9 . _ -",
			fileNameOnBucketCreate: (): string => {
				return UserConfiguration.userAvatarFileName;
			}
		},
		pathUrl: 'user',
		pathQueryParam: null
	};

	avatarUrl: string;
	avatarContainerShow = true

	userNotifificationGroupingLabel = {
		ASSET_REQUEST_CHANGE_STATUS : 'tickets',
		ASSET_REQUEST_CHAT_MESSAGE : 'tickets',
		ORDER_SEND : 'order',
		SURVEY_CREATE : 'survey',
	}

	userNotifificationGrouping: any[] = null;

	constructor(
		private store: Store,
		private fb: FormBuilder,
		private subscribeManagerService: SubscribeManagerService,
		public permissionUtilService: PermissionUtilService,
		private router: Router,
		private utilBreadcrumbService: UtilBreadcrumbService,
		public utilService: AppUtilService,
		private dialog: MatDialog,
		private bucketManagerService: BucketManagerService,
		private utilUserService: UtilUserService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
	) {
		this.createForm();
		const subscribeList: SubscribeManagerItem[] = [
			{ key: 'form-change', observable: this.subscribeFormChange() },
			{ key: 'user-notification', observable: this.subscribeUserNotificationState() }
		];
		this.store.dispatch(UserNotificationStateAction.load());

		ConfigurationSubscribeManager.init(subscribeList, this.subscribeManagerService);
		this.setColumns();
		this.subscribeManagerService.populate(this.subscribeUserState().subscribe(), 'user-state');

		// language list
		this.languageList$.pipe(take(1)).subscribe(res => {
			this.languageList = res ? res.data : null;
		});

		this.utilBreadcrumbService.title.value = 'user.profile.title';
		this.utilBreadcrumbService.subtitle.value = '';
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	// subscribe
	subscribeUserState() {
		return this.user$.pipe(
			skipWhile((state: BaseStateModel<ExtendedUserDetailModel>) => !(state && state.data)),
			map((state: BaseStateModel<ExtendedUserDetailModel>) => {
				if (state) {
					this.user = state ? state.data : null;
					this.updateListContextCodeItemData(this.user.context_application_list);
					this.updateFormFieldValue();
					this.form.disable({ emitEvent: false });
					this.isEditing = false;
					this.attachmentListManagerConfiguration.pathQueryParam = this.user.id;
					this.avatarUrl =
						this.bucketManagerService.returnUrlWithCacheReset(
							this.bucketManagerService.returnBucketManagerDownloadUrl(
								UserConfiguration.userAvatarFileName,
								this.attachmentListManagerConfiguration.pathUrl,
								this.attachmentListManagerConfiguration.pathQueryParam
							)
						);
				}
			})
		);
	}

	subscribeUserNotificationState() {
		return this.userNotification$.pipe(
			filter((state: BaseStateModel<UserNotificationModel>) => !!(state && state.type && state.data)),
			map((state: BaseStateModel<UserNotificationModel>) => {
				this.userNotification = state.data;
				this.createUserNotificationForm()
				switch (state.type) {
					case UserNotificationActionEnum.SAVE_NOTIFICATION_COMPLETE: {
						const message =
							this.sentencecasePipe.transform(
								this.utilTranslateService.translate.instant('user.profile.notifications.save_complete')
							);
						this.snackBar.open(message, 'OK', { duration: 5000 });
						break;
					}
				}
			})
		);
	}

	subscribeFormChange(): Observable<void> {
		return this.form.valueChanges.pipe(
			debounceTime(500),
			map(e => {
				if (this.form.valid) {
					const updatedUser: ExtendedUserDetailModel = _.cloneDeep(this.user);
					updatedUser.first_name = this.form.value.name;
					updatedUser.last_name = this.form.value.lastName;
					updatedUser.language = this.form.value.language;
					delete updatedUser.avatar;
					for (let contextApplication of updatedUser.context_application_list) {
						contextApplication = this.utilService.deleteEmptyProperties(contextApplication);
					}
					this.store.dispatch(UserStateAction.save(new BaseState(updatedUser)));
				}
			})
		);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListContextCodeItemData(context_list: ContextPermission[]) {
		context_list.forEach(context => {
			this.contextApplicationList.push({
				code: <ContextApplicationItemCodeEnum>context.code,
				context_code_data: {
					sort: {
						name: 'code',
						order: TdDataTableSortingOrder.Ascending
					},
					data: context.context_code_list
				}
			});
		});
	}

	// form
	createForm() {
		this.form = this.fb.group({
			name: [{ value: null, disabled: !this.isEditing }, [Validators.required]],
			lastName: [{ value: null, disabled: !this.isEditing }, [Validators.required]],
			language: [{ value: null, disabled: !this.isEditing }, [Validators.required]],
			email: [{ value: null, disabled: true }, [Validators.required, Validators.email]]
		});
	}

	updateFormFieldValue() {
		this.form.patchValue(
			{
				name: this.user.first_name ? this.user.first_name : null,
				lastName: this.user.last_name ? this.user.last_name : null,
				language: (this.user as ExtendedUserDetailModel).language || null,
				email: this.user.username ? this.user.username : null
			},
			{ emitEvent: false }
		);
	}


	createUserNotificationForm() {
		const dataFormControls = {};
		if(this.userNotification && this.userNotification.data) {
			this.userNotifificationGrouping = [];
			this.userNotification.data.forEach((item, index) => {
				if(!(item.key in this.userNotifificationGrouping)) {
					this.userNotifificationGrouping[item.key] = [];
				}


				item.values.forEach((value, indexvalue) => {
					const label = this.userNotifificationGroupingLabel[value.code_item];

					if(!(label in this.userNotifificationGrouping[item.key])) {
						this.userNotifificationGrouping[item.key][label] = [];
					}
					const controlName = `${index}_${indexvalue}`;
					dataFormControls[controlName] = [value.valid];
					this.userNotifificationGrouping[item.key][label][value.code_item] = controlName;
				});
			});
		}
		this.userNotificationForm = this.fb.group(dataFormControls);
	}


	editForm() {
		this.isEditing = true;
		['name', 'lastName', 'language'].forEach(field => this.form.get(field).enable({ emitEvent: false }));
		this.userBeforeEdit = _.cloneDeep(this.user);
	}

	exitEditMode() {
		this.isEditing = false;
		this.form.disable({ emitEvent: false });
		this.user = _.cloneDeep(this.userBeforeEdit);
		this.updateFormFieldValue();
	}

	setColumns() {
		const base: ITdDataTableColumnCustom[] = this.columns;
		// B2C
		this.columns[ContextApplicationItemCodeEnum.B2C] = base.filter(field =>
			['code', 'description'].includes(field.name)
		);
		// Other contexts
		this.columns[ContextApplicationItemCodeEnum.AGENT] = this.columns[
			ContextApplicationItemCodeEnum.B2B
		] = this.columns[ContextApplicationItemCodeEnum.BACKOFFICE] = this.columns[
			ContextApplicationItemCodeEnum.PORTAL
		] = this.columns[ContextApplicationItemCodeEnum.CRM] = base.filter(field =>
			['code', 'description'].includes(field.name)
		);
	}

	openDialogUploadAvatar() {
		const dialogRef = this.dialog.open(DialogAttachmentListManagerComponent, {
			data: {
				title: 'user.profile.upload_image',
				attachmentListManagerConfiguration: this.attachmentListManagerConfiguration,
				closeDialoOnAttachmentListManagerChange: true
			},
			panelClass: ['dialog-normal', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(() => {
			this.avatarContainerShow = false;
			this.utilUserService.updateAvatarSource.next();
			setTimeout(() =>{
				this.avatarUrl =
					this.bucketManagerService.returnUrlWithCacheReset(
						this.bucketManagerService.returnBucketManagerDownloadUrl(
							UserConfiguration.userAvatarFileName,
							this.attachmentListManagerConfiguration.pathUrl,
							this.attachmentListManagerConfiguration.pathQueryParam
						)
					);
				this.avatarContainerShow = true;
			}, 500);
		});
	}

	/**
	 * navigation
	 */

	goToPasswordUpdate() {
		this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.passwordUpdate]);
	}


	onUserNotificationFormSubmit() {
		if (this.userNotificationForm.valid) {
			const userNotification = _.cloneDeep(this.userNotification);
			userNotification.date_update = new Date().valueOf();
			if(userNotification && userNotification.data) {
				userNotification.data.forEach((item, index) => {
					item.values.forEach((value, indexvalue) => {
						const controlName = `${index}_${indexvalue}`;
						value.valid = this.userNotificationForm.get(controlName).value
					});
				});
			}
			this.store.dispatch(UserNotificationStateAction.save(new BaseState(userNotification)));
		}
	}

	protected readonly Object = Object;
}
