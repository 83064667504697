import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { NewsModel } from '../../model/news.model';
import { NewsDetailActionEnum, NewsDetailStateAction } from './news-detail.actions';

export namespace NewsDetailStateReducer {
	export const initialState: BaseStateModel<NewsModel> = null;

	const _reducer = createReducer(
		initialState,
		on(NewsDetailStateAction.load, state => state),
		on(NewsDetailStateAction.save, (state, news) => news),
		on(NewsDetailStateAction.saveComplete, (state, news) => news),
		on(NewsDetailStateAction.update, (state, news) => news),
		on(NewsDetailStateAction.saveAttachmentComplete, (state, news) => news),
		on(NewsDetailStateAction.reset, () => initialState),
		on(NewsDetailStateAction.error, (state, news) => ({
			data: state ? state.data : null,
			type: news.type
		}))
	);

	export function reducer(state: BaseStateModel<NewsModel>, action: TypedAction<NewsDetailActionEnum>) {
		return _reducer(state, action);
	}
}
