import { AbstractPouchdbMethod, IPouchAppConfig, ILoaderService } from '@saep-ict/pouch-db';
import { StatisticsAgent } from '../../../../model/statistics-agent.model';
import { StatisticsDetailOrders } from '../../../../model/statistics-detail-orders.model';
import { StatisticsDetailSold } from '../../../../model/statistics-detail-sold.model';
import { StatisticsDetailClients } from '../../../../model/statistics-detail-clients.model';
import { StatisticsDetailBalance } from '../../../../model/statistics-detail-balance.model';
import { HttpClient } from '@angular/common/http';
import { AppUtilService } from '../../../util/app-util.service';
import { TransmissionService } from '@saep-ict/pouch-db';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../../../constants/configuration-customer/app-structure/app-structure.constant';


export class StatisticsPouch extends AbstractPouchdbMethod {
	constructor(
		activeDB: PouchDB.Database,
		httpClient: HttpClient,
		config: IPouchAppConfig,
		transmissionService: TransmissionService,
		loaderService: ILoaderService,
		private utilService: AppUtilService
	) {
		super(activeDB, httpClient, config, transmissionService, loaderService);
	}

	getStatisticsDetailOrders(code_item: string): Promise<StatisticsDetailOrders> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatistics(code_item);
			promise
				.then((doc: StatisticsAgent) => {
					const orders_doc: StatisticsDetailOrders = {
						data: this.utilService.findLeaf(doc.data, 'orders'),
						date_update: doc.date_update
					};
					resolve(orders_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsDetailSold(code_item: string): Promise<StatisticsDetailSold> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatistics(code_item);
			promise
				.then((doc: StatisticsAgent) => {
					const fulfilled_doc: StatisticsDetailSold = {
						data: this.utilService.findLeaf(doc.data, 'fulfilled'),
						date_update: doc.date_update
					};
					resolve(fulfilled_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsDetailClients(code_item: string): Promise<StatisticsDetailClients> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatistics(code_item);
			promise
				.then((doc: StatisticsAgent) => {
					const clients_doc: StatisticsDetailClients = {
						data: this.utilService.findLeaf(doc.data, 'clients'),
						date_update: doc.date_update
					};
					resolve(clients_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsDetailBalance(code_item: string): Promise<StatisticsDetailBalance> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatistics(code_item);
			promise
				.then((doc: StatisticsAgent) => {
					const balance_doc: StatisticsDetailBalance = {
						data: this.utilService.findLeaf(doc.data, 'balance'),
						date_update: doc.date_update
					};
					resolve(balance_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatistics(code_item: string): Promise<any> {
		return this.get(`statistics${ConfigurationCustomerAppStructure.noSqlDocSeparator}${code_item}`);
	}
}
