import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	// CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../state';
import { LinkCodeModel, ROUTE_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { KeycloakAuthGuard } from 'keycloak-angular';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
	providedIn: 'root'
})
export class AuthLoginGuard extends KeycloakAuthGuard {
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	@LocalStorage('link_code') link_code: LinkCodeModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		protected router: Router,
		private store: Store,
		protected readonly keycloak: KeycloakService
	) {
		super(router, keycloak);
		console.log('AuthLoginGuard');

		// keycloak.keycloakEvents$.subscribe({
		// 	next: e => {
		// 		if (e.type == KeycloakEventType.OnTokenExpired) {
		// 			keycloak.updateToken(20);
		// 		}
		// 	}
		// });
	}

	public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log('=== KEYCLOAK ====', 'AuthLoginGuard -> isAccessAllowed');

		// Force the user to log in if currently unauthenticated.
		const t = await this.keycloak.getToken();
		console.log('=== KEYCLOAK ====', 'AuthLoginGuard -> token:', t);

		if (!this.authenticated) {
			await this.keycloak.login({
				redirectUri: window.location.origin + state.url
			});
			return false;
		}
		//		return true;

		/*
		// Get the roles required from the route.
		const requiredRoles = route.data.roles;

		// Allow the user to proceed if no additional roles are required to access the route.
		if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
			return true;
		}

		// Allow the user to proceed if all the required roles are present.
		//return requiredRoles.every((role) => this.roles.includes(role));
		if (!requiredRoles.every(role => this.roles.includes(role))) {
			return false;
		}
		*/

		this.authenticationToken = await this.keycloakAngular.getToken();
		let authState = false;
		if (this.authenticationToken) {
			this.user$.pipe(take(1)).subscribe(res => {
				this.user = res ? res.data : null;
			});

			authState =
				this.user && !(this.link_code || this.user.current_permission.context_application) ? true : false;
			if (!authState) {
				this.router.navigate(['/', ROUTE_URL.private]);
			}
			return authState;
		} else {
			return true;
		}
	}

	// canActivate(
	// 	route: ActivatedRouteSnapshot,
	// 	state: RouterStateSnapshot
	// ): Observable<boolean> | Promise<boolean> | boolean {
	// 	if (!environment.mockup) {
	// 		let authState = false;
	// 		if (this.authenticationToken) {
	// 			this.user$.pipe(take(1)).subscribe(res => {
	// 				this.user = res ? res.data : null;
	// 			});
	// 			authState =
	// 				this.user && !(this.link_code || this.user.current_permission.context_application) ? true : false;
	// 			if (!authState) {
	// 				this.router.navigate(['/', ROUTE_URL.private]);
	// 			}
	// 			return authState;
	// 		} else {
	// 			return true;
	// 		}
	// 	}
	// 	return true;
	// }
}
