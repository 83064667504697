import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { PipeModule } from './pipes/pipe.module';
import { DirectivesModule } from './directives/directives.module';
import { WidgetModule } from './widget/widget.module';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG_TOKEN, ENVIRONMENT_SETTING } from './model/structure/lib-app-config.interface';
import { LOADER_SERVICE_TOKEN } from './interface/loader-service.interface';
import { RestModule } from './rest.module';
import { NgrxStoreModule } from './store/ngrx-store.module';
import { UtilStore } from './misc/util/util-store';
import { UtilStructure } from './misc/util/util-structure';
import { returnRxStompServiceProviderList } from './service/rx-stomp/rx-stomp-service-factory';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PipeModule,
    DirectivesModule,
    WidgetModule,
    RestModule,
    NgrxStoreModule
  ],
  exports: [
    PipeModule,
    DirectivesModule,
    WidgetModule
  ]
})

export class SaepICTAngularCoreModule {
  static forRoot(opt): ModuleWithProviders<SaepICTAngularCoreModule> {
		return {
			ngModule: SaepICTAngularCoreModule,
			providers: [
				{ provide: ENVIRONMENT_SETTING, useValue: opt.env },
				{
					provide: APP_CONFIG_TOKEN,
					useExisting: opt.appConfig,
				},
				{
					provide: APP_INITIALIZER,
					deps: [opt.appConfig, ENVIRONMENT_SETTING, HttpClient],
					useFactory: UtilStructure.loadLibConfig,
					multi: true,
				},
				{
					provide: LOADER_SERVICE_TOKEN,
					useExisting: opt.loaderSerivce,
				},
				...UtilStore.returnStoreEffectProviderList(),
				...returnRxStompServiceProviderList(opt),
			]
		}
	}
}
