import { NgModule } from '@angular/core';
import { UtilPriceReturnItemValueFormattedPipe } from './util-price-returnItemValueFormatted.pipe';
import { UtilReturnIsMainOfListPropertyPipe } from './util-returnIsMainOfListProperty.pipe';
import { SafePipe } from './safe.pipe';
import { DateAsAgoPipe } from './date-as-ago.pipe';

@NgModule({
	declarations: [
		UtilPriceReturnItemValueFormattedPipe,
		UtilReturnIsMainOfListPropertyPipe,
		SafePipe,
		DateAsAgoPipe
	],
	exports: [
		UtilPriceReturnItemValueFormattedPipe,
		UtilReturnIsMainOfListPropertyPipe,
		SafePipe,
		DateAsAgoPipe
	],
	providers: [
		DateAsAgoPipe
	]
})
export class AppPipeModule {}
