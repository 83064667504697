import { ConfigurationCustomerContextSelector } from "../model/configuration.model";


export namespace TicketCenterLocalSearchTextKeyList {

    export const base: string[] = ['id'];

    export const contextApplicationMap = <ConfigurationCustomerContextSelector<string[]>>{
        BACKOFFICE_ADMIN: TicketCenterLocalSearchTextKeyList.base,
        BACKOFFICE: TicketCenterLocalSearchTextKeyList.base,
        B2B: TicketCenterLocalSearchTextKeyList.base,
        AGENT: TicketCenterLocalSearchTextKeyList.base,
        CRM: TicketCenterLocalSearchTextKeyList.base,
    };

}
