import { Component, EventEmitter, Output } from '@angular/core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { Router } from '@angular/router';
import { AppUtilService } from '../../../service/util/app-util.service';
import { Store } from '@ngrx/store';
import { PouchDbModel, ROUTE_URL, OfferStateModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'offer-list-wrapper',
	templateUrl: './offer-list-wrapper.component.html',
	styleUrls: ['./offer-list-wrapper.component.scss']
})
export class OfferListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectOpportunity: EventEmitter<{ code: string }> = new EventEmitter();
	@Output() selectOrganization: EventEmitter<{
		level: string;
		code: string;
	}> = new EventEmitter();

	constructor(
		public utilService: AppUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilPriceService: UtilPriceService,
		private router: Router
	) {
		super(utilService, translateService, store);
	}

	goToAttachmentList(offer: OfferStateModel) {
		this.router.navigate([
			ROUTE_URL.private,
			ROUTE_URL.offer,
			this.utilService.returnDocumentId(
				PouchDbModel.PouchDbDocumentType.ORGANIZATION,
				offer.header.organization.code_item
			),
			this.utilService.returnDocumentId(
				PouchDbModel.PouchDbDocumentType.OPPORTUNITY,
				offer.header.custom_field.opportunity.code_item
			),
			offer._id,
			ROUTE_URL.attachment
		]);
	}
}
