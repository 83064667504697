import { ChartType, ChartOptions, ChartData, ChartDataset } from 'chart.js'
//import { Color, Label, SingleOrMultiDataSet } from 'ng2-charts';

export interface CardShowParameterModel {
  show?: boolean;
}
export interface CardOffsetOptionModel {
  offsetY?: number;
  offsetX?: number;
}
export interface CardLabelModel
  extends CardShowParameterModel,
    CardOffsetOptionModel {}
export interface CardChartModel {
  type: string;
  toolbar: CardShowParameterModel;
  height?: number;
}
export interface CardStrokeModel {
  curve: string;
  width: number;
  dashArray: number;
}
export interface CardAxisModel {
  labels: CardLabelModel;
  categories?: any[];
  position?: string;
  axisBorder?: CardShowParameterModel;
  axisTicks?: CardShowParameterModel;
}
export interface CardTooltipModel {
  followCursor?: boolean;
  enabled?: boolean;
  offsetY?: number;
  offsetX?: number;
  x?: CardShowParameterModel;
  fixed?: { enabled: boolean; position: string };
}
export interface CardSeriesModel {
  label: string;
  data?: any[];
}
export interface CardDataLabelModel {
  position: string;
}
export interface CardPlotOptionModel {
  bar: { dataLabels: CardDataLabels };
}
export interface CardDataLabels {
  enabled?: boolean;
  offsetY?: number;
  offsetX?: number;
  position?: string;
  style?: CardStyleModel;
}
export interface CardStyleModel {
  fontSize: string;
  colors: string[];
}
export interface CardMainModel {
  chart: CardChartModel;
  colors: string[];
  stroke: CardStrokeModel;
  xaxis: CardAxisModel;
  yaxis: CardAxisModel;
  tooltip: CardTooltipModel;
  grid: CardShowParameterModel;
  plotOptions?: CardPlotOptionModel;
  dataLabels?: CardDataLabels;
  series: CardSeriesModel[];
}

/**
 * Modello dati
 * https://pub.dev/documentation/chartjs/latest/chart.js/ChartOptions-class.html
 */

export interface ChartjsModel {
  data?: ChartData;
  datasets: ChartDataset[];
  labels: any[];
  //chartType?: any;
  type?: ChartType;
  options?: ChartOptions;
  colors: any[];
  legend: boolean;
  plugins?: any[];
}
export interface DatasetsModel {
  data: any[];
  label: string;
}

export interface CardTrendConfigurationModel {
  // la color palette viene usata per colorare lo sfondo con il tono 500 ed il testo con il tono 500 del relativo contrast
  // background-color e color non vengono sovrascritti lasciando la prop. null
  theme?: 'mat-primary' | 'mat-accent' | 'mat-warn';
  title: string;
  subtitle: string;
  trend: number;
  valueType?: string;
  time: string;
  value: string;
}
export interface CardArrayItemModel {
  cardTrendConfiguration: CardTrendConfigurationModel;
  main: ChartjsModel;
}
