<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme user-detail">
			<div class="section" *ngIf="!isEditing; else editingTemplate">
				<p class="section__title">{{ 'user.profile.information' | translate | sentencecase }}</p>
				<div class="info">
					<div class="info__profile-image">
						<!-- <div class="placeholder-box"></div> -->
						<ng-container *ngTemplateOutlet="avatarPreviewContainer"></ng-container>
					</div>
					<div class="info__content">
						<div class="info__row">
							<p class="info__row__header">{{ 'user.field.first_name' | translate | sentencecase }}</p>
							<p class="info__row__content">{{ user.first_name }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">{{ 'user.field.last_name' | translate | sentencecase }}</p>
							<p class="info__row__content">{{ user.last_name }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">
								{{ 'theme.contact.field.email_label' | translate | sentencecase }}
							</p>
							<p class="info__row__content">{{ user.username }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">{{ 'language.name' | translate | sentencecase }}</p>
							<p class="info__row__content">
								{{ 'language.' + user.language | translate | sentencecase }}
							</p>
						</div>
					</div>

					<div class="edit-button">
						<ng-container actions *ngIf="!isEditing">
							<form-button-bar
								[align]="utilService.isMobile() ? 'vertical' : 'right'"
								[primaryButtonLabel]="'general.edit_profile' | translate | sentencecase"
								(primaryButtonClickEmitter)="editForm()"
							></form-button-bar>
						</ng-container>
					</div>
				</div>
				<!-- Change user data -->
			</div>
			<ng-template #editingTemplate>
				<p class="section__title">{{ 'user.profile.information' | translate | sentencecase }}</p>
				<form [formGroup]="form" class="michelangelo-theme">
					<div class="section__user-info">
						<div class="avatar-wrapper">
							<ng-container *ngIf="avatarContainerShow">
								<ng-container *ngTemplateOutlet="avatarPreviewContainer"></ng-container>
							</ng-container>
							<button
								mat-button
								(click)="openDialogUploadAvatar()"
								type="button"
								class="p-0 upload_image"
							>
								<mat-icon>account_circle</mat-icon>
								{{ 'user.profile.upload_image' | translate | sentencecase }}
							</button>
						</div>
						<mat-form-field>
							<mat-select
								formControlName="language"
								placeholder="{{ 'language.name' | translate | sentencecase }}"
							>
								<mat-option *ngFor="let language of languageList" [value]="language.description_short">
									{{ 'language.' + language.description_short | translate | sentencecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field>
							<input
								#firstElementFocus
								formControlName="name"
								matInput
								[value]="user.first_name"
								placeholder="{{ 'user.field.first_name' | translate | sentencecase }}"
							/>
						</mat-form-field>
						<mat-form-field>
							<input
								formControlName="lastName"
								matInput
								[value]="user.last_name"
								placeholder="{{ 'user.field.last_name' | translate | sentencecase }}"
							/>
						</mat-form-field>
					</div>
					<ng-container actions *ngIf="isEditing">
						<form-button-bar
							class="actions-buttons"
							[align]="'space-between'"
							[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
							(secondaryButtonClickEmitter)="exitEditMode()"
						></form-button-bar>
					</ng-container>
				</form>
			</ng-template>
			<mat-divider class="top-spacing mt-20"></mat-divider>
			<div class="section">
				<p class="section__title">{{ 'user.profile.change_password.label' | translate | sentencecase }}</p>
				{{ 'user.profile.change_password.text' | translate | sentencecase }}
				<a [routerLink]="['/', ROUTE_URL.authentication, ROUTE_URL.passwordUpdate]">link</a>
			</div>








            <mat-divider class="top-spacing mt-20"></mat-divider>
            <div class="section user_notification">
                <p class="section__title">{{ 'user.profile.notifications.label' | translate | sentencecase }}</p>

<!--                <ng-container *ngIf="userNotification && userNotification.data.length > 0;else noDataUserNotification">-->
<!--                    <form [formGroup]="userNotificationForm" class="michelangelo-theme">-->
<!--                        <div class="notifications_wrapper">-->
<!--                            <section *ngFor="let item of userNotification.data; index as i" class="notifications_item_wrapper">-->
<!--                                <p class="notifications_title">-->
<!--                                    {{-->
<!--                                        permissionUtilService.contextTypeAndApplicationLinkFullObject(item.key)-->
<!--                                            .application_name-->
<!--                                    }}-->

<!--                                </p>-->
<!--                                <div *ngFor="let value of item.values; index as iv">-->
<!--                                    <mat-checkbox formControlName="{{ i }}_{{ iv }}">{{ "user.profile.notifications.code_item."+(value.code_item | lowercase) | translate | sentencecase }}</mat-checkbox>-->
<!--                                </div>-->
<!--                            </section>-->
<!--                        </div>-->
<!--                        <form-button-bar-->
<!--                                [align]="utilService.isMobile() ? 'vertical' : 'right'"-->
<!--                                [primaryButtonLabel]="'user.profile.notifications.save_button' | translate | sentencecase"-->
<!--                                (primaryButtonClickEmitter)="onUserNotificationFormSubmit()"-->
<!--                        ></form-button-bar>-->
<!--                    </form>-->
<!--                </ng-container>-->

                <ng-container *ngIf="userNotifificationGrouping;else noDataUserNotification">
                    <form [formGroup]="userNotificationForm" class="michelangelo-theme">
                        <div class="notifications_wrapper">
                            <section *ngFor="let contextName of Object.keys(userNotifificationGrouping)" class="notifications_item_wrapper">
                                <p class="notifications_title">
                                    {{
                                        permissionUtilService.contextTypeAndApplicationLinkFullObject(contextName)
                                            .application_name
                                    }}
                                </p>
                                <div class="notifications_group_wrapper">
                                    <div *ngFor="let group of Object.keys(userNotifificationGrouping[contextName])">
                                        <p>{{ "user.profile.notifications.groups." + group | translate | sentencecase }}</p>
                                        <div *ngFor="let item of Object.keys(userNotifificationGrouping[contextName][group])">
                                            <mat-checkbox formControlName="{{userNotifificationGrouping[contextName][group][item]}}">{{ "user.profile.notifications.code_item."+(item | lowercase) | translate | sentencecase }}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <form-button-bar
                                [align]="utilService.isMobile() ? 'vertical' : 'right'"
                                [primaryButtonLabel]="'user.profile.notifications.save_button' | translate | sentencecase"
                                (primaryButtonClickEmitter)="onUserNotificationFormSubmit()"
                        ></form-button-bar>
                    </form>
                </ng-container>

            </div>

			<mat-divider class="show-on-angelo-theme"></mat-divider>
			<ng-container
				*ngIf="
					user.context_application_list &&
					user.context_application_list.length > 1
				"
			>
				<ng-container *ngFor="let contextApplication of contextApplicationList; let last = last">
					<ng-container *ngIf="permissionUtilService.contextTypeAndApplicationLinkFullObject(contextApplication.code)">
						<div class="section">
							<p class="section__title">
								{{
									permissionUtilService.contextTypeAndApplicationLinkFullObject(contextApplication.code)
									.application_name
								}}
							</p>
							<context-code-management-wrapper
								[localListHandlerWidgetWrapperData]="contextApplication.context_code_data"
								[localListHandlerWidgetWrapperColumnList]="columns[contextApplication.code]"
								[showLinkToDetail]="false"
							>
							</context-code-management-wrapper>
						</div>
						<mat-divider *ngIf="!last" class="show-on-angelo-theme"></mat-divider>
					</ng-container>
				</ng-container>
			</ng-container>
		</mat-card>
	</ng-container>
</page-with-sidebar>

<!-- ng-template -->
<ng-template #avatarPreviewContainer>
	<div class="person-placeholder-box">
		<preview-container
			class="profile"
			[configuration]="
				{
					image: avatarUrl,
					icon: 'person',
					width: '120px',
					height: '120px',
					onClickHandler: false
				}
			"
			>
		</preview-container>
	</div>
</ng-template>


<!-- <loading-results-spinner *ngIf="isLoading"></loading-results-spinner> -->
<ng-template #noDataUserNotification>
    <p>
        {{ 'user.profile.notifications.no_data' | translate | sentencecase }}
    </p>
</ng-template>