import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { AddressPouchModel, OrganizationLevelEnum, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { UtilAddressService } from '../../../service/util/util-address.service';
import { OrganizationActionEnum } from '../../../state/organization/organization.actions';
import { ContextApplicationItemCodeEnum, OrganizationStateModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'organization-legal-information',
	templateUrl: './organization-legal-information.component.html',
	styleUrls: ['./organization-legal-information.component.scss']
})
export class OrganizationLegalInformationComponent {
	// store
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organizationSub: Subscription;

	organization: OrganizationStateModel;
	legalHeadquarter: AddressPouchModel;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	organizationLevelEnum = OrganizationLevelEnum;
	currentContext: ContextApplicationItemCodeEnum;

	constructor(private store: Store<OrganizationStateModel>, public utilAddressService: UtilAddressService) {
		// Organization
		this.organizationSub = this.organization$
			.pipe(
				filter(
					(customerState: BaseStateModel<OrganizationPouchModel>) => !!(customerState && customerState.data)
				),
				map((customerState: BaseStateModel<OrganizationPouchModel>) => {
					switch (customerState.type) {
						case OrganizationActionEnum.SAVE_SUCCESS:
						case OrganizationActionEnum.UPDATE:
							this.organization = customerState.data;
							this.legalHeadquarter =
								this.organization.destination_list?.find(
									destination => destination.is_registered_office === true
								)?.address || null;
							break;

						default:
							break;
					}
				})
			)
			.subscribe();
	}
}
