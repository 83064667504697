import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export const base: ITdDataTableColumnCustom[] = [
	{
		name: 'status',
		label: '',
		width: 50,
		sortable: false
	},
	{
		name: 'level',
		label: '',
		width: 80,
		sortable: false
	},
	{
		name: 'input_registration_code',
		labelPath: 'organization.field.input_registration_code',
		label: null
	},
	{
		name: 'business_name',
		labelPath: 'saep_ict_angular_spin8_core.page.organization.business_name',
		label: null
	},
	{
		name: 'organization_class',
		labelPath: 'theme.organization.field.class',
		label: null
	},
	{
		name: 'vat_number_or_tax_code',
		labelPath: 'organization.field.vat_number_or_tax_code',
		label: null
	},
	{
		name: 'contact_list.email',
		labelPath: 'theme.organization.field.reference_internal.name',
		label: null
	},
	{
		name: 'address',
		labelPath: 'saep_ict_angular_spin8_core.widget.destination.address.name',
		label: null,
		sortable: false
	},
	{
		name: 'add_new_order',
		label: '',
		sortable: false,
		width: 50,
		numeric: true
	}
];
