import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';
import { InformativePageModel } from '../../model/informative-page.model';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';

@Injectable()
export class AngularSpin8CoreInformativePageService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path:  `/${returnApiUrlSegment(ApiType.PUBLIC)}/informative-page/`
	})
	getInformativePage: IResourceMethodPromise<{ slug: string } | void, RestBaseResponse<InformativePageModel[]>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path:  `/${returnApiUrlSegment(ApiType.PUBLIC)}/informative-page/`
	})
	createInformativePage: IResourceMethodPromise<InformativePageModel, any>;
}
