import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { AngularSpin8CoreUtilCompanyService } from '@saep-ict/angular-spin8-core';
import { ActionPouchModel, ActionStatusEnum, ActionTypes } from '../../../model/state/action-state.model';
import { Store } from '@ngrx/store';

@Component({
	selector: 'action-list-wrapper',
	templateUrl: './action-list-wrapper.component.html',
	styleUrls: ['./action-list-wrapper.component.scss']
})
export class ActionListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Input() isLogs: boolean;
	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();
	@Output() openDialogConfirmCloseAction = new EventEmitter<ActionPouchModel>();

	ActionTypes = ActionTypes;
	ActionStatusEnum = ActionStatusEnum;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		public store: Store
	) {
		super(utilService, translateService, store);
	}

	getIcon(type: string) {
		return ActionTypes.find(at => at.code == type).icon;
	}
}
