<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="
			'general.search' | translate: { x: 'receipt.document_type.invoice' | translate } | sentencecase
			"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
	>
		<ng-template tdDataTableTemplate="brand" let-value="value" let-row="row">
			<a
				*ngIf="row.category_list && row.category_list.length > 0 else codeTemplate"
				(click)="onItemSelect.emit(row)"
				href="javascript:void(0)"
			>
				<ng-container *ngTemplateOutlet="codeTemplate"></ng-container>
			</a>
			<ng-template #codeTemplate>
				{{ utilTranslateService.getTranslationFromLanguage(row.language_list).description }}
			</ng-template>
		</ng-template>
		<ng-template tdDataTableTemplate="line" let-value="value" let-row="row">
			{{ utilTranslateService.getTranslationFromLanguage(row.language_list).description }}
		</ng-template>
		<ng-template tdDataTableTemplate="product" let-value="value" let-row="row">
			{{ utilTranslateService.getTranslationFromLanguage(row.articleDescription?.language_list).description }}
		</ng-template>
		<ng-template tdDataTableTemplate="image" let-value="value" let-row="row">
			<button
				*ngIf="
					row.language_list &&
					row.language_list[0] &&
					row.language_list[0].image_list &&
					row.language_list[0].image_list[0]
				"
				mat-icon-button
				(click)="
					dialogGallery(row);
					$event.stopPropagation()
				"
			>
				<mat-icon class="image">image</mat-icon>
			</button>
		</ng-template>
		<ng-template tdDataTableTemplate="action" let-value="value" let-row="row">
			<button
				type="button"
				mat-icon-button
				(click)="
					onItemEdit.emit(row);
					$event.stopPropagation()
				"
			>
				<mat-icon class="edit">edit</mat-icon>
			</button>
			<button
				*ngIf="
					row.language_list &&
					row.language_list[0] &&
					row.language_list[0].image_list &&
					row.language_list[0].image_list[0]
				"
				mat-icon-button
				(click)="
					onItemDelete.emit(row);
					$event.stopPropagation()
				"
			>
				<mat-icon
					class="trash delete"
					[svgIcon]="'custom-trash'"
				>
					delete
				</mat-icon
				>
			</button>
		</ng-template>
	</td-data-table>

	<mat-toolbar class="mt-20" *ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow">
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
				[hidePageSize]="true"
			>
			</mat-paginator>
		</mat-toolbar-row>
	</mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
