import { ArticleChangeInputConfig } from "../model/order-util.model";

export const goToByKeyboard = (e: KeyboardEvent, c: ArticleChangeInputConfig) => {
    const nodeList = document.querySelectorAll('.inputTag');
    switch (e.key) {
        case 'ArrowUp':
            goToPreviousItem(nodeList, c, true);
            break;
        case 'ArrowRight':
            goToNextItem(nodeList, c);
            break;
        case 'ArrowDown':
            goToNextItem(nodeList, c, true);
            break;
        case 'ArrowLeft':
            goToPreviousItem(nodeList, c);
            break;
    }
}

export const goToNextItem = (
    nodeList: NodeListOf<Element>,
    c: ArticleChangeInputConfig,
    matchInputKey?: boolean
) => {
    iLoop: for (let i = 0; i < nodeList.length; i++) {
        if (nodeList[i].id === `${c.row.code_item}_${c.key}`) {
            for (let n = 1; n < (nodeList.length - i); n++) {
                const nextItem = (nodeList.item(i + n) as HTMLInputElement);
                if (matchItemAvailability(nextItem, c, matchInputKey)) {
                    nextItem.focus();
                    break iLoop;
                }
            }
        }
    }
}

export const goToPreviousItem = (
    nodeList: NodeListOf<Element>,
    c: ArticleChangeInputConfig,
    matchInputKey?: boolean
) => {
    iLoop: for (let i = 0; i < nodeList.length; i++) {
        if (nodeList[i].id === `${c.row.code_item}_${c.key}`) {
            for (let n = i - 1; n >= 0; n--) {
                const nextItem = (nodeList.item(n) as HTMLInputElement);
                if (matchItemAvailability(nextItem, c, matchInputKey)) {
                    nextItem.focus();
                    break iLoop;
                }
            }
        }
    }
}

export const matchItemAvailability = (
    nextItem: HTMLInputElement,
    c: ArticleChangeInputConfig,
    matchInputKey: boolean
): boolean => {
    return nextItem &&
        !nextItem.disabled &&
        (
            matchInputKey ?
            (nextItem.getAttribute('data-input-key') === c.key) :
            true
        );
}