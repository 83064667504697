<mat-accordion>
	<div *ngFor="let category of categoryList">
		<mat-expansion-panel *ngIf="category && category.category_list && category.category_list.length > 0">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<!-- uncomment it in case checkbox is needed at any level of the category tree -->
					<!-- <mat-checkbox
						(change)="utilCategoryListService.changeSelectedCategoryList(category.code_item)"
						[checked]="utilCategoryListService.selectedCategoryList.has(category.code_item)"
						(click)="$event.stopPropagation()"
					>
						{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}
					</mat-checkbox> -->
					<p>{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}</p>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<recursive-category-list [categoryList]="category.category_list"></recursive-category-list>
		</mat-expansion-panel>
		<mat-checkbox
			color="primary"
			*ngIf="category && (!category.category_list || category.category_list.length == 0)"
			(change)="utilCategoryListService.changeSelectedCategoryList(category.code_item)"
			[checked]="utilCategoryListService.selectedCategoryList.has(category.code_item)"
			class="{{ category.level }}"
		>
			{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}
		</mat-checkbox>
	</div>
</mat-accordion>
