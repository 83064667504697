import { Component, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
	NG_VALUE_ACCESSOR,
	ControlValueAccessor,
	UntypedFormGroup,
	UntypedFormBuilder,
	UntypedFormArray,
	NG_VALIDATORS,
	Validator,
	AbstractControl
} from '@angular/forms';
import * as FormControlMultipurposeEnum from '../../../enum/widget/form-control-multipurpose.enum';
import * as FormControlMultipurposeModel from '../../../model/widget/form-control-multipurpose.model';
import { Observable, Subscription } from 'rxjs';
import _ from 'lodash';
import { filter, map } from 'rxjs/operators';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { FormControlMultipurposeService } from '../../../service/util/form-control-multipurpose.service';
import { AngularCoreUtilService } from '../../../service/util/util.service';
import { UntypedFormControl } from '@angular/forms';
import moment from 'moment';
import * as FormControlMultipurposeConfiguration from '../../../constants/form-control-multipurpose.constant';

@Component({
	selector: 'form-control-multipurpose',
	templateUrl: './form-control-multipurpose.component.html',
	styleUrls: ['./form-control-multipurpose.component.scss'],
	providers: [
		SubscribeManagerService,
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: FormControlMultipurposeComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: FormControlMultipurposeComponent
		}
	]
})
export class FormControlMultipurposeComponent implements ControlValueAccessor, OnDestroy, Validator {
	formControlTypeEnum = FormControlMultipurposeEnum.ItemType;
	_field: FormControlMultipurposeModel.Item;
	formFieldMultipurposeValue$: Observable<any>;
	formControlMultipurposeConfiguration = FormControlMultipurposeConfiguration;

	@Input() set field(f: FormControlMultipurposeModel.Item) {
		if (f) {
			this._field = f;
			if (this._field.type === FormControlMultipurposeEnum.ItemType.RTF) {
				this.ignoreRTFCreationEmit = true;
			}
			if (this._field.type !== FormControlMultipurposeEnum.ItemType.IMAGE_PREVIEW) {
				this.createForm();
				this.subscribeManagerService.populate(
					this.subscribeRootFormChange().subscribe(),
					`${this._field.form_id}_${this.utilService.guid()}`
				);
			}
		}
	}

	// form
	form; // FormGroup | FormArray;
	formValueChange$: Subscription;
	/**
	 * Oggetto utile alla renderizzazione annidata dei gruppi presenti in _field di tipo
	 * FORM_ARRAY
	 *
	 * @type {FormControlMultipurposeModel.Item[][]}
	 * @memberof FormControlMultipurposeComponent
	 */
	formArrayItemControlList: FormControlMultipurposeModel.Item[][] = [];

	/**
	 * Per i _field di tipo RTF, inibisce l'emit del primo valore subito dopo la creazione del form.
	 * Questo comportamento ad oggi è noto soltanto nei casi di utilizzo di quill-editor come form field
	 *
	 * @type {boolean}
	 * @memberof FormControlMultipurposeComponent
	 */
	ignoreRTFCreationEmit: boolean;

	constructor(
		private formFieldMultipurposeService: FormControlMultipurposeService,
		private fb: UntypedFormBuilder,
		private subscribeManagerService: SubscribeManagerService,
		private cdr: ChangeDetectorRef,
		public utilService: AngularCoreUtilService
	) { }

	// ControlValueAccessor
	validate(control: AbstractControl) {
		if (this.form && this.form.valid) {
			return null;
		}
		let errors: any = {};
		if (this._field) {
			errors = this.addControlErrors(errors, this._field);
		}
		return errors;
	}
	addControlErrors(allErrors: any, field: FormControlMultipurposeModel.Item) {
		const errors = { ...allErrors };
		switch (field.type) {
			case FormControlMultipurposeEnum.ItemType.FORM_GROUP:
				for (const subField of field.form_control_list) {
					const controlErrors = this.form.controls[subField.name].errors;
					if (controlErrors) {
						errors[subField.name] = controlErrors;
					}
				}
				break;
			case FormControlMultipurposeEnum.ItemType.FORM_ARRAY:
				this.form.controls.forEach(i => {
					const controlErrors = i.errors;
					errors[field.name] = controlErrors;
				});
				break;
			case FormControlMultipurposeEnum.ItemType.DATE_RANGE:
				for (const date of Object.keys(FormControlMultipurposeEnum.DateRange)) {
					errors[FormControlMultipurposeEnum.DateRange[date]] =
						this.form.controls[this._field.name].controls[FormControlMultipurposeEnum.DateRange[date]].errors
				}
				break;
			default:
		}
		return errors;
	}
	writeValue(value: any) {
		if (this._field.type !== FormControlMultipurposeEnum.ItemType.IMAGE_PREVIEW && value) {
			this.form.patchValue(value, { emitEvent: false });
		}
		// il seguente permette di forzare l'invalidamento del form a livello root nei casi
		//  - formgroup annidato in formgroup passato a sua volta come elemento di formarray
		this.cdr.detectChanges();
	}
	propagateChange = (_: any) => {
		// do nothing.
	};
	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}
	registerOnTouched(fn: any) {
		// do nothing.
	}

	// lifecycle and data
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
	subscribeRootFormChange(): Observable<any> {
		return this.formFieldMultipurposeService.returnRootFormValue(this._field.form_id).pipe(
			// TODO: verificare alternativa, dovesse emergere la necessità di monitorare un null
			filter(value => !!value),
			map(async value => {
				// aggiornamento del form per applicazione dei validatori dinamici che tengono
				// conto del valore del root form
				await this.formFieldMultipurposeService.updateFormControlAccordingToType(this.form, this._field, value);
			})
		);
	}

	async createForm() {
		const controls = {};
		switch (this._field.type) {
			case FormControlMultipurposeEnum.ItemType.FORM_GROUP:
				// viene creato un form field per ogni sub field presente in _field.form_control_list
				for (const field of this._field.form_control_list) {
					controls[field.name] = this.formFieldMultipurposeService.returnNewFormControl(field);
				}
				// form è instanziato come FormGroup standard
				this.form = new UntypedFormGroup(controls);
				break;
			case FormControlMultipurposeEnum.ItemType.FORM_ARRAY:
				controls[this._field.name] = [];
				// i formarray sono eventualmente forniti di _field.value (any[]) come le altre
				// tipologie, da questi vengono generate le istanze che compongono il form array
				// La morfologia dei gruppi è invece data dalla mappa dei campi di form_control_list
				if (this._field.value && this._field.value.length > 0) {
					// casistica in cui esiste _field.value
					//  - default proveniente dalla mappa in se
					//  - valori attribuiti a valle di un merge pregresso (updateFormFieldListValue)
					//    con dati provenienti da remoto

					for (let i = 0; i < this._field.value.length; i++) {
						// per ogni elemento di _field.value viene creato un elemento in
						// formArrayItemControlList, contenente il merge tra la mappa degli elementi
						// che compongono il gruppo (formarray item) e i valori strutturati di _field.value[i]
						// il passaggio è necessario per sfruttare ricorsivamente form-control-multipurpose
						this.formArrayItemControlList[i] = this.formFieldMultipurposeService.updateFormFieldListValue(
							this._field.form_control_list,
							this._field.form_value,
							this._field.value[i]
						);
						const item = {};
						for (const field of this._field.form_control_list) {
							// vengono creati i gruppi che compongono gli elementi del formarray
							// il valore viene sovrascritto con null per evitare la propagazione dei valori di
							// deafult della mappa, che nel caso FORM_ARRAY devono essere presi da value per la
							// totalità dell'oggetto annidato
							item[field.name] = this.formFieldMultipurposeService.returnNewFormControl(
								field,
								null // TODO: capire perchè settato a this._field.value[i][field.name]
								// determina la comparsa dei valori di default (valid: true) presenti
								// sulla mappa, ma non in valueTest
							);
						}
						controls[this._field.name].push(this.fb.group(item));
					}
				} else {
					// casistica in cui non esiste _field.value
					//  - mappa semplice senza default in contesto di creazione

					// è creata una singola istanza in formArrayItemControlList priva di valori
					// ma avente struttura tale da sfruttare ricorsivamente form-control-multipurpose
					const item = {};
					this.formArrayItemControlList[0] = [];
					for (const field of this._field.form_control_list) {
						this.formArrayItemControlList[0].push(field);
						item[field.name] = this.formFieldMultipurposeService.returnNewFormControl(field, null);
					}
					controls[this._field.name].push(this.fb.group(item));
				}
				// viene instanziato form come FormArray
				this.form = new UntypedFormArray(controls[this._field.name]);
				break;
			default:
				// casistiche single field
				switch (this._field.type) {
					case FormControlMultipurposeEnum.ItemType.DATE: {
						// conversione del valore per visualizzazione in mat-datepicker
						this._field.value = this._field.value ? moment(this._field.value) : null;
						// viene creato un unico form field
						controls[this._field.name] = this.formFieldMultipurposeService.returnNewFormControl(this._field);
						break;
					}
					case FormControlMultipurposeEnum.ItemType.DATE_RANGE: {
						this._field.value =
							this._field.value ?
								{ start: moment(this._field.value.start), end: moment(this._field.value.end) } :
								{ start: null, end: null };
						const dateRangeFormGroup = {};
						for (const date of Object.keys(FormControlMultipurposeEnum.DateRange)) {
							dateRangeFormGroup[FormControlMultipurposeEnum.DateRange[date]] =
								new UntypedFormControl(
									this._field.value[FormControlMultipurposeEnum.DateRange[date]],
									this._field.validator_list && this._field.validator_list.length > 0 ? this._field.validator_list : []
								)
						}
						controls[this._field.name] = new UntypedFormGroup(dateRangeFormGroup,
							this._field.validator_list && this._field.validator_list.length > 0 ? this._field.validator_list : []);
						break;
					}
					default: {
						// viene creato un unico form field
						controls[this._field.name] = this.formFieldMultipurposeService.returnNewFormControl(this._field);
						break;
					}
				}
				// form è instanziato come FormGroup avente un unico control, avente nome a sua volta
				// uguale a _field.name
				this.form = new UntypedFormGroup(controls);
				break;
		}
		// aggiornamento del form per applicazione dei validatori dinamici che tengono
		// conto del valore del root form
		await this.formFieldMultipurposeService.updateFormControlAccordingToType(
			this.form,
			this._field,
			this._field.form_value
		);
		// aggiornamento dell'indicazione required nel placeholder
		this.markFieldAsRequired(this._field);
		if (this.formValueChange$) {
			this.formValueChange$.unsubscribe();
		}
		this.subscribeManagerService.populate(
			this.form.valueChanges.subscribe(value => {
				if (!this.ignoreRTFCreationEmit) {
					// restituisce il valore diretto del campo nei casi in cui esso sia annidato a sua volta
					// nel from field relativo a se stesso, avente suo stesso name
					// la condizione è ragionevolmente possibile l'addove un FORM_GROUP abbia in se una proprietà
					// uguale al suo stesso nome
					if (
						this._field.type !== FormControlMultipurposeEnum.ItemType.FORM_GROUP &&
						Object.prototype.hasOwnProperty.call(value, this._field.name)
					) {
						// caso dei single field
						if (value[this._field.name]) {
							switch (this._field.type) {
								case FormControlMultipurposeEnum.ItemType.NUMBER:
									value[this._field.name] = parseFloat(value[this._field.name]);
									break;
								case FormControlMultipurposeEnum.ItemType.DATE:
									value[this._field.name] = value[this._field.name].valueOf();
									break;
								case FormControlMultipurposeEnum.ItemType.DATE_RANGE:
									for (const date of Object.keys(FormControlMultipurposeEnum.DateRange)) {
										value[this._field.name][FormControlMultipurposeEnum.DateRange[date]] =
											value[this._field.name][FormControlMultipurposeEnum.DateRange[date]] ?
												value[this._field.name][FormControlMultipurposeEnum.DateRange[date]].valueOf() :
												null
									}
									break;
							}
						}
						this.propagateChange(this.utilService.deleteEmptyProperties(value[this._field.name]));
					} else {
						this.propagateChange(this.utilService.deleteEmptyProperties(value));
					}
					// aggiornamento dell'indicazione required nel placeholder
					this.markFieldAsRequired(this._field);	
				} else {
					this.ignoreRTFCreationEmit = false;
				}
			}),
			'form-control-multipurpose-value-change'
		);
	}
	/**
	 * Valorizza a true o elimina la prop. required_placeholder_marker. Da usare solo dopo che il form sia stato inizializzato
	 * ed aggiornato con gli eventuali validatori dinamici.
	 * @param formControl
	 * @param field
	 */
	markFieldAsRequired(field: FormControlMultipurposeModel.Item) {
		let errorObject;
		switch (field.type) {
			case FormControlMultipurposeEnum.ItemType.DATE_RANGE:
				// i validatori sono configurati a livello del gruppo che contiene `start` e `end` e vanno di pari passo
				errorObject = this.form.controls[field.name].controls.start;
				break;
			default:
				errorObject = this.form.controls[field.name];
		}
		if (errorObject && errorObject.errors && errorObject.errors.required) {
			field.required_placeholder_marker = true;
		} else {
			delete field.required_placeholder_marker;
		}
	}

	/**
	 * Gestisce il `compareWith` di `mat-select` anche nei casi diversi da `select_save_whole_object`
	 *
	 * @param {*} option
	 * @param {*} value
	 * @returns {boolean}
	 * @memberof FormControlMultipurposeComponent
	 */
	selectOption(option: any, value: any): boolean {
		if (value) {
			if (value.value) {
				return value.value === option.value;
			}
			return value === option;
		}
		return false;
	}
}
