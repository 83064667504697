import { Validators } from "@angular/forms";
import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";

export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
    {
        name: "stock_type",
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.FORM_ARRAY,
        form_control_list: [
            {
                name: 'code_item',
                on_form_template: false
            },
            {
                name: 'charge_type',
                on_form_template: false
            },
            {
                name: 'description_short',
                on_form_template: false
            },
            {
                name: 'um',
                on_form_template: false
            },
            {
                name: 'description',
                label: 'general.description',
                on_form_template: true,
                type: FormControlMultipurposeEnum.ItemType.STRING,
                set_disabled: () => true
            },
            {
                name: 'value',
                label: 'additional_service.value',
                on_form_template: true,
                type: FormControlMultipurposeEnum.ItemType.NUMBER,
                validator_list: [ Validators.required ],
                column_width: 6
            },
            {
                name: 'charge',
                label: 'additional_service.charge',
                on_form_template: true,
                type: FormControlMultipurposeEnum.ItemType.NUMBER,
                validator_list: [ Validators.required ],
                column_width: 6
            },
        ]
    }
];