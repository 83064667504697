import { FormControlMultipurposeModel } from "@saep-ict/angular-core";
import { OrderListFilterCreationFieldMapFormValue, OrderListFilterCreationFieldMapSelectOptionMap } from "../../../../model/order-util.model";
import { TableOrderModel } from "../../../../model/table/table-order.model";
import { OrderStatusEnum } from "@saep-ict/pouch_agent_models";
import * as ConfigurationCustomerOrder from "../order.constant";
import * as FormDynamicUtil from '../../../form-dynamic.util.constant';

// option add
export const returnCreationFieldMapWithOptionOrderStatus = (
    creationFieldMap: FormControlMultipurposeModel.Item[],
	selectOptionMap: OrderListFilterCreationFieldMapSelectOptionMap,
	orderStatus?: boolean
): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			if (orderStatus) {
				creationFieldMap = creationFieldMap.filter(i => i.name !== 'status');
			} else {
				const orderStatusField = creationFieldMap.find(i => i.name === 'status');
				orderStatusField.option_list =
					selectOptionMap.status.map(i => {
						return {
							label: i.label,
							// TODO: valutare se ammorbidire la modellazione di FormControlMultipurposeModel.OptionList
							// per permettergli di storare value diversi da number/string
							value: i.related_list as any
						}
					})
			}
			resolve(creationFieldMap);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnCreationFieldMapWithOptionOrderCausalList = (
    creationFieldMap: FormControlMultipurposeModel.Item[],
	selectOptionMap: OrderListFilterCreationFieldMapSelectOptionMap
): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const order_causalListField = creationFieldMap.find(i => i.name === 'order_causal');
			order_causalListField.option_list =
				selectOptionMap.order_causal.map(i => {
					return {
						label: i.description,
						value: i.code_item
					}
				})
			resolve(creationFieldMap);
		} catch(e) {
			throw new Error(e);
		}
	});
}

// filter
export const returnConfigurationCustomerOrderListFilterByFormFieldDefault = async (
    orderList: TableOrderModel[],
    formValue: OrderListFilterCreationFieldMapFormValue
): Promise<TableOrderModel[]> => {
	try {
		if (orderList && orderList.length && formValue) {
			orderList = await returnConfigurationCustomerOrderListHeaderFilterByDateRange(orderList, formValue, 'date');
			if (formValue.status && formValue.status.length > 0) {
				let statusList: OrderStatusEnum[] = [];
				for (const statusAggregation of formValue.status) {
					if (statusAggregation && statusAggregation.length > 0) {
						statusList = statusList.concat(statusAggregation);
					}
				}
				orderList = await ConfigurationCustomerOrder.filterByOrderStatus(orderList, statusList);
			}
		}
		return orderList;
	} catch(e) {
		throw new Error(e);
	}
}

export const returnConfigurationCustomerOrderListFilterByFormFieldOrderCausalList = (
    orderList: TableOrderModel[],
    formValue: OrderListFilterCreationFieldMapFormValue
): Promise<TableOrderModel[]> => {
	return new Promise(resolve => {
		try {
			if (orderList && orderList.length && formValue) {
				if (formValue.order_causal && formValue.order_causal.length > 0) {
					const filteredCausalList = [];
					for (const order of orderList) {
						for (const causalCodeItem of formValue.order_causal) {
							if (order.header.order_causal === causalCodeItem) {
								filteredCausalList.unshift(order)
							}
						}
					}
					orderList = filteredCausalList;
				}
			}
			resolve(orderList);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerOrderListHeaderFilterByDateRange = (
	orderList: TableOrderModel[],
    formValue: OrderListFilterCreationFieldMapFormValue,
	fieldName: string
): Promise<TableOrderModel[]> => {
	return new Promise(resolve => {
		try {
			if (orderList && orderList.length && formValue && formValue[fieldName]) {
				if (formValue[fieldName].start) {
					orderList = orderList.filter(order => order.header[fieldName] >= +formValue[fieldName].start);
				}
				if (formValue[fieldName].end) {
					orderList = orderList.filter(order => order.header[fieldName] <= +FormDynamicUtil.add1day(formValue[fieldName].end));
				}
			}
			resolve(orderList);
		} catch(e) {
			throw new Error(e);
		}
	});
}
