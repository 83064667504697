import { Location, SlicePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseState,
	BaseStateModel,
	LoaderService,
	SentencecasePipe,
	SubscribeManagerService,
	ThousandPipe
} from '@saep-ict/angular-core';
import * as _ from 'lodash';
import moment from 'moment';
import { merge, Observable } from 'rxjs';
import { filter, map, mergeMap, skipWhile, startWith } from 'rxjs/operators';
import { ContactPouchModel, ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { GuidFormatterPipe } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../../state';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../../state/opportunity/opportunity.actions';
import { DialogSelectAssegneeComponent } from '../../../../../widget/dialog/dialog-select-assegnee/dialog-select-assegnee.component';
import { DialogSelectOrganizationComponent } from '../../../../../widget/dialog/dialog-select-organization/dialog-select-organization.component';
import { DialogCloseOpportunityComponent } from '../../../../../widget/dialog/dialog-close-opportunity/dialog-close-opportunity.component';
import { OrganizationStateAction } from '../../../../../state/organization/organization.actions';
import { DialogContactDetailComponent } from '../../../../../widget/dialog/dialog-contact-detail/dialog-contact-detail.component';
import { StatusBarOrderService } from '../../../../../service/status-bar-config/implementation/status-bar-order.service';
import { ContactStateAction } from '../../../../../state/contact/contact.actions';
import { OrganizationListStateAction } from '../../../../../state/common/organization-list/organization-list.actions';
import { MatSelectChange } from '@angular/material/select';
import { DialogActionListComponent } from '../../../../../widget/dialog/dialog-action-list/dialog-action-list.component';
import { LocalStorage } from 'ngx-webstorage';
import {
	ContextApplicationItemCodeEnum,
	OpportunityEnum,
	OpportunityStep,
	ROUTE_URL,
	UserDetailModel,
	OrganizationStateModel,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../../../service/util/app-util.service';
import { ContactModel } from '../../../../../model/contact.model';
import * as ConfigurationCustomerAppStructure from '../../../../../constants/configuration-customer/app-structure/app-structure.constant';

@Component({
	selector: 'opportunity-detail-main',
	templateUrl: './opportunity-detail-main.component.html',
	styleUrls: ['./opportunity-detail-main.component.scss'],
	providers: [SubscribeManagerService, SentencecasePipe, SlicePipe]
})
export class OpportunityDetailMainComponent implements OnInit, OnDestroy {
	@LocalStorage('user') private user: UserDetailModel;

	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	contact$: Observable<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> = this.store.select(
		StateFeature.getContact
	);
	contacts: ContactPouchModel[];

	idOpportunity: string;
	opportunityLevel: OpportunityEnum.Level;
	OpportunityEnum = OpportunityEnum;

	selectedOrganization: OrganizationStateModel;
	selectedAssegnee: UserDetailModel;
	referentList: ContactPouchModel[] = [];

	form: FormGroup;

	get opportunityStatusCode() {
		return Object.values(OpportunityEnum.Status.Outcome);
	}

	stepList = new OpportunityStep().stepList;
	ROUTE_URL = ROUTE_URL;

	constructor(
		private store: Store<any>,
		public route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private dialog: MatDialog,
		public translate: TranslateService,
		private subscribeManagerService: SubscribeManagerService,
		private utilService: AppUtilService,
		private thousandPipe: ThousandPipe,
		private slicePipe: SlicePipe,
		private loader: LoaderService,
		private sentencecasePipe: SentencecasePipe,
		private translateService: TranslateService,
		public statusBarOrderService: StatusBarOrderService,
		private guidFormatterPipe: GuidFormatterPipe,
		private location: Location
	) {}

	ngOnInit() {
		// retrieve opportunity information
		this.opportunityLevel = `${this.route.parent.snapshot.paramMap.get(
			'opportunityLevel'
		)}` as OpportunityEnum.Level;
		this.idOpportunity = `${this.route.parent.snapshot.paramMap.get('idOpportunity')}`;

		this.store.dispatch(OrganizationListStateAction.loadAll());

		this.store.dispatch(ContactStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeContactList().subscribe(), 'subscribeContactList');

		this.createForm();

		// subscribe
		this.subscribeManagerService.populate(
			this.subscribeCurrencyFields().subscribe(),
			'dimension-visible-total-form-change'
		);
		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');

		// populate form if not new
		if (this.idOpportunity !== ROUTE_URL.newOpportunity) {
			this.store.dispatch(OpportunityStateAction.load({ data: { _id: this.idOpportunity } }));
		}
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// Reset dello state opportunity nel componente padre
	}

	/**
	 * Form
	 */

	getFormControl(key: string): FormControl {
		return <FormControl>this.form.get(key);
	}

	displayFn(referent: ContactModel): string {
		return referent
			? (referent.full_name || `${referent.first_name} ${referent.last_name}`) +
					(referent.organization?.business_name ? ` | ${referent.organization.business_name}` : '')
			: '';
	}

	filteredReferentList: Observable<ContactModel[]>;
	createForm() {
		this.form = this.fb.group({
			origin_name: ['', { validators: [] }],
			id: ['', { validators: [] }],
			title: ['', { validators: [] }],
			reference: ['', { validators: [] }],
			actual_value: ['', { validators: [] }],
			topic: ['', { validators: [] }],
			message: ['', { validators: [Validators.required] }],
			opportunity_type: ['', { validators: [Validators.required] }],
			client_id: ['', { validators: [] }],
			user_id: ['', { validators: [] }],
			budget_amount: ['', { validators: [] }],
			organization: ['', { validators: [Validators.required] }],
			contact: ['', { validators: [Validators.required] }],
			assegnee: ['', { validators: [] }],
			estimated_close_date: ['', { validators: [] }],
			schedule_followup_prospect: ['', { validators: [] }],
			project_interest: ['', { validators: [Validators.min(0), Validators.max(100)] }],
			close_probability: ['', { validators: [Validators.min(0), Validators.max(100)] }],
			step: ['', { validators: [] }],
			added_by: ['', { validators: [] }]
		});

		this.filteredReferentList = this.getFormControl('contact').valueChanges.pipe(
			startWith(''),
			map(state =>
				state && typeof state == 'string'
					? this._filterReferentList(state)
					: (this.referentList.slice() as ContactModel[])
			)
		);

		this.disableFieldList(['id', 'origin_name', 'client_id', 'added_by', 'user_id']);

		if (this.idOpportunity === ROUTE_URL.newOpportunity) {
			this.populateDefaultData();
		}
	}

	private _filterReferentList(value: string): ContactModel[] {
		const filterValue = value.toLowerCase();
		return this.referentList
			.map(referent => {
				referent.full_name = referent.full_name || `${referent.first_name} ${referent.last_name}`;
				return referent;
			})
			.filter(referent => referent.full_name.toLowerCase().includes(filterValue)) as ContactModel[];
	}

	populateDefaultData() {
		this.form.patchValue(
			{
				origin_name: 'Creato da operatore',
				estimated_close_date: moment().add(1, 'M')
			},
			{ emitEvent: false }
		);
	}

	disableFieldList(fieldList: string[]) {
		fieldList.forEach(nameField => {
			this.form.get(nameField)?.disable({ emitEvent: false });
		});
	}

	setFormFromRemoteData() {
		let contact: ContactPouchModel = null;

		if (this.opportunity.organization.code_item) {
			this.selectedOrganization = this.organizationList.find(
				x => x.code_item === this.opportunity.organization.code_item
			);
			this.referentList = this.selectedOrganization.contact_list;
		}

		if (this.selectedOrganization.contact_list && this.opportunity.contact_id) {
			contact = this.selectedOrganization.contact_list.find(
				contact => contact.code_item === this.opportunity.contact_id
			);
		}

		// Compongo assegnee name
		let assegneeName = '';
		if (this.opportunity.assegnee_user_name) {
			// assegneeName = `${this.opportunity.assegnee_user_name} - ${this.opportunity.assegnee_full_name}`;
			assegneeName = `${this.opportunity.assegnee_full_name}`;
		}

		this.form.patchValue(
			{
				origin_name: this.opportunity.origin_name,
				id: this.guidFormatterPipe.transform(this.opportunity._id || ''),
				title: this.opportunity.title,
				reference: this.opportunity.reference,
				actual_value: this.opportunity.actual_value ? this.formatCurrency(this.opportunity.actual_value) : null,
				topic: this.opportunity.topic,
				message: this.opportunity.message,
				opportunity_type: this.opportunity.opportunity_type,
				client_id: this.opportunity.client_id,
				user_id: this.opportunity.user_id,
				budget_amount: this.opportunity.budget_amount
					? this.formatCurrency(this.opportunity.budget_amount)
					: null,
				organization: this.selectedOrganization.business_name,
				contact: this.opportunity.contact_id ? contact : null,
				assegnee: assegneeName,
				estimated_close_date: this.opportunity.estimated_close_date
					? moment(this.opportunity.estimated_close_date)
					: null,
				schedule_followup_prospect: this.opportunity.schedule_followup_prospect
					? moment(this.opportunity.schedule_followup_prospect)
					: null,
				project_interest: this.opportunity.project_interest,
				close_probability: this.opportunity.close_probability,
				step: this.opportunity.step_id ? this.opportunity.step_id : null,
				added_by: this.opportunity.added_by ? this.opportunity.added_by : null
			},
			{ emitEvent: false }
		);

		// Disattivo il form se la opportunity è !OPEN
		if (this.opportunity.status_code !== OpportunityEnum.Status.Outcome.Open) {
			this.form.disable({ emitEvent: false });
		}
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.store.dispatch(OpportunityStateAction.save(new BaseState([this.prepareSaveForm()])));
			this.location.back();
		} else {
			this.utilService.showDialog('general.save_missing_data');
		}
	}

	prepareSaveForm() {
		const formValue = this.form.getRawValue();

		// initialization
		let newOpportunity: OpportunityPouchModel = this.opportunity
			? _.cloneDeep(this.opportunity)
			: <OpportunityPouchModel>{};
		newOpportunity.type = 'opportunity';
		newOpportunity.date_creation = newOpportunity.date_creation || moment().valueOf();
		newOpportunity.origin_name = formValue.origin_name ? formValue.origin_name : null;
		newOpportunity.title = formValue.title ? formValue.title : null;
		newOpportunity.reference = formValue.reference || null;
		newOpportunity.actual_value = formValue.actual_value ? this.parseCurrency(formValue.actual_value) : null;
		newOpportunity.topic = formValue.topic ? formValue.topic : null;
		newOpportunity.message = formValue.message ? formValue.message : null;
		newOpportunity.opportunity_type = formValue.opportunity_type ? formValue.opportunity_type : null;
		newOpportunity.client_id = formValue.client_id ? formValue.client_id : null;
		newOpportunity.user_id = formValue.user_id ? formValue.user_id : null;
		newOpportunity.budget_amount = formValue.budget_amount ? this.parseCurrency(formValue.budget_amount) : null;
		newOpportunity.estimated_close_date = formValue.estimated_close_date
			? formValue.estimated_close_date.valueOf()
			: null;
		newOpportunity.schedule_followup_prospect = formValue.schedule_followup_prospect
			? formValue.schedule_followup_prospect.valueOf()
			: null;
		newOpportunity.project_interest = formValue.project_interest ? formValue.project_interest : null;
		newOpportunity.close_probability = formValue.close_probability ? formValue.close_probability : null;

		newOpportunity.status_code = newOpportunity.status_code || OpportunityEnum.Status.Outcome.Open;

		// organization
		if (this.selectedOrganization) {
			newOpportunity.organization_id = this.selectedOrganization.code_item;
			newOpportunity.organization = {
				code_item: this.selectedOrganization.code_item,
				business_name: this.selectedOrganization.business_name
			};
		}

		// contact
		newOpportunity.contact_id = formValue.contact ? formValue.contact.code_item : null;
		newOpportunity.contact_name = formValue.contact ? formValue.contact.full_name : null;

		// assegnee
		if (this.selectedAssegnee) {
			newOpportunity.assegnee_user_name = this.selectedAssegnee.username || null;
			newOpportunity.assegnee_user_id = this.selectedAssegnee.id || null;
			newOpportunity.assegnee_avatar = this.selectedAssegnee.avatar || null;
			newOpportunity.assegnee_full_name = `${this.utilService.getUserNameFormatted(this.selectedAssegnee)}`;
		}

		// step
		newOpportunity.step_id = formValue.step ? formValue.step : null;
		// newOpportunity.step_name = formValue.step ? formValue.step.title : null;

		// level
		newOpportunity.level = this.opportunityLevel;
		if (!newOpportunity.context_application) {
			newOpportunity.context_application = ContextApplicationItemCodeEnum.CRM;
		}

		// manageable (automatica)
		if (newOpportunity.opportunity_type == 'RG' || newOpportunity.opportunity_type == 'RC') {
			newOpportunity.manageable = true;
		} else {
			newOpportunity.manageable = false;
		}

		if (this.user && !this.opportunity?.added_by) {
			newOpportunity.added_by = this.user.first_name
				? this.user.first_name + ' ' + this.user.last_name
				: this.user.username;
		}

		// return
		this.utilService.deleteEmptyProperties(newOpportunity);
		// verifica perché non salva mittente
		// console.log(newOpportunity);
		return newOpportunity;
	}

	updateFormFieldValue(key: string, value: any) {
		const formField = {};
		formField[key] = _.cloneDeep(value);
		this.form.patchValue(formField);
	}

	canSaveForm() {
		if (!this.opportunity || (this.opportunity && !this.opportunity.close_date)) {
			return true;
		}
		return false;
	}

	canPromoteForm() {
		if (
			this.opportunity &&
			this.opportunity._id &&
			this.route.parent.snapshot.paramMap.get('opportunityLevel') === this.OpportunityEnum.Level.LEAD &&
			!this.opportunity.close_date
		) {
			return true;
		}
		return false;
	}

	canCloseForm() {
		if (this.opportunity && this.opportunity._id && !this.opportunity.close_date) {
			return true;
		}
		return false;
	}

	/**
	 * Subscription
	 */

	subscribeContactList() {
		return this.contact$.pipe(
			filter((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => !!state),
			map((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => {
				this.contacts = state.data.docs;
				return state;
			})
		);
	}

	subscribeOpportunity() {
		return this.organizationList$.pipe(
			filter(res => !!(res && res.data)),
			mergeMap(res => {
				this.organizationList = res.data;
				return this.opportunity$;
			}),
			skipWhile(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:
						// Se salvo la prima volta faccio il redirect per aggioranre l'url con il nuovo id
						if ((!this.opportunity || !this.opportunity._id) && opportunityState.data[0]._id) {
							this.router.navigate([
								this.router.url.replace(ROUTE_URL.newOpportunity, opportunityState.data[0]._id)
							]);
							this.idOpportunity = opportunityState.data[0]._id;
						}

						// Se cambia la tipologia di opportunity cambio anche il param
						// if (this.opportunity && this.opportunity.level !== opportunityState.data[0].level) {
						// 	const currentType = this.opportunity.level;
						// 	const newType = opportunityState.data[0].level;
						// 	this.opportunityLevel = newType;
						// 	this.router.navigate([this.router.url.replace(currentType, newType)]);
						// }

						this.opportunity = opportunityState.data[0];
						this.setFormFromRemoteData();
						break;

					case OpportunityActionEnum.ERROR:
						this.utilService.showDialog('opportunity.save_error');
						break;
				}
			})
		);
	}

	subscribeCurrencyFields() {
		const subscribeKey: string[] = ['budget_amount', 'actual_value'];
		const subscribe: Observable<number>[] = [];
		subscribeKey.forEach(i => {
			subscribe.push(this.form.get(i).valueChanges.pipe());
		});
		return merge(...subscribe).pipe(
			skipWhile(data => !data),
			map(data => {
				subscribeKey.forEach(i => {
					const value = this.form.get(i).value;
					if (value) {
						// Converto la stringa in numero non formattato
						let formattedValue: any = this.parseCurrency(value);
						// Formatto con thousandPipe
						formattedValue = this.thousandPipe.transform(formattedValue);
						// Setto il valore
						this.form.get(i).setValue(formattedValue, { emitEvent: false });
					}
				});
			})
		);
	}

	/**
	 * Dialog
	 */

	openDialogSelectOrganization() {
		const dialogRef: MatDialogRef<DialogSelectOrganizationComponent> = this.dialog.open(
			DialogSelectOrganizationComponent,
			{
				disableClose: true,
				panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
			}
		);
		dialogRef.afterClosed().subscribe((organization: OrganizationStateModel) => {
			if (organization) {
				// console.log(organization);
				// Aggiorno l'organizzazione selezionata
				this.selectedOrganization = organization;
				// Popolo il field del form
				this.updateFormFieldValue('organization', organization.business_name);

				// Aggiorno la lista di referent
				this.referentList = organization.contact_list;
				// Tolgo il riferimento al vecchio valore
				if (this.opportunity) {
					this.opportunity.contact_id = null;
					this.opportunity.contact_name = null;
				}
				// Svuoto il field del form
				this.updateFormFieldValue('contact', null);
			}
		});
	}

	openDialogSelectAssegnee() {
		const dialogRef: MatDialogRef<DialogSelectAssegneeComponent> = this.dialog.open(DialogSelectAssegneeComponent, {
			disableClose: true,
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe((assegnee: UserDetailModel) => {
			if (assegnee) {
				this.selectedAssegnee = assegnee;
				this.updateFormFieldValue(
					'assegnee',
					// `${assegnee.username} - ${this.utilService.getUserNameFormatted(assegnee)}`
					`${this.utilService.getUserNameFormatted(assegnee)}`
				);
			}
		});
	}

	openDialogCloseOpportunity() {
		const dialogRef: MatDialogRef<DialogCloseOpportunityComponent> = this.dialog.open(
			DialogCloseOpportunityComponent,
			{
				disableClose: true,
				data: { opportunityLevel: this.route.parent.snapshot.paramMap.get('opportunityLevel') }
			}
		);
		dialogRef.afterClosed().subscribe((res: OpportunityEnum.Status.Outcome) => {
			if (res) {
				const newOpportunity = _.cloneDeep(this.opportunity);
				newOpportunity.status_code = res;
				newOpportunity.close_date = moment().valueOf();
				this.store.dispatch(OpportunityStateAction.save(new BaseState([newOpportunity])));
			}
		});
	}

	openDialogOrganizationContact() {
		if (this.selectedOrganization) {
			const dialog = this.dialog.open(DialogContactDetailComponent, {
				data: {
					title: this.sentencecasePipe.transform(this.translateService.instant('contact.add_new')),
					organizationCodeItem: this.selectedOrganization.code_item,
					canEdit: true
				},
				panelClass: ['dialog-medium', 'michelangelo-theme-dialog'],
				minHeight: '32vh',
				disableClose: true
			});
			dialog.afterClosed().subscribe((contactToSave: ContactPouchModel) => {
				if (contactToSave) {
					const updatedOrganization = _.cloneDeep(this.selectedOrganization);
					const guid = this.loader.guid();
					if (!contactToSave._id) {
						contactToSave._id = `contact${ConfigurationCustomerAppStructure.noSqlDocSeparator}${guid}`;
					}
					if (!contactToSave.code_item) {
						contactToSave.code_item = guid;
					}
					if (!updatedOrganization.contact_list) {
						updatedOrganization.contact_list = [];
					}
					updatedOrganization.contact_list.push(contactToSave);

					// Aggiorno la lista di referent e seleziono il contatto aggiunto
					this.referentList = updatedOrganization.contact_list;
					this.form.get('contact').setValue(contactToSave);
					this.form.get('contact_id').setValue(contactToSave._id);

					this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
				}
			});
		}
	}

	openDialogActionList() {
		const dialog = this.dialog.open(DialogActionListComponent, {
			data: {
				modalTitle: this.sentencecasePipe.transform(this.translateService.instant('action.show')),
				opportunity: this.opportunity,
				contact: this.form.get('contact').value
			},
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog'],
			minHeight: '32vh',
			disableClose: true
		});
		dialog.afterClosed().subscribe((opportunityToSave: OpportunityPouchModel) => {
			if (opportunityToSave) {
				// this.updateOpportunity(opportunityToSave);
			}
		});
	}

	/**
	 * Utilities
	 */

	formatCurrency(currency: number): string {
		return this.thousandPipe.transform(currency);
	}

	parseCurrency(currency: string): number {
		// cancello i punti (divisore migliaia)
		currency = currency.replace(/\./g, '');
		// Sostituisco le virgole con i punti
		currency = currency.replace(/\,/g, '.');
		return parseInt(currency, 10);
	}

	resetSelectField(event, controlName: string) {
		this.form.get(controlName).setValue('');
		event.stopPropagation();
	}

	changeStatusOpportunityTo(level: OpportunityEnum.Level) {
		let opportunity: OpportunityPouchModel = _.cloneDeep(this.opportunity);
		opportunity.level = level;
		this.store.dispatch(OpportunityStateAction.save(new BaseState([opportunity])));
	}

	getReferentDescription(referent) {
		let text = '';
		if (referent.full_name || referent.first_name || referent.last_name) {
			text += referent.full_name || `${referent.first_name} ${referent.last_name}`;
		}
		if (referent.email) {
			text += (text ? ' - ' : '') + referent.email;
		}
		if (referent.role) {
			text += (text ? ' - ' : '') + referent.role;
		}
		return text;
	}
}
