import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { NewsModel } from '../../model/news.model';
import { NewsListActionEnum, NewsListStateAction } from './news-list.actions';

export namespace NewsListStateReducer {
	export const initialState: BaseStateModel<NewsModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(NewsListStateAction.loadAll, state => state),
		on(NewsListStateAction.saveAll, (state, news) => news),
		on(NewsListStateAction.update, (state, news) => news),
		on(NewsListStateAction.reset, () => initialState),
		on(NewsListStateAction.error, (state, news) => ({
			data: state ? state.data : null,
			type: news.type
		}))
	);

	export function reducer(state: BaseStateModel<NewsModel[]>, action: TypedAction<NewsListActionEnum>) {
		return _reducer(state, action);
	}
}
