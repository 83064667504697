import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// model
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { TicketModel } from '../../model/lib.model';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../store';
import { TicketCenterTicketDetailStoreAction } from '../../store/ticket/ticket-detail/ticket-detail.action';

// misc
import { Observable } from 'rxjs';
import { DialogTicketDetailComponent } from '../../widget/dialog/dialog-ticket-detail/dialog-ticket-detail.component';
import { take } from 'rxjs/operators';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

@Injectable({
  providedIn: 'root',
})
export class TicketCenterMainService {

  user$: Observable<BaseStateModel<any>> = this.store.select(StateFeature.getUserStore);
  user: UserDetailModel;

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
  ) {
    this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
  }

  dialogCreateNew(e?: TicketModel<any>) {
    this.store.dispatch(TicketCenterTicketDetailStoreAction.update(new BaseState(e)));
    const dialogRef: MatDialogRef<DialogTicketDetailComponent> = this.dialog.open(DialogTicketDetailComponent, {
      data: {
        ticket: e ? e : null,
        user: this.user
      },
      width: '80vw'
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.store.dispatch(TicketCenterTicketDetailStoreAction.reset());
    });
  }

}
