export interface BasePouchModel {
	_id?: string;
	_rev?: string;
	valid?: boolean;
	type?: string;
    source?: string;
	date_creation?: number;
}

export interface ListDataPouchModel<T extends BasePouchModel | BodyTablePouchModel | BodyTableStandardPouchModel> {
	docs: T[];
}

export interface HeaderRecordPouchModel extends BasePouchModel {
	cancellation?: string;
	code_company?: string;
	code?: string;
	valid: boolean;
}

export interface HeaderTablePouchModel<T extends BodyTablePouchModel | BodyTableStandardPouchModel>
	extends BasePouchModel {
	values: T[];
}

export interface BodyTablePouchModel {
	code_item?: string;
	description?: string;
	description_short?: string;
	code_erp?: string;
	date_creation?: number;
	multilangRef?: string;
}

export interface BodyTableStandardPouchModel extends BodyTablePouchModel {
	description_extended: string;
}

export interface BaseListViewDataPouchModel {
	total_rows: number;
	offset: number;
	rows: any[];
}

export interface ListViewDataPouchModel<T> extends BaseListViewDataPouchModel {
	rows: BaseViewPouchModel<T>[];
}

export interface AllDocsDataPouchModel<T> extends BaseListViewDataPouchModel {
	rows: BaseAllDocsPouchModel<T>[];
}

export interface BaseViewObjectPouchModel {
	id: string;
	key: string | number | (string | number)[];
	value: any;
}

export interface BaseViewPouchModel<T> extends BaseViewObjectPouchModel {
	value: T;
}

export interface BaseAllDocsPouchModel<T> extends BaseViewObjectPouchModel {
	value: { _rev: string };
	doc?: T;
}
