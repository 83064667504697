import { createReducer, on } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { ForecastStateAction, ForecastActionEnum } from './forecast.action';
import * as ForecastModel from '../../model/forecast.model'

export namespace ForecastStateReducer {
	export const initialState: BaseStateModel<ForecastModel.Document> = null;

	const _reducer = createReducer(
		initialState,
		on(ForecastStateAction.load, state => state),
		on(ForecastStateAction.update, (state, param) => param),
		on(ForecastStateAction.saveComplete, (state, param) => param),
		on(ForecastStateAction.reset, () => initialState),
		on(ForecastStateAction.error, (state, param) => ({
			data: state ? state.data : null,
			type: param.type
		}))
	);

	export function reducer(
		state: BaseStateModel<ForecastModel.Document>,
		action: TypedAction<ForecastActionEnum>
	) {
		return _reducer(state, action);
	}
}
