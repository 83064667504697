import { InjectionToken } from '@angular/core';
import { RxStompConfig } from '@stomp/rx-stomp';

export const APP_CONFIG_TOKEN: InjectionToken<string> = new InjectionToken<
	string
>('APP_CONFIG_TOKEN');

export const CUSTOM_PROVIDE_LOADER: InjectionToken<string> = new InjectionToken<
	string
>('CUSTOM_PROVIDE_LOADER');


export const CUSTOM_TANSLATE_SERVICE: InjectionToken<string> = new InjectionToken<
	string
>('CUSTOM_TANSLATE_SERVICE');

export interface IMediaCenterConfigModel {
  be_url: string;
  suffix?: string;
  rxStomp?: {
    configuration: RxStompConfig
  }
}

export interface IMediaCenterConfig {
	media_center: IMediaCenterConfigModel;
}

export interface IMediaCenterAppConfig {
	mc_config: IMediaCenterConfigModel;
	token: string;

	initializeConfig(arg0: { [key: string]: any }): void;
}
