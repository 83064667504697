import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from '../../../model/structure/AbstractListModel';

enum PaginationActionType {
	FIRST = 'FIRST',
	PREVIOUS = 'PREVIOUS',
	NEXT = 'NEXT',
	LAST = 'LAST'
}

@Component({
	selector: 'pouch-paging-bar',
	templateUrl: './pouch-paging-bar.component.html',
	styleUrls: ['./pouch-paging-bar.component.scss']
})
export class PouchPagingBarComponent implements OnInit {
	@Input() pagination: Pagination;
	@Output() change = new EventEmitter<Pagination>();
	@Input() data: any[];

	constructor() {}

	ngOnInit() {}

	changePageSize(size: number) {
		this.pagination.page_size = size;
		this.change.emit(this.pagination);
	}

	changePageNumber(actionType: string) {
		switch (actionType) {
			case PaginationActionType.FIRST:
				this.pagination.page_current = 1;
				break;
			case PaginationActionType.PREVIOUS:
				this.pagination.page_current--;
				break;
			case PaginationActionType.NEXT:
				this.pagination.page_current++;
				break;
			case PaginationActionType.LAST:
				this.pagination.page_current = -1;
				break;
		}
		// al momento non è possibile navigare fino all'ultima pagina, manca il total count
		this.change.emit(this.pagination);
	}
}
