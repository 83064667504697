import { LanguageItem } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../service/pouchdb/model/pouch-base.model';

export interface InformativePageModel extends BodyTablePouchCustomModel {
	language_list?: InformativePageLangItemModel[];
	document_type?: string;
}

export interface InformativePageLangItemModel extends LanguageItem {
	title: string;
	subtitle: string;
	body: string;
	document_type?: string;
}
