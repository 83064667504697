import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'emoji-response',
	templateUrl: './emoji-response.component.html',
	styleUrls: ['./emoji-response.component.scss']
})
export class EmojiResponseComponent implements OnInit {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() message: string;
	@Input() hasError = false;
	@Input() hasBackButton = true;

	constructor(private router: Router) {}

	ngOnInit() {}

	goToHome() {
		this.router.navigate([ROUTE_URL.private]);
	}
}
