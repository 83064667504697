import {Component, Input, Output, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import { 
	AngularSpin8CoreUtilTranslateService, 
	OrderStateModel,
	UserDetailModel,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-spin8-core';
import { ArticleListCategoryTreeRecursionBase } from '../../../../shared/directives/article-list-category-tree-recursion-base.directive';
import { ArticleCheckoutTree, ArticleListCategoryTreeRecursionBaseModel, ArticleDescriptionItem, ArticlePouchModel, DivisionPouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import { OrderActionEnum } from '../../../../state/order/order.actions';
import { ArticleActionEnum } from '../../../../state/article/article.actions';
import { UtilCategoryListService } from '../../../../service/util/util-category-list.service';
import { ArticleTableConfigurationAction } from '../../../../model/article.model';
import * as ConfigurationCategory from '../../../../constants/category.constant';
import { AppUtilService } from '../../../../service/util/app-util.service';
import * as ConfigurationCustomerOrder from '../../../../constants/configuration-customer/order/order.constant';
import * as ConfigurationCustomerContact from "../../../../constants/configuration-customer/contact/contact.constant";
import * as ConfigurationCustomerPrice from "../../../../constants/configuration-customer/price/price.constant";

@Component({
	selector: 'article-list-category-tree-recursion-order',
	templateUrl: './article-list-category-tree-recursion-order.component.html',
	styleUrls: ['./article-list-category-tree-recursion-order.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleListCategoryTreeRecursionCatalog extends ArticleListCategoryTreeRecursionBase implements OnInit, OnDestroy {

	@Input() set localListHandlerCategoryTreeConfiguration(e: ArticleListCategoryTreeRecursionBaseModel<ArticleCheckoutTree>) {
		if (e && e.data && e.data.length) {
			if (e.level && e.level === 1) {
				this.initSetRecursiveCategorySectionListLevel(e);
			} else {
				this._localListHandlerCategoryTreeConfiguration = e;
				this.subscribeManagerInit();
			}
		}
	}
	_localListHandlerCategoryTreeConfiguration: ArticleListCategoryTreeRecursionBaseModel<ArticleCheckoutTree>;
	@Input() _localListHandlerWidgetWrapperColumnList;
	@Input() configurationAction: ArticleTableConfigurationAction;

	@Output() onDeleteArticle: EventEmitter<ArticlePouchModel | ArticleDescriptionItem> = new EventEmitter();

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private utilCategryListService: UtilCategoryListService,
		private utilService: AppUtilService,
	) {
		super(utilTranslateService);
	}

	ngOnInit() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res.data;
		});
	}



	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	// subscribe
	initMandatoryData(): Observable<void> {
		return this.order$.pipe(
			filter((store: BaseStateModel<OrderStateModel>) => store && store.type !== OrderActionEnum.LOAD),
			mergeMap((store: BaseStateModel<OrderStateModel>) => {
				switch (store.type) {
					case OrderActionEnum.UPDATE:
						this.order = store.data;
						break;
					case OrderActionEnum.COMPLETED:
						this.order = store.data;
						break;
				}
				return this.articleList$;
			}),
			filter((store: BaseStateModel<ArticlePouchModel[]>) => store && store.type !== ArticleActionEnum.LOAD_FROM_RECAP),
			map((store: BaseStateModel<ArticlePouchModel[]>) => {
				switch (store.type) {
					case ArticleActionEnum.UPDATE:
						this.articleList = store.data;
						if (
							this._localListHandlerCategoryTreeConfiguration.level &&
							this._localListHandlerCategoryTreeConfiguration.level === 1 &&
							this.order &&
							this.order.product_list &&
							this.order.product_list.length &&
							this.order.header.status === OrderStatusEnum.DRAFT
						) {
							this.setRootCategoryArticleMetaInformation();
						}
						break;
				}
			})
		);
	}

	setRootCategoryArticleMetaInformation() {
		for (const category of this._localListHandlerCategoryTreeConfiguration.data) {
			category.metaInformation =
				this.utilCategryListService.returnCategoryArticleMetaInformation(
					category.code_item,
					this.order,
					this.articleList,
					this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list).division
				);
		}
	}

	async initSetRecursiveCategorySectionListLevel(e: ArticleListCategoryTreeRecursionBaseModel<ArticleCheckoutTree>) {
		if (ConfigurationCustomerOrder.categorySectionList && ConfigurationCustomerOrder.categorySectionList.length > 0) {
			e.data = await this.setRecursiveCategorySectionListLevel(e.data);
		}
		this._localListHandlerCategoryTreeConfiguration = e;
		this.subscribeManagerInit();
	}

	setRecursiveCategorySectionListLevel(e: ArticleCheckoutTree[]): Promise<ArticleCheckoutTree[]> {
		return new Promise(async resolve => {
			try {
				for (let category of e) {
					if (
						!(category.category_list && category.category_list.length > 0) &&
						(category.article_list && category.article_list.length > 0)
					) {
						category =
							await ConfigurationCategory.returnCategorySectionListLevel(
								category,
								ConfigurationCustomerOrder.categorySectionList
						);
					} else {
						category.category_list = await this.setRecursiveCategorySectionListLevel(category.category_list);
					}
				}
				resolve(e);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

}
