import { Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ITdDataTableColumnCustom } from "@saep-ict/pouch_agent_models";

const injector = Injector.create({
    providers: [{provide: TranslateService, deps: []}]
})

const translate = injector.get(TranslateService)

export const baseColumns: ITdDataTableColumnCustom[] = 
[
    {
        name: 'id',
        label: 'id',
        width: 50,
        sortable: true
    },
    {
        name: 'avatar',
        label: '',
        width: 80
    },
    {
        name: 'username',
        label: 'email',
        sortable: true
    },
    {
        name: 'full_name',
        label: 'full name'
    },
    {
        name: 'context_application_list',
        label: 'has access to'
    },
    {
        name: 'is_active',
        label: 'user status',
        numeric: true,
        width: 100
    }
];