import { Component, OnDestroy, ViewChild } from '@angular/core';
import { AttachmentListManager, BaseStateModel, SubscribeManagerService, BaseState, DialogConfirmComponent, SideBarPositionValues } from '@saep-ict/angular-core';
import { filter, mergeMap, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import { CategoryListWrapperComponent } from '../../../widget/backoffice/category-list-wrapper/category-list-wrapper.component';
import * as CategoryEnum  from '../../../enum/category.enum';
import { UtilCategoryListService } from '../../../service/util/util-category-list.service';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { LocalListHandlerBaseModel,Category, CategoryMap, ArticlePouchModel, AttachedFile, LanguageItem } from '@saep-ict/pouch_agent_models';
import { MatDialog } from '@angular/material/dialog';
import { DialogUploadFileComponent } from '../../../widget/dialog/dialog-upload-file/dialog-upload-file.component';
import { CategoryDetailAction, CategoryDetailActionEnum } from '../../../state/category-detail/category-detail.actions';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import { StoreUtilService } from '../../../service/util/store-util.service';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import * as CategoryModel from '../../../model/category-list.model';
import { UtilAttachmentListManager, SentencecasePipe } from '@saep-ict/angular-core';
import { AppUtilService } from '../../../service/util/app-util.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';

@Component({
	selector: 'category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class CategoryListComponent implements OnDestroy {
	@ViewChild(CategoryListWrapperComponent) categoryListWrapperComponent: CategoryListWrapperComponent;

	categoryList$: Observable<BaseStateModel<CategoryMap>> = this.store.select(StateFeature.getCategoryListState);
	brandList: Category[] = [];

	categoryDetail$: Observable<BaseStateModel<Category>> = this.store.select(StateFeature.getCategoryDetailState);
	categoryDetailImageUploadPayload: CategoryModel.CategoryDetailUploadPayload;

	lineList: Category[] = [];
	leafList: ArticlePouchModel[] = [];
	categoryTree: Category[];
	categoryListLevel: CategoryEnum.Level = CategoryEnum.Level.BRAND;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'data', observable: this.subscribeData() }
	];

	configurationCustomerArticle = ConfigurationCustomerArticle;
	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	categoryEnum = CategoryEnum;

	sideBarPositionValues = SideBarPositionValues;

	listPageBaseData: LocalListHandlerBaseModel<Category | ArticlePouchModel>;

	attachmentListManagerConfiguration: AttachmentListManager.Configuration<AttachedFile> = {
		localListHandler: null,
		page: null,
		upload: { // TODO: da utilizzare solo dopo aver esternalizzato i metodi fuori dal componente attachment-list-manager
			multiple: true,
			fileNameRegex: /^[a-z0-9_. -]+$/i,
			fileNameCharactersAccepted: "a-z 0-9 . _ -"
		},
		privilege: null,
		pathUrl: 'category',
		pathQueryParam: null
	};

	constructor(
		private store: Store,
		private utilCategoryListService: UtilCategoryListService,
		private dialog: MatDialog,
		private utilStoreService: StoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
		private utilAttachmentListManager: UtilAttachmentListManager,
		private utilService: AppUtilService,
		public translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue');
		this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue_categories');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['catalogue','catalogue_categories']);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<ArticlePouchModel[]>(this.articleList$).subscribe(e => {
			this.articleList = e.data;
		});
		this.utilStoreService.retrieveSyncState<CategoryMap>(this.categoryList$).subscribe(e => {
			this.categoryTree = e.data.tree;
			this.brandList = e.data.tree[0].category_list;
			this.listPageBaseData = {
				pagination: {
					pageSize: this.contextApplicationItemCodeEnum.BACKOFFICE? 25 : 10,
					pageIndex: null,
					length: null
				},
				filters: {
					localSearchText: {
						value: null,
						key_list: [
							'language_list.description',
						]
					}
				},
				sort: {
					name: 'brand',
					order: TdDataTableSortingOrder.Ascending
				},
				data: this.brandList
			};
		});
	}

	subscribeData(): Observable<void> {
		return this.categoryDetail$.pipe(
			filter((e: BaseStateModel<Category>) => !!(e)),
			mergeMap(async (e: BaseStateModel<Category>) => {
				switch (e.type) {
					case CategoryDetailActionEnum.ERROR:
						throw new Error(CategoryDetailActionEnum.ERROR);
					case CategoryDetailActionEnum.UPDATE:
						await this.changeCategoryImage(this.categoryDetailImageUploadPayload, e.data);
						break;
					case CategoryDetailActionEnum.SAVE_UPDATE:
						this.attachmentListManagerConfiguration.pathQueryParam = null;
						this.store.dispatch(CategoryDetailAction.reset());
						this.snackBar.open(this.sentencecasePipe.transform(this.translateService.instant('category.update_success')), null, {
							duration: 3000
						});
						break;
				}
			})
		);
	}

	showChildrenCategoryList(row: Category) {
		let _childrenList: (Category | ArticlePouchModel)[];
		const category = row;
		if (category.children?.length) {
			switch (category.level) {
				case CategoryEnum.Level.BRAND:
					// Show line
					this.lineList = category.category_list;
					_childrenList = this.lineList;
					this.categoryListLevel = CategoryEnum.Level.LINE;
					break;
				case CategoryEnum.Level.LINE:
					// Show leaf
					this.leafList = this.utilCategoryListService.returnArticleListFilteredByCategoryList(
						this.articleList,
						[category.code_item]
					);
					_childrenList = this.leafList;
					this.categoryListLevel = CategoryEnum.Level.LEAF;
					break;
			}
			this.categoryListWrapperComponent.updateListPageBaseData(_childrenList);
		}
	}

	showParentCategoryList() {
		let _parentList: (Category | ArticlePouchModel)[];
		switch (this.categoryListLevel) {
			case CategoryEnum.Level.LEAF:
				_parentList = this.lineList;
				this.leafList = [];
				this.categoryListLevel = CategoryEnum.Level.LINE;
				break;
			case CategoryEnum.Level.LINE:
				_parentList = this.brandList;
				this.lineList = [];
				this.categoryListLevel = CategoryEnum.Level.BRAND;
				break;
		}
		this.categoryListWrapperComponent.updateListPageBaseData(_parentList);
	}

	returnLanguangeAttachmentList(language_list: LanguageItem[]): Promise<AttachedFile[]> {
		const attachmentList: AttachedFile[] = [];
		return new Promise(resolve => {
			try {
				for (const languageItem of language_list) {
					for (const attachment of languageItem.image_list) {
						attachmentList.push(attachment)
					}
				}
				resolve(attachmentList);
			} catch(err) {
				throw new Error(err);
			}
		});
	}

	async changeCategoryImage(e: CategoryModel.CategoryDetailUploadPayload, category: Category): Promise<void> {
			try {
				if (e.category.code_item === category.code_item) {
					this.attachmentListManagerConfiguration.pathQueryParam = category.code_item;
					let res;
					switch(e.changeType) {
						case CategoryEnum.ChangeImageType.CREATE:
							res =
								await this.utilAttachmentListManager.postAttachmentList(
									[e.file],
									this.attachmentListManagerConfiguration
								);
							// preparazione payload per il salvataggio del documento nosql
							category.language_list[0].image_list[0] = {
								id: null,
								name: res[0].name,
								alt: res[0].name,
								data: null,
								bucket_link: null
							};
							this.utilService.deleteEmptyProperties(category.language_list[0].image_list[0]);
							break;
						case CategoryEnum.ChangeImageType.DELETE_ALL:
							res =
								await this.utilAttachmentListManager.deleteAttachmentList(
									await this.returnLanguangeAttachmentList(category.language_list),
									this.attachmentListManagerConfiguration
								);
							// preparazione payload per il salvataggio del documento nosql
							category.language_list[0].image_list = [];
							break;
					}

					// salvataggio attraverso data bind dei riferimenti all'immagine anche per la categoria appartenente alla
					// lista annidata
					e.category.language_list[0].image_list = category.language_list[0].image_list;

					this.store.dispatch(CategoryDetailAction.save(new BaseState(category)));
				}
				return;
			} catch (err) {
				throw new Error(err);
			}
	}

	openDialogEdit(e: Category) {
		const title = this.sentencecasePipe.transform(this.translateService.instant('general.image.add'));
		const dialogRef = this.dialog.open(DialogUploadFileComponent, {
			data: {
				title: title,
				fileEmitterType: 'files'
			},
			disableClose: true,
			panelClass: ['dialog-normal', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.categoryDetailImageUploadPayload = {
					category: e,
					file: res,
					changeType: CategoryEnum.ChangeImageType.CREATE
				};
				this.store.dispatch(CategoryDetailAction.load({ id: e._id }));
			}
		});
	}

	openDialogDelete(e: Category) {
		const title = this.sentencecasePipe.transform(this.translateService.instant('general.image.remove'));
		const question = this.sentencecasePipe.transform(this.translateService.instant('general.image.remove_confirm'));
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question
			},
			disableClose: true,
			panelClass: ['dialog-normal', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.categoryDetailImageUploadPayload = {
					category: e,
					changeType: CategoryEnum.ChangeImageType.DELETE_ALL
				};
				this.store.dispatch(CategoryDetailAction.load({ id: e._id }));
			}
		});
	}
}
