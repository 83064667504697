import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { Injectable } from '@angular/core';
import { BaseStatusBarConfigService } from '@saep-ict/angular-spin8-core';
import { StatusBarConfigNew, StatusBarConfigNewBis, StatusBarElementNew } from '../../../model/status-bar-new.model';
import * as StatusBar from '../../../constants/configuration-customer/order/status-aggregation-map-status-bar.constant';
import _ from 'lodash';

@Injectable()
export class StatusBarOrderService extends BaseStatusBarConfigService {
	constructor() {
		super();
	}

	returnStatusBarList(c: StatusBarConfigNewBis, status: OrderStatusEnum): StatusBarConfigNew {
		const statusList: StatusBarElementNew[] = _.cloneDeep(StatusBar.statusList);
		const configuration: StatusBarConfigNew = {
			theme: null,
			condensed: c.condensed,
			steps: []
		};
		if (configuration.condensed) {
			const step: StatusBarElementNew = statusList.find(i => i.id === status);
			step.selected = true;
			configuration.steps = [step];
		} else {
			const statusStep: StatusBarElementNew = statusList.find(n => n.id === status);
			c.statusMap.forEach(i => {
				if (i.related_list.includes(status)) {
					const currentStep: StatusBarElementNew = statusList.find(n => n.id === status);
					if (!currentStep.goesInOveride) {
						const masterStep: StatusBarElementNew = statusList.find(n => n.id === i.state);
						masterStep.selected = true;
						configuration.steps.push(masterStep);
					} else {
						currentStep.selected = true;
						configuration.steps.push(currentStep);
					}
				} else {
					const masterStep: StatusBarElementNew = statusList.find(n => n.id === i.state);
					configuration.steps.push(masterStep);
				}
			});
		}
		return configuration;
	}

}
