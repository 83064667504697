// model
import { BaseStateModel } from '@saep-ict/angular-core';
import { ITicketCenterConfigModel } from '../../model/lib-app-config.interface';

// store
import { createAction, props } from '@ngrx/store';

export enum TicketCenterLibConfigurationStoreEnum {
	UPDATE = '[ticket-center-configuration] Update',
	LOAD = '[ticket-center-configuration] Load',
	RESET = '[ticket-center-configuration] Reset',
  ERROR = '[ticket-center-configuration] Error'
}

export namespace TicketCenterLibConfigurationStoreAction {
	export const update = createAction(TicketCenterLibConfigurationStoreEnum.UPDATE, props<BaseStateModel<ITicketCenterConfigModel>>());
	export const reset = createAction(TicketCenterLibConfigurationStoreEnum.RESET);
	export const error = createAction(TicketCenterLibConfigurationStoreEnum.ERROR, props<BaseStateModel<ITicketCenterConfigModel>>());
}
