/**
 * Restituisce una stringa contenente un blocco html di `ul`, `li`
*/
export const returnMessageCodeUl = (message: string, codeList: string[]): string => {
	for (let i = 0; i < codeList.length; i++) {
		switch (i) {
			case 0:
				message = `${message}<ul><li>${codeList[i]}</li>`
				break;
			default:
				message = `${message}<li>${codeList[i]}</li>`
				break;
		}
		if (i === codeList.length - 1) {
			message = `${message}</ul>`
		}
	}
	return message;
}