import { Component, OnDestroy } from '@angular/core';
import { BaseStateModel, SideBarPositionValues } from '@saep-ict/angular-core';
import * as fromState from '../../../state/index';
import { Store } from '@ngrx/store';
import { ConfigurationService } from '../../../service/rest/backoffice/configuration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../service/util/app-util.service';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { dataConfigurationList } from '../../../constants/configuration-customer/data-configuration/data-configuration.constant';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { DataConfigurationItemModel } from '../../../model/data-configuration.model';
import _ from 'lodash';

@Component({
	selector: 'data-configuration',
	templateUrl: './data-configuration.component.html',
	styleUrls: ['./data-configuration.component.scss'],
	providers: [SubscribeManagerService]
})
export class DataConfigurationComponent implements OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(fromState.StateFeature.getUserState);
	storeUser: BaseStateModel<UserDetailModel>;

	sideBarPositionValues = SideBarPositionValues;
	dataConfigurationList: DataConfigurationItemModel[];

	constructor(
		private configurationService: ConfigurationService,
		private utilService: AppUtilService,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private utilStoreService: StoreUtilService
	) {
		this.loadStaticData();
		this.dataConfigurationList =
			_.cloneDeep(
				dataConfigurationList(this.configurationService, this.snackBar))
				.filter(i => this.utilService.hasAtLeastOnePermission(this.storeUser, i.permission)
			);
		this.setRouteMetaInformation();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.storeUser = e;
		});
	}

	setRouteMetaInformation() {
		if (this.activatedRoute.snapshot.routeConfig.path === ROUTE_URL.categories) {
			this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue');
			this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(
				'catalogue_categories'
			);
			this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['catalogue']);
		} else {
			this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('configuration');
			this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['configuration']);
		}
	}

	onAttachmentResolverFileChange(e: any[], index: number) {
		this.dataConfigurationList[index].button.display = e.length !== 0;
		this.dataConfigurationList[index].upload.data = e;
	}
}
