import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidatorCustom } from '@saep-ict/angular-core';
import { LoginAuthRequestModel, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { AuthService } from '../../../../service/rest/auth.service';
import * as ConfigurationCustomerAppStructure from '../../../../constants/configuration-customer/app-structure/app-structure.constant';

/**
 * Contesti in cui viene utilizzato:
 * + /login (parte pubblica)
 * + /authentication/login (parte privata)
 */
@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	form: FormGroup;
	userLogin: LoginAuthRequestModel;
	hide = true;
	canAccessToRegistration: boolean =
		ConfigurationCustomerAppStructure.authenticationWrapper.can_access_to_registration;

	ROUTE_URL = ROUTE_URL;

	constructor(private router: Router, private authService: AuthService, private fb: FormBuilder) {
		this.createForm();
	}

	createForm() {
		this.form = this.fb.group({
			email: ['', FormValidatorCustom.email],
			password: ['', Validators.required]
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.userLogin = this.form.value;
			this.authService.login(this.userLogin);
		}
	}

	linkAccessToRegistration(): string {
		return ConfigurationCustomerAppStructure.authenticationWrapper.link_access_to_registration;
	}
}
