import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';

@Injectable()
export class AngularSpin8CoreStorefrontConfigurationService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		//path: '/public/configuration/'
    path: `/${returnApiUrlSegment(ApiType.PUBLIC)}/configuration/`
	})
	getConfiguration: IResourceMethodPromise<void, any>;
}
