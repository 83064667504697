import * as CategoryEnum  from "../../../enum/category.enum";
import * as CategoryModel from "../../../model/category-list.model";

export const categoryTabOnTopConfiguration: CategoryModel.Configuration = {
    debounceTime: 700,
    initialization: {
        active: true,
        selectLevelBy: {
            index: [0, 0]
        }
    },
    levelStyleList: [
        {
            styleType: CategoryEnum.TabOnTopStyleType.CHIP,
        }
    ]
}