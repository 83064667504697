import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { BaseStateModel } from '@saep-ict/angular-core';
import { UserActivationStateModel } from '@saep-ict/angular-spin8-core';
import { UserActivateStateAction, UserActivateActionEnum } from './user-activate.actions';

export namespace UserActivateStateReducer {
	export const initialState: BaseStateModel<UserActivationStateModel> = null;

	const _reducer = createReducer(
		initialState,
		on(UserActivateStateAction.load, state => state),
		on(UserActivateStateAction.update, (state, userActivate) => userActivate),
		on(UserActivateStateAction.reset, () => initialState),
		on(UserActivateStateAction.error, (state, userActivate) => userActivate),
		on(UserActivateStateAction.activate, state => state),
		on(UserActivateStateAction.set_password, state => state),
		on(UserActivateStateAction.completed, (state, userActivate) => userActivate)
	);

	export function reducer(
		state: BaseStateModel<UserActivationStateModel>,
		action: TypedAction<UserActivateActionEnum>
	) {
		return _reducer(state, action);
	}
}
