import { Component, Input } from '@angular/core';
import { OrderEnum, OrderPriceMap, OrderStateModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerPrice from '../../../constants/configuration-customer/price/price.constant';
import { UtilPriceService } from '../../../service/util/util-price.service';

@Component({
	selector: 'order-detail-price',
	templateUrl: './order-detail-price.component.html',
	styleUrls: ['./order-detail-price.component.scss']
})
export class OrderDetailPriceComponent {
	@Input() set order(e: OrderStateModel) {
		if (e && e.header) {
			this.currency = e.header.currency.description_short;
			this.orderPriceMap = new OrderPriceMap();
			this.orderPriceMap = this.utilPriceService.updateOrderPriceMap(e);
		}
	};
	orderPriceMap: OrderPriceMap = new OrderPriceMap();
	currency: string;
	objectConstructor = Object;

	orderEnum = OrderEnum;

	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(
		private utilPriceService: UtilPriceService,
	) {}

}
