import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import _ from "lodash";
import { OrderHeaderBase } from "./order-header-creation-field-map-base.constant";
import * as ConfigurationCustomerOrderDate from "../order-date.constant";

export const OrderHeaderExtraFieldList: FormControlMultipurposeModel.Item[] = [
	{
		name: 'customer_order_ref_code',
		on_form_template: true,
		label: 'order.field.customer_reference',
		type: FormControlMultipurposeEnum.ItemType.STRING,
		column_width: 3
	},
	{
		name: 'customer_order_ref_date',
		on_form_template: true,
		label: 'theme.order.field.customer_date',
		type: FormControlMultipurposeEnum.ItemType.DATE,
		column_width: 3,
		dateMax:
			ConfigurationCustomerOrderDate.addDays(ConfigurationCustomerOrderDate.customerOrderRefDateMaxDate),
		dateFilter: ConfigurationCustomerOrderDate.matDatepickerFilter.customer_date
	}
];

export const OrderHeader = (): FormControlMultipurposeModel.Item[] => {
	const orderHeader = _.cloneDeep(OrderHeaderBase);
	orderHeader.splice(2, 0, ...OrderHeaderExtraFieldList);
	return orderHeader;
}