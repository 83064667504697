<ng-container *ngIf="_localListHandlerCategoryTreeConfiguration">
	<ng-container *ngFor="let branch of _localListHandlerCategoryTreeConfiguration.data">
		<div class="mat-h{{_localListHandlerCategoryTreeConfiguration.level}}">
			<span *ngIf="
						(user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT && branch.level === 'root')
						|| (user.current_permission.context_application !== contextApplicationItemCodeEnum.AGENT && branch.level !== 'section');
						else sectionDescription">
				<!-- pipe `translate` necessaria per gestione label multingua nei soli articoli senza categoria -->
				{{
					utilTranslateService.getTranslationFromLanguage(branch.language_list).description |
					translate |
					lowercase |
					titlecase
				}}
			</span>
			<ng-template #sectionDescription>
				<span *ngIf="branch.article_list && branch.article_list.length > 0 && branch.description !== 'theme.article.field.products'">
					{{ branch.description | translate | sentencecase }}
				</span>
			</ng-template>
			<ng-container *ngIf="branch.metaInformation && branch.code_item">
				<span class="flex-span"></span>
				<span>
					{{ 'category.metaInformation.ordered_quantity' | translate | titlecase}}
					<strong>
						{{ branch['metaInformation'].ordered_quantity | utilPriceReturnItemValueFormattedPipe: 0 }}
					</strong>
				</span>
				<span>
					{{ 'theme.category.metaInformation.qty_free' | translate | titlecase}}
					<strong>
						{{ branch['metaInformation'].qty_free | utilPriceReturnItemValueFormattedPipe: 0 }}
					</strong>
				</span>
				<span>
					{{ 'category.metaInformation.free_sample' | translate | titlecase}}
					<strong>
						{{ branch['metaInformation'].free_sample | utilPriceReturnItemValueFormattedPipe: 0 }}
					</strong>
				</span>
				<span>
					{{ 'category.metaInformation.total' | translate | titlecase}}
					<strong>
						{{ branch['metaInformation'].total | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
						{{ order.header.currency.description_short }}
					</strong>
				</span>
			</ng-container>
		</div>
		<article-table-wrapper
			*ngIf="
				branch.article_list &&
				branch.article_list.length > 0 &&
				_localListHandlerCategoryTreeConfiguration &&
				_localListHandlerWidgetWrapperColumnList &&
				order &&
				organization &&
				configurationAction
			"
			[configuration]="{
				localListHandlerData: {
					data: [],
					dataSubset: branch.article_list,
					filters: _localListHandlerCategoryTreeConfiguration.filters,
					languageKey: _localListHandlerCategoryTreeConfiguration.languageKey,
					pageName: _localListHandlerCategoryTreeConfiguration.pageName,
					pagination: _localListHandlerCategoryTreeConfiguration.pagination,
					sort: _localListHandlerCategoryTreeConfiguration.sort,
					sortRemapObject: _localListHandlerCategoryTreeConfiguration.sortRemapObject,
					columnList: _localListHandlerWidgetWrapperColumnList
				},
				order: order,
				organization: organization,
				configurationAction: configurationAction

			}"
			(onArticleChange)="onArticleChange.emit($event)"
			(onDeleteArticle)="onDeleteArticle.emit($event)"
		>
		</article-table-wrapper>
		<article-list-category-tree-recursion-order
			*ngIf="branch.category_list && branch.category_list.length > 0"
			[localListHandlerCategoryTreeConfiguration]="{
				level: _localListHandlerCategoryTreeConfiguration.level + 1,
				data: branch.category_list,
				filters: _localListHandlerCategoryTreeConfiguration.filters,
				languageKey: _localListHandlerCategoryTreeConfiguration.languageKey,
				pageName: _localListHandlerCategoryTreeConfiguration.pageName,
				pagination: _localListHandlerCategoryTreeConfiguration.pagination,
				sort: _localListHandlerCategoryTreeConfiguration.sort,
				sortRemapObject: _localListHandlerCategoryTreeConfiguration.sortRemapObject
			}"
			[_localListHandlerWidgetWrapperColumnList]="_localListHandlerWidgetWrapperColumnList"
			[configurationAction]="configurationAction"
			[organization]="organization"
			(onArticleChange)="onArticleChange.emit($event)"
			(onDeleteArticle)="onDeleteArticle.emit($event)"
		>
		</article-list-category-tree-recursion-order>
	</ng-container>	
</ng-container>