import { Inject, Injectable, Optional } from '@angular/core';
import { IResourceActionInner, IResourceResponse, Resource, ResourceParams } from '@ngx-resource/core';

import { Store } from '@ngrx/store';
import { APP_CONFIG_TOKEN, BaseStateModel, ILoaderExceptionCall, ILoaderService, LOADER_SERVICE_TOKEN } from '@saep-ict/angular-core';
import { ISaepIctAngularSpin8CoreAppConfig } from '../../model/structure/lib-app-config.interface';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { UserDetailModel } from '../../model/permission/user.model';
import { StoreFeature } from '../../store';
import { filter } from 'rxjs/operators';
import { BucketManagerEnum } from '../../enum/bucket-manager.enum';
import { Router } from '@angular/router';
import { ROUTE_URL } from '../../constant/navigation/route/route-naming';
import { Api } from '../../constant/api/api-configuration.constant';

@Injectable()
@ResourceParams({
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT, DELETE',
		'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
	},
  removeTrailingSlash: false
})
export class AngularSpin8CoreBaseApiService extends Resource {
	@LocalStorage('authenticationToken') authenticationToken: string;
	loaderExceptions: ILoaderExceptionCall[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StoreFeature.getUserState);
	user: UserDetailModel;

	constructor(
    @Optional() @Inject(LOADER_SERVICE_TOKEN) protected loaderService: ILoaderService,
		@Inject(APP_CONFIG_TOKEN) protected appConfig: ISaepIctAngularSpin8CoreAppConfig,
    public store: Store<any>,
    private router: Router,
    private localStorageService: LocalStorageService
	) {
		super();
		this.appConfig['config$'].subscribe(config => {
				if (config && config.saepIctAngularSpin8Core && config.saepIctAngularSpin8Core.be_url) {
					let urlPrefix = config.saepIctAngularSpin8Core.be_url;
					if (config.saepIctAngularSpin8Core.suffix) {
						urlPrefix += config.saepIctAngularSpin8Core.suffix;
					}
					super.$setPathPrefix(urlPrefix);
				}
			});
      this.user$.pipe(filter(res => !!(res && res.data))).subscribe(res => {
        this.user = res ? res.data : null;
      });
	}

    // returnApiUrlSegment1 (apiType:string): string {
    // let apiVersion: string;
    // let apiId: string;
    // let apiKey: string;
    // switch(apiType) {
    //     case ApiType.PRIVATE:
    //         apiVersion = Api.privateApi.version;
    //         apiId = Api.privateApi.id;
    //         apiKey = Api.privateApi.key;
    //         this.$setHeaders({Key: apiKey})
    //         break;
    //     case ApiType.PUBLIC:
    //         apiVersion = Api.publicApi.version
    //         apiId = Api.publicApi.id;
    //         apiKey = Api.publicApi.key;
    //         this.$setHeaders({Key: apiKey})
    //         break;
    // }
    // return '/' + apiId + '/' + apiVersion;
    // };

	$restAction(options: IResourceActionInner): any {
		const exception =
			this.loaderExceptions &&
			this.loaderExceptions.find((exc) => {
				return (
					options.actionOptions.path === exc.path &&
					options.actionOptions.method === exc.method
				);
			});

		if (!exception && this.loaderService && this.loaderNeeded(options)) {
			const guid = this.loaderService.populateLoader(
				options.actionOptions.path
			);
			options.returnData = { id: guid };
		}

		const token = this.appConfig.token ? 'bearer ' + this.appConfig.token : 'token';

		this.$setHeaders({
			Authorization: token
		})
    const header = this.$getHeaders();
    header['X-API-Key'] = Api.key.key;
    this.$setHeaders(header);
		if (
      this.user &&
      this.user.current_permission &&
      this.user.current_permission.context_application &&
      this.user.current_permission.context_code
    ) {
      const headers = this.$getHeaders();
      headers['Context-Code'] = this.user.current_permission.context_code.code;
      headers['Context-Application'] = this.user.current_permission.context_application;
      this.$setHeaders(headers);
    }
		return super.$restAction(options);
	}

	$handleSuccessResponse(
		options: IResourceActionInner,
		resp: IResourceResponse
	): any {
		if (options.returnData && options.returnData.id && this.loaderService) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}
		return super.$handleSuccessResponse(options, resp);
	}

	$handleErrorResponse(
		options: IResourceActionInner,
		resp: IResourceResponse
	): any {
		if (options.returnData && options.returnData.id && this.loaderService) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}
    if (resp.status === 401) {
			this.localStorageService.clear('authenticationToken');
			// TOFIX: sostituire con this.authService.logout(); ma risolvere dipendenza circolare
			this.router.navigate([ROUTE_URL.authentication, ROUTE_URL.login]);
		}
		return super.$handleErrorResponse(options, resp);
	}

	loaderNeeded(o: IResourceActionInner): boolean {
		if (
			o &&
			o.actionAttributes &&
			o.actionAttributes.body &&
			o.actionAttributes.body.id_bucket &&
			(
				o.actionAttributes.body.id_bucket === BucketManagerEnum.BucketId.CATALOG_ARTICLE ||
				o.actionAttributes.body.id_bucket === BucketManagerEnum.BucketId.CATALOG_CATEGORY
			)
		) {
			return false;
		}
		return true;
	}

}
