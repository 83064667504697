<div class="status-info">
    <ng-container *ngFor="let step of _config.steps; let last = last;">
        <div
            class="status active"
            [ngClass]="{'active': step.selected}"
        >

            <mat-icon class="bordered rounded" [ngClass]="{'filled': step.selected}" *ngIf="step.iconCustom" [svgIcon]="step.icon" matTooltip='{{ step.label | translate | sentencecase }}'></mat-icon>
            <mat-icon class="bordered rounded" [ngClass]="{'filled': step.selected}" *ngIf="!step.iconCustom" matTooltip='{{ step.label | translate | sentencecase }}'>{{ step.icon }}</mat-icon>
            <span *ngIf="step.showLabel">{{ step.label | translate | sentencecase }}</span>
        </div>
        <div
            *ngIf="!last"
            class="separator"
        >
        </div>
    </ng-container>
</div>
