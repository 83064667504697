import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'sentencecase'
})
export class SentencecasePipe implements PipeTransform {
	transform(value: any) {
		if (!value) {
			return value;
		}
		const re: RegExp = /(^|[.!?]\s+)([a-z])/g;
		return value.toString().replace(re, function (match, separator, char) {
			return separator + char.toUpperCase();
		});
	}
}
