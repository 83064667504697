export namespace OpportunityEnum {
	export enum Type {
		RG = 'richiesta generica',
		RC = 'richiesta commerciale',
		RM = 'richiesta materiale',
		RN = 'richiesta newsletter',
		RE = 'richiesta eventi',
		RA = 'richiesta registrazione'
	}
	export enum TopicType {
		'ERP' = 'ERP',
		'WEB/MOBILE' = 'WEB/MOBILE',
		'E-Commerce' = 'E-Commerce',
		'APP Mobile' = 'APP Mobile',
		'Forza vendita' = 'Forza vendita',
		'Produzione' = 'Produzione',
		'Logistica' = 'Logistica',
		'Acquisti' = 'Acquisti',
		'Contabilità' = 'Contabilità'
	}
	export enum Level {
		LEAD = 'lead',
		PROSPECT = 'prospect',
		CLIENT = 'client'
	}
	export namespace Status {
		export enum Document {
			DRAFT = 'DRAFT'
		}
		export enum Outcome {
			Closed = 'CLOSED',
			Lost = 'LOST',
			Open = 'OPEN',
			Rejected = 'REJECTED',
			Won = 'WON'
		}
	}
}
