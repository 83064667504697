import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListSettingStoreAction, UtilStoreService } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService, ROUTE_URL } from '@saep-ict/angular-spin8-core';


export class LinkListClass {
	// TODO: consolidare il modello di item in qualcosa che possa gestire stringhe e oggetti strutturati (language_list)
	item: any;
	url: string;
	queryParam?: any;
}

@Component({
	selector: 'app-unordered-list',
	templateUrl: './unordered-list.component.html',
	styleUrls: ['./unordered-list.component.scss']
})
export class UnorderedListComponent implements OnInit {
	@Input() title;
	@Input() items: LinkListClass[];

	constructor(
		public router: Router,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private utilStoreService: UtilStoreService
	) {}

	ngOnInit() {}

	goTo(i: LinkListClass) {
		if (i.url === ROUTE_URL.catalog) {
			this.utilStoreService.serielizedStoreResetItem(
				ListSettingStoreAction.resetItem,
				ROUTE_URL.catalog
			);
		}
		this.router.navigate(
			[i.url],
			{ queryParams: i.queryParam }
		);
	}
}
