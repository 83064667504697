import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum, LinkCodeModel, LinkDetailModel, ROUTE_URL, TokenPayload, UserConstant, UserDetailModel, UserTypeContextModel } from '@saep-ict/angular-spin8-core';
import { OfflineDeviceService } from '@saep-ict/pouch-db';
import jwt_decode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { lastValueFrom, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CustomerAppConfig } from '../../customer-app.config';
import { courtesyRoute } from '../../router/courtesy-route.config';
import { StateFeature } from '../../state';
import { LanguageListStateAction } from '../../state/language-list/language-list.actions';
import { UserStateAction } from '../../state/user/user.actions';
import { AuthService } from '../rest/auth.service';
import { ContextManagerService } from '../structure/context-manager.service';
import { StoreUtilService } from '../util/store-util.service';
import { ContextType, UtilGuardService } from './util-guard/util-guard.service';
import * as Sentry from "@sentry/browser";

@Injectable()
export class AuthTokenGuard implements CanActivate {
	@LocalStorage('authenticationToken') authenticationToken: string;
	// TODO: capire se sia rimpiazzabile da currentContext
	@LocalStorage('link_code') link_code: LinkCodeModel;

	authState: boolean;
	user: UserDetailModel;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	loginContextCode$: Observable<BaseStateModel<ContextType>> = this.store.select(StateFeature.getLoginContextCodeState);
	urlToRedirectTo: string[];

	constructor(
		private router: Router,
		private authService: AuthService,
		private store: Store,
		private utilGuardService: UtilGuardService,
		@Inject(PLATFORM_ID) private platformId: string,
		private offlineDeviceService: OfflineDeviceService,
		protected appConfig: CustomerAppConfig,
		private contextManagerService: ContextManagerService,
		private utilService: AngularCoreUtilService,
		private utilStoreService: StoreUtilService
	) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		if (isPlatformServer(this.platformId)) {
			this.router.resetConfig([courtesyRoute, ...this.router.config]);
			this.router.navigate([ROUTE_URL.courtesy]);
			return false;
		}
		// language list
		this.store.dispatch(LanguageListStateAction.loadAll());
		if (!environment.mockup) {
			if (this.authenticationToken) {
				let contextState: ContextType;
				const userState = (await lastValueFrom(this.user$.pipe(take(1))))?.data;
				try {
					if (userState) {
						// Sentry: setto tag id con id dell'utente autenticato
						Sentry.configureScope(
							(scope) => { scope.setUser({id: this.user.id});
								scope.setTag('displayWidth', window.innerWidth);
								scope.setTag('displayHeight', window.innerHeight);
							})
						if (userState.current_permission && userState.current_permission.context_application) {
							contextState = (await lastValueFrom(this.loginContextCode$.pipe(take(1))))?.data;
						}
					}
				} catch (err) {
					console.log(err);
					this.authService.logout();
					return false;
				}

				if (userState && contextState) {
					return true;
				}
				// decodifico il token e lo piazzo nella variabile del localstorage
				const tk_decoded = jwt_decode(this.authenticationToken);
				this.authService.tokenPayload = new TokenPayload(tk_decoded);
				return await this.checkUserPermission(state);
			} else {
				return await this.contextManagerService.manageNotAuthenticated(state.url);
			}
		}
		return true;
	}

	async checkUserPermission(state: RouterStateSnapshot): Promise<boolean> {
		// INIT SELECTED DEVICE
		if (!this.offlineDeviceService.selectedDevice) {
			await this.offlineDeviceService.init();
		}
		try {
			this.authState = false;
			let contextList: UserTypeContextModel[];

			// retrieving user information
			this.user = await this.contextManagerService.retrieveUserInfo();

			await Promise.all(
				this.appConfig.config.couch.map(
					async x => (x.offline = await this.contextManagerService.retrieveConnectionState())
				)
			);
			//  start only generic db
			await this.contextManagerService.connectToGenericDb();
			if (!this.link_code) {
				contextList = this.utilGuardService.retrievePermissionRoute(this.user);
				if (contextList.length > 0) {
					this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
				} else {
					this.utilService.showDialog('Context list is not defined', 'OK');
					throw new Error('Context list is not defined');
				}

				if (this.utilGuardService.checkAmbiguousPermission(contextList)) {
					this.urlToRedirectTo = [`/${ROUTE_URL.authentication}/${ROUTE_URL.contextSelection}`];
				} else {
					await this.setUserContext(contextList[0], state.url).catch(() => {
						throw new Error('Cannot connect to couchdb');
					});
					await this.contextManagerService.retrieveAuxiliaryPermissionList();
				}
			}
			// set current permission by the context, code and general permission
			else if (this.link_code) {
				// retrieving Auxiliary Permission List
				await this.contextManagerService.retrieveAuxiliaryPermissionList();
				await this.prepareUserContext(state);
			}
			if (this.urlToRedirectTo) {
				this.router.navigateByUrl(this.urlToRedirectTo.join());
			}
			return this.authState;
		} catch (err) {
			console.error(err);
			this.authService.logout();
			return false;
		}
	}

	/**
	 * Compile currentContext variable which is then passed to the setUserContext method
	 *
	 * @param state - routerSnapshot containing the redirect url
	 */
	async prepareUserContext(state: RouterStateSnapshot): Promise<boolean> {
		const currentContext = this.utilGuardService.retrieveContextPermission(this.link_code.context);
		const contextLink = this.utilGuardService.checkUserContext(this.user, this.link_code.context);
		const currentLink = contextLink.currentContext.context_code_list.find((link: LinkDetailModel) => {
			return link.code === this.link_code.code;
		});

		if (!currentLink) {
			throw new Error('current code is invalid');
		}

		try {
			return await this.setUserContext(currentContext, state.url, this.link_code.code);
		} catch (err) {
			return false;
		}
	}

	/**
	 * @param context - current context to set in the user model
	 * @param url - User origin URL, to be used to redirect the user to the end of authguard
	 * @param explicitLink - current operator code of the user, if it doesn't exist retrieve the zero element of array
	 */
	async setUserContext(context: UserTypeContextModel, url: string, explicitLink?: string): Promise<boolean> {
		if (!explicitLink) {
			const contextLink = this.utilGuardService.checkUserContext(this.user, context.type);
			this.link_code = {
				context: context.type,
				code: contextLink.currentContext.context_code_list[0].code.toString()
			};
		}
		const statusInitContextDb = await this.contextManagerService.initContextDb(context.type);
		if (statusInitContextDb) {
			const sync = await this.contextManagerService.verifyLocalDbSync();
			if (!sync) {
				return true;
			}
		}
		this.utilGuardService.dispatchUserContext(context, this.user, explicitLink);

		// questo blocco era in checkUserPermission
		// spostato per evitare chiamate REST aventi token ma senza user.current_permission
		// valutare futuri problemi
		const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
			this.link_code,
			this.user
		);
		// If I don't have any permissions associated I throw an error that logout
		if (!currentPermission.length) {
			this.utilService.showDialog('Permission list is not defined', 'OK');
			throw new Error('Permission list is not defined');
		}
		this.user = await this.contextManagerService.returnUserCurrentPermission(
			this.link_code.context,
			this.link_code.code,
			currentPermission,
			this.user
		);
		this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
		// fine

		const res = await lastValueFrom(this.contextManagerService.initObservableState().pipe(take(1)));
		try {
			await this.contextManagerService.retrieveStateForContext(context.type, res[0] || res);
			// this.contextManagerService.destroy$.next(true);
			this.setRouteTree(context, url);
			return true;
		} catch (error) {
			// this.contextManagerService.destroy$.next(true);
			console.log(error);
			throw error;
		}
	}

	/**
	 * route management, hooking the routes for the authenticated user based on the context
	 *
	 * @param userTypeContext - current kind of context with linked data (route, action, state ecc...)
	 * @param url - url to redirect the navigation on the end of workflow
	 */
	setRouteTree(userTypeContext: UserTypeContextModel, url: string) {
		const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
			this.link_code,
			this.user
		);
		userTypeContext.route[0].children = this.utilGuardService.filterRouteWithPermission(
			userTypeContext.route[0].children,
			currentPermission
		);
		const user_entry = this.router.config.find(route => route.data && route.data['id'] === UserConstant.AUTHENTICATED_USER_ENTRY);
		if (!user_entry) {
			throw new Error('Route tree does not exist');
		}
		user_entry.children = userTypeContext.route;
		this.router.resetConfig(this.router.config);
		this.authState = true;
		if (
			this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2C &&
			this.utilStoreService.stateForeConcextResult.order &&
			this.authService.explicitLogin
		) {
			this.urlToRedirectTo = [`/${ROUTE_URL.cart}`];
			this.authService.explicitLogin = false;
		} else if (url === '/' && !this.appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C)) {
			this.urlToRedirectTo = [`/${ROUTE_URL.authentication}/${ROUTE_URL.login}`];
		} else {
			this.urlToRedirectTo = [url];
		}
	}
}
