<top-bar
    [goBackButton]=true
    [goBackPath]="backPath"
    [badgeContent]="'saep_ict_angular_spin8_core.status.order.' + (offer && offer.header && offer.header.status ? offer.header.status : offerStatusEnum.DRAFT) | translate | sentencecase"
>
    <ng-container left-side-content>
        <h2>{{ 'offer.detail' | translate | sentencecase }}</h2>
    </ng-container>
</top-bar>
<tab-wrapper [toExclude]="tabsToExclude">
    <ng-container main>
        <offer-detail-head></offer-detail-head>
    </ng-container>
</tab-wrapper>
