import { Component, OnInit, Input } from '@angular/core';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

@Component({
  selector: 'organization-table-heading',
  templateUrl: './organization-table-heading.component.html',
  styleUrls: ['./organization-table-heading.component.scss']
})
export class OrganizationTableHeadingComponent {

  @Input() organization: OrganizationPouchModel;
}
