export namespace ConfigurationUtil {
	export namespace Context {
		export interface NameBase<T> {
			AGENT?: T;
			B2C?: T;
			BACKOFFICE?: T;
			BACKOFFICE_ADMIN?: T;
			CRM?: T;
			PORTAL?: T;
		}
		export interface ApplicationSelector<T> extends ConfigurationUtil.Context.NameBase<T> {
			B2B?: T;
			PUBLIC?: T;
		}
		export interface CodeSelector<T> extends ConfigurationUtil.Context.NameBase<T> {
			ORGANIZATION?: T;
		}
	}

	export interface DocumentSelector<T> extends ConfigurationUtil.Context.CodeSelector<T> {
    DOCUMENT_BASE?: T;
    CONTEXT_CODE_BASE?: T;
		CONTACT?: T;
		OFFER?: T;
    DIVISION?: T,
    DESTINATION_BASE?: T;
    DESTINATION_LIST_EDIT?: T;
	}
}
