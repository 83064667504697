export namespace ArticleEnum {
  export namespace Status {
    export enum Stock {
      BU = 'ACTIVE',
      SU = 'LOW',
      ND = 'NOT_AVAILABLE'
    }
    export enum Available {
      AVAILABLE = 'V',
      LOW = 'G',
      NOT_AVAILABLE = 'R',
    }
  }
  export enum Sheet {
    ART = 'ART',
    LIN = 'LIN'
  }
}
