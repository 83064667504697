<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[placeholder]="'general.search' | translate | sentencecase"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="code" let-value="value" let-row="row">
			<p>
				<a
					*ngIf="showLinkToDetail; else noLinkToDetail"
					(click)="onItemSelect.emit(row)"
					href="javascript:void(0)"
				>
					<ng-container *ngTemplateOutlet="noLinkToDetail"></ng-container>
				</a>
			</p>
			<ng-template #noLinkToDetail>
				{{
					row.code_erp ?
					row.code_erp :
					(
						(
							row.code ||
							row.code_item
						)
						| guidFormatter
					)
				}}
				<ng-container *ngIf="row.code_erp">
					<br />
					<small class="cell-subtitle">
						{{ row.code | guidFormatter }}
					</small>
				</ng-container>
			</ng-template>
		</ng-template>
		<ng-template tdDataTableTemplate="description" let-row="row">
			<organization-table-heading
				[organization]="{'business_name': row.description || row.business_name, 'code_erp': row.code_erp}"
			>
			</organization-table-heading>
		</ng-template>
	</td-data-table>
	<ng-container [ngTemplateOutlet]="paginationAndActions"></ng-container>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
	<ng-container [ngTemplateOutlet]="paginationAndActions"></ng-container>
</ng-template>

<ng-template #paginationAndActions>
	<pagination-and-actions>
		<ng-container pagination *ngIf="localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0">
			<mat-paginator
					*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
					#matPaginator
					(page)="localListHandlerApplyFilter({ pagination: $event })"
					[length]="localListHandlerData.pagination.totalFilteredItemCount"
					[pageSize]="localListHandlerData.pagination.pageSize"
					[pageSizeOptions]="[5, 10, 25, 100]"
			>
			</mat-paginator>
		</ng-container>

		<ng-container actions>
			<button
					mat-fab
					color="primary"
					class="mat-fab-button__in-page"
					(click)="dialogCreateContextCodeItemEvent.emit(contextTypeAndApplicationLink.context_code_item)"
					[matTooltip]="
									('general.add_new' | translate | sentencecase) +
									' ' +
									(contextTypeAndApplicationLink.context_code_item.description | translate | sentencecase)
								"
					matTooltipPosition="left"
					*ngIf="localListHandlerWidgetWrapperCanCreate"
			>
				<mat-icon>add</mat-icon>
				<span class="label">{{
					('general.add_new' | translate | sentencecase) +
					' ' +
					(contextTypeAndApplicationLink.context_code_item.description | translate | sentencecase)
					}}</span>
			</button>
		</ng-container>
	</pagination-and-actions>
</ng-template>