<!-- <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<h2 mat-dialog-title>
		{{ data.title }}
		<button mat-icon-button [mat-dialog-close]="false">
			<mat-icon>clear</mat-icon>
		</button>
	</h2>
	<mat-dialog-content class="mat-typography">
		<mat-form-field>
			<textarea formControlName="text" matInput></textarea>
			<mat-hint *ngIf="form.controls.text.invalid && form.controls.text.touched">Enter text</mat-hint>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions align="end" *ngIf="!data.disabled">
		<button mat-button type="button" [mat-dialog-close]="false">
			{{ 'general.cancel' | translate | sentencecase }}
		</button>
		<button mat-raised-button color="primary" type="submit">{{ 'general.save' | translate | sentencecase }}</button>
	</mat-dialog-actions>
</form> -->

<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<mat-dialog-wrapper [title]="data.title | sentencecase">
		<ng-container content>
			<mat-form-field>
				<textarea formControlName="text" matInput rows="10"></textarea>
				<mat-hint *ngIf="form.controls['text'].invalid && form.controls['text'].touched">Enter text</mat-hint>
			</mat-form-field>
		</ng-container>

		<ng-container actions>
			<button mat-button type="button" [mat-dialog-close]="false">
				{{ 'general.cancel' | translate | sentencecase }}
			</button>
			<button mat-raised-button color="primary" type="submit">
				{{ 'general.save' | translate | sentencecase }}
			</button>
		</ng-container>
	</mat-dialog-wrapper>
</form>
