import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	BaseStateModel
} from '@saep-ict/angular-core';
import { ArticleRecap} from '../model/state/article-list-state.model';
import { UserDetailModel } from '../model/permission/user.model'

export namespace StateFeature {

	export const getArticleDescription = createFeatureSelector<BaseStateModel<ArticleRecap>>('article-description');
	export const getUserState = createFeatureSelector<BaseStateModel<UserDetailModel>>('user');
	
}
