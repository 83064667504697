<div class="td-paging-bar pouch-paging-bar" *ngIf="data">
	<span>{{ 'general.items_per_page' | translate | sentencecase }}</span>
	<mat-select [style.width.px]="50" [(ngModel)]="pagination.page_size" (ngModelChange)="changePageSize($event)">
		<mat-option *ngFor="let size of [5, 10, 20, 50]" [value]="size">
			{{ size }}
		</mat-option>
	</mat-select>
	<div class="td-paging-bar-navigation">
		<button
			class="td-paging-bar-first-page mat-icon-button mat-button-base"
			mat-icon-button=""
			type="button"
			(click)="changePageNumber('FIRST')"
			[disabled]="pagination.page_current == 1"
		>
			<span class="mat-button-wrapper">
				<mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
					skip_previous</mat-icon
				>
			</span>
			<div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
			<div class="mat-button-focus-overlay"></div>
		</button>
		<button
			class="td-paging-bar-prev-page mat-icon-button mat-button-base"
			mat-icon-button=""
			type="button"
			(click)="changePageNumber('PREVIOUS')"
			[disabled]="pagination.page_current == 1"
		>
			<span class="mat-button-wrapper">
				<mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
					navigate_before
				</mat-icon>
			</span>
			<div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
			<div class="mat-button-focus-overlay"></div>
		</button>
		<button
			class="td-paging-bar-next-page mat-icon-button mat-button-base"
			mat-icon-button=""
			type="button"
			(click)="changePageNumber('NEXT')"
			[disabled]="data.length < pagination.page_size"
		>
			<span class="mat-button-wrapper">
				<mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
					navigate_next</mat-icon
				>
			</span>
			<div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
			<div class="mat-button-focus-overlay"></div>
		</button>
	</div>
</div>
