import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// store
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

// misc
import { TranslateService } from '@ngx-translate/core';
import { AngularCoreUtilService, BaseStateModel, RestBaseMessageError, SentencecasePipe } from '@saep-ict/angular-core';

@Injectable({
  providedIn: 'root',
})
export class TicketCenterUtilService extends AngularCoreUtilService {

  constructor(
    public snackbar: MatSnackBar,
    public translate: TranslateService,
    public sentencecasePipe: SentencecasePipe,
    private store: Store<any>
  ) {
		super(snackbar, translate, sentencecasePipe);
  }

  // store
  returnStoreReducerError<T>(state: BaseStateModel<T>, param: BaseStateModel<T>): BaseStateModel<T> {
    param.data = state && state.data ? state.data : null;
    return param;
  }

  dispatchStoreActionError<T>(error: RestBaseMessageError, action: ActionCreator<any, (props?: any) => TypedAction<any>>) {
    const errorState = <BaseStateModel<T>> {
      errorMessage: error && error.body && error.body.message ? error.body.message : 'tc.error.generic'
    };
    this.store.dispatch(action(errorState));
  }

}
