import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import * as ConfigurationCustomerOrderDestination from '../order-destination.constant';
import * as _ from 'lodash';
import moment from 'moment';

// TODO: rivedere tutto il flusso dopo una gestione migliore dei fields associati ad oggetti contenenti metadati descrittivi
export const returnOrderHeaderFieldListWithValue = (
    creationFieldMap: FormControlMultipurposeModel.Item[],
    order: OrderStateModel
): Promise<FormControlMultipurposeModel.Item[]> => {
    return new Promise(resolve => {
        try {
            creationFieldMap = _.cloneDeep(creationFieldMap);
            for (const i of creationFieldMap) {
                switch(i.type) {
                    case FormControlMultipurposeEnum.ItemType.DATE:
                        i.value = moment(order.header[i.name]).format('DD/MM/yyyy');
                        break;
                    default:
                    i.value = (order.header[i.name] || order.header[i.name] === false) ? order.header[i.name] : '-';
                }
                // gestione temporanea di campi specifici
                switch(i.name) {
                    case 'goods_destination_code':
                        i.value =
                            order.header.goods_destination_object ?
                            ConfigurationCustomerOrderDestination.getFormattedAddress(order.header.goods_destination_object):
                            '-';
                        break;
                    case 'order_causal':
                        i.value =
                            order.header.order_causal_object && order.header.order_causal_object.description_short ?
                            order.header.order_causal_object.code_item + "-" + order.header.order_causal_object.description_short :
                            '-';
                        break;
                    case 'payment_code':
                        i.value =
                            order.header.payment_object && order.header.payment_object.description_short ?
                            order.header.payment_object.description_short :
                            '-';
                        break;
                    case 'shipping_method_code':
                        i.value =
                            order.header.shipping_method_code && order.header.shipping_method_code ?
                            order.header.shipping_method_object.description :
                            '-';
                        break;
                }
                i.value = (i.value || i.value === false) ? i.value : '-';
            }
            resolve(creationFieldMap);
        } catch(err) {
            throw new Error(err);
        }
    });
}