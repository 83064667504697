<app-page-header
	[title]="'cart.label' | translate | sentencecase"
	[backgroundImageUrl]="'theme.header_bg.cart.backgroundImageUrl' | translate"
></app-page-header>

<div class="cart__list">
	<div class="container">
		<div class="row">
			<div class="col-12" *ngIf="order?.product_list.length; else noProducts">
				<ng-container *ngFor="let article of articleList; let i = index">
					<mat-divider *ngIf="i"></mat-divider>
					<div class="row cart__item">
						<div class="col-md-2 col-lg-2 d-none d-lg-block">
							<article-image
								[image]="
									returnImageBucketLink(
										utilTranslateService.getTranslationFromLanguage(
											article.articleDescription.language_list
										),
										article
									)
								"
								[alt]="
									utilTranslateService.getImageWithLanguage(article) &&
									utilTranslateService.getImageWithLanguage(article).alt
										? utilTranslateService.getImageWithLanguage(article).alt
										: null
								"
								[name]="
									utilTranslateService.getImageWithLanguage(article) &&
									utilTranslateService.getImageWithLanguage(article).name
										? utilTranslateService.getImageWithLanguage(article).name
										: null
								"
								[link]="['/product/' + article.code_item]"
							>
							</article-image>
						</div>
						<div class="col-md-12 col-lg-3">
							<div class="cart__item__head">
								<a [routerLink]="['/product/' + article.code_item]" href="javascript:void(0)">
									<h3 class="cart__item__title">
										{{
											utilTranslateService.getTranslationFromLanguage(
												article.articleDescription.language_list
											).description | sentencecase
										}}
									</h3>
								</a>
								<button mat-button color="warn" (click)="changeQuantity(0, article)">
									{{ 'cart.product.remove' | translate | sentencecase }}
								</button>
							</div>
						</div>
						<div class="col-md-4 col-lg-2">
							<div class="cart__item__price">
								<span class="cart__item__label">
									{{ 'cart.product.price' | translate | sentencecase }}
								</span>
								<span class="cart__item__value">
									<b2c-article-price [article]="article"></b2c-article-price>
								</span>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<div class="cart__item__quantity">
								<span class="cart__item__label">
									{{ 'cart.product.quantity' | translate | sentencecase }}
								</span>
								<span class="cart__item__value">
									<b2c-quantity-selector
										[value]="article.input_quantity"
										[article]="article"
										[organization]="organization"
										(changeValue)="changeQuantity($event, article)"
									></b2c-quantity-selector>
								</span>
							</div>
						</div>
						<div class="col-md-4 col-lg-2">
							<div class="cart__item__total">
								<span class="cart__item__label">
									{{ 'cart.product.total' | translate | sentencecase }}
								</span>
								<p>
									<span
										class="cart__item__value cart__item__value--total"
										[innerHTML]="
											(
												returnArticleTotalPrice(article) |
												utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit
											) +
											' ' +
											configurationCustomerPrice.defaultCurrencySymbol
										"
									>
									</span>
									<br>
									<span
										class="cart__item__variant"
										*ngIf="organization?.organization_type === organizationTypeEnum.COMPANY"
										[innerHTML]="
											'(+ ' +
											('general.vat' | translate | uppercase) +
											' ' +
											(
												(
													utilPriceService.round(
														article.calculate_price_for_single_item *
														article.articlePrice.vat /
														100
													) *
													article.ordered_quantity
												) |
												utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit
											) +
											' ' +
											configurationCustomerPrice.defaultCurrencySymbol +
											')'
										"
									>
									</span>
								</p>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<div class="cart__subtotal">
	<div class="container">
		<div class="row cart__subtotal__content">
			<div class="col-12 mb-3">
				<span class="cart__subtotal__label">{{ 'cart.subtotal.label' | translate | uppercase }}</span>
			</div>
			<div class="col-12">
				<span
					class="cart__subtotal__value"
					[innerHTML]="
						(
							(
								orderPriceMap.article.value ?
									(
										organization?.organization_type === organizationTypeEnum.COMPANY ?
										orderPriceMap.article.value :
										orderPriceMap.article.value + utilPriceService.vatPrice.vatArticle
									):
									0
							) |
							utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit
						) +
						' ' +
						configurationCustomerPrice.defaultCurrencySymbol
					"
				>
				</span>
				<span
					class="cart__item__variant"
					*ngIf="organization?.organization_type === organizationTypeEnum.COMPANY"
					[innerHTML]="
						'(+ ' +
						('general.vat' | translate | uppercase) +
						' ' +
						(
							(
								utilPriceService.vatPrice.vatArticle
							) |
							utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit
						) +
						' ' +
						configurationCustomerPrice.defaultCurrencySymbol +
						')'
					"
				>
				</span>
			</div>
			<div class="col-12">
				<p class="cart__subtotal__message">
					{{ 'theme.order.subtotal.info.start' | translate | sentencecase }}
					<a [routerLink]="['/', ROUTE_URL.deliveryPolicy]" target="_blank">
						{{ 'theme.order.subtotal.info.end' | translate }}</a
					>
				</p>
			</div>
		</div>
	</div>
</div>

<div class="cart__action">
	<div class="container">
		<div class="row">
			<div class="col">
				<div class="cart__action__content">
					<!-- TODO: gestire eventuali classi e visiblità dei bottoni -->
					<form-button-bar
						[align]="mediaReplayService.deviceW > 768 ? 'right' : 'left'"
						[primaryButtonIsDisabled]="
							!(order && order.product_list && order.product_list.length) ||
							utilOrderService.orderDisabledViewConfirm
						"
						[primaryButtonLabel]="'general.forward_with_the_order' | translate | sentencecase"
						(primaryButtonClickEmitter)="router.navigate([PATH_URL.CART, ROUTE_URL.checkout])"
						[secondaryButtonIsDisabled]="utilOrderService.orderDisabledViewConfirm"
						[secondaryButtonLabel]="'general.back_catalog' | translate | sentencecase"
						(secondaryButtonClickEmitter)="router.navigate([ROUTE_URL.public, ROUTE_URL.catalog])"
					></form-button-bar>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #noProducts>
	<div class="col-12 cart__no-products">
		<p>Nel tuo carrello non ci sono ancora prodotti</p>
		<a
			mat-raised-button
			color="accent"
			class="custom-button"
			[routerLink]="[ROUTE_URL.public, ROUTE_URL.catalog]"
			href="javascript:void(0)"
		>
			{{ 'general.back_catalog' | translate | sentencecase }}
		</a>
	</div>
</ng-template>
