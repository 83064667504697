import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export namespace DestinationColumnMap {
	export const base: ITdDataTableColumnCustom[] = [
		{
			name: 'business_name',
			labelPath: 'saep_ict_angular_spin8_core.widget.destination.item_name',
			label: null
		},
		{
			name: 'address.address',
			labelPath: 'saep_ict_angular_spin8_core.widget.destination.address.name',
			label: null
		},
		{
			name: 'address.locality',
			labelPath: 'saep_ict_angular_spin8_core.widget.destination.address.city',
			label: null
		}
	];
}
