import { FormControlMultipurposeModel, FormControlMultipurposeEnum } from '@saep-ict/angular-core';

export const creationFieldMapDivision: FormControlMultipurposeModel.Item[] = [
  {
    name: 'division',
    label: 'organization.field.division',
    on_form_template: false,
    type: FormControlMultipurposeEnum.ItemType.STRING
  },
  {
    name: 'valid',
    on_form_template: false,
    type: FormControlMultipurposeEnum.ItemType.BOOLEAN
  },
  {
    name: 'is_main_of_list',
    label: 'general.favorite',
    on_form_template: false,
    type: FormControlMultipurposeEnum.ItemType.BOOLEAN
  }
];
