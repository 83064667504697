import { ITdDataTableColumnCustom } from "@saep-ict/pouch_agent_models";

export const baseColumns: ITdDataTableColumnCustom[] = [
		{
			name: 'code',
			labelPath: 'context_code.field.code',
			label: 'Code'
		},
		{
			name: 'description',
			labelPath: 'context_code.field.description',
			label: 'Description'
		},
		{
			name: 'typology',
			labelPath: 'context_code.field.typology',
			label: 'Typology'
		},
		{
			name: 'permission',
			labelPath: 'context_code.field.permissions',
			label: 'Permission'
		},
		{
			name: 'action',
			label: null,
			width: 56,
			hidden:true,
			numeric: true
		}
	];
