import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	MediaCenterContext,
	MediaCenterFilter,
	MediaCenterModel
} from '../model/media-center.model';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularCoreUtilService, SentencecasePipe } from '@saep-ict/angular-core';

@Injectable({
	providedIn: 'root',
})
export class UtilService extends AngularCoreUtilService {
	context: BehaviorSubject<MediaCenterContext> =
		new BehaviorSubject<MediaCenterContext>(
			{
				filter: {
					text: null,
					latest: false,
					limit: null,
					path: null
				},
				customHeaders: null
			}
		);
	contextChange: Observable<MediaCenterContext> = this.context.asObservable();
	folderContent: MediaCenterModel[];
	currentFolder: MediaCenterModel;

	itemListSelected: MediaCenterModel[] = [];

	iconsMap: Map<string, string> = new Map<string, string>([
		['folder', 'folder'],
		['image/jpeg', 'photo'],
		['image/pjpeg', 'photo'],
		['image/png', 'photo'],
		['image/x-png', 'photo'],
		['image/svg+xml', 'photo'],
		['image/svg', 'photo'],
		['application/txt', 'text_fields'],
		['text/plain', 'text_fields'],
		['application/msword', 'description'],
		['application/docx', 'description'],
		['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'description'],
		['application/vnd.ms-excel', 'description'],
		['application/xlsx', 'description'],
		['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'all_inbox'],
		['application/pdf', 'description'],
		['application/zip', 'all_inbox']
	]);

	//il formto espresso in iconsMap deve essere uguale anche nell'espressione qui sotto se manca o non completo rimarrà con un colore neutrale

	iconsColorMap: Map<string, string> = new Map<string, string>([
		['folder', 'color_folder'],
		['image/jpeg', 'color_jpg'],
		['image/png', 'color_png'],
		['image/svg', 'color_svg'],
		['aplication/txt', 'color_txt'],
		['application/docx', 'color_docx'],
		['application/xlsx', 'color_xlsx'],
		['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'color_xlsx'],
		['application/pdf', 'color_pdf'],
		['application/zip', 'color_zip'],
	]);

	constructor(
		public snackbar: MatSnackBar,
		public translate: TranslateService,
		public sentencecasePipe: SentencecasePipe
	) {
		super(snackbar, translate, sentencecasePipe);
	}

	mapIcon(type: string) {
		let icon = '';
		const typeArray = type.split(', ');
		typeArray.forEach((value) => {
			if (this.iconsMap.get(value)) {
				icon = this.iconsMap.get(value)
			};
		});
		return icon;
	}

	mapIconColor(color) {
		return this.iconsColorMap.get(color);
	}

	/**
	 * Controlla se una cartella possiede una determinata permission
	 *
	 * @param  folder
	 * @param  action
	 * @returns
	 * @memberof UtilService
	 */
	checkAction(folder: MediaCenterModel, action: string) {
		return (
			folder &&
			folder.actions &&
			folder.actions.length > 0 &&
			folder.actions.indexOf(action) > -1
		);
	}

	/**
	 * Restituisce il path navigabile di un file
	 *
	 * @param file
	 * @returns
	 * @memberof UtilService
	 */
	returnFileFolderContext(file: MediaCenterModel) {
		const context: MediaCenterContext = this.context.value;
		context.filter.path = file.path.substring(0, file.path.length - file.name.length);
		return context;
	}

	returnGetFolderQueryParam(context: MediaCenterContext): MediaCenterFilter {
		let queryParam: MediaCenterFilter = {};
		if (context) {
			if (context.filter.path) { queryParam.path = context.filter.path; }
			if (context.filter.text) { queryParam.text = context.filter.text; }
			if (context.filter.latest) { queryParam.latest = context.filter.latest; }
			if (context.filter.limit) { queryParam.limit = context.filter.limit; }
		} else {
			queryParam.path = this.context.value.filter.path;
		}
		return queryParam;
	}

}
