// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';

// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ContextTypeAndApplicationLink, BodyTablePouchCustomModel } from '@saep-ict/angular-spin8-core';

export enum ContextCodeManagementActionEnum {
	UPDATE_LIST = '[context-code-management] Update List',
	UPDATE_ITEM = '[context-code-management] Update Item',
	LOAD_ITEM = '[context-code-management] Load Item',
	LOAD_LIST = '[context-code-management] Load List',
	RESET = '[context-code-management] Reset',
	ERROR = '[context-code-management] Error',
	SAVE_ITEM = '[context-code-management] Save Item',
	SAVE_ITEM_SUCCESS = '[context-code-management] Save Item Success',
	DELETE_ITEM = '[context-code-management] Delete Item',
	DELETE_ITEM_SUCCESS = '[context-code-management] Delete Item Success'
}

export namespace ContextCodeManagementStateAction {
	export const loadList = createAction(
		ContextCodeManagementActionEnum.LOAD_LIST,
		props<{ contextTypeAndApplicationLink: ContextTypeAndApplicationLink }>()
	);
	export const loadItem = createAction(
		ContextCodeManagementActionEnum.LOAD_ITEM,
		props<BaseStateModel<{ _id: string; contextTypeAndApplicationLink: ContextTypeAndApplicationLink }>>()
	);
	export const updateList = createAction(
		ContextCodeManagementActionEnum.UPDATE_LIST,
		props<BaseStateModel<BodyTablePouchCustomModel[]>>()
	);
	export const updateItem = createAction(
		ContextCodeManagementActionEnum.UPDATE_ITEM,
		props<BaseStateModel<BodyTablePouchCustomModel[]>>()
	);
	export const reset = createAction(ContextCodeManagementActionEnum.RESET);
	export const error = createAction(ContextCodeManagementActionEnum.ERROR, props<BaseStateModel<any>>());
	export const saveItem = createAction(
		ContextCodeManagementActionEnum.SAVE_ITEM,
		props<BaseStateModel<BodyTablePouchCustomModel>>()
	);
	export const saveItemSuccess = createAction(
		ContextCodeManagementActionEnum.SAVE_ITEM_SUCCESS,
		props<BaseStateModel<BodyTablePouchCustomModel[]>>()
	);
	export const deleteItem = createAction(
		ContextCodeManagementActionEnum.DELETE_ITEM,
		props<BaseStateModel<BodyTablePouchCustomModel>>()
	);
	export const deleteItemSuccess = createAction(
		ContextCodeManagementActionEnum.DELETE_ITEM_SUCCESS,
		props<BaseStateModel<null>>()
	);
}
