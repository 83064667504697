import { Component, Input, OnInit } from '@angular/core';
import { StatusBarConfigNew, StatusBarElementNew } from '../../model/status-bar-new.model';

@Component({
  selector: 'status-bar-new',
  templateUrl: './status-bar-new.component.html',
  styleUrls: ['./status-bar-new.component.scss']
})
export class StatusBarNewComponent implements OnInit {

	_config: StatusBarConfigNew = new StatusBarConfigNew();
	theme: string;

	@Input() set config(c) {
		if (c) {
			this._config.steps = this.hightlightSelected(c);
		}
	}
	constructor() {}
	ngOnInit() {}

	hightlightSelected(c: StatusBarConfigNew): StatusBarElementNew[] {
		this.theme = c.theme;
		const steps: StatusBarElementNew[] = [];
		let target: boolean;
		c.steps.forEach(s => {
			if (c.condensed) {
				if (s.selected) {
					steps.push(s);
					return steps;
				}
			} else {
				if (s.selected) {
					s.showLabel = true;
				}
				if (!target) {
					target = s.selected ? true : false;
					s.selected = true;
				}
				steps.push(s);
			}
		});
		return steps;
	}
}