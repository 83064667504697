import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../state';
import { OrderActionEnum } from '../../state/order/order.actions';
import { OrderStateModel, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class CheckoutGuard implements CanActivate {
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	constructor(
		private store: Store,
		private router: Router
	) {}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.order$.pipe(
			filter((order: BaseStateModel<OrderStateModel>) => order && order.type !== OrderActionEnum.LOAD),
			take(1),
			map(res => {
				this.order = res ? res.data : null;
				if (this.order && this.order.product_list.length) {
					return true;
				} else {
					this.router.navigate([ROUTE_URL.public]);
					return false;
				}
			})
		);
	}
}
