import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import * as ConfigurationCustomerAuthentication from '../../constants/configuration-customer/authentication/authentication.constant';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable({
	providedIn: 'root'
})
export class HomepageGuard implements CanActivate {
	constructor(
		private router: Router
	) {
		//
	}

	canActivate(): boolean {
		if (ConfigurationCustomerAuthentication.enableNavigationForHomepage) {
			return true;
		} else {
			this.router.navigate([`/${ROUTE_URL.private}`]);
			return false;
		}
	}
}
