import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ConfigurationActionEnum, ConfigurationStateAction } from './configuration.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ConfigurationViewModel } from '../../model/configuration.model';

export namespace ConfigurationStateReducer {
	export const initialState: BaseStateModel<ConfigurationViewModel> = null;

	const _reducer = createReducer(
		initialState,
		on(ConfigurationStateAction.load, state => state),
		on(ConfigurationStateAction.update, (state, highlights) => highlights),
		on(ConfigurationStateAction.reset, () => initialState),
		on(ConfigurationStateAction.error, (state, highlights) => ({
			data: state ? state.data : null,
			type: highlights.type
		}))
	);

	export function reducer(
		state: BaseStateModel<ConfigurationViewModel>,
		action: TypedAction<ConfigurationActionEnum>
	) {
		return _reducer(state, action);
	}
}
