import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from '../../material-components.module';
import { IllustrationAccountComponent } from './illustration-account.component';
import { IllustrationEmptyComponent } from './illustration-empty.component';

@NgModule({
	declarations: [
		IllustrationAccountComponent,
		IllustrationEmptyComponent
	],
	imports: [RouterModule, MaterialComponentsModule],
	providers: [],
	exports: [
		IllustrationAccountComponent,
		IllustrationEmptyComponent
	]
})
export class IllustrationsModule {}
