<div class="container">
	<div class="row">
		<div class="col-sm-12 col-md-6 col-lg-6 general-info__data">
			<!-- Dati personali -->
			<div class="general-info__full-data">
				<organization-legal-information></organization-legal-information>
			</div>

			<!-- Contatti -->
			<div class="general-info__contacts">
				<p class="title">
					{{ 'saep_ict_angular_spin8_core.page.contact.name_plural' | translate | sentencecase }}
				</p>
				<contact-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columns"
					[localListHandlerPaginatorShow]="false"
				></contact-list-wrapper>
			</div>
		</div>

		<div class="col-sm-12 col-md-5 offset-md-1 col-lg-5 offset-lg-1 general-info__illustration">
			<app-illustration-account [theme]="'accent'"></app-illustration-account>
		</div>
	</div>
</div>
