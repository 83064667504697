export namespace InformativePageEnum {
	export enum Type {
		CONTACT = 'contact',
		COOKIE_POLICY = 'cookie_policy',
		DELIVERY_POLICY = 'delivery_policy',
		PRIVACY_POLICY = 'privacy_policy',
		RETURN_POLICY = 'return_policy',
		TERMS_AND_CONDITIONS = 'terms_and_conditions'
	}
}
