import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// model
import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { ItemType } from '../b2c-article-item/card-slider.models';

// misc
import { LocalListHandlerWidgetWrapper, AngularCoreUtilService } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'b2c-article-list-wrapper',
	templateUrl: './b2c-article-list-wrapper.component.html',
	styleUrls: ['./b2c-article-list-wrapper.component.scss']
})
export class B2cArticleListWrapper extends LocalListHandlerWidgetWrapper {
	@Input() viewType: ViewTypeEnum = ViewTypeEnum.list;
	@Input() pageSizeOptions: number[] = [5, 10, 25, 100];
	public ViewTypeEnum = ViewTypeEnum;
	ItemType = ItemType;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store
		) {
		super(utilService, translateService, store);
	}
}
