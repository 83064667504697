import * as FormControlMultipurposeModel from "../widget/form-control-multipurpose.model";

export interface FormDynamicConfigurationModel {
	creationFieldMap: FormControlMultipurposeModel.Item[];
	formValue?: any;
	/**
	 * Definisce il tempo di debounce tra una modifica dell'utente e l'eventuale emit del componente; default 500ms
	 */
	formDebounceTime?: number;
	/**
	 * Consente di scatenare l'emit del valore di form-dynamic nonostante il form non sia valido, dunque ad ogni
	 * change 
	 */
	emitAlsoNonValidForm?: boolean;
	/**
	 * Configurazione del button di reset form
	 */
	buttonReset?: {
		/**
		 * Mostra l'elemento; default `false`
		 */
		show?: boolean;
		/**
		 * Etichetta dell'elemento: default `saep_ict_angular_core.general.reset` che applica per it, en
		 */
		label?: string;
	}
}
