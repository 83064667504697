import * as FormControlMultipurposeModel from '../model/widget/form-control-multipurpose.model';

export const fieldRTFDefault: FormControlMultipurposeModel.Item = {
	name: null,
	on_form_template: false,
	fieldRTFConfiguration: {
		height: 500,
		quill: {
			toolbar: [
					[
						'bold',
						'italic',
						'underline',
						'strike'
					],
					[
						{
							'align': []
						}
					],
					[
						'blockquote'
					],
					[
						{
							list: 'ordered'
						},
						{
							list: 'bullet'
						}
					],
					[
						{
							header: [1, 2, 3, 4, 5, 6, false]
						}
					],
					[
						{
							color: []
						},
						{
							background: []
						}
					],
					[
						'link',
						'image'
					],
					[
						'clean'
					]
			]
		}
	}
}