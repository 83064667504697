import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { AdditionalServiceStateModel } from '@saep-ict/angular-spin8-core';
import * as AdditionalServiceModel from '../../model/additional-service.model';

export enum AdditionalServiceActionEnum {
	UPDATE = '[additional-service] Update',
	LOAD_ALL = '[additional-service] Load All',
	SAVE = '[additional-service] Save',
	SAVE_COMPLETE = '[additional-service] Save complete',
	RESET = '[additional-service] Reset',
	ERROR = '[additional-service] Error'
}

export namespace AdditionalServiceStateAction {
	export const loadAll = createAction(AdditionalServiceActionEnum.LOAD_ALL);
	export const update = createAction(
		AdditionalServiceActionEnum.UPDATE,
		props<BaseStateModel<AdditionalServiceStateModel>>()
	);
	export const save = createAction(
		AdditionalServiceActionEnum.SAVE,
		props<AdditionalServiceModel.SavePayload>()
	);
	export const saveComplete = createAction(AdditionalServiceActionEnum.SAVE_COMPLETE, props<BaseStateModel<any>>());
	export const reset = createAction(AdditionalServiceActionEnum.RESET);
	export const error = createAction(AdditionalServiceActionEnum.ERROR, props<BaseStateModel<any>>());
}
