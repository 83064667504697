<a
	*ngIf="_category"
	(click)="goTo(_category.code_item)"
	href="javascript:void(0)"
>
	<div
		class="b2c-category-item"
		[ngStyle]="{
			'background-image': backgroundImage ? 'url(' + backgroundImage.bucket_link + ')' : null
		}"
	>
		<div class="b2c-category-item__title">
			<p>
				{{
					utilTranslateService.getTranslationFromLanguage(_category.language_list).description | sentencecase
				}}
			</p>
		</div>
	</div>
</a>
