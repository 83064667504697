import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/* Example of usage
  <mat-error>
    <form-error-message
      ngDefaultControl
      [formControl]="form.get('zip_code')"
      minLengthValue="zipCodeMinLength"
    ></form-error-message>
  </mat-error>
*/

@Component({
	selector: 'form-error-message',
	templateUrl: './form-error-message.component.html',
	styleUrls: ['./form-error-message.component.scss']
})
export class FormErrorMessageComponent implements OnInit {
	@Input() formControl: AbstractControl;
	@Input() minLengthValue: number;
	@Input() maxLengthValue: number;

	constructor() {}

	ngOnInit() {}
}
