<app-call-to-action [callToActionConfig]="callToActionConfig" (clickButton)="clickCallToAction()"></app-call-to-action>
<footer class="footer">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-lg-3 footer__logo">
				<div class="logo-container">
					<img src="{{ 'theme.footer.logo' | translate }}" alt="" />
				</div>
			</div>
			<div class="col-md-6 col-lg-6 footer__info">
				<div class="footer__info__wrapper">
					<span class="label">
						{{ 'theme.contact.field.phone_label' | translate | sentencecase }}
					</span>
					<span>
						{{ customerReference.PHONE | translate | sentencecase }}
					</span>
					<span class="label"> {{ 'theme.contact.field.email_label' | translate | sentencecase }} </span
					><span>
						<a href="mailto:{{ customerReference.EMAIL | translate }}">
							{{ customerReference.EMAIL | translate | sentencecase }}
						</a>
					</span>
				</div>
			</div>
			<div class="col-md-6 col-lg-3 footer__copyright">
				<span>
					{{
						'theme.footer.field.copyright'
							| translate: { year: currentYear, company_name: customerReference.NAME_LONG | translate }
							| sentencecase
					}}
				</span>
			</div>

			<div class="col-md-6 col-lg-3 footer__bio">
				<p>
					{{ 'theme.footer.field.text' | translate | sentencecase }}
				</p>
			</div>
			<div *ngIf="linkCategoryList && linkCategoryList.length" class="col-md-4 col-lg-3 footer__link">
				<app-unordered-list
					[title]="'footer.field.products' | translate | sentencecase"
					[items]="linkCategoryList"
				></app-unordered-list>
			</div>
			<div class="col-md-4 col-lg-3 footer__link">
				<app-unordered-list
					[title]="'footer.field.info' | translate | sentencecase"
					[items]="linkInfoList"
				></app-unordered-list>
			</div>
			<div
				*ngIf="acceptedPayments.length > 0"
				class="col-md-4 col-lg-3 footer__payments"
			>
				<p>{{ 'footer.field.payment.methods' | translate | sentencecase }}</p>
				<img
					*ngFor="let paymentIconItem of acceptedPayments"
					[src]="paymentIconItem.icon"
					[alt]="paymentIconItem.description | translate | sentencecase"
					[title]="paymentIconItem.description | translate | sentencecase"
					class="footer__payments__logo"
				/>
			</div>
		</div>
	</div>

	<div class="footer__credits">
		<div class="container">
			<div class="row">
				<div class="col-md-6 footer__credits__version">
					<span>
						{{ appTag }}
					</span>
				</div>
				<div class="col-md-6 footer__credits__author">
					<span>
						{{ 'footer.powered_by' | translate | sentencecase }}
						<a href="https://www.saep-ict.it/" target="_blank">
							{{ customerReferenceDev.NAME_LONG | translate | uppercase }}
						</a>
					</span>
				</div>
			</div>
		</div>
	</div>
</footer>
