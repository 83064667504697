import { Injectable } from '@angular/core';

// model
import { BaseState, BaseStateModel, RestBaseMessageError, RestBaseResponse } from '@saep-ict/angular-core';
import { TicketModel } from '../../../model/lib.model';

// store
import { TicketCenterTicketDetailActionEnum, TicketCenterTicketDetailStoreAction } from './ticket-detail.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// misc
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RestActionService } from '../../../service/rest/rest-action.service';
import { from } from 'rxjs';
import { TicketCenterUtilService } from '../../../service/util/ticket-center-util.service';

@Injectable()
export class TicketCenterTicketDetailEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TicketCenterTicketDetailActionEnum.LOAD),
			mergeMap((action: BaseStateModel<TicketModel<any>>) => from(this.load(action))),
			map((state: BaseStateModel<TicketModel<any>>) => TicketCenterTicketDetailStoreAction.update(state)),
			catchError((error, caught) => {
        this.ticketCenterUtilService.dispatchStoreActionError<TicketModel<any>>(error, TicketCenterTicketDetailStoreAction.error);
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TicketCenterTicketDetailActionEnum.SAVE),
			mergeMap((state: BaseStateModel<TicketModel<any>>) => from(this.save(state))),
			map((state: BaseStateModel<TicketModel<any>>) => TicketCenterTicketDetailStoreAction.saveSuccess(state)),
			catchError((error, caught) => {
        this.ticketCenterUtilService.dispatchStoreActionError<TicketModel<any>>(error, TicketCenterTicketDetailStoreAction.error);
				return caught;
			})
		)
	);

	constructor(
    private actions$: Actions,
    private restActionService: RestActionService,
    private ticketCenterUtilService: TicketCenterUtilService
  ) {

  }

  load(action: BaseStateModel<TicketModel<any>>): Promise<BaseStateModel<TicketModel<any>>> {
    return this.restActionService.getDetail({id: action.data.id.toString()})
      .then((res: RestBaseResponse<TicketModel<any>>) => {
        return new BaseState(res.data);
      })
      .catch((error: RestBaseMessageError) => {
        throw error;
      });
  }

  save(action: BaseStateModel<TicketModel<any>>): Promise<BaseStateModel<TicketModel<any>>> {
    return this.restActionService.postDetail(action.data)
      .then((res: RestBaseResponse<TicketModel<any>>) => {
        return new BaseState(res.data);
      })
      .catch((error: RestBaseMessageError) => {
        throw error;
      });
  }

}
