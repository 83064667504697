import { IMediaCenterAppConfig } from "../interface/media-center-app-config.interface";

// @dynamic
export class UtilStructure {

  public static loadLibConfig = (appConfig: IMediaCenterAppConfig) => {
    return () => {
      return new Promise(async (resolve, reject) => {
        appConfig['config$'].subscribe(async config => {
          if (config && config.media_center) {
            appConfig.initializeConfig(config.media_center);
            resolve(null);
          } else {
            throw new Error(
              'media_center field is not present in environment configuration'
            );
          }
        });
      });
    };
  };

}
