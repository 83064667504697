export const dayCurrent: Date = new Date();
export const yearCurrent: number = dayCurrent.getFullYear();
// per maggiore chiarezza si considera come chiave il dizionario dei mesi dell'applicazione, dove `jan: 1`
// (in getMonth() è `jan: 0`)
export const monthCurrent: number = dayCurrent.getMonth() + 1;
export const returnInputDisable = (year: number, month: number): boolean => {
    if (year > yearCurrent) {
        return false;
    }
    return month <= monthCurrent;
}