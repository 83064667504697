import { KanbanModel } from "../../model/widget/kanban.model";


export class OpportunityStep {
	stepList = [
		{
			id: '1',
			name: 'PRIMO CONTATTO'
		},
		{
			id: '2',
			name: 'SECONDO CONTATTO'
		},
		{
			id: '3',
			name: 'OFFERTA FORMULATA'
		},
		{
			id: '4',
			name: 'NEGOZIAZIONE'
		}
	];

	firstStep = this.stepList[0];
	lastStep = this.stepList[this.stepList.length - 1];

	get stepListName() {
		return this.stepList.map(i => i.name);
	}

	findById(id: string) {
		return this.stepList.find(x => x.id === id);
	}

	buildDefaultKanban() {
		const kanbanPouchModel: KanbanModel.Base = {
			type: 'kanban_opportunity',
			name: 'Opportunity Kanban',
			column: [
				{ id: '1', name: 'PRIMO CONTATTO', cards: [] },
				{ id: '2', name: 'SECONDO CONTATTO', cards: [] },
				{ id: '3', name: 'OFFERTA FORMULATA', cards: [] },
				{ id: '4', name: 'NEGOZIAZIONE', cards: [] }
			]
		};
		return kanbanPouchModel;
	}
}
