import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MaterialComponentsModule } from '../material-components.module';
import { PipeModule } from '../pipes/pipe.module';
import { DirectivesModule } from '../directives/directives.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { CovalentModule } from '../covalent.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialComponentsModule,
        TranslateModule,
        PipeModule,
        DirectivesModule,
        NgScrollbarModule,
        NgScrollbarReachedModule,
        CovalentModule
    ],
    declarations: [],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WidgetModule {}
