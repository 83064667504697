<div
  *ngIf="configuration"
  class="preview-container"
  [ngClass]="{'square-ratio':configuration.forceSquareRatio}"
  [style.max-width]="configuration.width"
  [style.max-height]="configuration.height"
>
  <mat-icon
    *ngIf="imageError"
    [ngClass]="configuration.icon"
  >
    {{ configuration.icon }}
  </mat-icon>
  <img
    *ngIf="!imageError"
    [attr.src]="configuration.image"
    [style.cursor]="configuration.onClickHandler ? 'pointer' : null"
    (error)="imageError = true"
    (click)="clickHandler()"
  />
</div>
