import { ITdDataTableColumnCustom, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import {
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-spin8-core';
import * as ConfigurationReceiptContextApplicationColumnMap from './receipt-column-map.constant';
import {
    returnConfigurationCustomerReceiptListFilterByFormValue,
    returnConfigurationCustomerReceiptListFilterCreationFieldMap
} from './receipt-list-filter-form-dynamic-configuration.constant';
import { ReceiptStateModel } from '../../../model/state/receipt-state.model';
import { ReceiptListFilterCreationFieldMapFormValue } from '../../../model/receipt-util.model';

export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumnCustom[] => {
    switch (contextApplication) {
        case ContextApplicationItemCodeEnum.B2B:
        case ContextApplicationItemCodeEnum.B2C:
        case ContextApplicationItemCodeEnum.PORTAL:
            return ConfigurationReceiptContextApplicationColumnMap.base;
        default:
            return ConfigurationReceiptContextApplicationColumnMap.base;
    }
};
export const listFilterCreationFieldMap = async (): Promise<FormControlMultipurposeModel.Item[]> => {
    try {
        return await returnConfigurationCustomerReceiptListFilterCreationFieldMap();
    } catch(e) {
        throw new Error(e);
    }
}

export const returnListFilterByFormValue = async (
    list: ReceiptStateModel[],
    formValue: ReceiptListFilterCreationFieldMapFormValue
): Promise<ReceiptStateModel[]> => {
        try {
            return await returnConfigurationCustomerReceiptListFilterByFormValue(list, formValue);
        } catch(e) {
            throw new Error(e);
        }
}