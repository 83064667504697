import { ConfigurationUtil } from '@saep-ict/angular-spin8-core';
import { TicketStatusContextApplicationMapItem } from '@saep-ict/ticket-center';

export enum TypeEnum {
	SENT = 'SENT',
	ARCHIVED = 'ARCHIVED'
}

export const contextApplicationMap = <
	ConfigurationUtil.Context.ApplicationSelector<TicketStatusContextApplicationMapItem>
>(<unknown>{
	BACKOFFICE: {
		selection_list: [
			{
				value: TypeEnum.SENT,
				name: 'New Request',
				color: '#4caf50'
			},
			{ value: TypeEnum.ARCHIVED }
		],
		filter_list: [
			{
				value: TypeEnum.SENT,
				name: 'New Request'
			},
			{ value: TypeEnum.ARCHIVED }
		]
	},
	B2B: {
		filter_list: [{ value: TypeEnum.SENT }, { value: TypeEnum.ARCHIVED }]
	},
	PORTAL: {
		filter_list: [{ value: TypeEnum.SENT }, { value: TypeEnum.ARCHIVED }]
	}
});
