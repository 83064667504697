import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';
import { ContextApplicationItemCodeEnum } from '../../enum/permission/context-application-item-code.enum';

export interface PermissionAuxiliaryTableStateModel {
	permission_list?: PermissionItem[];
	permission_validation?: PermissionItem[];
	permission_group_list?: PermissionGroupItem[];
	context_list?: Context[];
}

export interface PermissionItem {
	key: string;
	description: string;
	context_application: ContextApplicationItemCodeEnum[];
	validation: PermissionValidation;
}

export interface PermissionValidation {
	mandatory: {
		context_application: string[];
	};
}

export interface PermissionGroupItem extends PermissionItem {
	permission: PermissionItem[];
}

export interface Context {
	code: number;
	key: string;
	description: string;
}

export const PermissionAuxiliaryDictionary = [
	{
		key: 'permission_list',
		endpoint: `/${returnApiUrlSegment(ApiType.PRIVATE)}/permissions/detail/`
	},
	{
		key: 'permission_validation',
		endpoint:  `/${returnApiUrlSegment(ApiType.PRIVATE)}/permissions/detail/?type=mandatory`
	},
	{
		key: 'permission_group_list',
		endpoint: `/${returnApiUrlSegment(ApiType.PRIVATE)}/permission-contexts/detail/`
	},
	{
		key: 'context_list',
		endpoint: `/${returnApiUrlSegment(ApiType.PRIVATE)}/contexts/detail/`
  }
];
