<div class="step">
	<div class="step__wrapper">
		<img
			class="step__logo"
			src="../../../../assets/common/img/logo/SPIN8/v2/logo-SPIN8-generic-icon.svg"
			alt="spin-8 icon"
		/>

		<div class="step__content">
			<div *ngIf="commonDownloadStatus" [@inOutAnimation]>
				<p class="step__content__description">Stiamo scaricando i dati comuni dal sistema centrale</p>
				<mat-progress-bar
					mode="determinate"
					value="{{ commonDownloadStatus?.progressPerc }}"
				></mat-progress-bar>
				<p class="step__content__percentage">{{ commonDownloadStatus?.progressPerc }} %</p>
			</div>

			<div *ngIf="agentDownloadStatus" [@inOutAnimation]>
				<p class="step__content__description">
					Stiamo scaricando i dati specifici per l'agente dal sistema centrale
				</p>
				<mat-progress-bar mode="determinate" value="{{ agentDownloadStatus?.progressPerc }}"></mat-progress-bar>
				<p class="step__content__percentage">{{ agentDownloadStatus?.progressPerc }} %</p>
			</div>

			<!-- <div *ngIf="downloadStatus.currentAction === currentActionEnum.PROGRESS" [@inOutAnimation]>
				<p class="step__content__description">Stiamo scaricando i dati dal sistema centrale</p>
				<mat-progress-bar mode="determinate" value="{{ downloadStatus?.progress }}"></mat-progress-bar>
				<p class="step__content__percentage">{{ downloadStatus?.progress }} %</p>
			</div> -->

			<!-- <div *ngIf="unzipStatus.currentAction === currentActionEnum.PROGRESS" [@inOutAnimation]>
				<p class="step__content__description">Un ultimo tocco...</p>
				<mat-progress-bar mode="indeterminate" value="{{ unzipStatus?.progress }}"></mat-progress-bar>
			</div> -->
		</div>

		<div class="step__tips" *ngFor="let tips of tipsList; let i = index">
			<p *ngIf="i === counter" [@inOutAnimation]>{{ tips }}</p>
		</div>
	</div>
</div>
