import { Location } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
	@Input() title: string;
	@Input() badge: string;
	@Input() options: boolean;
	@Input() badgeClass: string = '';
	@Input() expandedActions: boolean = false;
	@Input() pathList: string[] = [];

	/**
	 * Se true viene mostrato il bottone di back che scatena un evento di route.back
	 * Se viene passata una stringa con il path questa verrà usata per il redirect
	 */
	@Input() backButton: boolean | string;

	constructor(public location: Location, private router: Router) {}
	ngOnInit() {}

	onClickBackButton() {
		if (this.backButton === true) {
			this.location.back();
		} else {
			this.router.navigate([this.backButton]);
		}
	}
}
