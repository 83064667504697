import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { SearchModel } from '../../model/structure/elastic-search.model';

export enum SearchResultsActionEnum {
	LOAD = '[search results] Load',
	UPDATE = '[search results] Update',
	SKIP = '[search results] Skip',
	RESET = '[search results] Reset',
	ERROR = '[search results] Error'
}

export namespace SearchResultsStateAction {
	export const load = createAction(SearchResultsActionEnum.LOAD, props<SearchModel>());
	export const skip = createAction(SearchResultsActionEnum.SKIP);
	export const update = createAction(SearchResultsActionEnum.UPDATE, props<BaseStateModel<ArticlePouchModel[]>>());
	export const reset = createAction(SearchResultsActionEnum.RESET);
	export const error = createAction(SearchResultsActionEnum.ERROR, props<BaseStateModel<any>>());
}
