import { FormControlMultipurposeModel } from '@saep-ict/angular-core';
import {
    ContextTypeAndApplicationLink,
    ContextApplicationItemCodeEnum, 
    ConfigurationUtil
} from '@saep-ict/angular-spin8-core';
import * as ContextCodeItemBackoffice from '../../../constants/configuration-customer/context-code/context-code-item-backoffice.constant';
import * as ContextCodeItemB2B from '../../../constants/configuration-customer/context-code/context-code-item-b2b.constant';
import * as ContextCodeItemAgent from '../../../constants/configuration-customer/context-code/context-code-item-agent.constant';
import * as ContextCodeItemB2C from '../../../constants/configuration-customer/context-code/context-code-item-b2c.constant';
import * as ContextCodeItemCRM from '../../../constants/configuration-customer/context-code/context-code-item-crm.constant';
import * as ContextCodeItemPortal from '../../../constants/configuration-customer/context-code/context-code-item-portal.constant';
import * as CreationFieldMapContextCodeOrganizationBackoffice from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-backoffice.constant';
import * as CreationFieldMapContextCodeOrganizationAgent from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-agent.constant';
import * as CreationFieldMapContextCodeOrganizationB2b from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-b2b.constant';
import * as CreationFieldMapContextCodeOrganizationB2c from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-b2c.constant';
import * as CreationFieldMapContextCodeOrganizationPortal from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-portal.constant';
import * as CreationFieldMapContextCodeOrganizationCrm from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-crm.constant';
import * as CreationFieldMapContextCodeOrganizationPublic from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-public.constant';
import * as CreationFieldMapContextCodeBase from './form-control-multipurpose/creation-field-map/context-code/creation-field-map-context-code-base.constant';
import * as CreationFieldMapContactDetailDialog from './form-control-multipurpose/creation-field-map/contact/creation-field-map-contact-detail-dialog.constant';
import * as creationFieldMapDocumentBase from './form-control-multipurpose/creation-field-map/creation-field-map-base.constant';
import * as creationFieldMapDestinationBase from './form-control-multipurpose/creation-field-map/destination/creation-field-map-destination-base.constant';
import * as creationFieldMapDestinationListEdit from './form-control-multipurpose/creation-field-map/destination/creation-field-map-destination-listEdit.constant';
import * as creationFieldMapContextCodeBase from './form-control-multipurpose/creation-field-map/context-code/creation-field-map-context-code-base.constant';
import * as creationFieldMapDivision from './form-control-multipurpose/creation-field-map/creation-field-map-division.constant';

export const map: ContextTypeAndApplicationLink[] = [
    {
        context_application_item_code: ContextApplicationItemCodeEnum.BACKOFFICE,
        context_code_item: ContextCodeItemBackoffice.projectOverride(),
        application_name: 'Backoffice',
        icon: 'admin_panel_settings'
    },
    {
        context_application_item_code: ContextApplicationItemCodeEnum.B2B,
        context_code_item: ContextCodeItemB2B.projectOverride(),
        application_name: 'B2B',
        icon: 'shopping_bag'
    }
];

export const formControlMultipurpose:
ConfigurationUtil.Context.ApplicationSelector<ConfigurationUtil.DocumentSelector<FormControlMultipurposeModel.Item[]>> ={
	BACKOFFICE: {
		ORGANIZATION: CreationFieldMapContextCodeOrganizationBackoffice.projectOverride,
		BACKOFFICE: CreationFieldMapContextCodeBase.projectOverride,
		AGENT: CreationFieldMapContextCodeBase.projectOverride,
		CRM: CreationFieldMapContextCodeBase.projectOverride,
		CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
		CONTEXT_CODE_BASE: creationFieldMapContextCodeBase.projectOverride,
		DOCUMENT_BASE: creationFieldMapDocumentBase.projectOverride,
		DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
		DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride,
		DIVISION: creationFieldMapDivision.projectOverride
	},
	AGENT: {
		ORGANIZATION: CreationFieldMapContextCodeOrganizationAgent.projectOverride,
		CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
		DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
		DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
	},
	B2B: {
		ORGANIZATION: CreationFieldMapContextCodeOrganizationB2b.projectOverride,
		CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
		DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
		DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
	},
	B2C: {
		ORGANIZATION: CreationFieldMapContextCodeOrganizationB2c.projectOverride,
		CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
		DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
		DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
	},
	PORTAL: {
		ORGANIZATION: CreationFieldMapContextCodeOrganizationPortal.projectOverride,
		CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
		DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
		DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
	},
	CRM: {
		ORGANIZATION: CreationFieldMapContextCodeOrganizationCrm.projectOverride,
		CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
		DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
		DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
	},
	PUBLIC: {
		ORGANIZATION: CreationFieldMapContextCodeOrganizationPublic.projectOverride,
		CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
		DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
		DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
	}
};