import { Injectable } from '@angular/core';
import * as ConfigurationCustomerContextApplication from '../../constants/configuration-customer/context-application/context-application.constant';
import { Store } from '@ngrx/store';
import { BaseStateModel, AngularCoreUtilService } from '@saep-ict/angular-core';
import { BodyTablePouchModel, OrganizationPouchModel, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StateFeature } from '../../state';
import { ContextCodeManagementStateAction } from '../../state/backoffice/context-code/context-code-management/context-code-management.actions';
import {
	ContextApplicationItemCodeEnum,
	ContextCodeAssociationItem,
	ContextCodeItem,
	ContextOtherItemCodeEnum,
	ContextPermission,
	ContextPermissionGroupItem,
	ContextTypeAndApplicationLink,
	PermissionGroupItem,
	PermissionItem,
	PermissionKeyEnum,
	PouchDbConstant,
	PouchDbModel,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';

@Injectable({
	providedIn: 'root'
})
export class PermissionUtilService {
	contextCodeManagementState$: Observable<BaseStateModel<BodyTablePouchModel[]>> = this.store.select(
		StateFeature.getContextCodeManagementState
	);

	constructor(
		private utilService: AngularCoreUtilService,
		private store: Store
	) {}

	// subscribe

	/**
	 * Subscribe che recepisce gli aggiornamenti degli state
	 *
	 * - customerListState
	 * - agentListState
	 * - backofficeCodeListState
	 *
	 * Qualsiasi di essi ritorni, il dato viene rimodellato in base al parametro model_type
	 *
	 * @param {(ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum)} context_code_item_code
	 * @returns {(Observable<BaseStateModel<ContextCodeItem[]>>)}
	 * @memberof PermissionUtilService
	 */
	subscribeContextCodeList(): Observable<BaseStateModel<ContextCodeItem[]>> {
		const contextCodeManagementState = this.contextCodeManagementState$.pipe(
			filter((state: any) => state),
			map((state: BaseStateModel<BodyTablePouchModel[]>) => state)
		);

		return contextCodeManagementState.pipe(
			map((state: BaseStateModel<BodyTablePouchModel[] | OrganizationPouchModel[]>) => {
				const stateReturn = <BaseStateModel<ContextCodeItem[]>>{
					type: state.type,
					data: []
				};
				if (state.data) {
					state.data.map ( (value, i) => {
						switch(value.type){
							case('organization_credit'):
							case('organization_credit_summary'):
								state.data.splice(i, 1);
								break;
						}
					})
					// TODO: deprecare le divergenze tra code e code_item e tra description e business_name
					for (let i = 0; i < state.data.length; i++) {
						interface ExtendedContextCodeItem extends ContextCodeItem {
							typology?: OrganizationTypeEnum;
						}
						const item: ExtendedContextCodeItem = {
							code: state.data[i]['code_item'],
							code_erp: state.data[i]['code_erp'],
							description: state.data[i]['business_name'] || state.data[i]['description'],
							typology: state.data[i]['organization_type'] || null
						};
						stateReturn.data[i] = item;
					}
				}
				return stateReturn;
			})
		);
	}

	// widget & utility

	/**
	 * Resetta tutti gli stati dei quali solitamente viene fatta una dispatch nei contesti che utilizzano la subscribe
	 * subscribeContextCodeList()
	 *
	 * @memberof PermissionUtilService
	 */
	resetContextCodeListAssociatedState() {
		this.store.dispatch(ContextCodeManagementStateAction.reset());
	}

	/**
	 * Genera un albero di permessi che permette la selezione contemporanea di tutte le permission del gruppo oppure delle
	 * singole
	 *
	 * @param {PermissionGroupItem[]} groupermissionList
	 * @param {PermissionItem[]} permissionList
	 * @returns
	 * @memberof DialogChangePermissionContextCodeComponent
	 */
	createContextPermissionGroupList(
		groupermissionList: PermissionGroupItem[],
		permissionList: PermissionItem[],
		context_application_item_code_filter?: ContextApplicationItemCodeEnum,
		exclude_permission_list?: string[]
	): ContextPermissionGroupItem[] {
		let contextPermissionTree: ContextPermissionGroupItem[] = [];

		// filtro sulle permission contestuali (context_code)
		if (context_application_item_code_filter) {
			groupermissionList = groupermissionList.filter((i: PermissionGroupItem) =>
				i.context_application.includes(context_application_item_code_filter)
			);
			permissionList = permissionList.filter(i =>
				i.context_application.includes(context_application_item_code_filter)
			);
		}

		if (exclude_permission_list) {
			groupermissionList = groupermissionList.map(i => {
				const returnPermission: PermissionItem[] = [];
				i.permission.forEach(n => {
					if (!exclude_permission_list.includes(n.key)) {
						returnPermission.push(n);
					}
				});
				const returnGroupPermissionItem = <PermissionGroupItem>i;
				returnGroupPermissionItem.permission = returnPermission;
				return returnGroupPermissionItem;
			});
			permissionList = permissionList.filter(i => !exclude_permission_list.includes(i.key));
		}

		// creazione array di appoggio per ricavare le permission non incluse in nessun gruppo
		const permissionListNotIncludedInAnyGroup: PermissionItem[] = JSON.parse(JSON.stringify(permissionList));

		// creazione array di valori di permission, rimozione permissio trovate nella lista permissionListNotIncludedInAnyGroup
		contextPermissionTree = groupermissionList.map(groupItem => {
			const contextPermissionGroupListItem = <ContextPermissionGroupItem>{
				//code: groupItem.code,
				key: groupItem.key,
				description: groupItem.description,
				permission_group_value_list: [],
				permission_group_full_object_list: groupItem.permission
			};
			groupItem.permission.forEach(i => {
				contextPermissionGroupListItem.permission_group_value_list.push(i.key);
				if (permissionListNotIncludedInAnyGroup.find(pi => pi.key === i.key)) {
					permissionListNotIncludedInAnyGroup.splice(
						this.utilService.getElementIndex(permissionListNotIncludedInAnyGroup, 'key', i.key),
						1
					);
				}
			});
			return contextPermissionGroupListItem;
		});

		// nel caso ci siano permission non incluse in nessun gruppo viene creato un gruppo per le escluse che ne
		// permetta l'aggiunta massiva e quella delle singole
		if (permissionListNotIncludedInAnyGroup && permissionListNotIncludedInAnyGroup.length > 0) {
			const contextPermission = <ContextPermissionGroupItem>{
				description: 'Permissions not contained in any group',
				permission_group_value_list: [],
				permission_group_full_object_list: []
			};

			permissionListNotIncludedInAnyGroup.forEach(i => {
				contextPermission.permission_group_value_list.push(i.key);
				contextPermission.permission_group_full_object_list.push(i);
			});

			contextPermissionTree.push(contextPermission);
		}
		return contextPermissionTree;
	}

	returnPermissionFullObject(permissionList: PermissionItem[], permission: string): PermissionItem {
		return permissionList.find(pi => pi.key === permission);
	}

	contextTypeAndApplicationLinkFullObject(key: ContextApplicationItemCodeEnum): ContextTypeAndApplicationLink {
		return ConfigurationCustomerContextApplication.map.find(i => i.context_application_item_code === key);
	}

	/**
	 * Restituisce un elemento filtrato per proprietà code accettando in ingresso
	 * gli enum che definiscono context_*
	 *
	 * @param {any[]} context_list
	 * @param {(ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum)} context_item_code
	 * @returns {ContextPermission}
	 * @memberof PermissionUtilService
	 */
	getContextItem(
		context_list: any[],
		context_item_code: ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum
	): any {
		const contextItem = <ContextPermission | ContextCodeAssociationItem>(
			context_list.find(i => i.code === context_item_code)
		);
		return contextItem;
	}

	/**
	 * Restituisce il valore di context_application_list dopo averne aggiornato uno dei singoli oggetti
	 * che lo compongono
	 *
	 * @param {(ContextPermission[] | ContextCodeAssociationItem[])} context_list
	 * @param {(ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum)} context_item_code
	 * @param {(ContextPermission | ContextCodeAssociationItem)} value
	 * @returns {(ContextPermission[] | ContextCodeAssociationItem[])}
	 * @memberof PermissionUtilService
	 */
	returnUpdatedContextListValue(
		context_list: ContextPermission[] | ContextCodeAssociationItem[],
		context_item_code: ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum,
		value: ContextPermission | ContextCodeAssociationItem
	): ContextPermission[] | ContextCodeAssociationItem[] {
		context_list = context_list ? context_list : [];
		let context_item_not_found = true;
		// aggiornamento di un context_application_item
		for (let i = 0; i < context_list.length; i++) {
			if (context_list[i].code === context_item_code) {
				context_item_not_found = false;
				context_list[i] = value;
			}
		}
		// creazione di un context_application_item ancora non esistente nel form
		if (context_item_not_found) {
			context_list[context_list.length] = value;
		}
		return context_list;
	}

	/**
	 * Verifica la presenza di almeno una permission associata alla general_context_key nella lista di permission
	 * passata
	 *
	 * @param {ContextApplicationItemCodeEnum} key
	 * @param {PermissionItem[]} permissionList
	 * @returns {boolean}
	 * @memberof PermissionUtilService
	 */
	isAnyContextGeneralPermission(key: ContextApplicationItemCodeEnum, permissionList: PermissionItem[]): boolean {
		for (let i = 0; i < permissionList.length; i++) {
			if (permissionList[i].context_application.includes(key)) {
				return true;
			}
		}
		return false;
	}

	// TODO - in questo modello current_permission è un array
	returnUserBackofficeContextCodeAssociated(
		user: UserDetailModel,
		context_application_item_code: ContextApplicationItemCodeEnum
	): string[] {
		const context_code_list: string[] = [];

		if (
			user.current_permission.context_code &&
			user.current_permission.context_code.context_code_association_list &&
			user.current_permission.context_code.context_code_association_list.length > 0
		) {
			const user_context_code_association: ContextCodeAssociationItem = user.current_permission.context_code.context_code_association_list.find(
				i => i.code === context_application_item_code
			);
			if (
				user_context_code_association &&
				user_context_code_association.context_code_list &&
				user_context_code_association.context_code_list.length > 0
			) {
				for (let i = 0; i < user_context_code_association.context_code_list.length; i++) {
					context_code_list.push(
						// TODO: eliminare il toString() una volta stabilizzata la tipizzazione
						user_context_code_association.context_code_list[i].code.toString()
					);
				}
			}
		}
		return context_code_list;
	}

	/**
	 * Check if user can access, for current context, to commons databases such as `commons_organization` or `commons_article`
	 * through flag `context_code_list_all`
	 * @param user current user
	 * @param context_application_item_code current context code
	 */
	checkIfUserCanAccessCommons(
		user: UserDetailModel,
		context_application_item_code: ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum | string
	): string[] | boolean {
		if (
			user.current_permission.context_code &&
			user.current_permission.context_code.context_code_association_list &&
			user.current_permission.context_code.context_code_association_list.length > 0
		) {
			const user_context_code_association: ContextCodeAssociationItem = user.current_permission.context_code.context_code_association_list.find(
				i => i.code === context_application_item_code
			);
			if (user_context_code_association && user_context_code_association.context_code_list_all) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Estrae dalla mappa il contesto da verificare, attraverso `checkIfUserCanAccessCommons`, per il tipo di stato attuale
	 */
	getPermissionToCheckForStateType(documentType: PouchDbModel.PouchDbDocumentType) {
		// cicla sulla mappa. Se c'è corrispondenza, restituisci il valore corretto, altrimenti null
		for (const [context, state] of PouchDbConstant.contextStateMap) {
			if (state.indexOf(documentType as PouchDbModel.PouchDbDocumentType) != -1) {
				return context;
			}
		}
		return null;
	}

	/**
	 * Il metodo restituisce `true` in caso l'utente sia nel context application B2B o B2C
	 *
	 * @param {UserDetailModel} user
	 * @returns {boolean}
	 * @memberof PermissionUtilService
	 */
	isOrganizationCodeItem(user: UserDetailModel): boolean {
		if (
			user.current_permission.context_application === ContextApplicationItemCodeEnum.B2B ||
			user.current_permission.context_application === ContextApplicationItemCodeEnum.B2C
		) {
			return true;
		}
		return false;
	}
	/** il metodo restituisce true se l'utente ha una certa permission - usato per visibilità widget nella dashboard **/ 
	userHasPermission(user: UserDetailModel, permission: PermissionKeyEnum): boolean {
		return user.current_permission.permission.includes(permission)?true:false;
		}

}
