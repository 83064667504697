import { createAction, props } from '@ngrx/store';
import { ArticleRecap } from '../../model/state/article-list-state.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { ArticleRecapLoadFilter } from '../../model/article.model';

export enum ArticleActionEnum {
	UPDATE = '[article] Update',
	UPDATE_ARTICLE = '[article] Update article',
	LOAD_FROM_RECAP = '[article] Load from recap',
	RESET = '[article] Reset',
	ERROR = '[article] Error'
}

export namespace ArticleStateAction {
	export const loadFromRecap = createAction(ArticleActionEnum.LOAD_FROM_RECAP, props<BaseStateModel<ArticleRecap, ArticleRecapLoadFilter>>());
	export const update = createAction(ArticleActionEnum.UPDATE, props<BaseStateModel<ArticlePouchModel[]>>());
	export const updateArticle = createAction(
		ArticleActionEnum.UPDATE_ARTICLE,
		props<BaseStateModel<ArticlePouchModel>>()
	);
	export const reset = createAction(ArticleActionEnum.RESET);
	export const error = createAction(ArticleActionEnum.ERROR, props<BaseStateModel<any>>());
}
