import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as merge from 'deepmerge';

export interface ITranslationResource {
	prefix?: string;
	suffix?: string;
	configKey?: string; // Una delle chavi di configurazioni delle traduzioni esistenti in environment es. mc_config
}

export class TranslateMultiHttpLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		private customerAppConfig: any,
		private resources: ITranslationResource[]
	) {}

	public getTranslation(lang: string): Observable<any> {
		// Resolve config/urls
		const resourcesToLoad = this.resources.map(resource => {
			if (resource.configKey) {
				const cfg = this.customerAppConfig[resource.configKey];
				return <ITranslationResource>{
					prefix: './' + cfg.translation.default_translation_path + '/',
					suffix: cfg.translation.type
				};
			} else {
				return resource;
			}
		});

		// Load resources
		const requests = resourcesToLoad.map(resource => {
			const path = resource.prefix + lang + resource.suffix;
			return this.http.get(path).pipe(
				catchError(res => {
					console.error('Something went wrong for the following translation file:', path);
					console.error(res.message);
					return of({});
				})
			);
		});

		return forkJoin(requests).pipe(map(response => merge.all(response)));
	}
}
