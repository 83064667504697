import { ITdDataTableColumnCustom } from "@saep-ict/pouch_agent_models";

export const baseColumn: ITdDataTableColumnCustom[] = [  

    {
        name: 'description',
        labelPath: 'offer.line.field.description',
        label: null
    },
    {
        name: 'price',
        labelPath: 'offer.line.field.price',
        label: null,
        numeric: true
    },
    {
        name: 'discount_agent.value',
        labelPath: 'offer.line.field.discount',
        label: null,
        numeric: true,
        format: (v: number) => v + '%'
    },
    {
        name: 'price_total',
        labelPath: 'offer.line.field.price_total',
        label: null,
        numeric: true
    },
    {
        name: 'actions',
        labelPath: '',
        label: null,
        numeric: true
    }
];