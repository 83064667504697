<div
  class="mc-media-center-container"
  [style.position]="utilService.context.value.filter.loaderExpandBeyondLibrary ? 'unset' : 'relative'"
>
  <loading-overlay [loaderServiceInstance]="loaderService"></loading-overlay>
  <ng-container *ngIf="customHeaders; else noPermissionTemplate">
    <process-queue
      [webSocketChannelList]="rxStompService.channelList"
      (processStop)="
        rxStompService.processStop($event.idChannel, $event.idProcess)
      "
    ></process-queue>
    <media-center-home *ngIf="!utilService.context.value.filter.latest"></media-center-home>
    <media-center-latest *ngIf="utilService.context.value.filter.latest"></media-center-latest>
  </ng-container>
  <ng-template #noPermissionTemplate>
    <div class="container">
      <div class="row">
        <div class="col-12">
          {{ 'mc.general.no_user_permission' | translate }}
        </div>
      </div>
    </div>
  </ng-template>
</div>