import { Injectable } from '@angular/core';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoryDetailAction, CategoryDetailActionEnum } from './category-detail.actions';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Category } from '@saep-ict/pouch_agent_models';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';

@Injectable()
export class CategoryDetailEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoryDetailActionEnum.LOAD),
			mergeMap((e: { id: string }) => from(this.commonsAdapter.basePouch.getDetail<Category>(e.id))),
			map((e: Category) => CategoryDetailAction.update(new BaseState(e))),
			catchError(() => of({ type: CategoryDetailActionEnum.ERROR }))
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoryDetailActionEnum.SAVE),
			mergeMap((e: BaseStateModel<Category>) => from(this.commonsAdapter.basePouch.saveBodyTable<Category>(e.data))),
			map((e) => CategoryDetailAction.saveUpdate()),
			catchError(() => of({ type: CategoryDetailActionEnum.ERROR }))
		)
	);

	constructor(
		private actions$: Actions,
		private commonsAdapter: PouchDbCommonsAdapter
	) {}

}
