import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { WebSocketChannel } from '../../model/websocket.model';

@Component({
	selector: 'process-queue',
	templateUrl: './process-queue.component.html',
	styleUrls: ['./process-queue.component.scss']
})
export class ProcessQueueComponent {
	@ViewChild('scrollbar', { static: false }) scrollbar: NgScrollbar;
	@Input() webSocketChannelList: WebSocketChannel<any, any>[];
	@Output() processStop = new EventEmitter<{ idChannel: string, idProcess: string }>();

	public totalHeight = 0;
	isExpanded = true;

	constructor() {}

	getTotalHeight() {
		const listItemHeight = 56;
		const height = this.webSocketChannelList.length > 3 ? listItemHeight * 3 : this.webSocketChannelList.length * listItemHeight;
		return height + 'px';
	}

	toggleExpandedState() {
		this.isExpanded = !this.isExpanded;
		this.scrollbar.scrollTo({ bottom: 0 });
	}

}
