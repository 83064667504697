import * as _ from 'lodash';
import { BaseStateSerializerModel } from '../../model/state/base-state.model';
import { ListSetting, LocalListHandlerBaseModel } from "@saep-ict/pouch_agent_models";

export class UtilListSetting {

  public static returnListSettingForStoreUpdate<T>(e: LocalListHandlerBaseModel<T>):
  BaseStateSerializerModel<ListSetting<T>> {
    return {
      data: {
        pageName: e.pageName,
        filters: e.filters,
        pagination: e.pagination,
        sort: e.sort,
        sortRemapObject: e.sortRemapObject,
        languageKey: e.languageKey
      },
      stateSlice: {
        type: e.pageName
      }
    };
  };

  public static returnListSettingForLocalListHanlderBaseObject<T>(
    localListHandler: LocalListHandlerBaseModel<T>,
    listSetting: ListSetting
  ): LocalListHandlerBaseModel<T> {
    if (listSetting) {
      localListHandler.filters = listSetting.filters;
      localListHandler.pagination = listSetting.pagination;
      localListHandler.sort = listSetting.sort;
      localListHandler.languageKey = listSetting.languageKey;
    }
    return localListHandler;
  };

}
