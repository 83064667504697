import { Inject, Injectable, Optional } from '@angular/core';
import { IResourceActionInner, IResourceResponse, Resource, ResourceParams } from '@ngx-resource/core';
import { ILoaderService, LOADER_SERVICE_TOKEN } from '../../model/loader-service.interface';
import { APP_CONFIG_TOKEN, ITicketCenterAppConfig,  } from '../../model/lib-app-config.interface';
import { ILoaderExceptionCall } from '../../model/ILoaderExceptionCall';
import { Observable } from 'rxjs';
import { BaseStateModel } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../store';
import { Api, UserDetailModel } from '@saep-ict/angular-spin8-core';

@Injectable()
@ResourceParams({
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT, DELETE',
		'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
	},
})
export class BaseApiService extends Resource {

  user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserStore);
	user: UserDetailModel;

	loaderExceptions: ILoaderExceptionCall[];

	constructor(
		@Optional() @Inject(LOADER_SERVICE_TOKEN) protected loaderService: ILoaderService,
		@Inject(APP_CONFIG_TOKEN) protected appConfig: ITicketCenterAppConfig,
    public store: Store<any>
	) {
		super();
    this.appConfig['config$'].subscribe(config => {
			if (config && config.ticket_center && config.ticket_center.be_url) {
        let urlPrefix = config.ticket_center.be_url;
        if (config.ticket_center.suffix) {
          urlPrefix += config.ticket_center.suffix;
        }
        super.$setPathPrefix(urlPrefix);
			}
		});
    this.user$.pipe().subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	$restAction(options: IResourceActionInner): any {
		const exception =
			this.loaderExceptions &&
			this.loaderExceptions.find((exc) => {
				return (
					options.actionOptions.path === exc.path &&
					options.actionOptions.method === exc.method
				);
			});

		if (!exception && this.loaderService) {
			const guid = this.loaderService.populateLoader(
				options.actionOptions.path
			);
			options.returnData = { id: guid };
		}

		const token = this.appConfig.token
			? 'bearer ' + this.appConfig.token
			: 'token';

		this.$setHeaders({
			Authorization: token
		});

    if ( this.user &&
        this.user.current_permission &&
        this.user.current_permission.context_application &&
        this.user.current_permission.context_code) {
			const headers = this.$getHeaders();
			headers['context-code'] = this.user.current_permission.context_code.code;
      // TODO: eliminare .toString() dopo il passaggio a stringa di context application
			headers['context-application'] = this.user.current_permission.context_application;
      headers['Key']= Api.key.key
			this.$setHeaders(headers);
		}

		return super.$restAction(options);
	}

	$handleSuccessResponse(
		options: IResourceActionInner,
		resp: IResourceResponse
	): any {
		if (options.returnData && options.returnData.id && this.loaderService) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}
		return super.$handleSuccessResponse(options, resp);
	}

	$handleErrorResponse(
		options: IResourceActionInner,
		resp: IResourceResponse
	): any {
		if (options.returnData && options.returnData.id && this.loaderService) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}

		return super.$handleErrorResponse(options, resp);
	}

	protected setPathPrefix(pathPrefix: string) {
		super.$setPathPrefix(pathPrefix);
	}

}
