import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleDescriptionStateAction, ArticleDescriptionActionEnum } from './article-description.actions';
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ArticleRecap } from '../../model/state/article-list-state.model';

export namespace ArticleDescriptionStateReducer {
	export const initialState: BaseStateModel<ArticleRecap> = null;

	const _reducer = createReducer(
		initialState,
		on(ArticleDescriptionStateAction.loadDescriptionFromRecap, state => state),
		on(ArticleDescriptionStateAction.update, (state, articleDescription) => articleDescription),
		on(ArticleDescriptionStateAction.saveComplete, (state, articleDescription) => articleDescription),
		on(ArticleDescriptionStateAction.reset, () => initialState),
		on(ArticleDescriptionStateAction.error, (state, articleDescription) => ({
			data: state ? state.data : null,
			type: articleDescription.type
		}))
	);

	export function reducer(state: BaseStateModel<ArticleRecap>, action: TypedAction<ArticleDescriptionActionEnum>) {
		return _reducer(state, action);
	}
}
