import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';

@Component({
	selector: 'contact-list-wrapper',
	templateUrl: './contact-list-wrapper.component.html',
	styleUrls: ['./contact-list-wrapper.component.scss']
})
export class ContactListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() toggleHasNotification = new EventEmitter<{ row: ContactPouchModel }>();
	@Output() openDialogConfirmRemoveContact = new EventEmitter<ContactPouchModel>();
	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();
	@Output() openDialogAddAction: EventEmitter<ContactPouchModel> = new EventEmitter();
	@Output() openDialogActionList: EventEmitter<ContactPouchModel> = new EventEmitter();
	@Output() openAnalytics: EventEmitter<ContactPouchModel> = new EventEmitter();
	@Output("openDialogContactDetail") openDialogContactDetailEvent: EventEmitter<any> = new EventEmitter();
	@Input() disabledOrderConfirmEmail: boolean;
	@Input() currentContext: ContextApplicationItemCodeEnum;

	public companyContact = ConfigurationCustomerAppStructure.contact;
	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store
	) {
		super(utilService, translateService, store);
	}

}
