import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export const base: ITdDataTableColumnCustom[] = [
	{
		name: 'ref',
		labelPath: 'article.field.ref',
		label: null
	},
	{
		name: 'brand',
		labelPath: 'product.brand.label',
		label: null
	},
	{
		name: 'line',
		labelPath: 'product.line.label',
		label: null
	},
	{
		name: 'product',
		labelPath: 'product.name',
		label: null
	},
	{
		name: 'image',
		labelPath: 'general.image.name',
		label: null,
		width: 100
	},
	{
		name: 'action',
		labelPath: 'action.name',
		label: null,
		width: 100
	}
];

export const forBrand: string[] = ['brand', 'image', 'action'];
export const forLine: string[] = ['line', 'image', 'action'];
export const forLeaf: string[] = ['ref', 'product', 'image', 'action'];
