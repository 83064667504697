<div class="main-toolbar">
	<!-- <span class="sidenav__logo_client">
		<div class="logo-container"></div>
	</span> -->
	<div class="sidenav__breadcrumb-container">
		<h1 *ngIf="utilBreadcrumbService.title">
            {{ utilBreadcrumbService.title.value | translate: utilBreadcrumbService.title.param | sentencecase }}
        </h1>
		<h2 *ngIf="utilBreadcrumbService.subtitle">
            {{ utilBreadcrumbService.subtitle.value | translate: utilBreadcrumbService.subtitle.param | sentencecase }}
        </h2>
	</div>
    <div class="right-content-wrapper">
        <div class="main-toolbar__icon-container">
            <ng-container *ngFor="let icon of iconList">
                <ng-container *ngIf="icon.visible">
                    <button
                        *ngIf="icon.link; else iconTemplate"
                        mat-icon-button
                        [routerLink]="['./', icon.link]"
                    >
                        <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
                    </button>
                    <ng-template #iconTemplate>
                        <mat-icon [matTooltip]="icon.description | translate | sentencecase">{{ icon.matIconKey }}</mat-icon>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
        <ms-toolbar-user-button></ms-toolbar-user-button>
    </div>
</div>
<mat-divider class="bottom-spacing"></mat-divider>
