<div class="container-fluid">
	<div class="row" *ngIf="offer && attachmentListManagerConfiguration">
		<div class="col-12">
			<attachment-list-manager
				[configuration]="attachmentListManagerConfiguration"
				(attachmentListchange)="attachmentListManagerChange($event)"
			>
			</attachment-list-manager>
		</div>
	</div>
</div>
