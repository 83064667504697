import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseState, BaseStateModel, SentencecasePipe, SubscribeManagerService } from '@saep-ict/angular-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { UtilOrderService } from '../../../../../service/util/util-order.service';
import { OfferOtherDescriptionModel, OfferStateModel } from '@saep-ict/angular-spin8-core';
import { StateFeature } from '../../../../../state';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../../state/opportunity/opportunity.actions';
import { OrderActionEnum, OrderStateAction } from '../../../../../state/order/order.actions';
import {
	AuxiliaryTableStateModel,
	OrderStateModel,
	ROUTE_URL,
	OrganizationStateModel,
	AngularSpin8CoreUtilOfferService,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';
import { StoreUtilService } from '../../../../../service/util/store-util.service';

@Component({
	selector: 'offer-detail-head',
	templateUrl: './offer-detail-head.component.html',
	styleUrls: ['./offer-detail-head.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferDetailHeadComponent implements OnInit, OnDestroy {
	form: FormGroup;
	public offerStatusEnum = OrderStatusEnum;

	// state
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	offer: OfferStateModel;

	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunity: OpportunityPouchModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	constructor(
		private fb: FormBuilder,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router,
		public activatedRoute: ActivatedRoute,
		private utilOfferService: AngularSpin8CoreUtilOfferService,
		private utilStoreService: StoreUtilService,
	) {}

	ngOnInit() {
		this.createForm();

		this.subscribeManagerInit();

		this.organization$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1),
				map(state => {
					this.organization = state.data;
					return state;
				})
			)
			.subscribe();

		this.utilStoreService
			.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$)
			.pipe(
				filter(
					(auxiliaryTable: BaseStateModel<AuxiliaryTableStateModel>) =>
						!!(auxiliaryTable && auxiliaryTable.data)
				)
			)
			.subscribe(res => {
				this.auxiliaryTable = res.data;
			});
	}

	// Subscribe
	subscribeManagerInit() {
		this.subscribeManagerService.populate(this.subscribeInitialData().subscribe(), 'initial-data');
	}
	subscribeInitialData() {
		return this.order$.pipe(
			filter(state => !!(state && state.data)),
			mergeMap(state => {
				switch (state.type) {
					case OrderActionEnum.UPDATE:
						// al primo save aggiorno l'url
						if ((!this.offer || !this.offer._id) && state.data._id) {
							this.router.navigate([this.router.url.replace(ROUTE_URL.newOffer, state.data._id)]);
						}
						// Non mostrare la dialog al primo update
						if (this.offer) {
							this.showDialog('offer.save');
						}

						this.offer = <OfferStateModel>state.data;
						break;

					default:
						break;
				}
				return this.opportunity$;
			}),
			filter(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:
						this.opportunity = opportunityState.data[0];
						this.setFormFromRemoteData();
						break;

					default:
						break;
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// reset dello state offer nel padre
		this.store.dispatch(OpportunityStateAction.reset());
	}

	/**
	 * Form
	 */
	getFormControl(key: string): FormControl {
		return <FormControl>this.form.get(key);
	}

	createForm() {
		this.form = this.fb.group({
			title: ['', { validators: [Validators.required] }],
			first_evasion_date: ['', { validators: [Validators.required] }],
			payment_method: ['', { validators: [Validators.required] }],
			other_descriptions: ['', { validators: [] }],
			object: ['', { validators: [] }],
			summary: ['', { validators: [] }],
			references: ['', { validators: [] }],
			premise: ['', { validators: [] }]
		});
		this.subscribeManagerService.populate(this.onChangeForm().subscribe(), 'on-change-form');
	}

	onChangeForm() {
		return this.form.valueChanges.pipe(
			debounceTime(500),
			tap(() => {
				if (this.form.valid) {
					const newOffer = this.utilOfferService.getPayloadToSaveOffer(
						this.prepareSaveForm(),
						this.offer._id,
						this.organization
					);
					this.store.dispatch(OrderStateAction.save(new BaseState(newOffer.orderData.order)));
				}
			})
		);
	}

	prepareSaveForm(): OfferStateModel {
		let offer: OfferStateModel = _.cloneDeep(this.offer);
		const formValue = this.form.value;
		offer.header.custom_field.title = formValue.title;
		offer.header.first_evasion_date = formValue.first_evasion_date.valueOf();
		offer.header.payment_code = formValue.payment_method;

		offer.header.custom_field.other_descriptions = <OfferOtherDescriptionModel>{};

		if (formValue.other_descriptions) {
			offer.header.custom_field.other_descriptions.object = formValue.object;
			offer.header.custom_field.other_descriptions.summary = formValue.summary;
			offer.header.custom_field.other_descriptions.references = formValue.references;
			offer.header.custom_field.other_descriptions.premise = formValue.premise;
		} else {
			delete offer.header.custom_field.other_descriptions;
		}

		// Assegno l'opportunity solo in fase di creazione
		// così si cristallizza la versione di opportunity
		// per la quale l'offerta è stata scritta
		if (!this.offer.header.custom_field.opportunity) {
			offer.header.custom_field.opportunity = {} as OpportunityPouchModel;
			offer.header.custom_field.opportunity.code_item = this.opportunity.code_item;
			offer.header.custom_field.opportunity.title = this.opportunity.title || '';

			offer.header.organization.code_item = this.opportunity.organization.code_item;
			offer.header.organization.business_name =
				this.opportunity.organization?.business_name || this.organization.business_name;
		}

		offer.header.date = Date.now();
		offer.header.status = this.offer?.header?.status || OrderStatusEnum.DRAFT;

		return offer;
	}

	setFormFromRemoteData() {
		const otherDescription = this.offer.header.custom_field?.other_descriptions || null;

		this.form.patchValue(
			{
				title: this.offer.header.custom_field?.title || this.opportunity?.title,
				first_evasion_date: this.offer.header.first_evasion_date
					? moment(this.offer.header.first_evasion_date)
					: moment(this.opportunity?.estimated_close_date),
				payment_method: this.offer.header.payment_code,

				other_descriptions: otherDescription ? true : false,
				object: otherDescription ? otherDescription.object : null,
				summary: otherDescription ? otherDescription.summary : null,
				references: otherDescription ? otherDescription.references : null,
				premise: otherDescription ? otherDescription.premise : null
			},
			{ emitEvent: false }
		);
	}

	/**
	 * Utility
	 */

	showDialog(msg: string, action: string = null, duration: number = 3000) {
		msg = this.sentencecasePipe.transform(this.translateService.instant(msg));
		this.snackBar.open(msg, action, { duration: duration });
	}
}
