import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ListSetting } from '@saep-ict/pouch_agent_models';
import { BaseStateSerializerModel } from '../model/state/base-state.model';


// model
export namespace StoreFeature {
	export const getListSettingStore = createFeatureSelector<BaseStateSerializerModel<ListSetting>[]>('list-setting');

	export const selectListSettingSliceStore = createSelector(
		getListSettingStore,
		(state: BaseStateSerializerModel<ListSetting>[], key: string) => {
			return state.find(i => i.stateSlice.type === key);
		}
	);
}
