<mat-dialog-wrapper [title]="data.title" [showCloseIcon]="false">
	<ng-container content>
		<div *ngIf="productList && productList.length > 0; else noDataFound">
			<td-data-table #dataTable [data]="productList" [columns]="columns">
				<!-- Description -->
				<ng-template tdDataTableTemplate="description" let-row="row">
					<span [matTooltip]="row.description">
						{{ row.description }} <br />
						<small>{{ row.description_add }}</small>
					</span>
				</ng-template>

				<!-- article price -->
				<ng-template tdDataTableTemplate="articlePrice.price" let-value="value" let-row="row">
					<span>
						{{ value | currency: 'EUR':'symbol':'':'it' }}<br />
						<!-- <small class="cell-subtitle d-block" [innerHTML]="retrieveArticlePrice(row)"> </small> -->
					</span>
				</ng-template>

				<!-- quantity -->
				<ng-template tdDataTableTemplate="productDetail.input_quantity" let-value="value" let-row="row">
					<span>
						{{ value ? value : 0 }}
					</span>
				</ng-template>

				<!-- Articoli gratuiti -->
				<ng-template tdDataTableTemplate="productDetail.qty_free" let-value="value" let-row="row">
					<span>
						{{ value ? value : 0 }}
					</span>
				</ng-template>

				<ng-template tdDataTableTemplate="calculate_price" let-value="value" let-row="row">
					<span>
						{{
							(row.productDetail?.input_quantity ? getCalculatePrice(row) : 0)
								| currency: 'EUR':'symbol':'':'it'
						}}
					</span>
				</ng-template>
			</td-data-table>
		</div>
		<ng-template #noDataFound>
			<p>Non ci sono risultati...</p>
		</ng-template>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.back_catalog' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
