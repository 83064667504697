<!-- TODO: VERIFICARE SE IL COMPONENTE SERVE -->

<form class="michelangelo-theme" [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
		<ng-container content>
			<!-- TODO -->
			<!-- <company-detail-destination-new></company-detail-destination-new> -->
		</ng-container>

		<ng-container actions>
			<form-button-bar
				[primaryButtonLabel]="'destination.add_new' | translate | sentencecase"
				[primaryButtonIsSubmit]="true"
				[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
				(secondaryButtonClickEmitter)="dialogRef.close(false)"
				align="right"
			></form-button-bar>
		</ng-container>
	</mat-dialog-wrapper>
</form>
