import { Injector } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { ArticleCheckoutTree, ArticlePouchModel, Category } from '@saep-ict/pouch_agent_models';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	AngularSpin8CoreUtilTranslateService
} from '@saep-ict/angular-spin8-core';
import * as ConfigurationCategory from '../../category.constant';
import * as CategoryEnum from '../../../enum/category.enum';
import * as StatisticModel from '../../../model/statistics.model';
import * as StatisticEnum from '../../../enum/statistic.enum';
import * as _ from 'lodash';

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

const utilService = injector.get(AngularCoreUtilService);

export const returnNestedCategoryParse = (user: UserDetailModel, e: Category[]): Category[] => {
    const categoryReturn: Category[] = _.cloneDeep(e[0].category_list);
    switch (user.current_permission.context_application) {
        case ContextApplicationItemCodeEnum.B2B:
            for (let i = 0; i < categoryReturn.length; i++) {
                delete categoryReturn[i].category_list;
            }
            break;
        default:
            break;
    }
    return categoryReturn;
};
export const categoryTreeWithArticleParse = async (
    user: UserDetailModel,
    tree: Category[],
    articleList: ArticlePouchModel[],
    utilTranslateService: AngularSpin8CoreUtilTranslateService
): Promise<ArticleCheckoutTree[]> => {
    try {
        // TODO: sistemare questa tipizzazione durante il riordino massivo di tutte le dinamiche relative
        // ad ArticleCheckoutTree
        let categoryReturn = tree as ArticleCheckoutTree[];
        switch (user.current_permission.context_application) {
            case ContextApplicationItemCodeEnum.B2B: {
                break;
            }
            default: {
                const articleListReturn: ArticlePouchModel[] = [];
                for (const article of articleList) {
                    if (
                        article.articleDescription &&
                        Object.keys(article.articleDescription).length > 0 &&
                        article.articleDescription.free_sample !== 'S'
                    ) {
                        articleListReturn.push(article);
                    }
                }
                categoryReturn = await ConfigurationCategory.returnArticleCheckoutTree(
                    articleListReturn,
                    categoryReturn,
                    utilTranslateService.languages
                );
                const articleListWithoutCategory: number = utilService.getElementIndex(
                    categoryReturn,
                    'code_item',
                    CategoryEnum.CodeItem.ARTICLE_LIST_WITHOUT_CATEGORY
                );
                if (articleListWithoutCategory || articleListWithoutCategory === 0) {
                    categoryReturn.splice(articleListWithoutCategory, 1);
                }
                break;
            }
        }
        return categoryReturn;
    } catch (err) {
        throw new Error(err);
    }
};
export const returnCategoryListFilter = (categoryList: Category[]): Category[] => {
    categoryList = _.cloneDeep(categoryList);
    const categoryListReturn: Category[] = categoryList[0].category_list;
    categoryListReturn.forEach(i => {
        delete i.category_list;
    });
    return categoryListReturn;
};
export const calculateSummaryTrend = (summary: StatisticModel.Base[]): StatisticModel.Base[] => {
    const summaryPresent: StatisticModel.Base = summary.find(
        i => i.key === StatisticEnum.Key.PRESENT
    );
    const summaryPast: StatisticModel.Base = summary.find(i => i.key === StatisticEnum.Key.PAST);
    if (summaryPresent && summaryPast && summaryPresent.total && summaryPast.total) {
        for (const itemUnit in StatisticEnum.Unit) {
            const itemUnitName: string = StatisticEnum.Unit[itemUnit];
            if (!summaryPresent.trend) {
                summaryPresent.trend = <StatisticModel.ValueType>{};
            }
            summaryPresent.trend[itemUnitName] =
                (summaryPresent.total[itemUnitName] / summaryPast.total[itemUnitName]) * 100 - 100;
        }
    }
    return summary;
};
export const comparisonYearList = (): number[] => {
    const goBackTill = 2;
    const yearCurrent: number = new Date().getFullYear();
    const comparisonYearList: number[] = [];
    for (let i = 1; i <= goBackTill; i++) {
        comparisonYearList.push(yearCurrent - i);
    }
    return comparisonYearList;
};
export const timeRangeSelector: StatisticModel.TimeRangeSelector[] = [
    {
        key: 'days',
        value: 7,
        label: 'statistic.time_range.last_7_days'
    },
    {
        key: 'months',
        value: 1,
        label: 'statistic.time_range.last_month'
    },
    {
        key: 'months',
        value: 6,
        label: 'statistic.time_range.last_6_months'
    }
];