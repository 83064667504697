<div class="dialog__header" *ngIf="title">
	<h2 mat-dialog-title class="dialog__title">{{ title }}</h2>
	<button mat-icon-button (click)="dialogRef.close()" *ngIf="showCloseIcon">
		<mat-icon class="color-gray">clear</mat-icon>
	</button>
</div>

<ng-scrollbar 
	[autoWidthDisabled]="true" 
	[autoHeightDisabled]="false"
	[style.max-height.px]="mediaReplayService.deviceH - 180"
>
	<div scrollViewport class="custom-viewport" [class.hidden]="!showContent">
		<div class="scroll-content-wrapper" #ngContentContent>
			<ng-content select="[content]"></ng-content>
		</div>
	</div>
</ng-scrollbar>

<mat-dialog-actions 
	align="end" 
	#ngContentActions 
	[class.hidden]="!showActions"
>
	<ng-content select="[actions]"></ng-content>
</mat-dialog-actions>
