<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ 'general.items_number' | translate | sentencecase }}
		<span class="badge ml-3">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<!-- ID -->
		<ng-template tdDataTableTemplate="_id" let-row="row">
			<p>
				<a href="javascript:void(0)" (click)="onItemSelect.emit(row)">
					{{ row.id_first_row }}
				</a>
				<br />
				<small class="cell-subtitle" *ngIf="row.id_second_row">
					{{ row.id_second_row }}
				</small>
			</p>
		</ng-template>
		<!-- Organization -->
		<ng-template tdDataTableTemplate="header.organization.business_name" let-value="value" let-row="row">
			<p>
				<a
					href="javascript:void(0)"
					(click)="selectOrganization.emit({ level: 'no-filter', code: row.header.organization.code_item })"
				>
					{{ value | titlecase }}
				</a>
				<br />
				<small class="cell-subtitle">{{
					row.header.custom_field?.opportunity?.organization?.tax_data?.vat_number ||
						row.header.custom_field?.opportunity?.organization?.tax_data?.tax_code ||
						'-'
				}}</small>
			</p>
		</ng-template>
		<!-- Price total -->
		<ng-template tdDataTableTemplate="header.price.total" let-row="row">
			<div *ngIf="utilPriceService.updateOrderPriceMap(row) && utilPriceService.updateOrderPriceMap(row).total">
				{{ utilPriceService.updateOrderPriceMap(row).total.value | currency: 'EUR':'symbol':'':'it' }}
			</div>
		</ng-template>
		<!-- Status -->
		<ng-template tdDataTableTemplate="header.status" let-value="value">
			<div class="chips-mini">
				<span
					[ngClass]="value"
					matTooltip="{{ 'saep_ict_angular_spin8_core.status.order.' + value | translate | sentencecase }}"
				></span>
			</div>
		</ng-template>
		<!-- Attachment -->
		<ng-template tdDataTableTemplate="attachment" let-row="row">
			<button
				mat-icon-button
				[matTooltip]="'attachment.name' | translate | sentencecase"
				(click)="goToAttachmentList(row)"
			>
				<mat-icon [ngClass]="{ attachmentPresent: row.attachment_list && row.attachment_list.length > 0 }"
					>attach_file</mat-icon
				>
			</button>
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
