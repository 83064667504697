<h1 mat-dialog-title>{{ "mc.general.delete" | translate | titlecase }}</h1>
<div mat-dialog-content>
  <span>{{ "mc.dialog_delete.question" | translate }}</span>
  <mat-list dense role="list">
    <mat-list-item *ngFor="let item of data" role="listitem">
      {{ item }}
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ "mc.general.cancel" | translate | uppercase }}
  </button>
  <button mat-button (click)="submit()" mat-raised-button color="primary">
    {{ "mc.general.confirm" | translate | uppercase }}
  </button>
</div>
