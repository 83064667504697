import { NgModule } from '@angular/core';
import { FilterByAllTheBooleansPipe } from './filter-by-boolean.pipe';
import { FilterByStringPipe } from './filter-by-string.pipe';
import { FormatMultiplesCurrency } from './format-multiples-currency.pipe';
import { GuidFormatterPipe } from './guid-formatter.pipe';
import { SafeDomSanitizerPipe } from './safe-dom-sanitizer.pipe';
import { SentencecasePipe } from './sentencecase.pipe';
import { ThousandPipe } from './thousand-separator.pipe';

@NgModule({
	declarations: [
		SentencecasePipe,
		SafeDomSanitizerPipe,
		ThousandPipe,
		FilterByStringPipe,
    FilterByAllTheBooleansPipe,
    GuidFormatterPipe,
		FormatMultiplesCurrency
	],
	exports: [
		SentencecasePipe,
		SafeDomSanitizerPipe,
		ThousandPipe,
		FilterByStringPipe,
    FilterByAllTheBooleansPipe,
    GuidFormatterPipe,
		FormatMultiplesCurrency
	],
	providers: [
		SentencecasePipe,
		SafeDomSanitizerPipe,
		ThousandPipe,
		FilterByStringPipe,
    FilterByAllTheBooleansPipe,
    GuidFormatterPipe,
		FormatMultiplesCurrency
	]
})
export class PipeModule { }
