import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	DialogConfirmComponent,
	DialogTextEditComponent,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StateFeature } from '../../../../../state';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../../state/opportunity/opportunity.actions';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserDetailModel, CommentStateModel, OpportunityPouchModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'opportunity-detail-comment',
	templateUrl: './opportunity-detail-comment.component.html',
	styleUrls: ['./opportunity-detail-comment.component.scss'],
	providers: [SubscribeManagerService]
})
export class OpportunityDetailCommentComponent implements OnInit, OnDestroy {
	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	addCommentValue = '';

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private dialog: MatDialog,
		private translate: TranslateService
	) {
		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');

		// retrieve user
		this.user$.pipe(take(1)).subscribe(user => {
			this.user = user.data;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// Reset dello state opportunity nel componente padre
	}

	subscribeOpportunity() {
		return this.opportunity$.pipe(
			filter(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:
						this.opportunity = opportunityState.data[0];
						this.addCommentValue = '';
						break;

					default:
						break;
				}
			})
		);
	}

	addComment() {
		const comment: CommentStateModel = {
			date_creation: moment().valueOf(),
			user_id: this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : this.user.username,
			text: this.addCommentValue
		};
		this.opportunity = this.prepareOpportunity(comment);
		this.store.dispatch(OpportunityStateAction.save(new BaseState([this.opportunity])));
	}

	deleteComment(index) {
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.translate.instant('comment.question.delete')
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let opportunity = _.cloneDeep(this.opportunity);
				opportunity.comment_list.splice(index, 1);
				this.store.dispatch(OpportunityStateAction.save(new BaseState([opportunity])));
			}
		});
	}

	editComment(index) {
		const dialogRef: MatDialogRef<DialogTextEditComponent> = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: this.translate.instant('comment.edit'),
				text: this.opportunity.comment_list[index].text
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let opportunity = _.cloneDeep(this.opportunity);
				opportunity.comment_list[index].text = res;
				this.store.dispatch(OpportunityStateAction.save(new BaseState([opportunity])));
			}
		});
	}

	prepareOpportunity(comment: CommentStateModel): OpportunityPouchModel {
		let opportunity = _.cloneDeep(this.opportunity);
		if (opportunity.comment_list) {
			opportunity.comment_list.push(comment);
		} else {
			opportunity.comment_list = [comment];
		}
		return opportunity;
	}
}
