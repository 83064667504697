<div class="wrapper">
	<div class="row-wrapper">
		<div class="row-item">
			<div class="header">
				{{ 'saep_ict_angular_spin8_core.page.organization.business_name' | translate | sentencecase }}
			</div>
			<div class="content" *ngIf="organization.business_name; else noData">{{ organization.business_name }}</div>
		</div>
		<div class="row-item">
			<div class="header">
				{{ 'organization.field.sdi' | translate | sentencecase }}
			</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.sdi_code; else noData">
				{{ organization.tax_data.sdi_code }}
			</div>
		</div>
	</div>

	<div class="row-wrapper">
		<div class="row-item">
			<div class="header">
				{{ 'organization.field.tax_code' | translate | sentencecase }}
			</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.tax_code; else noData">
				{{ organization.tax_data.tax_code }}
			</div>
		</div>

		<div class="row-item">
			<div class="header">
				{{ 'organization.field.legal_headquarter' | translate | sentencecase }}
			</div>
			<div class="content" *ngIf="legalHeadquarter; else noData">
				{{ utilAddressService.getFormattedAddress(legalHeadquarter) }}
			</div>
		</div>
	</div>

	<div class="row-wrapper">
		<div class="row-item">
			<div class="header">
				{{ 'organization.field.vat_number' | translate | sentencecase }}
			</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.vat_number; else noData">
				{{ organization.tax_data.vat_number }}
			</div>
		</div>

		<div class="row-item">
			<div class="header">{{ 'organization.field.pec' | translate | sentencecase }}</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.pec; else noData">
				{{ organization.tax_data.pec }}
			</div>
		</div>
	</div>

	<!-- <div class="row">
		<div class="info-row col-12 col-lg-6">
			<div class="header">
				{{ 'saep_ict_angular_spin8_core.page.organization.business_name' | translate | sentencecase }}
			</div>
			<div class="content" *ngIf="organization.business_name; else noData">{{ organization.business_name }}</div>
		</div>
		<div class="info-row col-12 col-lg-6">
			<div class="header">{{ 'organization.field.sdi' | translate | sentencecase }}</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.sdi_code; else noData">
				{{ organization.tax_data.sdi_code }}
			</div>
		</div>
	</div>
	<div class="row">
		<div class="info-row col-12 col-lg-6">
			<div class="header">{{ 'organization.field.tax_code' | translate | sentencecase }}</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.tax_code; else noData">
				{{ organization.tax_data.tax_code }}
			</div>
		</div>
		<div class="info-row col-12 col-lg-6">
			<div class="header">{{ 'organization.field.legal_headquarter' | translate | sentencecase }}</div>
			<div class="content" *ngIf="legalHeadquarter; else noData">
				{{ utilAddressService.getFormattedAddress(legalHeadquarter) }}
			</div>
		</div>
	</div>
	<div class="row">
		<div class="info-row col-12 col-lg-6">
			<div class="header">{{ 'organization.field.vat_number' | translate | sentencecase }}</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.vat_number; else noData">
				{{ organization.tax_data.vat_number }}
			</div>
		</div>
		<div class="info-row col-12 col-lg-6">
			<div class="header">{{ 'organization.field.pec' | translate | sentencecase }}</div>
			<div class="content" *ngIf="organization.tax_data && organization.tax_data.pec; else noData">
				{{ organization.tax_data.pec }}
			</div>
		</div>
	</div> -->

	<ng-template #noData>
		<span class="content">-</span>
	</ng-template>
</div>
