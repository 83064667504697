import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	AngularCoreUtilService, SubscribeManagerService
} from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService} from '@saep-ict/angular-spin8-core';
import { ArticlePouchModel, ArticleDescriptionItem, ArticleCheckoutTree, LocalListHandlerBaseApplyFilterParam } from '@saep-ict/pouch_agent_models';
import { ArticleTableConfigurationAction } from '../../../../model/article.model';
import { ArticleListStructureWrapperAccordionBaseDirective } from '../../../../shared/directives/article-list-structure-wrapper-accordion-base/article-list-structure-wrapper-accordion.directive';
import * as ForecastModel from '../../../../model/forecast.model';
import * as ConfigurationCategory from '../../../../constants/category.constant';
import { CustomerAppConfig } from '../../../../customer-app.config';
import _ from 'lodash';

@Component({
	selector: 'article-list-structure-wrapper-accordion',
	templateUrl: './article-list-structure-wrapper-accordion.component.html',
	styleUrls: ['./article-list-structure-wrapper-accordion.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleListStructureWrapperAccordionComponent extends ArticleListStructureWrapperAccordionBaseDirective implements OnInit {
	@Input('configurationAction') configurationAction: ArticleTableConfigurationAction;

	@Output() onDeleteArticle: EventEmitter<ArticlePouchModel | ArticleDescriptionItem> = new EventEmitter();
	@Output() onForecastChange: EventEmitter<ForecastModel.CodeItemUpdate> = new EventEmitter();

	constructor(
		public store: Store,
		public utilService: AngularCoreUtilService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public subscribeManagerService: SubscribeManagerService,
		public appConfig: CustomerAppConfig
	) {
		super(store, utilService, utilTranslateService, subscribeManagerService);
	}

	ngOnInit(): void {
	}

	async onForecastChangeHandler(e: ForecastModel.CodeItemUpdate) {
		e = _.cloneDeep(e);
		for (let categoryRoot of this.categoryTree) {
			const node: ArticleCheckoutTree | ArticlePouchModel =
				await ConfigurationCategory.returnCategoryTreeNodeByCodeItem<ArticleCheckoutTree>(
					[categoryRoot], e.code_item
				);
			if (node) {
				if (!node.forecast) {
					node.forecast = [];
				}
				for (const timeRange of e.data) {
					const articleTimeRange = node.forecast.find(i => i.key === timeRange.key);
					if (articleTimeRange) {
						articleTimeRange.value = timeRange.value;
					} else {
						node.forecast.push({
							key: timeRange.key,
							value: timeRange.value
						});
					}
				}
				const updateListReturn = await this.updateForecastCategoryMetaInformation([categoryRoot]);
				if (updateListReturn && updateListReturn.length > 0) {
					categoryRoot = updateListReturn[0];
				}
				break;
			}
		}
		this.onForecastChange.emit(e);
	}
}
