import { Component, Inject, Input } from '@angular/core';

// model
import { BaseStateModel } from '@saep-ict/angular-core';
import { APP_CONFIG_TOKEN, ITicketCenterAppConfig } from '../../model/lib-app-config.interface';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../store';

// misc
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

@Component({
  selector: 'ticket-center',
  templateUrl: './ticket-center.component.html',
  styleUrls: ['./ticket-center.component.scss']
})
export class TicketCenterComponent {

  user$: Observable<BaseStateModel<any>> = this.store.select(StateFeature.getUserStore);
  user: UserDetailModel;

  @Input() frameHeight: number;

  public deviceH: number;

  constructor(
    @Inject(APP_CONFIG_TOKEN) protected appConfig: ITicketCenterAppConfig,
    private store: Store<any>
  ) {
    this.user$.pipe(take(1)).subscribe(store => {
			this.user = store ? store.data : null;
		});
    this.deviceH = window.innerHeight;
  }

}
