import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService, SideBarPositionValues } from '@saep-ict/angular-core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { StateFeature } from '../../../../state';
import { PATH_URL,
	UserDetailModel,
	OrderStateModel,
	ExtraFieldOrderHeaderPouchModel,
	AngularSpin8CoreUtilCompanyService,
	ROUTE_URL,
	PermissionKeyEnum
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import * as DashboardConfiguration from '../../../../constants/dashboard.constants'
import { OrderListActionEnum, OrderListStateAction } from '../../../../state/order-list/order-list.actions';
import moment from 'moment';
import { LocalListHandlerBaseModel, OrderPouchModel } from '@saep-ict/pouch_agent_models';
import {
	ItemBoxTrendInfoComponentConfig
} from '../../../../widget/box-trend-info/box-trend-info.component';
import * as ConfigurationCustomerStatistic from '../../../../constants/configuration-customer/statistic/statistic.constant';
import * as ConfigurationCustomerOrder from '../../../../constants/configuration-customer/order/order.constant';
import { TableOrderModel } from '../../../../model/table/table-order.model';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import * as ConfigurationSubscribeManager from '../../../../constants/subscribe-manager.constant';
import { SubscribeManagerItem } from '../../../../model/subscribe-manager.model';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { ViewAllButtonConfiguration } from '../../../../model/util.model';
import * as ConfigurationCustomerOrderErp from '../../../../constants/configuration-customer/order/order-erp.constant';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard-agent.component.html',
	styleUrls: ['./dashboard-agent.component.scss'],
	providers: [SubscribeManagerService]
})
export class DashboardAgentComponent implements OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: OrderStateModel[];

	sideBarPositionValues = SideBarPositionValues;
	displayCreditDebitInfoBox: boolean;


	configurationCustomerStatistic = ConfigurationCustomerStatistic;
	dashboardConfiguration = DashboardConfiguration;

	updatedAtGeneral = moment().format("DD/MM/YYYY, HH:mm");



	subscribeList: SubscribeManagerItem[] = [
		// { key: 'news-list', observable: this.subscribeNewsList() },
		{ key: 'order-list', observable: this.subscribeOrderList() },

	];

	boxTrendInfoOrder: Array<ItemBoxTrendInfoComponentConfig>;

	PATH_URL = PATH_URL;
	ROUTE_URL = ROUTE_URL;

	listPageBaseLastOrders = <LocalListHandlerBaseModel<TableOrderModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'date_update',
			order: TdDataTableSortingOrder.Descending
		}
	};

	// permission differente per visibilità singola card dashboard
	permissionKeyEnum = {
		//news: PermissionKeyEnum.AGENT_NEWS,
		//mediaCenter: PermissionKeyEnum.AGENT_MEDIACENTER,
		order: PermissionKeyEnum.AGENT_ORDER_LIST,
		//credit: PermissionKeyEnum.AGENT_ORGANIZATION_CREDIT
	}

	viewAllButtonConfiguration: ViewAllButtonConfiguration = {
		display: false
	}

	constructor(
		private store: Store,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		private utilBreadcrumbService: UtilBreadcrumbService,
		public permissionUtilService: PermissionUtilService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private utilStoreService: StoreUtilService,
		public utilService: AppUtilService,
		) {
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('dashboard');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['dashboard']);
		this.store.dispatch(OrderListStateAction.loadAll());
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('dashboard');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['dashboard']);
	}


	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.user = e.data;
			this.listPageBaseLastOrders.columnList = ConfigurationCustomerOrder.columnList(
				this.user.current_permission.context_application
			);
		});
	}





	subscribeOrderList(): Observable<void> {
		return this.orderList$.pipe(
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					this.user.current_permission.context_code.code &&
					orderList &&
					orderList.type !== OrderListActionEnum.LOAD_ALL
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				if (orderList.type === OrderListActionEnum.ERROR) {
					throw new Error(OrderListActionEnum.ERROR);
				}
				this.orderList = orderList.data;
				this.boxTrendInfoOrder =
					DashboardConfiguration.returnBoxTrendInfoOrderContent(
						ConfigurationCustomerOrder.dashboardOrderTrendBoxStatusList[this.user.current_permission.context_application],
						this.orderList,
						ConfigurationCustomerStatistic.timeRangeSelector[0]
					);

				// last orders permanent filters
				const listPageBaseLastOrders =
					ConfigurationCustomerOrder.configurationLastOrderslistFilter(
						_.cloneDeep(orderList.data),
						this.user.current_permission.context_application
					);

				this.listPageBaseLastOrders.data = ConfigurationCustomerOrderErp.getTableOrderList(listPageBaseLastOrders);
				this.listPageBaseLastOrders = _.cloneDeep(this.listPageBaseLastOrders);
			})
		);


	}

	updateBoxTrendInfoOrderContent(e: MatSelectChange) {
		this.boxTrendInfoOrder =
			DashboardConfiguration.returnBoxTrendInfoOrderContent(
				ConfigurationCustomerOrder.dashboardOrderTrendBoxStatusList[this.user.current_permission.context_application],
				this.orderList,
				e.value
			);
	}


	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		this.router.navigate([
			PATH_URL.PRIVATE,
			'orders',
			selectedOrder.header.status,
			selectedOrder.header.organization.code_item,
			selectedOrder._id,
			'checkout'
		]);
	}


	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}
}
