import { createAction, props } from '@ngrx/store';
import { BaseStateModel, RestBasePk } from '@saep-ict/angular-core';
import { InformativePageModel } from '@saep-ict/angular-spin8-core';

export enum InformativePageActionEnum {
	UPDATE = '[informative page] Update',
	LOAD = '[informative page] Load',
	LOAD_ALL = '[informative page] Load All',
	SAVE = '[informative page] Save',
	RESET = '[informative page] Reset',
	ERROR = '[informative page] Error'
}

export namespace InformativePageStateAction {
	export const load = createAction(InformativePageActionEnum.LOAD, props<{ slug: string }>());
	export const loadAll = createAction(InformativePageActionEnum.LOAD_ALL);
	export const save = createAction(InformativePageActionEnum.SAVE, props<BaseStateModel<InformativePageModel[]>>());
	export const update = createAction(
		InformativePageActionEnum.UPDATE,
		props<BaseStateModel<InformativePageModel[]>>()
	);
	export const reset = createAction(InformativePageActionEnum.RESET);
	export const error = createAction(InformativePageActionEnum.ERROR, props<BaseStateModel<any>>());
}
