<ng-container *ngIf="article && article.articleDescription">
	<app-page-header
		[title]="utilTranslateService.getTranslationFromLanguage(article.articleDescription.language_list).description"
		[breadcrumbsList]="[
			'home',
			utilTranslateService.getTranslationFromLanguage(article.articleDescription.language_list).description
		]"
		[backgroundImageUrl]="'theme.header_bg.article_detail.backgroundImageUrl' | translate"
	>
	</app-page-header>

	<section class="b2c-article-detail">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-md-12 col-lg-4">
					<ng-container *ngIf="galleryOptions && galleryImages">
						<div class="gallery-wrapper">
							<ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
						</div>
					</ng-container>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-8">
					<div class="b2c-article-detail__content">
						<h2 class="b2c-article-detail__content__title">{{
								utilTranslateService
								.getTranslationFromLanguage(article.articleDescription.language_list)
								.description |
								sentencecase
						}}</h2>
						<div
							*ngIf="showContent.description_add"
							class="ql-editor"
						>
							<p
								class="b2c-article-detail__content__description"
								[innerHTML]="
									utilTranslateService.getTranslationFromLanguage(article.articleDescription.language_list)
									.description_add
								"
							></p>
						</div>
						<ng-container *ngIf="showContent.technical_info">
							<p class="b2c-article-detail__content__title">{{
								'general.information.technical' | translate | sentencecase
							}}</p>
							<div class="ql-editor">
								<p
									class="b2c-article-detail__content__description"
									[innerHTML]="
										utilTranslateService.getTranslationFromLanguage(article.articleDescription.language_list)
										.technical_info
									"
								></p>
							</div>
						</ng-container>
						<div class="b2c-article-detail__content__variant">
							<div class="variant__col">
								<p class="b2c-article-detail__content--label pb-3"></p>
								<div class="chips-mini">
									<span
										[ngClass]="
											isAvailable
												? articleEnum.Status.Available.AVAILABLE
												: articleEnum.Status.Available.NOT_AVAILABLE
										"
									></span>
								</div>
							</div>
							<div class="variant__col" *ngIf="article.articleDescription.size">
								<p class="b2c-article-detail__content--label pb-3">{{
									'article.field.size' | translate | sentencecase
								}}</p>
								<p class="b2c-article-detail__content__variant__format">{{
									article.articleDescription.size
								}}</p>
							</div>
							<div class="variant__col">
								<p class="b2c-article-detail__content--label pb-3">{{
									'theme.article_detail_label.price' | translate
								}}</p>
								<b2c-article-price
									[article]="article"
									[articlePriceTooltip]="'theme.article.field.price.tooltip' | translate | sentencecase"
								>
								</b2c-article-price>
							</div>
							<div class="variant__col">
								<p class="b2c-article-detail__content--label">{{
									'theme.article_detail_label.quantity' | translate
								}}</p>
								<b2c-quantity-selector
									[value]="inputQuantity"
									[article]="article"
									[organization]="organization"
									[disabled]="!isAvailable"
									(changeValue)="changeValue($event)"
								></b2c-quantity-selector>
							</div>
						</div>
						<!-- Quantity not available -->
						<alert *ngIf="!isAvailable" [type]="alertTypeEnum.warning">{{
							'article.quantity.not_available' | translate | sentencecase
						}}</alert>

						<button
							mat-flat-button
							class="custom-button mt-3"
							[routerLink]="[ROUTE_URL.public, ROUTE_URL.catalog]"
						>{{
							'general.back_catalog' | translate | sentencecase
						}}</button>
						<!-- Add cart -->
						<button
							mat-flat-button
							color="accent"
							class="custom-button mt-3"
							[disabled]="
								!(
									inputQuantity > 0 &&
									inputQuantity <= quantityCanBeOrdered &&
									isAvailable
								)
							"
							(click)="addToCart()"
						>{{
							'checkout.add_to_cart' | translate | sentencecase
						}}</button>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="b2c-article-detail__more-info">
		<div class="container">
			<div
				class="row"
				*ngIf="
					utilTranslateService.getTranslationFromLanguage(article.articleDescription.language_list)
						.description_extended
				"
			>
				<div class="col-sm-12 col-md-12 col-lg-12">
					<div class="b2c-article-detail__details">
						<mat-accordion>
							<!-- Dettagli prodotto -->
							<mat-expansion-panel [expanded]="true">
								<mat-expansion-panel-header>
									<mat-panel-title>{{
										'general.details.name' | translate | sentencecase
									}} {{ 'theme.article.field.product' | translate
								}}</mat-panel-title>
								</mat-expansion-panel-header>
								<p
									[innerHTML]="
										utilTranslateService.getTranslationFromLanguage(
											article.articleDescription.language_list
										).description_extended
									"
								></p>
							</mat-expansion-panel>
						</mat-accordion>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section *ngIf="config && config.data && config.data.length" class="b2c-article-detail__related-products">
		<div class="container">
			<div class="row">
				<div class="col">
					<h2>{{ 'article.related.list' | translate | sentencecase }}</h2>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col">
					<!-- Slider category -->
					<app-card-slider *ngIf="config" [config]="config">
						<div *sliderItem="let item; let idx = index" class="card-slider-item">
							<b2c-article-item
								[itemType]="ItemType.product"
								[viewType]="viewTypeEnum.module"
								[article]="item"
							></b2c-article-item>
						</div>
					</app-card-slider>
				</div>
			</div>
		</div>
	</section>
</ng-container>