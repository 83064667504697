import { Injector } from '@angular/core';
import * as FormControlMultipurposeModel from '../../model/widget/form-control-multipurpose.model';
import { AngularCoreUtilService } from '../../service/util/util.service';

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

const utilService = injector.get(AngularCoreUtilService);

export class FormControlMultipurposeSetDisabled {
	public static disabledIfSomeFieldIsSomeValue(
		formValue,
		configurationParam: FormControlMultipurposeModel.SetValidatorConfigurationParam
	): boolean {
		if (formValue && configurationParam) {
			return utilService.checkObjectNestedValueByPath(formValue, configurationParam.pathToProperty).value ===
				configurationParam.propertyValue
				? true
				: false;
		}
		return true;
	}

	public static disabledAlways(): boolean {
		return true;
	}

}
