import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AutocompleteConfigModel } from '../../model/autocomplete.model';

@Component({
	selector: 'autocomplete',
	styleUrls: ['./autocomplete.scss'],
	templateUrl: './autocomplete.html'
})
export class AutocompleteComponent {
	isOpen: boolean = false;
	_results: any = {};
	@Output() selectionEmitter = new EventEmitter<any>();
	@Input() config: AutocompleteConfigModel;
	@Input() set results(r) {
		// questo input scatta a fronte dell'emitter (searchDebounce)="getFilteredList(true)",
		// che risponde a sua volta al modelChange de [(ngModel)]="filterText", il tutto relativo in questo caso
		// al componente td-search-box
		// è importante che filterText sia una variabile isolata per evitare che _results venga sovrascritto involontariamente
		// durante la raccolta della response
		if (r) {
			this._results = r;
			this.isOpen = true;
		}
	}
	@HostListener('window:keyup', ['$event'])
	keyEvent(event: KeyboardEvent) {
		// da verificare con la chiamata agganciata se le condizioni qui sotto vanno bene
		if (this.config.multipleSelection && this.isOpen && event.keyCode === 13) {
			this.emitSelection();
		}
	}
	constructor() {}
	ngOnInit() {}
	onClickOutside() {
		if (this.isOpen) {
			this.isOpen = false;
		}
	}
	emitSelection(item?) {
		let selection: any = null;
		if (item) {
			this._results.list = [];
			this._results.list.push(item);
			selection = this._results;
		}
		this.selectionEmitter.emit(selection);
		this.isOpen = false;
	}
}
