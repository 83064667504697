<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight">
			<div class="row-wrapper columns-2 mt-20 mb-20">
				<div class="column">
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'customer_credit.field.due' | translate | sentencecase }}
							<span class="flex-span"></span>
						</mat-toolbar-row>
						<mat-toolbar-row>
							<mat-card-subtitle
								>{{ 'customer_credit.field.debit' | translate | sentencecase }} -
								{{ 'customer_credit.field.credit' | translate | sentencecase }}</mat-card-subtitle
							>
						</mat-toolbar-row>
					</mat-toolbar>

					<box-trend-info [boxTrendInfoComponentConfig]="dueTrendBoxContent"></box-trend-info>

					<box-credit-debit-info
						[boxCreditDebitInfoComponentConfig]="dueCreditDebitBoxContent"
					></box-credit-debit-info>

					<!-- <div class="row" *ngIf="displayCreditDebitInfoBox && chartConfigurationDue.datasets[0]">
						<div class="col-6">
							<span class="stats__title">
								{{ 'customer_credit.field.credit' | translate | sentencecase }}
							</span>
							<p class="stats__number">
								{{ credits !== null ? (credits | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
						<div class="col-6">
							<span class="stats__title">{{
								'customer_credit.field.debit' | translate | sentencecase
							}}</span>
							<p class="stats__number">
								{{ debits !== null ? (debits | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
					</div> -->
				</div>

				<div class="column">
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'customer_credit.field.overdue' | translate | sentencecase }}
							<span class="flex-span"></span>
						</mat-toolbar-row>
						<mat-toolbar-row>
							<mat-card-subtitle
								>{{ 'customer_credit.field.debit_expired' | translate | sentencecase }} -
								{{
									'customer_credit.field.credit_expired' | translate | sentencecase
								}}</mat-card-subtitle
							>
						</mat-toolbar-row>
					</mat-toolbar>

					<box-trend-info [boxTrendInfoComponentConfig]="overdueTrendBoxContent"></box-trend-info>

					<box-credit-debit-info
						[boxCreditDebitInfoComponentConfig]="overdueCreditDebitBoxContent"
					></box-credit-debit-info>

					<!-- <div class="row" *ngIf="displayCreditDebitInfoBox && chartConfigurationOverdue.datasets[0]">
						<div class="col-6">
							<span class="stats__title">
								{{ 'customer_credit.field.credit_expired' | translate | sentencecase }}
							</span>
							<p class="stats__number">
								{{ creditsExpired !== null ? (creditsExpired | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
						<div class="col-6">
							<span class="stats__title">
								{{ 'customer_credit.field.debit_expired' | translate | sentencecase }}
							</span>
							<p class="stats__number">
								{{ debitsExpired !== null ? (debitsExpired | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
					</div> -->
				</div>
			</div>

			<!-- <div class="row">
				<div class="col-md-6">
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'customer_credit.field.due' | translate | sentencecase }}
							<span class="flex-span"></span>
						</mat-toolbar-row>
					</mat-toolbar>
					<box-trend-info
						class="show-last-separator"
						[boxTrendInfoComponentConfig]="dueTrendBoxContent"
					></box-trend-info>

					<box-credit-debit-info
						[boxCreditDebitInfoComponentConfig]="dueCreditDebitBoxContent"
						class="show-last-separator"
					></box-credit-debit-info>

					<div class="row" *ngIf="displayCreditDebitInfoBox && chartConfigurationDue.datasets[0]">
						<div class="col-6">
							<span class="stats__title">
								{{ 'customer_credit.field.credit' | translate | sentencecase }}
							</span>
							<p class="stats__number">
								{{ credits !== null ? (credits | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
						<div class="col-6">
							<span class="stats__title">{{
								'customer_credit.field.debit' | translate | sentencecase
							}}</span>
							<p class="stats__number">
								{{ debits !== null ? (debits | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'customer_credit.field.overdue' | translate | sentencecase }}
							<span class="flex-span"></span>
						</mat-toolbar-row>
					</mat-toolbar>
					<box-trend-info [boxTrendInfoComponentConfig]="overdueTrendBoxContent"></box-trend-info>

					<box-credit-debit-info
						[boxCreditDebitInfoComponentConfig]="overdueCreditDebitBoxContent"
					></box-credit-debit-info>

					<div class="row" *ngIf="displayCreditDebitInfoBox && chartConfigurationOverdue.datasets[0]">
						<div class="col-6">
							<span class="stats__title">
								{{ 'customer_credit.field.credit_expired' | translate | sentencecase }}
							</span>
							<p class="stats__number">
								{{ creditsExpired !== null ? (creditsExpired | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
						<div class="col-6">
							<span class="stats__title">
								{{ 'customer_credit.field.debit_expired' | translate | sentencecase }}
							</span>
							<p class="stats__number">
								{{ debitsExpired !== null ? (debitsExpired | currency: 'EUR':'code':'':'it') : '-' }}
							</p>
						</div>
					</div>
				</div>
			</div> -->
			<mat-divider class="both-spacing"></mat-divider>
			<credit-list-wrapper
				[lastCustomerCreditDate]="customerCreditBatchLastDate"
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTitle]="'customer_credit.name' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
			>
			</credit-list-wrapper>
		</mat-card>
	</ng-container>
</page-with-sidebar>
