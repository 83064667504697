import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ArticleStockActionEnum, ArticleStockStateAction } from './article-stock.actions';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { ArticleRecapStockStateModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class ArticleStockEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleStockActionEnum.LOAD),
			mergeMap(() => from(this.getDoc())),
			map((action: BaseStateModel<ArticleRecapStockStateModel>) => ArticleStockStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(ArticleStockStateAction.error(new BaseState(error)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter
	) {}

	async getDoc(): Promise<BaseStateModel<ArticleRecapStockStateModel>> {
		return await this.pouchDbCommonsAdapter.basePouch
			.getDetail(`article_recap${ConfigurationCustomerAppStructure.noSqlDocSeparator}stock`)
			.then((doc: ArticleRecapStockStateModel) => {
				return new BaseState(doc);
			})
			.catch(err => {
				throw new Error(err.message);
			});
	}
}
