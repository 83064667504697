import { Injectable } from '@angular/core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { LoginContextCodeActionEnum, LoginContextCodeStateAction } from './login-context-code.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { BodyTablePouchCustomModel } from '@saep-ict/angular-spin8-core';

@Injectable()
export class LoginContextCodeEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoginContextCodeActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(action))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) => LoginContextCodeStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(LoginContextCodeStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store,
		private storeUtilService: StoreUtilService
	) {}
}
