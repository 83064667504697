export const heading = [[
    'Ref.',
    'Name',
    'Brand',
    'Line',
    'Stock',
    'Allocated',
    'Available',
    'Incoming' 
]];

export const exportExcel = (list:any) => {
	let json = [];
    list.forEach(product => {
		json.push({
			ref: product.code_erp ? product.code_erp : '',
			name: product.description.article ? product.description.article : '',
			brand: product.description.brand ? product.description.brand : '',
			line: product.description.line ? product.description.line : '',
			stock:  product.stock.qty_current ? product.stock.qty_current : '',
            allocated:  product.stock.qty_commited ?  product.stock.qty_commited : '',
            available: product.stock.qty_available ? product.stock.qty_available  : '',
            incoming:  product.stock.qty_incoming ? product.stock.qty_incoming : ''
		});
	});
	return json;
};
