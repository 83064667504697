<div class="container-fluid">
	<!-- HEADER -->
	<div class="header">
		<!-- Timerange -->
		<div>{{ 'chart.field.time_range' | translate | sentencecase }}</div>
		&nbsp; &nbsp;
		<mat-form-field class="header__timerange">
			<mat-select [value]="timeRange.values[0]" (selectionChange)="setTimeRange($event)">
				<mat-option *ngFor="let rangeTemp of timeRange.values" [value]="rangeTemp">
					{{ 'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- Separator -->
		<div class="header__separator"></div>

		<!-- Date last update statistics -->
		<div class="header__statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div>

	<!-- CLIENT TREND -->
	<div class="row">
		<!-- Clients: active -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="activeClientsChartConfigService.cardDashboardConfigClientsActive">
				<div class="clients-value">
					<div class="row clients-value__header" *ngIf="activeClientsChartConfigService.metrics">
						<div class="clients-value__header__average">
							{{ activeClientsChartConfigService.metrics.average | number: '1.2-2':'it' }}
						</div>
						<div class="clients-value__header__separator"></div>
						<div class="clients-value__header__trend">
							<icon-trend [value]="activeClientsChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row clients-value__body">
						<chart-wrapper
							class="row clients-value__body__chart"
							[config]="activeClientsChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>

		<!-- Clients: inactive -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				class="card-dashboard-accent"
				[cardDashboardConfig]="inactiveClientsChartConfigService.cardDashboardConfigClientsInactive"
			>
				<div class="clients-amount">
					<div class="row clients-amount__header" *ngIf="inactiveClientsChartConfigService.metrics">
						<div class="clients-amount__header__average">
							{{ inactiveClientsChartConfigService.metrics.average | number: '1.2-2':'it' }}
						</div>
						<div class="clients-value__header__separator"></div>
						<div class="clients-amount__header__trend">
							<icon-trend [value]="inactiveClientsChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row clients-amount__body">
						<chart-wrapper
							class="row clients-amount__body__chart"
							[config]="inactiveClientsChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>
	</div>

	<!-- CLIENTS LIST TABS: active and inactive -->
	<div class="row" >
		<card-dashboard [cardDashboardConfig]="cardDashboardConfigClientsActiveInactive" class="col-12">
			<mat-tab-group mat-align-tabs="start">
				<!-- active clients -->
				<mat-tab label="{{ 'general.status.active' | translate | uppercase }}">
					<organization-list-wrapper
						*ngIf="this.activeClients"
						[localListHandlerWidgetWrapperData]="this.activeClients"
						[localListHandlerWidgetWrapperColumnList]="clientColumns"
					>
					</organization-list-wrapper>
				</mat-tab>
				<!-- inactive clients -->
				<mat-tab label="{{ 'general.status.inactive' | translate | uppercase }}">
					<organization-list-wrapper
						*ngIf="this.inactiveClients"
						[localListHandlerWidgetWrapperData]="this.inactiveClients"
						[localListHandlerWidgetWrapperColumnList]="clientColumns"
						[localListHandlerPaginatorShow]="false"
					>
					</organization-list-wrapper>
				</mat-tab>
			</mat-tab-group>

		</card-dashboard>
	</div>

	<!-- ORDERS AVERAGE -->
	<div class="row">
		<!-- Chart: Average value of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="clientsTop5AverageChartConfigService.cardDashboardConfigClientsTop5Average"
			>
				<ng-container *ngIf="clientsTop5AverageChartConfigService.chart.datasets.length">
					<chart-wrapper [config]="clientsTop5AverageChartConfigService.chart"></chart-wrapper>
				</ng-container>
			</card-dashboard>
		</div>

		<!-- Table: Average value of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigClientsTop5Average">
				<ng-container *ngIf="clientsTop5AverageTableConfigService.currentDataSet.length; else noData">
					<td-data-table
						[data]="clientsTop5AverageTableConfigService.currentDataSet"
						[columns]="clientsTop5AverageTableConfigService.columns"
					>
						<ng-template tdDataTableTemplate="business_name" let-value="value">
							<p>
								{{ value }}
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="average" let-value="value">
							<p>
								{{ value | appFormatMultiplesCurrency }}
							</p>
						</ng-template>
					</td-data-table>
				</ng-container>
				<ng-template #noData>
					<chart-no-data icon="grid_on"></chart-no-data>
				</ng-template>
			</card-dashboard>
		</div>
	</div>

	<!-- ORDERS QUANTITY -->
	<div class="row">
		<!-- Chart: number of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="clientsTop5QuantityChartConfigService.cardDashboardConfigClientsTop5Quantity"
			>
				<ng-container *ngIf="clientsTop5QuantityChartConfigService.chart.datasets.length">
					<chart-wrapper [config]="clientsTop5QuantityChartConfigService.chart"></chart-wrapper>
				</ng-container>
			</card-dashboard>
		</div>

		<!-- Table: number of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigClientsTop5Quantity">
				<ng-container *ngIf="clientsTop5QuantityTableConfigService.currentDataSet.length; else noData">
					<td-data-table
						[data]="clientsTop5QuantityTableConfigService.currentDataSet"
						[columns]="clientsTop5QuantityTableConfigService.columns"
					>
						<ng-template tdDataTableTemplate="business_name" let-value="value">
							<p>
								{{ value }}
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="count" let-value="value">
							<p>
								{{ value | appFormatMultiplesCurrency }}
							</p>
						</ng-template>
					</td-data-table>
				</ng-container>
				<ng-template #noData>
					<chart-no-data icon="grid_on"></chart-no-data>
				</ng-template>
			</card-dashboard>
		</div>
	</div>
</div>
