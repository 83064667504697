<mat-dialog-wrapper
	[title]="
		(data.opportunityLevel === OpportunityEnum.Level.LEAD ? 'request.close' : 'opportunity.close')
			| translate
			| sentencecase
	"
	[showCloseIcon]="false"
>
	<ng-container content>
		<!-- Lead -->
		<ng-container *ngIf="data.opportunityLevel === OpportunityEnum.Level.LEAD">
			{{ 'opportunity.question.close_lead' | translate | sentencecase }}
		</ng-container>

		<!-- Prospect/Client -->
		<ng-container *ngIf="data.opportunityLevel !== OpportunityEnum.Level.LEAD">
			{{ 'opportunity.question.close_not_lead' | translate | sentencecase }}
		</ng-container>
	</ng-container>

	<ng-container actions>
		<!-- Lead -->
		<ng-container *ngIf="data.opportunityLevel === OpportunityEnum.Level.LEAD">
			<form-button-bar
				[align]="'right'"
				[primaryButtonLabel]="'request.close' | translate | sentencecase"
				(primaryButtonClickEmitter)="closeDialog(OpportunityEnum.Status.Outcome.Rejected)"
				[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
				(secondaryButtonClickEmitter)="dialogRef.close()"
			></form-button-bar>
		</ng-container>

		<!-- Prospect/Client -->
		<ng-container *ngIf="data.opportunityLevel !== OpportunityEnum.Level.LEAD">
			<form-button-bar
				[align]="'right'"
				[primaryButtonLabel]="'opportunity.status.WON' | translate | sentencecase"
				(primaryButtonClickEmitter)="closeDialog(OpportunityEnum.Status.Outcome.Won)"
				[secondaryButtonLabel]="'opportunity.status.LOST' | translate | sentencecase"
				(secondaryButtonClickEmitter)="closeDialog(OpportunityEnum.Status.Outcome.Lost)"
				[otherButtonLabel]="'general.cancel' | translate | sentencecase"
				(otherButtonClickEmitter)="dialogRef.close()"
			></form-button-bar>
		</ng-container>
	</ng-container>
</mat-dialog-wrapper>
