import { ITdDataTableColumnCustom, ListStructureEnum } from '@saep-ict/pouch_agent_models';

export const articleColumnMapCrmCatalog = (currency: string) : ITdDataTableColumnCustom[] =>  [
	{
		name: 'code_erp',
		labelPath: 'article.field.ref',
		label: null
	},
	{
		name: 'description',
		labelPath: 'article.field.description',
		label: null,
		sortType: ListStructureEnum.Sort.Type.STRING
	},
	{
		name: 'articlePrice.price',
		labelPath: 'article.field.price',
		labelPathParam: { currency: currency },
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER
	},
	{
		name: 'tableMetaInformation.discountDescription',
		labelPath: 'article.field.discount',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER
	},
	{
		name: 'input_quantity',
		labelPath: 'article.field.quantity',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER
	},
	{
		name: 'discount_agent',
		labelPath: 'article.field.agent_discount',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER
	},
	{
		name: 'calculate_price',
		labelPath: 'article.field.total',
		labelPathParam: { currency: currency },
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER
	},
	{
		name: 'articleDescription.sequence',
		labelPath: '',
		label: null,
		hidden: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER
	}
];