import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { UserNotificationActionEnum, UserNotificationStateAction } from './user-notification.actions';
import { UserNotificationModel } from '@saep-ict/angular-spin8-core';
import { BaseStateModel } from '@saep-ict/angular-core';

export namespace UserNotificationStateReducer {
	export const initialState: BaseStateModel<UserNotificationModel> = null;
	const _reducer = createReducer(
		initialState,
		on(UserNotificationStateAction.load, state => state),
		on(UserNotificationStateAction.save, (state, userNotification) => state),
		on(UserNotificationStateAction.saveComplete, (state, userNotification) => userNotification),
		on(UserNotificationStateAction.update, (state, userNotification) => userNotification),
		on(UserNotificationStateAction.error, (state, news) => ({
			data: state ? state.data : null,
		})),
	);

	export function reducer(state: BaseStateModel<UserNotificationModel>, action: TypedAction<UserNotificationActionEnum>) {
		return _reducer(state, action);
	}
}
