import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as ConfigurationCustomerDestination from '../../../../../constants/configuration-customer/destination/destination.constant';
import * as ConfigurationCustomerOrganization from '../../../../../constants/configuration-customer/organization/organization.constant';
import * as ConfigurationCustomerContextApplication from '../../../../../constants/configuration-customer/context-application/context-application.constant';
import { Store } from '@ngrx/store';
import { LocalListHandlerBaseModel, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, of } from 'rxjs';
import { filter, catchError, delay, map } from 'rxjs/operators';
import { BaseState, BaseStateModel, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import { StateFeature } from '../../../../../state';
import { LoaderService, SubscribeManagerService } from '@saep-ict/angular-core';
import {
	OrganizationStateAction,
	OrganizationActionEnum
} from '../../../../../state/organization/organization.actions';
import { DialogDestinationDetailComponent } from '../../../../../widget/dialog/dialog-destination-detail/dialog-destination-detail.component';
import { UtilAddressService } from '../../../../../service/util/util-address.service';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	FormControlMap,
	OrganizationStateModel
} from '@saep-ict/angular-spin8-core';
import { ExtendedDestinationPouchModel } from '../../../../../model/destination.model';
import { SubscribeManagerItem } from '../../../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../../../constants/subscribe-manager.constant';
import { StoreUtilService } from '../../../../../service/util/store-util.service';

@Component({
	selector: 'organization-detail-destination',
	templateUrl: './organization-detail-destination.component.html',
	styleUrls: ['./organization-detail-destination.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrganizationDetailDestinationComponent implements OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'organization-data', observable: this.subscribeOrganization() }
	];

	listPageBaseData = <LocalListHandlerBaseModel<DestinationPouchModel>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'business_name',
					'address.zip_code',
					'address.address',
					'address.locality',
					'address.country',
					'address.province.label'
				]
			}
		},
		sort: {
			name: 'address.address',
			order: 'ASC'
		},
		data: []
	};

	configurationCustomerOrganization = ConfigurationCustomerOrganization;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private store: Store,
		private dialog: MatDialog,
		private loader: LoaderService,
		private subscribeManagerService: SubscribeManagerService,
		private utilAddressService: UtilAddressService,
		private utilStoreService: StoreUtilService
	) {
		this.loadStaticData();
		this.listPageBaseData.columnList =
			ConfigurationCustomerDestination.columnList(this.user.current_permission.context_application);
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy('organization-destination');
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
	}

	subscribeOrganization(): Observable<BaseStateModel<OrganizationStateModel>> {
		// this function defines a new secondary stream, which has catchError, so that when the subscription is triggered,
		// the error can be handled (if needed) and that secondary stream is completed without affecting the principal one
		return this.organization$.pipe(
			delay(0),
			filter(res => !!(res && res.data)),
			map(organizationState => {
				if (!organizationState) {
					return null;
				}
				switch (organizationState.type) {
					case OrganizationActionEnum.UPDATE:
					case OrganizationActionEnum.SAVE_SUCCESS: {
						this.organization = organizationState.data;
						this.listPageBaseData.data =
							this.organization.destination_list && this.organization.destination_list.length > 0 ?
							this.organization.destination_list.sort((a, b) => (a.code_item > b.code_item ? 1 : -1)).filter(i => i.valid) :
							[];
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
					}
					default:
						break;
				}
			}),
			catchError(() => of(null))
		);
	}

	openDialogDestinationAdd() {
		const dialogRef = this.dialog.open(DialogDestinationDetailComponent, {
			data: {
				title: 'destination.add_new',
				oldValue: FormControlMap.CreationDefaultValue.destination.create,
				formFiledConfiguration: this.utilAddressService.setAddressProvinceOptionList(
					ConfigurationCustomerContextApplication.formControlMultipurpose[this.user.current_permission.context_application]
					.DESTINATION_LIST_EDIT
				),
				canEdit:
					ConfigurationCustomerOrganization.canCreate.destination[
						this.user.current_permission.context_application
					]
			},
			panelClass: ['dialog-normal', 'michelangelo-theme-dialog'],
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe(destination => {
			this.organizationSave(destination);
		});
	}

	openDialogDestinationEdit(e: DestinationPouchModel) {
		const creationFieldMapList: FormControlMultipurposeModel.Item[] =
			_.cloneDeep(this.utilAddressService.setAddressProvinceOptionList(
				ConfigurationCustomerContextApplication.formControlMultipurpose[
					this.user.current_permission.context_application
				]
				.DESTINATION_LIST_EDIT
			));
		if (e.is_main_of_list) {
			const isMainOfList = creationFieldMapList.find(i => i.name === 'is_main_of_list');
			if (isMainOfList) {
				isMainOfList.set_disabled = () => true;
			}
		}
		const dialogRef = this.dialog.open(DialogDestinationDetailComponent, {
			data: {
				title: 'destination.edit',
				oldValue: _.cloneDeep(e),
				formFiledConfiguration: creationFieldMapList,
				canEdit:
					ConfigurationCustomerOrganization.canEdit.destination[
						this.user.current_permission.context_application
					]
			},
			panelClass: ['dialog-normal', 'michelangelo-theme-dialog'],
			autoFocus: true
		});
		dialogRef.afterClosed().subscribe(destination => {
			this.organizationSave(destination);
		});
	}

	organizationSave(e: ExtendedDestinationPouchModel) {
		if (e) {
			const updatedOrganization = _.cloneDeep(this.organization);

			e = this.setDestinationForOrganizationSave(e);

			if (!updatedOrganization.destination_list) {
				updatedOrganization.destination_list = [];
			}

			const destinationToUpdateIndex = updatedOrganization.destination_list.findIndex(
				dest => dest.code_item === e.code_item
			);
			// in caso la nuova/aggiornata destinazione giungesse con is_main_of_list o is_registered_office
			// la prop. viene rimossa da tutte le altre destinazioni presenti, prima dell'aggiornamento
			// il check avviene solo in questo punto, valutare se spostare in futuro presso qualche util
			if (e.is_main_of_list) {
				for (let i = 0; i < updatedOrganization.destination_list.length; i++) {
					delete updatedOrganization.destination_list[i].is_main_of_list;
				}
			}
			if (e.is_registered_office) {
				for (let i = 0; i < updatedOrganization.destination_list.length; i++) {
					delete updatedOrganization.destination_list[i].is_registered_office;
				}
			}
			if (destinationToUpdateIndex > -1) {
				// Update
				e.date_creation =
					updatedOrganization.destination_list[destinationToUpdateIndex].date_creation;
				updatedOrganization.destination_list[destinationToUpdateIndex] = e;
			} else {
				// Add
				e.date_creation = Date.now();
				updatedOrganization.destination_list.push(e);
			}
			e.date_update = Date.now();
			this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
		}
	}

	setDestinationForOrganizationSave(e: ExtendedDestinationPouchModel): DestinationPouchModel {
		const destination = e.address;
		return {
			code_item: e.code_item ? e.code_item : this.loader.guid(),
			is_registered_office: e.is_registered_office,
			is_main_of_list: e.is_main_of_list,
			business_name: e.business_name,
			address: {
				address: destination.address ? destination.address : null,
				locality: destination.locality ? destination.locality : null,
				province: destination.province ? destination.province : null,
				zip_code: destination.zip_code ? destination.zip_code : null,
				country: destination.country ? destination.country : null
			},
			source: 'APPLICATION',
			valid: true
		};
	}

}
