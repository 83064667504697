import { Injector } from "@angular/core";

// model
import { ConfigurationCustomerContextSelector } from "../model/configuration.model";

// misc
import { DateMomentService, ITdDataTableColumnCustom } from "@saep-ict/angular-core";

const injector = Injector.create({
	providers: [{ provide: DateMomentService, deps: [] }]
});

const dateMomentService = injector.get(DateMomentService);

export namespace TicketCenterColumn {
    export const base: ITdDataTableColumnCustom[] = [
      {
        name: 'id',
        label: 'ID',
        sortable: false
      },
      {
        name: 'status',
        labelPath: 'tc.general.status',
        label: null,
      },
      {
        name: 'request.date_request',
        labelPath: 'tc.general.date_request',
        label: null,
        numeric: true,
        format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY'),
        sortable: true
      },
      {
        name: 'request.date_due',
        labelPath: 'tc.general.date_due',
        label: null,
        numeric: true,
        format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY'),
        sortable: true
      },
      {
        name: 'request.urgent',
        label: null,
        numeric: true
      }
    ];
    export const contextApplicationMap = <ConfigurationCustomerContextSelector<ITdDataTableColumnCustom[]>>{
      BACKOFFICE_ADMIN: TicketCenterColumn.base,
      BACKOFFICE: TicketCenterColumn.base,
      B2B: TicketCenterColumn.base,
      AGENT: TicketCenterColumn.base,
      CRM: TicketCenterColumn.base
  }
}

