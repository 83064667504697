<canvas
	baseChart
	*ngIf="_config?.datasets && _config?.datasets[0]?.data.length; else noData"
	[datasets]="_config.datasets"
	[labels]="_config.labels"
	[options]="_config.options"
	[legend]="_config.legend"
	[type]="_config.type"
	(chartHover)="chartHovered($event)"
	(chartClick)="chartClicked($event)"
>
</canvas>
<ng-template #noData>
	<chart-no-data></chart-no-data>
</ng-template>
