<mat-toolbar>
	<mat-toolbar-row>
		<button *ngIf="backButton" mat-icon-button (click)="onClickBackButton()">
			<mat-icon>arrow_back</mat-icon>
		</button>
		<div class="info-container toolbar-breadcrumbs">
			<div class="title-container m-0">
				<span class="title">{{ title }}</span> &nbsp;
				<span *ngIf="badge" class="badge {{ badgeClass }}">{{ badge }}</span>
			</div>

			<ul class="crumb">
				<li *ngFor="let path of pathList; let i = index">
					<mat-icon *ngIf="i" class="chevron">chevron_right</mat-icon>
					{{ path | translate | sentencecase }}
				</li>
			</ul>
		</div>
		<span class="flex-span"></span>
		<ng-container *ngIf="options && expandedActions">
			<div class="btn-breadcrumb">
				<ng-content select="[expandedActions]"> </ng-content>
			</div>
		</ng-container>
		<ng-container *ngIf="!expandedActions">
			<button
				mat-icon-button
				[matMenuTriggerFor]="menu"
				*ngIf="options"
				aria-label="Example icon-button with a menu"
			>
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<ng-content select="[actions]"></ng-content>
			</mat-menu>
		</ng-container>
	</mat-toolbar-row>
</mat-toolbar>
