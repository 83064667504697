import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CovalentCommonModule } from '@covalent/core/common';
import { CovalentDataTableModule } from '@covalent/core/data-table';
import { CovalentFileModule } from '@covalent/core/file';
import { CovalentPagingModule } from '@covalent/core/paging';
import { CovalentSearchModule } from '@covalent/core/search';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MediaCenterHomeComponent } from './components/media-center/media-center-home/media-center-home.component';
import { MediaCenterHomeSidebarComponent } from './components/media-center/media-center-home/media-center-home-sidebar/media-center-home-sidebar.component';
import { MediaCenterComponent } from './components/media-center/media-center.component';
import { LOADER_SERVICE_TOKEN } from './interface/loader-service.interface';
import { APP_CONFIG_TOKEN } from './interface/media-center-app-config.interface';
import {
  IMediaCenterModelOptions,
} from './model/media-center-options.model';
import { DirectivesModule } from './shared/directives/directives.module';
import { PipeModule } from './shared/pipe/pipe.module';
import { BreadcrumbComponent } from './widget/breadcrumb/breadcrumb.component';
import { DialogDeleteComponent } from './widget/dialog/dialog-delete/dialog-delete.component';
import { DialogMoveItemListComponent } from './widget/dialog/dialog-move-item-list/dialog-move-item-list.component';
import { DialogNameEditComponent } from './widget/dialog/dialog-name-edit/dialog-name-edit.component';
import { UtilStructure } from './util/util-structure';
import { LoaderService, SaepICTAngularCoreModule } from '@saep-ict/angular-core';
import { MediaCenterService } from './service/rest/media-center.service';
import { MediaCenterLatestComponent } from './components/media-center/media-center-latest/media-center-latest.component';

export const ENVIRONMENT_SETTING = new InjectionToken('MC_ENVIRONMENT_SETTING');

const materialComp = [
  MatInputModule,
  MatExpansionModule,
  MatTabsModule,
  MatListModule,
  MatButtonModule,
  MatToolbarModule,
  MatDialogModule,
  MatMenuModule,
  MatGridListModule,
  MatCardModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatSliderModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatSelectModule,
];

const widgetsComp = [
  DialogNameEditComponent,
  DialogMoveItemListComponent,
  DialogDeleteComponent,
  BreadcrumbComponent,
  MediaCenterComponent,
  MediaCenterHomeComponent,
  MediaCenterHomeSidebarComponent,
  MediaCenterLatestComponent,
];

// @dynamic
@NgModule({
  declarations: widgetsComp,
  imports: [
    ...materialComp,
    CommonModule,
    ResourceModule.forChild(),
    //    BrowserAnimationsModule,
    NgScrollbarModule,
    // covalent
    CovalentCommonModule,
    CovalentPagingModule,
    CovalentFileModule,
    CovalentSearchModule,
    CovalentDataTableModule,
    // angular
    //    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    // custom
    DirectivesModule,
    PipeModule,
    TranslateModule,
    SaepICTAngularCoreModule,
  ],
  exports: [
    DirectivesModule,
    PipeModule,
    MediaCenterComponent,
    MediaCenterHomeComponent,
    MediaCenterHomeSidebarComponent,
    MediaCenterLatestComponent,
    BreadcrumbComponent
  ]
})
export class MediaCenterModule {
  constructor(@Optional() @SkipSelf() parentModule: MediaCenterModule) {
    if (parentModule) {
      throw new Error(
        'MediaCenterModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(
    opt: IMediaCenterModelOptions
  ): ModuleWithProviders<MediaCenterModule> {
    return {
      ngModule: MediaCenterModule,
      providers: [
        { provide: ENVIRONMENT_SETTING, useValue: opt.env },
        {
          provide: APP_CONFIG_TOKEN,
          useExisting: opt.appConfig,
        },
        {
          provide: APP_INITIALIZER,
          deps: [opt.appConfig, ENVIRONMENT_SETTING, HttpClient],
          useFactory: UtilStructure.loadLibConfig,
          multi: true,
        },
        MediaCenterService,
        LoaderService,
        {
          provide: LOADER_SERVICE_TOKEN,
          useClass: LoaderService,
        }
      ],
    };
  }
}
