import { Injectable } from '@angular/core';
import { OfferStateModel } from '../../model/state/offer-state.model';
import { OrderWithArticleDetailRequest, OrderStateModel } from '../../model/state/order-state.model';
import { OrganizationStateModel } from '../../model/state/organization-state.model';

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreUtilOfferService {
	getPayloadToSaveOffer(
		offer: OfferStateModel,
		offerId: string,
		organization: OrganizationStateModel
	): OrderWithArticleDetailRequest {
		return {
			id: offerId ? offerId : null,
			orderData: {
				organization: organization,
				order: <OrderStateModel>offer,
				article: {
					code_list: [],
					excludePrice: false // questo parametro influisce sul reperimento di ArticleList in order.effect.ts:331 (mergeArticleWithProduct)
				}
			}
		};
	}

	defaultOffer: OfferStateModel = {
		type: 'order',
		order_type: 'offer',
		header: {
			organization: {
				code_item: null
			},
			order_causal: 'CA',
			discount: null,
			code_agent: null,
			code_area_manager: null,
			custom_field: {
				title: null,
				opportunity: null
			}
		},
		product_list: [],
		free_line_list: [],
		source: 'APPLICATION'
	};
}
