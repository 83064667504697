import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ConfigurationViewModel } from '../../model/configuration.model';

export enum ConfigurationActionEnum {
	UPDATE = '[configuration] Update',
	LOAD = '[configuration] Load',
	RESET = '[configuration] Reset',
	ERROR = '[configuration] Error'
}

export namespace ConfigurationStateAction {
	export const load = createAction(ConfigurationActionEnum.LOAD);
	export const update = createAction(ConfigurationActionEnum.UPDATE, props<BaseStateModel<ConfigurationViewModel>>());
	export const reset = createAction(ConfigurationActionEnum.RESET);
	export const error = createAction(ConfigurationActionEnum.ERROR, props<BaseStateModel<any>>());
}
