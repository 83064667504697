<drop-area-file
	*ngIf="dropAreaEnabled && (!selectionType || selectionType ===  selectionTypeEnum.FILE || selectionType === selectionTypeEnum.ALL)"
	[multiple]="multiple" [acceptedType]="acceptedType" (filesEmitter)="changeFiles($event)">
</drop-area-file>

<ng-container
	*ngIf="(selectionType && (selectionType ===  selectionTypeEnum.DIRECTORY || selectionType === selectionTypeEnum.ALL))">
	<button mat-stroked-button (click)="eventWrapperFolderInput()" color="primary"
		class="full-width add-folder mt-20 mt-30">
		<mat-icon class="folder" inline="true">folder</mat-icon>
		{{ 'saep_ict_angular_core.upload.upload_folder' | translate }}
	</button>
	<input #folderInput
		*ngIf="(selectionType && (selectionType ===  selectionTypeEnum.DIRECTORY || selectionType === selectionTypeEnum.ALL))"
		type="file" hidden webkitdirectory [multiple]="multiple" [accept]="acceptedType"
		(change)="onFolderSelected($event)" />
</ng-container>

<ng-container *ngIf="uploadService.fileList && uploadService.fileList.length > 0">
	<div *ngFor="let row of uploadService.fileList; let i = index" class="row file-selected-container">
		<div class="file-selected col-md-10" [matTooltip]="row.file.name">
			<mat-icon>attach_file</mat-icon>
			{{ row.file.name }}
		</div>
<!--		<div class="file-selected delete-button col-md-2">-->
<!--			<button mat-icon-button color="warn" (click)="deleteFile(i)">-->
<!--				<mat-icon class="delete">delete</mat-icon>-->
<!--			</button>-->
<!--		</div>-->
		<div class="col-md-12">
			<mat-progress-bar
				[mode]="row.progress.state == 'PENDING' || row.progress.state == 'DONE' || row.progress == 100 ? 'indeterminate' : 'determinate'"
				[value]="row.progress.progress">
			</mat-progress-bar>
		</div>
	</div>
</ng-container>