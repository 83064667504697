import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WidgetModule } from '../../widget/widget.module';
import { SharedModule } from '../../shared/shared.module';
import { PageCommonsModule } from '../commons/page-commons.module';

// page
import { DataConfigurationComponent } from './data-configuration/data-configuration.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserManagementDetailComponent } from './user-management/user-management-detail/user-management-detail.component';
import { ContextCodeManagementComponent } from './context-code-management/context-code-management.component';
import { ContextCodeManagementDetailComponent } from './context-code-management/context-code-management-detail/context-code-management-detail.component';
import { DashboardBackofficeComponent } from './dashboard/dashboard-backoffice/dashboard-backoffice.component';
import { DashboardBackofficeOrdersComponent } from './dashboard/dashboard-backoffice-orders/dashboard-backoffice-orders.component';
import { DashboardBackofficeClientsComponent } from './dashboard/dashboard-backoffice-clients/dashboard-backoffice-clients.component';
import { DashboardBackofficeSoldComponent } from './dashboard/dashboard-backoffice-sold/dashboard-backoffice-sold.component';
import { DashboardBackofficeExpiredComponent } from './dashboard/dashboard-backoffice-expired/dashboard-backoffice-expired.component';
import { InformativePageListComponent } from './informative-page/informative-page-list/informative-page-list.component';
import { InformativePageDetailComponent } from './informative-page/informative-page-detail/informative-page-detail.component';
import { BackofficeReportsComponent } from './backoffice-reports/backoffice-reports.component';
import { ProductStockComponent } from './product-stock/product-stock.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { BackofficeForecastComponent } from './backoffice-forecast/backoffice-forecast.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { AdditionalServiceConfigurationComponent } from './additional-service-configuration/additional-service-configuration.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule],
	declarations: [
		DataConfigurationComponent,
		UserManagementComponent,
		UserManagementDetailComponent,
		ContextCodeManagementComponent,
		ContextCodeManagementDetailComponent,
		DashboardBackofficeComponent,
		DashboardBackofficeOrdersComponent,
		DashboardBackofficeClientsComponent,
		DashboardBackofficeSoldComponent,
		DashboardBackofficeExpiredComponent,
		InformativePageListComponent,
		InformativePageDetailComponent,
		BackofficeReportsComponent,
		ProductStockComponent,
		CategoryListComponent,
		BackofficeForecastComponent,
		RichTextEditorComponent,
		AdditionalServiceConfigurationComponent
	],
	exports: [
		DataConfigurationComponent,
		UserManagementComponent,
		UserManagementDetailComponent,
		ContextCodeManagementComponent,
		ContextCodeManagementDetailComponent,
		DashboardBackofficeComponent,
		DashboardBackofficeOrdersComponent,
		DashboardBackofficeClientsComponent,
		DashboardBackofficeSoldComponent,
		DashboardBackofficeExpiredComponent,
		ProductStockComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageBackofficeModule {}
