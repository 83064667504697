import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {MediaCenterContext, MediaCenterFilter} from '../../model/media-center.model';
import { UtilService } from '../../service/util.service';
import { RxStompService } from '@saep-ict/angular-core';
import { ILoaderService, LOADER_SERVICE_TOKEN } from '../../interface/loader-service.interface';

@Component({
  selector: 'mc-media-center',
  templateUrl: './media-center.component.html',
  styleUrls: ['./media-center.component.scss'],
})
export class MediaCenterComponent implements OnInit, OnDestroy {

  @Input() customHeaders: any;
  @Input() mediaCenterFilter: MediaCenterFilter;

  constructor(
    public utilService: UtilService,
    public rxStompService: RxStompService,
    @Optional() @Inject(LOADER_SERVICE_TOKEN) public loaderService: ILoaderService,
  ) {}

  ngOnInit() {
    const context: MediaCenterContext = {
      customHeaders: this.customHeaders,
      filter: this.mediaCenterFilter
    };
    this.utilService.context.next(context);
  }

  ngOnDestroy() {}

}
