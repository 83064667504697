import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { NewsListActionEnum, NewsListStateAction } from './news-list.actions';
import { NewsModel } from '../../model/news.model';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { PouchDbConstant } from '@saep-ict/angular-spin8-core';
import { AllDocsDataPouchModel } from '@saep-ict/pouch_agent_models';
import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class NewsListEffects {
	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NewsListActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<NewsModel[]>) => from(this.getAllDocs(action))),
			map((action: BaseStateModel<NewsModel[]>) => NewsListStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(NewsListStateAction.error(null));
				return caught;
			})
		)
	);

	saveAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NewsListActionEnum.SAVE_ALL),
			mergeMap((action: BaseStateModel<NewsModel[]>) => from(this.putAllDocs(action))),
			//map((action: BaseStateModel<NewsModel[]>) => NewsListStateAction.update(action)),
            map(() => NewsListStateAction.loadAll()),
			catchError((error, caught) => {
				this.store.dispatch(NewsListStateAction.error(error));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter
	) {}

	async getAllDocs(action: BaseStateModel<NewsModel[]>): Promise<BaseStateModel<NewsModel[]>> {
		const documentName = 'news';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
		};

		await this.pouchDbCommonsAdapter.basePouch
			.allDocs(allDocsParam)
			.then((res: AllDocsDataPouchModel<NewsModel>) => {
				const rows: NewsModel[] = res.rows.map(i => i.doc);
				action.data = rows.filter(item => item.valid);
			})
			.catch(err => {
				throw new Error(err.message);
			});
		return action;
	}

	async putAllDocs(action: BaseStateModel<NewsModel[]>): Promise<BaseStateModel<NewsModel[]>> {
		let _list = [];
		for await (const news of action.data) {
			await this.pouchDbCommonsAdapter.basePouch
				.saveBodyTable(news)
				.then(async news => {
					_list.push(news);
				})
				.catch(err => {
					throw new Error(err.message);
				});
		}
		return new BaseState(_list);
	}
}
