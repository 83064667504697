<mat-dialog-wrapper
	[title]="
		'context_code.associate_x_existent'
			| translate: { context_code: data.context_code_item.description | translate | sentencecase }
			| sentencecase
	"
	[showCloseIcon]="false"
>
	<ng-container content>
		<context-code-management-wrapper
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="permissioneContextListColumn"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="true"
			[showBadgeQty]="false"
			(onItemSelect)="selectCode($event)"
		>
		</context-code-management-wrapper>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
