import { OrderPouchModel, ArticlePouchModel, AttachmentListModel, AttachedFile } from '@saep-ict/pouch_agent_models';
import { OpportunityPouchModel } from '../state/opportunity-state.model';

export interface OfferOtherDescriptionModel {
	object?: string;
	summary?: string;
	references?: string;
	premise?: string;
}

export interface OfferHeaderModel {
	opportunity: OpportunityPouchModel;
	title: string;
	first_evasion_date?: number;
	other_descriptions?: OfferOtherDescriptionModel;
}

export interface CrmOrderProductPouchModel extends ArticlePouchModel {
	description: string;
}

export interface OfferStateModel extends OrderPouchModel<OfferHeaderModel>, AttachmentListModel<AttachedFile> {
	order_type?: string;
	free_line_list?: CrmOrderProductPouchModel[];
	code_item?: string;
	source?: string;
}
