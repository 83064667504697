<div class="display-flex">
  <h1 mat-dialog-title>{{ 'Ticket / ' + (ticket.id ? ticket.id : ('tc.general.new' | translate)) }}</h1>
  <span class="flex-span"></span>
  <button
    mat-icon-button
    (click)="dialogRef.close()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <ng-scrollbar
    [visibility]="'hover'"
    [style.height]="'65vh'"
  >
    <tc-ticket-detail-wrapper
      [ticket]="ticket"
      (onSave)="onTicketDetailFormSave($event)"
      (onCancel)="onTicketDetailFormCancel()"
    >
    </tc-ticket-detail-wrapper>
    <!--
      temporaneamente deprecato, in favore di una select semplice, direttamente all'interno del form
      TODO: ripristinare o eliminare del tutto
    -->
    <!-- <tc-status-selector
      [currentStatus]="ticket.id ? ticket.status : null"
      (onStatusChange)="onTicketDetailStatusSelectorChange($event)"
    ></tc-status-selector> -->
  </ng-scrollbar>
</mat-dialog-content>
