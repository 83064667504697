import {Component, Input, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {filter, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseStateModel, GuidFormatterPipe, SubscribeManagerService} from '@saep-ict/angular-core';
import {AuthService} from '../../../service/rest/auth.service';
import {StateFeature} from '../../../state';
import {Router} from '@angular/router';
import {CustomerAppConfig} from '../../../customer-app.config';
import {
	AngularSpin8CoreUtilFontsizeService,
	AngularSpin8CoreUtilTranslateService,
	ContextApplicationItemCodeEnum,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import {AppUtilService} from '../../../service/util/app-util.service';
import {BucketManagerService} from '../../../service/util/util-bucket-manager.service';
import * as UserConfiguration from '../../../constants/user.constant';
import {SubscribeManagerItem} from '../../../model/subscribe-manager.model';
import {UtilUserService} from '../../../service/util/util-user.service';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import {MatSelectChange} from '@angular/material/select';
import {ToolbarUserButtonMenuSubItemList} from '../../../model/toolbar-user-button.model';
import * as ConfigurationCustomerAuthentication from '../../../constants/configuration-customer/authentication/authentication.constant';
@Component({
	selector: 'ms-toolbar-user-button',
	templateUrl: './toolbar-user-button.component.html',
	styleUrls: ['./toolbar-user-button.component.scss'],
	providers: [SubscribeManagerService]
})
export class ToolbarUserButtonComponent implements OnDestroy {
	@Input() openUp = false;
	@Input() contextClass : string = null;



	panelOpenState = false;
	isOpen: boolean;
	userInfo: string;
	menuSubItemList: ToolbarUserButtonMenuSubItemList = {
		fontSize: false,
		language: false
	};

	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	userRole: string;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'user-data', observable: this.subscribeUserData() },
		{ key: 'user-avatar-data', observable: this.subscribeUserAvatarData() }

	];

	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	avatarUrl: string;
	avatarContainerShow = true

	/**
	 * Serve a indicare se in almeno in un contesto ho più codici
	 */
	get hasMultipleContextCode() {
		return this.user.context_application_list.some(
			context_application => context_application.context_code_list.length > 1
		);
	}

	configurationCustomerAuthentication = ConfigurationCustomerAuthentication;

	constructor(
		public authService: AuthService,
		private store: Store,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public router: Router,
		public utilFontsizeService: AngularSpin8CoreUtilFontsizeService,
		public appConfig: CustomerAppConfig,
		private utilService: AppUtilService,
		private guidFormatterPipe: GuidFormatterPipe,
		private bucketManagerService: BucketManagerService,
		private utilUserService: UtilUserService,
		private subscribeManagerService: SubscribeManagerService
	) {
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	setUserInfo() {
		const contextApplication =
			ConfigurationCustomerAuthentication.routingPermissionTypeList.find(
				item => item.type === this.user.current_permission.context_application
			);
		this.userRole = `
			${contextApplication.description}
			 -
				${
			this.user.current_permission.context_code.code_erp ?
				this.user.current_permission.context_code.code_erp :
				this.guidFormatterPipe.transform(this.user.current_permission.context_code.code)
		}

		`;
		this.userInfo = this.utilService.getUserNameFormatted(this.user);
		this.avatarUrl =
			this.bucketManagerService.returnUrlWithCacheReset(
				this.bucketManagerService.returnBucketManagerDownloadUrl(
					UserConfiguration.userAvatarFileName,
					'user',
					this.user.id
				)
			);
	}

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	menuSubItemToggle(e: string) {
		this.menuSubItemList[e] = !this.menuSubItemList[e];
		for (const key of Object.keys(this.menuSubItemList)) {
			if (key !== e) {
				this.menuSubItemList[key] = false;
			}
		}
	}

	onClickOutside() {
		this.isOpen = false;
	}

	subscribeUserAvatarData(): Observable<void> {
		return this.utilUserService.updateAvatar$.pipe(
			map(() => {
				this.avatarContainerShow = false;
				setTimeout(() =>{
					this.avatarUrl =
						this.bucketManagerService.returnUrlWithCacheReset(
							this.bucketManagerService.returnBucketManagerDownloadUrl(
								UserConfiguration.userAvatarFileName,
								'user',
								this.user.id
							)
						);
					this.avatarContainerShow = true;
				}, 500);
			})
		)
	}


	subscribeUserData(): Observable<void> {
		return this.user$.pipe(
			filter(res => !!(res && res.data && res.data.current_permission)),
			map(res => {
				this.user = res.data;
				this.setUserInfo();
			})
		);
	}

	changeFontSize(fontsize) {
		return new MatSelectChange(null, fontsize);
	}


}
