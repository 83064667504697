import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import {
	AuxiliaryTableStateModel,
	OrderStateModel,
	ProductVariationIconEnum,
	ProductVariationTypeEnum
} from '@saep-ict/angular-spin8-core';
import { CausalRowSoPouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
// state
import { StateFeature } from '../../../../../state';

@Component({
	selector: 'order-detail-variation',
	templateUrl: './order-detail-variation.component.html',
	styleUrls: ['./order-detail-variation.component.scss']
})
export class OrderDetailVariationComponent implements OnInit {
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	lastVariationOrderDate: string;
	productListGroupedByCode: {};

	oldValue: any;
	newValue: any;

	// enum
	productVariationTypeEnum = ProductVariationTypeEnum;
	productVariationIconEnum = ProductVariationIconEnum;

	constructor(private store: Store<any>) {
		// state
		this.order$.pipe(take(1)).subscribe(res => {
			this.order = res ? res.data : null;
		});
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
	}

	ngOnInit() {
		if (this.order.order_variation_list && this.order.order_variation_list.length) {
			this.getLastVariationOrderDate();
			this.getProductListGroupedByCode();
		} else {
			this.productListGroupedByCode = null;
		}
	}

	getLastVariationOrderDate() {
		// Ordina gli oggetti per data DESC e prende il primo
		this.order.order_progress.values.sort((a, b) => (a.date < b.date ? 1 : -1));
		this.lastVariationOrderDate = moment(new Date(this.order.order_progress.values[0].date)).format(
			'DD/MM/YYYY HH:mm'
		);
	}

	getProductListGroupedByCode() {
		this.productListGroupedByCode = _.groupBy(this.order.order_variation_list, 'productCode');
	}

	checkPropertyName(propertyName: any, oldValue: any, newValue: any) {
		let oldCausal: CausalRowSoPouchModel;
		let newCausal: CausalRowSoPouchModel;
		if (propertyName === 'order_causal_code') {
			const causalRowList = this.auxiliaryTable.causalRowSoList;
			oldCausal = causalRowList.find(causalCode => {
				return causalCode.code_item === oldValue;
			});
			newCausal = causalRowList.find(causalCode => {
				return causalCode.code_item === newValue;
			});
		}
		this.oldValue = oldCausal ? oldCausal.description : oldValue;
		this.newValue = newCausal ? newCausal.description : newValue;
		return propertyName;
	}
}
