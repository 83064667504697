import { PassowrdMeterEnum } from "../../enum/widget/password-meter.enum";
import { PasswordMeterModel } from "../../model/widget/password-meter.model";

export namespace PassowordMeterConstant {
  export const ruleList: PasswordMeterModel.Rule[] = [
    {
      name: PassowrdMeterEnum.NameCheck.LENGTH,
      description: 'saep_ict_angular_spin8_core.widget.password_meter.validation.length',
      isRequired: true,
      params: { minLength: 8 }
    },
    {
      name: PassowrdMeterEnum.NameCheck.LOWERCASE,
      description: 'saep_ict_angular_spin8_core.widget.password_meter.validation.lowercase',
      isRequired: true
    },
    {
      name: PassowrdMeterEnum.NameCheck.UPPERCASE,
      description: 'saep_ict_angular_spin8_core.widget.password_meter.validation.uppercase',
      isRequired: true
    },
    {
      name: PassowrdMeterEnum.NameCheck.DIGIT,
      description: 'saep_ict_angular_spin8_core.widget.password_meter.validation.digit',
      isRequired: false
    },
    {
      name: PassowrdMeterEnum.NameCheck.SPECIAL_CHAR,
      description: 'saep_ict_angular_spin8_core.widget.password_meter.validation.special_character',
      isRequired: false
    }
  ];

}
