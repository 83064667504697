import { IPouchAppConfig } from "./app.config";
import {
  ConfigCouchModel,
  IPouchConfigModel,
} from "./environment-config.model";
import { PouchDbAdapter } from "./structure/pouchdb-adapter";

export class PouchUtilService {
  pouchInstance: PouchDbAdapter[];
  config: IPouchConfigModel;

  constructor(private appConfig: IPouchAppConfig) {
    this.appConfig.config$.subscribe((config) => {
      if (config && config.couch) {
        this.config = appConfig.config;
      }
    });
  }

  explicitInitCouch(toStartDbList?: ConfigCouchModel[]) {
    if (!toStartDbList) {
      toStartDbList = this.config && this.config.couch ? this.config.couch : [];
    }
    return new Promise(async (resolve, reject) => {
      try {
        for (const pouchdb of this.pouchInstance) {
          const environment = toStartDbList.find((env) => {
            return env.database === pouchdb.database;
          });
          if (environment) {
            await pouchdb.initDb(environment);
          }
        }
        resolve(null);
      } catch (err) {
        reject();
      }
    });
  }

  async explicitStopCouchDb(toStopDbList: ConfigCouchModel[]) {
    if (!toStopDbList) {
      toStopDbList = this.config && this.config.couch ? this.config.couch : [];
    }
    try {
      await Promise.all(
        this.pouchInstance.map(async (pouchdb) => {
          const environment = toStopDbList.find(
            (env) => env.baseDatabaseTemplate === pouchdb.baseDatabaseTemplate
          );
          if (environment) {
            await pouchdb.closeConnection();
          }
        })
      );
    } catch (e) {}
  }
}
