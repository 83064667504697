import { createAction, props } from '@ngrx/store';
import { BaseStateModel, RestBasePk } from '@saep-ict/angular-core';
import { KanbanModel } from '@saep-ict/angular-spin8-core';

export enum KanbanActionEnum {
	UPDATE = '[kanban] Update',
	LOAD = '[kanban] Load',
	LOAD_ALL = '[kanban] Load all',
	SAVE = '[kanban] Save',
	REMOVE = '[kanban] Remove',
	REMOVED = '[kanban] Removed',
	RESET = '[kanban] Reset',
	ERROR = '[kanban] Error'
}

export namespace KanbanStateAction {
	export const load = createAction(KanbanActionEnum.LOAD, props<BaseStateModel<RestBasePk>>());
	export const loadAll = createAction(KanbanActionEnum.LOAD_ALL);
	export const update = createAction(KanbanActionEnum.UPDATE, props<BaseStateModel<KanbanModel.Base[]>>());
	export const save = createAction(KanbanActionEnum.SAVE, props<BaseStateModel<KanbanModel.Base[]>>());
	export const remove = createAction(KanbanActionEnum.REMOVE, props<BaseStateModel<KanbanModel.Base[]>>());
	export const removed = createAction(KanbanActionEnum.REMOVED);
	export const reset = createAction(KanbanActionEnum.RESET);
	export const error = createAction(KanbanActionEnum.ERROR, props<BaseStateModel<any>>());
}
