<div class="viewtype-toggle">
	<div>
		<!-- Visualizzazione -->
		<mat-button-toggle-group #toggleGroup="matButtonToggleGroup" [value]="viewTypeDefault">
			<mat-button-toggle
				class="mat-button-color"
				(click)="setViewType(toggleGroup.value)"
				[value]="viewTypeEnum.list"
			>
				<mat-icon class="viewtype-toggle__visual__icon material-icons"> view_list </mat-icon>
			</mat-button-toggle>
			<mat-button-toggle (click)="setViewType(toggleGroup.value)" color="primary" [value]="viewTypeEnum.module">
				<mat-icon class="viewtype-toggle__visual__icon material-icons"> view_module </mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
</div>
