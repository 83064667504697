import { DialogContactDetailComponent } from './dialog/dialog-contact-detail/dialog-contact-detail.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';
import { CardTrendComponent } from './card-trend/card-trend.component';
import { ChartWrapperComponent } from './chart-wrapper/chart-wrapper.component';
import { MatAccordionWrapperComponent } from './mat-accordion-wrapper/mat-accordion-wrapper.component';
import { NestedCategoryListComponent } from './nested-category-list/nested-category-list.component';
import { InputTypeSelectableComponent } from './input-type-selectable/input-type-selectable.component';
import { MatCardHighlightValueComponent } from './mat-card-highlight-value/mat-card-highlight-value.component';
import { DialogNewCompanyDestinationComponent } from './dialog/dialog-new-company-destination/dialog-new-company-destination.component';
import { MatSnackBarWrapperComponent } from './mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { PasswordVerifyComponent } from './compound-input-interface/password-verify/password-verify.component';
import { DialogDestinationDetailComponent } from './dialog/dialog-destination-detail/dialog-destination-detail.component';
import { AlertComponent } from './alert/alert.component';
import { EmojiResponseComponent } from './emoji-response/emoji-response.component';
import { DialogProductKitComponent } from './dialog/dialog-product-kit/dialog-product-kit.component';
import { B2cArticleItemComponent } from './b2c/article/b2c-article-item/b2c-article-item.component';
import { CardSliderComponent } from './card-slider/card-slider.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { DialogChangePermissionContextCodeComponent } from './dialog/dialog-change-permission-context-code/dialog-change-permission-context-code.component';
import { DialogAddContextCodeComponent } from './dialog/dialog-add-context-code/dialog-add-context-code.component';
import { FormPermissionGroupSelectComponent } from './form/form-permission-group-select/form-permission-group-select.component';
import { DialogContextCodeEditComponent } from './dialog/dialog-context-code-edit/dialog-context-code-edit.component';

// kanban
import { KanbanCardComponent } from './kanban/kanban-card/kanban-card.component';
import { KanbanColumnComponent } from './kanban/kanban-column/kanban-column.component';
import { KanbanContainerComponent } from './kanban/kanban-container/kanban-container.component';
import { OpportunityRatingComponent } from './opportunity-rating/opportunity-rating.component';
import { AvatarComponent } from './avatar/avatar.component';
import { KabanModule } from './kanban/kaban.module';
import { CommentThreadComponent } from './comment-thread/comment-thread.component';
import { UnorderedListComponent } from './unordered-list/unordered-list.component';
import { SortSelectorComponent } from './sort-selector/sort-selector.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ViewtypeToggleComponent } from './viewtype-toggle/viewtype-toggle.component';
import { B2cQuantitySelectorComponent } from './b2c/article/b2c-quantity-selector/b2c-quantity-selector.component';
import { CardAddressComponent } from './card-address/card-address.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { DialogOrderDetailComponent } from './dialog/dialog-order-detail/dialog-order-detail.component';
import { B2cCheckoutArticleListComponent } from './b2c/order/b2c-checkout-article-list/b2c-checkout-article-list.component';
import { FormErrorMessageComponent } from './form-error-message/form-error-message.component';
import { PsToTopComponent } from './ps-to-top/ps-to-top.component';
import { OrderListWrapperComponent } from './order/order-list-wrapper/order-list-wrapper.component';
import { OrganizationListWrapperComponent } from './organization/organization-list-wrapper/organization-list-wrapper.component';
import { OperationStateBannerComponent } from './operation-state-banner/operation-state-banner.component';
import { DialogDestinationSelect } from './dialog/dialog-destination-select/dialog-destination-select.component';
import { HighlightNumberComponent } from './highlight-number/highlight-number.component';
import { CardDashboardComponent } from './card-dashboard/card-dashboard.component';
import { IconTrendComponent } from './icon-trend/icon-trend.component';
import { ChartNoDataComponent } from './chart-no-data/chart-no-data.component';
import { LoadingResultsSpinnerComponent } from './loading-results-spinner/loading-results-spinner.component';
import { PasswordMeterComponent } from './password-meter/password-meter.component';
import { ReceiptListWrapperComponent } from './receipt/receipt-list-wrapper/receipt-list-wrapper.component';
import { ContactListWrapperComponent } from './contact/contact-list-wrapper/contact-list-wrapper.component';
import { OpportunityListWrapperComponent } from './opportunity/opportunity-list-wrapper/opportunity-list-wrapper.component';
import { RequestListWrapperComponent } from './request/request-list-wrapper/request-list-wrapper.component';
import { DialogSelectOrganizationComponent } from './dialog/dialog-select-organization/dialog-select-organization.component';
import { DialogSelectAssegneeComponent } from './dialog/dialog-select-assegnee/dialog-select-assegnee.component';
import { OrganizationRegistrationComponent } from '../page/commons/authentication-wrapper/organization-registration/organization-registration.component';
import { OfferListWrapperComponent } from './offer/offer-list-wrapper/offer-list-wrapper.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { DestinationListWrapperComponent } from './destination/destination-list-wrapper/destination-list-wrapper.component';
import { ArticleListStructureWrapperPlainListComponent } from './article/article-list-structure-wrapper/article-list-structure-wrapper-plain-list/article-list-structure-wrapper-plain-list.component';
import { DialogCloseOpportunityComponent } from './dialog/dialog-close-opportunity/dialog-close-opportunity.component';
import { ContextCodeEditComponent } from './context-code/context-code-edit/context-code-edit.component';
import { FormFieldSelectComponent } from './form/form-field-select/form-field-select.component';
import { CreditListWrapperComponent } from './credit/credit-list-wrapper/credit-list-wrapper.component';
import { ContextCodeManagementWrapperComponent } from './context-code/context-code-management-wrapper/context-code-management-wrapper.component';
import { OrganizationLegalInformationComponent } from './organization/organization-legal-information/organization-legal-information.component';
import { DialogArticleSelectComponent } from './dialog/dialog-article-select/dialog-article-select.component';

import { B2cCategoryItemComponent } from './b2c/category/b2c-category-item/b2c-category-item.component';
import { B2cArticlePriceComponent } from './b2c/article/b2c-article-price/b2c-article-price.component';
import { DialogOfferFreeLineComponent } from './dialog/dialog-offer-free-line/dialog-offer-free-line.component';
import { OfferFreeLineListWrapperComponent } from './offer/offer-free-line-list-wrapper/offer-free-line-list-wrapper.component';
import { RecursiveCategoryListComponent } from './recursive-category-list/recursive-category-list.component';
import { OrderDetailPriceComponent } from './order/order-detail-price/order-detail-price.component';
import { InformativePageListWrapperComponent } from './informative-page/informative-page-list-wrapper/informative-page-list-wrapper/informative-page-list-wrapper.component';
import { ActionListWrapperComponent } from './action/action-list-wrapper/action-list-wrapper.component';
import { DialogActionListComponent } from './dialog/dialog-action-list/dialog-action-list.component';
import { ActionComponent } from '../page/crm/action/action.component';
import { DialogSelectContactComponent } from './dialog/dialog-select-contact/dialog-select-contact.component';
import { DialogSelectOpportunityComponent } from './dialog/dialog-select-opportunity/dialog-select-opportunity.component';
import { ArticleListStructureWrapperCategoryTree } from './article/article-list-structure-wrapper/article-list-structure-wrapper-category-tree/article-list-structure-wrapper-category-tree.component';
import { ArticleListCategoryTreeRecursionCatalog } from './article/article-list-category-tree-recursion/article-list-category-tree-recursion-order/article-list-category-tree-recursion-order.component';
import { ArticleTableWrapperComponent } from './article/article-table-wrapper/article-table-wrapper.component';
import { ArticleInputMultipleValidationComponent } from './article/article-input-multiple-validation/article-input-multiple-validation.component';
import { IllustrationsModule } from '@saep-ict/angular-spin8-core';
import { ArticleImageComponent } from './article/article-image/article-image.component';
import { NewsListWrapperComponent } from './news/news-list-wrapper/news-list-wrapper.component';
import { IframeResolverComponent } from './iframe-resolver/iframe-resolver.component';
import { PouchPagingBarComponent } from './euroitalia/pouch-paging-bar/pouch-paging-bar.component';
import { ArticleChangeInputComponent } from './article/article-change-input/article-change-input.component';
import { DialogActionsComponent } from './dialog/dialog-actions/dialog-actions.component';
//import { TopBarComponent } from './top-bar/top-bar.component';
import { StatusBarNewComponent } from './status-bar-new/status-bar-new.component';
import { BoxTrendInfoComponent } from './box-trend-info/box-trend-info.component';
import { SidebarContentStandardComponent } from './sidebar-content-standard/sidebar-content-standard.component';
import { ClosablePanelComponent } from './closable-panel/closable-panel.component';
import { StatisticNestedCategoryComponent } from './statistic-nested-category/statistic-nested-category.component';
import { BoxCreditDebitInfoComponent } from './box-credit-debit-info/box-credit-debit-info.component';
import { CategoryListWrapperComponent } from './backoffice/category-list-wrapper/category-list-wrapper.component';
import { ArticleListCategoryTreeRecursionSummaryComponent } from './article/article-list-category-tree-recursion/article-list-category-tree-recursion-summary/article-list-category-tree-recursion-summary.component';
import { CategoryTabListComponent } from './category/category-tab-list/category-tab-list.component';
import { ArticleListStructureWrapperAccordionComponent } from './article/article-list-structure-wrapper/article-list-structure-wrapper-accordion/article-list-structure-wrapper-accordion.component';
import { ArticleListStructureBackofficeForecastWrapperAccordionComponent } from './article/article-list-structure-wrapper/article-list-structure-backoffice-forecast-wrapper-accordion/article-list-structure-backoffice-forecast-wrapper-accordion.component';
import { DialogImageGalleryComponent } from './dialog/dialog-image-gallery/dialog-image-gallery.component';
import { DialogCompanyInfoComponent } from './dialog/dialog-company-info/dialog-company-info.component';
import { DialogUploadFileComponent } from './dialog/dialog-upload-file/dialog-upload-file.component';
import { FaqComponent } from './faq/faq.component';
import { ForecastInputListComponent } from './forecast/forecast-input-list/forecast-input-list.component';
import { DialogAttachmentListManagerComponent } from './dialog/dialog-attachment-list-manager/dialog-attachment-list-manager.component';
import { OrganizationTableHeadingComponent } from './organization/organization-table-heading/organization-table-heading.component';
import { ArticleStockWrapperComponent } from './article/article-stock-wrapper/article-stock-wrapper.component';
import { ArticleStockStructureWrapperPlainListComponent } from './article/article-stock-structure-wrapper-plain-list/article-stock-structure-wrapper-plain-list.component';
import { OrderPdfComponent } from './pdf-print/order-pdf/order-pdf.component';
import { PaginationAndActionsComponent } from './pagination-and-actions/pagination-and-actions.component';
import { AddressTableHeadingComponent } from './address-table-heading/address-table-heading.component';

@NgModule({
	imports: [SharedModule, KabanModule, IllustrationsModule],
	declarations: [
		// dialog
		DialogChangePermissionContextCodeComponent,
		DialogProductKitComponent,
		DialogNewCompanyDestinationComponent,
		DialogDestinationDetailComponent,
		DialogContactDetailComponent,
		DialogAddContextCodeComponent,
		DialogContextCodeEditComponent,
		DialogDestinationSelect,
		DialogOrderDetailComponent,
		DialogSelectOrganizationComponent,
		DialogSelectAssegneeComponent,
		DialogCloseOpportunityComponent,
		DialogOfferFreeLineComponent,
		DialogActionListComponent,
		DialogSelectContactComponent,
		DialogSelectOpportunityComponent,
		DialogActionsComponent,
		DialogImageGalleryComponent,
		DialogUploadFileComponent,
		DialogAttachmentListManagerComponent,

		AutocompleteComponent,
		FloatingMenuComponent,
		CardTrendComponent,
		ChartWrapperComponent,
		MatAccordionWrapperComponent,
		MatSnackBarWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		MatCardHighlightValueComponent,
		PasswordMeterComponent,
		PasswordVerifyComponent,
		AlertComponent,
		EmojiResponseComponent,
		ArticleListCategoryTreeRecursionSummaryComponent,
		B2cArticleItemComponent,
		CardSliderComponent,
		JumbotronComponent,
		CallToActionComponent,
		KanbanCardComponent,
		KanbanColumnComponent,
		KanbanContainerComponent,
		OpportunityRatingComponent,
		AvatarComponent,
		CommentThreadComponent,
		UnorderedListComponent,
		SortSelectorComponent,
		PageHeaderComponent,
		ViewtypeToggleComponent,
		B2cQuantitySelectorComponent,
		NoDataFoundComponent,
		CardAddressComponent,
		B2cCheckoutArticleListComponent,
		FormPermissionGroupSelectComponent,
		FormErrorMessageComponent,
		CommentThreadComponent,
		AvatarComponent,
		KanbanCardComponent,
		KanbanColumnComponent,
		KanbanContainerComponent,
		OpportunityRatingComponent,
		PsToTopComponent,
		OpportunityListWrapperComponent,
		RequestListWrapperComponent,
		OfferListWrapperComponent,
		OfferFreeLineListWrapperComponent,
		OrderListWrapperComponent,
		OrganizationListWrapperComponent,
		DestinationListWrapperComponent,
		OperationStateBannerComponent,
		HighlightNumberComponent,
		CardDashboardComponent,
		IconTrendComponent,
		ChartNoDataComponent,
		LoadingResultsSpinnerComponent,
		ContactListWrapperComponent,
		ReceiptListWrapperComponent,
		OrganizationRegistrationComponent,
		RecaptchaComponent,
		ArticleListStructureWrapperPlainListComponent,
		ArticleListStructureWrapperCategoryTree,
		ArticleListCategoryTreeRecursionCatalog,
		ArticleTableWrapperComponent,
		ContextCodeEditComponent,
		FormFieldSelectComponent,
		CreditListWrapperComponent,
		ContextCodeManagementWrapperComponent,
		OrganizationLegalInformationComponent,
		DialogArticleSelectComponent,
		B2cCategoryItemComponent,
		B2cArticlePriceComponent,
		RecursiveCategoryListComponent,
		OrderDetailPriceComponent,
		InformativePageListWrapperComponent,
		ActionListWrapperComponent,
		ActionComponent,
		ArticleInputMultipleValidationComponent,
		ArticleImageComponent,
		NewsListWrapperComponent,
		IframeResolverComponent,
		PouchPagingBarComponent,
		ArticleChangeInputComponent,
		//TopBarComponent,
		StatusBarNewComponent,
		BoxTrendInfoComponent,
		SidebarContentStandardComponent,
		ClosablePanelComponent,
		StatisticNestedCategoryComponent,
		BoxCreditDebitInfoComponent,
		CategoryListWrapperComponent,
		CategoryTabListComponent,
		ArticleListStructureWrapperAccordionComponent,
		ArticleListStructureBackofficeForecastWrapperAccordionComponent,
		DialogCompanyInfoComponent,
		FaqComponent,
		ForecastInputListComponent,
		OrganizationTableHeadingComponent,
		ArticleStockWrapperComponent,
		ArticleStockStructureWrapperPlainListComponent,
		OrderPdfComponent,
  		PaginationAndActionsComponent,
		AddressTableHeadingComponent
	],
	exports: [
		// dialog
		DialogChangePermissionContextCodeComponent,
		DialogProductKitComponent,
		DialogNewCompanyDestinationComponent,
		DialogDestinationDetailComponent,
		DialogContactDetailComponent,
		DialogAddContextCodeComponent,
		DialogContextCodeEditComponent,
		DialogDestinationSelect,
		DialogSelectOrganizationComponent,
		DialogSelectAssegneeComponent,
		DialogCloseOpportunityComponent,
		DialogOfferFreeLineComponent,
		DialogActionListComponent,
		DialogSelectContactComponent,
		DialogSelectOpportunityComponent,
		DialogActionsComponent,
		DialogImageGalleryComponent,
		DialogUploadFileComponent,
		DialogAttachmentListManagerComponent,

		AutocompleteComponent,
		FloatingMenuComponent,
		CardTrendComponent,
		ChartWrapperComponent,
		MatAccordionWrapperComponent,
		MatSnackBarWrapperComponent,
		DestinationListWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		MatCardHighlightValueComponent,
		PasswordVerifyComponent,
		AlertComponent,
		EmojiResponseComponent,
		ArticleListCategoryTreeRecursionSummaryComponent,
		B2cArticleItemComponent,
		CardSliderComponent,
		JumbotronComponent,
		CallToActionComponent,
		FormPermissionGroupSelectComponent,
		KanbanCardComponent,
		KanbanColumnComponent,
		KanbanContainerComponent,
		OpportunityRatingComponent,
		AvatarComponent,
		CommentThreadComponent,
		UnorderedListComponent,
		SortSelectorComponent,
		PageHeaderComponent,
		ViewtypeToggleComponent,
		B2cQuantitySelectorComponent,
		NoDataFoundComponent,
		CardAddressComponent,
		B2cCheckoutArticleListComponent,
		FormErrorMessageComponent,
		PsToTopComponent,
		OpportunityListWrapperComponent,
		RequestListWrapperComponent,
		OfferListWrapperComponent,
		OfferFreeLineListWrapperComponent,
		OrderListWrapperComponent,
		OrganizationListWrapperComponent,
		ReceiptListWrapperComponent,
		OperationStateBannerComponent,
		HighlightNumberComponent,
		CardDashboardComponent,
		IconTrendComponent,
		ChartNoDataComponent,
		LoadingResultsSpinnerComponent,
		ContactListWrapperComponent,
		OrganizationRegistrationComponent,
		RecaptchaComponent,
		ArticleListStructureWrapperPlainListComponent,
		ArticleListStructureWrapperCategoryTree,
		ArticleListCategoryTreeRecursionCatalog,
		ArticleTableWrapperComponent,
		ArticleStockWrapperComponent,
		ArticleStockStructureWrapperPlainListComponent,
		ContextCodeEditComponent,
		FormFieldSelectComponent,
		CreditListWrapperComponent,
		ContextCodeManagementWrapperComponent,
		OrganizationLegalInformationComponent,
		B2cCategoryItemComponent,
		B2cArticlePriceComponent,
		RecursiveCategoryListComponent,
		OrderDetailPriceComponent,
		InformativePageListWrapperComponent,
		ActionListWrapperComponent,
		ActionComponent,
		ArticleInputMultipleValidationComponent,
		ArticleImageComponent,
		NewsListWrapperComponent,
		IframeResolverComponent,
		PouchPagingBarComponent,
		ArticleChangeInputComponent,
		//TopBarComponent,
		StatusBarNewComponent,
		BoxTrendInfoComponent,
		SidebarContentStandardComponent,
		ClosablePanelComponent,
		StatisticNestedCategoryComponent,
		BoxCreditDebitInfoComponent,
		CategoryListWrapperComponent,
		CategoryTabListComponent,
		ArticleListStructureWrapperAccordionComponent,
		ArticleListStructureBackofficeForecastWrapperAccordionComponent,
		FaqComponent,
		OrganizationTableHeadingComponent,
		OrderPdfComponent,
		PaginationAndActionsComponent,
		AddressTableHeadingComponent
	]
})
export class WidgetModule {}
