import _ from "lodash";
import * as FormDynamicUtil from '../../form-dynamic.util.constant';
import { ReceiptStateModel } from "../../../model/state/receipt-state.model";
import { ReceiptListFilterCreationFieldMapFormValue } from "../../../model/receipt-util.model";

// filter
export const returnConfigurationCustomerReceiptListFilterByDateRangeDateCreation = (
    list: ReceiptStateModel[],
    formValue: ReceiptListFilterCreationFieldMapFormValue
): Promise<ReceiptStateModel[]> => {
	return new Promise(resolve => {
		try {
			if (list && list.length && formValue && formValue.date_creation) {
				if (formValue.date_creation.start) {
					list = list.filter(i => i.date_creation >= +formValue.date_creation.start);
				}
				if (formValue.date_creation.end) {
					list = list.filter(i => i.date_creation <= +FormDynamicUtil.add1day(formValue.date_creation.end));
				}
			}
			resolve(list);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerReceiptListFilterByDateRangeDateExpiration = (
    list: ReceiptStateModel[],
    formValue: ReceiptListFilterCreationFieldMapFormValue
): Promise<ReceiptStateModel[]> => {
	return new Promise(resolve => {
		try {
			if (
				list &&
				list.length &&
				formValue &&
				formValue.expiration_date &&
				formValue.expiration_date.start &&
				formValue.expiration_date.end
			) {
				list = list.filter(i => {
					if (i.expiration_date && i.expiration_date.length) {
						for (const date of i.expiration_date) {
							if (
								date >= +formValue.expiration_date.start &&
								date <= +FormDynamicUtil.add1day(formValue.expiration_date.end)
							) {
								return true;
							}
						}
					}
				});
			}
			resolve(list);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerReceiptListFilterByPriceTotalMin = (
    list: ReceiptStateModel[],
    formValue: ReceiptListFilterCreationFieldMapFormValue
): Promise<ReceiptStateModel[]> => {
	return new Promise(resolve => {
		try {
			if (list && list.length && formValue && formValue.priceTotalMin) {
				list = list.filter(i => i.price.total >= +formValue.priceTotalMin);
			}
			resolve(list);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerReceiptListFilterByPriceTotalMax = (
    list: ReceiptStateModel[],
    formValue: ReceiptListFilterCreationFieldMapFormValue
): Promise<ReceiptStateModel[]> => {
	return new Promise(resolve => {
		try {
			if (list && list.length && formValue && formValue.priceTotalMax) {
				list = list.filter(i => i.price.total <= +formValue.priceTotalMax);
			}
			resolve(list);
		} catch(e) {
			throw new Error(e);
		}
	});
}