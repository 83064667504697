import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import _ from 'lodash';
import moment from 'moment';

const column: ITdDataTableColumnCustom[] = [
	{
		name: 'number',
		labelPath: 'receipt.field.document_number',
		label: null
	},
	// // TODO: determinare se utile a distinguere fatture e note di credito oppure deprecabile
	// {
	// 	name: 'document_type',
	// 	labelPath: 'receipt.field.document_type',
	// 	label: null,
	// 	sortable: false
	// },
	{
		name: 'organization',
		labelPath: 'saep_ict_angular_spin8_core.page.organization.name',
		label: null,
		width: { min: 210, max: 250 },
	},
	{
		name: 'date_creation',
		labelPath: 'receipt.field.date',
		label: null,
		width: { min: 100, max: 200 },
		numeric: true,
		format: (v: string) => moment(v).format("DD/MM/YYYY")
	},
	{
		name: 'expiration_date',
		labelPath: 'receipt.field.expiration_date',
		label: null,
		width: { min: 100, max: 200 },
		numeric: true,
		sortable: false, // sort su array non gestito
		format: (v: string) => {
			let dateList = '';
			if (v && v.length > 0) {
				for (let i = 0; i < v.length; i++) {
					const dateMoment = moment(v[i]);
					if (dateMoment.isValid()) {
						if (i > 0) {
							dateList = dateList + ',';
						}
						dateList = `${dateList} ${dateMoment.format("DD/MM/YYYY")}`;
					}
				}
			}
			return dateList;
		}
	},
	{
		name: 'price.total',
		labelPath: 'receipt.field.amount',
		label: null,
		numeric: true
	},
	{
		name: 'actions',
		label: null,
		sortable: false,
		numeric: true
	}
];

export const base: ITdDataTableColumnCustom[] = _.cloneDeep(column);
export const excludeForOrganization: string[] = ['organization'];
