import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { UserActionEnum, UserStateAction } from './user.actions';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { BaseStateModel } from '@saep-ict/angular-core';

export namespace UserStateReducer {
	export const initialState: BaseStateModel<UserDetailModel> = null;

	const _reducer = createReducer(
		initialState,
		on(UserStateAction.load, state => state),
		on(UserStateAction.update, (state, user) => user),
		on(UserStateAction.password_change, (state, user) => ({ data: state ? state.data : null, type: user.type })),
		on(UserStateAction.password_recovery, (state, user) => state),
		on(UserStateAction.completed, (state, user) => ({ data: state ? state.data : null, type: user.type })),
		on(UserStateAction.reset, () => initialState),
		on(UserStateAction.error, (state, user) => ({ data: state ? state.data : null, type: user.type }))
	);

	export function reducer(state: BaseStateModel<UserDetailModel>, action: TypedAction<UserActionEnum>) {
		return _reducer(state, action);
	}
}
