<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.dataSubset?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
		<button
			*ngIf="localListHandlerSidebarFilterShow"
			mat-icon-button
			(click)="localListHandlerOpenSidebarFilter.emit()"
		>
			<mat-icon>filter_list</mat-icon>
		</button>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null"
    [clickable]="true"
    (rowClick)="selectTicket.emit($event.row)"
  >
    <ng-template tdDataTableTemplate="request.urgent" let-value="value">
      <mat-icon
        *ngIf="value"
        [matTooltip]="'tc.general.request_is_urgent' | translate | sentencecase"
      >
        priority_high
      </mat-icon>
    </ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<!-- TODO: sostituire, una volta che loading-results-spinner è stato trasferito in libreria -->
<!-- <loading-results-spinner *ngIf="isLoading"></loading-results-spinner> -->
<div
  *ngIf="isLoading"
  class="loading-results-spinner"
>
	<mat-spinner class="loading-results-spinner__spinner" diameter="32"></mat-spinner>
</div>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
