import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Moment } from "moment";
import * as FormControlMultipurposeEnum from "../../enum/widget/form-control-multipurpose.enum";
import { QuillModules } from "ngx-quill";

export interface Item {
    form_id?: string;
    form_value?: any;
    name: string;
    on_form_template: boolean;
    label?: string;
    type?: FormControlMultipurposeEnum.ItemType;
    value?: any;
    validator_list?: any[];
    set_validator_list?: (formValue: any) => ((control: AbstractControl) => ValidationErrors)[];
    validator_message?: string;
    set_disabled?: (formValue: any) => boolean;
    clear_value_if_disabled?: boolean;
    option_list?: OptionList[];
    form_control_list?: Item[];
    column_width?: ColumnWidth;
    /**
     * Proprietà di appoggio che serve ad aggiungere `*` alle tipologie di form field che non lo aggiungono di
     * default quando avente validatore `required`.
     */
    required_placeholder_marker?: boolean;
    /**
     * Applicano soltanto nei casi di type `SELECT`, `SELECT_MULTIPLE`
     */
    // Se `true` implica il salvataggio dell'intero oggetto OptionList e non soltanto del value.
    select_save_whole_object?: boolean;
    // Se 'true' permette di visualizzare l'input per il filtraggio delle option by text
    selectFilterTextShow?: boolean;
    // Se 'true' permette di definire le proprietà sulle quali compiere la ricerca. Default `label`
    selectFilterTextBy?: string[];
    // Definisce il placeholder del select filter text input, con applicazione delle pipe `translate`, `sentencecase`.
    // Default `search`
    selectFilterTextPlaceholder?: string;
    /**
     * Applicano solo per il type `DATE` e `DATE_RANGE`
     */
    // Definiscono il date range selezionabile dall'utente
    dateMin?: Date | Moment;
    dateMax?: Date | Moment;
    // Definisce una regola sulle date selezionabili secondo i meccanismi di matDatepickerFilter
    dateFilter?: (d: Date) => boolean;
    fieldRTFConfiguration?: {
        height?: number,
        quill?: QuillModules
    }
}

export interface OptionList {
    label?: string;
    value: string | number;
}

export interface SetValidatorConfigurationParam {
    pathToProperty: string;
    propertyValue: any;
}

export type ColumnWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface ConfigurationEdit {
    formControlList: Item[];
    value: any;
}
