import { ITdDataTableColumnCustom } from "@saep-ict/pouch_agent_models";

export const baseColumns: ITdDataTableColumnCustom[] = [
    {
        name: 'action_type',
        labelPath: 'action.plural_name',
        label: null,
        width: 80
    },
    {
        name: 'text',
        labelPath: 'action.field.text',
        label: null
    },
    {
        name: 'organization.business_name',
        labelPath: 'action.field.referent',
        label: null
    },
    {
        name: 'assegnee_full_name',
        labelPath: 'action.field.assegnee',
        label: null
    },
    {
        name: 'start_date',
        labelPath: 'action.field.start_date',
        label: null
    },
    {
        name: 'estimated_close_date',
        labelPath: 'action.field.estimated_close_date',
        label: null
    },
    {
        name: 'close_date',
        labelPath: 'action.field.close_date',
        label: null
    },
    {
        name: 'status',
        labelPath: 'action.field.status',
        label: null,
        width: 80
        // numeric: true
    },
    {
        name: 'actions',
        label: '',
        width: 50,
        numeric: true,
        sortable: false
    }
];