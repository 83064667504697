<div class="loading-overlay" [class.show]="isLoading">
	<ng-container *ngIf="type === 'spinner'; else progress">
		<div class="loading-overlay__spinner">
			<mat-spinner color="primary" strokeWidth="4"></mat-spinner>
		</div>
	</ng-container>

	<ng-template #progress>
		<mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
	</ng-template>
</div>
