import { ConfigurationUtil, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import * as contactColumnMapBackoffice from './contact-column-map/contact-column-map-backoffice.constant'
import * as contactColumnMapB2B from './contact-column-map/contact-column-map-b2b.constant';
import * as contactColumnMapAgent from './contact-column-map/contact-column-map-agent.constant'
import * as contactColumnMapB2C from './contact-column-map/contact-column-map-b2c.constant';
import * as contactColumnMapCRM from './contact-column-map/contact-column-map-crm.constant';
import * as contactColumnMapPortal from './contact-column-map/contact-column-map-portal.constant';

export const columnList = (ContextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumnCustom[] => {
    switch(ContextApplication){
        case(ContextApplicationItemCodeEnum.BACKOFFICE):
            return contactColumnMapBackoffice.projectOverride;
        case(ContextApplicationItemCodeEnum.AGENT):
            return contactColumnMapAgent.projectOverride;
        case(ContextApplicationItemCodeEnum.B2B):
            return contactColumnMapB2B.projectOverride;
        case(ContextApplicationItemCodeEnum.B2C):
            return contactColumnMapB2C.projectOverride;
        case(ContextApplicationItemCodeEnum.CRM):
            return contactColumnMapCRM.projectOverride;
        case(ContextApplicationItemCodeEnum.PORTAL):
            return contactColumnMapPortal.projectOverride;
    }
};
export const canCreate: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: true,
    B2C: false,
    BACKOFFICE_ADMIN: true,
    BACKOFFICE: true,
    PORTAL: true,
    CRM: true,
    PUBLIC: false
};
export const canEdit: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: true,
    B2C: false,
    BACKOFFICE_ADMIN: true,
    BACKOFFICE: true,
    PORTAL: true,
    CRM: true,
    PUBLIC: false
};
