import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';
import {
	PassowordMeterConstant,
	PassowrdMeterEnum,
	AngularSpin8CoreUtilPasswordVerifyService
} from '@saep-ict/angular-spin8-core';
import _ from 'lodash';

@Component({
	selector: 'password-meter',
	templateUrl: './password-meter.component.html',
	styleUrls: ['./password-meter.component.scss']
})
export class PasswordMeterComponent implements OnInit, OnDestroy {
	isPasswordValid: boolean = false;
	@Output() isPasswordValidEmitter = new EventEmitter<boolean>();
	@Input() newPassword: Observable<string>;
	newPasswordSubscription: Subscription;
	passowrdMeterEnum = PassowrdMeterEnum;
	passwordMeter: number = 0;
	passwordMeterDialog: string = 'login.general.password_meter.empty';
	passwordRules = _.cloneDeep(PassowordMeterConstant.ruleList);
	satisfiedRules: boolean[] = new Array(this.passwordRules.length).map(elem => false);

	constructor(private utilPasswordVerifyService: AngularSpin8CoreUtilPasswordVerifyService) {}

	ngOnInit() {
		this.passwordRules = this.utilPasswordVerifyService.sortRulesIsRequired(this.passwordRules);
		this.newPasswordSubscription = this.newPassword.subscribe(newPassword => {
			this.assessPassword(newPassword);
		});
	}

	assessPassword(password: string): void {
		this.satisfiedRules = this.utilPasswordVerifyService.checkSatisfied(password, this.passwordRules);
		this.passwordMeter = this.utilPasswordVerifyService.mapScore(password, this.satisfiedRules);
		switch (this.passwordMeter) {
			case PassowrdMeterEnum.PasswordStrength.EMPTY:
				this.passwordMeterDialog = 'login.general.password_meter.empty';
				this.isPasswordValid = false;
				break;
			case PassowrdMeterEnum.PasswordStrength.WEAK:
				this.passwordMeterDialog = 'login.general.password_meter.weak';
				this.isPasswordValid = false;
				break;
			case PassowrdMeterEnum.PasswordStrength.GOOD:
				this.passwordMeterDialog = 'login.general.password_meter.good';
				this.isPasswordValid = true;
				break;
			case PassowrdMeterEnum.PasswordStrength.EXCELLENT:
				this.passwordMeterDialog = 'login.general.password_meter.excellent';
				this.isPasswordValid = true;
				break;
		}
		this.isPasswordValidEmitter.emit(this.isPasswordValid);
	}

	ngOnDestroy() {
		this.newPasswordSubscription.unsubscribe();
	}
}
