import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaCenterModel } from '../../../../model/media-center.model';
import { PermissionEnum } from '../../../../model/enum/permission.enum';
import { MediaCenterService } from '../../../../service/rest/media-center.service';
import { UtilService } from '../../../../service/util.service';
import { DialogDeleteComponent } from '../../../../widget/dialog/dialog-delete/dialog-delete.component';
import { DialogMoveItemListComponent } from '../../../../widget/dialog/dialog-move-item-list/dialog-move-item-list.component';
import { DialogNameEditComponent } from '../../../../widget/dialog/dialog-name-edit/dialog-name-edit.component';
import { finalize } from 'rxjs';

// import { SelectionTypeEnum } from '../../enum/chat.enum';

import {
	AttachmentListManager,
	AttachmentResolverService,
	UploadService,
	SelectionTypeEnum
} from '@saep-ict/angular-core';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { FolderUtilService } from '../../../../service/folder-util.service';

@Component({
	selector: 'media-center-home-sidebar',
	templateUrl: './media-center-home-sidebar.component.html',
	styleUrls: ['./media-center-home-sidebar.component.scss'],
})
export class MediaCenterHomeSidebarComponent implements OnInit {
	@Input() currentFolder: MediaCenterModel;
	/**
	 * L'emitter scatta in caso di avvenuto
	 * - creazione cartella
	 * - upload lista elementi
	 * - spostamento lista elementi
	 *
	 * @memberof MediaCenterHomeSidebarComponent
	 */
	@Output() sidebarEventSuccess = new EventEmitter();
	permissionEnum = PermissionEnum;
	public selectionTypeEnum = SelectionTypeEnum;

	attachmentListManagerConfiguration: AttachmentListManager.Configuration<AttachedFile> = {
		localListHandler: null,
		page: null,
		privilege: {
			add: true
		},
		pathUrl: this.utilService.context.value.filter.bucketFolderName
	};

	constructor(
		private dialog: MatDialog,
		public utilService: UtilService,
		private mediaCenterService: MediaCenterService,
		public uploadService: UploadService,
		private attachmentResolverService: AttachmentResolverService,
		private mediaCenterFolderUtilCenter: FolderUtilService
	) { }

	ngOnInit() { }

	// widget & utility
	// dialog
	openDialogCreateFolder(): void {
		const dialog = this.dialog.open(DialogNameEditComponent, {
			width: '80vw',
			panelClass: ['michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((name: string) => {
			if (name) {
				this.createFolder(name);
			}
		});
	}

	openDialogMoveItemList(): void {
		const dialog = this.dialog.open(DialogMoveItemListComponent, {
			data: this.utilService.itemListSelected,
			width: '80vw',
			panelClass: ['michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((res) => {
			if (res) {
				this.sidebarEventSuccess.emit();
			}
		});
	}

	openDialogDeleteItemList(): void {
		const pathList: string[] = this.utilService.itemListSelected.map(i => i.path);
		const dialog = this.dialog.open(DialogDeleteComponent, {
			data: pathList,
			width: '80vw',
			panelClass: ['michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((result) => {
			if (result) {
				this.deleteItemList(pathList);
			}
		});
	}


	async createFoldersTreeOnBucket(foldersTree: any, path: string = '') {
		await this.mediaCenterFolderUtilCenter.createFoldersTreeOnBucket(foldersTree, path);
	}

	// rest
	fileUpload(files: FileList) {
		/** Verifico se si tratta di una cartella tramite la presenza di 'webkitRelativePath' **/
		if(this.mediaCenterFolderUtilCenter.checkIfFolder(files)) {
			const foldersTree = this.mediaCenterFolderUtilCenter.createFoldersTree(Array.from(files));
			this.createFoldersTreeOnBucket(foldersTree, '')
				.then(() => {
					// this.utilService.loadSnackbarWithAsyncTranslatedAction("saep_ict_angular_core.attachment_resolver.message.tree_folders_created",
					// 	'mc.general.close',
					// );
					this.upload(files, true);
				})
				.catch((error) => {
					//console.error("Si è verificato un errore:", error);
					this.utilService.loadSnackbarWithAsyncTranslatedAction(
						error.message,
						'mc.general.close',
						0
					);
					this.attachmentResolverService.empty();
				});
		} else {
			this.upload(files);
		}

	}

	upload(files: FileList, isDir: boolean = false) {
		Array.from(files).forEach((file: File) => {
			const attachmentListManagerConfiguration: AttachmentListManager.Configuration<AttachedFile> =
				_.cloneDeep(this.attachmentListManagerConfiguration);
			attachmentListManagerConfiguration.pathQueryParam = this.returnPathQueryParam(this.currentFolder.path, file.webkitRelativePath);
			this.uploadService.addFileToUpload(file, attachmentListManagerConfiguration);
		});
		if (files?.length) {
			try {
				this.uploadService
					.startUpload()
					.pipe(
						finalize(() => {
							// TODO: sembra funzionare MA verificare quanto segnalato a riga 154
							this.attachmentResolverService.empty();
							this.sidebarEventSuccess.emit();
							this.utilService.loadSnackbarWithAsyncTranslatedAction("saep_ict_angular_core.attachment_resolver.message.upload_completed",
								'mc.general.close', 0
							);
					}))
					.subscribe(v => {
						// se li sposto nel finalize non vengono invocati dopo la creazione dell'albero delle cartelle
						// in caso di upload di una directory
						// this.attachmentResolverService.empty();
						// this.sidebarEventSuccess.emit();

						// this.attachmentResolverService.empty();
						// this.sidebarEventSuccess.emit();
					});
			} catch (err) {
				this.utilService.loadSnackbarWithAsyncTranslatedAction(
					'mc.error.upload_item',
					'mc.general.close',
					2000
				);
				throw new Error(err);
			}
		}
	}

	createFolder(name: string) {
		this.mediaCenterService
			.createFolder({ path: this.utilService.context.value.filter.path, newName: name }, null, { fileType: 'folder' })
			.then((res) => { this.sidebarEventSuccess.emit(); })
			.catch((err) => { console.log(err); });
	}

	deleteItemList(pathList: string[]) {
		this.mediaCenterService
			.deleteFileList({ pathList: pathList })
			.then((res) => {
				this.sidebarEventSuccess.emit();
			})
			.catch((err) => {
				console.error(err);
			});
	}

	returnPathQueryParam(e: string, webkitRelativePathString: string = "") {
		let res: string;
		if (e.length > 1) {
			res = e.slice(1, -1);
		}

		if(webkitRelativePathString) {
			const webkitRelativePathArray = webkitRelativePathString.split("/");
			webkitRelativePathArray.pop();
			const webkitRelativePath = webkitRelativePathArray.join("/");
			return res ? res + "/" + webkitRelativePath : webkitRelativePath;
		}

		return res;
	}
}
