import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'icon-trend',
	templateUrl: './icon-trend.component.html',
	styleUrls: ['./icon-trend.component.scss']
})
export class IconTrendComponent implements OnInit {
	@Input() value: number;

	constructor() {}

	ngOnInit() {}
}
