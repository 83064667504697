import { Component, EventEmitter, Output } from '@angular/core';
import { GuidFormatterPipe } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';
import {
	ContextApplicationItemCodeEnum,
	OpportunityEnum,
	AngularSpin8CoreUtilCompanyService,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../service/util/app-util.service';

@Component({
	selector: 'request-list-wrapper',
	templateUrl: './request-list-wrapper.component.html',
	styleUrls: ['./request-list-wrapper.component.scss']
})
export class RequestListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();
	@Output() openDialogContactDetail: EventEmitter<OpportunityPouchModel> = new EventEmitter();
	@Output() openDialogAddAction: EventEmitter<ContactPouchModel> = new EventEmitter();
	@Output() openDialogActionList: EventEmitter<ContactPouchModel> = new EventEmitter();

	OpportunityEnum = OpportunityEnum;
	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		public utilService: AppUtilService,
		public translateService: TranslateService,
		public store: Store,
		public guidFormatterPipe: GuidFormatterPipe
	) {
		super(utilService, translateService, store);
	}

	getMainContactEmail(contactList: ContactPouchModel[]) {
		return this.utilService.returnIsMainOfList<ContactPouchModel>(contactList).email;
	}
}
