import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { AuxiliaryTableActionEnum, AuxiliaryTableStateAction } from './auxiliary-table.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { Store } from '@ngrx/store';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { HeaderTablePouchModel } from '@saep-ict/pouch_agent_models';
import { AuxiliaryDictionary, AuxiliaryTableStateModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';
@Injectable()
export class AuxiliaryTableEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuxiliaryTableActionEnum.LOAD),
			mergeMap(() => from(this.getAuxiliaryTable())),
			map((auxiliaryTable: BaseStateModel<AuxiliaryTableStateModel>) =>
				AuxiliaryTableStateAction.update(auxiliaryTable)
			),
			catchError((error, caught) => {
				this.store.dispatch(AuxiliaryTableStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter
	) {}

	async getAuxiliaryTable(): Promise<BaseStateModel<AuxiliaryTableStateModel>> {
		const promises = [];
		const auxiliaryTable: AuxiliaryTableStateModel = {};
		// TODO: spostare in libreria una volta tornata aggiornabile (merge keycloack?)
		AuxiliaryDictionary.push(
			{
				key: 'table_area_commercial',
				type: 'areaCommercial'
			}
		);
		AuxiliaryDictionary.forEach((auxiliary: { key: string; type: string }) => {
			let key = auxiliary.key.replace('table_', '');
            key = 'table' + ConfigurationCustomerAppStructure.noSqlDocSeparator + key;
			promises.push(
				this.pouchDbCommonsAdapter.basePouch
					.getDetail(key)
					.then((doc: HeaderTablePouchModel<any>) => {
						return (auxiliaryTable[auxiliary.type] = doc.values);
					})
					.catch(err => {
						return (auxiliaryTable[auxiliary.type] = null);
					})
			);
		});
		await Promise.all(promises);
		return new BaseState(auxiliaryTable);
	}
}
