import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AdditionalServiceActionEnum, AdditionalServiceStateAction } from './additional-service.action';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { AdditionalServiceStateModel, PouchDbConstant } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';
import * as AdditionalServiceModel from '../../model/additional-service.model';

@Injectable()
export class AdditionalServiceEffects {
	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdditionalServiceActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<AdditionalServiceStateModel>) =>
				from(this.getAllAdditionalServices(action))
			),
			map((state: BaseStateModel<AdditionalServiceStateModel>) => AdditionalServiceStateAction.update(state)),
			catchError((error, caught) => {
				this.store.dispatch(AdditionalServiceStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdditionalServiceActionEnum.SAVE),
			mergeMap((e: AdditionalServiceModel.SavePayload) => from(this.save(e))),
			map((e) => AdditionalServiceStateAction.saveComplete(null)),
			catchError(() => of({ type: AdditionalServiceActionEnum.ERROR }))
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private commonsAdapter: PouchDbCommonsAdapter) {}
	async getAllAdditionalServices(
		action: BaseStateModel<AdditionalServiceStateModel>
	): Promise<BaseStateModel<AdditionalServiceStateModel>> {
		const documentName = 'additional_services';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
		};
		action.data = {};

		await this.commonsAdapter.basePouch
			.allDocs(allDocsParam)
			.then(res => {
				res.rows.forEach((service: any) => {
					action.data[service.doc.service_type] = service.doc.values;
				});
			})
			.catch(err => console.log(err));
		return action;
	}

	save(e: AdditionalServiceModel.SavePayload): Promise<void> {
		return new Promise(async resolve => {
			const _id =
				'additional_services'+
				ConfigurationCustomerAppStructure.noSqlDocSeparator +
				e.service_type;
			const doc = await this.commonsAdapter.basePouch.getDetail<AdditionalServiceModel.Document>(_id);
			doc.values = e.data;
			await this.commonsAdapter.basePouch.saveBodyTable<AdditionalServiceModel.Document>(doc);
			resolve();
		});
	}
}
