import { InjectionToken } from '@angular/core';

export const APP_CONFIG_TOKEN: InjectionToken<string> = new InjectionToken<
	string
>('APP_CONFIG_TOKEN');

export const ENVIRONMENT_SETTING = new InjectionToken('ENVIRONMENT_SETTING');

export interface ISaepIctAngularCoreConfigModel {
  be_url: string;
  suffix?: string;
  translation?: {
    url?: string;
    type?: string;
    default_translation_path?: string;
    fallback_lang?: string;
  };
}

export interface ISaepIctAngularCoreAppConfig {
	saepIctAngularCore_config: ISaepIctAngularCoreConfigModel;
	token: string;

	initializeSaepIctAngularCore(arg0: { [key: string]: any }): void;
}
