<page-with-sidebar [isRoot]="true" [sideBarPosition]="formDynamicConfiguration ? sideBarPositionValues.TOP : sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<organization-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToDetail($event.level, $event.code)"
				(openOrderNew)="orderDraftCreate($event)"
				(createNew)="createNew()"
				[localListHandlerWidgetWrapperCanCreate]="configurationCustomerOrganization.canCreate.detail[
							contextApplicationItemCodeEnum[user.current_permission.context_application]
				]"
				(exportExcel)="exportExcel()"
			>
			</organization-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper>
			<ng-container main-content>
				<form-dynamic
				[configuration]="formDynamicConfiguration"
				(valueChange)="onFormValueChange($event)"
				></form-dynamic>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
