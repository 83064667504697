import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// routes
import * as baseRouteList from './base-routing';

const routes: Routes = baseRouteList.routes;

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// Modificato da enabledBlocking per evitare che l'inizializzazione del plugin di keycloak venga bloccata prima dell'esecuzione di AuthGuard.
			// enabledBlocking -> serve solo per compilazione SSR

			// abilitazione TRACE
			initialNavigation: 'enabledNonBlocking',
			enableTracing: false
		})
	],
	exports: [RouterModule]
})
export class RoutingModule {}
