import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AuthService } from '../service/rest/auth.service';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { PageAgentModule } from './agent/page-agent.module';
import { PageB2BModule } from './b2b/page-b2b.module';
import { PageBackofficeModule } from './backoffice/page-backoffice.module';
import { PageCommonsModule } from './commons/page-commons.module';
import { PageStorefrontModule } from './b2c/storefront/page-storefront.module';
import { PageCrmModule } from './crm/page-crm.module';
import { InformativePageComponent } from './commons/informative-page/informative-page.component';
import { PageB2cModule } from './b2c/page-b2c.module';
import { ResumeService } from '../service/rest/resume.service';
import {
	AngularSpin8CoreActionService,
	AngularSpin8CoreAttachedFileService,
	AngularSpin8CoreAuxiliaryTableRestService,
	AngularSpin8CoreCatalogService,
	AngularSpin8CoreCompanyService,
	AngularSpin8CoreInformativePageService,
	AngularSpin8CoreLanguageService,
	AngularSpin8CoreOrderService,
	AngularSpin8CoreStorefrontConfigurationService,
	AngularSpin8CoreUserService
} from '@saep-ict/angular-spin8-core';
import { ConfigurationService } from '../service/rest/backoffice/configuration.service';

@NgModule({
	imports: [
		WidgetModule,
		SharedModule,
		PageAgentModule,
		PageB2BModule,
		PageB2cModule,
		PageBackofficeModule,
		PageCommonsModule,
		PageStorefrontModule,
		PageCrmModule
	],
	declarations: [InformativePageComponent],
	providers: [
		AuthService,
		AngularSpin8CoreUserService,
		AngularSpin8CoreCompanyService,
		AngularSpin8CoreCatalogService,
		AngularSpin8CoreAuxiliaryTableRestService,
		AngularSpin8CoreStorefrontConfigurationService,
		AngularSpin8CoreOrderService,
		AngularSpin8CoreInformativePageService,
		AngularSpin8CoreLanguageService,
		AngularSpin8CoreAttachedFileService,
		AngularSpin8CoreActionService,
		ResumeService,

		// backoffice
		AngularSpin8CoreStorefrontConfigurationService,
		ConfigurationService
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {}
