import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, filter, map, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, SubscribeManagerService, SentencecasePipe, RestBaseResponse } from '@saep-ict/angular-core';
import { Observable, lastValueFrom } from 'rxjs';
import { StateFeature } from '../../../state';
import { AlertType } from '../../../widget/alert/alert.component';
import { DialogDestinationSelect } from '../../../widget/dialog/dialog-destination-select/dialog-destination-select.component';
import {
	DestinationPouchModel,
	OrderPouchModel,
	OrganizationTypeEnum,
	PaymentPouchModel,
	ContactPouchModel,
	ArticlePouchModel,
	CommercialAreaPouchModel,
	PriceEnum,
	TableListEnum,
	LocalListHandlerBaseModel
} from '@saep-ict/pouch_agent_models';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { OrderActionEnum, OrderStateAction } from '../../../state/order/order.actions';
import _ from 'lodash';
import { ConfigurationViewModel } from '../../../model/configuration.model';
import { CustomerAppConfig } from '../../../customer-app.config';
import { TranslateService } from '@ngx-translate/core';
import { AppUtilService } from '../../../service/util/app-util.service';
import * as ConfigurationCustomerOrder from '../../../constants/configuration-customer/order/order.constant';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizationActionEnum, OrganizationStateAction } from '../../../state/organization/organization.actions';
import { UtilAddressService } from '../../../service/util/util-address.service';
import {
	ROUTE_URL,
	UserDetailModel,
	FormEnum,
	OrganizationStateModel,
	AdditionalServiceStateModel,
	OrderStateModel,
	ExtraFieldOrderHeaderPouchModel,
	AngularSpin8CoreOrderService,
	AuxiliaryTableStateModel,
	contextCodeItemCompany
} from '@saep-ict/angular-spin8-core';
import { OrderListStateAction } from '../../../state/order-list/order-list.actions';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import * as ConfigurationCustomerDestination from '../../../constants/configuration-customer/destination/destination.constant';
import * as PaymentEnum from '../../../enum/payment.enum';
import { FlaskRestApiService } from '@saep-ict/angular-flask-integration';
import * as ConfigurationCustomerOrderDestination from '../../../constants/configuration-customer/order/order-destination.constant';
import * as ConfigurationCustomerOrderHeaderSetting from '../../../constants/configuration-customer/order/order-header-setting.constant';
import * as ConfigurationCustomerOrderDate from '../../../constants/configuration-customer/order/order-date.constant';
import * as ConfigurationCustomerReference from '../../../constants/configuration-customer/reference/reference.constant';
import * as ConfigurationCustomerAdditionalServiceOrder from '../../../constants/configuration-customer/additional-service/additional-service-order.constant';
import * as ConfigurationCustomerShipping from '../../../constants/configuration-customer/shipping/shipping.constant';
import { DialogContextCodeEditComponent } from '../../../widget/dialog/dialog-context-code-edit/dialog-context-code-edit.component';
import { OrganizationCreditAvailable } from '../../../model/organization.model';
import * as ShippingEnum from '../../../enum/shipping.enum';
import * as ConfigurationCustomerPrice from '../../../constants/configuration-customer/price/price.constant';

@Component({
	selector: 'b2c-checkout',
	templateUrl: './b2c-checkout.component.html',
	styleUrls: ['./b2c-checkout.component.scss'],
	providers: [SubscribeManagerService, AngularSpin8CoreOrderService]
})
export class B2cCheckoutComponent implements OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;
	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> =
		this.store.select(StateFeature.getAuxiliaryTableState);
	auxiliaryTable: AuxiliaryTableStateModel;
	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(StateFeature.getConfigurationState);
	configuration: ConfigurationViewModel;
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;
	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];
	additionalService$: Observable<BaseStateModel<AdditionalServiceStateModel>> =
		this.store.select(StateFeature.getAdditionalService);
	additionalService: AdditionalServiceStateModel;
	paymentMethods: PaymentPouchModel[];
	checkoutForm: FormGroup;
	destinationRegisteredOffice: DestinationPouchModel;
	/**
	 * Oggetto di appoggio per le nuove destinazioni. Impostato presso `openDialogNewDestination()` e sfruttato
	 * per la reinizializzazione dell'ordine presso `subscribeOrderData()`, dove non c'è distinzione tra organization
	 * UPDATE e SAVE_SUCCESS
	 */
	destinationNewCodeItem: string;
	commercialArea: CommercialAreaPouchModel;
	contactMainOfList: ContactPouchModel;
	operationCompleted = false;
	componentInit = true;
	organizationWarning: string;
	orderThresholdSatisfied = {
		status: false,
		conditionList: []
	};
	alertType = AlertType;
	paymentState = PaymentEnum.Status;
	organizationTypeEnum = OrganizationTypeEnum;
	ROUTE_URL = ROUTE_URL;
	tableListEnum = TableListEnum;
	configurationCustomerOrder = ConfigurationCustomerOrder;
	configurationCustomerOrderDestination = ConfigurationCustomerOrderDestination;
	customerReference = ConfigurationCustomerReference.customer;
	isCostToApplyForAdditionalService: boolean;
	isCostToApplyForShipping: boolean;

	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(
		private fb: FormBuilder,
		private store: Store,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService,
		private utilOrderService: UtilOrderService,
		private appConfig: CustomerAppConfig,
		private orderService: AngularSpin8CoreOrderService,
		private translate: TranslateService,
		private utilService: AppUtilService,
		private utilPriceService: UtilPriceService,
		private sentenceCasePipe: SentencecasePipe,
		private utilAddressService: UtilAddressService,
		private snackbar: MatSnackBar,
		private translateService: TranslateService,
		private flaskRestApiService: FlaskRestApiService
	) {
		this.loadStaticData();
		const subscribeList: SubscribeManagerItem[] = [
			{ key: 'order-data', observable: this.subscribeOrderData() }
		];
		ConfigurationSubscribeManager.init(subscribeList, this.subscribeManagerService);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.articleList$.pipe(take(1)).subscribe(res => {
			this.articleList = res.data;
		});
		this.additionalService$.pipe(take(1)).subscribe(res => {
			this.additionalService = res ? res.data : null;
		});
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res && res.data ? res.data : null;
		});
		this.configuration$.pipe(take(1)).subscribe(res => {
			this.configuration = res && res.data ? res.data : null;
		});
	}

	subscribeOrderData(): Observable<Promise<void>> {
		return this.organization$.pipe(
			filter(e =>
				!!(
					e && e.data &&
					e.type !== OrganizationActionEnum.SAVE
				)
			),
			mergeMap(e => {
				this.organization = e.data;
				this.setOrganizationWarnign();
				if (
					this.organization &&
					this.organization.destination_list &&
					this.organization.destination_list.length
				) {
					const mainDestination: DestinationPouchModel =
						this.organization.destination_list.find(destination => destination.is_registered_office) || null;
					this.destinationRegisteredOffice = mainDestination ? mainDestination : null;
					this.contactMainOfList = this.utilService.returnIsMainOfList(this.organization.contact_list);
				}
				if (this.subscribeManagerService.hasSubscription('form-data')) {
					this.subscribeManagerService.destroy('form-data');
				}
				this.createForm();
				const subscribeList: SubscribeManagerItem[] = [
					{ key: 'form-data', observable: this.subscribeFormData() }
				];
				ConfigurationSubscribeManager.init(subscribeList, this.subscribeManagerService);
				return this.order$;
			}),
			filter(
				(order: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					order && order.type !== OrderActionEnum.LOAD
			),
			map(async (order: BaseStateModel<OrderStateModel>) => {
				switch (order.type) {
					case OrderActionEnum.ERROR:
						this.operationCompleted = true;
						break;
					case OrderActionEnum.UPDATE:
						if (this.organization) {
							this.order = _.cloneDeep(order.data);
							if (
								this.componentInit &&
								// TODO: questa condizione permette di distingue se si sta ritornando da una schermata di pagamento
								// andrebbe sostituita con uno stato ordine apposito. Le istruzioni contenute nel seguente if devono
								// essere eseguite solo alla prima visualizzazione del checkout

								// TODO: rivedere il set del magazzino di partenza, in quanto questa casistica è spesso bypassata
								// dalla presenza di `this.order.header.payment_code`
								!this.order.header.payment_code
							) {
								this.utilOrderService.setOrderNewDraft(
									this.order,
									this.organization,
									this.configuration
								);
								const warehouseCode =
									this.auxiliaryTable.warehouseList.find(warehouse => warehouse.is_main_of_list).code_item;
								this.order.header.starting_warehouse = warehouseCode;
								this.componentInit = false;
							}
							if (this.destinationNewCodeItem) {
								this.order.header.goods_destination_object = {
									code_item: this.destinationNewCodeItem
								};
								ConfigurationCustomerOrderHeaderSetting.setOrderHeaderDestinationObject(
									this.order,
									this.organization.destination_list
								);
								this.destinationNewCodeItem = null;
							}
							this.articleList =
								this.utilOrderService.returnFilteredAndMergedArticleListByOrderDraft(
									this.order,
									this.articleList,
									this.organization
								);
							this.order =
								this.utilOrderService.returnOrderDraftWithoutMissingArticle(
									this.order,
									this.articleList
								);
							await this.setPaymentMethodList();
							this.setModelFormValueFromOrder(this.order);
							this.order =
								await this.setOrderAndCommercialAreaByFormValue(
									this.order,
									this.checkoutForm.value
								);
						}
						break;
					case OrderActionEnum.COMPLETED:
						delete this.utilOrderService.orderChangeTemp;
						this.operationCompleted = true;
						// resetta ordine attuale
						this.store.dispatch(OrderStateAction.skip());
						this.store.dispatch(OrderListStateAction.skip());
						break;
				}
			})
		);
	}

	subscribeFormData(): Observable<Promise<void>> {
		return this.checkoutForm.valueChanges.pipe(
			debounceTime(500),
			map(async e => {
				this.order = await this.setOrderAndCommercialAreaByFormValue(this.order, e);
			})
		);
	}

	// TODO: la sola condizione sul token non fa in tempo a prevenire gli errori tra il login
	// ed il redirect verso context-selection
	operationState() {
		if (!this.appConfig.authenticationToken || !this.organization) {
			return PaymentEnum.Status.NOT_AUTH;
		}
		if (!this.operationCompleted) {
			return PaymentEnum.Status.AUTH;
		}
	}

	createForm() {
		this.checkoutForm =
			this.fb.group({
				shipping_address: ['', Validators.required],
				additionalNotes: [''],
				invoiceRequested: false,
				paymentMethod: ['', Validators.required]
			});
		if (this.organization) {
			const additionalServices = this.fb.group({});
			if (ConfigurationCustomerOrder.additionalServiceDeliveryDate[this.organization.organization_type]) {
				additionalServices.addControl('delivery_date', new FormControl(null, [Validators.required]));
			}
			if (ConfigurationCustomerOrder.additionalServiceStockType[this.organization.organization_type]) {
				additionalServices.addControl('stock_type', new FormControl(null, [Validators.required]));
			}
			this.checkoutForm.addControl('additionalServices', additionalServices);
		}
	}

	setModelFormValueFromOrder(order: OrderStateModel) {
		const formPatchValue = {
			shipping_address:
				order.header.goods_destination_object &&
				order.header.goods_destination_object.code_item ?
				order.header.goods_destination_object.code_item:
				null,
			paymentMethod: order.header.payment_code,
			additionalNotes: order.header.note_order,
			invoiceRequested: order.header.invoice_requested,
			additionalServices: {
				delivery_date: null,
				stock_type: null
			}
		};
		if (ConfigurationCustomerOrder.additionalServiceDeliveryDate[this.organization.organization_type]) {
			formPatchValue.additionalServices.delivery_date =
				order.header.additional_services && order.header.additional_services.delivery_date ?
				order.header.additional_services.delivery_date.code_item :
				this.additionalService?.delivery_date[0].code_item;
		}
		if (ConfigurationCustomerOrder.additionalServiceStockType[this.organization.organization_type]) {
			formPatchValue.additionalServices.stock_type =
				order.header.additional_services && order.header.additional_services.stock_type
					? order.header.additional_services.stock_type.code_item
					: this.additionalService?.stock_type[0].code_item;
		}
		this.checkoutForm.patchValue(formPatchValue, { emitEvent: false });
	}

	async setOrderAndCommercialAreaByFormValue(order: OrderStateModel, formValue): Promise<OrderStateModel> {
		order = _.cloneDeep(order);
		order.header.note_order = formValue['additionalNotes'] || null;
		order.header.invoice_requested = formValue['invoiceRequested'] || null;
		// destination
		order.header.goods_destination_object = {
			code_item: formValue['shipping_address'] || null
		};
		ConfigurationCustomerOrderHeaderSetting.setOrderHeaderDestinationObject(
			order,
			this.organization.destination_list
		);
		this.commercialArea = await this.utilAddressService.selectCommercialArea(order, this.organization);
		// data di consegna
		order.header.carrier = null;
		if (this.organization.destination_list) {
			const destination =
            	this.organization.destination_list
				.find(i => i.code_item === order.header.goods_destination_object.code_item);
			if (destination && destination.division_list) {
				const destinationDivision =
					this.utilService.returnIsMainOfList(destination.division_list);
				if (destinationDivision && destinationDivision.carrier) {
					order.header.carrier = {
						code_item: destinationDivision.carrier
					}
					if (this.auxiliaryTable.carrierList) {
						const carrier =
							this.auxiliaryTable.carrierList.find(i => i.code_item === destinationDivision.carrier);
						if (carrier) {
							order.header.carrier.description = carrier.description;
						}
					}
				}
			}
		}
		if (!(order.header.carrier && order.header.carrier.code_item)) {
			order.header.carrier = {
				code_item: ShippingEnum.Carrier.OT1
			}
		}
		this.isCostToApplyForAdditionalService =
			ConfigurationCustomerAdditionalServiceOrder.isCostToApply(order);
		this.isCostToApplyForShipping =
			ConfigurationCustomerShipping.isCostToApply(order);
		order.header.first_evasion_date =
			ConfigurationCustomerOrderDate.returnB2cFirstEvasionDate(
				order,
				this.organization,
				this.commercialArea
			);
		// metodo di pagamento
		order.header.payment_code = formValue['paymentMethod'];
		ConfigurationCustomerOrderHeaderSetting.setOrderHeaderObject<PaymentPouchModel>(
			'payment_code',
			'payment_object',
			order,
			this.paymentMethods
		);
		// additional services
		if (this.checkoutForm.controls['additionalServices']) {
			this.setOrderHeaderAdditionalServices(order, formValue['additionalServices']);
		}
		// set shipping costs
		try {
			order = await this.utilPriceService.updatePriceOrder(order, this.organization, this.commercialArea);
		} catch (error) {
			console.log(error);
		}
		this.returnOrderThresholdSatisfiedStatus(order, this.commercialArea);
		return order;
	}

	onSubmit() {
		if (this.checkoutForm.valid) {
			this.orderService.postOrderPayment(this.order)
				.then(res => {
					if (res.data) {
						window.location.href = res.data;
					}
				})
				.catch(err => {
					this.snackbar.open(
						this.sentenceCasePipe.transform(this.translateService.instant('checkout.error.generic')),
						this.translateService.instant('general.close').toUpperCase(),
						{ duration: 10000 }
					);
					throw new Error(err);
				});
		}
	}

	setOrganizationWarnign() {
		this.organizationWarning = null;
		if (!(this.organization.tax_data && this.organization.tax_data.sdi_code)) {
			this.organizationWarning = this.translate.instant('organization.field.sdi');
		}
		if (
			this.organization.organization_type === OrganizationTypeEnum.COMPANY &&
			!(this.organization.tax_data && this.organization.tax_data.pec)
		) {
			this.organizationWarning =
				(this.organizationWarning ? `${this.organizationWarning}, `: '') +
				this.translate.instant('organization.field.pec');
		}
	}

	openDialogDestinationSelect() {
		const dialogRef = this.dialog.open(DialogDestinationSelect, {
			data: {
				listPageBaseData: {
					pagination: null,
					filters: {
						localSearchText: {
							value: null,
							key_list: [
								'business_name',
								'address.zip_code',
								'address.address',
								'address.locality',
								'address.country',
								'address.province.label'
							]
						}
					},
					sort: {
						name: 'address.address',
						order: 'ASC'
					},
					columnList: ConfigurationCustomerDestination.columnList(this.user.current_permission.context_application),
					data: this.organization.destination_list
				} as LocalListHandlerBaseModel<DestinationPouchModel>,
				modalTitle: this.sentenceCasePipe.transform(this.translateService.instant('checkout.field.destination'))
			},
			disableClose: true,
			autoFocus: true,
			width: '75%'
		});
		dialogRef.afterClosed().subscribe((e: DestinationPouchModel) => {
			if (e) {
				this.checkoutForm.patchValue({
					shipping_address: e.code_item
				});
			}
		});
	}

	openDialogNewDestination() {
		const dialogNewDestination =
			this.utilAddressService.openDialogNewDestination(
				this.order,
				this.checkoutForm,
				this.user
			).subscribe((e: DestinationPouchModel) => {
				if (e) {
					this.destinationNewCodeItem = e.code_item;
					if (this.organization.destination_list) {
						this.organization.destination_list.push(e);
					} else {
						this.organization.destination_list = [e];
					}
					this.store.dispatch(OrganizationStateAction.save(new BaseState(this.organization)));
				}
				dialogNewDestination.unsubscribe();
			});
	}

	openDialogOrganizationEdit() {
		const contextCodeItemLink = _.cloneDeep(contextCodeItemCompany);
		contextCodeItemLink.value = _.cloneDeep(this.organization);
		const dialog = this.dialog.open(DialogContextCodeEditComponent, {
			data: {
				...contextCodeItemLink,
				contestoDialog: 'organization',
				type: 'edit',
				description: 'organizzazione'
			},
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((res: OrganizationStateModel) => {
			if (res) {
				this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(res)));
			}
		});
	}

	setOrderHeaderAdditionalServices(order: OrderStateModel, additionalServices: AdditionalServiceStateModel) {
		if (this.isCostToApplyForAdditionalService) {
			for (const serviceKey in additionalServices) {
				if (additionalServices[serviceKey]) {
					if (
						additionalServices[serviceKey] === FormEnum.Value.NO_PREFERENCE &&
						order.header.additional_services &&
						order.header.additional_services[serviceKey]
					) {
						// NO_PREFERENCE è un caso attualmente non gestito
						delete order.header.additional_services[serviceKey];
						if (_.isEmpty(order.header.additional_services)) {
							delete order.header.additional_services;
						}
					} else {
						if (!order.header.additional_services) {
							order.header.additional_services = {};
						}
						order.header.additional_services[serviceKey] =
							this.additionalService[serviceKey].find(serv => serv.code_item === additionalServices[serviceKey]);
						if (['stock_type'].includes(serviceKey)) {
							// TODO: questo punto sembra ridondante rispetto a quanto su
							order.header.additional_services[serviceKey].charge_type =
								additionalServices[serviceKey].charge_type || PriceEnum.ValueType.ABSOLUTE;
							order.header.additional_services[serviceKey].ordered_quantity =
								order.header.weight ?
								Math.ceil(+order.header.weight / +order.header.additional_services[serviceKey].value) :
								1;
						}
					}
				}
			}
		} else {
			delete order.header.additional_services;
		}
	}

	returnOrderThresholdSatisfiedStatus(
		order: OrderStateModel,
		commercialArea: CommercialAreaPouchModel
	) {
		this.orderThresholdSatisfied.conditionList = [];
		if (commercialArea && commercialArea.threshold && commercialArea.threshold.length) {
			for (const t of commercialArea.threshold) {
				switch (t.type) {
					case TableListEnum.Threshold.Type.K: {
						if (t.value <= order.header.weight) {
							this.orderThresholdSatisfied.status = true;
							this.orderThresholdSatisfied.conditionList = [];
							return;
						}
						break;
					}
					case TableListEnum.Threshold.Type.I: {
						if (t.value <= order.header.price.article) {
							this.orderThresholdSatisfied.status = true;
							this.orderThresholdSatisfied.conditionList = [];
							return;
						}
						break;
					}
					case TableListEnum.Threshold.Type.C: {
						let itemNumber = 0;
						order.product_list.forEach(i => {
							itemNumber = itemNumber + i.ordered_quantity;
						});
						if (t.value <= itemNumber) {
							this.orderThresholdSatisfied.status = true;
							this.orderThresholdSatisfied.conditionList = [];
							return;
						}
						break;
					}
				}
				this.orderThresholdSatisfied.conditionList.push({
					type: t.type,
					description: `checkout.alert.to_proceed_condition_list.${t.type}`,
					value: t.value
				});
			}
		}
		this.orderThresholdSatisfied.status = false;
	}

	async setPaymentMethodList() {
		const paymentMethodList: PaymentPouchModel[] =
			_.cloneDeep(
				(this.organization && this.organization.organization_type === OrganizationTypeEnum.COMPANY) ?
				this.configuration.company_payment_methods :
				this.configuration.private_payment_methods
			);
		let organizationCreditAvailable: RestBaseResponse<OrganizationCreditAvailable>;
		let organizationPaymentMethod: PaymentPouchModel;
		const division = this.utilService.returnIsMainOfList(this.organization.division_list);
		if (
			division &&
			division.payment_condition &&
			this.organization.code_erp
		) {
			organizationPaymentMethod =
				this.auxiliaryTable.paymentList.find(i => i.code_item === division.payment_condition);
			if (organizationPaymentMethod) {
				organizationCreditAvailable =
					await lastValueFrom(
						this.flaskRestApiService.get<RestBaseResponse<OrganizationCreditAvailable>>(
							`organizations/${this.organization.code_erp}/credit-available`
						)
					);
			}
		}
		if (
			organizationCreditAvailable &&
			organizationCreditAvailable.data &&
			!organizationCreditAvailable.data.isBlocked &&
			organizationCreditAvailable.data.credit &&
			organizationCreditAvailable.data.credit - this.order.header.price.total >= 0
		) {
			// TODO: stabilire se unico punto in cui è possibile trovare il pagamento preferito
			// o se esiste una catena di fallback
			paymentMethodList.push(organizationPaymentMethod);
		} else {
			delete this.order.header.payment_code;
			delete this.order.header.payment_object;
			this.checkoutForm.patchValue(
				{
					paymentMethod: null
				},
				{ emitEvent: false }
			);
		}
		this.paymentMethods = paymentMethodList;
	}
}
