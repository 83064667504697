import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { combineLatest, from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { CustomerAppConfig } from '../../customer-app.config';
import { ConfigurationViewModel, ConfigurationModel as ConfigurationModel } from '../../model/configuration.model';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { AngularSpin8CoreStorefrontConfigurationService } from '@saep-ict/angular-spin8-core';
import { StateFeature } from '..';
import { ConfigurationActionEnum, ConfigurationStateAction as ConfigurationStateAction } from './configuration.actions';
import { CategoryMap } from '@saep-ict/pouch_agent_models';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class ConfigurationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConfigurationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<ConfigurationModel>) => from(this.getConfiguration())),
			mergeMap((highlights: Observable<BaseStateModel<ConfigurationViewModel>>) => highlights),
			map((highlights: BaseStateModel<ConfigurationViewModel>) => ConfigurationStateAction.update(highlights)),
			catchError((error, caught) => {
				this.store.dispatch(ConfigurationStateAction.error(null));
				return caught;
			})
		)
	);

	categoryList$: Observable<BaseStateModel<CategoryMap, ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter,
		private appConfig: CustomerAppConfig,
		private storefrontConfigurationService: AngularSpin8CoreStorefrontConfigurationService
	) {}

	async getConfiguration(): Promise<Observable<BaseStateModel<ConfigurationViewModel>>> {
		try {
			return new Promise((resolve, reject) => {
				if (this.appConfig.authenticationToken) {
					//resolve(this.pouchDbCommonsAdapter.configurationPouch.getConfiguration());
					resolve(this.pouchDbCommonsAdapter.basePouch.getDetail(`table${ConfigurationCustomerAppStructure.noSqlDocSeparator}configuration`));
				} else {
					this.storefrontConfigurationService.getConfiguration(conf => resolve(conf.data));
				}
			}).then(async (configuration: ConfigurationModel) => {
				let configurationView: ConfigurationViewModel = Object.assign(
					{},
					(configuration as unknown) as ConfigurationViewModel
				);
				configurationView.products = [];
				configurationView.categories = [];
				return combineLatest([this.articleList$, this.categoryList$]).pipe(
					map(([articleList, categoryList]) => {
						if (articleList && articleList.data) {
							configurationView.main = articleList.data.find(
								article => article.code_item == configuration.main
							);
							for (const productCode of configuration.products || []) {
								const articleDetail: ArticlePouchModel = articleList.data.find(
									article => article.code_item == productCode
								);
								configurationView.products.push(articleDetail);
							}
						}
						if (categoryList && categoryList.data && categoryList.data.is_highlighted?.length) {
							for (const categoryCode of configuration.categories || []) {
								const categoryDetail: any = categoryList.data.is_highlighted.find(
									category => category._id == categoryCode
								);
								configurationView.categories.push(categoryDetail);
							}
						}
						return new BaseState(configurationView);
					})
				);
			});
		} catch (err) {
			throw new Error(err);
		}
	}
}
