import { OrganizationPouchModel } from './organization-pouch.model';
import { ListPouchModel } from './util-pouch.model';

export enum ContactTypeEnum {
	PERSONAL = 'PERSONAL',
	FUNCTIONAL = 'FUNCTIONAL'
}

export interface ContactPouchModel extends ListPouchModel {
	contact_type?: ContactTypeEnum;
	full_name?: string;
	first_name?: string;
	last_name?: string;
	role?: string;
	area?: string; // reparto, compartimento aziendale
	email?: string;
	phone?: string;
	fax?: string;
	phone_mobile?: string;
	has_notification?: boolean;
	organization_list?: OrganizationPouchModel[];
}
