import { CompanyConfigModel } from './../../../model/structure/environment-config.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomerAppConfig } from '../../../customer-app.config';
import { MatDialog } from '@angular/material/dialog';
import { DialogCompanyInfoComponent } from '../../../widget/dialog/dialog-company-info/dialog-company-info.component';
import { Subscription } from 'rxjs';
import { LoaderService } from '@saep-ict/angular-core';

@Component({
	selector: 'authentication-wrapper',
	templateUrl: './authentication-wrapper.component.html',
	styleUrls: ['./authentication-wrapper.component.scss']
})
export class AuthenticationWrapperComponent implements OnInit, OnDestroy {
	configSubscription: Subscription;
	appTag: string;
	appRelease: string;
    company: CompanyConfigModel;

	constructor(
		protected appConfig: CustomerAppConfig,
		private dialog: MatDialog,
		public loaderService: LoaderService
	) {
		this.appConfig.config$.subscribe(config => {
			this.appTag = config.tag;
			this.appRelease = config.release;
            this.company = config.company;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}

    dialogShowCompanyInfo() {
		this.dialog.open(DialogCompanyInfoComponent, {
			disableClose: false,
			panelClass: ['company-info', 'michelangelo-theme-dialog']
		});
	}
}
