import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	AttachmentListManager,
	BaseStateModel,
	FormDynamicConfigurationModel,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { AttachedFile, LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ReceiptStateModel } from '../../../model/state/receipt-state.model';
import { StateFeature } from '../../../state';
import { ReceiptListActionEnum, ReceiptListStateAction } from '../../../state/receipt-list/receipt-list.actions';
import { ReceiptListWrapperComponent } from '../../../widget/receipt/receipt-list-wrapper/receipt-list-wrapper.component';
import { ActivatedRoute } from '@angular/router';
import { AngularSpin8CoreUtilCompanyService, ContextApplicationItemCodeEnum, OrganizationStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import * as ConfigurationCustomerReceipt from '../../../constants/configuration-customer/receipt/receipt.constant';
import { MatDialog } from '@angular/material/dialog';
import { DialogAttachmentListManagerComponent } from '../../../widget/dialog/dialog-attachment-list-manager/dialog-attachment-list-manager.component';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { invoiceAttachmentListManagerColumnListBase } from '../../../constants/configuration-customer/receipt/receipt-attachment-list-manager.column-map.constant';
import { ReceiptListFilterCreationFieldMapFormValue } from '../../../model/receipt-util.model';

@Component({
	selector: 'organization',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss'],
	providers: [SubscribeManagerService]
})
export class ReceiptComponent implements OnDestroy {
	@ViewChild(ReceiptListWrapperComponent) public receiptListWrapperComponent: ReceiptListWrapperComponent;

	receiptList$: Observable<BaseStateModel<ReceiptStateModel[]>> = this.store.select(StateFeature.getReceiptListState);
	receiptList: ReceiptStateModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	listPageBaseData = <LocalListHandlerBaseModel<ReceiptStateModel>>{
		pagination: {
			pageSize: this.contextApplicationItemCodeEnum.CRM ? 25 : 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['number', 'price.total']
			}
		},
		sort: {
			name: 'number',
			order: 'DESC'
		},
		data: []
	};

	sideBarPositionValues = SideBarPositionValues;

	formDynamicConfiguration: FormDynamicConfigurationModel;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		public activatedRoute: ActivatedRoute,
		private utilBreadcrumbService: UtilBreadcrumbService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private dialog: MatDialog,
	) {
		this.setFormDynamicConfiguration();
		this.store.dispatch(ReceiptListStateAction.loadAll());

		this.subscribeManagerService.populate(this.subscribeReceiptList().subscribe(), 'subscribeReceiptList');
		this.loadStaticData();
		this.setRouteMetaInformation();
	}

	ngOnDestroy() {
		this.store.dispatch(ReceiptListStateAction.reset());
		this.subscribeManagerService.destroy();
		this.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe(e => {
			this.user = e ? e.data : null;
			this.listPageBaseData.columnList =
				ConfigurationCustomerReceipt.columnList(this.user.current_permission.context_application);
		});
		this.organization$.pipe(take(1)).subscribe(e => {
			this.organization = e ? e.data : null;
		});
	}

	subscribeReceiptList(): Observable<void> {
		return this.receiptList$.pipe(
			filter((state: BaseStateModel<ReceiptStateModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<ReceiptStateModel[]>) => {
				switch (state.type) {
					case ReceiptListActionEnum.UPDATE:
						this.applyPermanentFilters(_.cloneDeep(state.data));
						break;
					case ReceiptListActionEnum.ERROR:
						throw new Error(ReceiptListActionEnum.ERROR);
					default:
						break;
				}
			})
		);
	}

	applyPermanentFilters(receiptList: ReceiptStateModel[]) {
		if (this.isPageDirectlyInFrameComponent()) {
			this.receiptList = receiptList;	
		} else {
			this.receiptList = receiptList.filter(i => i.organization.code_item === this.organization.code_item);
		}
		this.receiptListWrapperComponent.updateListPageBaseData(this.receiptList);
	}

	async setFormDynamicConfiguration() {
		this.formDynamicConfiguration = {
			creationFieldMap: await ConfigurationCustomerReceipt.listFilterCreationFieldMap(),
			emitAlsoNonValidForm: false,
			buttonReset: {
				show: true
			}
		};
	}

	async onFormDynamicValueChange(e: ReceiptListFilterCreationFieldMapFormValue) {
		if (e) {
			const filteredList =
				await ConfigurationCustomerReceipt.returnListFilterByFormValue(
					_.cloneDeep(this.receiptList),
					e
				);
			this.receiptListWrapperComponent.updateListPageBaseData(filteredList);
		}
	}

	setRouteMetaInformation() {
		if (this.isPageDirectlyInFrameComponent()) {
			switch (this.user.current_permission.context_application) {
				case ContextApplicationItemCodeEnum.AGENT:
					this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('archive');
					this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(
						'archive_receipts'
					);
					break;
				case ContextApplicationItemCodeEnum.B2B:
				case ContextApplicationItemCodeEnum.CRM:
					this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('receipts');
					this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['receipts']);
					break;
			}
		}
	}

	unsetRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			// Componente radice
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		}
	}

	dialogOpenAttachmentList(e) {
		const attachmentListManagerConfiguration: AttachmentListManager.Configuration<AttachedFile> = {
			localListHandler: {
				filters: {
					localSearchText: {
						value: null,
						key_list: ['name']
					}
				},
				sort: {
					name: 'date_creation',
					order: TdDataTableSortingOrder.Ascending
				},
				data: e.attachment_list
			},
			page: {
				title: 'saep_ict_angular_core.attachment_list_manager.name_plural',
				columnList: invoiceAttachmentListManagerColumnListBase,
				display: {
					tableHeader: true
				}
			},
			privilege: {
				add: false,
				delete: false,
				download: true
			},
			pathUrl: 'invoice',
			customHeaders: {
				'context-code': this.user.current_permission.context_code.code,
				'context-application': this.user.current_permission.context_application
			}
		}
		this.dialog.open(DialogAttachmentListManagerComponent, {
			data: {
				title: e.code_erp,
				attachmentListManagerConfiguration: attachmentListManagerConfiguration
			},
			disableClose: true,
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});
	}

	isPageDirectlyInFrameComponent(): boolean {
		return !this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent;
	}

}
