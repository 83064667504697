import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { Component } from '@angular/core';
import {
	GuidFormatterPipe,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
	SentencecasePipe
} from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	AngularSpin8CoreUtilTranslateService,
	AngularSpin8CoreUtilArticleService
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { BackofficeArticleFamilyDetailBaseDirective } from '../../../../shared/directives/backoffice-article-family/backoffice-article-family-detail-base.directive';
import { StateFeature } from '../../../../state';
import { SubscribeManagerItem } from '../../../../model/subscribe-manager.model';
import { ArticleDescriptionActionEnum, ArticleDescriptionStateAction } from '../../../../state/article-description/article-description.actions';
import * as ConfigurationCustomerArticleBackofficeDetail from '../../../../constants/configuration-customer/article/article-backoffice-detail.constant';
import { StoreUtilService } from '../../../../service/util/store-util.service';

@Component({
	selector: 'article-detail',
	templateUrl: './article-detail.component.html',
	styleUrls: ['../../../../shared/directives/backoffice-article-family/backoffice-article-family-detail-base.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleDetailComponent extends BackofficeArticleFamilyDetailBaseDirective<ArticlePouchModel> {

	mainList$ = this.store.select(StateFeature.getArticleDescription);
	subscribeListForType: SubscribeManagerItem[] = [];
	mainListActionEnum = ArticleDescriptionActionEnum;
	mainListStateAction = ArticleDescriptionStateAction;
	configurationCustomerArticleBackofficeDetail = ConfigurationCustomerArticleBackofficeDetail;

	constructor(
		public store: Store,
		public subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public route: ActivatedRoute,
		public router: Router,
		public dialog: MatDialog,
		public articleService: AngularSpin8CoreUtilArticleService,
		public sentencecasePipe: SentencecasePipe,
		public translateService: TranslateService,
		public snackBar: MatSnackBar,
		public utilBreadcrumbService: UtilBreadcrumbService,
		public guidFormatterPipe: GuidFormatterPipe,
		public appConfig: CustomerAppConfig,
		public utilService: AppUtilService,
		public utilStoreService: StoreUtilService
	) {
		super(
			store,
			subscribeManagerService,
			utilTranslateService,
			route,
			router,
			dialog,
			articleService,
			sentencecasePipe,
			translateService,
			snackBar,
			utilBreadcrumbService,
			guidFormatterPipe,
			appConfig,
			utilService,
			utilStoreService
		);
	}

}
