import { Injectable } from '@angular/core';
import { FloatingMenuItems, FloatingMenu } from '../../widget/floating-menu/floating-menu.component';

@Injectable()
export class NavigationFloatingMenuService {
	constructor() {}

	public addNote: FloatingMenuItems = {
		icon: 'sticky_note_2',
		emitName: 'ADD_NOTE',
		tooltipLabel: 'note.add_new'
	};
	public addContact: FloatingMenuItems = {
		icon: 'account_circle',
		emitName: 'ADD_CONTACT',
		tooltipLabel: 'contact.add_new'
	};
	public addAddress: FloatingMenuItems = {
		icon: 'location_on',
		emitName: 'ADD_ADDRESS',
		tooltipLabel: 'address.add_new'
	};
	public addOffer: FloatingMenuItems = {
		icon: 'assignment',
		emitName: 'ADD_OFFER',
		tooltipLabel: 'offer.add_new'
	};
	public addOpportunity: FloatingMenuItems = {
		icon: 'filter_alt',
		emitName: 'ADD_OPPORTUNITY',
		tooltipLabel: 'opportunity.add_new'
	};

	// OrganizationDetailComponent
	public organizationDetailFloatinMenu: FloatingMenu = {
		color: 'accent',
		icon: 'add',
		items: [this.addNote, this.addContact, this.addAddress, this.addOffer, this.addOpportunity]
	};
}
