import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'closable-panel',
  templateUrl: './closable-panel.component.html',
  styleUrls: ['./closable-panel.component.scss']
})
export class ClosablePanelComponent implements OnInit {

    @Input() isVisible: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    close() {
        this.isVisible = false;
    }

}
