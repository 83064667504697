<ng-container
	*ngIf="
		_field &&
		_field.type !== formControlTypeEnum.IMAGE_PREVIEW;
		else imagePreview
	"
	[formGroup]="form"
>
	<!-- formControlName NUMBER, STRING -->
	<ng-container
		*ngIf="
			_field.type === formControlTypeEnum.NUMBER ||
			_field.type === formControlTypeEnum.STRING
		"
	>
		<mat-form-field>
			<mat-label>
				<ng-container *ngTemplateOutlet="matLabel"></ng-container>
			</mat-label>
			<input
				*ngIf="_field.type === formControlTypeEnum.NUMBER || _field.type === formControlTypeEnum.STRING"
				matInput
				[formControlName]="_field.name"
				[type]="_field.type?.toLowerCase()"
			/>
			<ng-container *ngTemplateOutlet="matError"></ng-container>
		</mat-form-field>
	</ng-container>
	<!-- formControlName SELECT, SELECT_MULTIPLE -->
	<ng-container
		*ngIf="
			_field.type === formControlTypeEnum.SELECT ||
			_field.type === formControlTypeEnum.SELECT_MULTIPLE
		"
	>
		<mat-form-field>
			<mat-label>
				<ng-container *ngTemplateOutlet="matLabel"></ng-container>
			</mat-label>
			<mat-select
				[formControlName]="_field.name"
				[multiple]="_field.type === formControlTypeEnum.SELECT_MULTIPLE ? true : false"
				[compareWith]="selectOption"
				(openedChange)="inputSearch.value = ''"
			>
				<mat-form-field
					class="select-filter-text"
					[class.select-filter-text__hidden]="!_field.selectFilterTextShow"
				>
					<input
						matInput
						#inputSearch
						[placeholder]="
							(
								_field.selectFilterTextPlaceholder ?
								_field.selectFilterTextPlaceholder :
								'saep_ict_angular_core.general.search'
							) | translate | sentencecase
						" 
					/>
					<button
						matSuffix
						mat-icon-button
						aria-label="Clear"
						[disabled]="!inputSearch.value"
						(click)="inputSearch.value = ''"
					>
						<mat-icon>{{ inputSearch.value ? 'close' : 'search' }}</mat-icon>
					</button>
				</mat-form-field>
				<mat-option
					*ngIf="_field.selectFilterTextShow"
					class="select-filter-text__option-empty"
					disabled
				>
					{{ 'saep_ict_angular_core.general.no_match_found' | translate | sentencecase }}
				</mat-option>
				<mat-option
					*ngFor="
						let option of _field.option_list |
						filterByString : {
							propertyListName:
								_field.selectFilterTextBy &&
								_field.selectFilterTextBy.length > 0 ?
								_field.selectFilterTextBy :
								['label'],
							value: inputSearch.value
						}
					" 
		  			[value]="_field.select_save_whole_object ? option : option.value"
				>
					{{
						(
							option.label ?
							(option.label | translate) :
							utilService.formatPlaceholder(option.value)
						) | sentencecase
					}}
				</mat-option>
			</mat-select>
			<ng-container *ngTemplateOutlet="matError"></ng-container>
		</mat-form-field>
	</ng-container>
	<!-- formControlName DATE -->
	<ng-container *ngIf="_field.type === formControlTypeEnum.DATE">
		<mat-form-field>
			<mat-label>
				<ng-container *ngTemplateOutlet="matLabel"></ng-container>
			</mat-label>
			<input
				matInput
				[formControlName]="_field.name"
				[matDatepicker]="customerDatePicker"
				[min]="_field.dateMin"
				[max]="_field.dateMax"
				[matDatepickerFilter]="_field.dateFilter"
			/>
			<mat-datepicker-toggle matSuffix [for]="customerDatePicker"></mat-datepicker-toggle>
			<mat-datepicker #customerDatePicker></mat-datepicker>
			<ng-container *ngTemplateOutlet="matError"></ng-container>
		</mat-form-field>
	</ng-container>
	<!-- formControlName DATE_RANGE -->
	<ng-container
		*ngIf="_field.type === formControlTypeEnum.DATE_RANGE"
		[formGroupName]="_field.name"
	>
		<mat-form-field>
			<mat-label>
				<ng-container *ngTemplateOutlet="matLabel"></ng-container>
			</mat-label>
			<mat-date-range-input
				[rangePicker]="pickerRangeDate"
				[dateFilter]="_field.dateFilter"
			>
				<input
					matStartDate
					formControlName="start"
					[min]="_field.dateMin"
					[max]="_field.dateMax"
				/>
				<input
					matEndDate
					formControlName="end"
					[min]="_field.dateMin"
					[max]="_field.dateMax"
				/>
			</mat-date-range-input>
			<mat-datepicker-toggle matSuffix [for]="pickerRangeDate"></mat-datepicker-toggle>
			<mat-date-range-picker #pickerRangeDate></mat-date-range-picker>
			<ng-container *ngTemplateOutlet="matError"></ng-container>
		</mat-form-field>
	</ng-container>
	<!-- formControlName BOOLEAN -->
	<ng-container *ngIf="_field.type === formControlTypeEnum.BOOLEAN">
		<mat-slide-toggle color="primary" [formControlName]="_field.name">
			<ng-container *ngTemplateOutlet="matLabel"></ng-container>
		</mat-slide-toggle>
		<ng-container *ngTemplateOutlet="matError"></ng-container>
	</ng-container>
	<!-- formControlName RTF -->
	<ng-container *ngIf="_field.type === formControlTypeEnum.RTF">
		<div class="group-margin">
			<strong>
				<ng-container *ngTemplateOutlet="matLabel"></ng-container>
				<ng-container *ngTemplateOutlet="matError"></ng-container>
			</strong>
			<mat-divider class="group-margin"></mat-divider>
		</div>
		<quill-editor
			[formControlName]="_field.name"	
			[style.height.px]="
				_field.fieldRTFConfiguration &&
				_field.fieldRTFConfiguration.height ?
				_field.fieldRTFConfiguration.height :
				formControlMultipurposeConfiguration.fieldRTFDefault.fieldRTFConfiguration.height
			"
			[modules]="
				_field.fieldRTFConfiguration &&
				_field.fieldRTFConfiguration.quill ?
				_field.fieldRTFConfiguration.quill :
				formControlMultipurposeConfiguration.fieldRTFDefault.fieldRTFConfiguration.quill
			"
		></quill-editor>
	</ng-container>
	<!-- formControlName FORM_GROUP -->
	<ng-container *ngIf="_field.type === formControlTypeEnum.FORM_GROUP">
		<div class="group-margin">
			<strong>
				<ng-container *ngTemplateOutlet="matLabel"></ng-container>
				<ng-container *ngTemplateOutlet="matError"></ng-container>
			</strong>
			<mat-divider class="group-margin"></mat-divider>
		</div>
		<div class="row">
			<form-control-multipurpose
				*ngFor="
					let subField of _field.form_control_list |
					filterByAllTheBooleans : {
						propertyListName: ['on_form_template'],
						value: true
					}
				"
				[formControlName]="subField.name"
				[field]="subField"
				class="col-md-{{ subField.column_width ? +subField.column_width : 12 }}"
			>
			</form-control-multipurpose>
		</div>
	</ng-container>
	<!-- formControlName FORM_ARRAY -->
	<ng-container *ngIf="_field.type === formControlTypeEnum.FORM_ARRAY">
		<div class="group-margin">
			<strong>
				<ng-container *ngTemplateOutlet="matLabel"></ng-container>
			</strong>
			<ng-container *ngTemplateOutlet="matError"></ng-container>
			<mat-divider class="group-margin"></mat-divider>
		</div>
		<div
			*ngFor="let item of formArrayItemControlList; let i = index"
			[formGroupName]="i"
			class="row"
		>
			<form-control-multipurpose
				*ngFor="
					let subField of item |
					filterByAllTheBooleans : {
						propertyListName: ['on_form_template'],
						value: true
					}
				"
				[formControlName]="subField.name"
				[field]="subField"
				class="col-md-{{ subField.column_width ? +subField.column_width : 12 }}"
			>
			</form-control-multipurpose>
		</div>
	</ng-container>
</ng-container>

<!-- ng-template -->
<ng-template #matLabel>
	{{ (_field.label ? (_field.label | translate) : utilService.formatPlaceholder(_field.name)) | sentencecase }}
	{{
		(
			(
				_field.type === formControlTypeEnum.BOOLEAN ||
				_field.type === formControlTypeEnum.DATE_RANGE
			) &&
			_field.required_placeholder_marker
		) ?
		'*' :
		''
	}}
</ng-template>
<ng-template #matError>
	<mat-error *ngIf="_field.validator_message && form && !form.valid">
		{{ _field.validator_message | translate | sentencecase}}
	</mat-error>
</ng-template>
<ng-template #imagePreview>
	<preview-container [configuration]="
        {
			image: _field.value,
			icon: 'attach_file',
			width: '100%',
			height: '100%',
			onClickHandler: false
        }
      ">
	</preview-container>
</ng-template>