<ul *ngIf="data && data.list && data.list.length > 0; else noDataFound" class="sidebar-content-standard">
	<li *ngFor="let item of data.list">
    <mat-icon [ngClass]="utilService.mapIcon(item.fileType.toLowerCase())">
      {{ utilService.mapIcon(item.fileType.toLowerCase()) }}
    </mat-icon>
		<div class="list-content">
			<div (click)="utilDownloadService.downloadHandler($event, false, item);" >
				<span class="left-content" [matTooltip]="item.name">{{ item.name | translate | sentencecase }}</span>
				<span class="right-content"*ngIf="item.size">{{ item.size | filesize }}</span>
      </div>
			<div *ngIf="item.date_update" class="subtitle">{{ item.date_update | dateAsAgo }}</div>
		</div>
	</li>
</ul>
<!-- ng-template -->
<ng-template #noDataFound>
  <p class="no-data-found">
    {{ 'mc.general.no_item_found' | translate }}
  </p>
</ng-template>
