import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import { ArticlePouchModel } from "@saep-ict/pouch_agent_models";
import _ from "lodash";
import * as ConfigurationCustomerArticleBadge from './article-badge.constant';

export const creationFieldMapRoot: FormControlMultipurposeModel.Item[] = [
	{
        // TODO: chiarire l'attr. `maxlength="15"` presente sull'input nel template, eventualmente applicare al field
        // un validatore custom
        name: 'size',
		on_form_template: false,
		label: 'article.field.size',
		type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 3
	},
    {
        name: 'badge_list',
        on_form_template: false,
        label: 'article.field.badge_list',
        type: FormControlMultipurposeEnum.ItemType.SELECT_MULTIPLE ,
        column_width: 3,
        option_list: []
    },
    {
        name: 'is_highlighted',
        on_form_template: false,
        label: 'article.field.is_highlighted',
        type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
        column_width: 3
    },
    {
        name: 'visible',
        on_form_template: false,
        label: 'article.field.visible',
        type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
        column_width: 3
    }
];

export const returnConfigurationCustomerArticleDescriptionCreationFieldMap = (): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const creationFieldMapToReturn = _.cloneDeep(creationFieldMapRoot);
            const badge_list = creationFieldMapToReturn.find(i => i.name === 'badge_list');
            for (let i = 0; i < ConfigurationCustomerArticleBadge.list.length; i++) {
                badge_list.option_list[i] = {
                    label: ConfigurationCustomerArticleBadge.list[i].description,
                    value: ConfigurationCustomerArticleBadge.list[i].code_item
                };
            }
			resolve(creationFieldMapToReturn);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerArticleDescriptionFormValue = (article: ArticlePouchModel): Promise<any> => {
	return new Promise(resolve => {
		try {
			article = _.cloneDeep(article);
            if (article && article.articleDescription) {
                resolve({
                    size: article.articleDescription.size,
                    is_highlighted: article.articleDescription.is_highlighted,
                    visible: article.articleDescription.visible,
                    badge_list: article.articleDescription.badge_list
                });
            } else {
                resolve(null);
            }
		} catch(e) {
			throw new Error(e);
		}
	});
}