export const iconSvgList: { label: string, path: string }[] = [
    {
        label: 'custom-hamburger',
        path: '../assets/common/img/custom-icon/icon-hamburger.svg'
    },
    {
        label: 'custom-pencil',
        path: '../assets/common/img/custom-icon/icon-pencil.svg'
    },
    {
        label: 'custom-gear',
        path: '../assets/common/img/custom-icon/icon-gear.svg'
    },
    {
        label: 'custom-package',
        path: '../assets/common/img/custom-icon/icon-package.svg'
    },
    {
        label: 'custom-trend-up',
        path: '../assets/common/img/custom-icon/icon-trend-up.svg'
    },
    {
        label: 'custom-trend-down',
        path: '../assets/common/img/custom-icon/icon-trend-down.svg'
    },
    {
        label: 'custom-trend-steady',
        path: '../assets/common/img/custom-icon/icon-trend-steady.svg'
    },
    {
        label: 'custom-download',
        path: '../assets/common/img/custom-icon/icon-download.svg'
    },
    {
        label: 'custom-document',
        path: '../assets/common/img/custom-icon/icon-document.svg'
    },
    {
        label: 'custom-folder',
        path: '../assets/common/img/custom-icon/icon-folder.svg'
    },
    {
        label: 'custom-picture',
        path: '../assets/common/img/custom-icon/icon-picture.svg'
    },
    {
        label: 'custom-help',
        path: '../assets/common/img/custom-icon/icon-help.svg'
    },
    {
        label: 'custom-visibility',
        path: '../assets/common/img/custom-icon/icon-visibility.svg'
    },
    {
        label: 'custom-business',
        path: '../assets/common/img/custom-icon/icon-business.svg'
    },
    {
        label: 'custom-dashboard',
        path: '../assets/common/img/custom-icon/icon-dashboard.svg'
    },
    {
        label: 'custom-inbox',
        path: '../assets/common/img/custom-icon/icon-inbox.svg'
    },
    {
        label: 'custom-content_copy',
        path: '../assets/common/img/custom-icon/icon-content_copy.svg'
    },
    {
        label: 'custom-pie_chart',
        path: '../assets/common/img/custom-icon/icon-pie_chart.svg'
    },
    {
        label: 'custom-image',
        path: '../assets/common/img/custom-icon/icon-image.svg'
    },
    {
        label: 'custom-folder-2',
        path: '../assets/common/img/custom-icon/icon-folder-2.svg'
    },
    {
        label: 'custom-insert_drive_file',
        path: '../assets/common/img/custom-icon/icon-insert_drive_file.svg'
    },
    {
        label: 'custom-help_outline',
        path: '../assets/common/img/custom-icon/icon-help_outline.svg'
    },
    {
        label: 'custom-bar_chart',
        path: '../assets/common/img/custom-icon/icon-bar_chart.svg'
    },
    {
        label: 'custom-textsms',
        path: '../assets/common/img/custom-icon/icon-textsms.svg'
    },
    {
        label: 'custom-groups',
        path: '../assets/common/img/custom-icon/icon-groups.svg'
    },
    {
        label: 'custom-settings',
        path: '../assets/common/img/custom-icon/icon-settings.svg'
    },
    {
        label: 'custom-shopping_basket',
        path: '../assets/common/img/custom-icon/icon-shopping_basket.svg'
    },
    {
        label: 'custom-inventory_2',
        path: '../assets/common/img/custom-icon/icon-inventory_2.svg'
    },
    {
        label: 'custom-tune',
        path: '../assets/common/img/custom-icon/icon-tune.svg'
    },
    {
        label: 'custom-account_tree',
        path: '../assets/common/img/custom-icon/icon-account_tree.svg'
    },
    {
        label: 'custom-confirmation_number',
        path: '../assets/common/img/custom-icon/icon-confirmation_number.svg'
    },
    {
        label: 'custom-contact_page',
        path: '../assets/common/img/custom-icon/icon-contact_page.svg'
    },
    {
        label: 'custom-info',
        path: '../assets/common/img/custom-icon/icon-info.svg'
    },
    {
        label: 'custom-campaign',
        path: '../assets/common/img/custom-icon/icon-campaign.svg'
    },
    {
        label: 'custom-lightbulb',
        path: '../assets/common/img/custom-icon/icon-lightbulb.svg'
    },
    {
        label: 'custom-checklist',
        path: '../assets/common/img/custom-icon/icon-checklist.svg'
    },
    {
        label: 'custom-category',
        path: '../assets/common/img/custom-icon/icon-category.svg'
    },
    {
        label: 'custom-logo_dev',
        path: '../assets/common/img/custom-icon/icon-logo_dev.svg'
    },
    {
        label: 'custom-person',
        path: '../assets/common/img/custom-icon/icon-person.svg'
    },
    {
        label: 'custom-envelop',
        path: '../assets/common/img/custom-icon/icon-envelop.svg'
    },
    {
        label: 'custom-trash',
        path: '../assets/common/img/custom-icon/icon-trash.svg'
    },
    {
        label: 'custom-attach',
        path: '../assets/common/img/custom-icon/icon-attach.svg'
    },
    {
        label: 'custom-check_circle',
        path: '../assets/common/img/custom-icon/icon-check_circle.svg'
    },
    {
        label: 'icon-send',
        path: '../assets/common/img/custom-icon/icon-send.svg'
    },
    {
        label: 'custom-push_pin',
        path: '../assets/common/img/custom-icon/icon-push_pin.svg'
    },
    {
        label: 'custom-send',
        path: '../assets/common/img/custom-icon/icon-send.svg'
    },
    {
        label: 'custom-timer',
        path: '../assets/common/img/custom-icon/icon-timer.svg'
    },
    {
        label: 'not-authorized',
        path: '../assets/common/img/custom-icon/not-authorized.svg'
    },
    {
        label: 'custom-visibility_off',
        path: '../assets/common/img/custom-icon/icon-visibility_off.svg'
    },
    {
        label: 'custom-logout',
        path: '../assets/common/img/custom-icon/icon-logout.svg'
    },
    {
        label: 'custom-store',
        path: '../assets/common/img/custom-icon/icon-store.svg'
    },
    {
        label: 'custom-sync',
        path: '../assets/common/img/custom-icon/icon-sync.svg'
    },
    {
        label: 'custom-globe',
        path: '../assets/common/img/custom-icon/icon-globe.svg'
    },
    // custom project
    {
        label: 'custom-xlsx',
        path: '../assets/theme-current/img/custom-icon/xlsx_icon.svg'
    },
    {
        label: 'custom-pdf',
        path: '../assets/theme-current/img/custom-icon/pdf_icon.svg'
    },
    {
        label: 'custom-ppt',
        path: '../assets/theme-current/img/custom-icon/ppt_icon.svg'
    },
    {
        label: 'custom-doc',
        path: '../assets/theme-current/img/custom-icon/doc_icon.svg'
    },
    {
        label: 'custom-generic',
        path: '../assets/theme-current/img/custom-icon/generic_icon.svg'
    },
];