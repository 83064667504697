import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { Category } from '@saep-ict/pouch_agent_models';

export enum CategoryDetailActionEnum {
	UPDATE = '[Category Detail] Update',
	LOAD = '[Category Detail] Load',
	SAVE = '[Category Detail] Save',
	SAVE_UPDATE = '[Category Detail] Save Update',
	RESET = '[Category Detail] Reset',
	ERROR = '[Category Detail] Error'
}

export namespace CategoryDetailAction {
	export const update = createAction(CategoryDetailActionEnum.UPDATE, props<BaseStateModel<Category>>());
	export const load = createAction(CategoryDetailActionEnum.LOAD, props<{ id: string }>());
	export const save = createAction(CategoryDetailActionEnum.SAVE, props<BaseStateModel<Category>>());
	export const saveUpdate = createAction(CategoryDetailActionEnum.SAVE_UPDATE);
	export const reset = createAction(CategoryDetailActionEnum.RESET);
}
