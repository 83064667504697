<mat-dialog-wrapper [title]="'opportunity.select_organization' | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<context-code-management-wrapper
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="columns"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="true"
			(onItemSelect)="selectOrganization($event)"
		></context-code-management-wrapper>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
