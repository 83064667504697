import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { ContextApplicationItemCodeEnum, PATH_URL, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { StateRelatedLink } from './status-aggregation-map.constant';
import * as ConfigurationCustomerOrder from './order.constant';


export const getGoBackPath = (
    orderStatus: OrderStatusEnum,
    contextApplication: ContextApplicationItemCodeEnum
) => {
    const statusRelatedMap: StateRelatedLink[] =
        ConfigurationCustomerOrder.statusAggregationMap[
            ContextApplicationItemCodeEnum[contextApplication]
        ];
    const stateRelatedLink = statusRelatedMap.find(i => i.related_list.includes(orderStatus));
    return stateRelatedLink ?
        PATH_URL.PRIVATE + '/' + ROUTE_URL.orders + '/' + stateRelatedLink.state.toLowerCase() :
        null;
}