import { Category } from '../table/category-list.model';
import { LanguageItem } from '../util-pouch.model';
import { ArticlePouchModel, ArticleDescription } from '../article-pouch.model';

import { LocalListHandlerBaseModel } from '../structure/local-list-handler.model';
export interface ConfigArticleActions {
    deleteArticle?: boolean;
}
export interface ArticleCheckoutTree extends Category {
    article_list: ArticlePouchModel[];
}
export interface ArticleCheckoutTreeParseUtil {
    article_checkout_tree: ArticleCheckoutTree[];
    articleList: ArticlePouchModel[];
}
export interface ArticleListCategoryTreeRecursionBaseModel<T> extends LocalListHandlerBaseModel<T> {
    level: number;
}
export interface ArticleDescriptionItemRest {
    code_item: string;
    size: string;
    is_available: boolean;
    is_highlighted: boolean;
    related_article_list: string[];
    category_list: string[];
    language_list: LanguageItem[];
}
export interface ArticleDescriptionImageItem {
    id: string;
    alt: string;
    name: string;
    data: string;
}
export interface ExtendedArticleDescription extends ArticleDescription {
    um_selling?: string;
}
