import { createFeatureSelector } from '@ngrx/store';

// model
import { BaseStateModel } from '@saep-ict/angular-core';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { ITicketCenterConfigModel } from '../model/lib-app-config.interface';
import { TicketModel } from '../model/lib.model';


export namespace StateFeature {
  // parent app
  export const getUserStore = createFeatureSelector<BaseStateModel<UserDetailModel>>(
		'user'
	);

  // ticket
	export const getTicketStore = createFeatureSelector<BaseStateModel<TicketModel<any>[]>>(
		'ticket-center-ticket'
	);
	export const getTicketDetailStore = createFeatureSelector<BaseStateModel<TicketModel<any>>>(
		'ticket-center-ticket-detail'
	);

  // ticket-center configuration
  export const getTicketCenterConfiguration = createFeatureSelector<BaseStateModel<ITicketCenterConfigModel>>(
		'ticket-center-lib-configuration'
	);

}
