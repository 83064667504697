import moment from 'moment';
import * as StatusBar from '../../../../../../app/constants/configuration-customer/order/status-aggregation-map-status-bar.constant';
export const heading = [['Order Number', 'Customer Reference', 'Status', 'Date', 'Shipping Address', 'Amount']];

// portal b2c b2b
export const exportExcel = (list: any[]) => {
	let json = [];
	list.forEach(order => {
		let h = order.header;
		let dest = h.goods_destination_object;
		json.push({
			n: order._id.substring(order._id.length - 12).toUpperCase(),
			customer_reference: h.customer_order_ref_code ? h.customer_order_ref_code : '',
			status: StatusBar.statusList.find(i => i.id === h.status).label,
			date: h.date ? moment(h.date).toDate() : '',
			shipping_address:
				dest.address +
				' ' +
				dest.zip_code +
				' ' +
				dest.locality +
				(dest.province ? '(' + dest.province.label + ' )' : ' '),
			amount: h.price && h.currency ? h.price.total + ' ' + h.currency.code_item : ''
		});
	});
	return json;
};

