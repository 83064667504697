<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.RIGHT">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight">
			<tab-wrapper *ngIf="organization" [toExclude]="toExclude"></tab-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper>
			<ng-container main-content>
				<div class="row info-card">
					<div class="col-4">
						<!-- <div class="placeholder-box"></div> -->
						<div class="person-placeholder-box">
							<mat-icon [svgIcon]="'custom-business'" class=""></mat-icon>
						</div>
					</div>
					<div class="col-8">
						<ul>
							<li>
								<span class="title">{{ 'general.status.name' | translate | sentencecase }}</span>
								<p *ngIf="organization.status">
									{{
										'organization.status.' + (organization.status | uppercase)
											| translate
											| sentencecase
									}}
								</p>
							</li>
							<mat-divider></mat-divider>
							<li>
								<span class="title">{{ 'organization.field.type' | translate | sentencecase }}</span>
								<p *ngIf="organization.organization_type">
									{{ organization.organization_type | titlecase }}
								</p>
							</li>
							<mat-divider></mat-divider>
							<li>
								<span class="title">{{ 'organization.field.address' | translate | sentencecase }}</span>
								<p *ngIf="organization.destination_list?.length; else noData">
									{{
										utilAddressService.getFormattedAddress(
											utilService.returnIsMainOfList(organization.destination_list).address
										)
									}}
								</p>
							</li>
							<mat-divider></mat-divider>
							<li>
								<span class="title">{{
									'organization.field.tax_code' | translate | sentencecase
								}}</span>
								<p *ngIf="organization?.tax_data?.tax_code; else noData">
									{{ organization.tax_data.tax_code }}
								</p>
							</li>
							<mat-divider></mat-divider>
							<li>
								<span class="title">{{
									'organization.field.vat_number' | translate | sentencecase
								}}</span>
								<p *ngIf="organization?.tax_data?.vat_number; else noData">
									{{ organization.tax_data.vat_number }}
								</p>
							</li>
							<mat-divider></mat-divider>
							<li>
								<span class="title">{{
									'organization.field.url_website' | translate | sentencecase
								}}</span>
								<p *ngIf="organization?.crm?.url_website; else noData">
									{{ organization.crm.url_website }}
								</p>
							</li>
						</ul>
						<ng-template #noData>
							<p>-</p>
						</ng-template>
					</div>
				</div>
				<button mat-stroked-button (click)="editOrganization()" class="full-width" *ngIf="configurationCustomerOrganization.canCreate.detail[user.current_permission.context_application]">
					{{ 'general.edit_profile' | translate | sentencecase }}
				</button>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>

<tab-wrapper *ngIf="organization === 'WIP_OLD_CODE'" [toExclude]="toExclude">
	<!-- BACKOFFICE CONTEXT -->
	<mat-toolbar
		*ngIf="
			organization.level === organizationLevelEnum.LEAD &&
			user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE
		"
		class="toolbar organization-validation"
	>
		<img src="assets/common/img/icon/warning-sign.png" class="warning-sign" alt="Warning Sign" />
		<span class="message">
			{{ 'organization.validation.message' | translate | sentencecase }}
		</span>
		<button mat-flat-button color="primary" (click)="organizationValidation()">
			{{ 'organization.validation.valid' | translate | sentencecase }}
		</button>
	</mat-toolbar>
	<!-- END BACKOFFICE CONTEXT -->

	<div class="organization-header" *ngIf="organizationHeaderConfig">
		<div class="organization-header__avatar">
			<avatar
				[avatarConfigClass]="organizationHeaderConfig.avatar"
				[currentContext]="user.current_permission.context_application"
				[level]="organization.level"
			></avatar>
		</div>

		<div class="organization-header__content">
			<div class="organization-header__info">
				<span class="icon-label" *ngFor="let information of organizationHeaderConfig.informationList">
					<mat-icon>{{ information.icon }}</mat-icon>
					<div class="icon-label__content">
						<div class="icon-label__label">
							{{ information.label | translate | sentencecase }}
						</div>
						<div class="icon-label__value">
							<ng-container *ngIf="information.value; else noValue">
								{{ information.value }}
							</ng-container>
							<ng-template #noValue>
								<span class="no-data">
									{{ 'general.not_defined' | translate | sentencecase }}
								</span>
							</ng-template>
						</div>
					</div>
				</span>
			</div>
		</div>

		<div
			*ngIf="configurationCustomerOrganization.canCreate.detail[user.current_permission.context_application]"
			class="organization-header__actions"
		>
			<button mat-icon-button (click)="editOrganization()">
				<mat-icon class="color-gray">edit</mat-icon>
			</button>
		</div>
	</div>
</tab-wrapper>

<!-- <floating-menu
	class="mat-fab-button"
	[menuItems]="navigationFloatingMenuService.organizationDetailFloatinMenu"
	(actionEmitter)="onFloatingMenuClicked($event)"
	color="primary"
></floating-menu> -->
