import { UtilPriceService } from './../../../../service/util/util-price.service';
import { Component, Input } from '@angular/core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { AngularSpin8CoreUtilTranslateService, OrderStateModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerOrderErp from '../../../../constants/configuration-customer/order/order-erp.constant';
import * as ConfigurationCustomerPrice from '../../../../constants/configuration-customer/price/price.constant';

@Component({
	selector: 'b2c-checkout-article-list',
	templateUrl: './b2c-checkout-article-list.component.html',
	styleUrls: ['./b2c-checkout-article-list.component.scss']
})
export class B2cCheckoutArticleListComponent {
	@Input() productList: ArticlePouchModel[] = [];
	@Input() order: OrderStateModel;

	configurationCustomerOrderErp = ConfigurationCustomerOrderErp;
	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public utilPriceService: UtilPriceService
	) {}
}
