// Public API Surface of lib

export {LocalListHandlerBase }from '@saep-ict/angular-core';
export { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';

// export module
export * from './lib.module';
export * from './page/page.module';

// export component
export * from './page/ticket-center/ticket-center.component';

// export model
export * from './model/lib-app-config.interface';
export * from './model/lib.model';
export * from './model/configuration.model';
export * from './model/util.model';

// export route
export { TicketCenterRoutes } from './router/base-routing';

export {TicketCenterTranslateLoader} from './i18n/default-translate_loader';

// store

export * from './store/lib-configuration/lib-configuration.actions';
export * from './store/ticket/ticket.actions';
export * from './store/ticket/ticket-detail/ticket-detail.action';

// service
export * from './service/util/ticket-center-main.service';
