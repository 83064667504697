import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ArticleRecap } from '../../model/state/article-list-state.model';
import { BaseState, BaseStateModel, RestBaseResponse } from '@saep-ict/angular-core';

import { ArticleDescriptionActionEnum, ArticleDescriptionStateAction } from './article-description.actions';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { CustomerAppConfig } from '../../customer-app.config';
import {
	AngularSpin8CoreArticleService,
	AngularSpin8CoreCatalogService
} from '@saep-ict/angular-spin8-core';
import {ArticleDescriptionItemRest} from '@saep-ict/pouch_agent_models';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class ArticleDescriptionEffects {
	loadDescriptionFromRecap$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleDescriptionActionEnum.LOAD_DESCRIPTION_FROM_RECAP),
			mergeMap((action: BaseStateModel<any>) => from(this.getArticleDescriptionList())),
			map((articleDescription: BaseStateModel<ArticleRecap>) =>
				ArticleDescriptionStateAction.update(articleDescription)
			),
			catchError((error, caught) => {
				this.store.dispatch(ArticleDescriptionStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleDescriptionActionEnum.SAVE),
			mergeMap((action: BaseStateModel<ArticleDescriptionItemRest>) => from(this.saveArticleDescription(action))),
			map((articleRecap: BaseStateModel<ArticleRecap>) => ArticleDescriptionStateAction.saveComplete(articleRecap)),
			catchError((error, caught) => {
				this.store.dispatch(ArticleDescriptionStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter,
		private appConfig: CustomerAppConfig,
		private catalogService: AngularSpin8CoreCatalogService,
		private articleService: AngularSpin8CoreArticleService
	) {}

	async getArticleDescriptionList(): Promise<BaseStateModel<ArticleRecap>> {
		if (this.appConfig.authenticationToken) {
			return this.pouchDbCommonsAdapter.basePouch
				.getDetail(`article_recap${ConfigurationCustomerAppStructure.noSqlDocSeparator}description`)
				.then((doc: ArticleRecap) => {
					return new BaseState(doc);
				})
				.catch(err => {
					throw new Error(err.message);
				});
		} else {
			return this.catalogService
				.getArticlesDescription()
				.then((doc: RestBaseResponse<any>) => {
					return new BaseState(doc.data);
				})
				.catch(err => {
					throw new Error(err.message);
				});
		}
	}

	async saveArticleDescription(
		action: BaseStateModel<ArticleDescriptionItemRest>
	): Promise<BaseStateModel<ArticleRecap>> {
		const articleDescriptionUpdated = await this.articleService.updateArticleRecapDescriptionItem(action.data);
		return new BaseState(articleDescriptionUpdated.data);
	}
}
