import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KanbanMoveEvent } from '../model/KanbanMoveEvent';
import { KanbanSortEvent } from '../model/KanbanSortEvent';

@Component({
	selector: 'kanban-container',
	templateUrl: './kanban-container.component.html',
	styleUrls: ['./kanban-container.component.scss']
})
export class KanbanContainerComponent implements OnInit {
	@Output() cardSorted: EventEmitter<KanbanSortEvent> = new EventEmitter();
	@Output() cardMoved: EventEmitter<KanbanMoveEvent> = new EventEmitter();

	@Input() columnList = [];

	constructor() {}

	ngOnInit() {}
}
