<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.BOTTOM">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight mb-20" *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.order)">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'order.summary.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-select
						[compareWith]="dashboardConfiguration.compareValueOrderTrendTimeRange"
						[value]="configurationCustomerStatistic.timeRangeSelector[0]"
						(selectionChange)="updateBoxTrendInfoOrderContent($event)"
					>
						<mat-option
							*ngFor="let item of configurationCustomerStatistic.timeRangeSelector"
							[value]="item"
						>
							{{ item.label | translate | sentencecase }}
						</mat-option>
					</mat-select>
					<!-- <mat-icon> help_outline </mat-icon> -->

					<button mat-icon-button (click)="orderSummaryClosablePanel.isVisible = true">
						<mat-icon> help_outline </mat-icon>
					</button>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>
						{{ 'general.updated.at' | translate | sentencecase }}
						{{ updatedAtGeneral }}
					</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>

			<closable-panel #orderSummaryClosablePanel>
				<ng-container content>
					{{ 'order.summary.info_panel' | translate | sentencecase }}
				</ng-container>
			</closable-panel>

			<box-trend-info [boxTrendInfoComponentConfig]="boxTrendInfoOrder"></box-trend-info>

			<mat-divider class="both-spacing"></mat-divider>

			<order-list-wrapper
				[title]="'chart.recent_orders.title'"
				[localListHandlerWidgetWrapperData]="listPageBaseLastOrders"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseLastOrders.columnList"
				[localListHandlerPaginatorShow]="true"
				[viewAllButtonConfiguration]="viewAllButtonConfiguration"
				(onItemSelect)="goToOrderDetail($event)"
				(selectCompany)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code)"
			>
			</order-list-wrapper>
		</mat-card>

		<!-- TODO: ripristinare una volta preparati i dati -->
		<!-- <mat-card class="michelangelo-theme mt-20 mt-30 mb-20 mb-30 autoheight">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'order.value.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<button mat-icon-button (click)="orderValueClosablePanel.isVisible = true">
						<mat-icon> help_outline </mat-icon>
					</button>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>
						{{
							'general.updated.minutes_ago'
								| translate
									: {
											number: 2,
											label:
												(2 > 1 ? 'general.minute.label_plural' : 'general.minute.label')
												| translate
									  }
								| sentencecase
						}}
					</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>

			<closable-panel #orderValueClosablePanel>
				<ng-container content>
					{{ 'order.value.info_panel' | translate | sentencecase }}
				</ng-container>
			</closable-panel>

			<chart-wrapper [config]="chartData" class="mt-30 mt-20"></chart-wrapper>
		</mat-card> -->

		<mat-card class="michelangelo-theme autoheight" *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.credit)">
			<div class="row-wrapper columns-2 mt-20 mb-20">
				<div class="column">
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'customer_credit.field.due' | translate | sentencecase }}
							<span class="flex-span"></span>
						</mat-toolbar-row>
						<mat-toolbar-row>
							<mat-card-subtitle
								>{{ 'customer_credit.field.debit' | translate | sentencecase }} -
								{{ 'customer_credit.field.credit' | translate | sentencecase }}</mat-card-subtitle
							>
						</mat-toolbar-row>
					</mat-toolbar>
					<box-trend-info [boxTrendInfoComponentConfig]="dueTrendBoxContent"></box-trend-info>
				</div>
				<div class="column">
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'customer_credit.field.overdue' | translate | sentencecase }}
							<span class="flex-span"></span>
						</mat-toolbar-row>
						<mat-toolbar-row>
							<mat-card-subtitle
								>{{ 'customer_credit.field.debit_expired' | translate | sentencecase }} -
								{{
									'customer_credit.field.credit_expired' | translate | sentencecase
								}}</mat-card-subtitle
							>
						</mat-toolbar-row>
					</mat-toolbar>
					<box-trend-info [boxTrendInfoComponentConfig]="overdueTrendBoxContent"></box-trend-info>
				</div>
			</div>
			<!-- TODO: eventuale ripristino del pannello esplicativo -->
			<!-- <mat-toolbar>
				<mat-toolbar-row>
					{{ 'order.credits.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<button mat-icon-button (click)="orderCreditslosablePanel.isVisible = true">
						<mat-icon> help_outline </mat-icon>
					</button>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>
						{{
							'general.updated.minutes_ago'
								| translate
									: {
											number: 2,
											label:
												(2 > 1 ? 'general.minute.label_plural' : 'general.minute.label')
												| translate
									  }
								| sentencecase
						}}
					</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<closable-panel #orderCreditslosablePanel>
				<ng-container content>
					{{ 'order.credits.info_panel' | translate | sentencecase }}
				</ng-container>
			</closable-panel>
			<box-trend-info [boxTrendInfoComponentConfig]="trendBoxContentCredits"></box-trend-info> -->
		</mat-card>
	</ng-container>

	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'price.list' | translate | sentencecase" *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.order)">
			<ng-container main-content>
				<sidebar-content-standard
					[sidebarContentStandardComponentConfig]="sidebarContentPriceList"
				></sidebar-content-standard>

					<a
						[href]="
							bucketManager.returnBucketManagerDownloadUrl(
								user.current_permission.context_code.code + '.xlsx',
								'price_list',
								null
							)
						"
						target="_blank"
					>
						<button
							mat-stroked-button
							type="button"
							class="full-width"
						>
							{{ 'general.download' | translate | sentencecase }}
						</button>
					</a>
			</ng-container>
		</sidebar-content-wrapper>

		<sidebar-content-wrapper [title]="'news.label' | translate | sentencecase" *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.news)">
			<ng-container main-content>
				<sidebar-content-standard
					[sidebarContentStandardComponentConfig]="sidebarContentNews"
				></sidebar-content-standard>
				<button
					mat-stroked-button
					type="button"
					class="full-width"
					[routerLink]="[PATH_URL.PRIVATE, ROUTE_URL.news, ROUTE_URL.allNews]"
				>
					{{ 'general.view_all' | translate | sentencecase }}
				</button>
			</ng-container>
		</sidebar-content-wrapper>

		<sidebar-content-wrapper [title]="'file.label_plural' | translate | sentencecase" *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.mediaCenter)">
			<ng-container main-content>
				<mc-media-center
					*ngIf="mediaCenterCustomHeaders"
					[customHeaders]="mediaCenterCustomHeaders"
					[mediaCenterFilter]="mediaCenterFilter"
				></mc-media-center>
				<button
					mat-stroked-button
					type="button"
					class="full-width"
					[routerLink]="[PATH_URL.PRIVATE, ROUTE_URL.mediaCenter, 'mediacenter']"
				>
					{{ 'general.view_all' | translate | sentencecase }}
				</button>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
