import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import _ from "lodash";
import { ReceiptStateModel } from "../../../model/state/receipt-state.model";
import { ReceiptListFilterCreationFieldMapFormValue } from "../../../model/receipt-util.model";
import { returnConfigurationCustomerReceiptListFilterByDateRangeDateCreation, returnConfigurationCustomerReceiptListFilterByDateRangeDateExpiration, returnConfigurationCustomerReceiptListFilterByPriceTotalMax, returnConfigurationCustomerReceiptListFilterByPriceTotalMin } from "./receipt-list-filter-form-dynamic-util.constant";

export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
	{
		name: 'date_creation',
		on_form_template: true,
		label: 'receipt.field.date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'expiration_date',
		on_form_template: true,
		label: 'receipt.field.expiration_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'priceTotalMin',
		on_form_template: true,
		label: 'receipt.field.price_total_min',
		type: FormControlMultipurposeEnum.ItemType.NUMBER,
		column_width: 6
	},
	{
		name: 'priceTotalMax',
		on_form_template: true,
		label: 'receipt.field.price_total_max',
		type: FormControlMultipurposeEnum.ItemType.NUMBER,
		column_width: 6
	},
];

export const returnConfigurationCustomerReceiptListFilterCreationFieldMap = async (

): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const creationFieldMapToReturn = _.cloneDeep(creationFieldMap);
			resolve(creationFieldMapToReturn);
		} catch(err) {
			throw new Error(err);
		}
	});
}

export const returnConfigurationCustomerReceiptListFilterByFormValue = async (
    list: ReceiptStateModel[],
    formValue: ReceiptListFilterCreationFieldMapFormValue
): Promise<ReceiptStateModel[]> => {
	try {
		if (list && list.length && formValue) {
			list = await returnConfigurationCustomerReceiptListFilterByDateRangeDateCreation(list, formValue);
			list = await returnConfigurationCustomerReceiptListFilterByDateRangeDateExpiration(list, formValue);
			list = await returnConfigurationCustomerReceiptListFilterByPriceTotalMin(list, formValue);
			list = await returnConfigurationCustomerReceiptListFilterByPriceTotalMax(list, formValue);
		}
		return list;
	} catch(err) {
		throw new Error(err);
	}
}
