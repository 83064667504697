import { AdditionalService } from '@saep-ict/pouch_agent_models';

export interface AdditionalServiceStateModel {
	delivery_date?: AdditionalService[];
	stock_type?: AdditionalService[];
}

export interface AdditionalServiceHeader {
	delivery_date?: AdditionalService;
	stock_type?: AdditionalService;
}
