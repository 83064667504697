import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularSpin8CoreProbeTestService } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'probe-test',
	templateUrl: './probe-test.component.html',
	styleUrls: ['./probe-test.component.scss']
})
export class ProbeTestComponent implements OnInit {
	constructor(private route: ActivatedRoute, private probeTestService: AngularSpin8CoreProbeTestService) {}

	ngOnInit() {
		this.route.queryParamMap.subscribe(params => {
			if (params.get('crash')) {
				throw new Error('CRASH');
			}
		});
		this.probeTestService
			.getProbeTest()
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.log(err);
				throw new Error('CRASH');
			});
	}
}
