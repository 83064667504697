import { ContactPouchModel, ContactTypeEnum, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { Language } from '../enum/language.enum';

export class ContactModel implements ContactPouchModel {
	contact_type?: ContactTypeEnum;
	full_name?: string;
	first_name: string;
	last_name: string;
	role: string;
	area?: string;
	email: string;
	phone?: string;
	phone_mobile?: string;
	fax?: string;
	is_main_of_list?: boolean;
	has_notification?: boolean = true;
	organization?: OrganizationPouchModel;
	language?: string = 'it';
	client_id: string[];
}
