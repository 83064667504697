import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { StatisticsDetailExpiredStateAction } from '../../../../state/statistics-detail-expired/statistics-detail-expired.action';
import { StatisticsDetailBalance } from '../../../../model/statistics-detail-balance.model';
import { Observable } from 'rxjs';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../state';
import { filter, take } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { AccountBalanceDueChartConfigService } from '../../../../service/chart-structure/implementation/account-balance-due-chart.service';
import { AccountBalanceExpiredDetailChartConfigService } from '../../../../service/chart-structure/implementation/account-balance-expired-detail-chart.service';
import { chartTimeRange30Days } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'dashboard-agent-expired',
	templateUrl: './dashboard-agent-expired.component.html',
	styleUrls: ['./dashboard-agent-expired.component.scss'],
	providers: [AccountBalanceDueChartConfigService, AccountBalanceExpiredDetailChartConfigService]
})
export class DashboardAgentExpiredComponent implements OnDestroy {
	dateUpdatedStatistics: number;
	statistics$: Observable<BaseStateModel<StatisticsDetailBalance>> = this.store.select(
		StateFeature.getStatisticsDetailExpired
	);
	statistics: StatisticsDetailBalance;

	timeRange = chartTimeRange30Days;

	constructor(
		private store: Store<StatisticsDetailBalance>,
		public accountBalanceDueChartConfigService: AccountBalanceDueChartConfigService,
		public accountBalanceExpiredDetailChartConfigService: AccountBalanceExpiredDetailChartConfigService
	) {
		// Load Statistics Expired
		this.store.dispatch(StatisticsDetailExpiredStateAction.load());

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statistics$
			.pipe(
				filter((state: BaseStateModel<StatisticsDetailBalance>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.date_update;
			});
	}

	setTimeRange(selectedTimeRange: MatSelectChange) {
		const charts = [this.accountBalanceDueChartConfigService, this.accountBalanceExpiredDetailChartConfigService];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	ngOnDestroy() {
		this.store.dispatch(StatisticsDetailExpiredStateAction.reset());
	}
}
