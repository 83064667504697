import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class UtilSentryService {

  constructor() {}

  captureMessage(message: string, level: any, error?: any) { 
    let sentryMessage = null;
    if(error) {
      const errorString = typeof error === 'string' ? error: error.reason + error.status; 
      sentryMessage = `${message}: ${errorString}`;
    } else {
      sentryMessage = message;
    }
    Sentry.captureMessage(sentryMessage, level);
  }
}
