import { createAction, props } from '@ngrx/store';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';

export enum ContactActionEnum {
	UPDATE = '[contact] Update',
	LOAD_ALL = '[contact] Load All',
	RESET = '[contact] Reset',
	ERROR = '[contact] Error',
	SAVE = '[contact] Save Contact'
}

export namespace ContactStateAction {
	export const loadAll = createAction(ContactActionEnum.LOAD_ALL);
	export const update = createAction(
		ContactActionEnum.UPDATE,
		props<BaseStateModel<ListDataPouchModel<ContactPouchModel>>>()
	);
	export const save = createAction(ContactActionEnum.SAVE, props<BaseStateModel<ContactPouchModel>>());
	export const reset = createAction(ContactActionEnum.RESET);
	export const error = createAction(ContactActionEnum.ERROR, props<BaseStateModel<any>>());
}
