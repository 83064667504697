import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	FormValidatorCustom,
	ListWrapperComponent
} from '@saep-ict/angular-core';
import { OrderPouchModel, LocalListHandlerBaseModel, ITdDataTableColumnCustom} from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { AppUtilService } from '../../../service/util/app-util.service';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { OpportunityActionEnum } from '../../../state/opportunity/opportunity.actions';
import { OrderListActionEnum, OrderListStateAction } from '../../../state/order-list/order-list.actions';
import { OfferListWrapperComponent } from '../../../widget/offer/offer-list-wrapper/offer-list-wrapper.component';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { TableOrderModel } from '../../../model/table/table-order.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateFilterModel } from '../../../service/pouch-db/filter/order-filter.model';
import {
	ContextApplicationItemCodeEnum,
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	PouchDbModel,
	ROUTE_URL,
	AngularSpin8CoreUtilCompanyService,
	OpportunityPouchModel,
	OfferStateModel,
	AngularSpin8CoreUtilOpportunityService,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';

import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import * as ConfigurationCustomerOffer from '../../../constants/configuration-customer/offer/offer.constant';
import * as ConfigurationCustomerOrderErp from '../../../constants/configuration-customer/order/order-erp.constant';

@Component({
	selector: 'offer',
	templateUrl: './offer.component.html',
	styleUrls: ['./offer.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferComponent implements OnInit, OnDestroy {
	@ViewChild(OfferListWrapperComponent) offerListWrapperComponent: OfferListWrapperComponent;

	// state
	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	// orderStatus: OrderStatusEnum;

	orderList: TableOrderModel[] = [];
	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	public idOpportunity = this.activatedRoute.parent.snapshot.paramMap.get('idOpportunity');
	public idOrganization = this.activatedRoute.parent.snapshot.paramMap.get('idOrganization');

	// table
	public columns: ITdDataTableColumnCustom[];

	// Filter
	filterForm: FormGroup;

	// User
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	public listPageBaseData = <LocalListHandlerBaseModel<TableOrderModel>>{
		pagination: {
			pageSize: this.contextApplicationItemCodeEnum.CRM ? 25 : 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
				'_id',
				'header.organization.business_name',
				'header.organization.code_item',
				ConfigurationCustomerAppStructure.erp_has_erp ? 'product_list.code_erp' : 'product_list.code_item'
				]
			}
		},
		sort: {
			name: 'header.custom_field.title',
			order: 'ASC'
		},
		data: []
	};

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilOrderService: UtilOrderService,
		private utilService: AppUtilService,
		public activatedRoute: ActivatedRoute,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		public utilOpportunityService: AngularSpin8CoreUtilOpportunityService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.store.dispatch(OrderListStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeMandatoryData().subscribe(), 'mandatory-data');
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.columns = ConfigurationCustomerOffer.ContextApplicationSection[this.user.current_permission.context_application].OFFER;
		this.handleColumnVisibility();
		//this.utilBreadcrumbService.title = this.translateService.instant('offer.list');
		this.setRouteMetaInformation();

	}

	ngOnInit() {
		if (this.idOpportunity) {
			this.opportunity$
				.pipe(
					take(1),
					filter(
						(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
							!!(opportunityState && opportunityState.data)
					),
					map(opportunityState => {
						if (opportunityState.type === OpportunityActionEnum.UPDATE) {
							this.opportunity = opportunityState.data[0];
						}
					})
				)
				.subscribe();
		}
		this.createFilterForm();
	}

	handleColumnVisibility() {
		// se sono nel contesto specifico nascondo
		if (this.idOrganization || this.idOpportunity) {
			this.columns.map(col => {
				col.hidden = ['header.organization.business_name'].includes(col.name);
			});
		}
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// this.store.dispatch(OfferStateAction.reset());

		this.store.dispatch(OrderListStateAction.reset());
		// Reset dello state opportunity nel componente padre
		this.unsetRouteMetaInformation();
	}

	subscribeMandatoryData() {
		return this.orderList$.pipe(
			// filter(paramMap => !!paramMap),
			// mergeMap(paramMap => {
			// 	if (paramMap.get('orderStatus')) {
			// 		this.orderStatus = OrderStatusEnum[paramMap.get('orderStatus').toUpperCase()];
			// 	}
			// 	return this.orderList$;
			// }),
			filter((orderList: BaseStateModel<OrderStateModel[]>) => !!(orderList && orderList.data)),
			map((offerList: BaseStateModel<OrderStateModel[]>) => {
				switch (offerList.type) {
					case OrderListActionEnum.UPDATE:
						this.orderList = ConfigurationCustomerOrderErp.getTableOrderList(_.cloneDeep(offerList.data));
						this.applyPermanentFilters(this.orderList);
						// this.setupFilterArticleCode();
						break;

					case OrderListActionEnum.ERROR:
						this.updateListPageBaseData([]);
						throw new Error(OrderListActionEnum.ERROR);
						break;

					default:
						break;
				}
			})
		);
	}

	applyPermanentFilters(orderList: any[]) {
		// filtro solo le offerte
		orderList = orderList.filter(i => i.order_type === 'offer');
		// if (this.orderStatus) {
		// 	offerList = offerList.filter(offer => offer.header.status.toLowerCase() == this.orderStatus.toLowerCase());
		// }
		if (this.idOpportunity) {
			const opportunityCodeItem = this.idOpportunity.replace('opportunity' + ConfigurationCustomerAppStructure.noSqlDocSeparator, '');
			// se sono nel contesto specifico filtro solo le offerte di questa opportunity
			this.orderList = orderList.filter(offer => {
				if (offer.header && offer.header.custom_field && offer.header.custom_field.opportunity) {
					return offer.header.custom_field.opportunity.code_item === opportunityCodeItem;
				} else {
					return false;
				}
			});
		} else if (this.idOrganization) {
			this.orderList = orderList.filter(offer => {
				if (offer.header && offer.header.organization) {
					return offer.header.organization.code_item === this.idOrganization;
				} else {
					return false;
				}
			});
		} else {
			this.orderList = orderList;
		}
		this.updateListPageBaseData(this.orderList);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: TableOrderModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	createNew() {
		// :idOrganization/:idOpportunity/:idOffer
		this.router.navigate([
			ROUTE_URL.private,
			ROUTE_URL.offer,
			// OrderStatusEnum.DRAFT.toLowerCase(),
			this.utilService.returnDocumentId(
				PouchDbModel.PouchDbDocumentType.ORGANIZATION,
				this.opportunity.organization.code_item
			),
			this.idOpportunity,
			ROUTE_URL.newOffer
		]);
	}

	goToOfferDetail(offer: OfferStateModel) {
		if (offer && offer.header.custom_field.opportunity) {
			this.router.navigate([
				ROUTE_URL.private,
				ROUTE_URL.offer,
				// this.orderStatus ? this.orderStatus.toLowerCase() : OrderStatusEnum.DRAFT.toLowerCase(),
				this.utilService.returnDocumentId(
					PouchDbModel.PouchDbDocumentType.ORGANIZATION,
					offer.header.organization.code_item
				),
				this.utilService.returnDocumentId(
					PouchDbModel.PouchDbDocumentType.OPPORTUNITY,
					offer.header.custom_field.opportunity.code_item
				),
				offer._id
			]);
		} else {
			console.error('goToOfferDetail: offer non presente');
		}
	}

	goToOpportunity(code: string) {
		this.router.navigate([
			`${ROUTE_URL.private}/${ROUTE_URL.opportunity}/${ROUTE_URL.opportunity}/${code}/${ROUTE_URL.opportunityDetail}`
		]);
		//this.utilOpportunityService.navigateToOpportunityDetail(level, code, null);
	}

	/**
	 * Sidenav filter
	 */

	createFilterForm() {
		this.filterForm = this.fb.group({
			// order.header.date
			dateCreation: this.fb.group(
				{
					start: [''],
					end: ['']
				},
				{
					validators: [FormValidatorCustom.allEmptyOrAllFilledByObject]
				}
			),
			// order.header.first_evasion_date
			dateExpiration: this.fb.group(
				{
					start: [''],
					end: ['']
				},
				{
					validators: [FormValidatorCustom.allEmptyOrAllFilledByObject]
				}
			)
		});
	}

	applyFilterForm() {
		let filteredList = _.cloneDeep(this.orderList);
		if (this.filterForm.valid) {
			if (filteredList && filteredList.length && this.filterForm) {
				// order.header.date
				if (this.filterForm.value.dateCreation) {
					if (this.filterForm.value.dateCreation.start) {
						const filterDate: DateFilterModel = {
							begin: this.filterForm.value.dateCreation.start.valueOf()
						};
						filteredList = filteredList.filter(order => order.header.date >= +filterDate.begin);
					}
					if (this.filterForm.value.dateCreation.end) {
						const filterDate: DateFilterModel = {
							end: this.filterForm.value.dateCreation.end.add(1, 'days').valueOf()
						};
						filteredList = filteredList.filter(order => order.header.date <= +filterDate.end);
					}
				}
				// order.header.first_evasion_date
				if (this.filterForm.value.dateExpiration) {
					if (this.filterForm.value.dateExpiration.start) {
						const filterDate: DateFilterModel = {
							begin: this.filterForm.value.dateExpiration.start.valueOf()
						};
						filteredList = filteredList.filter(
							order => order.header.first_evasion_date >= +filterDate.begin
						);
					}
					if (this.filterForm.value.dateExpiration.end) {
						const filterDate: DateFilterModel = {
							end: this.filterForm.value.dateExpiration.end.add(1, 'days').valueOf()
						};
						filteredList = filteredList.filter(order => order.header.first_evasion_date <= +filterDate.end);
					}
				}
			}
			// Update list
			this.offerListWrapperComponent.updateListPageBaseData(filteredList);
		}
	}

	resetFilterForm() {
		this.filterForm.patchValue({
			// order.header.date
			dateCreation: {
				start: '',
				end: ''
			},
			// order.header.first_evasion_date
			dateExpiration: {
				start: '',
				end: ''
			}
		});
		this.applyFilterForm();
	}

	setRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('offers');
			this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['offers']);

		}
	}

	unsetRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		}
	}
}
