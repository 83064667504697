export interface ListStructureModel<D> {
  pagination?: Pagination;
  filters?: Filters;
  data?: D;
}

export interface Pagination {
  page_current: number;
  page_size: number;
  total_element?: number;
}

export interface PouchPaginationModel {
  pagination: Pagination;
  dataSize: number;
}

export interface Filters {
  user_code?: string;
  // filtri tabella azienda
  order_states?: string[];
  company_states?: string[];
  business_name?: string;

  // filtri tabella ordini
  order_status?: string;
  client_code?: string;
  date?: {
    begin: string,
    end: string
  };
  due_date?: {
    begin: string,
    end: string
  };
  tot?: string[];
  order_causal?: string[];

  // filtri tabella prodotti
  prezzo?: string[];
  category?: string;
  desc_prodotto?: string;

  // filtri tabella prodotti (order-detail-checkout)
  article_codes?: string[];

  text?: string;
  types?: string[];
}
