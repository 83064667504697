<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.BOTTOM">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'analytics.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<button mat-icon-button (click)="collapsedCardAnalytics = !collapsedCardAnalytics">
						<mat-icon
							[svgIcon]="collapsedCardAnalytics ? 'custom-visibility_off' : 'custom-visibility'"
							[class.dark-color]="collapsedCardAnalytics"
						>
							{{ collapsedCardAnalytics ? 'visibility_off' : 'visibility' }}
						</mat-icon>
					</button>
					<mat-icon [svgIcon]="'custom-help'"> help_outline </mat-icon>
				</mat-toolbar-row>
				<mat-toolbar-row [class.not-visible]="collapsedCardAnalytics">
					<mat-card-subtitle>{{ 'analytics.by_value' | translate | sentencecase }}</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<div [class.not-visible]="collapsedCardAnalytics">
				<box-trend-info [boxTrendInfoComponentConfig]="trendBoxContent"></box-trend-info>
				<mat-divider class="both-spacing"></mat-divider>
				<statistic-nested-category
					[configuration]="statisticNestedCategoryConfiguration"
				></statistic-nested-category>
			</div>
		</mat-card>
		<!-- TODO: definire se deprecabile -->
		<!-- <mat-card class="michelangelo-theme autoheight transparent mt-20 mt-30">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'report.area' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-icon [svgIcon]="'custom-visibility'"> visibility </mat-icon>
					<mat-icon [svgIcon]="'custom-help'"> help_outline </mat-icon>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>By macroarea</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<statistic-nested-category></statistic-nested-category>
		</mat-card> -->
		<mat-card class="michelangelo-theme mt-20 mt-30 autoheight" #myVar>
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'product.brand.label_plural' | translate | sentencecase }}
					<span class="flex-span"></span>
					<button mat-icon-button (click)="collapsedCardBrand = !collapsedCardBrand">
						<mat-icon
							[svgIcon]="collapsedCardBrand ? 'custom-visibility_off' : 'custom-visibility'"
							[class.dark-color]="collapsedCardBrand"
						>
							{{ collapsedCardBrand ? 'visibility_off' : 'visibility' }}
						</mat-icon>
					</button>
					<mat-icon [svgIcon]="'custom-help'"> help_outline </mat-icon>
				</mat-toolbar-row>
				<mat-toolbar-row [class.not-visible]="collapsedCardBrand">
					<mat-card-subtitle>{{
						'product.brand.filtered_brands' | translate | sentencecase
					}}</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>

			<!-- TODO: definire contenuti -->
			<!-- <closable-panel>
				<ng-container content>
					{{ 'order.value.info_panel' | translate | sentencecase }}
				</ng-container>
			</closable-panel> -->

			<div [class.not-visible]="collapsedCardBrand">
				<chart-wrapper [config]="chartCategoryRootBar.chart" class="mt-30 mt-20 mb-20 mb-30"></chart-wrapper>

				<mat-divider></mat-divider>

				<div *ngIf="chartStatistcSummaryListDoughnut" class="charts-group mb-30 mb-20 mt-40 mt-30">
					<chart-wrapper
						*ngFor="let chart of chartStatistcSummaryListDoughnut"
						[config]="chart.chart"
					></chart-wrapper>
				</div>
			</div>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'Filters'">
			<ng-container main-content>
				<!-- TODO: ripristino o rimozione della selezione del gruppo clienti -->
				<!-- <h3>{{ 'organization.name_plural' | translate | sentencecase }}</h3>
				<form class="michelangelo-theme" *ngIf="statisticGroupList" [formGroup]="formGroupOrganization">
					<mat-radio-group
						*ngIf="statisticGroupList"
						[formControlName]="statisticEnum.FormField.GROUP_ORGANIZATION"
					>
						<ng-container
							*ngFor="
								let group of statisticGroupList
									| filterByString
										: {
												propertyListName: ['groupType'],
												value: statisticGroupEnum.Group.Type.ORGANIZATION
										  }
							"
						>
							<mat-radio-button color="primary" *ngFor="let item of group.data" [value]="item.code_item">
								{{ item.description }}
							</mat-radio-button>
						</ng-container>
					</mat-radio-group>
				</form>
				<mat-divider></mat-divider> -->

				<h3>{{ 'general.comparison_year' | translate | sentencecase }}</h3>
				<form class="michelangelo-theme" [formGroup]="formComparisonYear">
					<mat-radio-group [formControlName]="statisticEnum.FormField.COMPARISON_YEAR">
						<mat-radio-button color="primary" *ngFor="let item of comparisonYearList" [value]="item">
							{{ item }}
						</mat-radio-button>
					</mat-radio-group>
				</form>

				<mat-divider></mat-divider>

				<h3>{{ 'product.brand.label_plural' | translate | sentencecase }}</h3>
				<form class="michelangelo-theme" *ngIf="formFilter" [formGroup]="formFilter">
					<ng-container formGroupName="category">
						<mat-checkbox
							color="primary"
							*ngFor="let category of categoryListFilter"
							[formControlName]="category.code_item"
						>
							{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}
						</mat-checkbox>
					</ng-container>

					<mat-divider></mat-divider>

					<h3>{{ 'general.unit.label' | translate | sentencecase }}</h3>
					<mat-radio-group formControlName="unit">
						<mat-radio-button
							color="primary"
							*ngFor="let item of object.keys(statisticEnum.Unit)"
							[value]="statisticEnum.Unit[item]"
						>
							{{ statisticEnum.Unit[item] | sentencecase }}
						</mat-radio-button>
					</mat-radio-group>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
