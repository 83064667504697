import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	SentencecasePipe,
	UtilColorService,
} from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';

import { StatisticsBaseSerieOrder } from '../../../model/statistics.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { AppUtilService } from '../../util/app-util.service';
import { MetricsDetail } from '../../../model/statistics.model';
import { ChartEnum } from '@saep-ict/angular-spin8-core';

@Injectable()
export class OrderQuantityChartConfigService extends BaseChartClass {
	metrics: MetricsDetail;

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
		this.chart.type = 'line';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.ACCENT);
		this.chart.colors = [{ borderColor: this.colorWhite }];
	}

	// Functions

	createDataSerie() {
		// const orderTrend = this.statistics.orders.order_trend;

		const serieOrderAverageList = [{ key: 'count' }];

		serieOrderAverageList.forEach((serieOrderTrend, index) => {
			const serieTemp: number[] = [];
			this.utilService
				.findLeaf(this.statistics.data, `orders.order_trend.${serieOrderTrend.key}`)
				.forEach((element: StatisticsBaseSerieOrder) => {
					serieTemp.push(element.value);

					// Popolo label solo la prima volta
					if (!index) {
						this.fullLabels.push(element.date);
					}
				});
			this.fullDataSets.push({
				label: serieOrderTrend.key,
				data: serieTemp
			});
		});
	}

	populateChart(rangeDays: number = this.cardDashboardConfigOrdersQuantity.rangeTempList.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];

		this.fullDataSets.forEach(item => {
			let serie = _.cloneDeep(item);
			serie.data = item.data.slice(-rangeDays);
			tempSerie.push(serie);
		});

		this.addData(this.chart.datasets, tempSerie);
		this.addData(this.chart.labels, this.fullLabels.slice(-rangeDays));

		this.getMetrics(rangeDays);
		this.chart = _.cloneDeep(this.chart);
	}

	getMetrics(rangeDays: number) {
		this.user$.pipe(take(1)).subscribe(res => {
			this.statistics$
				.pipe(
					filter(state => !!(state && state.data)),
					take(1)
				)
				.subscribe(res => {
					this.metrics = this.formatMetrics(
						this.utilService.findLeaf(res.data.data, `orders.metrics.daily_order_number.days_${rangeDays}`)
					);
				});
		});
	}

	formatMetrics(data) {
		const metrics = {
			average: data.average < 1 ? +data.average.toFixed(2) : +data.average.toFixed(0),
			trend: data.trend.toFixed(0)
		};
		return metrics;
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {


			label: function(context) {
				let label =
					translateService.instant('order.' + context.dataset.label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += context.parsed.y.toFixed(0);
				return label;
			}


			// label: function (tooltipItem, data) {
			// 	let label = translateService.instant('order.' + data.datasets[tooltipItem.datasetIndex].label) || '';

			// 	if (label) {
			// 		label = sentencecasePipe.transform(label);
			// 		label += ': ';
			// 	}
			// 	label += tooltipItem.yLabel.toFixed(0);
			// 	return label;
			// }
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return {
			stacked: false,
			grid: {
				drawBorder: false,
				display: false,
				borderDash: [4, 4],
				color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
			},
			ticks: {
				display: false,
				// Valore minimo dell'asse
				// min: 0,
				// Scala da usare
				// stepSize: 1000,
				// Sets the offset of the tick labels from the axis
				padding: 8,
				// Font color for tick labels.
				fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
				// callback: this.ticksCallback()
			}
		}
	}

	xAxesStyle() {
		return {
			stacked: false,
			grid: {
				drawBorder: false,
				display: false
			},
			ticks: {
				display: false
			}
		}
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				x: this.xAxesStyle(),
				y: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
