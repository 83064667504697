<loading-overlay [loaderServiceInstance]="loaderService"></loading-overlay>
<div class="layout-ctx-private" >
	<saep-sidebar class="sidebar-wrapper" [class.open]="isSidebarOpen" (isOpenEvent)="receiveIsSidebarOpen($event)"></saep-sidebar>
	<div class="layout-ctx-private-main">
		<ms-toolbar></ms-toolbar>
		<ng-scrollbar
			#scrollbar
            class="main-content"
			[autoWidthDisabled]="true"
			[autoHeightDisabled]="false"
			[style.max-height.px]="mediaReplayService.deviceH - 115"
            [style.marginRight.px]="20"
            [style.marginTop.px]="20"
		>
			<div scrollViewport class="custom-viewport">
				<div class="scroll-content-wrapper" [style.min-height.px]="mediaReplayService.deviceH - 115">
					<router-outlet class="page"></router-outlet>
				</div>
			</div>
		</ng-scrollbar>
		<ps-to-top [scrollbarRef]="scrollbar"></ps-to-top>
	</div>
</div>
