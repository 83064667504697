import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleRecapStockStateModel } from '@saep-ict/angular-spin8-core';

export enum ArticleStockActionEnum {
	LOAD = '[article-stock] Load',
	UPDATE = '[article-stock] Update',
	RESET = '[article-stock] Reset',
	ERROR = '[article-stock] Error'
}

export namespace ArticleStockStateAction {
	export const load = createAction(ArticleStockActionEnum.LOAD);
	export const update = createAction(
		ArticleStockActionEnum.UPDATE,
		props<BaseStateModel<ArticleRecapStockStateModel>>()
	);
	export const reset = createAction(ArticleStockActionEnum.RESET);
	export const error = createAction(ArticleStockActionEnum.ERROR, props<BaseStateModel<any>>());
}
