import { InjectionToken } from '@angular/core';
import { TicketCenterConfigurationModel } from './configuration.model';
import {TranslationUseEnum} from './enum/translation-use-type.enum';

export const APP_CONFIG_TOKEN: InjectionToken<string> = new InjectionToken<
	string
>('APP_CONFIG_TOKEN');

export const CUSTOM_PROVIDE_LOADER: InjectionToken<string> = new InjectionToken<
	string
>('CUSTOM_PROVIDE_LOADER');


export const CUSTOM_TANSLATE_SERVICE: InjectionToken<string> = new InjectionToken<
	string
>('CUSTOM_TANSLATE_SERVICE');

export interface ITicketCenterConfigModel {
  be_url: string;
  suffix?: string;
  translation?: {
    url?: string;
    type?: string;
    default_translation_path?: string;
    use?: TranslationUseEnum,
    fallback_lang?: string;
  };
  customConfiguration?: TicketCenterConfigurationModel;
}

export interface ITicketCenterConfig {
	ticket_center: ITicketCenterConfigModel;
}

export interface ITicketCenterAppConfig {
	tc_config: ITicketCenterConfigModel;
	token: string;

	initializeConfigTicketCenter(arg0: { [key: string]: any }): void;
}
