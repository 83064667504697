import { Validators } from '@angular/forms';
import { Injector } from '@angular/core';
import { OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import {
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum,
	FormControlMultipurposeService,
	FormControlMultipurposeSetDisabled,
	FormValidatorCustom
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { creationFieldMapContextCodeOrganizationBase } from './creation-field-map-context-code-organization-base.const';
import { creationFieldMapContextCodeOrganizationPartialCrmMarketing } from './context-code-organization-partial/creation-field-map-context-code-organization-partial-crmMarketing.const';
import { creationFieldMapOrganizationPartialContactListTypePublic } from './context-code-organization-partial/creation-field-map-context-code-organization-partial-contactList.const';

const injector = Injector.create({
	providers: [{ provide: FormControlMultipurposeService, deps: [] }]
});

const formControlMultipurposeService = injector.get(FormControlMultipurposeService);

export const creationFieldMapContextCodeOrganizationCrm: FormControlMultipurposeModel.Item[] =
  formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
    _.cloneDeep(creationFieldMapContextCodeOrganizationBase), ['destination_list'], true
  )
  .concat([
    {
      name: 'tax_data',
      label: 'organization.field.tax_data',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
      form_control_list: [
        {
          name: 'tax_code',
          label: 'organization.field.tax_code',
          on_form_template: true,
          type:
            FormControlMultipurposeEnum.ItemType.STRING || FormControlMultipurposeEnum.ItemType.NUMBER,
          column_width: 6,
          validator_list: [Validators.minLength(11), Validators.maxLength(16)]
        },
        {
          name: 'vat_number',
          label: 'organization.field.vat_number',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.STRING,
          column_width: 6,
          validator_list: [Validators.minLength(11), Validators.maxLength(11)],
          set_disabled: formValue =>
            FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
              pathToProperty: 'organization_type',
              propertyValue: OrganizationTypeEnum.PRIVATE
            }),
          clear_value_if_disabled: true
        },
        {
          name: 'sdi_code',
          label: 'organization.field.sdi',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.STRING,
          column_width: 6,
          validator_list: [Validators.minLength(6), Validators.maxLength(7)],
          set_disabled: formValue =>
            FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
              pathToProperty: 'organization_type',
              propertyValue: OrganizationTypeEnum.PRIVATE
            })
        },
        {
          name: 'pec',
          label: 'organization.field.pec',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.STRING,
          column_width: 6,
          validator_list: [FormValidatorCustom.email]
        }
      ]
    }
  ])
  .concat(_.cloneDeep(creationFieldMapContextCodeOrganizationPartialCrmMarketing))
  .concat(_.cloneDeep(creationFieldMapOrganizationPartialContactListTypePublic));
