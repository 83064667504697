import {
	ContextApplicationItemCodeEnum,
	InformativePageColumnMap
} from '@saep-ict/angular-spin8-core';

export const columnList = (contextApplication: ContextApplicationItemCodeEnum) => {
    switch (contextApplication) {
        default:
            return InformativePageColumnMap.base;
    }
};