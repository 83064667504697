import { Inject, Injectable, Optional } from '@angular/core';
import { APP_CONFIG_TOKEN, ISaepIctAngularCoreAppConfig } from '../../model/structure/lib-app-config.interface';
import { Store } from '@ngrx/store';
import { ILoaderService, LOADER_SERVICE_TOKEN } from '../../interface/loader-service.interface';
import { BaseApiService } from './baseApi.service';
import { CustomHeadersHandlerService } from '../structure/custom-headers-handler.service';

@Injectable()
export class BaseApiBucketManagerService extends BaseApiService {
	constructor(
		@Optional() @Inject(LOADER_SERVICE_TOKEN) protected loaderService: ILoaderService,
		@Inject(APP_CONFIG_TOKEN) protected appConfig: ISaepIctAngularCoreAppConfig,
    public store: Store<any>,
    public customHeadersHandlerService: CustomHeadersHandlerService,
	) {
		super(loaderService, appConfig, store, customHeadersHandlerService);
    this.appConfig['config$'].subscribe(config => {
			if (config && config.bucketManager && config.bucketManager.be_url) {
        super.$setPathPrefix(config.bucketManager.be_url);
			}
		});
	}

	protected setPathPrefix(pathPrefix: string) {
		super.$setPathPrefix(pathPrefix);
	}

}
