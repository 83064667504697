import { ArticleChangeInputComponent } from '../widget/article/article-change-input/article-change-input.component';
import { AvatarConfigClass } from '../widget/avatar/avatar.component';

export enum SourceType {
	APPLICATION = 'APPLICATION'
}

// organization

export class OrganizationHeaderConfig {
	avatar: AvatarConfigClass;
	informationList: {
		label: string;
		value?: string;
		icon: string;
		order: number;
	}[];
}

export interface ArticleChangeInputSelector {
	selected: ArticleChangeInputComponent;
	list: ArticleChangeInputComponent[]
}

export interface ViewAllButtonConfiguration {
	display: boolean;
	route?: string | any[];
	label?: string;
}