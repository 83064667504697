export class TranslationValueException extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TranslationValueException';
  }
}

export class TranslationConfigException extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TranslationConfigException';
  }
}
