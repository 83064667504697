import { Component, Input, OnInit } from '@angular/core';
import { FaqConfigurationModel, FaqModel } from '../../model/faq.model';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    @Input('configuration') set configuration(e: FaqConfigurationModel) {
        if (e && e.section) {
            const currentLang = this.utilTranslateService.translate.currentLang;
            this._configuration = e;
            this.utilTranslateService.translate.get('faq').subscribe(() => {
                let faqCommonList = [];
                let faqThemeList = [];
                if (this.utilTranslateService.translate.store.translations[currentLang]) {
                    if (
                        !e.hideCommon &&
                        this.utilTranslateService.translate.store.translations[currentLang].faq &&
                        this.utilTranslateService.translate.store.translations[currentLang].faq[e.section]
                    ) {
                        faqCommonList = this.utilTranslateService.translate.store.translations[currentLang].faq[e.section];
                    }
                    if (
                        this.utilTranslateService.translate.store.translations[currentLang].theme &&
                        this.utilTranslateService.translate.store.translations[currentLang].theme.faq &&
                        this.utilTranslateService.translate.store.translations[currentLang].theme.faq[e.section]
                    ) {
                        faqThemeList = this.utilTranslateService.translate.store.translations[currentLang].theme.faq[e.section];
                    }
                }
                for (const faqKey of Object.keys(faqCommonList)) {
                    this.faqCommon.push({ key: faqKey });
                }
                for (const faqKey of Object.keys(faqThemeList)) {
                    this.faqTheme.push({ key: faqKey });
                }
            });
        }
    }
    _configuration: FaqConfigurationModel;

    faqCommon: FaqModel[] = [];
    faqTheme: FaqModel[] = [];

    constructor(
        private utilTranslateService: AngularSpin8CoreUtilTranslateService
    ) { }

    ngOnInit(): void {
    }

}
