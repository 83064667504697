import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ActionPouchModel } from '../../model/state/action-state.model';
import { ActionActionEnum, ActionStateAction } from './action.actions';

export namespace ActionStateReducer {
	export const initialState: BaseStateModel<ActionPouchModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(ActionStateAction.load, state => state),
		on(ActionStateAction.loadAll, state => state),
		on(ActionStateAction.update, (state, action) => action),
		on(ActionStateAction.save, state => state),
		on(ActionStateAction.remove, (state, action) => ({
			data: state ? state.data : null,
			type: action.type
		})),
		on(ActionStateAction.removed, (state, action) => state),
		on(ActionStateAction.reset, () => initialState),
		on(ActionStateAction.error, (state, action) => ({
			data: state ? state.data : null,
			type: action.type
		}))
	);

	export function reducer(state: BaseStateModel<ActionPouchModel[]>, action: TypedAction<ActionActionEnum>) {
		return _reducer(state, action);
	}
}
