<mat-dialog-wrapper
	[title]="
		data['contestoDialog'] == 'organization' && data['type'] != 'edit'
			? ('organization.add_new' | translate | sentencecase)
			: (((formControlConfigurationEdit &&
			  formControlConfigurationEdit.value &&
			  formControlConfigurationEdit.value._id
					? 'general.edit'
					: 'general.add_new'
			  ) | translate) +
					' ' +
					(data.description | translate) | titlecase)
	"
	[showCloseIcon]="false"
>
	<ng-container content>
		<context-code-edit
			*ngIf="formControlConfigurationEdit"
			[configuration]="formControlConfigurationEdit"
			(onFormValueChange)="contextCodeEditOnChange($event)"
		></context-code-edit>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.save' | translate | sentencecase"
			[primaryButtonIsDisabled]="!formIsValid"
			(primaryButtonClickEmitter)="onFormSubmit()"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="contextCodeEditOnCancel()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
