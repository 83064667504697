import { PipeTransform, Pipe } from '@angular/core';
import { PipeFilterOption } from '../model/pipe/pipe-filter.model';

@Pipe({
	name: 'filterByAllTheBooleans'
})
export class FilterByAllTheBooleansPipe implements PipeTransform {
	transform(items: any[], filterOption: PipeFilterOption) {
		if (!items || !filterOption || !filterOption.propertyListName.length) {
			return items;
		}
		return items.filter(item => {
			let found = false;
			for (let i = 0; i < filterOption.propertyListName.length; i++) {
				const propertyName = filterOption.propertyListName[i];
				if (item.hasOwnProperty(propertyName) && item[propertyName] === filterOption.value) {
					found = true;
				} else {
					found = false;
					break;
				}
			}
			return found;
		});
	}
}
