<section>
	<ng-container *ngFor="let item of boxTrendInfoComponentConfig; let last = last">
		<div class="item">
			<div class="main">
				<div class="value-wrapper">
					<span class="value">{{ item.value }}</span>
					<span *ngIf="item.suffix" class="suffix">{{ item.suffix | translate }}</span>
				</div>
				<!-- <div *ngIf="(item.trendDirection !== trendDirectionValues.NONE)" class="icon-wrapper">

                </div> -->
				<mat-icon
					*ngIf="item.trendDirection !== trendDirectionValues.NONE"
					[svgIcon]="'custom-trend-' + item.trendDirection"
					[ngClass]="item.trendDirection"
				></mat-icon>
			</div>
			<div class="info" *ngIf="item.title || item.subtitle">
				<span *ngIf="item.title" class="title">{{ item.title | translate }}</span>
				<span *ngIf="item.subtitle" class="subtitle">{{ item.subtitle | translate }}</span>
			</div>
		</div>
	</ng-container>
</section>
