import { NgModule } from '@angular/core';

// model
import { ContextCodeManagementStateReducer } from './context-code-management.reducer';

// store
import { StoreModule } from '@ngrx/store';

@NgModule({
	imports: [StoreModule.forFeature('context-code-management', ContextCodeManagementStateReducer.reducer)]
})
export class ContextCodeManagementStateModule {}
