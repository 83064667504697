import { Subscription } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketActionEnum, WebSocketChannelTypeCodeEnum, WebSocketConnectionStateEnum, WebSocketProcessStateEnum, WebSocketTopicStateEnum } from '../enum/websocket.enum';

export interface WebSocketGetConnectionResponseModel {
    ws_path: string;
}

export interface WebSocketMessageModel<REQUEST_BODY_TYPE = never, RESPONSE_TYPE = never> {
    type: WebSocketChannelTypeCodeEnum;
    message: {
        header: {
            id: string;
            action: WebSocketActionEnum,
            state: WebSocketProcessStateEnum | WebSocketConnectionStateEnum | WebSocketTopicStateEnum,
            description?: string,
            progress: number,
            requestBody?: REQUEST_BODY_TYPE
        };
        data?: RESPONSE_TYPE;
    };
}

export interface WebSocketMessageDownloadArchiveRequest {
    path_list: string[];
}

export interface WebSocketChannel<REQUEST_BODY_TYPE, RESPONSE_TYPE> {
    id: string;
    type: WebSocketChannelTypeCodeEnum;
    webSocket$?: WebSocketSubject<any>;
    rxStompSubscribe$?: Subscription;
    processList: WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE>[];
}

export type WebSocketMessageEventHandle = {
  [key in WebSocketTopicStateEnum]?: Function
}
