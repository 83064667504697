import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'


@Pipe({
	name: 'dateAsAgo'
})
export class DateAsAgoPipe implements PipeTransform {

	constructor(private utilTranslateService: TranslateService) {}

	transform(value: any): string {
		if (!value) {
			return this.utilTranslateService.instant('pipe.date_as_ago.a_long_time_ago')
		}

		let time = (Date.now() - value) / 1000;

		if (time < 10) {
			return this.utilTranslateService.instant('pipe.date_as_ago.just_now');
		} else if (time < 60) {
			return this.utilTranslateService.instant('pipe.date_as_ago.a_moment_ago');
		}

		const divider = [60, 60, 24, 30, 12];
		const string = ['second', 'minute', 'hour', 'day', 'month', 'year'];

		let i;
		for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
			time /= divider[i];
		}
		const plural = Math.floor(time) > 1 ? 's' : '';

		return Math.floor(time) + " " + this.utilTranslateService.instant(`pipe.date_as_ago.${string[i]}${plural}`) + " " + this.utilTranslateService.instant('pipe.date_as_ago.ago');


		//return Math.floor(time) + this.utilTranslateService.translate.instant('pipe.date_as_ago.' + string[i] + plural) + this.utilTranslateService.translate.instant('pipe.date_as_ago.ago');
	}
}
