import {
	ContextApplicationItemCodeEnum,
	ROUTE_URL
} from '@saep-ict/angular-spin8-core';
import { NewsModel } from '../../../model/news.model';
import { NewsStatusEnum } from '../../../enum/news.enum';
import moment from 'moment';

export const canCreate = {
    detail: {
        AGENT: false,
        B2B: false,
        B2C: false,
        BACKOFFICE_ADMIN: true,
        BACKOFFICE: true,
        CRM: false,
        PORTAL: false,
        PUBLIC: false
    }
};
export const showLastN = 3;
export const listFilter = (list: NewsModel[], contextApplication: ContextApplicationItemCodeEnum): NewsModel[] => {
    switch (contextApplication) {
        case ContextApplicationItemCodeEnum.BACKOFFICE:
        case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN: {
            return list;
        }
        default: {
            const timestampNow = moment().valueOf();
            return list.filter(item => item.header.published && item.header.date_publication < timestampNow);
        }
    }
};
export const returnListFilteredByTab = (
    contextApplication: ContextApplicationItemCodeEnum,
    tab: string,
    list: NewsModel[]
): NewsModel[] => {
    list = listFilter(list, contextApplication);
    switch (contextApplication) {
        case ContextApplicationItemCodeEnum.BACKOFFICE:
        case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN: {
            switch (tab) {
                case ROUTE_URL.allNews:
                    return list;
                case ROUTE_URL.new:
                    return list.filter(item => item.header.status === NewsStatusEnum.NEW);
                case ROUTE_URL.archive:
                    return list.filter(item => item.header.status === NewsStatusEnum.ARCHIVE);
            }
            break;
        }
        default: {
            switch (tab) {
                case ROUTE_URL.allNews:
                    return list.filter(item => item.header.status === NewsStatusEnum.NEW);
                case ROUTE_URL.new:
                    return list
                        .filter(item => item.header.status === NewsStatusEnum.NEW)
                        .sort((a, b) => b.date_update - a.date_update)
                        .slice(0, showLastN);
            }
        }
    }
};