import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormatMultiplesCurrency, SentencecasePipe, UtilColorService } from '@saep-ict/angular-core';
import _ from 'lodash';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { AppUtilService } from '../../util/app-util.service';
import { ChartEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class FulfilledQuartersChartConfigService extends BaseChartClass {
	quarterList;
	quarters;

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		private formatMultiplesCurrency: FormatMultiplesCurrency,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
		this.chart.type = 'bar';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	createDataSerie(){
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			this.quarters = this.utilService.findLeaf(this.statistics.data, 'quarters');
		} else {
			this.quarters = this.utilService.findLeaf(this.statistics.data, 'fulfilled.quarters');
		}
	}

	populateQuarterList() {
		this.quarterList = this.getQuarterList(this.quarters);
	}

	// Functions

	populateChart(quarter: string = this.quarterList[0].q) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let serieTemp = [];
		let labelTemp = [];

		let selectedQuarter = this.quarters.find(item => item.name === quarter);

		selectedQuarter.months.forEach(month => {
			serieTemp.push(month.total);
			labelTemp.push('general.time.month.' + month.month.toString());
		});

		let fullDataSet = [];
		fullDataSet.push({
			label: 'fulfilled.total',
			backgroundColor: this.colorPrimary,
			barPercentage: 0.2,
			data: serieTemp
		});

		this.fulfilledTotal = selectedQuarter.total;
		this.fulfilledCount = selectedQuarter.count;
		this.fulfilledAverage = selectedQuarter.average;

		this.addData(this.chart.datasets, fullDataSet);
		this.addData(this.chart.labels, labelTemp);
		this.chart = _.cloneDeep(this.chart);
	}

	// Style

	tooltipsCallbacks(translateService: TranslateService, sentencecasePipe, formatMultiplesCurrency) {
		return {
			title: function (tooltipItems, data) {
				return translateService.instant(translateService.instant(tooltipItems[0].xLabel)) || '';
			},


			label: function(context) {
				let label =
					translateService.instant(context.dataset.label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += formatMultiplesCurrency.transform(+context.raw);
				return label;
			}


			// label: function (tooltipItem, data) {
			// 	let label = translateService.instant(data.datasets[tooltipItem.datasetIndex].label) || '';
			// 	if (label) {
			// 		label = sentencecasePipe.transform(label);
			// 		label += ': ';
			// 	}
			// 	label += formatMultiplesCurrency.transform(+tooltipItem.value);
			// 	return label;
			// }
		};
	}

	xAxesTicksCallback(translateService: TranslateService, sentencecasePipe) {
		return function (label, index, labels): string {
			return translateService.instant(labels[index]);
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return {
			stacked: false,
			grid: {
				drawBorder: false,
				display: true,
				borderDash: [4, 4],
				color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
			},
			ticks: {
				display: true,
				// Valore minimo dell'asse
				min: 0,
				// Scala da usare
				stepSize: 1000,
				// Sets the offset of the tick labels from the axis
				padding: 8,
				// Font color for tick labels.
				fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
			}
		}
	}

	xAxesStyle() {
		return {
			stacked: false,
			grid: {
				display: false
			},
			ticks: {
				display: true,
				// Valore minimo dell'asse
				min: 0,
				// Scala da usare
				stepSize: 1,
				callback: this.xAxesTicksCallback(this.translateService, this.sentencecasePipe)
			}
		}
}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(
				this.translateService,
				this.sentencecasePipe,
				this.formatMultiplesCurrency
			)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				x: this.xAxesStyle(),
				y: this.yAxesStyle(theme)
			},
			aspectRatio: 4
		};
	}
}
