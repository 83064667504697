import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseState, RestBasePk } from '@saep-ict/angular-core';

import { UserStateAction } from '../../state/user/user.actions';
import { AuthService } from '../rest/auth.service';
import jwt_decode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { TokenPayload, AngularSpin8CoreUserService } from '@saep-ict/angular-spin8-core';

@Injectable()
export class UserResolver implements Resolve<void> {
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	constructor(
		private userService: AngularSpin8CoreUserService,
		private authService: AuthService,
		private store: Store<any>
	) {}

	async resolve() {
		if (this.authenticationToken) {
			const tk_decoded = jwt_decode(this.authenticationToken);
			this.authService.tokenPayload = new TokenPayload(tk_decoded);
			const userDetailRequest: RestBasePk = { id: this.authService.tokenPayload.user_id };
			const user = (await this.userService.getUserDetail(userDetailRequest)).data;
			this.store.dispatch(UserStateAction.update(new BaseState(user)));
		}
	}
}
