import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import * as StatisticGroupModel from '../../../model/statistics-group.model';

export enum StatisticGroupActionEnum {
	LOAD_ALL = '[statistic-group] Load All',
	UPDATE = '[statistic-group] Update',
	RESET = '[statistic-group] Reset',
	ERROR = '[statistic-group] Error'
}

export namespace StatisticGroupStateAction {
	export const loadAll = createAction(StatisticGroupActionEnum.LOAD_ALL, props<StatisticGroupModel.LoadAllParam>());
	export const update = createAction(
		StatisticGroupActionEnum.UPDATE,
		props<BaseStateModel<StatisticGroupModel.Document[]>>()
	);
	export const reset = createAction(StatisticGroupActionEnum.RESET);
	export const error = createAction(StatisticGroupActionEnum.ERROR, props<BaseStateModel<any>>());
}
