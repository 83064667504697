import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import moment from 'moment';
import { BrandItemModel, SurveyModel } from '../../../../../model/survey-center/survey.model';
import { Pagination, Filters } from '../../../../../model/structure/AbstractListModel';
import { ThreadItemModel } from '../../../../../model/survey-center/chat.model';

export class SurveyCenterPouch extends AbstractPouchdbMethod {
	filteredOptions: any = {};

	getSurveyFilteredList(body: {
		surveyType: string;
		client_code?: string[];
		pagination: Pagination;
		filters: Filters;
		sort?: any[];
	}): Promise<any> {
		return new Promise(resolve => {

			this.filteredOptions = {
				type: 'survey'
			};

			if (body.client_code && body.client_code.length > 0) {
				this.filteredOptions.client_code = {
					$or: [
						{
							$in: body.client_code
						}
					]
				};
			}

			if (body.surveyType) {
				this.filteredOptions.survey_type = body.surveyType;
			}

			if (body.filters.commercialAreas && body.filters.commercialAreas.length) {
				this.filteredOptions.commercial_area = {
					codiceElemento: {
						$or: [
							{
								$in: body.filters.commercialAreas || []
							}
						]
					}
				};
			}

			if (body.filters.years && body.filters.years.length) {
				this.filteredOptions.year = {
					$or: [
						{
							$in: body.filters.years || []
						}
					]
				};
			}

			if (
				body.filters.brandList &&
				body.filters.brandList.length &&
				body.filters.brandApprovationStatusList &&
				body.filters.brandApprovationStatusList.length
			) {
				const brandList = {
					$or: []
				};

				body.filters.brandList.forEach(i => {
					brandList.$or.push({
						$elemMatch: {
							brand: {
								codice_brand: i
							},
							status: {
								$in: body.filters.brandApprovationStatusList
							}
						}
					});
				});

				this.filteredOptions.brand_list = brandList;
			}

			if (body.filters.text) {
				this.filteredOptions.$or = [
					{
						client_code: {
							$regex: `(?i)${body.filters.text}`
						}
					},
					{
						business_name: {
							$regex: `(?i)${body.filters.text}`
						}
					}
				];
			}
			// sort
			let sort = [];
			if (body.sort) {
				const sortItem = {};
				sortItem[body.sort[0].name] = body.sort[0].order;
				sort = [sortItem];
			}

			this.getWithIndexFilter('commercial_area', this.filteredOptions, body.pagination, sort)
				.then((res: any) => {
					const surveys = res['docs'];
					surveys.sort((a, b) => {
						return a.id - b.id;
					});
					resolve({
						data: surveys,
						total_element: surveys.length
					});
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getSurveyDetail(body: { idSurvey: string }): Promise<SurveyModel> {
		return new Promise(resolve => {
			this.get(body.idSurvey)
				.then((doc: SurveyModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	updateSurvey(e: SurveyModel): Promise<void> {
		return new Promise(async resolve => {
			try {
				await this.put(e, e._id);
				resolve();
			} catch(err) {
				console.log(err);
				throw new Error(err);
			}
		});
	}

	updateSurveyApproval(body: { idSurvey: string; brand: BrandItemModel }): Promise<any> {
		return new Promise(resolve => {
			this.get(body.idSurvey)
				.then((doc: any) => {
					doc['brand_list'].find(bl => bl.brand.codice_brand === body.brand.brand.codice_brand).status =
						body.brand.status;
					return this.put(doc, body.idSurvey);
				})
				.then(() => {
					resolve(null);
				})
				.catch(error => {
					console.log(error);
				});
		});
	}

	getSurveyChatDetail(body: { idSurvey: string; pagination?: Pagination }): Promise<ThreadItemModel[]> {
		return new Promise(resolve => {
			const filters = {
				survey_id: body.idSurvey
			};

			this.getWithIndexFilter('commercial_area', filters, null, [])
				.then((res: any) => {
					const chat = res['docs'];
					chat.sort((a, b) => {
						return moment(parseInt(a.date_sending, null)).diff(moment(parseInt(b.date_sending, null)));
					});
					resolve(chat);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	updateChatDetail(body: { idSurvey: string; threadItem: ThreadItemModel }): Promise<any> {
		return new Promise(resolve => {
			body.threadItem.survey_id = body.idSurvey;
			let isGuid = false;
			let id = body.threadItem._id;
			if (!body.threadItem._id) {
				isGuid = true;
				id = 'chat';
			}
			this.put(body.threadItem, body.idSurvey, isGuid)
				.then(response => {
					this.getSurveyChatDetail({ idSurvey: body.idSurvey }).then(res => {
						resolve(res);
					});
				})
				.catch(error => {
					console.log(error);
				});
		});
	}
}
