import { BaseState, BaseStateModel, DataSetting } from '@saep-ict/angular-core';

export interface AdaptiveLoadingStateModel<T> extends BaseStateModel<T> {
	loadAll?: boolean;
}

export class AdaptiveLoadingState<T, F = never> extends BaseState<T, F> {
	data: T;
	type?: string;
	dataSetting?: DataSetting<F>;
	loadAll?: boolean;
	constructor(innerData?: T, dataSetting?: DataSetting<F>, loadAll?: boolean) {
		super(innerData, dataSetting);
		this.loadAll = loadAll === undefined ? true : loadAll;
	}
}
