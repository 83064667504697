import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { StatusBarOrderService } from '../../service/status-bar-config/implementation/status-bar-order.service';
import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { PageCommonsModule } from '../commons/page-commons.module';
import { DashboardAgentComponent } from './dashboard/dashboard-agent/dashboard-agent.component';
import { UserComponent } from '../commons/user/user.component';
import { DashboardAgentOrdersComponent } from './dashboard/dashboard-agent-orders/dashboard-agent-orders.component';
import { DashboardAgentClientsComponent } from './dashboard/dashboard-agent-clients/dashboard-agent-clients.component';
import { DashboardAgentSoldComponent } from './dashboard/dashboard-agent-sold/dashboard-agent-sold.component';
import { DashboardAgentExpiredComponent } from './dashboard/dashboard-agent-expired/dashboard-agent-expired.component';

@NgModule({
    imports: [WidgetModule, SharedModule, PageCommonsModule],
    declarations: [
        DashboardAgentComponent,
        UserComponent,
        DashboardAgentOrdersComponent,
        DashboardAgentClientsComponent,
        DashboardAgentSoldComponent,
        DashboardAgentExpiredComponent
    ],
    exports: [
        DashboardAgentComponent,
        UserComponent,
        DashboardAgentOrdersComponent,
        DashboardAgentClientsComponent,
        DashboardAgentSoldComponent,
        DashboardAgentExpiredComponent
    ],
    providers: [StatusBarOrderService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageAgentModule {}
