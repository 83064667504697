<page-with-sidebar [isRoot]="
		user.current_permission.context_application === contextApplicationItemCodeEnum.B2B || !organization
	" [sideBarPosition]="sideBarPositionValues.TOP">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<!-- TODO: verificare funzionamento di `selectOrganization` su context application diverso da B2B -->
			<receipt-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(selectOrganization)="
					utilCompanyService.navigateToCompanyDetail(
						$event.level,
						$event.code,
						user.current_permission.context_application
					)
				"
				(selectActionDialog)="dialogOpenAttachmentList($event)"
			>
			</receipt-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'general.filters'">
			<ng-container main-content>
				<form-dynamic
					[configuration]="formDynamicConfiguration"
					(valueChange)="onFormDynamicValueChange($event)"
				></form-dynamic>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
