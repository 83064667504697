import { Injector } from '@angular/core';
import { RxStompConfig } from '@stomp/rx-stomp';

import { Versions } from '@stomp/stompjs';
import { RxStompService } from './rx-stomp.service';
import { IAttachmentAppConfig } from 'libs/angular-core/src/interface/attachment-app-config.interface';
import { IAttachmentCenterModelOptions } from 'libs/angular-core/src/model/attachment-options.model';
import { AngularCoreUtilService } from 'libs/angular-core/src/service/util/util.service';

const injector = Injector.create({
	providers: [
		{ provide: AngularCoreUtilService, deps: [] }
	]
});
const utilService = injector.get(AngularCoreUtilService);

const returnRxStompServiceFactory = (appConfig: IAttachmentAppConfig): RxStompService => {
  const rxStomp = new RxStompService(utilService);
  const rxStompConfiguration: RxStompConfig = appConfig.mc_config.rxStomp.configuration;
  rxStompConfiguration.stompVersions = new Versions(['1.0']),
  rxStompConfiguration.debug = (msg: string) => {
    // console.log(msg);
  };
  rxStomp.configure(rxStompConfiguration);
  rxStomp.activate();
  return rxStomp;
};

export const returnRxStompServiceProviderList = (opt: IAttachmentCenterModelOptions): any[] => {
  return [
    RxStompService,
    {
      provide: RxStompService,
      deps: [opt.appConfig],
      useFactory: returnRxStompServiceFactory
    }
  ]
}
