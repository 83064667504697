import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ActionPouchModel } from '../../model/state/action-state.model';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { AdaptiveLoadingStateModel } from '../../model/state/conditional-loading-state.model';

export enum ActionActionEnum {
	UPDATE = '[action] Update',
	LOAD = '[action] Load',
	LOAD_ALL = '[action] Load all',
	SAVE = '[action] Save',
	REMOVE = '[action] Remove',
	REMOVED = '[action] Removed',
	RESET = '[action] Reset',
	ERROR = '[action] Error'
}

export namespace ActionStateAction {
	export const load = createAction(ActionActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const loadAll = createAction(ActionActionEnum.LOAD_ALL);
	export const update = createAction(ActionActionEnum.UPDATE, props<BaseStateModel<ActionPouchModel[]>>());
	export const save = createAction(ActionActionEnum.SAVE, props<AdaptiveLoadingStateModel<ActionPouchModel[]>>());
	export const remove = createAction(ActionActionEnum.REMOVE, props<BaseStateModel<ActionPouchModel[]>>());
	export const removed = createAction(ActionActionEnum.REMOVED);
	export const reset = createAction(ActionActionEnum.RESET);
	export const error = createAction(ActionActionEnum.ERROR, props<BaseStateModel<any>>());
}
