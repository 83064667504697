<ng-container *ngIf="localListHandlerData">
	<mat-toolbar class="search-and-pagination" *ngIf="localListHandlerTextFilterShow">
		<mat-toolbar-row
			*ngIf="
				(localListHandlerData.filters && localListHandlerData.filters.localSearchText) ||
				localListHandlerSidebarFilterShow
			"
			class="____mt-30"
		>
			<span class="flex-span"></span>
			<td-search-box
				*ngIf="localListHandlerData.filters && localListHandlerData.filters.localSearchText"
				backIcon="search"
				[(ngModel)]="localListHandlerData.filters.localSearchText.value"
				[showUnderline]="true"
				[debounce]="500"
				[alwaysVisible]="true"
				(searchDebounce)="localListHandlerApplyFilter()"
				[placeholder]="'general.search' | translate | sentencecase"
			>
			</td-search-box>
			<button
				*ngIf="localListHandlerSidebarFilterShow"
				mat-icon-button
				(click)="localListHandlerOpenSidebarFilter.emit()"
			>
				<mat-icon>filter_list</mat-icon>
			</button>
		</mat-toolbar-row>
		<ng-container *ngTemplateOutlet="paginator"></ng-container>
	</mat-toolbar>
	<ng-container *ngIf="localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0; else noData">
		<article-table-wrapper
			*ngIf="
				localListHandlerData.dataSubset &&
				localListHandlerData.dataSubset.length > 0 &&
				virtualScrollConfiguration;
				else noData
			"
			[configuration]="
				{
					localListHandlerData: {
						data: [],
						dataSubset: localListHandlerData.dataSubset,
						filters: localListHandlerData.filters,
						languageKey: localListHandlerData.languageKey,
						pageName: localListHandlerData.pageName,
						pagination: localListHandlerData.pagination,
						sort: localListHandlerData.sort,
						sortRemapObject: localListHandlerData.sortRemapObject,
						columnList: localListHandlerData.columnList
					},
					order: order,
					organization: organization,
					configurationAction: configurationAction,
					onItemSelectShow: onItemSelectShow,
					virtualScrollConfiguration: virtualScrollConfiguration
				}
			"
			(onArticleChange)="onArticleChangeHandler($event)"
			(onItemSelect)="onItemSelect.emit($event)"
			(onDeleteArticle)="onDeleteArticle.emit($event)"
		>
		</article-table-wrapper>
		<ng-container *ngTemplateOutlet="paginator"></ng-container>
	</ng-container>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>

<ng-template #paginator>
	<mat-toolbar-row *ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow" >
		<span class="flex-span"></span>
		<mat-paginator
			#matPaginator
			(page)="localListHandlerApplyFilter({ pagination: $event })"
			[length]="localListHandlerData.pagination.totalFilteredItemCount"
			[pageSize]="localListHandlerData.pagination.pageSize"
			[pageSizeOptions]="[5, 10, 25, 100]"
		>
		</mat-paginator>
	</mat-toolbar-row>
</ng-template>
