export const company_variables = {
	title: 'EUROITALIA S.R.L.',
	copyright_owner: 'EUROITALIA S.R.L. ',
	logo_path: {
		header: 'assets/theme-current/img/company-logo/logo.png',
		footer: 'assets/common/img/email/logo-spin8-generic.png'
	},
	color: {
		primary: '#66CC33',
		accent: '#FF6600'
	},
	company: {
		address: ['Via G.Galilei 5, 20873 Cavenago di Brianza (MB)', 'Italy'],
		email: 'customerservice@euroitalia.it',
		name: 'EuroItalia S.r.l.',
		phone: '+39 02959161',
		vat: '00788550960'
	}
};
