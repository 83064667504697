import { Injectable } from '@angular/core';
import { CardMainModel } from '../../../model/chart.model';
import { GlobalColorsEnum } from '../../../enum/util/global-colors.enum';
import { AngularCoreUtilService } from '../../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class BarTimeChartConfigurationService {
  constructor(private utilService: AngularCoreUtilService) {}

  // TODO - rendere queste variabili a fattor comune
  colorPrimary = this.utilService.getGlobalColor(GlobalColorsEnum.PRIMARY);
  colorAccent = this.utilService.getGlobalColor(GlobalColorsEnum.ACCENT);
  colorWhite50 = 'rgba(255,255,255,0.5)';
  colorWhite = 'rgba(255,255,255,1)';
  colorBlack30 = 'rgba(0,0,0,0.3)';
  colorBlack = 'rgba(0, 0, 0, 0.87)';

  // TODO - Card Main Model o apexOption?
  default: CardMainModel = {
    chart: {
      type: 'bar',
      height: 200,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
      dashArray: 0,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -25,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    colors: ['#66CC33'],

    xaxis: {
      categories: ['', '', '', '', '', '', ''],
      position: 'bottom',
      labels: {
        offsetY: 0,
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },

    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
      },
    },
    tooltip: {
      enabled: true,
    },
    grid: {
      show: true,
    },
    series: [
      {
        label: 'Series name',
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  };
}
