import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
	BaseStateModel,
	FormDynamicConfigurationModel,
	GuidFormatterPipe,
	SubscribeManagerService,
	attachmentListManagerColumnListBase,
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { LocalListHandlerBaseModel, ArticlePouchModel, AttachedFile, LanguageItem } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import { Language } from '../../../enum/language.enum';
import _ from 'lodash';
import { ArticleDescriptionStateAction, ArticleDescriptionActionEnum } from '../../../state/article-description/article-description.actions';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogArticleSelectComponent } from '../../../widget/dialog/dialog-article-select/dialog-article-select.component';
import {
	DialogConfirmComponent,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	AngularSpin8CoreUtilTranslateService,
	AngularSpin8CoreUtilArticleService,
	ArticleRecap
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import { LanguageAttachmentListManagerConfiguration } from '../../../model/attachment-list-manager.model';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import { LanguageFormDynamicConfigurationModel } from '../../../model/article.model';
import { CustomerAppConfig } from '../../../customer-app.config';
import { AppUtilService } from '../../../service/util/app-util.service';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import { FamilyListActionEnum, FamilyListStateAction } from '../../../state/family-list/family-list.actions';
import { StateFeature } from '../../../state';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';

@Directive({
	selector: '[spin8BackofficeArticleFamilyDetailBaseDirective]'
})
export class BackofficeArticleFamilyDetailBaseDirective<MAIN_ITEM extends ArticlePouchModel> implements OnInit, OnDestroy {

	// articoli ad uso della prop. `mainList.articleDescription.related_article_list`
	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	instanceType: string;

	mainList$: Observable<BaseStateModel<ArticleRecap, never>>;
	mainList: MAIN_ITEM[];
	mainItem: MAIN_ITEM;

	subscribeListBase: SubscribeManagerItem[];
	subscribeListForType: SubscribeManagerItem[];

	localHandlerPrefix = 'local_handler_';
	local_handler_base: LocalListHandlerBaseModel<any> = {
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'articleDescription.language_list.description',
					ConfigurationCustomerAppStructure.erp_has_erp ? 'code_erp' : 'code_item'
				]
			}
		},
		columnList: ConfigurationCustomerArticle.backofficeArticleDetailColumnList,
		sort: {
			name: ConfigurationCustomerAppStructure.erp_has_erp ? 'code_erp' : 'code_item',
			order: TdDataTableSortingOrder.Ascending
		},
		languageKey: this.translateService.currentLang,
		data: []
	}

	local_handler_related_article_list: LocalListHandlerBaseModel<ArticlePouchModel> =
		_.cloneDeep(this.local_handler_base);

	configurationCustomerArticle = ConfigurationCustomerArticle;

	language = Language;

	backPath: string;

	attachmentListManagerConfiguration: LanguageAttachmentListManagerConfiguration;

	formDynamicConfiguration: FormDynamicConfigurationModel;
	formDynamicValue;
	
	formDynamicLanguageListConfiguration: LanguageFormDynamicConfigurationModel;

	mainListActionEnum: typeof ArticleDescriptionActionEnum | typeof FamilyListActionEnum;
	mainListStateAction: typeof ArticleDescriptionStateAction | typeof FamilyListStateAction;

	handleExtenderInit(): Promise<void> {
		return new Promise(resolve => {resolve()})
	}
	handleExtenderSave(itemPayload: any):void {}

	constructor(
		public store: Store,
		public subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public route: ActivatedRoute,
		public router: Router,
		public dialog: MatDialog,
		public articleService: AngularSpin8CoreUtilArticleService,
		public sentencecasePipe: SentencecasePipe,
		public translateService: TranslateService,
		public snackBar: MatSnackBar,
		public utilBreadcrumbService: UtilBreadcrumbService,
		public guidFormatterPipe: GuidFormatterPipe,
		public appConfig: CustomerAppConfig,
		public utilService: AppUtilService,
		public utilStoreService: StoreUtilService
	) {
		this.loadStaticData();
	}

	ngOnInit() {
		this.subscribeListBase = [
			{ key: 'mainList-data', observable: this.subscribeMainList() }
		];
		ConfigurationSubscribeManager.init(
			this.subscribeListBase.concat(this.subscribeListForType),
			this.subscribeManagerService
		);
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<ArticlePouchModel[]>(this.articleList$).subscribe(e => {
			this.articleList = e.data;
		});
		this.instanceType = this.route.parent.snapshot.data['instanceType'];
		this.backPath =  this.router.createUrlTree(['../'], { relativeTo: this.route }).toString();
	}

	subscribeMainList(): Observable<Promise<void>> {
		return this.mainList$.pipe(
			filter((e) => !!(e && e.data && e.data.article_list)),
			map(async (e) => {
				switch (e.type) {
					case this.mainListActionEnum.UPDATE: {
						this.mainList = e.data.article_list;
						const articleDescription =
							this.mainList.find(i => i.code_item === this.route.snapshot.paramMap.get(`${this.instanceType}Id`));
						if (articleDescription) {
							this.mainItem = {
								code_item: articleDescription.code_item,
								code_erp: articleDescription.code_erp,
								valid: articleDescription.valid,
								articleDescription: articleDescription
							} as any;
							// predisposizione modifica dati root (`mainItem.articleDescription`)
							this.formDynamicValue = await ConfigurationCustomerArticle.returnArticleDescriptionFormValue(this.mainItem);
							this.formDynamicConfiguration = {
								creationFieldMap: await ConfigurationCustomerArticle.articleDescriptionCreationFieldMap(),
								formValue: _.cloneDeep(this.formDynamicValue),
								emitAlsoNonValidForm: false,
							};
							// predisposizione modifica dati `mainItem.articleDescription.related_article_list`
							this.localHandlerListParser<ArticlePouchModel>(
								this.mainItem.articleDescription.related_article_list,
								this.articleList,
								'related_article_list'
							);
							// predisposizione modifica dati `mainItem.articleDescription.language_list`
							await this.setLanguageListConfiguration();
							this.setRouteMetaInformation();
						} else {
							this.snackBar.open(
								this.sentencecasePipe.transform(this.translateService.instant(`${this.instanceType}.error.not_found`)),
								this.translateService.instant('general.close').toUpperCase()
							);
							this.router.navigate(['../'], { relativeTo: this.route });
						}
						await this.handleExtenderInit();
						break;
					}
					case this.mainListActionEnum.SAVE_COMPLETE:
						this.snackBar.open(
							this.sentencecasePipe.transform(this.translateService.instant(`${this.instanceType}.saved`)),
							null,
							{ duration: 3000 }
						);
						break;
					case this.mainListActionEnum.ERROR:
						throw new Error(this.mainListActionEnum.ERROR);
				}
			})
		);
	}

	// form-dynamic
	// campi dinamici in `mainItem.articleDescription`
	formDynamicChange(e: any) {
		try {
			// l'evento scatta solo quando form-dynamic non presenta errori di validazione.
			// `formDynamicValue` rapprsenta dunque sempre l'ultimo stato valido emesso
			this.formDynamicValue = e;
			this.addPayloadToObject(this.mainItem.articleDescription, e);
			this.store.dispatch(this.mainListStateAction.save({ data: this.returnArticleSavePayload() }));
		} catch(e) {
			throw new Error(e)
		}
	}

	// campi dinamici in `mainItem.articleDescription.language_list[i]`
	async formDynamicLanguageListChange(e: LanguageItem, language: string) {
		try {
			e = await ConfigurationCustomerArticle.returnArticleDescriptionLanguageListFormValueForSave(e);
			let languageItemIndex: number;
			if (
				this.mainItem.articleDescription.language_list &&
				this.mainItem.articleDescription.language_list.length > 0
			) {
				languageItemIndex =
					this.utilService.getElementIndex(
						this.mainItem.articleDescription.language_list,
						'language',
						language
					);
			} else {
				this.mainItem.articleDescription.language_list = [];
			}
			if (languageItemIndex || languageItemIndex === 0) {
				this.mainItem.articleDescription.language_list[languageItemIndex] = _.cloneDeep(e);
			} else {
				this.mainItem.articleDescription.language_list.push(e);
			}
			await this.setLanguageListConfiguration();
			this.store.dispatch(this.mainListStateAction.save({ data: this.returnArticleSavePayload() }));
		} catch(e) {
			throw new Error(e);
		}
	}

	// aggiunta/rimozione immagini in `mainItem.articleDescription.language_list[i].image_list[n]`
	async attachmentListManagerChange(e: AttachedFile[], language: string) {
		try {
			let articleLanguageItem: LanguageItem;
			const articleLanguageItemNew: LanguageItem = {
				language: language,
				name: null,
				description: null,
				image_list: []
			};
			// controllo su presenza elemento lingua e relativa lista di immagini (eventuale set di articleLanguageItemNew)
			if (
				this.mainItem &&
				this.mainItem.articleDescription
			) {
				if (
					!(
						this.mainItem.articleDescription.language_list &&
						this.mainItem.articleDescription.language_list.length > 0
					)
				) {
					this.mainItem.articleDescription.language_list = [articleLanguageItemNew];
				} else {
					articleLanguageItem = this.mainItem.articleDescription.language_list.find(i => i.language === language);
					if (!articleLanguageItem) {
						this.mainItem.articleDescription.language_list.push(articleLanguageItemNew)
					} else {
						if (
							!(
								articleLanguageItem.image_list &&
								articleLanguageItem.image_list.length > 0
							)
						) {
							articleLanguageItem.image_list = [];
						}
					}
				}
			}
			// a questo punto articleLanguageItem è bindabile
			articleLanguageItem = this.mainItem.articleDescription.language_list.find(i => i.language === language);
			const imageList: AttachedFile[] = [];
			let isMainOfListFound = false;
			for (const imageEvent of e) {
				const imageArticle = articleLanguageItem.image_list.find(i => i.nameOnBucket === imageEvent.nameOnBucket);
				const imageToAdd = imageArticle ? imageArticle : imageEvent;
				imageList.push(imageToAdd);
				if (imageToAdd.is_main_of_list) {
					isMainOfListFound = true;
				}
			}
			if (!isMainOfListFound && imageList[0]) {
				imageList[0].is_main_of_list = true;
			}
			articleLanguageItem.image_list = imageList;
			// viene ricreato il form sulla base dei nuovi dati ora contenuti in `mainItem`
			// await this.createFormLanguageList();
			// viene aggiornata la configurazione di `attachment-list-manager` in modo da recepire eventuali modifiche ai metadati
			// delle immagini allegate
			await this.setLanguageListConfiguration();
			// salvataggio: non vi è controllo sulla validità di `formLanguageList` in quanto il widget che scatena il presente flusso
			// è visibile solo in assenza di suoi eventuali errori di validazione
			this.store.dispatch(this.mainListStateAction.save({ data: this.returnArticleSavePayload() }));
		} catch(e) {
			throw new Error(e);
		}
	}

	// aggiunta elementi dalle liste relazionali
	dialogRelatedListAdd(
		listForSelection: MAIN_ITEM[],
		localListHandlerName: string
	) {
		const relatedArticleCodeList = this.mainItem.articleDescription[localListHandlerName] || [];
		const dialogRef: MatDialogRef<DialogArticleSelectComponent> =
			this.dialog.open(DialogArticleSelectComponent, {
				data: {
					listForSelection: listForSelection,
					relatedArticleCodeListToFilter: [...relatedArticleCodeList, this.mainItem.code_item]
				},
				disableClose: true,
				panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
			});
		dialogRef.afterClosed().subscribe(articleCodeList => {
			if (articleCodeList && articleCodeList.length > 0) {
				if (!this.mainItem.articleDescription[localListHandlerName]) {
					this.mainItem.articleDescription[localListHandlerName] = [];
				}
				this.mainItem.articleDescription[localListHandlerName].push(...articleCodeList);
				this.localHandlerListParser<MAIN_ITEM>(
					this.mainItem.articleDescription[localListHandlerName],
					listForSelection,
					localListHandlerName
				);
				this.store.dispatch(this.mainListStateAction.save({ data: this.returnArticleSavePayload() }));
			}
		});
	}

	// rimozione elementi dalle liste relazionali
	dialogRelatedListDelete(
		item: MAIN_ITEM,
		listForSelection: MAIN_ITEM[],
		localListHandlerName: string
	) {
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.sentencecasePipe.transform(this.translateService.instant('general.remove')),
				text:
					this.sentencecasePipe.transform(
						this.translateService.instant(
							`${this.instanceType}.confirm_remove`,
							{
								description: item.articleDescription.language_list ?
								this.utilTranslateService.getTranslationFromLanguage(item.articleDescription.language_list).description :
								this.translateService.instant('general.unknown'),
								code: ConfigurationCustomerAppStructure.erp_has_erp ?
								item.code_erp :
								item.code_item
							}
						)
				)
			},
			disableClose: true,
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const removeIndex = this.mainItem.articleDescription[localListHandlerName].indexOf(item.code_item);
				this.mainItem.articleDescription[localListHandlerName].splice(removeIndex, 1);
				this.localHandlerListParser<MAIN_ITEM>(
					this.mainItem.articleDescription[localListHandlerName],
					listForSelection,
					localListHandlerName
				);
				this.store.dispatch(this.mainListStateAction.save({ data: this.returnArticleSavePayload() }));
			}
		});
	}

	// set delle configurazioni per le modifiche in `mainItem.articleDescription.language_list`
	async setLanguageListConfiguration(): Promise<void> {
		try {
			const attachmentListManagerConfiguration: LanguageAttachmentListManagerConfiguration = {};
			const formDynamicLanguageListConfiguration: LanguageFormDynamicConfigurationModel = {};
			for (const language of this.utilTranslateService.languages) {
				let imageList: AttachedFile[] = [];
				if (
					this.mainItem &&
					this.mainItem.articleDescription &&
					this.mainItem.articleDescription.language_list &&
					this.mainItem.articleDescription.language_list.length > 0
				) {
					for (const articleLanguageItem of this.mainItem.articleDescription.language_list) {
						if (
							articleLanguageItem.language &&
							articleLanguageItem.language === language &&
							articleLanguageItem.image_list &&
							articleLanguageItem.image_list.length > 0
						) {
							imageList = articleLanguageItem.image_list;
						}
					}
				}
				// attachment-list-manager per language_list > image_list
				attachmentListManagerConfiguration[language] = {
					localListHandler: {
						data: imageList
					},
					page: {
						title: null,
						columnList: attachmentListManagerColumnListBase
					},
					privilege: {
						add: true,
						delete: true,
						download: true
					},
					upload: {
						acceptedType: 'image/*',
						fileNameRegex: /^[a-z0-9_. -]+$/i,
						fileNameCharactersAccepted: "a-z 0-9 . _ -",
						fileNameAllowDuplicate: true,
						fileNameOnBucketCreate: (fileName, timeStamp): string => {
							return `${timeStamp}_${fileName}`;
						}
					},
					pathUrl: this.instanceType,
					pathQueryParam: `${this.mainItem.code_item}/${language}/gallery`
				};
				// form-dynamic per il language_list
				const iterationLanguageItem: LanguageItem = {
					language: language,
					name: null,
					description: null
				};
				let articleLanguageItem: LanguageItem;
				if (this.mainItem.articleDescription.language_list && this.mainItem.articleDescription.language_list.length > 0) {
					articleLanguageItem = this.mainItem.articleDescription.language_list.find(i => i.language === language);
				}
				const formDynamicLanguageListValue =
					await ConfigurationCustomerArticle.returnArticleDescriptionLanguageListFormValueForInitialization(
						articleLanguageItem ? articleLanguageItem : iterationLanguageItem,
						{
							pathUrl: `${this.appConfig.config.bucketManager.be_url}/${this.instanceType}`,
							pathQueryParam: `${this.mainItem.code_item}/${language}/gallery`,
							token: this.appConfig.token
						}
					);
				formDynamicLanguageListConfiguration[language] = {
					creationFieldMap:
						await ConfigurationCustomerArticle.articleDescriptionLanguageListCreationFieldMap(
							this.mainItem,
							language
						),
					formValue: _.cloneDeep(formDynamicLanguageListValue),
					emitAlsoNonValidForm: false,
					formDebounceTime: 3000
				};
			}
			this.formDynamicLanguageListConfiguration = _.cloneDeep(formDynamicLanguageListConfiguration);
			this.attachmentListManagerConfiguration = _.cloneDeep(attachmentListManagerConfiguration);
			return;
		} catch(e) {
			throw new Error(e);
		}
	}

	// il payload della save è ArticleDescriptionItemRest, ma in realtà viene inviato il valore del form non tipizzato
	// che presenta alcune differenze. Per coerenza con i servizi BE mantengo questo non tipizzato
	returnArticleSavePayload(): any {
		try {
			// campi già presenti direttamente su `mainItem`
			// - code_item
			// - related_article_list
			// - language_list
			const mainItem = _.cloneDeep(this.mainItem);
			const itemPayload: any = {
				code_item: mainItem.code_item,
				related_article_list: mainItem.articleDescription.related_article_list,
				language_list: mainItem.articleDescription.language_list
			}
			// form-dynamic
			// campi dinamici emessi da `form-dynamic` per il livello root `mainItem.articleDescription`
			const formDynamicValue = _.cloneDeep(this.formDynamicValue);
			this.handleExtenderSave(itemPayload);
			this.addPayloadToObject(itemPayload, formDynamicValue);
			return itemPayload;
		} catch(e) {
			throw new Error(e);
		}
	}

	addPayloadToObject(objectToModify: any, payload: any) {
		try {
			if (objectToModify && payload) {
				for (const p of Object.keys(payload)) {
					objectToModify[p] = payload[p];
				}
			}
		} catch(e) {
			throw new Error(e);
		}
	}

	setRouteMetaInformation() {
		try {
			this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue');
			if (this.mainItem && this.mainItem.code_item) {
				this.utilBreadcrumbService.subtitle.value =
					this.translateService.instant(this.utilBreadcrumbService.getBreadcrumbTitle(`catalogue_${this.instanceType}`)) +
					' | ' +
					(
						ConfigurationCustomerAppStructure.erp_has_erp ?
						this.mainItem.code_erp :
						this.guidFormatterPipe.transform(this.mainItem.code_item)
					) +
					' | ' +
					this.utilTranslateService.getTranslationFromLanguage(this.mainItem.articleDescription.language_list).description
			} else {
				this.utilBreadcrumbService.subtitle.value = this.translateService.instant(`${this.instanceType}.new`);
			}
			this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['catalogue',`catalogue_${this.instanceType}`]);
		} catch (e) {
			throw new Error(e);
		}
	}

	localHandlerListParser<LIST_TO_FILTER extends ArticlePouchModel>(
		mainItemRelatedList: string[],
		listToFilter: LIST_TO_FILTER[],
		localListHandlerName: string
	) {
		if (mainItemRelatedList && mainItemRelatedList.length > 0) {
			const relatedFamilyListData =
				listToFilter.filter(i => mainItemRelatedList.includes(i.code_item));
			const relatedFamilyListDataPouchModel = [];
			for (const article of relatedFamilyListData) {
				if (article.valid) {
					const articlePayload: ArticlePouchModel =  {
						code_item: article.code_item,
						code_erp: article.code_erp,
						valid: article.valid,
						articleDescription: _.cloneDeep(article.articleDescription)
					}
					relatedFamilyListDataPouchModel.push(articlePayload);
				}
			}
			this[this.localHandlerPrefix + localListHandlerName].data = relatedFamilyListDataPouchModel;
		} else {
			this[this.localHandlerPrefix + localListHandlerName].data = [];
		}
		this[this.localHandlerPrefix + localListHandlerName] =
			_.cloneDeep(this[this.localHandlerPrefix + localListHandlerName]);
	}

}
