<nav
	class="floating-menu-container expansion-{{ expansionSide }}"
	[ngClass]="{ subItemVisible: subItemVisible }"
	(clickOutside)="onClickOutside()"
>
	<button
		mat-fab
		class="fab-toggler"
		[color]="menuItems.color"
		[matTooltip]="menuItems.tooltipLabel"
		[matTooltipPosition]="menuItems.tooltipPosition ? menuItems.tooltipPosition : defaultMatTooltipPosition"
		(click)="itemClick(menuItems, menuItems.items.length > 0 ? true : false)"
	>
		<mat-icon [@fabToggler]="{ value: fabTogglerState }">{{ menuItems.icon }}</mat-icon>
	</button>
	<button
		*ngFor="let item of menuItems.items"
		mat-mini-fab
		[color]="item.color"
		[matTooltip]="item.tooltipLabel | translate | sentencecase"
		[matTooltipPosition]="menuItems.tooltipPosition ? menuItems.tooltipPosition : defaultMatTooltipPosition"
		(click)="itemClick(item)"
	>
		<mat-icon>{{ item.icon }}</mat-icon>
	</button>
</nav>
