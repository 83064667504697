import { Component, Input } from '@angular/core';
import { LoaderService } from '../../service/structure/loader.service';
import { SubscribeManagerService } from '../../service/util/subscribe-manager.service';

@Component({
	selector: 'loading-overlay',
	templateUrl: './loading-overlay.component.html',
	styleUrls: ['./loading-overlay.component.scss'],
  providers: [SubscribeManagerService]
})
export class LoadingOverlayComponent {
  @Input('loaderServiceInstance') set loaderServiceInstance(e: LoaderService) {
    if (e) {
      this.subscribeManagerService.populate(
        e.loader$.subscribe(res => {
          this.isLoading = res;
        }),
        'LoadingOverlayComponent.loaderServiceInstance-data'
      );
    }
  }
	@Input('isLoading') isLoading: boolean = false;
	@Input('type') type: string;
	@Input('id') id: string;

	constructor(
    private subscribeManagerService: SubscribeManagerService
  ) {

	}

  ngOnDestroy(): void {
    this.subscribeManagerService.destroy()
  }

}
