import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';
import {
	ContextApplicationItemCodeEnum,
	OpportunityEnum,
	AngularSpin8CoreUtilCompanyService,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'opportunity-list-wrapper',
	templateUrl: './opportunity-list-wrapper.component.html',
	styleUrls: ['./opportunity-list-wrapper.component.scss']
})
export class OpportunityListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();
	@Output() openDialogContactDetail: EventEmitter<OpportunityPouchModel> = new EventEmitter();
	@Output() openDialogAddAction: EventEmitter<ContactPouchModel> = new EventEmitter();
	@Output() openDialogActionList: EventEmitter<ContactPouchModel> = new EventEmitter();

	OpportunityEnum = OpportunityEnum;
	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService
	) {
		super(utilService, translateService, store);
	}
}
