import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { UserNotificationModel } from '@saep-ict/angular-spin8-core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';

export enum UserNotificationActionEnum {
	LOAD_NOTIFICATION_USER = '[user-notification] Load',
	UPDATE_NOTIFICATION_USER = '[user-notification] Update',
	ERROR_NOTIFICATION_USER = '[user-notification] Error',
	SAVE_NOTIFICATION_USER = '[user-notification] Save',
	SAVE_NOTIFICATION_COMPLETE = '[user-notification] Save complete',
}

export namespace UserNotificationStateAction {
	export const load = createAction(UserNotificationActionEnum.LOAD_NOTIFICATION_USER);
	export const update = createAction(UserNotificationActionEnum.UPDATE_NOTIFICATION_USER, props<BaseStateModel<UserNotificationModel>>());
	export const error = createAction(UserNotificationActionEnum.ERROR_NOTIFICATION_USER, props<BaseStateModel<any>>());
	export const save = createAction(UserNotificationActionEnum.SAVE_NOTIFICATION_USER, props<BaseStateModel<UserNotificationModel>>());
	export const saveComplete = createAction(UserNotificationActionEnum.SAVE_NOTIFICATION_COMPLETE, props<BaseStateModel<UserNotificationModel>>());

}
