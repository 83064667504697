import {ApiType} from "../../enum/api.enum"

export namespace Api {

  export const publicApi = {
     id: 'public',
     version: '',
    //  key: 'e9fbb0a8-8d25-41c8-952e-2b535cc1ad2d'
};

  export const privateApi = {
      id: 'private',
      version: '',
      // key: 'e9fbb0a8-8d25-41c8-952e-2b535cc1ad2d'

};

  export const key = {
    key: ''
  }

}

export const returnApiUrlSegment = (apiType:string) => {
      let apiVersion: string;
      let apiId: string;
      //let apiKey: string;
      switch(apiType) {
          case ApiType.PRIVATE:
              apiVersion = Api.privateApi.version;
              apiId = Api.privateApi.id;
             // apiKey = Api.privateApi.key;
              break;
          case ApiType.PUBLIC:
              apiVersion = Api.publicApi.version
              apiId = Api.publicApi.id;
              //apiKey = Api.publicApi.key;
              break;
      }
      return '/' + apiId + '/' + apiVersion ;
    }

