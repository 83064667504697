<mat-card *ngIf="_cardDashboardConfig" class="card-dashboard" [ngClass]="_cardDashboardConfig.theme">
	<mat-toolbar class="card-dashboard__header">
		<mat-toolbar-row>
			<mat-icon class="card-dashboard__icon" *ngIf="_cardDashboardConfig.icon">
				{{ _cardDashboardConfig.icon }}
			</mat-icon>
			<h1 class="card-dashboard__title">
				{{ _cardDashboardConfig.title | translate: _cardDashboardConfig.titleTranslateParams | sentencecase }}
			</h1>
			<span class="flex-span"></span>
			<mat-icon
				class="card-dashboard__help"
				*ngIf="_cardDashboardConfig.helpText"
				[matTooltip]="_cardDashboardConfig.helpText | translate | sentencecase"
			>
				help_outline
			</mat-icon>
		</mat-toolbar-row>
		<mat-toolbar-row>
			<mat-card-subtitle class="card-dashboard__subtitle">
				{{
					_cardDashboardConfig.subtitle
						| translate: _cardDashboardConfig.subtitleTranslateParams
						| sentencecase
				}}
			</mat-card-subtitle>
		</mat-toolbar-row>
	</mat-toolbar>
	<mat-card-content class="card-dashboard__content">
		<div class="card-dashboard__content__wrapper">
			<ng-content></ng-content>
		</div>
	</mat-card-content>
	<mat-card-actions
		class="card-dashboard__actions"
		*ngIf="_cardDashboardConfig.rangeTempList || _cardDashboardConfig.quarterList"
	>
		<!-- Range temporale -->
		<div class="col-6 m-0">
			<!-- Timerange -->
			<mat-form-field
				class="card-dashboard__actions__timerange"
				[class.light]="_cardDashboardConfig.theme === 'accent'"
				*ngIf="_cardDashboardConfig.rangeTempList"
			>
				<mat-select
					[value]="_cardDashboardConfig.rangeTempList.values[0]"
					(selectionChange)="changeTimeRange.emit($event.value)"
				>
					<mat-option *ngFor="let rangeTemp of _cardDashboardConfig.rangeTempList.values" [value]="rangeTemp">
						{{ 'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<!-- Quarter -->
			<mat-form-field
				class="card-dashboard__actions__timerange"
				[class.light]="_cardDashboardConfig.theme === 'accent'"
				*ngIf="_cardDashboardConfig.quarterList && _cardDashboardConfig.quarterList.length"
			>
				<mat-select
					[value]="_cardDashboardConfig.quarterList[0].q"
					(selectionChange)="changeTimeRange.emit($event.value)"
				>
					<mat-option *ngFor="let rangeTemp of _cardDashboardConfig.quarterList" [value]="rangeTemp.q">
						{{ 'general.time.quarter.' + rangeTemp.q | translate: { year: rangeTemp.y } | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-6 m-0" *ngIf="_cardDashboardConfig.detailLink">
			<a
				class="card-dashboard__actions__detail"
				color="accent"
				mat-button
				[routerLink]="[_cardDashboardConfig.detailLink]"
				>{{ 'general.details.name' | translate | titlecase }}</a
			>
		</div>
	</mat-card-actions>
</mat-card>
