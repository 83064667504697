import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStateModel, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilCompanyService, UserDetailModel, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../service/util/app-util.service';
import { Store } from '@ngrx/store';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, take } from 'rxjs';
import { StateFeature } from '../../../state';
import * as ConfigurationCustomerDestination from '../../../constants/configuration-customer/destination/destination.constant';
import * as ConfigurationCustomerOrganization
	from "../../../constants/configuration-customer/organization/organization.constant";
import * as ConfigurationCustomerPrice from "../../../constants/configuration-customer/price/price.constant";
import * as ConfigurationCustomerCalcSheet
	from "../../../constants/configuration-customer/calc-sheet/calc-sheet.constant";


@Component({
	selector: 'organization-list-wrapper',
	templateUrl: './organization-list-wrapper.component.html',
	styleUrls: ['./organization-list-wrapper.component.scss']
})
export class OrganizationListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() openOrderNew: EventEmitter<OrganizationPouchModel> = new EventEmitter();
	@Output("createNew") createNew: EventEmitter<any> = new EventEmitter();
	@Output() exportExcel: EventEmitter<any> = new EventEmitter();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	configurationCustomerDestination = ConfigurationCustomerDestination;
	configurationCustomerOrganization = ConfigurationCustomerOrganization;
	configurationCustomerPrice = ConfigurationCustomerPrice;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	configurationCustomerCalcSheet = ConfigurationCustomerCalcSheet;

	constructor(
		public utilService: AppUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService
	) {
		super(utilService, translateService, store);
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
			}
		});
	}
}
