import { Component, OnInit, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit {

  @ViewChild('quill') quill: QuillEditorComponent;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // this.quill.setDisabledState(true);
  }

  handleEvent(e) {
    console.log(e);
  }

}
