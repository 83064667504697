<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ 'general.items_number' | translate | sentencecase }}
		<span class="badge ml-3">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
	</mat-toolbar-row>
	<mat-toolbar-row
		*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
		class="mt-30"
	>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>

	<ng-template tdDataTableTemplate="business_name" let-value="value" let-row="row">
		<p class="title-card">
			<organization-table-heading
				[organization]="{ 'business_name' : row.business_name, 'code_erp': row.code_organization_erp}"
			>
			</organization-table-heading>
		</p>
	</ng-template>
		<ng-template tdDataTableTemplate="address.address" let-value="value" let-row="row">
			<p>
				<a href="javascript:void(0)" (click)="onItemSelect.emit(row)">
					{{ value }}
				</a>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="division_list.area_commercial" let-row="row">
			<ng-container
				*ngIf="
					utilService.returnIsMainOfList(row.division_list) &&
					utilService.returnIsMainOfList(row.division_list).area_commercial
				"
			>
				<ng-container *ngFor="let division of auxiliaryTableAreaCommercial">
					<span *ngIf="utilService.returnIsMainOfList(row.division_list).area_commercial === division.code_item">
						{{ division.description }}
					</span>
				</ng-container>
			</ng-container>
		</ng-template>
	</td-data-table>

	<mat-toolbar *ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow">
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<mat-paginator
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
			>
			</mat-paginator>
		</mat-toolbar-row>
	</mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
