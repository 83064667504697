// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  IResourceMethodObservable,
  IResourceMethodPromise,
  IResourceMethodPromiseStrict,
  ResourceAction,
  ResourceActionReturnType,
  ResourceRequestBodyType,
  ResourceRequestMethod,
} from '@ngx-resource/core';
import {
  ILoaderService,
  LOADER_SERVICE_TOKEN,
} from '../../interface/loader-service.interface';
import {
  APP_CONFIG_TOKEN,
  IMediaCenterAppConfig,
} from '../../interface/media-center-app-config.interface';
import {
  MediaCenterFilter,
  MediaCenterResponseModel,
  MoveItemListPayload,
} from '../../model/media-center.model';
import { UtilService } from '../util.service';
import { MediaCenterBaseApiService } from './baseApi.service';
import { WebSocketGetConnectionResponseModel } from '@saep-ict/angular-core'

@Injectable()
export class MediaCenterService extends MediaCenterBaseApiService {
  constructor(
    // private httpClient: HttpClient,
    @Optional() @Inject(LOADER_SERVICE_TOKEN) loaderService: ILoaderService,
    @Optional() @Inject(APP_CONFIG_TOKEN) appConf: IMediaCenterAppConfig,
    utils: UtilService
  ) {
    super(loaderService, appConf, utils);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/folder',
  })
  getFolderContent: IResourceMethodPromise<MediaCenterFilter, MediaCenterResponseModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/folder',
    returnAs: ResourceActionReturnType.Observable
  })
  getFolderContentObservable: IResourceMethodObservable<MediaCenterFilter, MediaCenterResponseModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/upload-multipart',
    requestBodyType: ResourceRequestBodyType.FORM_DATA,
  })
  uploadFile: IResourceMethodPromise<FormData, Response>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/delete',
  })
  deleteFileList: IResourceMethodPromise<{ pathList: string[] }, Response>;

  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/{!fileType}/rename',
  })
  renameItem: IResourceMethodPromiseStrict<{ path: string; oldName: string; newName: string }, void, {fileType: string}, Response>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/move',
  })
  moveItemList: IResourceMethodPromise<MoveItemListPayload, void>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!fileType}/create',
  })
  createFolder: IResourceMethodPromiseStrict<{path: string, newName: string}, void, {fileType: string}, Response>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/request-download-multiple',
  })
  getWebSocketConnection: IResourceMethodPromise<
    void,
    WebSocketGetConnectionResponseModel
  >;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/files/zip',
  })
  downloadZip: IResourceMethodPromise<{pathList: string, topic: string, token: string}, any>;

  // downloadFile(path: string, filename: string, contentType: string): any {
  //   const header = new HttpHeaders({
  //     Authorization: 'bearer ' + this.appConfig.token,
  //   });
  //   path = encodeURIComponent(path);
  //   filename = encodeURIComponent(filename);
  //   return this.httpClient.get(
  //     `${this.appConfig.mc_config.be_url}/download?path=${path}&filename=${filename}`,
  //     { headers: header }
  //   );
  // }
}
