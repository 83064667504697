import { Component, Input, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import * as StatisticModel from '../../model/statistics.model';
import _ from 'lodash';
import * as StatisticEnum from '../../enum/statistic.enum';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';
import * as CouchDocumentEnum from '../../enum/couch-document.enum';

@Component({
	selector: 'statistic-nested-category',
	templateUrl: './statistic-nested-category.component.html',
	styleUrls: ['./statistic-nested-category.component.scss']
})

export class StatisticNestedCategoryComponent implements OnInit {

	@Input() set configuration(e: StatisticModel.NestedCategoryConfiguration) {
		if (e && e.dataParse && e.dataParse.categoryTree) {
			this._configuration = e;
			this.dataSource.data = this._configuration.dataParse.categoryTree;
		}
	}
	_configuration: StatisticModel.NestedCategoryConfiguration;
	statisticEnum = StatisticEnum;

	treeControl = new NestedTreeControl<StatisticModel.Tree>(node => node.category_list);
	dataSource = new MatTreeNestedDataSource<StatisticModel.Tree>();

	articleCodeTypeToShow: string =
		ConfigurationCustomerAppStructure.erp_has_erp ?
		CouchDocumentEnum.CodeType.CODE_ERP :
		CouchDocumentEnum.CodeType.CODE_ITEM;

	constructor(
		public utilTranslateService: AngularSpin8CoreUtilTranslateService
	) { }

	ngOnInit(): void {}

}
