// model
import { FormControlMultipurposeModel } from '@saep-ict/angular-core';

// misc
import _ from 'lodash';

export const creationFieldMapDocumentBase: FormControlMultipurposeModel.Item[] = [
	{
		name: '_id',
		on_form_template: false
	},
	{
		name: '_rev',
		on_form_template: false
	},
	{
		name: 'valid',
		on_form_template: false
	},
	{
		name: 'code_item',
		on_form_template: false
	},
	{
		name: 'code_erp',
		on_form_template: false
	},
	{
		name: 'date_creation',
		on_form_template: false
	}
];
