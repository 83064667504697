import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// model
import { TicketModel } from '../../../model/lib.model';
import { TicketCenterConfigurationModel } from '../../../model/configuration.model';
import { ITicketCenterConfigModel } from '../../../model/lib-app-config.interface';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../store';
import { TicketCenterTicketActionEnum, TicketCenterTicketStoreAction } from '../../../store/ticket/ticket.actions';
import { TicketCenterTicketDetailActionEnum } from '../../../store/ticket/ticket-detail/ticket-detail.action';
import { TicketCenterLibConfigurationStoreEnum } from '../../../store/lib-configuration/lib-configuration.actions';

// misc
import {
  AngularCoreUtilService,
  BaseState,
  BaseStateModel,
  FormValidatorCustom,
  SubscribeManagerService,
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { catchError, filter, map, take } from 'rxjs/operators';
import { TicketCenterMainService } from '../../../service/util/ticket-center-main.service';
import { TicketWrapperComponent } from '../../../widget/ticket-wrapper/ticket-wrapper.component';
import { TicketCenterUtilService } from '../../../service/util/ticket-center-util.service';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';

@Component({
  selector: 'tc-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  providers: [SubscribeManagerService]
})
export class TicketComponent implements OnInit, OnDestroy  {
  @ViewChild(TicketWrapperComponent) ticketWrapperComponent: TicketWrapperComponent;

  user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserStore);
  user: UserDetailModel;

  ticket$: Observable<BaseStateModel<any>> = this.store.select(StateFeature.getTicketStore);
  ticketDetail$: Observable<BaseStateModel<any>> = this.store.select(StateFeature.getTicketDetailStore);
  ticketList: TicketModel<any>[];

  configuration: TicketCenterConfigurationModel;
  libConfig$: Observable<BaseStateModel<ITicketCenterConfigModel>> = this.store.select(StateFeature.getTicketCenterConfiguration);

  listPageBaseData = <LocalListHandlerBaseModel<TicketModel<any>>>{
    data: [],
		filters: {
			localSearchText: {
				value: null
      },
		},
		pagination: {
      pageSize: 25,
		},
		sort: {
			name: 'id',
			order: 'ASC'
		}
	};

  contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

  form: UntypedFormGroup;

  isLoading = true;

  constructor(
    private store: Store<any>,
    private fb: UntypedFormBuilder,
    private subscribeManagerService: SubscribeManagerService,
    public utilService: AngularCoreUtilService,
    public ticketCenterMainService: TicketCenterMainService,
    private ticketCenterUtilService: TicketCenterUtilService
  ) {
    this.store.dispatch(TicketCenterTicketStoreAction.load());
    this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			this.user = store ? store.data : null;
		});
    this.libConfig$.pipe(take(1)).subscribe((store: BaseStateModel<ITicketCenterConfigModel>) => {
      switch (store.type) {
        case TicketCenterLibConfigurationStoreEnum.UPDATE:
          this.configuration = store.data.customConfiguration;
          // filters localSearchText
          this.listPageBaseData.filters.localSearchText.key_list =
            this.configuration.page.list.localSearchTextKeyList[this.user.current_permission.context_application];
          break;
        case TicketCenterLibConfigurationStoreEnum.ERROR:
          this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction(store.errorMessage, 'OK');
          break;
      }
		});
    this.subscribeManagerService.populate(
      this.getTicketStore().subscribe(), 'ticket'
    );
    this.subscribeManagerService.populate(
      this.subscribeTicketDetail().subscribe(), 'ticket-detail'
    );
    this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.store.dispatch(TicketCenterTicketStoreAction.reset());
		this.subscribeManagerService.destroy();
  }

  getTicketStore(): Observable<BaseStateModel<TicketModel<any>[]>> {
		return this.ticket$.pipe(
      filter((store: BaseStateModel<TicketModel<any>[]>) => !!(store && (store.data || store.errorMessage))),
			map((store: BaseStateModel<TicketModel<any>[]>) => {
        switch (store.type) {
          case TicketCenterTicketActionEnum.UPDATE:
            this.ticketList = store.data;
            this.ticketWrapperComponent.updateListPageBaseData(this.ticketList);
            break;
          case TicketCenterTicketActionEnum.ERROR:
            this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction(store.errorMessage, 'OK');
            break;
        }
        return store;
      }),
      catchError((error, caught) => {
        console.log(error);
        this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction('tc.error.generic', 'OK');
        return caught;
      })
		);
  }

  subscribeTicketDetail(): Observable<BaseStateModel<TicketModel<any>>> {
		return this.ticketDetail$.pipe(
      filter((store: BaseStateModel<TicketModel<any>>) => !!(store && (store.data || store.errorMessage))),
			map((store: BaseStateModel<TicketModel<any>>) => {
        switch (store.type) {
          case TicketCenterTicketDetailActionEnum.SAVE_SUCCESS:
            this.store.dispatch(TicketCenterTicketStoreAction.addItemOrUpdateItem(new BaseState(store.data)));
            break;
          case TicketCenterTicketDetailActionEnum.ERROR:
            this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction(store.errorMessage, 'OK');
            break;
        }
        return store;
			}),
      catchError((error, caught) => {
        console.log(error);
        this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction('tc.error.generic', 'OK');
        return caught;
      })
		);
  }

  // form
  createForm() {
		this.form = this.fb.group({
			statusList: [[]],
			urgent: [null],
      dateRequest: this.fb.group(
        {
            start: [''],
            end: [''],
        },
        {
            validators: [FormValidatorCustom.allEmptyOrAllFilledByObject]
        }
    ),
      dateDue: this.fb.group(
        {
            start: [''],
            end: [''],
        },
        {
          validators: [FormValidatorCustom.allEmptyOrAllFilledByObject]
        }
      )
		});
  }

  prepareSaveForm() {
		const formModel = this.form.value;
    if (formModel.dateRequest && formModel.dateRequest.start && formModel.dateRequest.end) {
      formModel.dateRequest = {
        start: formModel.dateRequest.start.valueOf(),
        end: formModel.dateRequest.end.add(1, 'days').valueOf(),
      }
    } else {
      delete formModel.dateRequest;
    }
    if (formModel.dateDue && formModel.dateDue.start && formModel.dateDue.end) {
      formModel.dateDue = {
        start: formModel.dateDue.start.valueOf(),
        end: formModel.dateDue.end.add(1, 'days').valueOf(),
      }
    } else {
      delete formModel.dateDue;
    }
    this.utilService.deleteEmptyProperties(formModel);
		return formModel;
  }

  onFormResetFilters(){
    this.form.reset();
    this.ticketWrapperComponent.updateListPageBaseData(this.ticketList);
  }

  onFormSubmit(){
    if (this.form.valid) {
      let ticketList: TicketModel<any>[] = _.cloneDeep(this.ticketList);
      const formModel = this.prepareSaveForm();
      if (formModel.statusList) {
        ticketList = ticketList.filter(i => formModel.statusList.includes(i.status));
      }
      if (formModel.urgent) {
        ticketList = ticketList.filter(i => i.request.urgent);
      }
      if (formModel.dateRequest) {
        ticketList =
          ticketList.filter(i =>
            i.request.date_request >= formModel.dateRequest.start &&
            i.request.date_request <= formModel.dateRequest.end
          );
      }
      if (formModel.dateDue) {
        ticketList =
          ticketList.filter(i =>
            i.request.date_due >= formModel.dateDue.start &&
            i.request.date_due <= formModel.dateDue.end
          );
      }
      this.ticketWrapperComponent.updateListPageBaseData(ticketList);
    }
  }

}
