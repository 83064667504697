import { USER_PROVIDED_EFFECTS } from "@ngrx/effects";
import { BaseStateSerializerModel } from "../../model/state/base-state.model";
import { storeEffectList } from "../../store/store-effect-list";
import * as _ from 'lodash';

export class UtilStore {

  public static returnStoreEffectProviderList(): any[] {
    const storeEffectProviderList = [];
    storeEffectList.forEach(i => {
      storeEffectProviderList.push(i);
      storeEffectProviderList.push({
        provide: USER_PROVIDED_EFFECTS,
        multi: true,
        useValue: [i],
      });
    });
    return storeEffectProviderList;
  };

  public static returnUpdatedSerielizedStore<T>(
    store: BaseStateSerializerModel<T>[],
    param: BaseStateSerializerModel<T>
  ): BaseStateSerializerModel<T>[] {
    if (store && param && param.stateSlice && param.stateSlice.type) {
      for (let i = 0; i < store.length; i++) {
        if (store[i].stateSlice.type === param.stateSlice.type) {
          store[i] = param;
          return _.cloneDeep(store);
        }
      }
      store.push(param);
    }
    return _.cloneDeep(store);
  };

  public static returnResetItemSerielizedStore<T>(
    store: BaseStateSerializerModel<T>[],
    param: BaseStateSerializerModel<T>
  ): BaseStateSerializerModel<T>[] {
    if (store && param && param.stateSlice && param.stateSlice.type) {
      for (let i = 0; i < store.length; i++) {
        if (store[i].stateSlice.type === param.stateSlice.type) {
          store.splice(i, 1);
          return _.cloneDeep(store);
        }
      }
      store.push(param);
    }
    return _.cloneDeep(store);
  };

}
