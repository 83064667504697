<div
	*ngIf="_results && _results.list?.length > 0 && isOpen"
	(clickOutside)="onClickOutside()"
	class="autocomplete-container"
>
	<ul>
		<li
			*ngFor="let item of _results.list | slice:0:config.sliceLength"
			(click)="emitSelection(item)"
			class="list"
			[style.grid-template-columns]="'repeat('+ config.visibleProperties.length + ', 1fr)' | safeDomSanitizer: 'style'"
		>
			<div *ngFor="let p of config.visibleProperties">{{item[p]}}</div>
		</li>
		<li class="last" *ngIf="config.multipleSelection" (click)="emitSelection()">
			Show all {{results?.list.length}}
		</li>
	</ul>
</div>
