import { Injectable } from '@angular/core';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { SummaryListModel } from '../../model/summary.model';
import { Pagination } from '@saep-ict/angular-core';

@Injectable()
export class AngularSpin8CoreSummaryService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/summaries/'
	})
	getFilteredList: IResourceMethodPromise<Pagination, SummaryListModel>;
}
