import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleStateAction, ArticleActionEnum } from './article.actions';
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';

export namespace ArticleStateReducer {
	export const initialState: BaseStateModel<ArticlePouchModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(ArticleStateAction.updateArticle, state => state),
		on(ArticleStateAction.update, (state, param) => param),
		on(ArticleStateAction.loadFromRecap, state => state),
		on(ArticleStateAction.reset, () => initialState),
		on(ArticleStateAction.error, (state, param) => ({
			data: state ? state.data : null,
			type: param.type
		}))
	);

	export function reducer(state: BaseStateModel<ArticlePouchModel[]>, action: TypedAction<ArticleActionEnum>) {
		return _reducer(state, action);
	}
}
