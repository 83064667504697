import {
	ContextApplicationItemCodeEnum,
	ContextOtherItemCodeEnum
} from '../../enum/permission/context-application-item-code.enum';
import { ContextCodeAssociationItem, ContextPermission, LinkDetailModel } from './user.model';
import { PermissionItem, PermissionGroupItem } from './permission-auxiliary-table.model';
import { ActionCreator, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { RegistryModeEnum } from '../../enum/util/registry-mode.enum';
import { BasePouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import { QueryParameter } from '@saep-ict/angular-core';

export interface UserManagementQueryParameter extends QueryParameter {
	context_code?: number;
}

export interface ContextCodeItem extends BasePouchModel, LinkDetailModel {}

export interface ContextTypeAndApplicationLink {
	context_application_item_code: ContextApplicationItemCodeEnum;
	context_code_item: ContextCodeItemLink;
	application_name: string;
	icon: string;
}

export interface ContextCodeItemLink {
	code: ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum;
	description: string;
	association_type: ContextCodeItemLink[];
	couch_document_key: string;
	action?: {
		load?: ActionCreator<any, (props: any) => TypedAction<any>>;
		loadItem: ActionCreator<any, (props: any) => TypedAction<any>>;
		loadWithDetail?: ActionCreator<any, (props: any) => TypedAction<any>>;
		loadList?: ActionCreator<any, (props?: any) => TypedAction<any>>;
		loadListWithDetail?: ActionCreator<any, (props: any) => TypedAction<any>>;
		update?: ActionCreator<any, (props: any) => TypedAction<any>>;
		save?: ActionCreator<any, (props: any) => TypedAction<any>>;
		remove?: ActionCreator<any, (props: any) => TypedAction<any>>;
		reset?: ActionCreator<any, () => TypedAction<any>>;
		createItem?: ActionCreator<any, (props: any) => TypedAction<any>>;
		deleteItem: ActionCreator<any, (props: any) => TypedAction<any>>;
	};
	state?: MemoizedSelector<object, any, DefaultProjectorFn<any>>; // ADDED
	already_associated_context_code_list: (string | number)[];
	// model_type: LinkDetailModelReference;
}

export interface ContextCodeAssociation extends BodyTablePouchModel, BasePouchModel {
	context_code_association_list: ContextCodeAssociationItem[];
}

export interface UserManagementDialogChangePermissionContextCode {
	contextTypeAndApplicationLink: ContextTypeAndApplicationLink;
	contextCodeItem: ContextPermission;
	permission_default: string[];
	permission_list: PermissionItem[];
	permission_group_list: PermissionGroupItem[];
}

export interface ContextPermissionGroupItemContextApplicationLink {
	item_key: string;
	context_permission_tree: ContextPermissionGroupItem[];
}

export interface ContextPermissionGroupItem extends PermissionItem {
	permission_group_full_object_list: PermissionItem[];
	permission_group_value_list: string[];
}

export interface UserManagementDialogRegistryModel {
	title: string;
	mode: RegistryModeEnum;
	contextTypeAndApplicationLink: ContextTypeAndApplicationLink;
}
