import { Component, EventEmitter, Output } from '@angular/core';

// misc
import { AngularCoreUtilService, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { TicketModel } from '../../model/lib.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'tc-ticket-wrapper',
  templateUrl: './ticket-wrapper.component.html',
  styleUrls: ['./ticket-wrapper.component.scss']
})
export class TicketWrapperComponent extends LocalListHandlerWidgetWrapper {

	@Output() selectTicket: EventEmitter<TicketModel<any>> = new EventEmitter();

  constructor(
    public utilService: AngularCoreUtilService,
    private translateService: TranslateService,
    public store: Store
  ) {
    super(utilService, translateService, store);
  }

}
