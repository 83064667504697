<ng-container *ngIf="_configuration">
	<!-- <h3>{{ 'order.order_aside_help.frequent_questions' | translate | sentencecase }}</h3> -->
	<ul class="faqs">
		<ng-container *ngTemplateOutlet="faqItem; context: { list: faqCommon, initPath: 'faq.' }"></ng-container>
		<ng-container *ngTemplateOutlet="faqItem; context: { list: faqTheme, initPath: 'theme.faq.' }"></ng-container>
	</ul>
	<h3>{{ 'order.order_aside_help.any_questions' | translate | sentencecase }}</h3>
	<p>{{ 'order.order_aside_help.any_questions_quote' | translate }}</p>
	<!-- <button class="send-email">{{ 'order.order_aside_help.send_email' | translate | sentencecase }}</button> -->
</ng-container>
<!-- ng-template -->
<ng-template
	#faqItem
	let-list="list"
	let-initPath="initPath"
>
	<li *ngFor="let faq of list">
		<div class="question" (click)="faq.active = !faq.active">
			{{ (initPath + _configuration.section + '.' + faq.key + '.' + 'question') | translate | sentencecase }}
		</div>
		<div class="answer" [class.open]="faq.active">
			{{ (initPath + _configuration.section + '.' + faq.key + '.' + 'answer') | translate | sentencecase }}
		</div>
	</li>
</ng-template>
