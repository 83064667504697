import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, RestBasePk } from '@saep-ict/angular-core';
import { OfflineDeviceService, PouchDbAdapter, PouchUtilService } from '@saep-ict/pouch-db';
import {
	BodyTablePouchModel,
	OrderPouchModel,
	OrganizationPouchModel,
	OrganizationTypeEnum,
	CategoryMap,
} from '@saep-ict/pouch_agent_models';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { catchError, concatMap, filter, map, skipWhile, take } from 'rxjs/operators';
import * as ConfigurationCustomerArticle from '../../constants/configuration-customer/article/article.constant';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';
import { CustomerAppConfig, CustomerAppConfigModel } from '../../customer-app.config';
import { ConnectionModel } from '../../model/connection.model';
import { ConfigurationViewModel } from '../../model/configuration.model';
import { ArticleRecap, ArticleRecapArticleList } from '../../model/state/article-list-state.model';
import { StateForeConcextResultInterface } from '../../model/state/store-common.model';
import { StatisticsAgent } from '../../model/statistics-agent.model';
import { StatisticsBackoffice } from '../../model/statistics-backoffice.model';
import { StatisticsCrm } from '../../model/statistics-crm.model';
import { StatisticsOrganization } from '../../model/statistics-organization.model';
import { StateFeature } from '../../state';
import { AdditionalServiceStateAction } from '../../state/additional-service/additional-service.action';
import { ArticleDescriptionStateAction } from '../../state/article-description/article-description.actions';
import { AuxiliaryTableGeographicTreeStateAction } from '../../state/auxiliary-table/auxiliary-table-geographic-tree/auxiliary-table-geographic-tree.actions';
import {
	AuxiliaryTableActionEnum,
	AuxiliaryTableStateAction
} from '../../state/auxiliary-table/auxiliary-table.actions';
import {
	ContextCodeAssociationActionEnum,
	ContextCodeAssociationStateAction
} from '../../state/backoffice/context-code/context-code-association/context-code-association.actions';
import { CategoryListAction, CategoryListActionEnum } from '../../state/category-list/category-list.actions';
import { LoginContextCodeActionEnum } from '../../state/common/login/context-code/login-context-code.actions';
import { OrganizationListStateAction } from '../../state/common/organization-list/organization-list.actions';
import { ConfigurationStateAction } from '../../state/configuration/configuration.actions';
import { OrderListActionEnum, OrderListStateAction } from '../../state/order-list/order-list.actions';
import { OrderActionEnum, OrderStateAction } from '../../state/order/order.actions';
import { OrganizationActionEnum, OrganizationStateAction } from '../../state/organization/organization.actions';
import { PermissionAuxiliaryTableStateAction } from '../../state/permission-auxiliary-table/permission-auxiliary-table.actions';
import { StatisticsAgentStateAction } from '../../state/statistics-agent/statistics-agent.action';
import { StatisticsBackofficeStateAction } from '../../state/statistics-backoffice/statistics-backoffice.action';
import { StatisticsCrmStateAction } from '../../state/statistics-crm/statistics-crm.action';
import { StatisticsOrganizationStateAction } from '../../state/statistics-organization/statistics-organization.action';
import { ContextType, UtilGuardService } from '../guard/util-guard/util-guard.service';
import { PouchDbAgentAdapter } from '../pouch-db/spin8/pouchdb-agent.adapter';
import { AuthService } from '../rest/auth.service';
import { StoreUtilService } from '../util/store-util.service';
import { UtilOrderService } from '../util/util-order.service';
import {
	AuxiliaryTabeleGeographicTree,
	AuxiliaryTableStateModel,
	ContextApplicationItemCodeEnum,
	ContextCodeAssociation,
	CurrentContextPermission,
	ExtraFieldOrderHeaderPouchModel,
	LinkCodeModel,
	OrderStateModel,
	PermissionAuxiliaryDictionary,
	PermissionAuxiliaryTableStateModel,
	PouchDbEnum,
	ROUTE_URL,
	UserDetailModel,
	OrganizationStateModel,
	AngularSpin8CoreUserService
} from '@saep-ict/angular-spin8-core';
import { ArticleStateAction } from '../../state/article/article.actions';
import { ArticleRecapLoadFilter } from '../../model/article.model';
import { FamilyListStateAction } from '../../state/family-list/family-list.actions';
import * as ConfigurationCustomerAuthentication from '../../constants/configuration-customer/authentication/authentication.constant';

// TODO da riportare in libreria nell'oggetto UserTypeContextModel
const customAssociation = [
	{
		configurationPlaceholder: PouchDbEnum.DocumentPlaceholder.AGENT_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.AGENT
	},
	{
		configurationPlaceholder: PouchDbEnum.DocumentPlaceholder.ORGANIZATION_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.B2B
	},
	{
		configurationPlaceholder: PouchDbEnum.DocumentPlaceholder.ORGANIZATION_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.B2C
	},
	{
		configurationPlaceholder: PouchDbEnum.DocumentPlaceholder.BACKOFFICE_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.BACKOFFICE
	},
	{
		configurationPlaceholder: PouchDbEnum.DocumentPlaceholder.ORGANIZATION_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.PORTAL
	},
	{
		configurationPlaceholder: PouchDbEnum.DocumentPlaceholder.CRM_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.CRM
	}
];

@Injectable({
	providedIn: 'root'
})
export class ContextManagerService {
	@LocalStorage('user') user: UserDetailModel;
	@LocalStorage('permissions') permissions: PermissionAuxiliaryTableStateModel;
	@LocalStorage('current_order_id') current_order_id: string;
	@LocalStorage('authenticationToken') authenticationToken: string;
	@LocalStorage('link_code') currentPermission: LinkCodeModel;

	loginContextCode$: Observable<BaseStateModel<ContextType>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	connection$: Observable<BaseStateModel<ConnectionModel>> = this.store.select(StateFeature.getConnectionState);
	contextCodeAssociation$: Observable<BaseStateModel<ContextCodeAssociation>> = this.store.select(
		StateFeature.getContextCodeAssociationState
	);

	destroy$: Subject<boolean> = new Subject<boolean>();

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTableGeographicTree$: Observable<
		BaseStateModel<AuxiliaryTabeleGeographicTree.StateModel>
	> = this.store.select(StateFeature.getAuxiliaryTableGeographicTreeState);
	categoryList$ = this.store.select(StateFeature.getCategoryListState);
	configuration$ = this.store.select(StateFeature.getConfigurationState);
	organizationList$ = this.store.select(StateFeature.getOrganizationListState);
	articleDescription$ = this.store.select(StateFeature.getArticleDescription);
	articleList$ = this.store.select(StateFeature.getArticleList);
	orderList$ = this.store.select(StateFeature.getOrderListState);
	order$ = this.store.select(StateFeature.getOrderState);

	statisticsAgent$ = this.store.select(StateFeature.getStatisticsAgent);
	statisticsBackoffice$ = this.store.select(StateFeature.getStatisticsBackoffice);
	statisticsCrm$ = this.store.select(StateFeature.getStatisticsCrm);
	statisticsOrganization$ = this.store.select(StateFeature.getStatisticsOrganization);

	constructor(
		private store: Store,
		private userService: AngularSpin8CoreUserService,
		private authService: AuthService,
		protected appConfig: CustomerAppConfig,
		private pouchUtilService: PouchUtilService,
		private utilGuardService: UtilGuardService,
		private offlineDeviceService: OfflineDeviceService,
		private router: Router,
		private utilOrderService: UtilOrderService,
		private utilStoreService: StoreUtilService
	) {}

	// Device Case:
	// The first time I have to be online for retrieve the user's info, the next time I read it from localstorage
	// Browser Case:
	// I always retrieve the user's info from Be
	async retrieveUserInfo(): Promise<UserDetailModel> {
		// retrieving the user_id from the decoded token
		if (!this.authService || !this.authService.tokenPayload || !this.authService.tokenPayload.user_id) {
			throw new Error(`You must be logged in to retrieve this information`);
		}
		const userDetailRequest: RestBasePk = { id: this.authService.tokenPayload.user_id };
		if (!this.user || !(await this.retrieveConnectionState())) {
			// retrieving user payload
			this.user = (await this.userService.getUserDetail(userDetailRequest)).data;
			// retrieving avatar
			// await this.userService
			// 	.getUserAvatar({ id: userDetailRequest.id })
			// 	.then(res => (this.user.avatar = res.icon))
			// 	.catch(err => console.error(err));
		}
		// TODO: chiarire la seguente condizione
		// else if (!this.user && (await this.retrieveConnectionState())) {
		// 	throw new Error(`You must be online to get user info!`);
		// }
		return this.user;
	}

	// Device Case:
	// The first time I have to be online for retrieve the permissions' info, the next time I read it from localstorage
	// Dopodichè le leggo da localstorage
	// I always retrieve the permissions' info from Be
	async retrieveAuxiliaryPermissionList() {
		if (!this.isLocalPermissionOk() || !(await this.retrieveConnectionState())) {
			this.store.dispatch(PermissionAuxiliaryTableStateAction.load());
		}
		// TODO: chiarire la seguente condizione
		// else if (!this.isLocalPermissionOk() && (await this.retrieveConnectionState())) {
		// 	throw new Error(`You must be online to get permissions info!`);
		// }
		// TODO verify async
		this.store.dispatch(PermissionAuxiliaryTableStateAction.update(new BaseState(this.permissions)));
	}

	// start only generic db
	async connectToGenericDb() {
		const toStartDbList = this.appConfig.config.couch
			.filter(config => !config['context'])
			.filter(config => {
				return (
					this.pouchUtilService.pouchInstance.filter(instance => {
						if (config.database === instance.database) {
							return instance.couchDbUp.value;
						}
						return false;
					}).length === 0
				);
			});
		if (toStartDbList && toStartDbList.length > 0) {
			try {
				await this.pouchUtilService.explicitInitCouch(toStartDbList);
			} catch (err) {
				console.log(err);
				throw new Error(`Can't start couchdb`);
			}
		}
	}

	async initContextDb(current_context_type: ContextApplicationItemCodeEnum): Promise<boolean> {
		// retrieving operator code, use this method only if the context isn't ambiguous
		const contextLink = this.utilGuardService.checkUserContext(this.user, current_context_type);
		if (!this.currentPermission) {
			console.log('NO CURRENT PERMISSION');
		}
		const codeOperator = contextLink.currentContext.context_code_list
			.find(code_list => code_list.code === this.currentPermission.code)
			?.code.toString();

		const userTypeContext = customAssociation.find(current_context => {
			return current_context.type === current_context_type;
		});
		const config: CustomerAppConfigModel = await this.appConfig.config$.pipe(take(1)).toPromise();

		// change the configuration to init the correct couchdb
		const toStartDbList = config.couch.filter(couchConf => {
			if (couchConf.context && couchConf.context.includes(current_context_type)) {
				couchConf.database = couchConf.baseDatabaseTemplate.replace(
					userTypeContext.configurationPlaceholder,
					codeOperator
				);
				this.appConfig.config = config;
				return true;
			}
		});
		// change the database attribute in the couch/pouch instance
		this.pouchUtilService.pouchInstance.forEach((pouchInstance: PouchDbAdapter) => {
			if (pouchInstance['variation'] && pouchInstance['variation'] === current_context_type) {
				pouchInstance.database = pouchInstance.baseDatabaseTemplate.replace(
					userTypeContext.configurationPlaceholder,
					codeOperator
				);
			}
		});

		if (toStartDbList && toStartDbList.length > 0) {
			try {
				await this.pouchUtilService.explicitInitCouch(toStartDbList);
			} catch (err) {
				throw new Error('Cannot start specific database');
			}
		}
		return true;
	}

	/**
	 * the pipe observe the state of the passed context, check if the context operator code
	 * exist and return the payload of the relative dispatched document
	 *
	 * @example - with current context Agent observe in the state the document agent_339
	 */
	initObservableState(): Observable<ContextType> {
		return this.loginContextCode$.pipe(
			filter((contextState: BaseStateModel<ContextType>) => contextState != null && contextState.data != null),
			take(1),
			map((contextState: BaseStateModel<ContextType>) => {
				return contextState.data;
			})
		);
	}

	/**
	 * Method to formatting Object with current context and linked data
	 *
	 * @param context_application - Context Code Application Enum (B2B, AGENT ecc...)
	 * @param context_code - Context Code Operator (339, 810 ecc..)
	 * @param permission - list of current permission
	 * @returns Object contains current context_application, context_code, permission and context_code_association list
	 */
	async returnUserCurrentPermission(
		context_application: ContextApplicationItemCodeEnum,
		context_code: string,
		permission: string[],
		user: UserDetailModel
	): Promise<UserDetailModel> {
		try {
			const currentContextPermission: CurrentContextPermission = {
				context_application: context_application,
				context_code: {
					code: context_code,
					context_code_association_list: []
				},
				permission: permission
			};

			// inserimento code_erp
			for (const contextApplication of user.context_application_list) {
				if (contextApplication.code === context_application) {
					for (const contextCode of contextApplication.context_code_list) {
						if (contextCode.code === context_code) {
							if (contextCode.code_erp) {
								currentContextPermission.context_code.code_erp = contextCode.code_erp;
							}
							if (contextCode.description) {
								currentContextPermission.context_code.description = contextCode.description;
							}
						}
					}
				}
			}

			this.store.dispatch(
				ContextCodeAssociationStateAction.load(
					new BaseState({ _id: `context_code_association${ConfigurationCustomerAppStructure.noSqlDocSeparator}${context_code}` })
				)
			);
			return this.contextCodeAssociation$
				.pipe(
					skipWhile(
						(contextCodeAssociation: BaseStateModel<ContextCodeAssociation>) => !contextCodeAssociation
					),
					take(1),
					map((contextCodeAssociation: BaseStateModel<ContextCodeAssociation>) => {
						this.store.dispatch(ContextCodeAssociationStateAction.reset());
						if (contextCodeAssociation.type === ContextCodeAssociationActionEnum.ERROR) {
							user.current_permission = currentContextPermission;
							return user;
						}
						currentContextPermission.context_code.context_code_association_list =
							contextCodeAssociation.data.context_code_association_list;
						user.current_permission = currentContextPermission;
						return user;
					}),
					catchError(error => {
						throw new Error(error);
					})
				)
				.toPromise();
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * offlineMode True: if the sqlite local db doesn't exist redirect to the startupDownload to download the data
	 *
	 * @returns boolean false if in offlinemode db isn't syncronized
	 */
	async verifyLocalDbSync(): Promise<boolean> {
		let connection: ConnectionModel;
		this.connection$.pipe(take(1)).subscribe(res => {
			connection = res ? res.data : null;
		});
		const selectedDevice = await this.offlineDeviceService.init();
		// retrieve only agent instance
		const contextDBInstance: PouchDbAdapter = <PouchDbAdapter>(
			this.pouchUtilService.pouchInstance.find(x => x instanceof PouchDbAgentAdapter)
		);
		if (!connection.offline && selectedDevice.offlineMode && (await contextDBInstance.isDbSyncronized())) {
			this.router.navigate([ROUTE_URL.startupDownload]);
			return false;
		} else {
			await this.offlineDeviceService.init();
		}
		return true;
	}

	async retrieveConnectionState(): Promise<boolean> {
		return (await this.store.select(StateFeature.getConnectionState).pipe(take(1)).toPromise()).data.offline;
	}

	isLocalPermissionOk() {
		return this.permissions && PermissionAuxiliaryDictionary.every(x => this.permissions[x.key]);
	}

	async manageNotAuthenticated(currentUrl: string): Promise<boolean> {
		if (
			ConfigurationCustomerAuthentication.returnPublicNavigationCondition(
				this.appConfig.config.permissionContext,
				currentUrl
			)
		) {
			// per i non autenticati:
			// - abilita la navigazione delle rotte B2C pubbliche
			// effettua il caricamento degli store dedicati
			this.utilStoreService.stateForeConcextResult = {};
			const stateForeConcextResult = await this.loadStorefrontContent();
			return stateForeConcextResult ? true : false;
		} else {
			this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.login]);
			return new Promise(resolve => resolve(null));
		}
	}

	/**
	 * Scatena le dispatch comuni a tutti i context application & storefront
	 *
	 * @memberof ContextManagerService
	 */
	dispatchCommonStore() {
		this.store.dispatch(ArticleDescriptionStateAction.loadDescriptionFromRecap());
		if (ConfigurationCustomerArticle.has_family) {
			this.store.dispatch(FamilyListStateAction.loadDescriptionFromRecap());
		}
		this.store.dispatch(ConfigurationStateAction.load());
		this.store.dispatch(AuxiliaryTableGeographicTreeStateAction.load());
		if (ConfigurationCustomerArticle.has_category) {
			this.store.dispatch(CategoryListAction.loadAll());
		} else {
			this.store.dispatch(CategoryListAction.notExisting());
		}
	}

	/**
	 * Carico configurazione iniziale storefront (contenuti home, categorie e articoli, metodi di pagamento, ecc...)
	 */
	loadStorefrontContent(data?: OrganizationPouchModel): Promise<StateForeConcextResultInterface> {
		this.dispatchCommonStore();
		this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(data)));
		return new Promise(resolve => {
			this.subscribeCommonStore()
				.pipe(
					filter(commonStore => !!commonStore),
					concatMap(() => {
						const dispatchArticleRecapAction: BaseStateModel<ArticleRecap, ArticleRecapLoadFilter> =
							{
								dataSetting: {
									appliedFilter: {
										organization: null
									}
								},
								data: null
							};
						if (data) {
							dispatchArticleRecapAction.dataSetting.appliedFilter.organization = data;
							this.utilStoreService.contextManagerStoreSequenceHandler<BodyTablePouchModel, void>({
								data: data,
								type: LoginContextCodeActionEnum.UPDATE
							});
						}
						this.store.dispatch(ArticleStateAction.loadFromRecap(dispatchArticleRecapAction));
						if (this.current_order_id) {
							// esiste un ordine referenziato nel localstorage
							// il recupero avviene tramite order action di load che passa sempre
							// mediante chiamata API REST
							this.store.dispatch(
								OrderStateAction.loadFromLocalStorageByRest(new BaseState(this.current_order_id))
							);
						} else {
							this.store.dispatch(OrderStateAction.skip());
						}

						if (this.authenticationToken) {
							this.store.dispatch(AuxiliaryTableStateAction.load());
							this.store.dispatch(OrderListStateAction.loadAllAndGetDraft());
							this.store.dispatch(AdditionalServiceStateAction.loadAll());
						} else {
							this.store.dispatch(AuxiliaryTableStateAction.skip());
							this.store.dispatch(OrderListStateAction.skip());
						}
						return this.utilStoreService.contextManagerStoreSequenceHandler<void, AuxiliaryTableStateModel>(
							null,
							this.auxiliaryTable$
						);
					}),
					filter(store => !!(store && (store.data || store.type === AuxiliaryTableActionEnum.SKIP))),
					concatMap(store => {
						switch (store.type) {
							case AuxiliaryTableActionEnum.UPDATE:
								return this.utilStoreService.contextManagerStoreSequenceHandler<
									AuxiliaryTableStateModel,
									ArticleRecapArticleList[]
								>(store, this.articleList$);
							default:
								return this.utilStoreService.contextManagerStoreSequenceHandler<
									void,
									ArticleRecapArticleList[]
								>(null, this.articleList$);
						}
					}),
					filter(store => !!(store && store.data)),
					concatMap(store =>
						this.utilStoreService.contextManagerStoreSequenceHandler<
							ArticleRecapArticleList[],
							OrderStateModel
						>(store, this.order$)
					),
					// [order]
					filter(
						(store: BaseStateModel<OrderStateModel>) =>
							!!(store && (store.data || store.type === OrderActionEnum.SKIP))
					),
					concatMap(store => {
						switch (store.type) {
							case OrderActionEnum.UPDATE:
								// lo store contiene l'ordine referenziato nel localstorage, di seguito utilizzato per settare
								// stateForeConcextResult.order
								return this.utilStoreService.contextManagerStoreSequenceHandler<
									OrderStateModel,
									OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]
								>(store, this.orderList$);
							default:
								return this.utilStoreService.contextManagerStoreSequenceHandler<
									void,
									OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]
								>(null, this.orderList$);
						}
					}),
					// [order-list]
					filter(
						(store: BaseStateModel<OrderStateModel[]>) =>
							!!(store && (store.data || store.type === OrderListActionEnum.SKIP))
					),
					map(store => {
						switch (store.type) {
							case OrderListActionEnum.UPDATE: {
								// questa casistica è implicitamente autenticata in quanto passa esclusivamente
								// da una chiamata che il FE fa direttamente a couch
								// viene recuperata solo la prima bozza (il flusso non dovrebbe permetterne più di una, ma...)
								const orderFromOrganization: OrderStateModel = store.data[0];
								// se presente anche un ordine referenziato nel localstorage, quello avente la data più
								// recente va in priorità. La condizione potrebbe essere da rivedere in futuro o comunque
								// diventare dinamica
								if (
									this.utilStoreService.stateForeConcextResult.order &&
									this.utilStoreService.stateForeConcextResult.order.header.date >
										orderFromOrganization.header.date
								) {
									// stateForeConcextResult.order prioritario, viene eliminata la bozza già appartenente all'
									// organization (questo previene l'accumulo di bozze e conseguenti effetti indesiderati)
									this.store.dispatch(OrderStateAction.remove(new BaseState(orderFromOrganization)));
									// stateForeConcextResult.order viene adeguato l'ordine per il salvataggio sul db della rispettiva
									// organization
									this.utilOrderService.saveOrderNewFromLocalStorageToOrganization(
										this.utilStoreService.stateForeConcextResult.order,
										this.utilStoreService.stateForeConcextResult.loginContextCode,
										this.utilStoreService.stateForeConcextResult.configuration,
										this.utilStoreService.stateForeConcextResult.article
									);
								} else {
									// stateForeConcextResult.order non prioritario o non presente
									// orderFromOrganization va in update su order store
									this.utilStoreService.contextManagerStoreSequenceHandler<OrderStateModel, void>({
										data: orderFromOrganization,
										type: OrderActionEnum.UPDATE
									});
									this.store.dispatch(OrderStateAction.update(new BaseState(orderFromOrganization)));
								}
								break;
							}
							case OrderListActionEnum.SKIP: {
								// la load restituisce type SKIP in caso avvenga la sua dispatch (in questa pipe)
								// oppure nessuna bozza venga trovata nel db dell'organization (in effect)
								if (this.utilStoreService.stateForeConcextResult.order) {
									if (
										this.authenticationToken &&
										this.utilStoreService.stateForeConcextResult.loginContextCode
									) {
										// se l'utente è autenticato e organizzation è a disposizione
										// stateForeConcextResult.order viene adeguato l'ordine per il salvataggio sul db della rispettiva
										// organization
										this.utilOrderService.saveOrderNewFromLocalStorageToOrganization(
											this.utilStoreService.stateForeConcextResult.order,
											this.utilStoreService.stateForeConcextResult.loginContextCode,
											this.utilStoreService.stateForeConcextResult.configuration,
											this.utilStoreService.stateForeConcextResult.article
										);
									} else {
										// se l'utente è ancora anonimo
										// stateForeConcextResult.order viene mandato in update su order store
										this.store.dispatch(
											OrderStateAction.update(
												new BaseState(this.utilStoreService.stateForeConcextResult.order)
											)
										);
									}
								}
								break;
							}
						}
					}),
					take(1)
				)
				.subscribe(() => resolve(this.utilStoreService.stateForeConcextResult));
		});
	}

	async retrieveStateForContext(
		context: ContextApplicationItemCodeEnum,
		data: ContextType
	): Promise<StateForeConcextResultInterface> {
		this.utilStoreService.stateForeConcextResult = {};
		if (context !== ContextApplicationItemCodeEnum.B2C) {
			// nella cistica B2C dispatchCommonStore() è invocato direttamente da loadStorefrontContent()
			// chiamato a sua volta anche fuori da retrieveStateForContext()
			this.dispatchCommonStore();
			this.utilStoreService.contextManagerStoreSequenceHandler<BodyTablePouchModel, void>({
				data: data,
				type: LoginContextCodeActionEnum.UPDATE
			});
		}
		switch (context) {
			case ContextApplicationItemCodeEnum.AGENT:
				data = data as BodyTablePouchModel;
				this.store.dispatch(AuxiliaryTableStateAction.load());
				this.store.dispatch(OrganizationListStateAction.loadAll());
				this.store.dispatch(
					StatisticsAgentStateAction.load(new BaseState({ code_item: this.currentPermission.code }))
				);
				return new Promise(resolve => {
					this.subscribeCommonStore()
						.pipe(
							filter(commonStore => !!commonStore),
							concatMap(() =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									void,
									AuxiliaryTableStateModel
								>(null, this.auxiliaryTable$)
							),
							filter(auxiliaryTable => !!(auxiliaryTable && auxiliaryTable.data)),
							concatMap(auxiliaryTable =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									AuxiliaryTableStateModel,
									StatisticsAgent
								>(auxiliaryTable, this.statisticsAgent$)
							),
							filter(statistics => !!(statistics && statistics.data)),
							concatMap(statistics =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									StatisticsAgent,
									OrganizationStateModel[]
								>(statistics, this.organizationList$)
							),
							filter(organizationList => !!(organizationList && organizationList.data)),
							map(organizationList =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									OrganizationStateModel[],
									void
								>(organizationList)
							),
							take(1)
						)
						.subscribe(() => resolve(this.utilStoreService.stateForeConcextResult));
				});
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				this.store.dispatch(AuxiliaryTableStateAction.load());
				this.store.dispatch(OrganizationListStateAction.loadAll());
				this.store.dispatch(StatisticsBackofficeStateAction.load(new BaseState({ code_item: this.currentPermission.code })));
				return new Promise(resolve => {
					this.subscribeCommonStore()
						.pipe(
							filter(commonStore => !!commonStore),
							concatMap(() => {
								// TODO: il backoffice dovrebbe vedere sia il default PRIVATE che COMPANY
								this.store.dispatch(ArticleStateAction.loadFromRecap({
									dataSetting: {
										appliedFilter: {
											organization: {
												code_item: this.utilStoreService.stateForeConcextResult.loginContextCode.code_item,
												organization_type: OrganizationTypeEnum.COMPANY
											}
										}
									},
									data: null
								}));
								return this.utilStoreService.contextManagerStoreSequenceHandler<
									void,
									AuxiliaryTableStateModel
								>(null, this.auxiliaryTable$)
							}),
							filter(auxiliaryTable => !!(auxiliaryTable && auxiliaryTable.data)),
							concatMap(auxiliaryTable =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									AuxiliaryTableStateModel,
									ArticleRecapArticleList[]
								>(auxiliaryTable, this.articleList$)
							),
							filter(articleList => !!(articleList && articleList.data)),
							concatMap(articleList =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									ArticleRecapArticleList[],
									StatisticsBackoffice
								>(articleList, this.statisticsBackoffice$)
							),
							filter(statistics => !!(statistics && statistics.data)),
							concatMap(statistics =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									StatisticsBackoffice,
									OrganizationStateModel[]
								>(statistics, this.organizationList$)
							),
							filter(organizationList => !!(organizationList && organizationList.data)),
							map(organizationList =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									OrganizationStateModel[],
									void
								>(organizationList)
							),
							take(1)
						)
						.subscribe(() => resolve(this.utilStoreService.stateForeConcextResult));
				});
			case ContextApplicationItemCodeEnum.CRM:
				this.store.dispatch(AuxiliaryTableStateAction.load());
				this.store.dispatch(
					StatisticsCrmStateAction.load(new BaseState({ code_item: this.currentPermission.code }))
				);
				return new Promise(resolve => {
					this.subscribeCommonStore()
						.pipe(
							filter(commonStore => !!commonStore),
							concatMap(() =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									void,
									AuxiliaryTableStateModel
								>(null, this.auxiliaryTable$)
							),
							filter(auxiliaryTable => !!(auxiliaryTable && auxiliaryTable.data)),
							concatMap(auxiliaryTable =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									AuxiliaryTableStateModel,
									StatisticsCrm
								>(auxiliaryTable, this.statisticsCrm$)
							),
							// TODO: attualmente vuoto. Una volta gestito rafforzare il filter con .data
							filter(statistics => !!statistics),
							map(statistics =>
								this.utilStoreService.contextManagerStoreSequenceHandler<StatisticsCrm, void>(
									statistics
								)
							),
							take(1)
						)
						.subscribe(() => resolve(this.utilStoreService.stateForeConcextResult));
				});
			case ContextApplicationItemCodeEnum.B2B:
			case ContextApplicationItemCodeEnum.PORTAL:
				this.store.dispatch(AuxiliaryTableStateAction.load());
				this.utilStoreService.contextManagerStoreSequenceHandler<BodyTablePouchModel, void>({
					data: data,
					type: OrganizationActionEnum.UPDATE
				});
				this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(<OrganizationPouchModel>data)));
				this.store.dispatch(StatisticsOrganizationStateAction.load(new BaseState({ code_item: this.currentPermission.code })));
				return new Promise(resolve => {
					this.subscribeCommonStore()
						.pipe(
							filter(commonStore => !!commonStore),
							concatMap(() =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									void,
									AuxiliaryTableStateModel
								>(null, this.auxiliaryTable$)
							),
							filter(auxiliaryTable => !!(auxiliaryTable && auxiliaryTable.data)),
							concatMap(auxiliaryTable =>
								this.utilStoreService.contextManagerStoreSequenceHandler<
									AuxiliaryTableStateModel,
									StatisticsOrganization
								>(auxiliaryTable, this.statisticsOrganization$)
							),
							filter(statistics => !!(statistics && statistics.data)),
							map(statistics =>
								this.utilStoreService.contextManagerStoreSequenceHandler<StatisticsOrganization, void>(
									statistics
								)
							),
							take(1)
						)
						.subscribe(() => resolve(this.utilStoreService.stateForeConcextResult));
				});
			case ContextApplicationItemCodeEnum.B2C:
				return this.loadStorefrontContent(data as OrganizationPouchModel);
			default:
				return new Promise(resolve => resolve(this.utilStoreService.stateForeConcextResult));
		}
	}

	/**
	 * Restituisce il completamento degli gli observable comuni a tutti i context application & storefront
	 *
	 * @returns {Observable<boolean>}
	 * @memberof ContextManagerService
	 */
	subscribeCommonStore(): Observable<any> {
		return new Observable(subscriber => {
			this.articleDescription$
				.pipe(
					filter(articleDescription => !!(articleDescription && articleDescription.data)),
					concatMap(articleDescription =>
						this.utilStoreService.contextManagerStoreSequenceHandler<ArticleRecap, ConfigurationViewModel>(
							articleDescription,
							this.configuration$
						)
					),
					filter(configuration => !!(configuration && configuration.data)),
					concatMap(configuration =>
						this.utilStoreService.contextManagerStoreSequenceHandler<
							ConfigurationViewModel,
							AuxiliaryTabeleGeographicTree.StateModel
						>(configuration, this.auxiliaryTableGeographicTree$)
					),
					filter(
						auxiliaryTableGeographicTree =>
							!!(auxiliaryTableGeographicTree && auxiliaryTableGeographicTree.data)
					),
					concatMap(auxiliaryTableGeographicTree =>
						this.utilStoreService.contextManagerStoreSequenceHandler<
							AuxiliaryTabeleGeographicTree.StateModel,
							CategoryMap
						>(auxiliaryTableGeographicTree, this.categoryList$)
					),
					filter(
						categoryList =>
							!!(
								categoryList &&
								(categoryList.data || categoryList.type === CategoryListActionEnum.NOT_EXISTING)
							)
					),
					map(categoryList =>
						this.utilStoreService.contextManagerStoreSequenceHandler<CategoryMap, void>(categoryList)
					),
					take(1)
				)
				.subscribe(() => subscriber.next(this.utilStoreService.stateForeConcextResult));
		});
	}
}
