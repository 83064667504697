import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	BaseStateModel,
	FormControlMultipurposeEnum,
	FormControlMultipurposeModel,
	FormControlMultipurposeService,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import { ContactPouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { ContactModel } from '../../../model/contact.model';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { ContextApplicationItemCodeEnum, UserDetailModel, AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerContextApplication from '../../../constants/configuration-customer/context-application/context-application.constant';
import * as _ from 'lodash';

interface ExtendedContactPouchModel extends ContactPouchModel {
	organization?: OrganizationPouchModel;
}

@Component({
	selector: 'dialog-contact-detail',
	templateUrl: './dialog-contact-detail.component.html',
	styleUrls: ['./dialog-contact-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogContactDetailComponent {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	form: FormGroup;
	organizationCodeItem: string;
	canEdit: boolean;
	formFieldList: FormControlMultipurposeModel.Item[];

	subscribeFormDataPrefix = 'context-code-edit-form-value-change_';

	formControlConfiguration: FormControlMultipurposeModel.ConfigurationEdit;

	constructor(
		private store: Store,
		public dialogRef: MatDialogRef<DialogContactDetailComponent>,
		private formFieldMultipurposeService: FormControlMultipurposeService,
		private utilService: AngularCoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
		@Inject(MAT_DIALOG_DATA) public data,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		this.organizationCodeItem = data['organizationCodeItem'];
		this.canEdit = this.data.canEdit;

		const initialFormValue: ContactModel = this.initializeContact(this.data.contact);
		const formControlList: FormControlMultipurposeModel.Item[] =
			_.cloneDeep(
				ConfigurationCustomerContextApplication
				.formControlMultipurpose[this.user.current_permission.context_application]
				.CONTACT
			);
		if (!this.organizationCodeItem) {
			// la dialog non è stata aperta dall'interno di organization-detail
			const isMainOfListField = formControlList.find(i => i.name === 'is_main_of_list');
			if (isMainOfListField) {
				isMainOfListField.on_form_template = false;
			}
		}

		this.formControlConfiguration = {
			formControlList:
				this.utilTranslateService.returnCreationMapWithProjectLanguageListOptionList(formControlList),
			value: initialFormValue
		};
		// form-control-multipurpose: recupero della mappa di form field, specifica per la
		// combinazione di context applicatione e context code in creazione
		this.formFieldList = this.formFieldMultipurposeService.returnFormFieldListWithRootFormId(
			this.formControlConfiguration.formControlList,
			`${this.utilService.guid()}`
		);
		// creazione di un nuovo BehaviorSubject in rootFormValueList, l'associazione avviene
		// attraverso form_id, uguale in ogni elemento della mappa formFieldList
		this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, null);
		this.formFieldList = this.formFieldMultipurposeService.updateFormFieldListValue(
			this.formFieldList,
			initialFormValue
		);
		this.createForm();

		this.formFieldMultipurposeService.updateFormControlAccordingToType(
			this.form,
			<FormControlMultipurposeModel.Item>{
				type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
				form_control_list: this.formFieldList
			},
			initialFormValue
		);

		if (!this.canEdit) {
			this.formFieldList =
			this.formFieldMultipurposeService.returnFormFieldListWithAllDisabled(this.formFieldList);
		}
	}

	initializeContact(fullContact): ContactModel {
		if (fullContact) {
			const contactForm = <ContactModel>{
				first_name: fullContact.first_name || null,
				last_name: fullContact.last_name || null,
				role: fullContact.role || null,
				area: fullContact.area || null,
				email: fullContact.email || null,
				phone: fullContact.phone || null,
				phone_mobile: fullContact.phone_mobile || null,
				fax: fullContact.fax|| null,
				is_main_of_list: fullContact.is_main_of_list || false,
				has_notification: fullContact.has_notification || false,
				language: fullContact.language || null
			};
			if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.CRM) {
				contactForm.phone_mobile = fullContact.phone_mobile || null;
				contactForm.fax = fullContact.fax || null;
			}
			return contactForm;
		}
		return new ContactModel();
	}

	/**
	 *  Subscriptions
	 */

	subscribeFormData(): Observable<any> {
		return this.form.valueChanges.pipe(
			// form-control-multipurpose ha bisigno di propagare eventi di change da qualsiasi livello
			// di annidamento verso il root form, inclusi gli eventi updateValueAndValidity(),
			// il take(1) impedisce un loop infinito dovuto al riassestamento dei validatori in base a value
			take(1),
			debounceTime(100),
			map(async value => {
				value = this.utilService.deleteEmptyProperties(value);
				// viene propagato il valore del root form a tutte le istanze di form-control-multipurpose
				this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, value);
				// aggiorna i validatori del root form in base a value e scatena updateValueAndValidity()
				// per ognuno
				await this.formFieldMultipurposeService.updateFormControlAccordingToType(
					this.form,
					<FormControlMultipurposeModel.Item>{
						type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
						form_control_list: this.formFieldList
					},
					value
				);

				// dopo il trigger viene sottoscritta nuovamente la stessa subscribe in modo da riattivare
				// il listener
				this.subscribeManagerService.populate(
					this.subscribeFormData().subscribe(),
					this.subscribeFormDataPrefix + this.formFieldList[0].form_id
				);
			})
		);
	}

	/**
	 *  Form
	 */

	createForm() {
		const controls = {};
		for (const field of this.formFieldList) {
			controls[field.name] = this.formFieldMultipurposeService.returnNewFormControl(
				field,
				field.value || (this.data.contact ? this.data.contact[field.name] : null)
			);
		}
		this.form = new FormGroup(controls);
		this.subscribeManagerService.populate(
			this.subscribeFormData().subscribe(),
			this.subscribeFormDataPrefix + this.formFieldList[0].form_id
		);
	}

	async saveContact() {
		const isCreateMode = !this.data.contact;
		if (this.form.valid) {
			// compose full name
			const data = this.form.getRawValue();
			let fullName = data['first_name'];
			if (data['last_name']) {
				fullName = fullName + ' ' + data['last_name'];
			}

			// initialize contact
			let contact_to_save: ExtendedContactPouchModel = {};
			if (!isCreateMode) {
				contact_to_save = this.data.contact;
			}
			for (const key in this.form.value) {
				contact_to_save[key] = this.form.value[key];
			}

			// assign form values to contact
			contact_to_save.full_name = fullName;
			const has_notification = data['has_notification'] || false;
			if (has_notification !== undefined && has_notification !== null) {
				contact_to_save.has_notification = data['has_notification'];
			}

			// add new-contact properties
			if (isCreateMode) {
				const today_millis = new Date().getTime();
				contact_to_save.date_creation = today_millis;
				contact_to_save.type = 'contact';
				contact_to_save.valid = true;
				if (this.organizationCodeItem) {
					contact_to_save.organization = { code_item: this.organizationCodeItem, valid: true };
				}
			}

			if (contact_to_save) {
				this.dialogRef.close(this.utilService.deleteEmptyProperties(contact_to_save));
			}
		}
	}
}
