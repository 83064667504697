import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseState, BaseStateModel, DialogConfirmComponent, SubscribeManagerService } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { ExtraFieldOrderHeaderPouchModel, OrderStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { filter, map, Observable, take } from 'rxjs';
import { StateFeature } from '../../../../../state';
import * as ConfigurationCustomerOrder from '../../../../../constants/configuration-customer/order/order.constant';
import { UtilOrderService } from '../../../../../service/util/util-order.service';
import { OrderListStateAction } from '../../../../../state/order-list/order-list.actions';
import { ActivatedRoute } from '@angular/router';
import { OrderPouchModel } from '@saep-ict/pouch_agent_models';
import { OrderStateAction } from '../../../../../state/order/order.actions';

@Component({
	selector: 'order-detail-confirm',
	templateUrl: './order-detail-confirm.component.html',
	styleUrls: ['./order-detail-confirm.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailConfirmComponent implements OnDestroy {
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: OrderStateModel[];

	constructor(
		private dialog: MatDialog,
		private translateService: TranslateService,
		private store: Store,
		public utilOrderService: UtilOrderService,
		private route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.store.dispatch(OrderListStateAction.loadAll());
		const orderCode: string = this.route.snapshot.paramMap.get('orderId');

		this.user$.pipe(take(1)).subscribe((res: BaseState<UserDetailModel>) => {
			if (res) {
				this.user = res.data;
			} else {
				this.user = null;
			}
		});

		this.subscribeManagerService.populate(
			this.orderList$
				.pipe(
					filter((orderList: BaseStateModel<OrderStateModel[]>) => !!(orderList && orderList.data)),
					map(res => {
						this.order = res.data.find(x => x._id === orderCode || x.code_item === orderCode);
						this.store.dispatch(OrderStateAction.update(new BaseState(this.order)));
					})
				)
				.subscribe(),
			'subscribeOrders'
		);

		this.subscribeManagerService.populate(this.subscribeOrder().subscribe(), 'subscribeOrders');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeOrder() {
		return this.order$.pipe(
			filter((orderState: BaseStateModel<OrderStateModel>) => !!(orderState && orderState.data)),
			map((orderState: BaseStateModel<OrderStateModel>) => (this.order = orderState.data))
		);
	}

	// Valida testata ordine
	headerOrderValidation() {
		console.log(this.order);
		const title = this.translateService.instant('order.header_edit');
		const text = this.translateService.instant('order.question.revision_header');
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true
			},
			panelClass: ['michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				// Salvare nuovo status order su CoachDB
				const updatedOrder = _.cloneDeep(this.order);
				updatedOrder.header.status = ConfigurationCustomerOrder.orderToSendStatus;
				console.log(updatedOrder.header.status);
				this.utilOrderService.updateOrderSource.next({
					order: updatedOrder,
					useLoader: true,
					statusChange: true
				});
			}
			console.log(this.order);
		});
	}
}
