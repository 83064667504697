import { createAction, props } from '@ngrx/store';
import { ArticleRecap } from '../../model/state/article-list-state.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleDescriptionItemRest } from '@saep-ict/pouch_agent_models';

export enum ArticleDescriptionActionEnum {
	LOAD_DESCRIPTION_FROM_RECAP = '[Article Description] Load description from recap',
	UPDATE = '[Article Description] Update',
	SAVE = '[Article Description] Save',
	SAVE_COMPLETE = '[Article Description] Save complete',
	RESET = '[Article Description] Reset',
	ERROR = '[Article Description] Error'
}

export const ArticleDescriptionStateAction = {
	loadDescriptionFromRecap: createAction(ArticleDescriptionActionEnum.LOAD_DESCRIPTION_FROM_RECAP),
	update: createAction(ArticleDescriptionActionEnum.UPDATE, props<BaseStateModel<ArticleRecap>>()),
	saveComplete: createAction(ArticleDescriptionActionEnum.SAVE_COMPLETE, props<BaseStateModel<ArticleRecap>>()),
	save:
		createAction(ArticleDescriptionActionEnum.SAVE, props<BaseStateModel<ArticleDescriptionItemRest>>()),
	reset: createAction(ArticleDescriptionActionEnum.RESET),
	error: createAction(ArticleDescriptionActionEnum.ERROR, props<BaseStateModel<any>>())
};
