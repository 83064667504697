<div class="__container">
  <div class="___row">
    <div class="__col-md-12 breadcrumb-container mb-20">
      <td-search-box
        backIcon="search"
        [placeholder]="'mc.general.search_item' | translate"
        [showUnderline]="false"
        [debounce]="500"
        [alwaysVisible]="true"
        [(ngModel)]="filter.text"
        (searchDebounce)="filterTextChange()"
      >
      </td-search-box>
    </div>
    <div class="__col-md-12 breadcrumb-container mb-20">
      <mc-breadcrumb
        [configuration]="filter"
        (pathChange)="breadcrumbPathChange($event)"
      ></mc-breadcrumb>
    </div>
  </div>
</div>
<div class="____container">
  <!-- <div class="____row"> -->
  <div
    [ngClass]="
      utilService.checkAction(currentFolder, permissionEnum.UPLOAD) ||
      utilService.checkAction(currentFolder, permissionEnum.CREATE)
        ? '____col-md-9'
        : '____col-md-12'
    "
    class="td-data-table-container"
  >
    <td-data-table
      #dataTable
      *ngIf="
        data &&
          data.list &&
          data.list.length > 0 &&
          utilService.context &&
          utilService.context.value;
        else noDataFound
      "
      [(ngModel)]="utilService.itemListSelected"
      [data]="data.list"
      [columns]="columns"
      [sortable]="true"
      [selectable]="
        utilService.context.value.filter.avoidItemSelection ||
        utilService.context.value.filter.text
          ? false
          : true
      "
      [multiple]="true"
      (sortChange)="tableSortChange($event)"
      [clickable]="true"
      (rowClick)="goToFolder($event.row)"
    >
      <ng-template tdDataTableTemplate="name" let-row="row">
        <div
          class="item-type"
          [ngClass]="utilService.mapIconColor(row.fileType.toLowerCase())"
        >
          <mat-icon
            *ngIf="row.fileType === 'folder'" 
            [ngClass]="utilService.mapIconColor(row.fileType.toLowerCase())"
            >{{ utilService.mapIcon(row.fileType.toLowerCase()) }}
          </mat-icon>
          <preview-container
            *ngIf="row.fileType !== 'folder'" 
            [configuration]="
              {
                image: row.bucket_link_thumbnail,
                icon: 'attach_file',
                width: '30px',
                height: '50px',
                forceSquareRatio: true,
                onClickHandler: true
              }
            "
            (onClick)="openDialogPreview(row)"
          >
          </preview-container>
        </div>
        <span
          [matTooltip]="row.name"
          matTooltipPosition="right"
          matTooltipClass="name-tooltip"
          class="cell-name"
        >
          {{ row.name }}
        </span>
      </ng-template>
      <ng-template tdDataTableTemplate="opzioni" let-row="row">
        <button
            *ngIf="
              utilService.checkAction(currentFolder, permissionEnum.READ) &&
              utilService.context.value.filter.actionDownloadOnly &&
              (
                row.fileType !== 'folder' ||
                (
                  row.fileType === 'folder' &&
                  utilService.context.value.filter.actionDownloadArchive
                )
              )
            "
            mat-icon-button
            (click)="utilDownloadService.downloadHandler($event, row);"
          >
            <mat-icon class="download">download</mat-icon>
        </button>
        <button
          *ngIf="!utilService.context.value.filter.actionDownloadOnly"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container
            *ngIf="
              utilService.checkAction(currentFolder, permissionEnum.READ) &&
              utilService.context.value.filter.actionDownloadArchive &&
              utilService.itemListSelected &&
              utilService.itemListSelected.length > 0
            "
          >
            <button mat-menu-item (click)="utilDownloadService.downloadHandler($event)">
              <ng-container *ngTemplateOutlet="downloadSelectedListLabel"></ng-container>
            </button>
            <mat-divider></mat-divider>
          </ng-container>
          <button
            mat-menu-item
            *ngIf="utilService.checkAction(currentFolder, permissionEnum.READ)"
            (click)="utilDownloadService.downloadHandler($event, row)"
          >
            <span>
              {{
                ('mc.general.download' | translate) +
                  ' ' +
                  ('mc.general.item' | translate) | titlecase
              }}
            </span>
          </button>
          <button
            mat-menu-item
            *ngIf="
              utilService.checkAction(currentFolder, permissionEnum.READ) &&
              utilService.context.value.filter.text
            "
            (click)="goToItemFolder(row)"
          >
            <span>{{
              'mc.general.show_file_folder' | translate | titlecase
            }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="
              utilService.checkAction(currentFolder, permissionEnum.DELETE)
            "
            (click)="openDialogDelete(row)"
          >
            <span>{{ 'mc.general.delete' | translate | titlecase }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="
              utilService.checkAction(currentFolder, permissionEnum.CREATE)
            "
            (click)="openDialogNameEdit(row)"
          >
            <span>{{ 'mc.general.rename' | translate | titlecase }}</span>
          </button>
        </mat-menu>
      </ng-template>
    </td-data-table>
    <ng-template #noDataFound>
      <p class="no-data-found">
        {{ 'mc.general.no_item_found' | translate }}
      </p>
    </ng-template>
    <button
      *ngIf="
        utilService.itemListSelected &&
        utilService.itemListSelected.length > 0 &&
        utilService.context.value.filter.actionDownloadArchive
      "
      mat-button
      (click)="utilDownloadService.downloadHandler($event)"
    >
      <ng-container *ngTemplateOutlet="downloadSelectedListLabel"></ng-container>
    </button>
  </div>
  <div
    *ngIf="
      utilService.checkAction(currentFolder, permissionEnum.UPLOAD) ||
      utilService.checkAction(currentFolder, permissionEnum.CREATE)
    "
    class="____col-md-3 sidebar"
  >
    <media-center-home-sidebar
      *ngIf="currentFolder"
      [currentFolder]="currentFolder"
      (sidebarEventSuccess)="getFolderContent()"
    >
    </media-center-home-sidebar>
  </div>
  <!-- </div> -->
</div>

<!-- ng-template -->
<ng-template #downloadSelectedListLabel>
  <span>
    {{ ('mc.general.download' | translate) + ' ' + ('mc.general.selected_list' | translate) | titlecase }}
  </span>
</ng-template>
