import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ContextCodeFilterModel } from '../../service/pouch-db/filter/context-code-filter.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { LanguageListStateAction, LanguageListActionEnum } from './language-list.actions';
import { LanguageStateModel } from '@saep-ict/angular-spin8-core';

export namespace LanguageListStateReducer {
	export const initialState: BaseStateModel<LanguageStateModel[], ContextCodeFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(LanguageListStateAction.loadAll, state => state),
		on(LanguageListStateAction.update, (state, param) => param),
		on(LanguageListStateAction.reset, () => initialState),
		on(LanguageListStateAction.error, (state, param) => param)
	);

	export function reducer(state: BaseStateModel<LanguageStateModel[]>, action: TypedAction<LanguageListActionEnum>) {
		return _reducer(state, action);
	}
}
