export namespace PouchDbModel {
  export enum PouchDbDocumentType {
    AGENT = 'agent',
    ARTICLE = 'article',
    BACKOFFICE = 'backoffice',
    CONTACT = 'contact',
    CRM = 'crm',
    OPPORTUNITY = 'opportunity',
    ORDER = 'order',
    ORGANIZATION = 'organization',
    REQUEST = 'request',
    STATISTICS = 'statistics',
    TICKET = 'ticket',
    USER = 'user',
    USER_NOTIFICATION = "user_notification"
  }
}
