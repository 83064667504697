export const ROUTE_URL = {
	// BASE ROUTING
	private: 'dashboard',
	public: '',
	privacyPolicy: 'privacy-policy',
	probeTest: 'probe-test',
	verifyTokenError: 'verify-token-error',
	authentication: 'authentication',
	login: 'login',
	passwordRecovery: 'password-recovery',
	passwordUpdate: 'password-update',
	contextSelection: 'context-selection',
	accessRequest: 'access-request',
	startupDownload: 'startup-download',
	termsAndConditions: 'terms-and-conditions',
	deliveryPolicy: 'delivery-policy',
	returnPolicy: 'return-policy',
	contact: 'contact',
	news: 'news',
	allNews: 'all-news',
	new: 'new',
	archive: 'archive',
	cookiePolicy: 'cookie-policy',
	surveyCenter: 'survey-center',
	allSurvey: 'all-survey',
	budget: 'budget',
	forecast: 'forecast',
	mediaMarketing: 'media-marketing',
	reports: 'reports',

	// COURTESY ROUTE
	courtesy: 'courtesy',

	// BACKOFFICE
	userManagement: 'user-management',
	configurations: 'configurations',
	contextCodeManagement: 'context-code-management',
	mediaCenter: 'media-center',
	ticketCenter: 'ticket-center',
	organizations: 'organizations',
	categories: 'categories',
	articles: 'articles',
	families: 'families',
	requests: 'requests',
	informativePage: 'informative-page',
	productStock: 'product-stock',
	allRequests: 'all-requests',
	openRequests: 'open-requests',
	closedRequests: 'closed-requests',
	additionalServiceConfiguration: 'additional-service-configuration',

	// B2B
	user: 'user',
	company: 'company',

	// AGENT
	overview: 'overview',
	credits: 'credits',
	details: 'details',
	variation: 'variation',
	contacts: 'contacts',
	dashboardOrders: 'dashboard-orders',
	dashboardSold: 'dashboard-sold',
	dashboardClients: 'dashboard-clients',
	dashboardExpired: 'dashboard-expired',
	receipts: 'receipts',

	// B2C
	product: 'product',
	cart: 'cart',
	searchResults: 'search-results',
	checkout: 'checkout',
	createAccount: 'create-account',
	reservedArea: 'reserved-area',
	myAccount: 'my-account',
	userData: 'user-data',

	// CRM
	dashboardCrm: 'dashboard-crm',
	newOpportunity: 'new',
	opportunity: 'opportunity',
	opportunityKanban: 'opportunity-kanban',
	actions: 'actions',
	logs: 'logs',
	offer: 'offer',
	opportunityDetail: 'detail',
	opportunityNote: 'note',
	opportunityActivity: 'activity',
	opportunityOffer: 'offer',
	opportunityLog: 'log',
	comments: 'comments',
	offerHead: 'head',
	offerBody: 'body',
	newOffer: 'new',
	newAction: 'new',
	attachment: 'attachment',

	// COMMONS
	addresses: 'addresses',
	assetRequestTicketList: 'asset-request-ticket-list',
  assetRequestCenter: "asset-request-center",
	imageVideo: 'image-video',
	printProductionFlow: 'print-production-flow',
	catalog: 'catalog',
	orders: 'orders',
	upload: 'upload'
};

export const PATH_URL = {
	PRIVATE: `/${ROUTE_URL.private}`,
	CART: `/${ROUTE_URL.public}/${ROUTE_URL.cart}`,
	MY_ACCOUNT: `/${ROUTE_URL.private}/${ROUTE_URL.myAccount}`
};
