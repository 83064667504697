<button
  mat-raised-button
  *ngFor="
      let item of
      configuration.ticket.statusContextApplicationStatusMap[
        contextApplicationItemCodeEnum[
          user.current_permission.context_application
        ]
      ].selection_list"
  (click)="changeStatus(item.value)"
  [disabled]="item.value === currentStatus"
  [ngStyle]="{'background-color': item.color}"
>
  {{item.name ? item.name : item.value}}
</button>
