<app-page-header
	[title]="
		(informativePage
			? utilTranslateService.getTranslationFromLanguage(informativePage.language_list).title
			: 'informative_page.not_found'
		)
			| translate
			| sentencecase
	"
	[backgroundImageUrl]="'theme.header_bg.informative_page.backgroundImageUrl' | translate"
></app-page-header>

<div class="container">
	<div class="row">
		<div class="col-12 mt-32 mb-32">
			<div
				*ngIf="informativePage; else notFound"
				class="ql-editor"
				[innerHtml]="utilTranslateService.getTranslationFromLanguage(informativePage.language_list).body"
			></div>
		</div>
	</div>
</div>
<ng-template #notFound>
	<h1>{{ 'informative_page.not_found' | translate | sentencecase }}</h1>
</ng-template>
