import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { CategoryDetailAction, CategoryDetailActionEnum } from './category-detail.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { Category } from '@saep-ict/pouch_agent_models';

export const initialState: BaseStateModel<Category> = null;

const _reducer = createReducer(
	initialState,
	on(CategoryDetailAction.update, (state, param) => param),
	on(CategoryDetailAction.load, state => state),
	on(CategoryDetailAction.save, state => state),
	on(CategoryDetailAction.saveUpdate, state => {
		return { type: CategoryDetailActionEnum.SAVE_UPDATE } as BaseStateModel<Category>}
	),
	on(CategoryDetailAction.reset, state => null)
);

export function reducer(state: BaseStateModel<Category>, action: TypedAction<CategoryDetailActionEnum>) {
	return _reducer(state, action);
}
