import { ISaepIctAngularSpin8CoreAppConfig } from "../../model/structure/lib-app-config.interface";

// projects/angular-spin8-core/src/misc/util/util-structure.ts:6:33:
// Metadata collected contains an error that will be reported at runtime: Lambda not supported.

// @dynamic
export class UtilStructure {

  public static loadLibConfig = (appConfig: ISaepIctAngularSpin8CoreAppConfig) => {
    return () => {
      return new Promise(async (resolve, reject) => {
        appConfig['config$'].subscribe(async config => {
          if (config && config.saepIctAngularSpin8Core) {
            appConfig.initializeSaepIctAngularSpin8Core(config.saepIctAngularSpin8Core);
            resolve(null);
          } else {
            throw new Error(
              'saepIctAngularSpin8Core field is not present in environment configuration'
            );
          }
        });
      });
    };
  };

}
