import moment from 'moment'

export class MatDatepickerFilterCustom {

  public static excludeWeekend(d: Date): boolean {
    const day = moment(d).day();
    return day !== 0 && day !== 6;
  }

}
