import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models';

export const list: BodyTablePouchModel[] = [
    {
        code_item: 'icon1',
        description: 'icon1'
    },
    {
        code_item: 'icon2',
        description: 'icon2'
    }
];