import { Type } from '@angular/core';
import { ILoaderService } from '../interface/loader-service.interface';
import { IMediaCenterAppConfig } from '../interface/media-center-app-config.interface';

export interface IEnvironment {
	[key: string]: boolean;
}

export interface IMediaCenterModelOptions {
	env: IEnvironment;
	loaderSerivce?: Type<ILoaderService>;
	appConfig: Type<IMediaCenterAppConfig>;
}
