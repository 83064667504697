<div class="article-stock-wrapper-base" class="{{columnWidthConfigurationClass}}" [ngClass]="{'sidebarclosed':sidebarClosed}">
	<div class="head-wrapper">
		<div
			*ngFor="
				let column of _localListHandlerData.columnList
					| filterByAllTheBooleans
						: {
								propertyListName: ['hidden'],
								value: false
						  }
			"
		>
			{{ column.labelPath | translate: column.labelPathParam | sentencecase }}
		</div>
	</div>
	<!-- NO virtual scroll: la tabella renderizza gli elementi in base all'eventuale azione
		della paginazione di default -->
	<div
		*ngIf="
			!(virtualScrollConfiguration) ||
			(
				virtualScrollConfiguration &&
				!virtualScrollConfiguration.enabled
			)
		"
		class="rows-wrapper"
	>
		<div
			*ngFor="let row of _localListHandlerData.dataSubset"
			class="content-wrapper"
		>
			<ng-container *ngTemplateOutlet="rowLoop; context: { row: row }"></ng-container>
		</div>
	</div>
	<!-- virtual scroll -->
	<div
		*ngIf="
			virtualScrollConfiguration &&
			virtualScrollConfiguration.enabled
		"
		#virtualScrollContainer
		class="rows-wrapper"
	>
		<cdk-virtual-scroll-viewport
			*ngIf="
				virtualScrollConfiguration.itemHeight &&
				virtualScrollConfiguration.viewportHeight
			"
			[itemSize]="virtualScrollConfiguration.itemHeight"
			[style.height.px]="virtualScrollConfiguration.viewportHeight"
		>
			<div
				*cdkVirtualFor="let row of _localListHandlerData.dataSubset"
				class="content-wrapper"
			>
				<ng-container *ngTemplateOutlet="rowLoop; context: { row: row }"></ng-container>
			</div>
		</cdk-virtual-scroll-viewport>
	</div>
</div>
<!-- ng-template -->
<ng-template #rowLoop let-row="row">
	<div
		*ngFor="
			let column of _localListHandlerData.columnList
				| filterByAllTheBooleans
					: {
							propertyListName: ['hidden'],
							value: false
					}
		"
	>
		<ng-container [ngSwitch]="column.name">
			<!-- Availability -->
			<div *ngSwitchCase="'articleDescription.stock.qty_available'">
					<ng-template #qtyAvailable let-value="value"> 
                        <span [matTooltip]="value"
						matTooltipPosition="right">{{ value }}</span> 
                    </ng-template>
                    <ng-container
					*ngTemplateOutlet="
                    qtyAvailable;
						context: {
							value: row.stock?.qty_available
						}
					"
				>
				</ng-container>
			</div>
			<!-- Stock -->
			<div *ngSwitchCase="'articleDescription.stock.qty_current'">
					<ng-template #qtyAvailable let-value="value"> 
						<span [matTooltip]="value"
						matTooltipPosition="right">{{ value }}</span> 
					</ng-template>
					<ng-container
					*ngTemplateOutlet="
					qtyAvailable;
						context: {
							value: row.stock?.qty_current
						}
					"
				>
				</ng-container>
			</div>
			<!-- Allocated -->
			<div *ngSwitchCase="'articleDescription.stock.qty_commited'">
					<ng-template #qtyAvailable let-value="value"> 
						<span [matTooltip]="value" 
						matTooltipPosition="right">{{ value }}</span> 
					</ng-template>
					<ng-container
					*ngTemplateOutlet="
					qtyAvailable;
						context: {
							value: row.stock?.qty_commited
						}
					"
				>
				</ng-container>
			</div>	
			<!-- Incoming -->
			<div *ngSwitchCase="'articleDescription.stock.qty_incoming'">
					<ng-template #qtyAvailable let-value="value"> 
						<span [matTooltip]="value"
						matTooltipPosition="right">{{ value }} </span>
					</ng-template>
					<ng-container
					*ngTemplateOutlet="
					qtyAvailable;
						context: {
							value: row.stock?.qty_incoming
						}
					"
				>
				</ng-container>
			</div>		
			<!-- code_erp -->
			<div *ngSwitchCase="'code_erp'">
				<ng-container
					*ngTemplateOutlet="code_; context: { code: row.code_erp, row: row }"
				></ng-container>
			</div>
			<!-- Description -->
			<div *ngSwitchCase="'description'">
				<ng-template #descriptionValue let-value="value">
					<span [matTooltip]="value">{{ value }}</span>
				</ng-template>
				<ng-container
					*ngTemplateOutlet="
						descriptionValue;
						context: {
							value: row.description?.article
						}
					"
				>
				</ng-container>
			</div>
			<!-- Brand -->
			<div *ngSwitchCase="'articleDescription.stock.article_description.brand'">
				<ng-template #brandValue let-value="value">
					<span [matTooltip]="value">{{ value }}</span>
				</ng-template>
				<ng-container
					*ngTemplateOutlet="
						brandValue;
						context: {
							value: row.description?.brand
						}
					"
				>
				</ng-container>
			</div>
				<!-- Line -->
				<div *ngSwitchCase="'articleDescription.stock.article_description.line'">
					<ng-template #lineValue let-value="value">
						<span [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<ng-container
						*ngTemplateOutlet="
							lineValue;
							context: {
								value: row.description?.line
							}
						"
					>
					</ng-container>
				</div>
			<!-- default -->
			<div
				*ngSwitchDefault
				articleTableWrapperCellDefaultHandler
				[configuration]="{ row: row, name: column.name }"
			></div>
		</ng-container>
	</div>
</ng-template>

<!-- TODO: valutare se ridondare la dinamica nelle due diverse celle code_item/erp serva a guadagnare qualcosa nelle
tempistiche di rendering -->
<ng-template #code_ let-code="code" let-row="row">
	<a *ngIf="onItemSelectShow; else codeWithoutLink" (click)="onItemSelect.emit(row)" href="javascript:void(0)">
		<ng-container *ngTemplateOutlet="codeWithoutLink"></ng-container>
	</a>
	<ng-template #codeWithoutLink>{{ code }}</ng-template>
</ng-template>
