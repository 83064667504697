import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import {
  APP_CONFIG_TOKEN,
  ITicketCenterAppConfig,
} from '../model/lib-app-config.interface';
import { TranslationUseEnum } from '../model/enum/translation-use-type.enum';
import {
  TranslationConfigException,
  TranslationValueException,
} from '../model/exception/translation-exception.error';

const deepTransCopy = function (obj: any, value: any) {
  Object.keys(value).forEach((key) => {
    if (typeof value[key] === 'object') {
      if (!obj[key]) {
        obj[key] = {
          ...value[key],
        };
      } else {
        obj[key] = deepTransCopy(obj[key], value[key]);
      }
    } else if (typeof value[key] === 'string') {
      obj[key] = value[key];
    }
  });
  return obj;
};

@Injectable({
  providedIn: 'root',
})
export class TicketCenterTranslateLoader extends TranslateLoader {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG_TOKEN) private appConfig: ITicketCenterAppConfig
  ) {
    super();
  }

  private async mergeTranslations(lang): Promise<Object> {
    const translation = this.appConfig.tc_config.translation;
    if (!translation) {
      throw new TranslationConfigException(
        'translation configuration not found'
      );
    }
    const mode: TranslationUseEnum = translation.use;

    const fallback_lang: string = translation.fallback_lang || 'en';
    const baseUrl = translation.url || '/assets/';
    const suffix = translation.type || '.json';
    const basePath = `${translation.default_translation_path}/` || '/';

    switch (mode) {
      case TranslationUseEnum.ONLY_DEFAULT:
        return await this.getDefaultTranslation(
          lang,
          basePath,
          suffix,
          fallback_lang
        );

      case TranslationUseEnum.ONLY_APPLICATION:
        return await this.http.get(`${baseUrl}${lang}${suffix}`).toPromise();

      case TranslationUseEnum.BOTH_EXTENDS:
        let appTranslation;
        const defaultTranslation = await this.getDefaultTranslation(
          lang,
          basePath,
          suffix,
          fallback_lang
        );
        try {
          appTranslation = await this.http
            .get(`${baseUrl}${lang}${suffix}`)
            .toPromise();
        } catch (err) {
          appTranslation = {};
        }

        return deepTransCopy(defaultTranslation, appTranslation);
      default:
        throw new TranslationValueException(
          `mode must be set to one of the following: ${TranslationUseEnum.ONLY_DEFAULT} - ${TranslationUseEnum.ONLY_APPLICATION} - ${TranslationUseEnum.BOTH_EXTENDS}`
        );
    }
  }

  private async getDefaultTranslation(
    lang: string,
    basePath: string,
    suffix: string,
    fallback_lang: string = 'en'
  ) {
    let defaultTranslation;
    try {
      defaultTranslation = await this.http
        .get(`${basePath}${lang}${suffix}`)
        .toPromise();
    } catch (err) {
      defaultTranslation = await this.http
        .get(`${basePath}${fallback_lang}${suffix}`)
        .toPromise();
    }
    return defaultTranslation || null;
  }

  public getTranslation(lang: string): Observable<Object> {
    return from(this.mergeTranslations(lang));
  }
}
