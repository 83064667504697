<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<!-- Tabs | WIP (for associated users)-->
			<!-- <mat-tab-group (selectedTabChange)="filterCodesOnTabClick($event)">
				<mat-tab label="All code"></mat-tab>
				<mat-tab label="Associated code"></mat-tab>
				<mat-tab label="Blank code"></mat-tab>
			</mat-tab-group> -->
			<context-code-management-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="permissionContextListColumn"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToDetail($event)"
				(dialogCreateContextCodeItem)="dialogCreateContextCodeItem(contextTypeAndApplicationLink.context_code_item)"
				[contextTypeAndApplicationLink]="contextTypeAndApplicationLink"
				[localListHandlerWidgetWrapperCanCreate]="configurationCustomerUserManagement.canEditContextCode[
								contextApplicationItemCodeEnum[contextTypeAndApplicationLink.context_application_item_code]
							]"
			>
			</context-code-management-wrapper>
		</mat-card>
	</ng-container>
</page-with-sidebar>

<!--<button-->
<!--	mat-fab-->
<!--	color="primary"-->
<!--	class="mat-fab-button"-->
<!--	(click)="dialogCreateContextCodeItem(contextTypeAndApplicationLink.context_code_item)"-->
<!--	[matTooltip]="-->
<!--		('general.add_new' | translate | sentencecase) +-->
<!--		' ' +-->
<!--		(contextTypeAndApplicationLink.context_code_item.description | translate | sentencecase)-->
<!--	"-->
<!--	matTooltipPosition="left"-->
<!--	*ngIf="-->
<!--		configurationCustomerUserManagement.canEditContextCode[-->
<!--			contextApplicationItemCodeEnum[contextTypeAndApplicationLink.context_application_item_code]-->
<!--		]-->
<!--	"-->
<!--&gt;-->
<!--	<mat-icon>add</mat-icon>-->
<!--	<span class="label">{{-->
<!--		('general.add_new' | translate | sentencecase) +-->
<!--			' ' +-->
<!--			(contextTypeAndApplicationLink.context_code_item.description | translate | sentencecase)-->
<!--	}}</span>-->
<!--</button>-->
