<top-bar
    [goBackButton]=true
    [goBackPath]="backPath"
    [badgeContent]="opportunity ? ('general.status.' + opportunity.status_code | translate | sentencecase) : null"
>
    <ng-container left-side-content>
        <h2>{{ opportunity
			? (opportunity.title || OpportunityEnum.Type[opportunity.opportunity_type] | sentencecase) + ' '
			: ((route.parent.snapshot.paramMap.get('opportunityLevel') === OpportunityEnum.Level.LEAD
					? 'request.new'
					: 'opportunity.new'
			  )
			  | translate
			  | sentencecase)
             }}
        </h2>
    </ng-container>
</top-bar>

<tab-wrapper [toExclude]="tabsToExclude"></tab-wrapper>
