import { Component, Inject } from '@angular/core';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OpportunityEnum } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'dialog-close-opportunity',
	templateUrl: './dialog-close-opportunity.component.html',
	styleUrls: ['./dialog-close-opportunity.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogCloseOpportunityComponent {
	OpportunityEnum = OpportunityEnum;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { opportunityLevel: OpportunityEnum.Level },
		public dialogRef: MatDialogRef<DialogCloseOpportunityComponent>
	) {
		console.log(data.opportunityLevel);
	}

	closeDialog(status: OpportunityEnum.Status.Outcome) {
		this.dialogRef.close(status);
	}
}
