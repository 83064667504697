import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TranslateBundleResolver implements Resolve<any> {
	constructor(
		private translate: TranslateService,
		private http: HttpClient
	) {}

	resolve(
		route: ActivatedRouteSnapshot
	): Promise<void> {
		return new Promise(resolve => {
			const languageList = this.translate.getLangs();
			for (let i = 0; i < languageList.length; i++) {
				const url = `${route.data['translateBundlePath']}/${languageList[i]}.json`;
				this.http.get(url).subscribe(e => {
					this.translate.setTranslation(languageList[i], e, true);
					if (i === languageList.length - 1) {
						resolve();
					}
				});
			}
		});
	}
}