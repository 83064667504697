<div class="mat-fab-button z-index-position">
	<div class="scroll-to-top" [class.show]="isVisible">
		<button
			class="scroll-to-top-button"
			mat-fab
			color="primary"
			(click)="scrollToTop()"
			matTooltip="{{ 'general.back_to_top' | translate | sentencecase }}"
		>
			<mat-icon>arrow_upward</mat-icon>
		</button>
	</div>
</div>
