import { ConfigurationCustomerContextSelector, TicketStatusContextApplicationMapItem } from "../model/configuration.model";
import { TicketStatusEnum } from "../model/enum/status.enum";

export namespace TicketCenterStatus {
  export const ContextApplicationStatusMap = <ConfigurationCustomerContextSelector<TicketStatusContextApplicationMapItem>>{
    BACKOFFICE: {
      selection_list: [
        {
          value: TicketStatusEnum.SENT,
          name: 'New Request',
                  color: '#4caf50'
        },
        { value: TicketStatusEnum.COMPLETED }
      ],
      filter_list: [
        {
          value: TicketStatusEnum.SENT,
          name: 'New Request'
        },
        { value: TicketStatusEnum.COMPLETED }
      ]
    },
    B2B: {
      filter_list: [
        { value: TicketStatusEnum.SENT },
        { value: TicketStatusEnum.COMPLETED }
      ]
    }
  };
}

