import { APP_CONFIG_TOKEN, ENVIRONMENT_SETTING } from '@saep-ict/angular-core';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

const loadLibConfig = (appConfig) => {
	return () => {
		  appConfig['config$'].subscribe(async config => {
			if (config && config.angularFlaskIntegration) {
			  appConfig.initializeAngularFlaskIntegration(config.angularFlaskIntegration);
			  return null;
			} else {
			  throw new Error(
				'angularFlaskIntegration field is not present in environment configuration'
			  );
			}
		  });
	  };
};

@NgModule({
	imports: [
		CommonModule
	]
})
export class AngularFlaskIntegrationModule {
	static forRoot(opt): ModuleWithProviders<AngularFlaskIntegrationModule> {
		return {
			ngModule: AngularFlaskIntegrationModule,
			providers: [
				{ provide: ENVIRONMENT_SETTING, useValue: opt.env },
				{
					provide: APP_CONFIG_TOKEN,
					useExisting: opt.appConfig,
				},
				{
					provide: APP_INITIALIZER,
					deps: [opt.appConfig, ENVIRONMENT_SETTING, HttpClient],
					useFactory: loadLibConfig,
					multi: true,
				}
			]
		}
	}
}
