import { Validators } from '@angular/forms';
import { FormControlMultipurposeModel, FormControlMultipurposeEnum } from '@saep-ict/angular-core';

export const creationFieldMapContextCodeOrganizationPartialCrmMarketing: FormControlMultipurposeModel.Item[] = [
  {
    name: 'crm',
    on_form_template: true,
    type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
    form_control_list: [
      {
        name: 'organization_sector',
        label: 'organization.field.organization_sector',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      },
      {
        name: 'revenue_year',
        label: 'organization.field.revenue_year',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.NUMBER,
        column_width: 6
      },
      {
        name: 'employees_number',
        label: 'organization.field.employees_number',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.NUMBER,
        column_width: 6,
        validator_list: [Validators.pattern('^[0-9]*$')]
      },
      {
        name: 'organization_international_rating',
        label: 'organization.field.organization_international_rating',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      },
      {
        name: 'organization_rating',
        label: 'organization.field.organization_rating',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.NUMBER,
        column_width: 6,
        validator_list: [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(100)]
      },
      {
        name: 'url_website',
        label: 'organization.field.url_website',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      },
      {
        name: 'url_linkedin',
        label: 'organization.field.url_linkedin',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      },
      {
        name: 'url_documents',
        label: 'organization.field.url_documents',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      },
      {
        name: 'url_recipes',
        label: 'organization.field.url_recipes',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      },
      {
        name: 'url_tickets',
        label: 'organization.field.url_tickets',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      },
      {
        name: 'url_sales',
        label: 'organization.field.url_sales',
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 6
      }
    ]
  }
];
