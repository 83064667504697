import { Injector } from '@angular/core';
import {
	FormControlMultipurposeModel,
	FormControlMultipurposeService
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { creationFieldMapContextCodeOrganizationBase } from './creation-field-map-context-code-organization-base.const';
import { creationFieldMapContextCodeOrganizationPartialTaxData } from './context-code-organization-partial/creation-field-map-context-code-organization-partial-taxData.const';
import { creationFieldMapOrganizationPartialContactListTypePublic } from './context-code-organization-partial/creation-field-map-context-code-organization-partial-contactList.const';

const injector = Injector.create({
	providers: [{ provide: FormControlMultipurposeService, deps: [] }]
});

const formControlMultipurposeService = injector.get(FormControlMultipurposeService);

export const creationFieldMapContextCodeOrganizationPublic: FormControlMultipurposeModel.Item[] =
  formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
    formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
      _.cloneDeep(creationFieldMapContextCodeOrganizationBase),
      ['level'],
      false
    ),
    ['destination_list'],
    true
  )
  .concat(_.cloneDeep(creationFieldMapContextCodeOrganizationPartialTaxData))
  .concat(_.cloneDeep(creationFieldMapOrganizationPartialContactListTypePublic));
