<ng-container *ngIf="user; else noPermissionTemplate">
  <div class="overflow-container">
    <div
      class="main-container"
      [style.min-height]="deviceH - frameHeight + 'px' | safeDomSanitizer: 'style'"
    >
      <div class="dashboard-container flex-container wrap">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noPermissionTemplate>
  <div class="container">
    <div class="row">
      <div class="col-12">
        {{ 'tc.general.no_user_permission' | translate }}
      </div>
    </div>
  </div>
</ng-template>
