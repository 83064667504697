import { BasePouchModel } from '@saep-ict/pouch_agent_models';

export interface ActionPouchModel extends BasePouchModel {
	code_item: string;
	// organization contact that is the referent
	contact_id?: string;
	contact_name?: string;
	// assegnee - owner of the action
	assegnee_user_id?: string;
	assegnee_full_name?: string;
	// Date and time when the record was created
	start_date: number;
	// real closing date of the action
	close_date?: number;
	// Type of the action inserted by the operator
	action_type: ActionTypeEnum;
	// expected closing date of the action to help make accurate revenue forecasts
	estimated_close_date?: number;
	// Text of the action inserted by the operator
	text: string;
	// Answer to the action inserted by the operator
	answer?: string;
	// Shows whether the action is open, won, or lost. Won and lost opportunities are read-only and can't be edited until they are reactivated.
	status?: ActionStatusEnum;
	// Id of the opportunity linked to the action
	opportunity_id?: string;
	subject: string;
	// da chi è stata inserita
	added_by?: string;
}

export enum ActionStatusEnum {
	E = 'E',
	S = 'S',
	R = 'R',
	A = 'A'
}

export enum ActionTypeEnum {
	ML = 'ML',
	MT = 'MT',
	VS = 'VS',
	TL = 'TL',
	DM = 'DM',
	IN = 'IN',
	OT = 'OT'
}

export const ActionTypes = [
	{
		code: ActionTypeEnum.TL,
		icon: 'call',
		estimated_days: 3
	},
	{
		code: ActionTypeEnum.ML,
		icon: 'mail',
		estimated_days: 3
	},
	{
		code: ActionTypeEnum.VS,
		icon: 'person_pin',
		estimated_days: 7
	},
	{
		code: ActionTypeEnum.IN,
		icon: 'event',
		estimated_days: 30
	},
	{
		code: ActionTypeEnum.MT,
		icon: 'local_shipping',
		estimated_days: 3
	},
	{
		code: ActionTypeEnum.OT,
		icon: 'task',
		estimated_days: 30
	},
	{
		code: ActionTypeEnum.DM,
		icon: 'ondemand_video',
		estimated_days: 10
	}
];
