import { Validators } from '@angular/forms';
import {
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum,
  FormControlMultipurposeService
} from '@saep-ict/angular-core';
import { Injector } from '@angular/core';
import { creationFieldMapContactBase } from '../../../contact/creation-field-map-contact-base.const';
import _ from 'lodash';

const injector = Injector.create({
	providers: [{ provide: FormControlMultipurposeService, deps: [] }]
});

const formControlMultipurposeService = injector.get(FormControlMultipurposeService);

export const creationFieldMapContextCodeOrganizationPartialContactList: FormControlMultipurposeModel.Item[] = [
  {
    name: 'contact_list',
    label: 'organization.field.contact_list',
    on_form_template: true,
    type: FormControlMultipurposeEnum.ItemType.FORM_ARRAY,
    form_control_list: formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
      formControlMultipurposeService.returnFormFieldListWithSomeValidatorList(
        formControlMultipurposeService.returnFormFieldListWithSomeColumnWidth(
          formControlMultipurposeService.returnFormFieldListWithSomeColumnWidth(
            _.cloneDeep(creationFieldMapContactBase),
            ['phone', 'phone_mobile', 'fax'],
            6
          ),
          ['has_notification'],
          12
        ),
        ['first_name', 'last_name', 'email'],
        [Validators.required]
      ),
      ['contact_type', 'is_main_of_list'],
      false
    )
  }
];

export const creationFieldMapOrganizationPartialContactListTypePublic: FormControlMultipurposeModel.Item[] = [
  {
    name: 'contact_list',
    label: 'organization.field.contact_list',
    on_form_template: true,
    type: FormControlMultipurposeEnum.ItemType.FORM_ARRAY,
    form_control_list: formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
      formControlMultipurposeService.returnFormFieldListWithSomeValidatorList(
        formControlMultipurposeService.returnFormFieldListWithSomeColumnWidth(
          _.cloneDeep(creationFieldMapContactBase),
          ['phone'],
          6
        ),
        ['first_name', 'last_name', 'email'],
        [Validators.required]
      ),
      [
        'contact_type',
        'is_main_of_list',
        'phone_mobile',
        'fax',
        'has_notification',
        'area',
        'role',
        'language'
      ],
      false
    )
  }
];
