import { Injectable } from '@angular/core';

// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// widget & utility
import { from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { UtilPouchService } from '../../../../service/util/util-pouch.service';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { ContextCodeManagementActionEnum, ContextCodeManagementStateAction } from './context-code-management.actions';
import {
	ContextTypeAndApplicationLink,
	PouchDbConstant,
	PouchDbModel,
	BodyTablePouchCustomModel
} from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class ContextCodeManagementEffects {
	loadItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeManagementActionEnum.LOAD_ITEM),
			mergeMap(
				(
					action: BaseStateModel<{
						_id: string;
						contextTypeAndApplicationLink: ContextTypeAndApplicationLink;
					}>
				) =>
					from(
						this.storeUtilService.getCouchDetailAndReturnInListState<BodyTablePouchCustomModel>(
							action,
							PouchDbModel.PouchDbDocumentType[
								action.data.contextTypeAndApplicationLink.context_code_item.couch_document_key.toUpperCase()
							]
						)
					)
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) =>
				ContextCodeManagementStateAction.updateItem(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeManagementStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeManagementActionEnum.LOAD_LIST),
			mergeMap((opt: { contextTypeAndApplicationLink: ContextTypeAndApplicationLink }) => {
				const opts = {
					startkey: opt.contextTypeAndApplicationLink.context_code_item.couch_document_key,
					endkey:
						opt.contextTypeAndApplicationLink.context_code_item.couch_document_key +
						ConfigurationCustomerAppStructure.noSqlDocSeparator +
						PouchDbConstant.allDocsLastCharacter,
					include_docs: true
				};
				return from(
					this.utilPouchService.allDocs<BodyTablePouchCustomModel>(
						opts,
						PouchDbModel.PouchDbDocumentType[
							opt.contextTypeAndApplicationLink.context_code_item.couch_document_key.toUpperCase()
						]
					)
				);
			}),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) =>
				ContextCodeManagementStateAction.updateList(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeManagementStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	saveItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeManagementActionEnum.SAVE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(
					this.storeUtilService.saveCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(
						action,
						PouchDbModel.PouchDbDocumentType[action.data.type.toUpperCase()]
					)
				)
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				ContextCodeManagementStateAction.saveItemSuccess(new BaseState([action.data]))
			),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeManagementStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	deleteItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeManagementActionEnum.DELETE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel, any>) =>
				from(this.utilPouchService.deleteAnyDocument(action.data))
			),
			map((action: BodyTablePouchCustomModel) =>
				ContextCodeManagementStateAction.deleteItemSuccess(new BaseState(null, null))
			),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeManagementStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPouchService: UtilPouchService,
		private storeUtilService: StoreUtilService
	) {}
}
