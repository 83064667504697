<div
	class="container"
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<div class="row">
		<div
			*ngFor="let cards of localListHandlerData.dataSubset"
			class="pt-3 {{ viewType === ViewTypeEnum.list ? 'col-12' : 'col-sm-12 col-md-6 col-lg-4' }}"
		>
			<b2c-article-item [itemType]="ItemType.product" [article]="cards" [viewType]="viewType"></b2c-article-item>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="pageSizeOptions"
			>
			</mat-paginator>
		</div>
	</div>
</div>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>