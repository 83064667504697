import { BehaviorSubject, Observable } from "rxjs";
import {
  IPouchConfigModel,
  ConfigCouchModel,
} from "./environment-config.model";
import { Type } from "@angular/core";
import { PouchDbAdapter } from "./structure/pouchdb-adapter";


export interface IPouchAppConfig<T = IPouchConfigModel> {
  username: string;
  signature: string;

  configSource: BehaviorSubject<T>;
  config$: Observable<T>;
  config: T;
  envConfig?: T;
  documentSeparator?: string;
}

export interface PouchDbModuleOptions {
  config: Type<PouchDbAdapter>[];
  appConfig: Type<IPouchAppConfig>;
}

export interface AppConfigModel {
  couch: ConfigCouchModel[];
  environment: string;
  urlConfig: UrlConfigModel;
  tag: string;
  enableOffline: boolean;
}

export interface UrlConfigModel {
  label: string;
  api: string;
  couch: ConfigCouchModel[];
  origin: string;
}
