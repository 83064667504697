import { ConfigurationUtil } from '@saep-ict/angular-spin8-core';

export const base: string[] = ['id'];
export const backoffice: string[] = ['id', 'body.custom_field.organization_code_item'];

export const contextApplicationMap = <ConfigurationUtil.Context.ApplicationSelector<string[]>>(<unknown>{
	B2B: base,
	BACKOFFICE_ADMIN: backoffice,
	BACKOFFICE: backoffice,
	PORTAL: base
});
