<ng-container *ngIf="currentFolder && currentFolder.actions">
  <button
    mat-raised-button
    *ngIf="
      utilService.checkAction(currentFolder, permissionEnum.CREATE) &&
      utilService.itemListSelected &&
      utilService.itemListSelected.length > 0
    "
    (click)="openDialogMoveItemList()"
    class="full-width bg-alert move-item"
  >
    <mat-icon>swap_horiz</mat-icon>
    {{ 'mc.general.move_item_list' | translate | titlecase }}
  </button>
  <button
    mat-raised-button
    *ngIf="
      utilService.checkAction(currentFolder, permissionEnum.DELETE) &&
      utilService.itemListSelected &&
      utilService.itemListSelected.length > 0
    "
    (click)="openDialogDeleteItemList()"
    color="warn"
    class="full-width delete-item"
  >
    <mat-icon>clear</mat-icon>
    {{ 'mc.general.delete_item_list' | translate | titlecase }}
  </button>
  <button
    mat-stroked-button
    *ngIf="utilService.checkAction(currentFolder, permissionEnum.CREATE)"
    (click)="openDialogCreateFolder()"
    color="primary"
    class="full-width add-folder"
  >
    <mat-icon inline="true">create_new_folder</mat-icon>
    {{ 'mc.right_sidebar.new_folder' | translate | titlecase }}
  </button>
  <attachment-resolver
    *ngIf="utilService.checkAction(currentFolder, permissionEnum.UPLOAD)"
    [fileEmitterType]="'files'"
    [multiple]="true"
    [selectionType] = "selectionTypeEnum.ALL"
    (fileEmitter)="fileUpload($event)"
  ></attachment-resolver>
  <mat-progress-bar
    *ngIf="uploadService.uploadInProgressObservable | async as upload"
    [mode]="
      upload.state == 'PENDING' ?
      'buffer' :
      (
        upload.state == 'DONE' ||
        upload.progress == 100 ?
        'indeterminate' :
        'determinate'
      )
    "
    [value]="upload.progress"
  >
  </mat-progress-bar>
</ng-container>
