import { ArticlePouchModel } from './article-pouch.model';
import { BasePouchModel } from './base-pouch.model';
import { DiscountModel } from './util-pouch.model';
import { OrganizationPouchModel } from './organization-pouch.model';
import { CurrencyPouchModel } from './currency-pouch.model';
import { OrderAddressPouchModel } from './destination-pouch.model';

export interface OrderPouchModel<T> extends BasePouchModel {
	header?: HeaderPouchModel<T>;
	product_list?: ArticlePouchModel[];
	order_progress?: OrderProgressPouchModel<T>;
}

export interface HeaderPouchModel<T> {
	date?: number;
	first_evasion_date?: number;
	code_agent?: string;
	code_area_manager: string;
	division?: string;
	order_number?: number;
	order_causal?: string;
	client_line?: string;
	client_code?: string;
	price_list_code?: string;
	payment_code?: string;
	payment_object?: any; // table ? payment ?
	goods_destination_object?: OrderAddressPouchModel; // destination
	shipping_mode?: string;
	delivery_methods?: string;
	delivery_methods_object?: any; // delivery method ? table ?
	starting_warehouse?: string;
	starting_warehouse_object?: any; // starting_warehouse
	note?: string[];
	note_order?: string;
	discount_agent?: DiscountModel;
	discount: DiscountModel[];
	organization?: OrganizationPouchModel;
	status?: OrderStatusEnum;
	custom_field?: T;
	price?: any; // price_object = {"total": 0,"article": 0,"range": 0,"pumping": 0,"deposit": 0}
	weight?: number;
	currency?: CurrencyPouchModel
}

// TODO: da deprecare
export interface Company {
	business_name?: string;
	piva?: string;
}

export enum NoProgressOrderStatusEnum {
	DRAFT = 'DRAFT',
	TO_AUTHORIZE = 'TO_AUTHORIZE',
	NOT_AUTHORIZED = 'NOT_AUTHORIZED',
	READY_TO_SEND = 'READY_TO_SEND',
	ERROR_PREPARING = 'ERROR_PREPARING',
	QUEUE = 'QUEUE',
	SENDING = 'SENDING',
	ERROR_SENDING = 'ERROR_SENDING'
}

export enum ProgressOrderStatusEnum {
	PROCESSING = 'PROCESSING',
	CONSOLIDATED = 'CONSOLIDATED',
	PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
	FULFILLED = 'FULFILLED',
	DELETED = 'DELETED'
}

export const OrderStatusEnum = { ...NoProgressOrderStatusEnum, ...ProgressOrderStatusEnum };
export type OrderStatusEnum = NoProgressOrderStatusEnum | ProgressOrderStatusEnum;

export interface OrderProgressPouchModel<T> extends BasePouchModel {
	doc_id: string;
	// first_version: OrderPouchModel<T>; // TODO Ragionare se serve, duplica i dati
	values?: OrderProgressDelta[];
}

export interface OrderProgressDelta {
	date?: string;
	dictionary_item_added?: OrderProgressDeltaItems;
	dictionary_item_removed?: OrderProgressDeltaItems;
	iterable_item_added?: OrderProgressDeltaIterable;
	iterable_item_removed?: OrderProgressDeltaIterable;
	values_changed?: OrderProgressDeltaValuesChanged;
}

export interface OrderProgressDeltaItems {
	items: string[];
	map: {
		[key: string]: number;
	};
}

export interface OrderProgressDeltaIterable {
	[key: string]: string | number | boolean | Object | Array<any> | null;
}

export interface OrderProgressDeltaValuesChanged {
	[key: string]: ValuesDifferences;
}

export interface ValuesDifferences {
	new_value: string | number | boolean | Object | Array<any> | null;
	old_value: string | number | boolean | Object | Array<any> | null;
}
