/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
	SubscribeManagerService, BaseStateModel,
	SentencecasePipe, DialogTextEditComponent, ITdDataTableColumnCustom
} from '@saep-ict/angular-core';
import {
	ArticleDescriptionItem,
	ArticlePouchModel,
	ArticleTableMetaInformation,
	OrderStatusEnum,
	LanguageItem
} from '@saep-ict/pouch_agent_models';
import { UtilArticleKitService } from '../../../service/util/util-article-kit.service';
import { UtilPriceService } from '../../../service/util/util-price.service';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import * as ConfigurationCustomerOrder from '../../../constants/configuration-customer/order/order.constant';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import * as ConfigurationCustomerForecast from '../../../constants/configuration-customer/forecast/forecast.constant';
import _ from 'lodash';
import {
	ArticleEnum,
	OrderRowModel,
	AngularSpin8CoreUtilTranslateService,
	UserDetailModel,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-spin8-core';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { MatDialog } from '@angular/material/dialog';
import { DialogImageGalleryComponent } from '../../dialog/dialog-image-gallery/dialog-image-gallery.component';
import { TicketCenterMainService } from '@saep-ict/ticket-center';
import * as TicketCenterBodyCustomField from '../../../constants/configuration-customer/ticket-center/body-custom-field.constant';
import * as ForecastModel from '../../../model/forecast.model';
import * as UtilPrice from '../../../constants/util-price.constants';
import * as ConfigurationCustomerArticleAvailability from '../../../constants/configuration-customer/article/article-availability.constant';
import * as ConfigurationCustomerPrice from '../../../constants/configuration-customer/price/price.constant';
import { AppUtilService } from '../../../service/util/app-util.service';
import { CurrencyPouchModel } from '@saep-ict/pouch_agent_models';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ArticleTableWrapperConfiguration } from '../../../model/article-table-wrapper.model';

@Component({
	selector: 'article-table-wrapper',
	templateUrl: './article-table-wrapper.component.html',
	styleUrls: ['./article-table-wrapper.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleTableWrapperComponent implements OnInit {
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	@ViewChild('virtualScrollContainer') set virtualScrollContainer(e: ElementRef) {
		if (
			this._configuration.virtualScrollConfiguration &&
			this._configuration.virtualScrollConfiguration.enabled &&
			this._configuration.virtualScrollConfiguration.itemHeight &&
			!this._configuration.virtualScrollConfiguration.viewportHeight &&
			e &&
			e.nativeElement
		) {
			setTimeout(() => {
				const domRect: DOMRect = e.nativeElement.getBoundingClientRect();
				this._configuration.virtualScrollConfiguration.viewportHeight =
					(window.innerHeight - domRect.top) - this._configuration.virtualScrollConfiguration.itemHeight;
			}, 0);
		}
	}

	@Input() set configuration(e: ArticleTableWrapperConfiguration) {
		if (e) {
			this._configuration = e;
			const columnWidthConfigurationClassItem: ITdDataTableColumnCustom =
			e.localListHandlerData.columnList.find(i => i.name === "column-width-configuration-class");
			if (columnWidthConfigurationClassItem) {
				this.columnWidthConfigurationClass = columnWidthConfigurationClassItem.label;
			}
			if (
				e.localListHandlerData.filters &&
				e.localListHandlerData.filters.customFilters &&
				e.localListHandlerData.filters.customFilters.forecastYear
			) {
				this.forecastConfigurationInputList =
					ConfigurationCustomerForecast.returnInputList(
						e.localListHandlerData.filters.customFilters.forecastYear
					);
			}
			for (const article of e.localListHandlerData.dataSubset) {
				if (!article.tableMetaInformation) {article.tableMetaInformation = <ArticleTableMetaInformation>{};}
				this.articleDiscountHandler(article);
				// in questo momento il componente potrebbe ricevere anche un array di soli articleDescription
				// dunque privi a loro volta di un sotto livello articleDescription al quale fanno riferimento
				// i metodi seguenti
				if (article.articleDescription) {
					this.articleDescriptionHandler(article);
					this.inputQuantityDisableHandler(article);
					this.imageGalleryShowandler(article);
					article.calculate_price = article.calculate_price ? article.calculate_price : 0;
				}
			}
			if (this.virtualScroll && e.virtualScrollConfiguration && e.virtualScrollConfiguration.enabled) {
				this.virtualScroll.scrollToIndex(0);
			}
		}
	}
	_configuration: ArticleTableWrapperConfiguration;

	@Output() onItemSelect = new EventEmitter();
	@Output() onArticleChange = new EventEmitter<OrderRowModel>();
	@Output() onDeleteArticle: EventEmitter<ArticlePouchModel | ArticleDescriptionItem> = new EventEmitter();
	@Output() onForecastChange: EventEmitter<ForecastModel.CodeItemUpdate> = new EventEmitter();

	orderStatusEnum = OrderStatusEnum;
	configurationCustomerArticle = ConfigurationCustomerArticle;
	configurationCustomerOrder = ConfigurationCustomerOrder;
	configurationCustomerPrice = ConfigurationCustomerPrice;
	appStructure = ConfigurationCustomerAppStructure;
	articleEnum = ArticleEnum;
	ticketCenterBodyCustomField = TicketCenterBodyCustomField;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	columnWidthConfigurationClass: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	forecastConfigurationInputList: ForecastModel.ConfigurationInput[];

	public sidebarClosed = false;
	constructor(
		private subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private utilPriceService: UtilPriceService,
		public utilArticleKitService: UtilArticleKitService,
		public utilService: AppUtilService,
		public utilOrderService: UtilOrderService,
		private store: Store,
		private dialog: MatDialog,
		private sentenceCasePipe: SentencecasePipe,
		public ticketCenterMainService: TicketCenterMainService,
		private bucketManagerService: BucketManagerService
	) {
		this.loadStaticData();
		this.subscribeManagerInit();
	}

	ngOnInit(): void {
		const pwsElm = document.getElementsByTagName('page-with-sidebar').item(0);

		if(pwsElm) {
			const child = pwsElm.firstChild;
			const observer = new MutationObserver(mutationList => {
				this.checkSidebarclosed(mutationList, this)
			});


			observer.observe(child, {
				attributes: true,
				attributeFilter: ['class'],
				childList: false,
				characterData: false
			})
		}
	}



	checkSidebarclosed(event, instance) {
		const target = event[0].target as HTMLTextAreaElement;
		instance.setSidebardStatus(target.classList.contains('sidebarclosed'));
	}

	setSidebardStatus(status) {
		this.sidebarClosed = status;
	}


	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			this.user = store.data;
		});
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.utilTranslateService.translate.onLangChange.subscribe((e: LangChangeEvent) => {
				for (const article of this._configuration.localListHandlerData.dataSubset) {
					this.articleDescriptionHandler(article);
				}
			}),
			'utilTranslateService.translate.onLangChange'
		);
	}

	returnArticlePriceWithDiscountDescription(article: ArticlePouchModel): string {
		const priceWithDiscount = this.utilPriceService.retrievePriceWithDiscount(
			article.articlePrice.price,
			article.articlePrice.discount,
			article.discount_agent
		);
		const value = UtilPrice.returnItemValueFormatted(priceWithDiscount, ConfigurationCustomerPrice.decimalDigit);
		const currency =
			this.utilService.returnIsMainOfList<CurrencyPouchModel>(this._configuration.organization.currency).description_short;
		return `${value} ${currency}`;
	}

	articleDescriptionHandler(article: ArticlePouchModel) {
		article.description = this.utilTranslateService.getTranslationFromLanguage(
			article.articleDescription?.language_list
		).description;
	}

	articleDiscountHandler(article: ArticlePouchModel) {
		let deiscountDescription = '';
		if (article.articlePrice && article.articlePrice.discount && article.articlePrice.discount.length) {
			for (let i = 0; i < article.articlePrice.discount.length; i++) {
				deiscountDescription = deiscountDescription + article.articlePrice.discount[i].value + '%';
				if (i !== article.articlePrice.discount.length - 1) {
					deiscountDescription = deiscountDescription + ' + ';
				}
			}
		}
		if (deiscountDescription !== '') {
			article.tableMetaInformation.discountDescription = deiscountDescription;
			article.tableMetaInformation.priceWithDiscountDescription =
				this.returnArticlePriceWithDiscountDescription(article);
		} else {
			article.tableMetaInformation.discountDescription = '0%';
			if (article.discount_agent) {
				article.tableMetaInformation.priceWithDiscountDescription =
					this.returnArticlePriceWithDiscountDescription(article);
			}
		}
	}

	// TODO: decidere circa la modellazione delle prop. di utility che servono solo di appoggio per la table
	inputQuantityDisableHandler(article: ArticlePouchModel) {
		article.tableMetaInformation.inputQuantityDisable = this.canInputArticleQuantity(article);
	}

	imageGalleryShowandler(article: ArticlePouchModel) {
		article.tableMetaInformation.hasGalleryImage =
			this.utilTranslateService.getImageWithLanguage(article) ? true : false;
	}

	canInputArticleQuantity(article: ArticlePouchModel): boolean {
		let isAvailable: boolean =
			ConfigurationCustomerArticleAvailability.returnArticleAvailability(article, this._configuration.organization);
			if (
				!(this._configuration.order && this._configuration.order.header.status ===  OrderStatusEnum.DRAFT) 
			) {
			isAvailable = false;
		}
		return isAvailable;
	}

	async dialogGallery(article: ArticlePouchModel) {
		this.dialog.open(DialogImageGalleryComponent, {
			data: {
				language_list: await this.setAttachmentBucketLink(article)
			},
			disableClose: true,
			panelClass: ['michelangelo-theme-dialog', 'image-gallery']
		});
	}

	setAttachmentBucketLink(e: ArticlePouchModel): Promise<LanguageItem[]> {
		return new Promise(resolve => {
			try {
				e = _.cloneDeep(e);
				const languageItem = this.utilTranslateService.getLanguageItemWithImageList(e);
				if (languageItem) {
					for (const attachment of languageItem.image_list) {
						attachment.bucket_link =
							this.bucketManagerService.returnBucketManagerDownloadUrl(
								attachment.nameOnBucket,
								'article',
								`${e.code_item}/${languageItem.language}/gallery`
							);
					}
				}
				resolve(e.articleDescription.language_list);
			} catch(err) {
				throw new Error(err);
			}
		});
	}

	articleNoteChange(e: any, row: ArticlePouchModel) {
		const dialog = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: `
					${
						this.sentenceCasePipe.transform(
							this.utilTranslateService.translate.instant('comment.name') +
							' ' +
							this.utilTranslateService.translate.instant('product.name')
						)
					}
					${ConfigurationCustomerAppStructure.erp_has_erp ? row.code_erp : row.code_item}`,
				text: row.note_order,
				disabled: !(this._configuration.order.header.status === OrderStatusEnum.DRAFT),
				optional: true
			},
			panelClass: ['michelangelo-theme-dialog', 'note-change'],
		});
		dialog.afterClosed().subscribe(res => {
			if (typeof res === 'string') {
				if (res === '' || !res.replace(/\s/g, '').length) {
					row.note_order = null;
				} else {
					row.note_order = res;
				}
				this.onArticleChange.emit(
					{
						event: e, row: row, key: 'note_order'
					}
				);
			}
		});
	}

	checkNewLine(i: number) {
		if(i > 0 ) {
			let prevRow = this._configuration.localListHandlerData.dataSubset[i-1];
			let currentRow = this._configuration.localListHandlerData.dataSubset[i];
			return currentRow.articleDescription.aggregated_info.brand !== prevRow.articleDescription.aggregated_info.brand;
		}
		return true;
	}
}
