<mat-dialog-wrapper [title]="data.title ? data.title : 'order.action.upload_xls' | translate | sentencecase" [showCloseIcon]="true">
	<ng-container content>
		<attachment-resolver
			[multiple]="data ? data.multiple : false"
			[acceptedType]="data ? data.acceptedType : null"
			[fileEmitterType]="data && data.fileEmitterType ? data.fileEmitterType : 'filesPreview'"
			(fileEmitter)="onAttachmentResolverFileChange($event)"
		></attachment-resolver>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.upload' | translate | sentencecase"
			(primaryButtonClickEmitter)="emitDialogData()"
			[primaryButtonIsDisabled]="!(fileList && fileList.length > 0)"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
