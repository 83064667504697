import {Inject, Optional} from '@angular/core';
import {IResourceActionInner, IResourceResponse, Resource, ResourceParams} from '@ngx-resource/core';
import {ILoaderService, LOADER_SERVICE_TOKEN} from '../../interface/loader-service.interface';
import {APP_CONFIG_TOKEN, IMediaCenterAppConfig} from '../../interface/media-center-app-config.interface';
import {ILoaderExceptionCall} from '../../model/ILoaderExceptionCall';
import {MediaCenterContext} from '../../model/media-center.model';
import {UtilService} from '../util.service';

@ResourceParams({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT, DELETE',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
})
export class MediaCenterBaseApiService extends Resource {
  loaderExceptions: ILoaderExceptionCall[];

  protected context: MediaCenterContext;

  constructor(
    @Optional() @Inject(LOADER_SERVICE_TOKEN) protected loaderService: ILoaderService,
    @Inject(APP_CONFIG_TOKEN) protected appConfig: IMediaCenterAppConfig,
    protected utils: UtilService
  ) {
    super();
  }

  $restAction(options: IResourceActionInner): any {
    this.setPathPrefix();

    const exception =
      this.loaderExceptions &&
      this.loaderExceptions.find((exc) => {
        return (
          options.actionOptions.path === exc.path &&
          options.actionOptions.method === exc.method
        );
      });

    if (!exception && this.loaderService) {
      const guid = this.loaderService.populateLoader(
        // options.actionOptions.path
        'fix-loader-ref'
      );
      options.returnData = { id: guid };
    }

    const token = this.appConfig.token
      ? 'bearer ' + this.appConfig.token
      : 'token';

      this.$setHeaders({
      Authorization: token
    });

		if (this.utils.context.value && this.utils.context.value.customHeaders) {
			const headers = this.$getHeaders();
      for (const headerName in this.utils.context.value.customHeaders) {
        headers[headerName] = this.utils.context.value.customHeaders[headerName];
      }
			this.$setHeaders(headers);
		}

    return super.$restAction(options);
  }

  $handleSuccessResponse(
    options: IResourceActionInner,
    resp: IResourceResponse
  ): any {
    if (options.returnData && options.returnData.id && this.loaderService) {
      this.loaderService.changeSingleLoader(options.returnData.id);
    }
    return super.$handleSuccessResponse(options, resp);
  }

  $handleErrorResponse(
    options: IResourceActionInner,
    resp: IResourceResponse
  ): any {
    if (options.returnData && options.returnData.id && this.loaderService) {
      this.loaderService.changeSingleLoader(options.returnData.id);
    }

    return super.$handleErrorResponse(options, resp);
  }

  protected setPathPrefix() {
    let urlPrefix = this.appConfig.mc_config.be_url;
    if (this.appConfig.mc_config.suffix) {
      urlPrefix += this.appConfig.mc_config.suffix;
    }
    if (this.utils.context.value && this.utils.context.value.filter && this.utils.context.value.filter.bucketFolderName) {
      urlPrefix += `/${this.utils.context.value.filter.bucketFolderName}`
    }
    super.$setPathPrefix(urlPrefix);
  }

}
