import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TdPagingBarComponent } from '@covalent/core/paging';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	DialogConfirmComponent,
	ListWrapperComponent,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import * as _ from 'lodash';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';
import { ActionPouchModel, ActionStatusEnum, ActionTypes } from '../../../model/state/action-state.model';
import { ActionListWrapperComponent } from '../../../widget/action/action-list-wrapper/action-list-wrapper.component';
import { ActionActionEnum, ActionStateAction } from '../../../state/action/action.actions';
import { LocalListHandlerBaseModel, ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { AppUtilService } from '../../../service/util/app-util.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import {
	ContextApplicationItemCodeEnum,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel,
	OrganizationStateModel,
	AngularSpin8CoreUtilCompanyService,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';

import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import * as ConfigurationCustomerColumnAction from '../../../constants/configuration-customer/action/action-column-map/action-column-map-base.constant'

@Component({
	selector: 'action',
	templateUrl: './action.component.html',
	styleUrls: ['./action.component.scss'],
	providers: [ 	SubscribeManagerService]
})
export class ActionComponent implements OnDestroy {
	@Input() dialog: boolean = false;
	@Input() contact: ContactPouchModel;
	@Input() opportunity: OpportunityPouchModel;
	@Output() actionDone: EventEmitter<boolean> = new EventEmitter();

	@ViewChild('pagingBarPageSize', { static: false }) public pagingBarPageSize: TdPagingBarComponent;
	@ViewChild(ActionListWrapperComponent)
	public actionListWrapperComponent: ActionListWrapperComponent;

	action$: Observable<BaseStateModel<ActionPouchModel[]>> = this.store.select(StateFeature.getActionState);
	action: ActionPouchModel[];
	public actionLevel: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	public columns;
	ActionTypes = ActionTypes;

	public listPageBaseData = <LocalListHandlerBaseModel<ActionPouchModel>>{
		pagination: {
			pageSize: this.contextApplicationItemCodeEnum.CRM ? 25 : 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'action_type',
					'contact_name',
					'assegnee_full_name',
					'start_date',
					'estimated_close_date',
					'status',
					'opportunity_id'
				]
			}
		},
		sort: {
			name: 'estimated_close_date',
			order: 'DESC'
		},
		data: []
	};

	filterOpened = false;
	formFilters: FormGroup;

	public idOrganization;
	isLogs: boolean = false;

	constructor(
		private store: Store<any>,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder,
		private utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private utilService: AppUtilService,
		private translateService: TranslateService,
		private matDialog: MatDialog,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.columns = ConfigurationCustomerColumnAction.baseColumns;

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.store.dispatch(ActionStateAction.loadAll());

		this.isLogs = this.router.url.includes(ROUTE_URL.logs);
		if (this.isLogs) {
			this.columns.map(col => {
				col.hidden = ['estimated_close_date', 'status'].includes(col.name);
			});
		} else {
			this.columns.map(col => {
				col.hidden = ['close_date'].includes(col.name);
			});
		}
		this.createFormFilters();

		// handle column visibility
		// if(this.actionLevel === ActionLevelEnum.LEAD){
		// 	this.columns.map(col => {
		// 		col.hidden = ['organization'].includes(col.name);
		// 	});
		// }

		this.subscribeManagerService.populate(this.subscribeActionList().subscribe(), 'subscribeActionList');
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle(
			this.isLogs ? 'log' : 'action'
		);
	}

	ngOnDestroy() {
		this.store.dispatch(ActionStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeActionList() {
		return this.organizationList$.pipe(
			filter(res => !!(res && res.data)),
			mergeMap(res => {
				this.organizationList = res.data;
				return this.activatedRoute.paramMap;
			}),
			mergeMap(params => {
				this.actionLevel = params.get('actionLevel')?.toLowerCase() || null;
				return this.action$;
			}),
			filter((state: BaseStateModel<ActionPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<ActionPouchModel[]>) => {
				switch (state.type) {
					case ActionActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case ActionActionEnum.ERROR:
						throw new Error(ActionActionEnum.ERROR);

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(actionList: ActionPouchModel[]) {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.CRM: {
				if (this.opportunity) {
					actionList = this.getListFilteredByOpportunity(actionList);
				} else if (this.contact) {
					actionList = this.getListFilteredByContact(actionList);
				}
				actionList = this.getOrganization(actionList);
				break;
			}
			case ContextApplicationItemCodeEnum.AGENT: {
				break;
			}
			case ContextApplicationItemCodeEnum.B2B: {
				break;
			}
			case ContextApplicationItemCodeEnum.BACKOFFICE: {
				break;
			}
		}

		this.action = actionList;

		// filtri sidebar
		this.onFormFiltersSubmit();
	}

	/**
	 * Filters sidebar
	 */

	createFormFilters() {
		this.formFilters = this.fb.group({
			status_open: false,
			action_type: null
		});

		if (!this.isLogs) {
			this.formFilters.patchValue({
				status_open: true
			});
		}
	}

	getFormControl(key: string): FormControl {
		return <FormControl>this.formFilters.get(key);
	}

	resetFilters() {
		this.formFilters.patchValue({
			status_open: false,
			action_type: null
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.action);

		if (this.isLogs) {
			filteredList = filteredList.filter(action => action.status === ActionStatusEnum.E);
		} else {
			if (this.formFilters && this.formFilters.get('status_open').value) {
				filteredList = filteredList.filter(action => action.status !== ActionStatusEnum.E);
			}
		}
		if (this.formFilters && this.formFilters.value.action_type) {
			filteredList = filteredList.filter(item => {
				return item.action_type === this.formFilters.value.action_type.code;
			});
		}

		this.actionListWrapperComponent.updateListPageBaseData(filteredList);
	}

	/**
	 * navigation
	 */

	goToActionDetail(action: ActionPouchModel) {
		this.actionDone.emit();
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.actions, action._id]);
	}

	createNew() {
		this.actionDone.emit();
		this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.actions}/${ROUTE_URL.newAction}`], {
			queryParams: { contact_id: this.contact?.code_item, opportunity_id: this.opportunity?._id }
		});
	}

	// CRM filter by opport
	getListFilteredByOpportunity(actionList: ActionPouchModel[]) {
		return actionList.filter(action => action.opportunity_id == this.opportunity._id);
	}

	// CRM filter by contact
	getListFilteredByContact(actionList: ActionPouchModel[]) {
		return actionList.filter(action => {
			if (action && action.contact_id) {
				return action.contact_id === this.contact.code_item;
			}
			return false;
		});
	}

	// CRM filter by organization
	getOrganization(actionList: ActionPouchModel[]) {
		return actionList.map(action => {
			const organization = this.organizationList.find(org =>
				org.contact_list
					? org.contact_list.find(contact =>
							contact.code_item ? contact.code_item == action.contact_id : false
						)
					: false
			);
			if (organization) {
				action = <ActionPouchModel>(<unknown>{ organization: organization, ...action });
			}
			return action;
		});
	}

	selectCompany(level: string, code: string) {
		this.actionDone.emit();
		this.utilCompanyService.navigateToCompanyDetail(level, code);
	}

	openDialogConfirmCloseAction(row: ActionPouchModel) {
		const dialogRef = this.matDialog.open(DialogConfirmComponent, {
			data: {
				title: this.translateService.instant('action.close'),
				text: this.translateService.instant('question.remove') + ` ${row['code_item']}?`
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				row.status = ActionStatusEnum.E;
				row.close_date = new Date().valueOf();
				row.estimated_close_date = new Date().valueOf();
				this.store.dispatch(ActionStateAction.save(new BaseState([row])));
			}
		});
	}
}
