import { BasePouchModel } from './base-pouch.model';
import { SignEnum, SourceCreditGrantedEnum } from './util-pouch.model';

export interface OrganizationCreditSummaryPouchModel {
	date: string;
	// Header customer_credit
	reliability?: string;
	source_credit_granted: SourceCreditGrantedEnum;
	sign_credit_granted?: SignEnum;
	amount_credit_granted?: number;
	days_since_last_deadline?: number;

	// date_credit_granted?: string;
	// date_last_payment?: string;
	// date_last_invoice?: string;
	// date_last_deadline?: string;
	// related_customers?: string[];
	// code_customer_new?: string;
	// line_customer?: string;
	// sign_balance_b_s?: SignEnum;
	// amount_balance_b_s?: number;
	// sign_expired?: SignEnum;
	// amount_expired?: number;
	// sign_actual_risk?: SignEnum;
	// amount_actual_risk?: number;
	// sign_total_risk?: SignEnum;
	// amount_total_risk?: number;
	// sign_total_expired?: SignEnum;
	// amount_total_expired?: number;
	// sign_annual_turnover?: SignEnum;
	// amount_annual_turnover?: number;
	// sign_residual_sales_order?: SignEnum;
	// amount_residual_sales_order?: number;
	// sign_out_credit?: SignEnum;
	// amount_out_credit?: number;
	// sign_outstandings?: SignEnum;
	// amount_outstandings?: number;
	// number_outstandings?: number;
	// sign_exchange_exposure?: SignEnum;
	// amount_exchange_exposure?: number;
	// sign_positive_shipping_in_progress?: SignEnum;
	// amount_positive_shipping_in_progress?: number;
	// sign_negative_shipping_in_progress?: SignEnum;
	// amount_negative_shipping_in_progress?: number;
	// sign_secured_credit?: SignEnum;
	// amount_secured_credit?: number;
	// sign_debits_not_recognized?: SignEnum;
	// amount_debits_not_recognized?: number;
	// sign_credits_not_recognized?: SignEnum;
	// amount_credits_not_recognized?: number;
	// sign_payment_forecasts?: SignEnum;
	// amount_payment_forecasts?: number;
	// sign_xab_not_invoiced?: SignEnum;
	// amount_xab_not_invoiced?: number;
	// sign_returns_not_invoiced?: SignEnum;
	// amount_returns_not_invoiced?: number;
	// sign_commitments_sales_order?: SignEnum;
	// amount_commitments_sales_order?: number;

	// Statistiche
	total_give: number;
	total_take: number;
	total_give_expired: number;
	total_take_expired: number;
	balance_give_take: number;
	balance_give_take_expired: number;
}

export interface CustomerCreditSummaryTablePouchModel extends BasePouchModel {
	code_company: string,
	code: string,
	values: OrganizationCreditSummaryPouchModel[]
}
