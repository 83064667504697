<mat-dialog-wrapper
	[title]="data.title | translate | sentencecase"
	[showCloseIcon]="true"
>
	<ng-container content>
		<attachment-list-manager
		    *ngIf="data"
			[configuration]="data.attachmentListManagerConfiguration"
			(attachmentListchange)="attachmentListManagerChange()"
		>
		</attachment-list-manager>
	</ng-container>
</mat-dialog-wrapper>