import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel, DateMomentService, AngularCoreUtilService } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StatisticsOrganizationActionEnum, StatisticsOrganizationStateAction } from './statistics-organization.action';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { StatisticsOrganization } from '../../model/statistics-organization.model';
import { AppUtilService } from '../../service/util/app-util.service';
import { StatisticsBaseSerieOrder } from '../../model/statistics.model';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class StatisticsOrganizationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsOrganizationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<any>) => from(this.getStatisticsOrganization(action))),
			mergeMap((action: BaseStateModel<StatisticsOrganization>) => from(this.sortStatisticsOrganization(action))),
			mergeMap((action: BaseStateModel<StatisticsOrganization>) =>
				from(this.convertDateStatisticsOrganization(action))
			),
			map((action: BaseStateModel<StatisticsOrganization>) => StatisticsOrganizationStateAction.update(action)),
			catchError((error, caught) => {
				console.error(error);
				this.store.dispatch(StatisticsOrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private dateMomentService: DateMomentService,
		private appUtilService: AppUtilService
	) {}

	// Chiavi degli oggetti
	keyClients = ['active_client', 'inactive_client'];
	keyOrder = ['to_authorize', 'processing', 'consolidated', 'average', 'count'];
	keyClientsTop5 = ['days_30', 'days_60', 'days_90'];
	keyBalance = ['due', 'expired'];
	keyFulfilled = ['fulfilled_trend'];

	async getStatisticsOrganization(action: BaseStateModel<any>): Promise<BaseStateModel<StatisticsOrganization>> {
		await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter())// .statisticsPouch
		// 	.getStatistics(action.data.code_item)

		.basePouch
			.getDetail('statistic' + ConfigurationCustomerAppStructure.noSqlDocSeparator + action.data.code_item)
			.then(res => {
				action.data = res;
			})
			.catch(err => {
				console.log(err);
				return action;
			});
		return action;
	}

	async sortStatisticsOrganization(
		action: BaseStateModel<StatisticsOrganization>
	): Promise<BaseStateModel<StatisticsOrganization>> {
		if (!action.data.data) {
			return action;
		}

		// order_trend
		this.keyOrder.forEach(key => {
			if (
				action.data &&
				action.data.data &&
				this.appUtilService.findLeaf(action.data.data, `orders.order_trend.${key}`)
			) {
				this.appUtilService
					.findLeaf(action.data.data, `orders.order_trend.${key}`)
					.sort((a, b) => a.date - b.date);
			}
		});

		if (action.data && action.data.data) {
			// fulfilled
			this.appUtilService.findLeaf(action.data.data, `fulfilled.fulfilled_trend`).sort((a, b) => {
				if (typeof a.date === 'number' && typeof b.date === 'number') {
					return a.date - b.date;
				}
				return 0;
			});

			// balance
			this.keyBalance.forEach(key => {
				if (this.appUtilService.findLeaf(action.data.data, `balance.${key}`)) {
					this.appUtilService.findLeaf(action.data.data, `balance.${key}`).sort((a, b) => a.date - b.date);
				}
			});
		}

		return action;
	}

	async convertDateStatisticsOrganization(
		action: BaseStateModel<StatisticsOrganization>
	): Promise<BaseStateModel<StatisticsOrganization>> {
		if (!action.data.data) {
			return action;
		}

		if (action.data && action.data.data) {
			// order_trend
			this.keyOrder.forEach(key => {
				this.appUtilService
					.findLeaf(action.data.data, `orders.order_trend.${key}`)
					.map((element: StatisticsBaseSerieOrder) => {
						element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
					});
			});

			// balance
			this.keyBalance.forEach(key => {
				this.appUtilService
					.findLeaf(action.data.data, `balance.${key}`)
					.map((element: StatisticsBaseSerieOrder) => {
						element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
					});
			});

			// fulfilled
			// this.keyFulfilled.forEach(key => {
			// this.keyFulfilled.forEach(key => {
			// 	this.appUtilService
			// 		.findLeaf(action.data.data, `fulfilled.${key}`)
			// 		.map((element: StatisticsBaseSerieOrder) => {
			// 			element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
			// 		});
			// });
		}

		return action;
	}
}
