import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';
import { OrganizationStateModel } from '../../model/state/organization-state.model';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';

@Injectable()
export class AngularSpin8CoreOrganizationService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PUBLIC)}/organizations/`
	})
	postNewOrganization: IResourceMethodPromise<
		{ organization: OrganizationStateModel; registration_type: string },
		RestBaseResponse<any>
	>;
}
