import { Injectable, OnDestroy } from '@angular/core';
// import { Plugins, FilesystemDirectory, DeviceInfo } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Network } from '@capacitor/network';

// const { Device, Filesystem, Network } = Plugins;

export enum DBType {
	LocalSqlLite = 'cordova-sqlite',
	LocalIndexDB = 'indexeddb',
	RemoteDB = 'remote-db'
}

export enum PouchDbMultideviceAdapterType {
	Electron = 'electron',
	IOS = 'ios',
	Android = 'android',
	Browser = 'web'
}

export interface DeviceConfig {
	offlineMode: boolean;
	deviceType: PouchDbMultideviceAdapterType;
	databasePath?: string;
	filesystemDirectory?: Directory;
	dbType: DBType;
	deviceInfo: DeviceInfo;
}

@Injectable({ providedIn: 'root' })
export class OfflineDeviceService implements OnDestroy {

	public selectedDevice: DeviceConfig = null;

	private handler = Network.addListener('networkStatusChange', (status) => {
		console.log("Network status changed", status);
		//this.store.dispatch(ConnectionStateAction.load());
	});

	async isInternetAvailable(): Promise<boolean> {
		// Get the current network status
		let status = await Network.getStatus();
		return status.connected;
	}

//	constructor(private config: IConfigModel) { }

	ngOnDestroy() {
		this.handler.remove();
	}

	async init(): Promise<DeviceConfig> {
		const deviceInfo: DeviceInfo = await Device.getInfo();
		switch (deviceInfo.platform as any) { // TODO: Rimuovere il cast a ANY e aggiungere strategia di gestione electron per Capacitor 3
			case PouchDbMultideviceAdapterType.IOS:
				this.selectedDevice = <DeviceConfig>{
					deviceType: PouchDbMultideviceAdapterType.IOS,
					databasePath: '../Library/NoCloud/',
					filesystemDirectory: Directory.Data,
					offlineMode: true,
					dbType: DBType.LocalSqlLite
				};
				break;
			case PouchDbMultideviceAdapterType.Android:
				this.selectedDevice = <DeviceConfig>{
					deviceType: PouchDbMultideviceAdapterType.Android,
					databasePath: './',
					filesystemDirectory: Directory.Data,
					offlineMode: true,
					dbType: DBType.LocalSqlLite
				};
				break;
			case PouchDbMultideviceAdapterType.Electron:
				this.selectedDevice = <DeviceConfig>{
					deviceType: PouchDbMultideviceAdapterType.Electron,
					offlineMode: true,
					dbType: DBType.LocalIndexDB
				};
				break;
			case PouchDbMultideviceAdapterType.Browser: // Tutti i browser indipendentemente dal SO
			default:
				this.selectedDevice = <DeviceConfig>{
					deviceType: PouchDbMultideviceAdapterType.Browser,
					offlineMode: false,
					dbType: DBType.RemoteDB
				};
				break;
		}
		this.selectedDevice.deviceInfo = deviceInfo;

		return Promise.resolve(this.selectedDevice);
	}

	async cleanDBStorage() {
		const dbPath = this.selectedDevice.databasePath;
		const filesystemDirectory = this.selectedDevice.filesystemDirectory;
		try {
			const ret = await Filesystem.rmdir({
				path: dbPath,
				directory: filesystemDirectory,
				recursive: true
			});
			return Promise.resolve(ret);
		} catch (error) {
			return Promise.reject(`Unable to cleanDBStorage '${dbPath}' - ${error}`);
		}
	}

	async statsDBStorage() {
		const dbPath = this.selectedDevice.databasePath;
		const filesystemDirectory = this.selectedDevice.filesystemDirectory;
		try {
			let ret = new Array<{
				uri: string,
				size: string,
				name: string
			}>();

			const dirfiles = await Filesystem.readdir({
				path: dbPath,
				directory: filesystemDirectory
			});

			for(const fileName of dirfiles.files) {
				const fstat = await Filesystem.stat({
					path: `${dbPath}${fileName}`,
					directory: filesystemDirectory
				});
				ret.push({
					uri: fstat.uri,
					name: fstat.uri.split('/').pop(),
					size: ((fstat.size / 1024) / 1024) + ' MB'
				});
			}
			return Promise.resolve(ret);
		} catch (error) {
			return Promise.reject(`Unable to statsDBStorage '${dbPath}' - ${error}`);
		}
	}

}
