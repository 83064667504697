<ng-container *ngIf="orderPriceMap">
	<ng-container *ngFor="let key of objectConstructor.keys(orderPriceMap)">
		<div
			*ngIf="orderPriceMap[key].value || orderPriceMap[key].main"
			[ngClass]="orderPriceMap[key].main ? 'total' : 'partial'"
		>
			<p class="price-label">
				{{
					key !== orderEnum.PriceMapType.VAT
						? ('order.field.price.' + orderPriceMap[key].label | translate | sentencecase)
						: ('order.field.price.' + orderPriceMap[key].label | translate | uppercase)
				}}
			</p>
			<p
				class="total__price"
				[innerHTML]="
					(
						orderPriceMap[key].value |
						utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit
					) +
					' ' +
					currency
				"
			>
			</p>
		</div>
	</ng-container>
</ng-container>
