<div
	(mouseenter)="maskMouseenterHandler()"
	(mouseleave)="maskMouseleaveHandler()"
>
	<input
		#inputTemplateBind
		*ngIf="_configuration.visible"
		[attr.id]="_configuration.row.code_item + '_' + _configuration.key"
		[attr.data-input-key]="_configuration.key"
		class="inputTag"
		[ngClass]="
			{
				'input-highlight': _configuration.inputHighlight,
				'validationError': validationError
			}
		"
		appOnlyNumbers
		stopPropagation
		placeholder="0"
		[value]="_configuration.value"
		[disabled]="_configuration.disabled"
		appMaxNumberInput
		[maxValue]="_configuration.maxValue ? _configuration.maxValue : null"
		[attr.decimals]="_configuration.decimals ? _configuration.decimals : null"
		(focus)="
			maskShow = false;
			maskShowFocusOnInput = true;
		"
		(focusout)="
			maskShow = true;
			maskShowFocusOnInput = false;
			validationError = false;
			inputTemplateBind.value = _configuration.value;
		"
		(input)="
			updatedArticleRowSelection({
				event: $event,
				row: _configuration.row,
				key: _configuration.key,
				dataSubset: _configuration.dataSubset,
				dataTable: _configuration.dataTable,
				organization: _configuration.organization,
				onArticleChange: _configuration.onArticleChange
			})
		"
		(keydown)="onKeydown($event)"
	/>
	<div
		*ngIf="_configuration.maskEnabled && _configuration.visible"
		class="mask"
		[ngClass]="{maskShow: maskShow}"
	>
		{{ (_configuration.value | utilPriceReturnItemValueFormattedPipe: 0) || 0 }}
	</div>
	<ng-container *ngIf="!_configuration.visible"><span>-</span></ng-container>
</div>