import { OrganizationLevelEnum } from '@saep-ict/pouch_agent_models';
import { ConfigurationProjectModel, ROUTE_URL, PATH_URL, PermissionKeyEnum } from "@saep-ict/angular-spin8-core";
import _ from "lodash";

export const list: ConfigurationProjectModel.Navigation.MainSidebarItemModel[] = [
	{
		key: 'dashboard',
        name: 'theme.dashboard.name',
		icon: 'custom-dashboard',
		iconCustom: true,
		route: `${PATH_URL.PRIVATE}`,
		position: 1,
		permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD
	},
	{
		key: 'request_list',
		name: 'saep_ict_angular_spin8_core.page.request.name_plural',
		icon: 'history',
		route: `${ROUTE_URL.requests}`,
		position: 2,
		permission: PermissionKeyEnum.BACKOFFICE_REGISTRATION_REQUEST_LIST
	},
	{
		key: 'user_management',
		name: 'saep_ict_angular_spin8_core.page.user_management.name',
		icon: 'custom-groups',
		iconCustom: true,
		route: `${ROUTE_URL.userManagement}`,
		position: 2,
		permission: PermissionKeyEnum.BACKOFFICE_USER_MANAGEMENT_LIST
	},
	{
		key: 'configuration',
		name: 'saep_ict_angular_spin8_core.page.configuration.name_plural',
		icon: 'custom-settings',
		iconCustom: true,
		route: `${ROUTE_URL.configurations}`,
		position: 3,
		permission: PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION
	},
	{
		key: 'context_code_management',
		name: 'saep_ict_angular_spin8_core.page.context_code_management.name',
		icon: 'custom-account_tree',
		iconCustom: true,
		route: ``,
		position: 4,
		permission: PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
	},
	{
		key: 'context_code_management_backoffice',
		parentKey: 'context_code_management',
		name: 'saep_ict_angular_spin8_core.context_code.description.BACKOFFICE',
		icon: '',
		route: `/${ROUTE_URL.contextCodeManagement}/backoffice`,
		position: 1,
		permission: PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
	},
	{
		key: 'context_code_management_b2b',
		parentKey: 'context_code_management',
		name: 'saep_ict_angular_spin8_core.context_code.description.B2B',
		icon: '',
		route: `/${ROUTE_URL.contextCodeManagement}/b2b`,
		position: 2,
		permission: PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
	},
	{
		key: 'context_code_management_agent',
		parentKey: 'context_code_management',
		name: 'saep_ict_angular_spin8_core.context_code.description.AGENT',
		icon: '',
		route: `/${ROUTE_URL.contextCodeManagement}/agent`,
		position: 3,
		permission: PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
	},
	{
		key: 'context_code_management_b2c',
		parentKey: 'context_code_management',
		name: 'saep_ict_angular_spin8_core.context_code.description.B2C',
		icon: '',
		route: `/${ROUTE_URL.contextCodeManagement}/b2c`,
		position: 4,
		permission: PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
	},
	{
		key: 'context_code_management_crm',
		parentKey: 'context_code_management',
		name: 'saep_ict_angular_spin8_core.context_code.description.CRM',
		icon: '',
		route: `/${ROUTE_URL.contextCodeManagement}/crm`,
		position: 5,
		permission: PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
	},
	{
		key: 'context_code_management_portal',
		parentKey: 'context_code_management',
		name: 'saep_ict_angular_spin8_core.context_code.description.PORTAL',
		icon: '',
		route: `/${ROUTE_URL.contextCodeManagement}/portal`,
		position: 6,
		permission: PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
	},
	{
		key: 'mediacenter',
		name: 'theme.navigation.mediacenter_international_name',
		icon: 'custom-folder-2',
		iconCustom: true,
		route: `${ROUTE_URL.mediaCenter}/mediacenter`,
		position: 4,
		permission: PermissionKeyEnum.BACKOFFICE_MEDIACENTER
	},
	{
		key: 'mediacenter-italy',
		name: 'theme.navigation.mediacenter_italy_name',
		icon: 'custom-folder-2',
		iconCustom: true,
		route: `${ROUTE_URL.mediaCenter}/mediacenter-italy`,
		position: 4,
		permission: 'backoffice-mediacenter-italy'
	},
	{
		key: 'ticket_center',
		name: 'saep_ict_angular_spin8_core.page.ticket_center.name',
		icon: 'custom-confirmation_number',
		iconCustom: true,
		route: `${ROUTE_URL.ticketCenter}`,
		position: 6,
		permission: PermissionKeyEnum.BACKOFFICE_TICKET_CENTER
	},
	{
		key: 'orders',
		name: 'saep_ict_angular_spin8_core.page.order.name_plural',
		icon: 'custom-inbox',
		iconCustom: true,
		route: ``,
		position: 5,
		permission: PermissionKeyEnum.BACKOFFICE_ORDER_LIST
	},
	{
		key: 'orders_received',
		parentKey: 'orders',
		name: 'saep_ict_angular_spin8_core.status.order.RECEIVED',
		icon: '',
		route: `/${ROUTE_URL.orders}/received`,
		position: 1,
		permission: PermissionKeyEnum.BACKOFFICE_ORDER_LIST
	},
	{
		key: 'orders_consolidated',
		parentKey: 'orders',
		name: 'saep_ict_angular_spin8_core.status.order.CONSOLIDATED',
		icon: '',
		route: `/${ROUTE_URL.orders}/consolidated`,
		position: 2,
		permission: PermissionKeyEnum.BACKOFFICE_ORDER_LIST
	},
	{
		key: 'orders_fulfilled',
		parentKey: 'orders',
		name: 'saep_ict_angular_spin8_core.status.order.FULFILLED',
		icon: '',
		route: `/${ROUTE_URL.orders}/fulfilled`,
		position: 3,
		permission: PermissionKeyEnum.BACKOFFICE_ORDER_LIST
	},
	{
		key: 'organizations_customer',
		name: 'theme.navigation.organizations',
		icon: 'custom-business',
		iconCustom: true,
		route: `${ROUTE_URL.organizations}/${OrganizationLevelEnum.CUSTOMER}`,
		position: 6,
		permission: PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST
	},
    {
		key: 'contacts',
		name: 'saep_ict_angular_spin8_core.page.contact.name_plural',
		icon: 'custom-contact_page',
		iconCustom: true,
		route: `${ROUTE_URL.contacts}`,
		position: 10,
		permission: PermissionKeyEnum.BACKOFFICE_CONTACT_LIST
	},
	{
		key: 'catalogue',
		name: 'saep_ict_angular_spin8_core.general.catalogue',
		icon: 'custom-shopping_basket',
		iconCustom: true,
		route: ``,
		position: 11,
		permission: PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_LIST
	},
	{
		key: 'catalogue_article',
		parentKey: 'catalogue',
		name: 'saep_ict_angular_spin8_core.page.article.name_plural',
		icon: '',
		route: `/${ROUTE_URL.catalog}/${ROUTE_URL.articles}`,
		position: 1,
		permission: PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_LIST
	},
	{
		key: 'catalogue_family',
		parentKey: 'catalogue',
		name: 'saep_ict_angular_spin8_core.page.family.name_plural',
		icon: '',
		route: `/${ROUTE_URL.catalog}/${ROUTE_URL.families}`,
		position: 2,
		permission: PermissionKeyEnum.BACKOFFICE_FAMILY_CONFIGURATION_LIST
	},
	{
		key: 'catalogue_categories',
		parentKey: 'catalogue',
		name: 'saep_ict_angular_spin8_core.general.category.name_plural',
		icon: '',
		route: `/${ROUTE_URL.catalog}/${ROUTE_URL.categories}`,
		position: 2,
		permission: PermissionKeyEnum.BACKOFFICE_CATEGORY_CONFIGURATION_LIST
	},
	{
		key: 'informative_pages',
		name: 'saep_ict_angular_spin8_core.page.informative_page.name_plural',
		icon: 'custom-info',
		iconCustom: true,
		route: `${ROUTE_URL.informativePage}`,
		position: 11,
		permission: PermissionKeyEnum.BACKOFFICE_INFORMATIVE_PAGE_LIST
	},
	// NEW asset-request-center
	{
		key: 'asset-request-center_print-production-flow_euroitalia',
		name: 'Print production flow Euroitalia',
        icon: 'custom-campaign',
        iconCustom: true,
        route: 'print-production-flow-center/backoffice/asset-request-center/print-production-flow/euroitalia',
		//route: 'asset-request-center/backoffice/asset-request-center/print-production-flow/euroitalia',
		position: 19,
		permission: PermissionKeyEnum.BACKOFFICE_NEW_PRINT_PRODUCTION_FLOW_CENTER
    },
	{
		key: 'asset-request-center_print-production-flow_agency',
		name: 'Print production flow Agency',
        icon: 'custom-campaign',
        iconCustom: true,
        route: 'print-production-flow-center/backoffice/asset-request-center/print-production-flow/agency',
		//route: 'asset-request-center/backoffice/asset-request-center/print-production-flow/agency',
		position: 19,
		permission: PermissionKeyEnum.BACKOFFICE_NEW_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
    },
	{
		key: 'asset-request-center_image-video_euroitalia',
		name: 'Image/Video Euroitalia',
        icon: 'custom-campaign',
        iconCustom: true,
        route: 'asset-request-center/backoffice/asset-request-center/image-video/euroitalia',
        position: 19,
		permission: PermissionKeyEnum.BACKOFFICE_NEW_ASSET_REQUEST_CENTER
    },
	{
		key: 'asset-request-center_image-video_image-video_agency',
		name: 'Image/Video Agency',
        icon: 'custom-campaign',
        iconCustom: true,
        route: 'asset-request-center/backoffice/asset-request-center/image-video/agency',
        position: 19,
		permission: PermissionKeyEnum.BACKOFFICE_NEW_AGENCY_ASSET_REQUEST_CENTER
    },
	// END asset-request-center
	{
		key: 'news',
		name: 'saep_ict_angular_spin8_core.page.news.name',
		icon: 'custom-insert_drive_file',
		iconCustom: true,
		route: ROUTE_URL.news,
		position: 16,
		permission: PermissionKeyEnum.BACKOFFICE_NEWS
	},
	{
		key: 'reports',
		name: 'saep_ict_angular_spin8_core.page.reports.name',
		icon: 'custom-bar_chart',
		iconCustom: true,
		route: ROUTE_URL.reports,
		position: 18,
		permission: PermissionKeyEnum.BACKOFFICE_REPORTS
	},
	// NEW survey-center
	{
        key: 'survey-center_budget',
        name: 'saep_ict_angular_spin8_core.page.survey_center.budget',
        icon: 'custom-pie_chart',
        iconCustom: true,
        route: 'survey-center/backoffice/survey-center/budget',
        position: 19,
        permission: PermissionKeyEnum.BACKOFFICE_NEW_SURVEY_CENTER_BUDGET
    },
	{
		key: 'survey-center_media-marketing',
        name: 'saep_ict_angular_spin8_core.page.survey_center.media_marketing',
		icon: 'custom-pie_chart',
		iconCustom: true,
		route: 'survey-center/backoffice/survey-center/media-marketing',
		position: 19,
		permission: PermissionKeyEnum.BACKOFFICE_NEW_SURVEY_CENTER_BUDGET
	},
	// END survey-center
	{
		key: 'product_stock',
		name: 'saep_ict_angular_spin8_core.page.product_stock.name',
		icon: 'custom-inventory_2',
		iconCustom: true,
		route: ROUTE_URL.productStock,
		position: 19,
		permission: PermissionKeyEnum.BACKOFFICE_PRODUCT_STOCK
	},
	{
		key: 'additional_service_configuration',
		name: 'saep_ict_angular_spin8_core.page.additional_service_configuration.name_plural',
		icon: 'custom-settings',
		iconCustom: true,
		route: ROUTE_URL.additionalServiceConfiguration,
		position: 20,
		permission: PermissionKeyEnum.BACKOFFICE_ADDITIONAL_SERVICE_CONFIGURATION
	},
	// old request-center
	{
        key: 'asset_request_center_OLD_image-video-euroitalia',
        name: 'Image/Video Euroitalia',
		icon: 'custom-campaign',
		iconCustom: true,
		route: `${ROUTE_URL.assetRequestTicketList}/${ROUTE_URL.imageVideo}/euroitalia`,
		position: 12,
		permission: PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER
	},
	{
        key: 'asset_request_center_OLD_image-video-agency',
        name: 'Image/Video Agency',
		icon: 'custom-campaign',
		iconCustom: true,
		route: `${ROUTE_URL.assetRequestTicketList}/${ROUTE_URL.imageVideo}/agency`,
		position: 13,
		permission: PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER
	},
	{
        key: 'asset_request_center_OLD_print-production-flow-euroitalia',
        name: 'Print production flow Euroitalia',
		icon: 'custom-campaign',
		iconCustom: true,
		route: `${ROUTE_URL.assetRequestTicketList}/${ROUTE_URL.printProductionFlow}/euroitalia`,
		position: 14,
		permission: PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER
	},
	{
        key: 'asset_request_center_OLD_print-production-flow-agency',
        name: 'Print production flow Agency',
		icon: 'custom-campaign',
		iconCustom: true,
		route: `${ROUTE_URL.assetRequestTicketList}/${ROUTE_URL.printProductionFlow}/agency`,
		position: 15,
		permission: PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
	},

	// old survey-center
	{
		key: 'survey_center_budget_OLD',
        name: 'saep_ict_angular_spin8_core.page.survey_center.budget',
        icon: 'custom-pie_chart',
        iconCustom: true,
        route: ROUTE_URL.budget,
        position: 19,
        permission: PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_BUDGET
    },
    {
		key: 'survey_center_media_marketing_OLD',
		name: 'saep_ict_angular_spin8_core.page.survey_center.media_marketing',
        icon: 'custom-pie_chart',
        iconCustom: true,
        route: ROUTE_URL.mediaMarketing,
        position: 19,
        permission: PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_MEDIA_MARKETING
    }
];