import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { TicketModel } from '../../../model/lib.model';

export enum TicketCenterTicketDetailActionEnum {
	UPDATE = '[ticket-detail] Update',
	LOAD = '[ticket-detail] Load',
	SAVE = '[ticket-detail] Save',
	SAVE_SUCCESS = '[ticket-detail] Save Success',
	RESET = '[ticket-detail] Reset',
	ERROR = '[ticket-detail] Error',
}

export namespace TicketCenterTicketDetailStoreAction {
	export const update = createAction(TicketCenterTicketDetailActionEnum.UPDATE, props<BaseStateModel<TicketModel<any>>>());
	export const load = createAction(TicketCenterTicketDetailActionEnum.LOAD, props<BaseStateModel<TicketModel<any>>>());
  export const save = createAction(TicketCenterTicketDetailActionEnum.SAVE, props<BaseStateModel<TicketModel<any>>>());
  export const saveSuccess = createAction(TicketCenterTicketDetailActionEnum.SAVE_SUCCESS, props<BaseStateModel<TicketModel<any>>>());
	export const reset = createAction(TicketCenterTicketDetailActionEnum.RESET);
	export const error = createAction(TicketCenterTicketDetailActionEnum.ERROR, props<BaseStateModel<TicketModel<any>>>());
}
