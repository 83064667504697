import { Routes } from '@angular/router';
import { TicketComponent } from '../page/ticket-center/ticket/ticket.component';

export const TicketCenterRoutes: Routes = [
  {
    path: '',
    component: TicketComponent,
    pathMatch: 'full',
  },
];
