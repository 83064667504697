import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleRecapStockStateModel } from '@saep-ict/angular-spin8-core';
import { ArticleStockStateAction, ArticleStockActionEnum } from './article-stock.actions';

export namespace ArticleStockStateReducer {
	export const initialState: BaseStateModel<ArticleRecapStockStateModel> = null;

	const _reducer = createReducer(
		initialState,
		on(ArticleStockStateAction.load, state => state),
		on(ArticleStockStateAction.update, (state, articleStock) => articleStock),
		on(ArticleStockStateAction.reset, () => initialState),
		on(ArticleStockStateAction.error, (state, articleStock) => ({
			data: state ? state.data : null,
			type: articleStock.type
		}))
	);

	export function reducer(
		state: BaseStateModel<ArticleRecapStockStateModel>,
		action: TypedAction<ArticleStockActionEnum>
	) {
		return _reducer(state, action);
	}
}
