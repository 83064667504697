import { Injectable } from '@angular/core';
import { ChartjsModel, AngularCoreUtilService, UtilColorService } from '@saep-ict/angular-core';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ChartEnum } from '@saep-ict/angular-spin8-core';

@Injectable()
export class LineCurrencyChartConfigurationServiceLocal extends BaseChartClass {
	constructor(
		public utilService: AngularCoreUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
	}

	default: ChartjsModel = {
		data: null,
		datasets: [],
		labels: [],
		type: 'line',
		options: this.optionsStyle(ChartEnum.Theme.DEFAULT),
		colors: [
			{
				backgroundColor: this.colorPrimary10,
				borderColor: this.colorPrimary
			}
		],
		legend: false
	};

	accent: ChartjsModel = {
		...this.default,
		options: this.optionsStyle(ChartEnum.Theme.ACCENT),
		colors: [
			{
				backgroundColor: this.colorBlack30,
				borderColor: this.colorBlack
			},
			{
				backgroundColor: this.colorWhite50,
				borderColor: this.colorWhite
			}
		]
	};

	tooltipsCallbacks() {
		return {

			label: function(context) {

				let label = context.dataset.label || '';

				if (label) {
					label += ': ';
				}

				label += (context.parsed.y / 1000).toFixed(2).replace('.', ',') + 'k';
				return label;
			}




			// label: function (tooltipItem, data) {
			// 	var label = data.datasets[tooltipItem.datasetIndex].label || '';

			// 	if (label) {
			// 		label += ': ';
			// 	}
			// 	label += (tooltipItem.yLabel / 1000).toFixed(2).replace('.', ',') + 'k';
			// 	return label;
			// }
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return {
			grid: {
				drawBorder: false,
				display: true,
				borderDash: [4, 4],
				color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
			},
			ticks: {
				display: true,
				// Valore minimo dell'asse
				min: 0,
				// Scala da usare
				stepSize: 10000,
				// Sets the offset of the tick labels from the axis
				padding: 8,
				// Font color for tick labels.
				fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite,
				callback: this.ticksCallback()
			}
		}
	}
	xAxesStyle() {
		return {
			grid: {
				drawBorder: false,
				display: false
			},
			ticks: {
				display: false
			}
		}
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks()
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				x: this.xAxesStyle(),
				y: this.yAxesStyle(theme)
			},
			aspectRatio: 3
		};
	}
}
