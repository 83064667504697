import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TicketCenterComponent } from './ticket-center/ticket-center.component';
import { TicketComponent } from './ticket-center/ticket/ticket.component';
import { WidgetModule } from '../widget/widget.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [SharedModule, WidgetModule, CommonModule],
    declarations: [
        TicketCenterComponent,
        TicketComponent
    ],
    exports: [
        TicketCenterComponent,
    ]
})
export class PageModule { }
