<div class="background">
	<div class="wrapper">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.334 53.334" class="loader">
			<defs>
				<clipPath id="a" clipPathUnits="userSpaceOnUse">
					<path d="M0 48h48V0H0z" />
				</clipPath>
			</defs>
			<g clip-path="url(#a)" transform="matrix(1.33333 0 0 -1.33333 -5.333 58.667)">
				<path
					d="M31.913 40.346c4.621-2.27 7.847-6.265 9.296-10.833l1.74.443c-1.576 5.063-5.135 9.495-10.247 12.007-9.872 4.85-21.838.741-26.674-9.16a19.94 19.94 0 01-1.64-12.68l10.648 2.714a8.998 8.998 0 00.838 5.128c2.18 4.462 7.554 6.307 12.005 4.121a9.086 9.086 0 001.555-.976l-6.745-6.438 16.2 4.25c-1.279 3.91-4.062 7.321-8.029 9.27-7.798 3.831-17.252.584-21.072-7.237a15.727 15.727 0 01-1.579-7.73l-2.358-.616a18.11 18.11 0 001.79 9.401c4.4 9.011 15.287 12.75 24.272 8.336M41.9 15.151c2.051 4.2 2.643 8.496 1.583 13.283l-10.732-2.739a8.99 8.99 0 00-.758-5.65c-2.179-4.463-7.556-6.309-12.006-4.123a9.09 9.09 0 00-1.72 1.107l7.487 6.86-16.917-4.3c1.196-4.123 4.04-7.742 8.177-9.775 7.8-3.83 17.247-.582 21.068 7.24a15.732 15.732 0 011.522 8.463l2.338.574a18.11 18.11 0 00-1.718-10.09c-4.4-9.01-15.289-12.75-24.273-8.336-4.793 2.355-8.089 6.561-9.454 11.343l-1.737-.442c1.49-5.275 5.117-9.92 10.402-12.518 9.872-4.85 21.901-.799 26.737 9.103"
				/>
			</g>
		</svg>

		<svg
			version="1.1"
			id="Livello_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			viewBox="0 0 47.9 11.7"
			xml:space="preserve"
			class="loader-text"
		>
			<path
				class="st0"
				d="M-30.4-1.5c.1-.6-.2-1-1-1.4l-1.3-.5c-1.8-.8-2.7-1.9-2.6-3.3 0-.6.3-1.2.7-1.7.4-.5 1-.9 1.7-1.2.7-.3 1.5-.4 2.3-.4 1 0 2 .3 2.7 1 .7.7 1.1 1.6 1 2.6h-2.7c0-.4-.1-.7-.3-1.1-.2-.3-.6-.4-1-.4s-.8.1-1.2.3c-.3.2-.5.5-.6.9-.1.5.3.9 1.2 1.3.7.2 1.3.5 1.9.9 1.1.6 1.8 1.7 1.8 3 0 .6-.2 1.2-.6 1.7-.4.5-1 .9-1.6 1.1-.7.3-1.5.4-2.3.4-.6 0-1.2-.1-1.7-.3-.5-.2-1-.4-1.4-.8-.8-.7-1.2-1.7-1.1-2.8h2.7c-.1.5.1.9.3 1.3.3.3.8.5 1.3.4.4 0 .8-.1 1.1-.3.4-.1.6-.4.7-.7"
				transform="translate(36.394 10)"
			/>
			<path
				class="st0"
				d="M-32.7-4.6h1.6c.6 0 1.2-.2 1.6-.7.4-.4.5-1 .4-1.6 0-.3-.2-.6-.4-.8-.2-.2-.5-.3-.8-.3h-1.9l-.5 3.4zm-.3 2.1l-.7 3.9h-2.7l2-11.4h4.1c1.1-.1 2.2.3 3 1.1.7.7 1.1 1.7 1 2.7 0 .7-.3 1.4-.7 2-.4.6-1 1-1.7 1.3-.7.3-1.5.5-2.4.4H-33z"
				transform="translate(45.919 10.157)"
			/>
			<path class="st0" d="M-33.7 1.4h-2.7l2-11.4h2.7l-2 11.4z" transform="translate(56.278 10.157)" />
			<path
				class="st0"
				d="M-27.2 1.4h-2.6l-2.7-7.2-1.2 7.2h-2.7l2-11.4h2.6l2.7 7.2 1.2-7.2h2.7l-2 11.4z"
				transform="translate(60.856 10.157)"
			/>
			<path
				class="st0"
				d="M-36.4-9.1c0-.2.1-.5.2-.6.2-.2.4-.3.6-.3.2 0 .5.1.6.2.2.2.3.4.2.6 0 .2-.1.5-.3.6-.2.2-.4.3-.6.3-.2 0-.4-.1-.6-.2 0-.1-.1-.4-.1-.6"
				transform="translate(73.107 14.953)"
			/>
			<path
				class="st0"
				d="M-30.1-6.9c.1-.5 0-.9-.3-1.3s-.7-.6-1.2-.6-1.1.2-1.5.6c-.4.4-.7.9-.7 1.5-.1.5 0 1 .3 1.3.3.4.7.6 1.2.5.5 0 1.1-.2 1.5-.6.4-.3.6-.9.7-1.4m-.7 5.3c.1-.5-.1-1.1-.4-1.5-.3-.4-.8-.6-1.4-.6-.6 0-1.2.2-1.7.6-.5.4-.7 1-.8 1.6.1.5.2 1.1.5 1.5.3.4.8.6 1.3.6.6 0 1.2-.2 1.7-.6.5-.5.8-1 .8-1.6m2.1-5.3c0 .6-.3 1.1-.6 1.5-.4.5-.9.9-1.5 1.1.5.3.8.7 1.1 1.1.2.5.3 1 .3 1.5 0 .9-.5 1.8-1.2 2.4-.8.6-1.7.9-2.7.9-.9 0-1.7-.3-2.3-.9-.6-.6-.8-1.4-.7-2.3 0-.6.3-1.2.7-1.7.4-.5 1-.9 1.6-1.2-.4-.3-.7-.6-.9-1.1-.2-.4-.3-.9-.2-1.4 0-.9.5-1.7 1.1-2.3.7-.6 1.6-.9 2.5-.9.8 0 1.6.3 2.2.9.5.8.7 1.6.6 2.4"
				transform="translate(76.554 10)"
			/>
		</svg>
	</div>
</div>
