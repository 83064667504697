import {
	ContextApplicationItemCodeEnum,
	ContextOtherItemCodeEnum
} from '../enum/permission/context-application-item-code.enum';
import { PouchDbModel } from '../model/pouchdb.model';

export namespace PouchDbConstant {
	export const contextStateMap = new Map<
		ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum | string,
		PouchDbModel.PouchDbDocumentType[]
	>([
		[ContextApplicationItemCodeEnum.BACKOFFICE, [PouchDbModel.PouchDbDocumentType.BACKOFFICE]],
		[ContextApplicationItemCodeEnum.B2B, [PouchDbModel.PouchDbDocumentType.ORGANIZATION]],
		[ContextApplicationItemCodeEnum.AGENT, [PouchDbModel.PouchDbDocumentType.AGENT]],
		[ContextApplicationItemCodeEnum.B2C, []],
		[ContextApplicationItemCodeEnum.CRM, [PouchDbModel.PouchDbDocumentType.CRM]],
		[ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN, [PouchDbModel.PouchDbDocumentType.USER]],
		[ContextOtherItemCodeEnum.ORDER, [PouchDbModel.PouchDbDocumentType.ORDER]],
		[ContextOtherItemCodeEnum.ARTICLE, [PouchDbModel.PouchDbDocumentType.ARTICLE]],
		[ContextOtherItemCodeEnum.CONTACT, [PouchDbModel.PouchDbDocumentType.CONTACT]],
		[ContextOtherItemCodeEnum.REQUEST, [PouchDbModel.PouchDbDocumentType.REQUEST]],
		[ContextOtherItemCodeEnum.TICKET, [PouchDbModel.PouchDbDocumentType.TICKET]]
	]);
	export const allDocsLastCharacter = '\ufff0';
}
