import { RestBasePk } from '@saep-ict/angular-core';
import { KanbanModel } from '@saep-ict/angular-spin8-core';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { PouchDeleteResponse } from '../../model/pouch-base-response.model';

export class KanbanPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	putKanban(data: KanbanModel.Base, isGuid: boolean): Promise<KanbanModel.Base> {
		const guid = isGuid ? 'kanban' : data._id;
		if (!data.type) {
			data.type = 'kanban';
		}
		return new Promise((resolve, reject) => {
			this.put<KanbanModel.Base>(data, guid, isGuid)
				.then((doc: KanbanModel.Base) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteKanban(kanban: KanbanModel.Base): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(kanban)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
