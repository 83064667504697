import { Component, Input } from '@angular/core';
import { AngularCoreUtilService, BaseStateModel } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { GiveTakeFlagEnum } from '@saep-ict/pouch_agent_models';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { AuxiliaryTableStateModel, CurrencyStateModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import * as ConfigurationCustomerPrice from '../../../constants/configuration-customer/price/price.constant';

@Component({
	selector: 'credit-list-wrapper',
	templateUrl: './credit-list-wrapper.component.html',
	styleUrls: ['./credit-list-wrapper.component.scss']
})
export class CreditListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Input() lastCustomerCreditDate: string;
	currencyList: CurrencyStateModel[];

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);

	// enum
	giveTakeFlagEnum = GiveTakeFlagEnum;
	configurationCustomerArticle = ConfigurationCustomerArticle;
	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store
	) {
		super(utilService, translateService, store);
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.currencyList = res && res.data && res.data.currencyList ? res.data.currencyList : null;
		});
	}

	/**
	 * This function convert the currency code to the ISO Code stored in auxiliary table
	 * @param code_item This is the code saved in batch_list
	 */
	getCurrencyIsoCode(code_item: string): string {
		if (!this.currencyList) {
			return '';
		}
		const found = this.currencyList.find(currency => currency.code_item === code_item);
		return found ? found.code_iso : '';
	}
}
