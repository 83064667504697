export interface ElementOffset {
  x: number;
  y: number;
}

export enum ObjectNestedValueByPathEnum {
  IS_ARRAY = 'IS_ARRAY',
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND'
}
export interface ObjectNestedValueByPathModel {
  type: ObjectNestedValueByPathEnum;
  path: string,
  value: any;
}
