<ng-container *ngIf="_configuration && formFieldList && form">
  <form
    [formGroup]="form"
    class="michelangelo-theme"
  >
    <div class="row">
      <form-control-multipurpose
        *ngFor="
          let field of formFieldList
            | filterByAllTheBooleans
              : {
                  propertyListName: ['on_form_template'],
                  value: true
                }
        "
        [formControlName]="field.name"
        [field]="field"
        class="col-md-{{ field.column_width ? +field.column_width : 12 }}"
      ></form-control-multipurpose>
    </div>
    <button
      *ngIf="_configuration.buttonReset && _configuration.buttonReset.show"
      mat-button
      (click)="formReset()"
      type="button"
      class="mat-stroked-button"
    >
      {{
        (
          _configuration.buttonReset.label ?
          _configuration.buttonReset.label :
          'saep_ict_angular_core.general.reset'
        ) | translate | sentencecase
      }}
    </button>
  </form>
</ng-container>
