<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<mat-card-content>
					<div class="row">
						<div class="col-12">
							<destination-list-wrapper
								[localListHandlerWidgetWrapperData]="listPageBaseData"
								[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
								[localListHandlerTextFilterShow]="true"
								[localListHandlerPaginatorShow]="true"
								(onItemSelect)="openDialogDestinationEdit($event)"
							>
							</destination-list-wrapper>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div
		class="row add-address"
		*ngIf="
			configurationCustomerOrganization.canCreate.destination[
				contextApplicationItemCodeEnum[user.current_permission.context_application]
			]
		"
	>
		<div class="col-12">
			<button
				mat-fab
				(click)="openDialogDestinationAdd()"
				class="mat-fab-button__in-page"
				aria-label="{{ 'destination.add_address' | translate | sentencecase }}"
				color="primary"
			>
				<mat-icon>add</mat-icon>
				<span class="label">{{ 'destination.add_address' | translate | sentencecase }}</span>
			</button>
		</div>
	</div>
</div>
