import { FormControlMultipurposeModel } from '@saep-ict/angular-core';
import {
	OrganizationListFilterCreationFieldMapFormValue,
	OrganizationListFilterCreationFieldMapSelectOptionMap
} from '../../../../../model/organization.model';
import { OrganizationMacrochannelEnum, OrganizationOriginEnum, OrganizationPouchModel, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';

// Add options - client type (italiano/estero)
export const returnCreationFieldMapWithOptionOrganizationStatus = (
	creationFieldMap: FormControlMultipurposeModel.Item[],
	selectOptionMap: OrganizationListFilterCreationFieldMapSelectOptionMap
): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const organizationStatusField = creationFieldMap.find(i => i.name === 'macrochannel');
			organizationStatusField.option_list = selectOptionMap.macrochannel.map(i => {
				return {
					value: i,
					label: i
				};
			});
			resolve(creationFieldMap);
		} catch (e) {
			throw new Error(e);
		}
	});
};

// Filter -  client type (italiano/estero)
export const returnConfigurationCustomerOrganizationListFilterByFormFieldStatus = (
	organizationList: OrganizationPouchModel[],
	formValue: OrganizationListFilterCreationFieldMapFormValue
): Promise<OrganizationPouchModel[]> => {
	return new Promise(resolve => {
		try {
			if (organizationList && organizationList.length && formValue) {
				if (formValue.macrochannel) {
					const filteredOrganizationStatusList = [];
					for (const organization of organizationList) {
						switch(formValue.macrochannel) {
							case OrganizationMacrochannelEnum.INTERNAL: 
								if(organization.macrochannel === OrganizationOriginEnum.IT) {
									filteredOrganizationStatusList.unshift(organization);
								};
								break;
							case OrganizationMacrochannelEnum.EXTERNAL: 
								if(organization.macrochannel !==  OrganizationOriginEnum.IT) {
									filteredOrganizationStatusList.unshift(organization);
								};
								break;
						}						
					}
					organizationList = filteredOrganizationStatusList;
				}
			}
			resolve(organizationList);
		} catch (e) {
			throw new Error(e);
		}
	});
};