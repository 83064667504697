<section>
	<ng-container *ngFor="let item of boxCreditDebitInfoComponentConfig; let last = last">
		<div class="item" [ngClass]="{ 'hide-separator': !showSeparator }">
			<div class="info">
				<span class="title">{{ item.title | translate }}</span>
			</div>
			<div class="main">
				<div class="value-wrapper">
					<span class="value">{{ item.value }}</span>
					<span *ngIf="item.suffix" class="suffix">{{ item.suffix | translate }}</span>
				</div>
			</div>
		</div>
	</ng-container>
</section>
