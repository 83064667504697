import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';
import { LanguageStateModel } from '../../model/state/language-state.model';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';

@Injectable()
export class AngularSpin8CoreLanguageService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path:  `/${returnApiUrlSegment(ApiType.PUBLIC)}/languages/`
	})
	getLanguages: IResourceMethodPromise<void, RestBaseResponse<LanguageStateModel[]>>;
}
