import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreUtilKanbanService {
	private dataSource = new BehaviorSubject<any>(null);
	opportunitySelected = this.dataSource.asObservable();

	constructor() {}

	updatedOpportunitySelected(opportunitySelected: any) {
		this.dataSource.next(opportunitySelected);
	}

	clearOpportunitySelection() {
		this.dataSource.next(null);
	}

	getAvg(numberList: number[]): number {
		let avg = 0;
		if (numberList.length > 1) {
			avg = Math.round(numberList.reduce((a, b) => a + b) / numberList.length);
		} else if (numberList.length === 1) {
			avg = Math.round(numberList[0]);
		}

		return avg;
	}

	convertRatingFromLetter(letter: 'A' | 'B' | 'C' | 'D'): number {
		switch (letter) {
			case 'A':
				return 100;

			case 'B':
				return 75;

			case 'C':
				return 50;

			case 'D':
				return 25;

			default:
				return 0;
		}
	}
}
