import { Injectable } from '@angular/core';
import { IResourceMethodPromise, IResourceMethodPromiseStrict, ResourceAction, ResourceRequestBodyType, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '../../model/rest/rest-base.model';
import { AttachmentListManager } from '../../model/widget/attachmment-list-manager.model';
import { BaseApiBucketManagerService } from './baseApi-bucket-manager.service';

@Injectable()
export class RestAttachmentListManagerService extends BaseApiBucketManagerService {
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!path}/upload-multipart',
    requestBodyType: ResourceRequestBodyType.FORM_DATA
  })
  uploadMultipart:
    IResourceMethodPromiseStrict<
      FormData,
      void,
      AttachmentListManager.RestPath,
      AttachmentListManager.RestResponseUploadMultipart
    >;
  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!path}/delete'
  })
  delete:
    IResourceMethodPromiseStrict<
      AttachmentListManager.RestDeletePayload,
      void,
      AttachmentListManager.RestPath,
      RestBaseResponse<any>
    >;
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!path}/files/zip', //TODO: capire il perchè ->Error: Mandatory {!path} path parameter is missing
    //path: '/news/files/zip',

  })
  downloadZip: IResourceMethodPromiseStrict<{path:string},{ pathList: string, topic: string, token: string },void, any>;
}


