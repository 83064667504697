import { BaseStateModel } from '@saep-ict/angular-core';
import { FamilyListStateAction, FamilyListActionEnum } from './family-list.actions';
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ArticleRecap } from '../../model/state/article-list-state.model';

export const initialState: BaseStateModel<ArticleRecap> = null;

const _reducer = createReducer(
	initialState,
	on(FamilyListStateAction.loadDescriptionFromRecap, state => state),
	on(FamilyListStateAction.update, (state, param) => param),
	on(FamilyListStateAction.saveComplete, (state, param) => param),
	on(FamilyListStateAction.reset, () => initialState),
	on(FamilyListStateAction.error, (state, param) => ({
		data: state ? state.data : null,
		type: param.type
	}))
);

export function reducer(state: BaseStateModel<ArticleRecap>, action: TypedAction<FamilyListActionEnum>) {
	return _reducer(state, action);
}