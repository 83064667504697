import { Injectable } from '@angular/core';
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateSerializerModel } from '../../model/state/base-state.model';

@Injectable({
  providedIn: 'root',
})
export class UtilStoreService {

  constructor(private store: Store) {}

  serielizedStoreResetItem<T>(
    action: ActionCreator<any, (props: any) => TypedAction<any>>,
    key: string
  ): void {
    const param: BaseStateSerializerModel<T> = {
      data: null,
      stateSlice: {
        type: key
      }
    };
    this.store.dispatch(action(param));
  };
}
