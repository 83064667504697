import { MatSnackBar } from "@angular/material/snack-bar";
import { FileTypeEnum } from "@saep-ict/angular-core";
import { PermissionKeyEnum } from "@saep-ict/angular-spin8-core";
import { DataConfigurationItemModel } from "../../../model/data-configuration.model";
import { ConfigurationService } from '../../../service/rest/backoffice/configuration.service';
import * as Configuration from '../../../enum/configuration/type-upload-file';

export const genericSuccessMessage = (snackBar: MatSnackBar) => {
    snackBar.open('Operation successfull', 'OK', {
        duration: 3000
    });
}

export const genericErrorMessage = (snackBar: MatSnackBar) => {
    snackBar.open('An error has occurred, try again later', 'OK', {
        duration: 10000
    });
}
export const fileUploadHandler = (
    id: string,
    data: any,
    configurationService: ConfigurationService,
    snackBar: MatSnackBar
) => {
    const configurationItem = dataConfigurationList().find(i => i.id === id);
    configurationService.fileUpload(
        data,
        { type: configurationItem.type },
        res => genericSuccessMessage(snackBar),
        err => genericErrorMessage(snackBar)
    );
}

export const dataConfigurationList = (
    configurationService?: ConfigurationService,
    snackBar?: MatSnackBar
): DataConfigurationItemModel[] => {
    return [
        {
            id: 'category_order',
            title: 'configuration.order_form_category',
            type: 'category',
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (id: string, data: any) => fileUploadHandler(id, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.AGENT_DASHBOARD]
        },
        {
            id: 'category_survey_center',
            title: 'configuration.survey_center_budget',
            type: Configuration.type.SURVEY_CENTER,
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (id: string, data: any) => fileUploadHandler(id, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.AGENT_DASHBOARD]
        },
        {
            id: 'category_asset_request_center_print_production_flow',
            title: 'configuration.asset_request_center_print_production_flow',
            type: Configuration.type.PRINT_PRODUCTION_FLOW,
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (id: string, data: any) => fileUploadHandler(id, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER]
        },
        {
            id: 'category_asset_request_advertsing_material',
            title: 'configuration.advertsing_material',
            type: Configuration.type.ADVERTISING_MATERIAL,
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (id: string, data: any) => fileUploadHandler(id, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER]
        }
    ]
} 
