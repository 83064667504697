import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OpportunityActionEnum, OpportunityStateAction } from './opportunity.actions';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { AdapterOptions, PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { StoreUtilService } from '../../service/util/store-util.service';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import {
	PouchDbConstant,
	PouchDbModel,
	OpportunityPouchModel,
	UserDetailModel,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-spin8-core';
import { StateFeature } from '..';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class OpportunityEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) => from(this.getOpportunity(action))),
			map((opportunity: BaseStateModel<OpportunityPouchModel[]>) => OpportunityStateAction.update(opportunity)),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<OpportunityPouchModel[]>) => from(this.getAllOpportunity(action))),
			map((opportunity: BaseStateModel<OpportunityPouchModel[]>) => OpportunityStateAction.update(opportunity)),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.SAVE),
			mergeMap((action: BaseStateModel<OpportunityPouchModel[]>) => from(this.putOpportunity(action.data))),
			map((opportunities: BaseStateModel<OpportunityPouchModel[]>) => OpportunityStateAction.loadAll()),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(error));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<OpportunityPouchModel>) => from(this.deleteOpportunity(action.data))),
			map((user: BaseStateModel<PouchDeleteResponse>) => OpportunityStateAction.removed()),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(null));
				return caught;
			})
		)
	);

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	pouchDbDocumentType: PouchDbModel.PouchDbDocumentType;
	options: AdapterOptions = { accessCommons: false };

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private utilPouchService: UtilPouchService,
		private utilService: AngularCoreUtilService,
		private storeUtilService: StoreUtilService
	) {
		this.user$.pipe().subscribe(user => {
			this.user = user ? user.data : null;
			if (this.user && this.user.current_permission) {
				if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE) {
					this.pouchDbDocumentType = PouchDbModel.PouchDbDocumentType.USER;
					this.options = { accessCommons: true };
				} else {
					this.pouchDbDocumentType = PouchDbModel.PouchDbDocumentType.ORGANIZATION;
				}
			}
		});
	}

	async getOpportunity(action: BaseStateModel<BasePouchModel>): Promise<BaseStateModel<OpportunityPouchModel[]>> {
		try {
			return await this.storeUtilService.getCouchDetailAndReturnInListState<OpportunityPouchModel>(
				action,
				this.pouchDbDocumentType,
				this.options
			);
		} catch (err) {
			throw new Error(err);
		}
	}

	async putOpportunity(data: OpportunityPouchModel[]): Promise<BaseStateModel<OpportunityPouchModel[]>> {
		return (
			(
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(this.pouchDbDocumentType, this.options)
			).basePouch
				.saveBodyTable(data[0])
				// .opportunityPouch
				// 	.putOpportunity(data[0], data[0]._id ? false : true)
				.then(async (opportunity: OpportunityPouchModel) => {
					this.utilService.showDialog('opportunity.save');
					return new BaseState([opportunity]);
				})
				.catch((err: PouchErrorResponse) => {
					throw new Error(err.error + err.reason);
				})
		);
	}

	async deleteOpportunity(data: OpportunityPouchModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		return (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(this.pouchDbDocumentType, this.options)
		).opportunityPouch
			.deleteOpportunity(data)
			.then(async opportunity => {
				return new BaseState(opportunity);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getAllOpportunity(
		action: BaseStateModel<OpportunityPouchModel[]>
	): Promise<BaseStateModel<OpportunityPouchModel[]>> {
		const documentName = 'opportunity';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomerAppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
		};

		await this.utilPouchService
			.allDocs<OpportunityPouchModel>(allDocsParam, this.pouchDbDocumentType, this.options)
			.then(res => {
				action.data = res.data;
			})
			.catch(err => console.log(err));

		return action;
	}
}
