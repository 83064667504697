<mat-dialog-wrapper [title]="data.title" [style.width]="'70vw'">
	<ng-container content *ngIf="data">
		<p
      *ngIf="data.text"
      [innerHTML]="data.text"
    ></p>
    <preview-container
      [configuration]="
        {
          image: data.image,
          icon: 'attach_file',
          width: '70vw',
          height: '70vw',
          onClickHandler: false
        }
      "
    >
    </preview-container>
	</ng-container>
</mat-dialog-wrapper>
