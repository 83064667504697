import { BasePouchModel, BodyTablePouchModel } from "@saep-ict/pouch_agent_models";

export interface BodyTablePouchCustomModel extends BasePouchModel, BodyTablePouchModel {
	type: string;
}

export interface TicketStatusItem {
	value: string;
	name: string;
	color: string;
}

export interface FileUpload {
	name?: string;
	link?: string;
	data?: string;
	aws_s3_temporary_link?: string;
}
