import { Component, Inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControlMultipurposeEnum, FormControlMultipurposeModel, FormControlMultipurposeService, SubscribeManagerService, AngularCoreUtilService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, map, take } from 'rxjs/operators';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerContextApplication from '../../../constants/configuration-customer/context-application/context-application.constant';

@Component({
	selector: 'dialog-destination-detail',
	templateUrl: './dialog-destination-detail.component.html',
	styleUrls: ['./dialog-destination-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogDestinationDetailComponent implements OnDestroy {
	formFieldList: FormControlMultipurposeModel.Item[];
	subscribeFormDataPrefix = 'destination-edit-form-value-change_';
	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: {
			title: string,
			formFiledConfiguration: FormControlMultipurposeModel.Item[],
			oldValue: DestinationPouchModel,
			canEdit: boolean
		},
		public dialogRef: MatDialogRef<DialogDestinationDetailComponent>,
		private formFieldMultipurposeService: FormControlMultipurposeService,
		private utilService: AngularCoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
	) {
		this.formFieldList =
			this.formFieldMultipurposeService.returnFormFieldListWithRootFormId(
				this.data.formFiledConfiguration ?
				this.data.formFiledConfiguration :
				// TODO: dinamizzazione del context in sopstituzione di `ContextApplicationItemCodeEnum.BACKOFFICE`
				ConfigurationCustomerContextApplication.formControlMultipurpose[ContextApplicationItemCodeEnum.BACKOFFICE]
				.DESTINATION_BASE,
				`${this.utilService.guid()}`
			);
		if (!data.canEdit) {
			this.formFieldList =
			this.formFieldMultipurposeService.returnFormFieldListWithAllDisabled(this.formFieldList);
		}
		this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, this.data.oldValue);
		this.formFieldList =
			this.formFieldMultipurposeService.updateFormFieldListValue(
				this.formFieldList,
				this.data.oldValue
			);
		this.createForm();
		this.formFieldMultipurposeService.updateFormControlAccordingToType(
			this.form,
			<FormControlMultipurposeModel.Item>{
				type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
				form_control_list: this.formFieldList
			},
			this.data.oldValue
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeFormData(): Observable<any> {
		return this.form.valueChanges.pipe(
			take(1),
			debounceTime(100),
			map(async value => {
				value = this.utilService.deleteEmptyProperties(value);
				this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, value);
				await this.formFieldMultipurposeService.updateFormControlAccordingToType(
					this.form,
					<FormControlMultipurposeModel.Item>{
						type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
						form_control_list: this.formFieldList
					},
					value
				);
				this.subscribeManagerService.populate(
					this.subscribeFormData().subscribe(),
					this.subscribeFormDataPrefix + this.formFieldList[0].form_id
				);
			})
		);
	}

	createForm() {
		const controls = {};
		for (const field of this.formFieldList) {
			controls[field.name] = this.formFieldMultipurposeService.returnNewFormControl(field);
		}
		this.form = new FormGroup(controls);
		this.subscribeManagerService.populate(
			this.subscribeFormData().subscribe(),
			this.subscribeFormDataPrefix + this.formFieldList[0].form_id
		);
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.dialogRef.close(this.prepareSaveForm());
		}
	}

	prepareSaveForm() {
		const formModel = this.utilService.deleteEmptyProperties(this.form.value);
		formModel.address.address.trim() as string;
		formModel.address.locality.trim() as string;
		formModel.address.zip_code as string;
		formModel.address.country.toUpperCase() as string;
		return this.utilService.deleteEmptyProperties(formModel);
	}
}
