<ul *ngIf="sidebarContentStandardComponentConfig.length > 0" class="sidebar-content-standard">
	<li *ngFor="let item of sidebarContentStandardComponentConfig">
		<mat-icon *ngIf="item.icon && item.icon.type === iconType.MATERIAL">{{ item.icon.name }}</mat-icon>
		<mat-icon *ngIf="item.icon && item.icon.type === iconType.CUSTOM" [svgIcon]="item.icon.name"></mat-icon>

		<div class="list-content">
			<p>
				{{ item.title | translate | sentencecase }}
				<span *ngIf="item.rightContent">{{ item.rightContent | translate }}</span>
			</p>
			<div *ngIf="item.subtitle" class="subtitle">{{ item.subtitle | translate }}</div>
		</div>
	</li>
</ul>
