<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<div class="insert-comment" *ngIf="opportunity && !opportunity.close_date">
					<mat-form-field class="insert-comment__input">
						<mat-label>{{ 'opportunity.notes.add' | translate | sentencecase }}...</mat-label>
						<input matInput type="text" [(ngModel)]="addCommentValue" autocomplete="off" />
					</mat-form-field>
					<button
						class="insert-comment__button"
						mat-icon-button
						color="primary"
						[disabled]="!addCommentValue"
						(click)="addComment()"
					>
						<mat-icon>send</mat-icon>
					</button>
				</div>

				<comment-thread
					*ngIf="opportunity && opportunity.comment_list; else noData"
					[commentList]="opportunity.comment_list"
					[canEdit]="true"
					(onEdit)="editComment($event)"
					(onDelete)="deleteComment($event)"
				></comment-thread>
				<ng-template #noData>
					<p class="opacity-50">
						{{ 'opportunity.notes.not_found' | translate | sentencecase }}
					</p>
				</ng-template>
			</mat-card>
		</div>
	</div>
</div>
