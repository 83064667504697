import { BasePouchModel, BodyTablePouchModel } from './base-pouch.model';
import { DivisionPouchModel } from './organization-pouch.model';
import { AddressPouchModel, ListPouchModel } from './util-pouch.model';

export interface DestinationPouchModel extends ListPouchModel {
	address: AddressPouchModel;
	business_name: string; // ragione sociale specifica, diversa da quella dell'organization
	description?: string;
	division_list?: DivisionPouchModel[];
	is_registered_office?: boolean;
	position?: PositionPouchModel;
}

export interface PositionPouchModel {
	lat: number;
	lng: number;
}

export interface OrderAddressPouchModel extends AddressPouchModel, BasePouchModel, BodyTablePouchModel {}