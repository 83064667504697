import { Injectable } from '@angular/core';
import { OpportunityPouchModel } from '../../model/state/opportunity-state.model';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreUtilOpportunityService {
	constructor(private router: Router) {}

	addOpportunityId(opportunity: OpportunityPouchModel): OpportunityPouchModel {
		throw new Error('Method not implemented.');
	}

	// navigateToOpportunityDetail(companyLevel: string, companyCode: string, contextCode: ContextApplicationItemCodeEnum) {
	// 	if (contextCode && companyCode) {
	// 		switch (contextCode) {
	// 			case ContextApplicationItemCodeEnum.CRM:
	// 				this.router.navigate([
	// 					`${PATH_URL.PRIVATE}/${ROUTE_URL.opportunity}/${companyLevel}/${companyCode}`
	// 				]);
	// 				break;
	// 			default:
	// 				break;
	// 		}
	// 	} else if (companyCode) {
	// 		this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/${companyLevel}/${companyCode}`]);
	// 	}
	// }
}
