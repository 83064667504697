import { OrderStateModel } from "@saep-ict/angular-spin8-core";
import * as ShippingEnum from '../../../enum/shipping.enum';

export const isCostToApply = (order: OrderStateModel): boolean => {
    if (
        !order.header.carrier ||
        (
            order.header.carrier.code_item &&
            order.header.carrier.code_item !== ShippingEnum.Carrier.RAC
        )
    ) {
        return true;
    } else {
        return false;
    }
}