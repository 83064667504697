<!-- codice commentato per rimuovere sidebar da sales report per v3 agenti START<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.BOTTOM">
 END-->	
 <ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'analytics.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<button mat-icon-button (click)="salesReportClosablePanel.isVisible = true">
						<mat-icon> help_outline </mat-icon>
					</button>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle
						>{{ 'analytics.by' | translate | sentencecase }}
						{{ formFilter.get('unit').value }}</mat-card-subtitle
					>
				</mat-toolbar-row>
			</mat-toolbar>
			<closable-panel #salesReportClosablePanel>
				<ng-container content>
					{{ 'statistic.info_panel' | translate | sentencecase }}
				</ng-container>
			</closable-panel>
			<box-trend-info [boxTrendInfoComponentConfig]="trendBoxContent"></box-trend-info>
			<mat-divider class="both-spacing"></mat-divider>
			<statistic-nested-category
				[configuration]="statisticNestedCategoryConfiguration"
			></statistic-nested-category>
		</mat-card>
	<div *ngIf="user.current_permission.context_application !== contextApplicationItemCodeEnum.AGENT">
		<mat-card class="michelangelo-theme autoheight mt-20 mt-30">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'product.brand.label_plural' | translate | sentencecase }}
					<span class="flex-span"></span>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>{{ 'product.brand.all_brands' | translate | sentencecase }}</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<chart-wrapper [config]="chartCategoryRootBar.chart" class="mt-30 mt-20 mb-20 mb-30"></chart-wrapper>
		</mat-card>
		<mat-card class="michelangelo-theme autoheight mt-20 mt-30">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'analytics.progressive' | translate | sentencecase }}
					<span class="flex-span"></span>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>{{ 'product.brand.by_brands' | translate | sentencecase }}</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<div *ngIf="chartStatistcSummaryListDoughnut" class="charts-group mb-30 mb-20 mt-40 mt-30">
				<chart-wrapper
					*ngFor="let chart of chartStatistcSummaryListDoughnut"
					[config]="chart.chart"
				></chart-wrapper> 
				<!-- <chart-wrapper
					[config]="fakeChart()"
				></chart-wrapper> -->
			</div>
		</mat-card> 
	</div>
	</ng-container>
	<!-- <ng-container pageSide>
		<sidebar-content-wrapper [title]="'general.filters'">
			<ng-container main-content>
				<form class="michelangelo-theme" *ngIf="formFilter" [formGroup]="formFilter">
					<h3>{{ 'general.unit.label' | translate | sentencecase }}</h3>
					<mat-radio-group formControlName="unit">
						<mat-radio-button
							color="primary"
							*ngFor="let item of object.keys(statisticEnum.Unit)"
							[value]="statisticEnum.Unit[item]"
						>
							{{ statisticEnum.Unit[item] | sentencecase }}
						</mat-radio-button>
					</mat-radio-group>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar> --> 
