<app-page-header
	[title]="'saep_ict_angular_spin8_core.general.catalogue' | translate | sentencecase"
	[backgroundImageUrl]="'theme.header_bg.catalog.backgroundImageUrl' | translate"
></app-page-header>
<div class="container">
	<div
		list-wrapper
		#listWrapper
		class="list-wrapper list-wrapper__b2c-catalog"
		[filterOpened]="!isMobile"
		[mode]="isMobile ? 'over' : 'side'"
		sidenavWidth="300px"
		(window:resize)="onResize($event)"
	>
		<ng-container main>
			<section class="filter-simple">
				<div *ngIf="searchTerm" class="row">
					<div class="col">
						<h1 class="search-title">
							{{ 'catalogue.search.for' | translate | sentencecase }}: {{ searchTerm }}
						</h1>
						<span class="search-all" (click)="searchResultsReset()">{{
							'catalogue.search.all' | translate | sentencecase
						}}</span>
					</div>
				</div>
				<div *ngIf="articleList && articleList.length" class="row">
					<div class="col-12 col-lg-4">
						<sort-selector
							[configuration]="sortConfiguration"
							(onChange)="b2cArticleListWrapper.localListHandlerApplyFilter({ sort: $event })"
						></sort-selector>
					</div>
					<div
						class="col-12 col-lg-8 icon-container"
						[class.filter-simple__viewtype]="!isMobile"
						[class.col-lg-4]="!isMobile"
					>
						<span *ngIf="!isMobile" class="flex-span"></span>
						<app-viewtype-toggle
							[viewTypeDefault]="viewType"
							(changingViewList)="viewType = $event"
						></app-viewtype-toggle>
						<ng-container *ngIf="isMobile && filterShowWidget()">
							<span class="flex-span"></span>
							<button *ngIf="isMobile" mat-icon-button (click)="listWrapper.sidenav.open()">
								<mat-icon>filter_list</mat-icon>
							</button>
						</ng-container>
					</div>
				</div>
			</section>
			<b2c-article-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerPaginatorShow]="true"
				[pageSizeOptions]="[9, 18, 36, 72]"
				[viewType]="viewType"
			></b2c-article-list-wrapper>
		</ng-container>

		<ng-container side>
			<ng-container *ngIf="filterShowWidget(); else noFilterToShow">
				<!-- visibile quando presenti categorie (filtrate in base a articleList) -->
				<recursive-category-list
					*ngIf="
						utilCategoryListService.filteredCategoryList &&
						utilCategoryListService.filteredCategoryList.length
					"
					[categoryList]="utilCategoryListService.filteredCategoryList"
				></recursive-category-list>
				<!-- visibile quando i prodotti hanno tutti stesso prezzo -->
				<mat-accordion *ngIf="minValue < maxValue">
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>{{ 'catalogue.filter.price' | translate | uppercase }}</mat-panel-title>
						</mat-expansion-panel-header>
						<ngx-slider
							[(value)]="minValue"
							[(highValue)]="maxValue"
							[options]="options"
							(userChangeEnd)="filterArticle()"
						></ngx-slider>
					</mat-expansion-panel>
				</mat-accordion>
				<button
					mat-button
					color="primary"
					class="btn-sidebar__cancel custom-button"
					type="button"
					(click)="filterArticleReset()"
				>
					Reset
				</button>
			</ng-container>
		</ng-container>
	</div>
</div>
<ng-template #noFilterToShow>
	{{ 'general.no_filter_to_show' | translate | sentencecase }}
</ng-template>
