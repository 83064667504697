import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { CategoryListAction, CategoryListActionEnum } from './category-list.actions';
// model
import { BaseStateModel } from '@saep-ict/angular-core';
import { CategoryMap } from '@saep-ict/pouch_agent_models';

export const initialState: BaseStateModel<CategoryMap> = null;

const _reducer = createReducer(
	initialState,
	on(CategoryListAction.update, (state, param) => param),
	on(CategoryListAction.loadAll, state => state),
	on(CategoryListAction.notExisting, state => ({ type: CategoryListActionEnum.NOT_EXISTING, ...state })),
	on(CategoryListAction.reset, (state, param) => initialState)
);

export function reducer(state: BaseStateModel<CategoryMap>, action: TypedAction<CategoryListActionEnum>) {
	return _reducer(state, action);
}
