import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'b2c-authentication-wrapper',
	templateUrl: './b2c-authentication-wrapper.component.html',
	styleUrls: ['./b2c-authentication-wrapper.component.scss']
})
export class B2cAuthenticationWrapperComponent implements OnInit {
	context_type: string;

	constructor(private route: ActivatedRoute) {
		this.context_type = this.route.snapshot.data['context_type'] ? this.route.snapshot.data['context_type'] : null;
	}

	ngOnInit() {}
}
