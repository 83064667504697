<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="
				'general.search' | translate: { x: 'receipt.document_type.invoice' | translate } | sentencecase
			"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<!-- <ng-template tdDataTableTemplate="type" let-value="value">
			{{ 'receipt.document_type.' + value | translate | sentencecase }}
		</ng-template> -->
		<ng-template tdDataTableTemplate="organization" let-value="value" let-row="row">
			<p>
				<organization-table-heading
					[organization]="value"
				>
				</organization-table-heading>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="price.total" let-value="value" let-row="row">
			{{ ( value ? value : 0 ) | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
			{{ row.currency.description_short }}
		</ng-template>
		<ng-template tdDataTableTemplate="actions" let-row="row">
			<button
				*ngIf="row.attachment_list && row.attachment_list.length > 0"
				type="button"
				mat-icon-button
				(click)="
					selectActionDialog.emit(row);
					$event.stopPropagation()
				"
			>
				<mat-icon [svgIcon]="'custom-attach'">attach_file</mat-icon>
			</button>
		</ng-template>
	</td-data-table>

	<mat-toolbar class="____mt-20">
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
				[hidePageSize]="true"
			>
			</mat-paginator>
		</mat-toolbar-row>
	</mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
