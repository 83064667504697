import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { Store } from '@ngrx/store';

@Component({
	selector: 'offer-free-line-list-wrapper',
	templateUrl: './offer-free-line-list-wrapper.component.html',
	styleUrls: ['./offer-free-line-list-wrapper.component.scss']
})
export class OfferFreeLineListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectDelete: EventEmitter<number> = new EventEmitter();

	@Input() showActions: boolean = true;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilPriceService: UtilPriceService
	) {
		super(utilService, translateService, store);
	}
}
