import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { AdditionalService, ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { AdditionalServiceActionEnum, AdditionalServiceStateAction } from './additional-service.action';
import { AdditionalServiceStateModel } from '@saep-ict/angular-spin8-core';

export namespace AdditionalServiceStateReducer {
	export const initialState: BaseStateModel<AdditionalServiceStateModel> = null;

	const _reducer = createReducer(
		initialState,
		on(AdditionalServiceStateAction.loadAll, state => state),
		on(AdditionalServiceStateAction.update, (state, additionalServices) => additionalServices),
		on(AdditionalServiceStateAction.reset, () => initialState),
		on(AdditionalServiceStateAction.saveComplete, (state, additionalServices) => additionalServices),
		on(AdditionalServiceStateAction.error, (state, additionalService) => ({
			data: state ? state.data : null,
			type: additionalService.type
		}))
	);

	export function reducer(
		state: BaseStateModel<AdditionalServiceStateModel>,
		action: TypedAction<AdditionalServiceActionEnum>
	) {
		return _reducer(state, action);
	}
}
