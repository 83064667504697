import { Validators } from '@angular/forms';
import { Injector } from '@angular/core';

// model
import { OrganizationLevelEnum, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';

// constant
import { creationFieldMapDocumentBase } from '../../creation-field-map-base.const';
import { creationFieldMapDestinationBase } from '../../destination/creation-field-map-destination-base.const';

// misc
import {
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum,
	FormControlMultipurposeService,
	FormControlMultipurposeSetValidator,
	FormControlMultipurposeSetDisabled
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { creationFieldMapDivision } from '../../creation-field-map-division.const';

const injector = Injector.create({
	providers: [{ provide: FormControlMultipurposeService, deps: [] }]
});

const formControlMultipurposeService = injector.get(FormControlMultipurposeService);

export const creationFieldMapContextCodeOrganizationBase: FormControlMultipurposeModel.Item[] =
  _.cloneDeep(creationFieldMapDocumentBase).concat([
    {
      name: 'type',
      on_form_template: false
    },
    {
      name: 'source',
      on_form_template: false
    },
    {
      name: 'status',
      on_form_template: false,
      value: 'ENABLED'
    },
    {
      name: 'level',
      label: 'saep_ict_angular_spin8_core.general.level',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.SELECT,
      value: OrganizationLevelEnum.LEAD,
      validator_list: [Validators.required],
      option_list: [
        {
          value: OrganizationLevelEnum.LEAD,
          label: 'saep_ict_angular_spin8_core.page.organization.level.lead'
        },
        {
          value: OrganizationLevelEnum.PROSPECT,
          label: 'saep_ict_angular_spin8_core.page.organization.level.prospect'
        },
        {
          value: OrganizationLevelEnum.CUSTOMER,
          label: 'saep_ict_angular_spin8_core.page.organization.level.customer'
        }
      ]
    },
    {
      name: 'organization_type',
      label: 'saep_ict_angular_spin8_core.page.organization.organization_type',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.SELECT,
      column_width: 6,
      validator_list: [Validators.required],
      option_list: [
        {
          value: OrganizationTypeEnum.PRIVATE,
          label: 'saep_ict_angular_spin8_core.page.organization.organization_type_label.PRIVATE'
        },
        {
          value: OrganizationTypeEnum.COMPANY,
          label: 'saep_ict_angular_spin8_core.page.organization.organization_type_label.COMPANY'
        }
      ]
    },
    {
      name: 'business_name',
      // TODO: rivedere dopo aggiornamento libreria per mostrare l'* in maniera dinamica
      label: 'saep_ict_angular_spin8_core.page.organization.business_name',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 6,
      set_validator_list: formValue =>
        FormControlMultipurposeSetValidator.requiredIfSomeFieldIsSomeValue(formValue, {
          pathToProperty: 'organization_type',
          propertyValue: OrganizationTypeEnum.COMPANY
        }),
      set_disabled: formValue =>
        FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
          pathToProperty: 'organization_type',
          propertyValue: OrganizationTypeEnum.PRIVATE
        }),
      clear_value_if_disabled: true
    },
    {
      name: 'destination_list',
      label: 'organization.field.destination_list',
      on_form_template: false,
      type: FormControlMultipurposeEnum.ItemType.FORM_ARRAY,
      form_control_list: formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
        _.cloneDeep(creationFieldMapDestinationBase),
        ['is_main_of_list', 'is_registered_office'],
        false
      )
    },
    {
      name: 'division_list',
      label: 'organization.field.division_list',
      on_form_template: false,
      type: FormControlMultipurposeEnum.ItemType.FORM_ARRAY,
      form_control_list: formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
        _.cloneDeep(creationFieldMapDivision),
        ['division', 'is_main_of_list', 'valid'],
        true
      )
    }
  ]);
