import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MediaReplayService } from '../../../service/util/media-replay.service';

@Component({
	selector: 'mat-dialog-wrapper',
	templateUrl: './mat-dialog-wrapper.component.html',
	styleUrls: ['./mat-dialog-wrapper.component.scss']
})
export class MatDialogWrapperComponent implements OnInit, AfterViewInit {
	@ViewChild('ngContentContent') ngContentContent: ElementRef;
	@ViewChild('ngContentActions') ngContentActions: ElementRef;

	@Input() title: String;
	@Input() showCloseIcon: boolean = true;

	showContent = true;
	showActions = true;

	constructor(
    public dialogRef: MatDialogRef<MatDialogWrapperComponent>, 
    private cdRef: ChangeDetectorRef,
	public mediaReplayService: MediaReplayService,
  ) {}

	ngOnInit() {}

	ngAfterViewInit() {
		this.showContent = this.ngContentContent.nativeElement.children.length > 0;
		this.showActions = this.ngContentActions.nativeElement.children.length > 0;
		// detectChanges avoid problems about state changed afterViewInit
		this.cdRef.detectChanges();
	}
}
