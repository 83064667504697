import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { Message } from '@stomp/stompjs';
import { Subscription } from 'rxjs';
import { AngularCoreUtilService } from '../util/util.service';
import { WebSocketTopicStateEnum } from '../../enum/websocket.enum';
import { WebSocketChannel, WebSocketMessageEventHandle, WebSocketMessageModel } from '../../model/websocket.model';

@Injectable({
	providedIn: 'root'
})
export class RxStompService extends RxStomp {

  channelList = <WebSocketChannel<any, any>[]>[];

  constructor(
    private utilService: AngularCoreUtilService
  ) {
    super();
  }

  /**
   * In base ai parametri inviati crea la subscribe su stomp e la catagola in `channelList`
   * @param context : scope per scatenare le funzioni contenute in `messageEventHandler`
   * @param messageConfig : configurazione generale del topic che permette di istanziare un nuovo elemento in
   * `channelList` e archiviarvi contenente subscribe ai messaggi specifici, le informazioni di progress ecc
   * @param messageEventHandler : metodi scatenati in base allo stato del messagio ricevuto presso `subscribe()`
   * @returns
   */
  connect<CONTEXT_TYPE, REQUEST_BODY_TYPE, RESPONSE_TYPE>(
    context: CONTEXT_TYPE,
    messageConfig: WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE>,
    messageEventHandler?: WebSocketMessageEventHandle
  ): Promise<void> {
    return new Promise((resolve) => {
      try {
        // TODO: non essendoci una vera e propria gestione del canale, questi viene popolato con l'id del topic,
        // entità forse più assimilabile algli elementi di processList. In questa implementazione per ogni
        // channel è presente un solo elemento in processList, in quanto entrambi i livelli sfrutano come chiave per
        // l'identificazione degli index messageConfig.message.header.id.
        // Verificare se in futuro occorrerà aprire più canali in parallelo e per ognuno gestire molteplici processi o
        // semplificare modello e flusso
        const indexChannel = this.utilService.getElementIndex(this.channelList, 'id', messageConfig.message.header.id,);
        if (!indexChannel && indexChannel !== 0) {
          const channel: WebSocketChannel<REQUEST_BODY_TYPE, RESPONSE_TYPE> = {
            rxStompSubscribe$: this.subscription(context, messageConfig, messageEventHandler),
            id: messageConfig.message.header.id,
            type: messageConfig.type,
            processList: [messageConfig]
          };
          this.channelList.push(channel);
        }
        resolve();
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  /**
   * Gestisce la subscription al topic
   * @param context
   * @param messageConfig
   * @param messageEventHandler
   * @returns
   */
  private subscription<CONTEXT_TYPE, REQUEST_BODY_TYPE, RESPONSE_TYPE>(
    context: CONTEXT_TYPE,
    messageConfig: WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE>,
    messageEventHandler: WebSocketMessageEventHandle
  ): Subscription {
    return this.watch(`/topic/${messageConfig.message.header.id}`).subscribe((e: Message) => {
      const message: WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE> = JSON.parse(e.body);
      // si veda il commento in connect() che giustifica la ridondanza temporanea tra channel e processList item
      const indexChannel: number = this.utilService.getElementIndex(this.channelList, 'id', message.message.header.id);
      const indexProcess: number =
        this.returnProcessIndex(
          this.channelList[indexChannel].processList,
          message.message.header.id
        );
      this.channelList[indexChannel].processList =
        this.returnUpdatedProcessList<REQUEST_BODY_TYPE, RESPONSE_TYPE>(
          message,
          indexProcess,
          this.channelList[indexChannel].processList
        );
      // TODO: modificare il duplice passaggio di `message.message.header.id` presso `processStop()`
      // si veda il commento in `connect()`
      switch (message.message.header.state) {
        case WebSocketTopicStateEnum.COMPLETED:
          this.processStop(message.message.header.id, message.message.header.id);
          break;
        case WebSocketTopicStateEnum.ERROR:
          this.processStop(message.message.header.id, message.message.header.id);
          break;
      }
      if (messageEventHandler && messageEventHandler.hasOwnProperty(message.message.header.state)) {
        messageEventHandler[message.message.header.state](context, message);
      }
    });
  }

  /**
   * Restituisce l'index del processo, effettuando il match allo specifico livello di annidamento `message.header.id`
   * @param array
   * @param processId
   * @returns
   */
  private returnProcessIndex<REQUEST_BODY_TYPE, RESPONSE_TYPE>(
    array: WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE>[],
    processId: string
  ): number {
    let indexProcess: number;
    for (let i = 0; i < array.length; i++) {
      if (array[i].message.header.id === processId) {
        indexProcess = i;
        break;
      }
    }
    return indexProcess;
  }

  /**
   * Restituisce `processList` dopo aver creato o aggiornato uno dei suoi elementi
   * @param process
   * @param indexProcess
   * @param processList
   * @returns
   */
  private returnUpdatedProcessList<REQUEST_BODY_TYPE, RESPONSE_TYPE>(
    process: WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE>,
    indexProcess: number,
    processList: WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE>[]
  ): WebSocketMessageModel<REQUEST_BODY_TYPE, RESPONSE_TYPE>[] {
    if (indexProcess || indexProcess === 0) {
      processList[indexProcess].message.header.action = process.message.header.action;
      processList[indexProcess].message.header.description = process.message.header.description;
      processList[indexProcess].message.header.progress = process.message.header.progress;
      processList[indexProcess].message.header.state = process.message.header.state;
      processList[indexProcess].message.data = process.message.data;
    } else {
      processList.push(process);
    }
    return processList;
  }

  // TODO: queste dinamiche andranno bonificate una volta definito quanto presso connect()
  processStop(idChannel: string, idProcess: string) {
    const indexChannel: number = this.utilService.getElementIndex(this.channelList, 'id', idChannel);
    const indexProcess: number =
      this.returnProcessIndex(
        this.channelList[indexChannel].processList,
        idProcess
      );
    this.channelList[indexChannel].processList.splice(indexProcess, 1);
    if (this.channelList[indexChannel].processList.length === 0) {
      this.channelList[indexChannel].rxStompSubscribe$.unsubscribe();
      this.channelList.splice(indexChannel, 1);
    }
  }

}
