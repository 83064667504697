import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, RestBaseMessageError } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InformativePageModel, AngularSpin8CoreInformativePageService } from '@saep-ict/angular-spin8-core';
import { InformativePageActionEnum, InformativePageStateAction } from './informative-page.actions';

@Injectable()
export class InformativePageEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InformativePageActionEnum.LOAD),
			mergeMap((action: { slug: string }) => from(this.getInformativePage(action.slug))),
			map((informativePage: BaseStateModel<InformativePageModel[]>) =>
				InformativePageStateAction.update(informativePage)
			),
			catchError((error, caught) => {
				this.store.dispatch(InformativePageStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InformativePageActionEnum.LOAD_ALL),
			mergeMap((action: { slug: string }) => from(this.getAllInformativePage())),
			map((informativePageList: BaseStateModel<InformativePageModel[]>) =>
				InformativePageStateAction.update(informativePageList)
			),
			catchError((error, caught) => {
				this.store.dispatch(InformativePageStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InformativePageActionEnum.SAVE),
			mergeMap((action: BaseStateModel<InformativePageModel>) => from(this.saveInformativePage(action))),
			map((informativePage: BaseStateModel<InformativePageModel>) => InformativePageStateAction.loadAll()),
			catchError((error, caught) => {
				this.store.dispatch(InformativePageStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private informativePageService: AngularSpin8CoreInformativePageService
	) {}

	// returns the requested document based on the slug
	async getInformativePage(slug: string): Promise<BaseStateModel<InformativePageModel[]>> {
		return this.informativePageService
			.getInformativePage({ slug: slug })
			.then(res => {
				return new BaseState(res.data);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async getAllInformativePage(): Promise<BaseStateModel<InformativePageModel[]>> {
		return this.informativePageService
			.getInformativePage()
			.then(res => {
				return new BaseState(res.data);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async saveInformativePage(
		action: BaseStateModel<InformativePageModel>
	): Promise<BaseStateModel<InformativePageModel>> {
		this.informativePageService.createInformativePage(action.data);
		return new Promise((resolve, reject) => {
			resolve(action);
		});
	}
}
