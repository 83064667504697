<div class="kanban-column__header">
	<span class="kanban-column__title">
		{{ name | sentencecase }}
	</span>
	<span class="kanban-column__subtitle"> {{ opportunityList.length }} {{ 'opportunity.name' | translate }} </span>
</div>
<div class="kanban-column__content">
	<ng-scrollbar [autoWidthDisabled]="false" [autoHeightDisabled]="false" track="vertical">
		<div
			class="kanban-column__content__wrapper"
			cdkDropList
			[cdkDropListData]="opportunityList"
			(cdkDropListDropped)="drop($event)"
		>
			<kanban-card
				*ngFor="let opportunity of opportunityList"
				[opportunity]="opportunity"
				(click)="utilKanbanService.updatedOpportunitySelected(opportunity)"
				cdkDrag
			>
			</kanban-card>
		</div>
	</ng-scrollbar>
</div>
