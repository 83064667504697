import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalListHandlerWidgetWrapper } from '../../misc/local-list-handler/local-list-handler-widget-wrapper';
import { AngularCoreUtilService } from '../../service/util/util.service';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentListManager } from '../../model/widget/attachmment-list-manager.model';
import { AttachedFile, LocalListHandlerBaseApplyFilterParam } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';

@Component({
	selector: 'attachment-list-wrapper',
	templateUrl: './attachment-list-wrapper.component.html',
	styleUrls: ['./attachment-list-wrapper.component.scss']
})
export class AttachmentListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Input() configuration: AttachmentListManager.Configuration<AttachedFile>;
	@Output() onItemDelete: EventEmitter<AttachedFile> = new EventEmitter();
	@Output() onDownloadFromListSelected: EventEmitter<AttachedFile[]> = new EventEmitter();
	@Output() onItemPreviewSelect: EventEmitter<AttachedFile> = new EventEmitter();
   
	itemListSelected: AttachedFile[] = [];

	constructor(
		public utilService: AngularCoreUtilService,
		public translate: TranslateService,
		public store: Store
	) {
		super(utilService, translate, store,);
	}

	localListHandlerApplyFilterCustom(e: LocalListHandlerBaseApplyFilterParam) {
		this.itemListSelected = [];
		this.localListHandlerApplyFilter(e);
	}

}