import { Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';

// model
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { ITdDataTableColumnCustom } from '../../model/structure/list-structure.model';

// misc
import { LocalListHandlerBase } from './local-list-handler-base';
import { AngularCoreUtilService } from '../../service/util/util.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { TdDataTableComponent } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';

@Directive()
export abstract class LocalListHandlerWidgetWrapper extends LocalListHandlerBase {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	onLangChange$: Subscription;

	@Input('localListHandlerWidgetWrapperData')
	set localListHandlerWidgetWrapperData(localListHandlerWidgetWrapperData: LocalListHandlerBaseModel<any>) {
		if (localListHandlerWidgetWrapperData && localListHandlerWidgetWrapperData.data) {
			this.localListHandlerData = localListHandlerWidgetWrapperData;
			this.localListHandlerApplyFilter();
		}
	}
	@Input('localListHandlerWidgetWrapperColumnList')
	set localListHandlerWidgetWrapperColumnList(e: ITdDataTableColumnCustom[]) {
		if (e) {
			this._localListHandlerWidgetWrapperColumnList = this.returnColumnsListWithTransaltedLabel(e);
		}
	}
	_localListHandlerWidgetWrapperColumnList: ITdDataTableColumnCustom[];
	@Input('localListHandlerTextFilterShow') localListHandlerTextFilterShow = false;
	@Input('localListHandlerPaginatorShow') localListHandlerPaginatorShow = false;
	@Input('localListHandlerTitle') localListHandlerTitle: string;
	@Input('localListHandlerSidebarFilterShow') localListHandlerSidebarFilterShow = false;
	@Output('localListHandlerOpenSidebarFilter') localListHandlerOpenSidebarFilter: EventEmitter<string> =
		new EventEmitter();
	@Output('onItemSelect') onItemSelect: EventEmitter<any> = new EventEmitter();

	@Input('localListHandlerWidgetWrapperCanEdit') localListHandlerWidgetWrapperCanEdit = false;
	@Input('localListHandlerWidgetWrapperCanCreate') localListHandlerWidgetWrapperCanCreate = false;
	@Input('localListHandlerWidgetWrapperCanDelete') localListHandlerWidgetWrapperCanDelete = false;

	constructor(
    public utilService: AngularCoreUtilService,
    public translate: TranslateService,
    public store: Store
  ) {
		super(utilService, store);
		this.onLangChange$ = this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
			// se l'implementazione valorizza un set di colonne da passare a td-data-table, il cambio lingua scatena la
			// traduzione delle label che distinguono le colonne
			if (this._localListHandlerWidgetWrapperColumnList && this._localListHandlerWidgetWrapperColumnList.length) {
				this._localListHandlerWidgetWrapperColumnList = this.returnColumnsListWithTransaltedLabel(
					this._localListHandlerWidgetWrapperColumnList
				);
			}

			// la prop. localListHandlerData.languageKey è opzionale e valorizzata soltanto nei casi in cui filtri e ordinamento
			// debbano tenere conto della lingua corrente dell'applicazione. Questa viene aggiornata come seuge soltanto
			// se valorizzata esplicitamente durante la prima implementazione dell'oggetto `localListHandlerData`.
			if (this.localListHandlerData.languageKey) {
				this.localListHandlerData.languageKey = this.localListHandlerData.languageKey ? e.lang : null;
			}
      // il trigger di localListHandlerApplyFilter() scatena i change di pagina che permettono alla tabella di visualizzare
      // le label della lingua idonea, anche quando non è valorizzato localListHandlerData.languageKey
      this.localListHandlerApplyFilter();
			// NB: l'aggiornamento delle label in lingua avveniva per mezzo del deepClone di
			// this.returnColumnsListWithTransaltedLabel(this._localListHandlerWidgetWrapperColumnList)
			// la cosa comportava però la perdita dell'highlight sul sort applicato prima del cambio lingua.
			// nel caso la rimozione del deepClone dovesse comportare side effect, applicare il seguente workaround
			// 1. eliminare `this.localListHandlerApplyFilter()` (riga 58)
			// 2. scommentare il seguente blocco di cosice

			// if (this.localListHandlerData.sort && this.localListHandlerData.sort.name) {
			//   const activeSortElement = this._localListHandlerWidgetWrapperColumnList.find(i => i.name === this.localListHandlerData.sort.name);
			//   if (activeSortElement) {
			//     this.dataTable.handleSort(activeSortElement);
			//   }
			// }
		});
	}

	ngOnDestroy() {
		this.onLangChange$.unsubscribe();
	}

	/**
	 * Restituisce columnList dopo aver compilato label con il valore della stringa contenuta, secondo il percorso espresso
	 * da labelPath, nei bundle multilingua.
	 * Il metodo sfutta translate.instant().
	 * @param columnList
	 * @returns
	 */
	returnColumnsListWithTransaltedLabel(columnList: ITdDataTableColumnCustom[]): ITdDataTableColumnCustom[] {
		for (let i = 0; i < columnList.length; i++) {
			if (columnList[i].labelPath) {
				columnList[i].label = this.translate.instant(columnList[i].labelPath, columnList[i].labelPathParam);
			}
		}
		return columnList;
	}
}
