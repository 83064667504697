<ng-container [ngSwitch]="templateStyleType">
	<form *ngSwitchCase="categoryEnum.TabOnTopStyleType.SELECT" class="michelangelo-theme" [ngClass]="styleClassList">
		<mat-form-field>
			<mat-select
					[value]="_list && _list.length > 0 ? _list[0] : null"
					(selectionChange)="clickHandler($event.value)"
			>
				<mat-option
						*ngFor="let category of _list"
						[value]="category"
				>
					{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>


	<div *ngSwitchDefault [ngClass]="styleClassList">
		<ng-scrollbar [track]="'horizontal'">
			<div class="tab-wrapper">
				<div
					*ngFor="let category of _list"
					[ngClass]="{ active: category.active }"
					(click)="clickHandler(category)"
				>
					{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}
				</div>
			</div>
		</ng-scrollbar>
	</div>
</ng-container>

<category-tab-list
	*ngIf="listChildren"
	[list]="listChildren"
	[configuration]="listChildrenConfiguration"
	(categorySelectedChange)="emitHandler($event)"
></category-tab-list>
