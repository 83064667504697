import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AttachmentResolverService {
	private emptyAR = new Subject<void>();
	emptyAR$ = this.emptyAR.asObservable();

	constructor() {}

	public empty() {
		this.emptyAR.next();
	}
}
