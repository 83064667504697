import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import _ from 'lodash';
import * as ForecastModel from '../../../model/forecast.model';

@Component({
	selector: 'forecast-input-list',
	templateUrl: './forecast-input-list.component.html',
	styleUrls: ['./forecast-input-list.component.scss']
})
export class ForecastInputListComponent implements OnInit {

	@Input('configuration') set configuration(e: ForecastModel.ConfigurationInputList) {
		if (e) {
			this._configuration = _.cloneDeep(e);
			this.inputListMergeDataInStructure(this._configuration);
			this.inputListTotalUpdate();
		}
	};
	_configuration: ForecastModel.ConfigurationInputList;

	@Output() onForecastChange: EventEmitter<ForecastModel.ConfigurationInput[]> = new EventEmitter();
	inputListTotal: number = 0;

	constructor() {}

	ngOnInit(): void {
	}

	inputListMergeDataInStructure(configuration: ForecastModel.ConfigurationInputList) {
		if (configuration.article && configuration.article.forecast) {
			for (const input of configuration.data) {
				const timeRange: ForecastModel.ConfigurationInput =
					configuration.article.forecast.find(i => i.key === input.key);
				if (timeRange) {
					input.value = timeRange.value;
				}
			}
		}
	}

	inputListTotalUpdate() {
		this.inputListTotal = 0;
		for (const input of this._configuration.data) {
			this.inputListTotal = this.inputListTotal + input.value;
		}
	}

	inputUpdate(e: ForecastModel.InputUpdate) {
		let inputValueString = (e.event.target as HTMLInputElement).value;
		inputValueString = inputValueString !== '' ? inputValueString : '0';
		const inputValueNumber = _.toNumber(inputValueString);
		if (!isNaN(inputValueNumber)) {
			const input: ForecastModel.ConfigurationInput =
				this._configuration.data.find(i => e.key === i.key);
			input.value = inputValueNumber;
			this.inputListTotalUpdate();
			const configurationDataItem = this._configuration.data.find(i => i.key === e.key);
			const configurationInputPayload: ForecastModel.ConfigurationInput = {
				key: configurationDataItem.key,
				value: configurationDataItem.value
			};
			this.onForecastChange.emit([configurationInputPayload]);
		}
	}

}
