<div class="user-button-container" [class]="contextClass" [class.open]="isOpen" #userButton (clickOutside)="onClickOutside()" *ngIf="user">
	<button
		mat-button
		class="user-button-container__button"
		(click)="toggleDropdown()"
		[class.open]="isOpen"
		[matTooltip]="user.username"
	>
		<div class="button-content__wrapper">
			<div class="button-content__label">
				<span class="button-content__label__user-info">
					{{ userInfo }}
				</span>
				<span class="button-content__label__user-role opacity-50" *ngIf="userRole">
					{{ userRole | translate | uppercase }}
				</span>
			</div>
			<preview-container
				*ngIf="avatarContainerShow"
				class="avatar"
				[configuration]="
					{
						image: avatarUrl,
						icon: 'person',
						width: '40px',
						height: '40px',
						onClickHandler: false
					}
				"
			>
			</preview-container>
			<mat-icon class="arrow-down" [svgIcon]="'custom-trend-down'" [class.rotate]="isOpen">keyboard_arrow_down</mat-icon>
		</div>
	</button>
	<div class="user-button-container__dropdown mat-elevation-z1" [class.open]="isOpen" [class.open-up]="openUp">
		<!-- context !B2C: Profile -->
		<ng-template [ngIf]="user.current_permission.context_application !== ContextApplicationItemCodeEnum.B2C">
			<mat-divider></mat-divider>
			<button
				mat-button
				[routerLink]="'/' + ROUTE_URL.private + '/' + ROUTE_URL.user"
				class="dropdown__item"
				(click)="toggleDropdown()"
			>
				<mat-icon [svgIcon]="'custom-person'" class="dropdown__item__icon">perm_identity</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.profile' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>
		<!-- context B2C: Area riservata -->
		<ng-template
			[ngIf]="
				!router.url.includes(ROUTE_URL.private) &&
				user.current_permission.context_application === ContextApplicationItemCodeEnum.B2C
			"
		>
			<mat-divider></mat-divider>
			<button mat-button [routerLink]="[PATH_URL.MY_ACCOUNT]" class="dropdown__item" (click)="toggleDropdown()">
				<mat-icon class="dropdown__item__icon">perm_identity</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.reserved_area' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>
		<!-- context *: Dashboard -->
		<ng-template
			[ngIf]="
				!router.url.includes(ROUTE_URL.private) &&
				user.current_permission.context_application !== ContextApplicationItemCodeEnum.B2C
			"
		>
			<mat-divider></mat-divider>
			<button mat-button class="dropdown__item" [routerLink]="'/' + ROUTE_URL.private">
				<mat-icon [svgIcon]="'custom-dashboard'" class="dropdown__item__icon">dashboard</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.dashboard' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>
		<!-- context *: Storefront -->
		<ng-template
			[ngIf]="
				router.url.includes(ROUTE_URL.private) &&
				appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C) &&
				configurationCustomerAuthentication.enableNavigationForHomepage
			"
		>
			<mat-divider></mat-divider>
			<button mat-button class="dropdown__item" [routerLink]="'/' + ROUTE_URL.public">
				<mat-icon [svgIcon]="'custom-store'" class="dropdown__item__icon">store</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.storefront' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>
		<!-- context *: Change Context -->
		<ng-template [ngIf]="user.context_application_list.length > 1 || hasMultipleContextCode">
			<mat-divider></mat-divider>
			<button mat-button class="dropdown__item" (click)="authService.changeContext()">
				<mat-icon [svgIcon]="'custom-sync'" class="dropdown__item__icon">swap_horiz</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.change_context' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>
		<!-- context *: Language -->
		<ng-container
			*ngIf="
				utilTranslateService.languages &&
				utilTranslateService.languages.length &&
				utilTranslateService.languages.length > 1
			"
		>
			<mat-divider></mat-divider>
			<div class="language-selector" [ngClass]="{'open': menuSubItemList.language}" >
				<button mat-button class="dropdown__item"  (click)="menuSubItemToggle('language')">
					<mat-icon [svgIcon]="'custom-globe'" class="dropdown__item__icon">language</mat-icon>
					<span class="dropdown__item__title">
						{{ 'language.name_plural' | translate | sentencecase }}
					</span>
					<mat-icon [svgIcon]="'custom-trend-down'" class="toggleVisibility">keyboard_arrow_down</mat-icon>
				</button>
				<div class="language-list-wrapper" >
					<button mat-button
						*ngFor="let lang of utilTranslateService.languages"
						(click)="utilTranslateService.setLanguage(lang, true)"
						[ngClass]="{'selected': utilTranslateService.translate.currentLang === lang}"
						[value]="lang">
						{{ lang | uppercase }}
					</button>
				</div>
			</div>
		</ng-container>
		<!-- context *: Fontsize -->
		<mat-divider></mat-divider>
		<div class="font-size-selector" [ngClass]="{'open': menuSubItemList.fontSize}" >
			<button mat-button class="dropdown__item"  (click)="menuSubItemToggle('fontSize')">
				<mat-icon [svgIcon]="'custom-visibility'" class="dropdown__item__icon">eye</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.fontsize.name' | translate | sentencecase }}
				</span>
				<mat-icon [svgIcon]="'custom-trend-down'" class="toggleVisibility">keyboard_arrow_down</mat-icon>
			</button>
			<div class="font-size-list-wrapper" >
				<button mat-button
					*ngFor="let fontsize of utilFontsizeService.fontsizeList"
					(click)="utilFontsizeService.fontsizeChange(changeFontSize(fontsize))"
					[ngClass]="{'selected': utilFontsizeService.fontSizeSelected === fontsize}"
					[value]="fontsize">
						{{ 'general.fontsize.' + fontsize | translate | sentencecase }}
				</button>
			</div>
		</div>
		<!-- context *: Logout -->
		<mat-divider></mat-divider>
		<button mat-button (click)="authService.logout()"  class="dropdown__item" (click)="toggleDropdown()">
			<mat-icon [svgIcon]="'custom-logout'" class="dropdown__item__icon">exit_to_app</mat-icon>
			<span class="dropdown__item__title"> Logout </span>
		</button>
	</div>
</div>
