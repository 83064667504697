import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ArticleKitState } from '../../../../model/state/article-list-state.model';

export class ArticleKitPouch extends AbstractPouchdbMethod {
	getArticleKit(articleKitCode: string): Promise<ArticleKitState> {
		return new Promise((resolve, reject) => {
			this.get('article_kit_' + articleKitCode)
				.then((doc: ArticleKitState) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
