import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-courtesy-spinner',
	templateUrl: './courtesy-spinner.component.html',
	styleUrls: ['./courtesy-spinner.component.scss']
})
export class CourtesySpinnerComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
