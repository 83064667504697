import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OpportunityPouchModel } from '@saep-ict/angular-spin8-core';
import { OpportunityActionEnum, OpportunityStateAction } from './opportunity.actions';

export namespace OpportunityStateReducer {
	export const initialState: BaseStateModel<OpportunityPouchModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(OpportunityStateAction.load, state => state),
		on(OpportunityStateAction.loadAll, state => state),
		on(OpportunityStateAction.update, (state, opportunity) => opportunity),
		on(OpportunityStateAction.save, state => state),
		on(OpportunityStateAction.remove, (state, opportunity) => ({
			data: state ? state.data : null,
			type: opportunity.type
		})),
		on(OpportunityStateAction.removed, (state, opportunity) => state),
		on(OpportunityStateAction.reset, () => initialState),
		on(OpportunityStateAction.error, (state, opportunity) => ({
			data: state ? state.data : null,
			type: opportunity.type
		}))
	);

	export function reducer(
		state: BaseStateModel<OpportunityPouchModel[]>,
		action: TypedAction<OpportunityActionEnum>
	) {
		return _reducer(state, action);
	}
}
