import { FormControlMultipurposeEnum, FormControlMultipurposeModel, FormValidatorCustom } from "@saep-ict/angular-core";
import { Validators } from '@angular/forms';
import * as ConfigurationCustomerOrderDate from '../order-date.constant';

export const configurationCustomerOrderHeaderFieldListBackOffice: FormControlMultipurposeModel.Item[] = [
	{
		name: 'customer_order_ref_code',
		on_form_template: true,
		label: 'order.field.customer_reference',
		type: FormControlMultipurposeEnum.ItemType.STRING,
		validator_list: [Validators.required],
		column_width: 6
	},
	{
		name: 'first_evasion_date',
		type: FormControlMultipurposeEnum.ItemType.DATE,
		on_form_template: true,
		label: 'theme.order.field.delivery_date',
		column_width: 6,
		validator_list: [
			Validators.required,
			FormValidatorCustom.isDateFarEnough(
				ConfigurationCustomerOrderDate.firstEvasionDateMinDate,
				[0, 6]
			)
		],
		dateMin: ConfigurationCustomerOrderDate.addDays(ConfigurationCustomerOrderDate.firstEvasionDateMinDate),
		dateMax:
			ConfigurationCustomerOrderDate.addDays(ConfigurationCustomerOrderDate.firstEvasionDateMaxDate),
		dateFilter: ConfigurationCustomerOrderDate.matDatepickerFilter.due_date
	},
	{
		name: 'goods_destination_code',
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		on_form_template: true,
		label: 'theme.order.field.shipping_address',
		column_width: 6,
		validator_list: [Validators.required]
	},
	{
		name: 'shipping_method_code',
		on_form_template: true,
		label: 'theme.order.field.shipping_methods',
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		validator_list: [Validators.required],
		column_width: 6
	},
	{
		name: 'note_order',
		type: FormControlMultipurposeEnum.ItemType.STRING ,
		on_form_template: true,
		label: 'theme.order.field.note',
		column_width: 12,
	},
	{
		name: 'date',
		type: FormControlMultipurposeEnum.ItemType.DATE ,
		on_form_template: true,
		label: 'theme.order.field.date',
		column_width: 12,
	}
];

export const configurationCustomerOrderRevisionHeaderFieldListBackOffice: FormControlMultipurposeModel.Item[] = [
	{
		name: 'date',
		type: FormControlMultipurposeEnum.ItemType.DATE ,
		on_form_template: true,
		label: 'theme.order.field.date',
		column_width: 12,
	},
	
]
