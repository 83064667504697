export enum Type {
	SALES_REPORTS = 'group_sales-reports'
}
export enum Type {
	ORGANIZATION = 'organization'
}
export enum Value {
	ORGANIZATION_ALL = 'organization-all'
}


