import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { AppUtilService } from '../../util/app-util.service';
import { ActivatedRoute } from '@angular/router';
import { StatisticsBaseSerieClient } from '../../../model/statistics.model';
import { BaseTableClass } from '../../td-data-table/base-table.service';
import { chartTimeRange30Days, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class ClientsTop5QuantityTableConfigService extends BaseTableClass {
	statistics$: Observable<BaseStateModel<any>>;
	statistics: any;

	fullDataSetDays30: StatisticsBaseSerieClient[] = [];
	fullDataSetDays60: StatisticsBaseSerieClient[] = [];
	fullDataSetDays90: StatisticsBaseSerieClient[] = [];

	currentDataSet: StatisticsBaseSerieClient[] = [];

	// Questa proprietà viene letta anche per popolare cardDashboardConfigOrderTrend
	public timeRange = chartTimeRange30Days;

	public listClientsData = <LocalListHandlerBaseModel<StatisticsBaseSerieClient>>{
		pagination: {
			pageSize: 5
		}
	};

    columns: ITdDataTableColumn[] = [
		{
			name: 'business_name',
			label: this.translate.instant('client.name')
		},
		{
			name: 'count',
			label: this.translate.instant('order.count'),
			numeric: true
		}
	];

	constructor(
        public store: Store<any>,
        @Inject(TranslateService) private translate: TranslateService,
		public utilService: AppUtilService,
		public route: ActivatedRoute
    ) {
		super(utilService, store, route);
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardClients) {
			this.statistics$ = this.store.select(StateFeature.getStatisticsDetailClients);
		} else {
			this.statistics$ = this.store.select(StateFeature.getStatisticsAgent);
		}

		this.statistics$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.statistics = res.data;
				this.createDataSerie();
				this.populateTable();
			});
	}

	// Functions

	createDataSerie() {
		let clientsTop5 
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardClients) {
			clientsTop5 = this.utilService.findLeaf(this.statistics.data, 'top5.order_quantity');
		} else {
			clientsTop5 = this.utilService.findLeaf(this.statistics.data, 'clients.top5.order_quantity');
		}
		

		this.utilService.findLeaf(clientsTop5, 'days_30').forEach((item: StatisticsBaseSerieClient) => {
			this.fullDataSetDays30.push(item);
		});

		this.utilService.findLeaf(clientsTop5, 'days_60').forEach((item: StatisticsBaseSerieClient) => {
			this.fullDataSetDays60.push(item);
		});

		this.utilService.findLeaf(clientsTop5, 'days_90').forEach((item: StatisticsBaseSerieClient) => {
			this.fullDataSetDays90.push(item);
		});
	}

	populateTable(rangeDays: number = this.timeRange.values[0]) {
		switch (rangeDays) {
			case 30:
				this.currentDataSet = this.fullDataSetDays30;
				break;
			case 60:
				this.currentDataSet = this.fullDataSetDays60;
				break;
			case 90:
				this.currentDataSet = this.fullDataSetDays90;
				break;

			default:
				break;
		}
		this.updateListClientsData(this.currentDataSet);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListClientsData(list: StatisticsBaseSerieClient[]) {
		this.listData.data = _.cloneDeep(list);
		this.listData = Object.assign({}, this.listData);
	}
}
