import { ITdDataTableColumn } from '@covalent/core/data-table';
import * as PermissionContextListColumn from '../../../constants/configuration-customer/permission/permission-column-map/permission-column-map-base.constant';
import { ITdDataTableColumnCustom } from '@saep-ict/pouch_agent_models';

const permissionContextCodeListColumn: ITdDataTableColumnCustom[] = PermissionContextListColumn.baseColumns;


export const base = (canEdit: boolean): ITdDataTableColumn[] => {
	permissionContextCodeListColumn.find(element => element.name === 'action' ? element.hidden = !canEdit : null)
	return permissionContextCodeListColumn;
}

export const excludeForAgent: string[] = ['typology'];
export const excludeForB2b: string[] = ['typology'];
export const excludeForB2c: string[] = [];
export const excludeForBackoffice: string[] = ['typology'];
export const excludeForPortal: string[] = ['typology'];
export const excludeForCrm: string[] = ['typology'];
