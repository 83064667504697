import { NgModule } from '@angular/core';
import { DatePipe, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { EnumIteratorPipe } from './enum-iterator.pipe';

@NgModule({
	declarations: [EnumIteratorPipe],
	exports: [EnumIteratorPipe],
	providers: [DatePipe, TitleCasePipe, CurrencyPipe, TranslatePipe, EnumIteratorPipe]
})
export class PipeModule {}
