import { APP_CONFIG_TOKEN } from '@saep-ict/angular-core';
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, throwError } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FlaskRestApiService {

    baseUrl = this.appConfig.envConfig.angularFlaskIntegration.be_url;
    token = this.appConfig.token;

    constructor(
        @Inject(APP_CONFIG_TOKEN) protected appConfig,
        private readonly http: HttpClient
    ) { }

    returnHttpHeaders(): { headers: HttpHeaders } {
        return {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.token}`
              })
        };
    }

    get<RESPONSE>(dynamicSegment: string) {
        const url = `${this.appConfig.envConfig.angularFlaskIntegration.be_url}/${dynamicSegment}`;
        return this.http.get<RESPONSE>(
            url,
            this.returnHttpHeaders()
        ).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof Error) {
                    console.error("An error occurred:", error.error.message);
                } else {
                    console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                }
                return throwError(() => new Error((error.error)));
            })
        );
    }

}
