import { ArticlePouchModel, HeaderTablePouchModel } from '@saep-ict/pouch_agent_models';
import * as CategoryModel from './category-list.model';

/**
 * Configurazione a livello di blocco inserimento, contenente la struttura
 */
export interface ConfigurationInputList {
    /**
     * Lista dinamica di input da generare per ogni referenza articolo
     */
    data: ConfigurationInput[];
    /**
     * Dati relativi all'articolo, mappati in questa proprietà tipicamente dopo il merge coi dati del
     * forecast salvati a database.
     */
    article?: ArticlePouchModel
}

export interface ConfigurationInput {
    /**
     * Chiave
     */
    key: string | number;
    /**
     * Valore
     */
    value?: number;
    /**
     * Proprietà di appoggio determinata dalle specifiche regole per la disabilitazione del campo input. Da definire se utile
     * salvare su database
     */
    disabled?: boolean;
    /**
     * Proprietà di appoggio per la visualizzazione del nome leggibile del campo, tipicamente composta dinamica mente con il
     * riferimento ad un valore dei bundle multilingua. Da definire se utile salvare su database con eventuale valore assoluto.
     */
    label?: string;
}

export interface InputUpdate {
    key: string | number;
    event: Event
}

export interface CodeItemUpdate {
    code_item: string;
    data: ConfigurationInput[];
}

export interface LoadQueryParam {
    year: number;
    user_code_item?: string;
}

export interface Document extends HeaderTablePouchModel<CodeItemUpdate> {
    year?: number;
    date_update?: number;
}

export interface LocalListHandlerFilter extends CategoryModel.List {
    forecastYear: number;
}

export interface CalcSheetPayload {
    payload: CodeItemUpdate[];
    codeNotFoundList: string[];
    codeWithInalidTimeRangeList: string[];
}