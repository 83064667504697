<mat-dialog-wrapper [title]="'opportunity.select_assegnee' | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
		<ng-container
			*ngIf="userManagementState && userManagementState.data && userManagementState.data.length > 0; else noData"
		>
			<mat-toolbar>
				<mat-toolbar-row>
					<span class="flex-span"></span>
					<td-search-box
						backIcon="search"
						[placeholder]="
							('general.search' | translate) + ' ' + ('user.field.username' | translate) | sentencecase
						"
						[showUnderline]="true"
						[debounce]="500"
						[alwaysVisible]="false"
						[(ngModel)]="userManagementState.dataSetting.appliedFilter.text"
						(searchDebounce)="loadUserList()"
					>
					</td-search-box>
				</mat-toolbar-row>
			</mat-toolbar>

			<td-data-table
				#dataTable
				[data]="userManagementState.data"
				[columns]="userManagementListColumn"
				[clickable]="true"
				(sortChange)="sortChange($event)"
				(rowClick)="selectAssegnee($event.row)"
			>
				<ng-template tdDataTableTemplate="avatar" let-value="value" let-row="row">
					<img
						*ngIf="value; else noAvatar"
						[src]="value"
						[alt]="row.username"
						[title]="row.username"
						class="avatar"
					/>
					<ng-template #noAvatar>
						<mat-icon class="avatar--placeholder">person</mat-icon>
					</ng-template>
				</ng-template>

				<ng-template tdDataTableTemplate="full_name" let-value="value" let-row="row">
					{{ row.first_name || '' }}
					{{ row.first_name ? ' ' : '' }}
					{{ row.last_name || '' }}
				</ng-template>
			</td-data-table>

			<mat-toolbar>
				<mat-toolbar-row>
					<span class="flex-span"></span>
					<td-paging-bar
						#pagingBarPageSize
						[firstLast]="true"
						[pageSize]="userManagementState.dataSetting.pagination.page_size"
						[total]="userManagementState.dataSetting.pagination.total_element"
						(change)="paginationChange($event)"
					>
						<span hide-xs>{{ 'general.items_per_page' | translate | sentencecase }}</span>
						<mat-select
							[style.width.px]="50"
							[(ngModel)]="userManagementState.dataSetting.pagination.page_size"
							(ngModelChange)="changePageSize($event)"
						>
							<mat-option *ngFor="let size of [5, 10, 20, 50]" [value]="size">
								{{ size }}
							</mat-option>
						</mat-select>
					</td-paging-bar>
				</mat-toolbar-row>
			</mat-toolbar>
		</ng-container>
		<ng-template #noData>
			<p class="placeholder">{{ 'user.not_found' | translate | sentencecase }}</p>
		</ng-template>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
