import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseStateModel } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import {
	OrderStateModel,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { StatusBarOrderService } from '../../../service/status-bar-config/implementation/status-bar-order.service';
import { StatusBarConfigNew } from '../../../model/status-bar-new.model';
import * as ConfigurationCustomerOrder from '../../../constants/configuration-customer/order/order.constant';

@Component({
	selector: 'app-dialog-order-detail',
	templateUrl: './dialog-order-detail.component.html',
	styleUrls: ['./dialog-order-detail.component.scss']
})
export class DialogOrderDetailComponent {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	order: OrderStateModel = this.data.order;

	productList = this.utilOrderService.returnArticleListByOrderSent(this.order);

	statusBarConfig: StatusBarConfigNew;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private store: Store,
		private utilOrderService: UtilOrderService,
		private utilStoreService: StoreUtilService,
		private statusBardOrderService: StatusBarOrderService,
	) {
		this.loadStaticData();
		this.statusBarConfig =
			this.statusBardOrderService.returnStatusBarList(
				ConfigurationCustomerOrder.statusBarMap[this.user.current_permission.context_application],
				this.order.header.status
			);
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.user = e.data;
		});
	}

}
