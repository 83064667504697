import { Injectable } from '@angular/core';
import { ResourceAction, ResourceRequestMethod, IResourceMethod } from '@ngx-resource/core';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';
import { PersonListModel, EnumRole, PersonModel } from '../../model/company.model';

@Injectable()
export class AngularSpin8CoreContactService extends AngularSpin8CoreBaseApiService {
	// MOCKUP
	contacts: PersonListModel = {
		data: [
			{
				id: '1',
				first_name: 'Mario',
				last_name: 'Rossi',
				role: EnumRole.PRODUCTION_MANAGER,
				business_name: '123456',
				email: 'maross@info.it',
				mobilephone: '3336669990',
				telephone: '02458936',
				address: null,
				favorite: false
			},
			{
				id: '2',
				first_name: 'Giorgio',
				last_name: 'Verdi',
				role: EnumRole.CFO,
				business_name: '987456',
				email: 'giove@info.it',
				mobilephone: '3336669990',
				telephone: '02852147',
				address: null,
				favorite: false
			}
		],
		pagination: {
			page_current: 1,
			page_size: 10,
			total_element: 10
		},
		filters: {
			text: ''
		}
	};

	contact: PersonModel = {
		id: '1',
		first_name: 'Antonio',
		last_name: 'Di Soccio',
		role: EnumRole.PRODUCTION_MANAGER,
		business_name: '123456',
		email: 'antonio.disoccio@euroitalia.it',
		mobilephone: '3336669990',
		telephone: '02458936',
		address: null,
		favorite: false,
		note: 'del testo'
	};

	// SERVICE DA IMPLEMENTARE

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/contact/'
	})
	getContactsList: IResourceMethod<void, PersonListModel>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/contact/'
	})
	createContact: IResourceMethod<PersonModel, PersonModel>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/contact/{!id}/'
	})
	getContactDetail: IResourceMethod<{ id: string }, PersonModel>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: '/contact/{!id}/'
	})
	updateContact: IResourceMethod<PersonModel, PersonModel>;
}
