<ng-container *ngIf="_configuration">
  <ng-container *ngIf="
      _configuration.privilege &&
      _configuration.privilege.add
    ">
    <attachment-resolver
      [fileEmitterType]="'files'"
      [acceptedType]="_configuration.upload?.acceptedType"
      [multiple]="_configuration.upload?.multiple || false"
      (fileEmitter)="attachmentResolverFileEmitter($event)"
    ></attachment-resolver>
    <!-- <mat-toolbar class="save" *ngIf="attachmentListToAdd && attachmentListToAdd.length">
      <mat-toolbar-row>
        <span _ngcontent-spin8-c337="" class="flex-span"></span>
        <button
          type="button"
          (click)="attachmentListAdd()"
          mat-raised-button
          color="accent"
        >
          {{ 'general.save' | translate | sentencecase }}
        </button>
      </mat-toolbar-row>
    </mat-toolbar> -->
  </ng-container>

  <attachment-list-wrapper
    [ngClass]="{'show-table-head': _configuration && _configuration.page && _configuration.page.display && _configuration.page.display.tableHeader}"
    *ngIf="
      _configuration.localListHandler &&
      _configuration.page &&
      _configuration.page.columnList
    "
    [configuration]="_configuration"
    [localListHandlerWidgetWrapperData]="_configuration.localListHandler"
    [localListHandlerWidgetWrapperColumnList]="_configuration.page.columnList"
    [localListHandlerTextFilterShow]="_configuration.page.display?.textFilter || false"
    [localListHandlerPaginatorShow]="_configuration.page.display?.paginator || false"
    (onItemDelete)="dialogConfirmAttachmentListDelete([$event])"
    (onDownloadFromListSelected)="handleDownloadFromListSelected($event)"
    (onItemPreviewSelect)="dialogPreviewOpen($event)"
    [localListHandlerTitle]="_configuration.page.title || ''"
  >
  </attachment-list-wrapper>
  <process-queue
    [webSocketChannelList]="rxStompService.channelList"
    (processStop)="rxStompService.processStop($event.idChannel, $event.idProcess)"
  ></process-queue>
</ng-container>