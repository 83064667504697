import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';
import { ElementOffset } from '../../model/structure/util.model';
import { MEDIA_QUERIES } from '../../model/tokens/injection-tokens';

@Injectable()
export class MediaReplayService {
	deviceH: number;
	deviceW: number;
	dialogContentHeight: string;
	public media: Observable<BreakpointState>;
	private mediaQueries = [Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large];

	constructor(
		private breakpointObserver: BreakpointObserver,
		@Optional() @Inject(MEDIA_QUERIES) mediaQueriesList: string[]
	) {
		if (mediaQueriesList) {
			this.mediaQueries = mediaQueriesList;
		}
		this.media = this.breakpointObserver.observe([...this.mediaQueries]);
	}

	public matchTo(query: string): Observable<BreakpointState> {
		return this.media.pipe(
			filter((state: BreakpointState) => {
				return state.matches && state.breakpoints[query];
			})
		);
	}

	/** Altezza in px del device meno il top che deve rappresentare il valore in px delle parti statiche
	 *  Se presente paramtero rows, il valore viene ripartito su ogni riga
	 *  Es complesso, catalog.component (di primo livello):
	 *    il metodo restituisce il valore in px da usare come altezza della card in modo che 3 righe (parametro rows)
	 *    vengano distribuite sullo spazio rimanente:
	 *
	 *    deviceH-98(header)-70(title di pagina)-30(15*2 gap statici del grid layout)
	 *
	 *  Es semplice admin.component: conferisce una max-height alla ngx-scrollbar in modo che il contenuto
	 *    esulante risulti scrollabile in base all'altezza del device
	 *
	 *    deviceH-98(header)
	 *
	 */
	getElementMaxHeight(top: number, rows?: number) {
		let elH: any;
		if (!rows) {
			elH = this.deviceH - top;
		} else {
			elH = (this.deviceH - top) / rows;
		}
		elH = elH.toString() + 'px';
		return elH;
	}
	/** Altezza in px di un elemento proporzionato in % rispetto all'altezza del device
	 *  percentage: altezza in percentuale che deve avere l'elemento in questione
	 *  staticHeight: l'altezza fissa presente nella schermata (header, margin, padding, ecc)
	 *  container: se valorizzato a 'deviceH' il parametro percentage viene considerato relativamente alla altezza totale del device,
	 *  altrimenti ad una lunghezza uguale a this.deviceH-staticHeight
	 *
	 *  Es, dashboard.component:
	 *    l'img-item full width è alta il 36% di
	 *    deviceH-98(header)-15(margin-bottom statico di img-item)
	 *
	 *  Es, product.comparison-dialog.component:
	 *    comparator-item è alto l'82% di this.deviceH (padding top e bottom di 9vh) meno l'altezza fissa di 71 del titolo
	 */
	getElementHeight(percentage: number, staticHeight?: number, container?: string) {
		if (!staticHeight) {
			staticHeight = 0;
		}
		let elH: any;
		if (!container) {
			elH = ((this.deviceH - staticHeight) * percentage) / 100;
		} else if ('deviceH') {
			elH = (this.deviceH * percentage) / 100 - staticHeight;
		}
		elH = elH.toString() + 'px';
		return elH;
	}

	getElementOffset(element): ElementOffset {
		const elementOffset: ElementOffset = { x: 0, y: 0 };
		let xPosition = 0;
		let yPosition = 0;
		while (element) {
			xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
			yPosition += element.offsetTop - element.scrollTop + element.clientTop;
			element = element.offsetParent;
		}
		elementOffset.x = xPosition;
		elementOffset.y = yPosition;
		return elementOffset;
	}

	getRemainingDeviceHeight(element): number {
		let height: number;
		height = this.deviceH - this.getElementOffset(element).y;
		return height;
	}
}
