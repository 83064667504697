import { Inject, Injectable, Optional } from '@angular/core';

// model
import { TicketModel } from '../../model/lib.model';
import { RestBasePk, RestBaseResponse } from '@saep-ict/angular-core';

// misc
import {
  ILoaderService,
  LOADER_SERVICE_TOKEN,
} from '../../model/loader-service.interface';
import {
  APP_CONFIG_TOKEN,
  ITicketCenterAppConfig,
} from '../../model/lib-app-config.interface';

import { BaseApiService } from './baseApi.service';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { Store } from '@ngrx/store';
import {returnApiUrlSegment} from '@saep-ict/angular-spin8-core';

@Injectable({
  providedIn: 'root',
})
export class RestActionService extends BaseApiService {
  constructor(
    @Optional() @Inject(LOADER_SERVICE_TOKEN) loaderService: ILoaderService,
    @Optional() @Inject(APP_CONFIG_TOKEN) appConf: ITicketCenterAppConfig,
    public store: Store<any>
  ) {
    super(loaderService, appConf, store);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: `/${returnApiUrlSegment('privateApi')}/ticket-center/tickets/ `,
  })
  getList: IResourceMethodPromise<void, RestBaseResponse<TicketModel<any>[]>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: `/${returnApiUrlSegment('privateApi')}/ticket-center/tickets/{!id}/`,
  })
  getDetail: IResourceMethodPromise<RestBasePk, RestBaseResponse<TicketModel<any>>>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: `/${returnApiUrlSegment('privateApi')}/ticket-center/tickets/ `,
  })
  postDetail: IResourceMethodPromise<TicketModel<any>, RestBaseResponse<TicketModel<any>>>;

}
