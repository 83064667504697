export class StatusBarConfig {
	condensed?: boolean;
	theme: string;
	steps: StatusBarElement[];
}

export class StatusBarElement {
	id?: string;
	label: string;
	icon: string;
	selected?: boolean;
}