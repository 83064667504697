import { DatePipe } from "@angular/common";
import { ITdDataTableColumnCustom } from "@saep-ict/pouch_agent_models";


export const baseColumns: ITdDataTableColumnCustom[] = [
    {
        name: 'number_document',
        labelPath: 'customer_credit.field.number_document',
        label: null,
        width: 100
    },
    {
        name: 'description_document',
        labelPath: 'customer_credit.field.description_document',
        label: null
    },
    {
        name: 'flag_give_take_dare',
        labelPath: 'customer_credit.field.give',
        label: null,
        numeric: true
    },
    {
        name: 'flag_give_take_avere',
        labelPath: 'customer_credit.field.take',
        label: null,
        numeric: true
    },
    {
        name: 'date_document',
        labelPath: 'customer_credit.field.date_document',
        label: null,
        format: (v: number) => new DatePipe('en-US').transform(v, 'dd/MM/yyyy'),
        numeric: true,
        width: 125
    },
    {
        name: 'date_expiration',
        labelPath: 'customer_credit.field.date_expiration',
        label: null,
        format: (v: number) => new DatePipe('en-US').transform(v, 'dd/MM/yyyy'),
        numeric: true,
        width: 125
    }
];