<div class="container-fluid">
	<!-- HEADER -->
	<div class="header">
		<!-- Timerange -->
		<div>{{ 'chart.field.time_range' | translate | sentencecase }}</div>
		&nbsp; &nbsp;
		<mat-form-field class="header__timerange">
			<mat-select [value]="timeRange.values[0]" (selectionChange)="setTimeRange($event)">
				<mat-option *ngFor="let rangeTemp of timeRange.values" [value]="rangeTemp">
					{{ 'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- Separator -->
		<div class="header__separator"></div>

		<!-- Date last update statistics -->
		<div class="header__statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div>

	<!-- TREND -->
	<div class="row">
		<!-- Expired -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="accountBalanceExpiredDetailChartConfigService.cardDashboardConfigExpiredDetail"
			>
				<div class="trend-expired">
					<div
						class="row trend-expired__header"
						*ngIf="accountBalanceExpiredDetailChartConfigService.metrics"
					>
						<div class="trend-expired__header__average">
							{{
								accountBalanceExpiredDetailChartConfigService.metrics.average
									| appFormatMultiplesCurrency
							}}
						</div>
						&nbsp; &nbsp;
						<div class="trend-expired__header__trend">
							<icon-trend
								[value]="accountBalanceExpiredDetailChartConfigService.metrics.trend"
							></icon-trend>
						</div>
					</div>
					<div class="row trend-expired__body">
						<chart-wrapper
							class="row trend-expired__body__chart"
							[config]="accountBalanceExpiredDetailChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>

		<!-- Due -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				class="card-dashboard-accent"
				[cardDashboardConfig]="accountBalanceDueChartConfigService.cardDashboardConfigDue"
			>
				<div class="trend-due">
					<div class="row trend-due__header" *ngIf="accountBalanceDueChartConfigService.metrics">
						<div class="trend-due__header__average">
							{{ accountBalanceDueChartConfigService.metrics.average | appFormatMultiplesCurrency }}
						</div>
						&nbsp; &nbsp;
						<div class="trend-due__header__trend">
							<icon-trend [value]="accountBalanceDueChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row trend-due__body">
						<chart-wrapper
							class="row trend-due__body__chart"
							[config]="accountBalanceDueChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>
	</div>

	<!-- CUSTOMERS WITH EXPIRED -->
	<div class="row">
		<div class="col-12"></div>
	</div>
</div>
