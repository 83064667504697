import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { map } from 'rxjs';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { NgScrollbar } from 'ngx-scrollbar';
import { Router } from '@angular/router';

@Component({
	selector: 'ps-to-top',
	templateUrl: './ps-to-top.component.html',
	styleUrls: ['./ps-to-top.component.scss'],
	providers: [SubscribeManagerService]
})
export class PsToTopComponent implements OnInit, OnDestroy {
	@Input() set scrollbarRef(e: NgScrollbar) {
		if (e) {
			this._scrollbarRef = e;
			this.subscribeManagerService.populate(
				this._scrollbarRef.verticalScrolled.pipe(
					map((e: any) => this.updateShowToTop(e.target.scrollTop > 100))
				)
				.subscribe(),
				'toggle-scroll-to-top'
			);
			this.subscribeManagerService.populate(
				this.router.events.pipe().subscribe(e => {
					this.scrollToTop();
				}),
				'router-events'
			);
		}
	}
	_scrollbarRef: NgScrollbar;
	@Output() onScrollToTop = new EventEmitter<any>();

	isVisible = false;

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		private router: Router
	) {}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	updateShowToTop(show: boolean) {
		this.isVisible = show;
	}

	scrollToTop() {
		this._scrollbarRef.scrollTo({ top: 0, duration: 300 });
	}
}
