<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight mb-20" *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.order)">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'order.summary.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-select
						[compareWith]="dashboardConfiguration.compareValueOrderTrendTimeRange"
						[value]="configurationCustomerStatistic.timeRangeSelector[0]"
						(selectionChange)="updateBoxTrendInfoOrderContent($event)"
					>
						<mat-option
							*ngFor="let item of configurationCustomerStatistic.timeRangeSelector"
							[value]="item"
						>
							{{ item.label | translate | sentencecase }}
						</mat-option>
					</mat-select>
					<!-- <mat-icon> help_outline </mat-icon> -->

					<button mat-icon-button (click)="orderSummaryClosablePanel.isVisible = true">
						<mat-icon> help_outline </mat-icon>
					</button>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>
						{{ 'general.updated.at' | translate | sentencecase }}
						{{ updatedAtGeneral }}
					</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>

			<closable-panel #orderSummaryClosablePanel>
				<ng-container content>
					{{ 'order.summary.info_panel' | translate | sentencecase }}
				</ng-container>
			</closable-panel>


			<box-trend-info [boxTrendInfoComponentConfig]="boxTrendInfoOrder"></box-trend-info>

			<mat-divider class="both-spacing"></mat-divider>

			<order-list-wrapper
				[title]=""
				[localListHandlerWidgetWrapperData]="listPageBaseLastOrders"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseLastOrders.columnList"
				[localListHandlerPaginatorShow]="true"
				[viewAllButtonConfiguration]="viewAllButtonConfiguration"
				(onItemSelect)="goToOrderDetail($event)"
				(selectCompany)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code)"
			>
			</order-list-wrapper>
		</mat-card>


	</ng-container>
	<ng-container pageSide></ng-container>
</page-with-sidebar>
