import { ArticleTableConfigurationAction } from '../../../model/article.model';

export const relatedArticleListCanAdd = false;
export const relatedArticleListAction: ArticleTableConfigurationAction = {};

export const relatedFamilyListCanAdd = true;
export const relatedFamilyListAction: ArticleTableConfigurationAction = {
    delete: true
};

export const alternativeFamilyListCanAdd = true;
export const alternativeFamilyListAction: ArticleTableConfigurationAction = {
    delete: true
};