import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { OpportunityPouchModel } from '@saep-ict/angular-spin8-core';
import { PouchDeleteResponse } from '../../model/pouch-base-response.model';

export class OpportunityPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	deleteOpportunity(opportunity: OpportunityPouchModel): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(opportunity)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
