import { PouchAdapterEnum } from '../../enum/util/pouch-adapter.enum';
import { Pagination } from '../structure/list-structure.model';

export interface BaseStateModel<T, F = never> {
	data: T;
	type?: string;
	dataSetting?: DataSetting<F>;
	status?: number;
  	errorMessage?: string;
}
export interface DataSetting<F = never> {
	pagination?: Pagination;
	sort?: { [key: string]: 'asc' | 'desc' }[];
	appliedFilter?: F;
	adapter?: PouchAdapterEnum;
  useLoader?: boolean;
}
export class BaseState<T, F = never> implements BaseStateModel<T, F> {
	data: T;
	type?: string;
	dataSetting?: DataSetting<F>;
	constructor(innerData?: T, dataSetting?: DataSetting<F>) {
		this.data = innerData ? innerData : null;
		this.dataSetting = dataSetting ? dataSetting : null;
	}
}
export class BaseStateSerializerModel<T> extends BaseState<T> {
	stateSlice: {
		type: string;
		key?: string;
	};
}
