import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseStateModel,
	SubscribeManagerService,
	BaseStateSerializerModel,
	
	StoreFeature,
	ListSettingActionEnum,
	
} from '@saep-ict/angular-core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import { ArticleListFilterModel } from '../../../../service/pouch-db/filter/article-list-filter.model';
import {
	ArticleListStructureWrapperBase,
	AngularSpin8CoreUtilTranslateService
} from '@saep-ict/angular-spin8-core';
import {ListSetting, CategoryMap,
	ArticleCheckoutTree,
	ArticleListCategoryTreeRecursionBaseModel, LocalListHandlerBaseApplyFilterParam} from '@saep-ict/pouch_agent_models'
import * as ConfigurationCategory from '../../../../constants/category.constant';
import * as CategoryModel from '../../../../model/category-list.model';
import { ArticleTableConfigurationAction } from '../../../../model/article.model';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'article-list-structure-wrapper-category-tree',
	templateUrl: './article-list-structure-wrapper-category-tree.component.html',
	styleUrls: ['./article-list-structure-wrapper-category-tree.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleListStructureWrapperCategoryTree extends ArticleListStructureWrapperBase {
	categoryList$: Observable<BaseStateModel<CategoryMap, ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);

	localListHandlerCategoryTreeConfiguration: ArticleListCategoryTreeRecursionBaseModel<ArticleCheckoutTree>;

	listSettingSlice$: Observable<BaseStateSerializerModel<ListSetting>> = this.store.select(
		StoreFeature.selectListSettingSliceStore,
		'order-detail'
	);

	@Input('configurationAction') configurationAction: ArticleTableConfigurationAction;

	@Output() onDeleteArticle: EventEmitter<ArticlePouchModel> = new EventEmitter();

	constructor(
		public store: Store,
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private subscribeManagerService: SubscribeManagerService
	) {
		super(utilService, translateService, store);
		this.subscribeManagerService.populate(this.subscribeMainData().subscribe(), 'data');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeMainData() {
		return this.listSettingSlice$.pipe(
			filter(
				(e: BaseStateSerializerModel<ListSetting<CategoryModel.List>>) =>
					!!(
						e &&
						e.data &&
						e.type === ListSettingActionEnum.UPDATE &&
						this.localListHandlerData &&
						this.localListHandlerData.data &&
						this.localListHandlerData.dataSubset
					)
			),
			map((e: BaseStateSerializerModel<ListSetting<CategoryModel.List>>) => {
				ConfigurationCategory
					.returnArticleCheckoutTree(
						this.localListHandlerData.dataSubset,
						e.data.filters.customFilters.categoryList,
						this.utilTranslateService.languages
					)
					.then(res => {
						this.localListHandlerCategoryTreeConfiguration = {
							level: 1,
							data: res,
							filters: this.localListHandlerData.filters,
							languageKey: this.localListHandlerData.languageKey,
							pageName: this.localListHandlerData.pageName,
							pagination: this.localListHandlerData.pagination,
							sort: this.localListHandlerData.sort,
							sortRemapObject: this.localListHandlerData.sortRemapObject
						};
					})
					.catch(err => {
						console.log(err);
					});
			})
		);
	}

}
