import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import moment from 'moment';

export namespace InformativePageColumnMap {
	export const base: ITdDataTableColumnCustom[] = [
		{
			name: 'title',
			labelPath: 'saep_ict_angular_spin8_core.general.title',
			label: null
		},
		{
			name:'date_creation',
			labelPath: 'saep_ict_angular_spin8_core.general.date',
			label: null,
			format: (v) => v ? moment(v).format('DD/MM/YYYY h:mm a') : ''
		}
	];
}
