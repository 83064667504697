import { createReducer, on } from '@ngrx/store';
import { ListSettingStoreAction } from './list-setting.actions';
import { BaseStateSerializerModel } from '../../model/state/base-state.model';
import { ListSetting } from '@saep-ict/pouch_agent_models';
import { UtilStore } from '../../misc/util/util-store';

export namespace ListSettingStoreReducer {
  export const initialState: BaseStateSerializerModel<ListSetting<any>>[] = [];

  const _reducer = createReducer(initialState,
    on(
      ListSettingStoreAction.update,
      (store: BaseStateSerializerModel<ListSetting<any>>[], param: BaseStateSerializerModel<ListSetting<any>>) =>
        UtilStore.returnUpdatedSerielizedStore<ListSetting<any>>(store, param)
    ),
    on(
      ListSettingStoreAction.resetItem,
      (store: BaseStateSerializerModel<ListSetting<any>>[], param: BaseStateSerializerModel<ListSetting<any>>) =>
        UtilStore.returnResetItemSerielizedStore<ListSetting<any>>(store, param)
    ),
    on(ListSettingStoreAction.reset, (state) => initialState),
  );

  export function reducer(state, action) {
    return _reducer(state, action);
  }

}
