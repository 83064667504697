import { BasePouchModel } from '@saep-ict/pouch_agent_models';

export namespace KanbanModel {
  export interface Column {
    id: string;
    name: string;
    cards: [];
  }
  export interface Base extends BasePouchModel {
    name: string;
    column: KanbanModel.Column[];
  }
}
