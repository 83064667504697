<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<tab-wrapper></tab-wrapper>
			<request-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="requestColumns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToDetail($event)"
			>
			</request-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'general.filters' | translate | sentencecase">
			<ng-container main-content>
				<form class="michelangelo-theme" [formGroup]="formFilters">
					<mat-form-field>
						<mat-label>{{ 'organization.typology' | translate | sentencecase }}</mat-label>
						<mat-select formControlName="organizationType">
							<mat-option *ngFor="let item of organizationTypeList" [value]="item">
								{{
									'saep_ict_angular_spin8_core.page.organization.organization_type_label.' + item
										| translate
										| sentencecase
								}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
						(primaryButtonClickEmitter)="onFormFiltersSubmit()"
						[secondaryButtonIsDisabled]="!filtersActivated"
						[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
						(secondaryButtonClickEmitter)="resetFilters()"
					></form-button-bar>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
