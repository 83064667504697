import { Injectable } from '@angular/core';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';
// model
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBasePk, RestBaseResponse } from '@saep-ict/angular-core';
import { OrderStateModel } from '../../model/state/order-state.model';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';

@Injectable()
export class AngularSpin8CoreOrderService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path:  `/${returnApiUrlSegment(ApiType.PRIVATE)}/orders/`
	})
	getNewOrder: IResourceMethodPromise<RestBasePk, RestBaseResponse<any>>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path:  `/${returnApiUrlSegment(ApiType.PUBLIC)}/orders/`
	})
	putNewOrder: IResourceMethodPromise<OrderStateModel, RestBaseResponse<any>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/orders/payments/`
	})
	postOrderPayment: IResourceMethodPromise<OrderStateModel, RestBaseResponse<string>>;
}
