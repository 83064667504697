import { PipeTransform, Pipe } from '@angular/core';
import { PipeFilterOption } from '../model/pipe/pipe-filter.model';
import { SPECIAL_CHARS } from '../constants/misc.constants';

@Pipe({
	name: 'filterByString'
})
export class FilterByStringPipe implements PipeTransform {
	transform(items: any[], filterOption: PipeFilterOption) {
		if (!items || !filterOption || !filterOption.value || !filterOption.propertyListName.length) {
			return items;
		}
		const regex = new RegExp(filterOption.value.replace(SPECIAL_CHARS, ''), 'i');
		return items.filter(item => {
			let found = false;
			for (let i = 0; i < filterOption.propertyListName.length; i++) {
				const propertyName = filterOption.propertyListName[i];

				// general
				if (item[propertyName]) {
					if (regex.test(item[propertyName])) {
						found = true;
						break;
					}
				}

				// address specific
				if (item.address && item.address[propertyName]) {
					if (regex.test(item.address[propertyName])) {
						found = true;
						break;
					}
				}
			}
			return found;
		});
	}
}
