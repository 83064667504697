import { ConfigurationProjectModel, ROUTE_URL, ConfigurationUtil } from "@saep-ict/angular-spin8-core";

export const erp_has_erp = true;
export const erp_backoffice_configuration = {
    has_shipping_charges_company_organization: true,
    has_shipping_charges_private_organization: true,
    has_stock_synchronization: true
};
export const authenticationWrapper: ConfigurationProjectModel.AuthenticationWrapper = {
    can_access_to_registration: true,
    link_access_to_registration: `${ROUTE_URL.public}/${ROUTE_URL.authentication}/${ROUTE_URL.createAccount}`
};
// Manages the display of the following features in the CONTACTS section of the company
export const contact: ConfigurationProjectModel.Contact = {
    send_email_confirmation: true,
    can_activate_email_order_receipt: true
};
export const has_manual: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: true,
    B2C: true,
    BACKOFFICE_ADMIN: true,
    BACKOFFICE: true,
    PORTAL: true,
    CRM: true,
    PUBLIC: false
};
export const noSqlDocSeparator = ':';
export const recaptchaSiteKey = '6Leg14kaAAAAAMJDBlrXElHXb5uDs__GQpqaQCBH';