<form class="michelangelo-theme" (ngSubmit)="sendAccessRequest()" [formGroup]="form">
	<mat-form-field>
		<input matInput formControlName="business_name" placeholder="Azienda" />
		<mat-error *ngIf="form.get('business_name').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="address" placeholder="Indirizzo" />
		<mat-error *ngIf="form.get('address').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="piva" placeholder="Partita IVA" />
		<mat-error *ngIf="form.get('piva').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="client_code" placeholder="Numero ordine cliente" />
		<mat-error *ngIf="form.get('client_code').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="reference" placeholder="Referente" />
		<mat-error *ngIf="form.get('reference').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="department" placeholder="Dipartimento" />
		<mat-error *ngIf="form.get('department').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="email" placeholder="Email" />
		<mat-error *ngIf="form.get('email').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
		<mat-error *ngIf="form.get('email').hasError('email')">
			{{ 'general.validators.email' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="phone" placeholder="Telefono" />
		<mat-error *ngIf="form.get('phone').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>

	<div class="w-100 mb-3 mt-2">
		<mat-checkbox color="primary" required formControlName="privacyCheck">
			<span class="access-request_checkbox">
				{{ 'login.access_request.privacy_policy.checkbox.start' | translate | sentencecase
				}}<a [routerLink]="['/', ROUTE_URL.privacyPolicy]" target="_blank">{{
					'login.access_request.privacy_policy.checkbox.link_to' | translate | sentencecase
				}}</a
				>&nbsp;{{ 'login.access_request.privacy_policy.checkbox.end' | translate }}
			</span>
		</mat-checkbox>
	</div>
	<recaptcha (recaptchaResponseEmitter)="recaptchaResponse($event)"></recaptcha>

	<mat-toolbar>
		<button mat-raised-button type="submit" color="primary" class="d-block w-100">
			{{ 'login.access_request.send_request' | translate | sentencecase }}
		</button>
	</mat-toolbar>

	<div class="link mt-16">
		<a routerLink="/authentication/login"> {{ 'general.cancel' | translate | sentencecase }} </a>
	</div>
</form>
