import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel,ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { StateFeature } from '../../../../state';
import { CallToActionConfig } from '../../../../widget/call-to-action/call-to-action.component';

@Component({
	selector: 'search-results',
	templateUrl: './search-results.component.html',
	styleUrls: ['./search-results.component.scss'],
	providers: [SubscribeManagerService]
})
export class SearchResultsComponent implements OnInit {
	searchTerm: string = 'Usa la barra di ricerca in cima...';
	viewType: ViewTypeEnum;

	searchResults$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(
		StateFeature.getSearchResultsState
	);

	callToActionConfig: CallToActionConfig = {
		text: 'Lorem ipsum',
		btnLabel: 'contact.contact_us',
		theme: 'accent'
	};

	listPageBaseData = <LocalListHandlerBaseModel<ArticlePouchModel>>{
		pagination: {
			pageSize: 10
		},
		data: []
	};

	constructor(
		private store: Store<any>,
		private activatedRoute: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private appConfig: CustomerAppConfig
	) {
		if (this.appConfig.authenticationToken) {
			this.viewType = ViewTypeEnum.module;
		} else {
			this.viewType = ViewTypeEnum.list;
		}
	}

	ngOnInit() {
		this.subscribeManagerService.populate(
			this.activatedRoute.queryParamMap.subscribe(params => {
				if (params.get('q')) {
					this.searchTerm = `Ricerca: "${params.get('q')}"`;
				}
			}),
			'search-term'
		);

		this.subscribeManagerService.populate(
			this.searchResults$.pipe(take(1)).subscribe(searchResults => {
				if (searchResults && searchResults.data) {
					const results: ArticlePouchModel[] = searchResults.data;
					this.listPageBaseData.data = results;
				}
			}),
			'search-results'
		);
	}

	clickCallToAction() {
		console.log('clickCallToAction');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
