<mat-accordion [ngClass]="color">
	<mat-expansion-panel [expanded]="true">
		<mat-expansion-panel-header [collapsedHeight]="'64px'">
			<mat-panel-title>
				<ng-content select="[header]"></ng-content>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ng-content select="[main]"></ng-content>
	</mat-expansion-panel>
</mat-accordion>
