import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
// widget & utility
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { UtilCategoryListService } from '../../service/util/util-category-list.service';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { Category } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'nested-category-list',
	templateUrl: './nested-category-list.component.html',
	styleUrls: ['./nested-category-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class NestedCategoryListComponent implements OnInit, OnDestroy {
	_list: Category[] = [];
	categorySelected = <Category>{};

	private categorySelectedChangeDebounce = new Subject();

	@Input('list') set list(l: Category[]) {
		if (l) {
			this._list = _.cloneDeep(l);
			this.categorySelected.category_list = _.cloneDeep(l);
		}
	}

	@Input() debounceTime = 500;

	@Output() categorySelectedChange = new EventEmitter<Category>();

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		private utilCategoryListService: UtilCategoryListService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {}

	ngOnInit() {
		this.subscribeManagerService.populate(
			this.categorySelectedChangeDebounce
				.pipe(debounceTime(this.debounceTime))
				.subscribe((e: Category) => this.categorySelectedChange.emit(e)),
			'change-category-debounce'
		);
	}
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	async selectCategory(id?: string) {
		this.categorySelected = await this.utilCategoryListService.returnCategoryById(id, this._list);
		this.categorySelectedChangeDebounce.next(this.categorySelected);
	}

	async selectCategoryParent(id: string) {
		const categoryParent: Category = await this.utilCategoryListService.returnCategoryParentById(id, this._list);
		this.categorySelected = categoryParent ? categoryParent : <Category>{ category_list: this._list };
		this.categorySelectedChangeDebounce.next(this.categorySelected);
	}
}
