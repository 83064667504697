import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators, FormControl } from '@angular/forms';
export enum TypePasswordVerifyEnum {
	NEW,
	CHANGE
}

export interface PasswordVerifyModel {
	oldPassword?: string;
	newPassword: string;
	newPasswordConfirmed: string;
}

const PasswordValidator: ValidatorFn = (fg: FormGroup) => {
	const newPassword = fg.controls['newPassword'].value;
	const newPasswordConfirmed = fg.controls['newPasswordConfirmed'].value;
	return newPassword === newPasswordConfirmed ? null : { errorPassword: true };
};

@Component({
	selector: 'password-verify',
	templateUrl: './password-verify.component.html',
	styleUrls: ['./password-verify.component.scss']
})
export class PasswordVerifyComponent implements OnInit, OnDestroy {
	@Input() type: TypePasswordVerifyEnum;
	@Output() submitFormEvent: EventEmitter<PasswordVerifyModel> = new EventEmitter();
	typePasswordVerifyEnum = TypePasswordVerifyEnum;
	form: FormGroup;
	hidePassword: boolean[] = [true, true, true];
	isPasswordValid: boolean = false;
	newPasswordEmitter = new EventEmitter<string>();

    @Input() redirectLink: string[] = [];
    @Input() showCancelButton: boolean = false;

	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.form = this.fb.group(
			{
				newPassword: ['', [Validators.required]],
				newPasswordConfirmed: ['', [Validators.required]]
			},
			{ validator: PasswordValidator }
		);
		if (this.type === TypePasswordVerifyEnum.CHANGE) {
			this.form.addControl('oldPassword', new FormControl('', Validators.required));
		}
	}

	onFormSubmit() {
		if (this.form.valid) {
			const formValue = this.form.value;
			this.submitFormEvent.emit(formValue);
		}
	}

	onTypePassword(newPasswordValue: string) {
		this.newPasswordEmitter.emit(newPasswordValue);
	}

	isPasswordValidCheck(isPasswordValid: boolean) {
		this.isPasswordValid = isPasswordValid;
	}

	ngOnDestroy() {}
}
