import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { PipeModule } from './pipes/pipe.module';
import { DirectivesModule } from './directives/directives.module';
import { WidgetModule } from './widget/widget.module';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RestModule } from './rest.module';
import { NgrxStoreModule } from './store/ngrx-store.module';
import { UtilStructure } from './misc/util/util-structure';
import { LOADER_SERVICE_TOKEN, APP_CONFIG_TOKEN, ENVIRONMENT_SETTING, UtilStore } from '@saep-ict/angular-core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PipeModule,
    DirectivesModule,
    WidgetModule,
    RestModule,
    NgrxStoreModule
  ],
  exports: [
    PipeModule,
    DirectivesModule,
    WidgetModule
  ]
})

export class SaepICTAngularSpin8CoreModule {
  static forRoot(opt): ModuleWithProviders<SaepICTAngularSpin8CoreModule> {
		return {
			ngModule: SaepICTAngularSpin8CoreModule,
			providers: [
				{ provide: ENVIRONMENT_SETTING, useValue: opt.env },
				{
					provide: APP_CONFIG_TOKEN,
					useExisting: opt.appConfig,
				},
				{
					provide: APP_INITIALIZER,
					deps: [opt.appConfig, ENVIRONMENT_SETTING, HttpClient],
					useFactory: UtilStructure.loadLibConfig,
					multi: true,
				},
				{
					provide: LOADER_SERVICE_TOKEN,
					useExisting: opt.loaderSerivce,
				},
        ...UtilStore.returnStoreEffectProviderList()
			]
		}
	}
}
