import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { OrganizationActionEnum, OrganizationStateAction } from './organization.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OrganizationStateModel } from '@saep-ict/angular-spin8-core';

export namespace CustomerStateReducer {
	export const initialState: BaseStateModel<OrganizationStateModel> = null;

	const _reducer = createReducer(
		initialState,
		on(OrganizationStateAction.load, state => state),
		on(OrganizationStateAction.createItemApi, (state, organization) => state),
		on(OrganizationStateAction.save, (state, organization) => organization),
		on(OrganizationStateAction.saveSuccess, (state, organization) => organization),
		on(OrganizationStateAction.update, (state, organization) => organization),
		on(OrganizationStateAction.updateWithArticleRecapCodeItem, (state, organization) => organization),
		on(OrganizationStateAction.reset, () => initialState),
		on(OrganizationStateAction.error, (state, organization) => ({
			data: state ? state.data : null,
			type: organization.type
		}))
	);

	export function reducer(
		state: BaseStateModel<OrganizationStateModel>,
		action: TypedAction<OrganizationActionEnum>
	) {
		return _reducer(state, action);
	}
}
