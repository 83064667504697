import { ContextApplicationItemCodeEnum, ITdDataTableColumnCustom } from '@saep-ict/angular-spin8-core';
import * as columnListDestinationOrganizationBackoffice from './destination-column-map/destination-column-map-backoffice.constant';
import * as columnListDestinationOrganizationB2B  from './destination-column-map/destination-column-map-b2b.constant';
import * as columnListDestinationOrganizationB2C from  './destination-column-map/destination-column-map-b2c.constant';
import * as columnListDestinationOrganizationPortal from './destination-column-map/destination-column-map-portal.constant';
import * as columnListDestinationOrganizationAgent from './destination-column-map/destination-column-map-agent.constant'
import * as columnListDestinationOrganizationCRM from './destination-column-map/destination-column-map-crm.constant';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';

export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumnCustom[] => {
    switch (contextApplication) {
        case(ContextApplicationItemCodeEnum.BACKOFFICE):
            return columnListDestinationOrganizationBackoffice.projectOverride;
        case(ContextApplicationItemCodeEnum.AGENT):
            return columnListDestinationOrganizationAgent.projectOverride;
        case(ContextApplicationItemCodeEnum.B2B):
            return columnListDestinationOrganizationB2B.projectOverride;
        case(ContextApplicationItemCodeEnum.CRM):
            return columnListDestinationOrganizationCRM.projectOverride
        case(ContextApplicationItemCodeEnum.PORTAL):
            return columnListDestinationOrganizationPortal.projectOverride;
        case(ContextApplicationItemCodeEnum.B2C):
            return columnListDestinationOrganizationB2C.projectOverride;
    }
};

export const returnIsRegisteredOffice = (list: DestinationPouchModel[] = []): DestinationPouchModel =>
    list.find(i => i.is_registered_office);