import { HttpClient } from '@angular/common/http';
import {
	APP_INITIALIZER,
	Inject,
	InjectionToken,
	ModuleWithProviders,
	NgModule,
	Optional,
	SkipSelf,
} from '@angular/core';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import {
	FakeMissingTranslationHandler,
	MissingTranslationHandler,
	TranslateCompiler,
	TranslateDefaultParser,
	TranslateFakeCompiler,
	TranslateLoader,
	TranslateParser,
	TranslateService,
	TranslateStore,
	USE_DEFAULT_LANG,
	USE_STORE,
} from '@ngx-translate/core';

import { LOADER_SERVICE_TOKEN } from './model/loader-service.interface';
import {
	APP_CONFIG_TOKEN,
	ITicketCenterAppConfig,
} from './model/lib-app-config.interface';
import { TicketCenterTranslateLoader } from './i18n/default-translate_loader';
import {
	IEnvironment,
	ILibModelOptions,
} from './model/lib-options.model';
import { NoSuchValueException } from './model/no-such-value-exception.exception';
// store
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { TicketCenterTicketStoreModule } from './store/ticket/ticket.module';
import { TicketCenterTicketDetailStoreModule } from './store/ticket/ticket-detail/ticket-detail.module';
import { TicketCenterLibConfigurationStoreModule } from './store/lib-configuration/lib-configuration.module';

// lib module
import { PageModule } from './page/page.module';
import { DatePipe, SlicePipe } from '@angular/common';
import { TicketCenterTicketEffects } from './store/ticket/ticket.effects';
import { TicketCenterTicketDetailEffects } from './store/ticket/ticket-detail/ticket-detail.effects';
import { LibConfiguration } from './constant/indext';
import { TicketCenterLibConfigurationStoreAction } from './store/lib-configuration/lib-configuration.actions';
import { BaseState } from '@saep-ict/angular-core';

export const ENVIRONMENT_SETTING = new InjectionToken('TC_ENVIRONMENT_SETTING');

export const loadLibConfig = (appConfig: ITicketCenterAppConfig, ENVIRONMENT_SETTING, HttpClient, store: Store<any>) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      appConfig['config$'].subscribe(async config => {
        if (config && config.ticket_center) {
          appConfig.initializeConfigTicketCenter(config.ticket_center);
          // set della configurazione di default presente tra le costanti di libreria
          // viene inserita in store nel caso l'app parent non fornisca una configurazione specifica
          if (appConfig.tc_config.customConfiguration) {
            appConfig.tc_config.customConfiguration = {
              page: {
                list: {
                  title:
                    appConfig.tc_config.customConfiguration.page &&
                    appConfig.tc_config.customConfiguration.page.list &&
                    appConfig.tc_config.customConfiguration.page.list.title ?
                    appConfig.tc_config.customConfiguration.page.list.title :
                    LibConfiguration.base.page.list.title,
                  tableColumn:
                    appConfig.tc_config.customConfiguration.page &&
                    appConfig.tc_config.customConfiguration.page.list &&
                    appConfig.tc_config.customConfiguration.page.list.tableColumn ?
                    appConfig.tc_config.customConfiguration.page.list.tableColumn :
                    LibConfiguration.base.page.list.tableColumn,
                  localSearchTextKeyList:
                    appConfig.tc_config.customConfiguration.page &&
                    appConfig.tc_config.customConfiguration.page.list &&
                    appConfig.tc_config.customConfiguration.page.list.localSearchTextKeyList ?
                    appConfig.tc_config.customConfiguration.page.list.localSearchTextKeyList :
                    LibConfiguration.base.page.list.localSearchTextKeyList
                },
              },
              ticket: {
                bodyCustomField:
                  appConfig.tc_config.customConfiguration.ticket &&
                  appConfig.tc_config.customConfiguration.ticket.bodyCustomField ?
                  appConfig.tc_config.customConfiguration.ticket.bodyCustomField :
                  null,
                statusContextApplicationStatusMap:
                  appConfig.tc_config.customConfiguration.ticket &&
                  appConfig.tc_config.customConfiguration.ticket.statusContextApplicationStatusMap ?
                  appConfig.tc_config.customConfiguration.ticket.statusContextApplicationStatusMap :
                  LibConfiguration.base.ticket.statusContextApplicationStatusMap
              }
            }
          } else {
            appConfig.tc_config.customConfiguration = LibConfiguration.base;
          }
          store.dispatch(TicketCenterLibConfigurationStoreAction.update(new BaseState(appConfig.tc_config)));

          resolve(null);
        } else {
          throw new NoSuchValueException(
            'ticket-center field is not present in environment configuration'
          );
        }
      });
    });
  };
};

// risolve errori tipo ERROR: Error during template compile of 'TicketCenterModule'
//  Function calls are not supported in decorators but 'ResourceModule' was called.
export const resourceModule = ResourceModule.forChild();

export const storeDevtoolsModule = StoreDevtoolsModule.instrument({
  name: 'Ticket Center',
  maxAge: 25, // Retains last 25 states
});
export const storeModule = StoreModule.forRoot(
  {},
  {
    runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false
    }
  }
);

// @dynamic
@NgModule({
	imports: [
		resourceModule,
    // NgRx - Store
		storeDevtoolsModule,
		storeModule,
    TicketCenterTicketStoreModule,
	  TicketCenterTicketDetailStoreModule,
    TicketCenterLibConfigurationStoreModule
  ],
	exports: [
		PageModule
	],
	providers: [
		DatePipe,
		SlicePipe
	]
})
export class TicketCenterModule {
	constructor(@Optional() @SkipSelf() parentModule: TicketCenterModule) {
		if (parentModule) {
			throw new Error(
				'TicketCenterModule is already loaded. Import it in the AppModule only'
			);
		}
	}

	static forRootWithTranslation(opt: ILibModelOptions): ModuleWithProviders<TicketCenterModule> {
		return {
			ngModule: TicketCenterModule,
			providers: [
				{ provide: ENVIRONMENT_SETTING, useValue: opt.env },
				{
					provide: APP_CONFIG_TOKEN,
					useExisting: opt.appConfig,
				},
				{
					provide: LOADER_SERVICE_TOKEN,
					useExisting: opt.loaderSerivce,
				},
				{
					provide: APP_INITIALIZER,
					deps: [opt.appConfig, ENVIRONMENT_SETTING, HttpClient, [new Inject(Store)]],
					useFactory: loadLibConfig,
					multi: true,
				},
				{ provide: TranslateLoader, useClass: TicketCenterTranslateLoader },
				{ provide: TranslateCompiler, useClass: TranslateFakeCompiler },
				{ provide: TranslateParser, useClass: TranslateDefaultParser },
				{
					provide: MissingTranslationHandler,
					useClass: FakeMissingTranslationHandler,
				},
				TranslateStore,
				{ provide: USE_STORE, useValue: true },
				{ provide: USE_DEFAULT_LANG, useValue: true },
				TranslateService,
        // store effect
        TicketCenterTicketEffects,
        {
          provide: USER_PROVIDED_EFFECTS,
          multi: true,
          useValue: [TicketCenterTicketEffects],
        },
        TicketCenterTicketDetailEffects,
        {
          provide: USER_PROVIDED_EFFECTS,
          multi: true,
          useValue: [TicketCenterTicketDetailEffects],
        }
			]
		}
	}

	static forRoot(opt: ILibModelOptions): ModuleWithProviders<TicketCenterModule> {
		return {
			ngModule: TicketCenterModule,
			providers: [
				{ provide: ENVIRONMENT_SETTING, useValue: opt.env },
				{
					provide: APP_CONFIG_TOKEN,
					useExisting: opt.appConfig,
				},
				{
					provide: APP_INITIALIZER,
					deps: [opt.appConfig, ENVIRONMENT_SETTING, HttpClient, [new Inject(Store)]],
					useFactory: loadLibConfig,
					multi: true,
				},
				{
					provide: LOADER_SERVICE_TOKEN,
					useExisting: opt.loaderSerivce,
				},
        // store effect
        TicketCenterTicketEffects,
        {
          provide: USER_PROVIDED_EFFECTS,
          multi: true,
          useValue: [TicketCenterTicketEffects],
        },
        TicketCenterTicketDetailEffects,
        {
          provide: USER_PROVIDED_EFFECTS,
          multi: true,
          useValue: [TicketCenterTicketDetailEffects],
        }
			]
		}
	}

}
