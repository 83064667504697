import { Component, OnInit } from '@angular/core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ticket-center-wrapper',
	templateUrl: './ticket-center-wrapper.component.html',
	styleUrls: ['./ticket-center-wrapper.component.scss']
})
export class TicketCenterWrapperComponent implements OnInit {
	constructor(
        private utilBreadcrumbService: UtilBreadcrumbService,
        private translateService: TranslateService,
    ) {

        this.setRouteMetaInformation(this.translateService.instant('general.ticket_center'),null)
    }

	ngOnInit() {}

    ngOnDestroy() {
        this.utilBreadcrumbService.unsetRouteMetaInformation();
	}


    setRouteMetaInformation(title: string = null, subtitle: string = null) {
        // TODO: valutare se utile utilizzare la nuova gestione dei parametri
        title !== null ? this.utilBreadcrumbService.title.value = title : null;
        subtitle !== null ? this.utilBreadcrumbService.subtitle.value = subtitle : null;
        this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['ticket_center']);
    }
}
