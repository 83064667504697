import { HeaderRecordPouchModel, ThresholdPouchModel } from '..';
import { BodyTablePouchModel } from './base-pouch.model';

export interface PricePouchModel<T extends DiscountCurrentModel | number> extends HeaderRecordPouchModel {
	division: string;
	date_begin_val_cur?: string; //todo: as400 da portare in epoch
	date_begin_val_old?: string; //todo: as400 da portare in epoch
	code_article: string;
	price_current?: number;
	discounts_current: T[];
	sk_type: string;
	group_forced_x_canvass?: string;
	article_salable_sn?: string;
	divisions?: PriceDivisionModel<T>[];
}

export interface DiscountCurrentModel {
	discount: number;
	discount_z: string;
}

export interface PriceDivisionModel<T> {
	division: string;
	price_current?: number;
	date_begin_val_cur: string;
	date_begin_val_old: string;
	discounts_current?: T[];
	discounts_old?: T[];
	discounts_future?: T[];
}

export namespace PriceEnum {
	export enum ValueType {
		PERCENTAGE = 'PERCENTAGE',
		ABSOLUTE = 'ABSOLUTE'
	}
}

export interface ShippingChargePouchModel extends BodyTablePouchModel {
	begin_date?: number;
	surcharge?: number;
	thresholds?: ThresholdPouchModel[];
	carrier?: string;
}

