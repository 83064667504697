import { Component, Input, OnInit } from '@angular/core';
import { NavItem, SubscribeManagerService } from '@saep-ict/angular-core';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { Router } from '@angular/router';
import { PATH_URL } from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { Observable, map } from 'rxjs';

@Component({
	selector: 'saep-sidebar-item',
	templateUrl: './sidebar-item.component.html',
	styleUrls: ['./sidebar-item.component.scss'],
	providers: [SubscribeManagerService]
})
export class SaepSidebarItemComponent implements OnInit {
	@Input() set item(e: NavItem) {
		if (e) {
			this._item = e;
			this.subscribeManagerService.populate(
				this.subscribeBreadcrumbServiceUpdateActiveNavigationItem().subscribe(),
				'updateActiveNavigationItem$'
			);
		}
	};
	_item: NavItem;
	@Input() sidebarIsOpen: boolean;
	@Input() isSubitem: boolean;

	isActive = false;

    PATH_URL = PATH_URL;

	constructor(
		public navigationService: NavigationService,
		public router: Router,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private subscribeManagerService: SubscribeManagerService,
	) {

	}

	ngOnInit() {}
	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	subscribeBreadcrumbServiceUpdateActiveNavigationItem(): Observable<void> {
		return this.utilBreadcrumbService.updateActiveNavigationItem$.pipe(
			map((e: string[]) => {
				this.isActive =
					e &&
					e.length > 0 &&
					e.includes(this._item['key']) ? true : false;
			}),
		)
	}

}
