import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DialogTicketDetailComponent } from './dialog/dialog-ticket-detail/dialog-ticket-detail.component';
import { TicketDetailWrapperComponent } from './ticket-detail-wrapper/ticket-detail-wrapper.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StatusSelectorComponent } from './status-selector/status-selector.component';
import { TicketWrapperComponent } from './ticket-wrapper/ticket-wrapper.component';
@NgModule({
    imports: [SharedModule, MatDialogModule],
    declarations: [
        TicketWrapperComponent,
        TicketDetailWrapperComponent,
        DialogTicketDetailComponent,
        StatusSelectorComponent
    ],
    exports: [
        TicketWrapperComponent,
        TicketDetailWrapperComponent,
        DialogTicketDetailComponent,
        StatusSelectorComponent
    ]
})
export class WidgetModule { }
