import {  BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import {
	ArticleListTypePouchModel,
	CausalHeaderSoPouchModel,
	CausalRowSoPouchModel,
	CodeVatPouchModel,
	CommercialAreaPouchModel,
	CountryIsoPouchModel,
	PaymentPouchModel,
	UmPouchModel
} from '@saep-ict/pouch_agent_models';
import { DivisionPouchModel } from '@saep-ict/pouch_agent_models';
import { FormControlMultipurposeModel } from '@saep-ict/angular-core';

export interface AuxiliaryTableStateModel {
  areaCommercial?: BodyTablePouchModel[];
	shippingConditionList_COMPANY?: CommercialAreaPouchModel[];
	shippingConditionList_PRIVATE?: CommercialAreaPouchModel[];
	causalHeaderSoList?: CausalHeaderSoPouchModel[];
	causalRowSoList?: CausalRowSoPouchModel[];
	countryIsoList?: CountryIsoPouchModel[];
	codeVatList?: CodeVatPouchModel[];
	articlePriceList?: ArticleListTypePouchModel[];
	methodShippingList?: BodyTablePouchModel[];
	methodDeliveryList?: BodyTablePouchModel[];
	paymentList?: PaymentPouchModel[];
	causalSuspensionOrderList?: BodyTablePouchModel[];
	umList?: UmPouchModel[];
	typesCurrencyList?: BodyTablePouchModel[];
	carrierList?: BodyTablePouchModel[];
	divisionList?: DivisionPouchModel[];
	groupArticle?: BodyTablePouchModel[];
	currencyList?: CurrencyStateModel[];
	warehouseList?: WarehouseStateModel[];
}

export interface CarrierPumpingChargePouchModel {
	value: number;
	length_max: number;
}

export interface CurrencyStateModel {
	code_iso: string;
	code_item: string;
	description: string;
}

export interface WarehouseStateModel {
	code_item: string;
	description: string;
	description_short: string;
	is_main_of_list: boolean;
}

export const AuxiliaryDictionary = [
	{
		key: 'table_shipping_conditions_COMPANY',
		type: 'shippingConditionList_COMPANY'
	},
	{
		key: 'table_shipping_conditions_PRIVATE',
		type: 'shippingConditionList_PRIVATE'
	},
	{
		key: 'table_causal_header_so',
		type: 'causalHeaderSoList'
	},
	{
		key: 'table_causal_row_so',
		type: 'causalRowSoList'
	},
	{
		key: 'table_method_shipping',
		type: 'methodShippingList'
	},
	{
		key: 'table_method_delivery',
		type: 'methodDeliveryList'
	},
	{
		key: 'table_payment',
		type: 'paymentList'
	},
	{
		key: 'table_currency',
		type: 'currencyList'
	},
	{
		key: 'table_warehouse',
		type: 'warehouseList'
	},
	{
		key: 'table_carrier',
		type: 'carrierList'
	}
];

export namespace AuxiliaryTabeleGeographicTree {
	export enum ChildrenLevelEnum {
		NATION = 'NATION',
		REGION = 'REGION',
		PROVINCE = 'PROVINCE'
	}
	export interface Item extends BodyTablePouchModel {
		level: AuxiliaryTabeleGeographicTree.ChildrenLevelEnum;
		children: this[];
	}
	export interface StateModel {
		tree: AuxiliaryTabeleGeographicTree.Item[];
		provinceList: FormControlMultipurposeModel.OptionList[];
	}
	export interface CodeItemHierarchyConfigParam {
		codeItem: string;
		tree?: AuxiliaryTabeleGeographicTree.Item[];
		codeItemHierarchyList?: string[];
		codeItemFound?: boolean;
	}
}
