import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NewsListActionEnum, NewsListStateAction } from '../../../state/news-list/news-list.actions';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { BaseState, BaseStateModel, SideBarPositionValues, SubscribeManagerService } from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { filter, map, Observable, Subscription, take } from 'rxjs';
import { NewsModel } from '../../../model/news.model';
import { StateFeature } from '../../../state';
import { NewsListWrapperComponent } from '../../../widget/news/news-list-wrapper/news-list-wrapper.component';
import _ from 'lodash';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ContextApplicationItemCodeEnum, PATH_URL, ROUTE_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { NewsActionEnum, NewsStatusEnum } from '../../../enum/news.enum';
import { DateAsAgoPipe } from '../../../shared/pipe/date-as-ago.pipe';
import * as ConfigurationCustomerNews from '../../../constants/configuration-customer/news/news.constant';

@Component({
	selector: 'news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.scss'],
	providers: [SubscribeManagerService]
})
export class NewsComponent implements OnInit, OnDestroy {
	@ViewChild(NewsListWrapperComponent) public newsListWrapperComponent: NewsListWrapperComponent;

	newsList$: Observable<BaseStateModel<NewsModel[]>> = this.store.select(StateFeature.getNewsListState);
	newsList: NewsModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	// sidebarContent: Array<ItemSidebarContentStandardComponentConfig> = [];
	statusFilter: string;

	PATH_URL = PATH_URL;
	ROUTE_URL = ROUTE_URL;
	configurationCustomerNews = ConfigurationCustomerNews;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	private routerNavigationSub: Subscription;

	public listPageBaseData = <LocalListHandlerBaseModel<NewsModel>>{
		data: [],
		sort: {
			name: 'date_update',
			order: 'DESC'
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['body.subject', 'body.message']
			}
		},
		pagination: {
			pageSize: 5
		}
	};

	sideBarPositionValues = SideBarPositionValues;

	constructor(
		private utilBreadcrumbService: UtilBreadcrumbService,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		public router: Router,
		private dateAsAgo: DateAsAgoPipe
	) {
		this.subscribeManagerService.populate(this.subscribeNewsList().subscribe(), 'subscribeNewsList');
		this.routerNavigationSub = this.router.events.subscribe((evt: RouterEvent) => {
			if (evt instanceof NavigationEnd) {
				this.statusFilter = evt.urlAfterRedirects.split('/').pop();
				if (this.newsList) {
					this.applyTabFilter();
				}
			}
		});
		// User
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
			}
		});
	}

	ngOnInit(): void {
		this.store.dispatch(NewsListStateAction.loadAll());
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('news');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['news']);
	}

	ngOnDestroy(): void {
		this.store.dispatch(NewsListStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
		if (this.routerNavigationSub) {
			this.routerNavigationSub.unsubscribe();
		}
	}

	subscribeNewsList() {
		return this.newsList$.pipe(
			filter((state: BaseStateModel<NewsModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<NewsModel[]>) => {
				switch (state.type) {
					case NewsListActionEnum.UPDATE:
						this.applyPermanentFilter(state.data);
						this.applyTabFilter();
						// this.setSidebarContent();
						break;
				}
			})
		);
	}

	applyPermanentFilter(newsList: NewsModel[]) {
		this.newsList = ConfigurationCustomerNews.listFilter(newsList, this.user.current_permission.context_application);
		this.newsListWrapperComponent.updateListPageBaseData(this.newsList);
	}

	applyTabFilter() {
        // this.newsList = this.newsList.filter(function (item) {
        //     return item.valid === true;
        // });

		const filteredNewsList: NewsModel[] = ConfigurationCustomerNews.returnListFilteredByTab(
			this.user.current_permission.context_application,
			this.statusFilter,
			this.newsList
		);
		this.newsListWrapperComponent.updateListPageBaseData(filteredNewsList);

		// Reset selected items
		this.newsListWrapperComponent._selectedNewsList = [];
		// Tab info
		this.newsListWrapperComponent.statusFilter = this.statusFilter;
	}


	createNew() {
		this.router.navigate([PATH_URL.PRIVATE, ROUTE_URL.news, 'create']);
	}

	updateSelectedNewsList(codeItemList: string[], action: NewsActionEnum) {
		const newsToUpdateList: NewsModel[] = this.newsList.filter(item => codeItemList.includes(item.code_item));
		switch (action) {
			case NewsActionEnum.ARCHIVE:
				newsToUpdateList.forEach(item => (item.header.status = NewsStatusEnum.ARCHIVE));
				break;
			case NewsActionEnum.DELETE:
				newsToUpdateList.forEach(item => (item.valid = false));
				break;
            case NewsActionEnum.TOGGLE_STATUS:
                newsToUpdateList.forEach(item => (item.header.status = item.header.status == NewsStatusEnum.NEW?NewsStatusEnum.ARCHIVE:NewsStatusEnum.NEW));
                break;
		}
		this.store.dispatch(NewsListStateAction.saveAll(new BaseState(newsToUpdateList)));
	}

	// setSidebarContent() {
	// 	this.sidebarContent = this.newsList
	// 		.sort((a, b) => b.date_update - a.date_update)
	// 		.slice(0, 5)
	// 		.map(item => {
	// 			return {
	// 				title: item.body.subject,
	// 				subtitle: this.dateAsAgo.transform(item.date_update),
	// 				icon: { type: IconType.CUSTOM, name: 'custom-document' }
	// 			};
	// 		});
	// }
}
