import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import _ from "lodash";
import { OrderListFilterCreationFieldMapFormValue, OrderListFilterCreationFieldMapSelectOptionMap } from "../../../../../model/order-util.model";
import {
	returnConfigurationCustomerOrderListFilterByFormFieldDefault,
	returnConfigurationCustomerOrderListFilterByFormFieldOrderCausalList,
	returnCreationFieldMapWithOptionOrderCausalList,
	returnCreationFieldMapWithOptionOrderStatus
} from "../order-list-filter-form-dynamic-util.constant";
import { TableOrderModel } from "../../../../../model/table/table-order.model";

export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
	{
		name: 'date',
		on_form_template: true,
		label: 'order.field.creation_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'status',
		on_form_template: true,
		label: 'theme.order.order_status',
		type: FormControlMultipurposeEnum.ItemType.SELECT_MULTIPLE
	},
	{
		name: 'order_causal',
		on_form_template: true,
		label: 'theme.order.field.causal',
		type: FormControlMultipurposeEnum.ItemType.SELECT_MULTIPLE
	}
];

export const returnConfigurationCustomerOrderListFilterCreationFieldMapAgent = async (
	selectOptionMap: OrderListFilterCreationFieldMapSelectOptionMap,
	orderStatus?: boolean
): Promise<FormControlMultipurposeModel.Item[]> => {
	try {
		let creationFieldMapToReturn = _.cloneDeep(creationFieldMap);
		creationFieldMapToReturn =
			await returnCreationFieldMapWithOptionOrderStatus(
				creationFieldMapToReturn,
				selectOptionMap,
				orderStatus
			);
		creationFieldMapToReturn =
			await returnCreationFieldMapWithOptionOrderCausalList(
				creationFieldMapToReturn,
				selectOptionMap
			);
		return creationFieldMapToReturn;
	} catch(e) {
		throw new Error(e);
	}
}

export const returnConfigurationCustomerOrderListFilterByFormValueAgent = async (
    orderList: TableOrderModel[],
    formValue: OrderListFilterCreationFieldMapFormValue
): Promise<TableOrderModel[]> => {
	try {
		if (orderList && orderList.length && formValue) {
			orderList = await returnConfigurationCustomerOrderListFilterByFormFieldDefault(orderList, formValue);
			orderList = await returnConfigurationCustomerOrderListFilterByFormFieldOrderCausalList(orderList, formValue);
		}
		return orderList;
	} catch(e) {
		throw new Error(e);
	}
}