import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'loading-results-spinner',
	templateUrl: './loading-results-spinner.component.html',
	styleUrls: ['./loading-results-spinner.component.scss']
})
export class LoadingResultsSpinnerComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
