<breadcrumb [title]="configuration.page.list.title[user.current_permission.context_application] | translate"></breadcrumb>
<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<div class="row">
			<div class="col-12">
        <mat-card>
          <tc-ticket-wrapper
            [localListHandlerWidgetWrapperData]="listPageBaseData"
            [localListHandlerWidgetWrapperColumnList]="configuration.page.list.tableColumn[user.current_permission.context_application]"
            [localListHandlerTextFilterShow]="true"
            [localListHandlerPaginatorShow]="true"
            [localListHandlerSidebarFilterShow]="true"
            [localListHandlerTitle]="'tc.general.list' | translate | sentencecase"
            (localListHandlerOpenSidebarFilter)="listWrapper.sidenav.open()"
            (selectTicket)="ticketCenterMainService.dialogCreateNew($event)"
          >
          </tc-ticket-wrapper>
        </mat-card>
			</div>
		</div>
	</ng-container>
	<ng-container side>
		<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
      <mat-form-field>
        <mat-label>{{'tc.general.status' | translate | sentencecase}}</mat-label>
        <mat-select formControlName="statusList" multiple>
          <mat-option
            *ngFor="
              let item of configuration.ticket.statusContextApplicationStatusMap[
              user.current_permission.context_application]
              .filter_list
            "
            [value]="item.value">
            {{ utilService.formatPlaceholder(item.name ? item.name : item.value).toLocaleLowerCase() | sentencecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="urgent">{{'tc.right_sidebar.show_only_urgent_ticket' | translate | sentencecase}}</mat-checkbox>
      <ng-container formGroupName="dateRequest">
        <mat-form-field>
          <mat-label>{{ 'tc.general.date_request' | translate | sentencecase }}</mat-label>
          <mat-date-range-input [rangePicker]="pickerDateRequest">
            <input
              matStartDate
              formControlName="start"
              placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerDateRequest"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerDateRequest></mat-date-range-picker>
        </mat-form-field>
      </ng-container>
      <ng-container formGroupName="dateDue">
        <mat-form-field>
          <mat-label>{{ 'tc.general.date_due' | translate | sentencecase }}</mat-label>
          <mat-date-range-input [rangePicker]="pickerDateDue">
            <input
              matStartDate
              formControlName="start"
              placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerDateDue"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerDateDue></mat-date-range-picker>
        </mat-form-field>
      </ng-container>
			<button
        mat-flat-button
        [disabled]="!form.valid"
        type="submit"
        class="btn-sidebar mt-16"
        color="primary"
      >
				{{ 'general.apply_filters' | translate | sentencecase }}
			</button>
			<button mat-stroked-button color="primary" class="btn-sidebar" type="button" (click)="onFormResetFilters()">
				{{ 'general.reset' | translate | sentencecase }}
			</button>
		</form>
	</ng-container>
</div>

<button
	mat-fab
  color="primary"
	class="mat-fab-button"
	matTooltipPosition="left"
	*ngIf="user.current_permission.context_application === contextApplicationItemCodeEnum.B2B"
	(click)="ticketCenterMainService.dialogCreateNew()"
>
	<mat-icon>add</mat-icon>
</button>
