import { ListStructureModel } from './structure/list-structure.model';
import { ChatInstanceTypeEnum } from '../enum/chat.enum';

// store
export interface ChatDetailStateModel<USER> {
	client?: ChatModel<USER>;
	agency?: ChatModel<USER>;
	assistance?: ChatModel<USER>;
}

export interface ChatDetailStateFeatureSelectionParameterModel<USER> {
	side?: ChatInstanceTypeEnum;
	chat?: ChatModel<USER>;
	type?: string;
}

// entity
export class ChatModel<USER> {
	type?: string;
	_id?: string;
	_rev?: string;
	partecipant_list?: PartecipantList<USER>[];
	name?: string;
	last_message?: number;
	thread?: ListStructureModel<MessageModel<USER>[]>;
}

export class MessageModel<USER> {
	type?: string;
	_id?: string;
	thread_id?: string;
	sender?: number;
	sender_user_info?: USER;
	text?: string;
	date_sending?: number;
}

type PartecipantList<USER extends any> = USER & {
  last_access: number;
};
