<div class="unordered-list">
	<h4 class="unordered-list__title" *ngIf="title">{{ title }}</h4>

	<ul class="unordered-list__list" *ngIf="items">
		<li class="unordered-list__item" *ngFor="let i of items">
			<a
				href="javascript:void(0)"
				(click)="goTo(i)"
				[innerHTML]="
					i.item.language_list ?
					utilTranslateService.getTranslationFromLanguage(i.item.language_list).description :
					(i.item | translate | sentencecase)
				"
				[title]="
					i.item.language_list ?
					utilTranslateService.getTranslationFromLanguage(i.item.language_list).description :
					(i.item | translate | sentencecase)
				"
			></a>
		</li>
	</ul>
</div>
