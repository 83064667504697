import { ArticlePouchModel, ArticleCheckoutTree, Category  } from '@saep-ict/pouch_agent_models';
import * as CategoryEnum from '../enum/category.enum';

/**
 * Permette di indicare il percorso verso la categoria selezionata:
 * 
 * - index: es. [0, 2, 6] cerca la categoria selezionata nell'elemento ad index 0 per il level root,
 * poi nel terzo elemento (index 2) del secondo livello di annidamento, infine nel settimo per il terzo livello.
 * - propertyKey: TBD
 */
export interface SelectLevelBy {
	index?: number[]
	propertyKey?: {
		key: string,
		value: string | number
	}[]
}
/**
 * Interfacce utili al funzionamento del componente category-tab-list
 */
	export interface StyleType {
		styleType: CategoryEnum.TabOnTopStyleType,
		customClassList?: string[]
	}
	export interface Configuration {
		/**
		 * Utility per identificazione del livello root nell'uso ricorsivo del componente category-tab-list.
		 * category-tab-list ne incrementa il valore ogni volta che si reistanzia ricorsivamente.
		 * Non settare mai in inizializzazione.
		 */
		level?: number;
		/**
		 * Parametro di rxjs debounceTime (millisecondi): default 500. Utilizzato solo nei casi in cui
		 * level === 1
		 */
		debounceTime?: number;
		/**
		 * Utility per gestione della visualizzazione di default. Se `initialization.active: true` il componente renderà
		 * visibili i livelli annidati in base alla configurazione in `selectLevelBy` (altrnativamente `index` o `propertyKey`
		 * dove il primo ha la priorità)
		 */
		initialization: {
			active: boolean,
			selectLevelBy?: SelectLevelBy
		},
		/**
		 * Gestisce le classi di style che le istanze ricorsive di category-tab-list devono ereditare. Il match è
		 * effettuato tra l'index dell'array e la prop. level del nodo category-tab-list.
		 * Quando il numero di nodi (e di level) diventa maggiore dell'index, l'ultimo elemento di levelStyleList
		 * viene usato in default per tutti i successivi nodi figli.
		 * Se la prop. non è compilata oppure ha length === 0, tutte le istanze di category-tab-list
		 * ereditano lo style definito dal tipo CategoryEnum.TabOnTopStyleType.CHIP
		 */
		levelStyleList?: StyleType[]
	}

	export interface Active extends Category {
		active?: boolean;
	}


export interface List {
	categoryList: Category[]
}

export interface SectionItem {
	title: string,
	articleListFilter: (articleList: ArticlePouchModel[]) => ArticlePouchModel[]
	articleCategoryTree?: ArticleCheckoutTree[]
}

export interface CategoryDetailUploadPayload {
	category: Category;
	file?: File,
	changeType: CategoryEnum.ChangeImageType
}