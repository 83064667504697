import { NgModule } from '@angular/core';

// model
import { TicketCenterTicketDetailStoreReducer } from './ticket-detail.reducer';

// store
import { StoreModule } from '@ngrx/store';

export const storeModule = StoreModule.forFeature('ticket-center-ticket-detail', TicketCenterTicketDetailStoreReducer.reducer);

@NgModule({
    imports: [
      storeModule
    ]
})
export class TicketCenterTicketDetailStoreModule{}
