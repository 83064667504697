<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<mat-accordion multi>
	<mat-expansion-panel
		*ngFor="let category of categoryTree; let i = index"
		[expanded]="_configuration && _configuration.multipleOpen ? null : matAccordionExpansionPanelActiveIndex === i"
		(opened)="setMatAccordionExpansionPanelActiveIndex(i)"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
				<!-- pipe `translate` necessaria per gestione label multingua nei soli articoli senza categoria -->
				{{
					utilTranslateService.getTranslationFromLanguage(category.language_list).description
						| translate
						| sentencecase
				}}
				<!-- TODO: confermare l'utilizzo anche per il summary relativo al forecast -->
				{{ category.metaInformation && category.metaInformation.total ? category.metaInformation.total : '' }}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<!-- in `ConfigurationCustomerOrder.categorySectionList` è presente una lista di sezioni nella quale
		suddividere gli articoli del il livello -->
		<ng-container *ngIf="category.category_list && category.category_list.length > 0">
			<ng-container *ngFor="let section of category.category_list">
				<!-- trattandosi `section` di una categoria fittizia, la descrizione multilingua si trova nei bundle FE e non nel
				database: il controller salva il percorso per la traduzione nella prop. `description` lasciando il resto alla pipe
				`translate`, senza avvalersi dei meccanismi legati a `language_list` -->
				<ng-container *ngIf="section.article_list && section.article_list.length > 0">
					<h3>{{ section.description | translate | sentencecase }}</h3>
					<ng-container
						*ngTemplateOutlet="articleTableWrapper; context: { category: section }"
					></ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
		<!-- non c'è un sub annidamento, l'accordion rappresenta solo il livello configurato -->
		<ng-container *ngIf="!(category.category_list && category.category_list.length > 0)">
			<ng-container *ngTemplateOutlet="articleTableWrapper; context: { category: category }"></ng-container>
		</ng-container>

		<!-- dati statitici per forecast bo -->
		<ng-container *ngTemplateOutlet="forecastWrapper"></ng-container>
		<!-- dati statitici per forecast bo -->
	</mat-expansion-panel>
</mat-accordion>

<ng-template #articleTableWrapper let-category="category"> </ng-template>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>

<ng-template #forecastWrapper>
	<div class="forecast-wrapper">
		<mat-divider></mat-divider>
		<div class="list-row">
			<div class="list-wrapper">
				<h3>Contributors</h3>
				<ul>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
				</ul>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="list-row">
			<div class="list-wrapper">
				<h3>Forecast 2022</h3>
				<ul>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span><span>1.350 pcs</span></li>
				</ul>
			</div>
			<div class="list-wrapper">
				<h3>Sold 2021</h3>
				<ul>
					<li><span>Euroitalia srl Austria - 6600000</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span></li>
					<li><span>Euroitalia srl Austria - 6600000</span></li>
				</ul>
			</div>
		</div>

		<mat-divider></mat-divider>
		<div class="chart-wrapper">
			<h3>Previous year comparision</h3>
			<chart-wrapper style="width: 100%" [config]="chartDataHorizontal" class=""></chart-wrapper>
		</div>
		<mat-divider></mat-divider>
		<div class="chart-wrapper">
			<h3>Annual</h3>
			<chart-wrapper style="width: 100%" [config]="chartDataVertical" class=""></chart-wrapper>
		</div>
	</div>
</ng-template>
