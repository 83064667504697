import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper, AngularCoreUtilService } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'informative-page-list-wrapper',
	templateUrl: './informative-page-list-wrapper.component.html',
	styleUrls: ['./informative-page-list-wrapper.component.scss']
})
export class InformativePageListWrapperComponent extends LocalListHandlerWidgetWrapper {
	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {
		super(utilService, translateService, store);
	}
}
