import { ListStructureModel } from '@saep-ict/angular-core';
import { DiscountModel } from '@saep-ict/pouch_agent_models';

// list
export interface ProductListModel extends ListStructureModel<ProductModel[]> {}

// details
export interface ProductModel {
	id?: string;
	code?: string;
	description?: string;
	brand?: string;
	line?: string;
	price?: number;
	discount?: number;
	discount_agent?: DiscountModel;
	note?: string;
	qty?: number;
	qtyO?: number;
	disponibilita?: string;
}
