import {
    ITdDataTableColumnCustom
} from '@saep-ict/angular-core';
import {
	ConfigurationUtil
} from '@saep-ict/angular-spin8-core';
import * as ConfigurationContextCodeColumnMap from './context-code-column-map.constant';

export const columnList = (
    canEdit: boolean
) => <ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumnCustom[]>>{
    AGENT: ConfigurationContextCodeColumnMap.base(canEdit).filter(
        i => !ConfigurationContextCodeColumnMap.excludeForAgent.includes(i.name)
    ),
    B2B: ConfigurationContextCodeColumnMap.base(canEdit).filter(
        i => !ConfigurationContextCodeColumnMap.excludeForB2b.includes(i.name)
    ),
    B2C: ConfigurationContextCodeColumnMap.base(canEdit).filter(
        i => !ConfigurationContextCodeColumnMap.excludeForB2c.includes(i.name)
    ),
    BACKOFFICE: ConfigurationContextCodeColumnMap.base(canEdit).filter(
        i => !ConfigurationContextCodeColumnMap.excludeForBackoffice.includes(i.name)
    ),
    PORTAL: ConfigurationContextCodeColumnMap.base(canEdit).filter(
        i => !ConfigurationContextCodeColumnMap.excludeForPortal.includes(i.name)
    ),
    CRM: ConfigurationContextCodeColumnMap.base(canEdit).filter(
        i => !ConfigurationContextCodeColumnMap.excludeForCrm.includes(i.name)
    )
};