import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable()
export class SubscribeManagerService {
    private subscription: Subscription = new Subscription();
    private subObjectKey: Map<string, number> = new Map();

    populate($sub: Subscription, key: string){
        if (!this.subObjectKey.has(key)){
            this.subscription.add($sub);
            this.subObjectKey.set(key, this.subscription['_finalizers'].length - 1);
        }
    }

    destroy(name?: string){
        if (name){
            const index = this.subObjectKey.get(name);
            if (index || index === 0){
                this.subscription['_finalizers'][index].unsubscribe();
            }
            this.subObjectKey.delete(name);
        } else {
            this.subscription.unsubscribe();
            this.subObjectKey.clear();
        }
    }

    get activeSubscription(){
        return this.subObjectKey;
    }

    hasSubscription(key: string){
        return this.subObjectKey.has(key);
    }
}
