import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
	BaseState,
	BaseStateModel,
	FormValidatorCustom,
	GuidFormatterPipe,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { OpportunityEnum, OpportunityPouchModel, OrganizationStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import {
	ContactPouchModel,
	DestinationPouchModel,
	OrganizationLevelEnum,
	OrganizationTypeEnum
} from '@saep-ict/pouch_agent_models';
import { filter, map, Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { Store } from '@ngrx/store';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../state/opportunity/opportunity.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilService } from '../../../../service/util/app-util.service';
import _ from 'lodash';
import { UtilAddressService } from '../../../../service/util/util-address.service';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import * as ConfigurationCustomerOpportunity from '../../../../constants/configuration-customer/opportunity/opportunity.constant';
import { OrganizationActionEnum, OrganizationStateAction } from '../../../../state/organization/organization.actions';
import { RegistrationTypeEnum } from '../../../../enum/registration.enum';
import moment from 'moment';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { formRequestConfig } from '../../../../constants/configuration-customer/request/request-field-map-backoffice.constant';
import { request } from '../../../../constants/configuration-customer/organization/organization-list-filter/organization-list-filter-creation-field-map/organization-list-filter-creation-field-map-customer.constant';

@Component({
	selector: 'request-detail',
	templateUrl: './request-detail.component.html',
	styleUrls: ['./request-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class RequestDetailComponent implements OnDestroy {
	formRequestConfigRequestDetail = formRequestConfig;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunityBeforeEdit: OpportunityPouchModel;
	opportunityId: string = this.route.snapshot.paramMap.get('idRequest');

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	destination: DestinationPouchModel;
	contact: ContactPouchModel;

	form: FormGroup;
	formIsEditable = false;
	opportunityEnum = OpportunityEnum;
	organizationTypeEnum = OrganizationTypeEnum;
	organizationLevelEnum = OrganizationLevelEnum;
	configurationCustomerOpportunity = ConfigurationCustomerOpportunity;
	request = request;
	provinceList = this.utilAddressService.getProvinceList();

	backPath: string;



	constructor(
		private utilStoreService: StoreUtilService,
		public utilOrderService: UtilOrderService,
		private fb: FormBuilder,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		private utilService: AppUtilService,
		private utilAddressService: UtilAddressService,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private guidFormatterPipe: GuidFormatterPipe,
		private router: Router,
	) {
		this.loadStaticData();
		this.createForm();
		this.store.dispatch(OpportunityStateAction.load({ data: { _id: this.opportunityId } }));
		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');
		this.subscribeManagerService.populate(this.subscribeOrganization().subscribe(), 'subscribeOrganization');
		this.backPath =  this.router.createUrlTree(['../'], { relativeTo: this.route }).toString();
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
	}

	subscribeOpportunity() {
		return this.opportunity$.pipe(
			filter((state: BaseStateModel<OpportunityPouchModel[]>) => !!(state && state.data)),
			map(state => {
				switch (state.type) {
					case OpportunityActionEnum.UPDATE:
						this.opportunity = state.data[0];
						this.destination = this.utilService.returnIsMainOfList<DestinationPouchModel>(
							this.opportunity.organization.destination_list
						);
						this.contact = this.utilService.returnIsMainOfList<ContactPouchModel>(
							this.opportunity.organization.contact_list
						);
						this.updateFormFieldValue();
						this.setRouteMetaInformation();
						break;
				}
			})
		);
	}

	subscribeOrganization() {
		return this.organization$.pipe(
			filter((state: BaseStateModel<OrganizationStateModel>) => !!(state && state.data)),
			map(state => {
				switch (state.type) {
					case OrganizationActionEnum.SAVE_SUCCESS:
						this.opportunity.status_code = ConfigurationCustomerOpportunity.OpportunityEnumStatusOutcome[this.user.current_permission.context_application].Closed;
						this.store.dispatch(OpportunityStateAction.save(new BaseState([this.opportunity])));
				}
			})
		);
	}

	createForm() {
		this.form = this.fb.group({
			creationDate: [{ value: '', disabled: !this.formIsEditable }],
			statusCode: [{ value: '', disabled: !this.formIsEditable }],

			organizationBusinessName: [{ value: '', disabled: !this.formIsEditable }],
			organizationClass: [{ value: '', disabled: !this.formIsEditable }],
			organizationType: [{ value: '', disabled: !this.formIsEditable }],
			organizationLevel: [{ value: '', disabled: !this.formIsEditable }],
			organizationVatNumber: [
				{ value: '', disabled: !this.formIsEditable },
				[Validators.minLength(11), Validators.maxLength(11), FormValidatorCustom.checkLuhn]
			],
			organizationTaxCode: [
				{ value: '', disabled: !this.formIsEditable },
				[Validators.minLength(16), Validators.maxLength(16)]
			],

			destinationBusinessName: [{ value: '', disabled: !this.formIsEditable }],
			destinationZipCode: [{ value: '', disabled: !this.formIsEditable }],
			destinationAddress: [{ value: '', disabled: !this.formIsEditable }],
			destinationLocality: [{ value: '', disabled: !this.formIsEditable }],
			destinationProvince: [{ value: '', disabled: !this.formIsEditable }],
			destinationCountry: [{ value: '', disabled: !this.formIsEditable }],

			contactFistName: [{ value: '', disabled: !this.formIsEditable }],
			contactLastName: [{ value: '', disabled: !this.formIsEditable }],
			contactEmail: [{ value: '', disabled: !this.formIsEditable }, [FormValidatorCustom.email]],
			contactPhone: [{ value: '', disabled: !this.formIsEditable }]
		});
	}

	updateFormFieldValue() {
		this.form.patchValue(
			{
				creationDate: moment(this.opportunity.date_creation),
				statusCode: this.opportunity.status_code,

				organizationBusinessName: this.opportunity.organization.business_name,
				organizationClass: this.opportunity.organization.organization_class,
				organizationType: this.opportunity.organization.organization_type,
				organizationLevel: this.opportunity.organization.level,
				organizationVatNumber: this.opportunity.organization.tax_data.vat_number,
				organizationTaxCode: this.opportunity.organization.tax_data.tax_code,

				destinationBusinessName: this.destination.business_name,
				destinationZipCode: this.destination.address.zip_code,
				destinationAddress: this.destination.address.address,
				destinationLocality: this.destination.address.locality,
				destinationProvince: this.destination.address.province,
				destinationCountry: this.destination.address.country,

				contactFistName: this.contact.first_name,
				contactLastName: this.contact.last_name,
				contactEmail: this.contact.email,
				contactPhone: this.contact.phone
			},
			{ emitEvent: false }
		);
	}

	manageRequest() {
		switch (this.opportunity.status_code) {
			case ConfigurationCustomerOpportunity.OpportunityEnumStatusOutcome[this.user.current_permission.context_application].Open:
				this.opportunity.status_code = ConfigurationCustomerOpportunity.OpportunityEnumStatusOutcome[this.user.current_permission.context_application].Closed;
				break;
			case ConfigurationCustomerOpportunity.OpportunityEnumStatusOutcome[this.user.current_permission.context_application].Closed:
				this.opportunity.status_code = ConfigurationCustomerOpportunity.OpportunityEnumStatusOutcome[this.user.current_permission.context_application].Open;
				break;
		}
		this.store.dispatch(OpportunityStateAction.save(new BaseState([this.opportunity])));
	}

	onFormSubmit() {
		if (this.form.valid) {
			const updatedOpportunity = _.cloneDeep(this.opportunity);
			updatedOpportunity.date_creation = this.form.value.creationDate.valueOf();
			updatedOpportunity.status_code = this.form.value.statusCode;

			updatedOpportunity.organization.business_name = this.form.value.organizationBusinessName;
			updatedOpportunity.organization.organization_class = this.form.value.organizationClass;
			updatedOpportunity.organization.organization_type = this.form.value.organizationType;
			updatedOpportunity.organization.level = this.form.value.organizationLevel;
			updatedOpportunity.organization.tax_data.vat_number = this.form.value.organizationVatNumber;
			updatedOpportunity.organization.tax_data.tax_code = this.form.value.organizationTaxCode;

			const updatedDestination = this.utilService.returnIsMainOfList<DestinationPouchModel>(
				updatedOpportunity.organization.destination_list
			);
			updatedDestination.business_name = this.form.value.destinationBusinessName;
			updatedDestination.address.zip_code = this.form.value.destinationZipCode;
			updatedDestination.address.address = this.form.value.destinationAddress;
			updatedDestination.address.locality = this.form.value.destinationLocality;
			updatedDestination.address.province = this.form.value.destinationProvince;
			updatedDestination.address.country = this.form.value.destinationCountry;

			const updatedContact = this.utilService.returnIsMainOfList<ContactPouchModel>(
				updatedOpportunity.organization.contact_list
			);
			updatedContact.first_name = this.form.value.contactFistName;
			updatedContact.last_name = this.form.value.contactLastName;
			updatedContact.email = this.form.value.contactEmail;
			updatedContact.phone = this.form.value.contactPhone;

			this.store.dispatch(OpportunityStateAction.save(new BaseState([updatedOpportunity])));

			this.exitEditForm();
		}
	}

	approveOrganization() {
		this.store.dispatch(
			OrganizationStateAction.createItemApi(
				new BaseState({
					organization: this.opportunity.organization,
					registrationType: RegistrationTypeEnum.REGISTRATION
				})
			)
		);
	}

	editForm() {
		this.formIsEditable = true;
		this.form.enable({ emitEvent: false });
		this.opportunityBeforeEdit = _.cloneDeep(this.opportunity);
	}

	exitEditForm() {
		this.formIsEditable = false;
		this.form.disable({ emitEvent: false });
		this.opportunity = _.cloneDeep(this.opportunityBeforeEdit);
		this.updateFormFieldValue();
	}

	setRouteMetaInformation() {
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('request_list');
		this.utilBreadcrumbService.subtitle.value = this.guidFormatterPipe.transform(this.opportunity._id);
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['request_list']);
	}

	compareItemValue(a, b): boolean {
		return a && b ? a.value === b.value : a === b;
	}
}
