import { ITdDataTableColumnCustom } from "../model/structure/list-structure.model";
import moment from "moment";

export const attachmentListManagerColumnListBase: ITdDataTableColumnCustom[] = [
  {
    name: 'preview',
    label: null,
    width: 40,
  },
  {
    name: 'name',
    labelPath: 'saep_ict_angular_core.attachment_list_manager.column_list.name',
    label: null
  },
  {
    name: 'date_creation',
    labelPath: 'saep_ict_angular_core.attachment_list_manager.column_list.date_creation',
    label: null,
    format: (v) => v ? moment(v).format('DD/MM/YYYY H:mm') : ''
  },
  {
    name: 'action',
    label: null,
    //width: 150,
    numeric: true,
    sortable: false
  }
];
