import { Injectable } from '@angular/core';
import { ChartStateValuePouchModel, OrderStatusEnum, OrderStatistics, OrderStatisticsList } from '@saep-ict/pouch_agent_models';
import moment from 'moment';
import { DateRange } from '../util/date-moment.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class ChartService {

	constructor(private translateService: TranslateService) {}

	getTotalCountAndAverage(orders: ChartStateValuePouchModel[], level?: string): OrderStatisticsList {
		const orderStatisticsList: OrderStatisticsList = this.initializeEmptyOrderStatisticsList(level);

		Object.keys(OrderStatusEnum).forEach((status: string) => {
			const statusOrders = orders.filter(order => order.status === OrderStatusEnum[status]);
			const orderStats = level
				? orderStatisticsList[status.toLowerCase()][level]
				: orderStatisticsList[status.toLowerCase()];
			orderStats.count = statusOrders.length;
			orderStats.total = this.calculateOrderTotal(statusOrders);
			orderStats.average = this.calculateOrderAverage(orderStats.total, orderStats.count);
			orderStats.activeClients = this.getActiveClientsCount(statusOrders);
		});
		return orderStatisticsList;
	}

	addOrdersToOrderStatistics(
		orders: ChartStateValuePouchModel[],
		orderStatisticsList: OrderStatisticsList,
		level: string
	) {
		Object.keys(OrderStatusEnum).forEach((status: string) => {
			orderStatisticsList[status.toLowerCase()][level].orders = orders.filter(
				order => order.status === OrderStatusEnum[status]
			);
		});
	}

	getDailyStatistics(
		orders: ChartStateValuePouchModel[],
		range: DateRange,
		level?: string,
		includeOrders?: boolean
	): OrderStatisticsList {
		const orderStatisticsList: OrderStatisticsList = this.initializeEmptyOrderStatisticsList(level);
		Object.keys(OrderStatusEnum).forEach((status: string) => {
			const thisStatusOrders = orders.filter(order => order.status === OrderStatusEnum[status]);
			orderStatisticsList[status.toLowerCase()][level]['daily'] = this.calculateDailyStatistics(
				thisStatusOrders,
				range,
				includeOrders
			);
		});
		return orderStatisticsList;
	}

	calculateDailyStatistics(
		orders: ChartStateValuePouchModel[],
		range: DateRange,
		includeOrders?: boolean
	): OrderStatistics[] {
		const ordersDateFormatted = orders.map((order: ChartStateValuePouchModel) => {
			order.date_formatted = moment(order.date, 'x').format('YYYY/MM/DD');
			return order;
		});
		const ordersGroupbyDate = this.groupListOfObjectByField(ordersDateFormatted, 'date_formatted');
		let index = 0;
		const dayByDayStatistics: OrderStatistics[] = [];
		for (const day = range.from.clone(); day <= range.to; day.add(1, 'days')) {
			const formDate = day.format('YYYY/MM/DD');
			dayByDayStatistics[index] = this.initiliazeEmptyOrderStatistics();
			if (ordersGroupbyDate[formDate]) {
				dayByDayStatistics[index].count = ordersGroupbyDate[formDate].length;
				dayByDayStatistics[index].total = this.calculateOrderTotal(ordersGroupbyDate[formDate]);
				dayByDayStatistics[index].average = this.calculateOrderAverage(
					dayByDayStatistics[index].total,
					dayByDayStatistics[index].count
				);
				dayByDayStatistics[index].activeClients = this.getActiveClientsCount(ordersGroupbyDate[formDate]);
				dayByDayStatistics[index].orders = includeOrders ? ordersGroupbyDate[formDate] : [];
			} else {
				dayByDayStatistics[index].count = 0;
				dayByDayStatistics[index].total = 0;
				dayByDayStatistics[index].average = 0;
				dayByDayStatistics[index].activeClients = 0;
				dayByDayStatistics[index].orders = [];
			}
			index += 1;
		}
		return dayByDayStatistics;
	}

	addDailyStatsToOrderStatistics(
		toAddStats: OrderStatisticsList,
		orderStatistics: OrderStatisticsList,
		level: string
	) {
		Object.keys(OrderStatusEnum).forEach((status: string) => {
			status = status.toLowerCase();
			Object.keys(toAddStats[status][level]).forEach(element => {
				orderStatistics[status][level][element] = toAddStats[status][level][element];
			});
		});
	}

	calculateOrderTotal(orders: ChartStateValuePouchModel[]): number {
		return orders.reduce((sum, order) => sum + (order.total ? order.total : 0), 0);
	}

	getTotal(statistics: OrderStatisticsList, statusList: OrderStatusEnum[], period: string, field: string) {
		return statusList.reduce(
			(sum, status) =>
				sum +
				(statistics[status.toLowerCase()][period][field] ? statistics[status.toLowerCase()][period][field] : 0),
			0
		);
	}

	calculateOrderAverage(orderTotal: number, numberOfOrders: number) {
		return numberOfOrders !== 0 ? orderTotal / numberOfOrders : 0;
	}

	getActiveClientsCount(orders: ChartStateValuePouchModel[]) {
		return Object.keys(this.groupListOfObjectByField(orders, 'client_code')).length;
	}

	initializeEmptyOrderStatisticsList(level?: string): OrderStatisticsList {
		const orderStatisticsList: OrderStatisticsList = <OrderStatisticsList>{};
		Object.keys(OrderStatusEnum).forEach((status: string) => {
			orderStatisticsList[status.toLowerCase()] = {};
			if (level) {
				orderStatisticsList[status.toLowerCase()][level] = {};
			}
		});
		return orderStatisticsList;
	}

	initiliazeEmptyOrderStatistics(): OrderStatistics {
		return {
			count: 0,
			average: 0,
			total: 0,
			activeClients: 0,
			orders: []
		};
	}

	mergeStatistics(statistics: OrderStatisticsList[]): OrderStatisticsList {
		const result: OrderStatisticsList = this.initializeEmptyOrderStatisticsList();
		statistics.forEach((stat: OrderStatisticsList) => {
			Object.keys(OrderStatusEnum).forEach((status: string) => {
				status = status.toLowerCase();
				Object.keys(stat[status]).forEach(entry => {
					result[status][entry] = stat[status][entry];
				});
			});
		});
		return result;
	}

	public groupListOfObjectByField(list: any[], field: string) {
		list = list === undefined ? [] : list;
		const groupedList: Object = {};
		list.forEach(element => {
			if (element.hasOwnProperty(field)) {
				if (groupedList[element[field]] === undefined) {
					groupedList[element[field]] = [];
				}
				groupedList[element[field]].push(element);
			}
		});
		return groupedList;
	}

	calculateTrend(present: number, past: number): number {
		return past === 0 ? (present === 0 ? 0 : 100) : ((present - past) / past) * 100;
	}

	public calculateAverageOnField(list: any[], field: string) {
		let valoreMedioOrdini = 0;
		const numOrdiniArchivedWeek = list === undefined ? 0 : list.length;
		if (numOrdiniArchivedWeek === 0) {
			valoreMedioOrdini = 0;
		} else {
			list.forEach(order => {
				if (order[field]) {
					valoreMedioOrdini += order[field];
				}
			});
			valoreMedioOrdini /= numOrdiniArchivedWeek;
		}
		return valoreMedioOrdini;
	}

	// FIXME Sostituito da calculateTrend()
	public calculatePercentage(baseAvg: number, currAvg: number): number {
		if (baseAvg === 0) {
			if (currAvg === 0) {
				return 0;
			}
			return 100;
		}
		return ((currAvg - baseAvg) / baseAvg) * 100;
	}

	getDayWeekLabels(dateRange: DateRange): string[] {
		const result = [];
		moment.locale(this.translateService.currentLang);
		const weekdays: string[] = moment.weekdaysShort();
		const momFrom = moment(dateRange.from);
		const momTo = moment(dateRange.to);
		for (const d = momFrom; d <= momTo; d.add(1, 'days')) {
			result.push(weekdays[d.weekday()]);
		}
		return result;
	}
}
