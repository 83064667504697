import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appMaxNumberInput]'
})
export class MaxNumberInputDirective {
	@Input('maxValue') maxValue;

	private run() {
		setTimeout(() => {
			const currentValue: number = +this.el.nativeElement.value;
			if (
				(this.maxValue || this.maxValue === 0) &&
				typeof this.maxValue === 'number' &&
				currentValue > this.maxValue
			) {
				this.el.nativeElement.value = this.maxValue;
			}
		});
	}

	constructor(private el: ElementRef) {}

	@HostListener('keypress', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		this.run();
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		this.run();
	}
}
