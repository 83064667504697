import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum, ExtraFieldOrderHeaderPouchModel, OrderStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { OrderPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as ConfigurationCustomerFaq from '../../../../../../constants/configuration-customer/faq/faq.constant';
import { StoreUtilService } from '../../../../../../service/util/store-util.service';
import { UtilOrderService } from '../../../../../../service/util/util-order.service';
import { StateFeature } from '../../../../../../state';

@Component({
	selector: 'order-detail-aside-help',
	templateUrl: './order-detail-aside-help.component.html',
	styleUrls: ['./order-detail-aside-help.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailAsideHelpComponent implements OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	configurationCustomerFaq = ConfigurationCustomerFaq;

	constructor(
		private store: Store,
		private utilStoreService: StoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
		public utilOrderService: UtilOrderService
	) {
		this.loadStaticData();
		this.subscribeManagerInit();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {this.user = res.data;});
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.subscribeOrderData().subscribe(
				{ error: (e) => { console.log('order-data: something went wrong ', e); }}
			),
			'order-data'
		);
	}

	subscribeOrderData(): Observable<void> {
		return this.order$.pipe(
			filter((e: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) => !!(e && e.data)),
			map((e: BaseStateModel<OrderStateModel>) => { this.order = e.data; })
		);
	}

}
