<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<top-bar
				*ngIf="[categoryEnum.Level.LINE, categoryEnum.Level.LEAF].includes(categoryListLevel)"
				[goBackButton]="false"
				class="mb-20"
			>
				<ng-container left-side-content>
					<button
						mat-icon-button
						*ngIf="[categoryEnum.Level.LINE, categoryEnum.Level.LEAF].includes(categoryListLevel)"
						(click)="showParentCategoryList()"
						class="back"
					>
						<mat-icon class="bordered rounded">arrow_back</mat-icon>
					</button>
				</ng-container>
			</top-bar>

			<category-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerWidgetWrapperColumnList]="
					configurationCustomerArticle.categoryColumnList(categoryListLevel)
				"
				(onItemSelect)="showChildrenCategoryList($event)"
				(onItemEdit)="openDialogEdit($event)"
				(onItemDelete)="openDialogDelete($event)"
			></category-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container pageSide></ng-container>
</page-with-sidebar>
