import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IPageChangeEvent } from '@covalent/core/paging';
import { ITdDataTableSortChangeEvent, TdDataTableComponent, TdDataTableSortingOrder } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	MediaReplayService,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BackofficeUserFilterModel } from '../../../service/pouch-db/filter/backoffice-user-filter.model';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import * as ConfigurationCustomerContactColumnMapBase from '../../../constants/configuration-customer/contact/contact-column-map/contact-column-map-base.constant';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { ContactActionEnum, ContactStateAction } from '../../../state/contact/contact.actions';
import { ContactPouchModel, ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { ContactListWrapperComponent } from '../../contact/contact-list-wrapper/contact-list-wrapper.component';
import { ContactModel } from '../../../model/contact.model';
import * as _ from 'lodash';

@Component({
	selector: 'dialog-select-contact',
	templateUrl: './dialog-select-contact.component.html',
	styleUrls: ['./dialog-select-contact.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogSelectContactComponent implements OnDestroy {
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	@ViewChild(ContactListWrapperComponent)
	contactListWrapperComponent: ContactListWrapperComponent;

	contact$: Observable<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> = this.store.select(
		StateFeature.getContact
	);
	contacts: ContactModel[];

	public listPageBaseData: BaseStateModel<ContactPouchModel[], BackofficeUserFilterModel> = {
		data: [],
		dataSetting: {
			pagination: {
				page_current: 1,
				page_size: 5
			},
			appliedFilter: {
				text: null,
				context_code: null
			},
			sort: [{ ['full_name']: 'asc' }]
		}
	};

	form: FormGroup;
	isLoading = false;
	columns = _.cloneDeep(ConfigurationCustomerContactColumnMapBase.baseColumns);

	constructor(
		private store: Store,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogSelectContactComponent>,
		public utilService: AngularCoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
		public permissionUtilService: PermissionUtilService
	) {
		this.isLoading = true;
		this.store.dispatch(ContactStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeContactList().subscribe(), 'subscribeContactList');
		// Nascondo le colonne della tabella
		const columnsToExclude = ['order_confirm_email', 'actions'];
		this.columns.map(col => {
			col.hidden = columnsToExclude.includes(col.name);
		});
	}

	ngOnDestroy() {
		this.store.dispatch(ContactStateAction.reset());
		this.subscribeManagerService.destroy();
	}

	// Subscribe
	subscribeContactList() {
		return this.contact$.pipe(
			filter((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => !!state),
			map((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => {
				this.isLoading = false;
				switch (state.type) {
					case ContactActionEnum.UPDATE:
						this.applyPermanentFilters(state.data.docs);
						break;

					case ContactActionEnum.ERROR:
						this.listPageBaseData.data = [];
						throw new Error(ContactActionEnum.ERROR);
						break;

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(contactList: ContactPouchModel[] = []) {
		// TODO - Introdurre filtri se servono
		// switch (this.user.current_context) {
		// 	case ContextApplicationItemCodeEnum.AGENT: {
		// 		break;
		// 	}
		// 	case ContextApplicationItemCodeEnum.B2B: {
		// 		break;
		// 	}
		// 	case ContextApplicationItemCodeEnum.BACKOFFICE: {
		// 		break;
		// 	}
		// }

		this.contacts = this.mapContactList(contactList) as ContactModel[];
		this.listPageBaseData.data = this.contacts;
	}

	mapContactList(list: ContactPouchModel[]): ContactPouchModel[] {
		// Mappare per avere le info corrette da deprecare con un modello dati corretto
		list.map(contact => {
			if (!contact.full_name) {
				contact.full_name = contact.first_name + ' ' + contact.last_name;
			}
			// a scopo di soluzione sorting
			contact['contact_details'] = contact.email;
		});

		return list.filter(contact => contact.valid);
	}

	// widget & utility
	selectContact(contact: ContactPouchModel) {
		this.dialogRef.close(contact);
	}

	changePageSize(size: number) {
		if (size) {
			this.listPageBaseData.dataSetting.pagination.page_size = size;
			this.applyPermanentFilters();
		}
	}

	paginationChange(pagination: IPageChangeEvent) {
		if (pagination) {
			this.listPageBaseData.dataSetting.pagination.page_current = pagination.page;
			this.applyPermanentFilters();
		}
	}

	sortChange(e: ITdDataTableSortChangeEvent) {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				[e.name]: e.order === TdDataTableSortingOrder.Ascending ? 'asc' : 'desc'
			}
		];
		this.listPageBaseData.dataSetting.sort = sort;
		this.applyPermanentFilters();
	}
}
