import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { StatisticGroupStateAction, StatisticGroupActionEnum } from './statistic-group.action';
import * as StatisticGroupModel from '../../../model/statistics-group.model';


export namespace StatisticGroupStateReducer {
	export const initialState: BaseStateModel<StatisticGroupModel.Document[]> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticGroupStateAction.loadAll, state => state),
		on(StatisticGroupStateAction.update, (state, param) => param),
		on(StatisticGroupStateAction.reset, () => initialState),
		on(StatisticGroupStateAction.error, (state, param) => ({
			data: state ? state.data : null,
			type: param.type
		}))
	);

	export function reducer(
		state: BaseStateModel<StatisticGroupModel.Document[]>,
		action: TypedAction<StatisticGroupActionEnum>
	) {
		return _reducer(state, action);
	}
}
