import { ITdDataTableColumn } from '@covalent/core/data-table';
import _ from 'lodash';
import { ColumnListOrder } from '@saep-ict/angular-spin8-core';

// Copio l'array originale perché il cloneDeep non salva le manipolazioni effettuate sul nuovo array
const newColumnList = [...ColumnListOrder.base];
newColumnList.splice(2, 1, 
    {
		name: 'header.organization.business_name',
		labelPath: 'theme.organization.field.name',
		label: null,
		width: { min: 100 }
	},
    {
        name: 'header.date',
        labelPath: 'theme.order.field.date',
        label: '',
        width: 100
    },
	{
		name: 'header.first_evasion_date',
		labelPath: 'theme.order.field.delivery_date_short',
		label: null,
		width: { min: 100 }
	 },
	{
		name: 'header.price.article',
		labelPath: 'order.field.amount',
		label: '',
		width: 120,
		sortable: false,
		numeric: true
	}
	 
);

export const projectOverride: ITdDataTableColumn[] = _.cloneDeep(newColumnList);

