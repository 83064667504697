import { Category, OrderStatusEnum, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { ITdDataTableColumnCustom, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import {
	ContextApplicationItemCodeEnum,
	ConfigurationUtil,
    OrderEnum,
    UserDetailModel,
    ROUTE_URL,
    OrderStateModel
} from '@saep-ict/angular-spin8-core';
import {
	ContextPrivilegeMap,
    ConfigurationCustomerVat,
    ConfigurationCustomerOrganizationTypeSelector
} from '../../../model/structure/configuration-customer.model';
import {
	StateRelatedLinkBackoffice,
	StateRelatedLinkAgent,
	StateRelatedLinkB2b,
	StateRelatedLinkB2c,
	StateRelatedLink
} from './status-aggregation-map.constant';
import { StatusBarConfigNewBis } from '../../../model/status-bar-new.model';
import * as StatusBar from './status-aggregation-map-status-bar.constant';
import * as columnListOrderListBackoffice from './order-list-column-map/order-list-column-map-backoffice.constant';
import * as columnListOrderListAgent from './order-list-column-map/order-list-column-map-agent.constant';
import * as columnListOrderListB2B from './order-list-column-map/order-list-column-map-b2b-constant';
import * as columnListOrderListB2C from './order-list-column-map/order-list-column-map-b2c.constant';
import * as columnListOrderListPortal from './order-list-column-map/order-list-column-map-portal.constant';
import * as columnListOrderListCrm from './order-list-column-map/order-list-column-map-crm.constant';
import {
    OrderListFilterCreationFieldMapFormValue,
    OrderListFilterCreationFieldMapSelectOptionMap
} from '../../../model/order-util.model';
import {
	returnConfigurationCustomerOrderListFilterByFormValueBackoffice,
	returnConfigurationCustomerOrderListFilterCreationFieldMapBackoffice
} from './order-list-filter/order-list-filter-form-dynamic/order-list-filter-form-dynamic-backoffice.constant';
import {
	returnConfigurationCustomerOrderListFilterByFormValueB2b,
	returnConfigurationCustomerOrderListFilterCreationFieldMapB2b
} from './order-list-filter/order-list-filter-form-dynamic/order-list-filter-form-dynamic-b2b.constant';
import { TableOrderModel } from '../../../model/table/table-order.model';
import {
	returnConfigurationCustomerOrderListFilterByFormValueAgent,
	returnConfigurationCustomerOrderListFilterCreationFieldMapAgent
} from './order-list-filter/order-list-filter-form-dynamic/order-list-filter-form-dynamic-agent.constant';
import { CustomerConfigurationOrderHeaderAsideSummaryB2bFieldList } from './order-header-creation-field-map/order-header-creation-field-map-aside-summary/order-header-creation-field-map-aside-summary-b2b.constant';
import { CustomerConfigurationOrderHeaderAsideSummaryAgentFieldList } from './order-header-creation-field-map/order-header-creation-field-map-aside-summary/order-header-creation-field-map-aside-summary-agent.constant';
import * as  CategoryModel from '../../../model/category-list.model';
import { orderCategorySectionList } from './order-category-section-list.constants';
import * as _ from 'lodash';
import { OrderDetailAsideSummaryComponent } from '../../../page/commons/order/order-detail/aside/order-detail-aside-summary/order-detail-aside-summary.component';
import { OrderDetailAsideHelpComponent } from '../../../page/commons/order/order-detail/aside/order-detail-aside-help/order-detail-aside-help.component';
import moment from 'moment';
import * as CategoryEnum from '../../../enum/category.enum';
import * as OrderCommonCatalogEnum from '../../../enum/order-common-catalog.enum';
import * as OrderCommonCatalog from './order-common-catalog.constant';
import { VirtualScrollConfiguration } from '../../../model/virtual-scroll.model';
import { configurationCustomerOrderHeaderFieldList } from './order-header-creation-field-map/order-header-creation-field-map-b2b.constant';
import { configurationCustomerOrderHeaderFieldListBackOffice } from './order-header-creation-field-map/order-header-creation-field-map-backoffice.constant';
import * as OrderHeaderCreationFieldMapAgent from './order-header-creation-field-map/order-header-creation-field-map-agent.constant';
import * as ConfigurationCustomerOrderDate from './order-date.constant';

export const orderToSendStatus: OrderStatusEnum = OrderStatusEnum.READY_TO_SEND;
export const canEditByStatus: ConfigurationUtil.Context.ApplicationSelector<OrderStatusEnum[]> = {
    B2B: [OrderStatusEnum.DRAFT],
    AGENT: [OrderStatusEnum.DRAFT],
    B2C: [OrderStatusEnum.DRAFT],
    BACKOFFICE: [OrderStatusEnum.TO_AUTHORIZE],
    BACKOFFICE_ADMIN: [OrderStatusEnum.TO_AUTHORIZE],
    CRM: [OrderStatusEnum.DRAFT],
    PORTAL: [OrderStatusEnum.DRAFT],
    PUBLIC: [OrderStatusEnum.DRAFT]
}
export const canUpdateToStatus: ConfigurationUtil.Context.ApplicationSelector<OrderStatusEnum[]> = {
    B2B: [orderToSendStatus],
    AGENT: [orderToSendStatus],
    B2C: [orderToSendStatus],
    BACKOFFICE: [OrderStatusEnum.READY_TO_SEND],
    BACKOFFICE_ADMIN: [OrderStatusEnum.READY_TO_SEND],
    CRM: [orderToSendStatus],
    PORTAL: [orderToSendStatus],
    PUBLIC: [orderToSendStatus]
}
export const attachment: ContextPrivilegeMap = {
    canEdit: {
        AGENT: true,
        B2B: true,
        B2C: true,
        BACKOFFICE_ADMIN: true,
        BACKOFFICE: true,
        PORTAL: true,
        CRM: true,
        PUBLIC: true
    },
    canCreate: {
        AGENT: true,
        B2B: true,
        B2C: true,
        BACKOFFICE_ADMIN: true,
        BACKOFFICE: true,
        PORTAL: true,
        CRM: true,
        PUBLIC: true
    },
    canDownload: {
        AGENT: true,
        B2B: true,
        B2C: true,
        BACKOFFICE_ADMIN: true,
        BACKOFFICE: true,
        PORTAL: true,
        CRM: true,
        PUBLIC: true
    }
};
export const canCreate = (
    contextApplication: ContextApplicationItemCodeEnum,
    organization: OrganizationPouchModel
): boolean => {
    switch (contextApplication) {
        case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
            return organization ? true : false;
        case ContextApplicationItemCodeEnum.BACKOFFICE:
            return false;
        case ContextApplicationItemCodeEnum.B2B:
            return true;
        case ContextApplicationItemCodeEnum.AGENT:
            return organization ? true : false;
        case ContextApplicationItemCodeEnum.B2C:
            return false;
        case ContextApplicationItemCodeEnum.CRM:
            return false;
        default:
            return false;
    }
};
export const statusAggregationMap = <ConfigurationUtil.Context.ApplicationSelector<StateRelatedLink[]>>{
    BACKOFFICE: StateRelatedLinkBackoffice,
    AGENT: StateRelatedLinkAgent,
    B2B: StateRelatedLinkB2b,
    B2C: StateRelatedLinkB2c
};
export const statusBarMap: ConfigurationUtil.Context.ApplicationSelector<StatusBarConfigNewBis> = {
    AGENT: StatusBar.agent,
    B2B: StatusBar.b2b,
    BACKOFFICE: StatusBar.backoffice
};
export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumnCustom[] => {
    switch (contextApplication) {
        case ContextApplicationItemCodeEnum.BACKOFFICE:
            return columnListOrderListBackoffice.projectOverride;
        case ContextApplicationItemCodeEnum.AGENT:
            return columnListOrderListAgent.projectOverride;
        case ContextApplicationItemCodeEnum.B2B:
            return columnListOrderListB2B.projectOverride;
        case ContextApplicationItemCodeEnum.PORTAL:
            return columnListOrderListPortal.projectOverride;
        case ContextApplicationItemCodeEnum.B2C:
            return columnListOrderListB2C.projectOverride;
        case ContextApplicationItemCodeEnum.CRM:
            return columnListOrderListCrm.projectOverride;
    }
}
export const listFilterCreationFieldMap = async (
    contextApplication: ContextApplicationItemCodeEnum,
    selectOptionMap: OrderListFilterCreationFieldMapSelectOptionMap,
    orderStatus?: boolean
): Promise<FormControlMultipurposeModel.Item[]> => {
    try {
        switch (contextApplication) {
            case ContextApplicationItemCodeEnum.BACKOFFICE:
            case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
                return await returnConfigurationCustomerOrderListFilterCreationFieldMapBackoffice(
                    selectOptionMap,
                    orderStatus
                );
            case ContextApplicationItemCodeEnum.B2B:
                return await returnConfigurationCustomerOrderListFilterCreationFieldMapB2b(
                    selectOptionMap,
                    orderStatus
                );
            case ContextApplicationItemCodeEnum.AGENT:
                return await returnConfigurationCustomerOrderListFilterCreationFieldMapAgent(
                    selectOptionMap,
                    orderStatus
                );
            default:
                // TODO: determinare quale sia la mappa di default. Questo schema è ereditato da
                // filterOrderVisible
                return await returnConfigurationCustomerOrderListFilterCreationFieldMapAgent(
                    selectOptionMap,
                    orderStatus
                );
            }
    } catch(e) {
        throw new Error(e);
    }
}
export const returnListFilterByFormValue = async (
    contextApplication: ContextApplicationItemCodeEnum,
    orderList: TableOrderModel[],
    formValue: OrderListFilterCreationFieldMapFormValue
): Promise<TableOrderModel[]> => {
    try {
        switch (contextApplication) {
            case ContextApplicationItemCodeEnum.BACKOFFICE:
            case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
                return await returnConfigurationCustomerOrderListFilterByFormValueBackoffice(
                        orderList,
                        formValue
                );
            case ContextApplicationItemCodeEnum.B2B:
                return await returnConfigurationCustomerOrderListFilterByFormValueB2b(
                        orderList,
                        formValue
                );
            case ContextApplicationItemCodeEnum.AGENT:
                return await returnConfigurationCustomerOrderListFilterByFormValueAgent(
                        orderList,
                        formValue
                    );
            default:
                // TODO: modificare in accordo con la todo di listFilterCreationFieldMap
                return await returnConfigurationCustomerOrderListFilterByFormValueAgent(
                        orderList,
                        formValue
                    );
        }
    } catch(e) {
        throw new Error(e);
    }
}
export const vat: ConfigurationCustomerVat<number> = {
    ARTICLE: {
        AGENT: 22,
        B2B: 22,
        B2C: 22,
        BACKOFFICE_ADMIN: 22,
        BACKOFFICE: 22,
        PORTAL: 22,
        CRM: 22,
        PUBLIC: 22
    },
    SHIPPING: {
        AGENT: 22,
        B2B: 22,
        B2C: 22,
        BACKOFFICE_ADMIN: 22,
        BACKOFFICE: 22,
        PORTAL: 22,
        CRM: 22,
        PUBLIC: 22
    }
};
export const paymentMethodIcon = {
    paymentMethodIcon_501: '../../../../assets/common/img/metodi-pagamento/cc-mastercard.svg',
    paymentMethodIcon_500: '../../../../assets/common/img/metodi-pagamento/cc-paypal.svg'
};
export const common_checkout_article_structure: OrderEnum.CommonArticleStructure =
    OrderEnum.CommonArticleStructure.CATEGORY_TREE;
export const qtyBoxMultipleCheck: ConfigurationCustomerOrganizationTypeSelector<boolean> = {
    PRIVATE: true,
    COMPANY: true,
    PUBLIC: true
};
export const causalCode: ConfigurationUtil.Context.ApplicationSelector<string> = {
    AGENT: 'NI',
    B2B: 'NE',
    BACKOFFICE_ADMIN: 'NI',
    BACKOFFICE: 'NI',
    PORTAL: 'NI',
    CRM: 'NI'
};
export const headerAsideSummaryFieldList:
ConfigurationUtil.Context.ApplicationSelector<FormControlMultipurposeModel.Item[]> = {
    B2B: CustomerConfigurationOrderHeaderAsideSummaryB2bFieldList,
    AGENT: CustomerConfigurationOrderHeaderAsideSummaryAgentFieldList,
    BACKOFFICE: CustomerConfigurationOrderHeaderAsideSummaryB2bFieldList
};
export const returnNestedCategoryParseAsideSummary = (user: UserDetailModel, e: Category[]): Promise<Category[]> => {
    return new Promise(resolve => {
        try {
            let categoryReturn = _.cloneDeep(e);
            switch (user.current_permission.context_application) {
                default: {
                    // assume che le categorie siano strutturate come segue, componendo una lista di categorie di
                    // secondo livello
                    // order-form
                    //   brand 1
                    //   brand 2
                    // set
                    //   set 1
                    //   set 2
                    const loop = 2;
                    let categoryReturnParse: Category[] = [];
                    for (let i = 0; i < loop; i++) {
                        for (let n = 0; n < categoryReturn[i].category_list.length; n++) {
                            delete categoryReturn[i].category_list[n].category_list;
                        }
                        categoryReturnParse = categoryReturnParse.concat(categoryReturn[i].category_list);
                    }
                    categoryReturn = categoryReturnParse;
                    break;
                }
            }
            resolve(categoryReturn);
        } catch (err) {
            throw new Error(err);
        }
    });
};
export const categorySectionList: CategoryModel.SectionItem[] = orderCategorySectionList;
export const noteOrderVisibility: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: false,
    B2B: false,
    B2C: false,
    PORTAL: false
};
export const headerAndRowsOrderClone: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: false,
    B2C: false,
    PORTAL: false
}
export const detailAsideComponent =
    new Map<string, any>([
        [ ROUTE_URL.checkout, OrderDetailAsideSummaryComponent ],
        [ ROUTE_URL.catalog, OrderDetailAsideSummaryComponent ],
        // TODO: trasferire insieme agli altri segmenti
        [ "header-edit", OrderDetailAsideHelpComponent ]
    ]);
export const returnLastUrlSegment = (url: string): string => {
    const a: string[] = url.split("/");
    const s: string = a[a.length - 1];
    return s;
}
export const listFilter = (
    orderList: OrderStateModel[],
    contextApplication: ContextApplicationItemCodeEnum,
    organization: OrganizationPouchModel
): OrderStateModel[] => {
switch (contextApplication) {
    case ContextApplicationItemCodeEnum.BACKOFFICE:
        return organization
            ? orderList.filter(
                    order =>
                        order.header?.organization?.code_item === organization.code_item &&
                        //order.header.organization.code_item === organization.code_item &&
                        order.header.status !== OrderStatusEnum.DRAFT
              )
            : orderList.filter(order => order.header.status !== OrderStatusEnum.DRAFT);
    case ContextApplicationItemCodeEnum.B2B:
        return orderList;
    case ContextApplicationItemCodeEnum.B2C:
        return orderList.filter(order => order.header.status !== OrderStatusEnum.DRAFT);
    default:
        return organization
            ? orderList.filter(order => order.header?.organization?.code_item === organization.code_item)
            : orderList;
}
};
export const ShippingChargeFilterList: string[] = ['carrier'];
export const today: Date = new Date()

export const getFirstValidDate = (currentDate: Date, day: number): Date => {
    const currentDay = moment(currentDate).day();
    if ([0, 6].includes(currentDay)) {
        const oneDayAfter = moment(currentDate).add(day, 'day').toDate();
        return getFirstValidDate(oneDayAfter, day);
    } else {
        return currentDate;
    }
}
/**
 * Restituisce la prima data disponibile in base ai parametri immessi:
 * @param dayMin : numero minimo di giornate non selezionabili a partire dala data corrente
 * @param excludeDayList : giorni della settimana da ecludere (0 -> Dom, 1 -> Lun, ecc). Il metodo aggiunge 1 giorno
 * ogni volta che capita su una data esclusa per via di excludeDayList
 * @returns
 */
export const returnFirstValidDate = (dayMin: number, excludeDayList?: number[]): Date => {
    const dateMin = moment(today).add(dayMin, 'day');
    const dateMinDayNumber = dateMin.day();
    if (excludeDayList && excludeDayList.includes(dateMinDayNumber)) {
        return returnFirstValidDate(dayMin + 1, excludeDayList);
    }
    return dateMin.toDate();
}
export const filterByOrderStatus = (
    orderList: OrderStateModel[],
    statusList: OrderStatusEnum[]
): Promise<OrderStateModel[]> => {
    return new Promise(resolve => {
        try {
            orderList = orderList.filter(order => {
                if (order.header.status) {
                    return statusList.includes(order.header.status);
                } else {
                    return false;
                }
            });
            resolve(orderList);
        } catch(err) {
            throw new Error(err);
        }
    });
}
export const minDateSelectable: ConfigurationUtil.Context.ApplicationSelector<Date> = {
    B2B:
        returnFirstValidDate(
            ConfigurationCustomerOrderDate.firstEvasionDateMinDate,
            [6, 0]
        ),
    AGENT:
        returnFirstValidDate(
            1,
            [6, 0]
        ),
    B2C:
        returnFirstValidDate(
            1,
            [6, 0]
        ),
};
// TOFIX: capire come gestire questa costante
export const orderBackoffice = [
	{
		status: OrderStatusEnum.TO_AUTHORIZE,
		can_edit: true
	}
];
export const configurationLastOrderslistFilter = (
    orderList: OrderStateModel[],
    contextApplication: ContextApplicationItemCodeEnum
): OrderStateModel[] => {
    switch (contextApplication) {
        default:
            // exclude status: DRAFT, FULFILLED
            return orderList.filter(
                order =>
                    !([OrderStatusEnum.DRAFT, OrderStatusEnum.FULFILLED] as OrderStatusEnum[]).includes(
                        order.header.status
                    )
            );
    }
};
export const dashboardOrderTrendBoxStatusList: ConfigurationUtil.Context.ApplicationSelector<StateRelatedLink[]> = {
     // 'DELETED' // non gestibile
    AGENT: [
        {
            state: OrderStatusEnum.DRAFT,
            label: 'theme.order.summary.status_title_override.DRAFT',
            subtitle_label: 'theme.order.summary.status_subtitle_override.DRAFT',
            related_list: [ OrderStatusEnum.DRAFT, OrderStatusEnum.ERROR_PREPARING, OrderStatusEnum.ERROR_SENDING]
        },	
        {
            state: OrderStatusEnum.PROCESSING,
            label: 'theme.order.summary.status_title_override.PROCESSING',
            subtitle_label: 'theme.order.summary.status_subtitle_override.PROCESSING',
            related_list: [ OrderStatusEnum.QUEUE, OrderStatusEnum.READY_TO_SEND, OrderStatusEnum.SENDING, OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
        },	
        {
            state: OrderStatusEnum.FULFILLED,
            label: 'theme.order.summary.status_title_override.FULFILLED',
            subtitle_label: 'theme.order.summary.status_subtitle_override.FULFILLED',
            related_list: [ OrderStatusEnum.FULFILLED ]
        }
    ],     
    B2B: [
        {
            state: OrderStatusEnum.DRAFT,
            label: 'theme.order.summary.status_title_override.DRAFT',
            subtitle_label: 'theme.order.summary.status_subtitle_override.DRAFT',
            related_list: [ OrderStatusEnum.DRAFT ]
        },	
        {
            state: OrderStatusEnum.PROCESSING,
            label: 'theme.order.summary.status_title_override.PROCESSING',
            subtitle_label: 'theme.order.summary.status_subtitle_override.PROCESSING',
            related_list: [ OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED ]
        },	
        {
            state: OrderStatusEnum.FULFILLED,
            label: 'theme.order.summary.status_title_override.FULFILLED',
            subtitle_label: 'theme.order.summary.status_subtitle_override.FULFILLED',
            related_list: [ OrderStatusEnum.FULFILLED ]
        }
    ],     
    BACKOFFICE: [
        {
            state: OrderStatusEnum.PROCESSING,
            label: 'theme.order.summary.status_title_override.PROCESSING',
            subtitle_label: 'theme.order.summary.status_subtitle_override.PROCESSING',
            related_list: [ OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED ]
        },	
        {
            state: OrderStatusEnum.FULFILLED,
            label: 'theme.order.summary.status_title_override.FULFILLED',
            subtitle_label: 'theme.order.summary.status_subtitle_override.FULFILLED',
            related_list: [ OrderStatusEnum.FULFILLED ]
        },	
    ],
    BACKOFFICE_ADMIN: [
        {
            state: OrderStatusEnum.PROCESSING,
            label: 'theme.order.summary.status_title_override.PROCESSING',
            subtitle_label: 'theme.order.summary.status_subtitle_override.PROCESSING',
            related_list: [ OrderStatusEnum.PROCESSING, OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED ]
        },	
        {
            state: OrderStatusEnum.FULFILLED,
            label: 'theme.order.summary.status_title_override.FULFILLED',
            subtitle_label: 'theme.order.summary.status_subtitle_override.FULFILLED',
            related_list: [ OrderStatusEnum.FULFILLED ]
        },	
    ]
};
export const commonCatalogArticleStructure: OrderEnum.CommonArticleStructure =
    OrderEnum.CommonArticleStructure.ACCORDION
    // OrderEnum.CommonArticleStructure.PLAIN_LIST;
    // OrderEnum.CommonArticleStructure.CATEGORY_TREE
export const commonCatalogArticleStructureAccordionLevel: CategoryEnum.Level = CategoryEnum.Level.BRAND;
export const commonCatalogArticleStructureAccordionMultipleOpen = false;
export const commonCatalogCategoryStructure: OrderCommonCatalogEnum.CategoryStructure =
    OrderCommonCatalogEnum.CategoryStructure.TAB_ON_TOP;
export const commonCatalogCategoryTabOnTopConfiguration: CategoryModel.Configuration =
    OrderCommonCatalog.categoryTabOnTopConfiguration;
export const commonCatalogReturnNestedCategoryParse = (user: UserDetailModel, e: Category[]): Promise<Category[]> => {
    return new Promise(resolve => {
        try {
            const categoryReturn = _.cloneDeep(e);
            switch (user.current_permission.context_application) {
                default:
                    for (const category of categoryReturn) {
                        if (category.category_list && category.category_list.length > 0) {
                            if (category.key == 1) {
                                for (const categoryChild of category.category_list) {
                                    delete categoryChild.category_list;
                                }
                            }
                            else {
                                delete category.category_list;
                            }
                                
                        }
                    }
                    break;
            }
            resolve(categoryReturn);
        } catch (err) {
            throw new Error(err);
        }
    });
};
export const commonCatalogVirtualScrollConfiguration: VirtualScrollConfiguration = {
    enabled: true,
    itemHeight: 66.86
}
export const additionalServiceStockType =  {
    COMPANY: false,
    PRIVATE: false
}
export const additionalServiceDeliveryDate =  {
    COMPANY: true,
    PRIVATE: false
}
export const creationFieldMapHeader = (
    user: UserDetailModel
): FormControlMultipurposeModel.Item[] => {
    switch (user.current_permission.context_application) {
        case ContextApplicationItemCodeEnum.B2B:
            return configurationCustomerOrderHeaderFieldList;
        case ContextApplicationItemCodeEnum.BACKOFFICE:
            return configurationCustomerOrderHeaderFieldListBackOffice;
        case ContextApplicationItemCodeEnum.AGENT:
            return OrderHeaderCreationFieldMapAgent.OrderHeader();
        default:
            break;
    }
};
export const orderSavedPopupVisible: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: false,
    B2C: true,
    PORTAL: true
};
export const showOrderTotalWithVat: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    BACKOFFICE: false,
    B2B: false,
    B2C: true,
    PORTAL: true
};