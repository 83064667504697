<page-with-sidebar [isRoot]="true">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight">
			<category-tab-list
				[list]="categoryListParseVisualization"
				[configuration]="configurationCustomerForecast.categoryTabOnTopConfiguration"
				(categorySelectedChange)="categorySelectedChange($event)"
			></category-tab-list>
			<article-list-structure-backoffice-forecast-wrapper-accordion
				*ngIf="listPageBaseData"
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerTitle]="'article.list' | translate | sentencecase"
				[configuration]="{
					multipleOpen: configurationCustomerForecast.articleStructureAccordionMultipleOpen,
					forecastInstance: true
				}"
			>
			</article-list-structure-backoffice-forecast-wrapper-accordion>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'Filters'">
			<ng-container main-content>
				<form [formGroup]="form" class="michelangelo-theme">
					<mat-radio-group formControlName="dateSelector">
						<mat-radio-button  color="primary" *ngFor="let item of formDateSelectorList" [value]="item">
							{{ item }}
						</mat-radio-button>
					</mat-radio-group>
					<mat-form-field>
						<mat-label>{{ 'general.area_commercial' | translate | sentencecase }}</mat-label>
						<mat-select multiple formControlName="areaCommercial">
							<mat-form-field class="filter-input">
								<mat-label>{{ 'general.filter_by' | translate | sentencecase }}</mat-label>
								<input matInput type="text" #inputSearchAreaCommercial autocomplete="off" />
								<button
									mat-button
									*ngIf="inputSearchAreaCommercial.value"
									matSuffix
									mat-icon-button
									aria-label="Clear"
									(click)="inputSearchAreaCommercial.value = ''"
								>
									<mat-icon>close</mat-icon>
								</button>
								<mat-icon matSuffix>search</mat-icon>
							</mat-form-field>
							<mat-option
								*ngFor="
									let item of auxiliaryTableAreaCommercial
										| filterByString
											: {
													propertyListName: ['code_item', 'description', 'description_short'],
													value: inputSearchAreaCommercial.value
											  }
								"
								[value]="item.code_item"
							>
								{{ item.code_item }} -
								{{ item.description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field>
						<mat-label>{{ 'organization.list' | translate | sentencecase }}</mat-label>
						<mat-select multiple formControlName="organizationList">
							<mat-form-field class="filter-input">
								<mat-label>{{ 'general.filter_by' | translate | sentencecase }}</mat-label>
								<input matInput type="text" #inputSearchClientCode autocomplete="off" />
								<button
									mat-button
									*ngIf="inputSearchClientCode.value"
									matSuffix
									mat-icon-button
									aria-label="Clear"
									(click)="inputSearchClientCode.value = ''"
								>
									<mat-icon>close</mat-icon>
								</button>
								<mat-icon matSuffix>search</mat-icon>
							</mat-form-field>
							<mat-option
								*ngFor="
									let organization of organizationListFiltered
										| filterByString
											: {
													propertyListName: [
														'business_name',
														appStructure.erp_has_erp
															? 'code_erp'
															: 'code_item'
													],
													value: inputSearchClientCode.value
											  }
								"
								[value]="organization.code_item"
							>
								{{
									appStructure.erp_has_erp
										? organization.code_erp
										: (organization.code_item | guidFormatter)
								}}
								-
								{{ organization.business_name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <button mat-button (click)="formReset()">
						{{ 'general.reset' | translate | sentencecase }}
					</button> -->

					<form-button-bar
						class=""
						[align]="'space-between'"
						[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
						(secondaryButtonClickEmitter)="formReset()"
					></form-button-bar>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
