import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { BaseStateModel } from '@saep-ict/angular-core';
import { UtilGuardService } from '../../../../service/guard/util-guard/util-guard.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../state';
import { LoginContextCodeStateAction } from '../../../../state/common/login/context-code/login-context-code.actions';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { LinkCodeModel, LinkDetailModel, ROUTE_URL, UserDetailModel, UserTypeContextModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'context-selection',
	templateUrl: './context-selection.component.html',
	styleUrls: ['./context-selection.component.scss'],
	providers: [SubscribeManagerService]
})
export class ClientCodeSelectComponent implements OnInit {
	form: FormGroup;
	@LocalStorage('link_code') link_code: LinkCodeModel;

	codeList: LinkDetailModel[] = [];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	contextTypeList: UserTypeContextModel[];
	multipleContext = false;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private store: Store<any>,
		private utilGuardService: UtilGuardService,
		private subscribeManagerService: SubscribeManagerService,
		public utilService: AppUtilService
	) {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.retrieveAmbiguosRoute();
		this.createForm();
	}

	ngOnInit() {}

	retrieveAmbiguosRoute() {
		this.contextTypeList = this.utilGuardService.retrievePermissionRoute(this.user);
		if (this.contextTypeList.length > 1) {
			this.multipleContext = true;
		}
	}

	createForm() {
		this.form = this.fb.group({
			context: ['', Validators.required],
			linkCode: ['', Validators.required]
		});
		this.changeForm();
	}

	changeForm() {
		if (!this.multipleContext) {
			const context = this.utilGuardService.checkUserContext(this.user, this.contextTypeList[0].type);
			if (context.hasContext) {
				this.form.controls['context'].setValue(this.contextTypeList[0]);
				this.codeList = context.currentContext.context_code_list;
			}
		}
		this.subscribeManagerService.populate(
			this.form.controls['context'].valueChanges.subscribe((permission: UserTypeContextModel) => {
				const context = this.utilGuardService.checkUserContext(this.user, permission.type);
				if (context.hasContext) {
					this.codeList = context.currentContext.context_code_list;
				}
				this.form.controls['linkCode'].setValue('');
			}),
			'context'
		);
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		return { code: formModel.linkCode, context: formModel.context.type };
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.link_code = this.prepareSaveForm();
			this.store.dispatch(LoginContextCodeStateAction.reset());
			this.router.navigate([`/${ROUTE_URL.private}`]);
		}
	}
}
