import { BodyTablePouchModel } from './base-pouch.model';
import { PriceEnum } from './price-pouch.model';
import { TableListEnum } from './enum/table-list-pouch.enum';

export interface UmPouchModel extends BodyTablePouchModel {
	significant_decimals?: string;
}

export interface CountryIsoPouchModel extends BodyTablePouchModel {
	cee?: string;
}

export interface CodeVatPouchModel extends BodyTablePouchModel {
	tax_rate?: string;
}

export interface ArticleListTypePouchModel extends BodyTablePouchModel {
	date_begin_val_cur?: string;
}

export interface CausalHeaderSoPouchModel extends BodyTablePouchModel {
	pairing_cdv?: string;
	causals_row?: string[];
	description_causal_shipping?: string;
	first_delivery?: string;
}

export interface CausalRowSoPouchModel extends BodyTablePouchModel {
	type_format: string;
	type_row?: string;
	discount_row_perc?: string[];
	price: string;
	code_vat?: string;
	flag_code_pairing?: string;
	application_discounts: string;
	print_only_value: string;
	row_subjected_charges_perc: string;
	warehouse_shipping: string;
}

export interface PaymentPouchModel extends BodyTablePouchModel {
	flag_end_month?: string;
	num_days_first_deadline?: string;
	number_rate?: number;
	discount_in_billing?: string;
	type_payment?: string;
	send_provisional_lot?: string;
}

export interface LocationDepartureGoodsPouchModel extends BodyTablePouchModel {
	sales_warehouse: string;
}

export interface LocationDepartureDivisionPouchModel extends BodyTablePouchModel {
	division: string;
}

export interface CommercialAreaPouchModel extends BodyTablePouchModel {
	threshold?: ThresholdPouchModel[];
	delivery_days?: number;
	children?: CommercialAreaPouchModelChild[];
	commercial_area: string;
}

export interface CommercialAreaPouchModelChild extends CommercialAreaPouchModel {
	cap?: string[];
}

export interface AdditionalService extends BodyTablePouchModel {
	um?: string;
	charge: number;
	value?: number;
	ordered_quantity?: number;
	charge_type?: PriceEnum.ValueType;
}

export interface ThresholdPouchModel {
	value: number;
	type: TableListEnum.Threshold.Type;
	charge?: number;
}
