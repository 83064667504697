import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseStateModel, RestBaseMessageError, AngularCoreUtilService } from '@saep-ict/angular-core';
import { PouchUtilService } from '@saep-ict/pouch-db';
import { TicketCenterTicketDetailStoreAction, TicketCenterTicketStoreAction } from '@saep-ict/ticket-center';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import { StateFeature } from '../../state';
import { ArticleDescriptionStateAction } from '../../state/article-description/article-description.actions';
import { ArticleStateAction } from '../../state/article/article.actions';
import { AuxiliaryTableStateAction } from '../../state/auxiliary-table/auxiliary-table.actions';
import { ContextCodeAssociationStateAction } from '../../state/backoffice/context-code/context-code-association/context-code-association.actions';
import { ContextCodeManagementStateAction } from '../../state/backoffice/context-code/context-code-management/context-code-management.actions';
import { UserManagementStateAction } from '../../state/backoffice/user-management/user-management.actions';
import { CategoryListAction } from '../../state/category-list/category-list.actions';
import { LoginContextCodeStateAction } from '../../state/common/login/context-code/login-context-code.actions';
import { OrganizationListStateAction } from '../../state/common/organization-list/organization-list.actions';
import { CompanyAccountBalanceAction } from '../../state/company-account-balance/company-account-balance.actions';
import { ContactStateAction } from '../../state/contact/contact.actions';
import { ConfigurationStateAction } from '../../state/configuration/configuration.actions';
import { InformativePageStateAction } from '../../state/informative-page/informative-page.actions';
import { KanbanStateAction } from '../../state/kanban/kanban.actions';
import { LanguageListStateAction } from '../../state/language-list/language-list.actions';
// import { OfferStateAction } from '../../state/offer/offer.actions';
import { OpportunityStateAction } from '../../state/opportunity/opportunity.actions';
import { OrderListStateAction } from '../../state/order-list/order-list.actions';
import { OrderStateAction } from '../../state/order/order.actions';
import { OrganizationStateAction } from '../../state/organization/organization.actions';
import { PermissionAuxiliaryTableStateAction } from '../../state/permission-auxiliary-table/permission-auxiliary-table.actions';
import { ReceiptListStateAction } from '../../state/receipt-list/receipt-list.actions';
import { SearchResultsStateAction } from '../../state/search-results/search-results.actions';
import { StatisticsAgentStateAction } from '../../state/statistics-agent/statistics-agent.action';
import { StatisticsBackofficeStateAction } from '../../state/statistics-backoffice/statistics-backoffice.action';
import { StatisticsCrmStateAction } from '../../state/statistics-crm/statistics-crm.action';
import { StatisticsDetailClientsStateAction } from '../../state/statistics-detail-clients/statistics-detail-clients.action';
import { StatisticsDetailExpiredStateAction } from '../../state/statistics-detail-expired/statistics-detail-expired.action';
import { StatisticsDetailOrdersStateAction } from '../../state/statistics-detail-orders/statistics-detail-orders.action';
import { StatisticsDetailSoldStateAction } from '../../state/statistics-detail-sold/statistics-detail-sold.action';
import { StatisticsOrdersStateAction } from '../../state/statistics-orders/statistics-orders.action';
import { StatisticsOrganizationStateAction } from '../../state/statistics-organization/statistics-organization.action';
import { UserActivateStateAction } from '../../state/user-activate/user-activate.actions';
import { UserStateAction } from '../../state/user/user.actions';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import {
	AngularSpin8CoreUserService,
	ContextApplicationItemCodeEnum,
	LinkCodeModel,
	LoginAuthRequestModel,
	LoginAuthResponseModel,
	PermissionAuxiliaryTableStateModel,
	ROUTE_URL,
	TokenPayload,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../util/app-util.service';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { FamilyListStateAction } from '../../state/family-list/family-list.actions';

@Injectable()
export class AuthService {
	@LocalStorage('authenticationToken') localStorage_authenticationToken: string;
	@LocalStorage('payload') private localStorage_tokenPayload: TokenPayload;
	@LocalStorage('link_code') private localStorage_link_code: LinkCodeModel;
	@LocalStorage('user') private localStorage_user: LinkCodeModel;
	@LocalStorage('permissions') localStorage_permissions: PermissionAuxiliaryTableStateModel;
	@LocalStorage('current_order_id') localStorage_current_order_id: string;

	private tokenPayloadChange: BehaviorSubject<TokenPayload> = new BehaviorSubject<TokenPayload>(undefined);
	tokenPayloadChange$: Observable<TokenPayload> = this.tokenPayloadChange.asObservable();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	/**
	 * Tiene traccia del login esplicito (schermata di login). Attualmente impiegato tra le condizioni che scatenano il
	 * redirect alla paginadi carrello per l'utente B2C che ha un ordine in bozza.
	 *
	 * - Settato a `true` nella response di login()
	 * - Non settato durante il check implicito del token.
	 * - Risettato a `false` in frontend/src/app/service/guard/auth-token.guard.ts presso `checkUserPermission()` ...
	 *   `if (this.link_code)`.
	 *
	 * @memberof AuthService
	 */
	explicitLogin = false;

	constructor(
		private store: Store<any>,
		private router: Router,
		private localStorageService: LocalStorageService,
		private userService: AngularSpin8CoreUserService,
		public translate: TranslateService,
		public snackBar: MatSnackBar,
		private http: HttpClient,
		private appConfig: CustomerAppConfig,
		private cookieService: CookieService,
		private utilService: AppUtilService,
		private pouchService: PouchUtilService,
		private keycloak: KeycloakService
	) {
		const tk = this.cookieService.get('acstk');
		if (tk) {
			this.localStorage_authenticationToken = tk;
			const tk_decoded = jwt_decode(this.localStorage_authenticationToken);
			this.tokenPayload = new TokenPayload(tk_decoded);
		}
		this.keycloak.keycloakEvents$.subscribe({
			next(event) {
				if (event.type == KeycloakEventType.OnTokenExpired) {
					this.logoutBase();
				}
			}
		});
	}

	login(loginAuthRequest: LoginAuthRequestModel) {
		let loginCall: Promise<any>;
		if (this.cookieService.get('origin_srv') === 'true') {
			const headers = new HttpHeaders({
				'Content-Type': 'application/json'
			});
			loginCall = this.http
				.post(`${this.appConfig.config.urlConfig.origin}/authenticate`, loginAuthRequest, {
					headers
				})
				.toPromise();
		} else {
			loginCall = this.userService.login(loginAuthRequest);
		}

		loginCall
			.then((res: LoginAuthResponseModel) => {
				this.clearState();
				console.log('Auth service got token: ', res.accessToken);
				this.localStorage_authenticationToken = res.accessToken;
				this.explicitLogin = true;
				// TODO: NON TUTTE LE ROUTES HANNO LA DASHBOARD
				this.router.navigate([ROUTE_URL.private]);
			})
			.catch((err: RestBaseMessageError) => {
				const message = `Accesso non riuscito. <br> ${
					err && err.body && err.body.detail ? err.body.detail : err
				}`;
				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 5000,
					data: {
						message: message
					}
				});
			});
	}

	logoutBase() {
		// rocket chat
		const iframe = document.getElementById('rocketChatAuth') as HTMLIFrameElement;
		iframe.contentWindow.postMessage({
			externalCommand: 'logout'
		}, '*');
		////
		this.deleteRouting();
		this.clearOnLogout();
		this.clearPouchConnection();
		this.navigateToAuth();
	}

	/**
	 * Effettiva azione di logout eseguita dall'utente autenticato e logout forzato da sistema
	 * nel caso l'utente non abbia sufficienti autorizzazioni per accedere alla sezione richiesta
	 */

	logout() {
		this.logoutBase();
		// keycloak - redirect a url presente in environment.json
		this.keycloak.logout(this.appConfig.config.sso.logout).then((success) => {
		}).catch((error) => {
				console.log("--> log: logout error ", error );
		});
	}	

	/**
	 * Pulizia localStorage e store durante il logout
	 */
	clearOnLogout() {
		this.clearLocalStorage();
		this.clearState();
	}

	private clearPouchConnection(): void {
		// this.pouchService.clearConnection();
		const configToStop = this.appConfig.config.couch.filter(cnf => !!cnf['context']);
		console.log({ configToStop });
		this.pouchService.explicitStopCouchDb(configToStop);
	}

	/**
	 * Elimina gli oggetti del LocalStorageService, tranne quelli necessari al funzionamento di un eventuale
	 * storefront
	 *
	 * @memberof AuthService
	 */
	clearLocalStorage() {
		this.localStorageService.clear('authenticationToken');
		this.localStorageService.clear('payload');
		this.localStorageService.clear('link_code');
		this.localStorageService.clear('user');
		this.localStorageService.clear('permissions');
		this.localStorageService.clear('current_order_id');
	}

	/**
	 * Cancellazione info relative alle routes navigabili
	 */
	deleteRouting() {
		const routes = this.router.config;
		routes.find(rt => rt.path === ROUTE_URL.private).children = [];
		this.router.resetConfig(routes);
	}

	/**
	 * Navigate to primary baseUrl on logout
	 */
	navigateToAuth() {
		if (this.localStorage_link_code && this.localStorage_link_code.context !== ContextApplicationItemCodeEnum.B2C) {
			this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.login]);
		} else {
			this.utilService.redirectTo(ROUTE_URL.public);
		}
	}

	clearState() {
		this.store.dispatch(UserStateAction.reset());
		this.store.dispatch(OrganizationStateAction.reset());
		this.store.dispatch(ContactStateAction.reset());
		this.store.dispatch(UserActivateStateAction.reset());
		this.store.dispatch(OrderStateAction.reset());
		this.store.dispatch(OrderListStateAction.reset());
		this.store.dispatch(AuxiliaryTableStateAction.reset());
		this.store.dispatch(StatisticsAgentStateAction.reset());
		this.store.dispatch(StatisticsOrganizationStateAction.reset());
		this.store.dispatch(StatisticsBackofficeStateAction.reset());
		this.store.dispatch(StatisticsCrmStateAction.reset());
		this.store.dispatch(StatisticsDetailOrdersStateAction.reset());
		this.store.dispatch(StatisticsDetailSoldStateAction.reset());
		this.store.dispatch(StatisticsDetailClientsStateAction.reset());
		this.store.dispatch(StatisticsDetailExpiredStateAction.reset());
		this.store.dispatch(StatisticsOrdersStateAction.reset());
		this.store.dispatch(OrganizationListStateAction.reset());
		this.store.dispatch(ReceiptListStateAction.reset());
		this.store.dispatch(InformativePageStateAction.reset());
		this.store.dispatch(SearchResultsStateAction.reset());
		this.store.dispatch(UserManagementStateAction.reset());
		this.store.dispatch(CompanyAccountBalanceAction.reset());
		this.store.dispatch(ContextCodeAssociationStateAction.reset());
		this.store.dispatch(PermissionAuxiliaryTableStateAction.reset());
		this.store.dispatch(OpportunityStateAction.reset());
		// this.store.dispatch(OfferStateAction.reset());
		this.store.dispatch(KanbanStateAction.reset());
		this.store.dispatch(LoginContextCodeStateAction.reset());
		this.store.dispatch(ContextCodeManagementStateAction.reset());
		this.store.dispatch(ArticleStateAction.reset());

		// ticket-center
		this.store.dispatch(TicketCenterTicketStoreAction.reset());
		this.store.dispatch(TicketCenterTicketDetailStoreAction.reset());

		// TODO: verificare se la dispatch è sensata
		// if (this.appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C)) {
		// 	const articleRecapOrganizationReference = new BaseState(<ArticleRecap>{ _id: `article_recap_default` });

		// 	this.store.dispatch(ArticleStateAction.loadFromRecap(articleRecapOrganizationReference));
		// } else {
		if (!this.appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C)) {
			this.store.dispatch(LanguageListStateAction.reset());
			this.store.dispatch(ArticleDescriptionStateAction.reset());
			this.store.dispatch(FamilyListStateAction.reset());
			this.store.dispatch(CategoryListAction.reset());
			this.store.dispatch(ConfigurationStateAction.reset());
		}
	}

	get tokenPayload(): TokenPayload {
		if (!this.localStorage_tokenPayload) {
			return undefined;
		}
		return new TokenPayload(this.localStorage_tokenPayload);
	}

	set tokenPayload(tk: TokenPayload) {
		if (tk) {
			this.localStorage_tokenPayload = new TokenPayload(tk);
		} else {
			this.localStorage_tokenPayload = null;
		}
		this.tokenPayloadChange.next(this.localStorage_tokenPayload);
	}

	openSnackBar(message: string, action = 'Ok') {
		this.snackBar.open(message, action, {
			duration: 3000,
			verticalPosition: 'top'
		});
	}

	changeContext() {
		// Clear local storage
		this.localStorage_link_code = null;

		this.clearState();
		this.deleteRouting();
		console.log('clearing pouch connection');
		this.clearPouchConnection();

		// Redirect to context-selection
		this.router.navigate([ROUTE_URL.authentication, ROUTE_URL.contextSelection]);
	}
}
