import { Injectable } from '@angular/core';
import { CustomerAppConfig } from '../../customer-app.config';

// This solution should not include the package.json contents when doing a production build - only the version number (checked w/Angular 8 and 13).
// To be sure please check your generated main.#hash#.js file too!
import packageJson from '../../../../package.json';

@Injectable({
	providedIn: 'root'
})
export class AppVersionService {
	private version: string = packageJson.version;
	private release: string = packageJson.release;

	constructor(private appConfig: CustomerAppConfig) {}

	setTag() {
		this.appConfig.config.tag = this.version;
	}

	setRelease() {
		this.appConfig.config.release = this.release;
	}
}
