import { HttpClientModule } from "@angular/common/http";
import {
  APP_INITIALIZER,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from "@angular/core";
import { IPouchAppConfig, PouchDbModuleOptions } from "./app.config";
import { IPouchConfigModel } from "./environment-config.model";
import { OfflineDeviceService } from "./offline-device.service";
import { PouchUtilService } from "./pouch-util.service";
import { PouchDbAdapter } from "./structure/pouchdb-adapter";
import { TransmissionService } from "./transmission.service";

export const loadPouchService = (
  config: IPouchAppConfig,
  pouchUtilService: PouchUtilService,
  ...pouchAdapter: PouchDbAdapter[]
) => {
  return () => {
    return new Promise((resolve, reject) => {
      config.config$.subscribe(async (conf: IPouchConfigModel) => {
        const { couch } = conf || {};
        if (couch) {
          pouchUtilService.pouchInstance = pouchAdapter;
          // await pouchUtilService.explicitInitCouch();z
          resolve(null);
        } else {
          console.error(
            "Couch configuration not found, you must provide a couch configuration in your env file"
          );
          reject();
        }
      });
    });
  };
};

export function providePouchUtilService(appConfig: IPouchAppConfig) {
  return new PouchUtilService(appConfig);
}

export const appConfigToken = new InjectionToken("applicationConfig");

// @dynamic
@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  exports: [],
})
export class PouchdbModule {
  constructor(@Optional() @SkipSelf() parentModule: PouchdbModule) {
    if (parentModule) {
      throw new Error(
        "PouchdbModule is already loaded. Import it in the AppModule only"
      );
    }
  }

  static forRoot(
    opt: PouchDbModuleOptions
  ): ModuleWithProviders<PouchdbModule> {
    return {
      ngModule: PouchdbModule,
      providers: [
        OfflineDeviceService,
        TransmissionService,
        {
          provide: PouchUtilService,
          useFactory: providePouchUtilService,
          deps: [opt.appConfig],
        },
        {
          provide: appConfigToken,
          useExisting: opt.appConfig,
        },
        {
          provide: APP_INITIALIZER,
          deps: [opt.appConfig, PouchUtilService, ...opt.config],
          useFactory: loadPouchService,
          multi: true,
        },
      ],
    };
  }
}
