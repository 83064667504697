<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
  <h1 mat-dialog-title>{{ "mc.general.name_resource" | translate }}</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <input
        matInput
        placeholder="{{ 'mc.general.name' | translate }}"
        formControlName="name"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="button" mat-dialog-close>
      {{ "mc.general.cancel" | translate | uppercase }}
    </button>
    <button
      mat-button
      type="submit"
      cdkFocusInitial
      mat-raised-button
      color="primary"
    >
      {{ "mc.general.confirm" | translate | uppercase }}
    </button>
  </div>
</form>
