import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';

export class OrganizationPouch extends AbstractPouchdbMethod {
	// Aggiorna organizzazione
	// putOrganization(data: OrganizationStateModel, isGuid: boolean): Promise<OrganizationStateModel> {
	// 	const guid = isGuid ? data._id : 'organization';
	// 	data.type = data.type;
	// 	data.source = 'APPLICATION';
	// 	return new Promise((resolve, reject) => {
	// 		this.put<OrganizationStateModel>(data, guid, !isGuid)
	// 			.then((doc: OrganizationStateModel) => {
	// 				resolve(doc);
	// 			})
	// 			.catch(err => {
	// 				console.log(err);
	// 				reject(err);
	// 			});
	// 	});
	// }
}
