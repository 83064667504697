import { Component, Input } from '@angular/core';

// model
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';

// misc
import { UtilPriceService } from './../../../../service/util/util-price.service';
import * as ConfigurationCustomerPrice from '../../../../constants/configuration-customer/price/price.constant';

@Component({
	selector: 'b2c-article-price',
	templateUrl: './b2c-article-price.component.html',
	styleUrls: ['./b2c-article-price.component.scss']
})
export class B2cArticlePriceComponent {
	@Input() article: ArticlePouchModel;
	@Input() articlePriceTooltip: string = '';

	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(public utilPriceService: UtilPriceService) {}
}
