import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

    @Input() goBackButton: boolean = false;
    @Input() goBackPath: string = null;
    @Input() actionMenu: boolean = false;
    @Input() badgeContent: string = null;


    constructor(private router: Router, private location: Location) { }

    ngOnInit(): void {}

    goBack() {
        if(this.goBackButton) {
            if(this.goBackPath) {
                this.router.navigate([this.goBackPath]);
            } else {
                this.location.back();
            }
        }
    }
}
