import { ArticlePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import * as ConfigurationCustomerOrder from '../order/order.constant';

export const returnArticleAvailability = (
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): boolean => {
    let isAvailable = true;
    // se non è presente la prop. `articleDescription.stock`, si assume che l'articolo sia disponibile
    if (article.articleDescription.stock) {
        // se non è presente la prop. `articleDescription.stock.qty_available`, si assume che l'articolo
        // abbia disponibilità infinita (sempre che i controlli successivi ne accertino la disponibilità
        // in generale)
        if (Object.prototype.hasOwnProperty.call(article.articleDescription.stock, 'qty_available')) {
            const availableQuantity = returnArticleAvailableQuantity(article, organization);
            if (availableQuantity !== null) {
                isAvailable = availableQuantity ? true : false;
            }
            if (isAvailable) {
                // Check property: qty_available
                if (article.articleDescription.stock.qty_available && article.ordered_quantity) {
                    const quantityCanBeOrdered =
                        returnArticleAvailableQuantityConsideringCurrentOrder(
                            article.ordered_quantity,
                            article,
                            organization
                        );
                    isAvailable = quantityCanBeOrdered ? true : false;
                }
            }
        }
        // se non è presente la prop. `articleDescription.stock.is_available`, si assume che l'articolo sia
        // disponibile
        if (Object.prototype.hasOwnProperty.call(article.articleDescription.stock, 'is_available')) {
            isAvailable = isAvailable && (article.articleDescription.stock.is_available || false);
        }
    }
    return isAvailable;
}

export const returnArticleAvailableQuantityConsideringCurrentOrder = (
    inputQuantity: number,
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): number => {
    let quantityCanBeOrdered: number = inputQuantity;
    const availableQuantity: number = returnArticleAvailableQuantity(article, organization);
    if (availableQuantity || availableQuantity === 0) {
        const quantityAlreadyOrdered: number = article.ordered_quantity || 0;
        quantityCanBeOrdered = availableQuantity - quantityAlreadyOrdered;
    }
    return quantityCanBeOrdered;
}

export const returnArticleAvailableQuantity = (
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): number => {
    if (
        article &&
        article.articleDescription &&
        article.articleDescription.stock &&
        Object.prototype.hasOwnProperty.call(article.articleDescription.stock, 'is_available') &&
        !article.articleDescription.stock.is_available
    ) {
        return 0;
    }
    if (
            article &&
            article.articleDescription &&
            article.articleDescription.stock &&
            Object.prototype.hasOwnProperty.call(article.articleDescription.stock, 'qty_available')
    ) {
        let availableQuantity: number = article.articleDescription.stock.qty_available;
        // Check multiple quantity
        const multipleOfTotalQuantity: number =
            returnArticleQuantityByQtyBoxMultiple(
                availableQuantity,
                article,
                organization
            );
        // Multiple quantity greater than available
        if (multipleOfTotalQuantity > availableQuantity && article.articleDescription.qty_box) {
            availableQuantity = multipleOfTotalQuantity - article.articleDescription.qty_box;
        }
        return availableQuantity;
    }
}

/**
 * Restituisce il multiplo di qty_box più vicino (per eccesso) all'input dell'utente.
 * In base alle configurazioni di progetto il metodo potrebbe bypassare 1 come valore di default. Si veda:
 * - ConfigurationCustomerOrder.qtyBoxMultipleCheck
 *
 * @param {*} value
 * @param {ArticlePouchModel} article
 * @param {OrganizationPouchModel} organization
 * @returns {number}
 * @memberof UtilOrderService
 */
export const returnArticleQuantityByQtyBoxMultiple = (
    value,
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): number => {
    let coefficient: number;
    if (
        (article.articleDescription.qty_box &&
            organization &&
            ConfigurationCustomerOrder.qtyBoxMultipleCheck[organization.organization_type]) ||
        (!organization && ConfigurationCustomerOrder.qtyBoxMultipleCheck.PUBLIC)
    ) {
        coefficient = article.articleDescription.qty_box;
    } else {
        coefficient = 1;
    }
    if (value > 0) {
        value = Math.ceil(value / coefficient) * coefficient;
    } else if (value < 0) {
        value = Math.floor(value / coefficient) * coefficient;
    }
    return value;
}