import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Routes } from '@angular/router';

@Component({
  selector: 'tab-wrapper',
  templateUrl: './tab-wrapper.component.html',
  styleUrls: ['./tab-wrapper.component.scss']
})
export class TabWrapperComponent implements OnInit {

  /**
   * Filtra le routes figlie (route.routeConfig.children) escludendo i redirect (redirectTo)
   * toExclude è un array di stringhe contenenti i path da escludere dal sistema di tab
   *
   */

  @Input() hideTab: boolean = false;
  @Input() set toExclude(r: string[]) {
    this.routes = [];
    const array = this.route.routeConfig.children;
    if (r) {
      this.routes = array.filter(i => !i.redirectTo && !r.includes(i.path));
    } else {
      this.getNotEmptyRoutes(this.route.routeConfig.children);
    }
  }

  routes: Routes = [];

  constructor(
    private route: ActivatedRoute
  ) {
    this.getNotEmptyRoutes(this.route.routeConfig.children);
  }

  ngOnInit() {
  }

  /**
   * Filtra le routes figlie escludendo i redirect, necessari per forzare il rendering di almeno una delle routes figlie
   *
   * @param array (ActivatedRoute) route.routeConfig.children
   */
  getNotEmptyRoutes(array: Routes) {
    this.routes = array.filter(i => !i.redirectTo);
  }

}
