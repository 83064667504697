import { DirectivesModule } from './directives/directives.module';
import { NgModule } from '@angular/core';
import { PipeModule } from './pipe/pipe.module';
import { MaterialComponentsModule } from './material-components.module';
import { CommonModule } from '@angular/common';
import { SaepICTAngularCoreModule } from '@saep-ict/angular-core';
import { TranslateModule } from '@ngx-translate/core';
import { CovalentSearchModule } from '@covalent/core/search';
import { CovalentPagingModule } from '@covalent/core/paging';
import { CovalentCommonModule } from '@covalent/core/common';
import { CovalentFileModule } from '@covalent/core/file';
import { CovalentDataTableModule } from '@covalent/core/data-table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  exports: [
    CommonModule,
    BrowserAnimationsModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
    MaterialComponentsModule,
		// covalent
		CovalentCommonModule,
		CovalentPagingModule,
		CovalentFileModule,
		CovalentSearchModule,
		CovalentDataTableModule,
    // ngx-translate
    TranslateModule,
    // misc
    NgScrollbarModule,
    // saep-ict
    SaepICTAngularCoreModule,
    // proprie
    DirectivesModule,
    PipeModule,
  ]
})
export class SharedModule { }
