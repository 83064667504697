import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { OfflineDeviceService } from '@saep-ict/pouch-db';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ConnectionModel } from '../../model/connection.model';
import { ConnectionActionEnum, ConnectionStateAction } from './connection.actions';

@Injectable()
export class ConnectionEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConnectionActionEnum.LOAD),
			mergeMap((action: BaseStateModel<ConnectionModel>) => from(this.getConnection())),
			map((connection: BaseStateModel<ConnectionModel>) => ConnectionStateAction.update(connection)),
			catchError((error, caught) => {
				this.store.dispatch(ConnectionStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private offlineDeviceService: OfflineDeviceService
	) {}

	getConnection(): Promise<BaseStateModel<ConnectionModel>> {
		return new Promise((resolve, reject) => {
			this.offlineDeviceService.isInternetAvailable().then(online => {
				resolve(new BaseState({ offline: !online }));
			});
		});
	}
}
