import { Component, ElementRef,Input, OnInit, ViewChild } from '@angular/core';
import {
	SubscribeManagerService, BaseStateModel,
  ITdDataTableColumnCustom
} from '@saep-ict/angular-core';
import {
	ArticlePouchModel,
	LocalListHandlerBaseModel,
	OrganizationPouchModel,
} from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import {
	UserDetailModel,
  ArticleRecapStockStateModel
} from '@saep-ict/angular-spin8-core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { ArticleTableConfigurationAction } from '../../../model/article.model';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { VirtualScrollConfiguration } from '../../../model/virtual-scroll.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
	selector: 'article-stock-wrapper',
	templateUrl: './article-stock-wrapper.component.html',
	styleUrls: ['./article-stock-wrapper.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleStockWrapperComponent implements OnInit {
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	@ViewChild('virtualScrollContainer') set virtualScrollContainer(e: ElementRef) {
		if (
			this.virtualScrollConfiguration.enabled &&
			this.virtualScrollConfiguration.itemHeight &&
			!this.virtualScrollConfiguration.viewportHeight &&
			e &&
			e.nativeElement
		) {
			setTimeout(() => {
				const domRect: DOMRect = e.nativeElement.getBoundingClientRect();
				this.virtualScrollConfiguration.viewportHeight =
					(window.innerHeight - domRect.top) - this.virtualScrollConfiguration.itemHeight;
			}, 0);
		}
	}

	@Input() set localListHandlerData(e: LocalListHandlerBaseModel<ArticleRecapStockStateModel>) {
		if (e) {
			this._localListHandlerData = e;
			const columnWidthConfigurationClassItem: ITdDataTableColumnCustom =
				this._localListHandlerData.columnList.find(i => i.name === "column-width-configuration-class"); 
			if (columnWidthConfigurationClassItem) {
				this.columnWidthConfigurationClass = columnWidthConfigurationClassItem.label;
			}
			if (this.virtualScroll && this.virtualScrollConfiguration && this.virtualScrollConfiguration.enabled) {
				this.virtualScroll.scrollToIndex(0);
			}
		}
	};
	_localListHandlerData: LocalListHandlerBaseModel<ArticleRecapStockStateModel>;

	@Input() organization: OrganizationPouchModel;
	@Input() onItemSelectShow: boolean;
	@Input() configurationAction: ArticleTableConfigurationAction;
	@Input() order: OrderStateModel;
	@Input() virtualScrollConfiguration: VirtualScrollConfiguration = {
		enabled: false
	};

	columnWidthConfigurationClass: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;


	public sidebarClosed = false;

	constructor(
		private store: Store
	) {
		this.loadStaticData();
	}

	ngOnInit(): void {
		const pwsElm = document.getElementsByTagName('page-with-sidebar').item(0);

		if(pwsElm) {
			const child = pwsElm.firstChild;
			const observer = new MutationObserver(mutationList => {
				this.checkSidebarclosed(mutationList, this)
			});


			observer.observe(child, {
				attributes: true,
				attributeFilter: ['class'],
				childList: false,
				characterData: false
			})
		}
	}

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			this.user = store.data;
		});
	}

  setSidebardStatus(status) {
		this.sidebarClosed = status;
	}


	checkSidebarclosed(event, instance) {
		const target = event[0].target as HTMLTextAreaElement;
		instance.setSidebardStatus(target.classList.contains('sidebarclosed'));
	}

}
