import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AngularCoreUtilService, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../../state';
import { OrganizationActionEnum } from '../../../../../state/organization/organization.actions';
import _ from 'lodash';
import { CurrencyPipe } from '@angular/common';
import { UtilAddressService } from '../../../../../service/util/util-address.service';
import { CustomerAppConfig } from '../../../../../customer-app.config';
import { UsefulLinksModel } from '../../../../../model/structure/environment-config.model';
import * as ConfigurationCustomerOrganization from '../../../../../constants/configuration-customer/organization/organization.constant';
import {
	AuxiliaryTableStateModel,
	ContextApplicationItemCodeEnum,
	OrganizationStateModel,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { OrganizationPouchModelExtraField } from '../../../../../model/organization.model'
import * as ConfigurationCustomerCompanyPaymentColumns from '../../../../../constants/configuration-customer/organization/organization-payment-column-map/organization-payment-column-base.constant'
@Component({
	selector: 'organization-detail-full-detail',
	templateUrl: './organization-detail-full-detail.component.html',
	styleUrls: ['./organization-detail-full-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrganizationDetailFullDetailComponent implements OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationPouchModelExtraField;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	paymentList: PaymentPouchModel[];
	paymentListPartial: PaymentPouchModel[];

	otherInformationList: { label: string; value: string | number }[] = [];

	displayPaymentMethod : boolean;
	displayUtilLinks: boolean;
	displayPersonalData: boolean;
	displayLegalInformation: boolean;
	currentContext: ContextApplicationItemCodeEnum;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	cmrLinks: UsefulLinksModel = new UsefulLinksModel();

	companyDetailPayment = ConfigurationCustomerCompanyPaymentColumns.baseColumns;

	constructor(
		private store: Store,
		public utilAddressService: UtilAddressService,
		private currencyPipe: CurrencyPipe,
		public subscribeManagerService: SubscribeManagerService,
		private appConfig: CustomerAppConfig,
		public utilService: AngularCoreUtilService
	) {
		// User
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.currentContext = res ? res.data.current_permission.context_application : null;
			this.displayPaymentMethod =
				ConfigurationCustomerOrganization.displayPaymentMethod[
					ContextApplicationItemCodeEnum[this.currentContext]
				];
			this.displayUtilLinks =
				ConfigurationCustomerOrganization.displayUtilLinks[
					ContextApplicationItemCodeEnum[this.currentContext]
				];
			this.displayPersonalData = 	ConfigurationCustomerOrganization.displayPersonalData[
				ContextApplicationItemCodeEnum[this.currentContext]
			];
			this.displayLegalInformation = ConfigurationCustomerOrganization.displayLegalInformation[
				ContextApplicationItemCodeEnum[this.currentContext]
			]
		});

		// Aux Table
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});

		// Organization
		this.subscribeManagerService.populate(
			this.subscribeOrganizationState().subscribe(),
			'subscribeOrganizationState'
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeOrganizationState() {
		return this.organization$.pipe(
			filter((state: BaseStateModel<OrganizationStateModel>) => !!state),
			map((state: BaseStateModel<OrganizationStateModel>) => {
				switch (state.type) {
					case OrganizationActionEnum.UPDATE:
						this.organization = state.data;
						this.contextApplication();
						break;
				}
				return true;
			})
		);
	}

	contextApplication() {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
				this.getPayments();
				this.createOtherInformationList();
				break;
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				this.getPayments();
				this.createOtherInformationList();
				break;
			case ContextApplicationItemCodeEnum.AGENT:
				this.getPayments();
				this.createOtherInformationList();
				break;
			case ContextApplicationItemCodeEnum.CRM:
				this.getPayments();
				this.createOtherInformationList();
				break;
		}
	}

	seeAllPaymentMethods() {
		// TODO: open dialog
	}

	getPayments() {
		const paymentDivision: string[] = this.organization.division_list.map((i) => i.payment_condition);
		this.paymentList = 
			this.auxiliaryTable.paymentList.filter(payment => paymentDivision.includes(payment.code_item));
		this.paymentListPartial = this.paymentList.length > 3 ? this.paymentList.slice(0, 3) : this.paymentList;
	}

	createOtherInformationList() {
		if (this.organization) {
			// revenue_year
			if (this.organization.crm && this.organization.crm.revenue_year) {
				const revenue_year = {
					label: 'Fatturato annuo',
					value: this.currencyPipe.transform(this.organization.crm.revenue_year, 'EUR', 'symbol', '', 'it')
				};
				this.otherInformationList.push(revenue_year);
			}

			// employees_number
			if (this.organization.crm && this.organization.crm.employees_number) {
				const employees_number = {
					label: 'Numero dipendenti',
					value: this.organization.crm.employees_number
				};
				this.otherInformationList.push(employees_number);
			}

			// organization_international_rating
			if (this.organization.crm && this.organization.crm.organization_international_rating) {
				const organization_international_rating = {
					label: 'Voto affidabilità (internazionale)',
					value: this.organization.crm.organization_international_rating
				};
				this.otherInformationList.push(organization_international_rating);
			}

			// organization_rating
			if (this.organization.crm && this.organization.crm.organization_rating) {
				const organization_rating = {
					label: 'Voto affidabilità',
					value: this.organization.crm.organization_rating + ' %'
				};
				this.otherInformationList.push(organization_rating);
			}

			// organization_sector
			if (this.organization.crm && this.organization.crm.organization_sector) {
				const organization_sector = {
					label: 'Settore',
					value: this.organization.crm.organization_sector
				};
				this.otherInformationList.push(organization_sector);
			}

			// links
			if (this.user.current_permission.context_application == ContextApplicationItemCodeEnum.CRM) {
				if (!this.organization.crm) {
					this.organization.crm = {};
				}
				this.cmrLinks.recipes = this.organization.crm.url_recipes ?? this.appConfig.config.usefulLinks?.recipes;
				this.cmrLinks.documents =
					this.organization.crm.url_documents ?? this.appConfig.config.usefulLinks?.documents;
				this.cmrLinks.tickets = this.organization.crm.url_tickets ?? this.appConfig.config.usefulLinks?.tickets;
				this.cmrLinks.sales = this.organization.crm.url_sales ?? this.appConfig.config.usefulLinks?.sales;
			}
		}
	}

	showBusinessInformationAndPaymentMethods() {
		// To manage section visibility
		return true;
	}
}
