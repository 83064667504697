import { Injectable } from '@angular/core';

// model
import { BaseState, BaseStateModel, RestBaseMessageError, RestBaseResponse } from '@saep-ict/angular-core';
import { TicketModel } from '../../model/lib.model';

// store
import { TicketCenterTicketActionEnum, TicketCenterTicketStoreAction } from './ticket.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// misc
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RestActionService } from '../../service/rest/rest-action.service';
import { from } from 'rxjs';
import { TicketCenterUtilService } from '../../service/util/ticket-center-util.service';

@Injectable()
export class TicketCenterTicketEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TicketCenterTicketActionEnum.LOAD),
			mergeMap((state: any) => from(this.load())),
			map((state: BaseStateModel<TicketModel<any>[]>) => TicketCenterTicketStoreAction.update(state)),
			catchError((error: RestBaseMessageError, caught) => {
        this.ticketCenterUtilService.dispatchStoreActionError<TicketModel<any>>(error, TicketCenterTicketStoreAction.error);
				return caught;
			})
		)
	);
	constructor(
    private actions$: Actions,
    private restActionService: RestActionService,
    private ticketCenterUtilService: TicketCenterUtilService
  ) {

  }

  load(): Promise<BaseStateModel<any>> {
    return this.restActionService.getList()
      .then((res: RestBaseResponse<TicketModel<any>[]>) => {
        return new BaseState(res.data);
      })
      .catch((error: RestBaseMessageError) => {
        throw error;
      });
  }

}
