<loading-overlay [loaderServiceInstance]="loaderService"></loading-overlay>

<ng-scrollbar
	[autoWidthDisabled]="true"
	[autoHeightDisabled]="false"
	[scrollAuditTime]="20"
	#scrollbar
	[style.max-height.px]="mediaReplayService.deviceH"
	class="layout-ctx-public"
>
	<div scrollViewport class="custom-viewport">
		<div class="scroll-content-wrapper" [style.max-width.px]="mediaReplayService.deviceW">
			<div #scrollContainer>
				<div [style.min-height.px]="mediaReplayService.deviceH">
					<div class="container-fluid">
						<div class="row mb-0">
							<div class="col-12 p-0">
								<app-header></app-header>
								<router-outlet></router-outlet>
								<app-footer></app-footer>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-scrollbar>
<ps-to-top [scrollbarRef]="scrollbar"></ps-to-top>
