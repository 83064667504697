import { Injectable } from '@angular/core';

// model
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoryListAction, CategoryListActionEnum } from './category-list.actions';

// widget & utility
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { CustomerAppConfig } from '../../customer-app.config';
import { Category, CategoryMap, AllDocsDataPouchModel } from '@saep-ict/pouch_agent_models';
import { PouchDbConstant, AngularSpin8CoreCatalogService } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';

@Injectable()
export class CategoryListEffects {
	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoryListActionEnum.LOAD_ALL),
			mergeMap(() => from(this.loadAll())),
			mergeMap((action: BaseStateModel<Category[]>) => from(this.loadCategoryListTree(action))),
			map((action: BaseStateModel<CategoryMap>) => CategoryListAction.update(action)),
			catchError(() => of({ type: CategoryListActionEnum.ERROR }))
		)
	);

	highlightedCategoryList: Category[];

	constructor(
		private actions$: Actions,
		private appConfig: CustomerAppConfig,
		private catalogService: AngularSpin8CoreCatalogService,
		private commonsAdapter: PouchDbCommonsAdapter
	) {}

	// TODO: rivedere dopo che saranno normalizzate le risposte REST e couchdb, per capire se serve rimappare per restituire un risultato dello stesso type
	async loadAll() {
		this.highlightedCategoryList = [];
		return new Promise((resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				const params: any = {
					include_docs: true,
					startkey:
						'category' +
						ConfigurationCustomerAppStructure.noSqlDocSeparator,
					endkey:
						'category' +
						ConfigurationCustomerAppStructure.noSqlDocSeparator +
						PouchDbConstant.allDocsLastCharacter
				};
				this.commonsAdapter.basePouch
					.allDocs(params)
					.then((result: AllDocsDataPouchModel<Category>) =>
						resolve(new BaseState(result.rows.map(cat => cat.doc)))
					);
			} else {
				resolve(this.catalogService.getCategories());
			}
		})
			.then(
				(res: any): BaseState<any> => {
					// TODO: check if some properties in nodes can be left behind
					for (let key of Object.getOwnPropertyNames(res)) {
						if (!['data', 'type'].includes(key)) {
							delete res[key];
						}
					}
					return res;
				}
			)
			.catch(err => {
				console.log(err);
			});
	}

	/**
	 * Scatena loadCategoryChildrenRecursively() sull'array di categorie passate in action
	 * @param action
	 */
	async loadCategoryListTree(action: BaseStateModel<Category[]>): Promise<BaseStateModel<CategoryMap>> {
		const categoryTreeList: Category[] = [];
		if (action.data && action.data.length) {
			const rootCategories = action.data.filter(category => category.level === 'root');
			rootCategories.sort((a, b) => a.sequence - b.sequence);
			for (let i = 0; i < rootCategories.length; i++) {
				categoryTreeList.push(await this.loadCategoryChildrenRecursively(rootCategories[i], action.data));
			}
		}
		const categoryMapList: CategoryMap = {
			tree: categoryTreeList,
			is_highlighted: this.highlightedCategoryList
		};
		return new BaseState(categoryMapList);
	}

	// widget & utility

	/**
	 * Restituisce un array di category_list figlie in maniera ricorsiva per tutti i rami della categoria che presentano
	 * elementi nella la proprietà children e rispettano la condizione includeLevelType()
	 *
	 * @param category
	 * @param adapter
	 */
	async loadCategoryChildrenRecursively(category: Category, categoryList: Category[]): Promise<Category> {
		const category_list: Category[] = [];

		if (category && category.children && category.children.length) {
			for (let i = 0; i < category.children.length; i++) {
				const categoryDetail = this.getCategoryDetail(category.children[i], categoryList);
				if (categoryDetail && this.includeLevelType(categoryDetail.level)) {
					category_list[i] = categoryDetail;
					if (categoryDetail.children && categoryDetail.children.length > 0) {
						const recursiveRes = await this.loadCategoryChildrenRecursively(category_list[i], categoryList);
						if (recursiveRes) {
							category_list[i].category_list = recursiveRes.category_list;
						}
					}
				}
			}
		}
		category.category_list = category_list.sort((a, b) => a.sequence - b.sequence);

		// check is_highlighted
		if (category.is_highlighted) {
			this.highlightedCategoryList.push(category);
		}

		return category;
	}

	getCategoryDetail(categoryCodeItem: string, categoryList: Category[]) {
		return categoryList.find(category => category.code_item === categoryCodeItem) || null;
	}

	/**
	 * Escludono i documenti di categoria impropri controllando la prop. level come:
	 *
	 * - leaf
	 *
	 * @param level prop. di category
	 */
	includeLevelType(level: string): boolean {
		return level !== 'leaf';
	}
}
