<ng-container [formGroup]="form">
	<mat-form-field>
		<!-- hypothetical content -->
		<ng-content></ng-content>

		<mat-label>{{ label | translate | sentencecase }}</mat-label>

		<!-- readonly input if form disabled -->
		<input *ngIf="form.disabled" matInput type="text" [value]="explicitValue" disabled />

		<!-- real select input if form enabled -->
		<mat-select *ngIf="!form.disabled" [formControlName]="formControlName" (openedChange)="inputSearch.value = ''">
			<!-- text search field -->
			<mat-form-field class="filter-input" [class.hidden]="!canFilter">
				<mat-label>{{ searchLabel | translate | sentencecase }}</mat-label>
				<input matInput type="text" #inputSearch autocomplete="off" />
				<button
					mat-button
					matSuffix
					mat-icon-button
					aria-label="Clear"
					[disabled]="!inputSearch.value"
					(click)="inputSearch.value = ''"
				>
					<mat-icon *ngIf="inputSearch.value">close</mat-icon>
					<mat-icon *ngIf="!inputSearch.value">search</mat-icon>
				</button>
				<!-- <mat-icon matSuffix>search</mat-icon> -->
			</mat-form-field>
			<!-- select options -->
			<mat-option
				*ngFor="
					let option of canFilter
						? (optionList
						  | filterByString
								: {
										propertyListName: propertyListName,
										value: inputSearch.value
								  })
						: optionList
				"
				[value]="option[optionItemStructure.value]"
			>
				<span *ngFor="let label of optionItemStructure.label_list; let i = index">
					{{ option[label] }}
					<span *ngIf="i + 1 < optionItemStructure.label_list.length">-</span>
				</span>
			</mat-option>
		</mat-select>
	</mat-form-field>
</ng-container>
