import { ITdDataTableColumnCustom, ListStructureEnum } from '@saep-ict/pouch_agent_models';

export const articleColumnMapB2bCatalog = (currency: string) : ITdDataTableColumnCustom[] => [
	// colonne di configurazione
	{
		name: 'articleDescription.sequence',
		label: null,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: true
	},
	{
		name: 'column-width-configuration-class',
		label: 'articleColumnMapB2bCatalog',
		hidden: true
	},
	// colonne renderizzate
	{
		name: 'code_erp',
		labelPath: 'article.field.ref',
		label: null,
		hidden: false
	},
	{
		name: 'description',
		labelPath: 'theme.article.field.product',
		label: null,
		sortType: ListStructureEnum.Sort.Type.STRING,
		hidden: false
	},
	{
		name: 'actions',
		label: null,
		numeric: true,
		sortable: false,
		hidden: false
	},
	{
		name: 'input_quantity',
		labelPath: 'article.field.qty',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'articleDescription.relatedArticleTester.input_quantity',
		labelPath: 'article.field.tester_qty',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'articlePrice.price',
		labelPath: 'article.field.price',
		labelPathParam: { currency: currency },
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'calculate_price',
		labelPath: 'article.field.total',
		labelPathParam: { currency: currency },
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	}
];
