import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { NewsModel } from '../../model/news.model';

export enum NewsListActionEnum {
	LOAD_ALL = '[news-list] Load All',
	SAVE_ALL = '[news-list] Save All',
	UPDATE = '[news-list] Update',
	RESET = '[news-list] Reset',
	ERROR = '[news-list] Error'
}

export namespace NewsListStateAction {
	export const loadAll = createAction(NewsListActionEnum.LOAD_ALL);
	export const saveAll = createAction(NewsListActionEnum.SAVE_ALL, props<BaseStateModel<NewsModel[]>>());
	export const update = createAction(NewsListActionEnum.UPDATE, props<BaseStateModel<NewsModel[]>>());
	export const reset = createAction(NewsListActionEnum.RESET);
	export const error = createAction(NewsListActionEnum.ERROR, props<BaseStateModel<any>>());
}
