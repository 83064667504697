import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { WidgetModule } from '../../../widget/widget.module';
import { PageCommonsModule } from '../../commons/page-commons.module';
import { B2cCartComponent } from '../b2c-cart/b2c-cart.component';
import { B2cCatalogComponent } from '../b2c-catalog/b2c-catalog.component';
import { B2cCheckoutComponent } from '../b2c-checkout/b2c-checkout.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from '../b2c-article-detail/b2c-article-detail.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { B2cArticleListWrapper } from '../../../widget/b2c/article/b2c-article-list-wrapper/b2c-article-list-wrapper.component';
import { B2cCheckoutTransactionComponent } from '../../../page/b2c/b2c-checkout/b2c-checkout-transaction/b2c-checkout-transaction.component';
import { IllustrationsModule } from '@saep-ict/angular-spin8-core';

@NgModule({
    imports: [WidgetModule, SharedModule, PageCommonsModule, IllustrationsModule],
    declarations: [
        HomeComponent,
        B2cCatalogComponent,
        ProductDetailComponent,
        B2cCartComponent,
        B2cCheckoutComponent,
        B2cCheckoutTransactionComponent,
        SearchResultsComponent,
        B2cArticleListWrapper
    ],
    exports: [
        HomeComponent,
        B2cCatalogComponent,
        ProductDetailComponent,
        B2cCartComponent,
        B2cCheckoutComponent,
        SearchResultsComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageStorefrontModule {}
