import { Component, OnInit, Input } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

export class AvatarConfigClass {
	urlImage?: string;
	userName: string;
	isRounded?: boolean;
	size?: number;
}
@Component({
	selector: 'avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
	_avatarConfigClass: AvatarConfigClass = new AvatarConfigClass();
	@Input() set avatarConfigClass(avatarConfigClass: AvatarConfigClass) {
		if (avatarConfigClass) {
			this._avatarConfigClass = avatarConfigClass;
		}
	}
	@Input() currentContext: ContextApplicationItemCodeEnum;
	@Input() level: string;
	avatarSize: number = 32;

	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor() {}

	ngOnInit() {
		if (this._avatarConfigClass && this._avatarConfigClass.size) {
			this.avatarSize = this._avatarConfigClass.size;
		}
	}

	getInitalsName(name: string): string {
		let initials = '';

		const nameList: string[] = name.split(' ');
		nameList.forEach(item => (initials = initials + item.charAt(0)));

		if (nameList.length > 2) {
			initials = initials.substring(0, 2);
		}

		return initials.toUpperCase();
	}
}
