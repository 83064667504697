import { Validators } from '@angular/forms';
import {
	FormControlMultipurposeModel,
	FormControlMultipurposeService
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { Injector } from '@angular/core';
import { creationFieldMapContactBase } from './creation-field-map-contact-base.const';

const injector = Injector.create({
	providers: [{ provide: FormControlMultipurposeService, deps: [] }]
});
const formControlMultipurposeService = injector.get(FormControlMultipurposeService);

export const creationFieldMapContactDetailDialog: FormControlMultipurposeModel.Item[] =
  formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
    formControlMultipurposeService.returnFormFieldListWithSomeValidatorList(
      formControlMultipurposeService.returnFormFieldListWithSomeColumnWidth(
        _.cloneDeep(creationFieldMapContactBase),
        ['email', 'phone', 'phone_mobile', 'fax'],
        6
      ),
      ['first_name', 'last_name', 'role', 'email', 'phone'],
      [Validators.required]
    ),
    ['contact_type'],
    false
  );
