// model
import { TicketCenterLibConfigurationStoreAction, TicketCenterLibConfigurationStoreEnum } from './lib-configuration.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ITicketCenterConfigModel } from '../../model/lib-app-config.interface';

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Injector } from '@angular/core';

// misc
import { TicketCenterUtilService } from '../../service/util/ticket-center-util.service';

const injector = Injector.create({
	providers: [{ provide: TicketCenterUtilService, deps: [] }]
});

const ticketCenterUtilService = injector.get(TicketCenterUtilService);

export namespace TicketCenterLibConfigurationStoreReducer {
	export const initialState: BaseStateModel<ITicketCenterConfigModel> = null;

	const _reducer = createReducer(
		initialState,
		on(TicketCenterLibConfigurationStoreAction.update, (state, param) => param),
    on(TicketCenterLibConfigurationStoreAction.reset, (state) => null),
    on(TicketCenterLibConfigurationStoreAction.error, (state, param) =>
      ticketCenterUtilService.returnStoreReducerError<ITicketCenterConfigModel>(state, param)
    )
	);

	export function reducer(state: BaseStateModel<ITicketCenterConfigModel>, action: TypedAction<TicketCenterLibConfigurationStoreEnum>) {
    return _reducer(state, action);
	}
}
