import _ from 'lodash';
import { DestinationColumnMap, ITdDataTableColumnCustom } from '@saep-ict/angular-spin8-core';

export const projectOverride: ITdDataTableColumnCustom[] =
  [
    {
      name: 'code_erp',
      labelPath: 'destination.code_erp',
      label: null
    },
    ..._.cloneDeep(DestinationColumnMap.base),
    {
      name: 'address.country',
      labelPath: 'theme.destination.country',
      label: null
    },
    {
      name: 'division_list.area_commercial',
      labelPath: 'general.area_commercial',
      label: null
    }
  ];