import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import * as ForecastModel from '../../model/forecast.model'

export enum ForecastActionEnum {
	LOAD = '[forecast] Load',
	SAVE = '[forecast] Save',
	SAVE_COMPLETE = '[forecast] Save complete',
	UPDATE = '[forecast] Update',
	RESET = '[forecast] Reset',
	ERROR = '[forecast] Error'
}

export namespace ForecastStateAction {
	export const load = createAction(ForecastActionEnum.LOAD, props<ForecastModel.LoadQueryParam>());
	export const save = createAction(ForecastActionEnum.SAVE, props<BaseStateModel<ForecastModel.Document>>());
	export const saveComplete = createAction(
		ForecastActionEnum.SAVE_COMPLETE,
		props<BaseStateModel<ForecastModel.Document>>()
	);
	export const update = createAction(
		ForecastActionEnum.UPDATE,
		props<BaseStateModel<ForecastModel.Document>>()
	);
	export const reset = createAction(ForecastActionEnum.RESET);
	export const error = createAction(ForecastActionEnum.ERROR, props<BaseStateModel<any>>());
}
