// model
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '@saep-ict/angular-spin8-core';
// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ContextCodeManagementActionEnum, ContextCodeManagementStateAction } from './context-code-management.actions';

export namespace ContextCodeManagementStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchModel[]> = null;
	const _reducer = createReducer(
		initialState,
		on(ContextCodeManagementStateAction.loadList, state => state),
		on(ContextCodeManagementStateAction.updateList, (state, param) => param),
		on(ContextCodeManagementStateAction.updateItem, (state, param) => param),
		on(ContextCodeManagementStateAction.saveItemSuccess, (state, param) => param),
		on(ContextCodeManagementStateAction.deleteItemSuccess, (state, param) => param),
		on(ContextCodeManagementStateAction.reset, () => initialState),
		on(ContextCodeManagementStateAction.error, (state, param) => param)
	);

	export function reducer(
		state: BaseStateModel<BodyTablePouchCustomModel[]>,
		action: TypedAction<ContextCodeManagementActionEnum>
	) {
		return _reducer(state, action);
	}
}
