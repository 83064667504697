import { Directive, ViewChild } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';

// model
import { LocalListHandlerBaseApplyFilterParam, LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';

// misc
import { ITdDataTableSortChangeEvent, TdDataTableComponent, TdDataTableSortingOrder } from '@covalent/core/data-table';
import { AngularCoreUtilService } from '../../service/util/util.service';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { ListSettingStoreAction } from '../../store/list-setting/list-setting.actions';
import { UtilListSetting } from '../util/util-list-setting';
import { ListStructureEnum } from '@saep-ict/pouch_agent_models';
import { ITdDataTableColumnCustom } from '../../model/structure/list-structure.model';

@Directive()
export abstract class LocalListHandlerBase {
	constructor(
    public utilService: AngularCoreUtilService,
    public store: Store
  ) {}

	isLoading = true;

	@ViewChild('matPaginator', { static: false }) matPaginator: MatPaginator;
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	localListHandlerData: LocalListHandlerBaseModel<any>;

  /**
	 * Il metodo filtra i risultati tramite una regex che verifica sul valore di ogni proprietà dell'array key_list
	 * la corrispondenza parziale di value
	 *
	 * localSearchText?: {
	 *   alue: string,
	 *   key_list: string[]
	 * };
   *
   */
	localListHandlerFilterByText() {
		if (
			this.localListHandlerData.filters &&
			this.localListHandlerData.filters.localSearchText &&
			this.localListHandlerData.filters.localSearchText.value &&
			/\S/.test(this.localListHandlerData.filters.localSearchText.value)
		) {
      this.localListHandlerData.dataSubset = [];
			const regex = new RegExp(this.localListHandlerData.filters.localSearchText.value, 'i');
			for (let i = 0; i < this.localListHandlerData.data.length; i++) {
				for (let k = 0; k < this.localListHandlerData.filters.localSearchText.key_list.length; k++) {
          if (
            this.utilService.testObjectNestedValue(
              this.localListHandlerData.data[i],
              this.localListHandlerData.filters.localSearchText.key_list[k].toString(),
              regex,
              this.localListHandlerData.languageKey
            )
          ) {
						// serve clonare il data[i] altrimenti svuota il `product_list`
						// (lo faceva anche prima dell'introduzione della ricerca per array nel `checkObjectNestedValueByPath`?).
						// Non so perché ma so che ci ho perso una giornata
						this.localListHandlerData.dataSubset.push(_.cloneDeep(this.localListHandlerData.data[i]));
						break;
					}
				}
			}
		} else {
			this.localListHandlerData.dataSubset = JSON.parse(JSON.stringify(this.localListHandlerData.data));
		}
		if(this.localListHandlerData.pagination){
			this.localListHandlerData.pagination.totalFilteredItemCount = JSON.parse(
				JSON.stringify(this.localListHandlerData.dataSubset.length)
			);
		}
	}

  // TODO: il controllo permette l'applicazione di due diverse funzioni di comparazione in caso si tratti
  // di numeri o stringhe. Da gestire il caso misto
	localListHandlerSort(e: ITdDataTableSortChangeEvent) {
    if (e) {
			// salvataggio dei dati applicati durante l'ultimo sort change di td-data-table
			this.localListHandlerData.sort = _.cloneDeep(e);
		}
    let sort: ITdDataTableSortChangeEvent = _.cloneDeep(this.localListHandlerData.sort);
		if (
			this.localListHandlerData.dataSubset.length &&
			sort &&
			sort.name &&
			sort.order
		) {
      // oggetto che distingue il tipo di valori da ordinare (number, string)
      let itemTypeCheck: string;
      // oggetto di appoggio che identifica l'elemento di colonna che ha scatenato l'evento e presso il quale potrebbe
      // essere stato configurata la tipologia di valore da ordinare
      let columnItem: ITdDataTableColumnCustom;
      if (this.localListHandlerData.columnList) {
        // nel caso localListHandlerData contenga columnList, in essa viene cercata la proprietà che si vuole usare per
        // l'ordinamento
        columnItem = this.localListHandlerData.columnList.find(i => i.name === sort.name);
      }
      // itemTypeCheck viene popolato con columnItem.sortType; in mancanza con il typeof del primo elemento contenuto
      // nell'array localListHandlerData.dataSubset
      itemTypeCheck =
        columnItem && columnItem.sortType ?
        columnItem.sortType :
        typeof this.utilService.returnObjectNestedValue(
          this.localListHandlerData.dataSubset[0],
          sort.name,
          this.localListHandlerData.languageKey
        );
      if (
        this.localListHandlerData.sortRemapObject &&
        this.localListHandlerData.sortRemapObject[sort.name]
      ) {
        // applicazione di un eventuale remap della stringa usata come `name`. Quando restituita da td-data-table, potrebbe non
        // coincidere con il path convenzionato per raggiungere il valore desiderato all'interno degli elementi da ordinare
        // questo passaggio è fatto dopo il check del tipo di valori in quanto il name da qui in avanti potrebbe essere
        // riassegnato a qualcosa di non più coerente con quanto imputato nell'array columnList
        sort.name = this.localListHandlerData.sortRemapObject[sort.name]
      }
      if (itemTypeCheck) {
        switch(itemTypeCheck) {
          case ListStructureEnum.Sort.Type.NUMBER:
            this.localListHandlerData.dataSubset = this.localListHandlerData.dataSubset.sort((a, b) => {
              return (
                this.utilService.returnObjectNestedValue(a, sort.name, this.localListHandlerData.languageKey) -
                this.utilService.returnObjectNestedValue(b, sort.name, this.localListHandlerData.languageKey)
              );
            });
            break;
          case ListStructureEnum.Sort.Type.STRING:
            this.localListHandlerData.dataSubset = this.localListHandlerData.dataSubset.sort((a, b) => {
              const nestedValueA = this.utilService.returnObjectNestedValue(a, sort.name, this.localListHandlerData.languageKey);
              const nestedValueB = this.utilService.returnObjectNestedValue(b, sort.name, this.localListHandlerData.languageKey);
              if (nestedValueA && nestedValueB) {
                return nestedValueA.toLowerCase().localeCompare(nestedValueB.toLowerCase());
              }
              return nestedValueA ? -1 : nestedValueB ? 1 : 0;
            });
            break;
        }
        if (sort.order === TdDataTableSortingOrder.Descending) {
          // effettua l'inversione dell'array
          this.localListHandlerData.dataSubset.reverse();
        }
      }
		}
	}

	localListHandlerGoToPage(e: PageEvent) {
		if(this.localListHandlerData.pagination){
			if (e) {
				// salvataggio dei dati applicati durante l'ultimo change di mat-paginator: soltanto pageSize è davvero
				// indispensabile al flusso di paginazione, pageIndex viene tuttavia salvato per permetterne l'eventuale
				// recupero in caso sia necessario ripristinare il set totale di filtri applicati a localListHandlerData.dataSubset
				this.localListHandlerData.pagination.pageSize = e.pageSize;
				this.localListHandlerData.pagination.pageIndex = e.pageIndex;
			} else {
				// se il parametro è assente viene settato comunque per la restituione di pagina 1
				this.localListHandlerData.pagination.pageIndex = 0;
				// il controllo evità l'undefined sul ViewChild quando il metodo viene lanciato
				// in corrispondenza della getList. In questo caso matPaginator è già settato sulla prima pagina
				if (this.matPaginator) {
					this.matPaginator.firstPage();
				}
			}
			const fromRow = this.localListHandlerData.pagination.pageSize * this.localListHandlerData.pagination.pageIndex;
			let toRow;
			if (this.localListHandlerData.pagination.pageSize) {
				toRow = this.localListHandlerData.pagination.pageSize * (this.localListHandlerData.pagination.pageIndex + 1);
			} else {
				toRow =	this.localListHandlerData.data.length - 1;
			}
			this.localListHandlerData.dataSubset = this.localListHandlerData.dataSubset.slice(fromRow, toRow);
		}
	}

  localListHandlerUpdateStoreListSetting() {
    if (this.localListHandlerData.pageName) {
      this.store.dispatch(
        ListSettingStoreAction.update(
          UtilListSetting.returnListSettingForStoreUpdate(this.localListHandlerData)
        )
      );
    }
  }

  /**
	 * Il metodo scatena a cascata una serie di filtri sugli elementi dell'array localListHandlerData.dataSubset
	 * - localListHandlerFilterByText()
	 * - localListHandlerSort()
	 * - localListHandlerGoToPage()
   * - localListHandlerUpdateStoreListSetting()
	 *
	 * Nel caso in cui venga invocato senza parametro 'e' riporta in visualizzazione la prima pagina.
	 * Questo avviene tipicamente nei casi:
	 * - getList del componente che estende la classe LocalListHandlerBase
	 * - applicazione del filtro by text (es: td-search-box)
   *
   * @param e
   * @param sortRemapObject opzionale oggetto per il map delle proprietà invocate per il sort, le quali spesso non coincidono
   * con quanto emesso nell'evento di sort di td-data-table
   * Es: la dichiarazione della tabella `description` viene sovrascritta dal path convenzionato per raggiungere il valore
   * desiderato dell'ipotetico `article`
   *
   * {
   *   description: 'articleDescription.language_list.description'
   * }
   *
   */
	localListHandlerApplyFilter(e?: LocalListHandlerBaseApplyFilterParam) {
		this.isLoading = false;
		this.localListHandlerFilterByText();
		this.localListHandlerSort(e ? e.sort : null);
		this.localListHandlerGoToPage(e && e.pagination ? e.pagination : null);
    this.localListHandlerUpdateStoreListSetting();
	}

	updateListPageBaseData<T>(list: T[]) {
    if (!this.localListHandlerData.data) {
      this.localListHandlerData.data = [];
    }
		this.localListHandlerData.data = _.cloneDeep(list);
		this.localListHandlerData = Object.assign({}, this.localListHandlerData);
		this.localListHandlerApplyFilter();
	}
}
