import { Injector } from "@angular/core";
import { DateMomentService } from "@saep-ict/angular-core";
import { ITdDataTableColumnCustom } from "@saep-ict/pouch_agent_models";

const injector = Injector.create({
	providers: [{ provide: DateMomentService, deps: [] }]
});

const dateMomentService = injector.get(DateMomentService);

export const baseColumns: ITdDataTableColumnCustom[] = [
    {
        name: '_id',
        labelPath: 'opportunity.field.document_number',
        label: null,
        width: 120
    },
    {
        name: 'date_creation',
        labelPath: 'opportunity.field.date_creation',
        label: null,
        format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
    },
    {
        name: 'organization.business_name',
        labelPath: 'theme.opportunity.field.business_name',
        label: null
    },
    {
        name: 'organization.organization_class',
        labelPath: 'theme.organization.field.class',
        label: null
    },
    {
        name: 'organization.contact_list',
        labelPath: 'opportunity.field.email',
        label: null
    }
];