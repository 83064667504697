import { OrderStatusEnum, Company } from './order-pouch.model';

export interface ChartStateValuePouchModel {
  client_code: string;
  user_code: string;
  date: number;
  status: OrderStatusEnum;
  total: number;
  company: Company;
  date_formatted?: string;
}

// Struttura che mettiamo nello store
export interface OrderStatisticsList {
  draft?: OrderStatisticsStatus;
  to_authorize?: OrderStatisticsStatus;
  not_authorized?: OrderStatisticsStatus;
  ready_to_send?: OrderStatisticsStatus;
  queue?: OrderStatisticsStatus;
  sending?: OrderStatisticsStatus;
  processing?: OrderStatisticsStatus;
  consolidated?: OrderStatisticsStatus;
  archived?: OrderStatisticsStatus;
  deleted?: OrderStatisticsStatus;
  fulfilled?: OrderStatisticsStatus;
  partially_fulfilled?: OrderStatisticsStatus;
  error_preparing?: OrderStatisticsStatus;
  error_sending?: OrderStatisticsStatus;
}

export interface OrderStatisticsStatus {
  count?: number;
  total?: number;
  activeClients?: number;
  average?: number;
  week?: OrderStatistics;
  twoWeek?: OrderStatistics;
  month?: OrderStatistics;
  twoMonth?: OrderStatistics;
  threeMonth?: OrderStatistics;
}

export interface OrderStatistics {
  count: number;
  total: number;
  activeClients: number;
  average?: number;
  orders?: ChartStateValuePouchModel[];
  daily?: OrderStatistics[];
}
