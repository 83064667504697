export namespace PouchDbEnum {
	export enum CustomAdapter {
		AGENT = 'pouchDbAgentAdapter',
		BACKOFFICE = 'pouchDbBackofficeAdapter',
		COMMONS = 'pouchDbCommonsAdapter',
		COMMONS_AGENT = 'pouchDbCommonsAgentAdapter',
		COMMONS_ARTICLE = 'pouchDbCommonsArticleAdapter',
		COMMONS_ASSET_REQUEST = 'pouchDbCommonsAssetRequestAdapter',
		COMMONS_BACKOFFICE = 'pouchDbCommonsBackofficeAdapter',
		COMMONS_CONTACT = 'pouchDbCommonsContactAdapter',
		COMMONS_CRM = 'pouchDbCommonsCrmAdapter',
		COMMONS_ORDER = 'pouchDbCommonsOrderAdapter',
		COMMONS_ORGANIZATION = 'pouchDbCommonsOrganizationAdapter',
		COMMONS_REQUEST = 'pouchdbCommonsRequest',
		COMMONS_USER = 'pouchDbCommonsUserAdapter',
		CRM = 'pouchDbCrmAdapter',
		GENERAL = 'pouchDbGeneralAdapter',
		ORGANIZATION_B2B = 'pouchDbOrganizationB2BAdapter',
		ORGANIZATION_B2C = 'pouchDbOrganizationB2CAdapter',
		ORGANIZATION_PORTAL = 'pouchDbOrganizationPortalAdapter'
	}
	export enum DocumentPlaceholder {
		AGENT_CODE_PLACEHOLDER = '[agent_code_placeholder]',
		BACKOFFICE_CODE_PLACEHOLDER = '[backoffice_code_placeholder]',
		CRM_CODE_PLACEHOLDER = '[crm_code_placeholder]',
		ORGANIZATION_CODE_PLACEHOLDER = '[organization_code_placeholder]'
	}
}
