import { Component } from '@angular/core';
import { BaseIllustrationComponent } from './base-illustration.component';

@Component({
	selector: 'app-illustration-account',
	styleUrls: ['illustrations.scss'],
	template: `
		<svg
			id="29e4b1a0-13d0-4a0f-9be2-3c3977a6a4ba"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			width="785"
			height="753.73"
			viewBox="0 0 785 753.73"
		>
			<defs>
				<linearGradient
					id="b4c3f788-9d51-49bd-96f9-c54f9fd51398"
					x1="452.8"
					y1="753.73"
					x2="452.8"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stop-color="gray" stop-opacity="0.25" />
					<stop offset="0.54" stop-color="gray" stop-opacity="0.12" />
					<stop offset="1" stop-color="gray" stop-opacity="0.1" />
				</linearGradient>
				<linearGradient
					id="778ed1c4-cafd-494e-8a04-dc2b91aee6fd"
					x1="209.37"
					y1="339.46"
					x2="209.37"
					y2="101.61"
					xlink:href="#b4c3f788-9d51-49bd-96f9-c54f9fd51398"
				/>
				<linearGradient
					id="92c6cb1b-63b4-4a00-ae9b-05b36bfadf3e"
					x1="32.38"
					y1="227.8"
					x2="174.2"
					y2="227.8"
					xlink:href="#b4c3f788-9d51-49bd-96f9-c54f9fd51398"
				/>
				<linearGradient
					id="3111d6ac-9e05-4a73-9878-bd628330955d"
					x1="211.6"
					y1="606.34"
					x2="211.6"
					y2="582.89"
					xlink:href="#b4c3f788-9d51-49bd-96f9-c54f9fd51398"
				/>
				<linearGradient
					id="2e076d7a-ee6b-46b6-b939-69cbad87608e"
					x1="572.28"
					y1="718"
					x2="572.28"
					y2="646.54"
					xlink:href="#b4c3f788-9d51-49bd-96f9-c54f9fd51398"
				/>
				<linearGradient
					id="acad6429-7160-42af-9a00-db2d81d2388a"
					x1="630.34"
					y1="245.66"
					x2="630.34"
					y2="91.56"
					xlink:href="#b4c3f788-9d51-49bd-96f9-c54f9fd51398"
				/>
			</defs>
			<title>account</title>
			<rect x="120.6" width="664.4" height="753.73" fill="url(#b4c3f788-9d51-49bd-96f9-c54f9fd51398)" />
			<rect x="130.65" y="14.52" width="643.19" height="725.82" fill="#fff" />
			<rect x="65.88" y="134" width="365.14" height="232.26" [attr.fill]="color" opacity="0.2" />
			<rect y="101.61" width="418.74" height="237.84" fill="url(#778ed1c4-cafd-494e-8a04-dc2b91aee6fd)" />
			<rect x="4.47" y="136.23" width="408.69" height="197.65" fill="#fff" />
			<rect
				x="32.38"
				y="152.98"
				width="141.81"
				height="149.63"
				fill="url(#92c6cb1b-63b4-4a00-ae9b-05b36bfadf3e)"
			/>
			<rect x="36.85" y="156.33" width="134" height="142.93" [attr.fill]="color" />
			<path
				d="M348.77,285.9c-1-8.49-2.5-17-5.75-24.94s-8.47-15.24-15.74-19.75a18,18,0,0,0-8.7-3c-5.69-.2-10.95,3.64-13.88,8.52a39.74,39.74,0,0,0-2.59,5.27,12.27,12.27,0,0,0-10.18,11.24,31.28,31.28,0,0,0,4.42,59.06v5.33a31.39,31.39,0,0,0-23.45,30.27v14.52h62.53V357.88A31.39,31.39,0,0,0,312,327.61v-5.33A31.28,31.28,0,0,0,335.45,292c0-.07,0-.15,0-.22l3.52,7.83c3.35,7.46,6.78,15.46,5.47,23.54C350.86,312.15,350.33,298.54,348.77,285.9Z"
				transform="translate(-207.5 -73.13)"
				fill="#fff"
			/>
			<rect x="4.47" y="106.08" width="408.69" height="30.15" [attr.fill]="color" />
			<rect x="225.56" y="198.2" width="155.21" height="10.05" fill="#e0e0e0" />
			<rect x="225.56" y="240.64" width="155.21" height="10.05" fill="#f5f5f5" />
			<rect x="224.45" y="219.42" width="91.56" height="10.05" [attr.fill]="color" opacity="0.2" />
			<rect x="224.45" y="261.85" width="62.53" height="10.05" fill="#69f0ae" opacity="0.2" />
			<rect x="298.14" y="261.85" width="62.53" height="10.05" fill="#69f0ae" />
			<rect x="185.36" y="419.86" width="539.34" height="18.98" fill="#e0e0e0" />
			<rect x="185.36" y="461.17" width="179.78" height="18.98" fill="#e0e0e0" />
			<rect x="544.92" y="461.17" width="179.78" height="18.98" fill="#69f0ae" />
			<rect x="185.36" y="502.49" width="539.34" height="18.98" fill="#e0e0e0" />
			<rect x="185.36" y="543.81" width="539.34" height="18.98" fill="#e0e0e0" />
			<rect
				x="183.13"
				y="582.89"
				width="56.95"
				height="23.45"
				fill="url(#3111d6ac-9e05-4a73-9878-bd628330955d)"
			/>
			<rect x="185.36" y="585.12" width="53.6" height="18.98" [attr.fill]="color" />
			<rect x="426.56" y="585.12" width="53.6" height="18.98" fill="#69f0ae" />
			<rect x="671.1" y="585.12" width="53.6" height="18.98" fill="#69f0ae" />
			<rect
				x="413.16"
				y="646.54"
				width="318.24"
				height="71.47"
				fill="url(#2e076d7a-ee6b-46b6-b939-69cbad87608e)"
			/>
			<rect x="418.74" y="654.35" width="305.96" height="54.72" [attr.fill]="color" />
			<rect x="418.74" y="654.35" width="305.96" height="54.72" opacity="0.2" />
			<rect
				x="529.29"
				y="91.56"
				width="202.11"
				height="154.1"
				fill="url(#acad6429-7160-42af-9a00-db2d81d2388a)"
			/>
			<rect x="537.11" y="101.61" width="187.6" height="134" [attr.fill]="color" />
			<rect x="537.11" y="101.61" width="187.6" height="134" fill="#fff" opacity="0.2" />
		</svg>
	`
})
export class IllustrationAccountComponent extends BaseIllustrationComponent {}
