import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StatisticGroupActionEnum, StatisticGroupStateAction } from './statistic-group.action';
import * as StatisticGroupModel from '../../../model/statistics-group.model';
import { PouchDbConstant, PouchDbModel } from '@saep-ict/angular-spin8-core';
import { UtilPouchService } from '../../../service/util/util-pouch.service';

@Injectable()
export class StatisticGroupEffects {

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticGroupActionEnum.LOAD_ALL),
			mergeMap((action: StatisticGroupModel.LoadAllParam) => from(
				this.utilPouchService.allDocs<StatisticGroupModel.Document>(
					{
						include_docs: true,
						startkey: action.documentType + '_',
						endkey: action.documentType + '_' + PouchDbConstant.allDocsLastCharacter
					},
					PouchDbModel.PouchDbDocumentType.STATISTICS
				)
			)),
			map((action: BaseStateModel<StatisticGroupModel.Document[]>) => StatisticGroupStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticGroupStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPouchService: UtilPouchService
	) {}

}
