import { Inject, Injectable } from "@angular/core";
import { MediaCenterModel } from '../model/media-center.model';
import { MediaCenterService } from "./rest/media-center.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "./util.service";
import { APP_CONFIG_TOKEN, IMediaCenterAppConfig } from "../interface/media-center-app-config.interface";
import {
  UtilDownload,
  RxStompService,
  WebSocketMessageModel,
  WebSocketActionEnum,
  WebSocketChannelTypeCodeEnum,
  WebSocketTopicStateEnum
} from '@saep-ict/angular-core';

@Injectable({
	providedIn: "root",
})
export class UtilDownloadService {

	downloadThroughIframeId = this.utilService.guid();
    // Anche se non utilizzato nel template va assegnato in modo non perda il contesto
  // this all'interno del metodo `downloadArchiveSimulatingClick`
  utilDownload = UtilDownload;

	constructor(
		public mediaCenterService: MediaCenterService,
		public utilService: UtilService,
		public snackbar: MatSnackBar,
		public translate: TranslateService,
    private rxStompService: RxStompService,
    @Inject(APP_CONFIG_TOKEN) protected appConfig: IMediaCenterAppConfig,
	) {}

  ngOnDestroy(): void {
    UtilDownload.downloadThroughIframeRemove(this.downloadThroughIframeId);
  }

  downloadHandler($event: any, row?: MediaCenterModel) {
    if (
      !row &&
      this.utilService.itemListSelected &&
      this.utilService.itemListSelected.length === 1
    ) {
      row = this.utilService.itemListSelected[0];
    }
    if (row) {
      if (row.fileType !== 'folder') {
        this.downloadSingleFile(row);
      } else {
        this.downloadArchiveZip([row.path]);
        // TODO: deprecare
        // this.webSocketService.establishConnectionArchiveDownloadConnection([
        //   this.utilDownloadService.prepareRequestForWebSocketArchiveDownload([
        //     row,
        //   ]),
        // ]);
      }
    } else {
      const pathList: string[] = this.utilService.itemListSelected.map(i => i.path);
      this.downloadArchiveZip(pathList);
    }
    $event.stopPropagation();
  }

	downloadSingleFile(file: MediaCenterModel) {
    let endpointDynamicSegment: string;
    if (
      this.utilService.context.value &&
      this.utilService.context.value.filter &&
      this.utilService.context.value.filter.bucketFolderName
    ) {
      endpointDynamicSegment = `${this.utilService.context.value.filter.bucketFolderName}/download-multipart?path=${file.path}`;
    } else {
      endpointDynamicSegment = `download-multipart?path=${file.path}`;
    }
    UtilDownload.handleDownloadThroughIframe(
      `${this.appConfig.mc_config.be_url}/${endpointDynamicSegment}&token=${this.appConfig.token}`,
      this.downloadThroughIframeId
    );
    this.pushDownloadEventTracking(file);
	}

  async downloadArchiveZip(pathList: string[]) {
    let pathListSting = '';
    for (let i = 0; i < pathList.length; i++) {
      pathListSting += pathList[i];
      if (i !== pathList.length - 1) {
        pathListSting += ',';
      }
    }
    const message: WebSocketMessageModel<never, string> = {
      type: WebSocketChannelTypeCodeEnum.ARCHIVE_ZIP_DOWNLOAD,
      message: {
          header: {
              id: this.utilService.guid(),
              action: WebSocketActionEnum.START_PROCESS,
              state: WebSocketTopicStateEnum.REQUESTED,
              progress: 0
          }
      }
    };
    await this.rxStompService.connect<UtilDownloadService, void, string>(
      this,
      message,
      {
        COMPLETED: this.downloadArchiveSimulatingClick,
        ERROR: this.downloadArchiveWebsocketError
      }
    );
    this.mediaCenterService.downloadZip({
      pathList: pathListSting,
      topic: message.message.header.id,
      token: this.appConfig.token
    });
	}

  // TODO: deprecare seguenti util usate nella vecchia gestione del donwload, dipendenza di websocket.service

	// downloadBase64FromWebSocketArchiveDownload(base64: string) {
	// 	base64 = base64.split("b'")[1].slice(0, -1);
	// 	UtilDownload.downloadBase64(
	// 		base64,
	// 		"application/zip",
	// 		"media-center-archive.zip"
	// 	);
	// }

	// prepareRequestForWebSocketArchiveDownload(itemList: MediaCenterModel[]): WebSocketMessageDownloadArchiveRequest {
	// 	const requestBody = <WebSocketMessageDownloadArchiveRequest> {
	// 		path_list: []
	// 	};
	// 	itemList.forEach(i => {
	// 		requestBody.path_list.push(i.path);
	// 	});
	// 	return requestBody;
	// }

  // RxStompService messageEventHandle function
  downloadArchiveSimulatingClick(
    context: UtilDownloadService,
    message: WebSocketMessageModel<never, string>
  ) {
    let endpointDynamicSegment: string;
    if (
      context.utilService.context.value &&
      context.utilService.context.value.filter &&
      context.utilService.context.value.filter.bucketFolderName
    ) {
      endpointDynamicSegment =
        `${context.utilService.context.value.filter.bucketFolderName}/download-multipart?path=${message.message.data}&remove=true`;
    } else {
      endpointDynamicSegment = `download-multipart?path=${message.message.data}&remove=true`;
    }
    context.utilDownload.handleDownloadThroughIframe(
      `${context.appConfig.mc_config.be_url}/${endpointDynamicSegment}&token=${context.appConfig.token}`,
      context.downloadThroughIframeId
    );
    context.pushDownloadEventTracking({
      name: 'Archive ZIP',
      path: message.message.data
    });
  }

  downloadArchiveWebsocketError(
    context: UtilDownloadService,
    message: WebSocketMessageModel<never, string>
  ) {
    context.utilService.loadSnackbarWithAsyncTranslatedAction(
      'mc.error.websocket_generic_fail',
      'mc.general.ok',
      5000
    );
  }

  pushDownloadEventTracking(item: MediaCenterModel) {
    window['dataLayer'].push({
      event: 'downloadDaMediaCenter',
      percorso: item.path,
      nome_file: item.name
    });
  }


}
