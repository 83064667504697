
import { ITdDataTableColumnCustom } from "@saep-ict/angular-core";
import moment from "moment";

export const invoiceAttachmentListManagerColumnListBase: ITdDataTableColumnCustom[] = [
  {
    name: 'preview',
    label: null,
    width: 40,
    sortable: false
  },
  {
    name: 'name',
    labelPath: 'saep_ict_angular_core.attachment_list_manager.column_list.name',
    label: null
  },
  {
    name: 'date_creation',
    labelPath: 'saep_ict_angular_core.attachment_list_manager.column_list.date_creation',
    label: null,
    format: (v) => v ? moment(v).format('DD/MM/YYYY h:mm a') : ''
  },
  {
    name: 'date_update',
    labelPath: 'saep_ict_angular_core.attachment_list_manager.column_list.date_update',
    label: null,
    format: (v) => v ? moment(v).format('DD/MM/YYYY h:mm a') : ''
  },
  {
    name: 'action',
    label: null,
    width: 100,
    numeric: true,
    sortable: false
  }
];
