import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { NewsModel } from '../../model/news.model';

export enum NewsDetailActionEnum {
	LOAD = '[news-detail] Load',
	SAVE = '[news-detail] Save',
	SAVE_COMPLETE = '[news-detail] Save complete',
	SAVE_ATTACHMENT = '[news-detail] Save attachment',
	SAVE_ATTACHMENT_COMPLETE = '[news-detail] Save attachment complete',
	UPDATE = '[news-detail] Update',
	RESET = '[news-detail] Reset',
	ERROR = '[news-detail] Error'
}

export namespace NewsDetailStateAction {
	export const load = createAction(NewsDetailActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const save = createAction(NewsDetailActionEnum.SAVE, props<BaseStateModel<NewsModel>>());
	export const saveComplete = createAction(NewsDetailActionEnum.SAVE_COMPLETE, props<BaseStateModel<NewsModel>>());
	export const saveAttachment = createAction(NewsDetailActionEnum.SAVE_ATTACHMENT, props<BaseStateModel<NewsModel>>());
	export const saveAttachmentComplete =
		createAction(NewsDetailActionEnum.SAVE_ATTACHMENT_COMPLETE, props<BaseStateModel<NewsModel>>());
	export const update = createAction(NewsDetailActionEnum.UPDATE, props<BaseStateModel<NewsModel>>());
	export const reset = createAction(NewsDetailActionEnum.RESET);
	export const error = createAction(NewsDetailActionEnum.ERROR, props<BaseStateModel<any>>());
}
