// model
import { TicketModel } from './../../model/lib.model';

// store
import { createAction, props } from '@ngrx/store';

// misc
import { BaseStateModel } from '@saep-ict/angular-core';

export enum TicketCenterTicketActionEnum {
	UPDATE = '[ticket] Update',
	LOAD = '[ticket] Load',
  ADD_OR_UPDATE_ITEM = '[ticket] Add or update item',
  RESET = '[ticket] Reset',
	ERROR = '[ticket] Error'
}

export namespace TicketCenterTicketStoreAction {
  export const update = createAction(TicketCenterTicketActionEnum.UPDATE, props<BaseStateModel<TicketModel<any>[]>>());
  export const load = createAction(TicketCenterTicketActionEnum.LOAD);
  export const addItemOrUpdateItem = createAction(TicketCenterTicketActionEnum.ADD_OR_UPDATE_ITEM, props<BaseStateModel<TicketModel<any>>>());
  export const reset = createAction(TicketCenterTicketActionEnum.RESET);
  export const error = createAction(TicketCenterTicketActionEnum.ERROR, props<BaseStateModel<TicketModel<any>[]>>());
}
