import { ContactEmailConfirmation } from '../page/commons/authentication-wrapper/contact-email-confirmation/contact-email-confirmation.component';
import { Routes } from '@angular/router';
import { DownloadCouchComponent } from '../page/commons/download-couch/download-couch.component';
import { AuthenticationWrapperComponent } from '../page/commons/authentication-wrapper/authentication-wrapper.component';
import { NotFoundComponent } from '../page/commons/not-found/not-found.component';
import { ProbeTestComponent } from '../page/commons/probe-test/probe-test.component';
import { PasswordCreateComponent } from '../page/commons/authentication-wrapper/password-create/password-create.component';
import { AuthLoginGuard } from '../service/guard/auth-login.guard';
import { AuthTokenGuard } from '../service/guard/auth-token.guard';
import { AuthUserStateGuard } from '../service/guard/auth-user-state.guard';
import { VerifyTokenEmailGuard } from '../service/guard/verify-token-email.guard';
import { ClientCodeSelectComponent } from '../page/commons/authentication-wrapper/context-selection/context-selection.component';
import { LoginComponent } from './../page/commons/authentication-wrapper/login/login.component';
import { PasswordRecoveryComponent } from './../page/commons/authentication-wrapper/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from '../page/commons/authentication-wrapper/password-update/password-update.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import * as PublicRouteList from './public-routing';
import { AccessRequestComponent } from '../page/commons/authentication-wrapper/access-request/access-request.component';
import { UserComponent } from '../page/commons/user/user.component';
import { FramePageComponent } from '../frame/admin/admin.component';
import { ROUTE_URL, UserConstant } from '@saep-ict/angular-spin8-core';

export const routes: Routes = [
	{
		path: ROUTE_URL.authentication,
		component: AuthenticationWrapperComponent,
		resolve: {
			meta: MetaDataResolver
		},
		data: {
			meta: {
				title: 'Login',
				description: "Pagina di autenticazione dell'utente"
			}
		},
		children: [
			{
				path: '',
				redirectTo: ROUTE_URL.login,
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.login,
				canActivate: [AuthLoginGuard],
				component: LoginComponent,
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.passwordRecovery,
				canActivate: [AuthLoginGuard],
				component: PasswordRecoveryComponent,
				pathMatch: 'full',
				data: {
					redirectLink: ['/', ROUTE_URL.authentication, ROUTE_URL.login],
					cancelLink: ['/', ROUTE_URL.authentication, ROUTE_URL.login]
				}
			},
			{
				path: ROUTE_URL.passwordUpdate,
				canActivate: [AuthTokenGuard],
				component: PasswordUpdateComponent,
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.contextSelection,
				canActivate: [AuthUserStateGuard],
				component: ClientCodeSelectComponent,
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.accessRequest,
				component: AccessRequestComponent,
				pathMatch: 'full'
			}
		]
	},
	{
		path: 'contact-activation/:email/:customerCode/:idRecipient/:guid',
		component: ContactEmailConfirmation,
		pathMatch: 'full'
	},
	{
		path: 'user-activation/:type/:email/:guid',
		component: AuthenticationWrapperComponent,
		canActivate: [VerifyTokenEmailGuard],
		children: [
			{
				path: '',
				component: PasswordCreateComponent,
				pathMatch: 'full',
				data: { redirectLink: [ROUTE_URL.authentication, ROUTE_URL.login] }
			}
		]
	},
	{
		path: ROUTE_URL.verifyTokenError,
		component: NotFoundComponent,
		pathMatch: 'full'
	},
	// user profile
	{
		path: `${ROUTE_URL.private}/${ROUTE_URL.user}`,
		component: FramePageComponent,
		canActivate: [AuthTokenGuard],
		children: [
			{
				path: '',
				component: UserComponent,
				pathMatch: 'full',
				data: {
					meta: {
						title: 'Profilo utente',
						description: "Sezione del profilo dell'utente"
					}
				},
				resolve: {
					meta: MetaDataResolver
				}
			}
		]
	},
	{
		path: '',
		data: {
			id: UserConstant.PUBLIC_USER_ENTRY
		},
		canActivate: [AuthTokenGuard],
		children: PublicRouteList.routes
	},
	// {
	// 	path: ROUTE_URL.public,
	// 	data: {
	// 		id: UserConstant.PUBLIC_USER_ENTRY
	// 	},
	// 	canActivate: [AuthTokenGuard],
	// 	children: PublicRouteList.routes
	// },
	{
		path: ROUTE_URL.private,
		data: {
			id: UserConstant.AUTHENTICATED_USER_ENTRY
		},
		canActivate: [AuthTokenGuard],
		children: []
	},
	{
		path: ROUTE_URL.probeTest,
		component: ProbeTestComponent,
		pathMatch: 'full'
	},
	{
		path: ROUTE_URL.startupDownload,
		component: DownloadCouchComponent,
		pathMatch: 'full'
	},
	{
		path: '**',
		component: NotFoundComponent,
		// necessario per intercettare tutti i redirect alle route che non sono ancora
		// contemplate dal contesto se prima non sono aggiunte attraverso la guard sottostante
		canActivate: [AuthTokenGuard]
	}
];
