import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { ArticleListStructureWrapperBase } from '@saep-ict/angular-spin8-core';
import { ArticleTableConfigurationAction } from '../../../model/article.model';
import { VirtualScrollConfiguration } from '../../../model/virtual-scroll.model';


@Component({
	selector: 'article-stock-structure-wrapper-plain-list',
	templateUrl: './article-stock-structure-wrapper-plain-list.component.html',
	styleUrls: ['./article-stock-structure-wrapper-plain-list.component.scss']
})
export class ArticleStockStructureWrapperPlainListComponent extends ArticleListStructureWrapperBase {
	@Input() onItemSelectShow: boolean;
	@Input() paginatorOnTopShow = false;
	@Input('configurationAction') configurationAction: ArticleTableConfigurationAction;
	@Input() virtualScrollConfiguration: VirtualScrollConfiguration = {
		enabled: false
	};

	constructor(
		public store: Store,
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService
	) {
		super(utilService, translateService, store);
	}

}
