import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { ConfigurationUtil } from './configuration-util.model';
export namespace ConfigurationProjectModel {
	export namespace Util {
		export interface Property {
			key: string;
			active: boolean;
		}
	}
	export interface Organization {
		detail: ConfigurationUtil.Context.ApplicationSelector<boolean>;
		destination: ConfigurationUtil.Context.ApplicationSelector<boolean>;
		contact: ConfigurationUtil.Context.ApplicationSelector<boolean>;
	}
	export interface StoreAction {
		load_detail: ActionCreator<any, (props: any) => TypedAction<any>>;
		load_list: ActionCreator<any, (props: any) => TypedAction<any>>;
		reset: ActionCreator<any, () => TypedAction<any>>;
	}
	export interface Contact {
		send_email_confirmation: boolean;
		can_activate_email_order_receipt: boolean;
	}
	export namespace Article {
		export interface Property {
			price: ConfigurationProjectModel.Util.Property[];
			discount: ConfigurationProjectModel.Util.Property[];
		}
	}
	export interface AuthenticationWrapper {
		can_access_to_registration: boolean;
		link_access_to_registration: string;
	}
	export namespace UserManagement {
		export interface CanEditContext {
			context_code_item_code: number;
			can_edit: boolean;
		}
	}
	export interface ErpModel {
		has_erp: boolean;
	}
	export namespace Navigation {
		export interface MainSidebarItemModel {
			parentKey?: string;
			key: string;
			name: string;
			icon: string;
			route: any;
			position: number;
			permission?: string;
			badge?: string;
			badgeColor?: string;
			customClass?: string;
			iconCustom?: boolean;
			disableWhenOffline?: boolean;
		}
	}
}
