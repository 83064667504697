import {Component, Input, OnInit} from '@angular/core';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';
import {ArticleTableWrapperConfiguration} from "../../model/article-table-wrapper.model";

@Component({
    selector: 'spin8-address-table-heading',
    templateUrl: './address-table-heading.component.html',
    styleUrls: ['./address-table-heading.component.scss']
})
export class AddressTableHeadingComponent {

    public mainRow: string;
    public subRow: string;


    @Input() set address(address: AddressPouchModel) {
        const formattedAddress: string[] = [];

        if(address.locality) {
            formattedAddress.push(address.locality.toLowerCase());
        }

        if(address.province && address.province.label && address.province.label !== address.locality) {
            formattedAddress.push(address.province.label.toLowerCase());
        }

        if(address.zip_code) {
            formattedAddress.push(address.zip_code);
        }

        this.mainRow = formattedAddress.join(", ");
        this.subRow = address.address ? address.address.toLowerCase() : ' - ';
    }

}
