import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { NewsDetailActionEnum, NewsDetailStateAction } from './news-detail.actions';
import { NewsModel } from '../../model/news.model';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';

@Injectable()
export class NewsDetailEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NewsDetailActionEnum.LOAD),
			mergeMap((action: BaseStateModel<NewsModel>) => from(this.getDoc(action))),
			map((action: BaseStateModel<NewsModel>) => NewsDetailStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(NewsDetailStateAction.error(error));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NewsDetailActionEnum.SAVE),
			mergeMap((action: BaseStateModel<NewsModel>) => from(this.putDoc(action.data))),
			map((action: BaseStateModel<NewsModel>) => NewsDetailStateAction.saveComplete(action)),
			catchError((error, caught) => {
				this.store.dispatch(NewsDetailStateAction.error(error));
				return caught;
			})
		)
	);
	
	saveAttachment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NewsDetailActionEnum.SAVE_ATTACHMENT),
			mergeMap((action: BaseStateModel<NewsModel>) => from(this.putDoc(action.data))),
			map((action: BaseStateModel<NewsModel>) => NewsDetailStateAction.saveAttachmentComplete(action)),
			catchError((error, caught) => {
				this.store.dispatch(NewsDetailStateAction.error(error));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter
	) {}

	async getDoc(action: BaseStateModel<BasePouchModel>): Promise<BaseStateModel<NewsModel>> {
		return await this.pouchDbCommonsAdapter.basePouch
			.getDetail(action.data._id)
			.then((doc: any) => {
				return new BaseState(doc);
			})
			.catch(err => {
				throw new Error(err.message);
			});
	}

	async putDoc(data: NewsModel): Promise<BaseStateModel<NewsModel>> {
		return await this.pouchDbCommonsAdapter.basePouch
			.saveBodyTable(data)
			.then(async (news: NewsModel) => {
				return new BaseState(news);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
