import { Component, OnInit, Input } from '@angular/core';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { ArticleListCategoryTreeRecursionSummaryComponentConfiguration } from '../../../../model/article.model';

@Component({
	selector: 'article-list-category-tree-recursion-summary',
	templateUrl: './article-list-category-tree-recursion-summary.component.html',
	styleUrls: ['./article-list-category-tree-recursion-summary.component.scss']
})
export class ArticleListCategoryTreeRecursionSummaryComponent implements OnInit {
	@Input() configuration: ArticleListCategoryTreeRecursionSummaryComponentConfiguration;
	@Input() level = 1;

	constructor(public utilTranslateService: AngularSpin8CoreUtilTranslateService) {}

	ngOnInit() {}
}
