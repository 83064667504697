import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { CurrencyPipe } from '@angular/common';
import { ITdDataTableColumnKey } from '@saep-ict/angular-core';
import { UtilPriceService } from './../../../service/util/util-price.service';
import * as ConfigurationCustomerProductCatalogColumns from '../../../constants/configuration-customer/catalog/catalog-column-map/catalog-column-map-base'

@Component({
	selector: 'dialog-product-kit',
	templateUrl: './dialog-product-kit.component.html',
	styleUrls: ['./dialog-product-kit.component.scss']
})
export class DialogProductKitComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<DialogProductKitComponent>,
		private currencyPipe: CurrencyPipe,
        private utilPriceService: UtilPriceService
	) {}

	productList: ArticlePouchModel[];
	columns: ITdDataTableColumnKey[];

	ngOnInit() {
		this.productList = this.data.productList;
		this.columns = ConfigurationCustomerProductCatalogColumns.baseColumns;
	}

	retrieveArticlePrice(rowTable: ArticlePouchModel) {
		return rowTable.articlePrice.discount.length > 0 || rowTable.discount_agent
			? 'scontato: ' +
					this.currencyPipe.transform(
						this.utilPriceService.retrievePriceWithDiscount(
							rowTable.price ? rowTable.price : rowTable.articlePrice.price,
							rowTable.articlePrice.discount,
							rowTable.discount_agent ? rowTable.discount_agent : null
						),
						'EUR',
						'symbol',
						'',
						'it'
					)
			: null;
	}

	getCalculatePrice(rowTable: ArticlePouchModel) {
		const input_quantity = rowTable.input_quantity;
		const qty_free = rowTable.qty_free;
		const price = rowTable.articlePrice.price;

		return (input_quantity - qty_free) * price;
	}
}
