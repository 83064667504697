import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import * as ShippingEnum from '../../../enum/shipping.enum';

export const isCostToApply = (order: OrderStateModel): boolean => {
    if (
        !order.header.carrier ||
        (
            order.header.carrier.code_item &&
            order.header.carrier.code_item !== ShippingEnum.Carrier.RAC
        )
    ) {
        return true;
    } else {
        return false;
    }
}

export const updatePriceHandler = (order: OrderStateModel) => {
    if (
        order.header.additional_services.stock_type &&
        isCostToApply(order)
    ) {
        const ordered_quantity =
            order.header.additional_services.stock_type.ordered_quantity &&
            typeof order.header.additional_services.stock_type.ordered_quantity === 'number' ?
            order.header.additional_services.stock_type.ordered_quantity :
            0;
        const charge =
            order.header.additional_services.stock_type.charge &&
            typeof order.header.additional_services.stock_type.charge === 'number' ?
            order.header.additional_services.stock_type.charge :
            0;
        order.header.price['stock_type'] = ordered_quantity * charge;
    } else {
        order.header.price['stock_type'] = 0;
    }
}