import { Component, OnDestroy } from '@angular/core';
import { StatisticsDetailClientsStateAction } from '../../../../state/statistics-detail-clients/statistics-detail-clients.action';
import { StatisticsDetailOrdersStateAction } from '../../../../state/statistics-detail-orders/statistics-detail-orders.action';
import { Store } from '@ngrx/store';
import { StatisticsDetailClients } from '../../../../model/statistics-detail-clients.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel} from '@saep-ict/pouch_agent_models';
import { filter, take } from 'rxjs/operators';
import { StateFeature } from '../../../../state';
import { ActiveClientsChartConfigService } from '../../../../service/chart-structure/implementation/active-clients-chart.service';
import { InactiveClientsChartConfigService } from '../../../../service/chart-structure/implementation/inactive-clients-chart.service';
import { Observable } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { CardDashboardConfig } from '../../../../widget/card-dashboard/card-dashboard.component';

import { ClientsTop5AverageChartConfigService } from '../../../../service/chart-structure/implementation/clients-top-5-average-chart.service';
import { ClientsTop5QuantityChartConfigService } from '../../../../service/chart-structure/implementation/clients-top-5-quantity-chart.service';
import { ClientsTop5QuantityTableConfigService } from '../../../../service/chart-structure/implementation/clients-top-5-quantity-table.service';
import { ClientsTop5AverageTableConfigService } from '../../../../service/chart-structure/implementation/clients-top-5-average-table.service';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { StatisticsBaseSerieClient } from '../../../../model/statistics.model';
import { OrganizationStateModel, StatisticsDataKeyEnum, chartTimeRange30Days } from '@saep-ict/angular-spin8-core';
import * as ClientColumns from '../../../../constants/configuration-customer/client/client-column-map/client-column-map-base.constant';

@Component({
	selector: 'dashboard-agent-clients',
	templateUrl: './dashboard-agent-clients.component.html',
	styleUrls: ['./dashboard-agent-clients.component.scss'],
	providers: [
		ActiveClientsChartConfigService,
		InactiveClientsChartConfigService,
		ClientsTop5AverageChartConfigService,
		ClientsTop5QuantityChartConfigService,
		ClientsTop5AverageTableConfigService,
		ClientsTop5QuantityTableConfigService
	]
})
export class DashboardAgentClientsComponent implements OnDestroy {
	// store
	statistics$: Observable<BaseStateModel<StatisticsDetailClients>> = this.store.select(
		StateFeature.getStatisticsDetailClients
	);
	statistics: StatisticsDetailClients;
	organizations$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);

	// data
	timeRange = chartTimeRange30Days;
	dateUpdatedStatistics: number;
	activeClients = <LocalListHandlerBaseModel<StatisticsBaseSerieClient>>{
		pagination: {
			pageSize: 5
		},
		data: []
	};
	inactiveClients = <LocalListHandlerBaseModel<StatisticsBaseSerieClient>>{
		pagination: {
			pageSize: 5
		},
		data: []
	};

	// visualization tables
	cardDashboardConfigClientsActiveInactive: CardDashboardConfig = {
		title: 'chart.clients_active_inactive.title',
		subtitle: 'chart.clients_active_inactive.subtitle',
		icon: 'work',
		helpText: 'chart.clients_active_inactive.helptext'
	};
	cardDashboardConfigClientsTop5Average: CardDashboardConfig = {
		title: 'chart.clients_top_x_average_table.title',
		titleTranslateParams: { amount: '5' },
		subtitle: 'chart.clients_top_x_average_table.subtitle',
		helpText: 'chart.clients_top_x_average_table.helptext',
		icon: 'work'
	};
	cardDashboardConfigClientsTop5Quantity: CardDashboardConfig = {
		title: 'chart.clients_top_x_quantity_table.title',
		titleTranslateParams: { amount: '5' },
		subtitle: 'chart.clients_top_x_quantity_table.subtitle',
		helpText: 'chart.clients_top_x_quantity_table.helptext',
		icon: 'work'
	};

	clientColumns = ClientColumns.baseColumns;

	constructor(
		private store: Store<StatisticsDetailClients>,
		public activeClientsChartConfigService: ActiveClientsChartConfigService,
		public inactiveClientsChartConfigService: InactiveClientsChartConfigService,
		public clientsTop5AverageChartConfigService: ClientsTop5AverageChartConfigService,
		public clientsTop5QuantityChartConfigService: ClientsTop5QuantityChartConfigService,
		public clientsTop5AverageTableConfigService: ClientsTop5AverageTableConfigService,
		public clientsTop5QuantityTableConfigService: ClientsTop5QuantityTableConfigService,
		private utilService: AppUtilService
	) {
		// Load statistics clients
		this.store.dispatch(StatisticsDetailClientsStateAction.load());

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statistics$
			.pipe(
				filter((state: BaseStateModel<StatisticsDetailClients>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.date_update;
			});

		// Retrieve list of active and inactive clients
		this.organizations$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				const activeClients = state.data.filter(
					organization =>
						organization.statistics_data &&
						this.utilService.findLeaf(organization.statistics_data, 'general').active
				);
				activeClients.map(client => {
					const orders = client.statistics_data.find(data => data.key === StatisticsDataKeyEnum.ORDERS).value;
					const metrics = orders
						? orders.find(value => value.key === StatisticsDataKeyEnum.METRICS).value
						: null;
					this.activeClients.data.push({
						code: client.code_item ? client.code_item : null,
						total: metrics ? metrics.total : null,
						count: metrics ? metrics.count : null,
						last_order_date: orders
							? orders.find(value => value.key === StatisticsDataKeyEnum.DATE_LAST_ORDER).value
							: null,
						business_name: client.business_name ? client.business_name : null,
						vat_number: client.tax_data && client.tax_data.vat_number ? client.tax_data.vat_number : null,
						address: client.destination_list
							? client.destination_list.find(destination => destination.is_registered_office).address
							: null
					});
				});

				const inactiveClients = state.data.filter(
					organization =>
						organization.statistics_data &&
						!this.utilService.findLeaf(organization.statistics_data, 'general').active
				);
				inactiveClients.map(client => {
					const orders = client.statistics_data.find(data => data.key === StatisticsDataKeyEnum.ORDERS).value;
					const metrics = orders
						? orders.find(value => value.key === StatisticsDataKeyEnum.METRICS).value
						: null;
					this.inactiveClients.data.push({
						code: client.code_item ? client.code_item : null,
						total: metrics ? metrics.total : null,
						count: metrics ? metrics.count : null,
						last_order_date: orders
							? orders.find(value => value.key === StatisticsDataKeyEnum.DATE_LAST_ORDER).value
							: null,
						business_name: client.business_name ? client.business_name : null,
						vat_number: client.tax_data && client.tax_data.vat_number ? client.tax_data.vat_number : null,
						address: client.destination_list
							? client.destination_list.find(destination => destination.is_registered_office).address
							: null
					});
				});
			});
	}

	setTimeRange(selectedTimeRange: MatSelectChange) {
		const charts = [
			this.activeClientsChartConfigService,
			this.inactiveClientsChartConfigService,
			this.clientsTop5AverageChartConfigService,
			this.clientsTop5QuantityChartConfigService
		];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [this.clientsTop5AverageTableConfigService, this.clientsTop5QuantityTableConfigService];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	ngOnDestroy() {
		this.store.dispatch(StatisticsDetailOrdersStateAction.reset());
	}
}
