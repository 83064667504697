import { ITdDataTableColumn } from "@covalent/core/data-table";
import { FormControlMultipurposeModel } from '@saep-ict/angular-core';
import { TicketStatusItem } from "./util.model";

export interface ConfigurationCustomerContextSelector<T> {
	BACKOFFICE_ADMIN: T;
	BACKOFFICE: T;
	B2B: T;
	B2C: T;
	AGENT: T;
  CRM: T
}

export interface TicketStatusContextApplicationMapItem {
	selection_list: TicketStatusItem[];
	filter_list: TicketStatusItem[];
}

export interface TicketCenterConfigurationModel {
	page: {
		list: {
      title: ConfigurationCustomerContextSelector<string>;
      tableColumn: ConfigurationCustomerContextSelector<ITdDataTableColumn[]>;
			localSearchTextKeyList: ConfigurationCustomerContextSelector<string[]>;
		}
	};
	ticket: {
		bodyCustomField: FormControlMultipurposeModel.Item[];
		statusContextApplicationStatusMap: ConfigurationCustomerContextSelector<TicketStatusContextApplicationMapItem>;
	}

}
