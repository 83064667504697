import {
	ContactTypeEnum,
	OrganizationLevelEnum,
	OrganizationPouchModel,
	OrganizationTypeEnum
} from '@saep-ict/pouch_agent_models';
import { SourceEnum } from '../../../../enum/source.enum';
import { Destination } from './creation-default-value-destination.const';

export namespace CreationDefaultValueContextCode {
	export const Organization: OrganizationPouchModel = {
		valid: true,
		date_creation: Date.now(),
		type: 'organization',
		organization_type: OrganizationTypeEnum.COMPANY,
		level: OrganizationLevelEnum.LEAD,
		status: 'ENABLED',
		source: SourceEnum.Type.APPLICATION,
		contact_list: [
			{
				valid: true,
				is_main_of_list: true,
				contact_type: ContactTypeEnum.PERSONAL
			}
		],
		division_list: [
			{
				valid: true,
				is_main_of_list: true
			}
		],
		destination_list: [Destination.base] // dinamico, popolato tipicamente in component
	};
  // TODO: non esiste `export interface BodyTablePouchCustomModel extends BasePouchModel, BodyTablePouchModel {}`
	export const Backoffice: any = {
		date_creation: Date.now(),
		valid: true,
		type: 'backoffice'
	};
  // TODO: non esiste `export interface BodyTablePouchCustomModel extends BasePouchModel, BodyTablePouchModel {}`
	export const Agent: any = {
		valid: true,
		date_creation: Date.now(),
		type: 'agent'
	};
	export const CRM: OrganizationPouchModel = {
		valid: true,
		date_creation: Date.now(),
		type: 'crm',
		organization_type: OrganizationTypeEnum.COMPANY,
		level: OrganizationLevelEnum.LEAD,
		status: 'ENABLED',
		source: SourceEnum.Type.APPLICATION,
		contact_list: [
			{
				valid: true,
				is_main_of_list: true,
				contact_type: ContactTypeEnum.PERSONAL
			}
		],
		division_list: [
			{
				valid: true,
				is_main_of_list: true
			}
		]
	};
}
