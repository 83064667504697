<mat-dialog-wrapper
	*ngIf="data"
	[title]="data.modalTitle"
	[showCloseIcon]="false"
>
	<ng-container content>
		<destination-list-wrapper
			[localListHandlerWidgetWrapperData]="data.listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="data.listPageBaseData.columnList"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="false"
			(onItemSelect)="onItemSelect($event)"
		>
		</destination-list-wrapper>
	</ng-container>
	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
