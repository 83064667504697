import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularCoreUtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class CustomHeadersHandlerService {

  customHeaders: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	customHeaders$: Observable<any> = this.customHeaders.asObservable();

	constructor(private utilService: AngularCoreUtilService) {}

  addCustomHeaders(newCustomHeaders: any) {
    if (newCustomHeaders) {
      const customHeaders = this.customHeaders.value ? this.customHeaders.value : {};
      for (const headerName in newCustomHeaders) {
        customHeaders[headerName] = newCustomHeaders[headerName];
      }
      this.utilService.deleteEmptyProperties(customHeaders);
      this.customHeaders.next(customHeaders);
    }
  }

}
