import { FormControlMultipurposeModel, FormControlMultipurposeService } from '@saep-ict/angular-core';
import { creationFieldMapDestinationBase } from './creation-field-map-destination-base.const';
import _ from 'lodash';
import { Injector } from '@angular/core';

const injector = Injector.create({
	providers: [{ provide: FormControlMultipurposeService, deps: [] }]
});

const formControlMultipurposeService = injector.get(FormControlMultipurposeService);

	export const creationFieldMapDestinationListEdit: FormControlMultipurposeModel.Item[] =
		formControlMultipurposeService.returnFormFieldListWithSomeOnFormTemplate(
			_.cloneDeep(creationFieldMapDestinationBase),
			['is_main_of_list'],
			true
		);
