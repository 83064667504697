<mat-dialog-wrapper [title]="'opportunity.select' | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
		<ng-container
			*ngIf="listPageBaseData && listPageBaseData.data && listPageBaseData.data.length > 0; else noData"
		>
			<mat-toolbar>
				<mat-toolbar-row>
					<span class="flex-span"></span>
					<!-- [placeholder]="
						('general.filter_by' | translate) + ' ' + ('user.field.username' | translate) | sentencecase
					" -->
					<td-search-box
						backIcon="search"
						[showUnderline]="true"
						[debounce]="500"
						[alwaysVisible]="false"
						[(ngModel)]="listPageBaseData.dataSetting.appliedFilter.text"
						(searchDebounce)="applyPermanentFilters()"
						[placeholder]="'general.search' | translate | sentencecase"
					>
					</td-search-box>
				</mat-toolbar-row>
			</mat-toolbar>

			<td-data-table
				#dataTable
				[data]="listPageBaseData.data"
				[columns]="columns"
				[clickable]="true"
				(sortChange)="sortChange($event)"
				(rowClick)="selectOpportunity($event.row)"
			>
				<ng-template tdDataTableTemplate="title" let-value="value" let-row="row">
					<p>
						{{ value || OpportunityEnum.Type[row.opportunity_type] | sentencecase }}

						<br />
						<small class="cell-subtitle">{{ row.code }}</small>
					</p>
				</ng-template>
				<ng-template tdDataTableTemplate="organization" let-value="value">
					<p>
						{{ utilCompanyService.getBusinessNameLabel(value) | sentencecase }}
						<!-- {{ value?.business_name || '-' | titlecase }} -->
					</p>
				</ng-template>
				<ng-template tdDataTableTemplate="contact_name" let-value="value">
					<p>
						{{ value || '-' | titlecase }}
					</p>
				</ng-template>
				<ng-template tdDataTableTemplate="assegnee_full_name" let-value="value">
					<p>
						{{ value || '-' | titlecase }}
					</p>
				</ng-template>
				<ng-template tdDataTableTemplate="date_creation" let-value="value">
					<p>
						{{ value | date: 'dd/MM/yyyy' }}
						{{ value | date: 'HH:mm' }}
					</p>
				</ng-template>
				<ng-template tdDataTableTemplate="status_code" let-value="value">
					{{ value ? ('opportunity.status.' + value | translate | sentencecase) : '-' }}
				</ng-template>
				<ng-template tdDataTableTemplate="opportunity_type" let-value="value">
					{{ OpportunityEnum.Type[value] | sentencecase }}
				</ng-template>
			</td-data-table>

			<!-- <td-paging-bar
				#pagingBarPageSize
				[firstLast]="true"
				[pageSize]="listPageBaseData.pagination.pageSize"
				[total]="listPageBaseData.pagination.totalFilteredItemCount"
				(change)="paginationChange($event)"
			>
				<span hide-xs>{{ 'general.items_per_page' | translate | sentencecase }}</span>
				<mat-select
					[style.width.px]="50"
					[(ngModel)]="listPageBaseData.pagination.pageSize"
					(ngModelChange)="changePageSize($event)"
				>
					<mat-option *ngFor="let size of [5, 10, 20, 50]" [value]="size">
						{{ size }}
					</mat-option>
				</mat-select>
			</td-paging-bar> -->
		</ng-container>
		<ng-template #noData>
			<p class="placeholder">{{ 'opportunity.not_found' | translate | sentencecase }}</p>
		</ng-template>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
