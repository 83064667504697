import { Injectable } from '@angular/core';

// widget & utility
import { AngularSpin8CoreBaseApiService } from './baseApi.service';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CorePermissionService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/{!endpoint}'
	})
	getPermissionAuxiliaryTable: IResourceMethodPromise<{ endpoint: string }, any>;
}
