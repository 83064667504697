import { BasePouchModel, BodyTablePouchModel } from './base-pouch.model';

export enum DiscountTypeEnum {
	percentage = 0,
	decimal = 1
}

export enum SignEnum {
	plus = '+',
	minus = '-'
}

export enum GiveTakeFlagEnum {
	give = 'D',
	take = 'A'
}

export enum ExpiredFlagEnum {
	expired = 'E',
	not_expired = ''
}

export enum SourceCreditGrantedEnum {
	substituted_with_period_of_validity = 'S',
	from_customer_data = 'C',
	from_reliability = 'A',
	default = 'D'
}

export interface AddressPouchModel {
	zip_code?: string;
	address?: string;
	locality?: string;
	country?: string;
	province?: {
		label: string;
		value: string;
	};
}

export interface SimpleListItemModel {
	label: string;
	id: string;
}
export interface ElementOffset {
	x: number;
	y: number;
}

export interface DiscountModel {
	value: number;
	type: DiscountTypeEnum;
}

export interface IsMainOfList {
	is_main_of_list?: boolean;
}

export interface AttachmentListModel<T> {
	attachment_list?: T[];
}

export interface ListPouchModel extends BasePouchModel, BodyTablePouchModel, IsMainOfList {}

export interface LanguageItem {
	language: string;
	name: string;
	description: string;
	description_add?: string;
	description_extended?: string;
	image_list?: AttachedFile[];
	technical_info?: string;
}

export interface AttachedFile extends IsMainOfList {
	id?: string;
	alt?: string;
	name: string;
	nameOnBucket?: string;
	data?: string;
	bucket_link?: string;
	bucket_link_thumbnail?: string;
	date_creation?: number;
	is_logo?: boolean;
}
