import { Component, Input } from '@angular/core';

// model
import { UtilCategoryListService } from '../../service/util/util-category-list.service';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { Category } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'recursive-category-list',
	templateUrl: './recursive-category-list.component.html',
	styleUrls: ['./recursive-category-list.component.scss']
})
export class RecursiveCategoryListComponent {
	@Input('categoryList') categoryList: Category[] = [];

	constructor(
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public utilCategoryListService: UtilCategoryListService
	) {}
}
