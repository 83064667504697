import { ITdDataTableColumnCustom, ListStructureEnum } from '@saep-ict/pouch_agent_models';

export const articleColumnMapBackofficeProductStock: ITdDataTableColumnCustom[] = [
	// colonne di configurazione
	{
		name: 'articleDescription.sequence',
		label: null,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: true
	},
	{
		name: 'column-width-configuration-class',
		label: 'articleColumnMapBackofficeProductStock',
		hidden: true
	},
	// colonne renderizzate
	{
		name: 'code_erp',
		labelPath: 'article.field.ref',
		label: null,
		hidden: false
	},
	{
		name: 'description',
		labelPath: 'article.field.name',
		label: null,
		sortType: ListStructureEnum.Sort.Type.STRING,
		hidden: false
	},
	{
		name: 'articleDescription.stock.article_description.brand',
		labelPath: 'product.brand.label',
		label: null,
		hidden: false
	},
	{
		name: 'articleDescription.stock.article_description.line',
		labelPath: 'product.line.label',
		label: null,
		hidden: false
	},
	{
		name: 'articleDescription.stock.qty_current',
		labelPath: 'product.stock.label',
		label: null,
		hidden: false
	},
	{
		name: 'articleDescription.stock.qty_commited',
		labelPath: 'product.allocated',
		label: null,
		hidden: false
	},
	{
		name: 'articleDescription.stock.qty_available',
		labelPath: 'product.available',
		label: null,
		hidden: false
	},
	{
		name: 'articleDescription.stock.qty_incoming',
		labelPath: 'product.incoming',
		label: null,
		hidden: false
	}
];