import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewTypeEnum } from '../../../enum/view-type.enum';
import { ListSetting, LocalListHandlerBaseModel, ArticlePouchModel, DivisionPouchModel } from '@saep-ict/pouch_agent_models';
import { ArticleActionEnum } from '../../../state/article/article.actions';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { Observable } from 'rxjs';
import {
	BaseStateModel,
	StoreFeature,
	SubscribeManagerService,
	BaseStateSerializerModel,
	UtilListSetting
} from '@saep-ict/angular-core';
import { delay, filter, map, mergeMap, skip, take } from 'rxjs/operators';
import { CustomerAppConfig } from '../../../customer-app.config';
import { CallToActionConfig } from '../../../widget/call-to-action/call-to-action.component';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import { B2cArticleListWrapper } from '../../../widget/b2c/article/b2c-article-list-wrapper/b2c-article-list-wrapper.component';
import {
	SearchResultsActionEnum,
	SearchResultsStateAction
} from '../../../state/search-results/search-results.actions';
import {
	SortSelectorConfiguration,
	SortSelectorConfigurationItem
} from '../../../widget/sort-selector/sort-selector.component';
import { AppUtilService } from '../../../service/util/app-util.service';
import { UtilPriceService } from './../../../service/util/util-price.service';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UtilCategoryListService } from '../../../service/util/util-category-list.service';
import { Options } from '@angular-slider/ngx-slider';
import { OrganizationStateModel, ConfigurationUtil, ROUTE_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'b2c-catalog',
	templateUrl: './b2c-catalog.component.html',
	styleUrls: ['./b2c-catalog.component.scss'],
	providers: [SubscribeManagerService]
})
export class B2cCatalogComponent implements OnInit, OnDestroy {
	@ViewChild(B2cArticleListWrapper) b2cArticleListWrapper: B2cArticleListWrapper;

	// data
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;
	searchResults$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(
		StateFeature.getSearchResultsState
	);

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[] = [];
	// filters
	listPageBaseData = <LocalListHandlerBaseModel<ArticlePouchModel>>{
		pageName: ROUTE_URL.catalog,
		pagination: {
			pageSize: 9
		},
		languageKey: this.translateService.currentLang,
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'code_item',
					'code_erp',
					'articleDescription.language_list.description',
				]
			}			
		}
	};
	listSettingSlice$: Observable<BaseStateSerializerModel<ListSetting>> = this.store.select(
		StoreFeature.selectListSettingSliceStore,
		this.listPageBaseData.pageName
	);
	sortConfiguration: SortSelectorConfiguration;
	searchTerm: string;
	// price range slider config
	minValue = 0;
	maxValue = 0;
	options: Options = {
		floor: 0,
		ceil: 0,
		minRange: 1
	};

	// view
	viewType: ViewTypeEnum;
	isMobile = false;
	callToActionConfig: CallToActionConfig = {
		text: 'Lorem ipsum',
		btnLabel: 'contact.contact_us',
		theme: 'accent'
	};

	constructor(
		private route: ActivatedRoute,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private appConfig: CustomerAppConfig,
		private router: Router,
		private utilPriceService: UtilPriceService,
		private utilService: AppUtilService,
		private translateService: TranslateService,
		public utilCategoryListService: UtilCategoryListService
	) {
		this.loadStaticData();
		this.subscribeManagerInit();
		if (this.appConfig.authenticationToken) {
			this.viewType = ViewTypeEnum.module;
		} else {
			this.viewType = ViewTypeEnum.list;
		}
	}

	ngOnInit(): void {
		this.isMobile = window.innerWidth < 1200;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(SearchResultsStateAction.reset());
		this.utilCategoryListService.selectedCategoryList.clear();
	}

	// load static data
	loadStaticData() {
		this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			const catalogSortList: ConfigurationUtil.Context.ApplicationSelector<
				SortSelectorConfigurationItem[]
			> = _.cloneDeep(ConfigurationCustomerArticle.catalogSortList);
			const sortList: SortSelectorConfigurationItem[] =
				store && store.data
					? catalogSortList[store.data.current_permission.context_application]
					: catalogSortList.PUBLIC;
			this.sortConfiguration = {
				list: sortList
			};
			this.listPageBaseData.sort = sortList.find(i => i.selected);
		});
		this.organization$.pipe(take(1)).subscribe((store: BaseStateModel<OrganizationStateModel>) => {
			this.organization = store && store.data ? store.data : null;
		});
		this.listSettingSlice$.pipe(take(1)).subscribe((store: BaseStateSerializerModel<ListSetting>) => {
			this.listPageBaseData = UtilListSetting.returnListSettingForLocalListHanlderBaseObject<ArticlePouchModel>(
				this.listPageBaseData,
				store ? store.data : null
			);
			// passaggio non utile nei casi in cui il sort sia gestito dagli eventi in td-dada-table
			if (store && store.data && store.data.sort) {
				for (const i of this.sortConfiguration.list) {
					i.selected = i.name === store.data.sort.name && i.order === store.data.sort.order;
				}
			}
		});
	}

	// subscribe
	subscribeMainData() {
		return this.route.queryParams.pipe(
			// silenzia ERROR Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError relativo al change di
			// - listPageBaseData.data
			// - categoryList
			delay(0),
			mergeMap((e: any) => {
				// ogni cambio di url determina il riassetto della pagina

				// eliminazione delle categorie selezionate, residue di eventuali precedenti interazioni
				this.utilCategoryListService.selectedCategoryList.clear();

				// `e` contiene i queryParams visibili correntemente in url
				if (e.categories) {
					// `e.categories` determina le categorie correntemente selezionate e permette di invocare la pagina di catalogo
					// con un certo set secondo il pattern `...?categories=codeItem1,codeitem2`

					// ripopolamento delle categorie selezionate a partire dallo split di `e.categories`
					for (const urlCategory of this.utilCategoryListService.splitCategoryList(e.categories)) {
						this.utilCategoryListService.selectedCategoryList.add(urlCategory);
					}
				}
				if (e.search) {
					// `e.search` determina la chiave di ricerca inserita dall'utente presso l'header
					if (!this.searchTerm || this.searchTerm !== e.search) {
						// se `searchTerm` non è ancora stato settato oppure è diverso da `e.search`, ne viene aggiornato il
						// valore; lo stesso è usato per effettuare la dispatch `SearchResultsStateAction.load`
						this.searchTerm = e.search;
						// this.store.dispatch(SearchResultsStateAction.load({ term: e.search }));
						// ricerca temporaneamente sostituita da filtraggio locale by text
						this.listPageBaseData.filters.localSearchText.value = this.searchTerm;
						this.store.dispatch(SearchResultsStateAction.skip());
					}
				} else {
					// `e.search` non esiste
					// avviene la dispatch `SearchResultsStateAction.load`
					this.store.dispatch(SearchResultsStateAction.skip());
					// `searchTerm` viene annullato in modo di ometterne i riferimenti in pagina
					this.searchTerm = null;
					// ricerca temporaneamente sostituita da filtraggio locale by text
					this.listPageBaseData.filters.localSearchText.value = null;
				}
				return this.articleList$;
			}),
			filter((articleList: BaseStateModel<ArticlePouchModel[]>) => !!(articleList && articleList.data)),
			mergeMap((articleList: BaseStateModel<ArticlePouchModel[]>) => {
				switch (articleList.type) {
					case ArticleActionEnum.UPDATE:
						this.articleList = articleList.data.filter(article => article.articleDescription.visible);
						break;
					case ArticleActionEnum.ERROR:
						throw new Error(ArticleActionEnum.ERROR);
				}
				return this.searchResults$;
			}),
			filter(
				(searchResults: BaseStateModel<ArticlePouchModel[]>) =>
					searchResults &&
					!!(
						searchResults.data ||
						searchResults.type === SearchResultsActionEnum.SKIP ||
						searchResults.type === SearchResultsActionEnum.RESET ||
						searchResults.type === SearchResultsActionEnum.ERROR
					)
			),
			map((searchResults: BaseStateModel<ArticlePouchModel[]>) => {
				// TODO: la ricerca va in err500. Verificare dopo il ripristino il seguente refactor, che dovrebe garantire
				// di preservare oltre che il filtro, anche l'ordinamento degli articoli restituiti
				switch (searchResults.type) {
					case SearchResultsActionEnum.UPDATE: {
						const articleList: ArticlePouchModel[] = [];
						// viene rigenerato articleList in base alla lista di articoli restitita da searchResult
						for (const articleSearch of searchResults.data) {
							const article: ArticlePouchModel = this.articleList.find(
								i => i.code_item === articleSearch.code_item
							);
							if (article) {
								articleList.push(article);
							}
						}
						this.articleList = articleList;
						break;
					}
					case SearchResultsActionEnum.ERROR: {
						// TODO: con la seguente disabilitazione del throw, la pagina continua a lavorare con il completo dataset
						// di articleList. Ripristinare l'errore una volta riparata la chiamata.
						// throw new Error(SearchResultsActionEnum.ERROR);
						break;
					}
				}

				// aggiunta del prezzo finito per ogni elementio di articleList
				this.listPageBaseData.data = this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem(
					this.articleList,
					this.organization
						? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
								.division
						: null
				);

				// filtro delle categorie mostrate in sidebar in base agli articoli presenti; l'implementazione non consente di
				// scremare ulteriormente la lista di categorie selezionabili a valle della ulteriore selezione
				this.utilCategoryListService.filteredCategoryList = this.utilCategoryListService.categoryList.filter(
					category => {
						return this.articleList.find(
							article => article.articleDescription.category_list?.indexOf(category.code_item) > -1
						);
					}
				);
				this.selectMaxPriceOptionOnSlider();
				this.filterArticle();
			})
		);
	}

	subscribeCategoryListSelectedData() {
		return this.utilCategoryListService.selectedCategoryList$.pipe(
			// lo skip evita il primo emit del BehaviorSubject avente effetto imprevedibile
			// potendo essere o non essere già valorizzato con le categorie in URL
			skip(1),
			map(data => {
				this.changeCategoryListSelectedInQueryParams();
			})
		);
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.subscribeMainData().subscribe(
				res => {
					//
				},
				error => {
					console.log('subscribeMainData(): something went wrong ', error);
				}
			),
			'order-data'
		);
		this.subscribeManagerService.populate(
			this.subscribeCategoryListSelectedData().subscribe(
				res => {
					//
				},
				error => {
					console.log('subscribeCategoryListSelectedData(): something went wrong ', error);
				}
			),
			'categor-list-selected'
		);
	}

	// sidebar filters
	filterArticlesByPrice(productListToFilter?) {
		let filteredProductList: ArticlePouchModel[] = productListToFilter || this.articleList;
		filteredProductList = filteredProductList.filter(article => {
			if (
				article.calculate_price_for_single_item ||
				article.calculate_price_for_single_item === 0
			) {
				return (
					article.calculate_price_for_single_item >= this.minValue &&
					article.calculate_price_for_single_item <= this.maxValue
				);
			}
			return false;
		});
		return filteredProductList;
	}

	filterArticleByCategory(productListToFilter?): ArticlePouchModel[] {
		let filteredProductList: ArticlePouchModel[] = productListToFilter || this.articleList;
		if (this.utilCategoryListService.selectedCategoryList.size > 0) {
			filteredProductList = this.articleList.filter(article => {
				if (
					article.articleDescription &&
					article.articleDescription.category_list &&
					article.articleDescription.category_list.length > 0
				) {
					return (
						// return articles set as the intersection of selected categories
						article.articleDescription.category_list.filter(category =>
							this.utilCategoryListService.selectedCategoryList.has(category)
						).length === this.utilCategoryListService.selectedCategoryList.size

						// return articles set as the union of selected categories
						// article.articleDescription.category_list.filter(category =>
						// 	this.utilCategoryListService.selectedCategoryList.has(category)
						// ).length > 0
					);
				}
				return false;
			});
		}

		return filteredProductList;
	}

	filterArticleByValidity(articleList: ArticlePouchModel[]): ArticlePouchModel[] {
		return articleList.filter(article => article.valid);
	}

	filterArticle() {
		let filteredProductList: ArticlePouchModel[];

		filteredProductList = this.filterArticleByCategory();
		filteredProductList = this.filterArticlesByPrice(filteredProductList);
		filteredProductList = this.filterArticleByValidity(filteredProductList);

		this.listPageBaseData.data = filteredProductList;
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	filterArticleReset() {
		this.utilCategoryListService.selectedCategoryList.clear();
		this.selectMaxPriceOptionOnSlider();
		this.changeCategoryListSelectedInQueryParams();
	}

	/**
	 * Il metodo determina se avere visibili gli widget legati all'applicazione dei filtri di scrematura e ordinamento.
	 *
	 * @returns {boolean}
	 * @memberof B2cCatalogComponent
	 */
	filterShowWidget(): boolean {
		if (
			(this.utilCategoryListService.filteredCategoryList &&
				this.utilCategoryListService.filteredCategoryList.length) ||
			this.minValue < this.maxValue
		) {
			return true;
		}
		return false;
	}

	// misc

	/**
	 * Il metodo elimina i riferimenti all'eventuale ricerca testuale settata in pagina. Il reset determina:
	 * - svuotamento di `utilCategoryListService.selectedCategoryList`
	 * - reset dei valori minimo e massimo utilizzati presso `ngx-slider`
	 * - reset dello store `search-results`
	 * - ripristino di `utilCategoryListService.filteredCategoryList` (TODO: verificare)
	 *
	 * @memberof B2cCatalogComponent
	 */
	searchResultsReset() {
		this.utilCategoryListService.selectedCategoryList.clear();
		this.store.dispatch(SearchResultsStateAction.reset());
		this.utilCategoryListService.filteredCategoryList = this.utilCategoryListService.categoryList;
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: null
		});
	}

	/**
	 * Il metodo effettua un router.navigate dopo aver settato i queryParams di pagina secondo la seguente logica:
	 * - categories: corrente contenuto di utilCategoryListService.selectedCategoryList
	 * - search: corrente contenuto di route.queryParams.search
	 *
	 * @memberof B2cCatalogComponent
	 */
	changeCategoryListSelectedInQueryParams() {
		let queryParams;
		if (
			this.utilCategoryListService.selectedCategoryList &&
			this.utilCategoryListService.joinCategoryList(this.utilCategoryListService.selectedCategoryList)
		) {
			queryParams = {
				categories: this.utilCategoryListService.joinCategoryList(
					this.utilCategoryListService.selectedCategoryList
				)
			};
		}

		this.route.queryParams.pipe(take(1)).subscribe(res => {
			if (res && res['search']) {
				if (!queryParams) {
					queryParams = {};
				}
				queryParams.search = res['search'];
			}
		});

		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: queryParams
		});
	}

	/**
	 * Il metodo setta valori minimi e massimi dello slider che filtra gli articoli in base al range di prezzo
	 *
	 * @memberof B2cCatalogComponent
	 */
	selectMaxPriceOptionOnSlider() {
		const articleListMap = this.articleList.map(article => {
			return article.calculate_price_for_single_item;
		});
		// this.options.floor = this.minValue = Math.floor(
		// 	Math.min.apply(
		// 		Math,
		// 		this.articleList.map(article => {
		// 			return article.calculate_price_for_single_item;
		// 		})
		// 	)
		// );
		this.options.floor = this.minValue = Math.floor(Math.min(...articleListMap));
		// this.options.ceil = this.maxValue = Math.ceil(
		// 	Math.max.apply(
		// 		Math,
		// 		this.articleList.map(article => {
		// 			return article.calculate_price_for_single_item;
		// 		})
		// 	)
		// );
		this.options.ceil = this.maxValue = Math.ceil(Math.max(...articleListMap));
		this.options = Object.assign({}, this.options);
	}

	onResize(event) {
		this.isMobile = event.target.innerWidth < 1200;
	}
}
