import { Component, OnDestroy, OnInit } from '@angular/core';
import { ItemBoxTrendInfoComponentConfig } from '../../../widget/box-trend-info/box-trend-info.component';
import { Store } from '@ngrx/store';
import { StatisticActionEnum, StatisticStateAction } from '../../../state/statistic/statistic.action';
import {
	BaseStateModel,
	SubscribeManagerService,
	LoaderService,
	AngularCoreUtilService,
	UtilColorService,
	DateMomentService,
	SentencecasePipe,
	SideBarPositionValues
} from '@saep-ict/angular-core';
import { filter, map, take, Observable, debounceTime } from 'rxjs';
import * as StatisticModel from '../../../model/statistics.model';
import * as StatisticGroupModel from '../../../model/statistics-group.model';
import { StateFeature } from '../../../state';
import {
	StatisticGroupActionEnum,
	StatisticGroupStateAction
} from '../../../state/statistic/statistic-group/statistic-group.action';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as StatisticEnum from '../../../enum/statistic.enum';
import {
	AngularSpin8CoreUtilTranslateService,
	ArticleRecap,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { Category, CategoryMap} from '@saep-ict/pouch_agent_models';
import * as StatisticGroupEnum from '../../../enum/statistic-group.enum';
import _ from 'lodash';
import * as ConfigurationCustomerStatistic from '../../../constants/configuration-customer/statistic/statistic.constant';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { UtilStatisticService } from '../../../service/util/util-statistc.service';
import { DatePipe } from '@angular/common';
import { BaseChartClass } from '../../../service/chart-structure/chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { ChartData, TooltipItem as ChartTooltipItem } from 'chart.js';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as UtilPrice from '../../../constants/util-price.constants';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import moment from 'moment';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';

@Component({
	selector: 'backoffice-reports',
	templateUrl: './backoffice-reports.component.html',
	styleUrls: ['./backoffice-reports.component.scss'],
	providers: [SubscribeManagerService]
})
export class BackofficeReportsComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	statisticGroupList$: Observable<BaseStateModel<StatisticGroupModel.Document[]>> = this.store.select(
		StateFeature.getStatisticGroup
	);
	statisticGroupList: StatisticGroupModel.GroupListItem[];
	statistic$: Observable<BaseStateModel<StatisticModel.Document>> = this.store.select(StateFeature.getStatistic);
	statisticNestedCategoryConfiguration: StatisticModel.NestedCategoryConfiguration = {
		unit: StatisticEnum.Unit.VALUE,
		filter: {
			categoryToExlcude: []
		}
	};
	categoryList$: Observable<BaseStateModel<CategoryMap>> = this.store.select(StateFeature.getCategoryListState);
	categoryListFilter: Category[];
	categoryTree: Category[];
	articleDescriptionList$: Observable<BaseStateModel<ArticleRecap>> = this.store.select(StateFeature.getArticleDescription);
	articleList: ArticlePouchModel[];
	// TODO: ripristino o rimozione della selezione del gruppo clienti
	// formGroupOrganization: FormGroup;
	formComparisonYear: FormGroup;
	formFilter: FormGroup;
	statisticGroupEnum = StatisticGroupEnum;
	statisticEnum = StatisticEnum;
	object = Object;
	sideBarPositionValues = SideBarPositionValues;
	trendBoxContent: Array<ItemBoxTrendInfoComponentConfig> = [];
	chartCategoryRootBar: BaseChartClass = new BaseChartClass(
		this.utilService,
		this.utilColorService,
		this.store,
		this.route,
		this.dateMomentService
	);
	chartStatistcSummaryListDoughnut: BaseChartClass[];
	statisticDataUpdateSnackBarTurnedOff = true;
	configurationCustomerStatistic = ConfigurationCustomerStatistic;
	comparisonYearList = ConfigurationCustomerStatistic.comparisonYearList();

	collapsedCardAnalytics = false;
    collapsedCardBrand = false;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private fb: FormBuilder,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public utilBreadcrumbService: UtilBreadcrumbService,
		private utilStatisticService: UtilStatisticService,
		private datePipe: DatePipe,
		private loaderService: LoaderService,
		public utilService: AngularCoreUtilService,
		public utilColorService: UtilColorService,
		public route: ActivatedRoute,
		public dateMomentService: DateMomentService,
		private matSnackBar: MatSnackBar,
		private sentenceCasePipe: SentencecasePipe
	) {}

	async init() {
		this.formComparisonYearCreate();
		this.loadStaticData();
		this.store.dispatch(
			StatisticGroupStateAction.loadAll(
					{
						documentType:
							`statistic${ConfigurationCustomerAppStructure.noSqlDocSeparator}${StatisticGroupEnum.Type.SALES_REPORTS}`
					}
				)
		);
		this.dispatchStatisticSalesReports(StatisticGroupEnum.Value.ORGANIZATION_ALL, this.comparisonYearList[0]);
		const loaderGuid: string = this.loaderService.guid();
		this.loaderService.populateLoader(loaderGuid);
		this.utilStatisticService.categoryTreeWithArticle = await this.utilStatisticService.createDataStructureAndSource(
			this.user,
			this.categoryTree,
			this.articleList,
			loaderGuid
		);
		// TODO: ripristino o rimozione della selezione del gruppo clienti
		// this.formGroupOrganizationCreate();
		const subscribeList: SubscribeManagerItem[] = [
			{ key: 'statistic-group-data', observable: this.subscribeStatisticGroupData() },
			{ key: 'statistic-data', observable: this.subscribeStatisticData() },
			// TODO: ripristino o rimozione della selezione del gruppo clienti
			// { key: 'formGroup-data', observable: this.subscribeFormGroupChange() },
			{ key: 'formComparisonYear-data', observable: this.subscribeFormComparisonYearChange() },
			{ key: 'formFilter-data', observable: this.subscribeFormFilterChange() }
		];
		ConfigurationSubscribeManager.init(subscribeList, this.subscribeManagerService);
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('reports');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['reports']);
	}

	ngOnInit(): void {
		this.init();
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	// static data
	async loadStaticData() {
		this.user$.pipe(take(1)).subscribe((e: BaseStateModel<UserDetailModel>) => {
			this.user = e.data;
		});
		this.categoryList$.pipe(take(1)).subscribe((e: BaseStateModel<CategoryMap>) => {
			this.categoryTree = e.data.tree;
			this.categoryListFilter = ConfigurationCustomerStatistic.returnCategoryListFilter(e.data.tree);
			this.formFilterCreate();
		});
		this.articleDescriptionList$.pipe(take(1)).subscribe((e: BaseStateModel<ArticleRecap>) => {
			if (e && e.data && e.data.article_list && e.data.article_list.length > 0) {
				// la creazione di articleList avviene sulla base di article_recap_description in quanto
				// - il prezzo dell'articolo non è rilevante
				// - article_recap_* (COMPANY, PRIVATE, ecc) potrebbe non fare alcun match con gli articoli presenti nel file
				//   delle statistiche, non essendo accoppiato ad un listino di default
				this.articleList = [];
				for (const articleDescription of e.data.article_list) {
					const article: ArticlePouchModel =  {
						code_item: articleDescription.code_item,
						code_erp: articleDescription.code_erp,
						articleDescription: _.cloneDeep(articleDescription)
					}
					this.articleList.push(article);
				}
			}
		});
	}

	// subscribe
	subscribeStatisticGroupData(): Observable<void> {
		return this.statisticGroupList$.pipe(
			filter((e: BaseStateModel<StatisticGroupModel.Document[]>) => !!(e && e.data)),
			map((e: BaseStateModel<StatisticGroupModel.Document[]>) => {
				switch (e.type) {
					case StatisticGroupActionEnum.UPDATE:
						this.returnStatisticGroupList(e.data);
						break;
					case StatisticGroupActionEnum.ERROR:
						throw new Error(StatisticGroupActionEnum.ERROR);
				}
			})
		);
	}

	subscribeStatisticData(): Observable<Promise<void>> {
		return this.statistic$.pipe(
			filter((e: BaseStateModel<StatisticModel.Document>) => !!(e && e.data)),
			map(async (e: BaseStateModel<StatisticModel.Document>) => {
				switch (e.type) {
					case StatisticActionEnum.UPDATE:
						this.statisticNestedCategoryConfiguration.data = e.data;
						this.utilBreadcrumbService.subtitle = {
							value: 'general.last_update_date',
							param: {
								date: moment(e.data.date_update).format("DD/MM/YYYY")
							}
						};
						this.statisticDataParseUpdate();
						break;
					case StatisticActionEnum.ERROR:
						throw new Error(StatisticActionEnum.ERROR);
				}
			})
		);
	}

	// TODO: ripristino o rimozione della selezione del gruppo clienti
	// subscribeFormGroupChange(): Observable<void> {
	// 	return this.formGroupOrganization.valueChanges.pipe(
	// 		debounceTime(500),
	// 		map(e => {
	// 			this.dispatchStatisticSalesReports(
	// 				e[StatisticEnum.FormField.GROUP_ORGANIZATION],
	// 				this.returnFormField(this.formComparisonYear, StatisticEnum.FormField.COMPARISON_YEAR)
	// 			);
	// 		})
	// 	);
	// }

	subscribeFormComparisonYearChange(): Observable<void> {
		return this.formComparisonYear.valueChanges.pipe(
			debounceTime(500),
			map(e => {
				this.dispatchStatisticSalesReports(
					// TODO: ripristino o rimozione della selezione del gruppo clienti
					// this.returnFormField(this.formGroupOrganization, StatisticEnum.FormField.GROUP_ORGANIZATION),
					StatisticGroupEnum.Value.ORGANIZATION_ALL,
					e[StatisticEnum.FormField.COMPARISON_YEAR]
				);
			})
		);
	}

	subscribeFormFilterChange(): Observable<Promise<void>> {
		return this.formFilter.valueChanges.pipe(
			debounceTime(1500),
			map(async e => {
				this.statisticNestedCategoryConfiguration.unit = e.unit;
				this.statisticNestedCategoryConfiguration.filter.categoryToExlcude = [];
				for (const category of Object.keys(e.category)) {
					if (e.category[category] === false) {
						this.statisticNestedCategoryConfiguration.filter.categoryToExlcude.push(category);
					}
				}
				this.statisticDataParseUpdate();
			})
		);
	}

	// statistic data parse util
	async statisticDataParseUpdate() {
		const loaderGuid: string = this.loaderService.guid();
		this.loaderService.populateLoader(loaderGuid);
		this.statisticNestedCategoryConfiguration = _.cloneDeep(
			await this.utilStatisticService.createDataSource(this.statisticNestedCategoryConfiguration, loaderGuid)
		);
		this.trendBoxContentUpdate();
		this.chartCategoryRootBarUpdate(this.statisticNestedCategoryConfiguration);
		this.chartStatistcSummaryListDoughnutUpdate(this.statisticNestedCategoryConfiguration);
		if (!this.statisticDataUpdateSnackBarTurnedOff) {
			this.matSnackBar.open(
				this.sentenceCasePipe.transform(this.utilTranslateService.translate.instant('general.data_updated')),
				this.utilTranslateService.translate.instant('general.close').toUpperCase(),
				{ duration: 2000 }
			);
		}
		this.statisticDataUpdateSnackBarTurnedOff = false;
	}

	// summary util
	trendBoxContentUpdate() {
		this.trendBoxContent = [];
		this.statisticNestedCategoryConfiguration.dataParse.summary.forEach(i => {
			const summary: ItemBoxTrendInfoComponentConfig = {
				value: UtilPrice.returnItemValueFormatted(
					i.total[this.statisticNestedCategoryConfiguration.unit],
					0
				),
				suffix: this.statisticNestedCategoryConfiguration.data.unit[
					this.statisticNestedCategoryConfiguration.unit
				],
				title: `${i.total.description}`,
				subtitle: `${this.datePipe.transform(i.date.begin, 'dd/MM/yyyy')} -
                    ${this.datePipe.transform(i.date.end, 'dd/MM/yyyy')}`,
				trendDirection: this.utilStatisticService.returnTrandIndicator(
					i.trend[this.statisticNestedCategoryConfiguration.unit]
				)
			};
			this.trendBoxContent.push(summary);
		});
	}

	// chart util
	chartCategoryRootBarUpdate(configuration: StatisticModel.NestedCategoryConfiguration) {
		this.chartCategoryRootBar.chart.type = 'bar';
		this.chartCategoryRootBar.chart.colors = [{ borderColor: this.chartCategoryRootBar.colorWhite }];
		this.chartCategoryRootBar.fullLabels = [];
		this.chartCategoryRootBar.fullDataSets = [];
		for (const category of configuration.dataParse.categoryTree) {
			this.chartCategoryRootBar.fullLabels.push(
				this.utilTranslateService.getTranslationFromLanguage(category.language_list).description
			);
		}
		const backgroundColorGradient: number = 1 / configuration.dataParse.summary.length;
		for (let i = 0; i < configuration.dataParse.summary.length; i++) {
			const valueList: number[] = [];
			for (const category of configuration.dataParse.categoryTree) {
				let statisticItem: StatisticModel.Base;
				if (category.statisticList && category.statisticList.length) {
					statisticItem = category.statisticList.find(s => s.key === configuration.dataParse.summary[i].key);
				}
				valueList.push(statisticItem ? statisticItem.total[configuration.unit] : 0);
			}
			this.chartCategoryRootBar.fullDataSets.push({
				label: configuration.dataParse.summary[i].total.description,
				data: valueList,
				borderRadius: 100,
				backgroundColor: `rgba(${this.chartCategoryRootBar.colorPrimaryRGB.r},
                    ${this.chartCategoryRootBar.colorPrimaryRGB.g},
                    ${this.chartCategoryRootBar.colorPrimaryRGB.b},
                    ${backgroundColorGradient * (configuration.dataParse.summary.length - i)})`,
				hoverBackgroundColor: `rgba(${this.chartCategoryRootBar.colorAccentRGB.r},
                    ${this.chartCategoryRootBar.colorAccentRGB.g},
                    ${this.chartCategoryRootBar.colorAccentRGB.b},
                    1`
			});
		}
		this.chartCategoryRootBar.removeData(this.chartCategoryRootBar.chart.labels);
		this.chartCategoryRootBar.removeData(this.chartCategoryRootBar.chart.datasets);
		const tempSerie = [];
		this.chartCategoryRootBar.fullDataSets.forEach(item => {
			const serie = _.cloneDeep(item);
			serie.data = item.data;
			tempSerie.push(serie);
		});
		this.chartCategoryRootBar.addData(this.chartCategoryRootBar.chart.datasets, tempSerie);
		this.chartCategoryRootBar.addData(this.chartCategoryRootBar.chart.labels, this.chartCategoryRootBar.fullLabels);
		this.chartCategoryRootBar.chart.options = {
			responsive: true,
			plugins: {
				tooltip: {
					intersect: false,
					callbacks: {

						label: function(context) {
							const label = context.dataset.label +
							' ' +
							UtilPrice.returnItemValueFormatted(context.raw as number, 0) + configuration.data.unit[configuration.unit];

							return label;
						}
						// label: (tooltipItem: ChartTooltipItem<TType>, data: ChartData) => {
						// 	const label =
						// 		data.datasets[tooltipItem.datasetIndex].label +
						// 		' ' +
						// 		UtilPrice.returnItemValueFormatted(
						// 			data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number,
						// 			0
						// 		) +
						// 		configuration.data.unit[configuration.unit];
						// 	return label;
						// }
					}
				}
			},
			scales: {
				y: {
					ticks: {
						display: true,
						callback: this.chartCategoryRootBar.ticksCallbackReturnKIfThousand()
					}
				}
				// yAxes: [
				// 	{
				// 		ticks: {
				// 			display: true,
				// 			callback: this.chartCategoryRootBar.ticksCallbackReturnKIfThousand()
				// 		}
				// 	}
				// ]
			}
		};
		this.chartCategoryRootBar.chart = _.cloneDeep(this.chartCategoryRootBar.chart);
	}

	chartStatistcSummaryListDoughnutUpdate(configuration: StatisticModel.NestedCategoryConfiguration) {
		this.chartStatistcSummaryListDoughnut = [];
		const summaryTotal: StatisticModel.Base[] = configuration.dataParse.summary.filter(i => i.total);
		for (let i = 0; i < summaryTotal.length; i++) {
			this.chartStatistcSummaryListDoughnut[i] = new BaseChartClass(
				this.utilService,
				this.utilColorService,
				this.store,
				this.route,
				this.dateMomentService
			);
			this.chartStatistcSummaryListDoughnut[i].chart.type = 'doughnut';
			this.chartStatistcSummaryListDoughnut[i].chart.colors = [
				{ borderColor: this.chartStatistcSummaryListDoughnut[i].colorWhite }
			];
			this.chartStatistcSummaryListDoughnut[i].fullDataSets = [];
			for (const category of configuration.dataParse.categoryTree) {
				this.chartStatistcSummaryListDoughnut[i].fullLabels.push(
					this.utilTranslateService.getTranslationFromLanguage(category.language_list).description
				);
			}
			const backgroundColorGradient: number = 1 / configuration.dataParse.categoryTree.length;
			const valueList: number[] = [];
			const colorList: string[] = [];
			for (let c = 0; c < configuration.dataParse.categoryTree.length; c++) {
				let statisticItem: StatisticModel.Base;
				if (
					configuration.dataParse.categoryTree[c].statisticList &&
					configuration.dataParse.categoryTree[c].statisticList.length
				) {
					statisticItem = configuration.dataParse.categoryTree[c].statisticList.find(
						s => s.key === summaryTotal[i].key
					);
				}
				valueList.push(
					statisticItem && statisticItem.total
						? (statisticItem.total[configuration.unit] * 100) / summaryTotal[i].total[configuration.unit]
						: 0
				);
				colorList.push(
					`rgba(${this.chartStatistcSummaryListDoughnut[i].colorPrimaryRGB.r},
                    ${this.chartStatistcSummaryListDoughnut[i].colorPrimaryRGB.g},
                    ${this.chartStatistcSummaryListDoughnut[i].colorPrimaryRGB.b},
                    ${backgroundColorGradient * (configuration.dataParse.categoryTree.length - c)})`
				);
			}
			this.chartStatistcSummaryListDoughnut[i].fullDataSets.push({
				label: null,
				data: valueList,
				backgroundColor: colorList,
				hoverBackgroundColor: `rgba(${this.chartStatistcSummaryListDoughnut[i].colorAccentRGB.r},
                    ${this.chartStatistcSummaryListDoughnut[i].colorAccentRGB.g},
                    ${this.chartStatistcSummaryListDoughnut[i].colorAccentRGB.b},
                    1`
			});
			this.chartStatistcSummaryListDoughnut[i].removeData(this.chartStatistcSummaryListDoughnut[i].chart.labels);
			this.chartStatistcSummaryListDoughnut[i].removeData(
				this.chartStatistcSummaryListDoughnut[i].chart.datasets
			);
			const tempSerie = [];
			this.chartStatistcSummaryListDoughnut[i].fullDataSets.forEach(item => {
				const serie = _.cloneDeep(item);
				serie.data = item.data;
				tempSerie.push(serie);
			});
			this.chartStatistcSummaryListDoughnut[i].addData(
				this.chartStatistcSummaryListDoughnut[i].chart.datasets,
				tempSerie
			);
			this.chartStatistcSummaryListDoughnut[i].addData(
				this.chartStatistcSummaryListDoughnut[i].chart.labels,
				this.chartStatistcSummaryListDoughnut[i].fullLabels
			);
			this.chartStatistcSummaryListDoughnut[i].chart.options = {
				responsive: true,
				plugins: {
					title: {
						display: true,
						text: `${summaryTotal[i].total.description}`,
						position: 'bottom'
					},
					tooltip: {
						intersect: false,
						callbacks: {

							label: function(context) {
								const label = context.label;

								const value = UtilPrice.returnItemValueFormatted(
									context.raw  as number,
									1
								);

								return `${label}: ${value}%`;

				// 			// label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
				// 			// 	let label = data.labels[tooltipItem.index];
				// 			// 	let value = UtilPrice.returnItemValueFormatted(
				// 			// 		data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number,
				// 			// 		1
				// 			// 	);
				// 			// 	return `${label}: ${value}%`;
				// 			// }
							}
						}
					}
				},
			};
			this.chartStatistcSummaryListDoughnut[i].chart = _.cloneDeep(
				this.chartStatistcSummaryListDoughnut[i].chart
			);
		}
	}

	// sidebar
	// form util
	// create
	// group organization
	// formGroupOrganizationCreate() {
	// 	const formGroup = {} as FormGroup;
	// 	formGroup[StatisticEnum.FormField.GROUP_ORGANIZATION] =
	// 		new FormControl(StatisticGroupEnum.Group.Value.ORGANIZATION_ALL);
	// 	this.formGroupOrganization = this.fb.group(formGroup);
	// }

	// comparison year
	formComparisonYearCreate() {
		const formGroup = {} as FormGroup;
		formGroup[StatisticEnum.FormField.COMPARISON_YEAR] =
			new FormControl(this.comparisonYearList[0]);
		this.formComparisonYear = this.fb.group(formGroup);
	}

	// filter
	formFilterCreate() {
		const categoryGroup = {} as FormGroup;
		this.categoryListFilter.forEach(i => {
			categoryGroup[i.code_item] = new FormControl(true);
		});
		this.formFilter = this.fb.group({
			category: this.fb.group(categoryGroup),
			unit: [this.statisticNestedCategoryConfiguration.unit]
		});
	}

	// return form fiel
	returnFormField(form: FormGroup, formControlName: string) {
		const formField: AbstractControl = form.get(formControlName);
		return formField ? formField.value : null;
	}

	// store util
	dispatchStatisticSalesReports(groupCodeItem: string, comparisonYear: number) {
		this.store.dispatch(
			StatisticStateAction.load({
				_id:
					'statistic' +
					ConfigurationCustomerAppStructure.noSqlDocSeparator +
					StatisticEnum.Type.SALES_REPORTS +
					'_' +
					this.user.current_permission.context_code.code +
					'_' +
					groupCodeItem +
					'_' +
					comparisonYear
			})
		);
	}

	returnStatisticGroupList(statisticGroupDocument: StatisticGroupModel.Document[]) {
		this.statisticGroupList = [];
		for (const type in StatisticGroupEnum.Type) {
			const item: StatisticGroupModel.GroupListItem = {
				groupType: StatisticGroupEnum.Type[type],
				data: statisticGroupDocument.filter(i => i.group.type === StatisticGroupEnum.Type[type])
			};
			item.data.sort((a, b) => a.sequence - b.sequence);
			this.statisticGroupList.push(item);
		}
	}

}
