export interface INavItem {
	name: string;
	icon?: string;
	route?: any;
	parent?: NavItem;
	subItems?: NavItem[];
	position?: number;
	badge?: string;
	badgeColor?: string;
	customClass?: string;
	iconCustom?: boolean;
}

export class NavItem {
	name: string;
	icon: string;
	route: any;
	parent: NavItem;
	subItems: NavItem[];
	position: number;
	badge: string;
	badgeColor: string;
	customClass: string;
	iconCustom?: boolean;

	constructor(model: any = null) {
		if (model) {
			this.name = model.name;
			this.icon = model.icon;
			this.route = model.route;
			this.parent = model.parent;
			this.subItems = this.mapSubItems(model.subItems);
			this.position = model.position;
			this.badge = model.badge;
			this.badgeColor = model.badgeColor;
			this.customClass = model.customClass;
			this.iconCustom = model.iconCustom;
		}
	}

	// verifica se ha sotto elementi
	hasSubItems() {
		if (this.subItems) {
			return this.subItems.length > 0;
		}
		return false;
	}

	hasRouting() {
		return this.route.length > 0;
	}

	// verifica se ha sotto parenti
	hasParent() {
		return !!this.parent;
	}

	// prende dei componenti di tipo NavItem in ingresso e li elabora per collegare tra loro in un rapporto padre-figlio i NavItem
	mapSubItems(list: NavItem[]) {
		if (list) {
			list.forEach((item, index) => {
				list[index] = new NavItem(item);
			});

			return list;
		}
	}

	isRouteString() {
		return this.route instanceof String || typeof this.route === 'string';
	}
}
