import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//widget
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'table-column-toggle',
  templateUrl: './table-column-toggle.component.html',
  styleUrls: ['./table-column-toggle.component.scss']
})
export class TableColumnToggleComponent implements OnInit {

  _columns: ITdDataTableColumn[];
  @Output() emitter = new EventEmitter<any>();
  @Input() set columns(c: ITdDataTableColumn[]) {
    if (c) {
      this._columns = c.filter(i => i.label);
    }
  }
  constructor() {}
  ngOnInit() {}
  checkboxChange(e: MatCheckboxChange, c:ITdDataTableColumn) {
    let i = this._columns.filter(i=>i.name == c.name);
    let o = i[0];
    o.hidden = !e.checked;
    this.emitter.emit(o);
  }

}
