import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-b2c-organization-detail',
	templateUrl: './b2c-organization-detail.component.html',
	styleUrls: ['./b2c-organization-detail.component.scss']
})
export class B2cOrganizationDetailComponent {

	constructor(public router: Router) {
	}

}
