<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<!-- {{ 'general.items_number' | translate | sentencecase }}
		<span class="badge ml-3" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span> -->
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="_id" let-value="value" let-row="row">
			<a href="javascript:void(0)" (click)="onItemSelect.emit(row)">
				{{ guidFormatterPipe.transform(value) }}
			</a>
		</ng-template>
		<ng-template tdDataTableTemplate="organization.contact_list" let-value="value" let-row="row">
			{{ getMainContactEmail(value) }}
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<!-- <loading-results-spinner *ngIf="isLoading"></loading-results-spinner> -->
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
