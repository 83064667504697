<form class="michelangelo-theme" *ngIf="form" [formGroup]="form">
	<section class="filter-simple">
		<mat-form-field appearance="outline">
			<mat-select formControlName="sort" [placeholder]="'general.sort.order_by' | translate | sentencecase">
				<mat-option *ngFor="let o of _configuration.list" [value]="o">
					{{ utilService.formatPlaceholder(o.label ? (o.label | translate) : o.name) | sentencecase }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</section>
</form>
