<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ 'general.items_number' | translate | sentencecase }}
		<span class="badge ml-3">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="action_type" let-value="value" let-row="row">
			<p>
				<a href="javascript:void(0)" (click)="onItemSelect.emit(row)">
					<mat-icon
						[matTooltip]="value ? ('action.type.' + value | translate | sentencecase) : '-'"
						class="icon"
						>{{ getIcon(value) }}</mat-icon
					>
				</a>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="organization.business_name" let-value="value" let-row="row">
			<p>
				{{ value || '-' | titlecase }}
				<br />
				<a
					href="javascript:void(0)"
					(click)="selectCompany.emit({ level: row.organization.level, code: row.organization.code_item })"
				>
					<!-- <small class="cell-subtitle">
						{{ (row && row.organization ? row.organization.business_name : '') | sentencecase }}
					</small> -->
				</a>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="assegnee_full_name" let-value="value">
			<p>
				{{ value || '-' | titlecase }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="start_date" let-value="value">
			<p>
				{{ value | date: 'dd/MM/yyyy' }}
				{{ value | date: 'HH:mm' }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="close_date" let-value="value">
			<p>
				{{ value | date: 'dd/MM/yyyy' }}
				{{ value | date: 'HH:mm' }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="estimated_close_date" let-value="value">
			<p>
				{{ value | date: 'dd/MM/yyyy' }}
				{{ value | date: 'HH:mm' }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="status" let-value="value">
			{{ value ? ('action.status.' + value | translate | sentencecase) : '-' }}
			<mat-icon *ngIf="value && value == ActionStatusEnum.A" color="warn">warning</mat-icon>
		</ng-template>
		<ng-template tdDataTableTemplate="actions" let-value="value" let-row="row">
			<button
				mat-icon-button
				aria-label="close action"
				[matTooltip]="'action.close' | translate | sentencecase"
				matTooltipPosition="left"
				(click)="openDialogConfirmCloseAction.emit(row)"
				*ngIf="row.status != ActionStatusEnum.E"
			>
				<mat-icon>close</mat-icon>
			</button>
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
