export enum ItemCallback {
	SET_VALIDATOR_LIST = 'set_validator_list',
	SET_DISABLED = 'set_disabled'
}

export enum ItemType {
	BOOLEAN = 'BOOLEAN',
	NUMBER = 'NUMBER',
	STRING = 'STRING',
	SELECT = 'SELECT',
	SELECT_MULTIPLE = 'SELECT_MULTIPLE',
	DATE = 'DATE',
	DATE_RANGE = 'DATE_RANGE',
	FORM_GROUP = 'FORM_GROUP',
	FORM_ARRAY = 'FORM_ARRAY',
	IMAGE_PREVIEW = 'IMAGE_PREVIEW',
	RTF = 'RTF'
}

export enum DateRange {
	START = 'start',
	END = 'end'
}