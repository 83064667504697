import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'form-button-bar',
	templateUrl: './form-button-bar.component.html',
	styleUrls: ['./form-button-bar.component.scss']
})
export class FormButtonBarComponent implements OnInit {
	// Primary
	@Input() primaryButtonLabel: string;
	@Input() primaryButtonIconLabel: string;
	@Input() primaryButtonIsDisabled: boolean;
	@Input() primaryButtonIsSubmit: boolean = false;
	@Input() primaryButtonClass?: string;
	@Output() primaryButtonClickEmitter = new EventEmitter();


	// Secondary
	@Input() secondaryButtonLabel: string;
	@Input() secondaryButtonIconLabel: string;
	@Input() secondaryButtonIsDisabled: boolean;
	@Input() secondaryButtonClass?: string;
	@Output() secondaryButtonClickEmitter = new EventEmitter();

	// Third
	@Input() thirdButtonLabel: string;
	@Input() thirdButtonIconLabel: string;
	@Input() thirdButtonIsDisabled: boolean;
	@Input() thirdButtonClass?: string;
	@Output() thirdButtonClickEmitter = new EventEmitter();

	// Other
	@Input() otherButtonLabel: string;
	@Input() otherButtonIconLabel: string;
	@Input() otherButtonIsDisabled: boolean;
	@Input() otherButtonClass?: string;
	@Output() otherButtonClickEmitter = new EventEmitter();

	// Align
	@Input() align: 'left' | 'right' | 'vertical' | 'space-between' = 'left';

	constructor() {}

	ngOnInit() {}
}
