import { ConfigurationUtil } from '@saep-ict/angular-spin8-core';

export const exportOrderFileName = 'orders.xlsx';
export const exportOrderWorksheetName = 'orders';
export const exportOrganizationFileName = 'customers.xlsx';
export const exportOrganizationWorksheetName = 'customers';
export const exportProductStockFileName = 'productStock.xlsx';
export const exportProductStockWorksheetName = 'productStock';

export const enableExportOrder: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    BACKOFFICE: true,
    BACKOFFICE_ADMIN: true,
    B2B: true,
    B2C: true,
    AGENT: true,
    PORTAL: true,
    CRM: true
}

export const enableExportOrganization: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    BACKOFFICE: true,
    BACKOFFICE_ADMIN: true,
    B2B: true,
    B2C: true,
    AGENT: true,
    PORTAL: true,
    CRM: true
}

export const enableExportProductStock: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    BACKOFFICE: true,
    BACKOFFICE_ADMIN: true,
    B2B: true,
    B2C: true,
    AGENT: true,
    PORTAL: true,
    CRM: true
}