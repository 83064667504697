import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseState, BaseStateModel, SentencecasePipe } from '@saep-ict/angular-core';
import { StateFeature } from '../../state';
import { OrderActionEnum, OrderStateAction } from '../../state/order/order.actions';
import { OrderStateModel, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderListStateAction } from '../../state/order-list/order-list.actions';

@Injectable()
export class CheckoutTransactionGuard implements CanActivate {
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);

	constructor(
		private store: Store,
		private router: Router,
		private snackBar: MatSnackBar,
		private sentencecasePipe: SentencecasePipe,
		private translateService: TranslateService
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this.store.dispatch(OrderStateAction.load(new BaseState(route.params['orderId'])));
		
		return this.order$.pipe(
			filter((e: BaseStateModel<OrderStateModel>) =>
				!!(
					e &&
					e.type !== OrderActionEnum.LOAD &&
					(
						e.data ||
						e.type === OrderActionEnum.ERROR
					)
				)
			),
			map(e => {
				switch (e.type) {
					case OrderActionEnum.UPDATE: {
						return true;
					}
					case OrderActionEnum.ERROR: {
						const message =
							this.sentencecasePipe.transform(
								this.translateService.instant('order.error.not_found')
							);
						this.snackBar.open(message, 'OK', null);
						this.router.navigate([ROUTE_URL.public]);
						this.store.dispatch(OrderStateAction.skip());
						this.store.dispatch(OrderListStateAction.skip());
						return false;
					}
				}
			})
		);
	}
}
