import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import {
	StatisticsDetailExpiredActionEnum,
	StatisticsDetailExpiredStateAction
} from './statistics-detail-expired.action';
import { StateFeature } from '..';
import { DateMomentService } from '@saep-ict/angular-core';
import { StatisticsBaseSerieOrder } from '../../model/statistics.model';
import { StatisticsDetailBalance } from '../../model/statistics-detail-balance.model';
import { PouchDbModel } from '@saep-ict/angular-spin8-core';

@Injectable()
export class StatisticsDetailExpiredEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsDetailExpiredActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsDetailBalance>) => {
				return from(this.getStatisticsDetailBalance(action));
			}),
			mergeMap((action: BaseStateModel<StatisticsDetailBalance>) =>
				from(this.sortStatisticsDetailBalance(action))
			),
			mergeMap((action: BaseStateModel<StatisticsDetailBalance>) =>
				from(this.convertDateStatisticsDetailBalance(action))
			),
			map((action: BaseStateModel<StatisticsDetailBalance>) => StatisticsDetailExpiredStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsDetailExpiredStateAction.error(null));
				return caught;
			})
		)
	);

	// Chiavi di Balance
	keyBalance = ['due', 'expired'];

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private dateMomentService: DateMomentService
	) {}

	async getStatisticsDetailBalance(
		action: BaseStateModel<StatisticsDetailBalance>
	): Promise<BaseStateModel<StatisticsDetailBalance>> {
		let code_item;
		await this.store
			.select(StateFeature.getUserState)
			.pipe(take(1))
			.subscribe(res => {
				code_item = res ? res.data.current_permission.context_code.code : null;
			});
		await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.STATISTICS)
		).statisticsPouch
			.getStatisticsDetailBalance(code_item)
			.then(res => {
				action.data = res;
			})
			.catch(err => console.log(err));
		return action;
	}

	async sortStatisticsDetailBalance(
		action: BaseStateModel<StatisticsDetailBalance>
	): Promise<BaseStateModel<StatisticsDetailBalance>> {
		this.keyBalance.forEach(key => {
			if (action.data[key]) {
				action.data[key].sort((a, b) => a.date - b.date);
			}
		});
		return action;
	}

	async convertDateStatisticsDetailBalance(
		action: BaseStateModel<StatisticsDetailBalance>
	): Promise<BaseStateModel<StatisticsDetailBalance>> {
		this.keyBalance.forEach(key => {
			if (action.data[key]) {
				action.data[key].map((element: StatisticsBaseSerieOrder) => {
					element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
				});
			}
		});

		return action;
	}
}
