<div *ngIf="_configuration" class="forecast-input-list-wrapper">
	<div class="labels">
		<div *ngFor="let input of _configuration.data" class="column label">
			<span>{{ (input.label ? (input.label | translate) : input.key) | sentencecase }}</span>
		</div>
	</div>
	<div class="inputs">
		<div *ngFor="let input of _configuration.data" class="column input">
			<span class="prefix">{{ (input.label ? (input.label | translate) : input.key) | sentencecase }}</span>
			<input
				appOnlyNumbers
				[value]="input.value"
				[disabled]="input.disabled"
				placeholder="0"
				(input)="inputUpdate({ key: input.key, event: $event })"
			/>
		</div>
	</div>
	<div class="total" [matTooltip]="inputListTotal.toString()">{{ inputListTotal }}</div>
</div>
