import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
	selector: 'dialog-text-edit',
	templateUrl: './dialog-text-edit.component.html',
	styleUrls: ['./dialog-text-edit.component.scss']
})
export class DialogTextEditComponent implements OnInit {
	form: UntypedFormGroup;
	constructor(
		private fb: UntypedFormBuilder,
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<DialogTextEditComponent>
	) {
		this.createForm();
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			text: [
				this.data.text,
				{ disabled: this.data.disabled, validators: this.data.optional ? null : Validators.required }
			]
		});

		// TODO - controllare come mai non funzionava dal form builder
		if (this.data.disabled) {
			this.form.get('text').disable();
		}
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.dialogRef.close(this.prepareSaveForm());
		}
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm = {
			text: formModel.text as string
		};
		return saveForm.text;
	}
}
