import { UtilDownloadModel } from "../model/structure/util-download.model";

export const handleDownloadThroughIframe = (url: string, iframeId: string) => {
	let iframe = document.getElementById(iframeId);
	if (!iframe) {
		iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		iframe.setAttribute('id', iframeId);
		document.body.appendChild(iframe);
	}
	iframe.setAttribute('src', url);
}

export const downloadThroughIframeRemove = (iframeId: string) => {
	let iframe = document.getElementById(iframeId);
	if (iframe) {
		iframe.remove();
	}
}

export const isPopupBlockerActive = (): boolean => {
	let popupBlockerActive = false;
	for (let i = 0; i < 2; i++) {
		const popup = window.open();
		if (!popup || popup.closed || typeof popup.closed === 'undefined') {
			popupBlockerActive = true;
		} else {
			popup.close();
		}
	}
	return popupBlockerActive;
}

export const downloadBase64 = (base64: string, fileType: string, fileName: string) => {
	base64 = atob(base64);
	// TODO: atob is deprecated, test and substitute with following code
	// const buffer = Buffer.from(base64, 'base64');
	// base64 = buffer.toString('base64');
	const byteArrays = [];
	for (let offset = 0; offset < base64.length; offset += 512) {
		const slice = base64.slice(offset, offset + 512);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	const blob = new Blob(byteArrays, { type: fileType });
	const data = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = data;
	link.download = fileName;
	link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
	setTimeout(() => {
		window.URL.revokeObjectURL(data);
		link.remove();
	}, 100);
}

export const returnDownloadBase64Parameter = (
	base64: string
): UtilDownloadModel.DownloadBase64Parameter => {
	const separatorIndex = base64.indexOf(',');
	const header = base64.slice(0, separatorIndex);
	const downloadBase64Parameter: UtilDownloadModel.DownloadBase64Parameter = {
		data: base64.slice(separatorIndex + 1),
  	extension: header.substring(header.indexOf('/') + 1, header.indexOf(';'))
	};
	return downloadBase64Parameter;
}