import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	BaseState,
	DialogConfirmComponent,
	AngularCoreUtilService,
	SentencecasePipe,
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StateFeature } from '../../../../../state';
import { OrderActionEnum, OrderStateAction } from '../../../../../state/order/order.actions';
import { DialogOfferFreeLineComponent } from '../../../../../widget/dialog/dialog-offer-free-line/dialog-offer-free-line.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OrderStateModel, OrganizationStateModel } from '@saep-ict/angular-spin8-core';
import {
	AngularSpin8CoreUtilOfferService,
	OfferStateModel,
	CrmOrderProductPouchModel
} from '@saep-ict/angular-spin8-core';
import * as  OfferFreelineListColumn from '../../../../../constants/configuration-customer/offer/offer-column-map/offer-free-line-column-map/offer-free-line-column-map-base.constant';

@Component({
	selector: 'offer-detail-checkout',
	templateUrl: './offer-detail-checkout.component.html',
	styleUrls: ['./offer-detail-checkout.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferDetailCheckoutComponent implements OnInit {
	// state
	offer: OfferStateModel;
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;
	columns = OfferFreelineListColumn.baseColumn;

	// evitare il sorting, altrimenti la modifica e la cancellazione è da modificare:
	// attualmente viene gestita tramite ID quindi l'ordine è importante
	public listPageBaseData = <LocalListHandlerBaseModel<CrmOrderProductPouchModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {},
		data: []
	};

	constructor(
		private router: Router,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private dialog: MatDialog,
		private utilOfferService: AngularSpin8CoreUtilOfferService,
		private utilService: AngularCoreUtilService,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe
	) {}

	ngOnInit() {
		this.subscribeManagerService.populate(this.subscribeOrder().subscribe(), 'order');
		this.organization$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1),
				map(state => {
					this.organization = state.data;
					return state;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: CrmOrderProductPouchModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	// Subscribe

	subscribeOrder() {
		return this.order$.pipe(
			filter(state => !!(state && state.data)),
			map(state => {
				if (state.type === OrderActionEnum.UPDATE) {
					this.offer = <OfferStateModel>state.data;
					this.updateListPageBaseData(this.offer.free_line_list);
				}

				return state;
			})
		);
	}

	openDialogOfferFreeLine(e: { freeLine?: CrmOrderProductPouchModel; index?: number }) {
		const dialogRef: MatDialogRef<DialogOfferFreeLineComponent> = this.dialog.open(DialogOfferFreeLineComponent, {
			data: { freeLine: e.freeLine },
			disableClose: true,
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});

		dialogRef.afterClosed().subscribe((res: CrmOrderProductPouchModel) => {
			if (res) {
				const newOffer = _.cloneDeep(this.offer);
				newOffer.free_line_list = newOffer.free_line_list || [];
				if (e.freeLine) {
					// edit
					newOffer.free_line_list[e.index] = res;
				} else {
					// add
					newOffer.free_line_list.push(res);
				}

				const newOfferToSave = this.utilOfferService.getPayloadToSaveOffer(
					newOffer,
					this.offer._id,
					this.organization
				);
				this.store.dispatch(OrderStateAction.save(new BaseState(newOfferToSave.orderData.order)));
			}
		});
	}

	deleteOfferFreeLine(index: number) {
		const format = i => this.sentencecasePipe.transform(this.translateService.instant(i));
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: format('offer.line.remove'),
				text: format('offer.line.question.delete')
			},
			disableClose: true,
			panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const newOffer = _.cloneDeep(this.offer);
				newOffer.free_line_list.splice(index, 1);

				const newOfferToSave = this.utilOfferService.getPayloadToSaveOffer(
					newOffer,
					this.offer._id,
					this.organization
				);
				this.store.dispatch(OrderStateAction.save(new BaseState(newOfferToSave.orderData.order)));
			}
		});
	}

	// L'invio dell'offerta è nel componente order-detail-checkout

	// sendOffer() {
	// 	if (this.offer.free_line_list.length > 0 || this.offer.product_list.length > 0) {
	// 		const newOffer = _.cloneDeep(this.offer);
	// 		newOffer.header.status = OrderStatusEnum.READY_TO_SEND;

	// 		const newOfferToSave = this.utilOfferService.getPayloadToSaveOffer(
	// 			newOffer,
	// 			this.offer._id,
	// 			this.organization
	// 		);
	// 		this.store.dispatch(OrderStateAction.save(new BaseState(newOfferToSave)));
	// 		this.utilService.showDialog('Offerta inviata');
	// 	} else {
	// 		this.utilService.showDialog('Aggiungi almeno un prodotto all\'offerta');
	// 	}
	// }
}
