import { Component, OnInit, Input } from '@angular/core';

export enum TrendDirectionValues {
	UP = 'up',
	DOWN = 'down',
	STEADY = 'steady',
	NONE = 'none'
}

export class ItemBoxTrendInfoComponentConfig {
	value: string | number;
	suffix?: string;
	title?: string;
	subtitle?: string;
	trendDirection?: TrendDirectionValues;
}

@Component({
	selector: 'box-trend-info',
	templateUrl: './box-trend-info.component.html',
	styleUrls: ['./box-trend-info.component.scss']
})
export class BoxTrendInfoComponent implements OnInit {
	// @Input('boxTrendInfoComponentConfig') set boxTrendInfoComponentConfig(items: Array<ItemBoxTrendInfoComponentConfig>) {
	//     for(let item of items) {
	//         this.addItem(item);
	//     }
	// }

	@Input() boxTrendInfoComponentConfig: Array<ItemBoxTrendInfoComponentConfig>;

	//_boxTrendInfoComponentConfig: Array<ItemBoxTrendInfoComponentConfig>;

	trendDirectionValues = TrendDirectionValues;

	// public trendDirection = {
	//     UP: 'up',
	//     DOWN: 'down',
	//     STEADY: 'steady'
	// }

	// @Input() hasSidebar: boolean = true;
	//@Input() sidebarOnTop: boolean = false;
	// @Input() trendDirection: string;

	//public trendIcon: string;

	constructor() {}

	ngOnInit(): void {
		// switch(this._boxTrendInfoComponentConfig.trendDirection) {
		//     case this._trendDirectionValues.UP:
		//         this.trendIcon = 'custom-trend-up';
		//         break;
		//     case this._trendDirectionValues.DOWN:
		//         this.trendIcon = 'custom-trend-down';
		//         break;
		//     case this._trendDirectionValues.STEADY:
		//         this.trendIcon = 'custom-trend-steady';
		//         break;
		//     case this._trendDirectionValues.NONE:
		//     default:
		//         this.trendIcon = 'none';
		//         break;
		// }
	}

	// addItem(item: ItemBoxTrendInfoComponentConfig) {
	//     this._boxTrendInfoComponentConfig.push(item);
	//     console.log('ITEM');
	//     console.log(typeof this._boxTrendInfoComponentConfig);
	// }
}
