import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';

@Injectable()
export class AngularSpin8CoreCatalogService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${returnApiUrlSegment(ApiType.PUBLIC)}/catalog/categories/`
	})
	getCategories: IResourceMethodPromise<void, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${returnApiUrlSegment(ApiType.PUBLIC)}/catalog/articles/{!code}/`
	})
	getArticle: IResourceMethodPromise<{ code: string }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${returnApiUrlSegment(ApiType.PUBLIC)}/catalog/articles/`
    })
	getArticles: IResourceMethodPromise<{ filter: string }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${returnApiUrlSegment(ApiType.PUBLIC)}/catalog/articles-default/`
	})
	getArticlesDefault: IResourceMethodPromise<{ organization_type: OrganizationTypeEnum }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${returnApiUrlSegment(ApiType.PUBLIC)}/catalog/articles-description/`
	})
	getArticlesDescription: IResourceMethodPromise<void, any>;

	search(term: string): Promise<any> {
		if (term) {
			const params: { filter: string } = {
				filter: term
			};
			// filter: {
			// 	query: {
			// 		wildcard: {
			// 			description: {
			// 				value: `*${term}*`
			// 				// boost: 1.0,
			// 				// rewrite: 'constant_score'
			// 			}
			// 		}
			// 	}
			// }

			return this.getArticles(params);
		}
	}
}
