import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
	selector: 'mat-snack-bar-wrapper',
	templateUrl: './mat-snack-bar-wrapper.component.html',
	styleUrls: ['./mat-snack-bar-wrapper.component.scss']
})
export class MatSnackBarWrapperComponent implements OnInit {
	constructor(
		public snackBarRef: MatSnackBarRef<MatSnackBarWrapperComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any
	) {}

	ngOnInit() {}
}
