<ng-container
  *ngIf="
    user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE ||
    user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE_ADMIN
  ">
  <h2>User</h2>
  <div class="row">
    <div *ngFor="let userProperty of userInfoToShow" class="col-lg-4">
      <mat-form-field>
        <mat-label>{{ 'user.field.' + userProperty | translate | titlecase }}</mat-label>
        <input matInput [value]="_ticket.request.user[userProperty]" disabled />
      </mat-form-field>
    </div>
  </div>
</ng-container>
<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onFormSubmit()"
>
  <ng-container *ngIf="_ticket.id">
    <h2>{{ 'tc.general.status' | translate | titlecase }}</h2>
    <mat-form-field>
      <!-- TODO: sostituire con select che pone in visualizzazione il valore senza incrociarlo con le option -->
      <mat-select formControlName="status">
        <mat-option
          *ngFor="
            let item of
            configuration.ticket.statusContextApplicationStatusMap[
              contextApplicationItemCodeEnum[
                user.current_permission.context_application
              ]
            ].selection_list
          "
          [value]="item.value"
        >
          {{ utilService.formatPlaceholder(item.name ? item.name : item.value).toLocaleLowerCase()| sentencecase }}
        </mat-option>
        <!-- TODO: rimuore fix temporaneo, vedi su -->
        <mat-option
          *ngIf="
            user.current_permission.context_application !== contextApplicationItemCodeEnum.BACKOFFICE &&
            user.current_permission.context_application !== contextApplicationItemCodeEnum.BACKOFFICE_ADMIN
          "
          [value]="_ticket.status"
        >
          {{ utilService.formatPlaceholder(_ticket.status).toLocaleLowerCase()| sentencecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container formGroupName="request">
    <h2>{{ 'tc.general.basic_information' | translate | titlecase }}</h2>
    <mat-slide-toggle color="primary" formControlName="urgent">{{ 'tc.general.urgent' | translate | sentencecase }}</mat-slide-toggle>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>{{ 'tc.general.date_request' | translate | sentencecase }}</mat-label>
          <input
            matInput
            formControlName="date_request"
            [matDatepicker]="date_request"
          />
          <mat-datepicker-toggle matSuffix [for]="date_request"></mat-datepicker-toggle>
          <mat-datepicker #date_request></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>{{ 'tc.general.date_due' | translate | sentencecase }}</mat-label>
          <input
            matInput
            formControlName="date_due"
            [matDatepicker]="date_due"
          />
          <mat-datepicker-toggle matSuffix [for]="date_due"></mat-datepicker-toggle>
          <mat-datepicker #date_due></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <ng-container formGroupName="body">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label> {{ 'tc.general.reference' | translate | titlecase }}</mat-label>
          <input matInput formControlName="reference" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label> {{ 'tc.general.subject' | translate | titlecase }}</mat-label>
          <input matInput formControlName="subject" />
        </mat-form-field>
      </div>
    </div>
    <mat-form-field>
      <mat-label>{{ 'tc.general.message' | translate | titlecase }}</mat-label>
      <textarea matInput formControlName="message"></textarea>
    </mat-form-field>
    <ng-container *ngIf="formCustomFieldList">
      <h2>{{ 'tc.general.additional_information' | translate | titlecase }}</h2>
      <div formGroupName="custom_field" class="row">
        <form-control-multipurpose
          *ngFor="
            let field of formCustomFieldList
              | filterByAllTheBooleans
                : {
                    propertyListName: ['on_form_template'],
                    value: true
                  }
          "
          [formControlName]="field.name"
          [field]="field"
          class="col-md-{{ field.column_width ? +field.column_width : 12 }}"
        ></form-control-multipurpose>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-md-6">
        <ng-container *ngIf="_ticket.body && _ticket.body.attachment_list && _ticket.body.attachment_list.length > 0">
          <h3>{{ 'tc.general.attachment_list' | translate | titlecase }}</h3>
          <mat-list dense>
            <mat-list-item *ngFor="let item of _ticket.body.attachment_list; let i = index">
              <a [href]="item.link" [matTooltip]="item.name" target="_blank">
                {{ item.name }}
              </a>
              <span class="flex-span"></span>
              <button
                type="button"
                mat-icon-button
                *ngIf="
                  user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE ||
                  user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE_ADMIN
                "
                (click)="deleteTicketBodyAttachmentItem(i)"
              >
                <mat-icon color="warn">clear</mat-icon>
              </button>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
        </ng-container>
      </div>
      <div class="col-md-6">
        <ng-container
          *ngIf="
            user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE ||
            user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE_ADMIN ||
            !_ticket.id
          "
        >
          <h3>{{ 'tc.general.attach_an_example' | translate | titlecase }}</h3>
          <input type="hidden" formControlName="attachment_list" />
          <attachment-resolver
            [multiple]="true"
            (fileEmitter)="setFormValue($event, 'body.attachment_list')"
          ></attachment-resolver>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="
      user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE ||
      user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE_ADMIN ||
      !_ticket.id
    "
    class="form-button-bar"
  >
    <button [disabled]="!form.valid" type="submit" mat-raised-button color="primary">
      {{ 'general.save' | translate | sentencecase }}
    </button>
    <button mat-stroked-button type="button" (click)="onFormCancel()">
      {{ 'general.cancel' | translate | sentencecase }}
    </button>
  </div>
</form>
