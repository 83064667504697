import {
    BodyTablePouchModel,
    CausalHeaderSoPouchModel,
    DestinationPouchModel,
    DiscountTypeEnum, PaymentPouchModel } from '@saep-ict/pouch_agent_models';
import { Injector } from '@angular/core';
import { AngularSpin8CoreUtilService, DiscountStateModel, OrderStateModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerDestination from '../destination/destination.constant';
import { OrderHeaderObjectBodyTablePouchModel } from '../../../model/order-util.model';

const injector = Injector.create({
	providers: [
		{ provide: AngularSpin8CoreUtilService, deps: [] }
	]
});

const utilService = injector.get(AngularSpin8CoreUtilService);

/**
 * Applica setOrderHeaderObject() per tutti gli elementi di orderHeaderObjectBodyTablePouchModelList
 * @param order
 */
export const setOrderHeaderObjectForBodyTablePouchModel = (
    order: OrderStateModel,
    orderHeaderObjectBodyTablePouchModelList: OrderHeaderObjectBodyTablePouchModel[]
) => {
    for (const i of orderHeaderObjectBodyTablePouchModelList) {
        setOrderHeaderObject<CausalHeaderSoPouchModel | PaymentPouchModel>(
            i.keyCode,
            i.keyObject,
            order,
            i.auxiliaryTableList
        );
    }
}

export const setOrderHeaderObject = <T extends BodyTablePouchModel>(
    keyCode: string,
    keyObject: string,
    order: OrderStateModel,
    collection: T[]
) => {
    if (order && order.header && order.header[keyCode]) {
        let orderHeaderObject: T = null;
        if (collection) {
            orderHeaderObject = collection.find(x => {
                return x.code_item === order.header[keyCode];
            });
            if (orderHeaderObject) {
                order.header[keyObject] = {
                    code_item: orderHeaderObject.code_item,
                    description: orderHeaderObject.description,
                    description_short: orderHeaderObject.description_short
                        ? orderHeaderObject.description_short
                        : null
                };
            }
        }
    }
}

export const setOrderHeaderDestinationDefault = (
    order: OrderStateModel,
    destinationList: DestinationPouchModel[]
) => {
    if (destinationList?.length) {
        const defaultDestination: DestinationPouchModel =
            utilService.returnIsMainOfList<DestinationPouchModel>(destinationList) ||
            ConfigurationCustomerDestination.returnIsRegisteredOffice(destinationList) ||
            destinationList[0];
        order.header.goods_destination_object = defaultDestination.address;
        order.header.goods_destination_object.code_item = defaultDestination.code_item;
        if (defaultDestination.code_erp) {
            order.header.goods_destination_object.code_erp = defaultDestination.code_erp;
        }
    }
}

export const setOrderHeaderDestinationObject = (
    order: OrderStateModel,
    destinationList: DestinationPouchModel[]
) => {
    if (order && order.header) {
        let orderHeaderObject: DestinationPouchModel;
        if (destinationList) {
            orderHeaderObject = destinationList.find(x => {
                return x.code_item === order.header.goods_destination_object.code_item;
            });
            if (orderHeaderObject) {
                order.header.goods_destination_object = orderHeaderObject.address;
                order.header.goods_destination_object.code_item = orderHeaderObject.code_item;
                if (orderHeaderObject.code_erp) {
                    order.header.goods_destination_object.code_erp = orderHeaderObject.code_erp;
                }
            }
        }
    }
}

export const setOrderHeaderDiscount = (
    order: OrderStateModel,
    unconditional_discounts: number[] = []
) => {
    if (order?.header && unconditional_discounts) {
        unconditional_discounts.forEach(unconditional_discount => {
            order.header.discount.push(
                new DiscountStateModel({
                    value: unconditional_discount,
                    type: DiscountTypeEnum.percentage
                })
            );
        });
    }
}

export const updateOrderWeight = (order) => {
    const totalWeight = order.product_list.reduce(
        (cumulative, current) =>
            cumulative + (current.articleDescription.weight || 0) * (current.ordered_quantity || 1),
        0
    );
    order.header.weight = totalWeight;
}