import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TdDataTableComponent } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import {
	OrganizationCreditBatchPouchModel,
	OrganizationCreditPouchModel,
	CustomerCreditSummaryTablePouchModel,
	GiveTakeFlagEnum,
	OrganizationCreditSummaryPouchModel,
	CurrencyPouchModel,
	ITdDataTableColumnCustom
} from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import {
	BaseStateModel,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { CompanyAccountBalanceFilterModel } from '../../../../../service/pouch-db/filter/company-account-balance-filter.model';
import { StateFeature } from '../../../../../state';
import { CompanyAccountBalanceAction } from '../../../../../state/company-account-balance/company-account-balance.actions';
import { CustomerCreditPouchModelCustom } from '../../../../../state/company-account-balance/company-account-balance.reducer';
import { TranslateService } from '@ngx-translate/core';
import { LineCurrencyChartConfigurationServiceLocal } from '../../../../../service/chart-structure/implementation/due-overdue-company-chart.service';
import { LocalListHandlerBaseModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { CreditListWrapperComponent } from '../../../../../widget/credit/credit-list-wrapper/credit-list-wrapper.component';
import { ContextApplicationItemCodeEnum, UserDetailModel, OrganizationStateModel } from '@saep-ict/angular-spin8-core';
import { ItemBoxTrendInfoComponentConfig } from '../../../../../widget/box-trend-info/box-trend-info.component';
import { TrendDirectionValues } from '../../../../../widget/box-trend-info/box-trend-info.component';
import * as ConfigurationCustomerOrganization from '../../../../../constants/configuration-customer/organization/organization.constant';
import { ItemBoxCreditDebitInfoComponentConfig } from '../../../../../widget/box-credit-debit-info/box-credit-debit-info.component';
import { AppUtilService } from '../../../../../service/util/app-util.service';
import * as UtilPrice from '../../../../../constants/util-price.constants';
import * as ConfigurationCustomerCreditColumns from '../../../../../constants/configuration-customer/credit/credit-column-map/credit-column-map-base.constant';

export enum AccountBalanceSectionEnum {
	DASHBOARD,
	LIST
}
@Component({
	selector: 'organization-detail-credit',
	templateUrl: './organization-detail-credit.component.html',
	styleUrls: ['./organization-detail-credit.component.scss'],
	providers: [SubscribeManagerService, LineCurrencyChartConfigurationServiceLocal]
})
export class OrganizationDetailCreditComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;
	@ViewChild(CreditListWrapperComponent) public creditListWrapperComponent: CreditListWrapperComponent;

	form: FormGroup;
	columns: ITdDataTableColumnCustom[] = ConfigurationCustomerCreditColumns.baseColumns;

	// enum
	accountBalanceSectionEnum = AccountBalanceSectionEnum;
	giveTakeFlagEnum = GiveTakeFlagEnum;
	sideBarPositionValues = SideBarPositionValues;

	credits: number = null;
	debits: number = null;
	creditsExpired: number = null;
	debitsExpired: number = null;
	outOfCredit: number = null;
	creditAvailabile: number = null;
	customerCreditBatchLastDate: string = null;

	clientCode: string = this.route.snapshot.paramMap.get('clientCode')
		? this.route.snapshot.paramMap.get('clientCode')
		: this.route.parent.snapshot.paramMap.get('clientCode');

	minHeight: string;

	customerCreditPouchModelCustom = <BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>>{
		data: {},
		dataSetting: {
			sort: [],
			appliedFilter: {
				organization_code: null,
				date_document: null,
				date_expiration: null,
				text: null
			}
		}
	};

	// companyAccountBalance
	companyAccountBalance$: Observable<BaseStateModel<CustomerCreditPouchModelCustom>>;
	balanceHeader: OrganizationCreditPouchModel;
	balanceSummary: CustomerCreditSummaryTablePouchModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>>;
	organization: OrganizationStateModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	currentContext: ContextApplicationItemCodeEnum;
	public contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	/**
	 * CardTrendComponent
	 */
	// cardTrendConfigurationDueSubtitle =
	// 	this.translate.instant('customer_credit.field.debit') +
	// 	' - ' +
	// 	this.translate.instant('customer_credit.field.credit');

	// cardTrendConfigurationDue: CardTrendConfigurationModel = {
	// 	title: this.translate.instant('customer_credit.field.due'),
	// 	subtitle: this.cardTrendConfigurationDueSubtitle,
	// 	trend: 0,
	// 	valueType: '€',
	// 	time: this.translate.instant('general.time.last_x_months', { amount: '3' }),
	// 	value: '0'
	// };

	// cardTrendConfigurationOverDueSubtitle =
	// 	this.translate.instant('customer_credit.field.debit_expired') +
	// 	' - ' +
	// 	this.translate.instant('customer_credit.field.credit_expired');
	// cardTrendConfigurationOverdue: CardTrendConfigurationModel = {
	// 	theme: 'mat-accent',
	// 	title: this.translate.instant('customer_credit.field.overdue'),
	// 	subtitle: this.cardTrendConfigurationOverDueSubtitle,
	// 	trend: 0,
	// 	valueType: '€',
	// 	time: this.translate.instant('general.time.last_x_months', { amount: '3' }),
	// 	value: '0'
	// };

	dueTrendBoxContent: Array<ItemBoxTrendInfoComponentConfig> = [];
	overdueTrendBoxContent: Array<ItemBoxTrendInfoComponentConfig> = [];

	dueCreditDebitBoxContent: Array<ItemBoxCreditDebitInfoComponentConfig> = [];
	overdueCreditDebitBoxContent: Array<ItemBoxCreditDebitInfoComponentConfig> = [];

	chartConfigurationDue = _.cloneDeep(this.lineCurrencyChartConfigurationService.default);
	chartConfigurationOverdue = _.cloneDeep(this.lineCurrencyChartConfigurationService.accent);

	customerCreditBatch: OrganizationCreditBatchPouchModel[] = [];
	public listPageBaseData = <LocalListHandlerBaseModel<OrganizationCreditBatchPouchModel>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: ['number_document', 'description_document']
			}
		},
		sort: {
			name: 'date_document',
			order: 'DESC'
		},
		pagination: {
			pageSize: 10
		},
		data: []
	};

	displayCreditDebitInfoBox: boolean;

	constructor(
		private store: Store,
		public route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private fb: FormBuilder,
		public lineCurrencyChartConfigurationService: LineCurrencyChartConfigurationServiceLocal,
		private translate: TranslateService,
		private utilService: AppUtilService
	) {
		this.companyAccountBalance$ = this.store.select(StateFeature.getCompanyAccountBalance);
		this.organization$ = this.store.select(StateFeature.getOrganizationState);

		this.user$.pipe(take(1)).subscribe(res => {
			this.currentContext = res ? res.data.current_permission.context_application : null;
			this.displayCreditDebitInfoBox =
				ConfigurationCustomerOrganization.displayCreditDebitInfoBox[
					ContextApplicationItemCodeEnum[this.currentContext]
				];
			this.changeTableLabel();
		});

		this.createForm();
	}

	ngOnInit() {
		this.minHeight = window.innerHeight - 150 + 'px';
	}

	ngAfterViewInit() {
		this.subscribeManagerService.populate(
			this.subscribeCompanyAccountBalance().subscribe(),
			'organization-account-balance'
		);
		this.subscribeManagerService.populate(this.subscribeCustomer().subscribe(), 'organization');
	}

	subscribeCustomer() {
		return this.organization$.pipe(
			skipWhile((organization: BaseStateModel<OrganizationPouchModel>) => !organization),
			map((res: BaseStateModel<OrganizationPouchModel>) => {
				if (res && res.data) {
					this.organization = res.data;
					this.customerCreditPouchModelCustom.dataSetting.appliedFilter.organization_code = this.organization.code_item;
					this.loadAccountBalanceFilteredList();
				}
			})
		);
	}

	subscribeCompanyAccountBalance() {
		return this.companyAccountBalance$.pipe(
			filter(
				(companyAccountBalance: BaseStateModel<CustomerCreditPouchModelCustom>) =>
					!!(
						companyAccountBalance &&
						companyAccountBalance.data &&
						companyAccountBalance.data.header &&
						companyAccountBalance.data.summary
					)
			),
			map((companyAccountBalance: BaseStateModel<CustomerCreditPouchModelCustom>) => {
				this.balanceHeader = companyAccountBalance.data.header;
				this.balanceSummary = companyAccountBalance.data.summary;

				if (this.balanceHeader.batch_list) {
					this.customerCreditBatch = this.balanceHeader.batch_list;
					this.creditListWrapperComponent.updateListPageBaseData(this.customerCreditBatch);
				}

				if (companyAccountBalance.data.summary.values.length > 0) {
					const summaryValues = companyAccountBalance.data.summary.values;
					this.customerCreditBatchLastDate = this.getSummaryLastDate(summaryValues);
					const lastSummary = summaryValues[summaryValues.length - 1];

					this.getChartValues(companyAccountBalance.data);
					// this.getCardTrendValues(firstSummary, lastSummary);
					this.getStatsCardValues(lastSummary);

					if (this.balanceHeader.amount_credit_granted && this.debitsExpired !== null) {
						this.outOfCredit = this.debitsExpired - this.balanceHeader.amount_credit_granted;
						this.outOfCredit = this.outOfCredit < 0 ? 0 : this.outOfCredit;
						this.creditAvailabile = this.balanceHeader.amount_credit_granted - this.debitsExpired;
						this.creditAvailabile = this.creditAvailabile < 0 ? 0 : this.creditAvailabile;
					}
				}
			})
		);
	}

	getStatsCardValues(organizationCreditSummary: OrganizationCreditSummaryPouchModel): void {
		/**
		 *  IMPORTANT - Data from the organization's point of view
		 */
		this.credits = organizationCreditSummary.total_take;
		this.debits = organizationCreditSummary.total_give;
		this.creditsExpired = organizationCreditSummary.total_take_expired;
		this.debitsExpired = organizationCreditSummary.total_give_expired;
	}

	// getCardTrendValues(
	// 	firstSummary: OrganizationCreditSummaryPouchModel,
	// 	lastSummary: OrganizationCreditSummaryPouchModel
	// ): void {
	// 	// Due Chart
	// 	this.cardTrendConfigurationDue.value = this.currencyPipe.transform(
	// 		lastSummary.balance_give_take,
	// 		'EUR',
	// 		'',
	// 		'',
	// 		'it'
	// 	);
	// 	this.cardTrendConfigurationDue.trend = +this.utilService
	// 		.getTrendPercentage(firstSummary.balance_give_take, lastSummary.balance_give_take)
	// 		.toFixed(1);

	// 	// Overdue Chart
	// 	this.cardTrendConfigurationOverdue.value = this.currencyPipe.transform(
	// 		lastSummary.balance_give_take_expired,
	// 		'EUR',
	// 		'',
	// 		'',
	// 		'it'
	// 	);
	// 	this.cardTrendConfigurationOverdue.trend = +this.utilService
	// 		.getTrendPercentage(firstSummary.balance_give_take_expired, lastSummary.balance_give_take_expired)
	// 		.toFixed(1);
	// }

	getSummaryLastDate(summariesValues: OrganizationCreditSummaryPouchModel[]): string {
		if (!summariesValues) {
			return '-';
		}
		const summaryLastDate = _.maxBy(summariesValues, function (el: OrganizationCreditSummaryPouchModel) {
			return moment(el.date);
		});
		return moment(summaryLastDate.date).format('DD/MM/YYYY');
	}

	private getChartValues(companyAccountBalance: CustomerCreditPouchModelCustom): void {
		// const dueSeriesChart: { x: string; y: number }[] = [];
		// const overdueSeriesChart: { x: string; y: number }[] = [];
		const dueSeriesChart: number[] = [];
		const dueLabelChart: string[] = [];
		const overdueSeriesChart: number[] = [];
		const overdueLabelChart: string[] = [];

		const amountCreditGrantedSeriesChart: { x: string; y: number }[] = [];

		companyAccountBalance.summary.values.forEach(value => {
			const date = moment(value.date).format('DD/MM/YYYY').toString();
			const dueValue = +value.balance_give_take.toFixed(2);
			const overdueValue = +value.balance_give_take_expired.toFixed(2);

			// dueSeriesChart.push({ x: date, y: dueValue });
			// overdueSeriesChart.push({ x: date, y: overdueValue });

			dueSeriesChart.push(dueValue);
			dueLabelChart.push(date);
			overdueSeriesChart.push(overdueValue);
			overdueLabelChart.push(date);

			if (companyAccountBalance.header.amount_credit_granted) {
				let amount_credit_granted = companyAccountBalance.header.amount_credit_granted;
				if (companyAccountBalance.header.sign_credit_granted === '-') {
					amount_credit_granted = -amount_credit_granted;
				}
				amountCreditGrantedSeriesChart.push({ x: date, y: amount_credit_granted });
			}
		});

		if (!this.dueCreditDebitBoxContent.length && !this.overdueCreditDebitBoxContent.length) {
			const lastItem = companyAccountBalance.summary.values.sort((a, b) => (a.date < b.date ? 1 : -1))[0];

			const credit = +lastItem.total_take.toFixed(2);
			const debit = +lastItem.total_give.toFixed(2);

			const suffix =
				`currency.${this.utilService.returnIsMainOfList<CurrencyPouchModel>(this.organization.currency).multilangRef}.short`;
			this.dueCreditDebitBoxContent.push(
				{
					value: UtilPrice.returnItemValueFormatted(credit),
					suffix: suffix,
					title: 'customer_credit.field.credit'
				},
				{
					value: UtilPrice.returnItemValueFormatted(debit),
					suffix: suffix,
					title: 'customer_credit.field.debit'
				}
			);

			const overdueCredit = +lastItem.total_take_expired.toFixed(2);
			const overdueDebit = +lastItem.total_give_expired.toFixed(2);
			this.overdueCreditDebitBoxContent.push(
				{
					value: UtilPrice.returnItemValueFormatted(overdueCredit),
					suffix: suffix,
					title: 'customer_credit.field.credit_expired'
				},
				{
					value: UtilPrice.returnItemValueFormatted(overdueDebit),
					suffix: suffix,
					title: 'customer_credit.field.debit_expired'
				}
			);

			const dueValue = +lastItem.balance_give_take.toFixed(2);
			const overdueValue = +lastItem.balance_give_take_expired.toFixed(2);
			this.dueTrendBoxContent.push({
				value: UtilPrice.returnItemValueFormatted(dueValue),
				suffix: suffix,
				trendDirection: TrendDirectionValues.NONE
			});
			this.overdueTrendBoxContent.push({
				value: UtilPrice.returnItemValueFormatted(overdueValue),
				suffix: suffix,
				trendDirection: TrendDirectionValues.NONE
			});
		}

		this.chartConfigurationDue.datasets[0] = { label: 'DOVUTO', data: dueSeriesChart };
		this.chartConfigurationDue.labels = dueLabelChart;

		this.chartConfigurationOverdue.datasets[0] = { label: 'DOVUTO SCADUTO', data: overdueSeriesChart };
		this.chartConfigurationOverdue.labels = overdueLabelChart;

		if (companyAccountBalance.header.amount_credit_granted) {
			this.chartConfigurationOverdue.datasets[1] = { label: 'FIDO', data: amountCreditGrantedSeriesChart };
		}
	}

	// private getTrendPercentage(oldValue: number, newValue: number): number {
	// 	if (oldValue === 0) {
	// 		newValue++;
	// 		oldValue++;
	// 	}
	// 	return ((newValue - oldValue) / Math.abs(oldValue)) * 100;
	// }

	loadAccountBalanceFilteredList() {
		this.store.dispatch(CompanyAccountBalanceAction.load(this.customerCreditPouchModelCustom));
	}

	createForm() {
		this.form = this.fb.group({
			date_document: [null],
			date_expiration: [null]
		});
	}

	setValueForm() {
		this.form.patchValue({
			date_document: this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_document,
			date_expiration: this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_expiration
		});
	}

	onFormSubmit() {
		if (!this.balanceHeader) {
			return;
		}

		if (this.form.valid) {
			const formReady = this.prepareSaveForm();

			this.customerCreditPouchModelCustom.dataSetting.appliedFilter.text = null;
			this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_document = formReady.date_document;
			this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_expiration = formReady.date_expiration;

			if (this.balanceHeader['batch_list']) {
				this.customerCreditBatch = this.balanceHeader['batch_list'].filter(
					(i: OrganizationCreditBatchPouchModel) => {
						let feedback = true;

						if (this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_document) {
							const begin = this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_document
								.begin;
							const end = this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_document.end;
							feedback = this.dateIsBetween(begin, end, i.date_document);
						}

						if (this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_expiration) {
							const begin = this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_expiration
								.begin;
							const end = this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_expiration
								.end;
							feedback = this.dateIsBetween(begin, end, i.date_expiration);
						}

						return feedback;
					}
				);
			}
		}
	}

	dateIsBetween(dateBegin, dateEnd, dateToCheck): boolean {
		const deltaFirstDate = moment(dateToCheck).diff(moment(dateBegin));
		const deltaLastDate = moment(dateToCheck).diff(moment(dateEnd));

		if (deltaFirstDate >= 0 && deltaLastDate <= 0) {
			return true;
		} else {
			return false;
		}
	}

	prepareSaveForm() {
		const formModel = this.form.value;

		const saveForm = {
			date_document: formModel.date_document
				? {
						begin: this.getUnixDate(formModel.date_document.begin, 'start'),
						end: this.getUnixDate(formModel.date_document.end, 'end')
				  }
				: null,
			date_expiration: formModel.date_expiration
				? {
						begin: this.getUnixDate(formModel.date_expiration.begin, 'start'),
						end: this.getUnixDate(formModel.date_expiration.end, 'end')
				  }
				: null
		};
		return saveForm;
	}

	// REFACTOR : da spostare in un servizio, usato anche in ticket list
	private getUnixDate(date: Date, dayMoment: string): number {
		let unixDate = moment(date);
		if (dayMoment === 'start') {
			unixDate = unixDate.startOf('day');
		} else if (dayMoment === 'end') {
			unixDate = unixDate.endOf('day');
		}
		return parseInt(unixDate.format('x'), 10);
	}

	resetFilters() {
		this.form.reset();
		this.onFormSubmit();
	}

	// widget
	toggleColumn(e) {
		for (let i = 0; i < ConfigurationCustomerCreditColumns.baseColumns.length; i++) {
			if (ConfigurationCustomerCreditColumns.baseColumns[i].name === e.name) {
				ConfigurationCustomerCreditColumns.baseColumns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	onSearching() {
		if (!this.balanceHeader) {
			return;
		}

		if (this.customerCreditPouchModelCustom.dataSetting.appliedFilter.text !== null) {
			this.form.reset();
			this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_document = null;
			this.customerCreditPouchModelCustom.dataSetting.appliedFilter.date_expiration = null;

			if (this.balanceHeader.batch_list) {
				this.customerCreditBatch = this.balanceHeader.batch_list.filter(
					(item: OrganizationCreditBatchPouchModel) =>
						item.number_document.includes(
							this.customerCreditPouchModelCustom.dataSetting.appliedFilter.text
						)
				);
				this.dataTable.refresh();
			}
		}
	}

	changeTableLabel() {
		// Solo per contesto b2b: invertire labels "dare" e "avere"
		if (this.currentContext === ContextApplicationItemCodeEnum.B2B) {
			ConfigurationCustomerCreditColumns.baseColumns.forEach(column => {
				if (column.name === 'flag_give_take_dare') {
					column.label = this.translate.instant('customer_credit.field.take');
				}
				if (column.name === 'flag_give_take_avere') {
					column.label = this.translate.instant('customer_credit.field.give');
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.store.dispatch(CompanyAccountBalanceAction.reset());
	}
}
