import { ContextApplicationItemCodeEnum, PermissionKeyEnum, ROUTE_URL, UserTypeContextModel } from '@saep-ict/angular-spin8-core';
import * as B2BRouteList from '../../../router/b2b-routing';
import * as BackofficeRouteList from '../../../router/backoffice-routing';

export const routingPermissionTypeList: UserTypeContextModel[] = [
	{
		permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
		route: BackofficeRouteList.routes,
		type: ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN,
		description: 'Backoffice Area (Admin)',
		action_key: 'backoffice_admin'
	},
	{
		permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
		route: BackofficeRouteList.routes,
		type: ContextApplicationItemCodeEnum.BACKOFFICE,
		description: 'Backoffice Area',
		action_key: 'backoffice'
	},
	{
		permission: PermissionKeyEnum.B2B_DASHBOARD,
		route: B2BRouteList.routes,
		type: ContextApplicationItemCodeEnum.B2B,
		description: 'B2B Area',
		action_key: 'organization'
	}
];

export const returnPublicNavigationCondition = (
    permissionContext: ContextApplicationItemCodeEnum[],
    currentUrl: string
): boolean => {
    return false;
}

export const enableNavigationForHomepage = false;