import { CreationDefaultValueContextCode } from './creation-default-value/creation-default-value-context-code.const';
import { Destination } from './creation-default-value/creation-default-value-destination.const';
import { ConfigurationUtil } from '../../../model/structure/configuration-util.model';

export namespace FormControlMap {
  // TODO: gestione default/override come per i creation form field
	export namespace CreationDefaultValue {
		export const ContextApplication = <
			ConfigurationUtil.Context.ApplicationSelector<ConfigurationUtil.Context.CodeSelector<any>>
		>{
			BACKOFFICE: {
				ORGANIZATION: CreationDefaultValueContextCode.Organization,
				BACKOFFICE: CreationDefaultValueContextCode.Backoffice,
				AGENT: CreationDefaultValueContextCode.Agent,
				CRM: CreationDefaultValueContextCode.CRM
			},
			AGENT: {
				ORGANIZATION: CreationDefaultValueContextCode.Organization
			},
			CRM: {
				ORGANIZATION: CreationDefaultValueContextCode.CRM
			},
			B2B: {
				ORGANIZATION: CreationDefaultValueContextCode.Organization
			},
			B2C: {
				ORGANIZATION: CreationDefaultValueContextCode.Organization
			},
			PUBLIC: {
				ORGANIZATION: CreationDefaultValueContextCode.Organization
			}
		};
		export const destination = {
			base: Destination.base,
			edit: Destination.edit,
			create: Destination.create
		};
	}
}
