<mat-dialog-wrapper [title]="data.title | sentencecase">
	<ng-container content *ngIf="data.text">
		<p [innerHTML]="data.text"></p>
	</ng-container>
	<ng-container actions>
		<button mat-button [mat-dialog-close]="false" *ngIf="!data.hideCancelButton">
			{{ 'general.cancel' | translate | sentencecase }}
		</button>
		<button mat-raised-button color="primary" [mat-dialog-close]="true">
			{{ 'general.confirm' | translate | sentencecase }}
		</button>
	</ng-container>
</mat-dialog-wrapper>
