import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StatisticsBaseSerieProduct } from '../../../model/statistics.model';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AppUtilService } from '../../util/app-util.service';
import { BaseTableClass } from '../../td-data-table/base-table.service';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { chartTimeRange7Days, ContextApplicationItemCodeEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class OrderProductsTableConfigService extends BaseTableClass {
	columns: ITdDataTableColumn[] = [
		{
			name: 'description',
			label: this.translate.instant('product.field.name')
		},
		{
			name: 'value',
			label: this.translate.instant('product.field.total_income'),
			numeric: true
		}
	];

	cardDashboardConfigProductsTop5: CardDashboardConfig = {
		title: this.context === ContextApplicationItemCodeEnum.B2B ? 'chart.products_top_x.title_customer' : 'chart.products_top_x.title_seller',
		subtitle: this.context === ContextApplicationItemCodeEnum.B2B ? 'chart.products_top_x.subtitle_customer' : 'chart.products_top_x.subtitle_seller',
		helpText: 'chart.products_top_x.helptext',
		icon: 'assignment'
	};

	// Questa proprietà viene letta anche per popolare cardDashboardConfigOrderTrend
	public timeRange = chartTimeRange7Days;

	constructor(
		public store: Store<any>,
		@Inject(TranslateService) private translate: TranslateService,
		public utilService: AppUtilService,
		public route: ActivatedRoute
	) {
		super(utilService, store, route);
	}

	// Functions

	createDataSerie() {
		let productsTop5;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardOrders) {
			productsTop5 = this.utilService.findLeaf(this.statistics.data, `products`);
		} else {
			productsTop5 = this.utilService.findLeaf(this.statistics.data, `orders.products`);
		}

		this.utilService.findLeaf(productsTop5, 'days_7').forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetDays7.push(item);
		});

		this.utilService.findLeaf(productsTop5, 'days_15').forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetDays15.push(item);
		});

		this.utilService.findLeaf(productsTop5, 'days_30').forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetDays30.push(item);
		});
	}

	populateTable(rangeDays: number = this.timeRange.values[0]) {
		switch (rangeDays) {
			case this.timeRange.values[0]:
				this.currentDataSet = this.fullDataSetDays7;
				break;
			case this.timeRange.values[1]:
				this.currentDataSet = this.fullDataSetDays15;
				break;
			case this.timeRange.values[2]:
				this.currentDataSet = this.fullDataSetDays30;
				break;

			default:
				break;
		}
	}
}
