<button
	(click)="emitForNextPage()"
	#clickEventTriggerTag
	hidden
></button>
<ng-scrollbar
	#scrollbar
	(reachedTop)="scrollingUp($event);"
	[style.height.px]="threadHeight"
>
	<ng-container *ngIf="_chat && _chat.thread && _chat.thread.data && _chat.thread.data.length > 0; else noData">
		<div
			*ngFor="let message of _chat.thread.data"
			[ngClass]="{'sender': message.sender_user_info}"
			class="chat-item-container"
			[id]="message._id"
		>
			<div class="chat-item">
				<strong *ngIf="message.sender_user_info" class="sender">
					{{message.sender_user_info.first_name && message.sender_user_info.last_name ?  message.sender_user_info.first_name + ' ' + message.sender_user_info.last_name + ' | ': ''}}
					{{message.sender_user_info.username}}
				</strong>
				<div class="text">{{message.text}}</div>
				<div class="date-sending">{{message.date_sending | date: 'dd/MM/yy, HH:mm'}}</div>
			</div>
		</div>
	</ng-container>
	<ng-template #noData>
		<div class="no-message-found">
			No message for this thread...
		</div>
	</ng-template>
</ng-scrollbar>
<form
	[formGroup]="form"
	#resetTag="ngForm"
	(ngSubmit)="onFormSubmit()"
	*ngIf="!disabled"
>
	<i *ngIf="sender"><strong>{{sender}}</strong> is typing...</i>
	<mat-divider></mat-divider>
	<div class="row">
		<div class="col-10">
			<mat-form-field>
				<textarea matInput placeholder="Type a message" formControlName="text"></textarea>
			</mat-form-field>
		</div>
		<div class="col-2">
			<button mat-icon-button type="submit">
				<mat-icon>send</mat-icon>
			</button>
		</div>
	</div>
</form>
