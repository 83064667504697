import { Component } from '@angular/core';
import { ArticlePouchModel, FamilyPouchModel, LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import {
	GuidFormatterPipe,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
	SentencecasePipe
} from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	AngularSpin8CoreUtilTranslateService,
	AngularSpin8CoreUtilArticleService,
	PATH_URL,
	ROUTE_URL
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { BackofficeArticleFamilyDetailBaseDirective } from '../../../../shared/directives/backoffice-article-family/backoffice-article-family-detail-base.directive';
import { StateFeature } from '../../../../state';
import { SubscribeManagerItem } from '../../../../model/subscribe-manager.model';
import { FamilyListActionEnum, FamilyListStateAction } from '../../../../state/family-list/family-list.actions';
import * as ConfigurationCustomerFamilyBackofficeDetail from '../../../../constants/configuration-customer/family/family-backoffice-detail.constant';
import * as _ from 'lodash';
import { StoreUtilService } from '../../../../service/util/store-util.service';

@Component({
	selector: 'family-detail',
	templateUrl: './family-detail.component.html',
	styleUrls: ['../../../../shared/directives/backoffice-article-family/backoffice-article-family-detail-base.scss'],
	providers: [SubscribeManagerService]
})
export class FamilyDetailComponent extends BackofficeArticleFamilyDetailBaseDirective<FamilyPouchModel> {

	mainList$ = this.store.select(StateFeature.getFamilyList);
	familyList: FamilyPouchModel[];

	subscribeListForType: SubscribeManagerItem[] = [];
	mainListActionEnum = FamilyListActionEnum;
	mainListStateAction = FamilyListStateAction;
	configurationCustomerFamilyBackofficeDetail = ConfigurationCustomerFamilyBackofficeDetail;

	local_handler_related_family_list: LocalListHandlerBaseModel<FamilyPouchModel> =
		_.cloneDeep(this.local_handler_base);
	local_handler_alternative_family_list: LocalListHandlerBaseModel<FamilyPouchModel> =
		_.cloneDeep(this.local_handler_base);

	constructor(
		public store: Store,
		public subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public route: ActivatedRoute,
		public router: Router,
		public dialog: MatDialog,
		public articleService: AngularSpin8CoreUtilArticleService,
		public sentencecasePipe: SentencecasePipe,
		public translateService: TranslateService,
		public snackBar: MatSnackBar,
		public utilBreadcrumbService: UtilBreadcrumbService,
		public guidFormatterPipe: GuidFormatterPipe,
		public appConfig: CustomerAppConfig,
		public utilService: AppUtilService,
		public utilStoreService: StoreUtilService
	) {
		super(
			store,
			subscribeManagerService,
			utilTranslateService,
			route,
			router,
			dialog,
			articleService,
			sentencecasePipe,
			translateService,
			snackBar,
			utilBreadcrumbService,
			guidFormatterPipe,
			appConfig,
			utilService,
			utilStoreService
		);
	}

	async handleExtenderInit(): Promise<void> {
		await this.createFamilyListArticleDescription();
		this.localHandlerListParser<FamilyPouchModel>(
			this.mainItem.articleDescription.related_family_list,
			this.familyList,
			'related_family_list'
		);
		this.localHandlerListParser<FamilyPouchModel>(
			this.mainItem.articleDescription.alternative_family_list,
			this.familyList,
			'alternative_family_list'
		);
	}

	handleExtenderSave(itemPayload: any) {
		itemPayload.related_family_list = this.mainItem.articleDescription.related_family_list;
		itemPayload.alternative_family_list = this.mainItem.articleDescription.alternative_family_list;
	}

	createFamilyListArticleDescription(): Promise<void> {
		return new Promise(resolve => {
			this.familyList = [];
			for (let i = 0; i < this.mainList.length; i++) {
				this.familyList[i] = {
					code_item: this.mainList[i].code_item,
					code_erp: this.mainList[i].code_erp,
					valid: this.mainList[i].valid,
					articleDescription: this.mainList[i]
				} as any;
			}
			resolve();
		});
	}

	// TODO: gestire dinamicità per link alle famiglie/articoli
	goToArticleDetail(e: ArticlePouchModel) {
		const code = e.code_item;
		this.router.navigate([
			`${PATH_URL.PRIVATE}`,
			`${ROUTE_URL.catalog}`,
			`${ROUTE_URL.articles}`,
			`${code}`
		]);
	}

}
