import { Injectable } from '@angular/core';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import { AttachmentListManager } from '../../model/widget/attachmment-list-manager.model';
import { RestAttachmentListManagerService } from '../rest/rest-attachment-list-manager.service';
import { AngularCoreUtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class UtilAttachmentListManager {

  constructor(
    private restAttachmentListManagerService: RestAttachmentListManagerService,
    private utilService: AngularCoreUtilService
	) {}

  async postAttachmentList(
    attachmentList: File[],
    configuration: AttachmentListManager.Configuration<AttachedFile>
  ): Promise<AttachedFile[]> {
    return new Promise(async (resolve) => {
      try {
        const attachmentListReturn: AttachedFile[] = [];
        for (const attachment of attachmentList) {
          const pathQueryParamSegment = configuration.pathQueryParam ? `/${configuration.pathQueryParam}/` : '/';
          const formData = new FormData();
          formData.append('file', attachment);
          formData.append('path', pathQueryParamSegment);
          const res =
            await this.restAttachmentListManagerService.uploadMultipart(formData, null, {path: `${configuration.pathUrl}`});
          const attachedFile: AttachedFile = {
            id: null,
            alt: null,
            name:  res.data.file,
            data:  null,
            bucket_link:  null
          }
          ;
          attachmentListReturn.push(
            this.utilService.deleteEmptyProperties(attachedFile)
          );
        }
        resolve(attachmentListReturn);
      } catch(err) {
        throw new Error(err);
      }
    });
  }

  async deleteAttachmentList(
    attachmentList: AttachedFile[],
    configuration: AttachmentListManager.Configuration<AttachedFile>
  ): Promise<void> {
    return new Promise(async (resolve) => {
      try {
        const pathQueryParamSegment = configuration.pathQueryParam ? `/${configuration.pathQueryParam}/` : '/';
        const restDeletePayload: AttachmentListManager.RestDeletePayload = {
          pathList: attachmentList.map(i => pathQueryParamSegment + i.name)
        };
        await this.restAttachmentListManagerService.delete(restDeletePayload, null, {path: configuration.pathUrl});
        resolve();
      } catch(err) {
        throw new Error(err);
      }
    });
  }

}
