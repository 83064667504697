import { ITdDataTableColumn } from '@covalent/core/data-table';
import { ListStructureEnum } from '@saep-ict/pouch_agent_models';

export interface ListStructureModel<D> {
	pagination?: Pagination;
	filters?: Filters;
	data?: D;
}

export interface Pagination {
	page_current: number;
	page_size: number;
	total_element?: number;
}

export interface PouchPaginationModel {
	pagination: Pagination;
	dataSize: number;
}

export interface Filters {
	user_code?: string;
	// filtri tabella azienda
	order_states?: string[];
	company_states?: string[];
	business_name?: string;

	// filtri tabella ordini
	order_status?: string;
	client_code?: string;
	date?: {
		begin: string;
		end: string;
	};
	due_date?: {
		begin: string;
		end: string;
	};
	tot?: string[];
	order_causal?: string[];

	// filtri tabella prodotti
	prezzo?: string[];
	category?: string;
	desc_prodotto?: string;

	// filtri tabella prodotti (order-detail-checkout)
	article_codes?: string[];

	text?: string;
	types?: string[];

	// user-management
	exclude_code?: (string | number)[]; // TODO: una volta terminato lavoro su authguard portare a number[] il type
	context_code?: any; // TODO: capire se deprecare del tutto
	id?: string;
}

export interface Sort {
	sort_type?: string;
	sort_direction?: string;
}

export interface ITdDataTableColumnCustom extends ITdDataTableColumn {
  labelPath?: string; // percorso per giungere al valore di una traduzione contenuta nei bundle multilingua
  labelPathParam?: any; // oggetto che struttura eventuali parametri da passare al translate service
  sortType?: ListStructureEnum.Sort.Type
}
