import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseStateModel, SideBarPositionValues } from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { TdPagingBarComponent } from '@covalent/core/paging';
import { RequestListWrapperComponent } from '../../../widget/request/request-list-wrapper/request-list-wrapper.component';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../state/opportunity/opportunity.actions';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import _ from 'lodash';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';
import {
	ContextApplicationItemCodeEnum,
	OpportunityEnum,
	UserDetailModel,
	OpportunityPouchModel,
	ROUTE_URL,
	PATH_URL
} from '@saep-ict/angular-spin8-core';

import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import { AppUtilService } from '../../../service/util/app-util.service';
import { OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import * as RequestColumns from '../../../../app/constants/configuration-customer/request/request-column-map/request-column-map-base.constant'
@Component({
	selector: 'request-list',
	templateUrl: './request-list.component.html',
	styleUrls: ['./request-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class RequestListComponent implements OnDestroy {
	// state
	requestList$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(StateFeature.getRequestList);
	requestList: OpportunityPouchModel[];
	@ViewChild('pagingBarPageSize', { static: false }) public pagingBarPageSize: TdPagingBarComponent;
	@ViewChild(RequestListWrapperComponent)
	public requestListWrapperComponent: RequestListWrapperComponent;

	opportunityList$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunityList: OpportunityPouchModel[];
	opportunityLevel: string;
	OpportunityEnum = OpportunityEnum;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	organizationTypeList = Object.values(OrganizationTypeEnum);

	sideBarPositionValues = SideBarPositionValues;

	requestColumns = RequestColumns.baseColumns;

	public listPageBaseData = <LocalListHandlerBaseModel<OpportunityPouchModel>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['code_item', 'organization.business_name']
			}
		},
		sort: {
			name: 'date_creation',
			order: 'DESC'
		},
		data: []
		// languageKey: 'it'
	};

	formFilters: FormGroup;
	filtersActivated = false;
	statusFilter: string;

	routerNavigationSub: Subscription;

	constructor(
		private store: Store,
		private activatedRoute: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private router: Router,
		private fb: FormBuilder,
		private utilService: AppUtilService
	) {
		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.store.dispatch(OpportunityStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeOpportunityList().subscribe(), 'subscribeOpportunityList');

		this.setRouteMetaInformation();
		this.createFormFilters();
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.routerNavigationSub = this.router.events.subscribe((evt: RouterEvent) => {
			if (evt instanceof NavigationEnd) {
				this.statusFilter = evt.urlAfterRedirects.split('/').pop();
				if (this.opportunityList) {
					this.applyPermanentFilter();
				}
			}
		});
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
		this.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeOpportunityList() {
		return this.opportunityList$.pipe(
			filter((state: BaseStateModel<OpportunityPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<OpportunityPouchModel[]>) => {
				switch (state.type) {
					case OpportunityActionEnum.UPDATE:
						this.opportunityList = state.data;
						this.applyPermanentFilter();
						break;
					case OpportunityActionEnum.ERROR:
						throw new Error(OpportunityActionEnum.ERROR);
				}
				return state;
			})
		);
	}

	applyPermanentFilter() {
		let filteredNewsList: OpportunityPouchModel[] = [];
		switch (this.statusFilter) {
			case ROUTE_URL.allRequests:
				filteredNewsList = this.opportunityList;
				break;
			case ROUTE_URL.openRequests:
				filteredNewsList = this.opportunityList.filter(
					// questi status non sono la versione context app based, ma essendo open e closed condivisi trasversalmente
					// per ora può andar bene così
					i => i.status_code === OpportunityEnum.Status.Outcome.Open
				);
				break;
			case ROUTE_URL.closedRequests:
				filteredNewsList = this.opportunityList.filter(
					i => i.status_code === OpportunityEnum.Status.Outcome.Closed
				);
				break;
		}
		this.requestListWrapperComponent.updateListPageBaseData(filteredNewsList);
	}

	createFormFilters() {
		this.formFilters = this.fb.group({
			organizationType: ''
		});
		this.utilService.onCreateFormValueChange(this.formFilters).subscribe(val => (this.filtersActivated = val));
	}

	resetFilters() {
		this.formFilters.patchValue({
			organizationType: ''
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.opportunityList);
		if (this.formFilters && this.formFilters.value.organizationType) {
			const organizationType: string = this.formFilters.value.organizationType;
			filteredList = filteredList.filter(opportunity => {
				return opportunity.organization.organization_type === organizationType;
			});
		}

		this.requestListWrapperComponent.updateListPageBaseData(filteredList);
	}

	setRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('request_list');
			this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['request_list']);
		} else {
			this.utilBreadcrumbService.subtitle.value = 'tab_wrapper.opportunity';
		}
	}

	unsetRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		} else {
			this.utilBreadcrumbService.unsetRouteMetaInformation(this.utilBreadcrumbService.UNSET_SUBTITLE);
		}
	}

	goToDetail(item: OpportunityPouchModel) {
		this.router.navigate([PATH_URL.PRIVATE, ROUTE_URL.requests, item._id]);
	}
}
