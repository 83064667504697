import { ContextApplicationItemCodeEnum } from '../../enum/permission/context-application-item-code.enum';
import { ContextCodeItemLink } from '../../model/permission/user-management.model';

// B2B
export const contextCodeItemCompany: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.B2B,
	description: `saep_ict_angular_spin8_core.context_code.description.${ContextApplicationItemCodeEnum.B2B}`,
	couch_document_key: 'organization',
	association_type: [],
	already_associated_context_code_list: []
};

// AGENT
export const contextCodeItemAgent: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.AGENT,
	description: `saep_ict_angular_spin8_core.context_code.description.${ContextApplicationItemCodeEnum.AGENT}`,
	couch_document_key: 'agent',
	association_type: [contextCodeItemCompany],
	already_associated_context_code_list: []
};

// B2C
export const contextCodeItemB2C: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.B2C,
	description: `saep_ict_angular_spin8_core.context_code.description.${ContextApplicationItemCodeEnum.B2C}`,
	couch_document_key: 'organization',
	association_type: [],
	already_associated_context_code_list: []
};

// PORTAL
export const contextCodeItemPortal: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.PORTAL,
	description: `saep_ict_angular_spin8_core.context_code.description.${ContextApplicationItemCodeEnum.PORTAL}`,
	couch_document_key: 'organization',
	association_type: [],
	already_associated_context_code_list: []
};

// CRM
export const contextCodeItemCRM: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.CRM,
	description: `saep_ict_angular_spin8_core.context_code.description.${ContextApplicationItemCodeEnum.CRM}`,
	couch_document_key: 'crm',
	association_type: [],
	already_associated_context_code_list: []
};

// BACKOFFICE
// non è possibile associare l'elemento a se stesso
export const contextCodeItemBackoffice_declarationElement: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.BACKOFFICE,
	description: `saep_ict_angular_spin8_core.context_code.description.${ContextApplicationItemCodeEnum.BACKOFFICE}`,
	couch_document_key: 'backoffice',
	association_type: [],
	already_associated_context_code_list: []
};

export const contextCodeItemBackoffice: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.BACKOFFICE,
	description: `saep_ict_angular_spin8_core.context_code.description.${ContextApplicationItemCodeEnum.BACKOFFICE}`,
	couch_document_key: 'backoffice',
	association_type: [
		contextCodeItemCompany,
		contextCodeItemAgent,
		contextCodeItemCRM,
		contextCodeItemB2C,
		contextCodeItemBackoffice_declarationElement
	],
	already_associated_context_code_list: []
};
