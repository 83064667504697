<page-with-sidebar
    [isRoot]="true"
    [sideBarPosition]="sideBarPositionValues.NONE"
>
    <ng-container pageMain>
        <mat-card class="michelangelo-theme autoheight">
            <form-dynamic
                *ngIf="formDynamicConfiguration"
                [configuration]="formDynamicConfiguration"
                (valueChange)="onFormDynamicValueChange($event, 'stock_type')"
            ></form-dynamic>
        </mat-card>
    </ng-container>
</page-with-sidebar>
