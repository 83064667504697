<page-with-sidebar [ngClass]="{'agent-catalog': order?.header?.status === orderStatusEnum.DRAFT && routedMainContentKey === ROUTE_URL.catalog && user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT}" [isSticky]="true" [sideBarPosition]="order ? sideBarPositionValues.RIGHT : sideBarPositionValues.NONE">
	<ng-container pageMain>
		<!-- <mat-card  class="michelangelo-theme autoheight mb-20" *ngIf="this.order?.header?.status === this.orderToAuthorize">
			<order-detail-main-header-edit [configuration]="this.configuration"></order-detail-main-header-edit>
			Nuovo componente di conferma testata ordine
			<order-detail-confirm *ngIf="this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.BACKOFFICE"></order-detail-confirm>
		</mat-card> -->
		<mat-card class="michelangelo-theme autoheight">
			<ng-container *ngIf="order; else newOrder">



				<top-bar
					*ngIf="order && ((user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT && routedMainContentKey !== ROUTE_URL.catalog) || user.current_permission.context_application !== contextApplicationItemCodeEnum.AGENT)"
					[goBackButton]="true"
					[goBackPath]="goBackPath"
					[actionMenu]="order && order._id ? true : false"
				>
					<ng-container left-side-content>
						<status-bar-new [config]="statusBarConfig"></status-bar-new>
					</ng-container>
					<ng-container additional-right-content >
						<button *ngIf="order.header.status === orderStatusEnum.DRAFT && routedMainContentKey === ROUTE_URL.checkout" mat-raised-button  color="primary" (click)="router.navigate([ROUTE_URL.catalog], { relativeTo: route })" >
							<span>{{ 'order.edit' | translate | sentencecase }}</span>
						</button>
<!--						<div [matTooltip]="'' + order.product_list.length" *ngIf="user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT && order.header.status === orderStatusEnum.DRAFT && routedMainContentKey === ROUTE_URL.catalog" class="total-order-badge">{{ order && order.product_list ? order.product_list.length : 0 }}</div>-->
					</ng-container>
					<ng-container actions-menu-buttons>
						<div class="order-detail-actions-menu-buttons">

							<ng-container *ngIf="order && order._id">
								<!-- Delete button -->
								<button
									*ngIf="orderStatus === orderStatusEnum.DRAFT"
									mat-menu-item
									(click)="utilOrderService.dialogDeleteConfirm(order)"
								>
									<mat-icon class="delete">delete</mat-icon>
									<span>{{ 'general.delete' | translate | sentencecase }}</span>
								</button>
								<!-- Confirm order button -->
								<button
									*ngIf="order && order._id && orderStatus === orderStatusEnum.TO_AUTHORIZE"
									mat-menu-item
									(click)="confirmOrder()"
								>
									<mat-icon class="add_task">add_task</mat-icon>
									<span>{{ 'general.approves' | translate | sentencecase }}</span>
								</button>
								<!-- TODO: trasferire insieme agli altri segmenti -->
								<!-- Go to header-edit button -->
								<button
									*ngIf="orderStatus === orderStatusEnum.DRAFT"
									mat-menu-item
									(click)="router.navigate(['header-edit'], { relativeTo: route })"
								>
									<mat-icon class="edit">edit</mat-icon>
									<span>{{ 'order.header_edit' | translate | sentencecase }}</span>
								</button>
								<!-- Clone button -->
								<button
									mat-menu-item
									(click)="utilOrderService.dialogCloneConfirm(order)"
									*ngIf="
										user.current_permission.context_application ===
											contextApplicationItemCodeEnum.AGENT ||
										user.current_permission.context_application ===
											contextApplicationItemCodeEnum.B2B ||
										user.current_permission.context_application ===
											contextApplicationItemCodeEnum.CRM
									"
								>
									<mat-icon class="copy">file_copy</mat-icon>
									<span>{{ 'general.duplicate' | translate | sentencecase }}</span>
								</button>
								<!-- Generate PDF button -->
								<button mat-menu-item (click)="generatePDF()">
									<mat-icon class="pdf">picture_as_pdf</mat-icon>
									<span>{{ 'order.action.get_pdf' | translate | sentencecase }}</span>
								</button>
								<!-- Download excel template button-->
								<button
									*ngIf="orderStatus === orderStatusEnum.DRAFT"
									mat-menu-item
								>
									<a
										[href]="
											bucketManager.returnBucketManagerDownloadUrl(
												'article-list-import-template.xlsx',
												'template'
											)
										"
										target="_blank"
									>
										<mat-icon class="download">file_download</mat-icon>
										<span>{{ 'general.download_xls_template' | translate | sentencecase }}</span>
									</a>
								</button>
								<!-- Uplaod excel button-->
								<button
									*ngIf="orderStatus === orderStatusEnum.DRAFT"
									mat-menu-item
									(click)="openDialogCalcSheetArticleListUpdateOrder()"
								>
									<mat-icon class="upload">file_upload</mat-icon>
									<span>{{ 'order.action.upload_xls' | translate | sentencecase }}</span>
								</button>
							</ng-container>


						</div>
					</ng-container>
				</top-bar>
				<div class="order-detail-content-wrapper">
					<router-outlet></router-outlet>
				</div>
			</ng-container>


			<button	*ngIf="
						user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT &&
						order &&
						order.header &&
						order.header.status === orderStatusEnum.DRAFT &&
						(
							order.header.status === orderStatusEnum.DRAFT && routedMainContentKey === ROUTE_URL.checkout ||
							order.header.status === orderStatusEnum.DRAFT && routedMainContentKey === ROUTE_URL.catalog
						)
					"
					[disabled]="
						utilOrderService.orderDisabledViewConfirm || 
						!( 
							(order.product_list && order.product_list.length > 0) ||
							(order['free_line_list'] && order['free_line_list'].length > 0)) || 
						(
							routedMainContentKey === ROUTE_URL.checkout &&
							offline
						)"
						mat-fab
					   color="primary"
					   class="forward-order" (click)="forwardOrder()"
					   [matTooltip]="(routedMainContentKey === ROUTE_URL.checkout ? 'order.send' : 'general.go_to_checkout') | translate | sentencecase"
			>
				<mat-icon *ngIf="routedMainContentKey == ROUTE_URL.checkout">
				<svg width="100%" height="100%" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
					<path d="M15.324 0.676008C15.0442 0.393537 14.6953 0.189081 14.3121 0.0828916C13.9289 -0.0232974 13.5246 -0.0275334 13.1393 0.0706032L2.3814 2.76047C1.69784 2.93102 1.09143 3.32634 0.659562 3.88293C0.227695 4.43953 -0.0045693 5.1251 6.81191e-05 5.82955C0.00470554 6.534 0.245976 7.21645 0.685133 7.76731C1.12429 8.31817 1.73585 8.70547 2.4216 8.86701L6.23317 9.76364L7.13233 13.5774C7.28587 14.2669 7.67067 14.8832 8.22285 15.3238C8.77502 15.7645 9.46134 16.003 10.1678 15.9998H10.1908C10.8933 16.0079 11.5776 15.7767 12.1313 15.3444C12.6851 14.9121 13.0752 14.3043 13.2377 13.6208L15.9295 2.85973C16.0275 2.47454 16.0233 2.07041 15.9171 1.68738C15.8109 1.30436 15.6064 0.955717 15.324 0.676008ZM14.735 2.56277L12.0457 13.3181C11.946 13.7363 11.7064 14.1078 11.3667 14.3713C11.027 14.6347 10.6075 14.7743 10.1777 14.7668C9.74648 14.7721 9.32679 14.6279 8.98996 14.3587C8.65313 14.0895 8.41996 13.7119 8.33011 13.2902L7.42768 9.44289L10.8963 5.97289C11.005 5.85626 11.0642 5.702 11.0614 5.5426C11.0586 5.38321 10.994 5.23114 10.8812 5.11841C10.7685 5.00569 10.6164 4.94112 10.457 4.9383C10.2976 4.93549 10.1433 4.99466 10.0267 5.10334L6.55395 8.57334L2.70628 7.67097C2.28892 7.57294 1.91663 7.33745 1.64924 7.00236C1.38185 6.66726 1.23488 6.25201 1.23194 5.82334C1.22901 5.39466 1.37028 4.97744 1.63306 4.63871C1.89584 4.29999 2.26487 4.05943 2.68085 3.95569L13.4379 1.26501C13.524 1.24326 13.6125 1.23223 13.7013 1.23219C13.8638 1.23216 14.0241 1.26926 14.1701 1.34066C14.3161 1.41205 14.4438 1.51586 14.5435 1.64415C14.6433 1.77244 14.7123 1.92182 14.7455 2.08089C14.7787 2.23995 14.7751 2.40449 14.735 2.56195V2.56277Z"></path>
					</svg>
				</mat-icon>
				<mat-icon *ngIf="routedMainContentKey == ROUTE_URL.catalog">shopping_basket</mat-icon>
			</button>

		</mat-card>
	</ng-container>
	<ng-container outSideContent>
		<div
			[matTooltip]="
				'order.item_qty_badge' |
				translate: { qty: order.product_list.length} |
				sentencecase
			"
			*ngIf="
				user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT &&
				order &&
				order.header &&
				order.header.status === orderStatusEnum.DRAFT &&
				routedMainContentKey === ROUTE_URL.catalog
			"
			class="total-order-badge"
		>
			{{ order && order.product_list ? order.product_list.length : 0 }}
		</div>
	</ng-container>
	<ng-container pageSide>
		<ng-container *ngComponentOutlet="routedSidebarContent"></ng-container>
	</ng-container>
</page-with-sidebar>
<!-- ng-template -->
<ng-template #newOrder>
	<button
		*ngIf="
			(
				user.current_permission.context_application === contextApplicationItemCodeEnum.B2B ||
				user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT
			); else loadingOrder
		"
		class="add-order"
		mat-raised-button
		(click)="utilOrderService.orderDraftCreate(organization)"
		color="accent"
	>
		<mat-icon>add</mat-icon>
		<span>{{ 'order.add_new' | translate | sentencecase }}</span>
	</button>
</ng-template>
<ng-template #loadingOrder>
	{{ 'general.loading' | translate | sentencecase }}
</ng-template>