import { Directive, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	AngularCoreUtilService, BaseStateSerializerModel, ListSettingActionEnum,
	StoreFeature, SubscribeManagerService
} from '@saep-ict/angular-core';
import {
	AngularSpin8CoreUtilTranslateService, ArticleListStructureWrapperBase
} from '@saep-ict/angular-spin8-core';
import { ListSetting, ArticleCheckoutTree } from '@saep-ict/pouch_agent_models';
import { filter, mergeMap, Observable } from 'rxjs';
import * as articleListStructureWrapperAccordionConfigurationModel from './article-list-structure-wrapper-accordion-base.model';
import * as CategoryModel from '../../../model/category-list.model';
import * as ConfigurationCategory from '../../../constants/category.constant';
import _ from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';

@Directive({
	selector: '[articleListCategoryTreeRecursionBase]'
})
export class ArticleListStructureWrapperAccordionBaseDirective extends ArticleListStructureWrapperBase implements OnInit, OnDestroy {ù
	@ViewChild(NgScrollbar) scrollbar: NgScrollbar;
	@ViewChild('infiniteScrollContainer') set infiniteScrollContainer(e: ElementRef) {
		if (e && e.nativeElement && !this.infiniteScrollContainerHeight) {
			setTimeout(() => {
				const domRect: DOMRect = e.nativeElement.getBoundingClientRect();
				// 61: somma dei px di ingombro posti sotto la porzione scrollabile degli accordion
				this.infiniteScrollContainerHeight = (window.innerHeight - domRect.top) - 61;
			}, 0);
		}
	}
	infiniteScrollContainerHeight: number;
	infiniteScrollChunkSize = 50;

	@Input('configuration') set configuration(e: articleListStructureWrapperAccordionConfigurationModel.Configuration) {
		if (e) {
			this._configuration = e;
			this.subscribeManagerService.populate(
				this.subscribeData().subscribe({ error: (e) => { console.log('data: something went wrong', e); }}),
				'data'
			);
		}
	};
	_configuration: articleListStructureWrapperAccordionConfigurationModel.Configuration;

	// TODO: rinominare il feature select in modo più generico in quanto serve anche in contesti quali forecast
	listSettingSlice$: Observable<BaseStateSerializerModel<ListSetting<CategoryModel.List>>> =
		this.store.select(StoreFeature.selectListSettingSliceStore, 'order-detail');

	categoryTreeComplete: ArticleCheckoutTree[] = [];
	categoryTree: ArticleCheckoutTree[] = [];

	matAccordionExpansionPanelActiveIndex: number;

	constructor(
		public store: Store,
		public utilService: AngularCoreUtilService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public subscribeManagerService: SubscribeManagerService
	) {
		super(utilService, utilTranslateService.translate, store);
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeData(): Observable<void> {
		return this.listSettingSlice$.pipe(
			filter(
				(e: BaseStateSerializerModel<ListSetting<CategoryModel.List>>) =>
					!!(
						e &&
						e.data &&
						e.type === ListSettingActionEnum.UPDATE &&
						this.localListHandlerData &&
						this.localListHandlerData.data &&
						this.localListHandlerData.dataSubset &&
						e.data.filters &&
						e.data.filters.customFilters &&
						e.data.filters.customFilters.categoryList &&
						e.data.filters.customFilters.categoryList.length === 1
					)
			),
			mergeMap(async (e: BaseStateSerializerModel<ListSetting<CategoryModel.List>>) => {
				this.matAccordionExpansionPanelActiveIndex = null;
				try {
					let articleCheckoutTree: ArticleCheckoutTree[] =
						await ConfigurationCategory.returnArticleCheckoutTree(
							this.localListHandlerData.dataSubset,
							e.data.filters.customFilters.categoryList[0].category_list,
							this.utilTranslateService.languages
						);
					if (this._configuration.section && this._configuration.section.length > 0) {
						for (let category of articleCheckoutTree) {
							category =
								await ConfigurationCategory.returnCategorySectionListLevel(
									category,
									this._configuration.section
								);
						}
					}
					if (this._configuration.forecastInstance) {
						articleCheckoutTree = await this.updateForecastCategoryMetaInformation(articleCheckoutTree);
					}
					this.categoryTreeComplete = _.cloneDeep(articleCheckoutTree);
					this.categoryTree = articleCheckoutTree.slice(0, this.infiniteScrollChunkSize);
					if (this.scrollbar) {
						this.scrollbar.scrollTo({ top: 0, duration: 300 });
					}
				} catch (err) {
					throw new Error(err);
				}
			})
		);
	}

	setMatAccordionExpansionPanelActiveIndex(index: number) {
		this.matAccordionExpansionPanelActiveIndex = index;
	}

	/**
	 * TODO: testare una volta chiuso il giro di update forecast
	 * Il metodo salva in metaInformation.total di ogni categoria di primo livello (definita root ma da non confondere con il level
	 * convenzionato sul suo modello), il valore delle unità forecast.
	 * Funziona se le categorie presentano direttamente articoli oppure nel caso in cui queste siano a loro volta organizzate
	 * in sotto categorie (casistica limitata per natura stessa dei componenti article-list-structure-accordion a gestire il solo
	 * livello di categoria fittizia chiamato section, si veda ConfigurationCategory.returnCategorySectionListLevel ed annessi.
	 * Non è stato testato il comportamento con struttura a livelli annidati maggiori di 2)
	 * @param articleCheckoutTree 
	 * @param categoryRoot 
	 * @returns 
	 */
	updateForecastCategoryMetaInformation(
		articleCheckoutTree: ArticleCheckoutTree[],
		categoryRoot?: ArticleCheckoutTree
	): Promise<ArticleCheckoutTree[]> {
		return new Promise(async resolve => {
			try {
				for (const category of articleCheckoutTree) {
					if (categoryRoot) {
						if (!categoryRoot.metaInformation) {
							categoryRoot.metaInformation = {
								ordered_quantity: null,
								free_sample: null,
								qty_free: null,
								total: 0
							};
						}
					} else {
						category.metaInformation = {
							ordered_quantity: null,
							free_sample: null,
							qty_free: null,
							total: 0
						};
					}
					if (category.article_list && category.article_list.length > 0) {
						for (const article of category.article_list) {
							if (article.forecast && article.forecast.length >0) {
								for (const forecastTimeRange of article.forecast) {
									if (categoryRoot) {
										categoryRoot.metaInformation.total =
											categoryRoot.metaInformation.total + forecastTimeRange.value;
									} else {
										category.metaInformation.total =
											category.metaInformation.total + forecastTimeRange.value;
									}

								}
							}
						}
					}
					if (category.category_list && category.category_list.length > 0) {
						category.category_list = await this.updateForecastCategoryMetaInformation(category.category_list, category);
					}
				}
				resolve(articleCheckoutTree);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	infiniteScrollChunkAdd(categoryToAdd: number) {
		const index = _.cloneDeep(this.categoryTree.length);
		for (let i = 0; i < categoryToAdd; i++) {
			if (this.categoryTreeComplete[index+i]) {
				this.categoryTree.push(this.categoryTreeComplete[index+i]);
			}
		}
	}

}
