import { Injectable } from '@angular/core';

// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	ContextCodeAssociationActionEnum,
	ContextCodeAssociationStateAction
} from './context-code-association.actions';

// widget & utility
import { from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { UtilPouchService } from '../../../../service/util/util-pouch.service';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { ContextCodeAssociation } from '@saep-ict/angular-spin8-core';

@Injectable()
export class ContextCodeAssociationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeAssociationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInDetailState<ContextCodeAssociation>(action))
			),
			map((action: BaseStateModel<ContextCodeAssociation>) => ContextCodeAssociationStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeAssociationStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeAssociationActionEnum.SAVE),
			mergeMap((action: BaseStateModel<ContextCodeAssociation>) =>
				from(this.storeUtilService.saveCouchDetailAndReturnInDetailState<ContextCodeAssociation>(action))
			),
			map((action: BaseStateModel<ContextCodeAssociation>) =>
				ContextCodeAssociationStateAction.saveSuccess(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeAssociationStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	saveSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeAssociationActionEnum.SAVE_SUCCESS),
			map((action: BaseStateModel<ContextCodeAssociation>) => ContextCodeAssociationStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeAssociationStateAction.error(error));
				return caught;
			})
		)
	);

	deleteItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContextCodeAssociationActionEnum.DELETE_ITEM),
			mergeMap((action: BaseStateModel<ContextCodeAssociation>) =>
				from(this.utilPouchService.deleteAnyDocument(action.data))
			),
			map((action: BaseStateModel<ContextCodeAssociation>) =>
				ContextCodeAssociationStateAction.deleteItemSuccess(null)
			),
			catchError((error, caught) => {
				this.store.dispatch(ContextCodeAssociationStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPouchService: UtilPouchService,
		private storeUtilService: StoreUtilService
	) {}
}
