import { HttpClient, HttpParams } from "@angular/common/http";

import { IPouchAppConfig } from "../app.config";
import { Pagination } from "./list-structure.model";
import { TransmissionService } from "../transmission.service";
import * as _ from 'lodash';

export interface ILoaderService {
  populateLoader(some: string): string;
  changeSingleLoader(guid: string): void;
}

export abstract class AbstractPouchdbMethod {
  db: PouchDB.Database;

  combinationOperators = ["$and", "$or", "$not", "$nor"];
  conditionOperators = [
    "$lt",
    "$gt",
    "$lte",
    "$gte",
    "$eq",
    "$ne",
    "$exists",
    "$type",
    "$in",
    "$nin",
    "$size",
    "$mod",
    "$regex",
    "$all",
    "$elemMatch",
  ];

  documentSeparator = this.config.documentSeparator ? this.config.documentSeparator : "_";

  constructor(
    activeDB: PouchDB.Database,
    private httpClient: HttpClient,
    private config: IPouchAppConfig,
    private transmissionService: TransmissionService,
    private loaderService?: ILoaderService
  ) {
    this.setActiveDB(activeDB);
  }

  setActiveDB(db: PouchDB.Database) {
    this.db = db;
  }

  get(some: string, useLoader: boolean = true) {
    const guid = this.handleLoaderStart(useLoader, some);
    return new Promise((resolve, reject) => {
      this.db
        .get(some)
        .then((res) => {
          this.handlerLoaderStop(guid);
          resolve(res);
        })
        .catch((error) => {
          this.handlerLoaderStop(guid);
          reject(error);
        });
    });
  }

  async getWithIndexFilter<T>(
    some: string,
    filters: PouchDB.Find.Selector,
    pagination?: Pagination,
    sort?: { [key: string]: "asc" | "desc" }[] | string[],
    useLoader: boolean = true
  ) {
    const guid = this.handleLoaderStart(useLoader, some);
    const index_name: string = await this.findIndex(filters);
    if (!pagination) {
      pagination = {} as Pagination;
    }
    if (!pagination.page_size) {
      pagination.page_size = 999;
      pagination.page_current = 1;
    }
    return new Promise((resolve, reject) => {
      let filterFromIndex: PouchDB.Find.FindRequest<{}> = {
        selector: filters,
      };
      if (sort) {
        filterFromIndex = {
          ...filterFromIndex,
          sort: sort,
        };
      }
      if (index_name) {
        filterFromIndex = {
          ...filterFromIndex,
          use_index: index_name,
        };
      }

      filterFromIndex = {
        ...filterFromIndex,
        limit: pagination.page_size,
        skip: pagination.page_size * (pagination.page_current - 1),
      };

      this.db
        .find(filterFromIndex)
        .then((res) => {
          this.handlerLoaderStop(guid);
          resolve(res);
        })
        .catch((error) => {
          this.handlerLoaderStop(guid);
          console.log("error find" + error);
          reject(error);
        });
    });
  }

  put<T>(body: T, some: string, useGuid: boolean = false, useLoader: boolean = true): Promise<any> {

    const guid = this.handleLoaderStart(useLoader, some);

    const docGuid = this.guid();

    body["_id"] = useGuid ? some + this.documentSeparator + docGuid : some;
    body["code_item"] = useGuid
      ? docGuid
      : some.slice(some.indexOf(this.documentSeparator) + 1, some.length);
    if (!("valid" in body)) {
      body["valid"] = true;
    }

    const dateCreation = body["date_creation"];
    const current = new Date();
    const timestamp = current.getTime();

    if(dateCreation == null)
      body["date_creation"] = timestamp;
    body["date_update"] = timestamp;


    return new Promise((resolve, reject) => {
      this.db
        .put(body)
        .then((response) => {
          this.handlerLoaderStop(guid);
          this.get(response.id, useLoader).then((res) => {
            resolve(res);
          });
          if(response.ok){
            if (!this.transmissionService.isDbTransmissionForbidden(body)) {
              this.transmissionService.informDbTransmission(body, this.db);
            }
          }
        })
        .catch((error) => {
          this.handlerLoaderStop(guid);
          reject(error);
        });
    });
  }

  delete(target: any, useLoader: boolean = true): Promise<any> {
    const guid = this.handleLoaderStart(useLoader, null);
    return new Promise((resolve, reject) => {
      this.db
        .remove(target)
        .then((res) => {
          this.handlerLoaderStop(guid);
          resolve(res);
        })
        .catch((error) => {
          this.handlerLoaderStop(guid);
          console.error(error);
          reject(error);
        });
    });
  }

  queryIndex(
    idx: string,
    opts: { key?: any; keys?: any[]; startkey?: any; endkey?: any },
    useLoader: boolean = true
  ): Promise<any> {
    const queryOptions: PouchDB.Query.Options<
      { key?: any; keys?: any[]; startkey?: any; endkey?: any },
      unknown
    > = opts;
    const guid = this.handleLoaderStart(useLoader, idx);
    return new Promise((resolve, reject) => {
      this.db
        .query(idx, queryOptions)
        .then((res) => {
          this.handlerLoaderStop(guid);
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          this.handlerLoaderStop(guid);
          reject(error);
        });
    });
  }

  allDocs(
    options?:
      | PouchDB.Core.AllDocsWithKeyOptions
      | PouchDB.Core.AllDocsWithKeysOptions
      | PouchDB.Core.AllDocsWithinRangeOptions
      | PouchDB.Core.AllDocsOptions,
    useLoader: boolean = true
  ): Promise<any> {
    const guid = this.handleLoaderStart(useLoader, null);
    return new Promise((resolve, reject) => {
      this.db
        .allDocs(options)
        .then((res) => {
          this.handlerLoaderStop(guid);
          resolve(res);
        })
        .catch((err) => {
          this.handlerLoaderStop(guid);
          reject(err);
        });
    });
  }

  async findIndex(filter: PouchDB.Find.Selector) {
    const indexName = "";
    return this.explainSelector(filter).then((res) => {
      const explainIndex = res;
      const flatIndex: {
        [key: string]: string;
      }[] = this.explainIndexToFlatIndex(explainIndex);

      return this.db
        .getIndexes()
        .then((indexesRes) => {
          return this.findBestIndexName(indexesRes.indexes, flatIndex);
        })
        .catch(function (err) {
          console.log(err);
          return indexName;
        });
    });
  }

  findBestIndexName(
    allIndexes: PouchDB.Find.Index[],
    flatIndex: { [key: string]: string }[]
  ) {
    let bestIndexName: string;
    let bestIndexScore = 0.0;
    allIndexes.forEach((index) => {
      const indexFields = index.def.fields;
      let matchingKeys = 0;
      flatIndex.forEach((flatIndexField: { [key: string]: string }) => {
        Object.keys(indexFields).forEach((key) => {
          if (
            Object.keys(indexFields[key])[0] === Object.keys(flatIndexField)[0]
          ) {
            matchingKeys += 1;
          }
        });
      });
      const score = matchingKeys / flatIndex.length;

      if (score > bestIndexScore) {
        bestIndexScore = score;
        bestIndexName = index.ddoc;
      }
    });
    return bestIndexName;
  }

  explainIndexToFlatIndex(explainIndex): { [key: string]: string }[] {
    const normalizedSelector = this.getExplainIndexSelector(explainIndex);
    const flatIndex =
      this.findIndexKeysFromNormalizedSelector(normalizedSelector);
    const flatIndexNoDups = this.removeDuplicatesKeys(flatIndex);
    return flatIndexNoDups;
  }

  getExplainIndexSelector(explainIndex): PouchDB.Find.Selector {
    return explainIndex.selector;
  }

  isCombinationOperator(operator: string) {
    return this.combinationOperators.includes(operator);
  }

  isConditionOperator(operator: string) {
    return this.conditionOperators.includes(operator);
  }

  isKey(key: string) {
    return !this.isCombinationOperator(key) && !this.isConditionOperator(key);
  }

  findIndexKeysFromNormalizedSelector(
    selector: PouchDB.Find.Selector,
    parent: string = ""
  ) {
    let flatIndex: any[] = [];

    Object.keys(selector).forEach((key) => {
      if (this.isCombinationOperator(key)) {
        // Go down
        selector[key].forEach((innerSelector) => {
          flatIndex = flatIndex.concat(
            this.findIndexKeysFromNormalizedSelector(innerSelector)
          );
        });
      } else if (key === "$elemMatch") {
        // Append .[] and go down
        parent = parent.concat(".[]");
        flatIndex = flatIndex.concat(
          this.findIndexKeysFromNormalizedSelector(selector[key], parent)
        );
      } else if (this.isKey(key)) {
        const parentKey = parent.concat("." + key);
        const childFlatIndex = this.findIndexKeysFromNormalizedSelector(
          selector[key],
          parentKey
        );
        flatIndex = flatIndex.concat(childFlatIndex);
        if (childFlatIndex.length === 0) {
          flatIndex.push({});
          flatIndex[flatIndex.length - 1][parent + (parent ? "." : "") + key] =
            "asc";
        }
      }
    });
    return flatIndex;
  }

  removeDuplicatesKeys(
    anArray: { [key: string]: string }[]
  ): { [key: string]: string }[] {
    const result: { [key: string]: string }[] = [];
    anArray.forEach((element) => {
      if (
        !result.find(
          (toInsert) => Object.keys(toInsert)[0] === Object.keys(element)[0]
        )
      ) {
        result.push(element);
      }
    });
    return result;
  }

  explainSelector(selector: PouchDB.Find.Selector): Promise<any> {
    const findRequest: PouchDB.Find.FindRequest<{}> = {
      selector,
    };
    const local =
      this.db["adapter"] !== "https" && this.db["adapter"] !== "http";

    if (local) {
      return this.db["explain"](findRequest);
    } else {
      return this.explain(findRequest);
    }
  }

  explain(selector: PouchDB.Find.Selector): Promise<any> {
    const params = new HttpParams({ fromString: "name=term" });
    return new Promise((resolve, reject) => {
      this.httpClient
        .request("POST", `${this.db.name}/_explain`, {
          headers: {
            "x-auth-couchdb-username": this.config.username ? this.config.username : '',
            "x-auth-couchdb-roles": "",
            "x-auth-couchdb-token": this.config.signature ? this.config.signature : '',
          },
          body: selector,
          responseType: "json",
          params,
        })
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  handleLoaderStart(useLoader: boolean, some: string): string {
    return this.loaderService && useLoader ? this.loaderService.populateLoader(_.cloneDeep(some)) : null;
  }

  handlerLoaderStop(guid: string) {
    if (this.loaderService && guid) {
      this.loaderService.changeSingleLoader(guid);
    }
  }

	guid() {
		function S4() {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		}
		return (
			S4() +
			S4() +
			'-' +
			S4() +
			'-4' +
			S4().substr(0, 3) +
			'-' +
			S4() +
			'-' +
			S4() +
			S4() +
			S4()
		).toLowerCase();
	}

}
