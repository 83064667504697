import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { AngularSpin8CoreUtilTranslateService, OrderRowModel, OrderStateModel } from '@saep-ict/angular-spin8-core';

@Directive({
	selector: '[articleListCategoryTreeRecursionBase]'
})
export class ArticleListCategoryTreeRecursionBase {
	@Input() order: OrderStateModel;
	@Input() organization: OrganizationPouchModel;
	@Output() onArticleChange = new EventEmitter<OrderRowModel>();
	constructor(public utilTranslateService: AngularSpin8CoreUtilTranslateService) {}
}
