import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UtilUserService {
	updateAvatarSource = new Subject<void>();
	updateAvatar$: Observable<void> = this.updateAvatarSource.asObservable();
}
