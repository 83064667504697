<app-page-header
	[title]="'Checkout'"
	[backgroundImageUrl]="'theme.header_bg.checkout.backgroundImageUrl' | translate"
></app-page-header>
<form
	*ngIf="checkoutForm"
	[formGroup]="checkoutForm"
	(ngSubmit)="onSubmit()"
>
	<div class="container">
		<div class="row formCardContent">
			<ng-container *ngTemplateOutlet="paymentStateAuthLink; context: { dividerPosition: 'BOTTOM' }"></ng-container>
			<!-- sezione di scelta opzioni di acquisto -->
			<section class="form col-md-12 col-lg-6">
				<router-outlet></router-outlet>
				<div
					*ngIf="operationState() === paymentState.AUTH"
					class="container-form"
				>
					<div class="row">
						<div class="list col-12">
							<div class="list__content">
								<h2 class="list__text">1. {{ 'checkout.field.personal_data' | translate | sentencecase }}</h2>
							</div>
						</div>
						<div class="col-12">
							<ng-container *ngIf="organization.organization_type === organizationTypeEnum.PRIVATE">
								<ng-container *ngTemplateOutlet="organizationBusinessName"></ng-container>
								<div>
									{{ 'organization.field.tax_code' | translate | sentencecase }}:
									{{ organization.tax_data ? organization.tax_data.tax_code : '-' }}
								</div>
								<ng-container
									*ngTemplateOutlet="
										organizationContactMain;
										context: {
											label: {
												email: 'theme.contact.field.email_label',
												phone: 'theme.contact.field.phone_label'
											}
										}
									"
								>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="organization.organization_type === organizationTypeEnum.COMPANY">
								<ng-container *ngTemplateOutlet="organizationBusinessName"></ng-container>
								<ng-container *ngTemplateOutlet="organizationTaxData"></ng-container>
								<ng-container
									*ngTemplateOutlet="
										organizationContactMain;
										context: {
											label: {
												email: 'organization.field.reference_internal.email',
												phone: 'organization.field.reference_internal.phone'
											}
										}
									"
								>
								</ng-container>
								<ng-container *ngTemplateOutlet="alertShowWarning"></ng-container>
							</ng-container>
						</div>
						<div class="col-12">
							<!-- goods_destination -->
							<div class="list">
								<div class="list__content">
									<h2 class="list__text">2. {{ 'checkout.field.destination' | translate | sentencecase }}</h2>
								</div>
								<p>{{ 'checkout.select_address' | translate | sentencecase }}</p>
								<div
									*ngIf="order.header.goods_destination_object"
									class="selected-address"
								>
									<div *ngFor="let destination_name of organization.destination_list">
										<div
											*ngIf="
												destination_name.address.address ===
												order.header.goods_destination_object.address
											"
										>
											<strong>{{ destination_name.business_name }}</strong>
										</div>
									</div>
									<div>{{ order.header.goods_destination_object.address }}</div>
									<div>
										{{ order.header.goods_destination_object.zip_code }} -
										{{ order.header.goods_destination_object.locality }}
										({{ order.header.goods_destination_object.province.label }})
									</div>
									<div>{{ order.header.goods_destination_object.country }}</div>
								</div>
								<div class="shipping-button">
									<a
										mat-stroked-button
										(click)="openDialogDestinationSelect()"
										class="address-button"
										type="button"
									>
										{{ 'general.address.change' | translate | sentencecase }}
									</a>
									<a
										type="button"
										class="address-button"
										mat-stroked-button
										(click)="openDialogNewDestination()"
									>
										{{ 'general.address.add_new' | translate | sentencecase }}
									</a>
								</div>
							</div>
							<alert
								*ngIf="
									orderThresholdSatisfied &&
									orderThresholdSatisfied.conditionList &&
									orderThresholdSatisfied.conditionList.length > 0 &&
									!orderThresholdSatisfied.status
								"
								[type]="alertType.warning"
							>
								<b>{{ 'checkout.alert.attention' | translate | sentencecase }}</b>,
								{{ 'checkout.alert.to_proceed' | translate }}
								<ul>
									<li
										*ngFor="let condition of orderThresholdSatisfied.conditionList"
										[innerHTML]="
											condition.description |
											translate : {
												value:
													condition.type === tableListEnum.Threshold.Type.I ?
													(
														(condition.value | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit) +
														' ' +
														configurationCustomerPrice.defaultCurrencySymbol
													) :
													condition.value
											}
										"
									>
									</li>
								</ul>
							</alert>
						</div>

						<div class="col-12">
							<!-- shipping method -->
							<div class="list">
								<div class="list__content">
									<h2 class="list__text">3. {{ 'checkout.field.shipping' | translate | sentencecase }}</h2>
									<p>
										{{ 'theme.order.subtotal.info.start' | translate | sentencecase }}
										<a
											[routerLink]="['/', ROUTE_URL.deliveryPolicy]"
											target="_blank"
										>
											{{ 'theme.order.subtotal.info.end' | translate }}
										</a>
									</p>
								</div>
								<div
									*ngIf="isCostToApplyForAdditionalService"
									formGroupName="additionalServices"
								>
									<div
										*ngIf="configurationCustomerOrder.additionalServiceStockType[organization.organization_type]"
									>
										<div class="shipping__paragraph">
											<p>
												{{ 'checkout.shipping_charges.select_pallet.label' | translate | sentencecase }}
												<mat-icon
													class="shipping__icon"
													matTooltipPosition="right"
													[matTooltip]="
														'checkout.shipping_charges.select_pallet.help' | translate | sentencecase
													"
												>
													help_outline
												</mat-icon>
											</p>
										</div>
										<section class="card-highlight radio-section">
											<mat-radio-group formControlName="stock_type">
												<div
													*ngFor="let method of additionalService.stock_type"
													class="row radio-section__content"
												>
													<mat-radio-button
														color="primary"
														[value]="method.code_item"
													>
														<span
															[innerHTML]="
																method.description +
																': ' +
																(method.charge | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit) +
																' ' +
																configurationCustomerPrice.defaultCurrencySymbol +
																' ' +
																method.value +
																' ' +
																method.um
															"	
														></span>
													</mat-radio-button>
												</div>
											</mat-radio-group>
										</section>
									</div>
									<div
										*ngIf="
											configurationCustomerOrder.additionalServiceDeliveryDate[organization.organization_type]
										"
									>
										<div class="shipping__paragraph">
											<p>{{ 'checkout.shipping_charges.select_time.label' | translate | sentencecase }}</p>
										</div>
										<section class="card-highlight radio-section">
											<mat-radio-group formControlName="delivery_date">
												<div
													class="row"
													*ngFor="let item of additionalService.delivery_date"
													class="radio-section__content"
												>
													<mat-radio-button
														[value]="item.code_item"
														color="primary"
													>
														{{ item.description }}
													</mat-radio-button>
												</div>
											</mat-radio-group>
										</section>
									</div>
								</div>
							</div>
							<!-- payment method -->
							<div class="list">
								<div class="list__content">
									<h2 class="list__text">4. {{ 'checkout.field.payment_method' | translate | sentencecase }}</h2>
								</div>
							</div>
							<section class="radio-section">
								<mat-radio-group formControlName="paymentMethod">
									<mat-radio-button
										color="primary"
										*ngFor="let method of paymentMethods"
										[value]="method.code_item"
										class="radio-section__content"
									>
										{{ method.description ? method.description : method.description_short }}
										<ng-container
											*ngTemplateOutlet="
												paymentMethodIcon;
												context: {
													paymentMethod: {
														code_item: method.code_item,
														description: method.description,
														iconPath: 'paymentMethodIcon_' + method.code_item
													}
												}
											"
										>
										</ng-container>
									</mat-radio-button>
								</mat-radio-group>
							</section>
							<!-- note -->
							<div class="list">
								<div class="list__content">
									<h2 class="list__text">5. {{ 'checkout.field.shipping_information' | translate | sentencecase }}</h2>
								</div>
								<mat-form-field>
									<mat-label>{{ 'checkout.add_note' | translate | sentencecase }}</mat-label>
									<textarea matInput formControlName="additionalNotes"></textarea>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div
					*ngIf="operationState() === paymentState.NOT_AUTH"
					class="container-form"
				>
					<div class="row">
						<div class="list col-12">
							<div class="list__content">
								<a
									[routerLink]="['/', ROUTE_URL.authentication, ROUTE_URL.login]"
									href="javascript:void(0)"
								>
									<h2 class="list__text">{{ 'checkout.login' | translate | sentencecase }}</h2>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				*ngIf="user; else noUser"
				class="cards-section col-lg-6"
			>
				<div class="row">
					<div class="cards-section__container col-12">
						<b2c-checkout-article-list
							[productList]="articleList"
							[order]="order"
						></b2c-checkout-article-list>
						<order-detail-price [order]="order"></order-detail-price>
					</div>
					<!-- delivery date -->
					<div class="cards-section__container col-12">
						<div class="card-orderDetails">
							<h3>
								<b>{{ 'theme.order.field.delivery_date' | translate | sentencecase }}</b>
							</h3>
							{{
								(
									isCostToApplyForShipping ?
									'theme.checkout.delivery_date_info.to_apply' :
									'theme.checkout.delivery_date_info.not_to_apply'
								)
								| translate | sentencecase
							}}
						</div>
					</div>
					<!-- info box -->
					<div class="cards-section__container col-12">
						<div class="card-information">
							<div class="card-information__aside">
								<div class="row">
									<div class="col-md-4 col-lg-12">
										<div class="card-information__content">
											<span class="card-information__icon material-icons"> security </span>
											<div>
												<h3 class="card-information__textIcon">
													<b>{{ 'general.security' | translate | sentencecase }}</b>
												</h3>
											</div>
										</div>
										<p>
											{{ 'theme.checkout.descriptions.security.start' | translate | sentencecase }}
											<a
												[routerLink]="['/', ROUTE_URL.privacyPolicy]"
												target="_blank"
											>
												{{ 'informative_page.type.privacy_policy' | translate }}</a
											>
											{{ 'theme.checkout.descriptions.security.end' | translate }}
										</p>
									</div>
									<div class="col-md-4 col-lg-12">
										<div class="card-information__content">
											<span class="card-information__icon material-icons"> local_shipping </span>
											<h3 class="card-information__textIcon">
												<b>{{ 'general.shipping.name_plural' | translate | sentencecase }}</b>
											</h3>
										</div>
										<p>
											{{ 'theme.checkout.descriptions.shipping' | translate | sentencecase }}
											<a
												[routerLink]="['/', ROUTE_URL.deliveryPolicy]"
												target="_blank"
											>
												{{ 'informative_page.type.shipping_terms' | translate }}
											</a>
										</p>
									</div>
									<div class="col-md-4 col-lg-12">
										<div class="card-information__content">
											<span class="card-information__icon material-icons rotate">
												import_export
											</span>
											<h3 class="card-information__textIcon">
												<b>{{ 'checkout.return_products' | translate | sentencecase }}</b>
											</h3>
										</div>
										<p>
											{{ 'theme.checkout.descriptions.return_products' | translate | sentencecase }}
											<a
											 [routerLink]="['/', ROUTE_URL.termsAndConditions]"
											 target="_blank"
											>
												{{ 'informative_page.type.terms_and_conditions' | translate }}
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ng-template
				#noUser
				class="list col-md-12 col-lg-6"
			>
				<div class="list col-6">
					<div class="list__content">
						<h2 class="list__text">{{ 'login.general.new_user?' | translate | sentencecase }}</h2>
					</div>
					<a
						type="button"
						class="login__help__button"
						mat-stroked-button
						[routerLink]="[ROUTE_URL.public, ROUTE_URL.authentication, ROUTE_URL.createAccount]"
					>
						{{ 'login.general.new_account' | translate | sentencecase }}
					</a>
				</div>
			</ng-template>
			<ng-container *ngTemplateOutlet="paymentStateAuthLink; context: { dividerPosition: 'TOP' }"></ng-container>
		</div>
	</div>
</form>

<!-- ng-template -->
<ng-template
	#paymentStateAuthLink
	let-dividerPosition="dividerPosition"
>
	<section
		*ngIf="operationState() === paymentState.AUTH"
		class="buttonLink col-12"
	>
		<div class="buttonLink__content">
			<mat-divider *ngIf="dividerPosition === 'TOP'"></mat-divider>
			<div class="row buttonLink__aside">
				<div class="col-md-12 col-lg-6">
					<button
						type="submit"
						[disabled]="
							!(
								checkoutForm.valid &&
								order.product_list &&
								order.product_list.length &&
								commercialArea &&
								orderThresholdSatisfied.status &&
								!organizationWarning
							)
						"
						class="buttonLink__button"
						mat-raised-button
						color="primary"
					>
						{{ 'checkout.buy' | translate | sentencecase }}
					</button>
				</div>
				<div class="col-md-12 col-lg-6">
					<p class="buttonLink__containerText">
						<a [routerLink]="[ROUTE_URL.public, ROUTE_URL.cart]">
							{{ 'checkout.to_cart' | translate | sentencecase }}
						</a>
					</p>
				</div>
			</div>
			<mat-divider *ngIf="dividerPosition === 'BOTTOM'"></mat-divider>
		</div>
	</section>
</ng-template>
<ng-template
	#paymentMethodIcon
	let-paymentMethod="paymentMethod"
>
	<div *ngIf="configurationCustomerOrder.paymentMethodIcon[paymentMethod.iconPath]">
		<img
			[src]="configurationCustomerOrder.paymentMethodIcon[paymentMethod.iconPath]"
			[alt]="paymentMethod.description"
			[title]="paymentMethod.description"
			class="radio-section__colorSvg"
		/>
	</div>
</ng-template>
<ng-template #alertShowWarning>
	<alert
		*ngIf="organizationWarning"
		[type]="alertType.warning"
	>
		<p>{{ 'checkout.missing_fields' | translate | sentencecase }}: {{ organizationWarning }}</p>
		<p *ngIf="organization.code_erp else organizationWarningNoCodeErp">
			{{ 'checkout.missing_fields_contact' | translate | sentencecase }}:
			<a href="mailto:{{ customerReference.EMAIL | translate }}">
				{{ customerReference.EMAIL | translate | sentencecase }}
			</a>
		</p>
	</alert>
</ng-template>
<ng-template #organizationBusinessName>
	<div>
		<b>{{ organization.business_name }}</b>
	</div>
</ng-template>
<ng-template #organizationContactMain let-label="label">
	<div *ngIf="contactMainOfList">
		<div>{{ label.email | translate | sentencecase }}: {{ contactMainOfList.email }}</div>
		<div>{{ label.phone | translate | sentencecase }}: {{ contactMainOfList.phone }}</div>
	</div>
</ng-template>
<ng-template #organizationTaxData>
	<div>
		{{ 'organization.field.vat_number' | translate | sentencecase }}:
		{{ organization.tax_data ? organization.tax_data.vat_number : '-' }}
	</div>
	<div>
		{{ 'organization.field.legal_headquarter' | translate | sentencecase }}:
		{{ configurationCustomerOrderDestination.getFormattedAddress(destinationRegisteredOffice) }}
	</div>
	<div>
		{{ 'organization.field.pec' | translate | sentencecase }}:
		{{ organization.tax_data.pec || '-' }}
	</div>
	<div>
		{{ 'general.code' | translate | sentencecase }}
		{{ 'organization.field.sdi' | translate }}:
		{{ organization.tax_data ? organization.tax_data.sdi_code : '-' }}
	</div>
</ng-template>
<ng-template #organizationWarningNoCodeErp>
	<p>
		<a
			(click)="openDialogOrganizationEdit()"
			href="javascript:void(0)"
		>
			{{ 'checkout.missing_fields_insert' | translate | sentencecase }}
		</a>
	</p>
</ng-template>
