import { Component, Output, EventEmitter, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ITdDataTableSortChangeEvent } from '@covalent/core/data-table';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { AppUtilService } from '../../service/util/app-util.service';

export interface SortSelectorConfigurationItem extends ITdDataTableSortChangeEvent {
    selected?: boolean;
    label: string;
}

export interface SortSelectorConfiguration {
    list: SortSelectorConfigurationItem[];
}

@Component({
	selector: 'sort-selector',
	templateUrl: './sort-selector.component.html',
	styleUrls: ['./sort-selector.component.scss'],
	providers: [SubscribeManagerService]
})
export class SortSelectorComponent implements OnInit, OnDestroy {
    @Input() set configuration(e: SortSelectorConfiguration) {
        if (e) {
            this._configuration = e;
            this.createForm();
        }
    };
    _configuration: SortSelectorConfiguration;

    @Output() onChange: EventEmitter<ITdDataTableSortChangeEvent> = new EventEmitter();

    form: FormGroup;

	constructor(
        private fb: FormBuilder,
        private subscribeManagerService: SubscribeManagerService,
        public utilService: AppUtilService    
    ) {}

	ngOnInit() {

	}

	createForm() {
		this.form = this.fb.group({
            sort: [this._configuration.list.find(i => i.selected)]
		});

        if (!this.subscribeManagerService.hasSubscription('on-change-form')) {
            this.subscribeManagerService.populate(
                this.form.valueChanges.subscribe(change => {
                    const emitValue: ITdDataTableSortChangeEvent = {
                        name: change.sort.name,
                        order: change.sort.order
                    };
                    this.onChange.emit(emitValue);
                }),
                'on-change-form'
            );
        }
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
