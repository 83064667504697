import { Injector } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import _ from 'lodash';
import * as ForecastModel from '../model/forecast.model';
const injector = Injector.create({
	providers: [
		{ provide: AngularCoreUtilService, deps: [] }
	]
});

const utilService = injector.get(AngularCoreUtilService);

/**
 * Verifica la presenza di un elementi di time range / valore all'interno di un forecast item. Dunque lo crea oppure
 * lo modifica
 * 
 * @param payloadItem 
 * @param timeRange 
 */
export const updateCalcSheetForecastPayloadTimeRange = (
    payloadItem: ForecastModel.CodeItemUpdate,
    timeRange: ForecastModel.ConfigurationInput
) => {
    const payloadItemTimerange = payloadItem.data.find(i => i.key === timeRange.key);
    if (payloadItemTimerange) {
        payloadItemTimerange.value = timeRange.value;
    } else {
        payloadItem.data.push({ key: timeRange.key, value: timeRange.value });
    }
}

export const updateForecastList = (
    payloadList: ForecastModel.CodeItemUpdate[],
    forecastList: ForecastModel.CodeItemUpdate[]
): Promise<ForecastModel.CodeItemUpdate[]> => {
    return new Promise(resolve => {
        try {
            forecastList = _.cloneDeep(forecastList);
            payloadList = _.cloneDeep(payloadList);
            for (const payloadItem of payloadList) {
                let forecastItem = forecastList.find(i => i.code_item === payloadItem.code_item);
                if (forecastItem) {
                    for (const timeRange of payloadItem.data) {
                        const forecastItemTimeRange = forecastItem.data.find(i => i.key === timeRange.key);
                        if (forecastItemTimeRange) {
                            forecastItemTimeRange.value = timeRange.value;
                        } else {
                            forecastItem.data.push({
                                key: timeRange.key,
                                value: timeRange.value
                            });
                        }
                    }
                } else {
                    forecastItem = {
                        code_item: payloadItem.code_item,
                        data: payloadItem.data
                    };
                    forecastList.push(forecastItem);
                }
                for (let i = forecastItem.data.length - 1; i >= 0; i--) {
                    if (forecastItem.data[i].value === 0) {
                        forecastItem.data.splice(i, 1);
                    }
                }
                if (forecastItem.data.length === 0) {
                    const index = utilService.getElementIndex(forecastList, 'code_item', forecastItem.code_item);
                    forecastList.splice(index, 1);
                }
            }
            resolve(forecastList);
        } catch(err) {
            throw new Error(err);
        }
    });
}