<div class="top-bar">
	<div class="left-side">
		<button *ngIf="goBackButton" mat-icon-button (click)="goBack()" class="back">
			<mat-icon class="bordered rounded">arrow_back</mat-icon>
		</button>
		<ng-content select="[left-side-content]"></ng-content>
		<div *ngIf="badgeContent" class="badge">{{ badgeContent }}</div>
	</div>
	<div class="right-side">
		<ng-content select="[additional-right-content]"></ng-content>
		<div class="action-menu" *ngIf="actionMenu">
			<button mat-icon-button [matMenuTriggerFor]="actionsmenu" aria-label="">
				<mat-icon class="bordered rounded">more_vert</mat-icon>
			</button>
			<mat-menu #actionsmenu="matMenu">
				<ng-content select="[actions-menu-buttons]"></ng-content>
			</mat-menu>
		</div>
	</div>
</div>
<mat-divider class="both-spacing mb-20 mt-20"></mat-divider>
