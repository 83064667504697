import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";

export const orderHeaderAsideSummaryFieldList: FormControlMultipurposeModel.Item[] = [
	{
		name: 'date',
		on_form_template: true,
		label: 'order.field.creation_date',
		type: FormControlMultipurposeEnum.ItemType.DATE
	},
	{
		name: 'first_evasion_date',
		on_form_template: true,
		type: FormControlMultipurposeEnum.ItemType.DATE,
		label: 'theme.order.field.delivery_date',
	},
	{
		name: 'customer_order_ref_code',
		on_form_template: true,
		label: 'order.field.customer_reference',
		type: FormControlMultipurposeEnum.ItemType.STRING
	}
];