import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sidebar-content-wrapper',
  templateUrl: './sidebar-content-wrapper.component.html',
  styleUrls: ['./sidebar-content-wrapper.component.scss']
})
export class SidebarContentWrapperComponent implements OnInit {

    @Input() title: string = null;

    constructor() { }

    ngOnInit(): void {
    }

}
