export interface ConfigCouchModel {
	endpoint: string;
	database: string;
}

export interface UrlConfigModel {
	label: string;
	api: string;
	couch: ConfigCouchModel[];
	origin: string;
}

export interface CompanyConfigModel {
	name: string;
	theme: string;
	logo: string;
}
export interface ConfigModel {
	environment: string;
	urlConfig: UrlConfigModel;
	company: CompanyConfigModel;
}

export class UsefulLinksModel {
	documents: string;
	recipes: string;
	tickets: string;
	sales: string;
}
