<div class="jumbotron__container" [style.backgroundImage]="'url(' + (jumbotronConf.backgroundImage | translate) + ')'">
	<div class="container jumbotron__label">
		<h1 *ngIf="jumbotronConf.title" class="jumbotron__title" [innerHTML]="jumbotronConf.title | translate"></h1>
		<p *ngIf="jumbotronConf.subtitle" class="jumbotron__subtitle">
			{{ jumbotronConf.subtitle | translate | sentencecase }}
		</p>
		<button
			*ngIf="jumbotronConf.labelButton"
			[routerLink]="jumbotronConf.url"
			class="custom-button mt-3"
			mat-flat-button
			color="accent"
			[innerHTML]="jumbotronConf.labelButton | translate"
		></button>
	</div>
	<div
		class="jumbotron__backdrop"
		*ngIf="jumbotronConf.hasBackdrop"
		[style.backgroundColor]="jumbotronConf.backdropColor || 'rgba(0,0,0,0.5)'"
	></div>
</div>
