import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormDynamicConfigurationModel } from '../../../model/form/form-dynamic.model';
import { FormControlMultipurposeService } from '../../../service/util/form-control-multipurpose.service';
import * as _ from 'lodash';
import { AngularCoreUtilService } from '../../../service/util/util.service';
import * as FormControlMultipurposeModel from '../../../model/widget/form-control-multipurpose.model';
import { UntypedFormGroup } from '@angular/forms';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { debounceTime, mergeMap, Observable, take } from 'rxjs';
import * as FormControlMultipurposeEnum from '../../../enum/widget/form-control-multipurpose.enum';

@Component({
	selector: 'form-dynamic',
	templateUrl: './form-dynamic.component.html',
	styleUrls: ['./form-dynamic.component.scss'],
	providers: [SubscribeManagerService]
})
export class FormDynamicComponent implements OnDestroy {

	@Input() set configuration(e: FormDynamicConfigurationModel) {
		if (e) {
			this._configuration = _.cloneDeep(e);
			const formValue = this._configuration.formValue ? this._configuration.formValue : {};
			this.formCreate(formValue);
		}
	}
	_configuration: FormDynamicConfigurationModel;

	@Output() valueChange = new EventEmitter<any>();

	form: UntypedFormGroup;
	formFieldList: FormControlMultipurposeModel.Item[];
	subscribeFormDataPrefix = 'form-dynamic_';

	constructor(
		private formFieldMultipurposeService: FormControlMultipurposeService,
		private utilService: AngularCoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
	) { }

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeFormData(): Observable<void> {
		return this.form.valueChanges.pipe(
			debounceTime(this._configuration.formDebounceTime ? this._configuration.formDebounceTime : 500),
			mergeMap(async e => {
				e = this.utilService.deleteEmptyProperties(e);
				this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, e);
				await this.formFieldMultipurposeService.updateFormControlAccordingToType(
					this.form,
					<FormControlMultipurposeModel.Item>{
						type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
						form_control_list: this.formFieldList
					},
					e
				);
				this.subscribeManagerService.populate(
					this.subscribeFormData().subscribe(),
					this.subscribeFormDataPrefix + this.formFieldList[0].form_id
				);
				this.emitFormValue();
			}),
			take(1)
		);
	}

	formCreate(formValue: any) {
		this.formFieldList =
			this.formFieldMultipurposeService.returnFormFieldListWithRootFormId(
				this._configuration.creationFieldMap,
				`${this.utilService.guid()}`
			);
		this.formFieldMultipurposeService.updateRootFormValueList(
			this.formFieldList[0].form_id,
			formValue
		);
		this.formFieldList =
			this.formFieldMultipurposeService.updateFormFieldListValue(
				this.formFieldList,
				formValue
			);
		const controls = {};
		for (const field of this.formFieldList) {
			controls[field.name] = this.formFieldMultipurposeService.returnNewFormControl(field);
		}
		this.form = new UntypedFormGroup(controls);
		this.formFieldMultipurposeService.updateFormControlAccordingToType(
			this.form,
			<FormControlMultipurposeModel.Item>{
				type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
				form_control_list: this.formFieldList
			},
			formValue
		);
		this.subscribeManagerService.populate(
			this.subscribeFormData().subscribe(),
			this.subscribeFormDataPrefix + this.formFieldList[0].form_id
		);
	}

	formReset() {
		this.formFieldList = [];
		const formValue = {};
		this.formCreate(formValue);
		this.emitFormValue();
	}

	emitFormValue() {
		if (this._configuration.emitAlsoNonValidForm || this.form.valid) {
			const formValue = this.utilService.deleteEmptyProperties(this.form.value);
			this.valueChange.emit(formValue);
		}
	}

}
