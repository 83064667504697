import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { KanbanModel } from '@saep-ict/angular-spin8-core';
import { KanbanActionEnum, KanbanStateAction } from './kanban.actions';

export namespace KanbanStateReducer {
	export const initialState: BaseStateModel<KanbanModel.Base[]> = null;

	const _reducer = createReducer(
		initialState,
		on(KanbanStateAction.load, state => state),
		on(KanbanStateAction.loadAll, state => state),
		on(KanbanStateAction.update, (state, kanban) => kanban),
		on(KanbanStateAction.save, state => state),
		on(KanbanStateAction.remove, (state, kanban) => ({
			data: state ? state.data : null,
			type: kanban.type
		})),
		on(KanbanStateAction.removed, (state, kanban) => state),
		on(KanbanStateAction.reset, () => initialState),
		on(KanbanStateAction.error, (state, kanban) => ({
			data: state ? state.data : null,
			type: kanban.type
		}))
	);

	export function reducer(state: BaseStateModel<KanbanModel.Base[]>, action: TypedAction<KanbanActionEnum>) {
		return _reducer(state, action);
	}
}
