import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ListSettingStoreReducer } from './list-setting.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('list-setting', ListSettingStoreReducer.reducer)
  ]
})
export class ListSettingStateModule {}
