import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CategoryMap } from '@saep-ict/pouch_agent_models';
// model
export enum CategoryListActionEnum {
	UPDATE = '[Category List] Update',
	LOAD_ALL = '[Category List] Load All',
	NOT_EXISTING = '[Category List] Not Existing',
	RESET = '[Category List] Reset',
	ERROR = '[Category List] Error'
}

export namespace CategoryListAction {
	export const update = createAction(CategoryListActionEnum.UPDATE, props<BaseStateModel<CategoryMap>>());
	export const loadAll = createAction(CategoryListActionEnum.LOAD_ALL);
	export const notExisting = createAction(CategoryListActionEnum.NOT_EXISTING);
	export const reset = createAction(CategoryListActionEnum.RESET);
}
