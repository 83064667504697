<div 
	*ngIf="_article"
	class="card card--{{ viewType === ViewTypeEnum.module ? 'grid' : 'list' }}"
>
	<div class="row align-items-center">
		<div class="{{ viewType === ViewTypeEnum.module ? 'col-12' : 'col-md-6 col-lg-4' }}">
			<article-image
				[image]="articleImage.bucket_link"
				[alt]="articleImage.alt"
				[name]="articleImage.name"
				[link]="[
					ROUTE_URL.public,
					itemType === itemTypeEnum.category ? ROUTE_URL.catalog : ROUTE_URL.product,
					linkToItem()
				]"
			>
			</article-image>
		</div>
		<div class="{{ viewType === ViewTypeEnum.module ? 'col-12' : 'col-md-6 col-lg-8' }}">
			<div class="card__header" *ngIf="showDetails">
				<h3 class="card__title">
					<a
						href="javascript:void(0)"
						[routerLink]="[
							ROUTE_URL.public,
							itemType === itemTypeEnum.category ? ROUTE_URL.catalog : ROUTE_URL.product,
							linkToItem()
						]"
					>
						{{
							utilTranslateService.getTranslationFromLanguage(_article.articleDescription.language_list)
								.description | sentencecase
						}}
					</a>
				</h3>
				<b2c-article-price [article]="_article"></b2c-article-price>
				<span class="card__size" *ngIf="_article.articleDescription.size">
					<em>{{ _article.articleDescription.size }}</em>
				</span>
			</div>
			<p *ngIf="viewType !== ViewTypeEnum.module" class="card__description truncate">
				{{
					utilTranslateService.getTranslationFromLanguage(_article.articleDescription.language_list)
						.description_add | sentencecase
				}}
			</p>
		</div>
	</div>
</div>
