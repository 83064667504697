<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.TOP">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<mat-toolbar>
				<mat-toolbar-row>
					<!-- Tabs | WIP tab-wrapper -->
					<mat-tab-group (selectedTabChange)="filterUsersOnTabClick($event)">
						<mat-tab label="active_users">
							<ng-template mat-tab-label>{{
								'user.management.tabs.active_users' | translate | sentencecase
							}}</ng-template>
						</mat-tab>
						<mat-tab label="all_users">
							<ng-template mat-tab-label>{{
								'user.management.tabs.all_users' | translate | sentencecase
							}}</ng-template>
						</mat-tab>
						<mat-tab label="deactivated_users">
							<ng-template mat-tab-label>{{
								'user.management.tabs.deactivated_users' | translate | sentencecase
							}}</ng-template>
						</mat-tab>
					</mat-tab-group>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<span class="flex-span"></span>
					<td-search-box
						backIcon="search"
						[placeholder]="'general.search' | translate | sentencecase"
						[(ngModel)]="userManagementState.dataSetting.appliedFilter.text"
						[showUnderline]="true"
						[debounce]="500"
						[alwaysVisible]="true"
						(searchDebounce)="loadUserList()"
					>
					</td-search-box>
				</mat-toolbar-row>
			</mat-toolbar>
			<ng-container
				*ngIf="
					userManagementState && userManagementState.data && userManagementState.data.length > 0;
					else noData
				"
			>
				<td-data-table
					#dataTable
					[data]="userManagementState.data"
					[columns]="userManagementListColumn"
					[clickable]="true"
					(sortChange)="sortChange($event)"
					(rowClick)="goToDetail($event)"
				>
					<ng-template tdDataTableTemplate="context_application_list" let-value="value">
						<ng-container *ngFor="let item of value; last as isLast">
							{{
								permissionUtilService.contextTypeAndApplicationLinkFullObject(item.code)
									.application_name
							}}{{ isLast ? '' : ', ' }}
						</ng-container>
					</ng-template>
					<ng-template tdDataTableTemplate="avatar" let-value="value">
						<preview-container
							class="avatar"
							[configuration]="
								{
									image: value,
									icon: 'person',
									width: '36px',
									height: '36px',
									onClickHandler: false
								}
							"
						>
						</preview-container>
					</ng-template>
					<ng-template tdDataTableTemplate="is_active" let-value="value" let-row="row">
						<mat-icon
							[ngClass]="{ 'txt-green': value, 'status-icon': true }"
							[matTooltip]="value ? 'Active' : 'Not Active'"
						>
							{{ value ? 'check_circle' : 'check_circle_outline' }}
						</mat-icon>
					</ng-template>
				</td-data-table>

				<ng-container [ngTemplateOutlet]="paginationAndActions"></ng-container>
			</ng-container>

			<ng-template #noData>
				<p class="placeholder">{{ 'user.not_found' | translate | sentencecase }}</p>
				<ng-container [ngTemplateOutlet]="paginationAndActions"></ng-container>
			</ng-template>
		</mat-card>
	</ng-container>

	<!-- Filtri sidebar -->
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'general.filters' | translate | sentencecase">
			<ng-container main-content>
				<form [formGroup]="form" (ngSubmit)="onFormSubmit()" class="michelangelo-theme">
					<!-- Context application code -->
					<mat-form-field>
						<mat-label>{{ 'user.context' | translate | sentencecase }}</mat-label>

						<mat-select formControlName="context_code">
							<mat-select-trigger *ngIf="getFormFieldValue('context_code')">
								{{
									permissionUtilService.contextTypeAndApplicationLinkFullObject(
										getFormFieldValue('context_code')
									).application_name
								}}
							</mat-select-trigger>

							<mat-option
								*ngFor="let item of contextTypeAndApplicationLink"
								[value]="item.context_application_item_code"
							>
								{{ item.application_name }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
						(primaryButtonClickEmitter)="onFormSubmit()"
						[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
						(secondaryButtonClickEmitter)="resetFilters()"
					></form-button-bar>

					<!-- <mat-toolbar>
                        <button mat-flat-button color="primary" class="mb-3 mr-3" type="submit">
                            {{ 'general.apply_filters' | translate | sentencecase }}
                        </button>
                        <button mat-stroked-button color="primary" class="mb-3 mr-3" type="button" (click)="resetFilters()">
                            {{ 'general.reset' | translate | sentencecase }}
                        </button>
                    </mat-toolbar> -->
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>



<ng-template #paginationAndActions>
	<pagination-and-actions>
		<ng-container pagination *ngIf="userManagementState && userManagementState.data && userManagementState.data.length > 0">
			<td-paging-bar
					#pagingBarPageSize
					[firstLast]="true"
					[pageSize]="userManagementState.dataSetting.pagination.page_size"
					[total]="userManagementState.dataSetting.pagination.total_element"
					(change)="paginationChange($event)"
			>
				<span hide-xs>{{ 'general.items_per_page' | translate | sentencecase }}</span>
				<mat-select
						[style.width.px]="45"
						[(ngModel)]="userManagementState.dataSetting.pagination.page_size"
						(ngModelChange)="changePageSize($event)"
				>
					<mat-option *ngFor="let size of [5, 10, 20, 50]" [value]="size">
						{{ size }}
					</mat-option>
				</mat-select>
			</td-paging-bar>
		</ng-container>

		<ng-container actions>
			<button
					mat-fab
					color="primary"
					[matTooltip]="'user.add_new' | translate | sentencecase"
					matTooltipPosition="left"
					routerLink="./new"
					class="mat-fab-button__in-page"
			>
				<mat-icon>add</mat-icon>
				<span class="label">{{ 'user.add_new' | translate | sentencecase }}</span>
			</button>
		</ng-container>
	</pagination-and-actions>
</ng-template>






<!--<button-->
<!--	mat-fab-->
<!--	color="primary"-->
<!--	class="mat-fab-button"-->
<!--	[matTooltip]="'user.add_new' | translate | sentencecase"-->
<!--	matTooltipPosition="left"-->
<!--	routerLink="./new"-->
<!--&gt;-->
<!--	<mat-icon>add</mat-icon>-->
<!--	<span class="label">{{ 'user.add_new' | translate | sentencecase }}</span>-->
<!--</button>-->
