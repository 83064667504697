import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import {
	MediaReplayService,
	NavItem,
	BaseStateModel,
	SubscribeManagerService,
	UtilDownload,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { NavigationService } from '../../service/navigation/navigation.service';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { take, filter, map } from 'rxjs/operators';
import {
	ContextApplicationItemCodeEnum,
	PATH_URL,
	UserDetailModel,
	OrganizationStateModel,
	PermissionKeyEnum
} from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { OrganizationActionEnum } from '../../state/organization/organization.actions';
import { AppUtilService } from '../../service/util/app-util.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCompanyInfoComponent } from '../../widget/dialog/dialog-company-info/dialog-company-info.component';
import { UtilOrderService } from '../../service/util/util-order.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionUtilService } from '../../service/util/permission-util.service';
import * as ConfigurationCustomerAppStructure from './../../constants/configuration-customer/app-structure/app-structure.constant';

@Component({
	selector: 'saep-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	providers: [SubscribeManagerService]
})
export class SaepSidebarComponent implements OnInit, OnDestroy  {
	isOpen: boolean;
	@Output() isOpenEvent = new EventEmitter<boolean>();

	sidebarHeight;

	items: NavItem[];
	private _itemsSubscription: Subscription;
	private _routerEventsSubscription: Subscription;
	configSubscription: Subscription;
	appTag: string;
	appRelease: string;
	companyName: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	logoName: string;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	appStructure = ConfigurationCustomerAppStructure;

	orderPermission = PermissionKeyEnum.B2B_ORDER_LIST;

	headerContainerHeight: number;
	deviceHeight: number;

	showOrganizationInfo: boolean = false;
	showAddOrderButton: boolean = false;
	logoHeight: number;

	maxWidthLogo = 140;
	maxHeightLogo = 80;
	navigationItemHeight = 40;

	downloadThroughIframeId = this.utilService.guid();

	constructor(
		public navigationService: NavigationService,
		private router: Router,
		protected appConfig: CustomerAppConfig,
		public mediaReplayService: MediaReplayService,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		public utilService: AppUtilService,
		private dialog: MatDialog,
		private utilOrderService: UtilOrderService,
		private sentencecasePipe: SentencecasePipe,
		private translateService: TranslateService,
		private snackBar: MatSnackBar,
		public permissionUtilService: PermissionUtilService
	) {
		this.configSubscription = this.appConfig.config$.subscribe(config => {
			this.appTag = config.tag;
			this.appRelease = config.release;
			this.companyName = config.company.name;
		});

		this.user$.pipe(take(1)).subscribe(res => {
			if (res.data && res.data.current_permission && res.data.current_permission.context_application) {
				this.user = res.data;
				this.logoName = this.getSpin8LogoName(res.data.current_permission.context_application);
			}
		});

		this.subscribeManagerService.populate(this.subscribeOrganization().subscribe(), 'subscribeOrganization');
	}

	/**
	 * Dato un contesto dell'applicazione viene restituito il nome del logo declinato nella versione corretta
	 * @param applicationContext codice che identifica il contesto dell'applicazione attivo
	 * @returns stringa che identifica il nome del logo spin8 declinato nella versione corretta
	 */
	getSpin8LogoName(applicationContext: ContextApplicationItemCodeEnum): string {
		// Scelgo il logo da mostrare in base al contesto
		switch (applicationContext) {
			case ContextApplicationItemCodeEnum.AGENT:
				return 'logo-SPIN8-esales-agents.svg';
				break;

			case ContextApplicationItemCodeEnum.B2B:
				return 'logo-SPIN8-esales-business.svg';
				break;

			case ContextApplicationItemCodeEnum.B2C:
				return 'logo-SPIN8-esales-consumers.svg';
				break;

			case ContextApplicationItemCodeEnum.BACKOFFICE || ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
				return 'logo-SPIN8-controlcenter.svg';
				break;

			case ContextApplicationItemCodeEnum.CRM:
				return 'logo-SPIN8-esales-crm.svg';
				break;

			default:
				return 'logo-SPIN8-generic.svg';
				break;
		}
	}

	ngOnInit() {
		this.setIsOpen(this.utilService.isDesktop() ? true : false);
		this._itemsSubscription = this.navigationService.items$.subscribe((items: NavItem[]) => {
			this.items = this.sortRecursive(items, 'position') as NavItem[];
		});
		this._routerEventsSubscription = this.router.events.subscribe(event => {
			if (this.isOpen && !this.utilService.isDesktop()) {
				this.setIsOpen(false);
				this.navigationService.closeAll();
			}
		});

		/** righe per la gestione degli ingobmri in sidebar **/
		this.showOrganizationInfo = this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.B2B;
		this.showAddOrderButton = this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.B2B && this.permissionUtilService.userHasPermission(this.user, this.orderPermission);

		this.deviceHeight = this.mediaReplayService.deviceH;
		const logo = new Image();
		logo.src = 'assets/theme-current/img/company-logo/logo.svg'
		logo.onload = () => {
			const h = logo.naturalHeight;
			const w = logo.naturalWidth;
			this.logoHeight = (this.maxWidthLogo * h) / w;
			this.logoHeight = this.logoHeight > this.maxHeightLogo ? this.maxHeightLogo : this.logoHeight;
			this.headerContainerHeight = this.logoHeight + 20;
			logo.remove();
		};

		this.sidebarHeight = this.getHeight();
	}

	setIsOpen(value: boolean = true) {
		this.isOpen = value;
		this.sendIsOpen();
	}

	toggleSidebar() {
		this.setIsOpen(!this.isOpen);
		this.navigationService.closeAll();
		this.sidebarHeight = this.getHeight();
	}

	closeSidebar() {
		this.setIsOpen(false);
		this.navigationService.closeAll();
		this.sidebarHeight = this.getHeight();
	}

	sortRecursive(array: NavItem[], propertyName: string) {
		array.forEach(item => {
			const keys = _.keys(item);
			keys.forEach(key => {
				if (_.isArray(item[key])) {
					item[key] = this.sortRecursive(item[key], propertyName);
				}
			});
		});
		return _.sortBy(array, propertyName);
	}

	// Counts the amount of Sub Items there is and returns the count.
	getOpenSubItemsCount(item: NavItem): number {
		let count = 0;

		if (item.hasSubItems() && this.navigationService.isOpen(item)) {
			count += item.subItems.length;

			item.subItems.forEach(subItem => {
				count += this.getOpenSubItemsCount(subItem);
			});
		}

		return count;
	}

	toggleDropdown(item: NavItem): void {
		if (item && item.subItems && item.subItems.length > 0) {
			if (this.utilService.isMobile()) {
				if (item.hasParent()) {
					this.navigationService.toggleCurrentlyOpen(item.parent);
				} else {
					this.navigationService.toggleCurrentlyOpen(item);
				}
			} else {
				if (item.hasSubItems()) {
					this.navigationService.toggleCurrentlyOpen(item);
					this.setIsOpen(true);
				} else {
					this.navigationService.toggleCurrentlyOpen(item.parent);
				}
			}
			this.sidebarHeight = this.getHeight(item);
		}
	}

	ngOnDestroy() {
		if (this._itemsSubscription) {
			this._itemsSubscription.unsubscribe();
		}
		if (this._routerEventsSubscription) {
			this._routerEventsSubscription.unsubscribe();
		}
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
		this.subscribeManagerService.destroy();
		UtilDownload.downloadThroughIframeRemove(this.downloadThroughIframeId);
	}

	orderDraftCreate() {
		this.utilOrderService.orderDraftCreate(this.organization);
		this.router.navigate([
			`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.user.current_permission.context_code.code}/new/header-edit`
		]);
	}

	subscribeOrganization() {
		return this.organization$.pipe(
			filter(
				(organizationState: BaseStateModel<OrganizationStateModel>) =>
					!!(organizationState && organizationState.data)
			),
			map((organizationState: BaseStateModel<OrganizationStateModel>) => {
				switch (organizationState.type) {
					case OrganizationActionEnum.UPDATE:
						this.organization = organizationState.data;
						break;

					default:
						break;
				}
			})
		);
	}

	sendIsOpen() {
		this.isOpenEvent.emit(this.isOpen);
	}

	getMaxHeight() {
		let mediaQueryOffset = 70;
		switch (true) {
			case this.utilService.isTablet():
				mediaQueryOffset = 70;
				break;
			case this.utilService.isMobile():
				mediaQueryOffset = 30;
				break;
			case this.utilService.isDesktop():
			default:
				mediaQueryOffset = 70;
		}

		const organizationInfoHeight = this.showOrganizationInfo ? 70 : 0;
		const addOrderHeight = this.showAddOrderButton ? 60 : 0;
		const userGuideHeight = 40;
		const footerHeight = 40;

		const delta: number = this.headerContainerHeight + organizationInfoHeight + addOrderHeight + userGuideHeight + footerHeight + mediaQueryOffset;
		//const itemHeight = 44;

		const usefulHeight = this.deviceHeight - delta;

		//facendo il return qui il navigation container occuperà sempre tutto lo spazio disponibile con il rischio di tagliare l'ultimo elemento
		//return usefulHeight;


		//facendo il return qui il navigation container occuperà sempre tutto lo spazio disponibile utile
		// per visualizzare il maggior numeri di elementi evitando il "taglio" dell'ultimo
		const maxItemsNumber = Math.floor(usefulHeight / this.navigationItemHeight);
		return (maxItemsNumber * this.navigationItemHeight);
	}



	getHeight(item: NavItem = null) {
		const height = this.items.length * this.navigationItemHeight;
		if (item && this.navigationService.currentlyOpen.length > 0) {
			const maxHeight = this.getMaxHeight();
			// 36 è l'altezza dei subitem
			// 10 è il margine inferiore dell'ultimo subitem

			const subItemsOpenHeight = this.getOpenSubItemsCount(item) * 36 + 10 + height;
			return subItemsOpenHeight <= maxHeight ? subItemsOpenHeight : maxHeight;
		}
		return height;
	}

	async downloadUserManual() {
		const fileName = `user_manual_${this.user.current_permission.context_application.toLowerCase()}.pdf`;
		 	const url =
				`${this.appConfig.config.bucketManager.be_url}/user_manual/download-multipart?path=/${fileName}&token=${this.appConfig.token}`;
			UtilDownload.handleDownloadThroughIframe(url, this.downloadThroughIframeId);
	}

	dialogShowCompanyInfo() {
		this.dialog.open(DialogCompanyInfoComponent, {
			disableClose: false,
			panelClass: ['company-info', 'michelangelo-theme-dialog']
		});
	}
}