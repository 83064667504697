<mat-dialog-wrapper [title]="'Company'" [showCloseIcon]="true">
	<!-- <button class="close" type="button" mat-icon-button (click)="close()">
		<mat-icon>close</mat-icon>
	</button> -->
	<ng-container content>
		<div class="logo">
			<img
				src="{{ customerReferenceDev.LOGO }}"
				title="{{ customerReferenceDev.NAME_LONG }}"
				alt="{{ customerReferenceDev.NAME_LONG }}"
			/>
		</div>
		<div class="company-info">
			<h4>{{ customerReferenceDev.NAME_LONG }}</h4>
			<div class="address">
				<span>{{ customerReferenceDev.ADDRESS_SHORT }}</span>
				<span
					>{{ customerReferenceDev.POSTAL_CODE }} {{ customerReferenceDev.CITY }} ({{
						customerReferenceDev.PROVINCE
					}}), {{ customerReferenceDev.COUNTRY }}</span
				>
			</div>

			<div class="contacts">
				<span>{{ 'company_info.phone' | translate }}: {{ customerReferenceDev.PHONE }}</span>
				<span>{{ 'company_info.email' | translate }}: {{ customerReferenceDev.EMAIL }}</span>
				<span>{{ 'company_info.website' | translate }}: {{ customerReferenceDev.WEBSITE }}</span>
			</div>

			<div class="vat">
				<span>{{ 'company_info.vat' | translate | uppercase }}: {{ customerReferenceDev.VAT }}</span>
			</div>
		</div>

		<div class="software-info">
			<span
				>Spin8 {{ 'company_info.version' | translate }} {{ softwareInfo.version }}
				{{ softwareInfo.release }}</span
			>
		</div></ng-container
	>
</mat-dialog-wrapper>
