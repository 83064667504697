<div class="header">
	<ng-content></ng-content>
	<ul *ngIf="!hideTab">
		<li *ngFor="let r of routes" [routerLink]="[r.path]" routerLinkActive="active">
			<mat-icon *ngIf="r.data.icon">{{r.data.icon}}</mat-icon>
			{{ 'tab_wrapper.' + r.path | translate | uppercase }}
		</li>
	</ul>
</div>
<div class="main">
	<router-outlet></router-outlet>
</div>
