import { ContactTypeEnum } from '@saep-ict/pouch_agent_models';
import { creationFieldMapDocumentBase } from '../creation-field-map-base.const';
import {
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum,
	FormControlMultipurposeSetDisabled,
	FormValidatorCustom
} from '@saep-ict/angular-core';
import _ from 'lodash';

export const creationFieldMapContactBase: FormControlMultipurposeModel.Item[] =
  _.cloneDeep(creationFieldMapDocumentBase).concat([
    {
      name: 'contact_type',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.SELECT,
      column_width: 4,
      option_list: [
        {
          value: ContactTypeEnum.PERSONAL
        },
        {
          value: ContactTypeEnum.FUNCTIONAL
        }
      ]
    },
    {
      name: 'first_name',
      label: 'user.field.first_name',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 6,
      set_disabled: formValue =>
        FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
          pathToProperty: 'contact_list.contact_type',
          propertyValue: ContactTypeEnum.FUNCTIONAL
        }),
      clear_value_if_disabled: true
    },
    {
      name: 'last_name',
      label: 'user.field.last_name',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 6,
      set_disabled: formValue =>
        FormControlMultipurposeSetDisabled.disabledIfSomeFieldIsSomeValue(formValue, {
          pathToProperty: 'contact_list.contact_type',
          propertyValue: ContactTypeEnum.FUNCTIONAL
        }),
      clear_value_if_disabled: true
    },
    {
      name: 'email',
      label: 'user.field.email',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 6,
      validator_list: [FormValidatorCustom.email]
    },
    {
      name: 'phone',
      label: 'contact.field.phone_label',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 4
    },
    {
      name: 'phone_mobile',
      label: 'contact.field.phone_mobile',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 4
    },
    {
      name: 'fax',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 4
    },
    {
      name: 'area',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 6
    },
    {
      name: 'role',
      label: 'contact.field.role',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 6
    },
    {
      name: 'language',
      label: 'contact.field.language',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.SELECT,
      column_width: 6,
      option_list: [
        // riempito dalla configurazione di progetto
      ]
    },
    {
      name: 'is_main_of_list',
      label: 'contact.field.is_main_of_list',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
      value: false,
      column_width: 4
    },
    {
      name: 'has_notification',
      label: 'contact.field.has_notification',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
      value: false,
      column_width: 4
    }
  ]);
