import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AngularCoreUtilService, GuidFormatterPipe, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import _ from 'lodash';
import { DialogImageGalleryComponent } from '../../dialog/dialog-image-gallery/dialog-image-gallery.component';
import { Category, LanguageItem } from '@saep-ict/pouch_agent_models';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';

@Component({
	selector: 'category-list-wrapper',
	templateUrl: './category-list-wrapper.component.html',
	styleUrls: ['./category-list-wrapper.component.scss']
})
export class CategoryListWrapperComponent extends LocalListHandlerWidgetWrapper {

	@Output() onItemEdit: EventEmitter<Category> = new EventEmitter();
	@Output() onItemDelete: EventEmitter<Category> = new EventEmitter();

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public guidFormatterPipe: GuidFormatterPipe,
		public router: Router,
		private dialog: MatDialog,
		private bucketManagerService: BucketManagerService
	) {
		super(utilService, translateService, store);
	}

	async dialogGallery(e: Category) {
		this.dialog.open(DialogImageGalleryComponent, {
			data: {
				language_list: await this.setAttachmentBucketLink(e)
			},
			disableClose: true,
			panelClass: ['michelangelo-theme-dialog', 'image-gallery']
		});
	}

	setAttachmentBucketLink(e: Category): Promise<LanguageItem[]> {
		return new Promise(resolve => {
			try {
				e = _.cloneDeep(e);
				for (const languageItem of e.language_list) {
					for (const attachment of languageItem.image_list) {
						attachment.bucket_link =
							this.bucketManagerService.returnBucketManagerDownloadUrl(
								attachment.name,
								'category',
								e.code_item
							);
					}
				}
				resolve(e.language_list);
			} catch(err) {
				throw new Error(err);
			}
		});
	}

}
