import { Component, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel, OrderPouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { TableOrderModel } from '../../../../model/table/table-order.model';
import { StateFeature } from '../../../../state';
import { OrderListActionEnum, OrderListStateAction } from '../../../../state/order-list/order-list.actions';
import { AppUtilService } from '../../../../service/util/app-util.service';
import {
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel,
	AngularSpin8CoreUtilCompanyService,
	PermissionKeyEnum
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import {
	ItemSidebarContentStandardComponentConfig,
	IconType
} from '../../../../widget/sidebar-content-standard/sidebar-content-standard.component';

import {
	ItemBoxTrendInfoComponentConfig,
	TrendDirectionValues
} from '../../../../widget/box-trend-info/box-trend-info.component';
import * as ConfigurationCustomerStatistic from '../../../../constants/configuration-customer/statistic/statistic.constant';
import * as ConfigurationCustomerOrder from '../../../../constants/configuration-customer/order/order.constant';
import * as ConfigurationCustomerNews from '../../../../constants/configuration-customer/news/news.constant';
import * as Dashboard from '../../../../constants/dashboard.constants';
import * as DashboardConfiguration from '../../../../constants/dashboard.constants'
import moment from 'moment';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { SubscribeManagerItem } from '../../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../../constants/subscribe-manager.constant';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { NewsModel } from '../../../../model/news.model';
import { NewsListActionEnum } from '../../../../state/news-list/news-list.actions';
import { NewsStatusEnum } from '../../../../enum/news.enum';
import { DateAsAgoPipe } from '../../../../shared/pipe/date-as-ago.pipe';
import { NewsListStateAction } from '../../../../state/news-list/news-list.actions';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import { ViewAllButtonConfiguration } from '../../../../model/util.model';
import * as ConfigurationCustomerOrderErp from '../../../../constants/configuration-customer/order/order-erp.constant';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard-backoffice.component.html',
	styleUrls: ['./dashboard-backoffice.component.scss'],
	providers: [ SubscribeManagerService ]
})
export class DashboardBackofficeComponent implements OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: OrderStateModel[];
	newsList$: Observable<BaseStateModel<NewsModel[]>> = this.store.select(StateFeature.getNewsListState);

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'news-list', observable: this.subscribeNewsList() },
		{ key: 'order-list', observable: this.subscribeOrderList() }
	];

	listPageBaseLastOrders = <LocalListHandlerBaseModel<TableOrderModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'header.date',
			order: TdDataTableSortingOrder.Descending
		}
	};

	sidebarContentNews: Array<ItemSidebarContentStandardComponentConfig> = [];

	PATH_URL = PATH_URL;
	ROUTE_URL = ROUTE_URL;

	sideBarPositionValues = SideBarPositionValues;

	cardVisibilityPermissions = Dashboard.cardVisibilityPermissions.BACKOFFICE;

	boxTrendInfoOrder: Array<ItemBoxTrendInfoComponentConfig>;

	configurationCustomerStatistic = ConfigurationCustomerStatistic;
	dashboardConfiguration = DashboardConfiguration;

	updatedAtGeneral = moment().format("DD/MM/YYYY, h:mm");

	permissionKeyEnum = {
		news: PermissionKeyEnum.BACKOFFICE_NEWS,
		order: PermissionKeyEnum.BACKOFFICE_ORDER_LIST
	}

	viewAllButtonConfiguration: ViewAllButtonConfiguration = {
		display: false,
	}

	sidebarConent: Array<ItemSidebarContentStandardComponentConfig> = [
		{
			title: 'string',
			subtitle: 'sdfdsfsdfsdf',
			rightContent: '250k',
			icon: { type: IconType.MATERIAL, name: 'assignment' }
		},
		{
			title: 'strinsd g strinsd g strinsd g strinsd g strinsd g strinsd g',
			subtitle: 'sdfdsfsdfsdf sdfdsfsdfsdf sdfdsfsdfsdf sdfdsfsdfsdf sdfdsfsdfsdf sdfdsfsdfsdf',
			rightContent: '-',
			icon: { type: IconType.CUSTOM, name: 'custom-package' }
		}
	];

	trendBoxContent: Array<ItemBoxTrendInfoComponentConfig> = [
		{
			value: '78.195',
			suffix: 'eur',
			title: 'Credits',
			subtitle: 'On 15 movements',
			trendDirection: TrendDirectionValues.UP
		},
		{
			value: '4.5%',
			//suffix: '%',
			title: 'Value',
			subtitle: '2 out of 15 movements',
			trendDirection: TrendDirectionValues.DOWN
		},
		{
			value: '0',
			// suffix: 'eur',
			title: 'Cancelled',
			subtitle: 'Deleted By User',
			trendDirection: TrendDirectionValues.STEADY
		}
	];

	constructor(
		private store: Store,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		public utilService: AppUtilService,
		public utilBreadcrumbService: UtilBreadcrumbService,
		private utilStoreService: StoreUtilService,
		private dateAsAgo: DateAsAgoPipe,
		public permissionUtilService: PermissionUtilService
	) {
		this.loadStaticData();
		this.store.dispatch(NewsListStateAction.loadAll());
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('dashboard');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['dashboard'])
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.user = e.data;
			this.listPageBaseLastOrders.columnList = ConfigurationCustomerOrder.columnList(
				this.user.current_permission.context_application
			);
			this.store.dispatch(OrderListStateAction.loadAll());
		});
	}

	subscribeOrderList(): Observable<void> {
		return this.orderList$.pipe(
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					this.user.current_permission.context_code.code &&
					orderList &&
					orderList.type !== OrderListActionEnum.LOAD_ALL
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				if (orderList) {
					if (orderList.type === OrderListActionEnum.ERROR) {
						throw new Error(OrderListActionEnum.ERROR);
					}
					this.orderList = orderList.data;
					this.boxTrendInfoOrder =
						DashboardConfiguration.returnBoxTrendInfoOrderContent(
							ConfigurationCustomerOrder.dashboardOrderTrendBoxStatusList[this.user.current_permission.context_application],
							this.orderList,
							ConfigurationCustomerStatistic.timeRangeSelector[0]
						);
					// last orders permanent filters
					const listPageBaseLastOrders =
						ConfigurationCustomerOrder.configurationLastOrderslistFilter(
							_.cloneDeep(orderList.data),
							this.user.current_permission.context_application
						);
					this.listPageBaseLastOrders.data = ConfigurationCustomerOrderErp.getTableOrderList(listPageBaseLastOrders);
					this.listPageBaseLastOrders = _.cloneDeep(this.listPageBaseLastOrders);
				}
			})
		);
	}

	subscribeNewsList(): Observable<void> {
		return this.newsList$.pipe(
			filter((state: BaseStateModel<NewsModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<NewsModel[]>) => {
				switch (state.type) {
					case NewsListActionEnum.UPDATE:
						this.sidebarContentNews =
							// il filtro BACKOFFICE mostrerebbe anche le news in bozza
							ConfigurationCustomerNews.listFilter(state.data, ContextApplicationItemCodeEnum.B2B)
							.filter(item => item.header.status === NewsStatusEnum.NEW)
							.sort((a, b) => b.date_update - a.date_update)
							.slice(0, ConfigurationCustomerNews.showLastN)
							.map(item => {
								return {
									title: item.body.subject,
									subtitle: this.dateAsAgo.transform(item.date_update),
									icon: { type: IconType.CUSTOM, name: 'custom-document' },
									clickEvent: 'sadsds'
								};
							});
				}
			})
		);
	}

	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		this.router.navigate([
			`${PATH_URL.PRIVATE}/orders/${selectedOrder.header.status}/${selectedOrder.header.organization.code_item}/${selectedOrder._id}/checkout`
		]);
	}

	hasVisibility(permission: string[]) {
		return this.utilService.hasAtLeastOnePermission({ data: this.user }, permission);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrderListStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
		this.store.dispatch(NewsListStateAction.reset());
	}

	updateBoxTrendInfoOrderContent(e: MatSelectChange) {
		this.boxTrendInfoOrder =
			DashboardConfiguration.returnBoxTrendInfoOrderContent(
				ConfigurationCustomerOrder.dashboardOrderTrendBoxStatusList[this.user.current_permission.context_application],
				this.orderList,
				e.value
			);
	}

}
