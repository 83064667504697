import { createAction, props, Action } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

import { ConnectionModel } from '../../model/connection.model';

export enum ConnectionActionEnum {
	UPDATE = '[connection] Update',
	LOAD = '[connection] Load',
	ERROR = '[connection] Error'
}

export namespace ConnectionStateAction {
	export const load = createAction(ConnectionActionEnum.LOAD);
	export const update = createAction(ConnectionActionEnum.UPDATE, props<BaseStateModel<ConnectionModel>>());
	export const error = createAction(ConnectionActionEnum.ERROR, props<BaseStateModel<any>>());
}

export enum AppActionTypes {
	StartAppInitializer = '[AppActipnTypes] Start App Initializer',
	FinishAppInitializer = '[AppActipnTypes] Finish App Initializer'
}

export class StartAppInitializer implements Action {
	public readonly type = AppActionTypes.StartAppInitializer;
}

export class FinishAppInitializer implements Action {
	public readonly type = AppActionTypes.FinishAppInitializer;
}

export type AppActions = StartAppInitializer | FinishAppInitializer;
