import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { AngularCoreUtilService, FormControlMultipurposeModel, SentencecasePipe } from '@saep-ict/angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { ArticlePouchModel, AttachedFile, LanguageItem } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';

export interface LanguageItemCatalog {
	language: any;
	description: string;
}


@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreUtilTranslateService {
	language_enum: any;
	@LocalStorage('language') private language: string;
	public languages: string[];

	constructor(
		public translate: TranslateService,
		private dateAdapter: DateAdapter<any>,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private utilService: AngularCoreUtilService
  ) {
  }
	/**
	 * This function set all the mandatory data to handle translation
   * - `translate.addLangs`: setta le lingue a disposizione a partire da Language enum passato dall'applicazione che implementa
   *   la libreria, escludendo DEFAULT e FALLBACK
   * - `translate.setDefaultLang`: setta la lingua di fallback. Usato FALLBACK oppure o in mancanza la prima lingua presente
   *   nel set di lingue disponibili
   * - `setLanguage`: modifica la lingua in uso, se presente tra quelle a disposizione, secondo l'ordine
   *   1 Passata attraverso query param
   *   2 Salvata nel local storage
   *   3 Passata dal browser
   *   4 Indicata come default
   *   5 Prima del set di lingue disponibili
	 */
	setLanguageFirstTime(lang: any) {
		try {
			this.language_enum = lang;
			this.languages = Object.keys(this.language_enum)
				.filter(i => i !== 'DEFAULT' && i !== 'FALLBACK')
				.map(i => this.language_enum[i]);
      if (this.languages && this.languages.length > 0) {
        this.translate.addLangs(this.languages);

        const browserLang = this.translate.getBrowserLang();
        if (this.languages.includes(this.language_enum.FALLBACK)) {
          this.translate.setDefaultLang(this.language_enum.FALLBACK);
        } else {
          this.languages[0];
        }

        let selectedLang: string;

        this.route.queryParams.subscribe(params => {
          // prima del set di lingue disponibili
          selectedLang = this.languages[0];
          // default
          if (this.languages.includes(this.language_enum.DEFAULT)) {
            selectedLang = this.language_enum.DEFAULT;
          }
          // browser
          if (this.languages.includes(browserLang)) {
            selectedLang = browserLang;
          }
          // salvata nel local storage
          if (this.languages.includes(this.language)) {
            selectedLang = this.language;
          }
          // query param
          if (params['lang'] && this.languages.includes(params['lang'])) {
            selectedLang = params['lang'];
          }
          this.setLanguage(selectedLang, false);
        });
      } else {
        const en = 'en';
        this.translate.addLangs([en]);
        this.translate.setDefaultLang(en);
        this.setLanguage(en, false);
        Sentry.captureMessage('Nessuna lingua disponibile in languages. Settata lingua inglese');
      }
		} catch (err) {
			Sentry.captureMessage(`Lingua non recuperata correttamente in Angular-spin8-core errore: ${err}`);
			throw new Error(err);
		}
	}

	/**
	 * @param language locale about the language to set
	 * @param showSnackBar boolean to decide to show the snackbar
	 */
	public setLanguage(language, showSnackBar = false) {
		this.translate.use(language).subscribe(res => {
			// set localstorage
			this.language = language;

			// select locale datepicker
			this.dateAdapter.setLocale(language);

			// show snackbar about change lang
			if (showSnackBar) {
				const currentLanguage = this.sentencecasePipe.transform(
					this.translate.instant('language.current_language')
				);
				const newLanguage = this.sentencecasePipe.transform(this.translate.instant('language.' + language));

				this.snackBar.open(this.translate.instant(currentLanguage + ': ' + newLanguage), 'Ok', {
					duration: 10000
				});
			}
		});
	}

	/**
	 *
	 *
	 * @template T
	 * @param {T[]} languageList
	 * @param {*} [referenceLanguage]
	 * @param {boolean} [avoidFallback] se true evita di cercare Language.DEFAULT e Language.FALLBACK in assenza della lingua richiesta
	 * @returns {T}
	 * @memberof UtilTranslateService
	 */
	getTranslationFromLanguage<T extends LanguageItem>(
		languageList: T[],
		referenceLanguage?,
		avoidFallback?: boolean
	): T {
		// TEMP FIX for Irinox only. Non fare lo stesso errore su spin8. Riportato commentato perché si farà lo stesso errore su spin8
		// itemCatalog.map(ic => (ic.language = ic.language.toLowerCase() as Language));
		if (!referenceLanguage) {
			referenceLanguage = this.translate.currentLang;
		}
		if (languageList) {
			const requestedLanguage = languageList.find(
				i => i.language.toLowerCase() == referenceLanguage.toLowerCase()
			);
			if (requestedLanguage || avoidFallback) {
				return requestedLanguage;
			}

			const defaultLanguage = languageList.find(
				i => i.language.toLowerCase() == this.language_enum['DEFAULT'].toLowerCase()
			);
			if (defaultLanguage) {
				return defaultLanguage;
			}

			const fallbackLanguage = languageList.find(
				i => i.language.toLowerCase() == this.language_enum['FALLBACK'].toLowerCase()
			);
			if (fallbackLanguage) {
				return fallbackLanguage;
			}

			if (languageList[0]) {
				return languageList[0];
			}
		}
		// TODO: il metodo viene usato spesso per puntare ad una prop. di T. In mancanza degli elementi in lingua restituisce {}
		// in modo che l'applicazione non vada in errore. Valutare gli effetti collaterali di questa scelta.
		return <T>{};
	}

	returnCreationMapWithProjectLanguageListOptionList(
		creationMap: FormControlMultipurposeModel.Item[]
	): FormControlMultipurposeModel.Item[] {
		creationMap = _.cloneDeep(creationMap);
		const option_list: FormControlMultipurposeModel.OptionList[] = [];
		for (const l of this.languages) {
			option_list.push({
				label: `language.${l}`,
				value: l
			});
		}
		const i: number = this.utilService.getElementIndex(creationMap, 'name', 'language');
		if (i || i === 0) {
			creationMap[i].option_list = option_list;
		}
		return creationMap;
	}

	getImageWithLanguage(article: ArticlePouchModel): AttachedFile {
		let language = this.getTranslationFromLanguage(article.articleDescription.language_list);
		const languageToTry = [this.language_enum['DEFAULT'], this.language_enum['FALLBACK']];
		for (let i = 0; i <= languageToTry.length; i++) {
			if (language && language.image_list && language.image_list.length) {
				const image = language.image_list.find(image => image.is_main_of_list);
				return image || language.image_list[0];
			} else {
				language = languageToTry[i]
					? this.getTranslationFromLanguage(article.articleDescription.language_list, languageToTry[i], true)
					: null;
			}
		}
		return null;
	}

	getLanguageItemWithImageList(article: ArticlePouchModel): LanguageItem {
		const languageToTryAll = [
			this.translate.currentLang,
			this.language_enum['DEFAULT'],
			this.language_enum['FALLBACK'],
			...this.languages
		];
		const languageToTry: string[] = [];
		for (const lang of languageToTryAll) {
			if (
				this.language_enum['DEFAULT'] &&
				this.languages.includes(this.language_enum['DEFAULT']) &&
				this.language_enum['FALLBACK'] &&
				this.languages.includes(this.language_enum['FALLBACK']) &&
				!languageToTry.includes(lang)
			) {
				languageToTry.push(lang);
			}
		}
		for (const lang of languageToTry) {
			const languageItem =
				this.getTranslationFromLanguage(article.articleDescription.language_list, lang, true);
			if (languageItem && languageItem.image_list && languageItem.image_list.length > 0) {

				languageItem.image_list =
					languageItem.image_list.sort((a, b) =>
						Number(b.is_main_of_list ? true : false) -
						Number(a.is_main_of_list ? true : false)
					);

				return languageItem;
			}
		}
		return null;
	}
}
