<form [formGroup]="form" #formTemplate class="michelangelo-theme">
	<mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
		<ng-container content>
			<div class="row">
				<form-control-multipurpose
					*ngFor="
						let field of formFieldList
							| filterByAllTheBooleans
								: {
										propertyListName: ['on_form_template'],
										value: true
								  }
					"
					[formControlName]="field.name"
					[field]="field"
					class="col-md-{{ field.column_width ? +field.column_width : 12 }}"
				></form-control-multipurpose>
			</div>
		</ng-container>

		<ng-container actions>
			<form-button-bar
				*ngIf="canEdit"
				[align]="'right'"
				[primaryButtonIsDisabled]="!canEdit || !form.valid"
				[primaryButtonLabel]="'general.save' | translate | sentencecase"
				(primaryButtonClickEmitter)="saveContact()"
				[secondaryButtonLabel]="'general.close' | translate | sentencecase"
				(secondaryButtonClickEmitter)="dialogRef.close()"
			></form-button-bar>
		</ng-container>
	</mat-dialog-wrapper>
</form>
