import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsOrganization } from '../../model/statistics-organization.model';

export enum StatisticsOrganizationActionEnum {
	LOAD = '[statistics-organization] Load',
	UPDATE = '[statistics-organization] Update',
	RESET = '[statistics-organization] Reset',
	ERROR = '[statistics-organization] Error'
}

export namespace StatisticsOrganizationStateAction {
	export const load = createAction(StatisticsOrganizationActionEnum.LOAD, props<BaseStateModel<any>>());
	export const update = createAction(
		StatisticsOrganizationActionEnum.UPDATE,
		props<BaseStateModel<StatisticsOrganization>>()
	);
	export const reset = createAction(StatisticsOrganizationActionEnum.RESET);
	export const error = createAction(StatisticsOrganizationActionEnum.ERROR, props<BaseStateModel<any>>());
}
