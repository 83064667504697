import { AdditionalServiceStateModel, AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { BaseStateModel, FormDynamicConfigurationModel, SentencecasePipe, SideBarPositionValues, SubscribeManagerService } from '@saep-ict/angular-core';
import { Component, OnDestroy } from '@angular/core';
import { UtilBreadcrumbService } from "../../../service/util/util-breadcrumb.service";
import { Store } from '@ngrx/store';
import { Observable, filter, map } from 'rxjs';
import { StateFeature } from '../../../state';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../constants/subscribe-manager.constant';
import { AdditionalServiceStateAction, AdditionalServiceActionEnum } from '../../../state/additional-service/additional-service.action';
import * as ConfigurationCustomerAdditionalService from '../../../constants/configuration-customer/additional-service/additional-service-creation-field-map.constant';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
	selector: 'spin8-additional-service-configuration',
	templateUrl: './additional-service-configuration.component.html',
	styleUrls: ['./additional-service-configuration.component.css'],
	providers: [SubscribeManagerService]
})
export class AdditionalServiceConfigurationComponent implements OnDestroy {

	additionalService$: Observable<BaseStateModel<AdditionalServiceStateModel>> = this.store.select(StateFeature.getAdditionalService);
	additionalService: AdditionalServiceStateModel;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'additional-service-data', observable: this.subscribeAdditionalServiceData() }
	];

	sideBarPositionValues = SideBarPositionValues;

	formDynamicConfiguration: FormDynamicConfigurationModel;
	serviceTypeSaved: string;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private snackBar: MatSnackBar,
		private sentencecasePipe: SentencecasePipe,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.store.dispatch(AdditionalServiceStateAction.loadAll());
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('additional_service_configuration');
		this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle("'optional subtitle'");
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['additional_service_configuration']);
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnDestroy(): void {
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	subscribeAdditionalServiceData(): Observable<void> {
		return this.additionalService$.pipe(
			filter(e =>
				!!(
					e &&
					(
						e.data ||
						e.type === AdditionalServiceActionEnum.SAVE_COMPLETE
					)
				)
			),
			map(e => {
				switch (e.type) {
					case AdditionalServiceActionEnum.ERROR:
						throw new Error(AdditionalServiceActionEnum.ERROR);
					case AdditionalServiceActionEnum.UPDATE:
						this.formDynamicConfiguration = {
							creationFieldMap: ConfigurationCustomerAdditionalService.creationFieldMap,
							formValue: {
								stock_type: e.data.stock_type
							},
							emitAlsoNonValidForm: false
						};
						break;
					case AdditionalServiceActionEnum.SAVE_COMPLETE:
						const message =
							this.sentencecasePipe.transform(
								this.utilTranslateService.translate.instant(
									`additional_service.save_configuration.${this.serviceTypeSaved}`
								)
							);
						this.snackBar.open(message, null, { duration: 3000 });
						this.serviceTypeSaved = null;
						break;
				}
			})
		);
	}

	async onFormDynamicValueChange(e, service_type: string) {
		if (e) {
			this.serviceTypeSaved = service_type;
			this.store.dispatch(AdditionalServiceStateAction.save({
				service_type: service_type,
				data: e.stock_type
			}));
		}
	}
}
