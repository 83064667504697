import {
	BaseStateModel,
	DateMomentService,
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatSelectChange } from '@angular/material/select';
import _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Statistics, StatisticsQuarterData } from '../../model/statistics.model';
import { StateFeature } from '../../state';
import { CardDashboardConfig } from '../../widget/card-dashboard/card-dashboard.component';
import { AppUtilService } from '../util/app-util.service';
import { chartTimeRange30Days, chartTimeRange7Days, ContextApplicationItemCodeEnum, ROUTE_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';

export class ChartBarStackedSerie {
	label: string;
	data: number[];
	backgroundColor?: string;
	barPercentage?: number;
}

export class QuarterList {
	q: string;
	y: number;
}

export class BaseTableClass {
	// store
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	context: ContextApplicationItemCodeEnum;

	statistics$: Observable<BaseStateModel<Statistics>>;
	statistics: Statistics;

	// config - generali

	fullDataSetDays7 = [];
	fullDataSetDays15 = [];
	fullDataSetDays30 = [];
	fullDataSetDays60 = [];
	fullDataSetDays90 = [];

	currentDataSet: any[] = [];
	listData = <LocalListHandlerBaseModel<any>>{
		pagination: {
			pageSize: 5
		},
		data: []
	};

	columns: ITdDataTableColumn[];
	timeRange = chartTimeRange30Days;

	// config - specifiche

	cardDashboardConfigClientsTop5: CardDashboardConfig = {
		title: 'chart.clients_top_x_table.title',
		titleTranslateParams: { amount: '5' },
		subtitle: 'chart.clients_top_x_table.subtitle',
		helpText: 'chart.clients_top_x_table.helptext',
		icon: 'work',
		detailLink: ROUTE_URL.dashboardClients,
		rangeTempList: this.timeRange
	};
	cardDashboardConfigFulfilledProductsTop5: CardDashboardConfig = {
		title: 'chart.products_top_x.title',
		subtitle: 'chart.products_top_x.subtitle',
		helpText: 'chart.products_top_x.helptext',
		icon: 'shopping_cart'
	};
	cardDashboardConfigCategoriesTop5: CardDashboardConfig = {
		title: 'chart.categories_top_x.title',
		subtitle: 'chart.categories_top_x.subtitle',
		helpText: 'chart.categories_top_x.helptext',
		icon: 'shopping_cart'
	};

	constructor(
		public utilService: AppUtilService,
		public store: Store<any>,
		public route: ActivatedRoute,
		public dateMomentService?: DateMomentService
	) {
		this.getStatistics();
	}

	/**
	 * Functions
	 */

	populateQuarterList() {}
	createDataSerie() {}
	populateTable() {}

	/**
	 * Funzione per svuotare l'array di dati.
	 * Attenzione: assegnare una quadra vuota non fa funzionare l'aggiornamento del grafico
	 * @param list Array to empty
	 */
	removeData(list) {
		list.length = 0;
	}

	/**
	 * Funzione per popolare l'array di dati
	 * Attenzione: assegnare l'oggetto direttamente non fa funzionare l'aggiornamento del grafico
	 * @param list array to populate
	 * @param listToAdd array to copy in the list param
	 */
	addData(list, listToAdd) {
		list.push(...listToAdd);
	}

	hoverStyle() {
		return {
			// se false il punto si evidenza se si passa sull'asse x
			intersect: false
		};
	}

	layoutStyle() {
		return {
			padding: 4
		};
	}

	elementsStyle() {
		return {
			point: {
				radius: 0
			}
		};
	}

	/**
	 * Funzione necessaria a popolare la dropdown sotto il grafico
	 * @param list Lista dalla quale recuperare la lista da visualizzare nel dropdown della scelta temporale
	 */
	getQuarterList(list: StatisticsQuarterData[]): QuarterList[] {
		let quarterList: QuarterList[] = [];
		list.forEach(item => quarterList.push({ q: item.name, y: item.year }));
		return quarterList;
	}

	// TODO: trasferire in utilChartService (?)
	changeTimeRange(e: MatSelectChange, serviceContext) {
		serviceContext.populateChart(e.value);
	}

	getStatistics() {
		switch (this.route.snapshot.routeConfig.path) {
			case ROUTE_URL.dashboardOrders:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailOrders);
				break;
			case ROUTE_URL.dashboardSold:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailSold);
				break;
			case ROUTE_URL.dashboardExpired:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailExpired);
				break;
			case ROUTE_URL.dashboardClients:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailClients);
				break;
			default:
				this.user$
					.pipe(
						filter(res => !!(res && res.data)),
						take(1)
					)
					.subscribe(res => {
						this.context = res.data.current_permission.context_application;
						switch (this.context) {
							case ContextApplicationItemCodeEnum.AGENT:
								this.statistics$ = this.store.select(StateFeature.getStatisticsAgent);
								break;
							case ContextApplicationItemCodeEnum.B2B:
								this.statistics$ = this.store.select(StateFeature.getStatisticsOrganization);
								this.timeRange = chartTimeRange7Days;
								break;
							case ContextApplicationItemCodeEnum.BACKOFFICE:
								this.statistics$ = this.store.select(StateFeature.getStatisticsBackoffice);
								break;
						}
					});
				break;
		}
		if (this.statistics$) {
			this.statistics$
				.pipe(
					filter(state => !!(state && state.data)),
					take(1)
				)
				.subscribe(res => {
					this.statistics = res.data;
					this.populateQuarterList();
					this.createDataSerie();
					this.populateTable();
				});
		}
	}
}
