<!-- [pathList]="[action?.organization?.business_name + ' - ' + action?.contact_name]" -->
<top-bar
	[goBackButton]="true"
	[goBackPath]="backPath"
	[badgeContent]="action && action.status ? ('action.status.' + action.status | translate | sentencecase) : null"
>
</top-bar>

<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<form class="michelangelo-theme" *ngIf="form" [formGroup]="form">
					<div class="row">
						<!-- Contatto -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>
									{{ 'action.field.referent' | translate | sentencecase }}
									<!-- *ngIf="getFormControl('contact').errors?.required" -->
									<span>*</span>
								</mat-label>
								<input matInput [matAutocomplete]="auto" formControlName="contact" />
								<button
									*ngIf="getFormControl('contact').value"
									matSuffix
									mat-icon-button
									aria-label="Clear"
									(click)="getFormControl('contact').setValue('')"
								>
									<mat-icon>close</mat-icon>
								</button>
								<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
									<mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
										<span>{{ getFullname(contact) }}</span>
										<small>{{
											contact['organization'] ? ' | ' + contact['organization'].business_name : ''
										}}</small>
									</mat-option>
								</mat-autocomplete>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('contact')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- assegnee -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>
									{{ 'action.field.assegnee' | translate | sentencecase }}
									<span>*</span>
								</mat-label>
								<input matInput formControlName="assegnee" readonly />
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Edit"
									(click)="openDialogSelectAssegnee()"
								>
									<!-- *ngIf="!action || (action && action.status != ActionStatusEnum.E)" -->
									<mat-icon>edit</mat-icon>
								</button>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('assegnee')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Data inizio -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'action.field.start_date' | translate | sentencecase }} *</mat-label>
								<input matInput formControlName="start_date" type="datetime-local" />
								<!-- <input
									matInput
									readonly
									formControlName="start_date"
									[matDatepicker]="startDatePicker"
									(click)="startDatePicker.open()"
								/> -->
								<!-- <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
								<mat-datepicker #startDatePicker></mat-datepicker> -->
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('start_date')"
									></form-error-message>
								</mat-error>
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Clear"
									*ngIf="(!action || (action && !action.close_date)) && form.get('start_date').value"
									(click)="resetSelectField($event, 'start_date')"
								>
									<mat-icon>clear</mat-icon>
								</button>
							</mat-form-field>
						</div>

						<!-- Orario inizio -->
						<!-- <div class="col-md-6">
							<mat-form-field>
								<input matInput type="datetime-local" placeholder="start date" />
							</mat-form-field>
						</div> -->

						<!-- Data fine -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'action.field.close_date' | translate | sentencecase }}</mat-label>
								<input matInput formControlName="close_date" type="datetime-local" />
								<!-- <input
									matInput
									readonly
									formControlName="close_date"
									[matDatepicker]="closeDatePicker"
									(click)="closeDatePicker.open()"
								/>
								<mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
								<mat-datepicker #closeDatePicker></mat-datepicker> -->
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('close_date')"
									></form-error-message>
								</mat-error>
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Clear"
									*ngIf="(!action || (action && !action.close_date)) && form.get('close_date').value"
									(click)="resetSelectField($event, 'close_date')"
								>
									<mat-icon>clear</mat-icon>
								</button>
							</mat-form-field>
						</div>

						<!-- Tipo azione -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label
									>{{ 'action.field.type' | translate | sentencecase }}
									<span>*</span>
								</mat-label>
								<mat-select formControlName="action_type" (selectionChange)="actionTypeChange($event)">
									<mat-select-trigger>
										<mat-icon>{{ getFormControl('action_type').value.icon }}</mat-icon>
										{{
											'action.type.' + getFormControl('action_type').value.code
												| translate
												| sentencecase
										}}
									</mat-select-trigger>
									<mat-option *ngFor="let actionType of ActionTypes" [value]="actionType">
										<mat-icon>{{ actionType.icon }}</mat-icon
										>{{ 'action.type.' + actionType.code | translate | sentencecase }}
									</mat-option>
								</mat-select>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('action_type')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Data chiusura stimata -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{
									'action.field.estimated_close_date' | translate | sentencecase
								}}</mat-label>
								<input matInput formControlName="estimated_close_date" type="datetime-local" />
								<!-- <input
									matInput
									readonly
									formControlName="estimated_close_date"
									[matDatepicker]="estimatedCloseDatePicker"
									(click)="estimatedCloseDatePicker.open()"
								/>
								<mat-datepicker-toggle
									matSuffix
									[for]="estimatedCloseDatePicker"
								></mat-datepicker-toggle>
								<mat-datepicker #estimatedCloseDatePicker></mat-datepicker> -->
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('estimated_close_date')"
									></form-error-message>
								</mat-error>
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Clear"
									*ngIf="
										(!action || (action && !action.close_date)) &&
										form.get('estimated_close_date').value
									"
									(click)="resetSelectField($event, 'estimated_close_date')"
								>
									<mat-icon>clear</mat-icon>
								</button>
							</mat-form-field>
						</div>

						<!-- Separatore -->
						<!-- <div class="col-12"></div> -->

						<!-- Testo -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'action.field.text' | translate | sentencecase }}</mat-label>
								<textarea matInput formControlName="text" rows="1"></textarea>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('text')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Risposta -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'action.field.answer' | translate | sentencecase }}</mat-label>
								<textarea matInput formControlName="answer" rows="1"></textarea>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('answer')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Separatore -->
						<!-- <div class="col-12"></div> -->

						<!-- Richiesta -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label> {{ 'action.field.opportunity' | translate | sentencecase }}</mat-label>
								<input matInput formControlName="opportunity" readonly />
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Edit"
									(click)="openDialogSelectOpportunity()"
								>
									<!-- *ngIf="!action || (action && action.status != ActionStatusEnum.E)" -->
									<mat-icon>edit</mat-icon>
								</button>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('opportunity')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Stato -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'action.field.status' | translate | sentencecase }}</mat-label>
								<mat-select formControlName="status">
									<mat-option
										*ngFor="let statusType of ActionStatusEnum | enumIterator"
										[value]="statusType.id"
									>
										{{ 'action.status.' + statusType.description | translate | sentencecase }}
									</mat-option>
								</mat-select>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('status')"
									></form-error-message>
								</mat-error>
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Clear"
									*ngIf="form.get('status').value"
									(click)="resetSelectField($event, 'status')"
								>
									<mat-icon>clear</mat-icon>
								</button>
							</mat-form-field>
						</div>

						<!-- Email -->
						<div class="row"></div>
						<div class="col-md-12" *ngIf="action && action.action_type === actionTypeEnum.ML">
							<mat-form-field>
								<mat-label>
									{{ 'action.field.email' | translate | sentencecase }}
								</mat-label>
								<input matInput formControlName="subject" />
							</mat-form-field>
						</div>

						<!-- Utente di inserimento -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'Utente di inserimento' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="added_by" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('added_by')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
					</div>

					<!--  Footer -->
					<div class="row">
						<!-- Separatore -->
						<!-- <div class="col-12"></div> -->

						<!-- <div class="col-12">
							<p class="opacity-50" *ngIf="action && action.date_creation">
								Aperta il {{ action.date_creation | date: 'dd/MM/yyyy HH:mm' }}
							</p>

							<p class="opacity-50" *ngIf="action && action.close_date">
								Chiusa il {{ action.close_date | date: 'dd/MM/yyyy HH:mm' }}
							</p>
						</div> -->

						<!-- *ngIf="!action || (action && action.status != ActionStatusEnum.E)" -->
						<mat-toolbar>
							<!-- Salva -->
							<!-- *ngIf="!action || (action && action.status != ActionStatusEnum.E)" -->
							<button
								mat-raised-button
								[color]="action && action.action_type === actionTypeEnum.ML ? null : 'primary'"
								(click)="saveAction()"
								class="mr-3"
							>
								{{ 'general.save' | translate | sentencecase }}
							</button>
							<button
								mat-raised-button
								class="mr-3"
								(click)="sendAction()"
								*ngIf="action && action.action_type === actionTypeEnum.ML"
								color="primary"
								[disabled]="!action.code_item"
							>
								{{ 'action.send' | translate | sentencecase }}
							</button>
							<!-- Promuovi -->
							<!-- <button
								mat-stroked-button
								type="button"
								class="mr-3"
								(click)="changeStatusActionTo(actionLevelEnum.PROSPECT)"
								*ngIf="
									action &&
									action._id &&
									route.parent.snapshot.paramMap.get('actionLevel') === actionLevelEnum.LEAD &&
									!action.close_date
								"
							>
								{{ 'action.promote' | translate | sentencecase }}
							</button> -->
							<!-- Chiudi action -->
							<!-- <button
								mat-stroked-button
								type="button"
								(click)="openDialogCloseAction()"
								*ngIf="action && action._id && !action.close_date"
							>
								{{
									(route.parent.snapshot.paramMap.get('actionLevel') === actionLevelEnum.LEAD
										? 'request.close'
										: 'action.close'
									)
										| translate
										| sentencecase
								}}
							</button> -->

							<!-- <form-button-bar
								[align]="'left'"
								[primaryButtonLabel]="
								canSaveForm
										? ('general.save' | translate | sentencecase)
										: null
								"
								(primaryButtonClickEmitter)="onFormSubmit()"
								[secondaryButtonLabel]="
									canPromoteForm
										? ('action.promote' | translate | sentencecase)
										: null
								"
								(secondaryButtonClickEmitter)="changeStatusActionTo(actionLevelEnum.PROSPECT)"
								[otherButtonLabel]="
									action && action._id && !action.close_date
										? ((route.parent.snapshot.paramMap.get('actionLevel') ===
										  actionLevelEnum.LEAD
												? 'request.close'
												: 'action.close'
										  )
										  | translate
										  | sentencecase)
										: null
								"
								(otherButtonClickEmitter)="openDialogCloseAction()"
							></form-button-bar> -->
						</mat-toolbar>
					</div>
				</form>
			</mat-card>
		</div>
	</div>
</div>
