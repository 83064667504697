<ng-container *ngFor="let item of breadcrumb; let last = last">
	<span *ngIf="item.path !== breadcrumb[0].path">></span>
	<button
		mat-button
		(click)="
			!last ?
			emitSelectedPath(item.path) :
			null
		"
		[disabled]="last"
	>
		{{ item.label }}
	</button>
</ng-container>