import { ITdDataTableColumn } from "@covalent/core/data-table";
import _ from "lodash";
import * as ConfigurationContactColumnMapBase from "./contact-column-map-base.constant";
import { AngularCoreUtilService, ITdDataTableColumnCustom } from "@saep-ict/angular-core";
import { Injector } from "@angular/core";

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

const action: ITdDataTableColumnCustom = 
	{
		name: 'actions',
		label: '',
		width: 100,
		numeric: true,
		sortable: false
	};

const orderConfirmEmail: ITdDataTableColumnCustom = 
	{
		name: 'order_confirm_email',
		labelPath: 'contact.field.order_confirm_email',
		label: null,
		width: 170
	};	

const dateCreation: ITdDataTableColumnCustom = 
	{
		name: 'date_creation',
		labelPath: 'contact.field.date_creation',
		label: null
	};

const utilService = injector.get(AngularCoreUtilService);
const newColumnList = [...ConfigurationContactColumnMapBase.baseColumns];
const businessNameIndex = utilService.getElementIndex(newColumnList, 'name', 'organization.business_name');
newColumnList.splice(businessNameIndex + 1, 0, dateCreation, orderConfirmEmail, action);

export const projectOverride: ITdDataTableColumn[] = 
_.cloneDeep(newColumnList);