import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import { OrganizationListFilterCreationFieldMapFormValue, OrganizationListFilterCreationFieldMapSelectOptionMap } from "../../../../../model/organization.model";
import _ from "lodash";
import { OrganizationPouchModel } from "@saep-ict/pouch_agent_models";
import { returnConfigurationCustomerOrganizationListFilterByFormFieldStatus, returnCreationFieldMapWithOptionOrganizationStatus } from "./organization-list-filtercreation-field-map.util.constant";

export const request = [
	'Medici ortopedici', 
	'Fisioterapista', 
	'Fisiatra', 
	'Specializzandi', 
	'Infermieri' 
];

// Add options for organization_type filter
export const returnCreationFieldMapWithOptionOrganizationType = (
	creationFieldMap: FormControlMultipurposeModel.Item[],
	selectOptionMap: OrganizationListFilterCreationFieldMapSelectOptionMap
): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const organization_organizationTypeField = creationFieldMap.find(i => i.name === 'organization_type');
			organization_organizationTypeField.option_list = selectOptionMap.organization_type.map(i => {
				return { value: i };
			});
			resolve(creationFieldMap);
		} catch (e) {
			throw new Error(e);
		}
	});
};

// filter - organization type
export const returnConfigurationCustomerOrganizationListFilterByFormFieldOrganizationTypeList = (
	organizationList: OrganizationPouchModel[],
	formValue: OrganizationListFilterCreationFieldMapFormValue
): Promise<OrganizationPouchModel[]> => {
	return new Promise(resolve => {
		try {
			if (organizationList && organizationList.length && formValue) {
				if (formValue.organization_type) {
					const filteredOrganizationTypeList = [];
					for (const organization of organizationList) {
						if (organization.organization_type === formValue.organization_type) {
							filteredOrganizationTypeList.unshift(organization);
						}
					}
					organizationList = filteredOrganizationTypeList;
				}
			}
			resolve(organizationList);
		} catch (e) {
			throw new Error(e);
		}
	});
};

// filter organization_class - per Sicseg
export const returnConfigurationCustomerOrganizationListFilterByFormFieldOrganizationClassification = (
	organizationList: OrganizationPouchModel[],
	formValue: OrganizationListFilterCreationFieldMapFormValue
): Promise<OrganizationPouchModel[]> => {
	return new Promise(resolve => {
		try {
			if (organizationList && organizationList.length && formValue) {
				if (formValue.organization_class) {
					const filteredOrganizationClassificationList = [];
					for (const organization of organizationList) {
						if (organization.organization_class === formValue.organization_class) {
							filteredOrganizationClassificationList.unshift(organization);
						}
					}
					organizationList = filteredOrganizationClassificationList;
				}
			}
			resolve(organizationList);
		} catch (e) {
			throw new Error(e);
		}
	});
};


// methods to pass in index for add options and filter custom
export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
    
    {
		name: 'status',
		on_form_template: true,
		label: 'organization.field.status',
		type: FormControlMultipurposeEnum.ItemType.SELECT
	},
    {
		name: 'organization_type',
		on_form_template: true,
		label: 'organization.typology',
		type: FormControlMultipurposeEnum.ItemType.SELECT
	},
	// Gestione filtro per organization_class in Sicseg
	{
		name: 'organization_class',
		on_form_template: true,
		label: 'theme.organization.field.class',
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		option_list: 
			request.map( option => {
				return { value: option }
			})		
	}
];

export const returnConfigurationCustomerOrganizationListFilterCreationFieldMapDefault = async (
	selectOptionMap: OrganizationListFilterCreationFieldMapSelectOptionMap
): Promise<FormControlMultipurposeModel.Item[]> => {
	try {
		let creationFieldMapToReturn = _.cloneDeep(creationFieldMap);
		creationFieldMapToReturn = await returnCreationFieldMapWithOptionOrganizationStatus(
			creationFieldMapToReturn,
			selectOptionMap
		);
		creationFieldMapToReturn = await returnCreationFieldMapWithOptionOrganizationType(
			creationFieldMapToReturn,
			selectOptionMap
		);
		return creationFieldMapToReturn;
	} catch (e) {
		throw new Error(e);
	}
};

export const returnConfigurationCustomerOrganizationListFilterByFormValueDefault = async (
	organizationList: OrganizationPouchModel[],
	formValue: OrganizationListFilterCreationFieldMapFormValue
): Promise<OrganizationPouchModel[]> => {
	try {
		if (organizationList && organizationList.length && formValue) {
			organizationList = await returnConfigurationCustomerOrganizationListFilterByFormFieldStatus(
				organizationList,
				formValue
			);
			organizationList = await returnConfigurationCustomerOrganizationListFilterByFormFieldOrganizationTypeList(
				organizationList,
				formValue
			);
			organizationList = await returnConfigurationCustomerOrganizationListFilterByFormFieldOrganizationClassification(
				organizationList,
				formValue
			);
		}
		return organizationList;
	} catch (err) {
		throw new Error(err);
	}
};


