import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { BaseState, BaseStateModel, SentencecasePipe, RestBasePk, RestBaseMessageError } from '@saep-ict/angular-core';
import { UserNotificationActionEnum, UserNotificationStateAction } from './user-notification.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
	AngularSpin8CoreUserService,
	PouchDbModel,
	UserNotificationModel
} from '@saep-ict/angular-spin8-core';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { StateFeature } from '..';
import * as ConfigurationCustomerAppStructure from '../../constants/configuration-customer/app-structure/app-structure.constant';
import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';


@Injectable()
export class UserNotificationEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserNotificationActionEnum.LOAD_NOTIFICATION_USER),
			mergeMap(() => from(this.getDoc())),
			map((action: BaseStateModel<UserNotificationModel>) => UserNotificationStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(UserNotificationStateAction.error(error));
				return caught;
			})
		)
	);


	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserNotificationActionEnum.SAVE_NOTIFICATION_USER),
			mergeMap((action: BaseStateModel<UserNotificationModel>) => from(this.putDoc(action.data))),
			map((action: BaseStateModel<UserNotificationModel>) => UserNotificationStateAction.saveComplete(action)),
			catchError((error, caught) => {
				this.store.dispatch(UserNotificationStateAction.error(error));
				return caught;
			})
		)
	);


	saveComplete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserNotificationActionEnum.SAVE_NOTIFICATION_COMPLETE),
			map((action: BaseStateModel<UserNotificationModel>) => UserNotificationStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(UserNotificationStateAction.error(error));
				return caught;
			})
		)
	);


	async putDoc(data: UserNotificationModel): Promise<BaseStateModel<UserNotificationModel>> {
		return await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.USER_NOTIFICATION, { accessCommons: true })).basePouch
			.saveBodyTable(data)
			.then(async (userNotification: UserNotificationModel) => {
				return new BaseState(userNotification);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}


	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getDoc() {
		let code_item;
		this.store
			.select(StateFeature.getUserState)
			.pipe(take(1))
			.subscribe(res => {
				code_item = res ? res.data.id : null;
			});
		return await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.USER_NOTIFICATION, { accessCommons: true })).basePouch
		.getDetail(`user_notification${ConfigurationCustomerAppStructure.noSqlDocSeparator}${code_item}`)
			.then((doc: any) => {
				return new BaseState(doc);
			})
			.catch(err => {
				console.log("ERROR ACTION DATA");
				throw new Error(err.message);
			});
	}

}
