import { AttachedFile } from "@saep-ict/pouch_agent_models";

// in caso fosse necessario in futuro rinominare il file
// const myNewFile = new File([file], 'new_name.xls', {type: file.type});

/**
 * Restituisce il valore del primo file name duplicato trovato, ponendo a confronto fra di loro gli elementi di `fileList`
 * e ogni elemento di `fileList` con gli elementi di `attachedList`
 *
 * @param fileList: tipicamente file selezionati
 * @param attachedList: tipicamente file precedentemente salvati
 * @returns
 */
export const isPresentDuplicate = (
  fileList: File[],
  attachedList: AttachedFile[]
): Promise<string> => {
  return new Promise(resolve => {
    try {
      let res: string;
      for (let file of fileList) {
        const fileListDuplicated = fileList.filter(i => i.name === file.name);
        const attachedItemDuplicated = attachedList.find(i => i.name === file.name);
        if (fileListDuplicated.length > 1 || attachedItemDuplicated) {
          res = file.name;
          break;
        }
      }
      resolve(res);
    } catch (err) {
      throw new Error(err);
    }
  });
};

/**
 * Restituisce il valore del primo file name che includa caratteri non ammessi da `regex`
 * @param fileList
 * @param regex
 * @returns
 */
export const isPresentCharacterNotAccepted = (
  fileList: File[],
  regex: RegExp
): Promise<string> => {
  return new Promise(resolve => {
    try {
      let res: string;
      if (regex) {
        for (let file of fileList) {
          if (!regex.test(file.name)) {
            res = file.name;
            break;
          }
        }
      }
      resolve(res);
    } catch (err) {
      throw new Error(err);
    }
  });
};

