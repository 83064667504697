import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { PageSettingsModel } from '../../../../../model/structure/AbstractListModel';

export class AssetRequestCenterPouch extends AbstractPouchdbMethod {
	loadTicketFilteredList(data: PageSettingsModel): Promise<any> {
		return new Promise(resolve => {
			const filterSelector: PouchDB.Find.Selector = this.getFilterSelector(data);
			const sortSelector: {
				[key: string]: 'asc' | 'desc';
			}[] = this.getSortSelector(data.sort);

			this.getWithIndexFilter('request-center', filterSelector, data.pagination, sortSelector)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getSortSelector(sort) {
		let sortSelector = [];

		if (sort && sort.length) {
			const sortItem = {};
			sortItem[sort[0].name] = sort[0].order;
			sortSelector = [sortItem];
		}

		return sortSelector;
	}

	getFilterSelector(data) {
		const selector = {
			$and: [
				{
					// gestione temporanea, in attesa di sostituire le find con allDocs
					type: data.filters.types[0]
				}
			]
		} as PouchDB.Find.Selector;

		// filters
		const filters = data.filters;
		if (filters) {
			// FO - filtro su specifico request.company.client_code
			if (filters.company && filters.company.length > 0) {
				const s = {
					request: {
						company: {
							client_code: filters.company
						}
					}
				};
				selector.$and.push(s);
			}
			// BO ALL - filtro regex
			if (filters.text && !filters.company) {
				const s = {
					$or: [
						{
							request: {
								company: {
									client_code: {
										$regex: `(?i)${filters.text}`
									}
								}
							}
						},
						{
							request: {
								company: {
									business_name: {
										$regex: `(?i)${filters.text}`
									}
								}
							}
						},
						{
							request: {
								subject: {
									$regex: `(?i)${filters.text}`
								}
							}
						},
						{
							request: {
								assignee: {
									$regex: `(?i)${filters.text}`
								}
							}
						},
						{
							_id: {
								$regex: `(?i)${filters.text}`
							}
						}
					]
				};
				selector.$and.push(s);
			}
			// FO - filtro regex
			if (filters.text && filters.company) {
				const s = {
					$or: [
						{
							_id: {
								$regex: `(?i)${filters.text}`
							}
						},
						{
							request: {
								subject: {
									$regex: `(?i)${filters.text}`
								}
							}
						}
					]
				};
				selector.$and.push(s);
			}
			// sidebar
			if (filters.status_list && filters.status_list.length > 0) {
				const s = {
					status: {
						$in: filters.status_list
					}
				};
				selector.$and.push(s);
			}
			if (filters.asset_type) {
				const s = {
					asset_list: {
						$elemMatch: {
							type: {
								id: filters.asset_type
							}
						}
					}
				};
				selector.$and.push(s);
			}
			if (filters.urgent) {
				const s = {
					request: {
						urgent: filters.urgent
					}
				};
				selector.$and.push(s);
			}
			if (filters.date_due && filters.date_due.start && filters.date_due.end) {
				const s = {
					request: {
						date_due: {
							$lte: filters.date_due.end,
							$gte: filters.date_due.start
						}
					}
				};
				selector.$and.push(s);
			}
			if (filters.date_request && filters.date_request.start && filters.date_request.end) {
				const s = {
					request: {
						date_request: {
							$lte: filters.date_request.end,
							$gte: filters.date_request.start
						}
					}
				};
				selector.$and.push(s);
			}
			if (filters.brandList && filters.brandList.length > 0) {
				const s = {
					asset_list: {
						$elemMatch: {
							brand: {
								code: {
									$in: filters.brandList
								}
							}
						}
					}
				};
				selector.$and.push(s);
			}
			if (filters.lineList && filters.lineList.length > 0) {
				const s = {
					asset_list: {
						$elemMatch: {
							line: {
								code: {
									$in: filters.lineList
								}
							}
						}
					}
				};
				selector.$and.push(s);
			}
		}

		return selector;
	}

	loadTicket(id: string): Promise<any> {
		return new Promise(resolve => {
			this.get(id)
				.then(thread => {
					resolve(thread);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
