import { Injectable } from '@angular/core';
import { LoaderService } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { Category, ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import * as ConfigurationCustomerStatistic from '../../constants/configuration-customer/statistic/statistic.constant';
import * as StatisticEnum from '../../enum/statistic.enum';
import * as StatisticModel from '../../model/statistics.model';
import { TrendDirectionValues } from '../../widget/box-trend-info/box-trend-info.component';
import * as ConfigurationCategory from '../../constants/category.constant';

@Injectable({
	providedIn: 'root'
})
export class UtilStatisticService {
	categoryTreeWithArticle: StatisticModel.Tree[];
	constructor(
		private loaderService: LoaderService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {}
	/**
	 * Genera il categoryTreeWithArticle di partenza, effettuando il merge tra:
	 * - treeParse: formattazione ad hoc dell'alberatura di categorie
	 * - articleList
	 * Il metodo va invocato soltanto una volta durante l'inizializzazione del componente. Non è necessario rigenerare i dati
	 * di cui si occupa durante l'applicazione di un nuovo documento di statistiche (es: cambio gruppo) o dei filtri.
	 */
	 async createDataStructureAndSource(
		user: UserDetailModel,
		categoryTree: Category[],
		articleList: ArticlePouchModel[],
		loaderGuid: string
	): Promise<StatisticModel.Tree[]> {
		try {
			const treeParse: Category[] =
				ConfigurationCustomerStatistic.returnNestedCategoryParse(user, categoryTree);
			const categoryTreeWithArticle =
				await ConfigurationCustomerStatistic.categoryTreeWithArticleParse(
					user,
					treeParse,
					articleList,
					this.utilTranslateService
				);
			this.loaderService.changeSingleLoader(loaderGuid);
			return categoryTreeWithArticle;
		} catch (err) {
			throw new Error(err);
		}
	}

	/**
	 * Genera dataSource.data (oggetto passato mat-tree), effettuando il merge tra catalogo e documento di statistiche:
	 * - ordina gli elementi del summary
	 * - elimina i rami relativi alle categorie passate in categoryToExlcude
	 * - resetta statisticSummary
	 * - copia sui nodi del catalogo il relativo elemento dei valori statistici
	 * - calcola il summary trend in base a ConfigurationCustomer
	 */
	async createDataSource(
		configuration: StatisticModel.NestedCategoryConfiguration,
		loaderGuid: string
	): Promise<StatisticModel.NestedCategoryConfiguration> {
		try {
			configuration.dataParse = {
				summary: _.cloneDeep(configuration.data.summary.sort((a, b) => a.sequence - b.sequence)),
				categoryTree: []
			};
			const categoryTreeWithArticle: StatisticModel.Tree[] =
				await this.returnCategoryTreeRecursivelyDeleteByCodeItemList(
					_.cloneDeep(this.categoryTreeWithArticle),
					configuration.filter.categoryToExlcude
				);

			configuration.dataParse.summary = await this.returnStatisticSummaryReset(configuration.data.summary);
			configuration.dataParse.categoryTree = await this.returnCategoryTreeWithStatistic(categoryTreeWithArticle, configuration);	
			ConfigurationCustomerStatistic.calculateSummaryTrend(configuration.dataParse.summary);
			this.loaderService.changeSingleLoader(loaderGuid);
			return configuration;
		} catch (err) {
			throw new Error(err);
		}
	}

	/**
	 * Restituisce l'alberatura di catalogo dopo aver ricorsivamente eliminato i rami relativi alla lista di code_item
	 * passati come parametro.
	 * @param tree 
	 * @param codeItemList 
	 * @returns 
	 */
	async returnCategoryTreeRecursivelyDeleteByCodeItemList(
		tree: StatisticModel.Tree[],
		codeItemList: string[]
	): Promise<StatisticModel.Tree[]> {
		try {
			tree = tree.filter(i => !codeItemList.includes(i.code_item));
			for (let i = 0; i < tree.length; i++) {
				if (tree[i].category_list && tree[i].category_list.length > 0) {
					tree[i].category_list =
						await this.returnCategoryTreeRecursivelyDeleteByCodeItemList(tree[i].category_list, codeItemList);
				}
			}
			return tree;
		} catch (err) {
			throw new Error(err);
		}
	}

	// category tree and statistic values merge
	/**
	 * Effettua il merge dei valori statistici nel relativo nodo di catalogo, aggiornando parallelamente statisticSummary:
	 * - ordina gli elementi del valore statistico in base al sequence indicato negli elementi di summary
	 * - cerca il nodo in base al match del code item del valore statistico
	 * - aggiorna statisticSummary
	 * @param tree 
	 * @param statistic 
	 * @returns 
	 */
	async returnCategoryTreeWithStatistic(
		tree: StatisticModel.Tree[],
		configuration: StatisticModel.NestedCategoryConfiguration
	): Promise<StatisticModel.Tree[]> {
		try {
			for (let i = 0; i < configuration.data.values.length; i++) {
				configuration.data.values[i].data.sort((a, b) =>
					configuration.data.summary.find(s => s.key === a.key).sequence -
					configuration.data.summary.find(s => s.key === b.key).sequence
				);
				const node: StatisticModel.Tree | StatisticModel.Article =
					await ConfigurationCategory.returnCategoryTreeNodeByCodeItem<StatisticModel.Tree>(tree, configuration.data.values[i].code_item);
				if (node) {
					node.statisticList = configuration.data.values[i].data;
					if (
						!(
							(
								node.article_list &&
								node.article_list.length > 0
							) ||
							(
								node.category_list &&
								node.category_list.length > 0
							)
						)
					) {
						configuration.dataParse.summary =
							await this.returnStatisticSummaryAddToTotal(
								configuration.data.values[i].data,
								configuration.dataParse.summary
							);
					}
				}
			}
			return tree;
		} catch (err) {
			throw new Error(err);
		}
	}

	/**
	 * Restituisce statisticSummary dopo aver aggiunto alle sue proprietà total i valori dell'elemento statistico
	 * @param list 
	 * @param statisticSummary 
	 * @returns 
	 */
	returnStatisticSummaryAddToTotal(list: StatisticModel.Base[], statisticSummary: StatisticModel.Base[]):
	Promise<StatisticModel.Base[]> {
		return new Promise(resolve => {
			try {
				for (let i = 0; i < list.length; i++) {
					const headingItem: StatisticModel.Base = statisticSummary.find(h => h.key === list[i].key);
					if (headingItem) {
						if (headingItem.total && list[i].total) {
							for (const itemUnit in StatisticEnum.Unit) {
								const itemUnitName: string = StatisticEnum.Unit[itemUnit];
								headingItem.total[itemUnitName] =
									list[i].total[itemUnitName] ?
									headingItem.total[itemUnitName] + list[i].total[itemUnitName] :
									headingItem.total[itemUnitName];								
							}
						}
					}
				}
				resolve(statisticSummary);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	// statistic summary
	// statistic summary reset
	/**
	 * Restituisce statisticSummary rimuovendo la referenza rispetto alla struttura passata come parametro
	 * azzerandone gli elementi.
	 * @param statisticSummary 
	 * @returns 
	 */
	async returnStatisticSummaryReset(
		statisticSummary: StatisticModel.Base[]
	): Promise<StatisticModel.Base[]> {
		try {
			statisticSummary = _.cloneDeep(statisticSummary);
			for (let i = 0; i < statisticSummary.length; i++) {
				statisticSummary[i] = await this.returnStatisticSummaryItemReset(statisticSummary[i]);
			}
			return statisticSummary;
		} catch (err) {
			throw new Error(err);
		}
	}

	/**
	 * Restituisce un elemento di statisticSummary con i valori associati a StatisticEnum.Item.Type azzerati
	 * @param item 
	 * @returns 
	 */
	async returnStatisticSummaryItemReset(
		item: StatisticModel.Base
	): Promise<StatisticModel.Base> {
		try {
			for (const itemType in StatisticEnum.Type) {
				const itemTypeName: string = StatisticEnum.Type[itemType];
				if (item[itemTypeName]) {
					item[itemTypeName] = await this.returnStatisticSummaryItemUnitReset(item[itemTypeName]);
				}
			}
			return item;
		} catch (err) {
			throw new Error(err);
		}
	}

	/**
	 * Restituisce un elemento di un elemento di statisticSummary con i valori associati a StatisticEnum.Unit azzerati
	 * @param item 
	 * @returns 
	 */
	returnStatisticSummaryItemUnitReset(item: StatisticModel.ValueType): Promise<StatisticModel.ValueType> {
		return new Promise(resolve => {
			try {
				for (const itemUnit in StatisticEnum.Unit) {
					const itemUnitName: string = StatisticEnum.Unit[itemUnit];
					item[itemUnitName] = 0;
				}
				resolve(item);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	returnTrandIndicator(value: number): TrendDirectionValues {
		if (!value || value === 0) { return TrendDirectionValues.STEADY }
		if (value > 0) { return TrendDirectionValues.UP }
		if (value < 0) { return TrendDirectionValues.DOWN }
	}

}
