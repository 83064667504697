import { Injectable } from '@angular/core';
import { CustomerAppConfig } from '../../customer-app.config';
import { AppUtilService } from './app-util.service';

@Injectable({
	providedIn: 'root'
})
export class BucketManagerService {
	constructor(
		private appConfig: CustomerAppConfig,
		private utilService: AppUtilService
	) {}

	returnBucketManagerDownloadUrl(fileName:string, pathUrl?: string | number, pathQueryParam?: string | number): string {
		const pathUrlToReturn = pathUrl ? `/${pathUrl}/` : '/';
		const pathQueryParamToReturn = pathQueryParam ? `/${pathQueryParam}/` : '/';
		return `${this.appConfig.config.bucketManager.be_url}${pathUrlToReturn}download-multipart?path=${pathQueryParamToReturn}${fileName}&token=${this.appConfig.token}`;
	}

	returnUrlWithCacheReset(url: string) {
		return url + '?cacheReset=' + this.utilService.guid();
	}

}
