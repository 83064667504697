import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'dialog-destination-select',
	templateUrl: './dialog-destination-select.component.html',
	styleUrls: ['./dialog-destination-select.component.scss']
})
export class DialogDestinationSelect {

	constructor(
		public dialogRef: MatDialogRef<DialogDestinationSelect>,
		@Inject(MAT_DIALOG_DATA) public data
	) {

	}

	onItemSelect(pickedAddress: AddressPouchModel) {
		this.dialogRef.close(pickedAddress);
	}
}
