import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const LOADER_SERVICE_TOKEN = new InjectionToken('LOADER_SERVICE');

export abstract class ILoaderService {
	loader$: Observable<boolean>;

	public abstract changeLoader(status: boolean): void;
	public abstract populateLoader(content: string): string;
	public abstract changeSingleLoader(id: string): void;
	public abstract guid(): string;
}
