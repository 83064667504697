import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

interface OptionItemStructure {
	label_list: string[];
	value: string;
}

@Component({
	selector: 'form-field-select',
	templateUrl: './form-field-select.component.html',
	styleUrls: ['./form-field-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: FormFieldSelectComponent
		}
	]
})
export class FormFieldSelectComponent implements OnInit, ControlValueAccessor {
	@Input() optionList: any[];
	@Input() optionItemStructure: OptionItemStructure;
	@Input() formControlName: string;
	@Input() form: FormGroup;
	@Input() label: string;
	@Input() searchLabel: string;
	@Input() explicitValue: string;
	@Input() set propertyListNameSettings(properties: string[]) {
		this.propertyListName = properties;
	}
	@Input() canFilter: boolean = false;
	propertyListName: string[];

	constructor() {}

	ngOnInit(): void {}

	writeValue(value: any) {
		// console.log('value', value);
		if (value) {
			// this.form.patchValue(value, { emitEvent: false });
		}
	}
	propagateChange = (_: any) => {};
	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}
	registerOnTouched(fn: any) {}
}
