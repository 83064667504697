import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import {
	StatisticsFulfilledData
} from '../../../model/statistics.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { ActivatedRoute } from '@angular/router';
import { DateMomentService } from '@saep-ict/angular-core';
import { AppUtilService } from '../../util/app-util.service';
import { MetricsDetail, StatisticsBaseSerieOrder } from '../../../model/statistics.model';
import { ChartEnum, chartTimeRange7Days, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class FulfilledQuantityChartConfigService extends BaseChartClass {
	metrics: MetricsDetail;
	quarterList: StatisticsFulfilledData[];
	fulfilledTrend;

	cardDashboardConfigFulfilledQuantity: CardDashboardConfig = {
		title: 'chart.fulfilled_quantity.title',
		subtitle: 'chart.fulfilled_quantity.subtitle',
		helpText: 'chart.fulfilled_quantity.helptext',
		theme: 'accent',
		icon: 'shopping_cart'
	};

	defaultTimeRange: number = chartTimeRange7Days.values[0];

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public route: ActivatedRoute,
		public dateMomentService: DateMomentService
	) {
		super(utilService, utilColorService, store, route, dateMomentService);
		this.chart.type = 'line';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.ACCENT);
		this.chart.colors = [
			{
				borderColor: this.colorWhite
			}
		];
	}

	// Functions

	createDataSerie() {
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			this.fulfilledTrend = this.utilService.findLeaf(this.statistics.data, 'fulfilled_trend');
		} else {
			this.fulfilledTrend = this.utilService.findLeaf(this.statistics.data, 'fulfilled.fulfilled_trend');
		}

		const serieTemp: number[] = [];
		this.fulfilledTrend.forEach((element: StatisticsBaseSerieOrder) => {
			serieTemp.push(element.quantity);
			this.fullLabels.push(element.date);
		});
		this.fullDataSets.push({
			label: 'count',
			data: serieTemp
		});
	}

	populateQuarterList() {
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			this.quarterList = this.utilService.findLeaf(this.statistics.data, 'quarters');
		} else {
			this.quarterList = this.utilService.findLeaf(this.statistics.data, 'fulfilled.quarters');
		}
	}

	populateChart(quarter: string = this.quarterList[0].name) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];
		let tempLabels = [];

		const period = {
			from: this.quarterList.find(item => item.name === quarter).from,
			to: this.quarterList.find(item => item.name === quarter).to
		};

		this.fullDataSets[0].data.forEach((item, index) => {
			if (this.fullLabels[index] && this.fullLabels[index] >= period.from && this.fullLabels[index] < period.to) {
				let serie = _.cloneDeep(item);
				tempSerie.push(serie);
				tempLabels.push(this.convertDateStatisticsDetailSold(this.fullLabels[index]));
			}
		});

		let fullDataSet = [];
		fullDataSet.push({
			label: 'count',
			data: tempSerie
		});

		this.addData(this.chart.datasets, fullDataSet);
		this.addData(this.chart.labels, tempLabels);

		this.getMetrics(quarter);
		this.chart = _.cloneDeep(this.chart);
	}

	getMetrics(quarterName: string) {
		const quarter = this.quarterList.find(item => item.name === quarterName);
		const data = [];
		this.fulfilledTrend.forEach(item => {
			if (item.date < quarter.to && item.date > quarter.from) {
				data.push(item.value);
			}
		});

		let metrics;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			metrics = this.utilService.findLeaf(this.statistics.data, 'metrics');
		} else {
			metrics = this.utilService.findLeaf(this.statistics.data, 'fulfilled.metrics');
		}
		this.metrics = this.formatMetrics(this.utilService.findLeaf(metrics, `sold.${quarterName}`));
	}

	formatMetrics(data) {
		const metrics = {
			average: +data.average < 1 ? +data.average.toFixed(2) : +data.average.toFixed(0),
			trend: data.trend.toFixed(0)
		};
		return metrics;
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {

			label: function(context) {
				let label =
					translateService.instant(context.dataset.label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += context.parsed.y.toFixed(0);
				return label;
			}


			// label: function (tooltipItem, data) {
			// 	let label = translateService.instant('order.' + data.datasets[tooltipItem.datasetIndex].label) || '';

			// 	if (label) {
			// 		label = sentencecasePipe.transform(label);
			// 		label += ': ';
			// 	}
			// 	label += tooltipItem.yLabel.toFixed(0);
			// 	return label;
			// }
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return {
			stacked: false,
			grid: {
				drawBorder: false,
				display: true,
				borderDash: [8, 6],
				color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
			},
			ticks: {
				display: true,
				// Valore minimo dell'asse
				// min: 0,
				// Scala da usare
				stepSize: 1,
				// Sets the offset of the tick labels from the axis
				padding: 8,
				// Font color for tick labels.
				fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
				// callback: this.ticksCallback()
			}
		}
	}

	xAxesStyle() {
		return {
			stacked: false,
			grid: {
				drawBorder: false,
				display: false
			},
			ticks: {
				display: false
			}
		}
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				x: this.xAxesStyle(),
				y: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}

	convertDateStatisticsDetailSold(date: number | string): string {
		return this.dateMomentService.convertMilllisStringToStringDate('' + date);
	}
}
