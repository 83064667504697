import { Injectable } from '@angular/core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { ArticleRecapDescription, ArticleRecapStockStateModel } from '../../model/state/article-list-state.model';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../state';
import { filter, take } from 'rxjs/operators';
import { ArticleDescriptionItem, ArticlePouchModel } from '@saep-ict/pouch_agent_models';

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreUtilArticleService {
	articleDescriptionList$: Observable<BaseStateModel<ArticleRecapDescription>> = this.store.select(
		StateFeature.getArticleDescription
	);
	articleRecapDescription: ArticleRecapDescription;

	constructor(private store: Store) {
		this.articleDescriptionList$
			.pipe(
				filter(res => !!(res && res.data)),
				take(1)
			)
			.subscribe(state => {
				this.articleRecapDescription = state.data;
			});
	}

	getArticleDescriptionList(codeItemList: string[]): ArticleDescriptionItem[] {
		return this.articleRecapDescription.article_list.filter(article => codeItemList.includes(article.code_item));
	}

	mergeArticleDescriptionStock(
		articleList: ArticlePouchModel[],
		articleListStock: ArticleDescriptionItem[]
	): ArticlePouchModel[] {
		articleList.forEach(article => {
			let articleStock: ArticleDescriptionItem = articleListStock.find(
				articleStock => articleStock.code_item === article.code_item
			);
			if (articleStock) {
				article.articleDescription.stock = articleStock.stock;
			}
		});
		return articleList;
	}
}
