import { TicketCenterConfigurationModel } from '@saep-ict/ticket-center';
import * as TicketCenterColumn from './column-map.constant';
import * as TicketCenterLocalSearchTextKeyList from './local-search-text-key-list.constant';
import * as TicketCenterBodyCustomField from './body-custom-field.constant';
import * as TicketCenterStatus from './status-configuration.constant';

export const configuration = <TicketCenterConfigurationModel>{
    page: {
        list: {
            title: {
                AGENT: 'lib.ticket_center.page.list.title',
                B2B: 'lib.ticket_center.page.list.title',
                B2C: 'lib.ticket_center.page.list.title',
                BACKOFFICE_ADMIN: 'lib.ticket_center.page.list.title',
                BACKOFFICE: 'lib.ticket_center.page.list.title',
                PORTAL: 'lib.ticket_center.page.list.title',
                CRM: 'lib.ticket_center.page.list.title'
            },
            tableColumn: TicketCenterColumn.contextApplicationMap,
            localSearchTextKeyList: TicketCenterLocalSearchTextKeyList.contextApplicationMap
        }
    },
    ticket: {
        bodyCustomField: TicketCenterBodyCustomField.creationFieldMap,
        statusContextApplicationStatusMap: TicketCenterStatus.contextApplicationMap
    }
};