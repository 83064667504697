import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { SourceEnum } from '../../../../enum/source.enum';

export namespace Destination {
	export const base: DestinationPouchModel = {
		valid: true,
		source: SourceEnum.Type.APPLICATION,
		date_creation: Date.now(),
		business_name: null,
		address: null
	}
	export const edit: DestinationPouchModel = {
		..._.cloneDeep(Destination.base),
		is_main_of_list: true
	}
	export const create: DestinationPouchModel = {
		..._.cloneDeep(Destination.edit),
		is_registered_office: true
	}
}
