import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// model
import { TicketModel } from '../../../model/lib.model';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../store';
import { TicketCenterTicketDetailActionEnum, TicketCenterTicketDetailStoreAction } from '../../../store/ticket/ticket-detail/ticket-detail.action';

// misc
import { BaseStateModel, SubscribeManagerService, BaseState } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { TicketCenterUtilService } from '../../../service/util/ticket-center-util.service';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';

@Component({
  selector: 'tc-dialog-ticket-detail',
  templateUrl: './dialog-ticket-detail.component.html',
  styleUrls: ['./dialog-ticket-detail.component.scss'],
  providers: [
		SubscribeManagerService
  ]
})
export class DialogTicketDetailComponent implements OnInit, OnDestroy {

  ticket: TicketModel<any>;
  ticketDetail$: Observable<BaseStateModel<any>> = this.store.select(StateFeature.getTicketDetailStore);

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserStore);
	user: UserDetailModel;

  contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogTicketDetailComponent>,
    private store: Store<any>,
    private subscribeManagerService: SubscribeManagerService,
    private ticketCenterUtilService: TicketCenterUtilService
  ) {

    this.ticket = this.data.ticket ? this.data.ticket : {};

    this.user$.pipe(take(1)).subscribe(store => {
			this.user = store ? store.data : null;
		});

    this.subscribeManagerService.populate(
      this.subscribeTicketDetail().subscribe(), 'ticket-detail'
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscribeManagerService.destroy();
  }

  subscribeTicketDetail(): Observable<BaseStateModel<TicketModel<any>>> {
		return this.ticketDetail$.pipe(
      filter((store: BaseStateModel<TicketModel<any>>) => !!(store && (store.data || store.errorMessage))),
			map((store: BaseStateModel<TicketModel<any>>) => {
        switch (store.type) {
          case TicketCenterTicketDetailActionEnum.SAVE_SUCCESS:
            this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction('tc.success.generic', 'tc.general.ok');
            this.dialogRef.close();
            break;
          case TicketCenterTicketDetailActionEnum.ERROR:
            this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction(store.errorMessage, 'OK');
            break;
        }
        return store;
			}),
      catchError((error, caught) => {
        console.log(error);
        this.ticketCenterUtilService.loadSnackbarWithAsyncTranslatedAction('tc.error.generic', 'OK');
        return caught;
      })
		);
  }

  onTicketDetailFormSave(e: TicketModel<any>) {
    this.store.dispatch(TicketCenterTicketDetailStoreAction.save(new BaseState(e)));
  }

  onTicketDetailFormCancel() {
    this.dialogRef.close();
  }

  // temporaneamente deprecato, in favore di una select semplice, direttamente all'interno del form
  // TODO: ripristinare o eliminare del tutto
  // onTicketDetailStatusSelectorChange(e: string){
  //   this.ticket.status = e;
  //   this.store.dispatch(TicketCenterTicketDetailStoreAction.save(new BaseState(this.ticket)));
  // }

}
