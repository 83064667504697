<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme autoheight">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'order.summary.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-select
						[compareWith]="dashboardConfiguration.compareValueOrderTrendTimeRange"
						[value]="configurationCustomerStatistic.timeRangeSelector[0]"
						(selectionChange)="updateBoxTrendInfoOrderContent($event)"
					>
						<mat-option
							*ngFor="let item of configurationCustomerStatistic.timeRangeSelector"
							[value]="item"
						>
							{{ item.label | translate | sentencecase }}
						</mat-option>
					</mat-select>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>
						{{ 'general.updated.at' | translate | sentencecase }}
						{{ updatedAtGeneral }}
					</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<box-trend-info [boxTrendInfoComponentConfig]="boxTrendInfoOrder"></box-trend-info>
			<!-- TODO: ripristinare una volta preparati i dati -->
			<!-- <mat-divider class="both-spacing"></mat-divider>
			<mat-toolbar class="mt-20">
				<mat-toolbar-row>
					{{ 'order.summary.value' | translate | sentencecase }}
				</mat-toolbar-row>
			</mat-toolbar>
			<chart-wrapper></chart-wrapper> -->
		</mat-card>
	</ng-container>
</page-with-sidebar>