<app-page-header
	[title]="'general.my_account' | translate | sentencecase"
	[backgroundImageUrl]="'theme.header_bg.login.backgroundImageUrl' | translate"
></app-page-header>

<div class="container">
	<div class="row justify-content-center">
		<div class="col-sm-12 col-md-6 col-lg-6 my-account__form">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
