import { Injectable } from '@angular/core';

// widget & utility
import { AngularSpin8CoreBaseApiService } from './baseApi.service';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { ArticleDescriptionItemRest } from '@saep-ict/pouch_agent_models';
import { returnApiUrlSegment } from '../../constant/api/api-configuration.constant';
import { ApiType } from '../../enum/api.enum';

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreArticleService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/catalog/articles-description/`
	})
	updateArticleRecapDescriptionItem: IResourceMethodPromise<ArticleDescriptionItemRest, any>;
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${returnApiUrlSegment(ApiType.PRIVATE)}/catalog/families-description/`
	})
	updateFamilyItem: IResourceMethodPromise<ArticleDescriptionItemRest, any>;
}
