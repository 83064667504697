import { Injector } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import * as FormControlMultipurposeModel from '../../model/widget/form-control-multipurpose.model';
import { AngularCoreUtilService } from '../../service/util/util.service';

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

const utilService = injector.get(AngularCoreUtilService);

export class FormControlMultipurposeSetValidator {
	public static requiredIfSomeFieldIsSomeValue(
		formValue,
		configurationParam: FormControlMultipurposeModel.SetValidatorConfigurationParam,
		defaultValidatorList?: ((control: AbstractControl) => ValidationErrors)[]
	): ((control: AbstractControl) => ValidationErrors)[] {
		defaultValidatorList = defaultValidatorList && defaultValidatorList.length > 0 ? defaultValidatorList : [];

		if (formValue && configurationParam) {
			return utilService.checkObjectNestedValueByPath(formValue, configurationParam.pathToProperty).value ===
				configurationParam.propertyValue
				? defaultValidatorList.concat([Validators.required])
				: defaultValidatorList;
		}
		return defaultValidatorList.concat([Validators.required]);
	}
}
