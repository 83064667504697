<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<ng-container *ngIf="opportunity && opportunity.log_list; else noData">
					<ng-container *ngFor="let log of opportunity.log_list">
						<div class="log">
							<div class="log__title">
								{{ log.date_creation | date: 'dd/MM/yyyy' }}
								{{ log.date_creation | date: 'HH:mm' }}
							</div>
							<div class="log__content">
								{{ log.description | sentencecase }}
							</div>
						</div>
					</ng-container>
				</ng-container>

				<ng-template #noData>
					<p class="opacity-50">
						{{ 'general.no_data' | translate | sentencecase }}
					</p>
				</ng-template>
			</mat-card>
		</div>
	</div>
</div>
