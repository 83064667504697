import { Injectable } from '@angular/core';
import { GlobalColorsEnum } from '../../../enum/util/global-colors.enum';
import { AngularCoreUtilService } from '../../util/util.service';
import { UtilColorService } from '../../util/util-color.service';
import { ChartjsModel } from '../../../model/chart.model';

export enum themeChartEnum {
  DEFAULT,
  ACCENT,
}

@Injectable({
  providedIn: 'root',
})
export class LineCurrencyChartConfigurationService {
  constructor(
    private utilService: AngularCoreUtilService,
    private utilColorService: UtilColorService
  ) {}

  colorPrimary = this.utilService.getGlobalColor(GlobalColorsEnum.PRIMARY);
  colorAccent = this.utilService.getGlobalColor(GlobalColorsEnum.ACCENT);

  colorPrimaryRGB = this.utilColorService.hexToRgb(this.colorPrimary);
  colorPrimary10 = `rgba(${this.colorPrimaryRGB.r}, ${this.colorPrimaryRGB.g}, ${this.colorPrimaryRGB.b}, 0.1)`;

  // white
  colorWhite = 'rgba(255,255,255,1)';
  colorWhite50 = 'rgba(255,255,255,0.5)';

  // black
  colorBlack = 'rgba(0,0,0,0.87)';
  colorBlack30 = 'rgba(0,0,0,0.3)';

  default: ChartjsModel = {
    data: null,
    datasets: [],
    labels: [],
    type: 'line',
    options: this.optionsStyle(themeChartEnum.DEFAULT),
    colors: [
      {
        backgroundColor: this.colorPrimary10,
        borderColor: this.colorPrimary,
      },
    ],
    legend: false,
  };

  accent: ChartjsModel = {
    ...this.default,
    options: this.optionsStyle(themeChartEnum.ACCENT),
    colors: [
      {
        backgroundColor: this.colorBlack30,
        borderColor: this.colorBlack,
      },
      {
        backgroundColor: this.colorWhite50,
        borderColor: this.colorWhite,
      },
    ],
  };

  tooltipsCallbacks() {
    return {

      label: function(context) {

        var label = context.dataset.label || '';

				if (label) {
					label += ': ';
				}
        label += (context.parsed.y / 1000).toFixed(2).replace('.', ',') + 'k';
				return label;
			}



      // label: function (tooltipItem, data) {
      //   var label = data.datasets[tooltipItem.datasetIndex].label || '';

      //   if (label) {
      //     label += ': ';
      //   }
      //   label += (tooltipItem.yLabel / 1000).toFixed(2).replace('.', ',') + 'k';
      //   return label;
      // },
    };
  }

  ticksCallback() {
    return function (label, index, labels) {
      return (label / 1000).toFixed(0) + 'k';
    };
  }

  yAxesStyle(theme: themeChartEnum) {
    return {
      grid: {
        drawBorder: false,
        display: true,
        borderDash: [4, 4],
        color:
          theme === themeChartEnum.DEFAULT
            ? this.colorBlack30
            : this.colorWhite50,
      },
      ticks: {
        display: true,
        // Valore minimo dell'asse
        // min: 0,
        // Scala da usare
        stepSize: 1000,
        // Sets the offset of the tick labels from the axis
        padding: 8,
        // Font color for tick labels.
        fontColor:
          theme === themeChartEnum.DEFAULT
            ? this.colorBlack
            : this.colorWhite,
        callback: this.ticksCallback(),
      },
    }
  }
  xAxesStyle() {
    return {
      gridLines: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        display: false,
      },
    }
  }

  tooltipsStyle() {
    return {
      // se false il tooltip compare se sull'asse x
      intersect: false,
      callbacks: this.tooltipsCallbacks(),
    };
  }

  layoutStyle() {
    return {
      padding: 4,
    };
  }

  elementsStyle() {
    return {
      point: {
        radius: 0,
      },
    };
  }

  hoverStyle() {
    return {
      // se false il punto si evidenza se si passa sull'asse x
      intersect: false,
    };
  }

  optionsStyle(theme: themeChartEnum) {
    return {
      responsive: true,
      layout: this.layoutStyle(),
      elements: this.elementsStyle(),
      tooltips: this.tooltipsStyle(),
      hover: this.hoverStyle(),
      scales: {
        x: this.xAxesStyle(),
        y: this.yAxesStyle(theme),
      },
    };
  }
}
