<form [formGroup]="form" class="michelangelo-theme">
	<div class="row">
		<form-control-multipurpose
			*ngFor="
				let field of formFieldList
					| filterByAllTheBooleans
						: {
								propertyListName: ['on_form_template'],
								value: true
						  }
			"
			[formControlName]="field.name"
			[field]="field"
			class="col-md-{{ field.column_width ? +field.column_width : 12 }}"
		></form-control-multipurpose>
	</div>
</form>
