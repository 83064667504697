import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { LocalListHandlerBase, AngularCoreUtilService } from '@saep-ict/angular-core';
import { ArticlePouchModel, LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import { TranslateService } from '@ngx-translate/core';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import * as _ from 'lodash';

@Component({
	selector: 'dialog-article-select',
	templateUrl: './dialog-article-select.component.html',
	styleUrls: ['./dialog-article-select.component.scss'],
	providers: []
})
export class DialogArticleSelectComponent extends LocalListHandlerBase {

	appStructure = ConfigurationCustomerAppStructure;

	localListHandlerData = <LocalListHandlerBaseModel<ArticlePouchModel>>{
		data: [],
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'articleDescription.language_list.description',
					this.appStructure.erp_has_erp ? 'code_erp' : 'code_item'
				]
			}
		},
		columnList: ConfigurationCustomerArticle.backofficeArticleDetailColumnList,
		sort: {
			name: this.appStructure.erp_has_erp ? 'code_erp' : 'code_item',
			order: 'ASC'
		}
	};

	selectedArticleList: ArticlePouchModel[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: {
			listForSelection: any[],
			relatedArticleCodeListToFilter: string[]
		},
		public store: Store,
		public dialogRef: MatDialogRef<DialogArticleSelectComponent>,
		public utilService: AngularCoreUtilService,
		private translateService: TranslateService
	) {
		super(utilService, store);
		this.localListHandlerData.languageKey = this.translateService.currentLang;
		const listForSelection = _.cloneDeep(data.listForSelection);
			const articleList =
			listForSelection.filter(i => !this.data.relatedArticleCodeListToFilter.includes(i.code_item));
		this.localListHandlerData.data.push(...(<ArticlePouchModel[]>articleList));
	}

	// widget & utility
	outputCode() {
		this.dialogRef.close(this.selectedArticleList.map(article => article.code_item));
	}

	selectCode(article: ArticlePouchModel) {
		if (this.selectedArticleList.map(article => article.code_item)?.includes(article.code_item)) {
			const removeIndex = this.selectedArticleList.map(article => article.code_item).indexOf(article.code_item);
			this.selectedArticleList.splice(removeIndex, 1);
		} else {
			this.selectedArticleList.push(article);
		}
	}

	removeCode(code) {
		const removeIndex = this.selectedArticleList.map(article => article.code_item).indexOf(code);
		this.selectedArticleList.splice(removeIndex, 1);
	}
}
