<!--
	TODO: per ora le operazioni di sort non sono gestite, in quanto la classe `LocalListHandler` lavora sull'elenco piatto
	`dataSubset` del suo oggetto di riferimento contenente gli articoli indicizzati, ordinamento e visualizzazione
	dipendono invece dall'alberatura delle categorie
-->
<mat-toolbar class="search-and-pagination" *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
		<button
			*ngIf="localListHandlerSidebarFilterShow"
			mat-icon-button
			(click)="localListHandlerOpenSidebarFilter.emit()"
		>
			<mat-icon>filter_list</mat-icon>
		</button>
	</mat-toolbar-row>
	<ng-container *ngTemplateOutlet="paginatorTemplate"></ng-container>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<article-list-category-tree-recursion-order
		[localListHandlerCategoryTreeConfiguration]="localListHandlerCategoryTreeConfiguration"
		[_localListHandlerWidgetWrapperColumnList]="_localListHandlerWidgetWrapperColumnList"
		[organization]="organization"
		[configurationAction]="configurationAction"
		(onArticleChange)="onArticleChangeHandler($event)"
		(onDeleteArticle)="onDeleteArticle.emit($event)"
	></article-list-category-tree-recursion-order>
	<mat-toolbar class="bottom-paginator">
		<ng-container *ngTemplateOutlet="paginatorTemplate"></ng-container>
	</mat-toolbar>
</ng-container>
<!-- <ng-template #paginatorTemplate>
	<mat-paginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[pageIndex]="localListHandlerData.pagination.pageIndex"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-template> -->

<ng-template #paginatorTemplate>
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<mat-paginator
			*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
			#matPaginator
			(page)="localListHandlerApplyFilter({ pagination: $event })"
			[pageIndex]="localListHandlerData.pagination.pageIndex"
			[length]="localListHandlerData.pagination.totalFilteredItemCount"
			[pageSize]="localListHandlerData.pagination.pageSize"
			[pageSizeOptions]="[5, 10, 25, 100]"
		>
		</mat-paginator>
	</mat-toolbar-row>
</ng-template>

<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
