import { Injector } from '@angular/core';

// model
import { BaseStateModel } from '@saep-ict/angular-core';
import { TicketModel } from '../../../model/lib.model';

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { TicketCenterTicketDetailActionEnum, TicketCenterTicketDetailStoreAction } from './ticket-detail.action';

// misc
import { TicketCenterUtilService } from '../../../service/util/ticket-center-util.service';

const injector = Injector.create({
	providers: [{ provide: TicketCenterUtilService, deps: [] }]
});

const ticketCenterUtilService = injector.get(TicketCenterUtilService);

export namespace TicketCenterTicketDetailStoreReducer {

  export const initialState: BaseStateModel<TicketModel<any>> = null;

	const _reducer = createReducer(
		initialState,
		on(TicketCenterTicketDetailStoreAction.load, state => state),
		on(TicketCenterTicketDetailStoreAction.update, (state, param) => param),
		on(TicketCenterTicketDetailStoreAction.save, (state, param) => state),
		on(TicketCenterTicketDetailStoreAction.saveSuccess, (state, param) => param),
		on(TicketCenterTicketDetailStoreAction.reset, () => null),
    on(TicketCenterTicketDetailStoreAction.error, (state, param) =>
      ticketCenterUtilService.returnStoreReducerError<TicketModel<any>>(state, param)
    )
	);

	export function reducer(state: BaseStateModel<TicketModel<any>>, action: TypedAction<TicketCenterTicketDetailActionEnum>) {
    return _reducer(state, action);
	}
}
