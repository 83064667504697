import { ChartTimeRangeModel } from "../model/chart.model";

export const chartTimeRange7Days: ChartTimeRangeModel = {
	name: 'chartTimeRangeDays',
	values: [7, 15, 30]
};
export const chartTimeRange30Days: ChartTimeRangeModel = {
	name: 'chartTimeRangeDays',
	values: [30, 60, 90]
};
