<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>view_column</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button
    *ngFor="let c of _columns"
    (click)="$event.stopPropagation()"
    mat-menu-item
  >
    <mat-checkbox color="primary" [checked]="!c.hidden" (change)="checkboxChange($event, c)">{{
      c.label
    }}</mat-checkbox>
  </button>
</mat-menu>
