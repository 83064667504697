import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import { creationFieldMapContextCodeOrganizationBackoffice } from '@saep-ict/angular-spin8-core';

export const projectOverride: FormControlMultipurposeModel.Item[] = 
  _.cloneDeep(creationFieldMapContextCodeOrganizationBackoffice)
  .concat([
    // specifico per SICSEG backoffice
    {
      name: 'input_registration_code',
      label: 'organization.field.input_registration_code',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING
    }
  ]);