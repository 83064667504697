import { Directive, EventEmitter, HostListener, Output, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[libDropArea]'
})
export class DropAreaDirective {
  @Output() private filesChangeEmiter: EventEmitter<FileList> = new EventEmitter();
  @Input() disabled = false;

  @HostBinding('style.background') background = '#eee';

  @HostListener('dragover', ['$event']) public onDragOver(evt){
    if (!this.disabled){
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#3d4b57';
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt){
    if (!this.disabled){
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#eee';
    }
  }

  @HostListener('drop', ['$event']) public onDrop(evt){
    if (!this.disabled){
      evt.preventDefault();
      evt.stopPropagation();
      const files = evt.dataTransfer.files;
      if (files.length > 0){
        this.background = '#eee';
        this.filesChangeEmiter.emit(files);
      }
    }
  }
  constructor() { }

}
