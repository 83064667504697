export namespace PassowrdMeterEnum {
  export enum NameCheck {
    LENGTH = 'checkLength',
    LOWERCASE = 'checkLowercase',
    UPPERCASE = 'checkUppercase',
    DIGIT = 'checkDigit',
    SPECIAL_CHAR = 'checkSpecialChar'
  }
  export enum PasswordStrength {
    EMPTY = 0,
    WEAK = 1,
    GOOD = 2,
    EXCELLENT = 3
  }
}
