import { Component, Input } from '@angular/core';

@Component({
	selector: 'person-card',
	templateUrl: './person-card.component.html',
	styleUrls: ['./person-card.component.scss']
})
export class PersonCardComponent {
	@Input() title: string;
	@Input() placeholderUrl: string;
	@Input() subtitle: string;
	@Input() personImage: string;
	@Input() isActiveIndicator: boolean = null;

	constructor() {}

	ngOnInit() {}
}
