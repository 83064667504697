import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
	AuxiliaryTableGeographicTreeActionEnum,
	AuxiliaryTableGeographicTreeStateAction
} from './auxiliary-table-geographic-tree.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { BaseState } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { Store } from '@ngrx/store';
import { BodyTablePouchModel, HeaderTablePouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerAppConfig } from '../../../customer-app.config';
import { PouchDbCommonsAdapter } from '../../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { UtilAddressService } from '../../../service/util/util-address.service';
import { UtilPouchService } from '../../../service/util/util-pouch.service';
import { AuxiliaryTabeleGeographicTree, AngularSpin8CoreAuxiliaryTableRestService } from '@saep-ict/angular-spin8-core';

@Injectable()
export class AuxiliaryTableGeographicTreeEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuxiliaryTableGeographicTreeActionEnum.LOAD),
			mergeMap(() => from(this.getAuxiliaryTable('geographic-tree'))),
			map((action: AuxiliaryTabeleGeographicTree.StateModel) =>
				AuxiliaryTableGeographicTreeStateAction.update(new BaseState(action))
			),
			catchError((error, caught) => {
				this.store.dispatch(AuxiliaryTableGeographicTreeStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private auxiliaryTableRestService: AngularSpin8CoreAuxiliaryTableRestService,
		private appConfig: CustomerAppConfig,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter,
		private utilAddressService: UtilAddressService,
		private utilPouchService: UtilPouchService
	) {}

	async getAuxiliaryTable(id: string): Promise<AuxiliaryTabeleGeographicTree.StateModel> {
		try {
			const auxiliaryTabeleGeographicTree: AuxiliaryTabeleGeographicTree.Item[] = this.appConfig
				.authenticationToken
				? (
						await this.pouchDbCommonsAdapter.basePouch.getDetail<
							HeaderTablePouchModel<AuxiliaryTabeleGeographicTree.Item>
						>(this.utilPouchService.auxiliaryTableIdFormatter(id))
				  ).values
				: (await this.auxiliaryTableRestService.getAuxiliaryTable({ id: id })).data.values;
			const auxiliaryTabeleGeographicTreeStateModel: AuxiliaryTabeleGeographicTree.StateModel = {
				provinceList: await this.utilAddressService.mapAuxiliaryTableGeographicTreeToOptionList(
					auxiliaryTabeleGeographicTree,
					AuxiliaryTabeleGeographicTree.ChildrenLevelEnum.PROVINCE
				),
				tree: auxiliaryTabeleGeographicTree
			};
			return auxiliaryTabeleGeographicTreeStateModel;
		} catch (err) {
			throw new Error(err);
		}
	}
}
