import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'thousand' })
export class ThousandPipe implements PipeTransform {
	transform(value): string {
		value = parseInt(value);

		if (!value) {
			return '';
		}

		let str = '';
		let res: string = '';
		let lastIndex: number;

		if (value > 1000) {
			str = value + '';
			lastIndex = str.length;
			for (let index = str.length - 3; index > 0; index -= 3) {
				res = '.' + str.substr(index, 3) + res;
				lastIndex = index;
			}
			res = str.substr(0, lastIndex) + res;
			return res;
		}
		return '' + value;
	}
}
