import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { RestBaseMessageError } from '@saep-ict/angular-core';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { UserActivateActionEnum, UserActivateStateAction } from './user-activate.actions';
import {
	UserPasswordChangeModelRequest,
	UserPasswordChangeModel,
	UserActivateAccountRequestModel,
	VerifyGuidEmailRequestModel,
	UserActivationStateModel,
	AngularSpin8CoreUserService
} from '@saep-ict/angular-spin8-core';

@Injectable()
export class UserActivateEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActivateActionEnum.LOAD),
			concatMap((action: BaseStateModel<VerifyGuidEmailRequestModel>) => from(this.verifyTokenEmail(action))),
			map((userActivate: BaseStateModel<UserActivationStateModel>) =>
				UserActivateStateAction.update(userActivate)
			),
			catchError((error, caught) => {
				this.store.dispatch(UserActivateStateAction.error(null));
				return caught;
			})
		)
	);

	activate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActivateActionEnum.ACTIVATE),
			concatMap((action: BaseStateModel<UserPasswordChangeModel>) => from(this.activateUser(action))),
			map((userActivate: BaseStateModel<any>) => UserActivateStateAction.completed(userActivate)),
			catchError((error, caught) => {
				this.store.dispatch(UserActivateStateAction.error({ type: UserActivateActionEnum.ERROR, data: null }));
				return caught;
			})
		)
	);

	set_password$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActivateActionEnum.SET_PASSWORD),
			concatMap((action: BaseStateModel<UserPasswordChangeModel>) => from(this.setPassword(action))),
			map((userActivate: BaseStateModel<any>) => UserActivateStateAction.completed(userActivate)),
			catchError((error, caught) => {
				this.store.dispatch(UserActivateStateAction.error({ type: UserActivateActionEnum.ERROR, data: null }));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private userService: AngularSpin8CoreUserService,
		private store: Store<any>
	) {}

	async verifyTokenEmail(
		requestVerifyToken: BaseStateModel<VerifyGuidEmailRequestModel>
	): Promise<BaseStateModel<UserActivationStateModel>> {
		return this.userService
			.verifyGuidEmail(requestVerifyToken.data)
			.then(res => {
				if (res.data) {
					const userStateUpdate: UserActivationStateModel = { ...res.data, ...requestVerifyToken.data };
					return new BaseState(userStateUpdate);
				}
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async activateUser(userActivateState: BaseStateModel<UserPasswordChangeModel>): Promise<BaseStateModel<any>> {
		const requestActivateAccount: UserActivateAccountRequestModel = {
			id: userActivateState.data.id,
			guid: userActivateState.data.guid
		};
		return this.userService
			.activateAccount({ password: userActivateState.data.password }, null, requestActivateAccount)
			.then(res => {
				return new BaseState(null);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async setPassword(userActivateState: BaseStateModel<UserPasswordChangeModel>): Promise<BaseStateModel<any>> {
		const requestPasswordChange: UserPasswordChangeModelRequest = {
			password: userActivateState.data.password,
			guid: userActivateState.data.guid
		};
		return this.userService
			.passwordChange(requestPasswordChange, null, { id: userActivateState.data.id })
			.then(res => {
				return new BaseState(null);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}
}
