import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as PreviewContainerModel from '../../model/widget/preview-container.model';

@Component({
  selector: 'preview-container',
  templateUrl: './preview-container.component.html',
  styleUrls: ['./preview-container.component.scss']
})
export class PreviewContainerComponent {

  @Input() configuration: PreviewContainerModel.Configuration;
  @Output() onClick = new EventEmitter()

  imageError: boolean;

  clickHandler() {
    if (this.configuration.onClickHandler) {
      this.onClick.emit();
    }
  }

}
