import { Validators } from '@angular/forms';
import { FormControlMultipurposeModel, FormControlMultipurposeEnum, FormControlMultipurposeService } from '@saep-ict/angular-core';
import { creationFieldMapDocumentBase } from '../creation-field-map-base.const';
import _ from 'lodash';

/**
 * One number is mandatory and if you insert a dot also decimals are mandatory
 */
// const regexLatLang = '^[0-9]+(\.[0-9]+)?$';

export const creationFieldMapDestinationBase: FormControlMultipurposeModel.Item[] =
  _.cloneDeep(creationFieldMapDocumentBase).concat([
    {
      name: 'business_name',
      label: 'saep_ict_angular_spin8_core.widget.destination.item_name',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.STRING,
      column_width: 12
    },
    {
      name: 'is_registered_office',
      on_form_template: false,
    },
    {
      name: 'is_main_of_list',
      label: 'general.favorite',
      on_form_template: false,
      type: FormControlMultipurposeEnum.ItemType.BOOLEAN
    },
    {
      name: 'address',
      label: 'organization.field.destination_list',
      on_form_template: true,
      type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
      form_control_list: [
        {
          name: 'zip_code',
          label: 'general.address.zip_code',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.STRING,
          column_width: 6,
          validator_list: [Validators.required]
        },
        {
          name: 'address',
          label: 'saep_ict_angular_spin8_core.widget.destination.address.name',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.STRING,
          column_width: 6,
          validator_list: [Validators.required]
        },
        {
          name: 'locality',
          label: 'saep_ict_angular_spin8_core.widget.destination.address.city',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.STRING,
          column_width: 6,
          validator_list: [Validators.required]
        },
        {
          name: 'province',
          label: 'saep_ict_angular_spin8_core.widget.destination.address.province',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.SELECT,
          column_width: 6,
          validator_list: [Validators.required],
          option_list: [], // compilato dinamicamente dallo store auxiliary-table-geographic-tree
          select_save_whole_object: true
        },
        {
          name: 'country',
          label: 'saep_ict_angular_spin8_core.widget.destination.address.country',
          on_form_template: true,
          type: FormControlMultipurposeEnum.ItemType.STRING,
          column_width: 6,
          validator_list: [Validators.required]
        }
      ]
    }
  ]);
