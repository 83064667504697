import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StatisticsBaseSerieProduct } from '../../../model/statistics.model';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AppUtilService } from '../../util/app-util.service';
import { BaseTableClass } from '../../td-data-table/base-table.service';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class FulfilledProductsTableConfigService extends BaseTableClass {
	fullDataSetQ1: StatisticsBaseSerieProduct[] = [];
	fullDataSetQ2: StatisticsBaseSerieProduct[] = [];
	fullDataSetQ3: StatisticsBaseSerieProduct[] = [];
	fullDataSetQ4: StatisticsBaseSerieProduct[] = [];

	currentDataSet: StatisticsBaseSerieProduct[] = [];

	columns: ITdDataTableColumn[] = [
		{
			name: 'description',
			label: this.translate.instant('product.field.name')
		},
		{
			name: 'value',
			label: this.translate.instant('product.field.total_income'),
			numeric: true
		}
	];

	public quarterList;

	constructor(
		public store: Store<any>,
		@Inject(TranslateService) private translate: TranslateService,
		public route: ActivatedRoute,
		public utilService: AppUtilService
	) {
		super(utilService, store, route);
	}

	// Functions

	createDataSerie() {
		this.quarterList[0].products.forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetQ1.push(item);
		});

		this.quarterList[1].products.forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetQ2.push(item);
		});

		this.quarterList[2].products.forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetQ3.push(item);
		});

		this.quarterList[3].products.forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetQ4.push(item);
		});
	}

	populateQuarterList() {
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			this.quarterList = this.utilService.findLeaf(this.statistics.data, 'quarters');
		} else {
			this.quarterList = this.utilService.findLeaf(this.statistics.data, 'fulfilled.quarters');
		}
	}

	populateTable(quarter: string = this.quarterList[0].name) {
		switch (quarter) {
			case this.quarterList[0].name:
				this.currentDataSet = this.fullDataSetQ1;
				break;
			case this.quarterList[1].name:
				this.currentDataSet = this.fullDataSetQ2;
				break;
			case this.quarterList[2].name:
				this.currentDataSet = this.fullDataSetQ3;
				break;
			case this.quarterList[3].name:
				this.currentDataSet = this.fullDataSetQ4;
				break;
			default:
				break;
		}
	}
}
