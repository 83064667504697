import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { AngularSpin8CoreUtilKanbanService, OpportunityPouchModel } from '@saep-ict/angular-spin8-core';
import { AvatarConfigClass } from '../../avatar/avatar.component';

@Component({
	selector: 'kanban-card',
	templateUrl: './kanban-card.component.html',
	styleUrls: ['./kanban-card.component.scss']
})
export class KanbanCardComponent implements OnInit {
	@Input() opportunity: OpportunityPouchModel;
	daysFromCloseDate = 0;
	ratingAvg = 0;
	avatarConfigClass: AvatarConfigClass = null;

	constructor(private utilKanbanService: AngularSpin8CoreUtilKanbanService) {}

	ngOnInit() {
		this.avatarConfigClass = {
			urlImage: this.opportunity.assegnee_avatar,
			userName: this.opportunity.assegnee_full_name || this.opportunity.assegnee_user_name,
			isRounded: true
		};

		this.daysFromCloseDate = moment(this.opportunity.estimated_close_date).diff(moment(), 'd', false) + 1;
		this.ratingAvg = this.utilKanbanService.getAvg([
			this.opportunity?.organization?.crm?.organization_rating || 0,
			this.opportunity.project_interest || 0,
			this.opportunity.close_probability || 0
		]);
	}
}
