import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	ListSettingStoreAction,
	SubscribeManagerService,
	UtilStoreService
} from '@saep-ict/angular-core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { StateFeature } from '../../state';
import {
	AngularSpin8CoreUtilTranslateService,
	OrderStateModel,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../service/util/app-util.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	providers: [SubscribeManagerService]
})
export class HeaderComponent implements OnInit, OnDestroy {
	path = 'link_1';
	options: string[] = ['One', 'Two', 'Three'];
	filteredOptions: Subject<ArticlePouchModel[]> = new Subject();
	textQueryChanged: Subject<string> = new Subject();
	navbarIsOpen = false;
	mobileSearchIsOpen = false;

	private routerNavigationSub: Subscription;
	ROUTE_URL = ROUTE_URL;

	// Mock
	linkList = [
		{ label: 'Home', route: `${ROUTE_URL.public}`, altText: 'Home' },
		{
			label: 'saep_ict_angular_spin8_core.general.catalogue',
			route: `${ROUTE_URL.public}/${ROUTE_URL.catalog}`,
			altText: 'saep_ict_angular_spin8_core.general.catalogue'
		}
	];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	searchResults$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(
		StateFeature.getSearchResultsState
	);

	filterValue: string;
	redirect = false;

	constructor(
		public router: Router,
		private store: Store,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private utilService: AppUtilService,
		private subscribeManagerService: SubscribeManagerService,
		private utilStoreService: UtilStoreService
	) {
		this.routerNavigationSub = this.router.events.subscribe((evt: RouterEvent) => {
			if (evt instanceof NavigationEnd) {
				this.path = this.linkList.map(ls => ls.route).find(route => this.router.isActive(route, true)) || '';
				this.navbarIsOpen = false;
			}
		});
	}

	async ngOnInit() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.subscribeManagerService.populate(
			this.order$.subscribe(res => {
				this.order = res ? res.data : null;
			}),
			'order'
		);
		this.subscribeManagerService.populate(
			this.searchResults$.subscribe(res => {
				const searchResults = res ? res.data : null;
				if (searchResults) {
					this.filteredOptions.next(searchResults);
					if (this.redirect) {
						this.goToSearchResultsPage();
						this.redirect = false;
					}
				}
			}),
			'search'
		);

		this.subscribeManagerService.populate(
			this.textQueryChanged.pipe(debounceTime(600)).subscribe((value: string) => {
				if (value && typeof value == 'string') {
					this.filterValue = value.toLowerCase();
					this.goToSearchResultsPage();
					// this.store.dispatch(SearchResultsStateAction.load({ term: this.filterValue }));
				}
			}),
			'text-query'
		);
	}

	public async filter(value: string, redirect?: boolean) {
		this.textQueryChanged.next(value);
		this.redirect = redirect;
		if (!value) {
			this.filteredOptions.next([]);
		}
	}

	getOptionText(option: ArticlePouchModel) {
		return option ? option.description : '';
	}

	goToArticle(product: ArticlePouchModel) {
		this.utilService.redirectTo(ROUTE_URL.public + '/' + ROUTE_URL.product + '/' + product.code_item);
	}

	goToSearchResultsPage() {
		// this.utilService.redirectTo(ROUTE_URL.public + '/' + ROUTE_URL.searchResults, this.filterValue);
		// this.router.navigate([ROUTE_URL.public, ROUTE_URL.catalog], { queryParams: { search: this.filterValue }, replaceUrl: true });
		this.router.navigateByUrl('random', { skipLocationChange: true }).then(() =>
			this.router.navigate([ROUTE_URL.public, ROUTE_URL.catalog], {
				queryParams: { search: this.filterValue },
				replaceUrl: true
			})
		);
	}

	getOrderItemsNumber() {
		let qty: string | number = '';
		// TODO: quickfix per la action remove, da rivedere la pipe su che riempie ordercon dati incoerenti al modello
		if (this.order && this.order.product_list) {
			qty = this.order.product_list.reduce((previous, current) => {
				return previous + current.ordered_quantity;
			}, 0);
		}
		return qty ? qty : '';
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		if (this.routerNavigationSub) {
			this.routerNavigationSub.unsubscribe();
		}
	}

	goTo(url) {
		if (url === '/' + ROUTE_URL.catalog) {
			this.utilStoreService.serielizedStoreResetItem(ListSettingStoreAction.resetItem, ROUTE_URL.catalog);
		}
		this.router.navigate([url]);
	}
}
