import {
    ArticlePouchModel,
    ITdDataTableColumnCustom,
	LanguageItem,
	OrganizationTypeEnum
} from '@saep-ict/pouch_agent_models';
import { FormControlMultipurposeModel } from '@saep-ict/angular-core';
import { articleColumnMapBackofficeArticle } from './article-column-map-backoffice-article.constant';
import { articleColumnMapBackofficeArticleDetail } from './article-column-map-backoffice-article-detail.constant';
import { articleColumnMapBackofficeProductStock } from './article-column-map-backoffice-product-stock.constant';
import {
	ConfigurationUtil,
	ROUTE_URL
} from '@saep-ict/angular-spin8-core';
import { articleColumnMapAgentCatalog } from './article-column-map-agent-catalog.constant';
import { articleColumnMapAgentCheckout } from './article-column-map-agent-checkout.constant';
import { articleColumnMapCrmCatalog } from './article-column-map-crm-catalog.constant';
import { articleColumnMapB2bCheckout } from './article-column-map-b2b-checkout.constant';
import { articleColumnMapBackOfficeCatalog } from './article-column-map-backoffice-catalog.constant';
import { articleColumnMapBackOfficeCheckout } from './article-column-map-backoffice-checkout.constant';
import { articleColumnMapB2bCatalog } from './article-column-map-b2b-catalog.constant';
import * as ConfigurationCustomerArticleContextApplicationCatalogSortList from './article-catalog-sort-list.constant';
import { SortSelectorConfigurationItem } from '../../../widget/sort-selector/sort-selector.component';
import {
    returnConfigurationCustomerArticleDescriptionCreationFieldMap,
    returnConfigurationCustomerArticleDescriptionFormValue
} from './article-description-creation-field-map.constant';
import {
    returnConfigurationCustomerArticleDescriptionLanguageListCreationFieldMap,
    returnConfigurationCustomerArticleDescriptionLanguageListFormValueForInitialization,
    returnConfigurationCustomerArticleDescriptionLanguageListFormValueForSave
} from './article-description-language-list-creation-field-map.constant';
import { LanguageImagePreviewParamFormDynamic } from '../../../model/article.model';
import * as CategoryEnum from '../../../enum/category.enum';
import * as ConfigurationCustomerArticleCategoryColumnMap from './article-category-column-map.constant';

export const articleRecapDefaultPublic = OrganizationTypeEnum.COMPANY;
export const previewLength = 380;
export const has_category = true;
export const has_family = true;
export const currencyDefault = 'EUR';
export const backofficeArticleColumnList: ITdDataTableColumnCustom[] = articleColumnMapBackofficeArticle;
export const backofficeArticleDetailColumnList: ITdDataTableColumnCustom[] = articleColumnMapBackofficeArticleDetail;
export const articleColumnMapBackofficeProductStockColumnList: ITdDataTableColumnCustom[] = articleColumnMapBackofficeProductStock;
export const orderColumnList = (
    currency?: string,
    page?: string
): ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumnCustom[]> => {
    if (!currency) {
        currency = currencyDefault;
    }
    return <ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumnCustom[]>>{
        AGENT:
            page && page === ROUTE_URL.catalog ?
            articleColumnMapAgentCatalog(currency) :
            articleColumnMapAgentCheckout(currency),
        CRM:
            page && page === ROUTE_URL.catalog ?
            articleColumnMapCrmCatalog(currency) :
            articleColumnMapB2bCheckout(currency),
        BACKOFFICE:
            page && page === ROUTE_URL.catalog ?
            articleColumnMapBackOfficeCatalog(currency) :
            articleColumnMapBackOfficeCheckout(currency),
        B2B:
            page && page === ROUTE_URL.catalog ?
            articleColumnMapB2bCatalog(currency) :
            articleColumnMapB2bCheckout(currency)
    };
};
export const catalogSortList: ConfigurationUtil.Context.ApplicationSelector<SortSelectorConfigurationItem[]> = {
    BACKOFFICE_ADMIN: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
    BACKOFFICE: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
    AGENT: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
    B2B: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
    B2C: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
    PORTAL: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
    CRM: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
    PUBLIC: ConfigurationCustomerArticleContextApplicationCatalogSortList.base
};
export const articleChangeInputKeyboardNavigationEnabled: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true
};
export const articleChangeInputMask: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    B2B: true
};
export const imageWidth = 350;
export const originDefault = 'IT';
export const articleDescriptionCreationFieldMap = async (): Promise<FormControlMultipurposeModel.Item[]> => {
    try {
        return await returnConfigurationCustomerArticleDescriptionCreationFieldMap();
    } catch(e) {
        throw new Error(e);
    }
}
export const returnArticleDescriptionFormValue = async (article: ArticlePouchModel): Promise<any> => {
    try {
        return await returnConfigurationCustomerArticleDescriptionFormValue(article);
    } catch(e) {
        throw new Error(e);
    }
}
export const articleDescriptionLanguageListCreationFieldMap = async (
    article: ArticlePouchModel,
    language: string
): Promise<FormControlMultipurposeModel.Item[]> => {
    try {
        return await returnConfigurationCustomerArticleDescriptionLanguageListCreationFieldMap(article, language);
    } catch(e) {
        throw new Error(e);
    }
}
export const returnArticleDescriptionLanguageListFormValueForInitialization = async (
    languageItem: LanguageItem,
    imagePreviwParm: LanguageImagePreviewParamFormDynamic
): Promise<any> => {
    try {
        return await returnConfigurationCustomerArticleDescriptionLanguageListFormValueForInitialization(languageItem, imagePreviwParm);
    } catch(e) {
        throw new Error(e);
    }
}
export const returnArticleDescriptionLanguageListFormValueForSave = async (
    languageItem: LanguageItem
): Promise<any> => {
    try {
        return await returnConfigurationCustomerArticleDescriptionLanguageListFormValueForSave(languageItem);
    } catch(e) {
        throw new Error(e);
    }
}
export const articleChangeInputHighlight = {
    input_quantity: (article: ArticlePouchModel): boolean => {
        return article &&
            article.articleDescription &&
            article.articleDescription.free_sample === 'S' &&
            !article.articleDescription.is_tester;
    }
}
export const categoryColumnList = (level: CategoryEnum.Level): ITdDataTableColumnCustom[] => {
    switch (level) {
        case CategoryEnum.Level.BRAND:
            return ConfigurationCustomerArticleCategoryColumnMap.base.filter(i =>
                ConfigurationCustomerArticleCategoryColumnMap.forBrand.includes(i.name)
            );
        case CategoryEnum.Level.LINE:
            return ConfigurationCustomerArticleCategoryColumnMap.base.filter(i =>
                ConfigurationCustomerArticleCategoryColumnMap.forLine.includes(i.name)
            );
        case CategoryEnum.Level.LEAF:
            return ConfigurationCustomerArticleCategoryColumnMap.base.filter(i =>
                ConfigurationCustomerArticleCategoryColumnMap.forLeaf.includes(i.name)
            );
    }
};