import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import { DropAreaDirective } from './drop-area.directive';
import { DebounceTimeHandlerDirective } from './debounce-time-handler.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { PercentInputDirective } from './percent-input.directive';
import { MaxNumberInputDirective } from './max-number-input.directive';
import { UppercaseDirective } from './uppercase.directive';


@NgModule({
  declarations: [
    DropAreaDirective,
    ClickOutsideDirective,
    DebounceTimeHandlerDirective,
    StopPropagationDirective,
    OnlyNumbersDirective,
		PercentInputDirective,
		MaxNumberInputDirective,
		UppercaseDirective
  ],
  exports: [
    DropAreaDirective,
    ClickOutsideDirective,
    DebounceTimeHandlerDirective,
    StopPropagationDirective,
    OnlyNumbersDirective,
		PercentInputDirective,
		MaxNumberInputDirective,
		UppercaseDirective
  ]
})
export class DirectivesModule { }
