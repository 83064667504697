import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ContextPermissionGroupItem } from '@saep-ict/angular-spin8-core';
import { PermissionUtilService } from '../../../service/util/permission-util.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => FormPermissionGroupSelectComponent),
	multi: true
};

@Component({
	selector: 'form-permission-group-select',
	templateUrl: './form-permission-group-select.component.html',
	styleUrls: ['./form-permission-group-select.component.scss'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FormPermissionGroupSelectComponent implements OnInit, ControlValueAccessor {
	/**
	 * Definisce se il componente è abilitato o disabilitato
	 *
	 * @memberof FormPermissionGroupSelectComponent
	 */
	@Input() disabled = false;

	/**
	 * Definisce l'alberatura di permission nella struttura utile al template e per il passaggioe dei valori
	 *
	 * @type {ContextPermissionGroupItem[]}
	 * @memberof FormPermissionGroupSelectComponent
	 */
	@Input() groupList: ContextPermissionGroupItem[];

	/**
	 * Valore in ingresso da utilizzare soltanto nel caso in cui il componente non venga bindato ad un formControlName.
	 * Questo può contenere anche valori non inclusi in tree, i quali vengono emessi in trasparenza da extraFormValueEmit
	 *
	 * @memberof FormPermissionGroupSelectComponent
	 */
	@Input() set extraFormValue(value: string[]) {
		if (value) {
			this.formFieldValue = value;
		}
	}

	/**
	 * Valore in uscita da utilizzare soltanto nel caso in cui il componente non venga bindato ad un formControlName
	 *
	 * @memberof FormPermissionGroupSelectComponent
	 */
	@Output() extraFormValueEmit = new EventEmitter<string[]>();

	/**
	 * formControlName nello scope del componente
	 *
	 * @type {number[]}
	 * @memberof FormPermissionGroupSelectComponent
	 */
	formFieldValue: string[];

	constructor(public permissionUtilService: PermissionUtilService) {}
	ngOnInit() {}

	/**
	 * formControlName: valore in ingresso
	 *
	 * @param {number[]} value
	 * @memberof FormPermissionGroupSelectComponent
	 */
	writeValue(value: string[]) {
		this.formFieldValue = value;
	}
	/**
	 * formControlName: metodi di gestione valore in uscita
	 *
	 * @memberof FormPermissionGroupSelectComponent
	 */
	propagateChange = (_: any) => {};
	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}
	registerOnTouched(fn: any) {}

	/**
	 * Emissione del valore in uscita. Scatena entrambi:
	 * - propagateChange (formControlName)
	 * - extraFormValueEmit
	 *
	 * @param {any[]} value
	 * @memberof FormPermissionGroupSelectComponent
	 */
	updatePermissionValue() {
		this.propagateChange(this.formFieldValue);
		this.extraFormValueEmit.emit(this.formFieldValue);
	}

	/**
	 * 1 Aggiorna il valore in base al set da parte del gruppo
	 * 2 Scatena updatePermissionValue()
	 *
	 * @param {MatCheckboxChange} e
	 * @param {number[]} permission
	 * @memberof FormPermissionGroupSelectComponent
	 */
	setGroupPermission(e: MatCheckboxChange, permissionGroupValue: string[]) {
		const formFieldValue = this.formFieldValue ? JSON.parse(JSON.stringify(this.formFieldValue)) : [];
		permissionGroupValue.forEach(i => {
			if (e.checked) {
				if (!formFieldValue.includes(i)) {
					formFieldValue.push(i);
				}
			} else {
				if (formFieldValue.includes(i)) {
					const index = formFieldValue.indexOf(i);
					if (index > -1) {
						formFieldValue.splice(index, 1);
					}
				}
			}
		});
		this.formFieldValue = formFieldValue;
		this.updatePermissionValue();
	}

	/**
	 * Restituisce lo stato mat-checkbox indeterminata
	 *
	 * @param {number[]} permission
	 * @returns
	 * @memberof DialogChangePermissionContextCodeComponent
	 */
	someGroupPermissionChecked(permissionToProcess: string[]) {
		if (this.formFieldValue) {
			const isIncluded = i => this.formFieldValue.includes(i);
			return permissionToProcess.some(isIncluded) && !this.allGroupPermissionChecked(permissionToProcess);
		}
		return false;
	}

	/**
	 * Restituisce lo stato mat-checkbox selezionata
	 *
	 * @param {number[]} permission
	 * @returns
	 * @memberof DialogChangePermissionContextCodeComponent
	 */
	allGroupPermissionChecked(permissionToProcess: string[]) {
		if (this.formFieldValue) {
			const isIncluded = i => this.formFieldValue.includes(i);
			return permissionToProcess.every(isIncluded);
		}
		return false;
	}
}
