import { Injectable } from '@angular/core';

// model
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';

// store
import { Store } from '@ngrx/store';
import {
	PermissionAuxiliaryTableActionEnum,
	PermissionAuxiliaryTableStateAction
} from './permission-auxiliary-table.actions';

// widget & utility
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';
import {
	PermissionAuxiliaryDictionary,
	PermissionAuxiliaryTableStateModel,
	AngularSpin8CorePermissionService
} from '@saep-ict/angular-spin8-core';

@Injectable()
export class PermissionAuxiliaryTableEffects {
	@LocalStorage('permissions') permissions: PermissionAuxiliaryTableStateModel;
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PermissionAuxiliaryTableActionEnum.LOAD),
			mergeMap(() => from(this.getPermissionAuxiliaryTable())),
			map((PermissionAuxiliaryTable: BaseStateModel<PermissionAuxiliaryTableStateModel>) =>
				PermissionAuxiliaryTableStateAction.update(PermissionAuxiliaryTable)
			),
			catchError((error, caught) => {
				this.store.dispatch(PermissionAuxiliaryTableStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private permissionService: AngularSpin8CorePermissionService
	) {}

	async getPermissionAuxiliaryTable(): Promise<BaseStateModel<PermissionAuxiliaryTableStateModel>> {
		const promises = [];
		const permissionAuxiliaryTable = <PermissionAuxiliaryTableStateModel>{};
		PermissionAuxiliaryDictionary.forEach(i => {
			promises.push(
				this.permissionService.getPermissionAuxiliaryTable({ endpoint: i.endpoint }).then(res => {
					permissionAuxiliaryTable[i.key] = res.data;
				})
			);
		});
		await Promise.all(promises);
		this.permissions = permissionAuxiliaryTable;
		return new BaseState(permissionAuxiliaryTable);
	}
}
