import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dialog-upload-file',
  templateUrl: './dialog-upload-file.component.html',
  styleUrls: ['./dialog-upload-file.component.scss']
})
export class DialogUploadFileComponent implements OnInit {
  
  fileList: any[];

  constructor(
    public dialogRef: MatDialogRef<DialogUploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {

  }

  onAttachmentResolverFileChange(e) {
    this.fileList = e;
	}

  emitDialogData(){
    let res;
    if (this.data.multiple) {
      res = this.fileList;
    } else {
      res = this.fileList[0];
    }
    this.dialogRef.close(res);
  }

}
