import { ResourceAction, ResourceRequestMethod, IResourceMethodPromise } from '@ngx-resource/core';
import { AccessRequestModel } from '../../model/auth/access-request.model';
import { AngularSpin8CoreBaseApiService } from './baseApi.service';

export class AngularSpin8CoreAccessRequestService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/public/authentication/access-request/'
	})
	accessRequest: IResourceMethodPromise<any, AccessRequestModel>;
}
