import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContextApplicationItemCodeEnum } from '../../enum/permission/context-application-item-code.enum';
import { PATH_URL, ROUTE_URL } from '../../constant/navigation/route/route-naming';
import { ContactPouchModel, OrganizationLevelEnum, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';

import { OrganizationStateModel } from '../../model/state/organization-state.model';
import { AngularSpin8CoreUtilService } from './util.service';

@Injectable({
	providedIn: 'root'
})
export class AngularSpin8CoreUtilCompanyService {
	constructor(private router: Router, public utilService: AngularSpin8CoreUtilService) {}

	navigateToCompanyDetail(
		companyLevel: string = null,
		companyCode: string = null,
		contextCode: ContextApplicationItemCodeEnum = null
	) {
		if (contextCode && companyCode) {
			switch (contextCode) {
				case ContextApplicationItemCodeEnum.AGENT:
				case ContextApplicationItemCodeEnum.CRM:
					this.router.navigate([
						`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/${companyLevel}/${companyCode}`
					]);
					break;
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/no-filter/${companyCode}`]);
					break;
				default:
					break;
			}
		} else if (companyCode) {
			this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/${companyLevel}/${companyCode}`]);
		}
	}

	getBusinessNameLabel(organization: OrganizationStateModel) {
		if (organization && organization.business_name) {
			return organization.business_name;
		}
		if (
			organization &&
			organization.organization_type &&
			organization.organization_type === OrganizationTypeEnum.PRIVATE &&
			organization.contact_list
		) {
			const contact = this.utilService.returnIsMainOfList<ContactPouchModel>(organization.contact_list);
			if (contact) {
				return contact.full_name || contact.first_name + '|' + contact.last_name;
			}
		}
		return '-';
	}
}
