import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Message } from "./model/message.model";

@Component({
  selector: 'iframe-resolver',
  templateUrl: './iframe-resolver.component.html',
  styleUrls: ['./iframe-resolver.component.scss']
})
export class IframeResolverComponent {
  @Input()
  iframeUrl: string;
  safeUrl: SafeResourceUrl;
  bindFunction: EventListenerObject;
  iFrameHeight: number;
  constructor(public sanitizer: DomSanitizer) {
    this.bindEventFunction(this.receiveMessage);
  }

  ngOnInit(): void {
    this.safeUrl = this.sanitizeUrl(this.iframeUrl);
    this.addEventListener('message', this.bindFunction);
    this.iFrameHeight = window.innerHeight;
  }

  bindEventFunction(fn: Function){
    this.bindFunction =  fn.bind(this);
  }

  addEventListener(type: string, fn: EventListenerObject) {
    if (window.addEventListener) {
      window.addEventListener(type, fn, true);
    } else {
      (<any>window).attachEvent(`on${type}`, fn);
    }
  }

  removeEventListener(type: string, fn: EventListenerObject) {
    if (window.removeEventListener) {
      window.removeEventListener(type, fn, true);
    } else {
      (<any>window).detachEvent(`on${type}`, fn);
    }
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  receiveMessage(event: MessageEvent) {
    const message: Message<string> = event.data;
  }
  ngOnDestroy(): void {
    this.removeEventListener('message', this.bindFunction);
  }

}
