import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	AngularCoreUtilService,
	BaseStateModel,
	FormControlMultipurposeEnum,
	FormControlMultipurposeModel,
	FormControlMultipurposeService,
	MediaReplayService,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import {
	AuxiliaryTableStateModel,
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	OrganizationStateModel,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import {
	DestinationPouchModel,
	OrderPouchModel,
	OrderStatusEnum,
	OrganizationPouchModel
} from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../../../state';
import { OrganizationActionEnum } from '../../../../../../state/organization/organization.actions';
import { filter, map, mergeMap, take, debounceTime } from 'rxjs/operators';
import { OrderActionEnum } from '../../../../../../state/order/order.actions';
import { UtilOrderService } from '../../../../../../service/util/util-order.service';
import { FormGroup } from '@angular/forms';
import * as ConfigurationCustomerOrder from '../../../../../../constants/configuration-customer/order/order.constant';
import _ from 'lodash';
import { StoreUtilService } from '../../../../../../service/util/store-util.service';
import { SubscribeManagerItem } from '../../../../../../model/subscribe-manager.model';
import * as ConfigurationSubscribeManager from '../../../../../../constants/subscribe-manager.constant';
import * as ConfigurationCustomerOrderDestination from '../../../../../../constants/configuration-customer/order/order-destination.constant';
import * as ConfigurationCustomerOrderHeaderSetting from '../../../../../../constants/configuration-customer/order/order-header-setting.constant';

@Component({
	selector: 'order-detail-main-header-edit',
	templateUrl: './order-detail-main-header-edit.component.html',
	styleUrls: ['./order-detail-main-header-edit.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailMainHeaderEditComponent implements OnInit, OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> =
		this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'data', observable: this.subscribeData() }
	];

	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;
	orderStatusEnum = OrderStatusEnum;

	form: FormGroup;
	formFieldList: FormControlMultipurposeModel.Item[];
	subscribeFormDataPrefix = 'order-detail-main-header-edit-form-value-change_';
	formInit = true;

	constructor(
		public router: Router,
		public mediaReplayService: MediaReplayService,
		private subscribeManagerService: SubscribeManagerService,
		private store: Store,
		public utilOrderService: UtilOrderService,
		private formFieldMultipurposeService: FormControlMultipurposeService,
		private utilService: AngularCoreUtilService,
		private utilStoreService: StoreUtilService
	) {
		this.loadStaticData();
		//ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnInit(): void {
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// load static data
	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
		this.utilStoreService.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
			this.auxiliaryTable = res.data;
		});
	}

	subscribeData(): Observable<void> {
		return this.organization$.pipe(
			filter(
				(e: BaseStateModel<OrganizationPouchModel>) =>
					e && e.data && e.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((e: BaseStateModel<OrganizationPouchModel>) => {
				switch (e.type) {
					case OrganizationActionEnum.ERROR:
						throw new Error(OrganizationActionEnum.ERROR);
					case OrganizationActionEnum.UPDATE:
						this.organization = e.data;
						break;
				}
				return this.order$;
			}),
			filter(
				(e: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					this.organization &&
					e &&
					e.data &&
					!(
						e.type === OrderActionEnum.LOAD ||
						e.type === OrderActionEnum.REMOVE ||
						e.type === OrderActionEnum.DISABLE_SAVE_PUT
					)
			),
			map((e: BaseStateModel<OrderStateModel>) => {
				switch (e.type) {
					case OrderActionEnum.ERROR:
						throw new Error(OrderActionEnum.ERROR);
					case OrderActionEnum.UPDATE:
						if (!this.utilOrderService.orderDisabledViewConfirm) {
							this.order = e.data;
							if (this.formInit) {
								this.formInit = false;
								this.createForm();
							}
						}
						break;
				}
			})
		);
	}

	subscribeFormData(): Observable<any> {
		return this.form.valueChanges.pipe(
			debounceTime(500),
			map(async e => {
				this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, e);
				await this.formFieldMultipurposeService.updateFormControlAccordingToType(
					this.form,
					<FormControlMultipurposeModel.Item>{
						type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
						form_control_list: this.formFieldList
					},
					e
				);
				this.subscribeManagerService.populate(
					this.subscribeFormData().subscribe(),
					this.subscribeFormDataPrefix + this.formFieldList[0].form_id
				);
				if (this.form.valid) {
					this.utilOrderService.orderChangeTemp = this.returnOrderValueFromForm(e);
					this.utilOrderService.updateOrderSource.next({
						order: this.utilOrderService.orderChangeTemp,
						useLoader: false
					});
				}
			}),
			take(1)
		);
	}

	createForm() {
		const creationFieldMap: FormControlMultipurposeModel.Item[] =
			_.cloneDeep(ConfigurationCustomerOrder.creationFieldMapHeader(this.user));

		this.setOptionList(
			creationFieldMap,
			this.auxiliaryTable.causalHeaderSoList.map(i => {
				return {
					label: i.code_item + "-" + i.description,
					value: i.code_item
				}
			}),
			'order_causal'
		);

		this.setOptionList(
			creationFieldMap,
			this.auxiliaryTable.paymentList.map(i => {
				return {
					label: i.description,
					value: i.code_item
				}
			}),
			'payment_code'
		);

		const destinationList: DestinationPouchModel[] =
			this.organization.destination_list
			.filter(i => i.valid)
			.sort((a, b) => (a.code_item > b.code_item ? 1 : -1)); // TODO:  verificare il motivo del sort

		this.setOptionList(
			creationFieldMap,
			destinationList.map(i => {
				return {
					label: ConfigurationCustomerOrderDestination.getFormattedAddress(i),
					value: i.code_item
				}
			}),
			'goods_destination_code'
		);

		if (this.auxiliaryTable.methodShippingList) {
			this.setOptionList(
				creationFieldMap,
				this.auxiliaryTable.methodShippingList.map(i => {
					return {
						label: i.description,
						value: i.code_item
					}
				}),
				'shipping_method_code'
			);
		}

		this.formFieldList =
			this.formFieldMultipurposeService.returnFormFieldListWithRootFormId(
				creationFieldMap,
				`${this.utilService.guid()}`
			);
		const formValue = this.returnFormValueFromOrder(this.order);
		this.formFieldMultipurposeService.updateRootFormValueList(
			this.formFieldList[0].form_id,
			formValue
		);
		this.formFieldList =
			this.formFieldMultipurposeService.updateFormFieldListValue(
				this.formFieldList,
				formValue
			);

		const controls = {};
		for (const field of this.formFieldList) {
			controls[field.name] = this.formFieldMultipurposeService.returnNewFormControl(field);
		}
		this.form = new FormGroup(controls);
		this.subscribeManagerService.populate(
			this.subscribeFormData().subscribe(),
			this.subscribeFormDataPrefix + this.formFieldList[0].form_id
		);
		// scatena il primo salvataggio dell'ordine, nel caso in cui
		// - !orded._id
		// - la form non necessiti inserimenti per risultare valida
		if (!this.order._id && this.form.valid) {
			this.utilOrderService.updateOrderSource.next({
				order: this.returnOrderValueFromForm(this.form.value),
				useLoader: true
			});
		}
	}

	returnFormValueFromOrder(order: OrderStateModel) {
		const formValue = {};
		for (const i of ConfigurationCustomerOrder.creationFieldMapHeader(this.user)) {
			switch (i.name) {
				case 'goods_destination_code':
					formValue[i.name] =
						order.header.goods_destination_object ?
						order.header.goods_destination_object.code_item :
						null;
					break;
				default:
					if (order.header[i.name]) {
						formValue[i.name] = order.header[i.name];
					}
			}
		}
		return formValue;
	}

	returnOrderValueFromForm(formValue): OrderStateModel {
		const order: OrderStateModel = _.cloneDeep(this.order);
		for (const i of Object.keys(formValue)) {
			switch (i) {
				case 'goods_destination_code':
					order.header.goods_destination_object = {
						code_item: formValue[i]
					};
					break;
				default:
					order.header[i] = formValue[i];
					break;

			}		
		}
		ConfigurationCustomerOrderHeaderSetting.setOrderHeaderObjectForBodyTablePouchModel(
			order,
			this.utilOrderService.orderHeaderObjectBodyTablePouchModelList
		);
		ConfigurationCustomerOrderHeaderSetting.setOrderHeaderDestinationObject(
			order,
			this.organization.destination_list
		);
		return order;
	}

	setOptionList(
		creationFieldMap: FormControlMultipurposeModel.Item[],
		optionList: FormControlMultipurposeModel.OptionList[],
		fieldName: string
	) {
		const field = creationFieldMap.find(i => i.name === fieldName);
		if (field) {
			field.option_list = optionList;
		}
	}

}
