import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WidgetModule } from '../../widget/widget.module';
import { SharedModule } from '../../shared/shared.module';
import { PageCommonsModule } from '../commons/page-commons.module';
import { B2cOrganizationDetailComponent } from './b2c-organization/b2c-organization-detail/b2c-organization-detail.component';
import { B2cOrganizationDetailFullDetailComponent } from './b2c-organization/b2c-organization-detail/b2c-organization-detail-full-detail/b2c-organization-detail-full-detail.component';
import { IllustrationsModule } from '@saep-ict/angular-spin8-core';


@NgModule({
    imports: [WidgetModule, SharedModule, PageCommonsModule, IllustrationsModule],
    declarations: [B2cOrganizationDetailComponent, B2cOrganizationDetailFullDetailComponent],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageB2cModule {}
