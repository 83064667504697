import { AddressPouchModel } from './util-pouch.model';
import { DivisionPouchModel } from './organization-pouch.model';
import { BasePouchModel } from './base-pouch.model';

export interface AgentCustomerListPouchModel extends BasePouchModel {
	code_agent: string;
	values: AgentCustomerPouchModel[];
}

export interface AgentCustomerPouchModel {
	code: string;
	code_customer_destination: string[];
	business_name: string;
	vat_number: string;
	tax_code: string;
	customer_destination: boolean;
	status: AgentCustomerLockStatus;
	address: AddressPouchModel;
	divisions?: DivisionPouchModel[];
	valid: boolean;
}

export enum AgentCustomerLockStatus {
	ACTIVE = 'ACTIVE',
	BLOCKED = 'BLOCKED'
}
