import { LanguageItem } from '../util-pouch.model';

export interface Category<T = never> {
	_id: string;
	_rev?: string;
	type: 'category';
	level: string;
	code_item: string;
	code?: string;
	description: string;
	// parent?: string[] | string;
	parent?: any; // TODO: properly define type
	children: string[];
	sequence: number;
	hierarchy?: string[];
	category_list?: this[];
	custom_field?: T;
	valid?: boolean;
	language_list?: LanguageItem[];
	is_highlighted?: boolean;
  metaInformation?: CategoryMetaInformation;
}

export interface CategoryImage {
	code_item: string;
	image_id: string;
}

export interface CategoryMap {
	tree?: Category[];
	is_highlighted?: Category[];
}

export interface CategoryMetaInformation {
	ordered_quantity: number;
	free_sample: number;
	qty_free: number;
	total: number;
}
